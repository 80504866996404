import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface AddCircleOutlineIconProps extends SvgIconProps {}

const AddCircleOutlineIcon: React.FC<AddCircleOutlineIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z' />
  </IconBuilder>
);

export default AddCircleOutlineIcon;

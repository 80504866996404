import { type FC } from 'react';

// Custom HOCs
import WithAuth from 'core/components/shared/HOC/WithAuth';

// Custom Hooks
import useSelection from 'core/hooks/common/useSelection';
import { useAppSelector } from 'core/hooks/redux';
import {
  useManagerDocs,
  useDeleteManagersMutation,
  useEditManagerMutation,
} from 'features/manager/managers/hooks';

// Core Components
import Stack from 'core/components/base/layout/Stack';
import IconButton from 'core/components/base/inputs/IconButton';

// Common Components
import PageHeroBox from 'core/components/shared/Box/PageHero';
import RowStack from 'core/components/shared/Stack/RowStack';
import PageSize from 'core/components/shared/Filter/PageSize';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import Pagination from 'core/components/shared/Filter/Pagination';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import PageHeroButtonLink from 'core/components/shared/Button/PageHeroButtonLink';

// Feature Components
import ManagersDataGrid from 'features/manager/managers/components/list/DataGrid';

// Custom Icon Components
import DeleteIcon from 'core/components/icons/DeleteIcon';

// Context
import SelectionProvider from 'core/store/context/common/UseSelection';
import { useDialogContext } from 'core/store/context/DialogProvider';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Core Utilities
import { setAppAlert } from 'core/utilities/helper';

// Types
interface ManagersPageProps {}

const ManagersList: FC<ManagersPageProps> = (props) => {
  // Context
  const loading = useSelectLoading();
  const currentManagerId = useAppSelector((state) => state.manager.id);

  // Hooks
  const selectionMethods = useSelection();
  const { selections, onClearSelections } = selectionMethods;
  const { onOpenDialog, onCloseDialog } = useDialogContext();
  const managerDocs = useManagerDocs();
  const {
    data: { list, page },
    onPageNumChange,
    onPageSizeChange,
  } = managerDocs;
  const { mutate: handleDeleteManagers } = useDeleteManagersMutation({
    autoAlert: { mode: 'delete', name: 'مدیران انتخاب شده' },
    onSuccess: () => onClearSelections(),
    onSettled: () => onCloseDialog(),
  });
  const { mutate: handleEditManager } = useEditManagerMutation({
    autoAlert: { mode: 'update', name: 'وضعیت مدیر' },
  });

  const handleOpenDeleteDialog = () =>
    onOpenDialog({
      title: `آیا از حذف ${
        selections.length > 1 ? 'مدیران' : 'مدیر'
      } انتخاب شده مطمئن هستید؟`,
      onSubmit: handleDelete,
    });

  const handleDelete = () => {
    if (selections.length === 0) return;
    if (selections.includes(currentManagerId)) {
      setAppAlert('شما قادر به حذف حساب کاربری خودتان نمی‌باشید.', 'warning');
      return;
    }

    handleDeleteManagers(selections);
  };

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='مدیران' />
      <PageHeroBox>
        <HeadingSix>مدیران</HeadingSix>
        <Stack>
          {selections.length !== 0 && (
            <RowStack sx={{ alignItems: 'center' }} spacing={1}>
              <IconButton
                disabled={selections.length === 0 || loading}
                onClick={handleOpenDeleteDialog}
              >
                <DeleteIcon color='error' />
              </IconButton>
              <BodyTwo>{selections.length?.toPersian()} انتخاب شده</BodyTwo>
            </RowStack>
          )}
          <PageHeroButtonLink
            variant='outlined'
            disabled={selections.length > 1 || loading}
            href={`/managers/add`}
            state={
              selections.length === 1
                ? {
                    groupId: selections[0],
                  }
                : undefined
            }
          >
            افزودن
          </PageHeroButtonLink>
        </Stack>
      </PageHeroBox>
      <SelectionProvider {...selectionMethods}>
        <ColumnStack>
          <ManagersDataGrid
            managers={list}
            disabled={loading}
            onStatusChange={(managerId, status) =>
              handleEditManager({ id: managerId, data: { isActive: !status } })
            }
          />
          <RowStack>
            <PageSize
              disabled={loading || list?.length === 0}
              page={page}
              sizeRange={[5, 10, 20, 50, 100]}
              sx={{ height: '2rem' }}
              onSizeChange={onPageSizeChange}
              onPageChange={onPageNumChange}
            />
            <Pagination
              disabled={loading || list?.length === 0}
              page={page}
              onPageChange={onPageNumChange}
            />
          </RowStack>
        </ColumnStack>
      </SelectionProvider>
    </>
  );
};

export default WithAuth(ManagersList);

import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternTitleItem from 'features/appBuilder/patterns/core/components/shared/items/Title';

// Custom Types
export interface TagAndCategoryPatternPageConfigSectionItemProps {}
type CollapsedKey = 'section' | 'title';

const TagAndCategoryPatternPageConfigSection: FC<
  TagAndCategoryPatternPageConfigSectionItemProps
> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    title: false,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='تنظیمات محتوا'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='pageConfig'
    >
      <PatternTitleItem
        title='عنوان صفحه'
        reference='pageConfig.title'
        collapsed={collapsed.title}
        onCollapsedChange={handleCollapseChanged('title')}
        inputLabel='عبارت قبل از عنوان موضوع یا برچسب'
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'fontColor',
          'highlightColor',
        ]}
      />
    </PatternSectionContainer>
  );
};

export default TagAndCategoryPatternPageConfigSection;

import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  styled,
} from '@mui/material';

export interface AvatarProps extends MuiAvatarProps {}

const Avatar = styled(MuiAvatar)<AvatarProps>(({ theme, color }) => ({
  backgroundColor: theme.palette.info.main,
}));

export default Avatar;

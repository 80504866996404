import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface ExcelIconProps extends SvgIconProps {}
const ExcelIcon: React.FC<ExcelIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M10 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V18C0 19.11 0.89 20 2 20H14C15.11 20 16 19.11 16 18V6L10 0ZM14 18H2V2H9V7H14V18ZM8.9 12.5L11.8 17H10L8 13.6L6 17H4.2L7.1 12.5L4.2 8H6L8 11.4L10 8H11.8L8.9 12.5Z' />
  </IconBuilder>
);

export default ExcelIcon;

// Types
import type { FC } from 'react';

// Custom Core Components
import SvgIcon from 'core/components/base/display/SvgIcon';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';
import IconBuilder from 'core/components/shared/IconBuilder';

interface ColumnIconProps extends SvgIconProps {}

const ColumnIcon: FC<ColumnIconProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <g>
        <path d='M3.6,6.1h2.7c0.9,0,1.6,0.7,1.6,1.6v8.6c0,0.9-0.7,1.6-1.6,1.6H3.6c-0.9,0-1.6-0.7-1.6-1.6V7.7C2,6.8,2.7,6.1,3.6,6.1z' />
        <path d='M10.6,6.1h2.7c0.9,0,1.6,0.7,1.6,1.6v8.6c0,0.9-0.7,1.6-1.6,1.6h-2.7c-0.9,0-1.6-0.7-1.6-1.6V7.7C9.1,6.8,9.8,6.1,10.6,6.1z' />
        <path d='M17.7,6.1h2.7c0.9,0,1.6,0.7,1.6,1.6v8.6c0,0.9-0.7,1.6-1.6,1.6h-2.7c-0.9,0-1.6-0.7-1.6-1.6V7.7C16.1,6.8,16.8,6.1,17.7,6.1z' />
      </g>
    </IconBuilder>
  );
};

export default ColumnIcon;

import {
  TableBody as MuiTableBody,
  TableBodyProps as MuiTableBodyProps,
  styled,
} from '@mui/material';

export interface TableBodyProps extends MuiTableBodyProps {}

const TableBody = styled(MuiTableBody)<TableBodyProps>(() => ({}));

export default TableBody;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface ArrowBackIconProps extends SvgIconProps {}

const ArrowBackIcon: React.FC<ArrowBackIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20z' />
  </IconBuilder>
);

export default ArrowBackIcon;

import { type FC } from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Cusstom Core Components
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface RequiredIconProps extends SvgIconProps {}

const RequiredIcon: FC<RequiredIconProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <path
        style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
        d='M13.4,7.7c0,0.5,0.3,0.7,0.7,0.3l3.2-3.2c0.5-0.5,1.3-0.5,1.8,0L19.1,5c0.5,0.5,0.5,1.3,0,1.8L16,10
	c-0.4,0.4-0.2,0.7,0.3,0.7h4.5c0.7,0,1.3,0.6,1.3,1.3v0.2c0,0.7-0.6,1.3-1.3,1.3h-4.5c-0.5,0-0.7,0.3-0.3,0.7l3.2,3.2
	c0.5,0.5,0.5,1.3,0,1.8L19,19.1c-0.5,0.5-1.3,0.5-1.8,0L14,16c-0.4-0.4-0.7-0.2-0.7,0.3v4.5c0,0.7-0.6,1.3-1.3,1.3h-0.2
	c-0.7,0-1.3-0.6-1.3-1.3v-4.5c0-0.5-0.3-0.7-0.7-0.3l-3.2,3.2c-0.5,0.5-1.3,0.5-1.8,0L4.9,19c-0.5-0.5-0.5-1.3,0-1.8L8,14
	c0.4-0.4,0.2-0.7-0.3-0.7H3.3c-0.7,0-1.3-0.6-1.3-1.3v-0.2c0-0.7,0.6-1.3,1.3-1.3h4.5c0.5,0,0.7-0.3,0.3-0.7L4.9,6.8
	C4.4,6.3,4.4,5.5,4.9,5L5,4.9c0.5-0.5,1.3-0.5,1.8,0L10,8c0.4,0.4,0.7,0.2,0.7-0.3V3.3c0-0.7,0.6-1.3,1.3-1.3h0.2
	c0.7,0,1.3,0.6,1.3,1.3V7.7z'
      />
    </IconBuilder>
  );
};

export default RequiredIcon;

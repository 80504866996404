import { useEffect, useState, type FC } from 'react';
import { cloneDeepWith } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactSortable } from 'react-sortablejs';

// Core Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Feature Components
import ContentDetailsBlock from 'features/content/contents/components/body/blocks/block';
import RowStack from 'core/components/shared/Stack/RowStack';
import SortButton from 'features/form/shared/components/button/Sort';
import AddSectionLoadingButton from 'features/form/shared/components/loadingButton/AddSection';
import SaveLoadingButton from 'features/form/shared/components/loadingButton/Save';
import CancelButton from 'features/form/shared/components/button/Cancel';

// Core Utilities
import { forLoop } from 'core/utilities/helper';
import useBoolean from 'core/hooks/useBoolean';

// Feature Utilities
import { genContentBlockItem } from 'features/content/contents/utilities/content';

// Freature Types
import type { ContentDetailsProps } from 'features/content/contents/types/details';
import type { ContentBlockItemProps } from 'features/content/contents/types/details/block';

// Component Types
export interface ContentDetailsBlocksProps {}

const ContentDetailsBlocks: FC<ContentDetailsBlocksProps> = (props) => {
  // Props

  // Hooks
  const [focusedId, setFocusedId] = useState<string>('');
  const [blocks, setBlocks] = useState<ContentBlockItemProps[]>([]);
  const {
    state: sorting,
    setTrue: startSorting,
    setFalse: endSorting,
  } = useBoolean();
  const { control, getValues, setValue } =
    useFormContext<ContentDetailsProps>();

  useEffect(() => {
    if (sorting) {
      const clonedBlocks = cloneDeepWith(getValues('data.blocks'));
      setBlocks(clonedBlocks);
    }
  }, [sorting]);

  // Utilities
  const handleAddBlock = () => {
    const currentblocks = cloneDeepWith(getValues('data.blocks'));
    const sortIndex = currentblocks.length;

    const newBlock = genContentBlockItem(sortIndex);

    const blocks = cloneDeepWith(currentblocks);
    blocks.push(newBlock);
    setFocusedId(newBlock.id);

    setValue('data.blocks', blocks);
  };

  const handleDeleteBlock = (id: string) => {
    const currentblocks = cloneDeepWith(getValues('data.blocks'));

    const blockIndex = currentblocks.findIndex((block) => block.id === id);

    if (blockIndex > -1) {
      currentblocks.splice(blockIndex, 1);

      setValue('data.blocks', currentblocks);
    }
  };

  const handleFocus = (id: string) => {
    if (focusedId !== id) setFocusedId(id);
  };

  const handleSort = (sorted: ContentBlockItemProps[]) => {
    const sortedItems: ContentBlockItemProps[] = [];

    forLoop(sorted, (sortedItem, index) => {
      const updatedItemIndex = blocks.findIndex(
        (block) => block.id === sortedItem.id
      );

      if (updatedItemIndex > -1) {
        sortedItems.push({
          id: sortedItem.id,
          data: {
            ...blocks[updatedItemIndex].data,
            sortIndex: index,
          },
        });
      }
    });

    setValue('data.blocks', sortedItems);
  };

  const handleCancelSort = () => {
    setValue('data.blocks', blocks);
    endSorting();
  };

  return (
    <ColumnStack>
      <Controller
        control={control}
        name='data.blocks'
        render={({ field }) => (
          <ReactSortable
            disabled={!sorting}
            list={cloneDeepWith(field.value)}
            setList={(sorted) => handleSort(sorted)}
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            {field.value.map((block, index) => (
              <ContentDetailsBlock
                index={index}
                key={block.id}
                defaultValue={getValues(`data.blocks.${index}.data.text`) || ''}
                minimize={sorting}
                focused={block.id === focusedId}
                deletable={index > 0}
                onDelete={() => handleDeleteBlock(block.id)}
                onFocus={() => !sorting && handleFocus(block.id)}
                onHTMLChange={(html) =>
                  setValue(`data.blocks.${index}.data.text`, html)
                }
              />
            ))}
          </ReactSortable>
        )}
      />

      {sorting ? (
        <RowStack spacing={1}>
          <CancelButton onClick={handleCancelSort}>انصراف</CancelButton>
          <SaveLoadingButton
            sx={{ width: 'fit-content' }}
            onClick={() => endSorting()}
          />
        </RowStack>
      ) : (
        <RowStack spacing={1}>
          <AddSectionLoadingButton onClick={handleAddBlock}>
            بلوک جدید
          </AddSectionLoadingButton>
          <SortButton onClick={() => startSorting()}>
            جابجایی بلوک‌ها
          </SortButton>
        </RowStack>
      )}
    </ColumnStack>
  );
};

export default ContentDetailsBlocks;

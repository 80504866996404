import type { FC } from 'react';

// Feature Components
import ProfilePatternConfigSection from 'features/appBuilder/patterns/features/profile/components/Config';

// Custom Types
export interface ProfilePatternItemsProps {}

const ProfilePatternItems: FC<ProfilePatternItemsProps> = () => {
  return (
    <>
      <ProfilePatternConfigSection />
    </>
  );
};

export default ProfilePatternItems;

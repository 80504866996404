import { type FC } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';
import BodyOne from 'core/components/shared/Typography/BodyOne';
import BinIconButton from 'core/components/shared/IconButton/Bin';
import TextFieldNumber from 'core/components/shared/TextField/number';
import Button from 'core/components/base/inputs/Button';
import PlusCircleOutlineIcon from 'core/components/icons/PlusCircleOutline';
import DragIcon from 'core/components/icons/Drag';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import CloseIconButton from 'core/components/shared/IconButton/Close';
import IconButton from 'core/components/base/inputs/IconButton';
import UnfoldMoreIcon from 'core/components/icons/UnfoldMore';

// Feature Components
import PatternTypeAutocomplete from 'core/components/shared/Autocomplete/PatternType';
import PatternItemsAutocomplete from 'core/components/shared/Autocomplete/PatternItems';

// Core Utilities
import { generateObjectId } from 'core/utilities/helper/id';

// Feature Utilities
import { getPatternTitle } from 'features/appBuilder/patterns/core/utilities/helper/type';

// Feature Types
import type { PatternType } from 'features/appBuilder/patterns/core/types/item';
import type { PatternGroupColumnItemProps } from 'features/appBuilder/patterns/features/group/types/column';

// Custom Types
export interface GroupPatternColumnProps {
  reference: string;
  title?: string;
  prefix?: string;
  collapsed?: boolean;
  flexGrow?: boolean;
  itemIdToEdit?: string;
  onItemEditMode?: (itemId: string) => void;
  onRemove?: () => void;
  onCollapsedChange?: () => void;
}

type FieldKey = 0 | 1 | 2 | 3 | 4;
type FieldType = PatternGroupColumnItemProps & { fieldId: string };
const GroupPatternColumn: FC<GroupPatternColumnProps> = (props) => {
  // Props
  const {
    reference,
    prefix = 'ستون ',
    title = '',
    collapsed = false,
    itemIdToEdit,
    onRemove,
    onCollapsedChange,
    onItemEditMode,
  } = props;

  // Hooks
  const { control } = useFormContext();
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: `${reference}.data.items`,
    keyName: 'fieldId',
  });

  // Utilities
  const handleAddPattern = () => {
    if (fields.length < 5) {
      append({
        id: 'draft-header-' + generateObjectId(),
        data: {
          type: 'stable',
          patternId: '',
          patternTitle: '',
          sortIndex: fields.length - 1,
        },
      } as PatternGroupColumnItemProps);
    }
  };

  const updateFieldType = (
    pattern: PatternGroupColumnItemProps,
    index: number,
    type: PatternType
  ) => {
    update(index, {
      id: pattern.id,
      data: {
        ...pattern.data,
        type,
      },
    });
  };

  const updateFieldPatternId = (
    pattern: PatternGroupColumnItemProps,
    index: number,
    patternId: string,
    patternTitle: string
  ) => {
    update(index, {
      id: pattern.id,
      data: {
        ...pattern.data,
        patternTitle,
        patternId,
      },
    });
  };

  const handleRemoveGroup = (index: FieldKey) => {
    remove(index);

    if (onItemEditMode) onItemEditMode('');
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        flexGrow: collapsed ? 1 : 0,
        borderRadius: '0.5rem',
        overflow: 'hidden',
        display: 'flex',
        minHeight: '10rem',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          bgcolor: 'rgba(0, 0, 0, 0.1)',
          display: 'flex',
          p: '0.5rem 1rem',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: '3.5rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            flexGrow: 1,
            alignItems: 'center',
          }}
        >
          {!collapsed && (
            <IconButton
              onClick={() => onCollapsedChange && onCollapsedChange()}
            >
              <UnfoldMoreIcon sx={{ transform: 'rotate(90deg)' }} />
            </IconButton>
          )}
          <BodyOne>
            {collapsed ? prefix : ''}
            {title}
          </BodyOne>
        </Box>
        {collapsed && (
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Controller
              control={control}
              name={`${reference}.data.width`}
              render={({ field }) => (
                <InputWrapper
                  label='عرض: '
                  row
                  sx={{ alignItems: 'center', gap: 1 }}
                >
                  <TextFieldNumber
                    {...field}
                    variant='standard'
                    sx={{ width: '3.25rem', height: '2rem' }}
                    InputProps={{ sx: { height: '2rem' }, endAdornment: '%' }}
                  />
                </InputWrapper>
              )}
            />
            <BinIconButton onClick={onRemove} color='error' />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center',
          justifyContent: collapsed ? 'center' : 'flex-start',
          p: '1rem',
          width: '100%',
          height: '100%',
          flexGrow: 1,
        }}
      >
        {(fields as FieldType[]).map((field, index) => (
          <Box
            key={field.id + index}
            sx={{
              bgcolor: 'background.default',
              p: '0.75rem',
              borderRadius: '0.5rem',
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative',
              height: '3rem',
              maxHeight: '3rem',
              flexGrow: 1,
            }}
          >
            {collapsed && itemIdToEdit && itemIdToEdit !== field.id && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  bgcolor: 'rgba(255, 255, 255, 0.6)',
                  zIndex: 10,
                }}
              />
            )}
            {collapsed ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: itemIdToEdit === field.id ? 1 : 2,
                    flexGrow: 1,
                  }}
                >
                  {itemIdToEdit === field.id ? (
                    <Box sx={{ width: '2.5rem', height: '2.5rem' }} />
                  ) : (
                    <DragIcon />
                  )}
                  {itemIdToEdit === field.id ? (
                    <PatternTypeAutocomplete
                      exclude={[
                        // 'content',
                        'contentDetails',
                        'footer',
                        'form',
                        'group',
                        'header',
                        'profile',
                        'search',
                        // 'slider',
                        // 'stable',
                        'table',
                        'tagAndCategory',
                      ]}
                      defaultValue={field.data.type}
                      onChange={(type) => updateFieldType(field, index, type)}
                    />
                  ) : (
                    <BodyOne
                      sx={{
                        ':hover': {
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        },
                      }}
                      onClick={() => onItemEditMode && onItemEditMode(field.id)}
                    >
                      {getPatternTitle(field.data.type)}
                    </BodyOne>
                  )}
                  {itemIdToEdit === field.id ? (
                    <PatternItemsAutocomplete
                      patternType={field.data.type}
                      defaultValue={field.data.patternId}
                      onChange={(patternId, patternTitle) =>
                        updateFieldPatternId(
                          field,
                          index,
                          patternId,
                          patternTitle
                        )
                      }
                    />
                  ) : (
                    <Controller
                      control={control}
                      name={`${reference}.data.items.${index}.data.patternTitle`}
                      render={({ field }) => (
                        <BodyOne
                          color={field.value ? 'text.secondary' : 'error.light'}
                        >
                          {field.value ? field.value : 'انتخاب نشده*'}
                        </BodyOne>
                      )}
                    />
                  )}
                </Box>
                {itemIdToEdit === field.id ? (
                  <Button
                    variant='outlined'
                    onClick={() => onItemEditMode && onItemEditMode('')}
                  >
                    ذخیره
                  </Button>
                ) : (
                  <BinIconButton
                    onClick={() => handleRemoveGroup(index as FieldKey)}
                    color='error'
                  />
                )}
              </>
            ) : (
              <BodyOne>{getPatternTitle(field.data.type)}</BodyOne>
            )}
          </Box>
        ))}
        {collapsed && fields.length < 5 && (
          <Button
            variant='outlined'
            color='info'
            disabled={itemIdToEdit ? true : false}
            startIcon={<PlusCircleOutlineIcon />}
            onClick={handleAddPattern}
            sx={{ my: '1rem' }}
          >
            افزودن پترن
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default GroupPatternColumn;

export type FeatureType =
  | 'common' // For common use cases like profile page
  | 'content'
  | 'file'
  | 'form'
  | 'manager'
  | 'audience'
  | 'menu'
  | 'banner'
  | 'template'
  | 'data'
  | 'ticket';

export enum FeatureTypeLabel {
  common = 'عمومی',
  content = 'مطلب',
  file = 'فایل',
  form = 'فرم',
  manager = 'مدیر',
  audience = 'مخاطب',
  menu = 'منو',
  banner = 'بنر',
  template = 'اپلیکیشن‌ساز',
  data = 'دیتا',
  ticket = 'تیکت',
}

export type SubFeatureType =
  // Common
  | 'profile'
  // Content
  | 'contents'
  | 'categories'
  | 'tags'
  // File
  | 'files'
  //  Form
  | 'forms'
  | 'feedbacks'
  | 'processes'
  | 'formTags'
  // Manager
  | 'managers'
  | 'managerGroups'
  | 'managerSettings'
  | 'managerProfiles'
  // Audience
  | 'audiences'
  | 'audienceGroups'
  | 'audienceFolders'
  | 'audienceSettings'
  | 'audienceAccesses'
  // Menus
  | 'menus'
  // Banner
  | 'banners'
  // Template
  | 'templates'
  | 'customPages'
  | 'patterns'
  | 'skins'
  // Data
  | 'sheets'
  // Ticket
  | 'tickets'
  | 'ticketCategories';

export enum SubFeatureTypeLabel {
  profile = 'پروفایل',
  contents = 'مطالب',
  categories = 'موضوعات',
  tags = 'برچسب‌ها',
  files = 'فایل‌ها',
  forms = 'فرم‌ها',
  feedbacks = 'بازخوردها',
  processes = 'فرایندها',
  formTags = 'برچسب‌ها',
  managers = 'مدیران',
  managerGroups = 'گروه‌ها',
  managerSettings = 'تنظیمات',
  managerProfiles = 'پروفایل',
  audiences = 'مخاطبان',
  audienceGroups = 'گروه‌ها',
  audienceFolders = 'پوشه‌ها',
  audienceSettings = 'تنظیمات',
  audienceAccesses = 'اکسس‌ها',
  menus = 'منوها',
  banners = 'بنرها',
  templates = 'تمپلیت‌ها',
  customPages = 'صفحات سفارشی',
  patterns = 'پترن‌ها',
  skins = 'اسکین‌ها',
  sheets = 'شیت‌ها',
  tickets = 'تیکت‌ها',
  ticketCategories = 'موضوعات',
}

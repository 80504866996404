import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import TextFieldNumber from 'core/components/shared/TextField/number';

// Feature Shared Components
import PatternItemContainer, {
  type PatternItemContainerProps,
} from 'features/appBuilder/patterns/core/components/shared/items/Container';
import PatternImageDisplayTypeDropdown from 'features/appBuilder/patterns/core/components/shared/dropdown/DisplayType';
import PatternShareButtonDropdown from '../../dropdown/ShareButton';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
export interface PatternShareButtonItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
}

const PatternShareButtonItem: FC<PatternShareButtonItemProps> = (props) => {
  // Props
  const { reference, ...otherProps } = props;

  // Hooks
  const { control } = useFormContext();
  const loading = useSelectLoading();

  return (
    <PatternItemContainer reference={reference} {...otherProps}>
      <InputWrapper
        disabled={loading}
        label='اشتراک‌گذاری'
        sx={{ width: '100%' }}
      >
        <Controller
          control={control}
          name={`${reference}.items`}
          render={({ field }) => (
            <PatternShareButtonDropdown
              disabled={loading}
              defaultValue={field.value}
              reference={reference}
            />
          )}
        />
      </InputWrapper>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <InputWrapper
          disabled={loading}
          label='نوع نمایش'
          sx={{ width: '100%' }}
        >
          <Controller
            control={control}
            name={`${reference}.displayType`}
            render={({ field }) => (
              <PatternImageDisplayTypeDropdown
                disabled={loading}
                fullWidth
                reference={reference}
                defaultValue={field.value}
              />
            )}
          />
        </InputWrapper>
        <InputWrapper disabled={loading} label='اندازه' sx={{ width: '100%' }}>
          <Controller
            control={control}
            name={`${reference}.displayType`}
            render={({ field: f }) => (
              <Controller
                control={control}
                name={`${reference}.imageSize`}
                render={({ field }) => (
                  <TextFieldNumber
                    disabled={f.value === 'default' || loading}
                    fullWidth
                    {...field}
                  />
                )}
              />
            )}
          />
        </InputWrapper>
      </Box>
    </PatternItemContainer>
  );
};

export default PatternShareButtonItem;

import { memo, useCallback, type FC } from 'react';
import { useFormContext, Controller, SubmitHandler } from 'react-hook-form';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';

// Core Components
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import Box from 'core/components/base/layout/Box';
import GhostTextField from 'core/components/shared/ResizableTextField';
import SpacedBox from 'core/components/shared/Box/SpacedBox';
import Collapse from 'core/components/base/utils/Collapse';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Feature Components
import DeleteIconButton from 'features/form/shared/components/iconButton/Delete';
import SaveLoadingButton from 'features/form/shared/components/loadingButton/Save';
import ConfigIconButton from 'features/form/processes/components/details/shared/ConfigIconButton';
import EvaluationConfig from 'features/form/processes/components/details/Evaluation/Config';
import EvaluationOptions from 'features/form/processes/components/details/Evaluation/Options';
import AudienceGroupSelect from 'features/form/processes/components/details/shared/AudienceGroupSelect';
import ProcessCancelButton from 'features/form/processes/components/details/shared/Action/CancelButton';

// Core Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { useDialogContext } from 'core/store/context/DialogProvider';
import {
  useSelectProcessItemIsEditable,
  useSelectProcessItemIsFocused,
} from 'features/form/processes/store/selector';

// Context
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useFormDetailsSummaryContext } from 'features/form/forms/contexts/FormDetailsSummary';

// Feature Utilities
import { isEvaluationDraft } from 'features/form/processes/utilities/helper/evaluation';
import { updateProcessFocus } from 'features/form/processes/store/actions';
import {
  useAddProcessItemMutation,
  useDeleteProcessItemMutation,
  useEditProcessItemMutation,
} from 'features/form/processes/hooks';

// Feature Types
import type { EvaluationProps } from 'features/form/processes/types/details/evaluation';
import type { ProcessProps } from 'features/form/processes/types/details';

// Custom Types
export interface ProcessEvaluationSectionProps {
  index: number;
  onDelete: (index: number) => void;
}

const ProcessEvaluationSection: FC<ProcessEvaluationSectionProps> = (props) => {
  // Props
  const { index, onDelete } = props;

  // Context
  const { onOpenDialog, onCloseDialog } = useDialogContext();
  const { control, setValue, getValues, handleSubmit, reset, resetField } =
    useFormContext<ProcessProps>();
  const processId = getValues(`id`);
  const loading = useSelectLoading();
  const evaluationId = getValues(`data.items.${index}.id`);
  const isDraft = isEvaluationDraft(evaluationId);
  const isEditable = useSelectProcessItemIsEditable(evaluationId);
  const isFocused = useSelectProcessItemIsFocused(evaluationId);
  const formDetails = useFormDetailsSummaryContext();

  // Hooks
  const { mutate: handleAddProcessItem } = useAddProcessItemMutation({
    autoAlert: { mode: 'create', name: 'ارزیابی' },
    onSuccess: ({ docs }) => {
      reset({
        id: getValues('id'),
        data: {
          ...getValues('data'),
          items: docs,
        },
      });
      updateProcessFocus('');
    },
  });
  const { mutate: handleEditProcessItem } = useEditProcessItemMutation({
    autoAlert: { mode: 'edit', name: 'ارزیابی' },
    onSuccess: ({ docs }) => {
      reset({
        id: getValues('id'),
        data: {
          ...getValues('data'),
          items: docs,
        },
      });

      updateProcessFocus('');
    },
  });
  const { mutate: handleDeleteProcessItem } = useDeleteProcessItemMutation({
    autoAlert: { mode: 'delete', name: 'ارزیابی' },
    onSuccess: ({ docs }) => {
      const items = [...getValues('data.items')];
      items.splice(index, 1);
      resetField('data.items', {
        defaultValue: docs,
      });
      onCloseDialog();
      updateProcessFocus('');
    },
  });

  // States
  const isEditMode = useBoolean();

  // Utilities
  const handleCancel = useCallback(() => {
    updateProcessFocus('');
    if (isDraft) onDelete(index);
    else {
      resetField(`data.items.${index}.data.formFieldsIds`);
      resetField(`data.items.${index}.data.questionOptions`);
      resetField(`data.items.${index}.data.applicantFieldAccess`);
      resetField(`data.items.${index}.data.profileFieldAccess`);
      resetField(`data.items.${index}.data.evaluatorItemAccesses`);
      resetField(`data.items.${index}`);
    }
  }, [isDraft, index]);

  const handleOpenDeleteDialog = useCallback(() => {
    onOpenDialog({
      title: `آیا مطمئن هستید؟`,
      description: `با حذف ارزیابی، اطلاعات ثبت شده در این ارزیابی در بازخوردها باقی خواهند ماند اما در بازخوردهای بعدی، این ارزیابی وجود نخواهد داشت.`,
      onSubmit: () =>
        handleDeleteProcessItem(getValues(`data.items.${index}.id`)),
    });
  }, []);

  const handleFormSubmit: SubmitHandler<ProcessProps> = async (process) => {
    const item = process.data.items[index];

    if (item.data.processItemType === 'evaluation') {
      const isDraft = isEvaluationDraft(item.id);
      const evaluation = item as EvaluationProps;

      if (isDraft) {
        handleAddProcessItem({
          processId,
          processItem: evaluation,
        });
      } else {
        handleEditProcessItem({
          id: processId,
          data: evaluation,
        });
      }
    }
  };

  // Render
  return (
    <RoundPaper sx={{ p: '1.5rem' }}>
      <SpacedBox gap={1}>
        <Controller
          control={control}
          name={`data.items.${index}.data.title`}
          rules={{ onChange: () => updateProcessFocus(evaluationId) }}
          render={({ field }) => (
            <GhostTextField
              disabled={!isEditable || loading}
              variant='standard'
              placeholder='عنوان ارزیابی'
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name={`data.items.${index}.data.audienceGroupId`}
          render={({ field: idField }) => (
            <Controller
              control={control}
              name={`data.items.${index}.data.audienceGroupTitle`}
              render={({ field: titleField }) => (
                <AudienceGroupSelect
                  disabled={!isEditable || loading}
                  label={titleField.value || undefined}
                  selectedId={idField.value}
                  onItemClick={(item) => {
                    updateProcessFocus(evaluationId);
                    setValue(idField.name, item.id);
                    setValue(titleField.name, item.data.title);
                  }}
                />
              )}
            />
          )}
        />
        {isEditMode.state && !isDraft && (
          <DeleteIconButton
            disabled={!isEditable || loading}
            onClick={handleOpenDeleteDialog}
            disableRipple={false}
          />
        )}
        <ConfigIconButton disabled={!isEditable} onClick={isEditMode.toggle} />
      </SpacedBox>
      <ColumnStack spacing={1} sx={{ mt: '1rem', mb: '2rem' }}>
        <Controller
          control={control}
          name={`data.items.${index}.data.questionTitle`}
          rules={{ onChange: () => updateProcessFocus(evaluationId) }}
          render={({ field }) => (
            <GhostTextField
              disabled={!isEditable || loading}
              variant='standard'
              multiline
              placeholder='سوال ازریابی'
              {...field}
            />
          )}
        />
        <EvaluationOptions index={index} />
      </ColumnStack>
      {formDetails && (
        <Collapse in={isEditMode.state}>
          <EvaluationConfig index={index} />
        </Collapse>
      )}
      <Box sx={{ display: 'flex', gap: 1, width: 'fit-content', ml: 'auto' }}>
        <ProcessCancelButton
          isDraft={isDraft}
          itemId={evaluationId}
          onCancel={handleCancel}
        />
        <Controller
          control={control}
          name={`data.items.${index}.data.title`}
          render={({ field: titleField }) => (
            <Controller
              control={control}
              name={`data.items.${index}.data.questionTitle`}
              render={({ field: questionTitleField }) => (
                <Controller
                  control={control}
                  name={`data.items.${index}.data.audienceGroupId`}
                  render={({ field: audienceGroupIdField }) => (
                    <SaveLoadingButton
                      onClick={handleSubmit(handleFormSubmit)}
                      loading={loading}
                      loadingPosition='start'
                      disabled={
                        !titleField.value ||
                        !questionTitleField.value ||
                        !audienceGroupIdField.value ||
                        !isFocused
                      }
                      sx={{ p: '0.5rem', borderRadius: '0.5rem' }}
                      variant='outlined'
                    />
                  )}
                />
              )}
            />
          )}
        />
      </Box>
    </RoundPaper>
  );
};

export default memo(ProcessEvaluationSection, isSame);

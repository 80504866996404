// Feature Types
import type { SearchPatternPageConfigProps } from 'features/appBuilder/patterns/features/search/types/pageConfig';

export const searchPatterPageConfigDefaults: SearchPatternPageConfigProps = {
  isActive: true,
  title: {
    isActive: true,
    text: '',
    padding: '0px',
    fontSize: '14',
    fontColor: '#FFFFFF',
    highlightColor: '#12252E',
    textAlign: 'center',
    textDirection: 'rtl',
  },
};

import * as React from 'react';

// Custom Hooks
import { useSelectStepSections } from 'features/form/feedbacks/store';
import { useIndex } from 'core/store/context/IndexProvider';

// Custom Feature Components
import FeedbackSection from 'features/form/feedbacks/components/details/Section';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';

// Custom Types
interface FeedbackSectionsProps {
  stepId: string;
}

const FeedbackSections: React.FC<FeedbackSectionsProps> = (props) => {
  // Props
  const { stepId } = props;

  // Context
  const sections = useSelectStepSections(stepId);
  const feedbackIndex = useIndex();

  // Render
  return (
    <ColumnStack width='100%' spacing='1.5rem'>
      {sections.map(
        (section) =>
          !section.data.isHidden && (
            <FeedbackSection
              section={section}
              key={`feedback-${feedbackIndex}-${stepId}-${section.id}`}
            />
          )
      )}
    </ColumnStack>
  );
};

export default React.memo(FeedbackSections, isSame);

import * as React from 'react';
import isEqual from 'lodash/isEqual';

// Custom Hooks
import useArray from 'core/hooks/useArray';

// Common Components
import Paper from 'core/components/base/surfaces/Paper';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Feature Components
import InsertButton from 'features/file/files/components/shared/Dialog/InsertButton';
import FilesTable from 'features/file/files/components/shared/Table';
import UploadBox from 'features/file/files/components/shared/Dialog/UploadBox';

// Custom Utilities
import { filterInvalidFiles } from 'core/utilities/helper/file';

// Custom Types
import type { UseArrayReturnProps } from 'core/hooks/useArray';
import type { FileProps } from 'features/file/files/types';

interface FileUploadSectionProps {
  uploadQueue: UseArrayReturnProps<{ id: string; data: File }>;
  selectedFiles: UseArrayReturnProps<FileProps>;
  uploadedFiles?: FileProps[];
  files: UseArrayReturnProps<FileProps>;
  onInsertFile: () => void;
  onAddFileToQueue: (files: File[]) => void;
  onSelectFile: (file: FileProps, checked: boolean) => void;
  onToggleAll: () => void;
  onFileUploaded?: (file: FileProps) => void;
  onFileUpdated?: (file: FileProps) => void;
}

const FileUploadSection: React.FC<FileUploadSectionProps> = (props) => {
  // Props
  const {
    files,
    uploadQueue,
    selectedFiles,
    uploadedFiles,
    onAddFileToQueue,
    onFileUploaded,
    onInsertFile,
    onSelectFile,
    onToggleAll,
    onFileUpdated,
  } = props;

  // States
  const recentlyUploadedFiles = useArray<FileProps>(uploadedFiles || []);

  const handleFileUploaded = (file: FileProps) => {
    if (onFileUploaded) onFileUploaded(file);
    recentlyUploadedFiles.unshift(file);
    files.unshift(file);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    onAddFileToQueue(filterInvalidFiles(Array.from(e.dataTransfer.files)));
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleFileUpdated = (file: FileProps) => {
    if (onFileUpdated) onFileUpdated(file);
    recentlyUploadedFiles.updateById(file.id, (obj) => (obj.data = file.data));
  };

  // Render
  return (
    <ColumnStack
      component={Paper}
      spacing='1rem'
      sx={{ borderRadius: '1rem', paddingBottom: '1rem' }}
    >
      <FilesTable
        disableSort
        titleClickBehavior='popup'
        onSelectFile={onSelectFile}
        onFileUpdated={handleFileUpdated}
        onToggleAll={onToggleAll}
        selectedFiles={selectedFiles.state}
        disableDragAndDrop
        onFileUploaded={handleFileUploaded}
        uploadQueue={uploadQueue}
        files={recentlyUploadedFiles}
        sx={{
          minHeight: '40vh',
          borderRadius: 0,
          borderTopLeftRadius: '1rem',
          borderTopRightRadius: '1rem',
          borderBottom: '1px solid #ccc',
        }}
      />
      <ColumnStack sx={{ paddingX: '1rem' }}>
        {!recentlyUploadedFiles.isEmpty && (
          <InsertButton
            onClick={onInsertFile}
            disabled={selectedFiles.isEmpty}
          />
        )}
        <UploadBox
          onFileChange={(e) =>
            onAddFileToQueue(Array.from(e?.target?.files || []))
          }
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          sx={{
            width: '100%',
            border: '1px dashed #ccc',
            height: '176px',
          }}
        />
      </ColumnStack>
    </ColumnStack>
  );
};

export default React.memo(FileUploadSection, isEqual);

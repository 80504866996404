import * as React from 'react';
import { Link } from 'react-router-dom';

// Core Components
import IconButton from 'core/components/base/inputs/IconButton';

// Types
import type { IconButtonProps } from 'core/components/base/inputs/IconButton';
export interface IconButtonLinkProps extends IconButtonProps {
  href?: string;
  state?: any;
}

const IconButtonLink: React.FC<IconButtonLinkProps> = (props) => {
  const { children, state, disabled, href = '/', ...otherProps } = props;

  return disabled ? (
    <IconButton disabled={disabled} {...otherProps}>
      {children}
    </IconButton>
  ) : (
    <Link
      to={href}
      style={{
        color: 'inherit',
        textDecoration: 'none',
      }}
      state={state}
    >
      <IconButton {...otherProps}>{children}</IconButton>
    </Link>
  );
};

export default IconButtonLink;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface BinIconProps extends SvgIconProps {}

const BinIcon: React.FC<BinIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M4.77,6.33l0,12a3.45,3.45,0,0,0,3.45,3.45h7.5a3.45,3.45,0,0,0,3.45-3.45l0-12h2a.78.78,0,0,0,.69-.4.77.77,0,0,0,0-.8.79.79,0,0,0-.69-.39H15.61V4.07a1.86,1.86,0,0,0-1.86-1.85h-3.5A1.86,1.86,0,0,0,8.39,4.07v.67H2.8a.79.79,0,0,0-.69.39.77.77,0,0,0,0,.8.78.78,0,0,0,.69.4Zm8,10.93a.83.83,0,0,1-.4.69.81.81,0,0,1-.8,0,.83.83,0,0,1-.4-.69V9.59a.8.8,0,0,1,.4-.69.77.77,0,0,1,.8,0,.8.8,0,0,1,.4.69Zm1.32-7.67a.8.8,0,0,1,.4-.69.77.77,0,0,1,.8,0,.8.8,0,0,1,.4.69v7.67a.83.83,0,0,1-.4.69.81.81,0,0,1-.8,0,.83.83,0,0,1-.4-.69ZM10,4.07a.27.27,0,0,1,.27-.26h3.5a.27.27,0,0,1,.19.08.24.24,0,0,1,.07.18v.67H10ZM8.28,9.59a.8.8,0,0,1,.4-.69.77.77,0,0,1,.8,0,.8.8,0,0,1,.4.69v7.67a.83.83,0,0,1-.4.69.81.81,0,0,1-.8,0,.83.83,0,0,1-.4-.69Z' />
  </IconBuilder>
);

export default BinIcon;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface LayoutIconProps extends SvgIconProps {}

const LayoutIcon: React.FC<LayoutIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M20,4H4C2.9,4,2,4.9,2,6l0,12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M4,9h10.5v3.5H4V9z M4,14.5h10.5
	V18H5c-0.6,0-1-0.5-1-1V14.5z M19,18h-2.5V9H20v8C20,17.5,19.5,18,19,18z'
    />
  </IconBuilder>
);

export default LayoutIcon;

// Custom Page Components
import NotFound from 'features/manager/auth/components/shared/NotFound';
import Files from 'features/file/files/components/list';
import Edit from 'features/file/files/components/details/index';

// Custom Utilities
import { getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'file';
const subFeature: SubFeatureType = 'files';
const filesRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/files',
    Component: Files,
    feature,
    subFeature,
  },
  {
    path: '/files/:id',
    Component: Edit,
    feature,
    subFeature,
  },
  {
    path: '/404',
    Component: NotFound,
    feature,
    subFeature,
  },
];

export default filesRoutes;

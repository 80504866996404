import { FC } from 'react';
import { Editor, useCurrentEditor } from '@tiptap/react';

// Core Components
import InsertLinkIcon from 'core/components/icons/InsertLink';
import IconButton, {
  IconButtonProps,
} from 'core/components/base/inputs/IconButton';

// Component Types
interface EditorLinkIconButtonProps extends IconButtonProps {
  editor: Editor | null;
}

const EditorLinkIconButton: FC<EditorLinkIconButtonProps> = (props) => {
  // Props
  const { children, editor, size = 'small', color, ...otherProps } = props;

  return (
    <IconButton
      color={color ?? editor?.isActive('link') ? 'info' : 'inherit'}
      size={size}
      {...otherProps}
    >
      <InsertLinkIcon />
    </IconButton>
  );
};

export default EditorLinkIconButton;

import { type FC } from 'react';

// Custom Common Components
import DataGrid from 'core/components/shared/DataGrid';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import RowStack from 'core/components/shared/Stack/RowStack';
import Pagination from 'core/components/shared/Filter/Pagination';
import PageSize from 'core/components/shared/Filter/PageSize';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useDocsContext } from 'core/store/context/useDeprecatedDocsApi';
import { useSelectionContext } from 'core/store/context/common/UseSelection';

// Custom Utilities
import getFeedbacksListDataGridColumns from 'features/form/feedbacks/components/list/Body/columns';
import { forLoop } from 'core/utilities/helper';

// Custom Types
import type { DataGridRowValueProps } from 'core/components/shared/DataGrid/dataGrid';
import type { FeedbackListItemProps } from 'features/form/feedbacks/types';

export interface FeedbacksListBodyProps {
  formId: string;
}

const FeedbacksListBody: FC<FeedbacksListBodyProps> = (props) => {
  // Props
  const { formId } = props;

  // Hooks
  const loading = useSelectLoading();
  const { list, page, other, onPageSizeChange, onPageNumChange, refresh } =
    useDocsContext<FeedbackListItemProps>();
  const { selections, onSelectionsChange } = useSelectionContext();

  const feedbackTitle =
    other.feedbackTitle !== undefined && Array.isArray(other.feedbackTitle)
      ? other.feedbackTitle
      : [];

  const isPublic =
    other.isPublic !== undefined && typeof other.isPublic === 'boolean'
      ? (other.isPulic as boolean)
      : true;

  const columns = getFeedbacksListDataGridColumns(
    feedbackTitle,
    refresh,
    loading,
    formId,
    isPublic
  );
  const rows: DataGridRowValueProps[] = [];

  if (list && list.length > 0) {
    forLoop(list, (item) => {
      rows.push({
        id: item.id,
        col1: item.data.title,
        col2: item.data.createdAt,
        col3: item.data.evaluationStatusTitle || '',
        col4: item.data.evaluationStatusUpdateDate || '',
        col5: item.data.isEditable,
      });
    });
  }

  return (
    <ColumnStack>
      <DataGrid
        showSelectAll
        selections={selections}
        allIds={
          Array.isArray(other['filterResultIds'])
            ? other['filterResultIds']
            : []
        }
        selectionLabel='بازخورد'
        onSelectionChange={onSelectionsChange}
        columns={columns}
        rows={rows}
      />
      <RowStack>
        <PageSize
          disabled={loading || list.length === 0}
          page={page}
          sx={{ height: '2rem' }}
          onSizeChange={onPageSizeChange}
        />
        <Pagination
          disabled={loading || list.length === 0}
          page={page}
          onPageChange={(page) => onPageNumChange && onPageNumChange(page)}
        />
      </RowStack>
    </ColumnStack>
  );
};

export default FeedbacksListBody;

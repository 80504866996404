import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface PersonIconProps extends SvgIconProps {}

const PersonIcon: React.FC<PersonIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4m0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4' />
  </IconBuilder>
);

export default PersonIcon;

import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import InputWrapper from 'core/components/shared/Wrapper/Input';

// Feature Shared Components
import PatternItemContainer, {
  type PatternItemContainerProps,
} from 'features/appBuilder/patterns/core/components/shared/items/Container';
import TextFieldNumber from 'core/components/shared/TextField/number';

// Custom Types
export interface PatternSizeItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
  keyName?: string;
}

const PatternSizeItem: FC<PatternSizeItemProps> = (props) => {
  // Props
  const { reference, keyName = 'size', ...otherProps } = props;

  // Hooks
  const { control } = useFormContext();

  return (
    <PatternItemContainer reference={reference} {...otherProps}>
      <InputWrapper label='اندازه'>
        <Controller
          control={control}
          name={`${reference}.${keyName}`}
          render={({ field }) => <TextFieldNumber {...field} />}
        />
      </InputWrapper>
    </PatternItemContainer>
  );
};

export default PatternSizeItem;

// Types
import type { FC } from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface FileUploadIconProps extends SvgIconProps {}

const FileUploadIcon: FC<FileUploadIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M10 0.300781H2C0.9 0.300781 0.0100002 1.20078 0.0100002 2.30078L0 18.3008C0 19.4008 0.89 20.3008 1.99 20.3008H14C15.1 20.3008 16 19.4008 16 18.3008V6.30078L10 0.300781ZM14 18.3008H2V2.30078H9V7.30078H14V18.3008ZM4 13.3108L5.41 14.7208L7 13.1408V17.3008H9V13.1408L10.59 14.7308L12 13.3108L8.01 9.30078L4 13.3108Z' />
  </IconBuilder>
);

export default FileUploadIcon;

import * as React from 'react';
import isEqual from 'lodash/isEqual';

// Custom Common Components
import Prefixer from 'core/components/shared/Prefixer';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';

// Custom Features Components
import ProcessComment from 'features/form/feedbacks/components/details/Process/shared/Comment';

// Custom Icons
import EditPenIcon from 'features/form/feedbacks/components/icons/EditPen';

// Custom Utilities
import { getLocaleDateAndTime } from 'core/utilities/helper/date';

// Context
import { useSelectApplicantFullname } from 'features/form/feedbacks/store';

// Custom Types
import type { EvaluationReviewProps } from 'features/form/processes/types/details/evaluation/review';
import type { FeedbackProcessEvaluationProps } from 'features/form/feedbacks/types/detail/process/evaluation';

interface ApplicantReviewComponentProps {
  review: EvaluationReviewProps;
  evaluation: FeedbackProcessEvaluationProps;
}

const EvaluationReview: React.FC<ApplicantReviewComponentProps> = (props) => {
  // Props
  const { review, evaluation } = props;

  // Context
  const applicantFullname = useSelectApplicantFullname();
  const isCompleted =
    evaluation.data.overalStatus === 'completed' || review.data.completionDate
      ? true
      : false;

  // Render
  return (
    <ColumnStack
      sx={{
        borderRadius: '.5rem',
        gap: '.5rem',
        background: '#DFE8EF',
        width: '100%',
      }}
    >
      {(review.data.reviewQuestionTitle || isCompleted) && (
        <SpaceBetween
          sx={{
            width: '100%',
            gap: { xs: '.5rem', md: '1rem' },
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {review.data.reviewQuestionTitle && (
            <Prefixer
              Prefix={<EditPenIcon sx={{ color: '#59666D' }} />}
              spacing='.25rem'
              sx={{
                alignItems: 'center',
                gap: '.25rem',
                width: isCompleted ? { xs: '100%', md: '50%' } : '100%',
              }}
            >
              <ColumnStack spacing='.5rem'>
                <BodyTwo color='body'>
                  {review.data.reviewQuestionTitle}
                </BodyTwo>
                {review.data.createdAt && (
                  <BodyTwo
                    color='body'
                    sx={{ fontSize: '.875rem', direction: 'rtl' }}
                  >
                    {getLocaleDateAndTime(review.data.createdAt, '-')}
                  </BodyTwo>
                )}
              </ColumnStack>
            </Prefixer>
          )}
          {isCompleted && (
            <ProcessComment
              message={review.data.applicantReviewAnswer}
              fullName={applicantFullname}
              files={review.data.applicantReviewFiles}
              completionDate={review.data.completionDate}
              sx={{
                background: '#F5F8FA',
              }}
            />
          )}
        </SpaceBetween>
      )}
    </ColumnStack>
  );
};

export default React.memo(EvaluationReview, isEqual);

import * as React from 'react';

// Core Components
import Stack from 'core/components/base/layout/Stack';

import type { StackProps } from 'core/components/base/layout/Stack';
export interface ColumnStackProps extends StackProps {}

const ColumnStack: React.FC<ColumnStackProps> = (props) => {
  const { children, direction = 'column', spacing = 2, ...otherProps } = props;

  return (
    <Stack direction={direction} spacing={spacing} {...otherProps}>
      {children}
    </Stack>
  );
};

export default ColumnStack;

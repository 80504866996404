// Custom Utilities
import apiHandler from 'core/utilities/apiHandler';
import { getNumberTitle } from 'core/utilities/helper/helperPack';

// Custom Endpoinst
import { formStepsEndpoints as endpoints } from 'features/form/forms/utilities/api/endpoints';

// Custom Types
import type { FormStepProps } from 'features/form/forms/types/item/step';
import type { FormInput } from 'features/form/forms/types/item/input';
import type { FormSectionProps } from 'features/form/forms/types/item/section';

/**
 * Edits the steps of a form.
 *
 * @param {string} formId - The ID of the form whose steps will be edited.
 * @param {FormStepProps[]} steps - An array of form step objects containing the updated details.
 * @returns {Promise<{ status: number; docs: FormStepProps[] }>} A promise that resolves to an object containing the status and the updated form steps.
 *
 * @throws {Error} Throws an error if the request fails.
 */
export const editFormSteps = async (formId: string, steps: FormStepProps[]) => {
  const { status, data: response } = await apiHandler.patch<{
    status: number;
    docs: { data: FormStepProps[] };
  }>(endpoints.editDocs(), { steps });

  return { status, docs: response?.docs.data };
};

/**
 * Retrieves a form step by its ID.
 *
 * @param {string} stepId - The ID of the form step to retrieve.
 * @returns {Promise<{ status: number; sections: FormSectionProps[]; inputs: FormInput[] }>} A promise that resolves to an object containing the status, sections, and inputs of the form step.
 *
 * @throws {Error} Throws an error if the request fails.
 */
export const getFormStep = async (
  stepId: string,
  signal?: AbortSignal
): Promise<{
  status: number;
  sections?: FormSectionProps[];
  inputs?: FormInput[];
}> => {
  const { status, data: response } = await apiHandler.get<{
    docs: {
      id: string;
      data: {
        formId: string;
        inputs: FormInput[];
        sections: FormSectionProps[];
        sortIndex: number;
        title: string;
      };
    };
  }>(endpoints.getById(stepId), { signal });

  return {
    status,
    sections: response?.docs?.data?.sections,
    inputs: response?.docs?.data?.inputs,
  };
};

/**
 * Adds a new step to a form.
 *
 * @param {Object} payload - The payload containing form step details.
 * @param {string} payload.formId - The ID of the form to which the step will be added.
 * @param {number} payload.index - The index at which the step will be added.
 * @returns {Promise<{ status: number; docs?: FormStepProps }>} The status of the operation and the added form step details.
 */
export const addFormStep = async (payload: {
  formId: string;
  index: number;
}): Promise<{ status: number; docs?: FormStepProps }> => {
  const body = {
    formId: payload.formId,
    title: `مرحله ${getNumberTitle(payload.index + 1, 'fa-ordinal')}`,
    sortIndex: payload.index,
  };
  const { status, data } = await apiHandler.post<{
    status: number;
    docs: FormStepProps;
  }>(endpoints.add, body);
  return { status, docs: data?.docs };
};

/**
 * Removes a step from a form.
 *
 * @param {Object} payload - The payload containing step removal details.
 * @param {string} payload.stepId - The ID of the step to be removed.
 * @param {string} payload.activeStepId - The ID of the currently active step.
 * @returns {Promise<{
 *   status: number;
 *   sections: FormSectionProps[];
 *   inputs: FormInput[];
 * }>} The status of the operation, along with the updated sections and inputs of the form.
 */
export const removeFormStep = async (payload: {
  stepId: string;
  activeStepId: string;
}): Promise<{
  status: number;
  sections: FormSectionProps[];
  inputs: FormInput[];
}> => {
  const { status, data: response } = await apiHandler.delete<{
    docs: {
      data: {
        sections: FormSectionProps[];
        inputs: FormInput[];
      };
    };
  }>(endpoints.remove, {
    removeId: payload.stepId,
    getId: payload.activeStepId,
  });
  return {
    status,
    sections: response?.docs?.data?.sections || [],
    inputs: response?.docs?.data?.inputs || [],
  };
};

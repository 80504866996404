import store from 'core/store';
import { actions } from '../slice';

export const toggleFeedbackSectionVisibility = (sectionId: string) => {
  store.dispatch(actions.toggleSectionVisibility(sectionId));
};

export const setFeedbackColumnCount = (columnCount: number) => {
  store.dispatch(actions.setColumnCount(columnCount));
};

export const toggleFeedbackHideEmpties = (
  hide: boolean,
  inputIndex?: number,
  sectionIndex?: number
) => {
  store.dispatch(
    actions.toggleHideEmpties({ value: hide, inputIndex, sectionIndex })
  );
};

export const toggleFeedbackOnlyRequireds = (value: boolean) => {
  store.dispatch(actions.toggleOnlyRequireds(value));
};

import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

// Types
import type { PayloadAction } from '@reduxjs/toolkit';

// Custom Utilities
import {
  generateRows,
  initialTablePatternState,
  generateRow,
  getInitialTableCell,
  sortCells,
  moveRowCellsForward,
  moveRowCellsBackward,
} from 'features/appBuilder/patterns/features/table/utilities';
import {
  getAlphabetArrayRange,
  getAlphabetByIndex,
  getIndexByAlphabet,
  getNextAlphabet,
} from 'core/utilities/alphabet';

// Custom Types
import type {
  TablePatternStateDataProps,
  TablePatternStateProps,
  TableRowProps,
} from 'features/appBuilder/patterns/features/table/types';
import type { TableCellProps } from 'features/appBuilder/patterns/features/table/types/cell';
import type { TableCellStyleProps } from 'features/appBuilder/patterns/features/table/types/styles';
import type { ToolbarMode } from 'features/appBuilder/patterns/features/table/components/Toolbar';

export const patternSlice = createSlice({
  name: 'table',
  initialState: initialTablePatternState,
  reducers: {
    resetTable: (state) => {
      state = initialTablePatternState;
      return state;
    },
    createTable: (
      state,
      action: PayloadAction<{ colCount: number; rowCount: number }>
    ) => {
      const { colCount, rowCount } = action.payload;
      const rows: TableRowProps[] = generateRows(colCount, rowCount);

      state.data.rows = rows;
      state.data.initialized = true;
      state.data.rowCount = rowCount;
      state.data.colCount = colCount;
    },
    updateTable: (
      state,
      action: PayloadAction<
        Partial<{ id?: string; data?: Partial<TablePatternStateDataProps> }>
      >
    ) => {
      const table = action.payload;
      const clonedState = cloneDeep(state);

      const updatedTable: TablePatternStateProps = {
        id: table.id || clonedState.id,
        data: {
          ...clonedState.data,
          ...table.data,
        },
      };

      state = updatedTable;
      return state;
    },
    updateSelection: (
      state,
      action: PayloadAction<{
        cells: TableCellProps[];
        toolbarMode: ToolbarMode;
      }>
    ) => {
      const { cells, toolbarMode } = action.payload;

      state.data.selection = cells;
      state.data.toolbarMode = toolbarMode;
      return state;
    },
    addRow: (
      state,
      action: PayloadAction<{ index?: number; row?: TableRowProps } | undefined>
    ) => {
      const rows = cloneDeep(state.data.rows);
      const index =
        action.payload?.index !== undefined
          ? action.payload?.index
          : state.data.rowCount;
      const row =
        cloneDeep(action.payload?.row) ||
        generateRow(state.data.colCount, index);

      rows.splice(index + 1, 0, row);

      rows.forEach((row, rowIndex) => {
        Object.values(row).forEach((cell) => {
          cell.row = rowIndex;
        });
      });

      state.data.rowCount = rows.length;
      state.data.rows = rows;
    },
    deleteRow: (state, action: PayloadAction<number[]>) => {
      let rowIndexes = action.payload.sort((a, b) => (a - b > 0 ? 1 : -1));
      const rows: TableRowProps[] = [];

      state.data.rows.forEach((row, rowIndex) => {
        if (!rowIndexes.includes(rowIndex)) {
          const clonedRow = cloneDeep(row);
          rows.push(clonedRow);
        }
      });

      rows.forEach((row, rowIndex) => {
        Object.values(row).forEach((value) => (value.row = rowIndex));
      });

      state.data.rowCount = rows.length;
      state.data.rows = rows;
    },
    addColumn: (
      state,
      action: PayloadAction<{ index?: number; duplicate?: boolean } | undefined>
    ) => {
      const rows = cloneDeep(state.data.rows);
      const index =
        action.payload?.index !== undefined
          ? action.payload?.index
          : state.data.colCount;

      const colKey = getAlphabetByIndex(index, 'uppercase');

      let updatedRows = moveRowCellsForward(rows, colKey);

      let shouldDuplicate =
        action.payload?.duplicate &&
        state.data.selection.length > 0 &&
        state.data.selection.every(
          (cell) => cell.col === state.data.selection[0].col
        );

      const genNewCell = (row: number, col: number): TableCellProps => {
        if (shouldDuplicate) {
          const cellToReturn = state.data.selection.find(
            (cell) => cell.row === row && cell.col === col - 1
          );

          if (cellToReturn)
            return {
              ...cellToReturn,
              col: cellToReturn.col + 1,
            };
        }
        return getInitialTableCell({ row, col });
      };

      const newKey = getNextAlphabet(colKey, 'uppercase');
      updatedRows = updatedRows.map((row, rowIndex) => {
        row[newKey] = genNewCell(rowIndex, index + 1);

        let sortedRow = Object.keys(row)
          .sort()
          .reduce((sorted: TableRowProps, key: string) => {
            sorted[key] = row[key];

            return sorted;
          }, {});

        return sortedRow;
      });

      state.data.colCount = state.data.colCount + 1;
      state.data.rows = updatedRows;
    },
    deleteColumn: (state, action: PayloadAction<number>) => {
      const rows = cloneDeep(state.data.rows);
      const index = action.payload;

      const colKey = getAlphabetByIndex(index, 'uppercase');

      let updatedRows = moveRowCellsBackward(rows, colKey);

      state.data.colCount = state.data.colCount - 1;
      state.data.rows = updatedRows;
    },
    mergeCell: (
      state,
      action: PayloadAction<{ col: number; row: number; colSpan: number }[]>
    ) => {
      let cellsToMerge = sortCells(action.payload as TableCellProps[]).filter(
        (mergedCell) => mergedCell.colSpan > 1
      );

      const rows = cloneDeep(state.data.rows);
      let cellsToSelectAfterMerge: TableCellProps[] = [];

      cellsToMerge.forEach((cellToMerge) => {
        if (rows.length > cellToMerge.row) {
          const updatedRow = cloneDeep(rows[cellToMerge.row]);

          const cellName = getAlphabetByIndex(cellToMerge.col, 'uppercase');
          if (cellName in updatedRow) {
            updatedRow[cellName].colSpan = cellToMerge.colSpan;
            cellsToSelectAfterMerge.push(updatedRow[cellName]);
          }

          const cellsToRemove = getAlphabetArrayRange(
            cellToMerge.col + 1,
            cellToMerge.colSpan - 1,
            'uppercase'
          );

          cellsToRemove.forEach((cellName) => {
            if (cellName in updatedRow) {
              delete updatedRow[cellName];
            }
          });

          rows[cellToMerge.row] = updatedRow;
        }
      });

      state.data.selection = cellsToSelectAfterMerge;
      state.data.rows = rows;
    },
    unmergeCells: (state, action: PayloadAction<TableCellProps[]>) => {
      let mergedCells = sortCells(action.payload).filter(
        (mergedCell) => mergedCell.colSpan > 1
      );

      const rows = cloneDeep(state.data.rows);

      mergedCells.forEach((mergedCell) => {
        if (rows.length > mergedCell.row) {
          const rowNumber = mergedCell.row;
          const clonedRow = cloneDeep(rows[rowNumber]);

          const newCellNames = getAlphabetArrayRange(
            mergedCell.col + 1,
            mergedCell.colSpan - 1,
            'uppercase'
          );

          clonedRow[
            getAlphabetByIndex(mergedCell.col, 'uppercase')
          ].colSpan = 1;

          newCellNames.forEach((newCellName) => {
            const colNumber = getIndexByAlphabet(newCellName);
            clonedRow[newCellName] = getInitialTableCell({
              col: colNumber,
              row: rowNumber,
            });
          });

          rows[rowNumber] = clonedRow;
        }
      });

      state.data.rows = rows;
    },
    updateCell: (state, action: PayloadAction<TableCellProps>) => {
      const updatedCell = action.payload;
      const rows = cloneDeep(state.data.rows);

      if (rows.length > updatedCell.row) {
        const row = cloneDeep(rows[updatedCell.row]);
        const colKey = getAlphabetByIndex(updatedCell.col, 'uppercase');

        row[colKey] = {
          ...updatedCell,
        };

        rows[updatedCell.row] = row;
      }

      state.data.rows = rows;
    },
    updateCellsStyles: (
      state,
      action: PayloadAction<{
        cells: TableCellProps[];
        styles: TableCellStyleProps;
      }>
    ) => {
      const styles = action.payload.styles;
      let cells = sortCells(action.payload.cells);

      const rows = cloneDeep(state.data.rows);

      cells.forEach((cell) => {
        const rowNum = cell.row;
        const colName = getAlphabetByIndex(cell.col, 'uppercase');
        if (rows.length > cell.row && colName in rows[cell.row]) {
          const clonedRow = cloneDeep(rows[rowNum]);
          const clonedCell = cloneDeep(rows[rowNum][colName]);

          clonedCell.styles = styles;

          clonedRow[colName] = clonedCell;

          rows[rowNum] = clonedRow;
        }
      });

      state.data.rows = rows;
    },
    resetCells: (state, action: PayloadAction<TableCellProps[]>) => {
      let cells = sortCells(action.payload);

      const rows = cloneDeep(state.data.rows);

      cells.forEach((cell) => {
        const rowNum = cell.row;
        const colName = getAlphabetByIndex(cell.col, 'uppercase');
        if (rows.length > cell.row && colName in rows[cell.row]) {
          const clonedRow = cloneDeep(rows[rowNum]);
          const clonedCell = getInitialTableCell({
            col: cell.col,
            row: cell.row,
            colSpan: cell.colSpan || 1,
          });

          clonedRow[colName] = clonedCell;

          rows[rowNum] = clonedRow;
        }
      });

      state.data.rows = rows;
    },
  },
});

export default patternSlice.reducer;

export const {
  resetTable,
  createTable,
  updateTable,
  updateSelection,
  addRow,
  deleteRow,
  addColumn,
  deleteColumn,
  mergeCell,
  unmergeCells,
  resetCells,
  updateCell,
  updateCellsStyles,
} = patternSlice.actions;

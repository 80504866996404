import { useState, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';
import MenuItem from 'core/components/base/navigation/MenuItem';

// Common Components
import Select, { type SelectProps } from 'core/components/shared/Select';

// Custom Utilities
import { forLoop } from 'core/utilities/helper/array';

// Custom Types
import type { PatternShareType } from 'features/appBuilder/patterns/core/types/item/shareButton';
export interface PatternShareButtonDropdownProps
  extends Omit<SelectProps, 'defaultValue'> {
  reference: string;
  keyName?: string;
  defaultValue?: PatternShareType[];
}

type SelectOption = { value: PatternShareType; label: string };
const options: SelectOption[] = [
  { value: 'link', label: 'لینک' },
  { value: 'copy', label: 'کپی' },
  { value: 'telegram', label: 'تلگرام' },
  { value: 'whatsapp', label: 'واتس‌اپ' },
  { value: 'twitter', label: 'توئیتر' },
];

const PatternShareButtonDropdown: FC<PatternShareButtonDropdownProps> = (
  props
) => {
  // Props
  const {
    reference,
    keyName = 'items',
    defaultValue = ['link'],
    sx,
    ...otherProps
  } = props;

  // Hooks
  const { control, setValue, getValues } = useFormContext();
  const [opts] = useState<SelectOption[]>(options);

  // Utilties
  const getOptValue = (value: unknown): string[] => {
    const labels: string[] = [];

    if (value && Array.isArray(value) && value.length > 0) {
      forLoop(value, (v) => {
        const opt = options.find((opt) => opt.value === v);

        if (opt) {
          labels.push(opt.label);
        }
      });
    }

    return labels;
  };

  const handleChange = (opt: SelectOption) => {
    const values =
      (getValues(`${reference}.${keyName}`) as PatternShareType[]) || [];

    if (values && values.length > 0 && values.includes(opt.value)) {
      const indexToRemove = values.findIndex((v) => v === opt.value);

      if (indexToRemove > -1) {
        values.splice(indexToRemove);
      }
    } else {
      values.push(opt.value);
    }

    setValue(`${reference}.${keyName}`, values);
  };

  const renderValues = (value: unknown) => {
    const labels: string[] = getOptValue(value);

    return (
      <Box sx={{ display: 'flex', gap: 1, overflow: 'hidden' }}>
        {labels.map((label, index) => (
          <Box
            key={'dropdown' + index + label}
            sx={{
              p: '0.25rem 0.75rem',
              borderRadius: '0.25rem',
              bgcolor: 'background.default',
              width: 'fit-content',
            }}
          >
            {label}
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={`${reference}.${keyName}`}
      render={({ field }) => (
        <Select
          sx={{ height: '48px', ...sx }}
          size='small'
          multiple
          renderValue={renderValues}
          {...field}
        >
          {opts.map((opt) => (
            <MenuItem
              key={opt.label + opt.value}
              value={opt.value}
              onClick={() => handleChange(opt)}
            >
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

export default PatternShareButtonDropdown;

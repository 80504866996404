import { type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import MenuItem from 'core/components/base/navigation/MenuItem';

// Common Components
import Select, { type SelectProps } from 'core/components/shared/Select';

// Custom Types
import type {
  BgImageType,
  PatternImageDisplayType,
} from 'features/appBuilder/patterns/core/types/item/image';
export interface PatternBgTypeDropdownProps
  extends Omit<SelectProps, 'defaultValue'> {
  reference: string;
  keyName?: string;
  defaultValue?: BgImageType;
  disabled?: boolean;
}

type SelectOption = { value: PatternImageDisplayType; label: string };
const options: SelectOption[] = [
  { value: 'default', label: 'پیش فرض' },
  { value: 'custom', label: 'دلخواه' },
];

const PatternImageDisplayTypeDropdown: FC<PatternBgTypeDropdownProps> = (
  props
) => {
  // Props
  const {
    reference,
    keyName = 'displayType',
    defaultValue = 'default',
    sx,
    disabled,
    ...otherProps
  } = props;

  // Hooks
  const { control, setValue } = useFormContext();

  // Render
  return (
    <Controller
      control={control}
      name={`${reference}.${keyName}`}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Select
          disabled={disabled}
          sx={{ height: '48px', ...sx }}
          size='small'
          {...field}
        >
          {options.map((opt) => (
            <MenuItem
              key={`${reference}.${keyName}-${opt.label}`}
              value={opt.value}
              onClick={() => setValue(field.name, opt.value)}
            >
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

export default PatternImageDisplayTypeDropdown;

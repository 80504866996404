import * as React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';

// Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import SubmitButton from 'core/components/shared/Button/SubmitButton';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import TextFieldOutlined from 'core/components/shared/TextField/Outlined';
import PageHeroButtonLink from 'core/components/shared/Button/PageHeroButtonLink';
import MultiAutocomplete from 'core/components/shared/Autocomplete/api/MultipleAutocomplete';
import SingleAutocomplete from 'core/components/shared/Autocomplete/api/SingleAutocomplete';

// Feature Components
import AccessDialog from 'features/audience/audiences/components/list/Access';
import SelectOperation from 'features/audience/audiences/components/list/GroupOperation/SelectOperation';

// Custom Utilities
import { isSucceed, setAppAlert } from 'core/utilities/helper';
import { accessQueryKey } from 'features/audience/accesses/hooks';
import { audienceGroupsQueryKey } from 'features/audience/groups/hooks';
import { getAudienceAccessList } from 'features/audience/accesses/utilities/api';
import { getAudienceGroupSummaryList } from 'features/audience/groups/utilities/api';
import { audienceGroupOperation } from 'features/audience/audiences/utilities/helper';

// Context
import { useDocsContext } from 'core/hooks/api/useDocs/Context';
import { useDialogContext } from 'core/store/context/DialogProvider';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useSelectionContext } from 'core/store/context/common/UseSelection';

// Custom Types
import type { AudienceProps } from 'features/audience/audiences/types';
import type { AudienceOperationFormProps } from 'features/audience/audiences/components/list/Action';

interface AudienceOperationsProps {}
interface AccessResultProps {
  failed: string[];
  passed: string[];
}
const AudienceOperations: React.FC<AudienceOperationsProps> = (props) => {
  // States
  const showAccessDialog = useBoolean();
  const [accessResult, setAccessResult] = React.useState<AccessResultProps>();

  // Hooks
  const { control, setValue, getValues, reset, resetField } =
    useFormContext<AudienceOperationFormProps>();
  const selectedOperation = useWatch({
    control: control,
    name: 'selectedOperation',
    defaultValue: '',
  });

  // Context
  const loading = useSelectLoading();
  const { selections, onClearSelections, onSelectionsChange } =
    useSelectionContext();
  const { onOpenDialog, onCloseDialog } = useDialogContext();
  const { refetch } = useDocsContext<AudienceProps>();

  // Utilities
  const handleOpenConfirmDialog = () => {
    onOpenDialog({
      title:
        selectedOperation === 'DELETE'
          ? `آیا از حذف ${selections.length.toPersian()} مخاطب انتخاب شده مطمئن هستید؟`
          : `آیا از حذف گروه‌های انتخاب شده ${selections.length.toPersian()} مخاطب مطمئن هستید؟`,
      onSubmit: handleGroupOperation,
    });
  };

  const handleClearOperationValue = () => resetField('operationValue');

  const handleGroupOperation = async () => {
    const { operationValue, selectedOperation } = getValues();
    const { failed, passed, status } = await audienceGroupOperation(
      selectedOperation,
      selections,
      operationValue
    );

    if (isSucceed(status)) {
      refetch();
      reset();
      onClearSelections();
      onCloseDialog();
      if (selectedOperation === 'ADD_ACCESS') {
        setAccessResult({ failed, passed });
        showAccessDialog.setTrue();
      } else {
        setAppAlert('عملیات گروهی با موفقیت انجام شد.', 'success');
      }
    } else {
      setAppAlert('عملیات گروهی با خطا مواجه شد.');
    }
  };

  // Render
  return (
    <>
      <RowStack sx={{ alignItems: 'center' }}>
        <InputLabel
          color='text.secondary'
          sx={{ whiteSpace: 'nowrap', minWidth: 'fit-content' }}
        >
          عملیات گروهی
        </InputLabel>
        <Controller
          control={control}
          rules={{
            onChange: handleClearOperationValue,
          }}
          name='selectedOperation'
          render={({ field }) => (
            <SelectOperation
              disabled={loading || selections.length === 0}
              {...field}
            />
          )}
        />
        {['ADD_TO_GROUP', 'DELETE_FROM_GROUP'].includes(selectedOperation) && (
          <Controller
            control={control}
            key={selectedOperation}
            name='operationValue'
            render={({ field }) => (
              <MultiAutocomplete
                size='small'
                title='گروه مخاطبان'
                queryKey={audienceGroupsQueryKey}
                limitTags={1}
                apiHandler={getAudienceGroupSummaryList}
                disabled={loading || selections.length === 0}
                value={field.value}
                onChange={(_, selectedOpts) =>
                  setValue('operationValue', selectedOpts)
                }
                sx={{
                  width: '17rem',
                  '.MuiAutocomplete-inputRoot': {
                    minHeight: '2.5rem',
                  },
                }}
              />
            )}
          />
        )}
        {selectedOperation === 'UPDATE_JOB_TITLE' && (
          <Controller
            control={control}
            name='operationValue'
            render={({ field }) => (
              <TextFieldOutlined
                disabled={loading || selections.length === 0}
                placeholder='عنوان شغلی جدید'
                value={field?.value?.[0] || ''}
                onChange={(e) => setValue('operationValue', [e.target.value])}
                InputProps={{
                  style: {
                    height: '2.5rem',
                    borderRadius: '.5rem',
                    width: '17.125rem',
                  },
                }}
              />
            )}
          />
        )}
        {selectedOperation === 'ADD_ACCESS' && (
          <Controller
            control={control}
            name='operationValue'
            render={({ field }) => (
              <SingleAutocomplete
                placeholder='اکسس'
                apiHandler={getAudienceAccessList}
                queryKey={accessQueryKey}
                defaultValue={field?.value?.[0] || ''}
                size='small'
                onChange={(accessId) => setValue('operationValue', [accessId])}
                sx={{
                  width: '17.125rem',
                  '.MuiAutocomplete-inputRoot': {
                    minHeight: '2.5rem',
                  },
                }}
              />
            )}
          />
        )}
        {selectedOperation ? (
          <Controller
            control={control}
            name='operationValue'
            defaultValue={[]}
            render={({ field }) => (
              <SubmitButton
                onClick={
                  ['DELETE', 'DELETE_FROM_GROUP'].includes(selectedOperation)
                    ? handleOpenConfirmDialog
                    : handleGroupOperation
                }
                disabled={
                  loading ||
                  (['DELETE', 'REMOVE_ACCESS', 'EXPORT_EXCEL'].includes(
                    selectedOperation
                  )
                    ? false
                    : field.value.length === 0)
                }
                color='info'
                variant='contained'
              >
                اعمال
              </SubmitButton>
            )}
          />
        ) : (
          <PageHeroButtonLink
            sx={{ height: '2.5rem' }}
            variant='outlined'
            disabled={selections.length > 0 || loading}
            href={`/audiences/add`}
          >
            افزودن
          </PageHeroButtonLink>
        )}
      </RowStack>
      <AccessDialog
        open={showAccessDialog.state}
        failed={accessResult?.failed || []}
        passed={accessResult?.passed || []}
        onClose={showAccessDialog.setFalse}
        onChangeSelection={(ids) => {
          onSelectionsChange(ids);
          showAccessDialog.setFalse();
        }}
      />
    </>
  );
};

export default AudienceOperations;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface AuthIconProps extends SvgIconProps {}

const AuthIcon: React.FC<AuthIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M20.88,4.89a21.85,21.85,0,0,1-3.18-.8,13.44,13.44,0,0,1-5.16-2.82.52.52,0,0,0-.73,0A13.44,13.44,0,0,1,6.65,4.09a22.25,22.25,0,0,1-3.17.8A.55.55,0,0,0,3,5.4v9.07c.05.18,1,4.62,8.89,8.58a.18.18,0,0,1,.11.05.34.34,0,0,0,.14,0,.3.3,0,0,0,.13,0,.48.48,0,0,0,.11-.05c7.94-3.93,8.87-8.38,8.89-8.58V5.4a.53.53,0,0,0-.42-.51Zm-.62,1v8.49c-.11.44-1.25,4.2-8.09,7.67-6.85-3.49-8-7.27-8.09-7.67V5.85c1.31-.25,5.51-1.23,8.09-3.49C14.74,4.62,19,5.58,20.26,5.85Z' />
    <path d='M15.25,7.87h-.51V6.69a2.34,2.34,0,0,0-2.57-2,2.34,2.34,0,0,0-2.57,2V7.87H9.09a.47.47,0,0,0-.51.4v4a.47.47,0,0,0,.51.4h6.16a.47.47,0,0,0,.51-.4v-4A.47.47,0,0,0,15.25,7.87ZM10.63,6.69A1.4,1.4,0,0,1,12.17,5.5a1.4,1.4,0,0,1,1.54,1.19V7.87H10.63Zm4.11,5.14H9.6V8.66h5.14Z' />
    <path d='M9.55,14.41a.55.55,0,0,0-.72-.3l-.31.13V13.9a.56.56,0,1,0-1.11,0v.44L7,14.23a.56.56,0,0,0-.68.4.57.57,0,0,0,.39.69l.39.1-.21.35a.55.55,0,0,0,.17.77.52.52,0,0,0,.29.09.55.55,0,0,0,.47-.26L8,16l.25.31a.57.57,0,0,0,.43.2.53.53,0,0,0,.35-.13.56.56,0,0,0,.07-.79l-.26-.32.38-.15a.58.58,0,0,0,.3-.74Z' />
    <path d='M13.76,14.41a.55.55,0,0,0-.72-.3l-.3.13V13.9a.56.56,0,1,0-1.11,0v.44l-.42-.11a.56.56,0,0,0-.29,1.09l.39.1-.22.35a.56.56,0,0,0,.17.77.55.55,0,0,0,.3.09.56.56,0,0,0,.47-.26l.21-.34.26.31a.57.57,0,0,0,.43.2.53.53,0,0,0,.35-.13.56.56,0,0,0,.07-.79l-.26-.32.37-.15a.56.56,0,0,0,.3-.74Z' />
    <path d='M17.88,14.41a.55.55,0,0,0-.72-.3l-.3.13V13.9a.56.56,0,1,0-1.11,0v.44l-.42-.11A.56.56,0,0,0,15,15.32l.39.1-.22.35a.56.56,0,0,0,.17.77.55.55,0,0,0,.3.09.56.56,0,0,0,.47-.26l.21-.34.26.31a.57.57,0,0,0,.43.2.53.53,0,0,0,.35-.13.56.56,0,0,0,.07-.79l-.26-.32.37-.15A.56.56,0,0,0,17.88,14.41Z' />
  </IconBuilder>
);

export default AuthIcon;

import * as React from 'react';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import CircularProgress from 'core/components/base/feedback/CircularProgress';
import Typography from 'core/components/base/display/Typography';

// Custom Types
interface CircularProgressWithPercentageProps {
  percentage: number;
}

const CircularProgressWithPercentage: React.FC<
  CircularProgressWithPercentageProps
> = (props) => {
  // Props
  const { percentage } = props;

  // Render
  return (
    <>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          sx={{ minWidth: 60, minHeight: 60 }}
          variant='determinate'
          color={percentage === 100 ? 'success' : 'primary'}
          value={percentage}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant='caption' component='div' color='text.secondary'>
            {`${percentage}%`.toPersian()}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default CircularProgressWithPercentage;

import { useAppSelector } from 'core/hooks/redux';
import { forLoop } from 'core/utilities/helper';
import { deepClone, isSame } from 'core/utilities/helper/helperPack';
import { getInputValue } from 'features/form/feedbacks/utilities/details/helper';

// Custom Types
import type {
  AudienceInfo,
  FeedbackInputProps,
  FeedbackProps,
  FeedbackSectionProps,
  FeedbackStepProps,
} from 'features/form/feedbacks/types/detail';

/**
 * Returns all feedbacks from redux store.
 *
 * @returns {Feedback[]} Return the feedbacks or empty array if not found.
 */
export const useSelectFeedbacks = (): FeedbackProps[] =>
  useAppSelector(({ feedback }) => feedback.pdfValues || [], isSame);

/**
 * Finds and return feedback from redux store.
 *
 * @returns {Feedback[]} Return the feedback or undefind.
 */
export const useSelectFeedback = (
  index: number = -1
): FeedbackProps | undefined =>
  useAppSelector(
    ({ feedback }) =>
      index > -1
        ? feedback.pdfValues?.[index]
        : feedback.feedbackOverview?.feedback,
    isSame
  );

/**
 * Returns feedbacks from redux store.
 *
 * @returns {FeedbackProps[]} Return the feedbacks.
 */
export const useSelectFeedbackPdfValues = (): FeedbackProps[] =>
  useAppSelector(({ feedback }) => feedback.pdfValues, isSame);

/**
 * Returns feedbacks from redux store.
 *
 * @returns {FeedbackProps[]} Return the feedbacks.
 */
export const useSelectFeedbackExcelValues = (): FeedbackProps[] =>
  useAppSelector(({ feedback }) => feedback.excelValues, isSame);

/**
 * Returns all sections of a step from redux store.
 *
 * @param {string} stepId The id of the step.
 * @returns {FeedbackSectionProps[]} Return the step sections or empty array if not found.
 */
export const useSelectStepSections = (stepId: string): FeedbackSectionProps[] =>
  useAppSelector(({ feedback }) => {
    const sections: FeedbackSectionProps[] = [];
    if (feedback?.feedbackOverview?.inputs)
      forLoop(feedback?.feedbackOverview?.sections, (section) => {
        if (section?.data.stepId === stepId) sections.push(section);
      });

    return sections.sort((a, b) => a.data.sortIndex - b.data.sortIndex);
  }, isSame);

/**
 * Returns all steps from redux store.
 *
 * @returns {FeedbackStepProps[]} Return the steps or empty array if not found.
 */
export const useSelectSteps = (): FeedbackStepProps[] =>
  useAppSelector(
    ({ feedback }) =>
      deepClone(feedback?.feedbackOverview?.steps || [])?.sort(
        (a, b) => a.data.sortIndex - b.data.sortIndex
      ),
    isSame
  );

/**
 * Returns all sections from redux store.
 *
 * @returns {FeedbackSectionProps[]} Return the sections or empty array if not found.
 */
export const useSelectSections = (): FeedbackSectionProps[] =>
  useAppSelector(
    ({ feedback }) =>
      deepClone(feedback?.feedbackOverview?.sections || [])?.sort?.(
        (a, b) => a.data.sortIndex - b.data.sortIndex
      ),
    isSame
  );

/**
 * Returns all visible inputs of a section from redux store.
 *
 * @param {string} sectionId The id of the section.
 * @returns {FeedbackInputProps[]} Return the inputs or empty array if not found.
 */
export const useSelectVisibleSectionsInput = (
  sectionId: string,
  index: number = -1
): FeedbackInputProps[] =>
  useAppSelector(({ feedback }) => {
    const {
      onlyRequireds,
      hideEmpties,
      hiddenInputs: isInputHidden,
    } = feedback;
    let inputs: FeedbackInputProps[] = [];

    if (feedback?.feedbackOverview?.inputs)
      forLoop(feedback?.feedbackOverview?.inputs, (input) => {
        if (input?.data?.sectionId === sectionId) {
          let returnCondition = !(input.id in isInputHidden);

          if (onlyRequireds)
            returnCondition = returnCondition && input.data.isRequired;

          if (hideEmpties) {
            const value = getInputValue(input.id, index);
            const valueIsNotEmpty =
              value.length > 0 && value.some((v) => v.length > 0);

            returnCondition = returnCondition && valueIsNotEmpty;
          }

          if (returnCondition) inputs.push(input);
        }
      });

    return inputs.sort((a, b) => a.data.sortIndex - b.data.sortIndex);
  }, isSame);

/**
 * Returns all inputs of a section from redux store.
 *
 * @param {string} sectionId The id of the section.
 * @returns {FeedbackInputProps[]} Return the inputs or empty array if not found.
 */
export const useSelectSectionInputs = (
  sectionId: string
): FeedbackInputProps[] =>
  useAppSelector(
    ({ feedback }) =>
      (feedback.feedbackOverview?.inputs || [])
        .filter((input) => input?.data?.sectionId === sectionId)
        .sort((a, b) => a.data.sortIndex - b.data.sortIndex),
    isSame
  );

/**
 * Returns the value of an input from redux store.
 *
 * @param {string} inputId The id of the input.
 * @param {number} index The index of the feedback. if index is -1 It means that this selector is using in feedback details. else means is using
 * in PDF Preview dialog
 * @returns {string[]} Return the input value or empty array if not found..
 */
export const useSelectInputValue = (
  inputId: string,
  index: number = -1
): string[] =>
  useAppSelector(
    ({ feedback }) =>
      (index > -1
        ? feedback?.pdfValues?.[index]?.data?.values?.[inputId]
        : feedback?.feedbackOverview?.feedback?.data?.values?.[inputId]) || [],
    isSame
  );

/**
 * Returns the audience info of a feedback from redux store.
 *
 * @param {number} index The index of the feedback. if index is -1 It means that this selector is using in feedback details. else means is using
 * in PDF Preview dialog.
 *
 * @returns {Partial<AudienceInfo>} Return the profile or empty object if not found
 */
export const useSelectFeedbackProfile = (
  index: number = -1
): Partial<AudienceInfo> =>
  useAppSelector(
    ({ feedback }) =>
      (index > -1
        ? feedback?.pdfValues?.[index]?.data?.audienceInfo
        : feedback?.feedbackOverview?.feedback?.data?.audienceInfo) || {},
    isSame
  );

/**
 * Returns whether the input with the given ID is hidden.
 *
 * @param inputId The ID of the input to check.
 * @returns Whether the input is hidden.
 */
export const useSelectIsInputHidden = (inputId: string) =>
  useAppSelector(({ feedback }) => feedback.hiddenInputs?.[inputId] || false);

/**
 * Returns the hidden inputs id.
 *
 * @returns the hidden inputs id is hidden.
 */
export const useSelectHiddenInputs = () =>
  useAppSelector(({ feedback }) => feedback.hiddenInputs || {});

/**
 * Returns whether the section with the given ID is hidden.
 *
 * @param sectionId The ID of the section to check.
 * @returns Whether the section is hidden.
 */
export const useSelectIsSectionHidden = (sectionId: string) =>
  useAppSelector(({ feedback }) => feedback.hiddenInputs?.[sectionId] || false);

/**
 * Returns whether the input with the given ID is full width.
 *
 * @param inputId The ID of the input to check.
 * @returns Whether the input is full width.
 */
export const useSelectIsInputFullWidth = (inputId: string) =>
  useAppSelector(
    ({ feedback }) => feedback.fullWidthInputs?.[inputId] || false
  );

/**
 * Returns the fullWidthInputs object state.
 *
 * @returns the fullWidthInputs object state.
 */
export const useSelectFullWidthInputs = () =>
  useAppSelector(({ feedback }) => feedback.fullWidthInputs || {});

/**
 * Returns the feedback overview form title.
 *
 * @returns Feedback overview form title..
 */
export const useSelectFormTitle = () =>
  useAppSelector(({ feedback }) => feedback.feedbackOverview?.formTitle || '');

/**
 * Returns the inputs from the feedback overview.
 *
 * @returns The inputs from the feedback overview.
 */
export const useSelectInputs = () =>
  useAppSelector(
    ({ feedback }) => feedback.feedbackOverview?.inputs || [],
    isSame
  );

/**
 * Returns the audience info from the feedback overview.
 *
 * @returns The audience info from the feedback overview.
 */
export const useSelectAudienceInfo = () =>
  useAppSelector(
    ({ feedback }) => feedback.feedbackOverview?.audienceInfo || [],
    isSame
  );

/**
 * Select the feedback overview.
 *
 * @returns The feedback overview or null.
 */
export const useSelectFeedbackOverview = () =>
  useAppSelector(({ feedback }) => feedback.feedbackOverview, isSame);

/**
 * Select the feedback filter column count.
 *
 * @returns The column count.
 */
export const useSelectFeedbackColumnCount = () =>
  useAppSelector(({ feedback }) => feedback.columnCount);

/**
 * Select the the feedback filter onlyRequireds value.
 *
 * @returns True if onlyRequireds checked else, false.
 */
export const useSelectFeedbackOnlyRequireds = () =>
  useAppSelector(({ feedback }) => feedback.onlyRequireds);

/**
 * Select the feedback filter hide empties.
 *
 * @returns True if hideEmpties is checked else, false.
 */
export const useSelectFeedbackHideEmpties = () =>
  useAppSelector(({ feedback }) => feedback.hideEmpties);

/**
 * Select the feedback filter toggle mode.
 *
 * @returns Return the feedback toggle mode
 */
export const useSelectFeedbackToggleMode = () =>
  useAppSelector(({ feedback }) => feedback.toggleMode);

/**
 * Select the isPdfPreviewOpen.
 *
 * @returns Return true if Pdf Preview Dialog is Open, else return false
 */
export const useSelectIsPdfPreviewOpen = () =>
  useAppSelector(({ feedback }) => feedback.isPdfPreviewOpen);

import store from 'core/store';
import { addAlert } from 'core/store/slices/core/shared/notification';

// Custom Types
import type { AlertColor } from 'core/components/base/feedback/Alert';

/**
 * Sets an alert with the specified message, severity, and duration.
 * @param {string} message - The message to display in the alert.
 * @param {AlertColor} [severity] - The severity of the alert (optional). Default value is 'error'.
 * @param {string} redirectUrl - The url to redirect to it (optional).
 * @param {string} redirectTitle - The title to use in redirect button title (optional) .
 * @param {number} [duartion] - The duration of the alert in milliseconds (optional).
 * @returns {void}
 */
export const setAppAlert = (
  message: string,
  severity?: AlertColor,
  redirectUrl?: string,
  redirectTitle?: string,
  redirectButtonColor?: AlertColor,
  duartion: number = 2000
): void => {
  store.dispatch(
    addAlert({
      message,
      severity: severity || 'error',
      duartion,
      redirectTitle,
      redirectUrl,
      redirectButtonColor,
    })
  );
};

export type AlertCrudType = 'delete' | 'create' | 'edit' | 'read' | 'update';

/**
 * Gets the success message for a CRUD operation.
 *
 * @param type The type of CRUD operation.
 * @param featureName The name of the feature being operated on.
 * @returns Nothing.
 */
export const setSuccessCrudAlert = (
  type: AlertCrudType,
  featureName: string
) => {
  let message = '';
  switch (type) {
    case 'create':
      message = `${featureName} با موفقیت ایجاد شد.`;
      break;
    case 'read':
      message = `${featureName} با موفقیت دریافت شد.`;
      break;
    case 'delete':
      message = `${featureName} با موفقیت حذف شد.`;
      break;
    case 'edit':
      message = `${featureName} با موفقیت ویرایش شد.`;
      break;
    case 'update':
      message = `${featureName} با موفقیت بروزرسانی شد.`;
      break;
    default:
      message = 'عملیات مورد نظر با موفقیت انجام شد.';
  }
  setAppAlert(message, 'success');
};

/**
 * Gets the failure message for a CRUD operation.
 *
 * @param type The type of CRUD operation.
 * @param featureName The name of the feature being operated on.
 * @returns Nothing.
 */
export const setFailedCrudAlert = (
  type: AlertCrudType,
  featureName: string
) => {
  let message = '';
  switch (type) {
    case 'create':
      message = `ایجاد ${featureName} با خطا مواجه شد.`;
      break;
    case 'read':
      message = `دریافت ${featureName} با خطا مواجه شد.`;
      break;
    case 'delete':
      message = `حذف ${featureName} با خطا مواجه شد.`;
      break;
    case 'edit':
      message = `ویرایش ${featureName} با خطا مواجه شد.`;
      break;
    case 'update':
      message = `بروزرسانی ${featureName} با خطا مواجه شد.`;
      break;
    default:
      message = 'خطا در انجام عملیات مربوطه';
  }
  setAppAlert(message, 'error');
};

// Types
import type { FC } from 'react';

// Common Components
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import PageTitle from 'core/components/shared/Typography/PageTitle';

// Feature Components
import TemplateContainersDateGrid from 'features/appBuilder/templates/components/list/DataGrid';

// Custom Utilities
import { useTemplateDocs } from 'features/appBuilder/templates/hooks';

// Custom Types
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';

interface TemplatesProps extends WithAuthProps {}

const Templates: FC<TemplatesProps> = (props) => {
  // Hooks
  const { data: templates } = useTemplateDocs();

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='تمپلیت‌ها' />
      <PageHeroBox>
        <PageTitle>تمپلیت‌ها</PageTitle>
      </PageHeroBox>
      <ColumnStack>
        <TemplateContainersDateGrid
          selectable={false}
          containers={templates.list}
        />
      </ColumnStack>
    </>
  );
};

export default Templates;

import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import GhostTextField from 'core/components/shared/ResizableTextField';

// Feature Types
import type { ContentDetailsProps } from 'features/content/contents/types/details';

// Custom Types
export interface ContentDetailsTitleProps {}

const ContentDetailsTitle: FC<ContentDetailsTitleProps> = (props) => {
  // Hooks
  const { control, getValues, setValue } =
    useFormContext<ContentDetailsProps>();

  return (
    <Controller
      control={control}
      name='data.title'
      rules={{
        onBlur: () => {
          const title = getValues('data.title');
          const prevSlug = getValues('data.slug');
          let slugToSet = title
            .replaceAll(/[?$#!()@٬٫٪×،*+=:^&%--ـ]/g, '')
            .trim()
            .replaceAll(/ /g, '_');

          if (!prevSlug) setValue('data.slug', slugToSet);
        },
      }}
      render={({ field, fieldState }) => (
        <GhostTextField
          variant='standard'
          placeholder='نوشتن عنوان*...'
          fullWidth={false}
          InputProps={{
            sx: { fontSize: '2rem' },
            style: { borderRadius: '8px' },
          }}
          error={fieldState.error ? true : false}
          helperText={fieldState.error?.message}
          {...field}
        />
      )}
    />
  );
};

export default ContentDetailsTitle;

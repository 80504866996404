// Custom Utilities
import apiHandler from 'core/utilities/apiHandler';

// Feature Utilities
import { processItemEndpoints as endpoints } from 'features/form/processes/utilities/api/endpoints';
import { bakeProcessItem } from 'features/form/processes/utilities/api/bakers/processItem';

// Custom Types
import type { ManagerQuestionProps } from 'features/form/processes/types/details/managerQuestion';
import type { EvaluationProps } from 'features/form/processes/types/details/evaluation';
import type { ProcessItemType } from 'features/form/processes/types/details/common/type';
import { ApiGetDocResponse } from 'core/types/api/hook/response';

/**
 * Asynchronously adds a new process item to a specified process in the backend.
 *
 * This function sends a POST request to create a new process item, utilizing the
 * `bakeProcessItem` function to format the request body appropriately.
 *
 * @param {string} processId - The ID of the process to which the item will be added.
 * @param {ManagerQuestionProps | EvaluationProps} processItem - The process item to be added, which can be either a manager question or an evaluation.
 *
 * @returns {Promise<{
 *   status: number;
 *   docs: (EvaluationProps | ManagerQuestionProps)[];
 * }>} A promise that resolves to an object containing:
 *   - status: The HTTP status code of the response.
 *   - docs: An array of the created process item objects.
 *
 */
export const addProcessItem = async (payload: {
  processId: string;
  processItem: ManagerQuestionProps | EvaluationProps;
}): Promise<{
  status: number;
  docs: (EvaluationProps | ManagerQuestionProps)[];
}> => {
  const body = bakeProcessItem(payload.processId, payload.processItem);

  const { status, data: response } = await apiHandler.post<{
    docs: (EvaluationProps | ManagerQuestionProps)[];
  }>(endpoints.add, body);

  return {
    status: status,
    docs: (response && response.docs) || [],
  };
};

/**
 * Asynchronously edits an existing process item in the backend.
 *
 * This function sends a PATCH request to update the specified process item,
 * utilizing the `bakeProcessItem` function to format the request body appropriately.
 *
 * @param {string} processId - The ID of the process to which the item belongs.
 * @param {ManagerQuestionProps | EvaluationProps} processItem - The process item to be edited, which can be either a manager question or an evaluation.
 *
 * @returns {Promise<{
 *   status: number;
 *   docs: (EvaluationProps | ManagerQuestionProps)[];
 * }>} A promise that resolves to an object containing:
 *   - status: The HTTP status code of the response.
 *   - docs: An array of the updated process item objects.
 *
 */
export const editProcessItem = async (
  processId: string,
  processItem: ManagerQuestionProps | EvaluationProps
): Promise<{
  status: number;
  docs: (EvaluationProps | ManagerQuestionProps)[];
}> => {
  const body = bakeProcessItem(processId, processItem);

  const { status, data: response } = await apiHandler.patch<{
    docs: (EvaluationProps | ManagerQuestionProps)[];
  }>(endpoints.edit(processItem.id), body);

  return {
    status: status,
    docs: response?.docs || [],
  };
};

/**
 * Asynchronously retrieves a process item by its ID from the backend.
 *
 * This function sends a GET request to fetch the details of a specific process item,
 * optionally casting the response to a specific type based on the provided `asTypeOf` parameter.
 *
 * @param {string} processItemId - The ID of the process item to retrieve.
 * @param {ProcessItemType} [asTypeOf] - An optional parameter to specify the type of the process item
 *        ('evaluation' or 'managerQuestion') for type casting.
 *
 * @returns {Promise<ApiGetDocResponse<ManagerQuestionProps | EvaluationProps>>} A promise that resolves to an object containing:
 *   - status: The HTTP status code of the response.
 *   - doc: The retrieved process item, either of type `ManagerQuestionProps` or `EvaluationProps`, or undefined if not found.
 *
 */
export const getProcessItem = async (
  processItemId: string,
  asTypeOf?: ProcessItemType
): Promise<ApiGetDocResponse<ManagerQuestionProps | EvaluationProps>> => {
  const { status, data } = await apiHandler.get<{
    doc: EvaluationProps | ManagerQuestionProps;
  }>(endpoints.getById(processItemId));

  return {
    status,
    doc:
      asTypeOf === 'evaluation'
        ? (data?.doc as EvaluationProps)
        : (data?.doc as ManagerQuestionProps),
  };
};

/**
 * Asynchronously removes a process item from the backend by its ID.
 *
 * This function sends a DELETE request to the specified endpoint to delete the process item.
 *
 * @param {string} id - The ID of the process item to be removed.
 *
 * @returns {Promise<{
 *   status: number;
 *   docs?: (EvaluationProps | ManagerQuestionProps)[];
 * }>} A promise that resolves to an object containing:
 *   - status: The HTTP status code of the response.
 *   - docs: An array of the remaining process item objects after the deletion, or undefined if not available.
 *
 */
export const removeProcessItem = async (
  id: string
): Promise<{
  status: number;
  docs?: (EvaluationProps | ManagerQuestionProps)[];
}> => {
  const endpoint = endpoints.removeById(id);

  const { status, data: response } = await apiHandler.delete<{
    docs: (EvaluationProps | ManagerQuestionProps)[];
  }>(endpoint);

  return {
    status: status,
    docs: response && response.docs,
  };
};

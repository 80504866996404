import * as React from 'react';
import { ReactSortable } from 'react-sortablejs';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';

// Custom Feature Components
import MenuItem from 'features/menu/menus/components/details/MenuItem';

// Context
import { useMenuItemContext } from 'core/store/context/feature/menu/MenuItem';
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';
import {
  setMenuActionMode,
  updateSortNumbers,
} from 'core/store/slices/feature/menu';

// Custom Utilities
import { deepClone, isSame } from 'core/utilities/helper/helperPack';

// Custom Types
import type { MenuItemProps } from 'features/menu/menus/types';

interface ItemsProps {
  childrens: MenuItemProps[];
}

const Items: React.FC<ItemsProps> = (props) => {
  // Props
  const { childrens } = props;

  // States
  const isSorting = useBoolean();

  // Context
  const menu = useMenuItemContext();
  const loading = useSelectLoading();

  // Utilities
  const handleSortStart = () => {
    isSorting.setTrue();
    setMenuActionMode('SORT');
  };
  const handleSort = (sorted: MenuItemProps[]) => {
    if (!isSorting.state) return;
    const newSorted: MenuItemProps[] = sorted.map((item, index) => ({
      id: item.id,
      data: { ...item.data, sortNumber: index },
    }));

    if (!isSame(childrens, newSorted)) {
      updateSortNumbers(newSorted);
    }

    setMenuActionMode('NORMAL');
    isSorting.setFalse();
  };

  // Render
  return (
    <ReactSortable
      animation={150}
      disabled={loading}
      direction='vertical'
      onStart={handleSortStart}
      handle={`.MenuItemSortHandle-${menu.id}`}
      list={deepClone(childrens)}
      setList={handleSort}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '.5rem',
        margin: '.5rem 1rem 0 0',
      }}
    >
      {childrens.map((menuItem) => (
        <MenuItem
          key={`parent-${menu.id}-child-${menuItem.id}`}
          menu={menuItem}
          handleClassName={`MenuItemSortHandle-${menu.id}`}
        />
      ))}
    </ReactSortable>
  );
};

export default React.memo(Items, isSame);

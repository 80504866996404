import * as React from 'react';
import { alpha } from '@mui/material';

// Custom Hooks
import useTheme from '@mui/material/styles/useTheme';

// Custom Core Components
import Table from 'core/components/base/display/Table';
import TableRow from 'core/components/base/display/TableRow';
import TableBody from 'core/components/base/display/TableBody';
import TableCell from 'core/components/base/display/TableCell';
import TableHead from 'core/components/base/display/TableHead';
import TableContainer from 'core/components/base/display/TableContainer';

// Custom Sectino Components
import DataGridItem from 'features/form/forms/components/list/DataGridItem';

// Custom Common Components
import TableContainerPaper from 'core/components/shared/Paper/TableContainerPaper';

// Custom Utilities
import { tableScrollbarStyles } from 'core/utilities/styles/customStyles';

// Context
import { useDocsContext } from 'core/hooks/api/useDocs/Context';

// Custom Types
import type { FormListItemProps } from 'features/form/forms/types/list';

interface FormsDataGridProps {}

const FormsDataGrid: React.FC<FormsDataGridProps> = (props) => {
  // Context
  const {
    data: { list = [] },
    isFetching,
  } = useDocsContext<FormListItemProps>();

  // Hooks
  const { palette } = useTheme();

  // Render
  return (
    <TableContainer
      sx={{ paddingBottom: '1rem', ...tableScrollbarStyles }}
      component={TableContainerPaper}
    >
      <Table>
        <TableHead sx={{ background: alpha('#29B6F6', 0.3) }}>
          <TableRow sx={{ borderBottom: `1px solid ${palette.divider}` }}>
            <TableCell
              sx={{ width: '7rem', whiteSpace: 'nowrap', textAlign: 'center' }}
            >
              عنوان
            </TableCell>
            <TableCell
              sx={{ width: '7rem', whiteSpace: 'nowrap', textAlign: 'center' }}
            >
              بازخوردها
            </TableCell>
            <TableCell
              sx={{ width: '12rem', whiteSpace: 'nowrap', textAlign: 'center' }}
            >
              آدرس
            </TableCell>
            <TableCell
              sx={{ width: '12rem', whiteSpace: 'nowrap', textAlign: 'center' }}
            >
              گروه مخاطبان
            </TableCell>
            <TableCell
              sx={{ width: '12rem', whiteSpace: 'nowrap', textAlign: 'center' }}
            >
              اسکین
            </TableCell>
            <TableCell
              sx={{ width: '12rem', whiteSpace: 'nowrap', textAlign: 'center' }}
            >
              فرایند
            </TableCell>
            <TableCell
              sx={{ width: '7rem', whiteSpace: 'nowrap', textAlign: 'center' }}
            >
              سازنده
            </TableCell>
            <TableCell
              sx={{ width: '12rem', whiteSpace: 'nowrap', textAlign: 'center' }}
            >
              تاریخ ساخت
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isFetching && list.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4}>در حال دریافت اطلاعات فرم‌ها.</TableCell>
            </TableRow>
          ) : list.length > 0 ? (
            list.map((form) => <DataGridItem form={form} key={form.id} />)
          ) : (
            <TableRow>
              <TableCell colSpan={4}>فرمی جهت نمایش وجود ندارد.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FormsDataGrid;

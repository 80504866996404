import * as React from 'react';

// Custom Core Components
import ToggleButton from 'core/components/base/inputs/ToggleButton';
import ToggleButtonGroup from 'core/components/base/inputs/ToggleButtonGroup';

// Custom Icons
import ConfigIcon from 'core/components/icons/Config';
import VisibilityIcon from 'core/components/icons/Visibility';

// Custom Utilities
import {
  setFeedbackToggleMode,
  useSelectFeedbackToggleMode,
} from 'features/form/feedbacks/store';

// Custom Types
interface FilterModeButtonGroupProps {}

const FilterModeButtonGroup: React.FC<FilterModeButtonGroupProps> = (props) => {
  // Context
  const toggleMode = useSelectFeedbackToggleMode();

  // Render
  return (
    <ToggleButtonGroup
      value={toggleMode}
      exclusive
      onChange={(e: any, val) => setFeedbackToggleMode(e.target.value || val)}
      sx={{
        height: '2.5rem',
        background: '#d9d9d9',
        borderRadius: '1rem',
      }}
    >
      <ToggleButton
        sx={{
          border: 'none !important',
          '&.Mui-selected': {
            borderRadius: '1rem',
          },
        }}
        value='width'
      >
        <ConfigIcon />
      </ToggleButton>
      <ToggleButton
        sx={{
          border: 'none !important',
          '&.Mui-selected': {
            borderRadius: '1rem',
          },
        }}
        value='visibility'
      >
        <VisibilityIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default FilterModeButtonGroup;

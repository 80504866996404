/**
 * Checks if the status code indicates a successful response.
 * @param {number} status - The status code to check.
 * @returns {boolean} Returns true if the status code is between 200 and 210 (inclusive), indicating success. Otherwise, returns false.
 */
export const isSucceed = (status: number): boolean =>
  status >= 200 && status <= 210;

export const isRedirected = (status: number): boolean =>
  status >= 300 && 300 && status <= 399;

/**
 * Returns the status code from the error response, or a default value of 502 if no response is available.
 *
 * @param {any} err - The error object.
 * @returns {number} The status code.
 */
export const getErrorStatusCode = (err: any): number => {
  const error = err as any;

  return error?.response ? error?.response?.status : 502;
};

import * as React from 'react';

// Custom Commn Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface TextFilledIconProps extends SvgIconProps {}

const TextFilledIcon: React.FC<TextFilledIconProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <g>
        <path
          d='M6.5,7.8c-0.4-0.1-0.7-0.4-1-0.7h-3c-0.4,0-0.8,0.2-1.1,0.5C1.2,7.9,1,8.3,1,8.7v6.6c0,0.4,0.2,0.8,0.5,1.1
		c0.3,0.3,0.7,0.5,1.1,0.5h3c0.3-0.3,0.6-0.5,1-0.7V7.8z'
        />
        <path
          d='M21.4,7.1h-9.3c-0.3,0.3-0.6,0.5-1,0.7v8.4c0.4,0.1,0.7,0.4,1,0.7h9.3c0.4,0,0.8-0.2,1.1-0.5c0.3-0.3,0.5-0.7,0.5-1.1V8.7
		c0-0.4-0.2-0.8-0.5-1.1C22.2,7.3,21.9,7.1,21.4,7.1L21.4,7.1z'
        />
        <path
          d='M10.4,17.7H9.6V6.3h0.8c0.3,0,0.5-0.2,0.7-0.4c0.1-0.3,0.1-0.6,0-0.8c-0.1-0.2-0.4-0.4-0.7-0.4H7.3C7,4.7,6.7,4.8,6.6,5.1
		c-0.1,0.3-0.1,0.6,0,0.8C6.7,6.1,7,6.3,7.3,6.3h0.8v11.4H7.3c-0.3,0-0.5,0.2-0.7,0.4c-0.1,0.3-0.1,0.6,0,0.8
		c0.1,0.2,0.4,0.4,0.7,0.4h3.1c0.3,0,0.5-0.2,0.7-0.4c0.1-0.3,0.1-0.6,0-0.8C11,17.9,10.7,17.7,10.4,17.7z'
        />
      </g>
    </IconBuilder>
  );
};

export default TextFilledIcon;

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const removeAllCookies = () => {
  const cookieList = cookies.getAll();
  Object.keys(cookieList).forEach((cookieName) => {
    cookies.remove(cookieName);
  });
};

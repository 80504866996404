'use client';
import React, { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';

// Custom Hooks
import useArray from 'core/hooks/useArray';

// Feature Components
import FileUploadDialog from 'features/file/files/components/shared/Dialog';

// Custom Types
import type { FileProps } from 'features/file/files/types';
import type { FileUploadDialogProps } from 'features/file/files/components/shared/Dialog';

const UploadDialogContext = createContext<{
  settings: Partial<FileUploadDialogProps>;
  setSettings: React.Dispatch<
    React.SetStateAction<Partial<FileUploadDialogProps>>
  >;
}>({ settings: {}, setSettings: () => {} });

interface UploadDialogContextProps {
  children: React.ReactNode;
}

const UploadDialogProvider: React.FC<UploadDialogContextProps> = (props) => {
  // States
  const uploadedFiles = useArray<FileProps>();
  const [settings, setSettings] = React.useState<
    Partial<FileUploadDialogProps>
  >({
    open: false,
  });

  // Hooks
  const location = useLocation();

  React.useEffect(() => {
    uploadedFiles.clear();
    handleClose();
  }, [location.pathname]);

  // Utilities
  const handleClose = () => {
    setSettings({
      open: false,
    });
  };

  const MemoizedChildren = React.useMemo(
    () => props.children,
    [props.children]
  );

  // Render
  return (
    <UploadDialogContext.Provider value={{ settings, setSettings }}>
      {MemoizedChildren}
      {settings.open && (
        <FileUploadDialog
          {...settings}
          open={settings.open}
          onClose={handleClose}
          onFileUploaded={(file) => uploadedFiles.push(file)}
          uploadedFiles={uploadedFiles.state}
        />
      )}
    </UploadDialogContext.Provider>
  );
};

export const useUploadDialogContext = () => useContext(UploadDialogContext);

export default UploadDialogProvider;

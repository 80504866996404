export type AudienceFeatureType = 'telegramBot' | 'baleBot';

export enum AudienceFeatureTypeLabel {
  telegramBot = 'بات تلگرام',
  baleBot = 'بات بله',
}

export interface AudienceFeatureDataProps {
  type: AudienceFeatureType;
  label: AudienceFeatureTypeLabel;
}

export interface AudienceFeatureProps {
  id: string;
  data: AudienceFeatureDataProps;
}

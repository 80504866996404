import { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { ReactSortable } from 'react-sortablejs';
import cloneDeep from 'lodash/cloneDeep';

// Types
import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import ColorPicker from 'core/components/shared/Picker/Color';
import BodyOne from 'core/components/shared/Typography/BodyOne';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import AccessAutocomplete from 'core/components/shared/Autocomplete/Access';
import CustomPagePattern from 'core/components/shared/Card/CustomPagePattern';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Paper from 'core/components/base/surfaces/Paper';
import CircularProgress from 'core/components/base/feedback/CircularProgress';
import IconButton from 'core/components/base/inputs/IconButton';
import LoadingButton from 'core/components/base/inputs/LoadingButton';
import Button from 'core/components/base/inputs/Button';
import TextField from 'core/components/base/inputs/TextField';

// Custom Icon Components
import DeleteIcon from 'core/components/icons/DeleteIcon';
import DuplicateIcon from 'core/components/icons/Duplicate';
import PlusIcon from 'core/components/icons/Plus';
import LtrDirIcon from 'core/components/icons/LtrDirection';
import RtlDirIcon from 'core/components/icons/RtlDirection';
import PlusCircleOutlineIcon from 'core/components/icons/PlusCircleOutline';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import { getNumberTitle } from 'core/utilities/helper/helperPack';
import { setAppAlert } from 'core/utilities/helper';
import {
  useAddCustomPageBodyMutation,
  useDeleteCustomPageBodyMutation,
  useDuplicateCustomPageBodyMutation,
  useUpdateCustomPageBodyMutation,
} from 'features/appBuilder/customPages/components/hooks/body';

// Custom Types
import type { PatternInBodyProps } from 'features/appBuilder/patterns/types/inBody';
import type {
  CustomPageOperation,
  CustomPageProps,
} from 'features/appBuilder/customPages/types';

export interface CustomPageBodyComponentProps {
  operation: CustomPageOperation;
  onOperationChange: (operation: CustomPageOperation) => void;
}

const CustomPageBody: FC<CustomPageBodyComponentProps> = (props) => {
  // Props
  const { operation, onOperationChange } = props;

  // States
  const [activeBody, setActiveBody] = useState<number>(-1);
  const [titleMode, setTitleMode] = useState<'edit' | 'preview'>('preview');
  const [patterns, setPatterns] = useState<PatternInBodyProps[]>([]);

  // Hooks
  const ref = useRef<HTMLInputElement>(null);
  const loading = useSelectLoading();
  const { control, handleSubmit, getValues, setValue } =
    useFormContext<CustomPageProps>();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'data.bodies',
    keyName: 'fieldId',
  });

  useEffect(() => {
    if (titleMode === 'edit' && ref.current) {
      ref.current.focus();
    }
  }, [titleMode]);

  useEffect(() => {
    if (activeBody > -1) {
      const patternsToSet = getValues(
        `data.bodies.${activeBody}.data.patterns`
      );

      const sortedPatterns = patternsToSet.sort((a, b) =>
        a.data.sortNumber > b.data.sortNumber ? 1 : -1
      );

      setPatterns(cloneDeep(sortedPatterns));
    }
  }, [activeBody]);

  useEffect(() => {
    handleSort(patterns);
  }, [patterns]);

  // Utilities
  const { mutateAsync: addCustomPageBody } = useAddCustomPageBodyMutation();
  const { mutateAsync: duplicateCustomPageBody } =
    useDuplicateCustomPageBodyMutation();
  const { mutateAsync: updateCustomPageBody } =
    useUpdateCustomPageBodyMutation();
  const { mutateAsync: deleteCustomPageBody } =
    useDeleteCustomPageBodyMutation();

  const handleBodyButtonClick = (index: number) => () => {
    if (activeBody !== index) {
      if (
        activeBody !== -1 &&
        fields.length > activeBody &&
        fields[activeBody].id === 'draft'
      ) {
        setAppAlert(
          `ابتدا بادی ساخته شده (${fields[activeBody].data.title}) را ذخیره یا حذف کنید.`,
          'warning'
        );
      } else {
        setActiveBody(index);
      }
    } else {
      setTitleMode('edit');
    }
  };

  const handleAddBody = () => {
    const index = getValues('data.bodies').length;
    onOperationChange('INIT_BODY');

    if (
      activeBody !== -1 &&
      fields.length > activeBody &&
      fields[activeBody].id === 'draft'
    ) {
      setAppAlert(
        `ابتدا بادی ساخته شده (${fields[activeBody].data.title}) را ذخیره یا حذف کنید.`,
        'warning'
      );

      return;
    }

    append({
      id: 'draft',
      data: {
        accessId: '',
        accessTitle: '',
        bgColor: 'rgba(0,0,0,1)',
        bodyType: 'page',
        direction: 'rtl',
        isPrivate: true,
        pageId: getValues('id'),
        pageTitle: getValues('data.title'),
        patterns: [],
        title: `بادی ${getNumberTitle(index + 1, 'fa')}`,
      },
    });

    setActiveBody(index);
  };

  const toggleBodyDir = (index: number, dir: 'rtl' | 'ltr') => () => {
    setValue(
      `data.bodies.${index}.data.direction`,
      dir === 'ltr' ? 'rtl' : 'ltr'
    );
  };

  const handleAddPattern = () => {
    if (activeBody > -1 && getValues('data.bodies').length > activeBody) {
      onOperationChange('BODY_PATTERN');
      const bodyObj = getValues(`data.bodies.${activeBody}`);

      bodyObj.data.patterns.push({
        id: 'draft',
        data: {
          maxWidth: 'none',
          direction:
            getValues(`data.bodies.${activeBody}.data.direction`) || 'rtl',
          patternTitle: '',
          sortNumber: bodyObj.data.patterns.length,
          type: 'header',
        },
      });

      update(activeBody, bodyObj);
      setPatterns(bodyObj.data.patterns);
    }
  };

  const handlePatternDelete = (index: number) => () => {
    if (activeBody > -1 && getValues('data.bodies').length > activeBody) {
      const bodyObj = getValues(`data.bodies.${activeBody}`);

      bodyObj.data.patterns.splice(index, 1);

      update(activeBody, bodyObj);
      setPatterns(bodyObj.data.patterns);
      onOperationChange('INIT');
    }
  };

  const handlePatternUpdate = (
    index: number,
    newPattern: PatternInBodyProps
  ) => {
    if (activeBody > -1 && getValues('data.bodies').length > activeBody) {
      const bodyObj = getValues(`data.bodies.${activeBody}`);

      bodyObj.data.patterns.splice(index, 1, newPattern);

      update(activeBody, bodyObj);
      setPatterns(bodyObj.data.patterns);
      onOperationChange('INIT');
    }
  };

  const handleSort = (newPatterns: PatternInBodyProps[]) => {
    if (activeBody > -1) {
      const clonedPatterns = cloneDeep(
        getValues(`data.bodies.${activeBody}.data.patterns`)
      );

      clonedPatterns.forEach((p) => {
        const index = newPatterns.findIndex((pattern) => pattern.id === p.id);

        if (index > -1) {
          p.data.sortNumber = index;
        }
      });

      clonedPatterns.sort((a, b) =>
        a.data.sortNumber > b.data.sortNumber ? 1 : -1
      );
      setValue(`data.bodies.${activeBody}.data.patterns`, clonedPatterns);
    }
  };

  const handleCancel = (index: number) => {
    remove(index);
    onOperationChange('INIT');
  };

  const handleBodyDelete = async () => {
    if (activeBody > -1 && getValues('data.bodies').length > activeBody) {
      onOperationChange('DEL_BODY');
      const bodyId = getValues(`data.bodies.${activeBody}`).id;

      await deleteCustomPageBody(bodyId);

      setActiveBody(activeBody - 1);
    }
    onOperationChange('INIT');
  };

  const handleBodyDuplicate = async () => {
    if (activeBody > -1 && getValues('data.bodies').length > activeBody) {
      onOperationChange('DUP_BODY');
      const bodyId = getValues(`data.bodies.${activeBody}`).id;
      await duplicateCustomPageBody(bodyId);
    }
    onOperationChange('INIT');
  };

  const handleBodySubmit: SubmitHandler<CustomPageProps> = async (page) => {
    if (activeBody > -1 && getValues('data.bodies').length > activeBody) {
      onOperationChange('INIT_BODY');

      const isNew = page.data.bodies[activeBody].id === 'draft';

      if (isNew) {
        await addCustomPageBody(page.data.bodies[activeBody].data);
      } else {
        await updateCustomPageBody(page.data.bodies[activeBody]);
      }

      onOperationChange('INIT');
    }
  };

  return (
    <ColumnStack>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          background: 'rgba(193, 200, 211, 0.3)',
          p: '1.5rem 1rem',
          borderRadius: '0.5rem',
        }}
      >
        <BodyOne color='text.secondary' mr='1rem'>
          بادی
        </BodyOne>
        <Controller
          defaultValue={[]}
          control={control}
          name='data.bodies'
          render={({ field }) => (
            <>
              {field.value.map((b, index) => (
                <Box
                  key={b.id}
                  onClick={handleBodyButtonClick(index)}
                  sx={{
                    backgroundColor: 'background.paper',
                    cursor: activeBody === index ? 'text' : 'pointer',
                    p: '0.5rem 1rem',
                    borderRadius: '0.25rem',
                    opacity: index === activeBody ? '1' : '0.8',
                    color:
                      index === activeBody ? 'text.primary' : 'text.secondary',
                    ':hover': { opacity: '1', color: 'text.primary' },
                  }}
                >
                  {index === activeBody && titleMode === 'edit' ? (
                    <Controller
                      control={control}
                      name={`data.bodies.${index}.data.title`}
                      rules={{
                        onBlur: () => setTitleMode('preview'),
                      }}
                      render={({ field }) => (
                        <TextField
                          inputRef={ref}
                          placeholder='عنوان بادی'
                          variant='standard'
                          sx={{ height: '1.5rem', width: '6rem' }}
                          InputProps={{
                            sx: { height: '1.5rem', width: '6rem' },
                          }}
                          {...field}
                        />
                      )}
                    />
                  ) : (
                    <BodyOne>
                      {getValues(`data.bodies.${index}.data.title`)}
                    </BodyOne>
                  )}
                </Box>
              ))}
            </>
          )}
        />
        <Box
          sx={{
            backgroundColor: 'background.paper',
            opacity: '0.8',
            borderRadius: '0.25rem',
            color: 'text.secondary',
            cursor: loading ? 'default' : 'pointer',
            height: '40px',
            width: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ':hover': { opacity: '1', color: 'text.primary' },
          }}
          onClick={handleAddBody}
        >
          <PlusIcon fontSize='small' />
        </Box>
      </Box>
      {activeBody > -1 &&
        getValues(`data.bodies`).length > activeBody &&
        getValues(`data.bodies.${activeBody}`) && (
          <ColumnStack>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <RowStack spacing={3} alignItems='center'>
                <AccessAutocomplete
                  showPublic
                  defaultValue={
                    getValues(`data.bodies.${activeBody}.data.isPrivate`) ===
                    true
                      ? getValues(`data.bodies.${activeBody}.data.accessId`)
                      : 'viewer'
                  }
                  placeholder='بدون اکسس'
                  onChange={(accessId) => {
                    setValue(
                      `data.bodies.${activeBody}.data.accessId`,
                      accessId === 'viewer' ? '' : accessId
                    );
                    setValue(
                      `data.bodies.${activeBody}.data.isPrivate`,
                      accessId === 'viewer' ? false : true
                    );
                  }}
                  sx={{ width: '10rem' }}
                />

                <Controller
                  control={control}
                  name={`data.bodies.${activeBody}.data.direction`}
                  render={({ field }) => (
                    <Paper
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '3rem',
                        height: '3rem',
                        borderRadius: '0.5rem',
                        cursor: 'pointer',
                        boxShadow: 'none',
                      }}
                      onClick={toggleBodyDir(activeBody, field.value)}
                    >
                      {getValues(`data.bodies.${activeBody}.data.direction`) ===
                      'rtl' ? (
                        <RtlDirIcon />
                      ) : (
                        <LtrDirIcon />
                      )}
                    </Paper>
                  )}
                />
                <Controller
                  control={control}
                  defaultValue='#fff'
                  name={`data.bodies.${activeBody}.data.bgColor`}
                  render={({ field }) => {
                    const color = getValues(field.name);

                    return (
                      <ColorPicker
                        sx={{
                          position: 'relative',
                          top: '2px',
                        }}
                        color={color}
                        disabled={loading}
                        onColorChange={(color) => setValue(field.name, color)}
                      />
                    );
                  }}
                />
              </RowStack>
              <RowStack spacing={1}>
                {getValues(`data.bodies.${activeBody}.id`) === 'draft' ? (
                  <Button
                    onClick={() => handleCancel(activeBody)}
                    color='error'
                    variant='outlined'
                  >
                    انصراف
                  </Button>
                ) : (
                  <>
                    <IconButton
                      disabled={loading}
                      color='error'
                      onClick={handleBodyDelete}
                      sx={{
                        borderRadius: '0.25rem',
                        border: '1px solid',
                        borderColor: loading ? 'text.disabled' : 'error.main',
                      }}
                    >
                      {operation === 'DEL_BODY' && loading ? (
                        <CircularProgress color='error' size={22} />
                      ) : (
                        <DeleteIcon />
                      )}
                    </IconButton>
                    <LoadingButton
                      disabled={loading}
                      loading={operation === 'UP_BODY' && loading}
                      loadingPosition='start'
                      variant='outlined'
                      color='inherit'
                      onClick={handleBodyDuplicate}
                      startIcon={<DuplicateIcon />}
                    >
                      کپی
                    </LoadingButton>
                  </>
                )}
                <LoadingButton
                  disabled={loading || operation === 'BODY_PATTERN'}
                  loading={operation === 'UP_BODY' && loading}
                  loadingPosition='start'
                  startIcon={<></>}
                  variant='outlined'
                  onClick={handleSubmit(handleBodySubmit)}
                  sx={{ px: '2.5rem' }}
                >
                  ذخیره
                </LoadingButton>
              </RowStack>
            </Box>
            <ColumnStack>
              {patterns.length > 0 && (
                <ReactSortable
                  list={patterns}
                  disabled={loading}
                  setList={setPatterns}
                  animation={150}
                  direction='vertical'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                  }}
                >
                  {patterns.map((pattern, index) => (
                    <CustomPagePattern
                      onDelete={handlePatternDelete(index)}
                      key={pattern.id + index}
                      pattern={pattern}
                      onUpdate={(p) => handlePatternUpdate(index, p)}
                    />
                  ))}
                </ReactSortable>
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  disabled={loading || operation === 'BODY_PATTERN'}
                  color='info'
                  variant='outlined'
                  onClick={handleAddPattern}
                  startIcon={<PlusCircleOutlineIcon />}
                >
                  افزودن پترن
                </Button>
              </Box>
            </ColumnStack>
          </ColumnStack>
        )}
    </ColumnStack>
  );
};

export default CustomPageBody;

// Custom Hooks
import useDocsApi from 'core/hooks/api/useDocs';
import useDocApi from 'core/hooks/api/useDoc';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  addProcessItem,
  editProcessItem,
  removeProcessItem,
} from 'features/form/processes/utilities/api/processItem';
import {
  addProcess,
  editProcess,
  getProcess,
  getProcesses,
  removeProcesses,
  toggleProcessIsActive,
} from 'features/form/processes/utilities/api';

// Custom Types
import type { EvaluationProps } from 'features/form/processes/types/details/evaluation';
import type { ManagerQuestionProps } from 'features/form/processes/types/details/managerQuestion';
import type { ProcessSummaryProps } from 'features/form/processes/types/list';
import type {
  ProcessDataProps,
  ProcessProps,
} from 'features/form/processes/types/details';
import type {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';

/**
 * Query key for fetching processes in react query.
 *
 * @constant {Array<string>}
 */
export const processesQueryKey = ['processes'];

/**
 * Custom hook to add a process item.
 *
 * This hook utilizes the `useAddMutation` to handle the addition of a process item.
 *
 * @returns {UseMutationResult<{ status: number; docs: (EvaluationProps | ManagerQuestionProps)[] }, unknown, { processId: string; processItem: ManagerQuestionProps | EvaluationProps }>} The mutation result that includes the status and the list of documents after the addition.
 */
export const useAddProcessItemMutation = useAddMutation<
  {
    processId: string;
    processItem: ManagerQuestionProps | EvaluationProps;
  },
  { status: number; docs: (EvaluationProps | ManagerQuestionProps)[] }
>(addProcessItem, processesQueryKey);

/**
 * Custom hook to edit a process item.
 *
 * This hook utilizes the `useEditMutation` to handle the editing of a process item.
 *
 * @returns {UseMutationResult<{ status: number; docs: (EvaluationProps | ManagerQuestionProps)[] }, unknown, ManagerQuestionProps | EvaluationProps>} The mutation result that includes the status and the updated list of documents after the edit.
 */
export const useEditProcessItemMutation = useEditMutation<
  ManagerQuestionProps | EvaluationProps,
  {
    status: number;
    docs: (EvaluationProps | ManagerQuestionProps)[];
  }
>(editProcessItem, processesQueryKey);

/**
 * Custom hook to delete a process item.
 *
 * This hook utilizes the `useDeleteMutation` to handle the deletion of a process item.
 *
 * @returns {UseMutationResult<{ status: number; docs?: (EvaluationProps | ManagerQuestionProps)[] }, unknown, string>} The mutation result that includes the status and the list of documents after the deletion.
 */
export const useDeleteProcessItemMutation = useDeleteMutation<
  string,
  {
    status: number;
    docs?: (EvaluationProps | ManagerQuestionProps)[];
  }
>(removeProcessItem, processesQueryKey);

/**
 * Custom hook to fetch processes.
 *
 * This hook utilizes the `useDocsApi` to retrieve processes list on the provided options.
 *
 * @param {UseDocsOptionType<ProcessSummaryProps>} [options] - Optional configuration options for fetching the processes.
 * @returns {UseDocsApiReturnProps<ProcessSummaryProps>} The result of the `useDocsApi` hook, which includes the fetched processes
 */
export const useProcessDocs = (
  options?: UseDocsOptionType<ProcessSummaryProps>
): UseDocsApiReturnProps<ProcessSummaryProps> =>
  useDocsApi<ProcessSummaryProps>(getProcesses, processesQueryKey, options);

/**
 * Custom hook to fetch a process document.
 *
 * This hook utilizes the `useDocApi` to retrieve a specific process based on the provided options.
 *
 * @param {UseDocOptionType<ProcessProps>} [options] - Optional configuration options for fetching the process document.
 * @returns {UseDocApiReturnProps<ProcessProps>} The result of the `useDocApi` hook, which includes the fetched process document, loading state, and any errors.
 */
export const useProcessDoc = (
  options?: UseDocOptionType<ProcessProps>
): UseDocApiReturnProps<ProcessProps> =>
  useDocApi<ProcessProps>(getProcess, processesQueryKey, options);

/**
 * Custom hook to add a new process.
 *
 * This hook utilizes the `useAddMutation` to handle the addition of a new process.
 *
 * @returns {UseMutationResult<{ status: number; processId?: string }, unknown, ProcessDataProps>} The mutation result that includes the status of the operation and the ID of the newly created process.
 */
export const useAddProcessMutation = useAddMutation<
  ProcessDataProps,
  { status: number; processId?: string }
>(addProcess, processesQueryKey);

/**
 * Custom hook to edit an existing process.
 *
 * This hook utilizes the `useEditMutation` to handle the editing of an existing process.
 *
 * @returns {UseMutationResult<{ status: number; processId?: string }, unknown, ProcessDataProps>} The mutation result that includes the status of the operation and the ID of the edited process.
 */
export const useEditProcessMutation = useEditMutation<
  ProcessDataProps,
  { status: number; processId?: string }
>(editProcess, processesQueryKey);

/**
 * Custom hook to change process active status.
 *
 * This hook utilizes the `useEditMutation` to handle the editing of an existing process.
 *
 * @returns {UseMutationResult<{ status: number; checked?: boolean }, unknown, ProcessDataProps>} The mutation result that includes the new active status and the response status.
 */
export const useToggleProcessActiveStatusMutation = useEditMutation<
  boolean,
  { status: number; checked?: boolean }
>(toggleProcessIsActive, processesQueryKey);

/**
 * Custom hook to delete multiple processes.
 *
 * This hook utilizes the `useDeleteMutation` to handle the deletion of processes by their IDs.
 *
 * @returns {UseMutationResult<number, unknown, string[]>} The mutation result that includes the status of the operation for the deleted processes.
 */
export const useDeleteProcessesMutation = useDeleteMutation<string[]>(
  removeProcesses,
  processesQueryKey
);

import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import RMDatePicker, { DateObject } from 'react-multi-date-picker';
import RMTimePicker from 'react-multi-date-picker/plugins/time_picker';
import persian from 'react-date-object/calendars/persian';
import persian_fa from 'react-date-object/locales/persian_fa';

// Core Components
import Box from 'core/components/base/layout/Box';
import TextField from 'core/components/base/inputs/TextField';

// Custom Common Components
import CloseIconButton from '../IconButton/Close';

// Time Picker
import ClockIcon from 'core/components/icons/Clock';

// Types
import type { BoxProps } from 'core/components/base/layout/Box';

interface TimePickerProps extends BoxProps {
  disabled?: boolean;
  disableIcon?: boolean;
  fullWidth?: boolean;
  readonly?: boolean;
  value?: Date;
  onTimeChange?: (date: Date | null) => void;
  onClearValue?: () => void;
}

const TimePicker: React.FC<TimePickerProps> = (props) => {
  const {
    disabled,
    disableIcon,
    fullWidth,
    readonly: readOnly,
    value,
    sx,
    onTimeChange,
    onClearValue,
    ...otherProps
  } = props;

  let height: string | number =
    sx &&
    'height' in sx &&
    (typeof sx.height === 'number' || typeof sx.height === 'string')
      ? sx.height
      : '3rem';

  // States
  const dateRef = React.useRef(null);
  const [pickerValue, setPickerValue] = React.useState<Date | null>(null);

  // Hooks
  const { palette } = useTheme();

  React.useEffect(() => {
    setPickerValue(value ?? null);
  }, [value]);

  // Utilities
  const handleChange = (dates: DateObject | DateObject[] | null) => {
    if (dates && !Array.isArray(dates)) {
      setPickerValue(dates.toDate());
      if (onTimeChange) onTimeChange(dates.toDate());
    }
  };

  const handleOpen = () => {
    if (dateRef.current && !readOnly && !disabled)
      (dateRef.current as any).openCalendar();
  };

  const handleClear = () => {
    setPickerValue(null);
    if (onClearValue) onClearValue();
    if (onTimeChange) onTimeChange(null);
  };

  // Render
  return (
    <Box
      sx={{
        height,
        width: fullWidth ? '100%' : 'fit-content',
        ...sx,
        position: 'relative',
        '& .date-picker-container': {
          height: '100% !important',
          width: '100%',
          '& .rmdp-input': {
            fontFamily: 'Vazir',
            backgroundColor:
              palette.mode === 'light'
                ? palette.background.paper
                : palette.background.default,
            color: palette.text.primary,
            borderColor: 'rgba(0, 0, 0, 0.23)',
            height: '100%',
            textAlign: 'center',
            width: '100%',
            ':hover': {
              borderColor: palette.text.secondary,
            },
            ':disabled': {
              backgroundColor: 'transparent',
              color: palette.text.disabled,
            },
          },
        },
      }}
      {...otherProps}
    >
      <RMDatePicker
        ref={dateRef}
        calendar={persian}
        containerClassName='date-picker-container'
        disabled={disabled}
        // onOpenPickNewDate={false}
        disableDayPicker
        format='HH:mm:ss'
        locale={persian_fa}
        onChange={handleChange}
        plugins={[
          <RMTimePicker position='bottom' style={{ fontFamily: 'Vazir' }} />,
        ]}
        readOnly={readOnly}
        value={pickerValue}
        render={(value = '') => (
          <TextField
            value={value}
            disabled={disabled}
            onFocus={handleOpen}
            onClick={handleOpen}
            onTouchEnd={handleOpen}
            inputProps={{
              style: {
                textAlign: pickerValue ? 'center' : 'start',
              },
            }}
            InputProps={{
              sx: { height },
              readOnly,
            }}
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      {pickerValue ? (
        <CloseIconButton
          size='small'
          onClick={handleClear}
          sx={{
            color: disabled ? palette.text.disabled : palette.text.secondary,
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translate(-30%, -50%)',
          }}
        />
      ) : (
        <>
          {!disableIcon && (
            <Box
              onClick={handleOpen}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                background: '#C1C8D3',
                p: 1.2,
                color: disabled ? palette.text.disabled : palette.text.primary,
                position: 'absolute',
                top: 0,
                right: 0,
                height: '100%',
                borderRadius: ' 0 4px 4px 0',
              }}
            >
              <ClockIcon />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default TimePicker;

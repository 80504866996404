// Feature Types
import { HeaderPatternBannerProps } from 'features/appBuilder/patterns/features/header/types/banner';

export const headerPatternBannerDefaults: HeaderPatternBannerProps = {
  isActive: true,
  one: {
    containerId: '',
    containerTitle: '',
    imageSize: 32,
    imageType: 'default',
    isActive: true,
    itemCount: 6,
    padding: '0px',
    textAlign: 'end',
    borderRadius: 8,
  },
};

import { forwardRef, useState, type FC } from 'react';

// Core Components
import TextField, {
  TextFieldProps,
} from 'core/components/base/inputs/TextField';

// Custom Types
export type GhostTextFieldProps = TextFieldProps & {};

const GhostTextField: FC<GhostTextFieldProps> = forwardRef((props, ref) => {
  // Props
  const { InputProps, disabled, fullWidth = true, ...otherProps } = props;

  return (
    <TextField
      ref={ref}
      fullWidth={fullWidth}
      disabled={disabled}
      InputProps={{
        disableUnderline: true,
        ...InputProps,
        sx: {
          borderRadius: '0.25rem',
          px: '0.5rem',
          border: '1px solid transparent',
          ':hover': disabled
            ? {}
            : {
                border: '1px solid',
                borderColor: 'info.main',
              },
          ...InputProps?.sx,
        },
      }}
      {...otherProps}
    />
  );
});

export default GhostTextField;

'use client';
import * as React from 'react';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';

// Core Components
import Chip from 'core/components/base/display/Chip';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';
import Prefixer from 'core/components/shared/Prefixer';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';

// Custom Icons
import HelpIcon from 'core/components/icons/HelpIcon';

// Custom Feature Components
import ProcessActions from 'features/form/feedbacks/components/details/Process/shared/Actions';
import ProcessComment from 'features/form/feedbacks/components/details/Process/shared/Comment';

// Context
import { useSelectApplicantFullname } from 'features/form/feedbacks/store';

// Custom Types
import type { ManagerQuestionProps } from 'features/form/processes/types/details/managerQuestion';
import Paper from 'core/components/base/surfaces/Paper';

interface ManagerQuestionComponentProps {
  question: ManagerQuestionProps;
  showDeleteButton: boolean;
  showResetButton: boolean;
}

const ManagerQuestion: React.FC<ManagerQuestionComponentProps> = (props) => {
  // Props
  const {
    question: { id: questionId, data: question },
    showDeleteButton,
    showResetButton,
  } = props;

  // Context
  const applicantFullname = useSelectApplicantFullname();
  const hiddenSections = useAppSelector(
    (state) => state.feedback.hiddenProcesses[questionId]
  );

  // Render
  return (
    <ColumnStack
      component={Paper}
      spacing='1rem'
      sx={{
        boxShadow: 'none',
        background: ({ palette }) =>
          palette.mode === 'dark' ? undefined : '#DFE8EF',
        padding: '1rem',
        borderRadius: '.5rem',
        '@media print': {
          border: '1px solid #ccc',
        },
      }}
    >
      <SpaceBetween alignItems='center' width='100%'>
        {!hiddenSections?.title && question.title ? (
          <Prefixer Prefix={<HelpIcon />}>
            <HeadingSix>{question.title}</HeadingSix>
            {question.overalStatus !== 'completed' && (
              <Chip
                variant='filled'
                color='info'
                label='درحال پاسخ'
                sx={{
                  backgroundColor: '#0091FF33',
                  color: 'info.main',
                  borderRadius: '.5rem',
                  width: 'fit-content',
                  '.MuiChip-label': {
                    paddingX: '.25rem',
                  },
                }}
              />
            )}
          </Prefixer>
        ) : (
          <ColumnStack />
        )}
        {(showDeleteButton || showResetButton) && (
          <ProcessActions
            type='managerQuestion'
            showResetButton={showResetButton}
            showDeleteButton={showDeleteButton}
          />
        )}
      </SpaceBetween>
      <SpaceBetween
        sx={{
          alignItems: { xs: 'start', md: 'center' },
          flexDirection: { xs: 'column', md: 'row' },
          gap: '.5rem',
        }}
      >
        {!hiddenSections?.questionTitle && question.questionTitle && (
          <BodyTwo color={question.completionDate ? 'text' : 'body'}>
            {question.questionTitle}
          </BodyTwo>
        )}
        {(question.applicantAnswer ||
          question.completionDate ||
          applicantFullname ||
          (question.applicantAnswerFiles &&
            question.applicantAnswerFiles.length > 0)) && (
          <ProcessComment
            message={question.applicantAnswer}
            fullName={applicantFullname}
            files={question.applicantAnswerFiles}
            completionDate={question.completionDate}
            sx={{
              background: ({ palette }) =>
                palette.mode === 'dark' ? '#121212' : '#F5F8FA',
            }}
          />
        )}
      </SpaceBetween>
    </ColumnStack>
  );
};

export default ManagerQuestion;

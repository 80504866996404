import * as React from 'react';

// Types
import type { FC } from 'react';

// Custom Feature Components
import SheetTableCell from 'features/data/sheets/components/details/SheetTableCell';

// Custom Core Components
import TableContainer from 'core/components/base/display/TableContainer';
import Table from 'core/components/base/display/Table';
import TableHead from 'core/components/base/display/TableHead';
import TableRow from 'core/components/base/display/TableRow';
import TableBody from 'core/components/base/display/TableBody';
import Paper from 'core/components/base/surfaces/Paper';

// Utilities
import { sheetHeadersHandler } from 'features/data/sheets/utilities/helper';

// Custom Types
import type { SheetCellProps, SheetRowProps } from 'features/data/sheets/types';
interface SheetTableProps {
  tableHeader: string[];
  tableData: SheetRowProps[];
}

const SheetTable: FC<SheetTableProps> = (props) => {
  // Props
  const { tableHeader, tableData } = props;

  // States
  const [selectedCell, setSelectedCell] = React.useState<number | null>(null);

  // Utilities
  const sheetHeaders = sheetHeadersHandler(tableHeader);

  // Render
  return (
    <Paper sx={{ boxShadow: 'none', borderRadius: 0 }}>
      <TableContainer
        className='printable'
        sx={{
          '&::-webkit-scrollbar': {
            width: 20,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#C1C8D3',
            borderRadius: 2,
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <SheetTableCell></SheetTableCell>
              {sheetHeaders.map((head) => (
                <SheetTableCell
                  sx={{
                    color: '#C1C8D3',
                  }}
                >
                  {head}
                </SheetTableCell>
              ))}
            </TableRow>
            <TableRow>
              <SheetTableCell>1</SheetTableCell>
              {tableHeader &&
                tableHeader.map((head) => (
                  <SheetTableCell
                    key={head}
                    sx={{
                      p: 1,
                    }}
                  >
                    {head}
                  </SheetTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => {
              return (
                <TableRow
                  key={`${row.phoneNumber}-${index}`}
                  selected={selectedCell === index}
                >
                  <SheetTableCell
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setSelectedCell(index)}
                  >
                    {index + 2}
                  </SheetTableCell>
                  {Object.values(row).map((cell) => {
                    if (
                      Object.keys(cell)[0] !== 'phoneNumber' &&
                      typeof cell !== 'string'
                    ) {
                      let c = cell as SheetCellProps;
                      return (
                        <SheetTableCell
                          colSpan={cell.colSpan}
                          sx={{
                            p: 1,
                          }}
                        >
                          {c.value}
                        </SheetTableCell>
                      );
                    } else {
                      return <></>;
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default SheetTable;

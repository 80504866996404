import { ThemeOptions } from '@mui/material';

// global theme options, these options are common between light and dark theme
import globalThemeOptions from '../globalThemeOptions';

const lightThemeOptions: ThemeOptions = {
  ...globalThemeOptions,
  palette: {
    mode: 'light',
    primary: {
      main: '#1167b1',
      dark: '#2976b9',
      light: '#0f5d9f',
      contrastText: '#ffffff',
    },
    info: {
      main: '#109cf1',
      dark: '#0e8cd9',
      light: '#28a6f2',
      contrastText: '#ffffff',
    },
    error: {
      main: '#c32429',
      dark: '#b02025',
      light: '#c93a3e',
      contrastText: '#ffffff',
    },
    success: {
      main: '#026c38',
      dark: '#026132',
      light: '#1b7b4c',
      contrastText: '#ffffff',
    },
    divider: '#D8E8F1',
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
    text: {
      secondary: '#807d7b',
    },
  },
};

export default lightThemeOptions;

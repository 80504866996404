import * as React from 'react';
import { SelectChangeEvent } from '@mui/material';

// Custom Core Components
import MuiSelect from 'core/components/base/inputs/Select';

// Custom Common Components
import InputLabel from 'core/components/shared/Typography/InputLabel';

// Custom Icons
import ExpandMoreIcon from 'core/components/icons/ExpandMore';

// Custom Types
import type { SelectProps as MuiSelectProps } from 'core/components/base/inputs/Select';

interface SelectProps extends MuiSelectProps {
  disableCustomIcon?: boolean;
}

const Select: React.FC<SelectProps> = React.forwardRef((props, ref) => {
  const {
    children,
    sx,
    disabled,
    placeholder,
    onChange,
    displayEmpty,
    disableCustomIcon,
    renderValue,
    ...otherProps
  } = props;

  // Utilities
  const handleChange = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => {
    if (onChange) onChange(event, child);
  };

  const options = React.useMemo(
    () =>
      Array.isArray(children) ? children.flatMap((v) => v) : [children || []],
    [children]
  );

  const getValueLabel = (inputValue: string): string => {
    const option = options.find((child) => {
      const childValue = (child.props.value as string) || '';
      return childValue === inputValue;
    });

    const optText = option?.props?.children || '';

    return typeof optText === 'string' ? optText : placeholder || '';
  };

  // Render
  return (
    <MuiSelect
      ref={ref}
      disabled={disabled}
      onChange={handleChange}
      IconComponent={ExpandMoreIcon}
      sx={{
        backgroundColor: 'background.paper',
        borderRadius: '0.5rem',
        boxShadow: 'none',
        ...sx,
      }}
      MenuProps={{
        sx: { marginTop: '2px' },
        PaperProps: {
          sx: {
            boxShadow: 'none',
            borderRadius: '0.5rem',
            border: '1px solid #C1C8D3',
            padding: '0.25rem',
          },
        },
        MenuListProps: { sx: { padding: 0 } },
      }}
      displayEmpty
      renderValue={(value) => {
        const inputValue = (
          [undefined, null].includes(value as any) ? '' : value
        ) as string | string[];
        const isEmpty = inputValue.length === 0;

        if (isEmpty)
          return (
            <InputLabel
              color='text.disabled'
              gutterBottom={false}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {placeholder || ''}
            </InputLabel>
          );
        return (
          <InputLabel
            gutterBottom={false}
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {typeof value === 'string'
              ? getValueLabel(inputValue as string)
              : (value as string[]).map((v) => getValueLabel(v)).join(',')}
          </InputLabel>
        );
      }}
      {...otherProps}
    >
      {children}
    </MuiSelect>
  );
});

export default Select;

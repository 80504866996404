import { type FC } from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Core Components
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface QuoteIconProps extends SvgIconProps {}

const QuoteIcon: FC<QuoteIconProps> = (props) => {
  return (
    <IconBuilder viewBox='0 0 24 18' {...props}>
      <path
        style={{ transform: 'translateY(-12%)' }}
        d='M19.3,5.5h-3.6l-2.4,5.2v7.8h7.3v-7.8h-3.6L19.3,5.5z M9.6,5.5H5.9l-2.4,5.2v7.8h7.3v-7.8H7.1L9.6,5.5z'
      />
    </IconBuilder>
  );
};
export default QuoteIcon;

import * as React from 'react';

// Custom Core Components
import Skeleton from 'core/components/base/feedback/Skeleton';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import Box from 'core/components/base/layout/Box';

// Custom Types
interface FormSkeletonProps {}

const FormSkeleton: React.FC<FormSkeletonProps> = (props) => {
  // Render
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      {['عنوان', 'متن جایگزین'].map((item) => (
        <ColumnStack spacing={0.5} sx={{ width: '100%' }} key={item}>
          <InputLabel indentRight>{item}</InputLabel>
          <Skeleton variant='rounded' sx={{ width: '100%', height: '3rem' }} />
        </ColumnStack>
      ))}
    </Box>
  );
};

export default FormSkeleton;

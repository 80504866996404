import * as React from 'react';

// Core Components
import Button from 'core/components/base/inputs/Button';

// Context
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { ButtonProps } from 'core/components/base/inputs/Button';

interface InsertButtonProps extends ButtonProps {
  title?: string;
}

const InsertButton: React.FC<InsertButtonProps> = (props) => {
  // Props
  const { title, disabled, sx, ...otherProps } = props;

  // Context
  const loading = useSelectLoading();

  // Render
  return (
    <Button
      color='info'
      variant='contained'
      disabled={loading || disabled}
      sx={{
        width: 'fit-content',
        alignSelf: 'end',
        borderRadius: '.5rem',
        ...sx,
      }}
      {...otherProps}
    >
      {title || 'قرار دادن'}
    </Button>
  );
};

export default InsertButton;

// Types
import type { FC } from 'react';

// Custom Hooks
import useDocsApi from 'core/hooks/api/useDocs';

// Core Components
import Box from 'core/components/base/layout/Box';
import LoadingButton from 'core/components/base/inputs/LoadingButton';

// Common Components
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Feature Components
import SkinDataGrid from 'features/appBuilder/skins/components/list/DataGrid';

// Custom Icons
import PlusCircleOutlineIcon from 'core/components/icons/PlusCircleOutline';

// Custom Utitlities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import {
  useAddSkinMutation,
  useDeleteSkinsMutation,
  useEditSkinMutation,
  useTemplateSkinDocs,
} from 'features/appBuilder/skins/hooks';

// Custom Types

interface SkinListPageProps {}

const SkinList: FC<SkinListPageProps> = (props) => {
  // Context
  const loading = useSelectLoading();

  // Hooks
  const skinMethods = useTemplateSkinDocs();
  const { list: skinList } = skinMethods.data;
  const { mutate: handleDelete } = useDeleteSkinsMutation();
  const { mutate: handleUpdate } = useEditSkinMutation();
  const { mutate: handleAdd } = useAddSkinMutation();

  // API Utilities
  return (
    <>
      <PageHeadConfigs pageTitle='لیست اسکین‌ها' />
      <PageHeroBox>
        <PageTitle>لیست اسکین‌ها</PageTitle>
      </PageHeroBox>
      <ColumnStack>
        <SkinDataGrid
          skinList={skinList}
          onDelete={(id) => {
            handleDelete(id);
          }}
          onUpdate={(id, title) => {
            handleUpdate({ id, data: title });
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <LoadingButton
            loading={loading}
            loadingPosition='start'
            startIcon={<PlusCircleOutlineIcon />}
            variant='outlined'
            color='info'
            onClick={() => {
              handleAdd(`اسکین-${skinList.length + 1}`);
            }}
          >
            اسکین جدید
          </LoadingButton>
        </Box>
      </ColumnStack>
    </>
  );
};

export default SkinList;

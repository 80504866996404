import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DefinedUseQueryResult } from '@tanstack/react-query';

// Custom Hooks
import useMount from 'core/hooks/useMount';
import useAppQuery from 'core/hooks/useAppQuery';
import useUpdateEffect from 'core/hooks/useUpdateEffect';

// Custom Utilities
import { isSucceed } from 'core/utilities/helper';
import { getSearchParamsObject } from 'core/utilities/helper/helperPack';
import { initialPageProps } from 'core/utilities/pagination/pagination';

// Custom Types
import type { UseAppQueryOptionsProps } from 'core/hooks/useAppQuery';
import type { PageProps } from 'core/types/shared/pagination';

export interface ParamsProps {
  startDate?: string;
  endDate?: string;
  search?: string;
  [key: string]: any;
}

export interface UseDocsOptionType<T>
  extends Omit<
    UseAppQueryOptionsProps<{ list: T[]; page: PageProps }>,
    'queryFn' | 'initialData' | 'queryKey' | 'onFetch'
  > {
  id?: string;
  onFetch?: (data: T[]) => void;
  onFetchFailed?: () => void;
  defaultPageSize?: number;
  initialData?: T | null;
}

export type UseDocsApiReturnProps<T> = DefinedUseQueryResult<
  { list: T[]; page: PageProps },
  Error
> & {
  onPageNumChange: (pageNumber: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onFilter: (query: ParamsProps, customSearchParams?: URLSearchParams) => void;
};

function useDocsApi<T>(
  queryFn: (
    signal: AbortSignal,
    queries?: Record<string, any>,
    id?: string
  ) => any,
  queryKey: string[],
  options?: UseDocsOptionType<T>
): UseDocsApiReturnProps<T> {
  // Props
  const { defaultPageSize, onFetchFailed, onFetch, id, ...otherOptions } =
    options || {};
  // Hooks
  const [searchParams, setSearchParams] = useSearchParams();
  const pagination = {
    page: parseInt(searchParams.get('page') || '1'),
    limit: parseInt(
      searchParams.get('limit') || `${defaultPageSize ? defaultPageSize : 20}`
    ),
  };
  const page = useMemo(() => pagination.page, [pagination.page]);
  const limit = useMemo(() => pagination.limit, [pagination.limit]);

  const query = useAppQuery<{ list: T[]; page: PageProps }>({
    select: (data) => {
      return {
        list: 'list' in data ? data.list : [],
        page: 'page' in data ? data.page : initialPageProps,
      };
    },
    ...otherOptions,
    queryKey,
    gcTime: 60000,
    initialData: {
      list: [],
      page: initialPageProps,
    },
    queryFn: async ({ signal }) => {
      const response = await queryFn(
        signal,
        {
          page,
          limit,
          ...getSearchParamsObject(searchParams),
        },
        id
      );

      if (isSucceed(response.status)) return response;
      else throw new Error('ERROR');
    },
  });

  useMount(() => {
    if (onFetch && query?.data?.list && query?.data?.list.length > 0)
      onFetch(query?.data?.list || []);
  });

  useUpdateEffect(() => {
    query.refetch();
  }, [searchParams]);

  useUpdateEffect(() => {
    if (onFetch) onFetch(query?.data?.list || []);
  }, [query.data.list]);

  useUpdateEffect(() => {
    if (query.isError && onFetchFailed) onFetchFailed();
  }, [query.isError]);

  // Utilities
  const handlePageNumberChange = useCallback((pageNumber: number) => {
    searchParams.set('page', pageNumber.toString());
    setSearchParams(searchParams);
  }, []);

  const handlePageSizeChange = useCallback((pageSize: number) => {
    searchParams.set('limit', pageSize.toString());
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  }, []);

  const handleFilter = useCallback(
    (queries: ParamsProps = {}, customSearchParams?: URLSearchParams) => {
      if (customSearchParams) {
        setSearchParams(customSearchParams);
      } else {
        const newSearchParams = new URLSearchParams();

        // Add New Queries
        if (Object.keys(queries.length > 0))
          Object.entries(queries).forEach(([key, value]) => {
            if (!value) return;
            if (Array.isArray(value)) {
              value.forEach((v) => {
                newSearchParams.append(key, v);
              });
            } else newSearchParams.set(key, value);
          });

        // Set New SearchParam
        setSearchParams(newSearchParams);
      }
    },
    []
  );

  // Return
  return {
    ...query,
    onFilter: handleFilter,
    onPageNumChange: handlePageNumberChange,
    onPageSizeChange: handlePageSizeChange,
  };
}
export default useDocsApi;

import * as React from 'react';

// Core Components
import Typography from 'core/components/base/display/Typography';

import type { TypographyProps } from 'core/components/base/display/Typography';
interface CaptionProps extends TypographyProps {}

const Caption: React.FC<CaptionProps> = (props) => {
  const {
    children,
    component = 'p',
    variant = 'caption',
    ...otherProps
  } = props;

  return (
    <Typography component={component} variant={variant} {...otherProps}>
      {children}
    </Typography>
  );
};

export default Caption;

import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// Core Components
import Button from 'core/components/base/inputs/Button';
import Alert from 'core/components/base/feedback/Alert';
import Snackbar from 'core/components/base/feedback/Snackbar';
import SnackbarContent from 'core/components/base/feedback/SnackbarContent';

// Custom Hooks
import useUpdateEffect from 'core/hooks/useUpdateEffect';
import { useAppDispatch, useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import { reInitialAlert } from 'core/store/slices/core/shared/notification';

// Custom Types
import type { AlertColor } from 'core/components/base/feedback/Alert';

export interface NotificationStateProps {
  message: string;
  severity?: AlertColor;
  duartion?: number;
}

interface NotificationProps {}

const Notification: React.FC<NotificationProps> = () => {
  // Context
  const {
    duartion,
    message,
    severity,
    redirectUrl,
    redirectTitle,
    redirectButtonColor,
  } = useAppSelector((state) => state.notification);

  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Utilities
  const handleRedirect = () => {
    if (redirectUrl) navigate(redirectUrl);
    handleClose();
  };

  useUpdateEffect(() => {
    if (message) {
      setTimeout(() => dispatch(reInitialAlert()), duartion || 2500);
    }
  }, [message]);

  const handleClose = () => {
    dispatch(reInitialAlert());
  };

  const Action = (
    <Button
      variant='text'
      size='small'
      color={redirectButtonColor || 'warning'}
      onClick={handleRedirect}
    >
      {redirectTitle || 'انتقال'}
    </Button>
  );

  return message ? (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={duartion ? duartion : 5000}
      onClose={handleClose}
      open={message ? true : false}
    >
      {redirectUrl ? (
        <SnackbarContent
          message={message || 'لطفا پیغام را مشخص کنید'}
          action={Action}
        />
      ) : (
        <Alert
          onClose={handleClose}
          variant='filled'
          color={severity}
          severity={severity}
        >
          {message || 'لطفاً پیغام را مشخص کنید.'}
        </Alert>
      )}
    </Snackbar>
  ) : (
    <></>
  );
};

export default Notification;

import * as React from 'react';
import {
  ListItemButton as MuiListItemButton,
  ListItemButtonProps as MuiListItemButtonProps,
  styled,
} from '@mui/material';

export interface ListItemButtonProps extends MuiListItemButtonProps {
  to?: string;
  component?: React.ElementType;
  state?: any;
}

const ListItemButton = styled(MuiListItemButton)<ListItemButtonProps>(
  () => ({})
);

export default ListItemButton;

// Custom Hooks
import useDocsApi from 'core/hooks/api/useDocs';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  addBanner,
  deleteBannerContainer,
  editBanner,
  getBanners,
} from 'features/banner/banners/utilities/api';

// Custom Types
import type {
  BannerContainerDataProps,
  BannerContainerProps,
} from 'features/banner/banners/types';
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';
/**
 * Query key for fetching banners in react query.
 *
 * @constant {Array<string>}
 */
export const bannersQueryKey = ['banners'];

/**
 * Custom hook to fetch banners list.
 *
 * This hook utilizes the `useDocsApi` to retrieve a specific banners list on the provided options.
 *
 * @param {UseDocsOptionType<BannerContainerProps>} [options] - Optional configuration options for fetching the banners list.
 * @returns {UseDocsApiReturnProps<BannerContainerProps>} The result of the `useDocApi` hook, which includes the fetched banners list
 */
export const useBannerDocs = (
  options?: UseDocsOptionType<BannerContainerProps>
): UseDocsApiReturnProps<BannerContainerProps> =>
  useDocsApi<BannerContainerProps>(getBanners, bannersQueryKey, options);

/**
 * Custom hook for deleting a banner container.
 *
 * @returns {MutationResult<string, { status: number }>} The result of the delete mutation.
 */
export const useDeleteBannerContainerMutation = useDeleteMutation<string>(
  deleteBannerContainer,
  bannersQueryKey
);

/**
 * Custom hook for adding a new banner.
 *
 * @returns {MutationResult<BannerContainerDataProps, { status: number; banner?: BannerContainerProps }>} The result of the add mutation.
 */
export const useAddBannerMutation = useAddMutation<
  BannerContainerDataProps,
  { status: number; banner?: BannerContainerProps }
>(addBanner, bannersQueryKey);

/**
 * Custom hook for editing an existing banner.
 *
 * @returns {MutationResult<BannerContainerDataProps, { status: number; banner?: BannerContainerProps }>} The result of the edit mutation.
 */
export const useEditBannerMutation = useEditMutation<
  BannerContainerDataProps,
  { status: number; banner?: BannerContainerProps }
>(editBanner, bannersQueryKey);

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface UnfoldMoreIconProps extends SvgIconProps {}

const UnfoldMoreIcon: React.FC<UnfoldMoreIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M12 5.83 15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15z' />
  </IconBuilder>
);

export default UnfoldMoreIcon;

import * as Yup from 'yup';

export const TABLE_COL_LIMIT = 20;
export const TABLE_ROW_LIMIT = 100;

export const tablePatternValidationSchema = Yup.object().shape({
  colCount: Yup.number()
    .min(1, 'حداقل تعداد ستون جدول باید یک باشد.')
    .max(TABLE_COL_LIMIT, 'تعداد ستون‌های جدول نمی‌تواند بیش‌ از بیست باشد.')
    .required('وارد کردن تعداد ستون الزامی است.')
    .label('تعداد ستون'),
  rowCount: Yup.number()
    .min(1, 'حداقل تعداد سطر جدول باید یک باشد.')
    .max(TABLE_ROW_LIMIT, 'تعداد سطرهای جدول نمی‌تواند بیش‌ از صد باشد.')
    .required('وارد کردن تعداد ستون الزامی است.')
    .label('تعداد ستون'),
});

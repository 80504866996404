import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface ListProps extends SvgIconProps {}

const ListIcon: React.FC<ListProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <path d='M4 13.6H7V10.4H4V13.6ZM4 20H7V16.8H4V20ZM4 7.2H7V4H4V7.2ZM10 13.6H20V10.4H10V13.6ZM10 20H20V16.8H10V20ZM10 4V7.2H20V4H10Z' />
    </IconBuilder>
  );
};

export default ListIcon;

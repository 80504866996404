import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface NumbersFilledIconProps extends SvgIconProps {}

const NumbersFilledIcon: React.FC<NumbersFilledIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1ZM8.4,16.2H3.6V15H5.4V9H3.6V7.8h3V15H8.4Zm6-4.8a1.2,1.2,0,0,1-1.2,1.2H10.8V15h3.6v1.2H9.6V12.6a1.2,1.2,0,0,1,1.2-1.2h2.4V9H9.6V7.8h3.6A1.2,1.2,0,0,1,14.4,9Zm5.65,4.45a1.23,1.23,0,0,1-.85.35H15.6V15h3.6V12.6H16.8V11.4h2.4V9H15.6V7.8h3.6A1.2,1.2,0,0,1,20.4,9v6A1.23,1.23,0,0,1,20.05,15.85Z' />
  </IconBuilder>
);

export default NumbersFilledIcon;

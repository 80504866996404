import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface MaximizeIconProps extends SvgIconProps {}

const MaximizeIcon: React.FC<MaximizeIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M20.2,13l-9.3-9.2C10.3,3.1,10.7,2,11.7,2l9.3,0c0.6,0,1,0.4,1,1l0,9.2C21.9,13.1,20.8,13.6,20.2,13z' />
    <path d='M3.8,11l9.3,9.2c0.6,0.6,0.2,1.7-0.7,1.7l-9.3,0c-0.6,0-1-0.4-1-1l0-9.2C2.1,10.9,3.2,10.4,3.8,11z' />
  </IconBuilder>
);

export default MaximizeIcon;

import * as React from 'react';

// Core Components
import Box from 'core/components/base/layout/Box';

// Types
import type { BoxProps } from 'core/components/base/layout/Box';
interface PageHeroBoxProps extends BoxProps {}

const PageHeroBox: React.FC<PageHeroBoxProps> = React.forwardRef(
  (props, ref) => {
    const { children, sx, ...otherProps } = props;

    return (
      <Box
        ref={ref}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          marginY: '1rem',
          height: '3rem',
          ...sx,
        }}
        {...otherProps}
      >
        {children}
      </Box>
    );
  }
);

export default PageHeroBox;

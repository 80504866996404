import store from 'core/store';
import { actions } from '../slice';
import type {
  FeedbackOverviewDataProps,
  FeedbackProps,
} from 'features/form/feedbacks/types';

export * from './section';
export * from './input';

/**
 * Set the feedback data.
 *
 * @param feedback The feedback data.
 */
export const setFeedback = (feedback: FeedbackOverviewDataProps | null) => {
  store.dispatch(actions.setFeedback(feedback));
};

/**
 * Set the feedbackPdfValues.
 *
 * @param feedback The feedbacks.
 */
export const setFeedbackPdfValues = (feedback: FeedbackProps[]) => {
  store.dispatch(actions.setFeedbackPdfValues(feedback));
};

/**
 * Set the feedbackExcelValues.
 *
 * @param feedback The feedbacks.
 */
export const setFeedbackExcelValues = (feedback: FeedbackProps[]) => {
  store.dispatch(actions.setFeedbackExcelValues(feedback));
};

/**
 * Set the isFeedbackPdfPreviewOpen flag.
 *
 * @param value The isFeedbackPdfPreviewOpen flag.
 */
export const setIsFeedbackPdfPreviewOpen = (value: boolean) => {
  store.dispatch(actions.setIsFeedbackPdfPreviewOpen(value));
};

/**
 * Set the feedback toggle mode.
 *
 * @param action The toggle mode.
 */
export const setFeedbackToggleMode = (action: 'visibility' | 'width') => {
  store.dispatch(actions.setToggleMode(action));
};

/**
 * Updates the feedback hiddenInputs state.
 *
 * @param action The hiddenInputs object.
 */
export const setFeedbackHiddenInputs = (action: Record<string, boolean>) => {
  store.dispatch(actions.setHiddenInputs(action));
};

/**
 * Updates the feedback fullWidthInputs state.
 *
 * @param action The fullWidthInputs object.
 */
export const setFeedbackFullWidthInputs = (action: Record<string, boolean>) => {
  store.dispatch(actions.setHiddenInputs(action));
};

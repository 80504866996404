'use client';
import * as React from 'react';

// Core Components
import Chip from 'core/components/base/display/Chip';

// Custom Common Components
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import Prefixer from 'core/components/shared/Prefixer';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';

// Custom Feature Components
import ProcessComment from 'features/form/feedbacks/components/details/Process/shared/Comment';
import ProcessActions from 'features/form/feedbacks/components/details/Process/shared/Actions';
import EvaluationReviews from 'features/form/feedbacks/components/details/Process/Evaluation/Reviews';
import EvaluationQuestionTitle from 'features/form/feedbacks/components/details/Process/Evaluation/QuestionTitle';

// Custom Icons
import CheckCircleIcon from 'core/components/icons/CheckCircle';

// Custom Utilities
import { useAppSelector } from 'core/hooks/redux';
import { isSame } from 'core/utilities/helper/helperPack';
import { renderIfVisible } from 'features/form/feedbacks/utilities/details/helper';

// Custom Types
import type { FeedbackProcessEvaluationProps } from 'features/form/feedbacks/types/detail/process/evaluation';
import Paper from 'core/components/base/surfaces/Paper';

interface EvaluationComponentProps {
  evaluation: FeedbackProcessEvaluationProps;
  showDeleteButton: boolean;
  showResetButton: boolean;
}

const Evaluation: React.FC<EvaluationComponentProps> = (props) => {
  // Props
  const {
    showDeleteButton,
    showResetButton,
    evaluation: { id: evaluationId, data: evaluation },
  } = props;

  // Context
  const hiddenSections = useAppSelector(
    (state) => state.feedback.hiddenProcesses[evaluationId]
  );

  // States
  const isEvaluationCompleted = evaluation.overalStatus === 'completed';

  // Render
  return (
    <ColumnStack
      spacing='1rem'
      component={Paper}
      sx={{
        borderRadius: '.5rem',
        padding: '1rem',
        width: '100%',
        boxShadow: 'none',
        background: ({ palette }) =>
          palette.mode === 'dark' ? undefined : '#DFE8EF',
        '@media print': {
          border: '1px solid #ccc',
        },
      }}
    >
      <SpaceBetween component='section' width='100%'>
        {!hiddenSections?.title && evaluation?.title ? (
          <Prefixer
            Prefix={<CheckCircleIcon sx={{ fontSize: '1.4rem' }} />}
            gap={0}
          >
            <HeadingSix>{evaluation?.title}</HeadingSix>
            {evaluation.overalStatus !== 'completed' && (
              <Chip
                variant='filled'
                color='info'
                label='درحال ارزیابی'
                sx={{
                  backgroundColor: '#0091FF33',
                  color: 'info.main',
                  borderRadius: '.5rem',
                  width: 'fit-content',
                  '.MuiChip-label': {
                    paddingX: '.25rem',
                  },
                }}
              />
            )}
          </Prefixer>
        ) : (
          <ColumnStack />
        )}
        {(showDeleteButton || showResetButton) && (
          <ProcessActions
            type='evaluation'
            showDeleteButton={showDeleteButton}
            showResetButton={showResetButton}
          />
        )}
      </SpaceBetween>
      <EvaluationQuestionTitle
        selectedOptionTitle={renderIfVisible(
          hiddenSections,
          'selectedOpt',
          evaluation?.selectedOptionTitle
        )}
        questionTitle={renderIfVisible(
          hiddenSections,
          'questionTitle',
          evaluation?.questionTitle
        )}
        message={renderIfVisible(
          hiddenSections,
          'message',
          evaluation?.message
        )}
        overalStatus={evaluation.overalStatus}
      />
      {isEvaluationCompleted &&
        (!hiddenSections?.comment ||
          !hiddenSections?.evaluatorFullname ||
          !hiddenSections?.date) && (
          <ProcessComment
            files={
              renderIfVisible(
                hiddenSections,
                'comment',
                evaluation.evaluatorCommentFiles
              ) || []
            }
            message={renderIfVisible(
              hiddenSections,
              'comment',
              evaluation.evaluatorComment
            )}
            completionDate={renderIfVisible(
              hiddenSections,
              'date',
              evaluation?.completionDate
            )}
            fullName={renderIfVisible(
              hiddenSections,
              'evaluatorFullname',
              evaluation?.evaluatorFullname
            )}
            sx={{
              width: '100%',
              background: ({ palette }) =>
                palette.mode === 'dark' ? '#121212' : '#F5F8FA',
              padding: { xs: '.5rem', md: '.75rem' },
            }}
          />
        )}
      {evaluation.reviews &&
        evaluation.reviews.length > 0 &&
        !hiddenSections?.review && (
          <EvaluationReviews
            reviews={evaluation.reviews}
            evaluation={props.evaluation}
          />
        )}
    </ColumnStack>
  );
};

export default React.memo(Evaluation, isSame);

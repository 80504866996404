// Custom Hooks
import useDocsApi from 'core/hooks/api/useDocs';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  addTag,
  editTag,
  getTagsList,
  removeTags,
} from 'features/content/tags/utilities/api';

// Custom Types
import type {
  TagDataProps,
  TagListItemProps,
} from 'features/content/tags/types';
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';

/**
 * Query key for fetching tags in react query.
 *
 * @constant {Array<string>}
 */
export const tagsQueryKey = ['tags'];

/**
 * Custom hook to fetch tags.
 *
 * This hook utilizes the `useDocApi` to retrieve tags list on the provided options.
 *
 * @param {UseDocOptionType<TagListItemProps>} [options] - Optional configuration options for fetching the tags.
 * @returns {UseDocApiReturnProps<TagListItemProps>} The result of the `useDocApi` hook, which includes the fetched tags
 */
export const useTagDocs = (
  options?: UseDocsOptionType<TagListItemProps>
): UseDocsApiReturnProps<TagListItemProps> =>
  useDocsApi<TagListItemProps>(getTagsList, tagsQueryKey, options);

/**
 * Custom hook for deleting tags.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the delete mutation, including the status and potential errors.
 */
export const useDeleteTagsMutation = useDeleteMutation<string[]>(
  removeTags,
  tagsQueryKey
);

/**
 * Custom hook for adding a new tag.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the add mutation, including the status and potential errors.
 */
export const useAddTagMutation = useAddMutation<TagDataProps>(
  addTag,
  tagsQueryKey
);

/**
 * Custom hook for editing an existing tag.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the edit mutation, including the status and potential errors.
 */
export const useEditTagMutation = useEditMutation<TagDataProps>(
  editTag,
  tagsQueryKey
);

import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Custom Core Components
import Table from 'core/components/base/display/Table';
import Checkbox from 'core/components/base/inputs/Checkbox';
import TableRow from 'core/components/base/display/TableRow';
import TableCell from 'core/components/base/display/TableCell';
import TableBody from 'core/components/base/display/TableBody';
import TableHead from 'core/components/base/display/TableHead';
import TableContainer from 'core/components/base/display/TableContainer';

// Custom Common Components
import TableContainerPaper from 'core/components/shared/Paper/TableContainerPaper';

// Custom Feature Components
import DataGridItem from 'features/menu/menus/components/list/DataGridItem';

// Custom Utilities
import { useDocsContext } from 'core/hooks/api/useDocs/Context';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useSelectionContext } from 'core/store/context/common/UseSelection';
import { tableScrollbarStyles } from 'core/utilities/styles/customStyles';

// Custom Types
import type { MenuListItemProps } from 'features/menu/menus/types';

interface MenusDataGridProps {
  disabled?: boolean;
}

const MenusDataGrid: React.FC<MenusDataGridProps> = (props) => {
  // Props
  const { disabled } = props;

  // Context
  const loading = useSelectLoading();
  const {
    data: { list: menus },
  } = useDocsContext<MenuListItemProps>();
  const selections = useSelectionContext();

  // Hooks
  const { palette } = useTheme();

  // Render
  return (
    <TableContainer
      sx={{ paddingBottom: '1rem', ...tableScrollbarStyles }}
      component={TableContainerPaper}
    >
      <Table>
        <TableHead>
          <TableRow
            sx={{
              background: '#D0E3E680',
              borderBottom: `1px solid ${palette.divider}`,
            }}
          >
            <TableCell padding='checkbox'>
              <Checkbox
                color='info'
                disabled={disabled || menus.length === 0}
                checked={selections.isAllSelected(menus)}
                indeterminate={selections.isIndeterminate(menus)}
                onChange={() => selections.onToggleAll(menus)}
              />
            </TableCell>
            <TableCell
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              عنوان
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              تعداد منو
            </TableCell>
            <TableCell
              align='center'
              sx={{
                width: '7rem',
                whiteSpace: 'nowrap',
              }}
            >
              تاریخ ساخت
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {disabled && menus.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4}>در حال دریافت اطلاعات مخاطبان.</TableCell>
            </TableRow>
          ) : menus.length > 0 ? (
            menus.map((menu) => (
              <DataGridItem
                key={menu.id}
                menu={menu}
                disabled={loading}
                selected={selections.isSelected(menu.id)}
                onSelect={selections.onSelectionsChange}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>منویی جهت نمایش وجود ندارد.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MenusDataGrid;

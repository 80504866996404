import React, { useContext } from 'react';

// Custom Hooks
import useDialog from 'core/hooks/useDialog';

// Custom Components
import DeleteDialog from 'core/components/shared/Dialog/Delete';

// Custom Types
import type { ColorType } from 'core/types/mui/color';
import type { UseDialogReturnProps } from 'core/hooks/useDialog';

type SubmitHandler = () => any;
type OpenDialogType = (
  parameter?: Partial<
    Omit<
      UseDialogContextProps & { onSubmit: SubmitHandler },
      'onOpenDialog' | 'onCloseDialog' | 'showDialog'
    >
  >
) => void;

interface UseDialogContextProps {
  showDialog: boolean;
  title: string;
  description: string;
  submitTitle: string;
  submitColor: ColorType;
  onCloseDialog: () => void;
  onOpenDialog: OpenDialogType;
}

const DialogContext = React.createContext<UseDialogReturnProps | null>(null);

export const useDialogContext = (): UseDialogContextProps => {
  // Context
  const context = useContext(DialogContext) as UseDialogReturnProps;

  // Methods
  return {
    title: context.title || '',
    description: context.description || '',
    submitTitle: context.submitTitle || '',
    submitColor: context.submitColor || 'primary',
    showDialog: context.showDialog,
    onOpenDialog: context.onOpenDialog as OpenDialogType,
    onCloseDialog: context.onCloseDialog,
  };
};

const DialogProvider = (props: { children?: React.ReactNode }) => {
  const { children } = props;

  // States
  const methods = useDialog();
  const {
    showDialog,
    title,
    description,
    submitTitle,
    submitColor,
    onCloseDialog,
    onSubmit,
  } = methods;

  const onOpenDialog = (
    parameter = {} as Partial<
      UseDialogContextProps & { onSubmit: SubmitHandler }
    >
  ) => {
    const {
      title = '',
      description = '',
      submitTitle = '',
      submitColor = 'error',
      showDialog,
      onSubmit,
    } = parameter;

    if (onSubmit) methods.onSetSubmitHandler(() => onSubmit);
    if (showDialog) methods.onOpenDialog();

    methods.onSetTitle(title);
    methods.onSetDescription(description);
    methods.onSetSubmitTitle(submitTitle);
    methods.onSetSubmitColor(submitColor);

    methods.onOpenDialog();
  };

  // Utilities
  const MemoizedChildren = React.useMemo(() => children, [children]);

  // Render
  return (
    <DialogContext.Provider
      value={{
        ...methods,
        onOpenDialog,
      }}
    >
      {MemoizedChildren}
      {showDialog && (
        <DeleteDialog
          open={showDialog}
          description={description}
          title={title || 'آیا مطمئن هستید؟'}
          submitTitle={submitTitle || 'حذف'}
          submitColor={submitColor}
          onClose={onCloseDialog}
          onDelete={onSubmit}
        />
      )}
    </DialogContext.Provider>
  );
};

export default DialogProvider;

import * as React from 'react';

// Core Components
import Paper from 'core/components/base/surfaces/Paper';

import type { PaperProps } from 'core/components/base/surfaces/Paper';
export interface RoundPaperProps extends PaperProps {}

const RoundPaper: React.FC<RoundPaperProps> = React.forwardRef((props, ref) => {
  const { children, sx, ...otherProps } = props;

  return (
    <Paper
      ref={ref}
      sx={{
        borderRadius: '0.5rem',
        boxShadow: 'none',
        padding: '1rem 2rem',
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Paper>
  );
});

export default RoundPaper;

// Custom Utilitis
import apiHandler from 'core/utilities/apiHandler';
import { getDoc, getDocs, getSummary } from 'core/utilities/apiHandler/helper';
import { tagEndpoints as endpoints } from 'features/content/tags/utilities/api/endpoints';

// Custom Types
import type { ApiPaginationProps } from 'core/types/shared/pagination/api';
import type {
  ApiGetDocResponse,
  ApiGetDocsResponse,
  ApiGetSummaryResponse,
} from 'core/types/api/hook/response';
import type {
  TagDataProps,
  TagProps,
  TagSummaryProps,
  TagListItemProps,
} from 'features/content/tags/types';

/**
 * Adds a new tag.
 *
 * @param {TagDataProps} data - The data for the tag to be added.
 * @returns {Promise<{ status: number; tag?: TagProps }>} A promise that resolves to an object containing the status of the operation and the newly added tag, if available.
 */
export const addTag = async (
  data: TagDataProps
): Promise<{ status: number; tag?: TagProps }> => {
  const { status } = await apiHandler.post(endpoints.add, data);
  return { status };
};

/**
 * Edits an existing tag.
 *
 * @param {string} id - The ID of the tag to be edited.
 * @param {TagDataProps} data - The new data for the tag.
 * @returns {Promise<{ status: number }>} A promise that resolves to an object containing the status of the edit operation.
 */
export const editTag = async (
  id: string,
  data: TagDataProps
): Promise<{ status: number }> => {
  const { status } = await apiHandler.patch(`${endpoints.edit(id)}`, data);
  return { status };
};

/**
 * Removes one or more tags.
 *
 * @param {string | string[]} ids - The ID or array of IDs of the tags to be removed.
 * @returns {Promise<{ status: number }>} A promise that resolves to an object containing the status of the remove operation.
 */
export const removeTags = async (
  ids: string | string[]
): Promise<{ status: number }> => {
  const { status } = await apiHandler.delete(`${endpoints.remove}`, {
    ids: typeof ids === 'string' ? [ids] : ids,
  });
  return { status };
};

/**
 * Fetches a tag by its ID.
 *
 * @param {string} id - The ID of the tag to be fetched.
 * @param {AbortSignal} [signal] - An optional signal to abort the request.
 * @returns {Promise<ApiGetDocResponse<TagProps>>} A promise that resolves to an object containing the status and the fetched tag document, if available.
 */
export const getTag = async (
  id: string,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<TagProps>> => {
  const endpoint = endpoints.getById(id);

  return await getDoc<TagProps, TagProps>(endpoint, { signal });
};

/**
 * Fetches a list of tags.
 *
 * @param {AbortSignal} [signal] - An optional signal to abort the request.
 * @param {Record<string, any>} [queries] - Optional query parameters to filter the tag list.
 * @returns {Promise<ApiGetDocsResponse<TagListItemProps>>} A promise that resolves to an object containing the status, the list of tags, and pagination info, if available.
 */
export const getTagsList = async (
  signal?: AbortSignal,
  queries?: Record<string, any>
): Promise<ApiGetDocsResponse<TagListItemProps>> => {
  return await getDocs<
    { data: TagListItemProps[]; paginate: ApiPaginationProps },
    TagListItemProps
  >(endpoints.getList, {
    queries,
    signal,
  });
};

/**
 * Fetches a summary list of tags.
 *
 * @param {AbortSignal} [signal] - An optional signal to abort the request.
 * @returns {Promise<ApiGetSummaryResponse<TagSummaryProps>>} A promise that resolves to an object containing the status and the summary list of tags.
 */
export const getTagsSummaryList = async (
  signal?: AbortSignal
): Promise<ApiGetSummaryResponse<TagSummaryProps>> => {
  const endpoint = endpoints.getSummaryList;

  return await getSummary<TagSummaryProps[], TagSummaryProps>(endpoint, {
    signal,
  });
};

import { useCallback, useState } from 'react';

type Value = boolean | string | number;

/**
 * Custom React hook for toggling between two values.
 * @template T - Type of valueA
 * @template U - Type of valueB
 * @param {T} valueA - First value to toggle between.
 * @param {U} valueB - Second value to toggle between.
 * @param {T | U} [initialValue] - Initial value of the toggle. Defaults to null.
 * @returns {[T | U | null, (value?: T | U) => void]} Array containing the current state and a function to toggle the state.
 */
const useToggle = <T extends Value, U extends Value>(
  valueA: T,
  valueB: U,
  initialValue?: T | U
) => {
  const [state, setState] = useState<T | U | null>(initialValue || null);

  const onToggle = useCallback(
    (value?: T | U) => {
      setState(
        (prevState) => value || (prevState === valueA ? valueB : valueA)
      );
    },
    [state]
  );

  return [state, onToggle] as const;
};

export default useToggle;

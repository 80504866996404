import { type FC } from 'react';
import { useParams } from 'react-router-dom';

// Custom Feature Components
import FeedbacksListBody from 'features/form/feedbacks/components/list/Body';
import FormFeedbacksListActions from 'features/form/feedbacks/components/list/Actions';

// Custom Hooks
import useDeprecatedDocsApi from 'core/hooks/api/useDeprecatedDocs';
import useSelection from 'core/hooks/common/useSelection';
import useGroupOperation from 'core/hooks/common/useGroupOperation';
import useFilter from 'core/hooks/common/useFilter';

// Custom Contexts
import { DeprecatedDocsProvider } from 'core/store/context/useDeprecatedDocsApi';
import SelectionProvider from 'core/store/context/common/UseSelection';
import { GroupOperationProvider } from 'core/store/context/common/UseGroupOperation';
import { FilterProvider } from 'core/store/context/common/UseFilter';

// Custom Utilities
import { getFeedbacksByFormId } from 'features/form/feedbacks/utilities/api/list';

// Custom Types
import type { FeedbackListItemProps } from 'features/form/feedbacks/types';
import type { FeedbackListOperations } from 'features/form/feedbacks/components/list/Actions/Normal';

export interface FormFeedbacksListProps {}

const FormFeedbacksList: FC<FormFeedbacksListProps> = () => {
  // Hooks
  const { formId } = useParams();

  const selectionMethods = useSelection();
  const groupOperationMethods = useGroupOperation<FeedbackListOperations>();
  const filterMethods = useFilter();

  const methods = useDeprecatedDocsApi<FeedbackListItemProps>(
    getFeedbacksByFormId,
    {
      id: formId,
      shouldUseId: true,
      apiShouldAcceptIdKeys: true,
    }
  );

  return (
    <DeprecatedDocsProvider {...methods}>
      <SelectionProvider {...selectionMethods}>
        <GroupOperationProvider {...groupOperationMethods}>
          <FilterProvider {...filterMethods}>
            <FormFeedbacksListActions />
            <FeedbacksListBody formId={formId || ''} />
          </FilterProvider>
        </GroupOperationProvider>
      </SelectionProvider>
    </DeprecatedDocsProvider>
  );
};

export default FormFeedbacksList;

/**
 * Returns the API endpoint URL.
 * @returns {string} The API endpoint URL.
 */
export const getApiEndpoint = (): string =>
  import.meta.env.VITE_APP_API_ENDPOINT || '';

/**
 * Returns the API version.
 * @returns {string} The API version.
 */
export const getApiVersion = (): string =>
  import.meta.env.VITE_APP_API_ENDPOINT_VERSION || '';

/**
 * Returns the base URL for app requests.
 * @returns {string} The base URL for app requests.
 */
export const getAppBaseURL = (): string =>
  `${getApiEndpoint()}/admin/v${getApiVersion()}`;

/**
 * Returns the module base URL for app requests.
 * @returns {string} The module base URL for app requests.
 */
export const getAppModuleBaseURL = (): string =>
  `${getApiEndpoint()}/module/v${getApiVersion()}`;

/**
 * Returns the authentication token name.
 * @returns {string} The authentication token name.
 */
export const getAuthTokenName = (): string =>
  import.meta.env.VITE_APP_X_AUTH_TOKEN || '';

/**
 * Returns the app user URL.
 * @returns {string} The app user URL.
 */
export const getAppUserURL = (): string =>
  import.meta.env.VITE_APP_USER_URL || '';

/**
 * Returns the app admin URL.
 * @returns {string} The app admin URL.
 */
export const getAppAdminURL = (): string =>
  import.meta.env.VITE_APP_ADMIN_URL || '';

/**
 * Returns the app name.
 * @returns {string} The app name.
 */
export const getAppName = (): string => import.meta.env.VITE_APP_NAME || '';

/**
 * Returns the app name in Farsi.
 * @returns {string} The app name in Farsi.
 */
export const getAppNameFa = (): string =>
  import.meta.env.VITE_APP_NAME_FA || '';

import {
  useEffect,
  useState,
  useCallback,
  type FC,
  type MouseEvent,
  memo,
} from 'react';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';
import Menu from 'core/components/base/navigation/Menu';
import MenuItem from 'core/components/base/navigation/MenuItem';
import Checkbox from 'core/components/base/inputs/Checkbox';
import BodyOne from 'core/components/shared/Typography/BodyOne';

// Custom Icon Components
import HiddenFieldsIcon from 'features/form/forms/components/icons/HiddenFields';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { getFormSectionsSummary } from 'features/form/forms/utilities/api/section';
import { isSucceed, setAppAlert } from 'core/utilities/helper';

// Custom Types
import type { ButtonProps } from 'core/components/base/inputs/Button';
import type { FormSectionSummaryProps } from 'features/form/forms/types/item/section';
interface InputOptionSectionSelectProps extends Omit<ButtonProps, 'onChange'> {
  optId: string;
  values: string[];
  inputSectionId?: string;
  onChange?: (values: string[]) => void;
}

const InputOptionSectionSelect: FC<InputOptionSectionSelectProps> = (props) => {
  // Props
  const { optId, values, sx, inputSectionId, onChange, ...otherProps } = props;

  // Context
  const formId = useAppSelector((state) => state.form.formId);

  // States
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<FormSectionSummaryProps[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Utilities
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const handleSelect = useCallback(
    (id: string) => () => {
      let newValues: string[] = [...values];

      const vIndex = newValues.findIndex((v) => v === id);

      if (vIndex > -1) newValues.splice(vIndex, 1);
      else newValues.push(id);

      if (onChange) onChange(newValues);
    },
    [values]
  );

  const updateOptions = useCallback(async () => {
    if (open && formId) {
      setLoading(true);
      const { status, list = [] } = await getFormSectionsSummary(formId, {
        isHidden: true,
      });
      setLoading(false);

      if (isSucceed(status)) {
        setOptions(list);
      } else {
        setAppAlert('خطا در دریافت بخش‌های مخفی فرم.');
      }
    }
  }, [open, formId]);

  useEffect(() => {
    updateOptions();
  }, [updateOptions]);

  // Render
  return (
    <>
      <Button
        id={`input-section-button-${optId}`}
        aria-controls={open ? `input-section-menu-${optId}` : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        startIcon={<HiddenFieldsIcon />}
        onClick={handleClick}
        sx={{
          minWidth: '9rem',
          backgroundColor: 'background.default',
          color: 'text.secondary',
          ...sx,
        }}
        {...otherProps}
      >
        {values.length > 0 ? `${values.length} بخش مخفی` : 'بخش‌های مخفی'}
      </Button>
      <Menu
        id={`input-section-menu-${optId}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `input-section-button-${optId}`,
          sx: { m: 0, p: 0 },
        }}
        PaperProps={{
          sx: {
            marginTop: '0.25rem',
            minWidth: '10rem',
            boxShadow: 'none',
            backgroundColor: 'background.default',
          },
        }}
      >
        {options.length > 0 ? (
          options.map((opt) => (
            <MenuItem key={opt.id} onClick={handleSelect(opt.id)} disableRipple>
              <Checkbox
                checked={values.includes(opt.id)}
                onClick={handleSelect(opt.id)}
                size='small'
              />
              <BodyOne>{opt.data.label}</BodyOne>
            </MenuItem>
          ))
        ) : loading ? (
          <BodyOne sx={{ color: 'text.secondary', p: '1rem' }}>
            در حال دریافت لیست بخش‌ها...
          </BodyOne>
        ) : (
          <BodyOne sx={{ color: 'text.secondary', p: '1rem' }}>
            بخش قابل انتخاب یافت نشد.
          </BodyOne>
        )}
      </Menu>
    </>
  );
};

export default memo(InputOptionSectionSelect, isSame);

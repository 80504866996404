import { type FC } from 'react';
import { alpha, useTheme } from '@mui/material';

// Custom Core Components
import IconButton, {
  type IconButtonProps,
} from 'core/components/base/inputs/IconButton';

// Custom Utilities
import { getFormInputTypeIcon } from 'features/form/forms/utilities/input/type';

// Custom Types
import type { FormInputType } from 'features/form/forms/types/item/input/type';
export interface InputIndicatorIconButtonProps
  extends Omit<IconButtonProps, 'type'> {
  type: FormInputType;
}

const InputIndicatorIconButton: FC<InputIndicatorIconButtonProps> = (props) => {
  // Props
  const { type, sx, ...otherProps } = props;

  // Hooks
  const { palette } = useTheme();

  return (
    <IconButton
      disableRipple
      size='large'
      sx={{
        borderRadius: '0.5rem',
        backgroundColor: 'background.default',
        ':hover': {
          backgroundColor: alpha(palette.background.default, 0.8),
        },
        ':active': {
          backgroundColor: 'background.default',
        },
        ...sx,
      }}
      {...otherProps}
    >
      {getFormInputTypeIcon(type || 'text')}
    </IconButton>
  );
};

export default InputIndicatorIconButton;

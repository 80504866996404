import * as React from 'react';

// Custom HOC
import WithAuth from 'core/components/shared/HOC/WithAuth';

// Custom Hooks
import useUnMount from 'core/hooks/useUnMount';

// Custom Common Components
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';

// Custom Feature Components
import MenuContainer from 'features/menu/menus/components/details/Container';

// Context
import { resetMenuState } from 'core/store/slices/feature/menu';

// Custom Types
interface AddMenu {}

const AddMenu: React.FC<AddMenu> = (props) => {
  // Hooks
  useUnMount(() => resetMenuState());

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='منوها' />
      <PageHeroBox width='100%'>
        <HeadingSix>عنوان فهرست</HeadingSix>
      </PageHeroBox>
      <MenuContainer />
    </>
  );
};

export default WithAuth(AddMenu);

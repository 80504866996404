import { useState, type FC, useRef, useEffect } from 'react';

// Custom Common Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import TextField from 'core/components/base/inputs/TextField';

// Custom Hooks
import { useAppDispatch, useAppSelector } from 'core/hooks/redux';
import useClickOutside from 'core/hooks/useClickOutside';

// Custom Utilities
import { updateCell } from 'features/appBuilder/patterns/features/table/store';
import { isCellSelected } from 'features/appBuilder/patterns/features/table/utilities';

// Custom Types
import type { TableCellStringProps } from 'features/appBuilder/patterns/features/table/types/cell';
export interface TextCellProps {
  cell: TableCellStringProps;
}

const TextCell: FC<TextCellProps> = (props) => {
  // Props
  const { cell } = props;

  // States
  const [value, setValue] = useState<string>(cell.value);
  const [mode, setMode] = useState<'preview' | 'edit'>('preview');

  // Hooks
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const { selection } = useAppSelector((state) => state.table.data);

  const handleBlur = () => {
    const value = inputRef.current?.value || '';

    dispatch(updateCell({ ...cell, value }));
    setMode('preview');
  };

  useClickOutside(inputRef, handleBlur);
  useEffect(() => {
    if (mode === 'edit') {
      inputRef.current?.focus();
      setValue(cell.value);
    }
  }, [mode, cell.value]);

  return (
    <Box
      onClick={(e) => {
        if (isCellSelected(selection, cell)) e.stopPropagation();
        setMode('edit');
      }}
      sx={{
        bgColor: 'red',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {mode === 'edit' ? (
        <TextField
          fullWidth
          multiline
          minRows={1}
          maxRows={4}
          value={value}
          sx={{ minHeight: '2.5rem', border: 'none' }}
          inputRef={inputRef}
          InputProps={{ sx: { minHeight: '2.5rem' } }}
          onChange={(e) => {
            const v = e.target.value;
            setValue(v);
          }}
        />
      ) : (
        <BodyTwo dir='auto' sx={{ whiteSpace: 'pre-wrap' }}>
          {cell.value}
        </BodyTwo>
      )}
    </Box>
  );
};

export default TextCell;

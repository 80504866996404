import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface ManagerOutlinedIconProps extends SvgIconProps {}

const ManagerOutlinedIcon: React.FC<ManagerOutlinedIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M17,15.9c1.9,0,4.1,0.9,4.4,1.4V18h-8.8v-0.8C12.8,16.7,15.1,15.9,17,15.9z M17,14.2c-2,0-6.1,1-6.1,3v2.5H23
	v-2.5C23,15.2,19,14.2,17,14.2z M8.7,13.1C6.1,13.1,1,14.4,1,17v2.8h7.7V18h-6V17c0-0.7,3.1-2.4,7-2.2c0.5-0.6,1.1-1,1.7-1.4
	C10.4,13.2,9.6,13.1,8.7,13.1z M8.7,5.9C9,5.9,9.3,6,9.6,6.2c0.3,0.2,0.5,0.4,0.6,0.7c0.1,0.3,0.2,0.6,0.1,1
	c-0.1,0.3-0.2,0.6-0.5,0.8C9.6,9,9.3,9.2,9,9.2c-0.3,0.1-0.7,0-1-0.1C7.8,9,7.5,8.8,7.3,8.5C7.1,8.2,7.1,7.9,7.1,7.6
	c0-0.4,0.2-0.9,0.5-1.2C7.8,6.1,8.3,5.9,8.7,5.9z M8.7,4.3C8,4.3,7.4,4.5,6.9,4.9c-0.5,0.4-1,0.9-1.2,1.5C5.4,6.9,5.3,7.6,5.5,8.2
	c0.1,0.6,0.4,1.2,0.9,1.7c0.5,0.5,1,0.8,1.7,0.9c0.6,0.1,1.3,0.1,1.9-0.2c0.6-0.2,1.1-0.7,1.5-1.2C11.8,8.9,12,8.3,12,7.6
	c0-0.9-0.3-1.7-1-2.3C10.4,4.6,9.6,4.3,8.7,4.3z M17,8.1c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5C18,9,18.1,9.3,18,9.5
	s-0.1,0.4-0.3,0.6c-0.2,0.2-0.3,0.3-0.6,0.3c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.4-0.2-0.6
	c0-0.3,0.1-0.6,0.3-0.8C16.4,8.3,16.7,8.1,17,8.1z M17,6.5c-0.5,0-1.1,0.2-1.5,0.5c-0.5,0.3-0.8,0.7-1,1.2c-0.2,0.5-0.3,1.1-0.2,1.6
	c0.1,0.5,0.4,1,0.8,1.4c0.4,0.4,0.9,0.6,1.4,0.8c0.5,0.1,1.1,0.1,1.6-0.2c0.5-0.2,0.9-0.6,1.2-1c0.3-0.5,0.5-1,0.5-1.5
	c0-0.7-0.3-1.4-0.8-1.9C18.4,6.8,17.7,6.5,17,6.5z'
    />
  </IconBuilder>
);

export default ManagerOutlinedIcon;

import apiHandler from 'core/utilities/apiHandler';
import getEndpoint from 'core/utilities/helper/getEndpoint';
import { FeedbackProcessProps } from 'features/form/feedbacks/types/detail/process';

const { resetEvaluation, removeEvaluation } = getEndpoint();

/**
 * Resets a process item based on the provided feedback ID.
 *
 * @param {string} feedbackId - The ID of the feedback item to reset.
 * @returns {Promise<{ status: number; process?: FeedbackProcessProps }>}
 *          A promise that resolves to an object containing the status of the operation
 *          and optionally the reset process item.
 */
export const resetProcessItem = async (
  feedbackId: string
): Promise<{ status: number; process?: FeedbackProcessProps }> => {
  const { status, data } = await apiHandler.put<{
    docs: FeedbackProcessProps;
  }>(`${resetEvaluation}/${feedbackId}`);
  return { status, process: data?.docs };
};

/**
 * Deletes a process item based on the provided feedback ID.
 *
 * @param {string} feedbackId - The ID of the feedback item to delete.
 * @returns {Promise<{ status: number; process?: FeedbackProcessProps }>}
 *          A promise that resolves to an object containing the status of the operation
 *          and optionally the deleted process item.
 */
export const deleteProcessItem = async (
  feedbackId: string
): Promise<{ status: number; process?: FeedbackProcessProps }> => {
  const { status, data } = await apiHandler.delete<{
    docs: FeedbackProcessProps;
  }>(`${removeEvaluation}/${feedbackId}`);
  return { status, process: data?.docs };
};

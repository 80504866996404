import { ChangeEvent, useCallback, useEffect, type FC, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { ReactSortable } from 'react-sortablejs';
import cloneDeep from 'lodash/cloneDeep';

// Custom Core Components
import InputBase from 'core/components/base/inputs/InputBase';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import CheckboxWithLabel from 'core/components/shared/Checkbox/CheckboxWithLabel';

// Custom Feature Componenst
import FormInputWrapper from 'features/form/forms/components/details/item/Input/shared/Wrapper';
import ConfigNumberInput from 'features/form/forms/components/details/item/Input/shared/Common/ConfigNumberInput';
import FormInputOption from 'features/form/forms/components/details/item/Input/shared/Option';
import FormNewInputOption from 'features/form/forms/components/details/item/Input/shared/NewOption';

// Custom Utilities
import { useAppSelector } from 'core/hooks/redux';
import { genFormInputOption } from 'features/form/forms/utilities/input/initial/option';
import {
  setFormActionMode,
  setFormCurrentSectionId,
} from 'features/form/forms/store/actions';

// Custom Types
import type { RoundPaperProps } from 'core/components/shared/Paper/RoundPaper';
import type { FormSelectInputProps } from 'features/form/forms/types/item/input/select';
import type { FormProps } from 'features/form/forms/types/item';
import type { FormInputWrapperProps } from 'features/form/forms/components/details/item/Input/shared/Wrapper';
import type { FormInputOptionProps } from 'features/form/forms/types/item/input/option';
interface FormSelectInputComponentProps
  extends RoundPaperProps,
    FormInputWrapperProps {
  input: FormSelectInputProps;
}

const FormSelectInput: FC<FormSelectInputComponentProps> = (props) => {
  // Props
  const { input, sx, disabled, onUpdate, ...otherProps } = props;

  // Hooks
  const [sortStatus, setSortStaus] = useState<0 | 1>(1);
  const [sorted, setSorted] = useState<FormInputOptionProps[]>([]);
  const [lenghtIsInitialized, setLengthIsInitialized] =
    useState<boolean>(false);
  const [length, setLength] = useState<number>(0);
  const currentSectionId = useAppSelector(
    (state) => state.form.currentSectionId
  );
  const actionMode = useAppSelector((state) => state.form.actionMode);

  const { control, getValues, setValue, watch } = useFormContext<FormProps>();
  const index = getValues('data.inputsIndexMap')[input.id];

  const hasFieldCondition = watch(
    `data.inputs.${index}.data.hasFieldCondition`
  );

  const hasSectionCondition = watch(
    `data.inputs.${index}.data.hasSectionCondition`
  );

  const isInputInCurrentSection = currentSectionId === input.data.sectionId;

  const { fields, remove, append } = useFieldArray({
    control,
    name: `data.inputs.${index}.data.options`,
  });

  useEffect(() => {
    if (lenghtIsInitialized) {
      focusOnOption();
    }
  }, [length, lenghtIsInitialized]);

  const handleSort = useCallback(() => {
    if (sortStatus === 0) {
      const clonedOpts = cloneDeep(sorted);

      clonedOpts.forEach((opt, index) => (opt.data.sortIndex = index));

      setFormActionMode('INPUT_SORT');
      setFormCurrentSectionId(input.data.sectionId);
      setValue(`data.inputs.${index}.data.options`, clonedOpts);
    }
  }, [sortStatus]);

  useEffect(() => {
    handleSort();
  }, [handleSort]);

  const focusOnOption = () => {
    if (
      isInputInCurrentSection &&
      ['INPUT', 'INITIAL'].includes(actionMode) &&
      fields.length > 0
    ) {
      const optId = fields[fields.length - 1].id;

      const idToFocus = `input-option-${optId}`;
      const element = document.getElementById(idToFocus);

      if (element) element.focus();
    }
  };

  const handleAddOption = (title: string) => {
    let sortIndex = 0;

    if (fields.length > 0) {
      const lastField = fields[fields.length - 1];

      if (lastField && lastField.data !== undefined) {
        const lastIndex = lastField.data.sortIndex;
        sortIndex = lastIndex + 1;
      }
    }

    const optionToAdd = genFormInputOption(sortIndex);

    optionToAdd.data.title = title;

    setLength(fields.length + 1);
    setLengthIsInitialized(true);
    append(optionToAdd);
  };

  const FormTextInputConfig = (
    <ColumnStack spacing={1.5}>
      <RowStack>
        <RowStack spacing={0.5}>
          <InputLabel>کمترین انتخاب</InputLabel>
          <Controller
            control={control}
            name={`data.inputs.${index}.data.minSelectionCount`}
            rules={{
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                const value = parseInt(e.target.value);

                if (value < 1) {
                  setValue(`data.inputs.${index}.data.minSelectionCount`, 1);
                }

                if (onUpdate) onUpdate();
              },
            }}
            render={({ field }) => (
              <ConfigNumberInput
                disabled={disabled}
                sx={{ width: '2rem' }}
                {...field}
              />
            )}
          />
        </RowStack>
        <RowStack spacing={0.5}>
          <InputLabel>بیشترین انتخاب</InputLabel>
          <Controller
            control={control}
            name={`data.inputs.${index}.data.maxSelectionCount`}
            rules={{
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                const value = parseInt(e.target.value);
                const min =
                  getValues(`data.inputs.${index}.data.minSelectionCount`) || 0;

                if (value < min) {
                  setValue(`data.inputs.${index}.data.maxSelectionCount`, min);
                } else if (value < 0) {
                  setValue(`data.inputs.${index}.data.maxSelectionCount`, 1);
                }

                if (onUpdate) onUpdate();
              },
            }}
            render={({ field }) => (
              <ConfigNumberInput
                disabled={disabled}
                sx={{ width: '2rem' }}
                {...field}
              />
            )}
          />
        </RowStack>
      </RowStack>
      <Controller
        control={control}
        name={`data.inputs.${index}.data.hasFieldCondition`}
        defaultValue={false}
        render={({ field }) => (
          <CheckboxWithLabel
            checkboxProps={{
              disabled,
              checked: field.value,
              onChange: (e, checked) => {
                setValue(field.name, checked);
                if (onUpdate) onUpdate();
              },
            }}
            label='تغییر فیلد پنهان'
          />
        )}
      />
      <Controller
        control={control}
        name={`data.inputs.${index}.data.hasSectionCondition`}
        defaultValue={false}
        render={({ field }) => (
          <CheckboxWithLabel
            checkboxProps={{
              disabled,
              checked: field.value,
              onChange: (e, checked) => {
                setValue(field.name, checked);
                if (onUpdate) onUpdate();
              },
            }}
            label='تغییر بخش پنهان'
          />
        )}
      />
    </ColumnStack>
  );

  return (
    <FormInputWrapper
      id={input.id}
      type='select'
      sx={{ minHeight: '16rem', ...sx }}
      index={index}
      config={FormTextInputConfig}
      disabled={disabled}
      onUpdate={onUpdate}
      {...otherProps}
    >
      <Controller
        control={control}
        name={`data.inputs.${index}.data.placeholder`}
        defaultValue=''
        rules={{ onChange: onUpdate }}
        render={({ field }) => (
          <InputBase
            disabled={disabled}
            placeholder='متن راهنما...'
            fullWidth
            sx={{ fontSize: '0.875rem' }}
            {...field}
          />
        )}
      />
      <ReactSortable
        animation={200}
        list={fields}
        setList={(newList) => {
          setSorted(newList);
        }}
        onStart={() => setSortStaus(1)}
        onEnd={() => setSortStaus(0)}
        handle='.input-option_sort_handle'
      >
        {fields.map((opt, optIndex) => (
          <FormInputOption
            disabled={disabled}
            key={opt.id}
            parentInputId={input.id}
            option={opt}
            deletable={fields.length > 1}
            inputIndex={index}
            inputSectionId={input.data.sectionId}
            optionIndex={optIndex}
            hasFieldCondition={hasFieldCondition}
            hasSectionCondition={hasSectionCondition}
            onRemove={() => remove(optIndex)}
            onUpdate={onUpdate}
            sx={{ mb: optIndex < fields.length - 1 ? '0.25rem' : undefined }}
          />
        ))}
      </ReactSortable>
      <FormNewInputOption
        disabled={disabled}
        onUpdate={onUpdate}
        onChange={handleAddOption}
      />
    </FormInputWrapper>
  );
};

export default FormSelectInput;

import * as React from 'react';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyOne from 'core/components/shared/Typography/BodyOne';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';

// Custom Utilities
import { pagesAddress } from 'features/audience/settings/utilities/data';

// Custom Types
interface PageAddressesProps {}

const PageAddresses: React.FC<PageAddressesProps> = (props) => {
  // Render
  return (
    <ColumnStack>
      <HeadingSix sx={{ marginBottom: '1rem' }}>
        آدرس صفحات داینامیک در دسترس هر مخاطب
      </HeadingSix>
      {pagesAddress.map((address) => (
        <RowStack
          key={`page-address-${address}`}
          sx={{
            justifyContent: 'space-between',
            paddingX: '2rem',
            maxWidth: '50rem',
          }}
        >
          <BodyOne color={address.disabled ? 'text.disabled' : undefined}>
            {address.label}
          </BodyOne>
          <BodyOne
            color={address.disabled ? 'text.disabled' : undefined}
            dir='ltr'
          >
            /{address.link}
          </BodyOne>
        </RowStack>
      ))}
    </ColumnStack>
  );
};

export default PageAddresses;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface ClockFilledIconProps extends SvgIconProps {}

const ClockFilledIcon: React.FC<ClockFilledIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M12.17,2.09a10,10,0,1,0,10,10A10,10,0,0,0,12.17,2.09Zm4.61,11.54H12.17a.76.76,0,0,1-.55-.23.77.77,0,0,1-.22-.54V5.93a.77.77,0,0,1,.22-.54.81.81,0,0,1,.55-.23.79.79,0,0,1,.77.77v6.16h3.84a.75.75,0,0,1,.77.77.77.77,0,0,1-.22.54A.76.76,0,0,1,16.78,13.63Z' />
  </IconBuilder>
);

export default ClockFilledIcon;

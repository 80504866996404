import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface ExpandLessIconProps extends SvgIconProps {}

const ExpandLessIcon: React.FC<ExpandLessIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='m12 8-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z' />
  </IconBuilder>
);

export default ExpandLessIcon;

// Custom Utilities
import { generateObjectId } from 'core/utilities/helper/id';
import { getInitialCommonInputProps } from 'features/form/forms/utilities/input/initial/common';

// Custom Types
import type { FormInputInitialParams } from 'features/form/forms/utilities/input/initial/common';
import type { FormAgreementInputProps } from 'features/form/forms/types/item/input/agreement';

interface Params extends FormInputInitialParams {}

export const genFormAgreementInput = (
  params: Params
): FormAgreementInputProps => {
  const commonProps = getInitialCommonInputProps({
    ...params,
  });

  return {
    id: `draft-${generateObjectId()}`,
    data: {
      ...commonProps,
      description: '',
      checkboxLabel: 'موافقم',
      type: 'agreement',
    },
  };
};

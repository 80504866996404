import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface TicketIconProps extends SvgIconProps {}
const TicketIcon: React.FC<TicketIconProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <path d='M9,11h9v2H9v-2Zm9-4H6v2h12v-2Zm4-3V22l-4-4H4c-.53,0-1.04-.21-1.41-.59-.38-.38-.59-.88-.59-1.41V4c0-1.1,.9-2,2-2H20c.53,0,1.04,.21,1.41,.59,.38,.38,.59,.88,.59,1.41Zm-2,0H4v12h14.83l1.17,1.17V4Z' />
    </IconBuilder>
  );
};

export default TicketIcon;

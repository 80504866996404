import { useCallback, type FC } from 'react';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';

// Custom Core Components
import Box from 'core/components/base/layout/Box';

// Custom Common Components
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import RowStack from 'core/components/shared/Stack/RowStack';
import StylesIconButton from 'core/components/shared/IconButton/Styles';

// Custom Feature Components
import AddStepButton from 'features/form/forms/components/details/item/Steps/AddStepButton';
import StepButton from 'features/form/forms/components/details/item/Steps/StepButton';
import StepActions from 'features/form/forms/components/details/item/Steps/Actions';
import DeleteIconButton from 'features/form/shared/components/iconButton/Delete';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useDialogContext } from 'core/store/context/DialogProvider';

// Custom Utilities
import { findIndexById } from 'core/utilities/helper';
import { getNextFormStep } from 'features/form/forms/utilities/step';
import { getInputsIndexMap } from 'features/form/forms/utilities/input/helper';
import {
  setFormActionMode,
  setFormCurrentStep,
  updateFormSteps,
} from 'features/form/forms/store/actions';

// Custom Styles
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';

// Custom Types
import type { FormProps } from 'features/form/forms/types/item';
import {
  useDeleteFormStepMutation,
  useEditFormSteps,
} from 'features/form/forms/hooks';

interface FormStepItemProps {}

const FormItemSteps: FC<FormStepItemProps> = () => {
  // Context
  const loading = useSelectLoading();
  const formSteps = useAppSelector((state) => state.form.data?.steps || []);
  const formId = useAppSelector((state) => state.form.formId);
  const currentStep = useAppSelector((state) => state.form.currentStep);
  const actionMode = useAppSelector((state) => state.form.actionMode);
  const { control, setValue, handleSubmit } = useFormContext<FormProps>();

  // Hooks
  const { onOpenDialog, onCloseDialog } = useDialogContext();
  const { mutate: handleEditFormSteps } = useEditFormSteps({
    noRevalidate: true,
    autoAlert: { mode: 'update', name: 'مراحل' },
    onSuccess: ({ docs }) => {
      if (!docs) return;
      setFormActionMode('INITIAL');
      updateFormSteps(docs);
      setValue('data.steps', docs);
    },
  });

  const { mutate: handleDeleteFormStep } = useDeleteFormStepMutation({
    noRevalidate: true,
    autoAlert: { mode: 'delete', name: 'مرحله' },
    onSuccess: ({ inputs, sections }, { activeStepId: nextActiveStepId }) => {
      onCloseDialog();
      const steps = cloneDeep(formSteps);

      const nextIdx = findIndexById(steps, nextActiveStepId);
      const stepIdxToRemove = findIndexById(steps, currentStep.id);

      if (stepIdxToRemove > -1) steps.splice(stepIdxToRemove, 1);

      updateFormSteps(steps);
      setFormCurrentStep({
        id: nextActiveStepId,
        index: nextIdx > -1 ? nextIdx : 0,
        inputs,
        sections,
      });

      const inputsIndexMap = getInputsIndexMap(inputs);

      setValue('data.steps', steps);
      setValue('data.sections', sections);
      setValue('data.inputs', inputs);
      setValue('data.inputsIndexMap', inputsIndexMap);
    },
  });

  // Utilties
  const handleOpenDialog = () => {
    onOpenDialog({
      onSubmit: handleRemoveStep,
      description: 'با حذف این مرحله، تمامی بخش‌ها و فیلدهای آن حذف می‌شوند.',
    });
  };

  const handleCancel = () => {
    setFormActionMode('INITIAL');
    if (formSteps) setValue('data.steps', formSteps);
  };

  const handleFormSubmit: SubmitHandler<FormProps> = async (form) => {
    handleEditFormSteps({ id: formId, data: form.data.steps });
  };

  const handleRemoveStep = useCallback(async () => {
    if (formSteps) {
      const nextActiveStepId = getNextFormStep(formSteps, currentStep.id).id;
      handleDeleteFormStep({
        stepId: currentStep.id,
        activeStepId: nextActiveStepId,
      });
    }
  }, [formSteps, currentStep.id]);

  // Render
  return (
    <RoundPaper
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '80px',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 3,
          overflowY: 'hidden',
          flexGrow: 1,
          py: '0.5rem',
          ...customRoundedScrollbarStyles,
        }}
      >
        <Controller
          control={control}
          name='data.steps'
          render={({ field }) => {
            if (field.value) {
              if (actionMode === 'STEP')
                return (
                  <>
                    {field.value.map((step, index) => (
                      <StepButton index={index} key={step.id} step={step} />
                    ))}
                  </>
                );
              else if (field.value.length > 1)
                return (
                  <>
                    {field.value.map((step, index) => (
                      <StepButton
                        index={index}
                        disabled={loading || actionMode !== 'INITIAL'}
                        key={step.id}
                        step={step}
                      />
                    ))}
                  </>
                );
            }

            return <></>;
          }}
        />
        {actionMode !== 'STEP' && (
          <AddStepButton disabled={loading || actionMode !== 'INITIAL'} />
        )}
      </Box>
      {actionMode === 'STEP' ? (
        <StepActions
          onCancel={handleCancel}
          onSubmit={handleSubmit(handleFormSubmit)}
        />
      ) : (
        <RowStack spacing={0.5}>
          {formSteps && formSteps.length > 1 && (
            <DeleteIconButton
              onClick={handleOpenDialog}
              disabled={loading || actionMode !== 'INITIAL'}
            />
          )}
          <StylesIconButton
            disabled={loading || actionMode !== 'INITIAL'}
            onClick={() => setFormActionMode('STEP')}
          />
        </RowStack>
      )}
    </RoundPaper>
  );
};

export default FormItemSteps;

import { useState, useCallback } from 'react';

// Custom Types
/**
 * @interface UseSelectionResponse
 * @property {string[]} selections - The current selections made.
 * @property {(selection: string[] | string) => void} onSelectionsChange - Function to update selections.
 * @property {(dataList: Record<'id', string>[]) => void} onToggleAll - Function to toggle all selections.
 * @property {() => void} onClearSelections - Function to clear all selections.
 * @property {(id: string) => boolean} isSelected - Function to check if a specific id is selected.
 */
export interface UseSelectionResponse {
  selections: string[];
  /**
   * Handles changes to the selection. Can add or remove a single selection
   * or replace the entire selection with a new array of selections.
   *
   * @param {string[] | string} newSelection - The new selection(s) to be applied.
   */
  onSelectionsChange: (selection: string[] | string) => void;
  /**
   * Toggles all selections based on the provided data list.
   * If all items are selected, it clears the selection; otherwise, it selects all.
   *
   * @param {Record<'id', string>[]} dataList - The list of data items to toggle.
   */
  onToggleAll: (dataList: Record<'id', string>[]) => void;
  /**
   * Clears selections.
   */
  onClearSelections: () => void;
  /**
   * Checks if a specific id is currently selected.
   *
   * @param {string} id - The id to check for selection.
   * @returns {boolean} True if the id is selected, otherwise false.
   */
  isSelected: (id: string) => boolean;
  /**
   * Checks if all items in the provided data list are currently selected.
   *
   * @param {Record<'id', string>[]} [dataList=[]] - The list of data items to check against the selections.
   * @returns {boolean} True if all items are selected, otherwise false.
   */
  isAllSelected: (datalist: Record<'id', string>[]) => boolean;
  /**
   * Checks if the selection is in an indeterminate state.
   * This means some but not all items are selected.
   *
   * @param {Record<'id', string>[]} [dataList=[]] - The list of data items to check against the selections.
   * @returns {boolean} True if the selection is indeterminate, otherwise false.
   */
  isIndeterminate: (datalist: Record<'id', string>[]) => boolean;
}

/**
 * A custom hook that manages a selection state.
 *
 * @returns {UseSelectionResponse} An object containing the selection state and functions to manipulate it.
 */
function useSelection(): UseSelectionResponse {
  // Hooks
  const [selections, setSelection] = useState<string[]>([]);

  // Utilities
  const handleSelectionChange = useCallback(
    (newSelection: string[] | string) => {
      setSelection((selections) => {
        if (typeof newSelection === 'string') {
          const clonedSelection = [...selections];
          const index = clonedSelection.findIndex(
            (item) => item === newSelection
          );
          if (index > -1) {
            clonedSelection.splice(index, 1);
          } else {
            clonedSelection.push(newSelection);
          }
          return clonedSelection;
        } else {
          return newSelection;
        }
      });
    },
    []
  );

  const handleToggleAll = useCallback((dataList: Record<'id', string>[]) => {
    setSelection((selections) => {
      if (selections.length === dataList.length) return [];
      else return dataList.map((d) => d.id);
    });
  }, []);

  const handleClearSelections = useCallback(() => {
    setSelection([]);
  }, []);

  const isSelected = useCallback(
    (id: string) => selections.includes(id),
    [selections]
  );

  const isAllSelected = useCallback(
    (dataList: Record<'id', string>[] = []) =>
      dataList.length === 0 ? false : dataList.length === selections.length,
    [selections]
  );

  const isIndeterminate = useCallback(
    (dataList: Record<'id', string>[] = []) =>
      dataList.length > 0 && selections.length > 0 && !isAllSelected(dataList),
    [selections]
  );

  // Return
  return {
    selections,
    onClearSelections: handleClearSelections,
    onSelectionsChange: handleSelectionChange,
    onToggleAll: handleToggleAll,
    isSelected,
    isAllSelected,
    isIndeterminate,
  };
}

export default useSelection;

import { memo, type FC } from 'react';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import MenuItem from 'core/components/base/navigation/MenuItem';

// Cusotm Common Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import Select from 'core/components/shared/Select';

// Custom Icon Components
import AddLayerTwoIcon from 'core/components/icons/AddLayerTwo';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';

// Custom Types
export interface FormSectionRepeatCountProps {
  value?: number;
  onChange?: (value: number) => void;
}

const FormSectionRepeatCount: FC<FormSectionRepeatCountProps> = (props) => {
  // Props
  const { value = 0, onChange } = props;

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: 1,
        backgroundColor: 'white',
        borderRadius: '0.25rem',
        p: '0.25rem',
        color: 'text.secondary',
      }}
    >
      <AddLayerTwoIcon color='inherit' />
      <BodyOne whiteSpace='nowrap' color='inherit'>
        تکرار
      </BodyOne>
      <Select value={value} sx={{ height: '2rem', width: '4rem' }}>
        {[0, 1, 2, 3].map((value, index) => (
          <MenuItem
            key={`section-column-count-${value}-idx-${index}`}
            value={value}
            onClick={() => onChange && onChange(value)}
          >
            {value.toPersian()}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default memo(FormSectionRepeatCount, isSame);

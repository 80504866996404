import { useCallback, useEffect, useState } from 'react';

// Types
import type { FC } from 'react';

// Custom Common Components
import SkinTitle from 'features/appBuilder/skins/components/list/Title';
import DataGrid from 'core/components/shared/DataGrid';
import BodyOne from 'core/components/shared/Typography/BodyOne';

// Custom Core Components
import Box from 'core/components/base/layout/Box';

// Custom Utilities
import { getFeatureLabel } from 'core/utilities/feature';

// Types
import type { TemplateSkinProps } from 'features/appBuilder/skins/types/skin';
import type { DataGridProps } from 'core/components/shared/DataGrid';
import type {
  DataGridColumn,
  DataGridRowValueProps,
} from 'core/components/shared/DataGrid/dataGrid';

export interface SkinDataGridProps
  extends Omit<DataGridProps, 'columns' | 'rows'> {
  skinList: TemplateSkinProps[];
  onDelete: (skinId: string) => any;
  onUpdate: (skinId: string, title: string) => any;
}

const getColumns = (
  onDelete: (skinId: string) => any,
  onUpdate: (skinId: string, title: string) => any
): DataGridColumn[] => [
  {
    field: 'col1',
    headerTitle: 'عنوان',
    width: 250,
    renderCell: (params) => (
      <SkinTitle
        title={params.row.col1}
        onDelete={() => onDelete(params.row.id)}
        onUpdate={(title) => onUpdate(params.row.id, title)}
      />
    ),
  },
  {
    field: 'col2',
    headerTitle: 'تمپلیت‌ها',
    renderCell: (params) =>
      Array.isArray(params.row.col2) && params.row.col2.length !== 0 ? (
        <Box sx={{ display: 'flex', gap: 0.5 }}>
          {params.row.col2.map((f) => (
            <BodyOne
              key={`skin-feature-${f}`}
              sx={{
                backgroundColor: 'background.default',
                padding: '0.5rem',
                borderRadius: '0.25rem',
              }}
            >
              {getFeatureLabel(f)}
            </BodyOne>
          ))}
        </Box>
      ) : (
        <BodyOne>-</BodyOne>
      ),
  },
];

const SkinDataGrid: FC<SkinDataGridProps> = (props) => {
  // Props
  const { skinList, onDelete, onUpdate, ...otherProps } = props;

  // States
  const [rows, setRows] = useState<DataGridRowValueProps[]>([]);

  // Hooks
  const generateRows = useCallback(() => {
    const rowsToSet: DataGridRowValueProps[] = [];

    skinList.forEach(({ id, data }) => {
      const row = {
        id,
        col1: data.title,
        col2: data.features,
      };
      rowsToSet.push(row);
    });

    setRows(rowsToSet);
  }, [skinList]);

  useEffect(() => {
    generateRows();
  }, [skinList, generateRows]);

  return (
    <DataGrid
      selectable={false}
      headProps={{
        sx: { backgroundColor: '#D8E8F1' },
      }}
      columns={getColumns(onDelete, onUpdate)}
      rows={rows}
      {...otherProps}
    />
  );
};

export default SkinDataGrid;

// Custom Utilities
import { generateObjectId } from 'core/utilities/helper/id';
import { getPatternTitle } from 'features/appBuilder/patterns/core/utilities/helper/type';

// Custom Types
import type { PatternContainerProps } from 'features/appBuilder/patterns/types/container';
import type { PatternType } from 'features/appBuilder/patterns/core/types/item';

export const transformPatternContainers = (
  apiContainers: { type: PatternType; itemsCount: number }[]
): PatternContainerProps[] => {
  const conatiners: PatternContainerProps[] = [];

  apiContainers.forEach((c) =>
    conatiners.push({
      id: generateObjectId(),
      data: {
        type: c.type,
        itemsCount: c.itemsCount,
        title: getPatternTitle(c.type),
      },
    })
  );

  return conatiners;
};

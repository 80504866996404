import * as React from 'react';
import { useLocation } from 'react-router-dom';

// Custom Common Components
import RoundPaper from '../Paper/RoundPaper';
import ListItemButtonLink from '../List/ListItemButtonLink';

// Custom Core Components
import ListItemButton from 'core/components/base/display/ListItemButton';
import List from 'core/components/base/display/List';
import ListItem from 'core/components/base/display/ListItem';
import ListItemIcon from 'core/components/base/display/ListItemIcon';
import ListItemText from 'core/components/base/display/ListItemText';
import IconButton from 'core/components/base/inputs/IconButton';
import Collapse from 'core/components/base/utils/Collapse';
import Menu from 'core/components/base/navigation/Menu';

// Custom Icon Components
import DashboardIcon from 'core/components/icons/Dashboard';
import KeyboardDoubleArrowRightIcon from 'core/components/icons/KeyboardDoubleArrowRight';

// Custom Utilities
import { getFeatureIcon, getFeatureLabel } from 'core/utilities/feature';
import { getSubFeatureRoutes } from 'core/utilities/feature/helper/routeMap';
import { onSidebarSectionOpen } from 'core/store/slices/core/shared/sidebar';
import { useAppDispatch, useAppSelector } from 'core/hooks/redux';
import { getPermissionCookies } from 'core/utilities/permissions';

// Custom Types
import type { FeatureType } from 'core/types/feature/FeatureType';
import type {
  SidebarProps,
  SidebarSectionProps,
} from 'core/types/shared/sidebar';
import type { ProtectedRouteProps } from 'core/types/shared/routes';
export interface SidebarComponentProps {}

const Sidebar: React.FC<SidebarComponentProps> = () => {
  // States
  const [sidebarListData, setSidebarListData] = React.useState<SidebarProps>({
    title: 'پیشخوان',
    sections: [],
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Hooks
  const dispatch = useAppDispatch();
  const { collapsed, feature } = useAppSelector((state) => state.sidebar);
  const pathname = useLocation().pathname;
  const permissions = getPermissionCookies();

  const configSidebar = React.useCallback(() => {
    if (permissions.length > 0 && sidebarListData.sections.length === 0) {
      const sections: SidebarSectionProps[] = [];

      permissions.forEach((permission) => {
        const { feature, subFeatures } = permission;
        const routes: ProtectedRouteProps[] = getSubFeatureRoutes(subFeatures);

        const sectionToPush: SidebarSectionProps = {
          feature: feature,
          disabled: false,
          routes,
        };

        sections.push(sectionToPush);
      });

      setSidebarListData((prev) => ({ ...prev, sections }));
    }
  }, [permissions, sidebarListData]);

  React.useEffect(() => {
    configSidebar();
  }, [configSidebar]);

  // Utilities
  const isCurrentRoute = (feature: FeatureType) =>
    pathname.includes(feature) ? true : false;

  const handleClick =
    (feature: FeatureType) => (event: React.MouseEvent<HTMLButtonElement>) => {
      dispatch(onSidebarSectionOpen({ feature, keepOpen: true }));
      setAnchorEl(event.currentTarget);
    };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <RoundPaper
      sx={{
        padding: '0.25rem',
        width: collapsed ? '4rem' : '12rem',
        display: 'flex',
        justifyContent: 'center',
        transition: '300ms width ease-in-out',
        overflow: 'hidden',
      }}
    >
      <List sx={{ margin: 0, flexGrow: 1 }}>
        <ListItem>
          {!collapsed && (
            <>
              <ListItemIcon>
                <DashboardIcon sx={{ color: 'text.secondary' }} />
              </ListItemIcon>
              <ListItemText sx={{ color: 'text.secondary' }}>
                {sidebarListData.title}
              </ListItemText>
            </>
          )}
          <IconButton
            onClick={() => dispatch({ type: '/sidebar/toggle' })}
            sx={{ width: '3rem', height: '3rem' }}
          >
            <KeyboardDoubleArrowRightIcon
              sx={{
                color: 'text.secondary',
                transform: collapsed ? 'rotate(-180deg)' : undefined,
              }}
            />
          </IconButton>
        </ListItem>
        {sidebarListData.sections.map((sidebarSection, index) =>
          !collapsed ? (
            <React.Fragment key={sidebarSection.feature + index}>
              <ListItemButton
                onClick={() =>
                  dispatch(
                    onSidebarSectionOpen({ feature: sidebarSection.feature })
                  )
                }
                sx={{
                  color: isCurrentRoute(sidebarSection.feature)
                    ? 'info.main'
                    : 'text.secondary',
                }}
              >
                <ListItemIcon
                  sx={{
                    color: isCurrentRoute(sidebarSection.feature)
                      ? 'info.main'
                      : 'text.secondary',
                  }}
                >
                  {getFeatureIcon(sidebarSection.feature)}
                </ListItemIcon>
                <ListItemText>
                  {getFeatureLabel(sidebarSection.feature)}
                </ListItemText>
              </ListItemButton>
              <Collapse
                in={sidebarSection.feature === feature}
                sx={{ color: 'text.secondary' }}
              >
                {sidebarSection.routes
                  .filter((route) => route.sidebarTitle)
                  .map((route) => (
                    <ListItemButtonLink
                      href={route.path}
                      key={route.subFeature + route.path}
                      sx={{ color: 'text.secondary' }}
                    >
                      <ListItemText sx={{ marginLeft: '1rem' }}>
                        {route.sidebarTitle || '-'}
                      </ListItemText>
                    </ListItemButtonLink>
                  ))}
              </Collapse>
            </React.Fragment>
          ) : (
            <React.Fragment key={sidebarSection.feature + index}>
              <ListItem sx={{ height: '3rem' }}>
                <IconButton
                  id='sidebar-button'
                  aria-controls={open ? 'sidebar-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick(sidebarSection.feature)}
                  sx={{
                    color:
                      sidebarSection.feature === feature
                        ? 'info.main'
                        : 'text.secondary',
                  }}
                >
                  {getFeatureIcon(sidebarSection.feature)}
                </IconButton>
              </ListItem>
              {feature === sidebarSection.feature && (
                <Menu
                  id='sidebar-menu'
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'sidebar-button',
                  }}
                  PaperProps={{
                    sx: {
                      borderRadius: '1rem',
                      borderTopLeftRadius: 0,
                      padding: '0.5rem',
                      boxShadow: 2,
                    },
                  }}
                >
                  {sidebarSection.routes
                    .filter((route) => route.sidebarTitle)
                    .map((route) => (
                      <ListItemButtonLink
                        href={route.path}
                        key={route.subFeature + route.path}
                        sx={{ color: 'text.secondary' }}
                      >
                        <ListItemText>{route.sidebarTitle || '-'}</ListItemText>
                      </ListItemButtonLink>
                    ))}
                </Menu>
              )}
            </React.Fragment>
          )
        )}
      </List>
    </RoundPaper>
  );
};

export default Sidebar;

import { type FC } from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Core Components
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface AgreementIconProps extends SvgIconProps {}

const AgreementIcon: FC<AgreementIconProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <path
        style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
        d='M4,4.7C4,3.2,5.2,2,6.7,2h10.7C18.8,2,20,3.2,20,4.7v14.7c0,1.5-1.2,2.7-2.7,2.7H6.7C5.2,22,4,20.8,4,19.3V4.7z
	 M14.9,7.1c0.2-0.3,0.2-0.7-0.1-0.9C14.5,5.9,14,6,13.8,6.3l-2.6,3.4l-1-1.2C9.9,8.1,9.5,8.1,9.2,8.3C9,8.5,8.9,9,9.2,9.3l1.6,1.8
	c0.1,0.2,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.3L14.9,7.1z M7.3,15c0-0.4,0.3-0.7,0.7-0.7h8c0.4,0,0.7,0.3,0.7,0.7s-0.3,0.7-0.7,0.7
	H8C7.6,15.7,7.3,15.4,7.3,15z M8,17c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h8c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H8
	z'
      />
    </IconBuilder>
  );
};
export default AgreementIcon;

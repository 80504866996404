import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthContextStateProps {
  isAuthenticated: boolean;
}

export const initialState = (): AuthContextStateProps => {
  const localAuthData = localStorage.getItem('Auth');

  return localAuthData ? JSON.parse(localAuthData) : { isAuthenticated: false };
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initializeAuth: (
      state: AuthContextStateProps,
      action: PayloadAction<AuthContextStateProps>
    ) => {
      state = action.payload;
      localStorage.setItem('Auth', JSON.stringify(action.payload));
      return state;
    },
  },
});

export const { initializeAuth } = AuthSlice.actions;
export default AuthSlice.reducer;

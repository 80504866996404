import { type FC } from 'react';

// Custom Common Components
import RoundPaper from 'core/components/shared/Paper/RoundPaper';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Table from 'core/components/base/display/Table';
import TableCell from 'core/components/base/display/TableCell';
import TableRow from 'core/components/base/display/TableRow';

// Custom Utilities
import { prefixFileURL } from 'core/utilities/helper/link';
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';
import { convertNumberToPersian } from 'core/utilities/helper/helperPack';

// Custom Types
import type { TablePatternProps } from 'features/appBuilder/patterns/types';
interface TablePreviewProps {
  preview: TablePatternProps;
}

const TablePatternPreview: FC<TablePreviewProps> = (props) => {
  // Props
  const { preview } = props;

  // Utilities
  const isImage = (value: string): boolean =>
    value.endsWith('.jpg') || value.endsWith('.png');

  const getRefValue = (sheetId: string, keyName: string): string => {
    if (sheetId in preview.data.refs && keyName in preview.data.refs[sheetId]) {
      return convertNumberToPersian(preview.data.refs[sheetId][keyName]) || '';
    }

    return '-';
  };

  return (
    <RoundPaper
      sx={{
        padding: '4rem 3rem',
        overflowX: 'hidden',
      }}
    >
      <Box
        sx={{
          overflowX: 'auto',
          p: '0.25rem',
          pb: '0.5rem',
          ...customRoundedScrollbarStyles,
        }}
      >
        <Table id='printable-section' sx={{ pageBreakAfter: 'auto' }}>
          {preview.data.rows.map((row, rowIndex) => (
            <TableRow key={`table-${preview.id}-row-${rowIndex}`}>
              {Object.entries(row).map(([key, value], colIndex) => (
                <TableCell
                  id='printable-min-width'
                  dir={value.styles.direction}
                  key={`table=${preview.id}-row-${rowIndex}-col-${colIndex}`}
                  colSpan={value.colSpan}
                  sx={{
                    bgcolor: value.styles.bgColor,
                    textAlign: value.styles.align,
                    height: value.styles.height,
                    minWidth: value.styles.width,
                    width: value.styles.width,
                    fontSize: value.styles.size,
                    whiteSpace: 'pre-wrap',
                    color: value.styles.color,
                    border: '1px solid',
                    borderColor: value.styles.borderColor,
                    padding: `${value.styles.paddingTop || 0}px ${
                      value.styles.paddingRight || 0
                    }px ${value.styles.paddingBottom || 0}px ${
                      value.styles.paddingLeft || 0
                    }px `,
                    ':hover': {
                      color: value.styles.hoverColor,
                      bgcolor: value.styles.hoverBgColor,
                      borderColor: value.styles.hoverBorderColor,
                    },
                  }}
                >
                  {value.type === 'file' ? (
                    <img
                      alt=''
                      src={prefixFileURL(value.file.data.url)}
                      width={value.styles.size}
                      style={{ objectFit: 'contain' }}
                    />
                  ) : value.type === 'ref' &&
                    isImage(getRefValue(value.sheetId, value.columnKey)) ? (
                    <img
                      alt=''
                      src={getRefValue(value.sheetId, value.columnKey)}
                      width={value.styles.size}
                      style={{ objectFit: 'contain' }}
                    />
                  ) : value.type === 'ref' ? (
                    getRefValue(value.sheetId, value.columnKey)
                  ) : (
                    value.value?.toPersian()
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </Table>
      </Box>
    </RoundPaper>
  );
};

export default TablePatternPreview;

import * as React from 'react';

// Core Components
import TextField from 'core/components/base/inputs/TextField';

import type { TextFieldProps } from 'core/components/base/inputs/TextField';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TwoFactorTextField: React.FC<TextFieldProps> = React.forwardRef(
  (props, ref) => {
    const { InputProps, ...otherProps } = props;

    return (
      <TextField
        InputProps={{
          inputRef: ref,
          ...InputProps,
        }}
        {...otherProps}
      />
    );
  }
);

export default TwoFactorTextField;

import { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';
import useArray from 'core/hooks/useArray';

// Custom Utilities
import { isSucceed } from 'core/utilities/helper';
import {
  getSearchParamsObject,
  searchParamsToQuery,
} from 'core/utilities/helper/helperPack';
import {
  bakeTypeQuery,
  getBakedLocations,
} from 'features/file/files/utilities/files';

// Custom Types
import type { DeprecatedApiGetDocsResponse } from 'core/types/api/hook/response';
import type { DeprecatedApiGetDocsHandler } from 'core/types/api/hook/handler';
import type { PageProps } from 'core/types/shared/pagination';

export interface UseDocsApiResponse<T> extends DeprecatedApiGetDocsResponse<T> {
  fetching: boolean;
  page: PageProps;
  selections: string[];
  showDeleteDialog: boolean;
  refreshed: boolean;
  initialized: boolean;
  refresh: () => void;
  onPageNumChange: (pageNum: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onPageTotalDocsChange: (totalDocs: number) => void;
  onSelectionChange: (selection: string[]) => void;
  onSelectionRemove: (id: string) => void;
  onSelectionAdd: (id: string) => void;
  onDialogChange: (dialog: boolean) => void;
  onSelectAll: () => void;
  onSelect: (id: string) => void;
  onFilter: (
    query: ParamsProps,
    customSearchParams?: URLSearchParams
  ) => Promise<void>;
}

export interface ParamsProps {
  startDate?: string;
  endDate?: string;
  search?: string;
  [key: string]: any;
}

function useDeprecatedDocsApi<T>(
  apiHandler: DeprecatedApiGetDocsHandler<T>,
  configs?: {
    id?: string | string[];
    shouldUseId?: boolean;
    defaultPageSize?: number;
    prefetch?: boolean;
    apiShouldAcceptIdKeys?: boolean;
    additionalData?: Record<string, any>;
    handlerParams?: string;
  }
): UseDocsApiResponse<T> {
  // States
  const data = useArray<T>();
  const selection = useArray<string>();
  const isMounted = useBoolean();
  const fetching = useBoolean();
  const refreshed = useBoolean(true);
  const initialized = useBoolean();
  const showDeleteDialog = useBoolean();
  const [totalDocs, setTotalDocs] = useState<number>(0);
  const [otherData, setOtherData] = useState<{
    [key: string]: boolean | string | string[];
  }>({});
  const [status, setStatus] = useState<number | null>(null);

  // Hooks
  const [searchParams, setSearchParams] = useSearchParams();
  const routerDomLocation = useLocation();
  const page = parseInt(searchParams.get('page') || '1');
  const limit = parseInt(
    searchParams.get('limit') ||
      `${configs && configs.defaultPageSize ? configs.defaultPageSize : 20}`
  );

  // Utilities
  const current = useMemo(() => page, [page]);
  const size = useMemo(() => limit, [limit]);

  const handlePageNumChange = useCallback(
    (pageNum: number) => {
      if (pageNum !== current) {
        const freshSearchParams = new URLSearchParams(location.search);
        const queries = getSearchParamsObject(freshSearchParams);

        if (pageNum === 1) delete queries.page;
        else queries.page = pageNum.toString();

        setSearchParams(queries);
      }
    },
    [current]
  );

  const handlePageTotalDocsChange = useCallback(
    (newTotalDocs: number) => {
      if (newTotalDocs !== totalDocs) setTotalDocs(totalDocs);
    },
    [totalDocs]
  );

  const handlePageSizeChange = useCallback(
    (pageSize: number) => {
      const freshSearchParams = new URLSearchParams(routerDomLocation.search);
      const queries = getSearchParamsObject(freshSearchParams);
      queries.limit = pageSize.toString();
      queries.page = '1';
      setSearchParams(queries);
    },
    [size]
  );

  const handleDialogChange = useCallback((dialog: boolean) => {
    showDeleteDialog.set(dialog);
  }, []);

  const handleSelectionRemove = (id: string) => {
    const selectionClone = [...selection.state];
    const index = selectionClone.findIndex((selectId) => selectId === id);
    if (index > -1) selectionClone.splice(index, 1);
    selection.set(selectionClone);
  };

  const handleFilter = async (
    queries: ParamsProps = {},
    customSearchParams?: URLSearchParams
  ) => {
    if (customSearchParams) {
      setSearchParams(customSearchParams);
    } else {
      const newSearchParams = new URLSearchParams();

      // Add New Queries
      if (Object.keys(queries.length > 0))
        Object.entries(queries).forEach(([key, value]) => {
          if (
            ![
              'search',
              'startDate',
              'endDate',
              'page',
              'limit',
              'type',
            ].includes(key)
          )
            return;
          if (!value) return;
          if (Array.isArray(value)) {
            const values = value.map((item) => `"${item}"`);
            newSearchParams.set(key, `[${values}]`);
          } else newSearchParams.set(key, value);
        });

      // Set New SearchParam
      setSearchParams(newSearchParams);
    }
  };

  const handleSelectAll = () => {
    if (selection.length > 0) selection.clear();
    else {
      // @ts-ignore
      const ids = data.map((v) => v.id);
      selection.set(ids);
    }
  };

  const handleSelect = (id: string) => {
    if (selection.state.includes(id)) handleSelectionRemove(id);
    else handleSelectionAdd(id);
  };

  const handleSelectionAdd = (id: string) => selection.set((v) => [...v, id]);

  const handleSelectionChange = useCallback(
    (selections: string[]) => selection.set(selections),
    []
  );

  const handleRefresh = () => refreshed.setFalse();

  const getData = useCallback(async () => {
    fetching.setTrue();

    let location: string[] = searchParams.getAll('location') || '[]';
    let type: string[] = searchParams.getAll('type') || '[]';

    if (type.length > 0) bakeTypeQuery(type, searchParams);
    if (location.length > 0)
      searchParams.set('location', `[${getBakedLocations(location)}]`);

    let params =
      searchParamsToQuery(searchParams, true, configs?.apiShouldAcceptIdKeys) ||
      '';

    if (configs?.handlerParams) {
      params += `&${configs.handlerParams}`;
    }

    if (configs && configs.shouldUseId && !configs.id) return;

    const { status, page, list, other } = await apiHandler({
      id: configs && configs.shouldUseId ? configs.id : undefined,
      page: {
        current,
        size,
        totalDocs: 0,
      },
      params,
      additionalData: configs?.additionalData || {},
    });

    if (page) setTotalDocs(page.totalDocs);
    refreshed.setTrue();
    if (status && isSucceed(status) && list) {
      data.set(list);
      if (other) setOtherData(other);

      initialized.setTrue();
    }
    setStatus(status);
    fetching.setFalse();
  }, [searchParams, apiHandler]);

  // Hooks
  // useUnMount(() => cancelSource.cancel());

  useEffect(() => isMounted.setTrue(), [isMounted.state]);

  useEffect(() => {
    const prefetch = configs?.prefetch === undefined ? true : configs?.prefetch;

    if (isMounted.state && prefetch) getData();
  }, [getData, isMounted.state]);

  useEffect(() => {
    if (isMounted.state && !refreshed.state) {
      getData();
    }
  }, [getData, isMounted.state, refreshed.state]);

  // Return
  return {
    initialized: initialized.state,
    status,
    list: data.state,
    other: otherData,
    fetching: fetching.state,
    selections: selection.state,
    refreshed: refreshed.state,
    showDeleteDialog: showDeleteDialog.state,
    page: {
      current,
      size,
      totalDocs: totalDocs,
    },
    refresh: handleRefresh,
    onPageNumChange: handlePageNumChange,
    onPageSizeChange: handlePageSizeChange,
    onPageTotalDocsChange: handlePageTotalDocsChange,
    onSelectAll: handleSelectAll,
    onSelect: handleSelect,
    onSelectionChange: handleSelectionChange,
    onSelectionRemove: handleSelectionRemove,
    onSelectionAdd: handleSelectionAdd,
    onDialogChange: handleDialogChange,
    onFilter: handleFilter,
  };
}
export default useDeprecatedDocsApi;

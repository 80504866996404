// Custom Hooks
import useDocApi from 'core/hooks/api/useDoc';
import useDocsApi from 'core/hooks/api/useDocs';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  addSheet,
  deleteSheet,
  editSheet,
  getSheet,
  getSheets,
} from 'features/data/sheets/utilities/api';

// Custom Types
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';
import type {
  SheetDataProps,
  SheetListItemProps,
  SheetProps,
} from 'features/data/sheets/types';
import type {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';

/**
 * Query key for fetching sheets in react query.
 *
 * @constant {Array<string>}
 */
export const sheetsQueryKey = ['sheets'];

/**
 * Custom hook to fetch sheets.
 *
 * This hook utilizes the `useDocApi` to retrieve sheets list on the provided options.
 *
 * @param {UseDocOptionType<SheetListItemProps>} [options] - Optional configuration options for fetching the sheets.
 * @returns {UseDocApiReturnProps<SheetListItemProps>} The result of the `useDocApi` hook, which includes the fetched sheets
 */
export const useSheetDocs = (
  options?: UseDocsOptionType<SheetListItemProps>
): UseDocsApiReturnProps<SheetListItemProps> =>
  useDocsApi(getSheets, sheetsQueryKey, options);

/**
 * Custom hook to fetch a single sheet document.
 *
 * This hook utilizes the `useDocApi` to retrieve a specific sheet based on the provided options.
 *
 * @param {UseDocOptionType<SheetProps>} [options] - Optional configuration options for fetching the sheet.
 * @returns {UseDocApiReturnProps<SheetProps>} The result of the `useDocApi` hook, which includes the fetched sheet document.
 *
 */
export const useSheetDoc = (
  options?: UseDocOptionType<SheetProps>
): UseDocApiReturnProps<SheetProps> =>
  useDocApi(getSheet, sheetsQueryKey, options);

/**
 * Custom hook to handle the deletion of a sheet document.
 *
 * This hook utilizes the `useDeleteMutation` to perform the delete operation
 * for a specific sheet identified by its ID.
 *
 * @returns {UseDeleteMutationReturn<string>} The result of the `useDeleteMutation` hook,
 * which includes methods and state related to the delete operation.
 */
export const useDeleteSheetMutation = useDeleteMutation<string>(
  deleteSheet,
  sheetsQueryKey
);

/**
 * Custom hook to handle the addition of a new sheet document.
 *
 * This hook utilizes the `useAddMutation` to perform the add operation for a new sheet.
 *
 * @returns {UseAddMutationReturn<SheetDataProps>} The result of the `useAddMutation` hook,
 * which includes methods and state related to the add operation.
 */
export const useAddSheetMutation = useAddMutation<SheetDataProps>(
  addSheet,
  sheetsQueryKey
);

/**
 * Custom hook to handle the editing of an existing sheet document.
 *
 * This hook utilizes the `useEditMutation` to perform the edit operation
 * for a specific sheet identified by its ID.
 *
 * @returns {UseEditMutationReturn<SheetDataProps>} The result of the `useEditMutation` hook,
 * which includes methods and state related to the edit operation.
 */
export const useEditSheetMutation = useEditMutation<SheetDataProps>(
  editSheet,
  sheetsQueryKey
);

// Types
import type { FC } from 'react';

// Common Components
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import PageTitle from 'core/components/shared/Typography/PageTitle';

// Feature Components
import PatternContainersDateGrid from 'features/appBuilder/patterns/components/list/PatternContainerDataGrid';

// Custom Utilities
import { usePatternDocs } from 'features/appBuilder/patterns/hooks';

// Custom Types
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';

interface PatternsPageProps extends WithAuthProps {}

const Patterns: FC<PatternsPageProps> = (props) => {
  // Hooks
  const { data: patterns } = usePatternDocs();

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='پترن‌ها' />
      <PageHeroBox>
        <PageTitle>پترن‌ها</PageTitle>
      </PageHeroBox>
      <PatternContainersDateGrid
        selectable={false}
        containers={patterns.list}
      />
    </>
  );
};

export default Patterns;

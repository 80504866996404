import { useCallback, useState } from 'react';
import {
  findAndDelete,
  findAndDeleteByIndex,
  findAndUpdateById,
  findAndUpdateByIndex,
} from 'core/utilities/helper';

export interface UseArrayReturnProps<T> {
  state: T[];
  isEmpty: boolean;
  length: number;
  clear: () => void;
  push: (item: T) => void;
  pop: () => T | undefined;
  unshift: (item: T) => void;
  shift: () => T | undefined;
  set: React.Dispatch<React.SetStateAction<T[]>>;
  deleteByIndex: (index: number) => void;
  deleteById: (id: string) => void;
  updateByIndex: (index: number, onSuccess: (foundedObj: T) => void) => void;
  updateById: (id: string, onSuccess: (foundedObj: T) => void) => void;
}
/**
 * A React hook that provides a stateful array with utility functions for managing its elements.
 *
 * @param defValue (optional) The initial value of the array. Defaults to an empty array.
 *
 * @returns An object containing the following properties:
 *
 * - **state**: The current state of the array.
 * - **length**: The length of the state array.
 * - **isEmpty**: Returns true if state is empty.
 * - **clear**: A function to clear the state of the array.
 * - **set**: A function to update the state of the array.
 * - **push**: A function to add an element at the end of the array.
 * - **pop**: A function to remove an element at the end of the array.
 * - **shift**: A function to remove an element at the start of an array.
 * - **unshift**: A function to add an element at the start of an array.
 * - **deleteByIndex**: A function to delete an element from the array by its index.
 * - **deleteById**: A function to delete an element from the array by its id.
 * - **updateByIndex**: A function to update an element in the array by its index.
 * - **updateById**: A function to update an element in the array by its id.
 **/
function useArray<T>(defValue: T[] = []): UseArrayReturnProps<T> {
  // States
  const [state, setState] = useState<T[]>(defValue);

  // Utilities
  const handleDeleteByIndex = useCallback((index: number) => {
    setState((prevState) => findAndDeleteByIndex(prevState, index));
  }, []);

  const handleDeleteById = useCallback((id: string) => {
    setState((prevState) => findAndDelete(prevState as any[], id));
  }, []);

  const handleUpdateById = useCallback(
    (id: string, onSuccess: (foundedObj: T) => void) => {
      setState((prevState) =>
        findAndUpdateById(prevState as any[], id, onSuccess)
      );
    },
    []
  );

  const handleUpdateByIndex = useCallback(
    (index: number, onSuccess: (foundedObj: T) => void) => {
      setState((prevState) =>
        findAndUpdateByIndex(prevState as any[], index, onSuccess)
      );
    },
    []
  );

  const handlePush = useCallback((item: T) => {
    setState((v) => [...v, item]);
  }, []);

  const handleUnshift = useCallback((item: T) => {
    setState((v) => [item, ...v]);
  }, []);

  const handleShift = useCallback(() => {
    let deletedItem: T | undefined = undefined as T | undefined;
    setState((value) => {
      const items = [...(value || [])];
      deletedItem = items.shift();
      return items;
    });
    return deletedItem;
  }, []);

  const handlePop = useCallback(() => {
    let deletedItem: T | undefined = undefined as T | undefined;
    setState((value) => {
      const items = [...(value || [])];
      deletedItem = items.pop();
      return items;
    });
    return deletedItem;
  }, []);

  const handleClear = useCallback(() => {
    setState([]);
  }, []);

  // Return
  return {
    state,
    length: Array.isArray(state) ? state.length : 0,
    isEmpty: Array.isArray(state) ? state.length === 0 : true,
    set: setState,
    push: handlePush,
    pop: handlePop,
    unshift: handleUnshift,
    shift: handleShift,
    deleteById: handleDeleteById,
    deleteByIndex: handleDeleteByIndex,
    updateById: handleUpdateById,
    updateByIndex: handleUpdateByIndex,
    clear: handleClear,
  };
}

export default useArray;

import { useState, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import MenuItem from 'core/components/base/navigation/MenuItem';

// Common Components
import Select from 'core/components/shared/Select';

// Custom Types
import type { SelectProps } from 'core/components/shared/Select';
import type { BgImageType } from 'features/appBuilder/patterns/core/types/item/image';
import type { CommonPatternProps } from 'features/appBuilder/patterns/types/shared/common';
export interface PatternBgTypeDropdownProps
  extends Omit<SelectProps, 'defaultValue'> {
  defaultValue?: BgImageType;
  keyName?: string;
  disableTile?: boolean;
}

type SelectOption = { value: BgImageType; label: string };
const options: SelectOption[] = [
  { value: 'cover', label: 'هم‌اندازه' },
  { value: 'contain', label: 'براساس اندازه تصویر' },
  { value: 'tile', label: 'کاشی' },
];

const optionsWithNoTile: SelectOption[] = [
  { value: 'cover', label: 'هم‌اندازه' },
  { value: 'contain', label: 'براساس اندازه تصویر' },
];

const PatternBgTypeDropdown: FC<PatternBgTypeDropdownProps> = (props) => {
  // Props
  const {
    defaultValue = 'contain',
    keyName = 'bgImageType',
    disableTile,
    sx,
    ...otherProps
  } = props;

  // Hooks
  const { control, setValue } = useFormContext<CommonPatternProps>();
  const [opts] = useState<SelectOption[]>(
    disableTile ? optionsWithNoTile : options
  );

  // Render
  return (
    <Controller
      control={control}
      name={keyName}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Select
          sx={{ height: '36px', width: '12rem', ...sx }}
          size='small'
          {...field}
        >
          {opts.map((opt) => (
            <MenuItem
              key={opt.label + opt.value}
              value={opt.value}
              onClick={() => setValue(field.name, opt.value)}
            >
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

export default PatternBgTypeDropdown;

// Feature Types
import type {
  ProcessDataProps,
  ProcessProps,
} from 'features/form/processes/types/details';

/**
 * Transforms an API process object into a standardized process format.
 *
 * @param {Object} apiProcess - The API process object to transform.
 * @param {string} apiProcess.id - The unique identifier for the process.
 * @param {Partial<ProcessDataProps>} apiProcess.data - The data associated with the process, which may be partially defined.
 * @param {string} [apiProcess.data.title] - The title of the process.
 * @param {string} [apiProcess.data.formId] - The ID of the associated form.
 * @param {string} [apiProcess.data.formTitle] - The title of the associated form.
 * @param {string} [apiProcess.data.feedbackId] - The ID of the feedback associated with the process.
 * @param {boolean} [apiProcess.data.isActive] - Indicates whether the process is active.
 * @param {Array} [apiProcess.data.items] - An array of items related to the process.
 * @param {Array} [apiProcess.data.progressStack] - An array representing the progress stack of the process.
 *
 * @returns {ProcessProps} The transformed process object with standardized properties.
 */
export const transformApiProcess = (apiProcess: {
  id: string;
  data: Partial<ProcessDataProps>;
}): ProcessProps => ({
  id: apiProcess.id,
  data: {
    title: apiProcess.data.title || '',
    formId: apiProcess.data.formId || '',
    formTitle: apiProcess.data.formTitle || '',
    feedbackId: apiProcess.data.feedbackId || '',
    isActive: apiProcess.data.isActive || false,
    items: apiProcess.data.items || [],
    progressStack: apiProcess.data.progressStack || [],
  },
});

import * as React from 'react';

// Core Components
import MenuItem from 'core/components/base/navigation/MenuItem';

// Common Components
import SelectWithPlaceholder from 'core/components/shared/Select/SelectWithPlaceholder';

// Custom Utilities
import { audienceOperationsData } from 'features/audience/audiences/utilities/data';

// Custom Types
import type { AudienceOperationType } from 'features/audience/audiences/types';
interface SelectOperationProps {
  value: AudienceOperationType;
  onChange: (value: AudienceOperationType) => void;
  disabled?: boolean;
}

const SelectOperation: React.FC<SelectOperationProps> = (props) => {
  // Props
  const { value, disabled, onChange } = props;

  // Render
  return (
    <SelectWithPlaceholder
      value={value}
      sx={{ width: '186px', height: '2.5rem' }}
      placeholder='انتخاب عملیات'
      onChange={(e) =>
        onChange((e.target.value || '') as AudienceOperationType)
      }
      disabled={disabled}
    >
      {audienceOperationsData.map((opt) => (
        <MenuItem
          key={opt.value}
          value={opt.value}
          disabled={opt?.disabled}
          sx={{
            color: opt.color,
          }}
        >
          {opt.label}
        </MenuItem>
      ))}
    </SelectWithPlaceholder>
  );
};

export default SelectOperation;

import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
  styled,
} from '@mui/material';

export interface CircularProgressProps extends MuiCircularProgressProps {}

const CircularProgress = styled(MuiCircularProgress)<CircularProgressProps>(
  () => ({})
);

export default CircularProgress;

import { useState, useMemo, useCallback, memo, useEffect } from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Type
import type { FC } from 'react';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';
import {
  useSelectIsInputHidden,
  useSelectIsInputFullWidth,
  useSelectFeedbackToggleMode,
} from 'features/form/feedbacks/store';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';

// Custom Icons
import FullWidthRightIcon from 'features/form/feedbacks/components/icons/FullWidthRight';
import FullWidthLeftIcon from 'features/form/feedbacks/components/icons/FullWidthLeft';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';

// Context
import {
  toggleInputWidth,
  toggleInputVisibility,
} from 'features/form/feedbacks/store';

// Custom Types
import type { FeedbackInputProps } from 'features/form/feedbacks/types';

type ToggleActions = 'unhide' | 'fullWidth' | 'hide';

interface ToggleButtonProps {
  input: FeedbackInputProps;
}
const FilterInputModeButton: FC<ToggleButtonProps> = (props) => {
  // Props
  const { input } = props;

  // Context
  const toggleMode = useSelectFeedbackToggleMode();
  const loading = useSelectLoading();
  const isHidden = useSelectIsInputHidden(input.id);
  const isFullWidth = useSelectIsInputFullWidth(input.id);

  // Hooks
  const { palette } = useTheme();
  const title = useMemo(() => input?.data?.label || '', [input?.data?.label]);

  // States
  const [currentAction, setCurrentAction] = useState<ToggleActions>(
    isHidden ? 'hide' : isFullWidth ? 'fullWidth' : 'unhide'
  );

  useEffect(() => {
    setCurrentAction(isHidden ? 'hide' : isFullWidth ? 'fullWidth' : 'unhide');
  }, [isHidden, isFullWidth]);

  // Utilities
  const handleToggle = () => {
    if (toggleMode === 'width') {
      if (isHidden) return;
      setCurrentAction(isFullWidth ? 'unhide' : 'fullWidth');
      toggleInputWidth(input.id);
      return;
    } else {
      // Visibilty Toggle
      setCurrentAction(
        isHidden && isFullWidth ? 'fullWidth' : isHidden ? 'unhide' : 'hide'
      );
      toggleInputVisibility(input.id);
    }
  };

  const commonStyles = useMemo(
    () => ({
      minWidth: 'fit-content',
      maxWidth: 'fit-content',
      fontSize: '.875rem',
      fontWeight: '400',
      color: palette.text.primary,
      height: '2.375rem',
      borderRadius: '.25rem',
      padding: '0.313rem 0.75rem',
    }),
    [loading]
  );

  // Memoized Sections
  const FullWidthButton = useMemo(
    () => (
      <Button
        startIcon={<FullWidthRightIcon color='info' />}
        endIcon={<FullWidthLeftIcon color='info' />}
        onClick={handleToggle}
        disabled={loading}
        sx={{
          ...commonStyles,
          backgroundColor: loading ? palette.text.disabled : '#9ED5FF',
          color: palette.text.primary,
          transition: 'opacity .3s',
          ':hover': {
            backgroundColor: loading ? palette.text.disabled : '#9ED5FF',
            opacity: '.8',
          },
        }}
      >
        {title}
      </Button>
    ),
    [isFullWidth, isHidden, title, toggleMode, palette, loading]
  );
  const HideButton = useMemo(
    () => (
      <Button
        onClick={handleToggle}
        disabled={loading}
        sx={{
          ...commonStyles,
          backgroundColor: loading ? palette.text.disabled : '#EBEBEB',
          textDecoration: 'line-through',
          transition: 'opacity .3s',
          ':hover': {
            textDecoration: 'line-through',
            backgroundColor: loading ? palette.text.disabled : '#EBEBEB',
            opacity: '.8',
          },
        }}
      >
        {title}
      </Button>
    ),
    [isFullWidth, isHidden, title, toggleMode, palette, loading]
  );
  const UnhideButton = useMemo(
    () => (
      <Button
        onClick={handleToggle}
        disabled={loading}
        sx={{
          ...commonStyles,
          backgroundColor: loading ? palette.text.disabled : '#9ED5FF',
          color: palette.text.primary,
          transition: 'opacity .3s',
          ':hover': {
            backgroundColor: loading ? palette.text.disabled : '#9ED5FF',
            opacity: '.8',
          },
        }}
      >
        {title}
      </Button>
    ),
    [isFullWidth, isHidden, title, toggleMode, loading, palette]
  );

  const ButtonRoot = useCallback(() => {
    switch (currentAction) {
      case 'unhide':
        return UnhideButton;
      case 'fullWidth':
        return FullWidthButton;
      case 'hide':
        return HideButton;
      default:
        return UnhideButton;
    }
  }, [currentAction, toggleMode, palette, loading]);

  // Render
  return <ButtonRoot />;
};

export default memo(FilterInputModeButton, isSame);

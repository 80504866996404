import { useCallback, useState, type FC } from 'react';

// Core Components
import Select from 'core/components/shared/Select';
import MenuItem from 'core/components/base/navigation/MenuItem';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Core Hooks
import useMount from 'core/hooks/useMount';
import useUpdateEffect from 'core/hooks/useUpdateEffect';
import { useAppSelector } from 'core/hooks/redux';

// Core Utilities
import { isSucceed, setAppLoading } from 'core/utilities/helper';

// Feature Utilities
import { getMenuSummaryList } from 'features/menu/menus/utilities/api';

// Custom Types
import type { MenuContainerSummaryProps } from 'features/menu/menus/types';

export interface MenuContainerSelectProps {
  defaultValue?: string;
  defaultValueTitle?: string;
  disabled?: boolean;
  onChange?: (opt: MenuContainerSummaryProps) => void;
}

const MenuContainerSelect: FC<MenuContainerSelectProps> = (props) => {
  // Props
  const {
    disabled,
    defaultValue = '',
    defaultValueTitle = '',
    onChange,
  } = props;

  // States
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string>(defaultValue);
  const [valueTitle, setValueTitle] = useState<string>(defaultValueTitle);
  const [opts, setOpts] = useState<MenuContainerSummaryProps[]>([]);

  // Context
  const loading = disabled ?? useAppSelector((state) => state.loading);

  // Hooks
  const setOptions = useCallback(async () => {
    setAppLoading(true);
    const { status, list } = await getMenuSummaryList();

    setAppLoading(false);
    if (status && isSucceed(status) && list && list.length > 0) {
      setOpts(list);

      if (defaultValue && !defaultValueTitle) {
        const valueToSet = list.find((m) => m.id === defaultValue);

        if (valueToSet) {
          setValue(valueToSet.id);
          setValueTitle(valueToSet.data.title);
        }
      }
    }
  }, [defaultValue]);

  useMount(() => {
    if (defaultValue) setOptions();
  });
  useUpdateEffect(() => {
    if (defaultValue) setOptions();
  }, [defaultValue]);

  // Utilities
  const handleOpen = () => {
    if (opts.length === 0) setOptions();

    setOpen(true);
  };

  const handleValueChange = (opt: MenuContainerSummaryProps) => {
    setValue(opt.id);
    setValueTitle(opt.data.title);

    if (onChange) onChange(opt);
  };

  // Render
  return (
    <Select
      disabled={loading}
      open={open}
      onOpen={handleOpen}
      onClose={() => setOpen(false)}
      value={value}
      renderValue={() => <BodyTwo>{valueTitle}</BodyTwo>}
    >
      {opts.length > 0 ? (
        opts.map((opt) => (
          <MenuItem
            key={opt.id}
            value={opt.id}
            onClick={() => handleValueChange(opt)}
          >
            {opt.data.title}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>در حال دریافت...</MenuItem>
      )}
    </Select>
  );
};

export default MenuContainerSelect;

import type { FC } from 'react';

// Custom Core Components
import { type BoxProps } from 'core/components/base/layout/Box';

// Custom Common Components
import BodyOne from 'core/components/shared/Typography/BodyOne';

// Custom Feature Components
import UploaderInputAndPreviewWrapper from 'features/file/files/components/shared/Uploader/Wrapper';

// Icon Components
import FileUploadIcon from 'core/components/icons/FileUpload';

// Context
import { useUploadDialogContext } from 'features/file/files/context/UploadDialog';

// Custom Types
import type {
  DeprecatedFileLocationType,
  FileProps,
  FileType,
} from 'features/file/files/types';

export interface UploaderInputProps extends Omit<BoxProps, 'onChange'> {
  location: DeprecatedFileLocationType;
  limit?: number;
  accept?: FileType[];
  disabled?: boolean;
  hideIcon?: boolean;
  value?: FileProps[];
  Icon?: JSX.Element;
  label?: string;
  onChange?: (files: FileProps[]) => void;
}

const UploaderInput: FC<UploaderInputProps> = (props) => {
  // Props
  const {
    limit = 1,
    accept = [],
    disabled,
    hideIcon,
    location,
    value,
    Icon = <FileUploadIcon />,
    label = 'افزودن',
    width = '6rem',
    height = '5rem',
    onClick,
    onChange,
    ...otherProps
  } = props;

  // Context
  const { setSettings } = useUploadDialogContext();

  // Utilities
  const handleOpenDialog = () => {
    if (disabled) return;
    setSettings({
      open: true,
      accept,
      location,
      selectedFiles: value || [],
      multiple: limit > 1,
      maxSelect: limit,
      onInsertFile: (files) => onChange && onChange(files),
    });
  };

  // Render
  return (
    <UploaderInputAndPreviewWrapper
      disabled={disabled}
      width={width}
      height={height}
      onClick={handleOpenDialog}
      {...otherProps}
    >
      {!hideIcon && Icon}
      <BodyOne>{label}</BodyOne>
    </UploaderInputAndPreviewWrapper>
  );
};

export default UploaderInput;

// Types
import type { FC } from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface PauseFilledIconProps extends SvgIconProps {}

const PauseFilledIcon: FC<PauseFilledIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M15.9,15.2c0,0.4-0.3,0.6-0.6,0.6H8.8
	c-0.4,0-0.6-0.3-0.6-0.6V8.8c0-0.4,0.3-0.6,0.6-0.6h6.5c0.4,0,0.6,0.3,0.6,0.6V15.2z'
    />
  </IconBuilder>
);

export default PauseFilledIcon;

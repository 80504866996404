import type { FC } from 'react';

// Core Components
import Box from 'core/components/base/layout/Box';

// Custom Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import AntSwitch from 'core/components/shared/Switch/AntSwitch';

// Custom Types
import type { BoxProps } from 'core/components/base/layout/Box';
export interface SwitchWithLabelProps extends BoxProps {
  disabled?: boolean;
  checked?: boolean;
  checkedTitle?: string;
  unCheckedTitle?: string;
  onCheckChange?: (checked: boolean) => void;
}

const SwitchWithLabel: FC<SwitchWithLabelProps> = (props) => {
  // Props
  const {
    children,
    sx,
    disabled,
    checked,
    checkedTitle = 'فعال',
    unCheckedTitle = 'غیرفعال',
    onCheckChange,
    ...otherProps
  } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.25rem',
        justifyContent: 'center',
        bgcolor: 'background.paper',
        height: '40px',
        borderRadius: '0.5rem',
        padding: '0.5rem',
        ...sx,
      }}
      {...otherProps}
    >
      <AntSwitch
        disabled={disabled}
        checked={checked}
        onChange={(e, checked) => onCheckChange && onCheckChange(checked)}
      />
      <BodyTwo
        sx={{
          color: checked
            ? 'primary.main'
            : disabled
            ? 'text.disabled'
            : undefined,
        }}
      >
        {checked ? checkedTitle : unCheckedTitle}
      </BodyTwo>
    </Box>
  );
};

export default SwitchWithLabel;

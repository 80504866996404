import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface AgreementIconProps extends SvgIconProps {}

const FourWayArrow: React.FC<AgreementIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M9 8H13V5H16L11 0L6 5H9V8ZM8 9H5V6L0 11L5 16V13H8V9ZM22 11L17 6V9H14V13H17V16L22 11ZM13 14H9V17H6L11 22L16 17H13V14Z'
      fill='#0091FF'
    />
  </IconBuilder>
);

export default FourWayArrow;

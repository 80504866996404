// Custom Hooks
import useDocApi from 'core/hooks/api/useDoc';
import useDocsApi from 'core/hooks/api/useDocs';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  addAudienceGroup,
  deleteAudienceGroups,
  editAudienceGroup,
  getAudienceGroup,
  getAudienceGroups,
} from 'features/audience/groups/utilities/api';

// Custom Types
import type {
  AudienceGroupProps,
  AudienceGroupSummaryProps,
} from 'features/audience/groups/types';
import type {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';

/**
 * Query key for fetching audience groups in react query.
 *
 * @constant {Array<string>}
 */
export const audienceGroupsQueryKey = ['audienceGroups'];

/**
 * Custom hook to fetch audience groups list.
 *
 * This hook utilizes the `useDocsApi` to retrieve a specific audience groups list on the provided options.
 *
 * @param {UseDocsOptionType<AudienceGroupSummaryProps>} [options] - Optional configuration options for fetching the audience groups list.
 * @returns {UseDocsApiReturnProps<AudienceGroupSummaryProps>} The result of the `useDocApi` hook, which includes the fetched audience groups list
 */
export const useAudienceGroupDocs = (
  options?: UseDocsOptionType<AudienceGroupSummaryProps>
): UseDocsApiReturnProps<AudienceGroupSummaryProps> =>
  useDocsApi<AudienceGroupSummaryProps>(
    getAudienceGroups,
    audienceGroupsQueryKey,
    options
  );

/**
 * Custom hook to fetch an audience group document.
 *
 * This hook utilizes the `useDocApi` to retrieve a specific audience group based on the provided options.
 *
 * @param {UseDocOptionType<AudienceGroupProps>} [options] - Optional configuration options for fetching the audience group document.
 * @returns {UseDocApiReturnProps<AudienceGroupProps>} The result of the `useDocApi` hook, which includes the fetched audience group document
 */
export const useAudienceGroupDoc = (
  options?: UseDocOptionType<AudienceGroupProps>
): UseDocApiReturnProps<AudienceGroupProps> =>
  useDocApi<AudienceGroupProps>(
    getAudienceGroup,
    audienceGroupsQueryKey,
    options
  );

/**
 * Custom hook for deleting audience groups.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the delete mutation, including the status and potential errors.
 */
export const useDeleteAudienceGroupMutation = useDeleteMutation<string[]>(
  deleteAudienceGroups,
  audienceGroupsQueryKey
);

/**
 * Custom hook for adding a new audience group.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the add mutation, including the status and potential errors.
 */
export const useAddAudienceGroupMutation = useAddMutation<string>(
  addAudienceGroup,
  audienceGroupsQueryKey
);

/**
 * Custom hook for editing an existing audience group.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the edit mutation, including the status and potential errors.
 */
export const useEditAudienceGroupMutation = useEditMutation<string>(
  editAudienceGroup,
  audienceGroupsQueryKey
);

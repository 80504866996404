import * as React from 'react';

// Custom Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Core Components
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface CopyIconProps extends SvgIconProps {}

const CopyIcon: React.FC<CopyIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M15.8571 19.5V21.0625C15.8571 21.5803 15.4254 22 14.8929 22H3.96429C3.43172 22 3 21.5803 3 21.0625V6.6875C3 6.16973 3.43172 5.75 3.96429 5.75H6.85714V17.3125C6.85714 18.5187 7.86647 19.5 9.10714 19.5H15.8571ZM15.8571 6.0625V2H9.10714C8.57458 2 8.14286 2.41973 8.14286 2.9375V17.3125C8.14286 17.8303 8.57458 18.25 9.10714 18.25H20.0357C20.5683 18.25 21 17.8303 21 17.3125V7H16.8214C16.2911 7 15.8571 6.57812 15.8571 6.0625ZM20.7176 4.85043L18.0681 2.27457C17.8873 2.09877 17.642 2 17.3863 2H17.1429V5.75H21V5.51332C21 5.26469 20.8984 5.02624 20.7176 4.85043Z' />
  </IconBuilder>
);

export default CopyIcon;

// Core Utilities
import apiHandler from 'core/utilities/apiHandler';

// Feature Utilities
import { PatternsEndpoints } from 'features/appBuilder/patterns/core/utilities/api/endpoints';

// Feature Types
import type { PatternType } from 'features/appBuilder/patterns/core/types/item';

/**
 * Duplicates a pattern based on the provided type and ID.
 *
 * @param {PatternType} type - The type of the pattern to duplicate.
 * @param {string} id - The ID of the pattern to be duplicated.
 * @returns {Promise<{status: number;}>} A promise that resolves to a status code indicating the result of the duplication operation.
 */
export const duplicatePattern = async (payload: {
  type: PatternType;
  id: string;
}): Promise<{ status: number }> => {
  const endpoint = PatternsEndpoints.duplicate(payload.type, payload.id);

  const { status } = await apiHandler.post(endpoint);
  return { status };
};

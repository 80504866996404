import { type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Custom Core Components
import Box, { BoxProps } from 'core/components/base/layout/Box';
import Checkbox from 'core/components/base/inputs/Checkbox';
import InputBase from 'core/components/base/inputs/InputBase';
import Radio from 'core/components/base/inputs/Radio';

// Custom Common Components
import CloseIconButton from 'core/components/shared/IconButton/Close';

// Custom Feature Components
import InputOptionFieldSelect from 'features/form/forms/components/details/item/Input/shared/Option/FieldSelect';
import InputOptionSectionSelect from 'features/form/forms/components/details/item/Input/shared/Option/SectionSelect';

// Custom Icon Components
import DragIcon from 'core/components/icons/Drag';

// Custom Types
import type { FormInputOptionProps } from 'features/form/forms/types/item/input/option';
import type { FormProps } from 'features/form/forms/types/item';
import type { FormInputWrapperProps } from '../Wrapper';
export interface FormInputOptionComponentProps
  extends BoxProps,
    FormInputWrapperProps {
  deletable?: boolean;
  parentType?: 'checkbox' | 'radio';
  option: FormInputOptionProps;
  parentInputId: string;
  inputIndex: number;
  optionIndex: number;
  hasFieldCondition?: boolean;
  hasSectionCondition?: boolean;
  inputSectionId?: string;
  onRemove?: () => void;
}

const FormInputOption: FC<FormInputOptionComponentProps> = (props) => {
  // Props
  const {
    parentType = 'checkbox',
    inputIndex,
    optionIndex,
    deletable,
    option,
    parentInputId,
    hasFieldCondition,
    hasSectionCondition,
    sx,
    disabled,
    inputSectionId,
    onUpdate,
    onRemove,
    ...otherProps
  } = props;

  // Hooks
  const { control, setValue } = useFormContext<FormProps>();

  const handleRemove = () => {
    if (onRemove) onRemove();
    if (onUpdate) onUpdate();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        width: '100%',
        ...sx,
      }}
      {...otherProps}
    >
      <DragIcon
        className='input-option_sort_handle'
        sx={{
          color: disabled ? 'text.disabled' : 'text.secondary',
          cursor: disabled ? 'auto' : 'grab',
        }}
      />
      {parentType === 'checkbox' ? <Checkbox disabled /> : <Radio disabled />}
      <Controller
        control={control}
        name={`data.inputs.${inputIndex}.data.options.${optionIndex}.data.title`}
        rules={{
          onChange: onUpdate,
          onBlur: (e) => {
            const value = e.target.value;

            if (!value)
              setValue(
                `data.inputs.${inputIndex}.data.options.${optionIndex}.data.title`,
                'َعنوان'
              );
          },
        }}
        defaultValue=''
        render={({ field }) => (
          <InputBase
            disabled={disabled}
            id={`input-option-${option.id}`}
            placeholder='عنوان گزینه را وارد کنید.'
            sx={{ flexGrow: 1 }}
            {...field}
          />
        )}
      />
      {deletable && (
        <CloseIconButton
          disabled={disabled}
          onClick={handleRemove}
          size='small'
          sx={{ color: 'text.secondary' }}
        />
      )}
      {hasFieldCondition && (
        <Controller
          control={control}
          name={`data.inputs.${inputIndex}.data.options.${optionIndex}.data.fieldIdsToUnhide`}
          rules={{ onChange: onUpdate }}
          render={({ field }) => (
            <InputOptionFieldSelect
              disabled={disabled}
              optId={option.id}
              values={field.value}
              inputSectionId={inputSectionId}
              onChange={(values) => {
                setValue(field.name, values);
                if (onUpdate) onUpdate();
              }}
            />
          )}
        />
      )}
      {hasSectionCondition && (
        <Controller
          control={control}
          name={`data.inputs.${inputIndex}.data.options.${optionIndex}.data.sectionIdsToUnhide`}
          render={({ field }) => (
            <InputOptionSectionSelect
              disabled={disabled}
              optId={option.id}
              values={field.value}
              onChange={(values) => {
                setValue(field.name, values);
                if (onUpdate) onUpdate();
              }}
            />
          )}
        />
      )}
    </Box>
  );
};

export default FormInputOption;

// Utilities
import apiHandler from 'core/utilities/apiHandler';
import getEndpoint from 'core/utilities/helper/getEndpoint';
import { getDoc, getDocs } from 'core/utilities/apiHandler/helper';

// Types
import type { ApiPaginationProps } from 'core/types/shared/pagination/api';
import type {
  ApiGetDocResponse,
  ApiGetDocsResponse,
} from 'core/types/api/hook/response';
import type {
  TicketProps,
  TicketSummaryProps,
} from 'features/ticket/tickets/types';

/**
 * Fetches a list of tickets based on the provided query parameters.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @param {Record<string, any>} [queries] - An optional query object containing parameters for the request.
 * @returns {Promise<ApiGetDocsResponse<TicketSummaryProps>>} - A promise that resolves to an object containing the status, a list of ticket summaries, and pagination information.
 */
export const getTickets = async (
  signal?: AbortSignal,
  queries?: Record<string, any>
): Promise<ApiGetDocsResponse<TicketSummaryProps>> => {
  const { getTicketsURL } = getEndpoint();

  return await getDocs<
    { data: TicketSummaryProps[]; paginate: ApiPaginationProps },
    TicketSummaryProps
  >(getTicketsURL, {
    queries,
    signal,
  });
};

/**
 * Toggles the active status of a specific ticket.
 *
 * @param {string} ticketId - The ID of the ticket to be updated.
 * @param {boolean} activeStatus - The new active status of the ticket.
 * @returns {Promise<{ status: number }>} - A promise that resolves to an object containing the status of the operation.
 */
export const toggleTicketStatus = async (
  ticketId: string,
  activeStatus: boolean
): Promise<{ status: number }> => {
  const { toggleTicketStatusURL } = getEndpoint();
  const endpoint = `${toggleTicketStatusURL}/${ticketId}`;

  const { status } = await apiHandler.put(endpoint, { isActive: activeStatus });

  return { status };
};

/**
 * Fetches the details of a specific ticket by its ID.
 *
 * @param {string} ticketId - The ID of the ticket to retrieve.
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<ApiGetDocResponse<TicketProps>>} - A promise that resolves to an object containing the status and the ticket details.
 */
export const getTicket = async (
  ticketId: string,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<TicketProps>> => {
  const { getTicketURL } = getEndpoint();
  let endpoint = `${getTicketURL}/${ticketId}`;

  return await getDoc<TicketProps, TicketProps>(endpoint, { signal });
};

// Custom Hooks
import useDocApi from 'core/hooks/api/useDoc';
import { useAddMutation } from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  editAudienceSetting,
  getAudienceSetting,
} from 'features/audience/settings/utilities/api';

// Custom Types
import type {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';
import type {
  AudienceSettingsDataProps,
  AudienceSettingsProps,
} from 'features/audience/settings/types';

/**
 * Query key for fetching audience settings in react query.
 *
 * @constant {Array<string>}
 */
export const audienceSettingsQueryKey = ['audienceSettings'];

/**
 * Custom hook to fetch audience settings document.
 *
 * This hook utilizes the `useDocApi` to retrieve a specific audience settings document based on the provided options.
 *
 * @param {UseDocOptionType<AudienceSettingsProps>} [options] - Optional configuration options for fetching the audience settings document.
 * @returns {UseDocApiReturnProps<AudienceSettingsProps>} The result of the `useDocApi` hook, which includes the fetched audience settings document, loading state, and any errors.
 */
export const useAudienceSettingDoc = (
  options?: UseDocOptionType<AudienceSettingsProps>
): UseDocApiReturnProps<AudienceSettingsProps> =>
  useDocApi<AudienceSettingsProps>(
    getAudienceSetting,
    audienceSettingsQueryKey,
    options
  );

/**
 * Custom hook for editing audience settings.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the edit mutation, including status and potential errors.
 */
export const useEditAudienceSettingMutation =
  useAddMutation<AudienceSettingsDataProps>(
    editAudienceSetting,
    audienceSettingsQueryKey
  );

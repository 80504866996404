import * as React from 'react';
import { styled } from '@mui/material';

// Core Components
import LoadingButton from 'core/components/base/inputs/LoadingButton';

// Types
import type { LoadingButtonProps } from 'core/components/base/inputs/LoadingButton';
export interface SubmitLoadingButtonProps extends LoadingButtonProps {}

const SubmitLoadingButton = styled(LoadingButton)<SubmitLoadingButtonProps>(
  () => ({
    minWidth: '8rem',
  })
);

SubmitLoadingButton.defaultProps = {
  color: 'info',
  variant: 'contained',
};

export default SubmitLoadingButton;

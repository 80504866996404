import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Custom Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import RowStack from 'core/components/shared/Stack/RowStack';
import CheckboxWithLabel from 'core/components/shared/Checkbox/CheckboxWithLabel';

// Custom Utilities
import { getProfileFieldLabel } from 'features/audience/settings/utilities';
import { requiredFields } from 'features/audience/settings/utilities/data';

// Custom Types
import type { AudienceSettingsDataProps } from 'features/audience/settings/types';

interface RequiredFieldsProps {
  disabled: boolean;
}

const RequiredFields: React.FC<RequiredFieldsProps> = (props) => {
  // Props
  const { disabled } = props;

  // Hooks
  const { setValue, control } = useFormContext<AudienceSettingsDataProps>();

  // Render
  return (
    <ColumnStack>
      <HeadingSix>
        تکمیل(فیلدهای خالی) زیر توسط کاربر در ورود آینده ضروری است
      </HeadingSix>
      <RowStack sx={{ paddingX: '2rem' }}>
        <ColumnStack spacing={1} sx={{ minWidth: '15rem' }}>
          {requiredFields
            .slice(0, Math.floor(requiredFields.length / 2))
            .map((fieldName) => (
              <Controller
                key={`required-fields-${fieldName}`}
                name={`requiredAudienceFields.${fieldName}`}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CheckboxWithLabel
                    disabled={disabled}
                    label={getProfileFieldLabel(fieldName)}
                    checkboxProps={{
                      color: 'info',
                      checked: field.value,
                      onChange: (e, checked) =>
                        setValue(
                          `requiredAudienceFields.${fieldName}`,
                          checked
                        ),
                    }}
                  />
                )}
              />
            ))}
        </ColumnStack>
        <ColumnStack spacing={1}>
          {requiredFields
            .slice(Math.floor(requiredFields.length / 2))
            .map((fieldName) => (
              <Controller
                key={`required-fields-${fieldName}`}
                name={`requiredAudienceFields.${fieldName}`}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CheckboxWithLabel
                    disabled={disabled}
                    label={getProfileFieldLabel(fieldName)}
                    checkboxProps={{
                      color: 'info',
                      checked: field.value,
                      onChange: (e, checked) =>
                        setValue(
                          `requiredAudienceFields.${fieldName}`,
                          checked
                        ),
                    }}
                  />
                )}
              />
            ))}
        </ColumnStack>
      </RowStack>
    </ColumnStack>
  );
};

export default RequiredFields;

import * as React from 'react';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Feature Components
import SubmitButton from 'features/menu/menus/components/details/List/Actions/SubmitButton';
import CancelButton from 'features/menu/menus/components/details/List/Actions/CancelButton';
import DeleteButton from 'features/menu/menus/components/details/List/Actions/DeleteButton';
import AddMenuButton from 'features/menu/menus/components/details/List/Actions/AddMenuButton';

// Custom Utilities
import { useSelectMenuActionMode } from 'core/store/slices/feature/menu';

// Custom Types
interface ListActionsProps {}

const ListActions: React.FC<ListActionsProps> = (props) => {
  // Context
  const actionMode = useSelectMenuActionMode();

  // Render
  return (
    <RowStack width='100%' justifyContent='end'>
      {actionMode === 'DELETE' ? (
        <>
          <CancelButton />
          <DeleteButton />
        </>
      ) : (
        <>
          <DeleteButton />
          <AddMenuButton />
          <CancelButton />
          <SubmitButton />
        </>
      )}
    </RowStack>
  );
};

export default ListActions;

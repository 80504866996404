import * as React from 'react';

import useTheme from '@mui/material/styles/useTheme';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';

// Custom Icons
import FilterIcon from 'core/components/icons/Filter';

// Custom Types
interface FilterButtonProps {
  onClick: () => void;
  showFilter: boolean;
}

const FilterButton: React.FC<FilterButtonProps> = (props) => {
  // Props
  const { showFilter, onClick } = props;

  // Context
  const loading = useSelectLoading();

  // Hooks
  const { palette } = useTheme();

  // Render
  return (
    <Button
      variant='contained'
      onClick={onClick}
      disabled={loading}
      sx={{
        background: showFilter ? palette.info.main : palette.common.white,
        color: showFilter ? palette.common.white : palette.info.main,
        minWidth: '2rem',
        maxWidth: '2rem',
        height: '2rem',
        maxHeight: '2rem',
        borderRadius: '.5rem',
        transition: 'opacity .3s',
        ':hover': {
          background: showFilter ? palette.info.main : palette.common.white,
          opacity: '.7',
        },
      }}
    >
      <FilterIcon sx={{ position: 'relative' }} />
    </Button>
  );
};

export default FilterButton;

import type { FC } from 'react';

// Feature Components
import HeaderPatternBrandSection from 'features/appBuilder/patterns/features/header/components/Brand';
import HeaderPatternMenuSection from 'features/appBuilder/patterns/features/header/components/Menu';
import HeaderPatternButtonSection from 'features/appBuilder/patterns/features/header/components/Button';
import HeaderPatternBannerSection from 'features/appBuilder/patterns/features/header/components/Banner';

// Custom Types
export interface HeaderPatternItemsProps {}

const HeaderPatternItems: FC<HeaderPatternItemsProps> = () => {
  return (
    <>
      <HeaderPatternBrandSection />
      <HeaderPatternMenuSection />
      <HeaderPatternButtonSection />
      <HeaderPatternBannerSection />
    </>
  );
};

export default HeaderPatternItems;

// Custom Utilities
import { transformAudienceGroupsSummary } from 'features/audience/groups/utilities';
import { convertNumberToPersian } from 'core/utilities/helper/helperPack';

// Custom Types
import type { AudienceFolderProps } from 'features/audience/folders/types';
import type { ApiAudienceFoldersProps } from 'features/audience/folders/types/api';

/**
 * Transforms an API audience folder object to an audience folder object.
 *
 * @param {ApiAudienceFoldersProps} folder - The API audience folder object.
 * @returns {AudienceFolderProps} The transformed audience folder object.
 */
export const transformAudienceFolder = (
  folder: ApiAudienceFoldersProps
): AudienceFolderProps => {
  return {
    id: folder._id,
    data: {
      title: convertNumberToPersian(folder.data.title),
      capacity: folder.data.capacity / 1000000, // Byte to MB
      creator: transformAudienceGroupsSummary(folder.data.creator),
      reader: transformAudienceGroupsSummary(folder.data.reader),
      updater: transformAudienceGroupsSummary(folder.data.updater),
      tags: folder.data.tags,
      isActive: folder.data.isActive,
      reservedCapacity: 0,
    },
  };
};

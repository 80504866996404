import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Custom Core Components
import MuiSelect from 'core/components/base/inputs/Select';

// Custom Icons
import ExpandMoreIcon from 'core/components/icons/ExpandMore';

// Custom Types
import type { SelectProps as MuiSelectProps } from 'core/components/base/inputs/Select';

export interface SelectProps extends MuiSelectProps {
  useDefalutIcon?: boolean;
}

const Select: React.FC<SelectProps> = React.forwardRef((props, ref) => {
  const {
    children,
    useDefalutIcon,
    IconComponent = useDefalutIcon ? undefined : ExpandMoreIcon,
    sx,
    disabled,
    ...otherProps
  } = props;

  // Hooks
  const { palette } = useTheme();

  // Render
  return (
    <MuiSelect
      ref={ref}
      disabled={disabled}
      sx={{
        backgroundColor: palette.background.paper,
        borderRadius: '.5rem',
        boxShadow: 'none',
        height: '48px',
        ...sx,
      }}
      IconComponent={IconComponent}
      MenuProps={{
        sx: { marginTop: '2px' },
        PaperProps: {
          sx: {
            boxShadow: 'none',
            borderRadius: '.5rem',
            border: '1px solid #C1C8D3',
            padding: '0.25rem',
          },
        },
        MenuListProps: { sx: { padding: 0 } },
      }}
      {...otherProps}
    >
      {children}
    </MuiSelect>
  );
});

export default Select;

import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SxProps } from '@mui/material';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import DatePicker, {
  DatePickerProps,
} from 'core/components/shared/Picker/DatePicker';
import { useSearchParams } from 'react-router-dom';
import { TextFieldProps } from 'core/components/base/inputs/TextField';

// Custom Types
interface DateProps {
  label?: string;
  queryName: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  sx?: SxProps;
  datePickerProps: DatePickerProps;
}

interface FilterFormProps {
  date: string;
}

const DateFilter: React.FC<DateProps> = (props) => {
  // Props
  const { queryName, label, disabled, sx, onChange, datePickerProps } = props;

  // States
  const { control, setValue } = useForm<FilterFormProps>();

  // Hooks
  const [searchParams] = useSearchParams();
  const value = searchParams.get(queryName) || '';

  React.useEffect(() => {
    setValue('date', value);
    handleChange(value);
  }, [value]);

  // Utilities
  const handleChange = (value: string) => {
    if (onChange) onChange(value);
  };

  // Render
  return (
    <ColumnStack spacing={0}>
      {label && <InputLabel indentRight>{label}</InputLabel>}
      <Controller
        control={control}
        name={'date'}
        rules={{
          onChange: (e) => handleChange(e.target.value as string),
        }}
        render={({ field }) => (
          <DatePicker
            disabled={disabled}
            sx={{
              width: '100%',
              height: '3rem',
              '& .rmdp-input': {
                textAlign: 'start !important',
              },
              ...sx,
            }}
            {...datePickerProps}
            value={field.value ? new Date(field.value) : undefined}
            onDateChange={(newDate) =>
              field.onChange(newDate?.toISOString() || '')
            }
            onClearValue={() => field.onChange('')}
          />
        )}
      />
    </ColumnStack>
  );
};

export default DateFilter;

import type { FC } from 'react';

// Feature Components
import SearchPatternPageConfigSection from 'features/appBuilder/patterns/features/search/components/PageConfig';
import SearchPatternCardConfigSection from 'features/appBuilder/patterns/features/search/components/CardConfig';

// Custom Types
export interface SearchPatternItemsProps {}

const SearchPatternItems: FC<SearchPatternItemsProps> = () => {
  return (
    <>
      <SearchPatternPageConfigSection />
      <SearchPatternCardConfigSection />
    </>
  );
};

export default SearchPatternItems;

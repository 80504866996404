import * as React from 'react';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Checkbox from 'core/components/base/inputs/Checkbox';
import TableRow from 'core/components/base/display/TableRow';
import TableCell from 'core/components/base/display/TableCell';

// Custom Common Components
import RouteLink from 'core/components/shared/Link/RouteLink';

// Core Utilities
import { useSelectionContext } from 'core/store/context/common/UseSelection';

// Custom Types
import type { ProcessSummaryProps } from 'features/form/processes/types/list';

interface ProcessesDataGridItemProps {
  process: ProcessSummaryProps;
}

const ProcessesDataGridItem: React.FC<ProcessesDataGridItemProps> = (props) => {
  // Props
  const processId = props.process.id;
  const { title = '-', steps = [] } = props.process.data;

  // Utilities
  const selections = useSelectionContext();

  // Render
  return (
    <TableRow>
      <TableCell padding='checkbox'>
        <Checkbox
          checked={selections.isSelected(processId)}
          onClick={() => selections.onSelectionsChange(processId)}
          size='small'
        />
      </TableCell>
      <TableCell
        sx={{
          width: '7rem',
          whiteSpace: 'nowrap',
          textAlign: 'start',
        }}
      >
        <RouteLink href={`/processes/${processId}`}>{title}</RouteLink>
      </TableCell>
      <TableCell
        sx={{
          display: 'flex',
          gap: 1,
          width: '12rem',
          whiteSpace: 'nowrap',
          textAlign: 'start',
        }}
      >
        {steps.length > 0
          ? steps.map((step, index) => (
              <Box
                key={`process-data-grid-item-step-${index}-${step}`}
                sx={{
                  backgroundColor: '#F5F5F5',
                  p: '1px 0.25rem',
                  color: '#59666D',
                  borderRadius: '0.25rem',
                }}
              >
                {step}
              </Box>
            ))
          : '-'}
      </TableCell>
    </TableRow>
  );
};

export default ProcessesDataGridItem;

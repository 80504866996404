import * as React from 'react';
import store from 'core/store';
import useTheme from '@mui/material/styles/useTheme';
import { utils, writeFile } from 'xlsx';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';

// Custom Feature Components
import ExcelPreview from 'features/form/feedbacks/components/details/Actions/Excel/Preview';

// Custom Utilites
import newArray from 'core/utilities/helper/newArray';
import { getLocaleDateAndTime } from 'core/utilities/helper/date';
import { forLoop, setAppAlert, setAppLoading } from 'core/utilities/helper';
import { useSelectHiddenProcessSections } from 'features/form/feedbacks/store';
import {
  convertPersianDigitsToLatin,
  getFileSource,
} from 'core/utilities/helper/helperPack';
import {
  calculateProcessTableHeaderCells,
  convertFeedbackValue,
  getAudienceInfoValue,
  getExcelInputValue,
  isInputHidden,
  isProcessSectionHidden,
} from 'features/form/feedbacks/utilities/details/helper';

// Custom Icons
import ExcelIcon from 'core/components/icons/ExcelIcon';

// Custom Types
import { AudienceDataFieldsLabel } from 'features/audience/settings/types';
import type {
  AudienceField,
  FeedbackInputProps,
} from 'features/form/feedbacks/types';

interface ExcelButtonProps {}

const ExcelButton: React.FC<ExcelButtonProps> = (props) => {
  // Hooks
  const { palette } = useTheme();

  // ‌Context
  const loading = useSelectLoading();
  const hiddenProcessSections = useSelectHiddenProcessSections();

  // States
  const [showExcelDialog, setShowExcelDialog] = React.useState<boolean>(false);

  const handleOpenExcelDialog = async () => {
    setShowExcelDialog(true);
  };
  const handleCloseExcelDialog = () => setShowExcelDialog(false);

  const handleCreateExcel = () => {
    setAppLoading(true);
    setAppAlert('در حال ساخت اکسل ...', 'info');
    setTimeout(() => createExcel(), 100);
  };

  const createExcel = () => {
    try {
      const excel = [] as string[][];
      const { feedbackOverview, excelValues, hiddenInputs } =
        store.getState().feedback;
      if (!feedbackOverview) return setAppLoading(false);
      const { audienceInfo, inputs } = feedbackOverview;

      // Active Inputs
      const activeInputs: FeedbackInputProps[] = [];

      forLoop(inputs, (input) => {
        if (!isInputHidden(hiddenInputs, input)) activeInputs.push(input);
      });

      // First Row -> [FeedbackInfo - AudienceInfo - Inputs - Process Sections]
      const processCellCounts = calculateProcessTableHeaderCells(
        hiddenProcessSections,
        excelValues
      );
      const firstRow: string[] = [];

      firstRow.push('عنوان بازخورد');
      firstRow.push('تاریخ ثبت');
      firstRow.push('شناسه بازخورد');
      firstRow.push('دسترسی');

      if (audienceInfo.length > 0)
        forLoop(audienceInfo, (info) =>
          firstRow.push(AudienceDataFieldsLabel[info] || '-')
        );

      forLoop(activeInputs, (input) =>
        firstRow.push(input?.data?.label || 'عنوان')
      );

      forLoop(newArray(processCellCounts), (_, index) =>
        firstRow.push(`رکورد ${index + 1}`)
      );

      // Data Rows -> [FeedbackInfo - AudienceInfo - Inputs Value - Process Values]
      const nextRows: string[][] = [];

      forLoop(excelValues, (feedback, index) => {
        const row: string[] = [];
        row.push(feedback.data.title || '-');

        row.push(
          convertPersianDigitsToLatin(
            getLocaleDateAndTime(feedback.data.createdAt, '-')
          )
        );
        row.push(feedback.id || '-');
        row.push(feedback.data.isPublic ? 'بینندگان' : 'مخاطبان');

        if (audienceInfo.length > 0)
          forLoop(audienceInfo, (info: AudienceField) =>
            row.push(
              getAudienceInfoValue(info, feedback.data.audienceInfo[info] || '')
            )
          );

        forLoop(activeInputs, (input) => {
          const value = getExcelInputValue(input.id, index);
          row.push(convertFeedbackValue(input.data, value));
        });

        if (
          feedback.data.process &&
          feedback.data.process.data.items.length > 0
        ) {
          forLoop(
            feedback.data.process.data.items,
            ({ id: itemId, data: item }) => {
              // Section Filter
              if (isProcessSectionHidden(hiddenProcessSections, itemId, 'all'))
                return;
              // ManagerQuestion Filter
              if (
                item.processItemType === 'managerQuestion' &&
                isProcessSectionHidden(
                  hiddenProcessSections,
                  itemId,
                  'questionDetails'
                )
              )
                return;

              if (item.processItemType === 'managerQuestion') {
                if (item?.title) row.push(item.title);
                if (item?.questionTitle) row.push(item.questionTitle);
                if (item?.applicantAnswer) row.push(item.applicantAnswer);
                if (item?.completionDate)
                  row.push(
                    convertPersianDigitsToLatin(
                      getLocaleDateAndTime(item.completionDate)
                    )
                  );
                if (
                  item?.applicantAnswerFiles &&
                  item.applicantAnswerFiles.length > 0
                )
                  row.push(
                    item.applicantAnswerFiles.map(getFileSource).join(' - ')
                  );
              } else {
                if (
                  !isProcessSectionHidden(
                    hiddenProcessSections,
                    itemId,
                    'title'
                  ) &&
                  item.title
                )
                  row.push(item.title);

                if (
                  !isProcessSectionHidden(
                    hiddenProcessSections,
                    itemId,
                    'questionTitle'
                  ) &&
                  item.questionTitle
                )
                  row.push(item.questionTitle);

                if (
                  !isProcessSectionHidden(
                    hiddenProcessSections,
                    itemId,
                    'selectedOpt'
                  ) &&
                  item.selectedOptionTitle
                )
                  row.push(item.selectedOptionTitle);

                if (
                  !isProcessSectionHidden(
                    hiddenProcessSections,
                    itemId,
                    'message'
                  ) &&
                  item.message
                )
                  row.push(item.message);

                if (
                  !isProcessSectionHidden(
                    hiddenProcessSections,
                    itemId,
                    'evaluatorFullname'
                  ) &&
                  item.evaluatorFullname
                )
                  row.push(item.evaluatorFullname);

                if (
                  !isProcessSectionHidden(
                    hiddenProcessSections,
                    itemId,
                    'comment'
                  ) &&
                  item.evaluatorComment
                )
                  row.push(item.evaluatorComment);

                if (
                  !isProcessSectionHidden(
                    hiddenProcessSections,
                    itemId,
                    'date'
                  ) &&
                  item.completionDate
                )
                  row.push(
                    convertPersianDigitsToLatin(
                      getLocaleDateAndTime(item.completionDate)
                    )
                  );

                if (
                  !isProcessSectionHidden(
                    hiddenProcessSections,
                    itemId,
                    'comment'
                  ) &&
                  item.evaluatorCommentFiles &&
                  item.evaluatorCommentFiles.length > 0
                )
                  row.push(
                    item.evaluatorCommentFiles.map(getFileSource).join(' - ')
                  );

                if (
                  !isProcessSectionHidden(
                    hiddenProcessSections,
                    itemId,
                    'review'
                  ) &&
                  item.reviews &&
                  item.reviews.length > 0
                ) {
                  forLoop(item.reviews, (review) => {
                    if (review.data.reviewQuestionTitle)
                      row.push(review.data.reviewQuestionTitle);
                    if (review.data.applicantReviewAnswer)
                      row.push(review.data.applicantReviewAnswer);
                    if (review.data.completionDate)
                      row.push(
                        convertPersianDigitsToLatin(
                          getLocaleDateAndTime(review.data.completionDate)
                        )
                      );
                    if (
                      review.data.applicantReviewFiles &&
                      review.data.applicantReviewFiles.length > 0
                    )
                      row.push(
                        review.data.applicantReviewFiles
                          .map(getFileSource)
                          .join(' - ')
                      );
                  });
                }
              }
            }
          );
        }

        nextRows.push(row);
      });

      excel.push(firstRow);
      excel.push(...nextRows);

      const worksheet = utils.aoa_to_sheet(excel);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      writeFile(workbook, 'FeedbacksExcel.xlsx');
      setAppAlert('اکسل با موفقیت ساخته شد', 'success');
    } catch (error) {
      setAppAlert('ساخت اکسل با شکست مواجه شد');
    }
    handleCloseExcelDialog();
    setAppLoading(false);
  };

  // Render
  return (
    <>
      <Button
        disabled={loading}
        onClick={handleOpenExcelDialog}
        variant='contained'
        startIcon={<ExcelIcon color='success' />}
        sx={{
          background: palette.common.white,
          color: palette.common.black,
          minWidth: '5.938rem',
          maxWidth: '5.938rem',
          height: '2.625rem',
          borderRadius: '.25rem',
          textTransform: 'none',
          transition: 'opacity .3s',
          ':hover': {
            background: palette.common.white,
            opacity: '.7',
          },
        }}
      >
        Excel
      </Button>
      <ExcelPreview
        open={showExcelDialog}
        onClose={handleCloseExcelDialog}
        onCreateExcel={handleCreateExcel}
      />
    </>
  );
};

export default ExcelButton;

// Custom Types
import { SubFeatureType } from 'core/types/feature/FeatureType';

export const subFeatureRouteMap: { [key in SubFeatureType]: string } = {
  // COMMON
  profile: '/profile',
  managerProfiles: '/profile',
  // CONTENT
  contents: '/contents',
  categories: '/contents/categories',
  tags: '/contents/tags',
  // FILE
  files: '/files',
  // FORM
  forms: '/forms',
  formTags: '/forms',
  feedbacks: '/feedback',
  processes: '/processes',
  // MANAGER
  managers: '/managers',
  managerGroups: '/manager-groups',
  managerSettings: '/settings',
  // AUDIENCE
  audienceAccesses: '/audiences/access',
  audiences: '/audiences',
  audienceFolders: '/audience-folders',
  audienceGroups: '/audience-groups',
  audienceSettings: '/audiences-settings',
  // MENU
  menus: '/menus',
  // BANNER
  banners: '/banners',
  // TEMPLATE
  customPages: '/custom-page',
  patterns: '/pattern',
  skins: '/template/skin',
  templates: '/template',
  // DATA
  sheets: '/sheet',
  // TICKET
  tickets: '/tickets',
  ticketCategories: '/tickets/topics/add',
};

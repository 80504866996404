import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

// Types
import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';

// Custom Hooks
import useUpdateEffect from 'core/hooks/useUpdateEffect';

// Common Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import CloseIconButton from 'core/components/shared/IconButton/Close';
import DeleteDialog from 'core/components/shared/Dialog/Delete';

// Core Components
import TextField from 'core/components/base/inputs/TextField';
import Box from 'core/components/base/layout/Box';
import Button from 'core/components/base/inputs/Button';
import IconButton from 'core/components/base/inputs/IconButton';
import Slide from 'core/components/base/utils/Slide';

// Custom Icons
import DeleteIcon from 'core/components/icons/DeleteIcon';

// Custom Types
export interface SkinTitleProps {
  title: string;
  onDelete: () => void;
  onUpdate: (title: string) => void;
}

const SkinTitle: FC<SkinTitleProps> = (props) => {
  // Props
  const { title, onDelete, onUpdate } = props;

  // States
  const [open, setOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<'preview' | 'edit'>('preview');

  // Hooks
  const { control, handleSubmit, reset } = useForm<{ title: string }>({
    mode: 'onTouched',
  });

  useUpdateEffect(() => reset({ title }), [title]);

  // Utilites
  const toggleMode = () => setMode(mode === 'preview' ? 'edit' : 'preview');

  const handleClose = () => {
    reset({ title });
    setOpen(false);
    setMode('preview');
  };

  const handleUpdate: SubmitHandler<{ title: string }> = (data) => {
    if (data.title !== title) {
      onUpdate(data.title);
    }
    handleClose();
  };

  return (
    <>
      {mode === 'preview' ? (
        <BodyOne
          sx={{ cursor: 'pointer', width: 'fit-content' }}
          onClick={toggleMode}
        >
          {title}
        </BodyOne>
      ) : (
        <Box
          sx={({ palette }) => ({
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '3.5rem',
            right: 0,
            left: 0,
            background: palette.background.paper,
            top: 0,
          })}
        >
          <Slide
            direction='left'
            in={mode === 'edit'}
            mountOnEnter
            unmountOnExit
          >
            <Box
              sx={({ palette }) => ({
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '3.5rem',
                right: 0,
                left: 0,
                background: palette.background.paper,
                top: 0,
                px: '1rem',
              })}
            >
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Controller
                  control={control}
                  name='title'
                  defaultValue={title}
                  render={({ field }) => (
                    <TextField
                      placeholder='عنوان اسکین'
                      InputProps={{ sx: { height: '2.5rem', width: '21rem' } }}
                      {...field}
                    />
                  )}
                />
                <Button
                  variant='outlined'
                  color='info'
                  sx={{ height: '2.5rem', width: '5rem' }}
                  onClick={handleSubmit(handleUpdate)}
                >
                  ذخیره
                </Button>
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton color='error' onClick={() => setOpen(true)}>
                  <DeleteIcon />
                </IconButton>
                <CloseIconButton onClick={toggleMode} />
              </Box>
            </Box>
          </Slide>
        </Box>
      )}
      <DeleteDialog
        title='آیا از حذف اسکین مطمئن هستید؟'
        open={open}
        onClose={() => setOpen(false)}
        onDelete={() => {
          handleClose();
          onDelete();
        }}
      />
    </>
  );
};

export default SkinTitle;

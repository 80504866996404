import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface FileIconProps extends SvgIconProps {}

const FileIcon: React.FC<FileIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M18.5 0H3.5C2.70462 0.000868526 1.94206 0.317218 1.37964 0.879639C0.817218 1.44206 0.500869 2.20462 0.5 3V15C0.500869 15.7954 0.817218 16.5579 1.37964 17.1204C1.94206 17.6828 2.70462 17.9991 3.5 18H18.5C19.2954 17.9991 20.0579 17.6828 20.6204 17.1204C21.1828 16.5579 21.4991 15.7954 21.5 15V3C21.4991 2.20462 21.1828 1.44206 20.6204 0.879639C20.0579 0.317218 19.2954 0.000868526 18.5 0ZM14.75 3C15.195 3 15.63 3.13196 16 3.37919C16.37 3.62643 16.6584 3.97783 16.8287 4.38896C16.999 4.8001 17.0436 5.2525 16.9568 5.68895C16.87 6.12541 16.6557 6.52632 16.341 6.84099C16.0263 7.15566 15.6254 7.36995 15.189 7.45677C14.7525 7.54358 14.3001 7.49903 13.889 7.32873C13.4778 7.15843 13.1264 6.87004 12.8792 6.50003C12.632 6.13002 12.5 5.69501 12.5 5.25C12.5006 4.65345 12.7379 4.08152 13.1597 3.65969C13.5815 3.23787 14.1535 3.00062 14.75 3ZM3.5 16.5C3.10218 16.5 2.72064 16.342 2.43934 16.0607C2.15804 15.7794 2 15.3978 2 15V11.8298L6.44562 7.87828C6.87455 7.49787 7.43238 7.2953 8.00545 7.31185C8.57853 7.3284 9.12374 7.56282 9.53 7.96734L12.5745 11.0053L7.07984 16.5H3.5ZM20 15C20 15.3978 19.842 15.7794 19.5607 16.0607C19.2794 16.342 18.8978 16.5 18.5 16.5H9.20141L14.893 10.8084C15.2959 10.4657 15.8073 10.277 16.3363 10.2756C16.8653 10.2742 17.3776 10.4603 17.7823 10.8009L20 12.6488V15Z' />
  </IconBuilder>
);

export default FileIcon;

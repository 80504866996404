import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// Types
import { SxProps } from '@mui/material';

// Custom Hooks
import useToggle from 'core/hooks/useToggle';
import useBoolean from 'core/hooks/useBoolean';
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// Custom Core Components
import Collapse from 'core/components/base/utils/Collapse';
import Paper from 'core/components/base/surfaces/Paper';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Custom Feature Components
import Items from 'features/menu/menus/components/details/MenuItem/Items';
import MenuItemPreview from 'features/menu/menus/components/details/MenuItem/Preview';
import EditMenu from 'features/menu/menus/components/details/MenuItem/Edit';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { menuItemSchema } from 'features/menu/menus/validations';

// Context
import { MenuItemProvider } from 'core/store/context/feature/menu/MenuItem';
import { useSelectMenuChildrens } from 'core/store/slices/feature/menu';

// Custom Types
import type {
  MenuItemDataProps,
  MenuItemProps,
} from 'features/menu/menus/types';

interface MenuProps {
  menu: MenuItemProps;
  handleClassName: string;
  sx?: SxProps;
}

export interface MenuItemFormProps extends MenuItemDataProps {
  selectedOpts: MenuItemProps[];
  unselectedOpts: MenuItemProps[];
  selectableOpts: MenuItemProps[];
}

const MenuItem: React.FC<MenuProps> = (props) => {
  const { menu, handleClassName, sx } = props;

  // Context
  const childrens = useSelectMenuChildrens(menu);

  // States
  const [mode, modeToggle] = useToggle('edit', 'preview', 'preview');
  const showSubItems = useBoolean();

  // Hooks
  const resolver = useYupValidationResolver(menuItemSchema);
  const methods = useForm<MenuItemFormProps>({
    mode: 'onTouched',
    resolver,
    defaultValues: {
      ...menu.data,
      selectableOpts: [],
      selectedOpts: [],
      unselectedOpts: [],
    },
  });

  // Render
  return (
    <MenuItemProvider menu={menu}>
      <FormProvider {...methods}>
        <ColumnStack spacing={0}>
          <Paper
            sx={{
              display: 'flex',
              boxShadow: 'none',
              flexDirection: 'column',
              borderRadius: '0.625rem',
              ...sx,
            }}
          >
            <MenuItemPreview
              mode={mode}
              onToggle={modeToggle}
              hasSubItems={childrens.length > 0}
              onToggleShowSubItems={showSubItems.toggle}
              handleClassName={handleClassName}
            />
            <Collapse
              in={mode === 'edit'}
              sx={{
                '.MuiCollapse-wrapperInner': {
                  padding: '2rem 1rem',
                },
              }}
            >
              <EditMenu
                childrens={childrens}
                mode={mode}
                onSetShowItems={showSubItems.set}
                onToggle={modeToggle}
              />
            </Collapse>
          </Paper>
          {childrens.length > 0 && (
            <Collapse in={showSubItems.state}>
              <Items childrens={childrens} />
            </Collapse>
          )}
        </ColumnStack>
      </FormProvider>
    </MenuItemProvider>
  );
};

export default React.memo(MenuItem, isSame);

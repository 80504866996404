import { useEffect, type FC, useState, useCallback } from 'react';
import { UseFieldArrayUpdate, useFormContext } from 'react-hook-form';
import { ReactSortable } from 'react-sortablejs';
import cloneDeep from 'lodash/cloneDeep';

// Core Components
import Box from 'core/components/base/layout/Box';
import Button from 'core/components/base/inputs/Button';

// Common Components
import CustomPagePattern from 'core/components/shared/Card/CustomPagePattern';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Icons
import PlusCircleOutlineIcon from 'core/components/icons/PlusCircleOutline';

// Context
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { PatternInBodyProps } from 'features/appBuilder/patterns/types/inBody';
import {
  TemplateOperation,
  TemplateProps,
} from 'features/appBuilder/templates/types';

interface PatternsProps {
  update: UseFieldArrayUpdate<TemplateProps, 'data.bodies'>;
  activeBody: number;
  operation: TemplateOperation;
  onOperationChange: (operation: TemplateOperation) => void;
}

const CustomPageBodyPatterns: FC<PatternsProps> = (props) => {
  // Props
  const { update, activeBody, onOperationChange, operation } = props;
  // States
  const [patterns, setPatterns] = useState<PatternInBodyProps[]>([]);

  // Hooks
  const { getValues, setValue } = useFormContext<TemplateProps>();
  const loading = useSelectLoading();

  useEffect(() => {
    if (activeBody > -1) {
      const patternsToSet = getValues(
        `data.bodies.${activeBody}.data.patterns`
      );
      const sortedPatterns = patternsToSet.sort((a, b) =>
        a.data.sortNumber > b.data.sortNumber ? 1 : -1
      );

      setPatterns(cloneDeep(sortedPatterns));
    }
  }, [activeBody, getValues]);

  // Utilities
  const handleSort = useCallback(
    (newPatterns: PatternInBodyProps[]) => {
      if (activeBody > -1) {
        const clonedPatterns = cloneDeep(
          getValues(`data.bodies.${activeBody}.data.patterns`)
        );

        clonedPatterns.forEach((p) => {
          const index = newPatterns.findIndex((pattern) => pattern.id === p.id);

          if (index > -1) {
            p.data.sortNumber = index;
          }
        });

        clonedPatterns.sort((a, b) =>
          a.data.sortNumber > b.data.sortNumber ? 1 : -1
        );

        setValue(`data.bodies.${activeBody}.data.patterns`, clonedPatterns);
      }
    },
    [activeBody, getValues, setValue]
  );
  useEffect(() => {
    handleSort(patterns);
  }, [patterns, handleSort]);

  const handleAddPattern = () => {
    if (activeBody > -1 && getValues('data.bodies').length > activeBody) {
      onOperationChange('BODY_PATTERN');
      const bodyObj = getValues(`data.bodies.${activeBody}`);

      bodyObj.data.patterns.push({
        id: 'draft',
        data: {
          maxWidth: 'none',
          direction:
            getValues(`data.bodies.${activeBody}.data.direction`) || 'rtl',
          patternTitle: '',
          sortNumber: bodyObj.data.patterns.length,
          type: 'header',
        },
      });
      update(activeBody, bodyObj);
      setPatterns(bodyObj.data.patterns);
    }
  };

  const handlePatternDelete = (index: number) => () => {
    if (activeBody > -1 && getValues('data.bodies').length > activeBody) {
      const bodyObj = getValues(`data.bodies.${activeBody}`);

      bodyObj.data.patterns.splice(index, 1);

      update(activeBody, bodyObj);
      setPatterns(bodyObj.data.patterns);
      onOperationChange('INIT');
    }
  };

  const handlePatternUpdate = (
    index: number,
    newPattern: PatternInBodyProps
  ) => {
    if (activeBody > -1 && getValues('data.bodies').length > activeBody) {
      const bodyObj = getValues(`data.bodies.${activeBody}`);

      bodyObj.data.patterns.splice(index, 1, newPattern);

      update(activeBody, bodyObj);
      setPatterns(bodyObj.data.patterns);
      onOperationChange('INIT');
    }
  };

  return (
    <ColumnStack>
      {patterns.length > 0 && (
        <ReactSortable
          list={patterns}
          disabled={loading}
          setList={setPatterns}
          animation={150}
          direction='vertical'
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          {patterns.map((pattern, index) => (
            <CustomPagePattern
              onDelete={handlePatternDelete(index)}
              key={pattern.id + index}
              pattern={pattern}
              onUpdate={(p) => handlePatternUpdate(index, p)}
            />
          ))}
        </ReactSortable>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          disabled={loading || operation === 'BODY_PATTERN'}
          color='info'
          variant='outlined'
          onClick={handleAddPattern}
          startIcon={<PlusCircleOutlineIcon />}
        >
          افزودن پترن
        </Button>
      </Box>
    </ColumnStack>
  );
};

export default CustomPageBodyPatterns;

import * as React from 'react';

// Core Components
import Paper from 'core/components/base/surfaces/Paper';
import Stack from 'core/components/base/layout/Stack';

// Types
import type { PaperProps } from 'core/components/base/surfaces/Paper';
import type { StackProps } from 'core/components/base/layout/Stack';
interface StackPaperProps extends StackProps {
  paperProps?: PaperProps;
}

const StackPaper: React.FC<StackPaperProps> = (props) => {
  const {
    children,
    paperProps,
    direction = 'column',
    spacing = 3,
    ...otherProps
  } = props;

  return (
    <Paper
      {...paperProps}
      sx={{
        borderRadius: '1rem',
        padding: '1.75rem',
        ...paperProps?.sx,
      }}
    >
      <Stack direction={direction} spacing={spacing} {...otherProps}>
        {children}
      </Stack>
    </Paper>
  );
};

export default StackPaper;

import * as React from 'react';

// Custom Hooks
import useDeprecatedDocsApi from 'core/hooks/api/useDeprecatedDocs';
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';
import { useParams, useSearchParams } from 'react-router-dom';
import { setFeedbackExcelValues } from 'features/form/feedbacks/store';

// Custom Core Components
import Skeleton from 'core/components/base/feedback/Skeleton';
import Button from 'core/components/base/inputs/Button';
import Dialog from 'core/components/base/feedback/Dialog';
import Table from 'core/components/base/display/Table';

// Custom Common Components
import TableContainerPaper from 'core/components/shared/Paper/TableContainerPaper';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Custom Feature Components
import ExcelTableHeader from 'features/form/feedbacks/components/details/Actions/Excel/Table/Header';
import ExcelTableBody from 'features/form/feedbacks/components/details/Actions/Excel/Table/Body';

// Custom Utilites
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';
import { getFeedbackValues } from 'features/form/feedbacks/utilities/api/details';

// Custom Types
interface ExcelPreviewProps {
  open: boolean;
  onCreateExcel: () => void;
  onClose: () => void;
}

const ExcelPreview: React.FC<ExcelPreviewProps> = (props) => {
  // Props
  const { open, onCreateExcel, onClose } = props;

  // Context
  const loading = useSelectLoading();

  // States
  const [ids, setIds] = React.useState<string[]>([]);

  // Hooks
  const { formId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const feedbackIds = searchParams.getAll('feedbackId');
  const feedbackApiMethods = useDeprecatedDocsApi(getFeedbackValues, {
    id: feedbackIds,
    shouldUseId: true,
    prefetch: false,
    defaultPageSize: feedbackIds.length,
    additionalData: {
      formId,
    },
  });

  const { list = [], refresh, initialized, fetching } = feedbackApiMethods;

  React.useEffect(() => setFeedbackExcelValues(list), [list]);

  React.useEffect(() => {
    if (open) {
      if (feedbackIds.length > 0 && list.length === 0) {
        setIds(feedbackIds);
        refresh();
      }
      const idsHaventChanged =
        feedbackIds.every((id) => ids.includes(id)) &&
        feedbackIds.length === ids.length;

      if (!idsHaventChanged) {
        setFeedbackExcelValues([]);
        setIds(feedbackIds);
        refresh();
      }
    }
  }, [open]);

  // Render
  return (
    <Dialog
      onClose={() => !loading && onClose()}
      open={open}
      PaperProps={{
        sx: {
          minWidth: '70vw',
          maxWidth: '70vw',
          padding: '2rem 1rem',
        },
      }}
    >
      <ColumnStack>
        <SpaceBetween>
          <BodyTwo
            sx={{
              fontWeight: 500,
              fontSize: '1.2rem',
            }}
          >
            {`پیش‌نمایش 10 بازخورد اول`.toPersian()}
          </BodyTwo>
          <Button
            variant='contained'
            color='error'
            onClick={onCreateExcel}
            disabled={loading || !initialized || fetching}
          >
            دانلود اکسل
          </Button>
        </SpaceBetween>
        {initialized ? (
          <TableContainerPaper
            sx={{
              maxWidth: '100%',
              maxHeight: '500px',
              overflow: 'auto',
              ...customRoundedScrollbarStyles,
            }}
          >
            <Table
              sx={{
                maxHeight: '150px',
                overflow: 'auto',
              }}
            >
              <ExcelTableHeader />
              <ExcelTableBody />
            </Table>
          </TableContainerPaper>
        ) : (
          <Skeleton
            variant='rounded'
            animation='wave'
            sx={{
              maxWidth: '100%',
              minHeight: '500px',
              maxHeight: '500px',
            }}
          />
        )}
      </ColumnStack>
    </Dialog>
  );
};

export default ExcelPreview;

import { memo, type FC } from 'react';
import { alpha } from '@mui/material';
import {
  Controller,
  useFormContext,
  type SubmitHandler,
} from 'react-hook-form';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';
import LoadingButton from 'core/components/base/inputs/LoadingButton';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import VisibilityIconButton from 'core/components/shared/IconButton/Visibility';
import CheckboxWithLabel from 'core/components/shared/Checkbox/CheckboxWithLabel';

// Custom Feature Components
import FormSectionColumnCount from 'features/form/forms/components/details/item/Section/HeaderActions/ColumnCount';
import FormSectionRepeatCount from 'features/form/forms/components/details/item/Section/HeaderActions/RepeatCount';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';
import { useDialogContext } from 'core/store/context/DialogProvider';
import {
  setFormActionMode,
  setFormCurrentSectionId,
  updateFormSections,
} from 'features/form/forms/store/actions';

// Custom Utilities
import { isSucceed, setAppAlert, setAppLoading } from 'core/utilities/helper';
import { deepClone, isSame } from 'core/utilities/helper/helperPack';
import { editFormSection } from 'features/form/forms/utilities/api/section';

// Custom Types
import type { FormSectionProps } from 'features/form/forms/types/item/section';
import type { FormProps } from 'features/form/forms/types/item';
export interface FormSectionHeaderEditActionsProps {
  section: FormSectionProps;
  index: number;
}

const FormSectionHeaderEditActions: FC<FormSectionHeaderEditActionsProps> = (
  props
) => {
  // Props
  const { section, index } = props;

  // Hooks
  const { onOpenDialog, onCloseDialog } = useDialogContext();
  const formSections = useAppSelector(
    (state) => state.form.data?.sections || []
  );
  const { control, getValues, setValue, handleSubmit } =
    useFormContext<FormProps>();

  // Utilities
  const handleCancel = () => {
    setValue('data.sections', formSections);
    setFormActionMode('INITIAL');
    onCloseDialog();
  };

  const handleOpenDialog = () => {
    onOpenDialog({
      onSubmit: handleCancel,
      submitTitle: 'لغو تغییرات',
      description: 'تغییرات ایجاد شده، اعمال نخواهند شد.',
    });
  };

  const handleFormSubmit: SubmitHandler<FormProps> = async (form) => {
    setAppLoading(true);
    const { status, doc } = await editFormSection(
      section.id,
      form.data.sections[index].data
    );
    setAppLoading(false);

    if (isSucceed(status) && doc) {
      const sections = deepClone(getValues('data.sections'));

      sections[index] = doc;

      setValue('data.sections', sections);
      updateFormSections(sections);

      setFormCurrentSectionId('false');
      setFormActionMode('INITIAL');
    } else {
      setAppAlert('خطا در بروزرسانی اطلاعات بخش');
    }
  };

  // Render
  return (
    <RowStack alignItems='center' spacing={1}>
      <Controller
        control={control}
        name={`data.sections.${index}.data.repeatCount`}
        render={({ field }) => (
          <FormSectionRepeatCount
            value={field.value}
            onChange={(value) => setValue(field.name, value)}
          />
        )}
      />
      <Controller
        control={control}
        name={`data.sections.${index}.data.columnCount`}
        render={({ field }) => (
          <FormSectionColumnCount
            value={field.value}
            onChange={(value) => setValue(field.name, value)}
          />
        )}
      />
      <Controller
        control={control}
        name={`data.sections.${index}.data.hideTitle`}
        render={({ field }) => (
          <CheckboxWithLabel
            checkboxProps={{
              checked: field.value,
              onChange: (event, checked) => setValue(field.name, checked),
            }}
            label='بدون عنوان'
          />
        )}
      />
      <Controller
        control={control}
        name={`data.sections.${index}.data.hideBorder`}
        render={({ field }) => (
          <CheckboxWithLabel
            checkboxProps={{
              checked: field.value,
              onChange: (event, checked) => setValue(field.name, checked),
            }}
            label='بدون کادر'
          />
        )}
      />
      <Controller
        control={control}
        name={`data.sections.${index}.data.isHidden`}
        render={({ field }) => (
          <VisibilityIconButton
            visible={!field.value}
            onClick={() => {
              setValue(field.name, !field.value);
            }}
          />
        )}
      />
      <Button
        variant='contained'
        sx={{
          minWidth: '4.5rem',
          backgroundColor: 'white',
          color: 'error.main',
          ':hover': {
            backgroundColor: alpha('#fff', 0.8),
          },
        }}
        onClick={handleOpenDialog}
      >
        انصراف
      </Button>
      <LoadingButton
        onClick={handleSubmit(handleFormSubmit)}
        variant='contained'
        sx={{
          minWidth: '4.5rem',
          backgroundColor: 'white',
          color: 'info.main',
          ':hover': {
            backgroundColor: alpha('#fff', 0.8),
          },
        }}
      >
        ذخیره
      </LoadingButton>
    </RowStack>
  );
};

export default memo(FormSectionHeaderEditActions, isSame);

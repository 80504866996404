// Custom Page Components
import AddForm from 'features/form/forms/components/details/Add';
import EditForm from 'features/form/forms/components/details/Edit';
import FormsList from 'features/form/forms/components/list';

// Custom Utilities
import { getFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'form';
const subFeature: SubFeatureType = 'forms';
const formsRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: `${getFeatureLabel(feature)}‌ها`,
    path: '/forms',
    Component: FormsList,
    feature,
    subFeature,
  },
  {
    path: '/forms/add',
    Component: AddForm,
    feature,
    subFeature,
  },
  {
    path: '/forms/:id',
    Component: EditForm,
    feature,
    subFeature,
  },
];

export default formsRoutes;

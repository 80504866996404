import type { FC } from 'react';
import type { Editor } from '@tiptap/react';

// Core Components
import IconButton from 'core/components/base/inputs/IconButton';
import BodyOne from 'core/components/shared/Typography/BodyOne';
import TextDecreaseIcon from 'core/components/icons/TextDecrease';
import TextIncreaseIcon from 'core/components/icons/TextIncrease';

// Component Types
interface EditorFontSizeIconButtonProps {
  editor: Editor | null;
}

const fontSizes = [8, 10, 12, 14, 16, 18, 20, 24, 32];

const EditorFontSizeIconButton: FC<EditorFontSizeIconButtonProps> = (props) => {
  // Props
  const { editor } = props;

  // Utilities
  const handleSizeChange = (mode: 'increase' | 'decrease') => () => {
    const currentFontSize = editor?.getAttributes('textStyle').fontSize || 16;
    let newFontSize = mode === 'increase' ? 18 : 14;

    const currentFontSizeIndex = fontSizes.findIndex(
      (size) => size === currentFontSize
    );

    if (currentFontSizeIndex > -1) {
      if (mode === 'increase') {
        newFontSize =
          fontSizes[
            currentFontSizeIndex === fontSizes.length - 1
              ? fontSizes.length - 1
              : currentFontSizeIndex + 1
          ];
      } else {
        newFontSize =
          fontSizes[currentFontSizeIndex === 0 ? 0 : currentFontSizeIndex - 1];
      }
    }

    editor
      ?.chain()
      .focus()
      .setMark('textStyle', { fontSize: newFontSize })
      .run();
  };

  return (
    <>
      <IconButton
        disabled={editor?.getAttributes('textStyle').fontSize === 8}
        color='inherit'
        onClick={handleSizeChange('decrease')}
      >
        <TextDecreaseIcon />
      </IconButton>
      <BodyOne sx={{ pt: 0.25, whiteSpace: 'nowrap' }}>
        {editor?.getAttributes('textStyle').fontSize || 16}
      </BodyOne>
      <IconButton
        disabled={editor?.getAttributes('textStyle').fontSize === 32}
        color='inherit'
        onClick={handleSizeChange('increase')}
      >
        <TextIncreaseIcon />
      </IconButton>
    </>
  );
};

export default EditorFontSizeIconButton;

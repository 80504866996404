import { type FC } from 'react';

// Custom Core Components
import IconButton, {
  type IconButtonProps,
} from 'core/components/base/inputs/IconButton';

// Custom Icon Components
import VisibilityIcon from 'core/components/icons/Visibility';
import VisibilityOffIcon from 'core/components/icons/VisibilityOff';

// Types
interface VisibilityIconButtonProps extends IconButtonProps {
  visible?: boolean;
}

const VisibilityIconButton: FC<VisibilityIconButtonProps> = (props) => {
  const { visible = true, children, ...otherProps } = props;

  return (
    <IconButton {...otherProps}>
      {visible ? (
        <VisibilityIcon color='inherit' fontSize='inherit' />
      ) : (
        <VisibilityOffIcon color='inherit' fontSize='inherit' />
      )}
    </IconButton>
  );
};

export default VisibilityIconButton;

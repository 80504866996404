import type { FC } from 'react';

// Feature Components
import SliderPatternBodySection from 'features/appBuilder/patterns/features/slider/components/Body';
import SliderPatternConfigSection from 'features/appBuilder/patterns/features/slider/components/Config';
import SliderPatternButtonSection from 'features/appBuilder/patterns/features/slider/components/Button';

// Custom Types
export interface SliderPatternItemsProps {}

const SliderPatternItems: FC<SliderPatternItemsProps> = () => {
  return (
    <>
      <SliderPatternBodySection />
      <SliderPatternConfigSection />
      <SliderPatternButtonSection />
    </>
  );
};

export default SliderPatternItems;

import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';
import TextField from 'core/components/base/inputs/TextField';

// Common Components
import InputWrapper from 'core/components/shared/Wrapper/Input';

// Feature Components
import GalleryUploadButton from 'features/file/files/components/shared/Gallery/UploadButton';
import PatternItemContainer from 'features/appBuilder/patterns/core/components/shared/items/Container';
import PatternButtonTypeDropdown from 'features/appBuilder/patterns/core/components/shared/dropdown/ButtonType';

// Context
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useUploadDialogContext } from 'features/file/files/context/UploadDialog';

// Custom Types
import type { PatternItemContainerProps } from 'features/appBuilder/patterns/core/components/shared/items/Container';
export interface PatternMenuButtonItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
  showIconConfig?: boolean;
  showLinkConfig?: boolean;
}

const PatternMenuButtonItem: FC<PatternMenuButtonItemProps> = (props) => {
  // Props
  const {
    reference,
    showLinkConfig = true,
    showIconConfig,
    ...otherProps
  } = props;

  // Context
  const { control, setValue } = useFormContext();
  const loading = useSelectLoading();
  const { setSettings } = useUploadDialogContext();

  // Utilities
  const handleOpenDialog = () => {
    setSettings({
      open: true,
      accept: ['image'],
      location: 'pageBuilder',
      onInsertFile: (files) => setValue(`${reference}.icon`, files[0]),
    });
  };

  // Helpers
  const LinkConfigComponent = (
    <InputWrapper label='آدرس دکمه'>
      <Controller
        control={control}
        name={`${reference}.link`}
        render={({ field }) => (
          <TextField disabled={loading} dir='ltr' {...field} />
        )}
      />
    </InputWrapper>
  );

  const IconConfigComponent = (
    <InputWrapper disabled={loading} label='آیکن دکمه'>
      <Controller
        control={control}
        name={`${reference}.icon`}
        render={({ field }) => (
          <GalleryUploadButton
            file={field.value ?? undefined}
            disabled={loading}
            onClick={handleOpenDialog}
            onRemove={() => setValue(field.name, null)}
            sx={{ width: '100%', height: '3rem' }}
          />
        )}
      />
    </InputWrapper>
  );

  return (
    <PatternItemContainer reference={reference} {...otherProps}>
      <Box sx={{ display: 'flex', gap: 2, ['&>*']: { flexGrow: 1 } }}>
        <InputWrapper disabled={loading} label='عنوان'>
          <Controller
            control={control}
            name={`${reference}.title`}
            render={({ field }) => <TextField disabled={loading} {...field} />}
          />
        </InputWrapper>
        {showLinkConfig && !showIconConfig && LinkConfigComponent}
        {showIconConfig && !showLinkConfig && IconConfigComponent}
      </Box>
      {showIconConfig && showLinkConfig && (
        <Box sx={{ display: 'flex', gap: 2, ['&>*']: { flexGrow: 1 } }}>
          {showLinkConfig && LinkConfigComponent}
          {IconConfigComponent}
        </Box>
      )}
      <PatternButtonTypeDropdown disabled={loading} reference={reference} />
    </PatternItemContainer>
  );
};

export default PatternMenuButtonItem;

import * as React from 'react';

// Custom Core Components
import TableBody from 'core/components/base/display/TableBody';
import TableCell from 'core/components/base/display/TableCell';
import TableRow from 'core/components/base/display/TableRow';

// Custom Feature Components
import ExcelBodyProcessCell from './Process';

// Custom Utilities
import { getLocaleDateAndTime } from 'core/utilities/helper/date';
import { convertPersianDigitsToLatin } from 'core/utilities/helper/helperPack';
import { excelTableCommonCellStyles } from 'features/form/feedbacks/utilities/details/styles';
import {
  convertFeedbackValue,
  getAudienceInfoValue,
  getExcelInputValue,
  isInputHidden,
} from 'features/form/feedbacks/utilities/details/helper';
import {
  useSelectAudienceInfo,
  useSelectFeedbackExcelValues,
  useSelectHiddenInputs,
  useSelectInputs,
} from 'features/form/feedbacks/store';

// Custom Types
interface ExcelTableBodyProps {}

const ExcelTableBody: React.FC<ExcelTableBodyProps> = (props) => {
  // Context
  const hiddenInputs = useSelectHiddenInputs();
  const feedbacks = useSelectFeedbackExcelValues();
  const inputs = useSelectInputs();
  const audienceInfo = useSelectAudienceInfo();

  // Render
  return (
    <TableBody>
      {feedbacks.slice(0, 10).map((feedback, index) => (
        <TableRow key={`table-preview-${feedback.id}`}>
          <TableCell {...excelTableCommonCellStyles}>
            {feedback.data.title || '-'}
          </TableCell>
          <TableCell {...excelTableCommonCellStyles}>
            {convertPersianDigitsToLatin(
              getLocaleDateAndTime(feedback.data.createdAt, '-')
            )}
          </TableCell>
          <TableCell {...excelTableCommonCellStyles}>{feedback.id}</TableCell>
          <TableCell {...excelTableCommonCellStyles}>
            {feedback.data.isPublic ? 'بینندگان' : 'مخاطبان'}
          </TableCell>
          {audienceInfo.length > 0 &&
            audienceInfo.map((info) => {
              return (
                <TableCell
                  key={`table-preview-audienceInfo-${info}-value`}
                  {...excelTableCommonCellStyles}
                >
                  {getAudienceInfoValue(
                    info,
                    feedback?.data?.audienceInfo?.[info] || ''
                  )}
                </TableCell>
              );
            })}
          {inputs.map((input) =>
            !isInputHidden(hiddenInputs, input) ? (
              <TableCell
                {...excelTableCommonCellStyles}
                key={`${feedback.id}-table-preview-value-${input.id}`}
              >
                {convertFeedbackValue(
                  input.data,
                  getExcelInputValue(input.id, index)
                )}
              </TableCell>
            ) : (
              <></>
            )
          )}
          {feedback.data.process &&
            feedback.data.process.data.items.map((item) => (
              <ExcelBodyProcessCell
                key={`${feedback.id}-${feedback.data.process?.id}-${item.id}`}
                feedbackId={feedback.id}
                processId={feedback.data.process?.id}
                item={item}
                applicantFullname={
                  feedback.data.process?.data?.applicantFullname
                }
              />
            ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default ExcelTableBody;

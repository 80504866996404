import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface PenToolProps extends SvgIconProps {}

const PenToolIcon: React.FC<PenToolProps> = (props) => {
  const { color, ...otherProps } = props;

  const styles: React.CSSProperties = {
    fill: color ? color : 'inherit',
  };

  return (
    <IconBuilder color={color && color} {...otherProps}>
      <path
        style={styles}
        d='M15.1,22.9c-0.3,0-0.5-0.1-0.7-0.3l-3-3c-0.4-0.4-0.4-1,0-1.4l7-7c0.4-0.4,1-0.4,1.4,0l3,3c0.4,0.4,0.4,1,0,1.4
	l-7,7C15.6,22.8,15.3,22.9,15.1,22.9z M13.5,18.9l1.6,1.6l5.6-5.6l-1.6-1.6L13.5,18.9z'
      />
      <path
        style={styles}
        d='M12.9,19.1c-0.1,0-0.1,0-0.2,0l-7.5-1.5c-0.4-0.1-0.7-0.4-0.8-0.7L0.9,2.3C0.9,2,1,1.6,1.2,1.4
	C1.4,1.1,1.8,1,2.1,1.1l14.5,3.5C17,4.7,17.3,5,17.4,5.4l1.5,7.5c0.1,0.3,0,0.7-0.3,0.9l-5,5C13.4,19,13.2,19.1,12.9,19.1z
	 M6.2,15.7l6.3,1.3l4.2-4.2l-1.3-6.3l-12.3-3L6.2,15.7z'
      />
      <path
        style={styles}
        d='M9.5,10.7c-0.3,0-0.5-0.1-0.7-0.3L1.2,2.8c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0L10.2,9
	c0.4,0.4,0.4,1,0,1.4C10,10.6,9.8,10.7,9.5,10.7z'
      />
      <path
        style={styles}
        d='M11.1,13.9c-1.7,0-3-1.3-3-3c0-1.7,1.3-3,3-3s3,1.3,3,3C14.1,12.5,12.7,13.9,11.1,13.9z M11.1,9.9
	c-0.6,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S11.6,9.9,11.1,9.9z'
      />
    </IconBuilder>
  );
};

export default PenToolIcon;

import React from 'react';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';
import useSelection from 'core/hooks/common/useSelection';

// Custom Core Components
import Stack from 'core/components/base/layout/Stack';

// Custom Common Components
import PageHeroBox from 'core/components/shared/Box/PageHero';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import PageHeroButtonLink from 'core/components/shared/Button/PageHeroButtonLink';
import IconButton from 'core/components/base/inputs/IconButton';
import RowStack from 'core/components/shared/Stack/RowStack';
import DeleteDialog from 'core/components/shared/Dialog/Delete';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import AudienceGroupsDataGrid from 'features/audience/groups/components/list/DataGrid';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Icons
import DeleteIcon from 'core/components/icons/DeleteIcon';

// Context
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import DocsProvider from 'core/hooks/api/useDocs/Context';
import SelectionProvider from 'core/store/context/common/UseSelection';

// Custom Utitlities
import { isPlural } from 'core/utilities/helper/helperPack';
import {
  useAudienceGroupDocs,
  useDeleteAudienceGroupMutation,
} from 'features/audience/groups/hooks';

// Custom Types

interface AudienceGroupsPageProps {}

const AudienceGroups: React.FC<AudienceGroupsPageProps> = (props) => {
  // States
  const showDeleteDialog = useBoolean();

  // Context
  const loading = useSelectLoading();

  // Hooks
  const selectionsMethods = useSelection();
  const { selections, onClearSelections } = selectionsMethods;
  const listApiMethods = useAudienceGroupDocs({
    alertOnFetchEmptyList: { message: 'تا کنون گروهی ایجاد نشده است.' },
    alertOnFetchError: {
      message: 'دریافت گروه‌های مخاطبان با خطا مواجه شد.',
    },
  });
  const { list } = listApiMethods.data;
  const { mutate: handleDeleteGroups } = useDeleteAudienceGroupMutation({
    autoAlert: { mode: 'delete', name: 'گروه‌های انتخابی' },
    onSuccess: () => {
      onClearSelections();
      showDeleteDialog.setFalse();
    },
  });

  // Render
  return (
    <DocsProvider {...listApiMethods}>
      <PageHeadConfigs pageTitle='گروه‌ مخاطبان' />
      <DeleteDialog
        open={showDeleteDialog.state}
        title={`آیا از حذف ${
          isPlural(selections) ? 'گروه‌های ' : 'گروه‌ '
        } انتخاب شده مطمئن هستید؟`}
        loading={loading}
        onDelete={() => handleDeleteGroups(selections)}
        onClose={showDeleteDialog.setFalse}
      />
      <PageHeroBox>
        <HeadingSix>گروه‌ مخاطبان</HeadingSix>
        <Stack>
          {selections.length !== 0 && (
            <RowStack sx={{ alignItems: 'center' }} spacing={1}>
              <IconButton
                disabled={selections.length === 0}
                onClick={showDeleteDialog.setTrue}
              >
                <DeleteIcon color='error' />
              </IconButton>
              <BodyTwo>{selections.length?.toPersian()} انتخاب شده</BodyTwo>
            </RowStack>
          )}
          <PageHeroButtonLink
            variant='outlined'
            disabled={selections.length > 1 || loading}
            href={
              selections.length === 0
                ? `/audience-groups/add`
                : `/audience-groups/${selections[0]}`
            }
          >
            {selections.length === 0 ? 'افزودن' : 'ویرایش'}
          </PageHeroButtonLink>
        </Stack>
      </PageHeroBox>
      <SelectionProvider {...selectionsMethods}>
        <AudienceGroupsDataGrid groups={list} disabled={loading} />
      </SelectionProvider>
    </DocsProvider>
  );
};

export default AudienceGroups;

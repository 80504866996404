import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

// Types
import type { FC } from 'react';

// Custom Feature Components
import SheetSetting from 'features/data/sheets/components/details/SheetSetting';
import SheetTable from 'features/data/sheets/components/details/SheetTable';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Context
import { useDialogContext } from 'core/store/context/DialogProvider';

// Utilities
import { findTableHeader } from 'features/data/sheets/utilities/helper';
import {
  useDeleteSheetMutation,
  useEditSheetMutation,
  useSheetDoc,
} from 'features/data/sheets/hooks';

// Custom Types
import type { SheetDataProps, SheetRowProps } from 'features/data/sheets/types';

interface EditSheetProps {}

const EditSheet: FC<EditSheetProps> = () => {
  // State
  const [originalData, setOriginalData] = React.useState<SheetRowProps[]>([]);
  const [tableHeader, setTableHeader] = React.useState<string[]>([]);

  // Context
  const { onOpenDialog, onCloseDialog } = useDialogContext();

  // Hooks
  const formMethods = useForm<SheetDataProps>();
  const { id: sheetId } = useParams();
  const sheetDocMethods = useSheetDoc({
    alertOnFetchError: { message: 'دریافت شیت با خطا مواجه شد.' },
    alertOnDocumentIdIsEmpty: { message: 'شناسه شیت شناسانی نشد.' },
    redirectAfterDocumentIdIsEmptyTo: '/sheets',
    redirectAfterErrorTo: '/sheets',
    autoLoading: true,
    onFetch: (sheet) => {
      setOriginalData(sheet.data.rows);
      formMethods.reset(sheet.data);
    },
  });
  const { mutate: handleEditSheet } = useEditSheetMutation({
    autoAlert: { mode: 'edit', name: 'شیت' },
  });
  const { mutate: handleDeleteSheet } = useDeleteSheetMutation({
    autoAlert: { mode: 'delete', name: 'شیت' },
    noRevalidate: true,
    redirectAfterSuccessTo: '/sheets',
    onSettled: () => onCloseDialog(),
  });

  React.useEffect(() => {
    if (originalData) setTableHeader(findTableHeader(originalData));
  }, [originalData]);

  // Utilites
  const bakeSheet = (): SheetDataProps => {
    const sheet: SheetDataProps = {
      title: formMethods.getValues('title'),
      isGeneric: false,
      rowCount: formMethods.getValues('rowCount'),
      colCount: formMethods.getValues('colCount'),
      headers: tableHeader,
      createdAt: '',
      updatedAt: '',
      rows: originalData ? originalData : [],
    };
    return sheet;
  };

  const handleSubmit = async () => {
    if (!sheetId) return;
    handleEditSheet({ id: sheetId, data: bakeSheet() });
  };

  const handleOpenDeleteDialog = () => {
    onOpenDialog({
      title: 'آیا از حذف شیت مطمئن هستید؟',
      onSubmit: () => sheetId && handleDeleteSheet(sheetId),
    });
  };

  // render
  return (
    <>
      <PageHeadConfigs pageTitle='ویرایش شیت مخاطبان' />
      <FormProvider {...formMethods}>
        <SheetSetting
          onOpenDeleteDialog={handleOpenDeleteDialog}
          onSubmit={handleSubmit}
          onSetOriginalData={setOriginalData}
          originalData={originalData}
        />
        <SheetTable tableData={originalData} tableHeader={tableHeader} />
      </FormProvider>
    </>
  );
};

export default EditSheet;

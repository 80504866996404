import { useState, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternEmptyItem from 'features/appBuilder/patterns/core/components/shared/items/Empty';
import PatternDateItem from 'features/appBuilder/patterns/core/components/shared/items/Date';

// Custom Types
export interface FormPatternConfigSectionItemProps {}
type CollapsedKey = 'section' | 'title' | 'description' | 'date' | 'step';

const FormPatternConfigSection: FC<FormPatternConfigSectionItemProps> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    title: false,
    description: false,
    date: false,
    step: false,
  });
  const { control } = useFormContext();

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='تنظیمات محتوا'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='config'
    >
      <PatternEmptyItem
        title='عنوان فرم'
        collapsed={collapsed.title}
        onCollapsedChange={handleCollapseChanged('title')}
        reference='config.title'
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'fontColor',
          'highlightColor',
        ]}
      />
      <PatternEmptyItem
        title='متن ابتدایی و انتهایی فرم'
        collapsed={collapsed.description}
        onCollapsedChange={handleCollapseChanged('description')}
        reference='config.description'
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'fontColor',
          'highlightColor',
        ]}
      />
      <PatternDateItem
        title='انتخاب تاریخ'
        collapsed={collapsed.date}
        onCollapsedChange={handleCollapseChanged('date')}
        reference='config.date'
        include={['padding', 'fontSize', 'fontColor', 'highlightColor']}
        noPrefix
      />
      <Controller
        control={control}
        name='variant'
        render={({ field }) =>
          field.value === 0 ? (
            <PatternEmptyItem
              title='مراحل فرم'
              collapsed={collapsed.step}
              onCollapsedChange={handleCollapseChanged('step')}
              reference='config.step'
              include={['padding', 'fontSize', 'textDirection', 'fontColor']}
            />
          ) : (
            <></>
          )
        }
      />
    </PatternSectionContainer>
  );
};

export default FormPatternConfigSection;

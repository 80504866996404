// Custom Hooks
import useDocsApi from 'core/hooks/api/useDocs';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities

// Custom Types
import type { UseDocsOptionType } from 'core/hooks/api/useDocs';
import {
  addFeedbacksToProcess,
  editFeedbackIsEditableStatus,
  getFeedbacksByFormId,
  removeFeedbacks,
} from 'features/form/feedbacks/utilities/api/list';
import {
  FeedbackListItemProps,
  FeedbackOverviewProps,
} from 'features/form/feedbacks/types';
import useDocApi, { UseDocOptionType } from 'core/hooks/api/useDoc';
import { getFeedback } from 'features/form/feedbacks/utilities/api/details';

/**
 * Query key for fetching feedbacks in react query.
 *
 * @constant {Array<string>}
 */
export const feedbacksQueryKey = ['feedbacks'];

export const useFeedbackDoc = (
  options?: UseDocOptionType<FeedbackOverviewProps>
) => useDocApi(getFeedback, feedbacksQueryKey, options);

// export const useFeedbackDocs = (
//   options?: UseDocsOptionType<FeedbackListItemProps>
// ) => useDocsApi(getFeedbacksByFormId, feedbacksQueryKey, options);

export const useDeleteFeedbacksMutation = useDeleteMutation<string[]>(
  removeFeedbacks,
  feedbacksQueryKey
);

export const useAddFeedbackToProcessMutation = useAddMutation<{
  selection: string[];
  processId: string;
}>(addFeedbacksToProcess, feedbacksQueryKey);

export const useEditFeedbackIsEditableStatusMutation = useAddMutation<{
  selection: string[];
  isEditable: boolean;
}>(editFeedbackIsEditableStatus, feedbacksQueryKey);

import { useNavigate } from 'react-router-dom';
import { Controller, useForm, type SubmitHandler } from 'react-hook-form';

// Custom Core Components
import MenuItem from 'core/components/base/navigation/MenuItem';
import Switch from 'core/components/base/inputs/Switch';
import TextField from 'core/components/base/inputs/TextField';

// Custom Common Componenst
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import DeprecatedImageUploader from 'core/components/shared/Input/Avatar';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import RowStack from 'core/components/shared/Stack/RowStack';
import Select from 'core/components/shared/Select';
import SpacedBox from 'core/components/shared/Box/SpacedBox';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import SubmitLoadingButton from 'core/components/shared/LoadingButton/Submit';

// Custom Hooks
import useRefId from 'core/hooks/useRefId';
import { useAppDispatch } from 'core/hooks/redux';
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// Custom Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { initializeManager } from 'core/store/slices/feature/manager';
import { setAppLoading } from 'core/utilities/helper';
import {
  useDisable2FaMutation,
  useEditProfileMutation,
  useProfileDoc,
} from 'features/manager/auth/hooks';

// Custom Validation Schemas
import ProfileValidationSchema from 'features/manager/auth/validations/Profile';

// Custom Types
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';
import type { ManagerDataProps } from 'features/manager/managers/types';

interface ProfilePageProps extends WithAuthProps {}

interface FormInputs extends ManagerDataProps {
  password: string;
}

const Profile: React.FC<ProfilePageProps> = (props) => {
  // States
  const [refId, setRefId] = useRefId();

  // Context
  const loading = useSelectLoading();

  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const resolver = useYupValidationResolver(ProfileValidationSchema);
  const formMethods = useForm<FormInputs>({
    mode: 'onTouched',
    resolver,
  });
  const { control, handleSubmit, reset } = formMethods;
  const { data: doc } = useProfileDoc({
    autoLoading: true,
    alertOnFetchError: { message: 'دریافت اطلاعات با خطا مواجه شد.' },
    enabled: true,
    onFetch: (doc) => {
      setRefId(doc.data.relatedMediaId);
      reset({
        ...doc.data,
        password: '',
      });
    },
  });
  const { mutate: handleEditProfile } = useEditProfileMutation({
    autoAlert: { mode: 'edit', name: 'اطلاعات شما' },
    noRevalidate: true,
    onSuccess: ({ doc }) => {
      if (!doc) return;
      setRefId(doc.data.relatedMediaId);
      reset({
        ...doc.data,
        password: '',
      });
      dispatch(initializeManager(doc));
    },
  });
  const { mutate: handleDisable2FA } = useDisable2FaMutation({
    customAlert: {
      successMessage: 'ورود دو مرحله‌ای با موفقیت غیرفعال شد.',
      errorMessage: 'خطا در غیرفعالسازی ورود دومرحله‌ای',
    },
  });

  // Utilities
  const handleEnable2FA = () => {
    setAppLoading(true);
    navigate('/enable-2fa');
  };

  const handleToggle2FA = () => {
    doc && doc.data.is2FAEnabled ? handleDisable2FA() : handleEnable2FA();
  };

  const handleFormSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (!doc || !doc.id) return;
    data.relatedMediaId = refId;
    handleEditProfile({ id: doc.id, data });
  };

  return (
    <>
      <PageHeadConfigs pageTitle='ویرایش پروفایل' />
      <PageHeroBox>
        <HeadingSix>ویرایش پروفایل</HeadingSix>
        <SubmitLoadingButton
          disabled={!doc || !doc.id}
          loading={doc && doc.id && loading ? true : false}
          onClick={handleSubmit(handleFormSubmit)}
        >
          ذخیره
        </SubmitLoadingButton>
      </PageHeroBox>
      <RoundPaper>
        <SpacedBox sx={{ alignItems: 'flex-start' }}>
          <ColumnStack sx={{ width: '20%', mt: 5 }}>
            <Controller
              name='avatar'
              control={control}
              render={({ field }) => (
                <DeprecatedImageUploader
                  location='manager'
                  disabled={!doc || !doc.id || loading}
                  onImageFileChange={(file) => field.onChange(file)}
                  imageFile={field.value?.id ? field.value : undefined}
                  sx={{
                    width: '167px',
                    height: '216px',
                    borderRadius: '1.2rem',
                  }}
                />
              )}
            />
          </ColumnStack>
          <ColumnStack sx={{ width: '75%', mt: 5, pb: 3, pr: 2 }}>
            <RowStack sx={{ width: '100%', justifyContent: 'space-between' }}>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>نام</InputLabel>
                <Controller
                  control={control}
                  name='firstName'
                  defaultValue=''
                  render={({ field, formState }) => (
                    <TextField
                      fullWidth
                      error={
                        formState.dirtyFields.firstName &&
                        formState.errors.firstName?.message
                          ? true
                          : false
                      }
                      helperText={
                        formState.dirtyFields.firstName &&
                        formState.errors.firstName?.message
                      }
                      disabled={!doc || !doc.id || loading}
                      placeholder='نام'
                      type='text'
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>نام‌خانوادگی</InputLabel>
                <Controller
                  name='lastName'
                  control={control}
                  defaultValue=''
                  render={({ field, formState }) => (
                    <TextField
                      fullWidth
                      error={
                        formState.dirtyFields.lastName &&
                        formState.errors.lastName?.message
                          ? true
                          : false
                      }
                      helperText={
                        formState.dirtyFields.lastName &&
                        formState.errors.lastName?.message
                      }
                      disabled={!doc || !doc.id || loading}
                      placeholder='نام‌خانوادگی'
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
            </RowStack>
            <RowStack sx={{ width: '100%', justifyContent: 'space-between' }}>
              <ColumnStack width='45%' spacing={0}>
                <InputLabel>ایمیل</InputLabel>
                <Controller
                  name='email'
                  control={control}
                  defaultValue=''
                  render={({ field, formState }) => (
                    <TextField
                      dir='ltr'
                      error={
                        formState.dirtyFields.email &&
                        formState.errors.email?.message
                          ? true
                          : false
                      }
                      helperText={
                        formState.dirtyFields.email &&
                        formState.errors.email?.message
                      }
                      placeholder='example@nazar.com'
                      fullWidth
                      disabled={!doc || !doc.id || loading}
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>کلمه عبور</InputLabel>
                <Controller
                  name='password'
                  control={control}
                  defaultValue=''
                  render={({ field, formState }) => (
                    <TextField
                      dir='ltr'
                      fullWidth
                      error={
                        formState.dirtyFields.password &&
                        formState.errors.password?.message
                          ? true
                          : false
                      }
                      helperText={
                        formState.dirtyFields.password &&
                        formState.errors.password?.message
                      }
                      type='password'
                      placeholder='password'
                      disabled={!doc || !doc.id || loading}
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
            </RowStack>
            <RowStack sx={{ justifyContent: 'space-between' }}>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>گروه‌های مدیریتی</InputLabel>
                <Select
                  disabled
                  sx={{ height: '3rem', borderRadius: '4px' }}
                  value={doc && doc.data.groupId ? doc.data.groupId : ''}
                >
                  <MenuItem disabled>{doc?.data.groupId}</MenuItem>
                </Select>
              </ColumnStack>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>غیرفعال کردن ورود دو مرحله‌ای</InputLabel>
                <RowStack
                  sx={({ palette }) => ({
                    width: '80%',
                    borderRadius: '10px',
                    background: palette.background.default,
                    paddingX: '1.5rem',
                    height: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  })}
                >
                  <InputLabel>
                    {doc && doc.data.is2FAEnabled ? 'فعال' : 'غیرفعال'}
                  </InputLabel>
                  <Switch
                    checked={doc && doc.data.is2FAEnabled ? true : false}
                    onChange={handleToggle2FA}
                  />
                </RowStack>
              </ColumnStack>
            </RowStack>
            <ColumnStack spacing={0}>
              <InputLabel>بیوگرافی</InputLabel>
              <Controller
                name='biography'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    disabled={!doc || !doc.id || loading}
                    multiline
                    fullWidth
                    rows={6}
                    placeholder='متن بیوگرافی'
                    {...field}
                  />
                )}
              />
            </ColumnStack>
          </ColumnStack>
        </SpacedBox>
      </RoundPaper>
    </>
  );
};

export default Profile;

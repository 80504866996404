import { type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';

// Custom Core Components
import Box, { BoxProps } from 'core/components/base/layout/Box';
import InputBase from 'core/components/base/inputs/InputBase';

// Custom Types
import { FormInputWrapperProps } from 'features/form/forms/components/details/item/Input/shared/Wrapper';
export interface FormInputOptionComponentProps
  extends Omit<BoxProps, 'onChange'>,
    FormInputWrapperProps {
  onChange?: (value: string) => void;
}

const FormNewInputOption: FC<FormInputOptionComponentProps> = (props) => {
  // Props
  const { sx, onChange, disabled, onUpdate, ...otherProps } = props;

  // Hooks
  const { control, getValues, reset } = useForm<{ title: string }>({
    mode: 'onTouched',
  });

  const debouncedChangeHanlder = debounce((value: string) => {
    if (onChange) {
      onChange(value);
      reset({ title: '' });
    }
  }, 300);

  const handleChange = () => {
    const value = getValues('title');

    if (value) {
      debouncedChangeHanlder(value);
      if (onUpdate) onUpdate();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        width: '100%',
        mb: '1rem',
        ...sx,
      }}
      {...otherProps}
    >
      <Box sx={{ width: '4.5rem', flexShrink: 0 }} />
      <Controller
        control={control}
        name='title'
        rules={{ onChange: handleChange }}
        defaultValue=''
        render={({ field }) => (
          <InputBase
            disabled={disabled}
            placeholder='گزینه جدید'
            sx={{ flexGrow: 1 }}
            {...field}
          />
        )}
      />
    </Box>
  );
};

export default FormNewInputOption;

// Core Utilities
import apiHandler from 'core/utilities/apiHandler';
import { isSucceed } from 'core/utilities/helper';

// Feature Utilities
import { PatternsEndpoints } from 'features/appBuilder/patterns/core/utilities/api/endpoints';
import { bakePatternBody } from 'features/appBuilder/patterns/core/utilities/api/baker';

// Feature Types
import type { PatternData } from 'features/appBuilder/patterns/core/types/item';

/**
 * Updates an existing pattern based on the provided ID and pattern data.
 *
 * @param {string} id - The ID of the pattern to be updated.
 * @param {PatternData} data - The new data to update the pattern with.
 * @returns {Promise<{ status: number; message: string }>} A promise that resolves to an object containing
 * the status code and a message indicating the result of the update operation.
 */
export const updatePattern = async (
  id: string,
  data: PatternData
): Promise<{ status: number; message: string }> => {
  let type = data.type;
  let body = bakePatternBody(data);

  const { status } = await apiHandler.patch(
    PatternsEndpoints.edit(type, id),
    body
  );

  return {
    status,
    message: isSucceed(status)
      ? 'تغییرات پترن اعمال شد.'
      : 'خطا در بروزرسانی پترن',
  };
};

// Custom Types
import type {
  FileDataProps,
  FileImageFormat,
  FileProps,
} from 'features/file/files/types';

export const initialFileData: FileDataProps = {
  alt: '',
  description: '',
  folderId: '',
  height: 0,
  location: 'file',
  mimeType: 'image/jpeg',
  model: 'media',
  originalname: '',
  relatedModelId: '',
  size: 0,
  link: '',
  title: '',
  type: 'image',
  url: '',
  width: 0,
  createdAt: '',
  user: {
    id: '',
    data: {
      firstName: '',
      lastName: '',
    },
  },
};

export const initialFile: FileProps = {
  id: '',
  data: initialFileData,
};

export const imageTypes: FileImageFormat[] = [
  'gif',
  'jpg',
  'jpeg',
  'png',
  'svg',
  'tiff',
];

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface AddCircleOutlineIconProps extends SvgIconProps {}

const AddCircleOutlineIcon: React.FC<AddCircleOutlineIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8' />
  </IconBuilder>
);

export default AddCircleOutlineIcon;

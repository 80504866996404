import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Custom Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import RowStack from 'core/components/shared/Stack/RowStack';
import CheckboxWithLabel from 'core/components/shared/Checkbox/CheckboxWithLabel';

// Custom Utilities
import { profileFields } from 'features/audience/settings/utilities/data';
import { getProfileFieldLabel } from 'features/audience/settings/utilities';

// Custom Types
import {
  AudienceSettingsDataProps,
  AudienceSettingsFieldsDataProps,
} from 'features/audience/settings/types';

export type ProfileField = keyof AudienceSettingsFieldsDataProps;

interface ProfileProps {
  disabled: boolean;
}

const ProfileFields: React.FC<ProfileProps> = (props) => {
  // Props
  const { disabled } = props;

  // Hooks
  const { setValue, control } = useFormContext<AudienceSettingsDataProps>();

  // Render
  return (
    <ColumnStack>
      <HeadingSix>فیلدهای پروفایل</HeadingSix>
      <RowStack sx={{ paddingX: '2rem' }}>
        <ColumnStack spacing={1} sx={{ minWidth: '15rem' }}>
          {profileFields
            .slice(0, Math.floor(profileFields.length / 2) + 1)
            .map((fieldName) => (
              <Controller
                key={`profile-fields-${fieldName}`}
                name={`audienceFields.${fieldName}`}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CheckboxWithLabel
                    disabled={disabled}
                    label={getProfileFieldLabel(fieldName)}
                    checkboxProps={{
                      color: 'info',
                      checked: field.value,
                      onChange: (e, checked) =>
                        setValue(`audienceFields.${fieldName}`, checked),
                    }}
                  />
                )}
              />
            ))}
        </ColumnStack>
        <ColumnStack>
          {profileFields
            .slice(Math.floor(profileFields.length / 2) + 1)
            .map((fieldName) => (
              <Controller
                key={`profile-fields-${fieldName}`}
                name={`audienceFields.${fieldName}`}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CheckboxWithLabel
                    disabled={disabled}
                    label={getProfileFieldLabel(fieldName)}
                    checkboxProps={{
                      color: 'info',
                      checked: field.value,
                      onChange: (e, checked) =>
                        setValue(`audienceFields.${fieldName}`, checked),
                    }}
                  />
                )}
              />
            ))}
        </ColumnStack>
      </RowStack>
    </ColumnStack>
  );
};

export default ProfileFields;

// Feature Utilities
import { bakePatternCommonProps } from 'features/appBuilder/patterns/core/utilities/api/baker';

// Feature Types
import type { TagAndCategoryPatternDataProps } from 'features/appBuilder/patterns/features/tagAndCategory/types';

/**
 * Transforms the provided tag and category pattern data by ensuring the `creator` field is included as a string.
 *
 * @param {TagAndCategoryPatternDataProps} data - The tag and category pattern data to transform.
 * @returns {Omit<TagAndCategoryPatternDataProps, 'creator'> & { creator: string }} The transformed tag and category pattern data with the `creator` field as a string.
 */
export const bakeTagAndCategoryPatternData = (
  data: TagAndCategoryPatternDataProps
): Omit<TagAndCategoryPatternDataProps, 'creator'> & { creator: string } => {
  const { pageConfig, cardConfig, ...otherProps } = data;

  const commonProps = bakePatternCommonProps(otherProps);

  return {
    ...commonProps,
    pageConfig,
    cardConfig,
  };
};

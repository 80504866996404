import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import TextFieldOutlined from 'core/components/shared/TextField/Outlined';

// Custom Types
import type { FileDataProps } from 'features/file/files/types';

interface FileFormProps {}
const FileForm: React.FC<FileFormProps> = (props) => {
  // Context
  const loading = useSelectLoading();
  const { control } = useFormContext<FileDataProps>();

  // Render
  return (
    <ColumnStack width='100%' spacing='1rem'>
      <ColumnStack spacing={0.5} sx={{ width: '100%' }}>
        <InputLabel disabled={loading}>عنوان</InputLabel>
        <Controller
          control={control}
          name='title'
          render={({ field }) => (
            <TextFieldOutlined
              disabled={loading}
              fullWidth
              placeholder='عنوان'
              dir='ltr'
              sx={{
                '.MuiOutlinedInput-root': {
                  height: '2.5rem',
                  borderRadius: '.5rem',
                },
              }}
              {...field}
            />
          )}
        />
      </ColumnStack>
      <ColumnStack spacing={0.5} sx={{ width: '100%' }}>
        <InputLabel disabled={loading}>متن جایگزین</InputLabel>
        <Controller
          control={control}
          name='alt'
          render={({ field }) => (
            <TextFieldOutlined
              disabled={loading}
              fullWidth
              placeholder='متن جایگزین'
              sx={{
                '.MuiOutlinedInput-root': {
                  height: '2.5rem',
                  borderRadius: '.5rem',
                },
              }}
              {...field}
            />
          )}
        />
      </ColumnStack>
    </ColumnStack>
  );
};

export default FileForm;

// Type
import type { AudienceGroupProps } from 'features/audience/groups/types';

export const initialAudienceGroup = (): AudienceGroupProps => {
  return {
    id: '',
    data: {
      title: '',
      audienceCount: 0,
      permissions: [],
    },
  };
};

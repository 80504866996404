// Feature Types
import type { FormPatternBodyProps } from 'features/appBuilder/patterns/features/form/types/body';

export const bodyPatterConfigDefaults: FormPatternBodyProps = {
  isActive: true,
  section: {
    isActive: true,
    fontSize: '20',
    padding: '0px',
    borderRadius: 8,
    textDirection: 'rtl',
    fontColor: '#004077',
    highlightColor: 'rgba(0, 0, 0, 0)',
    iconColor: '#807D7B',
    bgColor: '#807D7B',
    borderColor: '#807D7B',
  },
  field: {
    isActive: true,
    padding: '0px',
    fontSize: '16',
    textDirection: 'rtl',
    fontColor: '#12252E',
    highlightColor: 'rgba(0, 0, 0, 0)',
    iconColor: '#807D7B',
    bgColor: '#807D7B',
    borderColor: '#807D7B',
  },
};

import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Grid2 from 'core/components/base/layout/Grid2';
import Button from 'core/components/base/inputs/Button';
import Paper from 'core/components/base/surfaces/Paper';
import TextField from 'core/components/base/inputs/TextField';
import MenuItem from 'core/components/base/navigation/MenuItem';

// Common Components
import DateFilter from 'core/components/shared/Filter/Date';
import CloseIconButton from 'core/components/shared/IconButton/Close';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import Select from 'core/components/shared/Select/SelectWithPlaceholder';
import SingleAutocomplete from 'core/components/shared/Autocomplete/api/SingleAutocomplete';

// Feature Components
import SearchInput from 'features/file/files/components/list/SearchInput';

// Custom Icons
import DeleteIcon from 'core/components/icons/DeleteIcon';

// Custom Utilities
import { getManagersSummaryList } from 'features/manager/managers/utilities/api';
import {
  fileFeatureLocationOptions,
  fileTypesOptions,
} from 'features/file/files/utilities/files';

// Custom Types
import type { FileFilterProps } from 'features/file/files/types';

interface FileFilterComponentProps {
  onShowFilter: () => void;
  onCloseFilter: () => void;
  onFilter: () => void;
  onDeleteFilter: () => void;
}

const FileFilter: React.FC<FileFilterComponentProps> = React.forwardRef(
  (props, ref: any) => {
    // Props
    const { onFilter, onDeleteFilter, onCloseFilter, onShowFilter } = props;

    // Context
    const { getValues, control } = useFormContext<FileFilterProps>();

    // Render
    return (
      <ColumnStack
        ref={ref}
        component={Paper}
        sx={{
          borderRadius: '1rem',
          padding: '1rem',
          boxShadow: 'none',
        }}
      >
        <Grid2 container spacing='1rem'>
          <Grid2 xs={10} md={8}>
            <SearchInput
              sx={{ maxWidth: '100%', width: '500px' }}
              disableFilterButton
              onShowFilter={onShowFilter}
            />
          </Grid2>
          <Grid2
            xs={2}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <CloseIconButton onClick={onCloseFilter} />
          </Grid2>
          <Grid2 xs={6} md={3}>
            <Controller
              control={control}
              name='type'
              defaultValue={[]}
              render={({ field }) => (
                <Select
                  size='small'
                  multiple
                  placeholder='نوع'
                  fullWidth
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                >
                  {fileTypesOptions.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                      {opt.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid2>
          <Grid2 xs={6} md={3}>
            <Controller
              control={control}
              name='location'
              defaultValue={[]}
              render={({ field }) => (
                <Select
                  size='small'
                  multiple
                  placeholder='محل بارگذاری'
                  fullWidth
                  {...field}
                >
                  {fileFeatureLocationOptions.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                      {opt.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid2>
          <Grid2 xs={6} md={3}>
            <Controller
              control={control}
              name='startDate'
              render={({ field }) => (
                <DateFilter
                  queryName='startDate'
                  sx={{
                    width: '100%',
                    height: '2.5rem',
                    borderRadius: '3rem !important',
                  }}
                  datePickerProps={{
                    placeholder: 'تاریخ شروع',
                    iconSx: {
                      borderRadius: '0 .5rem .5rem 0',
                    },
                    InputProps: {
                      sx: {
                        '.MuiOutlinedInput-root': {
                          height: '2.5rem',
                          borderRadius: '.5rem',
                        },
                      },
                    },
                  }}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Grid2>
          <Grid2 xs={6} md={3}>
            <Controller
              control={control}
              name='endDate'
              render={({ field }) => (
                <DateFilter
                  queryName='endDate'
                  sx={{
                    width: '100%',
                    height: '2.5rem',
                    borderRadius: '3rem !important',
                  }}
                  datePickerProps={{
                    placeholder: 'تاریخ پایان',
                    iconSx: {
                      borderRadius: '0 .5rem .5rem 0',
                    },
                    InputProps: {
                      sx: {
                        '.MuiOutlinedInput-root': {
                          height: '2.5rem',
                          borderRadius: '.5rem',
                        },
                      },
                    },
                  }}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Grid2>
          <Grid2 xs={12} md={3}>
            <Controller
              control={control}
              name='uploadBy'
              defaultValue=''
              render={({ field }) => (
                <SingleAutocomplete
                  fullWidth
                  size='small'
                  sx={{
                    width: '100%',
                    '.MuiAutocomplete-inputRoot': {
                      minHeight: '2.5rem',
                    },
                  }}
                  apiHandler={getManagersSummaryList}
                  queryKey={['managers']}
                  placeholder='بارگذاری شده توسط'
                  getOptionLabel={(opt) => opt.data.fullName}
                  defaultValue={field.value}
                  onChange={(selectedId) => field.onChange(selectedId)}
                />
              )}
            />
          </Grid2>
          <Grid2
            xs={12}
            md={5}
            sx={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}
          >
            <Controller
              control={control}
              name='minSize'
              defaultValue=''
              render={({ field }) => (
                <TextField
                  type='number'
                  sx={{
                    width: { xs: '100%', md: '10rem' },
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: 'none',
                      },
                    '& input[type=number]': {
                      MozAppearance: 'textfield',
                    },
                    '.MuiOutlinedInput-root': {
                      height: '2.5rem',
                      borderRadius: '.5rem',
                    },
                  }}
                  placeholder='کمترین اندازه(KB)'
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name='maxSize'
              defaultValue=''
              render={({ field }) => (
                <TextField
                  type='number'
                  sx={{
                    width: { xs: '100%', md: '10rem' },
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: 'none',
                      },
                    '& input[type=number]': {
                      MozAppearance: 'textfield',
                    },
                    '.MuiOutlinedInput-root': {
                      height: '2.5rem',
                      borderRadius: '.5rem',
                    },
                  }}
                  placeholder='بیشترین اندازه(KB)'
                  {...field}
                />
              )}
            />
          </Grid2>
          <Grid2
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: '.5rem',
            }}
          >
            <Button
              onClick={onDeleteFilter}
              // disabled={searchParams.toString().length === 0}
              startIcon={<DeleteIcon />}
              variant='outlined'
              color='error'
              sx={{
                minWidth: 'fit-content',
                width: 'fit-content',
                height: '2rem',
                padding: '.5rem',
                borderRadius: '.5rem',
              }}
            >
              حذف فیلتر
            </Button>
            <Button
              onClick={onFilter}
              variant='outlined'
              color='info'
              sx={{
                minWidth: 'fit-content',
                width: 'fit-content',
                height: '2rem',
                padding: '.5rem',
                borderRadius: '.5rem',
              }}
            >
              اعمال
            </Button>
          </Grid2>
        </Grid2>
      </ColumnStack>
    );
  }
);

export default FileFilter;

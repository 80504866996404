import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// Custom Routes
import publicRoutes from 'core/configs/publicRoutes';

const PublicRoutesProvider: React.FC = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          {publicRoutes.map((route) => (
            <Route
              key={`public-route-${route.path}`}
              path={route.path}
              element={React.createElement(route.Component)}
            />
          ))}
          <Route path='*' element={<Navigate to='/login' />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default PublicRoutesProvider;

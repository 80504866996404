import * as React from 'react';
import isEqual from 'lodash/isEqual';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';

// Core Components
import Collapse from 'core/components/base/utils/Collapse';

// Common Components
import Pagination from 'core/components/shared/Filter/Pagination';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';
import RowStack from 'core/components/shared/Stack/RowStack';
import PageSize from 'core/components/shared/Filter/PageSize';

// Feature Components
import FilesTable from 'features/file/files/components/shared/Table';
import FileFilter from 'features/file/files/components/list/Filter';
import SearchInput from 'features/file/files/components/list/SearchInput';
import InsertButton from 'features/file/files/components/shared/Dialog/InsertButton';

// Context
import { useDocsContext } from 'core/hooks/api/useDocs/Context';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { FileProps } from 'features/file/files/types';
import type { UseArrayReturnProps } from 'core/hooks/useArray';

interface FileManagerSectionProps {
  files: UseArrayReturnProps<FileProps>;
  selectedFiles: FileProps[];
  onInsertFile: () => void;
  onSelectFile: (file: FileProps, checked: boolean) => void;
  onToggleAll: () => void;
  onFilter: () => void;
  onDeleteFilter: () => void;
  onFileUpdated?: (file: FileProps) => void;
}

const FileManagerSection: React.FC<FileManagerSectionProps> = (props) => {
  // Props
  const {
    files,
    selectedFiles,
    onInsertFile,
    onToggleAll,
    onSelectFile,
    onDeleteFilter,
    onFilter,
    onFileUpdated,
  } = props;

  // States
  const showFilter = useBoolean();

  // Context
  const loading = useSelectLoading();
  const {
    data: { list, page },
    onPageNumChange,
    onPageSizeChange,
  } = useDocsContext<FileProps>();

  // Render
  return (
    <ColumnStack
      spacing='1rem'
      sx={{ borderRadius: '1rem', paddingBottom: '1rem' }}
    >
      <ColumnStack>
        <Collapse in={showFilter.state}>
          <FileFilter
            onFilter={onFilter}
            onDeleteFilter={onDeleteFilter}
            onCloseFilter={showFilter.setFalse}
            onShowFilter={showFilter.setTrue}
          />
        </Collapse>
        <Collapse in={!showFilter.state}>
          <SearchInput onSearch={onFilter} onShowFilter={showFilter.setTrue} />
        </Collapse>
      </ColumnStack>
      <FilesTable
        titleClickBehavior='popup'
        disableDragAndDrop
        files={files}
        onFileUpdated={onFileUpdated}
        onSelectFile={onSelectFile}
        onToggleAll={onToggleAll}
        selectedFiles={selectedFiles}
      />
      <SpaceBetween sx={{ paddingX: '1rem' }}>
        <RowStack alignItems='center'>
          <PageSize
            disabled={loading || (list || []).length === 0}
            page={page}
            onSizeChange={onPageSizeChange}
            onPageChange={onPageNumChange}
            sx={{ height: '2rem' }}
          />
          <Pagination
            disabled={loading}
            page={page}
            onPageChange={onPageNumChange}
            rtl
            paginationItemSx={{
              backgroundColor: 'background.paper',
              border: 'none',
            }}
          />
        </RowStack>
        <InsertButton
          onClick={onInsertFile}
          disabled={selectedFiles.length === 0}
        />
      </SpaceBetween>
    </ColumnStack>
  );
};

export default React.memo(FileManagerSection, isEqual);

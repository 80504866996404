import type { FC } from 'react';
import type { Editor } from '@tiptap/react';

// Core Components
import Box from 'core/components/base/layout/Box';
import EditorQuoteIconButton from 'core/components/shared/Editor/Toolbar/IconButton/Quote';
import EditorListIconButton from 'core/components/shared/Editor/Toolbar/IconButton/List';
import EditorFontSizeIconButton from 'core/components/shared/Editor/Toolbar/IconButton/FontSize';
import EditorColorIconButton from 'core/components/shared/Editor/Toolbar/IconButton/Color';
import EditorHeadingIconButton from 'core/components/shared/Editor/Toolbar/IconButton/Heading';

// Custom Types
export interface EditorToolbarProps {
  editor: Editor | null;
  hide?: boolean;
}

const EditorToolbar: FC<EditorToolbarProps> = (props) => {
  // Props
  const { editor, hide } = props;

  return (
    <Box
      dir='auto'
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.25,
        opacity: hide ? 0 : 1,
        transition: 'all 150ms',
        bgcolor: 'background.default',
        width: 'fit-content',
        ml: 'auto',
        borderRadius: '0.5rem',
        p: '0.25rem',
      }}
    >
      <EditorQuoteIconButton editor={editor} />
      <EditorListIconButton editor={editor} />
      <EditorHeadingIconButton editor={editor} />
      <EditorFontSizeIconButton editor={editor} />
      <EditorColorIconButton editor={editor} />
    </Box>
  );
};

export default EditorToolbar;

import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  styled,
} from '@mui/material';

export interface ButtonProps extends MuiButtonProps {
  to?: string;
}

const Button = styled(MuiButton)<ButtonProps>(() => ({
  boxShadow: 'none',
  ':hover': {
    boxShadow: 'none',
  },
}));

export default Button;

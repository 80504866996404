// Core Utilities
import { generateObjectId } from 'core/utilities/helper/id';

// Feature Types
import type { ProcessProps } from 'features/form/processes/types/details';

export const genInitialProcessValue = (): ProcessProps => ({
  id: `${generateObjectId()}-process-draft`,
  data: {
    title: 'فرایند جدید',
    formId: '',
    formTitle: '',
    feedbackId: '',
    isActive: false,
    items: [],
    progressStack: [],
  },
});

// Custom Hooks
import useDocApi from 'core/hooks/api/useDoc';
import useDocsApi from 'core/hooks/api/useDocs';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  addAudienceFolder,
  deleteAudienceFolders,
  editAudienceFolder,
  getAudienceFolder,
  getAudienceFolders,
} from 'features/audience/folders/utilities/api/api';

// Custom Types
import type {
  AudienceFolderDataProps,
  AudienceFolderProps,
} from 'features/audience/folders/types';
import type {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';

/**
 * Query key for fetching audience folders in react query.
 *
 * @constant {Array<string>}
 */
export const audienceFoldersQueryKey = ['audienceFolders'];

/**
 * Custom hook to fetch audience folders.
 *
 * This hook utilizes the `useDocsApi` to retrieve audience folders based on the provided options.
 *
 * @param {UseDocsOptionType<AudienceFolderProps>} [options] - Optional configuration options for fetching the audience folders.
 * @returns {UseDocsApiReturnProps<AudienceFolderProps>} The result of the `useDocApi` hook, which includes the fetched audience folders, loading state, and any errors.
 */
export const useAudienceFoldersDoc = (
  options?: UseDocsOptionType<AudienceFolderProps>
): UseDocsApiReturnProps<AudienceFolderProps> =>
  useDocsApi<AudienceFolderProps>(
    getAudienceFolders,
    audienceFoldersQueryKey,
    options
  );

/**
 * Custom hook to fetch an audience folder document.
 *
 * This hook utilizes the `useDocApi` to retrieve a specific audience folder based on the provided options.
 *
 * @param {UseDocOptionType<AudienceFolderProps>} [options] - Optional configuration options for fetching the audience folder document.
 * @returns {UseDocApiReturnProps<AudienceFolderProps>} The result of the `useDocApi` hook, which includes the fetched audience folder document, loading state, and any errors.
 */
export const useAudienceFolderDoc = (
  options?: UseDocOptionType<AudienceFolderProps>
): UseDocApiReturnProps<AudienceFolderProps> =>
  useDocApi<AudienceFolderProps>(
    getAudienceFolder,
    audienceFoldersQueryKey,
    options
  );

/**
 * Custom hook for deleting audience folders.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the delete mutation, including status and other information.
 */
export const useDeleteAudienceFoldersMutation = useDeleteMutation<string[]>(
  deleteAudienceFolders,
  audienceFoldersQueryKey
);

/**
 * Custom hook for adding a new audience folder.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the add mutation, including status and other information.
 */
export const useAddAudienceFolderMutation =
  useAddMutation<AudienceFolderDataProps>(
    addAudienceFolder,
    audienceFoldersQueryKey
  );

/**
 * Custom hook for editing an existing audience folder.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the edit mutation, including status and other information.
 */
export const useEditAudienceFolderMutation =
  useEditMutation<AudienceFolderDataProps>(
    editAudienceFolder,
    audienceFoldersQueryKey
  );

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface PlusCircleIconProps extends SvgIconProps {}

const PlusCircleIcon: React.FC<PlusCircleIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm4.23,11.85H12.85v3.38a.84.84,0,0,1-.25.6.85.85,0,0,1-1.2,0,.84.84,0,0,1-.25-.6V12.85H7.77a.84.84,0,0,1-.6-.25.85.85,0,0,1,0-1.2.84.84,0,0,1,.6-.25h3.38V7.77a.84.84,0,0,1,.25-.6.85.85,0,0,1,1.2,0,.84.84,0,0,1,.25.6v3.38h3.38a.84.84,0,0,1,.6.25.85.85,0,0,1,0,1.2A.84.84,0,0,1,16.23,12.85Z' />
  </IconBuilder>
);

export default PlusCircleIcon;

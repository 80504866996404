import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentFocus: '',
};

export const processSlice = createSlice({
  name: 'process',
  initialState,
  reducers: {
    onResetState: (state) => {
      state.currentFocus = '';
      return state;
    },
    onProcessFocusChange: (state, action: PayloadAction<string>) => {
      state.currentFocus = action.payload;
      return state;
    },
  },
});

export const actions = processSlice.actions;
export default processSlice.reducer;

// Custom Types
import type { ProfilePatternDataProps } from 'features/appBuilder/patterns/features/profile/types';

/**
 * Validates the profile pattern data and returns an error message if validation fails.
 *
 * @param {ProfilePatternDataProps} data - The profile pattern data to validate.
 * @returns {string} A string containing the error message if validation fails, or an empty string if the data is valid.
 */
export const validateProfilePattern = (
  data: ProfilePatternDataProps
): string => {
  if (!data.patternTitle.trim()) {
    return 'وارد کردن عنوان پترن الزامی می‌باشد.';
  }

  return '';
};

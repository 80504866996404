import * as React from 'react';
import store from 'core/store';
import cloneDeep from 'lodash/cloneDeep';
import { alpha, useTheme } from '@mui/material';

// Custom Core Components
import Paper from 'core/components/base/surfaces/Paper';
import Button from 'core/components/base/inputs/Button';

// Custom Icons
import SaveIcon from 'core/components/icons/Save';

// Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { forLoop, setAppAlert, setAppLoading } from 'core/utilities/helper';
import {
  updateMenu,
  updateOriginalItems,
} from 'core/store/slices/feature/menu';

// Context
import { useUpdateMenuItemsMutation } from 'features/menu/menus/hooks';
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';
import {
  useSelectMenuActionMode,
  useSelectMenuItems,
  useSelectOriginalMenuItems,
} from 'core/store/slices/feature/menu';

// Custom Types
interface SubmitButtonProps {}

const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
  // Context
  const actionMode = useSelectMenuActionMode();
  const loading = useSelectLoading();
  const originalItems = useSelectOriginalMenuItems();
  const currentItems = useSelectMenuItems();

  // Hooks
  const { palette } = useTheme();

  const { mutate: handleUpdateMenuItems } = useUpdateMenuItemsMutation({
    autoAlert: { mode: 'edit', name: 'فهرست' },
    onSuccess: ({ data }) => {
      if (!data) return;
      forLoop(data, (item) =>
        updateMenu(item.id, {
          ...item.data,
          image: item.data?.image || null,
        })
      );
      updateOriginalItems();
    },
  });

  // Utilities
  const handleSubmit = async () => {
    const { current, originalItemsMap } = store.getState().menu;
    const items = cloneDeep(current?.data.items || []);
    let editedItems = items.filter(
      (item) => !isSame(item, originalItemsMap[item.id])
    );
    if (editedItems.length === 0)
      return setAppAlert('تغییری در اطلاعات منو مشاهده نشد', 'info');
    setAppLoading(true);

    // Set ParentId
    forLoop(editedItems, (item) => {
      item.data.parentId =
        item.data.parentIds.length > 0
          ? item.data.parentIds.at(-1) || null
          : null;
    });

    // API Call
    handleUpdateMenuItems(editedItems);
  };

  // Render
  return (
    <Button
      component={Paper}
      disabled={
        loading ||
        actionMode !== 'NORMAL' ||
        isSame(originalItems, currentItems)
      }
      color='inherit'
      startIcon={<SaveIcon />}
      onClick={handleSubmit}
      sx={{
        backgroundColor: palette.background.paper,
        color: palette.info.main,
        minWidth: 'fit-content',
        borderRadius: '.5rem',
        padding: '.5rem .75rem',
        ':hover': {
          backgroundColor: alpha(palette.background.paper, 0.8),
        },
      }}
    >
      ذخیره
    </Button>
  );
};

export default SubmitButton;

// Custom Hooks
import useDocApi from 'core/hooks/api/useDoc';
import useDocsApi from 'core/hooks/api/useDocs';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  addAudience,
  deleteAudiences,
  editAudience,
  getAudience,
  getAudiences,
  updateAudienceStatus,
} from 'features/audience/audiences/utilities/api';

// Custom Types
import type {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';
import type {
  AudienceDataProps,
  AudienceProps,
} from 'features/audience/audiences/types';
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';

/**
 * Query key for fetching audiences in react query.
 *
 * @constant {Array<string>}
 */
export const audiencesQueryKey = ['audiences'];

/**
 * Custom hook to fetch audiences list.
 *
 * This hook utilizes the `useDocsApi` to retrieve audiences list based on the provided options.
 *
 * @param {UseDocsOptionType<AudienceProps>} [options] - Optional configuration options for fetching the audiences list.
 * @returns {UseDocsApiReturnProps<AudienceProps>} The result of the `useDocApi` hook, which includes the fetched audiences list, loading state, and any errors.
 */
export const useAudienceDocs = (
  options?: UseDocsOptionType<AudienceProps>
): UseDocsApiReturnProps<AudienceProps> =>
  useDocsApi<AudienceProps>(getAudiences, audiencesQueryKey, options);

/**
 * Custom hook to fetch an audience document.
 *
 * This hook utilizes the `useDocApi` to retrieve a specific audience based on the provided options.
 *
 * @param {UseDocOptionType<AudienceProps | null>} [options] - Optional configuration options for fetching the audience document.
 * @returns {UseDocApiReturnProps<AudienceProps | null>} The result of the `useDocApi` hook, which includes the fetched audience document, loading state, and any errors.
 */
export const useAudienceDoc = (
  options?: UseDocOptionType<AudienceProps | null>
): UseDocApiReturnProps<AudienceProps | null> =>
  useDocApi<AudienceProps | null>(getAudience, audiencesQueryKey, options);

/**
 * Custom hook for deleting an audience.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the delete mutation, including status and potential errors.
 */
export const useDeleteAudienceMutation = useDeleteMutation<string[]>(
  deleteAudiences,
  audiencesQueryKey
);

/**
 * Custom hook for adding a new audience.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the add mutation, including status and potential errors.
 */
export const useAddAudienceMutation = useAddMutation<{
  phoneNumbers: string[];
  jobTitle: string;
  audienceGroups: string[];
}>(addAudience, audiencesQueryKey);

/**
 * Custom hook for editing an existing audience.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the edit mutation, including status and potential errors.
 */
export const useEditAudienceMutation = useEditMutation<AudienceDataProps>(
  editAudience,
  audiencesQueryKey
);

/**
 * Custom hook for updating the status of an audience.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the update status mutation, including status and potential errors.
 */
export const useUpdateAudienceStatusMutation = useEditMutation<boolean>(
  updateAudienceStatus,
  audiencesQueryKey
);

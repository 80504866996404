import * as React from 'react';

// Core Components
import IconButton, {
  IconButtonProps,
} from 'core/components/base/inputs/IconButton';

// Icon Components
import ContrastIcon from 'core/components/icons/Contrast';

interface ThemeTogglerIconButtonProps extends IconButtonProps {}

const ThemeTogglerIconButton: React.FC<ThemeTogglerIconButtonProps> = (
  props
) => {
  const { children, ...otherProps } = props;

  return (
    <IconButton {...otherProps}>
      <ContrastIcon
        color='inherit'
        fontSize='inherit'
        sx={{ transform: 'rotate(-180deg)' }}
      />
    </IconButton>
  );
};

export default ThemeTogglerIconButton;

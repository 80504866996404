// Custom Routes
import contentRoutes from 'features/content/contents/configs/contents';
import categoryRoutes from 'features/content/categories/configs/routes';
import tagRoutes from 'features/content/tags/configs/routes';
import bannersRoutes from 'features/banner/banners/configs/routes';
import menusRoutes from 'features/menu/menus/configs/routes';
import filesRoutes from 'features/file/files/configs/routes';
import feedbacksRoutes from 'features/form/feedbacks/configs/routes';
import formsRoutes from 'features/form/forms/configs/routes';
import managersRoutes from 'features/manager/managers/configs/routes';
import managerGroupsRoutes from 'features/manager/groups/configs/routes';
import managerSettingsRoutes from 'features/manager/settings/configs/routes';
import audienceAccessesRoutes from 'features/audience/accesses/configs/routes';
import audiencesRoutes from 'features/audience/audiences/configs/routes';
import audienceFoldersRoutes from 'features/audience/folders/configs/routes';
import audienceGroupsRoutes from 'features/audience/groups/configs/routes';
import audienceSettingsRoutes from 'features/audience/settings/configs/routes';
import ticketsRoutes from 'features/ticket/tickets/configs/routes';
import ticketCategoriesRoutes from 'features/ticket/topics/configs/routes';
import customPagesRoutes from 'features/appBuilder/customPages/configs/routes';
import patternsRoutes from 'features/appBuilder/patterns/configs/routes';
import skinsRoutes from 'features/appBuilder/skins/configs/routes';
import templatesRoutes from 'features/appBuilder/templates/configs/routes';
import sheetRoutes from 'features/data/sheets/configs/routes';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import processesRoutes from 'features/form/processes/configs/routes';

const protectedRoutes: ProtectedRouteProps[] = [
  // CONTENT
  ...contentRoutes,
  ...categoryRoutes,
  ...tagRoutes,
  // FILE
  ...filesRoutes,
  // FORM
  ...formsRoutes,
  ...feedbacksRoutes,
  ...processesRoutes,
  // MANAGER
  ...managersRoutes,
  ...managerGroupsRoutes,
  ...managerSettingsRoutes,
  // AUDIENCE
  ...audienceAccessesRoutes,
  ...audiencesRoutes,
  ...audienceFoldersRoutes,
  ...audienceGroupsRoutes,
  ...audienceSettingsRoutes,
  // MENU
  ...menusRoutes,
  // BANNER
  ...bannersRoutes,
  // TEMPLATE
  ...customPagesRoutes,
  ...patternsRoutes,
  ...skinsRoutes,
  ...templatesRoutes,
  // DATA
  ...sheetRoutes,
  // TICKET
  ...ticketsRoutes,
  ...ticketCategoriesRoutes,
];

export default protectedRoutes;

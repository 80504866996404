import { useState, type FC, useEffect, useCallback } from 'react';

// Custom Core Components
import Box from 'core/components/base/layout/Box';

// Custom Components
import PatternToolbarIconButton from './Icon';
import TextFieldNumber from 'core/components/shared/TextField/number';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Icon Components
import AddRowIcon from 'core/components/icons/AddRow';
import ConfigIcon from 'core/components/icons/Config';
import DeleteIcon from 'core/components/icons/DeleteIcon';
import DuplicateIcon from 'core/components/icons/Duplicate';
import CellHeightIcon from 'core/components/icons/CellHeight';

// Custom Hooks
import { useAppDispatch, useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import { updateCellsStyles } from 'features/appBuilder/patterns/features/table/store';

// Custom Types
import type { ToolbarMethods } from '.';
export interface TablePatternRowToolbarProps extends Partial<ToolbarMethods> {}

const TablePatternRowToolbar: FC<TablePatternRowToolbarProps> = (props) => {
  // Props
  const { onAddRow, onDuplicateRow, onDeleteRow, onStylesOpen } = props;

  // States
  const [height, setHeight] = useState<number>(35);

  // Hooks
  const dispatch = useAppDispatch();
  const { selection, rowCount } = useAppSelector((state) => state.table.data);

  const handleHeightUpdate = useCallback(() => {
    if (selection.length > 0) {
      const heightToSet = selection[0].styles.height;

      if (height !== heightToSet) setHeight(heightToSet);
    }
  }, [selection]);

  useEffect(() => {
    handleHeightUpdate();
  }, [selection, handleHeightUpdate]);

  const updateHeightInState = () => {
    if (selection.length > 0) {
      const stylesToSet = selection[0].styles;
      const heightToSet = !height || height < 35 ? 35 : height;
      dispatch(
        updateCellsStyles({
          cells: selection,
          styles: {
            ...stylesToSet,
            height: heightToSet,
          },
        })
      );
      if (!height || height < 35) setHeight(heightToSet);
    }
  };

  const items = [
    {
      title: 'duplicate',
      Icon: <DuplicateIcon />,
      handler: onDuplicateRow,
      disabled: rowCount >= 100,
    },
    {
      title: 'addRow',
      Icon: <AddRowIcon />,
      handler: onAddRow,
      disabled: rowCount >= 100,
    },
    { title: 'config', Icon: <ConfigIcon />, handler: onStylesOpen },
    {
      title: 'delete',
      Icon: <DeleteIcon />,
      handler: onDeleteRow,
      disabled: rowCount < 2,
    },
  ];

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {items.map(({ title, handler, Icon, ...otherProps }, index) => (
        <PatternToolbarIconButton
          key={title + index}
          onClick={handler}
          {...otherProps}
        >
          {Icon}
        </PatternToolbarIconButton>
      ))}
      <Box sx={{ display: 'flex' }}>
        <PatternToolbarIconButton
          sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        >
          <CellHeightIcon />
        </PatternToolbarIconButton>
        <TextFieldNumber
          dir='ltr'
          sx={{
            '.MuiInputBase-input': {
              paddingY: '12px',
            },
            width: '6rem',
          }}
          value={height}
          onChange={(e) => {
            const value = e.target.value;

            setHeight(parseInt(value));
          }}
          onBlur={updateHeightInState}
          InputProps={{
            sx: {
              borderRadius: '0.5rem',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
            endAdornment: (
              <>
                <BodyTwo sx={{ ml: '0.5rem' }}>px</BodyTwo>
              </>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default TablePatternRowToolbar;

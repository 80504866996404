import { ThemeOptions } from '@mui/material';

// global theme options, these options are common between light and dark theme
import globalThemeOptions from '../globalThemeOptions';

const darkThemeOptions: ThemeOptions = {
  ...globalThemeOptions,
  palette: {
    mode: 'dark',
    primary: {
      main: '#1167b1',
      dark: '#2976b9',
      light: '#0f5d9f',
      contrastText: '#ffffff',
    },
    info: {
      main: '#109cf1',
      dark: '#0e8cd9',
      light: '#28a6f2',
      contrastText: '#ffffff',
    },
  },
};

export default darkThemeOptions;

import { type FC } from 'react';
import {
  Controller,
  useFormContext,
  type SubmitHandler,
} from 'react-hook-form';

// Custom Common Components
import ActionsBox from 'core/components/shared/Box/Actions';
import LoadingButton from 'core/components/base/inputs/LoadingButton';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { ManagerGroupDataProps } from 'features/manager/groups/types';
export interface FormInputs
  extends Pick<ManagerGroupDataProps, 'permissions' | 'title'> {}
export interface ManagerGroupAddActionsProps {
  mode?: 'edit' | 'create';
  onSubmit?: (data: FormInputs) => void;
}

/**
 * ManagerGroupAddActions component
 * @component
 * @param {'edit' | 'create'} [props.mode] - Specifies the mode of the actions.
 * @param {() => void} [props.onSubmit] - Callback function to be called when the submit button pressed.
 * @returns {JSX.Element} - The rendered component.
 */
const ManagerGroupAddActions: FC<ManagerGroupAddActionsProps> = (props) => {
  // Props
  const { mode = 'create', onSubmit } = props;

  // Hooks
  const loading = useSelectLoading();
  const { control, handleSubmit } = useFormContext<FormInputs>();

  // Utilities
  const handleFormSubmit: SubmitHandler<FormInputs> = (data) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  return (
    <ActionsBox>
      <PageTitle>{`${
        mode === 'create' ? 'ایجاد ' : 'ویرایش '
      } گروه مدیران`}</PageTitle>
      <RowStack>
        <Controller
          control={control}
          name='title'
          render={({ field }) => (
            <LoadingButton
              loading={field.value && loading ? true : false}
              loadingPosition='center'
              disabled={!field.value}
              onClick={handleSubmit(handleFormSubmit)}
              sx={{ width: '10rem' }}
            >
              ذخیره
            </LoadingButton>
          )}
        />
      </RowStack>
    </ActionsBox>
  );
};

export default ManagerGroupAddActions;

// Core Utilities
import { getUrlWithQueryString } from 'core/utilities/helper/helperPack';
import { forLoop } from '../helper';

// Custom Types
import type { PageProps } from 'core/types/shared/pagination';

const genEndpointsOf = (basePath: string) => ({
  get add() {
    return `/${basePath}/add`;
  },
  edit(id?: string) {
    return `/${basePath}/edit${id ? `/${id}` : ''}`;
  },
  editDocs(id?: string) {
    return `/${basePath}/editDocs${id ? `/${id}` : ''}`;
  },
  get remove() {
    return `/${basePath}/remove`;
  },
  removeById(id: string) {
    return `/${basePath}/remove/${id}`;
  },
  getById(id: string) {
    return `/${basePath}/getDoc/${id}`;
  },
  getList(query?: Record<string, any>) {
    if (query) return getUrlWithQueryString(`/${basePath}/getDocs`, query);

    return `/${basePath}/getDocs`;
  },
  getSummaryListByParams(params: { [key: string]: string }) {
    let endpoint = `/${basePath}/getList`;

    if (Object.keys(params).length > 0) {
      endpoint += '?';
      forLoop(Object.keys(params), (key: string, index: number) => {
        endpoint += `${key}=${params[key]}`;
        if (index !== 0) {
          endpoint += '&';
        }
      });
    }

    return endpoint;
  },
  getListSummary(ids: string[]) {
    return `/${basePath}/getList?ids=${ids.join('&ids=')}`;
  },
  getListById(
    id: string,
    configs?: { page?: PageProps; paramsString?: string }
  ) {
    let endpoint = `/${basePath}/getDocs/${id}`;
    let hasParams = false;

    const initParams = () => {
      if (!hasParams) {
        endpoint += '?';
        hasParams = true;
      }
    };

    if (configs?.page) {
      initParams();
      endpoint += `page=${configs.page.current}&limit=${configs.page.size}`;
    }

    if (configs?.paramsString) {
      initParams();
      if (configs.page) {
        endpoint += '&';
      }

      endpoint += configs.paramsString;
    }

    return endpoint;
  },
  getAll(id?: string, params?: { [key: string]: string }) {
    let url = `/${basePath}/getAll`;

    if (id) {
      url += `/${id}`;
    }

    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach((key, index) => {
        if (index === 0) url += '?';
        url += `${key}=${params[key]}`;
      });
    }

    return url;
  },
  getDocSummary(id: string) {
    return `/${basePath}/getDocSummary/${id}`;
  },
  get getSummaryList() {
    return `/${basePath}/getList`;
  },
  getSummaryListById(id: string) {
    return `/${basePath}/getList/${id}`;
  },
  getDocsDetails(formId: string, ids: string[]) {
    return `/${basePath}/getDocsDetails?formId=${formId}&ids=${ids.join(
      '&ids='
    )}`;
  },
  groupOperation(operation: string, params?: string) {
    return `/${basePath}/groupOperation/${operation}${
      params ? `?${params}` : ''
    }`;
  },
  copyById(id: string) {
    return `/${basePath}/copy/${id}`;
  },
});

export { genEndpointsOf };

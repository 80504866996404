import type { FC } from 'react';

// Core Components
import SaveIcon from 'core/components/icons/Save';
import LoadingIconButton, {
  LoadingIconButtonProps,
} from 'core/components/base/inputs/LoadingIconButton';

// Custom Types
export interface SaveIconButton extends LoadingIconButtonProps {}

const SaveIconButton: FC<SaveIconButton> = (props) => {
  // Props
  const { color = 'info', disableRipple = true, sx, ...otherProps } = props;

  return (
    <LoadingIconButton
      color={color}
      disableRipple={disableRipple}
      sx={{
        bgcolor: 'background.paper',
        borderRadius: 2,
        ':active': { bgcolor: 'rgba(255, 255, 255, 0.6)' },
        ...sx,
      }}
      {...otherProps}
    >
      <SaveIcon />
    </LoadingIconButton>
  );
};

export default SaveIconButton;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface SortIconProps extends SvgIconProps {}

const SortIcon: React.FC<SortIconProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <path
        d='M16.2,2H7.8C4.2,2,2,4.2,2,7.8v8.4C2,19.8,4.2,22,7.8,22h8.4c3.6,0,5.8-2.2,5.8-5.8V7.8C22,4.2,19.8,2,16.2,2z M13.3,17
	h-2.7c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h2.7c0.4,0,0.8,0.3,0.8,0.8S13.7,17,13.3,17z M16,12.8H8c-0.4,0-0.8-0.3-0.8-0.8
	s0.3-0.8,0.8-0.8h8c0.4,0,0.8,0.3,0.8,0.8S16.4,12.8,16,12.8z M18,8.5H6c-0.4,0-0.8-0.3-0.8-0.8S5.6,7,6,7h12c0.4,0,0.8,0.3,0.8,0.8
	S18.4,8.5,18,8.5z'
      />
    </IconBuilder>
  );
};

export default SortIcon;

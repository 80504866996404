import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface OrderedListProps extends SvgIconProps {}

const OrderedListIcon: React.FC<OrderedListProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <path d='M10 13.6H20V10.4H10V13.6ZM10 20H20V16.8H10V20ZM10 4V7.2H20V4H10Z' />
      <path d='M6.13281 4.08594V6.99805H5.32812V5.08984C5.19792 5.1888 5.07161 5.26888 4.94922 5.33008C4.82812 5.39128 4.67578 5.44987 4.49219 5.50586V4.85352C4.76302 4.76628 4.97331 4.66146 5.12305 4.53906C5.27279 4.41667 5.38997 4.26562 5.47461 4.08594H6.13281Z' />
      <path d='M6.6582 13.498H4.27148C4.29883 13.2624 4.38151 13.041 4.51953 12.834C4.65885 12.6257 4.91927 12.3802 5.30078 12.0977C5.53385 11.9245 5.68294 11.793 5.74805 11.7031C5.81315 11.6133 5.8457 11.528 5.8457 11.4473C5.8457 11.36 5.81315 11.2858 5.74805 11.2246C5.68424 11.1621 5.60352 11.1309 5.50586 11.1309C5.4043 11.1309 5.32096 11.1628 5.25586 11.2266C5.19206 11.2904 5.14909 11.403 5.12695 11.5645L4.33008 11.5C4.36133 11.276 4.41862 11.1016 4.50195 10.9766C4.58529 10.8503 4.70247 10.7539 4.85352 10.6875C5.00586 10.6198 5.21615 10.5859 5.48438 10.5859C5.76432 10.5859 5.98177 10.6178 6.13672 10.6816C6.29297 10.7454 6.41536 10.8438 6.50391 10.9766C6.59375 11.1081 6.63867 11.2559 6.63867 11.4199C6.63867 11.5944 6.58724 11.7611 6.48438 11.9199C6.38281 12.0788 6.19727 12.2533 5.92773 12.4434C5.76758 12.554 5.66016 12.6315 5.60547 12.6758C5.55208 12.7201 5.48893 12.778 5.41602 12.8496H6.6582V13.498Z' />
      <path d='M5.10156 17.7676L4.34961 17.6328C4.41211 17.3932 4.5319 17.2096 4.70898 17.082C4.88737 16.9544 5.13932 16.8906 5.46484 16.8906C5.83854 16.8906 6.10872 16.9603 6.27539 17.0996C6.44206 17.2389 6.52539 17.4141 6.52539 17.625C6.52539 17.7487 6.49154 17.8607 6.42383 17.9609C6.35612 18.0612 6.25391 18.1491 6.11719 18.2246C6.22786 18.252 6.3125 18.2839 6.37109 18.3203C6.46615 18.3789 6.53971 18.4564 6.5918 18.5527C6.64518 18.6478 6.67188 18.7617 6.67188 18.8945C6.67188 19.0612 6.62826 19.2214 6.54102 19.375C6.45378 19.5273 6.32812 19.6452 6.16406 19.7285C6 19.8105 5.78451 19.8516 5.51758 19.8516C5.25716 19.8516 5.05143 19.821 4.90039 19.7598C4.75065 19.6986 4.62695 19.6094 4.5293 19.4922C4.43294 19.3737 4.35872 19.2253 4.30664 19.0469L5.10156 18.9414C5.13281 19.1016 5.18099 19.2129 5.24609 19.2754C5.3125 19.3366 5.39648 19.3672 5.49805 19.3672C5.60482 19.3672 5.69336 19.3281 5.76367 19.25C5.83529 19.1719 5.87109 19.0677 5.87109 18.9375C5.87109 18.8047 5.83659 18.7018 5.76758 18.6289C5.69987 18.556 5.60742 18.5195 5.49023 18.5195C5.42773 18.5195 5.3418 18.5352 5.23242 18.5664L5.27344 17.998C5.31771 18.0046 5.35221 18.0078 5.37695 18.0078C5.48112 18.0078 5.56771 17.9746 5.63672 17.9082C5.70703 17.8418 5.74219 17.763 5.74219 17.6719C5.74219 17.5846 5.71615 17.515 5.66406 17.4629C5.61198 17.4108 5.54036 17.3848 5.44922 17.3848C5.35547 17.3848 5.2793 17.4134 5.2207 17.4707C5.16211 17.5267 5.1224 17.6257 5.10156 17.7676Z' />
    </IconBuilder>
  );
};

export default OrderedListIcon;

import type { FC } from 'react';
import type { Editor } from '@tiptap/react';

// Feature Components
import QuoteIcon from 'features/form/feedbacks/components/icons/Quote';

// Core Components
import IconButton from 'core/components/base/inputs/IconButton';

// Component Types
interface EditorQuoteIconButtonProps {
  editor: Editor | null;
}

const EditorQuoteIconButton: FC<EditorQuoteIconButtonProps> = (props) => {
  // Hooks
  const { editor } = props;

  // Utilities
  const toggleBlockquote = () => {
    editor?.chain().focus().toggleBlockquote().run();
  };

  return (
    <IconButton
      onClick={toggleBlockquote}
      color={editor?.isActive('blockquote') ? 'info' : 'inherit'}
      size='small'
    >
      <QuoteIcon />
    </IconButton>
  );
};

export default EditorQuoteIconButton;

// Custom Utilitis
import apiHandler from 'core/utilities/apiHandler';
import { getDoc, getDocs, getSummary } from 'core/utilities/apiHandler/helper';
import { managerGroupEndpoints as endpoints } from 'features/manager/groups/utilities/api/endpoints';

// Custom Types
import type {
  ApiGetDocResponse,
  ApiGetDocsResponse,
  ApiGetSummaryResponse,
} from 'core/types/api/hook/response';
import type {
  ManagerGroupDataProps,
  ManagerGroupListItemProps,
  ManagerGroupProps,
  ManagerGroupSummaryProps,
} from 'features/manager/groups/types';

/**
 * Adds a manager group using the provided data.
 * @param {ManagerGroupDataProps} data - The data for the manager group.
 * @returns {Promise<{ status: number; record?: ManagerGroupProps }>} - A promise that resolves to an object containing the status and manager group record (if available).
 */
export const addManagerGroup = async (
  data: Pick<ManagerGroupDataProps, 'permissions' | 'title'>
): Promise<{ status: number; record?: ManagerGroupProps }> => {
  const { status } = await apiHandler.post(endpoints.add, data);

  return { status };
};

/**
 * Edits a manager group with the provided ID using the provided data.
 * @param {string} id - The ID of the manager group to be edited.
 * @param {ManagerGroupDataProps} data - The updated data for the manager group.
 * @returns {Promise<{ status: number; doc?: ManagerGroupProps }>} - A promise that resolves to an object containing the status and manager group record (if available).
 */
export const editManagerGroup = async (
  id: string,
  data: Pick<ManagerGroupDataProps, 'permissions' | 'title'>
): Promise<{ status: number; doc?: ManagerGroupProps }> => {
  const { status, data: response } = await apiHandler.patch<{
    docs: ManagerGroupProps;
  }>(endpoints.edit(id), data);

  return { status, doc: response?.docs };
};

/**
 * Deletes one or more manager groups with the provided IDs.
 * @param {string | string[]} ids - The ID(s) of the manager group(s) to be deleted.
 * @returns {Promise<{status:number}>} - A promise that resolves to the status code.
 */
export const removeManagerGroups = async (
  ids: string | string[]
): Promise<{ status: number }> => {
  const { status } = await apiHandler.delete(`${endpoints.remove}`, {
    ids: typeof ids === 'string' ? [ids] : ids,
  });

  return { status };
};

/**
 * Retrieves a manager group with the provided ID.
 * @param {string} id - The ID of the manager group to retrieve.
 * @returns {Promise<ApiGetDocResponse<ManagerGroupProps>>} - A promise that resolves to an object containing the status and manager group record (if available).
 */
export const getManagerGroup = async (
  id: string,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<ManagerGroupProps>> => {
  const endpoint = endpoints.getById(id);

  return await getDoc<ManagerGroupProps, ManagerGroupProps>(endpoint, {
    signal,
  });
};

/**
 * Retrieves a list of manager group items.
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @param {Record<string, any>} [queries] - Optional query parameters for the request.
 * @returns {Promise<ApiGetDocsResponse<ManagerGroupListItemProps>>} - A promise that resolves to an object containing the status code and the list of manager group items.
 */
export const getManagerGroupsList = async (
  signal?: AbortSignal,
  queries?: Record<string, any>
): Promise<ApiGetDocsResponse<ManagerGroupListItemProps>> => {
  const endpoint = endpoints.getList();

  return await getDocs<ManagerGroupListItemProps[], ManagerGroupListItemProps>(
    endpoint,
    {
      queries,
      signal,
      returnMutationFn: (status, data) => ({ status, list: data || [] }),
    }
  );
};

/**
 * Fetches a summary list of manager groups from the API.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<ApiGetSummaryResponse<ManagerGroupSummaryProps>>}
 *          A promise that resolves to an object containing the HTTP status and an optional list of manager group summaries.
 */
export const getManagerGroupsSummaryList = async (
  signal?: AbortSignal
): Promise<ApiGetSummaryResponse<ManagerGroupSummaryProps>> => {
  const endpoint = endpoints.getSummaryList;

  return await getSummary<ManagerGroupSummaryProps[], ManagerGroupSummaryProps>(
    endpoint,
    { signal }
  );
};

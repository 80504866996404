import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// HOC
import WithAuth from 'core/components/shared/HOC/WithAuth';

// Core Components
import Box from 'core/components/base/layout/Box';
import LoadingButton from 'core/components/base/inputs/LoadingButton';
import Paper from 'core/components/base/surfaces/Paper';
import Slide from 'core/components/base/utils/Slide';
import TextField from 'core/components/base/inputs/TextField';

// Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import CenterBox from 'core/components/shared/Box/CenterBox';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingOne from 'core/components/shared/Typography/HeadingOne';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import Notification from 'core/components/shared/Notification';

// Icon Components
import AuthIcon from 'core/components/icons/Auth';

// Custom Utilities
import { setAppLoading } from 'core/utilities/helper';

// ValidationSchema
import EmailValidationSchema from 'features/manager/auth/validations';

// Custom Hooks
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// Types
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';
import { useAppSelector } from 'core/hooks/redux';

interface LoginProps extends WithAuthProps {}
interface LoginFormProps {
  email: string;
}

const Login: React.FC<LoginProps> = (props) => {
  // Hooks
  const resolver = useYupValidationResolver(EmailValidationSchema);
  const alert = useAppSelector((state) => state.notification);
  const loading = useSelectLoading();
  const navigate = useNavigate();
  const pageWrapperRef = React.useRef(null);
  const { control, formState, handleSubmit } = useForm<LoginFormProps>({
    mode: 'onTouched',
    resolver,
    defaultValues: { email: '' },
  });

  // Utilities
  const onSubmit: SubmitHandler<LoginFormProps> = async ({ email }) => {
    if (email.trim()) {
      setAppLoading(true);

      navigate(`/password-login`, {
        state: { email },
      });

      setAppLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>ورود به داشبورد مدیرتی</title>
      </Helmet>
      <CenterBox
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        innerRef={pageWrapperRef}
      >
        <Slide container={pageWrapperRef.current} direction='right' in={true}>
          <Paper
            sx={{
              borderRadius: '1rem',
              boxShadow: 'none',
              padding: '3rem 6rem 4rem',
            }}
          >
            <ColumnStack spacing={3}>
              <Box>
                <HeadingOne gutterBottom textAlign='center' variant='h6'>
                  ورود
                </HeadingOne>
                <BodyOne textAlign='center'>
                  شناسه کاربری خود را وارد کنید.
                </BodyOne>
              </Box>
              <Box>
                <InputLabel>ایمیل</InputLabel>
                <Controller
                  control={control}
                  name='email'
                  defaultValue=''
                  render={({ field, fieldState }) => (
                    <TextField
                      error={
                        fieldState.isTouched && fieldState.error?.message
                          ? true
                          : false
                      }
                      helperText={
                        fieldState.isTouched && fieldState.error?.message
                      }
                      disabled={loading}
                      dir='ltr'
                      fullWidth
                      placeholder='Email'
                      {...field}
                    />
                  )}
                />
              </Box>
              <Controller
                control={control}
                name='email'
                render={({ field }) => (
                  <LoadingButton
                    disabled={
                      !field.value || formState.errors.email ? true : false
                    }
                    fullWidth
                    loading={loading}
                    startIcon={<AuthIcon color='inherit' fontSize='large' />}
                    sx={{ height: '3rem', width: '21rem' }}
                    type='submit'
                    variant='contained'
                  >
                    ورود با رمز ثابت
                  </LoadingButton>
                )}
              />
            </ColumnStack>
          </Paper>
        </Slide>
      </CenterBox>
      {alert && <Notification />}
    </>
  );
};

export default WithAuth(Login);

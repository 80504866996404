import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

// Core Components
import TableCell from 'core/components/base/display/TableCell';

// Common Components
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Icons
import ExpandLessIcon from 'core/components/icons/ExpandLess';
import ExpandMoreIcon from 'core/components/icons/ExpandMore';
import UnfoldMoreIcon from 'core/components/icons/UnfoldMore';

// Custom Utilities
import { getSearchParamsObject } from 'core/utilities/helper/helperPack';

// Custom Types
interface TableHeadCellProps {
  title: string;
  hasFilter?: boolean;
  query?: string;
  sort?: string;
  onSortChange?: (value: string) => void;
}

const TableHeadCell: React.FC<TableHeadCellProps> = (props) => {
  // Props
  const { title, hasFilter, onSortChange, sort, query } = props;

  // Hooks
  const [searchParams, setSearchParams] = useSearchParams();

  // States
  const handleClick = () => {
    const queries = getSearchParamsObject(searchParams);
    let value = '';
    if (query === 'createdAt') {
      if (sort === `${query}-desc`) value = 'asc';
      else value = '';
    } else {
      if (sort === `${query}-desc`) value = 'asc';
      else if (sort === `${query}-asc`) value = '';
      else value = 'desc';
    }
    if (!value) delete queries.sort;
    else queries.sort = `{"${query}":"${value === 'desc' ? '-1' : '1'}"}`;
    if (onSortChange)
      onSortChange(value ? `${query}-${value}` : `createdAt-desc`);
    setSearchParams(queries);
  };

  // Render
  return (
    <TableCell align='center'>
      {hasFilter ? (
        <RowStack
          onClick={handleClick}
          spacing='.25rem'
          sx={{
            width: 'fit-content',
            margin: '0 auto',
            cursor: 'pointer',
          }}
        >
          <span>{title}</span>
          {sort === `${query}-asc` ? (
            <ExpandLessIcon />
          ) : sort === `${query}-desc` ? (
            <ExpandMoreIcon />
          ) : (
            <UnfoldMoreIcon color='disabled' />
          )}
        </RowStack>
      ) : (
        title
      )}
    </TableCell>
  );
};

export default TableHeadCell;

import type { FC, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';
import IconButton from 'core/components/base/inputs/IconButton';
import Collapse from 'core/components/base/utils/Collapse';
import Divider from 'core/components/base/display/Divider';

// Common Components
import SpacedBox from 'core/components/shared/Box/SpacedBox';
import BodyOne from 'core/components/shared/Typography/BodyOne';
import RowStack from 'core/components/shared/Stack/RowStack';
import AntSwitch from 'core/components/shared/Switch/AntSwitch';
import BinIconButton from 'core/components/shared/IconButton/Bin';
import PatternItemStyles, {
  PatternIncludeArray,
} from 'features/appBuilder/patterns/core/components/shared/items/Styles';

// Custom Icons
import ConfigIcon from 'core/components/icons/Config';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
export interface PatternItemContainerProps {
  reference: string;
  collapsed?: boolean;
  children?: ReactNode;
  title?: string;
  include?: PatternIncludeArray;
  fullwidth?: boolean;
  onCollapsedChange?: (state: boolean) => void;
  onRemove?: () => void;
}

const PatternItemContainer: FC<PatternItemContainerProps> = (props) => {
  // Props
  const {
    reference,
    children,
    collapsed,
    title = 'عنوان آیتم',
    include,
    fullwidth,
    onCollapsedChange,
    onRemove,
  } = props;

  // Hooks
  const { control, getValues, setValue } = useFormContext();
  const loading = useSelectLoading();

  // Utilities
  const handleCollpase = () => {
    onCollapsedChange && onCollapsedChange(!collapsed);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        borderRadius: '0.5rem',
        p: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
      style={{
        width: fullwidth ? '100% important' : undefined,
        maxWidth: fullwidth ? '100% important' : undefined,
      }}
    >
      <SpacedBox sx={{ width: '100%' }}>
        <BodyOne>{title}</BodyOne>
        <RowStack spacing={0.5} sx={{ alignItems: 'center' }}>
          <Collapse in={collapsed}>
            <Controller
              control={control}
              name={`${reference}.isActive`}
              render={({ field }) => (
                <AntSwitch
                  disabled={loading}
                  sx={{ mr: '0.5rem' }}
                  checked={field.value}
                  onChange={(_, checked) => setValue(field.name, checked)}
                />
              )}
            />
          </Collapse>
          {onRemove && <BinIconButton onClick={onRemove} />}
          <IconButton onClick={handleCollpase}>
            <ConfigIcon
              color={collapsed ? 'info' : 'inherit'}
              sx={{
                transform: collapsed ? 'rotate(0)' : 'rotate(360deg)',
                transition: 'all 200ms',
              }}
              fontSize='inherit'
            />
          </IconButton>
        </RowStack>
      </SpacedBox>
      {children}
      <Collapse in={collapsed}>
        <Divider flexItem />
        <PatternItemStyles include={include} reference={reference} />
      </Collapse>
    </Box>
  );
};

export default PatternItemContainer;

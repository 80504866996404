type AlphabetMode = 'lowercase' | 'uppercase';

const lowerAlphabet = 'abcdefghijklmnopqrstuvwxyz';
const upperAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

/**
 * Returns an array of alphabets up to the specified length.
 * @param {number} length - The length of the alphabet array.
 * @param {'lowercase' | 'uppercase'} mode - The mode of the alphabet to use.
 * @returns {string[]} The array of alphabets.
 */
export const getAlphabetArray = (
  length: number,
  mode: AlphabetMode = 'lowercase'
) => {
  const result = [];

  for (let i = 0; i < length; i++) {
    result.push(
      mode === 'lowercase' ? lowerAlphabet.charAt(i) : upperAlphabet.charAt(i)
    );
  }

  return result;
};

/**
 * Returns an array of alphabets starting from the specified index up to the specified length.
 * @param {number} startIndex - The starting index of the alphabet array.
 * @param {number} length - The length of the alphabet array.
 * @param {'lowercase' | 'uppercase'} mode - The mode of the alphabet to use.
 * @returns {string[]} The array of alphabets.
 */
export const getAlphabetArrayRange = (
  startIndex: number,
  length: number,
  mode: 'lowercase' | 'uppercase' = 'lowercase'
) => {
  const result = [];
  const alphabet = mode === 'lowercase' ? lowerAlphabet : upperAlphabet;

  for (let i = startIndex; i < startIndex + length; i++) {
    result.push(alphabet.charAt(i));
  }

  return result;
};

/**
 * Returns the alphabet at the specified index.
 * @param {number} index - The index of the alphabet to return.
 * @param {'lowercase' | 'uppercase'} mode - The mode of the alphabet to use.
 * @returns {string} The alphabet at the specified index.
 */
export const getAlphabetByIndex = (
  index: number,
  mode: AlphabetMode = 'lowercase'
) => {
  const alphabet = mode === 'lowercase' ? lowerAlphabet : upperAlphabet;

  return alphabet.charAt(index);
};

/**
 * Returns the index of the specified alphabet.
 * @param {string} alphabet - The alphabet to find the index of.
 * @returns {number} The index of the specified alphabet.
 */
export const getIndexByAlphabet = (alphabet: string) =>
  lowerAlphabet.indexOf(alphabet.toLocaleLowerCase());

/**
 * Returns the next alphabet key for the given alphabet.
 * @param {string} alphabet - The alphabet key.
 * @returns {string | null} The next alphabet key, or null if the input is not a valid alphabet.
 */
export const getNextAlphabet = (
  alphabet: string,
  mode: AlphabetMode = 'lowercase',
  count: number = 1
): string => {
  if (alphabet.length !== 1) {
    return '';
  }

  const alphabets = mode === 'lowercase' ? lowerAlphabet : upperAlphabet;

  const currentIndex = lowerAlphabet.indexOf(alphabet.toLocaleLowerCase());

  if (currentIndex === alphabets.length - count) {
    return '';
  }

  return alphabets.charAt(currentIndex + count);
};

/**
 * Returns the previous alphabet key for the given alphabet.
 * @param {string} alphabet - The alphabet key.
 * @returns {string | null} The previous alphabet key, or null if the input is not a valid alphabet.
 */
export const getPreviousAlphabet = (
  alphabet: string,
  mode: AlphabetMode = 'lowercase'
): string => {
  if (alphabet.length !== 1) {
    return '';
  }

  const alphabets = mode === 'lowercase' ? lowerAlphabet : upperAlphabet;

  const currentIndex = lowerAlphabet.indexOf(alphabet.toLocaleLowerCase());

  if (currentIndex === 0) {
    return '';
  }

  return alphabets.charAt(currentIndex - 1);
};

/**
 * Checks if the first alphabet is after the second alphabet.
 * @param {string} firstAlphabet - The first alphabet.
 * @param {string} secondAlphabet - The second alphabet. The source one.
 * @returns {boolean} True if the first alphabet is after the second alphabet, false otherwise.
 */
export const isAlphabetAfter = (
  firstAlphabet: string,
  secondAlphabet: string
): boolean => {
  const firstIndex = lowerAlphabet.indexOf(firstAlphabet.toLocaleLowerCase());
  const secondIndex = lowerAlphabet.indexOf(secondAlphabet.toLocaleLowerCase());

  return firstIndex > secondIndex;
};

/**
 * Checks if the first alphabet is before the second alphabet.
 * @param {string} firstAlphabet - The first alphabet.
 * @param {string} secondAlphabet - The second alphabet. The source one.
 * @returns {boolean} True if the first alphabet is before the second alphabet, false otherwise.
 */
export const isAlphabetBefore = (
  firstAlphabet: string,
  secondAlphabet: string
): boolean => {
  const firstIndex = lowerAlphabet.indexOf(firstAlphabet.toLocaleLowerCase());
  const secondIndex = lowerAlphabet.indexOf(secondAlphabet.toLocaleLowerCase());

  return firstIndex < secondIndex;
};

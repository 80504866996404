// Types
import type { FC } from 'react';

// Custom Core Components
import LoadingButton from 'core/components/base/inputs/LoadingButton';

// Custom Common Components
import ActionsBox from 'core/components/shared/Box/Actions';
import DeleteLoadingButton from 'core/components/shared/LoadingButton/Delete';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import RowStack from 'core/components/shared/Stack/RowStack';

// Context
import { useSelectionContext } from 'core/store/context/common/UseSelection';

// Custom Types
export interface TagActionsProps {
  onOpen?: () => void;
  onRemove: (dialog: boolean) => void;
  disabled?: boolean;
  mode?: 'delete' | 'edit' | 'create' | 'none';
}

const TagActions: FC<TagActionsProps> = (props) => {
  // Props
  const { disabled, mode, onOpen, onRemove } = props;

  // Context
  const { selections } = useSelectionContext();

  // Render
  return (
    <ActionsBox>
      <PageTitle>برچسب‌ها</PageTitle>
      <RowStack>
        <DeleteLoadingButton
          loadingPosition='center'
          disabled={
            disabled ||
            selections?.length === 0 ||
            mode === 'create' ||
            mode === 'edit'
          }
          loading={mode === 'delete'}
          onClick={() => onRemove(true)}
        >
          حذف
        </DeleteLoadingButton>
        <LoadingButton
          loadingPosition='center'
          disabled={disabled || mode === 'create' || mode === 'edit'}
          onClick={onOpen}
          sx={{ width: '10rem' }}
        >
          {`${selections?.length === 0 ? 'ایجاد ' : 'ویرایش '} برچسب`}
        </LoadingButton>
      </RowStack>
    </ActionsBox>
  );
};

export default TagActions;

import { type FC } from 'react';

// Custom Shared Components
import FormButton, { type FormButtonProps } from '../Core';

// Custom Icon Components
import CloseIcon from 'core/components/icons/Close';

// Types
export interface CancelButtonProps extends FormButtonProps {}

const CancelButton: FC<CancelButtonProps> = (props) => {
  // Props
  const {
    children = 'انصراف',
    startIcon = <CloseIcon />,
    ...otherProps
  } = props;

  return (
    <FormButton color='error' startIcon={startIcon} {...otherProps}>
      {children}
    </FormButton>
  );
};

export default CancelButton;

import * as React from 'react';

// Custom Hooks
import { useSelectSteps } from 'features/form/feedbacks/store';

// Custom Feature Components
import FeedbackSections from 'features/form/feedbacks/components/details/Sections';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Custom Types
interface FeedbackStepsProps {}

const FeedbackSteps: React.FC<FeedbackStepsProps> = (props) => {
  // Context
  const steps = useSelectSteps();

  const StepsSections = React.useCallback(
    () => (
      <>
        {steps?.map?.((step) => (
          <ColumnStack
            width='100%'
            spacing='1rem'
            key={`feedback-step-${step.id}`}
          >
            <FeedbackSections stepId={step.id} />
          </ColumnStack>
        ))}
      </>
    ),
    [steps]
  );

  // Render
  return <StepsSections />;
};

export default FeedbackSteps;

// Feature Utilities
import { bakePatternCommonProps } from 'features/appBuilder/patterns/core/utilities/api/baker';

// Feature Types
import type { FormPatternDataProps } from 'features/appBuilder/patterns/features/form/types';

/**
 * Transforms the provided form pattern data by ensuring the `creator` field is included as a string.
 *
 * @param {FormPatternDataProps} data - The form pattern data to transform.
 * @returns {Omit<FormPatternDataProps, 'creator'> & { creator: string }} The transformed form pattern data with the `creator` field as a string.
 */
export const bakeFormPatternData = (
  data: FormPatternDataProps
): Omit<FormPatternDataProps, 'creator'> & { creator: string } => {
  const { config, body, button, error, alert, ...otherProps } = data;

  const commonProps = bakePatternCommonProps(otherProps);

  return {
    ...commonProps,
    config,
    body,
    button,
    error,
    alert,
  };
};

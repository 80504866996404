import {
  InputAdornment as MuiInputAdornment,
  InputAdornmentProps as MuiInputAdornmentProps,
  styled,
} from '@mui/material';

export interface InputAdornmentProps extends MuiInputAdornmentProps {}

const InputAdornment = styled(MuiInputAdornment)<InputAdornmentProps>(
  () => ({})
);

export default InputAdornment;

import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import RowStack from 'core/components/shared/Stack/RowStack';

// Feature Components
import ApplicantItemAccesses from 'features/form/processes/components/details/Evaluation/Config/ApplicantFieldAccesses/AccessItem';

// Core Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';

// Feature Utilities
import { updateProcessFocus } from 'features/form/processes/store/actions';
import { evaluationApplicantFieldsItems } from 'features/form/processes/utilities/helper/evaluation';

// Feature Types
import type { ProcessProps } from 'features/form/processes/types/details';
import type { ApplicantEvaluationFieldAccessType } from 'features/form/processes/types/details/common/itemAccess';

interface ApplicantFieldAccessesProps {
  index: number;
  disabled: boolean;
}

const ApplicantFieldAccesses: React.FC<ApplicantFieldAccessesProps> = (
  props
) => {
  // Props
  const { index, disabled } = props;

  // Context
  const { control, getValues, setValue } = useFormContext<ProcessProps>();
  const evaluationId = getValues(`data.items.${index}.id`);

  // Utilities
  const handleApplicantFieldAccessChange = React.useCallback(
    (field: ApplicantEvaluationFieldAccessType) => {
      const updatedFields = [
        ...getValues(`data.items.${index}.data.applicantFieldAccess`),
      ];

      const fieldIndex = updatedFields.indexOf(field);

      if (fieldIndex > -1) {
        updatedFields.splice(fieldIndex, 1);
      } else {
        updatedFields.push(field);
      }

      setValue(`data.items.${index}.data.applicantFieldAccess`, updatedFields);
      updateProcessFocus(evaluationId);
    },
    [index, evaluationId]
  );

  // Render
  return (
    <InputWrapper>
      <BodyOne>نمایش نتیجه ارزیابی به متقاضی</BodyOne>
      <Controller
        control={control}
        name={`data.items.${index}.data.applicantFieldAccess`}
        render={({ field }) => (
          <RowStack
            spacing={1}
            sx={{ overflow: 'auto', ...customRoundedScrollbarStyles }}
          >
            {evaluationApplicantFieldsItems &&
              evaluationApplicantFieldsItems.map((applicantField, mapIndex) => (
                <ApplicantItemAccesses
                  key={`${evaluationId}-applicant-${applicantField}-access-${mapIndex}`}
                  onChange={handleApplicantFieldAccessChange}
                  disabled={disabled}
                  applicantField={applicantField}
                  checked={
                    field.value && field.value.includes(applicantField)
                      ? true
                      : false
                  }
                />
              ))}
          </RowStack>
        )}
      />
    </InputWrapper>
  );
};

export default React.memo(ApplicantFieldAccesses, isSame);

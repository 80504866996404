import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface WidthLgIconProps extends SvgIconProps {}

const WidthLgIcon: React.FC<WidthLgIconProps> = (props) => {
  const {
    children,
    color = 'inherit',
    viewBox = '0 0 24 24',
    ...otherProps
  } = props;

  return (
    <IconBuilder {...props}>
      <path d='M17,2H7v2.5h10V2z' />
      <path d='M22,7.8H2v8.5h20V7.8z' />
      <path d='M17,19.5H7V22h10V19.5z' />
    </IconBuilder>
  );
};

export default WidthLgIcon;

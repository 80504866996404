// Custom Types
import type { MessageStatusType } from 'features/ticket/tickets/types';

export const ticketMessageStatusMap: { [key in MessageStatusType]: string } = {
  sendToPersonnel: 'ارسال به پرسنل',
  sendToDep: 'ارسال به دپارتمان',
  close: 'بسته شده',
  open: 'باز شده',
  depMention: 'منشن دپارتمان',
  subMention: 'منشن مشترکین',
  replyToPersonnel: 'پاسخ به پرسنل',
  replyToDep: 'پاسخ به دپارتمان',
  remove: 'حذف شده',
  restore: 'بازیابی شده',
  expire: 'منقضی شده',
};

/**
 * Retrieves the label corresponding to a ticket message status.
 *
 * @param {string} status The status of the ticket message.
 * @returns {string} The label corresponding to the status, or an empty string if the status is not found.
 */
export const getTicketMessageStatusLabel = (status: string): string => {
  if (Object.keys(ticketMessageStatusMap).includes(status)) {
    return ticketMessageStatusMap[status as MessageStatusType];
  } else {
    return '';
  }
};

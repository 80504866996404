import * as React from 'react';

// Core Components
import IconButtonLink from 'core/components/shared/IconButton/IconButtonLink';

// Icon Components
import AccountCircleIcon from 'core/components/icons/AccountCircle';

// Types
import { IconButtonLinkProps } from 'core/components/shared/IconButton/IconButtonLink';
import Avatar from 'core/components/base/display/Avatar';
interface ProfileIconButtonProps extends IconButtonLinkProps {
  imageUrl?: string;
}

const ProfileIconButton: React.FC<ProfileIconButtonProps> = (props) => {
  const { children, imageUrl, ...otherProps } = props;

  return (
    <IconButtonLink {...otherProps}>
      <Avatar
        src={imageUrl}
        sx={{
          backgroundColor: 'transparent',
          border: 'none',
          height: 24,
          width: 24,
        }}
      >
        <AccountCircleIcon sx={{ color: 'text.secondary' }} />
      </Avatar>
    </IconButtonLink>
  );
};

export default ProfileIconButton;

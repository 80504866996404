import {
  SnackbarContent as MuiSnackbarContent,
  SnackbarContentProps as MuiSnackbarContentProps,
  styled,
} from '@mui/material';

export interface SnackbarContentProps extends MuiSnackbarContentProps {}

const SnackbarContent = styled(MuiSnackbarContent)<SnackbarContentProps>(
  () => ({})
);

export default SnackbarContent;

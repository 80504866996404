import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Custom Core Components
import Table from 'core/components/base/display/Table';
import TableBody from 'core/components/base/display/TableBody';
import TableCell from 'core/components/base/display/TableCell';
import TableContainer from 'core/components/base/display/TableContainer';
import TableHead from 'core/components/base/display/TableHead';
import TableRow from 'core/components/base/display/TableRow';
import Checkbox from 'core/components/base/inputs/Checkbox';
import Switch from 'core/components/base/inputs/Switch';

// Custom Common Components
import TableContainerPaper from 'core/components/shared/Paper/TableContainerPaper';
import RouteLink from 'core/components/shared/Link/RouteLink';

// Custom Utilities
import { getLocaleDateAndTime } from 'core/utilities/helper/date';

// Context
import { useSelectionContext } from 'core/store/context/common/UseSelection';

// Custom Styles
import { tableScrollbarStyles } from 'core/utilities/styles/customStyles';

// Custom Types
import type { TicketTopicsProps } from 'features/ticket/topics/types';

interface TicketTopicsDataGridProps {
  disabled?: boolean;
  list: TicketTopicsProps[];
  onStatusChange?: (topicId: string, status: boolean) => void;
}

const TicketTopicsDataGrid: React.FC<TicketTopicsDataGridProps> = (props) => {
  // Props
  const { list, disabled, onStatusChange } = props;

  // Context
  const selections = useSelectionContext();

  // Hooks
  const theme = useTheme();

  // Render
  return (
    <TableContainer
      component={TableContainerPaper}
      sx={{ ...tableScrollbarStyles }}
    >
      <Table>
        <TableHead>
          <TableRow sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
            <TableCell padding='checkbox'>
              <Checkbox
                color='info'
                disabled={disabled || list.length === 0}
                checked={selections.isAllSelected(list)}
                indeterminate={selections.isIndeterminate(list)}
                onChange={() => selections.onToggleAll(list)}
              />
            </TableCell>
            <TableCell sx={{ width: '12rem', whiteSpace: 'nowrap' }}>
              عنوان
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              گروه پرسنل
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              مشترکین
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              دپارتمان
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              تعداد تیکت
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              وضعیت
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              تاریخ ثبت
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {disabled && list.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4}>
                در حال دریافت اطلاعات موضوعات تیکت.
              </TableCell>
            </TableRow>
          ) : list.length > 0 ? (
            list.map((topic) => (
              <TableRow key={topic.id}>
                <TableCell padding='checkbox'>
                  <Checkbox
                    color='info'
                    disabled={disabled || list.length === 0}
                    checked={selections.isSelected(topic.id)}
                    onChange={() => selections.onSelectionsChange(topic.id)}
                  />
                </TableCell>
                <TableCell sx={{ width: '12rem', whiteSpace: 'nowrap' }}>
                  <RouteLink
                    disabled={disabled}
                    to={`/tickets/topics/${topic.id}`}
                    sx={{
                      borderBottom: '1px dashed',
                      borderColor: 'text.secondary',
                      ':hover': {
                        borderBottom: '1px solid',
                      },
                    }}
                  >
                    {topic.data.title}
                  </RouteLink>
                </TableCell>
                <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
                  {topic.data.personnelGroups.length > 0
                    ? `${topic.data.personnelGroups[0]}${
                        topic.data.personnelGroups.length > 1
                          ? ` +${
                              topic.data.personnelGroups.length - 1
                            }`?.toPersian()
                          : ''
                      }`
                    : '-'}
                </TableCell>
                <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
                  {topic.data.subscribers.length > 0
                    ? `${topic.data.subscribers[0]}${
                        topic.data.subscribers.length > 1
                          ? ` +${
                              topic.data.subscribers.length - 1
                            }`?.toPersian()
                          : ''
                      }`
                    : '-'}
                </TableCell>
                <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
                  {`${topic.data.depTitle} ${
                    topic.data.depMembers.length > 1
                      ? topic.data.depMembers.length
                      : ''
                  }`?.toPersian()}
                </TableCell>
                <TableCell
                  sx={{
                    width: '7rem',
                    whiteSpace: 'nowrap',
                    position: 'relative',
                    left: '2rem',
                  }}
                >
                  {topic.data.ticketsCount?.toPersian()}
                </TableCell>
                <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
                  <Switch
                    color='info'
                    disabled={disabled}
                    checked={topic.data.isActive}
                    onChange={(e, checked) =>
                      onStatusChange && onStatusChange(topic.id, checked)
                    }
                  />
                </TableCell>
                <TableCell
                  dir='ltr'
                  sx={{ width: '7rem', whiteSpace: 'nowrap' }}
                >
                  {getLocaleDateAndTime(topic.data.creationDate)}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>موضوعی جهت نمایش وجود ندارد.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TicketTopicsDataGrid;

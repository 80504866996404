import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface ColorIconProps extends SvgIconProps {}

const ColorIcon: React.FC<ColorIconProps> = (props) => {
  const { color = 'inherit', ...otherProps } = props;
  const styles: React.CSSProperties = {
    fill: color,
    fillRule: 'evenodd',
  };

  return (
    <IconBuilder color={color} {...otherProps}>
      <path
        d='M20.7,19L15.2,4.1c-0.1-0.3-0.3-0.5-0.5-0.7c-0.3-0.2-0.5-0.3-0.9-0.3H10c-0.3,0-0.6,0.1-0.9,0.3C8.9,3.5,8.7,3.8,8.6,4.1
	L3.1,19C3,19.1,3,19.4,3,19.7s0.1,0.4,0.3,0.6c0.2,0.2,0.4,0.4,0.5,0.4c0.2,0.1,0.4,0.2,0.7,0.2h2.3c0.4,0,0.6-0.1,0.9-0.3
	c0.3-0.2,0.4-0.4,0.5-0.7l0.4-1.2h6.6l0.4,1.2c0.1,0.3,0.3,0.5,0.5,0.7c0.3,0.2,0.5,0.3,0.9,0.3h2.3c0.3,0,0.4-0.1,0.7-0.2
	c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.4,0.3-0.6C20.8,19.4,20.8,19.1,20.7,19z M10.4,13.5l1.5-4.5l1.5,4.5H10.4z'
      />
    </IconBuilder>
  );
};

export default ColorIcon;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface ExpandMoreIconProps extends SvgIconProps {}

const ExpandMoreIcon: React.FC<ExpandMoreIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z' />
  </IconBuilder>
);

export default ExpandMoreIcon;

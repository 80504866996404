import { useState, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import MenuItem from 'core/components/base/navigation/MenuItem';

// Common Components
import Select, { type SelectProps } from 'core/components/shared/Select';

// Custom Types
import type { PatternFontFamily } from 'features/appBuilder/patterns/core/types/item/common';

export interface PatternFontFamilyDropdownProps
  extends Omit<SelectProps, 'defaultValue'> {
  reference: string;
  keyName?: string;
  defaultValue?: PatternFontFamily;
}

type SelectOption = { value: PatternFontFamily; label: string };
const options: SelectOption[] = [
  { value: 'iransans', label: 'ایران سنس' },
  { value: 'peyda', label: 'پیدا' },
  { value: 'nastaliq', label: 'نستعلیق' },
  { value: 'vazir', label: 'وزیر' },
];

const PatternFontFamilyDropdown: FC<PatternFontFamilyDropdownProps> = (
  props
) => {
  // Props
  const {
    reference,
    keyName = 'displayType',
    defaultValue = 'default',
    sx,
    ...otherProps
  } = props;

  // Hooks
  const { control, setValue } = useFormContext();
  const [opts] = useState<SelectOption[]>(options);

  return (
    <Controller
      control={control}
      name={`${reference}.${keyName}`}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Select sx={{ height: '48px', ...sx }} size='small' {...field}>
          {opts.map((opt) => (
            <MenuItem
              key={opt.label + opt.value}
              value={opt.value}
              onClick={() => setValue(field.name, opt.value)}
            >
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

export default PatternFontFamilyDropdown;

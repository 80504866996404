import store from 'core/store';
import { useNavigate } from 'react-router-dom';
import {
  DefinedInitialDataOptions,
  QueryKey,
  useQuery,
} from '@tanstack/react-query';

// Custom Hooks
import useMount from 'core/hooks/useMount';
import useUpdateEffect from 'core/hooks/useUpdateEffect';

// Custom Utilities
import { setAppAlert, setAppLoading } from 'core/utilities/helper';

// Custom Types
import type { AlertColor } from 'core/components/base/feedback/Alert';

export interface AlertProps {
  /**
   * The message to be displayed in the alert.
   */
  message: string;
  /**
   * The severity of the alert (e.g., 'error', 'warning', 'info', 'success').
   */
  severity?: AlertColor;
}

export interface UseAppQueryOptionsProps<T>
  extends DefinedInitialDataOptions<T, Error, T, QueryKey> {
  /**
   * Callback function that is called when the fetch is successful.
   */
  onFetch?: (data: T) => void;
  /**
   * Callback function that is called when the fetch fails.
   */
  onFetchFailed?: () => void;
  /**
   * Alert properties to be shown when a fetch error occurs.
   */
  alertOnFetchError?: AlertProps;
  /**
   * Alert properties to be shown when the fetched list is empty.
   */
  alertOnFetchEmptyList?: AlertProps;
  /**
   * If a string is passed, after an error occurs during the fetch,
   * the application will automatically redirect to that path.
   * A value of -1 indicates no redirection.
   */
  redirectAfterErrorTo?: -1 | string;
  /**
   * Indicates whether to automatically set the application in loading mode.
   */
  autoLoading?: boolean;
}

function useAppQuery<T = unknown>(options: UseAppQueryOptionsProps<T>) {
  // Props
  const {
    alertOnFetchEmptyList,
    redirectAfterErrorTo,
    autoLoading,
    onFetchFailed,
    ...otherOptions
  } = options;

  // Hooks
  const navigate = useNavigate();
  const query = useQuery({
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...otherOptions,
  });

  useMount(() => {
    if (!autoLoading) return;
    const loading = store.getState().loading;

    if (('enabled' in options ? options.enabled : true) && !loading)
      setAppLoading(true);
  });

  useUpdateEffect(() => {
    if (!autoLoading) return;
    const loading = store.getState().loading;
    if (query.isFetched && !query.isFetching && loading) setAppLoading(false);
  }, [query.isFetching]);

  useUpdateEffect(() => {
    if (query.data && options.onFetch) options.onFetch(query.data);
  }, [query.data, query.isStale]);

  useUpdateEffect(() => {
    if (query.isError) {
      if (options?.alertOnFetchError) {
        setAppAlert(
          options.alertOnFetchError.message,
          options.alertOnFetchError?.severity || 'error'
        );
      }
      if (onFetchFailed) onFetchFailed();
      if (typeof redirectAfterErrorTo !== 'undefined') {
        setTimeout(() => navigate(redirectAfterErrorTo as any), 2500);
      }
    }
  }, [query.status]);

  useUpdateEffect(() => {
    if (
      query.isFetched &&
      query.isSuccess &&
      (('list' in (query?.data || ({} as any)) &&
        (query?.data || ({} as any)).list.length === 0) ||
        (Array.isArray(query.data) && query.data.length === 0))
    ) {
      if (options?.alertOnFetchEmptyList) {
        setAppAlert(
          options.alertOnFetchEmptyList.message,
          options.alertOnFetchEmptyList?.severity || 'warning'
        );
      }
    }
  }, [query.isFetching]);

  return query;
}

export default useAppQuery;

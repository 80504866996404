import {
  ListItemIcon as MuiListItemIcon,
  ListItemIconProps as MuiListItemIconProps,
  styled,
} from '@mui/material';

export interface ListItemIconProps extends MuiListItemIconProps {}

const ListItemIcon = styled(MuiListItemIcon)<ListItemIconProps>(() => ({
  minWidth: 'auto',
  marginRight: '1rem',
}));

export default ListItemIcon;

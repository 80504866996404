import { createContext, useContext, type FC, type ReactNode } from 'react';

// Custom Types
import type { UseGroupOperationResponse } from 'core/hooks/common/useGroupOperation';

interface UseGroupOperationProviderProps
  extends UseGroupOperationResponse<any> {
  children: ReactNode;
}

// @ts-ignore
const UseGroupOperationContext: T = createContext<UseGroupOperationResponse<T>>(
  {
    operation: 'none',
    onOperationChange: (operation) => {},
  }
);

export const useGroupOperationContext = <T = any,>(): Required<
  UseGroupOperationResponse<T>
> => useContext(UseGroupOperationContext);

export const GroupOperationProvider: FC<UseGroupOperationProviderProps> = (
  props
) => {
  // Props
  const { children, ...otherProps } = props;

  return (
    <UseGroupOperationContext.Provider value={otherProps}>
      {children}
    </UseGroupOperationContext.Provider>
  );
};

import * as React from 'react';

// Custom Common Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Utilities
import type { TypographyProps } from 'core/components/base/display/Typography';

// Custom Types
interface TextSeperatedByDivider extends TypographyProps {
  firstText?: string;
  firstTextProps?: TypographyProps;
  secondText?: string;
  secondTextProps?: TypographyProps;
}

const TextSeperatedByDivider: React.FC<TextSeperatedByDivider> = (props) => {
  // Props
  const {
    firstText,
    secondText,
    firstTextProps,
    secondTextProps,
    sx,
    ...otherProps
  } = props;

  // Render
  return (
    <BodyTwo
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '.5rem',
        verticalAlign: 'middle',
        ...sx,
      }}
      {...otherProps}
    >
      {firstText && (
        <BodyTwo component='span' {...firstTextProps}>
          {firstText}
        </BodyTwo>
      )}
      {firstText && secondText && <BodyTwo component='span'>|</BodyTwo>}
      {secondText && (
        <BodyTwo component='span' {...secondTextProps}>
          {secondText}
        </BodyTwo>
      )}
    </BodyTwo>
  );
};

export default TextSeperatedByDivider;

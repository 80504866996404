import * as React from 'react';
import debounce from 'lodash/debounce';
import { Controller, useForm } from 'react-hook-form';

// Custom Core Components
import Paper from 'core/components/base/surfaces/Paper';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import TextFieldOutlined from 'core/components/shared/TextField/Outlined';

// Custom Hooks
import { useAppDispatch, useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import { setCurrentContainerTitle } from 'core/store/slices/feature/banner';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
interface BannerContainerTitleProps {}

interface FormInput {
  title: string;
}

const BannerContainerTitle: React.FC<BannerContainerTitleProps> = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const loading = useSelectLoading();
  const containerId = useAppSelector((state) => state.banner.current?.id || '');
  const containerTitle = useAppSelector(
    (state) => state.banner.current?.data.title || ''
  );
  const { control, reset } = useForm<FormInput>({
    mode: 'onTouched',
    defaultValues: { title: containerTitle },
  });

  React.useEffect(() => {
    reset({ title: containerTitle || '' });
  }, [containerId, containerTitle]);

  //  Utilities
  const handleChange = (value: string) => {
    if (!containerId) return;
    const newTitle = value.trim();

    if (newTitle === containerTitle) return;

    dispatch(setCurrentContainerTitle(newTitle));
  };

  const debounceUpdate = debounce(handleChange, 400);

  // Render
  return (
    <Paper
      sx={{
        display: 'flex',
        borderRadius: '0.625rem',
        boxShadow: 'none',
        padding: '2rem',
        justifyContent: 'center',
      }}
    >
      <ColumnStack spacing={1} width='fit-content'>
        <InputLabel disabled={loading}>عنوان فهرست</InputLabel>
        <Controller
          control={control}
          name='title'
          rules={{
            onChange: (e) => debounceUpdate(e.target.value),
          }}
          render={({ field }) => (
            <TextFieldOutlined
              disabled={!containerId || loading}
              placeholder='عنوان فهرست را وارد کنید.'
              sx={{ width: '22rem' }}
              {...field}
            />
          )}
        />
      </ColumnStack>
    </Paper>
  );
};

export default BannerContainerTitle;

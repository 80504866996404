import { createSlice } from '@reduxjs/toolkit';

// Utilities

interface ThemeDataProps {
  themeMode: 'light' | 'dark';
}

const initialState = () => {
  const localThemeData = localStorage.getItem('ThemeState');
  return localThemeData
    ? { themeMode: JSON.parse(localThemeData) }
    : { themeMode: 'light' };
};

export const ThemeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme: (state: ThemeDataProps) => {
      const newTheme = state.themeMode === 'light' ? 'dark' : 'light';

      localStorage.setItem(
        'ThemeState',
        JSON.stringify(newTheme ? newTheme : { themeMode: 'light' })
      );

      state.themeMode = newTheme;
      return state;
    },
  },
});

export const { toggleTheme } = ThemeSlice.actions;
export default ThemeSlice.reducer;

import {
  Radio as MuiRadio,
  RadioProps as MuiRadioProps,
  styled,
} from '@mui/material';

export interface RadioProps extends MuiRadioProps {
  to?: string;
}

const Radio = styled(MuiRadio)<RadioProps>(() => ({}));

export default Radio;

import { type FC } from 'react';
import { alpha, useTheme } from '@mui/material';

// Custom Core Components
import LoadingButton, {
  type LoadingButtonProps,
} from 'core/components/base/inputs/LoadingButton';

// Custom Types
export interface FormLoadingButtonProps extends LoadingButtonProps {}

const FormLoadingButton: FC<FormLoadingButtonProps> = (props) => {
  // Props
  const {
    children,
    color = 'inherit',
    variant = 'contained',
    sx,
    ...otherProps
  } = props;

  // Hooks
  const { palette } = useTheme();

  return (
    <LoadingButton
      variant={variant}
      sx={{
        backgroundColor: 'background.paper',
        color: color === 'inherit' ? 'inherit' : `${color}.main`,
        ':hover': {
          backgroundColor: alpha(palette.background.paper, 0.8),
        },
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </LoadingButton>
  );
};

export default FormLoadingButton;

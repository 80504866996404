import store from 'core/store';
import { actions } from '../slice';

export const toggleInputVisibility = (inputId: string) => {
  store.dispatch(actions.toggleInputVisibility(inputId));
};

export const toggleInputWidth = (inputId: string) => {
  store.dispatch(actions.toggleInputFullWidth(inputId));
};

import debounce from 'lodash/debounce';
import type { ChangeEvent, FC } from 'react';

// Core Components
import Box from 'core/components/base/layout/Box';

// Custom Components
import RowStack from 'core/components/shared/Stack/RowStack';
import GhostTextField from 'core/components/shared/ResizableTextField';

// Custom Types
export interface EvaluationQuestionOptionBuilderComponentProps {
  disabled?: boolean;
  onChange?: (value: string) => void;
}

const EvaluationQuestionOptionBuilder: FC<
  EvaluationQuestionOptionBuilderComponentProps
> = (props) => {
  // Props
  const { disabled, onChange } = props;

  const handleChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    onChange && onChange(value || '');

    e.target.blur();
  }, 200);

  return (
    <RowStack spacing={0.5} sx={{ alignItems: 'start' }}>
      <Box sx={{ width: '2.5rem', flexShrink: 0 }} />
      <GhostTextField
        disabled={disabled}
        onBlur={(e) => (e.target.value = '')}
        variant='standard'
        onChange={handleChange}
        placeholder='عنوان گزینه'
      />
    </RowStack>
  );
};

export default EvaluationQuestionOptionBuilder;

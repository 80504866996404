import * as React from 'react';

// Core Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import RowStack from 'core/components/shared/Stack/RowStack';
import Checkbox from 'core/components/base/inputs/Checkbox';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { getEvaluationApplicantFieldAccessTitle } from 'features/form/processes/utilities/helper/evaluation';

// Custom Types
import type { ApplicantEvaluationFieldAccessType } from 'features/form/processes/types/details/common/itemAccess';

interface ApplicantItemAccessesProps {
  disabled: boolean;
  checked: boolean;
  onChange: (field: ApplicantEvaluationFieldAccessType) => void;
  applicantField: ApplicantEvaluationFieldAccessType;
}

const ApplicantItemAccesses: React.FC<ApplicantItemAccessesProps> = (props) => {
  // Props
  const { applicantField, disabled, checked, onChange } = props;

  // Render
  return (
    <RowStack sx={{ alignItems: 'center', p: 0 }} spacing={0}>
      <Checkbox
        disabled={disabled}
        size='small'
        disableRipple
        checked={checked}
        onClick={() => onChange(applicantField)}
      />
      <BodyTwo disabled={disabled} sx={{ whiteSpace: 'nowrap' }}>
        {getEvaluationApplicantFieldAccessTitle(applicantField)}
      </BodyTwo>
    </RowStack>
  );
};

export default React.memo(ApplicantItemAccesses, isSame);

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import type { SubmitHandler } from 'react-hook-form';

// Custom Common Components
import AudienceFolderForm from 'features/audience/folders/components/details/Form';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useAddAudienceFolderMutation } from 'features/audience/folders/hooks';

// Custom Hooks
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// Custom Validation Schemas
import AddAudienceFolderValidationSchema from 'features/audience/folders/validations';

// Custom Types
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';
import type { AudienceFolderDataProps } from 'features/audience/folders/types';

interface AddAudienceFolderProps extends WithAuthProps {}

const AddAudienceFolder: React.FC<AddAudienceFolderProps> = (props) => {
  // Context
  const loading = useSelectLoading();

  // Hooks
  const navigate = useNavigate();
  const resolver = useYupValidationResolver(AddAudienceFolderValidationSchema);
  const methods = useForm<AudienceFolderDataProps>({
    mode: 'onTouched',
    resolver,
    defaultValues: {
      title: '',
      capacity: undefined,
      creator: [],
      isActive: true,
      reader: [],
      tags: [],
      updater: [],
    },
  });

  const { mutate: handleAddFolder } = useAddAudienceFolderMutation({
    autoAlert: { mode: 'create', name: 'پوشه' },
    redirectAfterSuccessTo: '/audience-folders',
  });

  // Utilities
  const handleFormSubmit: SubmitHandler<AudienceFolderDataProps> = async (
    data
  ) => {
    handleAddFolder(data);
  };

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='پوشه جدید' />
      <FormProvider {...methods}>
        <AudienceFolderForm
          mode='create'
          disabled={loading}
          onFormSubmit={handleFormSubmit}
        />
      </FormProvider>
    </>
  );
};

export default AddAudienceFolder;

import * as React from 'react';

// Core Components
import Typography, {
  TypographyProps,
} from 'core/components/base/display/Typography';

interface HeadingTwoProps extends TypographyProps {}

const HeadingTwo: React.FC<HeadingTwoProps> = (props) => {
  const { children, component = 'h2', variant = 'h5', ...otherProps } = props;

  return (
    <Typography component={component} variant={variant} {...otherProps}>
      {children}
    </Typography>
  );
};

export default HeadingTwo;

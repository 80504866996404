import * as React from 'react';
import { styled } from '@mui/material';

// Core Components
import LoadingButton from 'core/components/base/inputs/LoadingButton';

// Types
import type { LoadingButtonProps } from 'core/components/base/inputs/LoadingButton';
export interface DeleteLoadingButtonProps extends LoadingButtonProps {}

const DeleteLoadingButton = styled(LoadingButton)<DeleteLoadingButtonProps>(
  () => ({
    minWidth: '5rem',
  })
);

DeleteLoadingButton.defaultProps = {
  color: 'error',
  variant: 'outlined',
};

export default DeleteLoadingButton;

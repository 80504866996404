// Custom Hooks
import useDocApi from 'core/hooks/api/useDoc';
import useDocsApi from 'core/hooks/api/useDocs';
import { useDeleteMutation, useEditMutation } from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  deleteFile,
  editFile,
  getFile,
  getFiles,
} from 'features/file/files/utilities/files';

// Custom Types
import type { FileDataProps, FileProps } from 'features/file/files/types';
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';
import type {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';

/**
 * Query key for fetching files.
 *
 * @constant {Array<string>}
 */
export const filesQueryKey = ['files'];

/**
 * Custom hook to fetch files.
 *
 * This hook utilizes the `useDocApi` to retrieve files list on the provided options.
 *
 * @param {UseDocOptionType<FileProps>} [options] - Optional configuration options for fetching the files.
 * @returns {UseDocApiReturnProps<FileProps>} The result of the `useDocApi` hook, which includes the fetched files
 */
export const useFileDocs = (
  options?: UseDocsOptionType<FileProps>
): UseDocsApiReturnProps<FileProps> =>
  useDocsApi<FileProps>(getFiles, filesQueryKey, options);

/**
 * Custom hook to fetch a file document.
 *
 * This hook utilizes the `useDocApi` to retrieve a specific file document based on the provided options.
 *
 * @param {UseDocOptionType<FileProps>} [options] - Optional configuration options for fetching the file document.
 * @returns {UseDocApiReturnProps<FileProps>} The result of the `useDocApi` hook, which includes the fetched file document, loading state, and any errors.
 */
export const useFileDoc = (
  options?: UseDocOptionType<FileProps>
): UseDocApiReturnProps<FileProps> =>
  useDocApi<FileProps>(getFile, filesQueryKey, options);

/**
 * Custom hook for deleting files.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the delete mutation, including status and potential errors.
 */
export const useDeleteFileMutation = useDeleteMutation<string[]>(
  deleteFile,
  filesQueryKey
);

/**
 * Custom hook for editing a file.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the edit mutation, including status and potential errors.
 */
export const useEditFileMutation = useEditMutation<FileDataProps>(
  editFile,
  filesQueryKey
);

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface SwapIconProps extends SvgIconProps {}

const SwapIcon: React.FC<SwapIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M16 17.01V10h-2v7.01h-3L15 21l4-3.99zM9 3 5 6.99h3V14h2V6.99h3z' />
  </IconBuilder>
);

export default SwapIcon;

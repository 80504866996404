import type { FC } from 'react';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Feature Components
import UploaderInput from 'features/file/files/components/shared/Uploader/Input';
import UploderPreview from 'features/file/files/components/shared/Uploader/Preview';

// Custom Utilities
import { getFileSource } from 'core/utilities/helper/helperPack';

// Custom Types
import type { UploaderInputProps } from 'features/file/files/components/shared/Uploader/Input';
import type { FileProps } from 'features/file/files/types';

export interface MultipleUploaderProps
  extends Omit<UploaderInputProps, 'onChange'> {
  value?: FileProps[];
  onChange?: (files: FileProps[]) => void;
}

const MultipleUploader: FC<MultipleUploaderProps> = (props) => {
  // Props
  const { limit = 2, disabled, value, onChange, ...otherProps } = props;

  // Utilities
  const handleRemove = (index: number) => {
    const updatedValues = value ? [...value] : [];

    if (updatedValues.length - 1 >= index) {
      updatedValues.splice(index, 1);
    }

    if (onChange) onChange(updatedValues);
  };

  return (
    <RowStack>
      {value &&
        value.length > 0 &&
        value?.map((file, index) => (
          <UploderPreview
            key={`file-uploader-preview-item-${index}`}
            disabled={disabled}
            fileType={file.data.type}
            previewLink={getFileSource(file)}
            onRemove={() => handleRemove(index)}
          />
        ))}
      {value && value.length < limit && (
        <UploaderInput
          value={value}
          disabled={disabled}
          limit={limit}
          onChange={onChange}
          {...otherProps}
        />
      )}
    </RowStack>
  );
};

export default MultipleUploader;

// Feature Types
import { PatternBadgeButtonProps } from 'features/appBuilder/patterns/core/types/item/badge';
import type { ContentDetailsPatternFooterProps } from 'features/appBuilder/patterns/features/contentDetails/types/footer';

const badgeDefaults: PatternBadgeButtonProps = {
  isActive: true,
  borderRadius: 8,
  textDirection: 'rtl',
  textAlign: 'center',
  fontSize: '14',
  padding: '0',
  fontColor: '#12252E',
  fontHoverColor: '#12252E',
  bgColor: '#C1C8D3',
  bgHoverColor: '#C1C8D3',
};

export const contentDetailsPatterFooterDefaults: ContentDetailsPatternFooterProps =
  {
    isActive: true,
    date: {
      isActive: true,
      borderRadius: 8,
      prefix: 'تاریخ نگارش: ',
      calendarType: 'jalali',
      fontColor: '#59666D',
      fontSize: '12',
      highlightColor: 'rgba(0, 0, 0, 0)',
      padding: '0px',
      textAlign: 'start',
      textDirection: 'rtl',
    },
    button: {
      borderRadius: 8,
      isActive: true,
      displayType: 'default',
      imageSize: 32,
      items: ['link'],
      padding: '0px',
      textAlign: 'end',
      fontColor: '#AEAEAE',
      bgColor: 'rgba(0, 0, 0, 0)',
      fontHoverColor: '#AEAEAE',
      bgHoverColor: 'rgba(0, 0, 0, 0)',
    },
    category: badgeDefaults,
    tag: badgeDefaults,
  };

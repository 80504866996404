// Feature Types
import type { FooterPatternBrandProps } from 'features/appBuilder/patterns/features/footer/types/brand';

export const footerPatternBrandDefaults: FooterPatternBrandProps = {
  isActive: true,
  logo: {
    isActive: true,
    borderRadius: 8,
    displayType: 'default',
    imageSize: 0,
    file: null,
    padding: '0px',
  },
  titleOne: {
    isActive: true,
    fontSize: '18',
    padding: '0px',
    text: '',
    textAlign: 'start',
    textDirection: 'rtl',
    fontFamily: 'vazir',
    fontColor: '#12252E',
    fontHoverColor: '#12252E',
    bgColor: 'rgba(0, 0, 0, 0)',
    bgHoverColor: 'rgba(0, 0, 0, 0)',
    highlightColor: 'rgba(0, 0, 0, 0)',
    highlightHoverColor: 'rgba(0, 0, 0, 0)',
  },
  titleTwo: {
    isActive: true,
    fontSize: '14',
    padding: '0px',
    text: '',
    textAlign: 'start',
    textDirection: 'rtl',
    fontFamily: 'vazir',
    fontColor: '#59666D',
    fontHoverColor: '#59666D',
    bgColor: 'rgba(0, 0, 0, 0)',
    bgHoverColor: 'rgba(0, 0, 0, 0)',
    highlightColor: 'rgba(0, 0, 0, 0)',
    highlightHoverColor: 'rgba(0, 0, 0, 0)',
  },
};

import { useState, type FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';

// Custom Core Components
import Collapse from 'core/components/base/utils/Collapse';

// Custom Common Components
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import CollapseIconButton from 'core/components/shared/IconButton/Collapse';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import RowStack from 'core/components/shared/Stack/RowStack';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import DatePicker from 'core/components/shared/Picker/DatePicker';
import TimePicker from 'core/components/shared/Picker/TimePicker';
import CheckboxWithLabel from 'core/components/shared/Checkbox/CheckboxWithLabel';

// Custom Feature Components
import ManagersAutocomplete from 'features/manager/managers/components/Autocomplete';
import AudienceGroupsAutocomplete from 'core/components/shared/Autocomplete/AudienceGroups';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { setFormActionMode } from 'features/form/forms/store/actions';

// Custom Types
import type { FormProps } from 'features/form/forms/types/item';
export interface FormSettingsAccessProps {
  disabled?: boolean;
}

const FormSettingsAccess: FC<FormSettingsAccessProps> = (props) => {
  // Props
  const { disabled } = props;

  // Hooks
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { control, setValue, setError } = useFormContext<FormProps>();

  const hasFeedback = useAppSelector((state) => state.form.data?.hasFeedback);
  const isPublic = useAppSelector(
    (state) => state.form.data && state.form.data.audienceGroupsIds.length === 0
  );

  // Utilities
  const toggleCollapse = () => setCollapsed(!collapsed);
  const updateActionMode = () => setFormActionMode('SETTINGS');

  // Render
  return (
    <RoundPaper
      sx={{
        backgroundColor: 'background.default',
        borderRadius: '1rem',
        width: '100%',
      }}
    >
      <SpaceBetween>
        <PageTitle color='text.secondary'>دسترسی</PageTitle>
        <CollapseIconButton collapsed={collapsed} onClick={toggleCollapse} />
      </SpaceBetween>
      <Collapse in={collapsed}>
        <ColumnStack pb='1rem'>
          <RowStack spacing={6}>
            <InputWrapper width='100%'>
              <InputLabel indentRight>تاریخ انتشار</InputLabel>
              <Controller
                control={control}
                name='data.publishDate'
                defaultValue=''
                render={({ field }) => (
                  <RowStack>
                    <DatePicker
                      disabled={disabled}
                      fullWidth
                      value={field.value ? new Date(field.value) : undefined}
                      onDateChange={(date) => {
                        setValue(
                          field.name,
                          date ? date.toISOString() : undefined
                        );

                        updateActionMode();
                      }}
                    />
                    <TimePicker
                      disabled={disabled}
                      fullWidth
                      value={field.value ? new Date(field.value) : undefined}
                      onTimeChange={(time) => {
                        setValue(
                          field.name,
                          time ? time.toISOString() : undefined
                        );
                        updateActionMode();
                      }}
                    />
                  </RowStack>
                )}
              />
            </InputWrapper>
            <InputWrapper width='100%'>
              <InputLabel indentRight>تاریخ پایان</InputLabel>
              <Controller
                control={control}
                name='data.expireDate'
                defaultValue=''
                render={({ field }) => (
                  <RowStack>
                    <DatePicker
                      disabled={disabled}
                      fullWidth
                      value={field.value ? new Date(field.value) : undefined}
                      onDateChange={(date) => {
                        setValue(
                          field.name,
                          date ? date.toISOString() : undefined
                        );
                        updateActionMode();
                      }}
                    />
                    <TimePicker
                      disabled={disabled}
                      fullWidth
                      value={field.value ? new Date(field.value) : undefined}
                      onTimeChange={(time) => {
                        updateActionMode();

                        setValue(
                          field.name,
                          time ? time.toISOString() : undefined
                        );
                      }}
                    />
                  </RowStack>
                )}
              />
            </InputWrapper>
          </RowStack>
          <RowStack spacing={6}>
            <InputWrapper width='100%'>
              <InputLabel sx={{ whiteSpace: 'none' }} indentRight required>
                کدام گروه از مدیران به بازخوردهای این فرم دسترسی دارند؟
              </InputLabel>
              <Controller
                control={control}
                name='data.managerGroupsIds'
                defaultValue={[]}
                render={({ field, fieldState }) => (
                  <ManagersAutocomplete
                    disabled={disabled}
                    defaultValue={field.value}
                    error={fieldState.error?.message ? true : false}
                    helperText={fieldState.error?.message}
                    onBlur={(selectedIds) => {
                      if (selectedIds.length < 1) field.onBlur();
                    }}
                    onChange={(selectedIds) => {
                      updateActionMode();
                      if (selectedIds.length > 0)
                        setError(field.name, {
                          message: '',
                        });
                      else {
                        field.onBlur();
                      }
                      setValue(field.name, selectedIds);
                    }}
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper width='100%'>
              <InputLabel sx={{ whiteSpace: 'none' }} indentRight>
                کدام گروه از مخاطبان امکان مشاهده و پرکردن این فرم را دارند؟
              </InputLabel>
              <Controller
                control={control}
                name='data.audienceGroupsIds'
                defaultValue={[]}
                render={({ field }) => (
                  <AudienceGroupsAutocomplete
                    error={
                      hasFeedback &&
                      ((isPublic && field.value.length > 0) ||
                        (!isPublic && field.value.length === 0))
                    }
                    helperText={
                      hasFeedback && isPublic && field.value.length > 0
                        ? 'تبدیل فرم به فرم مخاطبان ممکن نیست.'
                        : hasFeedback && !isPublic && field.value.length === 0
                        ? 'تبدیل فرم به فرم عمومی ممکن نیست.'
                        : undefined
                    }
                    disabled={disabled}
                    defaultValues={field.value}
                    onChange={(groups) => {
                      updateActionMode();

                      if (groups.length > 0) {
                        setValue('data.publicFeedbackTitles', []);
                      } else {
                        setValue('data.audienceFeedbackTitles', []);
                        setValue('data.audienceInfo', []);
                      }

                      setValue(
                        field.name,
                        groups.map((g) => g.id)
                      );
                    }}
                  />
                )}
              />
            </InputWrapper>
          </RowStack>
          <RowStack spacing={6}>
            <InputWrapper width='100%'>
              <Controller
                control={control}
                name='data.isDraft'
                defaultValue={false}
                render={({ field }) => (
                  <CheckboxWithLabel
                    disabled={disabled}
                    checkboxProps={{
                      disabled: disabled,
                      checked: field.value,
                      onChange: (event, checked) => {
                        updateActionMode();
                        setValue(field.name, checked);
                      },
                    }}
                    label='غیرفعال'
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper width='100%'>
              <Controller
                control={control}
                name='data.validatePerStep'
                defaultValue={false}
                render={({ field }) => (
                  <CheckboxWithLabel
                    disabled={disabled}
                    checkboxProps={{
                      disabled: disabled,
                      checked: field.value,
                      onChange: (event, checked) => {
                        updateActionMode();
                        setValue(field.name, checked);
                      },
                    }}
                    labelProps={{ whiteSpace: 'normal' }}
                    label='اعتبارسنجی هر مرحله (در صورت غیرفعال بودن میتوان مراحل را بدون تکمیل فیلدهای اجباری مرور کرد)'
                  />
                )}
              />
            </InputWrapper>
          </RowStack>
        </ColumnStack>
      </Collapse>
    </RoundPaper>
  );
};

export default memo(FormSettingsAccess, isSame);

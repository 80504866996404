import * as React from 'react';

// Core Components
import Button from 'core/components/base/inputs/Button';

// Common Components
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Icons
import UploadIcon from 'core/components/icons/Upload';

// Custom Types
import type { RoundPaperProps } from 'core/components/shared/Paper/RoundPaper';

interface UploadBoxProps extends RoundPaperProps {
  hideUploadButton?: boolean;
  onFileChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UploadBox: React.FC<UploadBoxProps> = React.forwardRef(
  (props, ref: any) => {
    // Props
    const { sx, hideUploadButton, onFileChange, ...otherProps } = props;

    // Hooks
    const fileInputRef = React.useRef<HTMLInputElement | null>(null);

    // Utilities
    const handleOpenFileSelect = () =>
      fileInputRef && fileInputRef.current && fileInputRef.current.click();

    // Render
    return (
      <RoundPaper
        ref={ref}
        sx={{
          borderRadius: '1rem',
          width: 359,
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '.5rem',
          boxShadow: 'none',
          ...sx,
        }}
        {...otherProps}
      >
        <UploadIcon sx={{ fontSize: '3rem' }} color='info' />
        <BodyTwo>فایل های خود را در اینجا رها کنید</BodyTwo>
        <BodyTwo color='text.disabled'>
          فرمت‌های مجاز: jpg, jpeg, png, gif, xlsx, pdf, doc, docx, mp4, mp3,
          mkv, svg, wma, webp, avi
        </BodyTwo>
        {!hideUploadButton && (
          <>
            <Button
              onClick={handleOpenFileSelect}
              variant='contained'
              color='info'
              sx={{
                height: '2.5rem',
                borderRadius: '.5rem',
              }}
            >
              افزودن فایل
            </Button>
            <input
              ref={fileInputRef}
              type='file'
              multiple
              style={{
                display: 'none',
              }}
              onChange={onFileChange}
            />
          </>
        )}
      </RoundPaper>
    );
  }
);

export default UploadBox;

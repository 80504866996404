import type { FC } from 'react';

// Core Components
import Box from 'core/components/base/layout/Box';
import IconButton from 'core/components/base/inputs/IconButton';

// Common Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import ColorPicker from 'core/components/shared/Picker/Color';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import RowStack from 'core/components/shared/Stack/RowStack';
import TextFieldNumber from 'core/components/shared/TextField/number';

// Feature Components
import PatternPadding from 'features/appBuilder/patterns/core/components/shared/Padding';

// Custom Icons
import FormatAlignCenter from 'core/components/icons/AlignCenter';
import FormatAlignLeft from 'core/components/icons/AlignLeft';
import FormatAlignRight from 'core/components/icons/AlignRight';
import FormatTextdirectionLToRIcon from 'core/components/icons/LtrDirection';
import FormatTextdirectionRToLIcon from 'core/components/icons/RtlDirection';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';
import { Controller, useFormContext } from 'react-hook-form';

// Custom Types
type PatternCommonStyleKey =
  | 'padding'
  | 'textDirection'
  | 'textAlign'
  | 'align'
  | 'borderRadius'
  | 'flexAlign'
  | 'fontSize'
  | 'size'
  | 'fontColor'
  | 'highlightColor'
  | 'fillColor'
  | 'color'
  | 'bgColor'
  | 'activeColor'
  | 'activeBgColor'
  | 'fontHoverColor'
  | 'highlightHoverColor'
  | 'hoverColor'
  | 'bgHoverColor'
  | 'cardBg'
  | 'cardBgHover';

export type PatternIncludeArray = PatternCommonStyleKey[];
export interface PatternItemStylesProps {
  reference: string;
  include?: PatternIncludeArray;
}

const PatternItemStyles: FC<PatternItemStylesProps> = (props) => {
  // Props
  const { reference, include = [] } = props;

  // Hooks
  const { control, setValue } = useFormContext();
  const loading = useSelectLoading();

  // Helpers
  const hasPadding = include.includes('padding');

  const getSizeByStr = (value: string = '') => {
    if (typeof value === 'number') return String(value);
    if (typeof value !== 'string') return '';
    else return value.replaceAll('px', '').trim() || '';
  };

  // Render
  return (
    <Box
      sx={{
        py: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        overflow: 'auto',
        ...customRoundedScrollbarStyles,
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        {hasPadding && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              flexGrow: 2,
            }}
          >
            <InputLabel>پدینگ</InputLabel>
            <Controller
              control={control}
              name={`${reference}.padding`}
              render={({ field }) => {
                return (
                  <PatternPadding
                    disabled={loading}
                    defaultValue={field.value}
                    onChange={(padding) => setValue(field.name, padding)}
                  />
                );
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: hasPadding ? 'column' : 'row',
            gap: hasPadding ? 1 : 2,
          }}
        >
          {include.includes('textDirection') && (
            <InputWrapper label='جهت متن'>
              <Controller
                control={control}
                name={`${reference}.textDirection`}
                render={({ field }) => (
                  <RowStack spacing={0.5}>
                    {['rtl', 'ltr'].map((dir, index) => (
                      <IconButton
                        disabled={loading}
                        color={field.value === dir ? 'info' : undefined}
                        key={index + 'pattern style' + dir}
                        onClick={() => setValue(field.name, dir)}
                      >
                        {dir === 'ltr' ? (
                          <FormatTextdirectionLToRIcon />
                        ) : (
                          <FormatTextdirectionRToLIcon />
                        )}
                      </IconButton>
                    ))}
                  </RowStack>
                )}
              />
            </InputWrapper>
          )}
          {include.includes('flexAlign') && (
            <InputWrapper label='چینش'>
              <Controller
                control={control}
                name={`${reference}.flexAlign`}
                render={({ field }) => (
                  <RowStack spacing={0.5}>
                    {['start', 'center', 'end'].map((align, index) => (
                      <IconButton
                        disabled={loading}
                        color={field.value === align ? 'info' : undefined}
                        key={index + 'pattern style' + align}
                        onClick={() => setValue(field.name, align)}
                      >
                        {align === 'start' ? (
                          <FormatAlignRight />
                        ) : align === 'center' ? (
                          <FormatAlignCenter />
                        ) : (
                          <FormatAlignLeft />
                        )}
                      </IconButton>
                    ))}
                  </RowStack>
                )}
              />
            </InputWrapper>
          )}
          {include.includes('textAlign') && (
            <InputWrapper label='چینش متن'>
              <Controller
                control={control}
                name={`${reference}.textAlign`}
                render={({ field }) => (
                  <RowStack spacing={0.5}>
                    {['start', 'center', 'end'].map((align, index) => (
                      <IconButton
                        disabled={loading}
                        color={field.value === align ? 'info' : undefined}
                        key={index + 'pattern style' + align}
                        onClick={() => setValue(field.name, align)}
                      >
                        {align === 'start' ? (
                          <FormatAlignRight />
                        ) : align === 'center' ? (
                          <FormatAlignCenter />
                        ) : (
                          <FormatAlignLeft />
                        )}
                      </IconButton>
                    ))}
                  </RowStack>
                )}
              />
            </InputWrapper>
          )}
          {include.includes('align') && (
            <InputWrapper label='چینش'>
              <Controller
                control={control}
                name={`${reference}.align`}
                render={({ field }) => (
                  <RowStack spacing={0.5}>
                    {['start', 'center', 'end'].map((align, index) => (
                      <IconButton
                        disabled={loading}
                        color={field.value === align ? 'info' : undefined}
                        key={index + 'pattern style' + align}
                        onClick={() => setValue(field.name, align)}
                      >
                        {align === 'start' ? (
                          <FormatAlignRight />
                        ) : align === 'center' ? (
                          <FormatAlignCenter />
                        ) : (
                          <FormatAlignLeft />
                        )}
                      </IconButton>
                    ))}
                  </RowStack>
                )}
              />
            </InputWrapper>
          )}
          <RowStack spacing={0} alignItems='center'>
            {include.includes('fontSize') && (
              <InputWrapper label='اندازه فونت'>
                <Controller
                  control={control}
                  name={`${reference}.fontSize`}
                  render={({ field: { value, ...otherFieldProps } }) => (
                    <TextFieldNumber
                      disabled={loading}
                      sx={{
                        mr: '0.5rem',
                        '.MuiOutlinedInput-root': {
                          paddingLeft: '6px',
                        },
                        '.MuiInputBase-input': {
                          padding: '12px',
                          textAlign: 'center',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <BodyTwo color='text.secondary'>px</BodyTwo>
                        ),
                      }}
                      value={getSizeByStr(value)}
                      {...otherFieldProps}
                    />
                  )}
                />
              </InputWrapper>
            )}
            {include.includes('size') && (
              <InputWrapper label='اندازه'>
                <Controller
                  control={control}
                  name={`${reference}.size`}
                  render={({ field: { value, ...otherFieldProps } }) => (
                    <TextFieldNumber
                      disabled={loading}
                      sx={{
                        mr: '0.5rem',
                        '.MuiOutlinedInput-root': {
                          paddingLeft: '6px',
                        },
                        '.MuiInputBase-input': {
                          padding: '12px',
                          textAlign: 'center',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <BodyTwo color='text.secondary'>px</BodyTwo>
                        ),
                      }}
                      value={getSizeByStr(value)}
                      {...otherFieldProps}
                    />
                  )}
                />
              </InputWrapper>
            )}
            {include.includes('borderRadius') && (
              <InputWrapper label='انحنا'>
                <Controller
                  control={control}
                  name={`${reference}.borderRadius`}
                  render={({ field: { value, ...otherFieldProps } }) => (
                    <TextFieldNumber
                      disabled={loading}
                      sx={{
                        mr: '0.5rem',
                        '.MuiOutlinedInput-root': {
                          paddingLeft: '6px',
                        },
                        '.MuiInputBase-input': {
                          padding: '12px',
                          textAlign: 'center',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <BodyTwo color='text.secondary'>px</BodyTwo>
                        ),
                      }}
                      value={getSizeByStr(value)}
                      {...otherFieldProps}
                    />
                  )}
                />
              </InputWrapper>
            )}
          </RowStack>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        {include.includes('fillColor') && (
          <InputWrapper row label='رنگ'>
            <Controller
              control={control}
              name={`${reference}.fillColor`}
              render={({ field }) => (
                <ColorPicker
                  disabled={loading}
                  color={field.value}
                  onColorChange={(color) => setValue(field.name, color)}
                />
              )}
            />
          </InputWrapper>
        )}
        {include.includes('color') && (
          <InputWrapper row label='متن'>
            <Controller
              control={control}
              name={`${reference}.color`}
              render={({ field }) => (
                <ColorPicker
                  disabled={loading}
                  color={field.value}
                  onColorChange={(color) => setValue(field.name, color)}
                />
              )}
            />
          </InputWrapper>
        )}
        {include.includes('cardBg') && (
          <InputWrapper row label='بکگراند کارت'>
            <Controller
              control={control}
              name={`${reference}.cardBg`}
              render={({ field }) => (
                <ColorPicker
                  disabled={loading}
                  color={field.value}
                  onColorChange={(color) => setValue(field.name, color)}
                />
              )}
            />
          </InputWrapper>
        )}
        {include.includes('fontColor') && (
          <InputWrapper row label='رنگ فونت'>
            <Controller
              control={control}
              name={`${reference}.fontColor`}
              render={({ field }) => (
                <ColorPicker
                  disabled={loading}
                  color={field.value}
                  onColorChange={(color) => setValue(field.name, color)}
                />
              )}
            />
          </InputWrapper>
        )}
        {include.includes('bgColor') && (
          <InputWrapper row label='بکگراند'>
            <Controller
              control={control}
              name={`${reference}.bgColor`}
              render={({ field }) => (
                <ColorPicker
                  disabled={loading}
                  color={field.value}
                  onColorChange={(color) => setValue(field.name, color)}
                />
              )}
            />
          </InputWrapper>
        )}
        {include.includes('highlightColor') && (
          <InputWrapper row label='هایلایت فونت'>
            <Controller
              control={control}
              name={`${reference}.highlightColor`}
              render={({ field }) => (
                <ColorPicker
                  disabled={loading}
                  color={field.value}
                  onColorChange={(color) => setValue(field.name, color)}
                />
              )}
            />
          </InputWrapper>
        )}
        {include.includes('fontHoverColor') && (
          <InputWrapper row label='هاور فونت'>
            <Controller
              control={control}
              name={`${reference}.fontHoverColor`}
              render={({ field }) => (
                <ColorPicker
                  disabled={loading}
                  color={field.value}
                  onColorChange={(color) => setValue(field.name, color)}
                />
              )}
            />
          </InputWrapper>
        )}
        {include.includes('highlightHoverColor') && (
          <InputWrapper row label='هاور هایلایت فونت'>
            <Controller
              control={control}
              name={`${reference}.highlightHoverColor`}
              render={({ field }) => (
                <ColorPicker
                  disabled={loading}
                  color={field.value}
                  onColorChange={(color) => setValue(field.name, color)}
                />
              )}
            />
          </InputWrapper>
        )}
        {include.includes('hoverColor') && (
          <InputWrapper row label='هاور متن'>
            <Controller
              control={control}
              name={`${reference}.hoverColor`}
              render={({ field }) => (
                <ColorPicker
                  disabled={loading}
                  color={field.value}
                  onColorChange={(color) => setValue(field.name, color)}
                />
              )}
            />
          </InputWrapper>
        )}
        {include.includes('bgHoverColor') && (
          <InputWrapper row label='هاور بکگراند'>
            <Controller
              control={control}
              name={`${reference}.bgHoverColor`}
              render={({ field }) => (
                <ColorPicker
                  disabled={loading}
                  color={field.value}
                  onColorChange={(color) => setValue(field.name, color)}
                />
              )}
            />
          </InputWrapper>
        )}
        {include.includes('activeColor') && (
          <InputWrapper row label='رنگ اکتیو'>
            <Controller
              control={control}
              name={`${reference}.activeColor`}
              render={({ field }) => (
                <ColorPicker
                  disabled={loading}
                  color={field.value}
                  onColorChange={(color) => setValue(field.name, color)}
                />
              )}
            />
          </InputWrapper>
        )}
        {include.includes('activeBgColor') && (
          <InputWrapper row label='بکگراند اکتیو'>
            <Controller
              control={control}
              name={`${reference}.activeBgColor`}
              render={({ field }) => (
                <ColorPicker
                  disabled={loading}
                  color={field.value}
                  onColorChange={(color) => setValue(field.name, color)}
                />
              )}
            />
          </InputWrapper>
        )}
        {include.includes('cardBgHover') && (
          <InputWrapper row label='هاور بکگراند کارت'>
            <Controller
              control={control}
              name={`${reference}.cardBgHover`}
              render={({ field }) => (
                <ColorPicker
                  disabled={loading}
                  color={field.value}
                  onColorChange={(color) => setValue(field.name, color)}
                />
              )}
            />
          </InputWrapper>
        )}
      </Box>
    </Box>
  );
};

export default PatternItemStyles;

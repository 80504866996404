// Feature Utilities
import { PROCESS_EVALUATION_DRAFT_FLAG } from 'features/form/processes/utilities/initial/evaluation';

// Feature Types
import {
  ApplicantEvaluationFieldAccessType,
  EvaluatorEvaluationFieldAccessType,
  EvaluatorProfileFiledAccessType,
  EvaluatorQuestionFieldAccessType,
} from 'features/form/processes/types/details/common/itemAccess';

/**
 * Checks if the given ID represents an evaluation draft.
 *
 * @param {string} id - The ID to check.
 * @returns {boolean} True if the ID ends with the evaluation draft flag; otherwise, false.
 */
export const isEvaluationDraft = (id: string) => {
  return id && id.endsWith(PROCESS_EVALUATION_DRAFT_FLAG) ? true : false;
};

export const evaluatorEvaluationAccessItems: {
  value: EvaluatorEvaluationFieldAccessType;
  label: string;
}[] = [
  { value: 'evaluationTitle', label: 'عنوان ارزیابی' },
  { value: 'evaluationQuestion', label: 'سوال ارزیابی' },
  { value: 'selectedOption', label: 'گزینه انتخاب شده' },
  { value: 'message', label: 'پیام پیشفرض' },
  { value: 'evaluator', label: 'نام ارزیاب' },
  { value: 'comment', label: 'کامنت' },
  { value: 'date', label: 'تاریخ' },
  { value: 'reviewDetails', label: 'اطلاعات فیلد بازبینی ' },
];

export const evaluatorManagerQuestionAccessItems: {
  value: EvaluatorQuestionFieldAccessType;
  label: string;
}[] = [{ value: 'questionDetails', label: 'جزئیات پرسش' }];

export const evaluationProfileAccessItems: {
  name: EvaluatorProfileFiledAccessType;
  label: string;
}[] = [
  { name: 'firstname', label: 'نام ' },
  { name: 'lastname', label: 'نام‌خانوادگی ' },
  { name: 'birthdate', label: 'تاریخ تولد' },
  { name: 'email', label: 'ایمیل' },
  { name: 'personnelCode', label: 'کدپرسنلی' },
  { name: 'nationalId', label: 'کد ملی' },
  { name: 'jobTitle', label: 'عنوان شغلی' },
  { name: 'audienceGroup', label: 'گروه مخاطبین' },
  { name: 'biography', label: 'بیوگرافی' },
  { name: 'avatar', label: 'عکس پروفایل' },
];

export const evaluationApplicantFieldsMap = {
  evaluationTitle: 'عنوان ارزیابی',
  evaluationQuestion: 'سوال ارزیابی',
  selectedOption: 'گزینه انتخاب شده',
  message: 'پیام پیشفرض',
  evaluator: 'نام ارزیاب',
  comment: 'کامنت',
  date: 'تاریخ',
};

export const evaluationApplicantFieldsItems: ApplicantEvaluationFieldAccessType[] =
  [
    'evaluationTitle',
    'evaluationQuestion',
    'selectedOption',
    'message',
    'evaluator',
    'comment',
    'date',
  ];

/**
 * Retrieves the corresponding evaluation applicant field access based on the provided field.
 *
 * @param {ApplicantEvaluationFieldAccessType} field - The field for which to retrieve the access type.
 * @returns {string} The corresponding access type if it exists in the evaluation applicant fields map; otherwise, returns the original field.
 */
export const getEvaluationApplicantFieldAccessTitle = (
  field: ApplicantEvaluationFieldAccessType
) => {
  return evaluationApplicantFieldsMap[field] || field;
};

import type {
  AudienceSettingsDataProps,
  AudienceSettingsFieldsDataProps,
  EditableAudienceSettingsFieldsDataProps,
  RequiredAudienceSettingsFieldsDataProps,
} from 'features/audience/settings/types';
import type {
  ApiAdditionalRequiredFieldData,
  ApiAudienceSettingsDataProps,
  ApiProfileRequiredFieldData,
  ApiUpdateRequiredFieldData,
} from 'features/audience/settings/types/api';

export const bakeAudienceSetting = (
  settings: AudienceSettingsDataProps
): Omit<
  ApiAudienceSettingsDataProps,
  | 'profileRequiredField'
  | 'additionalRequiredField'
  | 'updateRequiredField'
  | 'logo'
  | 'title'
> & {
  profileRequiredField: ApiProfileRequiredFieldData[];
  additionalRequiredField: ApiAdditionalRequiredFieldData[];
  updateRequiredField: ApiUpdateRequiredFieldData[];
} => {
  const profileRequiredField: ApiProfileRequiredFieldData[] = [];
  const additionalRequiredField: ApiAdditionalRequiredFieldData[] = [];
  const updateRequiredField: ApiUpdateRequiredFieldData[] = [];

  Object.keys(settings.audienceFields).forEach((field) => {
    const fieldTitle = field as keyof AudienceSettingsFieldsDataProps;

    profileRequiredField.push({
      title: fieldTitle,
      isActive: settings.audienceFields[fieldTitle],
    });
  });

  Object.keys(settings.requiredAudienceFields).forEach((field) => {
    const fieldTitle = field as keyof RequiredAudienceSettingsFieldsDataProps;

    additionalRequiredField.push({
      title: fieldTitle,
      isActive: settings.requiredAudienceFields[fieldTitle],
    });
  });

  Object.keys(settings.editableAudienceFields).forEach((field) => {
    const fieldTitle = field as keyof EditableAudienceSettingsFieldsDataProps;

    updateRequiredField.push({
      title: fieldTitle,
      isActive: settings.editableAudienceFields[fieldTitle],
    });
  });

  return {
    profileRequiredField,
    additionalRequiredField,
    updateRequiredField,
  };
};

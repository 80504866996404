import { useState, type FC } from 'react';

// Custom Hooks
import useSelection from 'core/hooks/common/useSelection';
import { useDeleteTagsMutation, useTagDocs } from 'features/content/tags/hooks';

// Custom Section Components
import TagCard from 'features/content/tags/components/details/Card';
import TagActions from 'features/content/tags/components/list/Actions';
import TagsDataGrid from 'features/content/tags/components/list/DataGrid';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Custom Core Components
import Collapse from 'core/components/base/utils/Collapse';

// Context
import DocsProvider from 'core/hooks/api/useDocs/Context';
import SelectionProvider from 'core/store/context/common/UseSelection';
import { useDialogContext } from 'core/store/context/DialogProvider';

// Custom Utilities
import { isSucceed } from 'core/utilities/helper';
import { getTag } from 'features/content/tags/utilities/api';

// Custom Types
import type { TagProps } from 'features/content/tags/types';

export interface TagsListProps {}

const TagsList: FC<TagsListProps> = (props) => {
  // States
  const [tag, setTag] = useState<TagProps | undefined>();
  const [mode, setMode] = useState<'edit' | 'create' | 'delete' | 'none'>(
    'none'
  );

  // Context
  const { onOpenDialog, onCloseDialog } = useDialogContext();

  // Hooks
  const tagsMethods = useTagDocs();
  const {
    data: { list },
    isFetching,
    onPageNumChange,
    onPageSizeChange,
  } = tagsMethods;
  const selectionMethods = useSelection();
  const { selections, onClearSelections, onSelectionsChange } =
    selectionMethods;

  const { mutate: handleDeleteTags } = useDeleteTagsMutation({
    autoAlert: { mode: 'delete', name: 'برچسب‌های انتخاب شده' },
    onSuccess: () => {
      setMode('none');
      onClearSelections();
      onCloseDialog();
    },
    onError: () => setMode('none'),
  });

  // Utilities
  const handleClose = () => {
    setMode('none');
    onClearSelections();
    setTag(undefined);
  };

  const handleOpen = async () => {
    if (selections.length > 0) {
      let initialized = false;
      if (selections.length === 1) {
        const { status, doc: tag } = await getTag(selections[0]);

        if (isSucceed(status) && tag) {
          setTag(tag);
          initialized = true;
        }
      }

      if (initialized) setMode('edit');
    } else setMode('create');
  };

  const handleRemove = () => {
    setMode('delete');
    onOpenDialog({
      title: 'حذف برچسب',
      description: `آیا از حذف ${
        selections.length > 1 ? 'برچسب‌ها' : 'برچسب'
      } انتخاب شده مطمئن هستید؟`,
      onSubmit: () => handleDeleteTags(selections),
    });
  };

  // Render
  return (
    <SelectionProvider {...selectionMethods}>
      <PageHeadConfigs pageTitle='برچسب‌ها' />
      <DocsProvider {...tagsMethods}>
        <TagActions
          disabled={isFetching}
          mode={mode}
          onOpen={handleOpen}
          onRemove={handleRemove}
        />
        <ColumnStack>
          <Collapse
            id='tag-card'
            in={mode === 'create' || mode === 'edit'}
            unmountOnExit
          >
            <TagCard tag={tag} onClose={handleClose} />
          </Collapse>
          <TagsDataGrid
            tags={list}
            disabled={isFetching || mode === 'create' || mode === 'edit'}
            fetching={isFetching}
            selections={selections}
            onSelectionChange={onSelectionsChange}
            onPageSizeChange={onPageSizeChange}
            onPageNumChange={onPageNumChange}
          />
        </ColumnStack>
      </DocsProvider>
    </SelectionProvider>
  );
};

export default TagsList;

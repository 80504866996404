import * as React from 'react';
import { SxProps, useTheme } from '@mui/material';
import RMDatePicker, { DateObject } from 'react-multi-date-picker';
import gregorian from 'react-date-object/calendars/gregorian';
import gregorian_fa from 'react-date-object/locales/gregorian_fa';
import persian from 'react-date-object/calendars/persian';
import persian_fa from 'react-date-object/locales/persian_fa';

// Core Components
import Box from 'core/components/base/layout/Box';
import TextField from 'core/components/base/inputs/TextField';

// Common Compnoents
import CloseIconButton from 'core/components/shared/IconButton/Close';

// Custom Icons
import CalendarIcon from 'core/components/icons/Calendar';

// Types
import type { BoxProps } from 'core/components/base/layout/Box';
import type { TextFieldProps } from 'core/components/base/inputs/TextField';

export interface DatePickerProps extends BoxProps {
  disabled?: boolean;
  disableIcon?: boolean;
  fullWidth?: boolean;
  format?: string;
  readonly?: boolean;
  iconSx?: SxProps;
  InputProps?: TextFieldProps;
  calendar?: 'Jalali' | 'Gregorian';
  value?: Date;
  onDateChange?: (date: Date | null) => void;
  onClearValue?: () => void;
}

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    disabled,
    disableIcon,
    placeholder = 'تاریخ',
    fullWidth,
    iconSx,
    readonly: readOnly,
    value,
    onDateChange,
    InputProps,
    format = 'YYYY/MM/DD',
    calendar = 'Jalali',
    onClearValue,
    sx,
    ...otherProps
  } = props;

  let height: string | number =
    sx &&
    'height' in sx &&
    (typeof sx.height === 'number' || typeof sx.height === 'string')
      ? sx.height
      : '3rem';

  // States
  const dateRef = React.useRef(null);
  const [pickerValue, setPickerValue] = React.useState<Date | undefined>(
    undefined
  );

  // Hooks
  const { palette } = useTheme();

  React.useEffect(() => {
    setPickerValue(value ?? undefined);
  }, [value]);

  // Event Handlers
  const handleChange = (dates: DateObject | DateObject[] | null) => {
    if (dates && !Array.isArray(dates)) {
      setPickerValue(dates.toDate());
      if (onDateChange) onDateChange(dates.toDate());
    }
  };

  const handleOpen = () => {
    if (dateRef.current && !readOnly && !disabled)
      (dateRef.current as any).openCalendar();
  };

  const handleClear = () => {
    setPickerValue(undefined);
    if (onClearValue) onClearValue();
    if (onDateChange) onDateChange(null);
  };

  // Render
  return (
    <Box
      sx={{
        height,
        width: fullWidth ? '100%' : 'fit-content',
        ...sx,
        position: 'relative',
        '& .date-picker-container': {
          height: '100% !important',
          width: '100%',
          '& .rmdp-input': {
            fontFamily: 'Vazir',
            backgroundColor:
              palette.mode === 'light'
                ? palette.background.paper
                : palette.background.default,
            color: palette.text.primary,
            borderColor: 'rgba(0, 0, 0, 0.23)',
            height: '100%',
            textAlign: 'center',
            width: '100%',
            ':hover': {
              borderColor: palette.text.secondary,
            },
            ':disabled': {
              backgroundColor: 'transparent',
              color: palette.text.disabled,
            },
          },
        },
      }}
      {...otherProps}
    >
      <RMDatePicker
        portal
        ref={dateRef}
        calendar={calendar === 'Jalali' ? persian : gregorian}
        containerClassName='date-picker-container'
        disabled={disabled}
        zIndex={10000}
        format={format}
        onOpenPickNewDate={false}
        locale={calendar === 'Jalali' ? persian_fa : gregorian_fa}
        onChange={handleChange}
        readOnly={readOnly}
        value={pickerValue}
        style={{ height }}
        render={(value = '') => (
          <TextField
            {...InputProps}
            placeholder={placeholder}
            disabled={disabled}
            onFocus={handleOpen}
            onClick={handleOpen}
            onTouchEnd={handleOpen}
            value={value}
            autoComplete='false'
            autoCorrect='false'
            autoCapitalize='false'
            inputProps={{
              autoCorrect: 'false',
              autoCapitalize: 'false',
              ...InputProps?.inputProps,
              style: {
                textAlign: pickerValue ? 'center' : 'start',
                ...InputProps?.style,
              },
            }}
            InputProps={{
              autoCorrect: 'false',
              autoCapitalize: 'false',
              autoComplete: 'false',
              ...InputProps?.InputProps,
              sx: { height, ...InputProps?.InputProps?.sx },
              readOnly,
            }}
            sx={{
              width: '100%',
              ...InputProps?.sx,
            }}
          />
        )}
      />
      {pickerValue ? (
        <CloseIconButton
          size='small'
          onClick={handleClear}
          sx={{
            color: disabled ? palette.text.disabled : palette.text.secondary,
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translate(-30%, -50%)',
          }}
        />
      ) : (
        <>
          {!disableIcon && (
            <Box
              onClick={handleOpen}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                background: '#C1C8D3',
                p: 1.2,
                color: disabled ? palette.text.disabled : palette.text.primary,
                position: 'absolute',
                top: 0,
                right: 0,
                height: '100%',
                borderRadius: ' 0 4px 4px 0',
                ...iconSx,
              }}
            >
              <CalendarIcon />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default DatePicker;

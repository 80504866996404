import { useEffect, useState } from 'react';

// Types
import type { FC, MouseEvent, ReactNode } from 'react';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';
import ListItemButton from 'core/components/base/display/ListItemButton';
import ListItemIcon from 'core/components/base/display/ListItemIcon';
import ListItemText from 'core/components/base/display/ListItemText';
import Menu from 'core/components/base/navigation/Menu';

// Custom Icon Components
import WidthSmIcon from 'core/components/icons/WidthSm';
import WidthLgIcon from 'core/components/icons/WidthLg';
import WidthFullIcon from 'core/components/icons/WidthFull';

// Custom Types
type Width = 'none' | 'lg' | 'xl';
export interface PatternWidthSelectProps {
  defaultValue?: Width;
  onWidthChange?: (width: Width) => void;
  disabled?: boolean;
}

const PatternWidthSelect: FC<PatternWidthSelectProps> = (props) => {
  // Props
  const { disabled, defaultValue, onWidthChange } = props;

  // State
  const [value, setValue] = useState<Width>('lg');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Utilities
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (width?: Width) => {
    setAnchorEl(null);
  };

  const handleChange = (width?: Width) => () => {
    handleClose();

    if (width) {
      onWidthChange && onWidthChange(width);
      setValue(width);
    }
  };

  useEffect(() => {
    if (defaultValue && value !== defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      <Button
        id='pattern-width-button'
        disabled={disabled}
        color='secondary'
        aria-controls={open ? 'pattern-width-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant={disabled ? 'text' : 'outlined'}
        sx={{
          color: disabled ? 'text.secondary' : 'secondary.main',
          width: '2.5rem',
          height: '2.5rem',
          minWidth: 'fit-content',
          p: '0.25rem',
        }}
      >
        {value === 'lg' ? (
          <WidthSmIcon
            sx={{ color: disabled ? 'text.secondary' : 'secondary.main' }}
          />
        ) : value === 'xl' ? (
          <WidthLgIcon
            sx={{ color: disabled ? 'text.secondary' : 'secondary.main' }}
          />
        ) : (
          <WidthFullIcon
            sx={{ color: disabled ? 'text.secondary' : 'secondary.main' }}
          />
        )}
      </Button>
      <Menu
        id='pattern-width-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        MenuListProps={{
          'aria-labelledby': 'pattern-width-button',
        }}
      >
        {(
          [
            {
              maxWidth: 'sm',
              title: 'هیچ کدام (Max 650px wide)',
              Icon: <WidthSmIcon />,
            },
            {
              maxWidth: 'lg',
              title: 'گسترده (Max 1200px wide)',
              Icon: <WidthLgIcon />,
            },
            {
              maxWidth: 'none',
              title: 'کامل',
              Icon: <WidthFullIcon />,
            },
          ] as {
            maxWidth: Width;
            title: string;
            Icon: ReactNode;
          }[]
        ).map((item) => (
          <ListItemButton
            selected={value === item.maxWidth}
            key={item.maxWidth + item.title}
            onClick={handleChange(item.maxWidth)}
          >
            <ListItemIcon>{item.Icon}</ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItemButton>
        ))}
      </Menu>
    </>
  );
};

export default PatternWidthSelect;

// Custom Types
import type { CreatorProps } from 'core/types/shared/creator';

export const initialCreator: CreatorProps = {
  id: '',
  data: {
    firstName: '',
    lastName: '',
    email: '',
    groupId: '',
  },
};

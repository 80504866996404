// Types
import type { FC } from 'react';
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';

// Custom HOCs
import WithAuth from 'core/components/shared/HOC/WithAuth';

// Feature Components
import AccessDataGrid from 'features/audience/accesses/components/DataGrid';

// Custom Common Components
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import LoadingButton from 'core/components/base/inputs/LoadingButton';

// Custom Icon Components
import PlusCircleOutlineIcon from 'core/components/icons/PlusCircleOutline';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Context
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utitlities
import {
  useAudienceAccessDocs,
  useAddAudienceAccessMutation,
  useDeleteAudienceAccessMutation,
  useEditAudienceAccessMutation,
} from 'features/audience/accesses/hooks';

// Custom Types
interface AudienceAccessPageProps extends WithAuthProps {}

const AudienceAccess: FC<AudienceAccessPageProps> = (props) => {
  // Hooks
  const loading = useSelectLoading();
  const { data: accesses } = useAudienceAccessDocs();
  const { mutateAsync: handleEditAccess } = useEditAudienceAccessMutation({
    autoAlert: { mode: 'edit', name: 'اکسس' },
  });
  const { mutate: handleAddAccess } = useAddAudienceAccessMutation({
    autoAlert: { mode: 'create', name: 'اکسس' },
  });
  const { mutate: handleDeleteAccess } = useDeleteAudienceAccessMutation({
    autoAlert: { mode: 'delete', name: 'اکسس' },
  });

  // API Utilities
  const handleAdd = () => {
    handleAddAccess(`اکسس-${accesses.list ? accesses.list.length + 1 : 0}`);
  };

  const handleDelete = (accessId: string) => {
    if (accessId) handleDeleteAccess(accessId);
  };

  const handleUpdate = async (accessId: string, title: string) => {
    if (accessId && title) {
      await handleEditAccess({ id: accessId, data: title });
    }
  };

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='لیست اکسس‌ها' />
      <PageHeroBox>
        <PageTitle>لیست اکسس‌ها</PageTitle>
      </PageHeroBox>
      <ColumnStack>
        <AccessDataGrid
          accessList={accesses.list ? accesses.list : []}
          onDelete={handleDelete}
          onUpdate={handleUpdate}
        />

        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <LoadingButton
            loading={loading}
            loadingPosition='start'
            startIcon={<PlusCircleOutlineIcon />}
            variant='outlined'
            color='info'
            onClick={handleAdd}
          >
            اکسس جدید
          </LoadingButton>
        </Box>
      </ColumnStack>
    </>
  );
};

export default WithAuth(AudienceAccess);

import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// Custom Layouts
import MainLayout from 'core/components/layouts/Main';

// Custom Components
import RouteChangeListener from 'core/components/shared/RouteChangeListener';

// Custom Routes
import protectedRoutes from 'core/configs/protectedRoutes';
import { commonRoutes } from 'core/configs/protectedRoutes/commonRoutes';

// Custom Hooks
import { useAppDispatch } from 'core/hooks/redux';

// Custom Utilities
import { getToken } from 'core/utilities/token/token';
import { getProfile } from 'features/manager/auth/utilities/shared/api';
import { initializeManager } from 'core/store/slices/feature/manager';
import { forLoop, isSucceed } from 'core/utilities/helper';
import { getPermissionCookies } from 'core/utilities/permissions';

// Context
import UploadDialogProvider from 'features/file/files/context/UploadDialog';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';

const ProtectedRoutesProvider: React.FC = () => {
  // Hooks
  const dispatch = useAppDispatch();

  const [initialized, setInitialized] = React.useState(false);
  const [pRoutes, setPRoutes] =
    React.useState<ProtectedRouteProps[]>(protectedRoutes);

  const handleInitializeRoutes = React.useCallback(() => {
    if (!initialized) {
      const perms = getPermissionCookies();

      const routes: ProtectedRouteProps[] = [];

      forLoop(protectedRoutes, (route) => {
        const feature = perms.find((perm) => perm.feature === route.feature);

        if (feature) {
          const subFeature = feature.subFeatures.find(
            (subFeature) => subFeature === route.subFeature
          );

          if (subFeature) {
            routes.push(route);
          }
        }
      });

      setPRoutes(routes);
      setInitialized(true);
    }
  }, [initialized]);

  const synchornizeProfileData = React.useCallback(async () => {
    const hasToken = getToken() ? true : false;
    if (hasToken) {
      const { status, doc } = await getProfile();

      if (status && isSucceed(status) && doc) dispatch(initializeManager(doc));
    }
  }, [dispatch]);

  React.useEffect(() => {
    synchornizeProfileData();
  }, [synchornizeProfileData]);

  React.useEffect(() => {
    handleInitializeRoutes();
  }, [handleInitializeRoutes]);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <MainLayout>
          <RouteChangeListener>
            <UploadDialogProvider>
              <Routes>
                {commonRoutes.map(({ Component, path }) => (
                  <Route
                    caseSensitive
                    key={`common-route-${path}`}
                    path={path}
                    element={React.createElement(Component)}
                  />
                ))}
                {pRoutes.map(({ Component, path, feature, subFeature }) => (
                  <Route
                    key={feature + subFeature}
                    caseSensitive
                    path={path}
                    element={React.createElement(Component)}
                  />
                ))}
                <Route path='/' element={<Navigate to='/contents' />} />
                <Route path='*' element={<Navigate to='/404' />} />
              </Routes>
            </UploadDialogProvider>
          </RouteChangeListener>
        </MainLayout>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default ProtectedRoutesProvider;

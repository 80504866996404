import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

// Utilities
import { initialSettings } from 'features/manager/settings/utilities';

// Types
import {
  SettingsPaletteProps,
  SettingsProps,
} from 'features/manager/settings/types';

const initialState = initialSettings;

interface UpdateThemeProps {
  theme: SettingsPaletteProps;
  index: number;
}
export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    updateSetting: (
      state: SettingsProps,
      action: PayloadAction<SettingsProps>
    ) => {
      state = action.payload;
      return state;
    },
    updateTheme: (
      state: SettingsProps,
      action: PayloadAction<UpdateThemeProps>
    ) => {
      const clonedState = cloneDeep(state);

      clonedState.data.themes[action.payload.index] = action.payload.theme;

      return clonedState;
    },
  },
});

export const { updateSetting, updateTheme } = settingSlice.actions;
export default settingSlice.reducer;

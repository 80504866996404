// Feature Types
import type { PatternTitleItemProps } from 'features/appBuilder/patterns/core/types/item/title';
import type { FormPatternButtonProps } from 'features/appBuilder/patterns/features/form/types/button';

const commonProps: PatternTitleItemProps = {
  isActive: true,
  padding: '0px',
  fontSize: '15',
  textDirection: 'rtl',
  fontFamily: 'vazir',
  text: '',
  textAlign: 'start',
  fontColor: '#FFFFFF',
  highlightColor: '#0091FF',
  bgColor: '#0091FF',
  fontHoverColor: '#FFFFFF',
  highlightHoverColor: '#0091FF',
  bgHoverColor: '#0091FF',
};

export const formPatterButtonDefaults: FormPatternButtonProps = {
  isActive: true,
  next: {
    ...commonProps,
    borderRadius: 8,
    text: 'مرحله بعد',
  },
  prev: {
    ...commonProps,
    borderRadius: 8,
    text: 'مرحله قبل',
  },
  confirm: {
    ...commonProps,
    borderRadius: 8,
    text: 'تایید',
  },
  edit: {
    ...commonProps,
    borderRadius: 8,
    text: 'ویرایش',
  },
  submit: {
    ...commonProps,
    borderRadius: 8,
    text: 'ثبت فرم',
  },
  back: {
    ...commonProps,
    borderRadius: 8,
    text: 'بازگشت',
    fontColor: '#0091FF',
    bgColor: 'rgba(0, 0, 0, 0)',
    fontHoverColor: '#0091FF',
    bgHoverColor: 'rgba(0, 0, 0, 0)',
  },
  view: {
    ...commonProps,
    borderRadius: 8,
    text: 'مشاهده',
    fontColor: '#0091FF',
    bgColor: 'rgba(0, 0, 0, 0)',
    fontHoverColor: '#0091FF',
    bgHoverColor: 'rgba(0, 0, 0, 0)',
  },
  addRepetitive: {
    ...commonProps,
    borderRadius: 8,
    fontSize: '16',
    text: 'افزودن «{title}» جدید',
    fontColor: '#0091FF',
    bgColor: '#C1C8D3',
    fontHoverColor: '#0091FF',
    bgHoverColor: '#C1C8D3',
  },
  delRepetitive: {
    ...commonProps,
    borderRadius: 8,
    fontSize: '16',
    text: 'حذف «{title}»',
    fontColor: '#D32F2F',
    bgColor: '#C1C8D3',
    fontHoverColor: '#D32F2F',
    bgHoverColor: '#C1C8D3',
  },
  cancel: {
    ...commonProps,
    borderRadius: 8,
    fontSize: '16',
    text: 'لغو تغییرات',
    fontColor: '#D32F2F',
    bgColor: '#C1C8D3',
    fontHoverColor: '#D32F2F',
    bgHoverColor: '#C1C8D3',
  },
};

// Custom Utilities
import { generateObjectId } from 'core/utilities/helper/id';
import { getInitialCommonInputProps } from 'features/form/forms/utilities/input/initial/common';

// Custom Types
import type { FormInputInitialParams } from 'features/form/forms/utilities/input/initial/common';
import type { FormTextAreaInputProps } from 'features/form/forms/types/item/input/textarea';

interface Params extends FormInputInitialParams {}

export const genFormTextareaInput = (
  params: Params
): FormTextAreaInputProps => {
  const commonProps = getInitialCommonInputProps({
    ...params,
  });

  return {
    id: `draft-${generateObjectId()}`,
    data: {
      ...commonProps,
      minLength: 1,
      maxLength: 1000,
      type: 'textarea',
    },
  };
};

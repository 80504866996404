import store from 'core/store';
import { getLocaleDate, getLocaleTime } from 'core/utilities/helper/date';
import { forLoop } from 'core/utilities/helper';
import {
  convertPersianDigitsToLatin,
  getFileSource,
} from 'core/utilities/helper/helperPack';
import type { ProcessFilterNameType } from '../../components/details/Actions/Filter/ViewSettings/ProcessFields';
import type {
  AudienceField,
  FeedbackInputDataProps,
  FeedbackInputProps,
  FeedbackProps,
} from 'features/form/feedbacks/types';

/**
 * Retrieves the input value from the feedback data.
 *
 * @param {string} inputId - The ID of the input value to retrieve.
 * @param {number} index - The index of the feedback in the feedbacks array.
 * @returns {Array} - The input value from the feedback data, or an empty array if not found.
 */
export const getInputValue = (inputId: string, index: number = -1): string[] =>
  (index > -1
    ? store?.getState?.()?.feedback?.pdfValues?.[index]?.data?.values?.[inputId]
    : store?.getState?.()?.feedback?.feedbackOverview?.feedback?.data?.values?.[
        inputId
      ]) || [];

/**
 * Retrieves the value of an input from the excelValues object in the store.
 * If an index is provided, it retrieves the value from the excelValues array at that index.
 * @param {string} inputId - The ID of the input.
 * @param {number} [index=-1] - The index of the excelValues array. Defaults to -1.
 * @returns {string[]} The value of the input as an array.
 */
export const getExcelInputValue = (
  inputId: string,
  index: number = -1
): string[] => {
  return (
    store?.getState?.()?.feedback?.excelValues?.[index]?.data?.values?.[
      inputId
    ] || []
  );
};

export const isInputFullWidth = (inputId: string) => {
  return inputId in store?.getState?.()?.feedback?.fullWidthInputs || false;
};

/**
 * This keyword is a reserved key for feedback input value seperator(For example checkbox with multiple options checked.)
 * Example --> 'opt1%--Sep--%opt2';
 */
export const feedbackSeperatorKey = '%--Sep--%';

/**
 * Converts feedback value based on input type and value.
 * @param {FeedbackInputDataProps} input - The input data properties.
 * @param {string[]} value - The array of values to convert.
 * @returns {string} The converted feedback value.
 */
export const convertFeedbackValue = (
  input: FeedbackInputDataProps,
  value: string[]
): string => {
  let output = '';

  if (value.length === 0) return '-';
  switch (input.type) {
    case 'file':
      output = value
        .map((val) => val.split(feedbackSeperatorKey).join(' - ') || ' - ')
        .join(' / ');
      break;
    case 'date':
      output = value
        .map((v) =>
          v
            .split(feedbackSeperatorKey)
            .map((val) =>
              val ? getLocaleDate(val, input.calendarType, input.pick) : '-'
            )
            .join(' - ')
        )
        .join(' / ');
      break;
    case 'time':
      output = value
        .map((v) =>
          v
            .split(feedbackSeperatorKey)
            .map((val) => (val ? getLocaleTime(val, input.calendarType) : '-'))
            .join(' - ')
        )
        .join(' / ');
      break;
    default:
      output = convertPersianDigitsToLatin(
        value
          .map((val) => val.split(feedbackSeperatorKey).join(' - ') || ' - ')
          .join(' / ')
      );
      break;
  }

  return output || '-';
};

/**
 * Gets audience information value based on field name.
 * @param {AudienceField} fieldName - The field name.
 * @param {string} value - The value to process.
 * @returns {string} The processed audience information value.
 */
export const getAudienceInfoValue = (
  fieldName: AudienceField,
  value: string
) => {
  if (!value) return '-';
  switch (fieldName) {
    case 'avatar':
      return getFileSource(value);

    case 'birthDate':
      return convertPersianDigitsToLatin(getLocaleDate(value));

    default:
      return value;
  }
};

/**
 * Renders the value if the section is visible based on the hidden sections.
 * @param {Partial<Record<ProcessFilterNameType, boolean>>} hiddenSections - The hidden sections object.
 * @param {ProcessFilterNameType} name - The name of the section.
 * @param {string} value - The value to render.
 * @returns {T} The rendered value or undefined if section is hidden.
 */
export const renderIfVisible = <T = any>(
  hiddenSections: Partial<Record<ProcessFilterNameType, boolean>>,
  name: ProcessFilterNameType,
  value: T
): any => (hiddenSections?.[name] ? undefined : value || '');

/**
 * Function to check if a specific process section is hidden.
 * @param {Record<string, Partial<Record<ProcessFilterNameType, boolean>>>} hiddenSections - Object containing hidden sections information.
 * @param {string} processId - The ID of the process.
 * @param {ProcessFilterNameType} name - The name of the process filter.
 * @returns {boolean} - Returns true if the section is hidden, false otherwise.
 */
export const isProcessSectionHidden = (
  hiddenSections: Record<
    string,
    Partial<Record<ProcessFilterNameType, boolean>>
  >,
  processId: string,
  name: ProcessFilterNameType
) => hiddenSections[processId]?.[name] || false;

/**
 * Function to calculate the total number of rows for the process table header cells.
 * @param {Record<string, Partial<Record<ProcessFilterNameType, boolean>>>} hiddenSections - Object containing hidden sections information.
 * @param {FeedbackProps[]} feedbacks - Array of feedback properties.
 * @returns {number} - Total number of rows for the process table header cells.
 */
export const calculateProcessTableHeaderCells = (
  hiddenSections: Record<
    string,
    Partial<Record<ProcessFilterNameType, boolean>>
  >,
  feedbacks: FeedbackProps[]
) => {
  let totalRows = 0;
  forLoop(feedbacks, (feedback) => {
    if (!feedback.data.process) return;
    forLoop(
      feedback.data.process.data.items,
      ({ id: itemId, data: item }, index) => {
        const processId = feedback.data.process?.id || '';
        const isManagerQuestion = item.processItemType === 'managerQuestion';
        let count = 0;
        if (isProcessSectionHidden(hiddenSections, processId, 'all')) return;
        if (
          isManagerQuestion &&
          isProcessSectionHidden(hiddenSections, processId, 'questionDetails')
        )
          return;

        if (item.processItemType === 'managerQuestion') {
          if (item?.title) ++count;
          if (item?.questionTitle) ++count;
          if (item?.applicantAnswer) ++count;
          if (
            item?.applicantAnswerFiles &&
            item.applicantAnswerFiles.length > 0
          )
            ++count;
          if (item?.completionDate) ++count;
        } else {
          if (
            !isProcessSectionHidden(hiddenSections, itemId, 'title') &&
            item.title
          )
            ++count;
          if (
            !isProcessSectionHidden(hiddenSections, itemId, 'questionTitle') &&
            item.questionTitle
          )
            ++count;
          if (
            !isProcessSectionHidden(hiddenSections, itemId, 'message') &&
            item.message
          )
            ++count;
          if (
            !isProcessSectionHidden(hiddenSections, itemId, 'selectedOpt') &&
            item.selectedOptionTitle
          )
            ++count;
          if (
            !isProcessSectionHidden(hiddenSections, itemId, 'date') &&
            item.completionDate
          )
            ++count;
          if (
            !isProcessSectionHidden(hiddenSections, itemId, 'comment') &&
            (item.evaluatorComment ||
              (item.evaluatorCommentFiles &&
                item.evaluatorCommentFiles.length > 0))
          ) {
            if (item.evaluatorComment) ++count;
            if (
              item.evaluatorCommentFiles &&
              item.evaluatorCommentFiles.length > 0
            )
              ++count;
          }
          if (
            !isProcessSectionHidden(
              hiddenSections,
              itemId,
              'evaluatorFullname'
            ) &&
            item.evaluatorFullname
          )
            ++count;

          if (
            !isProcessSectionHidden(hiddenSections, itemId, 'review') &&
            item.reviews &&
            item.reviews.length > 0
          ) {
            forLoop(item.reviews, (review) => {
              if (review.data.reviewQuestionTitle) ++count;
              if (review.data.applicantReviewAnswer) ++count;
              if (
                review.data.applicantReviewFiles &&
                review.data.applicantReviewFiles.length > 0
              )
                ++count;
              if (review.data.completionDate) ++count;
            });
          }
        }

        totalRows += count;
      }
    );
  });

  return totalRows;
};

export const isInputHidden = (
  hiddenInputs: Record<string, boolean>,
  input: FeedbackInputProps
) => input.id in hiddenInputs || input.data.sectionId in hiddenInputs || false;

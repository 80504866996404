import * as Yup from 'yup';

// Custom Schemas
import {
  emailSchema,
  firstNameSchema,
  lastNameSchema,
} from 'core/utilities/validationSchema/common/Schemas';

const EditManagerValidationSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  password: Yup.string().test({
    test: (value) => {
      if (!value) return true;

      if (value && value.length >= 8) return true;

      return false;
    },
    name: 'کلمه‌عبور',
    message: 'کلمه‌عبور باید دارای حداقل ۸ کاراکتر باشد.',
  }),
});

export default EditManagerValidationSchema;

import { type FC } from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Core Components
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface FullWidthRightIconProps extends SvgIconProps {}

const FullWidthRightIcon: FC<FullWidthRightIconProps> = (props) => {
  return (
    <IconBuilder viewBox='0 0 12 20' {...props}>
      <path d='M11.707 9.293L7.707 5.293C7.5184 5.11084 7.2658 5.01005 7.0036 5.01233C6.7414 5.0146 6.49059 5.11977 6.30518 5.30518C6.11977 5.49059 6.0146 5.7414 6.01233 6.0036C6.01005 6.2658 6.11084 6.5184 6.293 6.707L8.586 9H2V1C2 0.734784 1.89464 0.48043 1.70711 0.292893C1.51957 0.105357 1.26522 0 1 0C0.734784 0 0.480429 0.105357 0.292892 0.292893C0.105356 0.48043 0 0.734784 0 1V19C0 19.2652 0.105356 19.5196 0.292892 19.7071C0.480429 19.8946 0.734784 20 1 20C1.26522 20 1.51957 19.8946 1.70711 19.7071C1.89464 19.5196 2 19.2652 2 19V11H8.586L6.293 13.293C6.19749 13.3852 6.12131 13.4956 6.0689 13.6176C6.01649 13.7396 5.9889 13.8708 5.98775 14.0036C5.9866 14.1364 6.0119 14.2681 6.06218 14.391C6.11246 14.5138 6.18671 14.6255 6.28061 14.7194C6.3745 14.8133 6.48615 14.8875 6.60905 14.9378C6.73194 14.9881 6.86362 15.0134 6.9964 15.0123C7.12918 15.0111 7.2604 14.9835 7.3824 14.9311C7.50441 14.8787 7.61475 14.8025 7.707 14.707L11.707 10.707C11.8945 10.5195 11.9998 10.2652 11.9998 10C11.9998 9.73484 11.8945 9.48053 11.707 9.293Z' />
    </IconBuilder>
  );
};
export default FullWidthRightIcon;

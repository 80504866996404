// Custom Page Components
import Tickets from 'features/ticket/tickets/components/list';
import TicketDetails from 'features/ticket/tickets/components/details';

// Custom Utilities
import { getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'ticket';
const subFeature: SubFeatureType = 'tickets';
const ticketsRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/tickets',
    Component: Tickets,
    feature,
    subFeature,
  },
  {
    path: '/tickets/:id',
    Component: TicketDetails,
    feature,
    subFeature,
  },
];

export default ticketsRoutes;

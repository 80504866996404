import { sortMenus } from 'features/menu/menus/utilities/helper';
import { isSame } from 'core/utilities/helper/helperPack';
import { useAppSelector } from 'core/hooks/redux';
import { MenuItemProps } from 'features/menu/menus/types';

export const useSelectSortedParents = () =>
  useAppSelector(
    (store) =>
      store.menu.current?.data.items
        .filter((v) => v.data.parentIds.length === 0)
        .sort(sortMenus) || [],
    isSame
  );

export const useSelectSortedChilds = (parentId: string, hasChildren: boolean) =>
  useAppSelector((store) => {
    if (!hasChildren) return [];
    return (
      store.menu.current?.data.items
        .filter((v) => v.data.parentIds.at(-1) === parentId)
        .sort(sortMenus) || []
    );
  }, isSame);

export const useSelectItemsWithNoParent = (): MenuItemProps[] =>
  useAppSelector((state) =>
    state.menu.current?.data.items
      .filter((v) => v.data.parentIds.length === 0)
      .sort(sortMenus)
  ) || [];

export const useSelectItemsWithNoParentCount = (): number =>
  useAppSelector(
    (state) =>
      state.menu.current?.data.items.filter(
        (v) => v.data.parentIds.length === 0
      ) || []
  ).length;

export const useSelectMenuActionMode = () =>
  useAppSelector((state) => state.menu.mode);

export const useSelectMenuContainerId = () =>
  useAppSelector((state) => state.menu.current?.id || '');

export const useSelectIsDeleteMode = () =>
  useAppSelector((state) => state.menu.mode === 'DELETE');

export const useSelectIsMenuSelectedForDeletion = (
  menu: MenuItemProps
): { selected: boolean; parentSelected: boolean } =>
  useAppSelector((state) => {
    const deleteMap = state.menu.deleteIdsMap;
    if (menu.id in deleteMap) return { selected: true, parentSelected: false };
    if (menu.data.parentIds.length > 0)
      return {
        selected: false,
        parentSelected: menu.data.parentIds.some(
          (parentId) => parentId in deleteMap
        ),
      };
    return { selected: false, parentSelected: false };
  });

export const useSelectDeleteIdsMap = () =>
  useAppSelector((state) => state.menu.deleteIdsMap);

export const useSelectInThisEditItem = (menuId: string) =>
  useAppSelector((state) => state.menu.inEditItem === menuId);

export const useSelectInEditItem = () =>
  useAppSelector((state) => state.menu.inEditItem);

export const useSelectMenuChildrens = (menu: MenuItemProps) =>
  useAppSelector((state) => {
    return menu.data.hasSubItems
      ? (
          state.menu.current?.data.items.filter(
            (item) => item.data.parentIds.at(-1) === menu.id
          ) || []
        ).sort(sortMenus)
      : [];
  });

export const useSelectMenuContainerTitle = () =>
  useAppSelector((state) => state.menu.current?.data.title || '');

export const useSelectOriginalMenuItems = () =>
  useAppSelector((state) => state.menu.originalItems || []);

export const useSelectMenuItems = () =>
  useAppSelector((state) => state.menu.current?.data.items || []);

// Feature Utilities
import { bakePatternCommonProps } from 'features/appBuilder/patterns/core/utilities/api/baker';

// Feature Types
import type { PatternGroupColumnProps } from 'features/appBuilder/patterns/features/group/types/column';
import type { GroupPatternDataProps } from 'features/appBuilder/patterns/features/group/types';

/**
 * Transforms the provided column data by ensuring all required fields are included.
 *
 * @param {PatternGroupColumnProps} [column] - The column data to transform. If not provided, a default column configuration is used.
 * @returns {PatternGroupColumnProps} The transformed column data with all required fields included.
 */
export const bakeColumn = (column?: PatternGroupColumnProps) => {
  return column
    ? {
        title: column.data.title,
        width: column.data.width,
        items: column.data.items.map((item) => item.data),
      }
    : null;
};

/**
 * Transforms the provided group pattern data by ensuring all required fields are included and formatted correctly.
 *
 * @param {GroupPatternDataProps} data - The group pattern data to transform.
 * @returns {Omit<GroupPatternDataProps, 'creator'> & { creator: string }} The transformed group pattern data with the `creator` field explicitly set as a string.
 */
export const bakeGroupPatternData = (
  data: GroupPatternDataProps
): Omit<
  GroupPatternDataProps,
  'creator' | 'columnOne' | 'columnTwo' | 'columnThree'
> & {
  creator: string;
  columnOne: {} | null;
  columnTwo: {} | null;
  columnThree: {} | null;
} => {
  const { columnOne, columnTwo, columnThree, ...otherProps } = data;

  const commonProps = bakePatternCommonProps(otherProps);

  return {
    ...commonProps,
    columnOne: bakeColumn(columnOne ?? undefined),
    columnTwo: bakeColumn(columnTwo ?? undefined),
    columnThree: bakeColumn(columnThree ?? undefined),
  };
};

import { forwardRef, useCallback, useEffect, useState } from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Types
import type { FC, ReactNode } from 'react';
import type { SxProps, Theme } from '@mui/material';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Select from 'core/components/base/inputs/Select';
import MenuItem from 'core/components/base/navigation/MenuItem';
import FormHelperText from 'core/components/base/inputs/FormHelperText';
import CircularProgress from 'core/components/base/feedback/CircularProgress';

// Custom Icons
import ArrowDropDownIcon from 'core/components/icons/ArrowDropDown';

// Custom Hooks
import useUpdateEffect from 'core/hooks/useUpdateEffect';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import { setAppLoading } from 'core/utilities/helper';
import { useTemplateSkinDocs } from 'features/appBuilder/skins/hooks';

// Custom Types
import type { TemplateSkinProps } from 'features/appBuilder/skins/types/skin';
interface SelectProps {
  noDisableOnFetch?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  helperText?: React.ReactNode;
  error?: boolean;
  onChange?: (skinId: string) => void;
}

const IconWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <Box
    sx={({ palette }) => ({
      color: palette.info.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '2rem',
      px: '1rem',
    })}
  >
    {children}
  </Box>
);

const SkinSelect: FC<SelectProps> = forwardRef((props, ref) => {
  // Props
  const {
    noDisableOnFetch = false,
    defaultValue,
    sx,
    disabled,
    error,
    helperText,
    onChange,
  } = props;

  // State
  const [selectedId, setSelectedId] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  // Context
  const loading = useSelectLoading();

  // Hooks
  const {
    data: { list: options = [] },
    isFetching,
    isFetched,
  } = useTemplateSkinDocs({ defaultPageSize: 999 });

  const { palette } = useTheme();

  useUpdateEffect(() => {
    if (noDisableOnFetch) return;
    setAppLoading(isFetching);
  }, [isFetching]);

  useUpdateEffect(() => {
    if (isFetched) {
      const valueToSet = options.find((v) => v.id === defaultValue);
      if (valueToSet) setSelectedId(valueToSet.id);
    }
  }, [isFetched]);

  useUpdateEffect(() => {
    if (isFetched && onChange) onChange(selectedId);
  }, [selectedId]);

  // Utilities
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const LoadingIcon: FC = useCallback(
    () => (
      <IconWrapper>
        <CircularProgress color='info' size={24} />
      </IconWrapper>
    ),
    []
  );

  const ArrowUpIcon: FC = useCallback(
    () => (
      <IconWrapper>
        <ArrowDropDownIcon
          onClick={handleClose}
          color='info'
          sx={{ transform: 'rotate(-180deg)' }}
        />
      </IconWrapper>
    ),
    []
  );

  const ArrowDownIcon: FC = useCallback(
    () => (
      <IconWrapper>
        <ArrowDropDownIcon onClick={handleOpen} color='inherit' />
      </IconWrapper>
    ),
    []
  );

  const SelectRoot = (
    <Select
      ref={ref}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      displayEmpty
      value={selectedId}
      renderValue={(valueId) => {
        if (valueId && typeof valueId === 'string') {
          const value = options.find((opt) => opt.id === valueId);

          if (value) {
            return <BodyTwo>{value.data.title}</BodyTwo>;
          }
        }

        return <BodyTwo color='text.disabled'>بدون اسکین</BodyTwo>;
      }}
      disabled={loading || disabled}
      sx={{
        backgroundColor: palette.background.paper,
        borderColor: 'red',
        height: '3rem',
        boxShadow: 'none',
        '.MuiSvgIcon-root': {
          color: disabled ? palette.text.disabled : palette.info.main,
          cursor: 'pointer',
          fontSize: '1.5rem',
        },
        '& fieldset': {
          borderColor: error ? palette.error.main : undefined,
        },
        ...sx,
      }}
      IconComponent={loading ? LoadingIcon : open ? ArrowUpIcon : ArrowDownIcon}
      MenuProps={{
        sx: { marginTop: '2px' },
        PaperProps: {
          sx: {
            boxShadow: 'none',
            border: '1px solid #C1C8D3',
            padding: '0.25rem',
          },
        },
        MenuListProps: { sx: { padding: 0 } },
      }}
    >
      {options.map((opt) => (
        <MenuItem
          selected={opt.id === selectedId}
          key={opt.id}
          sx={{ background: palette.background.paper }}
          onClick={() => setSelectedId(opt.id)}
        >
          {opt.data.title}
        </MenuItem>
      ))}
    </Select>
  );

  // Render
  return (
    <>
      {helperText ? (
        <ColumnStack spacing={0}>
          {SelectRoot}
          <FormHelperText
            sx={{
              color: error ? palette.error.main : palette.text.primary,
              fontSize: '.75rem',
              fontWeight: 400,
              lineHeight: 1.66,
            }}
          >
            {helperText}
          </FormHelperText>
        </ColumnStack>
      ) : (
        SelectRoot
      )}
    </>
  );
});

export default SkinSelect;

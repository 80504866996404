import {
  TableContainer as MuiTableContainer,
  TableContainerProps as MuiTableContainerProps,
  styled,
} from '@mui/material';

export interface TableContainerProps extends MuiTableContainerProps {
  component?: React.ElementType;
}

const TableContainer = styled(MuiTableContainer)<TableContainerProps>(
  () => ({})
);

export default TableContainer;

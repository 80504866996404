import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { sanitize } from 'isomorphic-dompurify';

// Custom Hooks
import useArray from 'core/hooks/useArray';
import useBoolean from 'core/hooks/useBoolean';
import { useDeleteFileMutation, useFileDocs } from 'features/file/files/hooks';

// Core Components
import Paper from 'core/components/base/surfaces/Paper';
import Button from 'core/components/base/inputs/Button';
import Collapse from 'core/components/base/utils/Collapse';

// Custom Components
import Pagination from 'core/components/shared/Filter/Pagination';
import PageSize from 'core/components/shared/Filter/PageSize';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import RowStack from 'core/components/shared/Stack/RowStack';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Feature Components
import DeleteDialog from 'features/file/files/components/list/DeleteDialog';
import FilesTable from 'features/file/files/components/shared/Table';
import FileFilter from 'features/file/files/components/list/Filter';
import SearchInput from 'features/file/files/components/list/SearchInput';

// Custom Icons
import DeleteIcon from 'core/components/icons/DeleteIcon';
import AddCircleOutlineIcon from 'core/components/icons/PlusCircleOutliend';

// Custom Utilities
import { generateObjectId } from 'core/utilities/helper/id';

// Context
import DocsProvider from 'core/hooks/api/useDocs/Context';
import { filterInvalidFiles } from 'core/utilities/helper/file';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { setFailedCrudAlert, setSuccessCrudAlert } from 'core/utilities/helper';

// Custom Types
import type { FileFilterProps, FileProps } from 'features/file/files/types';

interface FilesProps {
  disableDragAndDrop?: boolean;
  disableUploadButton?: boolean;
  disablePageTitle?: boolean;
  disableDeleteButton?: boolean;
}

const Files: React.FC<FilesProps> = (props) => {
  // Props
  const {
    disableDragAndDrop,
    disablePageTitle,
    disableUploadButton,
    disableDeleteButton,
  } = props;

  // States
  const showFilter = useBoolean();
  const showDeleteDialog = useBoolean();
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const uploadQueue = useArray<{ id: string; data: File }>();
  const files = useArray<FileProps>();
  const selectedFiles = useArray<FileProps>();

  // Context
  const loading = useSelectLoading();

  // Hooks
  const [searchParams, setSearchParams] = useSearchParams();
  const filesFilterMethods = useForm<FileFilterProps>({
    defaultValues: {
      search: sanitize(searchParams.get('search') || ''),
      startDate: sanitize(searchParams.get('startDate') || ''),
      uploadBy: sanitize(searchParams.get('uploadBy') || ''),
      endDate: sanitize(searchParams.get('endDate') || ''),
      type: (searchParams.getAll('type').map((type) => sanitize(type)) ||
        []) as any[],
      location: (searchParams
        .getAll('location')
        .map((location) => sanitize(location)) || []) as any[],
    },
  });
  const { getValues, reset } = filesFilterMethods;

  const filesDocs = useFileDocs({
    onFetch: files.set,
    defaultPageSize: 10,
  });

  const { onFilter, onPageNumChange, onPageSizeChange } = filesDocs;

  const { mutate: handleDeleteFiles } = useDeleteFileMutation({
    onSuccess: (_, selections) => {
      setSuccessCrudAlert('delete', selections.length > 1 ? 'فایل‌ها' : 'فایل');
      showDeleteDialog.setFalse();
      selectedFiles.clear();
    },
    onError: (_, selections) =>
      setFailedCrudAlert('delete', selections.length > 1 ? 'فایل‌ها' : 'فایل'),
  });

  // Utilities
  const handleOpenFileSelect = () =>
    fileInputRef && fileInputRef.current && fileInputRef.current.click();

  const handleFilter = React.useCallback(() => {
    const {
      startDate,
      endDate,
      search,
      limit,
      location,
      page,
      type,
      minSize,
      maxSize,
    } = getValues();

    onFilter({
      ...(type.length > 0 && { type: type.map((item) => sanitize(item)) }),
      ...(page && { page: sanitize(String(page)) }),
      ...(startDate && { startDate: sanitize(startDate) }),
      ...(endDate && { endDate: sanitize(endDate) }),
      ...(limit && { limit: sanitize(String(limit)) }),
      ...(search && { search: sanitize(search) }),
      ...(minSize && { minSize: sanitize(minSize) }),
      ...(maxSize && { maxSize: sanitize(maxSize) }),
      ...(location.length > 0 && {
        location: location.map((loc) => sanitize(loc)),
      }),
    });
  }, []);

  const handleDeleteFilter = React.useCallback(() => {
    setSearchParams({});
    reset({
      type: [],
      location: [],
      startDate: '',
      endDate: '',
      minSize: '',
      maxSize: '',
      search: '',
    });
  }, []);

  const handleSelectFile = React.useCallback(
    (file: FileProps, selected: boolean) => {
      if (selected) selectedFiles.push(file);
      else {
        const index = selectedFiles.state.findIndex(
          (item) => item.id === file.id
        );
        selectedFiles.deleteByIndex(index);
      }
    },
    [selectedFiles.length]
  );

  const handleToggleAll = React.useCallback(() => {
    if (selectedFiles.length === files.length) selectedFiles.clear();
    else selectedFiles.set(files.state);
  }, [selectedFiles.length, files.length]);

  // Render
  return (
    <DocsProvider {...filesDocs}>
      <FormProvider {...filesFilterMethods}>
        <PageHeadConfigs pageTitle='فایل‌ها' />
        <ColumnStack>
          <Collapse in={showFilter.state}>
            <FileFilter
              onFilter={handleFilter}
              onDeleteFilter={handleDeleteFilter}
              onCloseFilter={showFilter.setFalse}
              onShowFilter={showFilter.setTrue}
            />
          </Collapse>
          <Collapse in={!showFilter.state}>
            <PageHeroBox>
              {!disablePageTitle && <PageTitle>فایل‌ها</PageTitle>}
              <RowStack alignItems='center' justifyContent='end'>
                {!disableDeleteButton && !selectedFiles.isEmpty && (
                  <Button
                    type='button'
                    role='button'
                    onClick={showDeleteDialog.setTrue}
                    component={Paper}
                    startIcon={<DeleteIcon />}
                    variant='contained'
                    sx={{
                      height: '2.5rem',
                      backgroundColor: 'background.paper',
                      color: 'error.main',
                      borderRadius: '.5rem',
                      ':hover': {
                        opacity: '.9s',
                        backgroundColor: 'background.paper',
                      },
                    }}
                  >
                    {`حذف ${selectedFiles.length} فایل`.toPersian()}
                  </Button>
                )}
                <SearchInput
                  onSearch={handleFilter}
                  onShowFilter={showFilter.setTrue}
                />
                {!disableUploadButton && !showFilter.state && (
                  <Button
                    onClick={handleOpenFileSelect}
                    variant='contained'
                    color='info'
                    startIcon={<AddCircleOutlineIcon />}
                    sx={{
                      height: '2.5rem',
                      borderRadius: '.5rem',
                    }}
                  >
                    افزودن فایل
                  </Button>
                )}
                <input
                  ref={fileInputRef}
                  type='file'
                  multiple
                  style={{
                    display: 'none',
                  }}
                  onChange={(e) =>
                    uploadQueue.set([
                      ...uploadQueue.state,
                      ...filterInvalidFiles(
                        Array.from(e?.target?.files || [])
                      ).map((file) => ({
                        id: generateObjectId(),
                        data: file,
                      })),
                    ])
                  }
                />
              </RowStack>
            </PageHeroBox>
          </Collapse>
        </ColumnStack>
        <ColumnStack spacing='1rem'>
          <FilesTable
            disableDragAndDrop={disableDragAndDrop}
            titleClickBehavior='link'
            selectedFiles={selectedFiles.state}
            onSelectFile={handleSelectFile}
            onToggleAll={handleToggleAll}
            uploadQueue={uploadQueue}
            files={files}
            onFileUploaded={files.unshift}
          />
          <RowStack alignItems='center'>
            <PageSize
              disabled={loading || (filesDocs.data.list || []).length === 0}
              page={filesDocs.data.page}
              onSizeChange={onPageSizeChange}
              onPageChange={onPageNumChange}
              sx={{ height: '2rem' }}
            />
            <Pagination
              rtl
              disabled={loading}
              page={filesDocs.data.page}
              onPageChange={onPageNumChange}
              paginationItemSx={{
                backgroundColor: 'background.paper',
                border: 'none',
              }}
            />
          </RowStack>
        </ColumnStack>
        {showDeleteDialog.state && (
          <DeleteDialog
            title={`آیا از حذف ${selectedFiles.state.length} فایل انتخاب شده مطمئن هستید؟`.toPersian()}
            open={showDeleteDialog.state}
            onDelete={() =>
              handleDeleteFiles(selectedFiles.state.map((file) => file.id))
            }
            onClose={showDeleteDialog.setFalse}
          />
        )}
      </FormProvider>
    </DocsProvider>
  );
};

export default Files;

import { type FC } from 'react';
import { useFormContext } from 'react-hook-form';

// Share Components
import ActionsBox from 'core/components/shared/Box/Actions';
import RouteLink from 'core/components/shared/Link/RouteLink';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import Breadcrumbs from 'core/components/base/navigation/Breadcrumbs';

// Feature Components
import CancelButton from 'features/appBuilder/patterns/core/components/shared/button/Cancel';
import SubmitButton from 'features/appBuilder/patterns/core/components/shared/button/Submit';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { setAppAlert, setAppLoading } from 'core/utilities/helper';

// Shared Utilities
import { getPatternName } from 'features/appBuilder/patterns/core/utilities/name';
import { validatePattern } from 'features/appBuilder/patterns/core/utilities/validation';
import {
  useAddPatternMutation,
  useUpdatePatternMutation,
} from 'features/appBuilder/patterns/hooks';

// Custom Types
import type { PatternType } from 'features/appBuilder/patterns/core/types/item';
import { bakeGroupPatternData } from 'features/appBuilder/patterns/features/group/utilities/api/baker';

export interface PatternActionBoxProps {
  patternType?: PatternType;
  patternId?: string;
  editMode?: boolean;
}

const PatternActionBox: FC<PatternActionBoxProps> = (props) => {
  // Props
  const { patternType, patternId, editMode } = props;

  // Hooks
  const loading = useSelectLoading();
  const { handleSubmit } = useFormContext();
  const { mutate: handleUpdatePattern } = useUpdatePatternMutation({
    autoAlert: { mode: 'update', name: 'پترن' },
  });
  const { mutate: handleAddPattern } = useAddPatternMutation({
    autoAlert: { mode: 'create', name: 'پترن' },
    stayOnLoadingAfterMutate: true,
    redirectAfterSuccessTo: `/pattern/${patternType}`,
  });

  // Helpers
  const modeTitle = editMode ? 'ویرایش' : 'ایجاد';

  // Utilities
  const handleFormSubmit = async (data: any) => {
    setAppLoading(true);

    // Validation
    const error = validatePattern(data);

    if (error) {
      setAppAlert(error, 'warning');
      setAppLoading(false);
      return;
    }

    if (editMode && patternId) {
      handleUpdatePattern({ id: patternId, data });
    }

    if (!editMode && patternType) {
      handleAddPattern(data);
    }
  };

  return (
    <ColumnStack spacing={0}>
      <ActionsBox>
        <PageTitle>{modeTitle} پترن</PageTitle>
        <RowStack spacing={1}>
          <CancelButton disabled={loading} />
          <SubmitButton
            loading={loading}
            onClick={handleSubmit(handleFormSubmit)}
          />
        </RowStack>
      </ActionsBox>
      <Breadcrumbs aria-label='breadcrumb' separator='>'>
        <RouteLink
          href='/pattern'
          sx={{ color: 'inherit', fontSize: '0.875rem' }}
          underline='hover'
        >
          پترن‌ها
        </RouteLink>
        <RouteLink
          href={`/pattern/${patternType}`}
          sx={{ color: 'inherit', fontSize: '0.875rem' }}
          underline='hover'
        >
          {getPatternName(patternType)}
        </RouteLink>
        <BodyTwo>{modeTitle}</BodyTwo>
      </Breadcrumbs>
    </ColumnStack>
  );
};

export default PatternActionBox;

import {
  LoadingButton as MuiLoadingButton,
  LoadingButtonProps as MuiLoadingButtonProps,
} from '@mui/lab';
import { styled } from '@mui/material';

// Icon Component
import SaveIcon from 'core/components/icons/Save';

export interface LoadingButtonProps extends MuiLoadingButtonProps {}

const LoadingButton = styled(MuiLoadingButton)<LoadingButtonProps>(() => ({
  boxShadow: 'none',
  ':hover': {
    boxShadow: 'none',
  },
}));

LoadingButton.defaultProps = {
  variant: 'contained',
  color: 'info',
  loadingPosition: 'end',
  endIcon: <SaveIcon sx={{ color: 'transparent', position: 'absolute' }} />,
};

export default LoadingButton;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface DeleteIconProps extends SvgIconProps {}

const DeleteIcon: React.FC<DeleteIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z' />
  </IconBuilder>
);

export default DeleteIcon;

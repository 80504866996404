import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface FeedOutlineIconProps extends SvgIconProps {}

const FeedOutlineIcon: React.FC<FeedOutlineIconProps> = (props) => {
  const { color, ...otherProps } = props;

  const styles: React.CSSProperties = {
    fill: color ? color : 'inherit',
  };

  return (
    <IconBuilder color={color && color} {...otherProps}>
      <path
        style={styles}
        d='M18,23H6c-1.7,0-3-1.3-3-3V4c0-1.7,1.3-3,3-3h8c3.9,0,7,3.1,7,7v12C21,21.7,19.7,23,18,23z M6,3C5.4,3,5,3.4,5,4v16
	c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1V8c0-2.8-2.2-5-5-5H6z'
      />
      <path
        style={styles}
        d='M20,9h-6c-0.6,0-1-0.4-1-1V2c0-0.6,0.4-1,1-1s1,0.4,1,1v5h5c0.6,0,1,0.4,1,1S20.6,9,20,9z'
      />
      <rect style={styles} x='7' y='12' width='10' height='2' />
      <rect style={styles} x='7' y='16' width='10' height='2' />
      <rect style={styles} x='7' y='8' width='4' height='2' />
    </IconBuilder>
  );
};

export default FeedOutlineIcon;

import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Switch, SwitchProps } from '@mui/material';

const AntSwitchCore = styled(Switch)(({ theme, disabled }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: disabled ? theme.palette.background.paper : '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: disabled
          ? alpha(theme.palette.text.disabled, 0.2)
          : theme.palette.mode === 'dark'
          ? '#177ddc'
          : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: disabled ? 'none' : '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

interface AntSwitchProps extends SwitchProps {}

const AntSwitch: React.FC<AntSwitchProps> = React.forwardRef((props, ref) => {
  return <AntSwitchCore {...props} />;
});

export default AntSwitch;

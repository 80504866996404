// Utilities
import apiHandler from 'core/utilities/apiHandler';
import getEndpoint from 'core/utilities/helper/getEndpoint';
import { generateObjectId } from 'core/utilities/helper/id';
import { getDoc } from 'core/utilities/apiHandler/helper';
import { initialFile } from 'features/file/files/utilities/file';

// Types
import type { FileProps } from 'features/file/files/types';
import type { ApiGetDocResponse } from 'core/types/api/hook/response';
import type { ApiSettingsProps } from 'features/manager/settings/types/api';
import type {
  SettingsDataToSubmit,
  SettingsProps,
} from 'features/manager/settings/types';

/**
 * Retrieves settings data with optional cancellation support.
 *
 * @returns {Promise<ApiGetDocResponse<SettingsProps>>} A promise that resolves to an object
 * containing the status of the request and, if successful, the settings properties.
 */
export const getSettings = async (
  _?: any,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<SettingsProps>> => {
  const { getSettingsURL: endpoint } = getEndpoint();

  return await getDoc<ApiSettingsProps, SettingsProps>(endpoint, {
    signal,
    returnMutationFn: (status, data) => ({
      status,
      doc: data
        ? {
            id: generateObjectId(),
            data: {
              relatedMediaId: data?.releatedMediaId || '',
              enableDeleteFeedback: data?.enableDeleteFeedback,
              enableDeleteForm: data?.enableDeleteForm,
              description: data?.description,
              email: {
                address: data?.email.mail,
                name: data?.email.name,
                smtp: data?.email.smtp,
                username: data?.email.username,
              },
              fontFamily: data?.fontFamily || 'Vazir',
              favicon:
                data?.favicon && Object.keys(data?.favicon).length > 0
                  ? (data?.favicon as FileProps)
                  : initialFile,
              logo:
                data?.logo && Object.keys(data?.logo).length
                  ? (data?.logo as FileProps)
                  : initialFile,
              themes: data?.themes,
            },
          }
        : undefined,
    }),
  });
};

/**
 * Submits updated settings data with optional cancellation support.
 *
 * @param {SettingsDataToSubmit} values - The updated settings data to be submitted.
 * @returns {Promise<{status:number}>} A promise that resolves when the settings have been successfully updated.
 */
export const editSettings = async (
  values: SettingsDataToSubmit
): Promise<{ status: number }> => {
  const { editSettingsURL: endpoint } = getEndpoint();

  const { email, ...otherFields } = values;

  const { status } = await apiHandler.post(endpoint, {
    ...email,
    ...otherFields,
  });

  return { status };
};

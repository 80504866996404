import {
  ButtonGroup as MuiButtonGroup,
  ButtonGroupProps as MuiButtonGroupProps,
  styled,
} from '@mui/material';

export interface ButtonGroupProps extends MuiButtonGroupProps {}

const ButtonGroup = styled(MuiButtonGroup)<ButtonGroupProps>(() => ({}));

export default ButtonGroup;

import * as React from 'react';
import { rgbToHex } from '@mui/material';

// Types
import type { ColorResult } from 'react-color';
import type { FunctionComponent, MouseEvent } from 'react';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Menu from 'core/components/base/navigation/Menu';

// Custom Types
import type { BoxProps } from 'core/components/base/layout/Box';
import { isHexTransparent } from 'core/utilities/helper';
interface ColorPickerProps extends BoxProps {
  color?: string;
  disabled?: boolean;
  onColorChange?: (color: string) => void;
  onOpen?: () => void;
  onClose?: () => void;
}

// Lazy Imports
const SketchPicker = React.lazy(() =>
  import('react-color').then((m) => ({ default: m.SketchPicker }))
);

const ColorPicker: React.FC<ColorPickerProps> = React.forwardRef(
  (props, ref) => {
    // Props
    const {
      color,
      disabled,
      onColorChange,
      onOpen,
      onClose,
      sx,
      ...otherProps
    } = props;

    // Hooks
    const [tileColor, setTileColor] = React.useState<string>(
      'rgba(255,255,255, 0)'
    );
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    React.useEffect(() => {
      if (color && tileColor !== color) {
        setTileColor(color);
      }
    }, [color]);

    React.useEffect(() => {
      if (open && onOpen) onOpen();
      else if (!open && onClose) onClose();
    }, [open]);

    const initilizeColor = React.useCallback(() => {
      if (color && color !== tileColor) {
        setTileColor(color);
      }
    }, []);

    React.useEffect(() => {
      initilizeColor();
    }, [initilizeColor]);

    // Utilities
    const handleBoxClick = (event: MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClosePicker = () => {
      setAnchorEl(null);
    };

    const handleColorChange = (color: ColorResult) => {
      const { r, g, b, a = 1 } = color.rgb;
      const updatedColor = `rgba(${r}, ${g}, ${b}, ${a})`;
      setTileColor(updatedColor);
      if (onColorChange) {
        onColorChange(updatedColor);
      }
    };

    return (
      <Box ref={ref} sx={{ position: 'relative', ...sx }}>
        <Box
          onClick={handleBoxClick}
          sx={{
            backgroundColor: tileColor,
            borderRadius: '0.5rem',
            width: '2rem',
            height: '2rem',
            border: '1px solid #807D7B',
            cursor: disabled ? undefined : 'pointer',
            position: 'relative',
            bottom: '4px',
          }}
          {...otherProps}
        >
          {isHexTransparent(rgbToHex(tileColor)) && (
            <div
              style={{
                position: 'absolute',
                width: '1px',
                height: '100%',
                backgroundColor: '#000',
                left: '50%',
                transform: 'translateX(-50%) rotate(45deg)',
              }}
            />
          )}
        </Box>
        <React.Suspense fallback={<></>}>
          <Menu
            anchorEl={anchorEl}
            open={!disabled && open}
            anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
            onClose={handleClosePicker}
            MenuListProps={{ sx: { margin: 0, padding: 0 } }}
          >
            <SketchPicker color={color} onChange={handleColorChange} />
          </Menu>
        </React.Suspense>
      </Box>
    );
  }
);
export default ColorPicker;

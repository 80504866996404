import * as React from 'react';
import { useLocation } from 'react-router-dom';

// Custom Hooks
import useUpdateEffect from 'core/hooks/useUpdateEffect';

// Context
import { setAppLoading } from 'core/utilities/helper';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
interface RouteChangeListenerProps {
  children?: React.ReactNode;
}

const RouteChangeListener: React.FC<RouteChangeListenerProps> = (props) => {
  // Props
  const { children } = props;

  // Context
  const loading = useSelectLoading();

  // Hooks
  const location = useLocation();

  // Utilities
  useUpdateEffect(() => {
    if (loading) setAppLoading(false);
  }, [location.pathname]);

  // Render
  return children;
};

export default RouteChangeListener;

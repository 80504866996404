// Core Utilities
import { generateObjectId } from 'core/utilities/helper/id';

// Feature Types
import type { ManagerQuestionProps } from '../../types/details/managerQuestion';

export const PROCESS_MANAGER_QUESTION_DRAFT_FLAG = '-manager-question-draft';

/**
 * Generates the initial manager question value with default properties.
 *
 * @returns {ManagerQuestionProps} The initial manager question object with default values.
 */
export const genInitialManagerQuestionValue = (): ManagerQuestionProps => ({
  id: `${generateObjectId()}${PROCESS_MANAGER_QUESTION_DRAFT_FLAG}`,
  data: {
    processItemType: 'managerQuestion',
    overalStatus: 'pending',
    title: 'پرسش جدید',
    questionTitle: 'متن پرسش جدید',
    completionDate: '',
    applicantAnswer: '',
    linkedId: '',
    linkedTitle: 'پایان',
    status: 'end',
    applicantAnswerFiles: [],
  },
});

import { useCallback, useEffect, useState } from 'react';

// Core Components
import DataGrid from 'core/components/shared/DataGrid';
import RouteLink from 'core/components/shared/Link/RouteLink';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import Box from 'core/components/base/layout/Box';

// Core Utilities
import { getLocaleDate } from 'core/utilities/helper/date';

// Core Types
import type { DataGridProps } from 'core/components/shared/DataGrid';
import type {
  DataGridColumn,
  DataGridRowValueProps,
} from 'core/components/shared/DataGrid/dataGrid';

// Feature Types
import type { ContentListItemProps } from 'features/content/contents/types/list';

// Custom Types
export interface ContentsDataGridProps
  extends Omit<DataGridProps, 'columns' | 'rows'> {
  contents: ContentListItemProps[];
}

const columns: DataGridColumn[] = [
  {
    field: 'col1',
    headerTitle: 'عنوان',
    width: 150,
    renderCell: (params) => (
      <RouteLink href={`/contents/${params.row.id}`} underline='hover'>
        {params.row.col1}
      </RouteLink>
    ),
  },
  { field: 'col2', headerTitle: 'نویسنده', width: 150 },
  { field: 'col3', headerTitle: 'تعداد بیننده', width: 150 },
  {
    field: 'col4',
    headerTitle: 'موضوع',
    width: 125,
    renderCell: (params) => {
      const categories = params.row.col4 as string[];
      const title =
        categories.length > 1
          ? `${categories[0]} +${(categories.length - 1).toPersian()}`
          : categories.length === 1
          ? categories[0]
          : '-';

      return (
        <Box
          sx={{
            position: 'relative',
            ':hover > .tooltip':
              categories.length > 1 ? { display: 'inline-flex' } : undefined,
            ':hover > .title':
              categories.length > 1 ? { display: 'none' } : undefined,
          }}
        >
          <BodyTwo className='title'>{title}</BodyTwo>
          <BodyTwo className='tooltip' sx={{ display: 'none' }}>
            {categories.join('، ')}
          </BodyTwo>
        </Box>
      );
    },
  },
  {
    field: 'col5',
    headerTitle: 'برچسب',
    width: 125,
    renderCell: (params) => {
      const tags = params.row.col5 as string[];
      const title =
        tags.length > 1
          ? `${tags[0]} +${(tags.length - 1).toPersian()}`
          : tags.length === 1
          ? tags[0]
          : '-';

      return (
        <Box
          sx={{
            position: 'relative',
            ':hover > .tooltip':
              tags.length > 1 ? { display: 'inline-flex' } : undefined,
            ':hover > .title':
              tags.length > 1 ? { display: 'none' } : undefined,
          }}
        >
          <BodyTwo className='title'>{title}</BodyTwo>
          <BodyTwo className='tooltip' sx={{ display: 'none' }}>
            {tags.join('، ')}
          </BodyTwo>
        </Box>
      );
    },
  },
  { field: 'col6', headerTitle: 'وضعیت', width: 100 },
  { field: 'col7', headerTitle: 'تاریخ و ساعت', width: 100 },
];

const ContentsDataGrid: React.FC<ContentsDataGridProps> = (props) => {
  // Props
  const { contents, ...otherProps } = props;

  // States
  const [rows, setRows] = useState<DataGridRowValueProps[]>([]);

  // Hooks
  const generateRows = useCallback(() => {
    const rowsToSet: DataGridRowValueProps[] = [];

    contents.forEach(({ id, data }) => {
      const row = {
        id,
        col1: data.title,
        col2: data.creatorFullname || '',
        col3: (data.viewsCount || '0').toPersian(),
        col4: data.categories || [],
        col5: data.tags || [],
        col6: data.isDraft ? 'پیش‌نویش' : 'فعال',
        col7: data.publishDate
          ? getLocaleDate(data.publishDate)
          : data.createdAt
          ? getLocaleDate(data.createdAt)
          : '-',
      };
      rowsToSet.push(row);
    });

    setRows(rowsToSet);
  }, [contents]);

  useEffect(() => {
    generateRows();
  }, [contents, generateRows]);

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      headProps={{
        sx: {
          bgcolor: '#E1E7EA',
        },
      }}
      {...otherProps}
    />
  );
};

export default ContentsDataGrid;

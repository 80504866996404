import apiHandler, { AppReqInitType } from 'core/utilities/apiHandler';

import {
  getPaginate,
  initialPageProps,
} from 'core/utilities/pagination/pagination';
import { getUrlWithQueryString } from 'core/utilities/helper/helperPack';
import type {
  ApiGetDocResponse,
  ApiGetDocsResponse,
  ApiGetSummaryResponse,
} from 'core/types/api/hook/response';

interface CommonProps {
  /**
   * Optional query parameters to be included in the request.
   */
  queries?: Record<string, any>;
  /**
   * Optional AbortSignal to cancel the request.
   */
  signal?: AbortSignal;
}

/**
 * Configuration properties for the `getDocs` function.
 *
 * @template TResponse - The type of the response data.
 * @template TList - The type of the list of documents.
 * @interface GetDocsConfigProps
 */
interface GetDocsConfigProps<TResponse = unknown, TList = unknown>
  extends CommonProps {
  /**
   * Optional function to handle the response after fetching.
   */
  returnMutationFn?: (
    status: number,
    data: TResponse
  ) => ApiGetDocsResponse<TList>;
}

/**
 * Configuration properties for the `getDocs` function.
 *
 * @template TResponse - The type of the response data.
 * @template TList - The type of the list of documents.
 * @interface GetDocsConfigProps
 */
interface GetDocConfigProps<TResponse = unknown, TData = unknown>
  extends CommonProps {
  /**
   * Optional function to handle the response after fetching.
   */
  returnMutationFn?: (
    status: number,
    data: TResponse
  ) => ApiGetDocResponse<TData>;
}

interface GetSummaryConfigProps<TResponse = unknown, TData = unknown>
  extends CommonProps {
  /**
   * Optional function to handle the response after fetching.
   */
  returnMutationFn?: (
    status: number,
    data: TResponse
  ) => ApiGetSummaryResponse<TData>;
}

/**
 * Fetches documents from a specified endpoint with optional configurations.
 *
 * @template TResponse - The type of the response data.
 * @template TList - The type of the list of documents.
 * @param {string} endpoint - The API endpoint to fetch data from.
 * @param {GetDocsConfigProps<TResponse, TList> & Partial<AppReqInitType>} [configs] - Optional configurations for the request.
 * @returns {Promise<ApiGetDocsResponse<TList>>} A promise that resolves to the API response containing the list of documents and pagination information.
 */
export async function getDocs<TResponse = unknown, TList = unknown>(
  endpoint: string,
  configs?: GetDocsConfigProps<TResponse, TList> & Partial<AppReqInitType>
): Promise<ApiGetDocsResponse<TList>> {
  const { signal, queries, returnMutationFn, ...otherConfigs } = configs || {};
  const { status, data } = await apiHandler.get<{
    docs?: TResponse;
  }>(getUrlWithQueryString(endpoint, queries || {}), {
    signal,
    ...otherConfigs,
  });

  if (returnMutationFn) {
    const result = returnMutationFn(status, data?.docs as TResponse);
    return {
      status: result.status,
      list: result?.list || [],
      page: result?.page || initialPageProps,
    };
  }

  return {
    status,
    list: (data?.docs as any)?.data || ([] as TList[]),
    page: getPaginate((data?.docs as any)?.paginate),
  };
}

export async function getDoc<TResponse = unknown, TData = unknown>(
  endpoint: string,
  configs?: GetDocConfigProps<TResponse, TData> & Partial<AppReqInitType>
): Promise<ApiGetDocResponse<TData>> {
  const { signal, queries, returnMutationFn, ...otherConfigs } = configs || {};
  const { status, data } = await apiHandler.get<{
    docs?: TResponse;
  }>(getUrlWithQueryString(endpoint, queries || {}), {
    signal,
    ...otherConfigs,
  });

  if (returnMutationFn) {
    const result = returnMutationFn(status, data?.docs as TResponse);
    return {
      status: result.status,
      doc: result?.doc || undefined,
    };
  }

  return {
    status,
    doc: data?.docs as TData,
  };
}

export async function getSummary<TResponse = unknown, TData = unknown>(
  endpoint: string,
  configs?: GetSummaryConfigProps<TResponse, TData> & Partial<AppReqInitType>
): Promise<ApiGetSummaryResponse<TData>> {
  const { signal, queries, returnMutationFn, ...otherConfigs } = configs || {};
  const { status, data } = await apiHandler.get<{
    docs?: TResponse;
  }>(getUrlWithQueryString(endpoint, { limit: 999, ...(queries || {}) }), {
    signal,
    ...otherConfigs,
  });

  if (returnMutationFn) {
    const result = returnMutationFn(status, data?.docs as TResponse);
    return {
      status: result.status,
      list: result?.list || [],
    };
  }

  return {
    status,
    list: (data?.docs || []) as TData[],
  };
}

// Custom Page Components
import AddPattern from 'features/appBuilder/patterns/core/components/AddPattern';
import EditPattern from 'features/appBuilder/patterns/core/components/EditPattern';
import PatternList from 'features/appBuilder/patterns/core/components/PatternList';

import Patterns from 'features/appBuilder/patterns/components/list/Patterns';
import TablePreview from 'features/appBuilder/patterns/features/table/components/Preview';

// Custom Utilities
import { getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'template';
const subFeature: SubFeatureType = 'patterns';
const patternsRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/pattern',
    Component: Patterns,
    feature,
    subFeature,
  },
  {
    path: '/pattern/:patternType',
    Component: PatternList,
    feature,
    subFeature,
  },
  {
    path: '/pattern/:patternType/add',
    Component: AddPattern,
    feature,
    subFeature,
  },
  {
    path: '/pattern/:patternType/:id',
    Component: EditPattern,
    feature,
    subFeature,
  },
  {
    path: '/pattern/table/preview/:id',
    Component: TablePreview,
    feature,
    subFeature,
  },
];

export default patternsRoutes;

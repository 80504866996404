import { memo, type FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

// Cusotm Core Components
import Box, { type BoxProps } from 'core/components/base/layout/Box';

// Custom Common Components
import BodyOne from 'core/components/shared/Typography/BodyOne';

// Custom Icon Components
import PlusCircleIcon from 'core/components/icons/PlusCircle';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { useAddFormStepMutation } from 'features/form/forms/hooks';
import {
  setFormCurrentStep,
  updateFormSteps,
} from 'features/form/forms/store/actions/step';

// Custom Types
import type { FormProps } from 'features/form/forms/types/item';
export interface AddStepButtonProps extends BoxProps {
  disabled?: boolean;
}

const AddStepButton: FC<AddStepButtonProps> = (props) => {
  // Props
  const {
    color = 'text.secondary',
    disabled,
    sx,
    onClick,
    ...otherProps
  } = props;

  // Hooks
  const { getValues, setValue } = useFormContext<FormProps>();
  const formId = useAppSelector((state) => state.form.formId);
  const { mutate: handleAddFormStep } = useAddFormStepMutation({
    noRevalidate: true,
    autoAlert: { mode: 'create', name: 'مرحله جدید' },
    onSuccess: ({ docs }) => {
      if (!docs) return;
      const steps = getValues('data.steps');
      setFormCurrentStep({
        id: docs.id,
        index: docs.data.sortIndex,
        sections: [],
        inputs: [],
      });
      steps.push(docs);

      setValue('data.steps', steps);
      setValue('data.sections', []);
      setValue('data.inputs', []);
      setValue('data.inputsIndexMap', {});
      updateFormSteps(steps);
    },
  });
  // Utilities
  const handleAddStep = useCallback(async () => {
    if (!formId || disabled) return;

    const steps = getValues('data.steps');
    handleAddFormStep({ formId, index: steps.length });
  }, [formId, disabled]);

  // Render
  return (
    <Box
      color={color}
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: disabled ? 'text.disabled' : 'text.secondary',
        whiteSpace: 'nowrap',
        gap: 1,
        ':hover': {
          color: disabled ? undefined : 'text.secondary',
        },
        ...sx,
      }}
      onClick={handleAddStep}
      {...otherProps}
    >
      <PlusCircleIcon
        sx={{ cursor: disabled ? 'auto' : 'pointer' }}
        color='inherit'
      />
      <BodyOne
        sx={{ cursor: disabled ? 'auto' : 'pointer' }}
        fontWeight='medium'
      >
        افزودن مرحله
      </BodyOne>
    </Box>
  );
};

export default memo(AddStepButton, isSame);

import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Checkbox from 'core/components/base/inputs/Checkbox';
import Box from 'core/components/base/layout/Box';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import TextField from 'core/components/base/inputs/TextField';

// Feature Types
import type { ContentDetailsProps } from 'features/content/contents/types/details';

// Component Types
interface ContentDetailsConfigAbstractProps {}

const ContentDetailsConfigAbstract: FC<
  ContentDetailsConfigAbstractProps
> = () => {
  // Hooks
  const { control, setValue } = useFormContext<ContentDetailsProps>();

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: 0, width: '35%' }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <BodyTwo color='text.secondary'>چکیده</BodyTwo>
        <Controller
          control={control}
          name='data.showAbstractInContent'
          render={({ field }) => (
            <Box sx={{ display: 'flex', gap: 0, alignItems: 'center' }}>
              <Checkbox
                size='small'
                checked={field.value}
                onChange={(_, checked) => setValue(field.name, checked)}
              />
              <BodyTwo color={field.value ? 'text.primary' : 'text.secondary'}>
                نمایش چکیده در مطلب
              </BodyTwo>
            </Box>
          )}
        />
      </Box>
      <Controller
        control={control}
        name='data.abstract'
        render={({ field }) => (
          <TextField
            multiline
            rows={5}
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: '#F8F8F8',
              },
            }}
            {...field}
          />
        )}
      />
    </Box>
  );
};

export default ContentDetailsConfigAbstract;

import {
  Stack as MuiStack,
  StackProps as MuiStackProps,
  styled,
} from '@mui/material';

export interface StackProps extends MuiStackProps {}

const Stack = styled(MuiStack)<StackProps>(() => ({}));

Stack.defaultProps = {
  direction: 'row',
  spacing: 2,
};

export default Stack;

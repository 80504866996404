import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface KeyboardArrowDownIconProps extends SvgIconProps {}

const KeyboardArrowDownIcon: React.FC<KeyboardArrowDownIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6z' />
  </IconBuilder>
);

export default KeyboardArrowDownIcon;

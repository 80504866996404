// Custom Page Components
import Banners from 'features/banner/banners/components/details';

// Custom Utilities
import { getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'banner';
const subFeature: SubFeatureType = 'banners';
const bannerRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/banners',
    Component: Banners,
    feature,
    subFeature,
  },
];

export default bannerRoutes;

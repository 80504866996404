import { useAppSelector } from 'core/hooks/redux';

export const useSelectProcessItemIsFocused = (id: string): boolean =>
  useAppSelector(({ process }) => process.currentFocus === id);

export const useSelectProcessItemIsEditable = (id: string): boolean =>
  useAppSelector(
    ({ process }) => process.currentFocus === '' || process.currentFocus === id
  );

export const useSelectProcessFocusIsEmpty = (): boolean =>
  useAppSelector(({ process }) => process.currentFocus === '');

import { type FC } from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Core Components
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface EditPenIconProps extends SvgIconProps {}

const EditPenIcon: FC<EditPenIconProps> = (props) => {
  return (
    <IconBuilder viewBox='0 0 18 18' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 18C13.9702 18 18 13.9717 18 9C18 4.03124 13.9702 0 9 0C4.02979 0 0 4.03124 0 9C0 13.9717 4.02979 18 9 18ZM12.1789 8.32141L9.67858 5.82098L4.25099 11.2484L4.00295 13.4793C3.96974 13.7777 4.22189 14.0301 4.52032 13.9971L6.74955 13.751L12.1789 8.32141ZM12.5514 4.27466L13.7253 5.44871C14.0915 5.81492 14.0915 6.40868 13.7253 6.77509L12.6209 7.87961L10.1206 5.37917L11.225 4.27466C11.5912 3.90845 12.1852 3.90845 12.5514 4.27466Z'
      />
    </IconBuilder>
  );
};
export default EditPenIcon;

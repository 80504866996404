import * as React from 'react';
import { alpha, useTheme } from '@mui/material';

// Custom Core Components
import Table from 'core/components/base/display/Table';
import TableBody from 'core/components/base/display/TableBody';
import TableCell from 'core/components/base/display/TableCell';
import TableContainer from 'core/components/base/display/TableContainer';
import TableHead from 'core/components/base/display/TableHead';
import TableRow from 'core/components/base/display/TableRow';
import Checkbox from 'core/components/base/inputs/Checkbox';
import Switch from 'core/components/base/inputs/Switch';

// Custom Common Components
import TableContainerPaper from 'core/components/shared/Paper/TableContainerPaper';
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import RouteLink from 'core/components/shared/Link/RouteLink';

// Hooks
import { useAppSelector } from 'core/hooks/redux';

// Context
import { useSelectionContext } from 'core/store/context/common/UseSelection';

// Custom Utilities
import { getLocaleDate } from 'core/utilities/helper/date';
import { getLocaleDateAndTime } from 'core/utilities/helper/date';

// Custom Types
import type { ManagerListItemProps } from 'features/manager/managers/types';

interface ManagersDataGridProps {
  managers: ManagerListItemProps[];
  disabled?: boolean;
  onStatusChange?: (managerId: string, status: boolean) => void;
}

const ManagersDataGrid: React.FC<ManagersDataGridProps> = (props) => {
  // Props
  const { managers = [], disabled, onStatusChange } = props;

  // Context
  const { selections, onSelectionsChange, onToggleAll } = useSelectionContext();

  // Hooks
  const theme = useTheme();
  const state = useAppSelector((state) => state.manager);

  // Render
  return (
    <TableContainer component={TableContainerPaper}>
      <Table>
        <TableHead>
          <TableRow sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
            <TableCell padding='checkbox'>
              <Checkbox
                checked={
                  managers.length > 0 && selections.length === managers.length
                }
                color='info'
                disabled={disabled || managers.length === 0}
                indeterminate={
                  managers.length > 0 &&
                  selections.length > 0 &&
                  selections.length < managers.length
                }
                onChange={() => onToggleAll(managers)}
              />
            </TableCell>
            <TableCell sx={{ width: '10rem' }}>نام و نام‌خانوادگی</TableCell>
            <TableCell sx={{ width: '7rem' }}>تاریخ عضویت</TableCell>
            <TableCell>گروه‌ها</TableCell>
            <TableCell sx={{ width: '7rem' }}>وضعیت</TableCell>
            <TableCell sx={{ width: '10rem' }}>آخرین ورود</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {disabled && managers.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4}>در حال دریافت اطلاعات مدیران.</TableCell>
            </TableRow>
          ) : managers.length > 0 ? (
            managers.map((manager) => (
              <TableRow key={manager.id}>
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={selections.includes(manager.id)}
                    color='info'
                    disabled={disabled || managers.length === 0}
                    onChange={() => onSelectionsChange(manager.id)}
                  />
                </TableCell>
                <TableCell sx={{ width: '10rem' }}>
                  <RouteLink
                    disabled={disabled}
                    to={`/managers/${manager.id}`}
                    sx={{
                      borderBottom: '1px dashed',
                      borderColor: 'text.secondary',
                      ':hover': {
                        borderBottom: '1px solid',
                      },
                    }}
                  >
                    {manager.data.fullName}
                  </RouteLink>
                </TableCell>
                <TableCell sx={{ width: '7rem' }}>
                  {getLocaleDate(manager.data.createdAt)}
                </TableCell>
                <TableCell>
                  <RowStack spacing={0.5}>
                    <BodyTwo
                      sx={{
                        background: alpha(
                          theme.palette.background.default,
                          0.3
                        ),
                        padding: '0.25rem 0.5rem',
                        borderRadius: '2px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {manager.data.groupTitle}
                    </BodyTwo>
                  </RowStack>
                </TableCell>
                <TableCell sx={{ width: '7rem' }}>
                  <Switch
                    disabled={disabled || state.id === manager.id}
                    checked={manager.data.isActive}
                    onChange={(e, checked) =>
                      onStatusChange && onStatusChange(manager.id, checked)
                    }
                  />
                </TableCell>
                <TableCell dir='ltr' sx={{ width: '10rem' }}>
                  {manager.data.lastLogin
                    ? getLocaleDateAndTime(
                        manager.data.lastLogin.data.timestamp,
                        '-'
                      )
                    : '-'}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>مدیری جهت نمایش وجود ندارد.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ManagersDataGrid;

import * as React from 'react';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';

// Custom Icons
import SaveIcon from 'core/components/icons/Save';
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';

// Custom Types
interface SubmitButtonProps {}

const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
  // Context
  const loading = useSelectLoading();

  // Render
  return (
    <Button
      type='submit'
      disabled={loading}
      startIcon={<SaveIcon />}
      variant='outlined'
      color='info'
      sx={{
        borderRadius: '.5rem',
      }}
    >
      ذخیره
    </Button>
  );
};

export default SubmitButton;

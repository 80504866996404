import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Feature Components
import ProcessModeButton from './ProcessModeButton';

// Custom Utilities
import { toggleProcessItemSectionsVisibility } from 'features/form/feedbacks/store/actions/process';
import { isSame } from 'core/utilities/helper/helperPack';
import { useSelectFeedbackProcessItem } from 'features/form/feedbacks/store';
import {
  evaluationFilterOptions,
  managerQuestionFilterOptions,
} from 'features/form/feedbacks/utilities/details/data';

// Context
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { FeedbackProcessEvaluationProps } from 'features/form/feedbacks/types/detail/process/evaluation';
import type { ManagerQuestionProps } from 'features/form/processes/types/details/managerQuestion';

export type ProcessFilterNameType =
  | 'all'
  | 'questionDetails' // ManagerQuestion
  | 'title'
  | 'questionTitle'
  | 'selectedOpt'
  | 'message'
  | 'evaluatorFullname'
  | 'comment'
  | 'date'
  | 'review';

export interface ProcessFilterItemProps {
  label: string;
  name: ProcessFilterNameType;
}

interface ProcessFieldsProps {
  item: FeedbackProcessEvaluationProps | ManagerQuestionProps;
}

const ProcessFields: React.FC<ProcessFieldsProps> = (props) => {
  // Props
  const { item } = props;

  // Context
  const hiddenSections = useSelectFeedbackProcessItem(item.id);
  const loading = useSelectLoading();

  // Hooks
  const { palette } = useTheme();

  // Render
  return (
    <RowStack>
      <Button
        onClick={() => toggleProcessItemSectionsVisibility(item.id, 'all')}
        disabled={loading}
        sx={{
          minWidth: '8.125rem',
          maxWidth: 'fit-content',
          fontSize: '1rem',
          fontWeight: '700',
          color: palette.text.primary,
          height: '2.375rem',
          borderRadius: '.25rem',
          padding: '0.313rem 0.75rem',
          background: hiddenSections?.all ? '#EBEBEB' : '#9ED5FF',
          textDecoration: hiddenSections?.all ? 'line-through' : 'none',
          transition: 'opacity .3s',
          ':hover': {
            textDecoration: hiddenSections?.all ? 'line-through' : 'none',
            background: hiddenSections?.all ? '#EBEBEB' : '#9ED5FF',
            opacity: '.8',
          },
          ':disabled': {
            background: palette.text.disabled,
          },
        }}
      >
        {item?.data?.title}
      </Button>
      {(item.data.processItemType === 'evaluation'
        ? evaluationFilterOptions
        : managerQuestionFilterOptions
      ).map((field) => (
        <ProcessModeButton
          item={field}
          processItemId={item.id}
          key={`${item.id}-${field.name}`}
        />
      ))}
    </RowStack>
  );
};

export default React.memo(ProcessFields, isSame);

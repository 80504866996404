import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface ScoreFilledIconProps extends SvgIconProps {}

const ScoreFilledIcon: React.FC<ScoreFilledIconProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <path d='M18.32,22.27a.76.76,0,0,1-.43-.14L12,17.86,6.1,22.13a.73.73,0,0,1-.86,0A.72.72,0,0,1,5,21.3l2.3-6.81-6-4.09A.67.67,0,0,1,1,10a.8.8,0,0,1,0-.46.72.72,0,0,1,.27-.37.74.74,0,0,1,.43-.14H9.08L11.3,2.24a.74.74,0,0,1,.7-.51.76.76,0,0,1,.43.14.72.72,0,0,1,.27.37l2.22,6.83h7.35a.74.74,0,0,1,.43.14.72.72,0,0,1,.27.37A.8.8,0,0,1,23,10a.74.74,0,0,1-.28.37l-6,4.08L19,21.3a.66.66,0,0,1,0,.34.7.7,0,0,1-.13.32,1,1,0,0,1-.26.23A.77.77,0,0,1,18.32,22.27Z' />
    </IconBuilder>
  );
};

export default ScoreFilledIcon;

// Feature Types
import { ButtonItemProps } from 'features/appBuilder/patterns/core/types/item/bannerButton';
import type { SliderPatternButtonProps } from 'features/appBuilder/patterns/features/slider/types/button';

const buttonDefaults: ButtonItemProps = {
  isActive: true,
  padding: '8px 16px',
  flexAlign: 'center',
  fontSize: '14',
  textDirection: 'rtl',
  variant: 'contained',
  color: '#FFFFFF',
  bgColor: '#12252E',
  hoverColor: '#FFFFFF',
  bgHoverColor: '#12252E',
  borderRadius: 8,
};

export const sliderPatterButtonDefaults: SliderPatternButtonProps = {
  isActive: true,
  buttonOne: buttonDefaults,
  buttonTwo: buttonDefaults,
  arrows: {
    borderRadius: 8,
    isActive: true,
    padding: '0px',
    size: 32,
    color: '#0091FF',
    bgColor: '#FFFFFF',
    hoverColor: '#0091FF',
    bgHoverColor: '#FFFFFF',
  },
  pagination: {
    borderRadius: 8,
    isActive: true,
    padding: '8px 16px',
    fillColor: '#C1C8D3',
    activeColor: '#12252E',
  },
  tabs: {
    borderRadius: 8,
    isActive: true,
    padding: '0px',
    fontSize: '14px',
    textDirection: 'rtl',
    flexAlign: 'start',
    hoverColor: '#FFFFFF',
    bgHoverColor: '#12252E',
    activeTabBgColor: '#12252E',
    activeTabColor: '#FFFFFF',
  },
  archive: {
    borderRadius: 8,
    bgColor: '#12252E',
    color: '#FFFFFF',
    isActive: true,
    padding: '8px 16px',
    textDirection: 'rtl',
    flexAlign: 'end',
    hoverColor: '#FFFFFF',
    bgHoverColor: '#12252E',
    fontSize: '14px',
  },
};

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface FormatTextdirectionRToLIconProps extends SvgIconProps {}

const FormatTextdirectionRToLIcon: React.FC<
  FormatTextdirectionRToLIconProps
> = (props) => (
  <IconBuilder {...props}>
    <path d='M10 10v5h2V4h2v11h2V4h2V2h-8C7.79 2 6 3.79 6 6s1.79 4 4 4m-2 7v-3l-4 4 4 4v-3h12v-2z' />
  </IconBuilder>
);

export default FormatTextdirectionRToLIcon;

// Custom Utilities
import { generateObjectId } from 'core/utilities/helper/id';
import { getInitialCommonInputProps } from 'features/form/forms/utilities/input/initial/common';

// Custom Types
import type { FormInputInitialParams } from 'features/form/forms/utilities/input/initial/common';
import type { FormMobileInputProps } from 'features/form/forms/types/item/input/mobile';

interface Params extends FormInputInitialParams {}

export const genFormMobileInput = (params: Params): FormMobileInputProps => {
  const commonProps = getInitialCommonInputProps({
    ...params,
  });

  return {
    id: `draft-${generateObjectId()}`,
    data: {
      ...commonProps,
      label: 'شماره موبایل',
      type: 'mobile',
    },
  };
};

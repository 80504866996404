import { type FC } from 'react';

// Custom Shared Components
import FormButton, { type FormButtonProps } from '../Core';

// Custom Icon Components
import PlusCircleIcon from 'core/components/icons/PlusCircle';

// Types
export interface AddButtonProps extends FormButtonProps {}

const AddButton: FC<AddButtonProps> = (props) => {
  // Props
  const {
    children = 'افزودن فیلد',
    startIcon = <PlusCircleIcon />,
    color = 'info',
    ...otherProps
  } = props;

  return (
    <FormButton color={color} startIcon={startIcon} {...otherProps}>
      {children}
    </FormButton>
  );
};

export default AddButton;

import * as React from 'react';
import isEqual from 'lodash/isEqual';
import { Controller, useFormContext } from 'react-hook-form';

// Core Componen ts
import Paper from 'core/components/base/surfaces/Paper';
import TextField from 'core/components/base/inputs/TextField';

// Common Components
import InsertButton from 'features/file/files/components/shared/Dialog/InsertButton';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';

// Custom Utilities
import { getErrorAndHelperText } from 'core/utilities/helper/helperPack';

// Context
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { FileScriptProps } from 'features/file/files/components/shared/Dialog';

interface FileExternalSectionProps {
  onInsertScript: () => void;
}

const FileExternalSection: React.FC<FileExternalSectionProps> = (props) => {
  // Props
  const { onInsertScript } = props;

  // Context
  const loading = useSelectLoading();
  const { control } = useFormContext<FileScriptProps>();

  // Render
  return (
    <ColumnStack
      component={Paper}
      spacing='1rem'
      sx={{ borderRadius: '1rem', paddingBottom: '1rem' }}
    >
      <ColumnStack
        sx={{
          width: '100%',
          minHeight: '60vh',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingX: '1rem',
        }}
      >
        <ColumnStack spacing={0} sx={{ width: '25rem' }}>
          <InputLabel indentRight fontWeight='bold'>
            کداسکریپت
          </InputLabel>
          <Controller
            control={control}
            name='url'
            render={({ field, fieldState }) => (
              <TextField
                disabled={loading}
                fullWidth
                dir='ltr'
                placeholder='https://...'
                {...field}
                {...getErrorAndHelperText(fieldState)}
              />
            )}
          />
        </ColumnStack>
        <InsertButton
          onClick={onInsertScript}
          sx={{ alignSelf: 'center' }}
          title='افزودن به مطلب'
        />
      </ColumnStack>
    </ColumnStack>
  );
};

export default React.memo(FileExternalSection, isEqual);

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import { SvgIconProps } from 'core/components/base/display/SvgIcon';
interface EditPenIconProps extends SvgIconProps {}

const EditPenIcon: React.FC<EditPenIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M19,2a.65.65,0,0,1,.48.2L21.8,4.55a.68.68,0,0,1,0,1L21,6.27,19.33,8,16,4.67,17.73,3l.75-.76A.71.71,0,0,1,19,2Z' />
    <polygon points='15.19 5.5 18.5 8.81 7.24 20.07 6.8 19.63 4.37 17.2 3.92 16.76 4.82 15.87 15.19 5.5' />
    <path d='M3.13,17.55l.13.13,3.19,3.19h0l-.6.16-2.66.74L2.45,22c-.33.09-.52-.1-.43-.43l.21-.74h0L3,18.15l.16-.59Z' />
  </IconBuilder>
);

export default EditPenIcon;

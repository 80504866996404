import type { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

// Core Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Feature Components
import ProcessConfigSection from 'features/form/processes/components/details/Config';
import ProcessDetailsActions from 'features/form/processes/components/details/shared/Action';
import ProcessAddItemSection from 'features/form/processes/components/details/AddSection';
import ProcessEvaluationSection from 'features/form/processes/components/details/Evaluation';
import ProcessManagerQuestionSection from 'features/form/processes/components/details/ManagerQuestion';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { ProcessProps } from 'features/form/processes/types/details';

export interface ProcessDetailsPageProps {
  mode?: 'edit' | 'add';
}

const ProcessDetails: FC<ProcessDetailsPageProps> = (props) => {
  // Props
  const { mode = 'add' } = props;

  // Context
  const loading = useSelectLoading();
  const { control } = useFormContext<ProcessProps>();

  // Hooks
  const items = useFieldArray({
    control,
    name: 'data.items',
    keyName: 'keyId',
  });

  // Render
  return (
    <>
      <ProcessDetailsActions disabled={loading} mode={mode} />
      <ColumnStack>
        <ProcessConfigSection mode={mode} />
        {items.fields.length > 0 && (
          <ColumnStack>
            {items.fields.map(({ keyId, ...item }, index) =>
              item.data.processItemType === 'managerQuestion' ? (
                <ProcessManagerQuestionSection
                  index={index}
                  key={keyId}
                  onDelete={items.remove}
                />
              ) : (
                <ProcessEvaluationSection
                  index={index}
                  key={keyId}
                  onDelete={items.remove}
                />
              )
            )}
          </ColumnStack>
        )}
        <ProcessAddItemSection
          onAdd={(item) => items.append(item)}
          disabled={mode === 'add' || loading}
        />
      </ColumnStack>
    </>
  );
};

export default ProcessDetails;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface PhoneFilledIconProps extends SvgIconProps {}

const PhoneFilledIcon: React.FC<PhoneFilledIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M18.63,23a9.29,9.29,0,0,1-4.32-1.47,28.19,28.19,0,0,1-6.79-5.08,25.69,25.69,0,0,1-5.1-6.79C.61,6.37.92,4.65,1.26,3.91A4.59,4.59,0,0,1,3.06,2a8.62,8.62,0,0,1,1.41-.74l.14-.06a1.28,1.28,0,0,1,1.07-.1,3,3,0,0,1,1,.78A17,17,0,0,1,9.27,5.67a3.74,3.74,0,0,1,.5,1.55,2.47,2.47,0,0,1-.62,1.47c-.07.08-.13.17-.19.25-.38.49-.46.63-.41.89a9.36,9.36,0,0,0,2.27,3.36,8.85,8.85,0,0,0,3.33,2.21c.26.06.41,0,.92-.41l.22-.17a2.48,2.48,0,0,1,1.49-.67h0a3.6,3.6,0,0,1,1.57.55,19,19,0,0,1,3.78,2.54,3.15,3.15,0,0,1,.79,1,1.31,1.31,0,0,1-.1,1.08.49.49,0,0,1-.06.13A8,8,0,0,1,22,20.89a4.57,4.57,0,0,1-1.93,1.79A3.17,3.17,0,0,1,18.63,23Z' />
  </IconBuilder>
);

export default PhoneFilledIcon;

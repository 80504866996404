import type { FC } from 'react';

// Core Components
import IconButton, {
  IconButtonProps,
} from 'core/components/base/inputs/IconButton';
import SwapIcon from 'core/components/icons/Swap';

// Custom Types
export interface SortIconButtonProps extends IconButtonProps {}

const SortIconButton: FC<SortIconButtonProps> = (props) => {
  // Props
  const { children, ...otherProps } = props;

  return (
    <IconButton {...otherProps}>
      <SwapIcon />
    </IconButton>
  );
};

export default SortIconButton;

import { transformAudienceSetting } from 'features/audience/settings/utilities';

// Utilities
import apiHandler from 'core/utilities/apiHandler';
import { getDoc } from 'core/utilities/apiHandler/helper';

// Custom Utilities
import getEndpoint from 'core/utilities/helper/getEndpoint';
import { bakeAudienceSetting } from 'features/audience/settings/utilities/api/baker';

// Custom Types
import type { ApiGetDocResponse } from 'core/types/api/hook/response';
import type { ApiAudienceSettingsProps } from 'features/audience/settings/types/api';
import type {
  AudienceSettingsDataProps,
  AudienceSettingsProps,
} from 'features/audience/settings/types';

/**
 * Fetches the audience settings.
 *
 * @param {string} [_] - An optional parameter that is required for compatibility with the 'useDoc' hook; this argument does not affect the function's behavior.
 * @param {AbortSignal} [signal] - An optional signal to abort the request.
 * @returns {Promise<ApiGetDocResponse<AudienceSettingsProps>>} A promise that resolves to an object containing the status and the audience settings document, if available.
 */
export const getAudienceSetting = async (
  _?: string, // I add this because we can't use 'useDoc' hook without this. this argument won't do anything
  signal?: AbortSignal
): Promise<ApiGetDocResponse<AudienceSettingsProps>> => {
  const { getAudienceSetting: endpoint } = getEndpoint();

  return await getDoc<ApiAudienceSettingsProps, AudienceSettingsProps>(
    endpoint,
    {
      signal,
      returnMutationFn: (status, data) => ({
        status,
        doc: data ? transformAudienceSetting(data) : undefined,
      }),
    }
  );
};

/**
 * Edits the audience settings.
 *
 * @param {AudienceSettingsDataProps} settings - The audience settings data to be edited.
 * @returns {Promise<{ status: number }>} A promise that resolves to an object containing the status of the edit operation.
 */
export const editAudienceSetting = async (
  settings: AudienceSettingsDataProps
): Promise<{ status: number }> => {
  const { editAudienceSetting: endpoint } = getEndpoint();
  const data = bakeAudienceSetting(settings);
  const { status } = await apiHandler.patch(endpoint, data);
  return { status };
};

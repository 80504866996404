import * as React from 'react';
import isEqual from 'lodash/isEqual';

// Core Components
import Button from 'core/components/base/inputs/Button';

// Common Components
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Types
interface FileTabsProps {
  value: string;
  onTabChange: (value: string) => void;
  disableExternalSection?: boolean;
  disableUploadSection?: boolean;
  disableFileManagerSection?: boolean;
  disableFilesInSection?: boolean;
}

const FileTabs: React.FC<FileTabsProps> = (props) => {
  // Props
  const {
    value,
    disableExternalSection,
    disableUploadSection,
    disableFileManagerSection,
    disableFilesInSection,
    onTabChange,
  } = props;

  // Hooks
  const tabs = React.useMemo(
    () => [
      {
        isActive: !disableUploadSection,
        value: 'upload',
        title: 'بارگذاری فایل',
      },
      {
        isActive: !disableFileManagerSection,
        value: 'file-manager',
        title: 'فایل منیجر',
      },
      {
        isActive: !disableExternalSection,
        value: 'external-url',
        title: 'انتخاب از آدرس',
      },
      {
        isActive: !disableFilesInSection,
        value: 'section-files',
        title: 'فایل‌های آپلود شده در این بخش',
      },
    ],
    [
      disableExternalSection,
      disableUploadSection,
      disableFileManagerSection,
      disableFilesInSection,
    ]
  );

  // Render
  return (
    <RowStack spacing={0} alignItems='center'>
      {tabs
        .filter((tab) => tab.isActive)
        .map((tab) => (
          <Button
            key={tab.value}
            onClick={() => onTabChange(tab.value)}
            variant='text'
            color={value === tab.value ? 'info' : 'inherit'}
          >
            {tab.title}
          </Button>
        ))}
    </RowStack>
  );
};

export default React.memo(FileTabs, isEqual);

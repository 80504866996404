import * as React from 'react';
import store from 'core/store';
import cloneDeep from 'lodash/cloneDeep';
import { alpha, useTheme } from '@mui/material';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';

// Custom Core Components
import Paper from 'core/components/base/surfaces/Paper';
import Button from 'core/components/base/inputs/Button';

// Custom Common Components
import DeleteDialog from 'core/components/shared/Dialog/Delete';

// Custom Icons
import BinIcon from 'core/components/icons/Bin';

// Context
import { useDeleteMenuItemsMutation } from 'features/menu/menus/hooks';
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';
import {
  deleteMenu,
  setMenuActionMode,
  updateOriginalItems,
  useSelectDeleteIdsMap,
  useSelectMenuActionMode,
  useSelectMenuItems,
  useSelectOriginalMenuItems,
} from 'core/store/slices/feature/menu';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { forLoop, setAppAlert } from 'core/utilities/helper';

interface DeleteButtonProps {}

const DeleteButton: React.FC<DeleteButtonProps> = (props) => {
  // States
  const showDeleteDialog = useBoolean();

  // Context
  const actionMode = useSelectMenuActionMode();
  const loading = useSelectLoading();
  const deleteIdsMap = useSelectDeleteIdsMap();
  const originalItems = useSelectOriginalMenuItems();
  const currentItems = useSelectMenuItems();

  // Hooks
  const { palette } = useTheme();
  const { mutate: handleDeleteMenuItems } = useDeleteMenuItemsMutation({
    autoAlert: { mode: 'delete', name: 'منو آیتم' },
    onSuccess: () => {
      const items = cloneDeep(store.getState().menu.current?.data.items || []);
      setMenuActionMode('NORMAL');
      showDeleteDialog.setFalse();
      forLoop(items, (item) => {
        if (item.id in deleteIdsMap) deleteMenu(item);
      });
      updateOriginalItems();
    },
  });
  // Utilities
  const handleDeleteAction = async () => {
    if (!isSame(originalItems, currentItems))
      return setAppAlert('لطفا ابتدا تغییرات خود را ذخیره کنید', 'info');
    setMenuActionMode('DELETE');
    showDeleteDialog.setTrue();
  };

  const handleDelete = async () => {
    const itemsToDelete = Object.keys(deleteIdsMap);
    handleDeleteMenuItems(itemsToDelete);
  };

  // Render
  return (
    <>
      <Button
        component={Paper}
        color='inherit'
        onClick={handleDeleteAction}
        disabled={
          actionMode === 'DELETE'
            ? Object.keys(deleteIdsMap).length === 0 || loading
            : loading || actionMode !== 'NORMAL' || currentItems.length === 0
        }
        startIcon={<BinIcon />}
        sx={{
          backgroundColor: palette.background.paper,
          color: palette.error.main,
          minWidth: 'fit-content',
          borderRadius: '.5rem',
          padding: '.5rem .75rem',
          ':hover': {
            backgroundColor: alpha(palette.background.paper, 0.8),
          },
        }}
      >
        حذف
      </Button>
      <DeleteDialog
        open={showDeleteDialog.state}
        title='حذف منو آیتم'
        onDelete={handleDelete}
        onClose={showDeleteDialog.setFalse}
        PaperProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '0.5rem',
            boxShadow: 'none',
            minWidth: '40rem',
            padding: '5rem 3rem',
          },
        }}
        description={
          <p>
            هشدار: با حذف هر آیتم از منو، زیرمنوهای آن آیتم نیز حذف می‌شوند.
            <br />
            آیا از حذف آیتم منوی انتخاب شده اطمینان دارید؟
          </p>
        }
      />
    </>
  );
};

export default DeleteButton;

// Custom Types
import type { FooterPatternDataProps } from 'features/appBuilder/patterns/features/footer/types';

/**
 * Validates the footer pattern data and returns an error message if validation fails.
 *
 * @param {FooterPatternDataProps} data - The footer pattern data to validate.
 * @returns {string} A string containing the error message if validation fails, or an empty string if the data is valid.
 */
export const validateFooterPattern = (data: FooterPatternDataProps): string => {
  if (!data.patternTitle.trim()) {
    return 'وارد کردن عنوان پترن الزامی می‌باشد.';
  }

  return '';
};

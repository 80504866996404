import { useState, type FC } from 'react';

// Core Components
import Stack from 'core/components/base/layout/Stack';
import IconButton from 'core/components/base/inputs/IconButton';
import CircularProgress from 'core/components/base/feedback/CircularProgress';

// Custom Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import ButtonLink from 'core/components/shared/Button/ButtonLink';
import DeleteDialog from 'core/components/shared/Dialog/Delete';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageTitle from 'core/components/shared/Typography/PageTitle';

// Feature Components
import ContentsDataGrid from 'features/content/contents/components/list/Table';

// Custom Icons
import BinIcon from 'core/components/icons/Bin';
import AddCircleOutlineIcon from 'core/components/icons/PlusCircleOutliend';

// Context
import DocsProvider from 'core/hooks/api/useDocs/Context';

// Utilities
import useSelection from 'core/hooks/common/useSelection';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import {
  useContentsDocs,
  useDeleteContentMutation,
} from 'features/content/contents/hooks';

// Core Types
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';

// Custom Types
interface ContentsPageProps extends WithAuthProps {}

const Contents: FC<ContentsPageProps> = (props) => {
  // States
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);

  // Context
  const loading = useSelectLoading();

  // Hooks
  const { selections, onSelectionsChange, onClearSelections } = useSelection();
  const contentsListMethods = useContentsDocs();
  const { data, onPageNumChange, onPageSizeChange } = contentsListMethods;
  const { mutate: handleDeleteContent } = useDeleteContentMutation({
    autoAlert: { mode: 'delete', name: 'مطالب انتخاب شده' },
    onSuccess: () => {
      onClearSelections();
      setDeleteDialog(false);
    },
  });

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='مطالب' />
      <DeleteDialog
        open={deleteDialog}
        onDelete={() => handleDeleteContent(selections)}
        onClose={() => setDeleteDialog(false)}
      />
      <DocsProvider {...contentsListMethods}>
        <PageHeroBox>
          <PageTitle>مطالب</PageTitle>
          <Stack>
            {selections.length > 0 && (
              <Stack alignItems='center' gap={0}>
                <IconButton
                  color='error'
                  disabled={selections.length === 0}
                  onClick={() => setDeleteDialog(true)}
                >
                  {selections.length > 0 && loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <BinIcon />
                  )}
                </IconButton>
                <BodyTwo>{selections.length.toPersian()} انتخاب شده</BodyTwo>
              </Stack>
            )}
            <ButtonLink
              color='info'
              disabled={selections.length !== 0 || loading}
              href='/contents/add'
              sx={{ minWidth: '7rem' }}
              variant='contained'
              startIcon={<AddCircleOutlineIcon />}
            >
              مطلب جدید
            </ButtonLink>
          </Stack>
        </PageHeroBox>
        <ContentsDataGrid
          disabled={loading}
          page={data.page}
          contents={data.list}
          selections={selections}
          onPageSizeChange={onPageSizeChange}
          onPageNumChange={onPageNumChange}
          onSelectionChange={onSelectionsChange}
        />
      </DocsProvider>
    </>
  );
};

export default Contents;

// Feature Utilities
import { bakePatternCommonProps } from 'features/appBuilder/patterns/core/utilities/api/baker';

// Feature Types
import type { FooterPatternDataProps } from 'features/appBuilder/patterns/features/footer/types';

/**
 * Transforms the provided footer pattern data by ensuring the `creator` field is included as a string.
 *
 * @param {FooterPatternDataProps} data - The footer pattern data to transform.
 * @returns {Omit<FooterPatternDataProps, 'creator'> & { creator: string }} The transformed footer pattern data with the `creator` field as a string.
 */
export const bakeFooterPatternData = (
  data: FooterPatternDataProps
): Omit<FooterPatternDataProps, 'creator'> & { creator: string } => {
  const { brand, menu, description, banner, ...otherProps } = data;

  const commonProps = bakePatternCommonProps(otherProps);

  return {
    ...commonProps,
    brand,
    menu,
    description,
    banner,
  };
};

import type { FC } from 'react';

// Core Components
import Button, { ButtonProps } from 'core/components/base/inputs/Button';

// Custom Types
export interface EvaluationAccessButtonProps extends ButtonProps {
  selected?: boolean;
}

const EvaluationAccessButton: FC<EvaluationAccessButtonProps> = (props) => {
  // Props
  const {
    children,
    variant = 'contained',
    selected,
    sx,
    ...otherProps
  } = props;

  return (
    <Button
      variant='contained'
      sx={{
        fontWeight: 400,
        fontSize: '14px',
        color: selected ? 'text.primary' : 'text.secondary',
        backgroundColor: selected ? '#9ED5FF' : '#C1C8D3',
        ':hover': {
          backgroundColor: selected ? '#9ED5FF' : '#C1C8D3',
        },
        height: '1.5rem',
        whiteSpace: 'nowrap',
        flexShrink: 0,
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export default EvaluationAccessButton;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Type
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface CalendarIconProps extends SvgIconProps {}

const CalendarIcon: React.FC<CalendarIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M19,4H17V2H15V4H9V2H7V4H5A2,2,0,0,0,3,6V20a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V6A2,2,0,0,0,19,4Zm0,5H5V7H19Z' />
  </IconBuilder>
);

export default CalendarIcon;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface TextareaFilledIconProps extends SvgIconProps {}
const TextareaFilledIcon: React.FC<TextareaFilledIconProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <path
        style={{ clipRule: 'evenodd', fillRule: 'evenodd' }}
        d='M23,4.3H1v15.4h22V4.3z M20.6,16.4V17h-0.5c-0.3,0.1-0.5,0.4-0.4,0.7c0,0.2,0.2,0.4,0.4,0.4h1c0.3,0,0.5-0.3,0.5-0.5c0,0,0,0,0,0v-1.1c0-0.3-0.2-0.5-0.5-0.5c0,0,0,0,0,0C20.8,15.9,20.6,16.1,20.6,16.4C20.6,16.4,20.6,16.4,20.6,16.4z M3.9,9.8c-0.3,0-0.5,0.2-0.5,0.6c0,0.3,0.2,0.5,0.5,0.5h1c0.3-0.1,0.5-0.4,0.4-0.7C5.2,10,5,9.8,4.8,9.8V7.1h0.5c0,0.3,0.2,0.5,0.5,0.5c0,0,0,0,0,0c0.3,0,0.5-0.3,0.5-0.5c0,0,0,0,0,0V6.5C6.3,6.2,6.1,6,5.8,6c0,0,0,0,0,0H2.9C2.6,6,2.4,6.2,2.4,6.5c0,0,0,0,0,0v0.6c0,0.3,0.2,0.5,0.5,0.5c0,0,0,0,0,0c0.3,0,0.5-0.3,0.5-0.5c0,0,0,0,0,0h0.5V9.8z'
      />
    </IconBuilder>
  );
};

export default TextareaFilledIcon;

import { UAParser } from 'ua-parser-js';

/**
 * Returns the operating system information.
 *
 * @returns {UAParser.IOS} The operating system information.
 */
const getOS = (): UAParser.IOS => {
  const parser = new UAParser();

  return parser.getOS();
};

export default getOS;

import * as React from 'react';

// Core Components
import Grid, { GridProps } from 'core/components/base/layout/Grid';

export interface GridItemProps extends GridProps {}

const GridItem: React.FC<GridItemProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <Grid item {...otherProps}>
      {children}
    </Grid>
  );
};

export default GridItem;

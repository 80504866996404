import * as React from 'react';
import isEqual from 'lodash/isEqual';
import { createTheme, ThemeProvider } from '@mui/material';

// Custom Hooks
import useUnMount from 'core/hooks/useUnMount';
import useBoolean from 'core/hooks/useBoolean';

// Core Components
import TableCell from 'core/components/base/display/TableCell';
import TableRow from 'core/components/base/display/TableRow';
import LinearProgress from 'core/components/base/feedback/LinearProgress';

// Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';
import CloseIconButton from 'core/components/shared/IconButton/Close';

// Custom Utilities
import { addFiles } from 'features/file/files/utilities/files';
import { isSucceed } from 'core/utilities/helper';
import { convertBytes } from 'features/file/files/utilities/file';

// Context
import { useUploadDialogContext } from 'features/file/files/context/UploadDialog';

// Custom Types
import type { FileProps } from 'features/file/files/types';
interface UploadRowProps {
  file: File;
  refId: string;
  status: 'queue' | 'upload';
  onDelete: () => void;
  onUploadFinish: (file: FileProps) => void;
}

const UploadRow: React.FC<UploadRowProps> = (props) => {
  // Props
  const { file, refId, status, onUploadFinish, onDelete } = props;

  // States
  const [progress, setProgress] = React.useState<number>(0);
  const errorAccoured = useBoolean();
  const uploadStarted = useBoolean();

  // Context
  const { settings } = useUploadDialogContext();

  // Hooks
  const request = React.useMemo(() => new XMLHttpRequest(), []);

  React.useEffect(() => {
    if (status === 'queue' || uploadStarted.state) return;
    uploadStarted.setTrue();
    (async () => {
      const response = await addFiles(
        {
          files: [file as File],
          location: settings?.location || 'file',
          relatedModelId: settings?.refId || refId,
        },
        setProgress,
        request
      );
      if (
        isSucceed(response.status) &&
        response.files &&
        response.files.length > 0
      ) {
        onUploadFinish(response.files[0]);
        onDelete();
      } else errorAccoured.setTrue();
    })();
  }, [status]);

  useUnMount(() => request.abort());

  // Utilities
  const handleCancel = () => {
    request.abort();
    onDelete();
  };

  // Render
  return (
    <TableRow
      sx={{
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'divider',
      }}
    >
      <TableCell colSpan={7} sx={{ padding: '.5rem' }}>
        <SpaceBetween sx={{ width: '100%' }}>
          <RowStack spacing='.5rem' alignItems='center'>
            <CloseIconButton size='small' onClick={handleCancel} />
            <BodyTwo
              sx={{ whiteSpace: 'nowrap', direction: 'rtl', fontSize: '1rem' }}
            >
              {file.name.toPersian()}
            </BodyTwo>
            <BodyTwo
              sx={{
                color: 'text.disabled',
                whiteSpace: 'nowrap',
                direction: 'rtl',
                fontSize: '14px',
              }}
            >
              {convertBytes(file.size, 0).toPersian()}
            </BodyTwo>
          </RowStack>
          {errorAccoured.state || status === 'queue' ? (
            <BodyTwo
              sx={{
                marginRight: '1.5rem',
                color: errorAccoured.state ? 'error.main' : 'primary.text',
              }}
            >
              {errorAccoured.state ? 'خطا در آپلود' : 'در صف‌ آپلود'}
            </BodyTwo>
          ) : (
            <RowStack
              spacing='.5rem'
              justifyContent='end'
              alignItems='center'
              width='fit-content'
            >
              <ThemeProvider theme={createTheme()}>
                <LinearProgress
                  variant='determinate'
                  value={progress}
                  sx={{
                    width: '224px',
                    backgroundColor: 'rgba(193, 200, 211, 1)',
                    borderRadius: '1rem',
                    '.MuiLinearProgress-bar1Determinate': {
                      borderRadius: '.5rem',
                      backgroundColor: 'rgba(0, 145, 255, 1)',
                    },
                  }}
                />
              </ThemeProvider>
              <BodyTwo color='info.main'>{`${progress}%`.toPersian()}</BodyTwo>
            </RowStack>
          )}
        </SpaceBetween>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UploadRow, isEqual);

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface H4IconProps extends SvgIconProps {}

const H4Icon: React.FC<H4IconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M5.88583 6.66227V10.5409H12.1142V6.66227C12.1142 6.10818 12.2437 5.69261 12.5028 5.41557C12.7684 5.13852 13.115 5 13.5425 5C13.9765 5 14.3263 5.13852 14.5919 5.41557C14.864 5.68646 15 6.10202 15 6.66227V17.3285C15 17.8887 14.864 18.3074 14.5919 18.5844C14.3198 18.8615 13.97 19 13.5425 19C13.1085 19 12.7619 18.8615 12.5028 18.5844C12.2437 18.3012 12.1142 17.8826 12.1142 17.3285V12.7757H5.88583V17.3285C5.88583 17.8887 5.7498 18.3074 5.47773 18.5844C5.20567 18.8615 4.85587 19 4.42834 19C3.99433 19 3.64777 18.8615 3.38866 18.5844C3.12955 18.3012 3 17.8826 3 17.3285V6.66227C3 6.10818 3.12632 5.69261 3.37895 5.41557C3.63806 5.13852 3.98785 5 4.42834 5C4.86235 5 5.21215 5.13852 5.47773 5.41557C5.7498 5.68646 5.88583 6.10202 5.88583 6.66227Z' />
    <path d='M20.0684 19.2559V18.166H17.127C16.7559 18.166 16.4766 18.082 16.2891 17.9141C16.1055 17.7461 16.0137 17.5176 16.0137 17.2285C16.0137 17.1543 16.0273 17.0801 16.0547 17.0059C16.082 16.9277 16.123 16.8477 16.1777 16.7656C16.2324 16.6797 16.2891 16.5977 16.3477 16.5195C16.4062 16.4414 16.4785 16.3457 16.5645 16.2324L19.6699 12.0781C19.873 11.8047 20.0391 11.6074 20.168 11.4863C20.3008 11.3613 20.459 11.2988 20.6426 11.2988C21.2363 11.2988 21.5332 11.6387 21.5332 12.3184V16.8535H21.7852C22.0859 16.8535 22.3262 16.8945 22.5059 16.9766C22.6895 17.0586 22.7812 17.2383 22.7812 17.5156C22.7812 17.7422 22.707 17.9082 22.5586 18.0137C22.4102 18.1152 22.1875 18.166 21.8906 18.166H21.5332V19.2559C21.5332 19.5566 21.4668 19.7812 21.334 19.9297C21.2012 20.0742 21.0234 20.1465 20.8008 20.1465C20.582 20.1465 20.4043 20.0723 20.2676 19.9238C20.1348 19.7715 20.0684 19.5488 20.0684 19.2559ZM17.5488 16.8535H20.0684V13.4492L17.5488 16.8535Z' />
  </IconBuilder>
);

export default H4Icon;

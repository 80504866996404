// Custom Hooks
import useDocApi from 'core/hooks/api/useDoc';
import useDocsApi from 'core/hooks/api/useDocs';
import { useEditMutation } from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  getTicket,
  getTickets,
  toggleTicketStatus,
} from 'features/ticket/tickets/utilities/api';

// Custom Types
import type {
  TicketProps,
  TicketSummaryProps,
} from 'features/ticket/tickets/types';
import type {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';

/**
 * Query key for fetching tickets in react query.
 *
 * @constant {Array<string>}
 */
export const ticketsQueryKey = ['tickets'];

/**
 * Custom hook to fetch ticket.
 *
 * This hook utilizes the `useDocsApi` to retrieve ticket list on the provided options.
 *
 * @param {UseDocsOptionType<TicketSummaryProps>} [options] - Optional configuration options for fetching the ticket.
 * @returns {UseDocsApiReturnProps<TicketSummaryProps>} The result of the `useDocsApi` hook, which includes the fetched ticket
 */
export const useTicketDocs = (
  options?: UseDocsOptionType<TicketSummaryProps>
): UseDocsApiReturnProps<TicketSummaryProps> =>
  useDocsApi<TicketSummaryProps>(getTickets, ticketsQueryKey, options);

/**
 * Custom hook to fetch a process document.
 *
 * This hook utilizes the `useDocApi` to retrieve a specific process based on the provided options.
 *
 * @param {UseDocOptionType<TicketProps>} [options] - Optional configuration options for fetching the process document.
 * @returns {UseDocApiReturnProps<TicketProps>} The result of the `useDocApi` hook, which includes the fetched process document, loading state, and any errors.
 */
export const useTicketDoc = (
  options?: UseDocOptionType<TicketProps>
): UseDocApiReturnProps<TicketProps> =>
  useDocApi<TicketProps>(getTicket, ticketsQueryKey, options);

export const useToggleTicketStatusMutation = useEditMutation<boolean>(
  toggleTicketStatus,
  ticketsQueryKey
);

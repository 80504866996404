import * as React from 'react';

// Types
import type { FC } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

// Custom Common Components
import SubmitButton from 'core/components/shared/Button/SubmitButton';
import GridContainer from 'core/components/shared/Grid/GridContainer';
import GridItem from 'core/components/shared/Grid/GridItem';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import RowStack from 'core/components/shared/Stack/RowStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import PageTitle from 'core/components/shared/Typography/PageTitle';

// Custom Core Components
import Container from 'core/components/base/layout/Container';
import IconButton from 'core/components/base/inputs/IconButton';
import MenuItem from 'core/components/base/navigation/MenuItem';
import Select from 'core/components/base/inputs/Select';
import TextField from 'core/components/base/inputs/TextField';

// Custom Icon Components
import AddCircleOutlineIcon from 'core/components/icons/AddCircleOutline';
import CancelIcon from 'core/components/icons/Cancel';

// Custom Types
interface AudienceDependantsProps {}

const AudienceDependants: FC<AudienceDependantsProps> = () => {
  // Hooks
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dependants',
  });

  const handleAddDependant = () => {
    append({
      data: {
        firstName: '',
        lastName: '',
        nationalId: '',
        relation: '',
      },
      id: '',
    });
  };

  return (
    <Container sx={{ marginY: '1rem' }}>
      <GridContainer sx={{ marginY: '1rem' }}>
        <GridItem xs={2} md={2.5} lg={2}></GridItem>
        <GridItem xs={10} md={9.5} lg={10}>
          <RoundPaper>
            <PageTitle sx={{ p: 2 }}>افراد تحت تکفل</PageTitle>
            {fields.map((field, index) => {
              return (
                <RowStack sx={{ alignItems: 'flex-end', mt: 2 }}>
                  <IconButton
                    onClick={() => remove(index)}
                    sx={{ mb: 2, height: '20px', width: '20px' }}
                  >
                    <CancelIcon color='error' />
                  </IconButton>
                  <ColumnStack spacing={0}>
                    <InputLabel>نام</InputLabel>
                    <Controller
                      name={`dependants.${index}.data.firstName`}
                      control={control}
                      render={({ field }) => (
                        <TextField placeholder='نام' {...field} />
                      )}
                    />
                  </ColumnStack>
                  <ColumnStack spacing={0}>
                    <InputLabel>نام خانوادگی</InputLabel>
                    <Controller
                      name={`dependants.${index}.data.lastName`}
                      control={control}
                      render={({ field }) => (
                        <TextField placeholder='نام خانوادگی' {...field} />
                      )}
                    />
                  </ColumnStack>
                  <ColumnStack spacing={0}>
                    <InputLabel>کدملی</InputLabel>
                    <Controller
                      name={`dependants.${index}.data.nationalId`}
                      control={control}
                      render={({ field }) => (
                        <TextField placeholder='کدملی' {...field} />
                      )}
                    />
                  </ColumnStack>
                  <ColumnStack sx={{ width: '220px' }} spacing={0}>
                    <InputLabel>رابطه</InputLabel>
                    <Controller
                      name={`dependants.${index}.data.relation`}
                      control={control}
                      render={({ field }) => (
                        <Select sx={{ borderRadius: '4px' }} {...field}>
                          <MenuItem value='پدر'>پدر</MenuItem>
                          <MenuItem value='مادر'>مادر</MenuItem>
                          <MenuItem value='خواهر'>خواهر</MenuItem>
                          <MenuItem value='برادر'>برادر</MenuItem>
                          <MenuItem value='همسر'>همسر</MenuItem>
                          <MenuItem value='فرزند'>فرزند</MenuItem>
                        </Select>
                      )}
                    />
                  </ColumnStack>
                </RowStack>
              );
            })}
            <SubmitButton
              onClick={handleAddDependant}
              startIcon={<AddCircleOutlineIcon />}
              sx={{ m: 3 }}
            >
              افزودن
            </SubmitButton>
          </RoundPaper>
        </GridItem>
      </GridContainer>
    </Container>
  );
};

export default AudienceDependants;

import { useQueryClient } from '@tanstack/react-query';

// Custom Hooks
import useAppMutation from 'core/hooks/api/useMutation/useAppMutation';

// Custom Utilities
import { isSucceed } from 'core/utilities/helper';

// Custom Types
import type { UseAppMutationOptionsProps } from 'core/hooks/api/useMutation/useAppMutation';

export const useEditMutation = <TData, TResponse = { status: number }>(
  apiHandler: (id: string, data: TData) => Promise<TResponse>,
  queryKey: string[]
) => {
  return (
    options?: Omit<
      UseAppMutationOptionsProps<TResponse, Error, { id: string; data: TData }>,
      'mutationFn' | 'mutationKey'
    >
  ) => {
    const queryClient = useQueryClient();

    return useAppMutation({
      mutationFn: async (payload) => {
        const response = (await apiHandler(payload.id, payload.data)) as any;
        const status =
          'status' in response
            ? (response.status as number)
            : typeof response === 'number'
            ? response
            : 502;

        if (isSucceed(status)) {
          return response as TResponse;
        } else {
          throw new Error('ERROR');
        }
      },
      mutationKey: queryKey,
      ...options,
      onSuccess: (data, variables, context) => {
        if (!options?.noRevalidate)
          queryClient.invalidateQueries({
            queryKey,
          });
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    });
  };
};

// Custom Utilities
import getEndpoint from 'core/utilities/helper/getEndpoint';
import { getDoc, getDocs } from 'core/utilities/apiHandler/helper';
import { transformTemplateBodies } from 'features/appBuilder/templates/utilities/transformer';

// Custom Types
import type { TemplateProps } from 'features/appBuilder/templates/types';
import type { TemplateFeatureType } from 'features/appBuilder/templates/types/featureType';
import type { TemplateListItemProps } from 'features/appBuilder/templates/types/listItem';
import type { ApiTemplateBodyProps } from 'features/appBuilder/templates/types/api';
import type { ApiGetDocResponse } from 'core/types/api/hook/response';

/**
 * Fetches a list of templates with optional query parameters.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @param {Record<string, any>} [queries] - Optional query parameters for filtering templates.
 * @returns {Promise<ApiGetDocResponse<TemplateListItemProps>>} - A promise that resolves to the response containing a list of template items.
 */
export const getTemplates = async (
  signal?: AbortSignal,
  queries?: Record<string, any>
): Promise<ApiGetDocResponse<TemplateListItemProps>> => {
  const endpoint = getEndpoint().getTemplatesURL;
  return await getDocs<
    { [key in TemplateFeatureType]: string[] },
    TemplateListItemProps
  >(endpoint, {
    signal,
    queries,
    returnMutationFn: (status, data) => ({
      status,
      list: Object.entries(data || {}).map(([feature, skins]) => ({
        id: `template-feature-${feature}`,
        data: {
          feature: feature as TemplateFeatureType,
          skins,
        },
      })),
    }),
  });
};

/**
 * Fetches a specific template by its feature type.
 *
 * @param {TemplateFeatureType} type - The type of the template to fetch.
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<ApiGetDocResponse<TemplateProps>>} - A promise that resolves to the response containing the template data.
 */
export const getTemplate = async (
  type: TemplateFeatureType,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<TemplateProps>> => {
  const endpoint = `${getEndpoint().getTemplateURL}/${type}`;
  return await getDoc<{ bodies: ApiTemplateBodyProps[] }, TemplateProps>(
    endpoint,
    {
      signal,
      returnMutationFn: (status, data) => ({
        status,
        doc: data
          ? {
              id: `template-feature-${type}`,
              data: {
                feature: type,
                bodies: transformTemplateBodies(data?.bodies || []),
              },
            }
          : undefined,
      }),
    }
  );
};

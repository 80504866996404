// Feature Utilities
import { bakePatternCommonProps } from 'features/appBuilder/patterns/core/utilities/api/baker';

// Feature Types
import type { TablePatternDataProps } from 'features/appBuilder/patterns/features/table/types';

/**
 * Transforms the provided table pattern data by ensuring the `creator` field is included as a string.
 *
 * @param {TablePatternDataProps} data - The table pattern data to transform.
 * @returns {Omit<TablePatternDataProps, 'creator'> & { creator: string }} The transformed table pattern data with the `creator` field as a string.
 */
export const bakeTablePatternData = (
  data: TablePatternDataProps
): Omit<TablePatternDataProps, 'creator'> & { creator: string } => {
  const { refs, rows, sheetIds, rowCount, colCount, ...otherProps } = data;

  const commonProps = bakePatternCommonProps(otherProps);

  return {
    ...commonProps,
    refs,
    rows,
    sheetIds,
    rowCount,
    colCount,
  };
};

import {
  Menu as MuiMenu,
  MenuProps as MuiMenuProps,
  styled,
} from '@mui/material';

export interface MenuProps extends MuiMenuProps {}

const Menu = styled(MuiMenu)<MenuProps>(() => ({}));

export default Menu;

import { type FC } from 'react';

// Custom Core Components
import Autocomplete from 'core/components/base/inputs/Autocomplete';
import TextField from 'core/components/base/inputs/TextField';

// Custom Common Components
import MultiAutocomplete from 'core/components/shared/Autocomplete/api/MultipleAutocomplete';

// Custom Icon Components
import ArrowDropDownIcon from 'core/components/icons/ArrowDropDown';

// Custom Utilities
import { formsQueryKey } from 'features/form/forms/hooks';
import { getInputsListOfAudiences } from 'features/form/forms/utilities/api/inputs';

// Custom Types
import type { GenericMultiAutocompleteProps } from 'core/components/shared/Autocomplete/api/MultipleAutocomplete';
export interface InputAutocompleteProps
  extends Omit<GenericMultiAutocompleteProps<{}>, 'apiHandler' | 'queryKey'> {
  formId: string;
}

const InputAutocomplete: FC<InputAutocompleteProps> = (props) => {
  // Props
  const { formId, ...otherProps } = props;

  return formId ? (
    <MultiAutocomplete
      limitTags={1}
      getOptionLabel={(option) => {
        if (typeof option === 'string') return option;

        return option.data.label;
      }}
      defaultRefreshCount={2}
      title='عنوان بازخورد'
      apiHandler={getInputsListOfAudiences}
      queryKey={formsQueryKey}
      {...otherProps}
    />
  ) : (
    <Autocomplete
      disabled
      fullWidth
      renderInput={(params) => (
        <TextField placeholder='انتخاب عنوان بازخورد' {...params} />
      )}
      popupIcon={<ArrowDropDownIcon sx={{ color: 'text.disabled' }} />}
      options={[]}
    />
  );
};

export default InputAutocomplete;

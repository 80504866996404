import type { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

// Custom Hooks
import useUnMount from 'core/hooks/useUnMount';

// Feature Components
import ProcessDetails from 'features/form/processes/components/details/Details';

// Core Utilities
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// Feature Utilities
import { resetProcessState } from 'features/form/processes/store/actions';
import { processSchema } from 'features/form/processes/utilities/validations';
import { genInitialProcessValue } from 'features/form/processes/utilities/initial/process';

// Custom Types
import type { ProcessProps } from 'features/form/processes/types/details';

export interface AddProcessProps {}

const initialProcessValues = genInitialProcessValue();

const AddProcess: FC<AddProcessProps> = (props) => {
  // Hooks
  const validationSchema = useYupValidationResolver(processSchema);
  const methods = useForm<ProcessProps>({
    mode: 'onTouched',
    resolver: validationSchema,
    defaultValues: initialProcessValues,
  });

  useUnMount(() => resetProcessState());

  // Render
  return (
    <FormProvider {...methods}>
      <ProcessDetails />
    </FormProvider>
  );
};

export default AddProcess;

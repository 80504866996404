// Custom Utilities
import apiHandler from 'core/utilities/apiHandler';
import { getDocs } from 'core/utilities/apiHandler/helper';
import { getUrlWithQueryString } from 'core/utilities/helper/helperPack';
import { AudienceAccessesEndpoints } from 'features/audience/accesses/utilities/api/endpoints';

// Custom Types
import type { AccessProps } from 'features/audience/accesses/types';
import type { ApiGetDocsResponse } from 'core/types/api/hook/response';

/**
 * Retrieves a list of audience access data from the server.
 * @function getAudienceAccessList
 * @async
 * @returns {Promise<ApiGetDocsResponse<AccessProps>>} A Promise that resolves with an object containing the HTTP status code and the list of audience access data.
 */
export const getAudienceAccessList = async (
  signal?: AbortSignal,
  queries?: Record<string, any>
): Promise<ApiGetDocsResponse<AccessProps>> => {
  return await getDocs<{ accesses: AccessProps[] }, AccessProps>(
    AudienceAccessesEndpoints.getList,
    {
      queries,
      signal,
      returnMutationFn: (status, data) => ({
        status,
        list: data.accesses || [],
      }),
    }
  );
};

/**
 * Adds a new audience access.
 *
 * @param {string} title - The title of the audience access to be added.
 * @returns {Promise<{ status: number }>} A promise that resolves to an object containing the status of the operation.
 */
export const addAudienceAccess = async (
  title: string
): Promise<{ status: number }> => {
  const { status } = await apiHandler.post(AudienceAccessesEndpoints.add, {
    title,
  });
  return { status };
};

/**
 * Updates an existing audience access.
 *
 * @param {string} accessId - The ID of the audience access to be updated.
 * @param {string} title - The new title for the audience access.
 * @returns {Promise<{ status: number }>} A promise that resolves to an object containing the status of the operation.
 */
export const updateAudienceAccess = async (
  accessId: string,
  title: string
): Promise<{ status: number }> => {
  const { status } = await apiHandler.patch(
    AudienceAccessesEndpoints.edit(accessId),
    { title }
  );
  return { status };
};

/**
 * Deletes an existing audience access.
 *
 * @param {string} accessId - The ID of the audience access to be deleted.
 * @returns {Promise<{ status: number }>} A promise that resolves to an object containing the status of the operation.
 */
export const deleteAudienceAccess = async (
  accessId: string
): Promise<{ status: number }> => {
  const { status } = await apiHandler.delete(
    AudienceAccessesEndpoints.remove(accessId)
  );
  return { status };
};

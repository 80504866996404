import { useNavigate } from 'react-router-dom';
import {
  QueryClient,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';

// Custom Utilities
import {
  AlertCrudType,
  setAppAlert,
  setAppLoading,
  setFailedCrudAlert,
  setSuccessCrudAlert,
} from 'core/utilities/helper';

// Custom Types
import type { AlertColor } from 'core/components/base/feedback/Alert';

interface AutoAlertProps {
  /**
   * The action type for the alert, indicating the type of CRUD operation.
   * Example values include:
   * - 'create': Indicates a new item has been created.
   * - 'read': Indicates an item has been fetched.
   * - 'update': Indicates an existing item has been updated.
   * - 'delete': Indicates an item has been deleted.
   * - 'edit': Indicates an item has been edited.
   */
  mode: AlertCrudType;
  /**
   * The feature name to be displayed in the alert.
   */
  name: string;
}
interface CustomAlertProps {
  successMessage: string;
  successSeverity?: AlertColor;
  errorMessage: string;
  errorSeverity?: AlertColor;
}

export type UseAppMutationOptionsProps<TData, TError, TVariables> =
  UseMutationOptions<TData, TError, TVariables> & {
    /**
     * If true, disables the default auto cache revalidator. default is set to false
     */
    noRevalidate?: boolean;
    /**
     * If true, disables the loading state during the mutation. default is set to false
     */
    disableLoading?: boolean;
    /**
     * If true, the loading state will persist after the mutation has completed. default is set to false
     */
    stayOnLoadingAfterMutate?: boolean;
    /**
     * If an object is passed, the mutation alert will be automatically handled with setSuccessCrudAlert and setFailedCrudAlert.
     */
    autoAlert?: AutoAlertProps;
    /**
     * If an object is passed, the mutation alert will be automatically handled with custom severity and message.
     */
    customAlert?: CustomAlertProps;
    /**
     * If an string passed, after the success mutation application will automatically redirect to that path.
     */
    redirectAfterSuccessTo?: string;
  };

function useAppMutation<TData, TError, TVariables>(
  options: UseAppMutationOptionsProps<TData, TError, TVariables>,
  queryClient?: QueryClient
): UseMutationResult<TData, TError, TVariables> {
  const {
    disableLoading,
    stayOnLoadingAfterMutate,
    onMutate,
    onSettled,
    ...otherOptions
  } = options;

  // Hooks
  const navigate = useNavigate();

  const mutation = useMutation(
    {
      ...otherOptions,
      onMutate: (variables) => {
        if (!disableLoading) setAppLoading(true);
        if (onMutate) onMutate(variables);
      },
      onSettled: (data, error, variables, context) => {
        if (!disableLoading && !stayOnLoadingAfterMutate) setAppLoading(false);
        if (error) {
          if (options?.autoAlert) {
            setFailedCrudAlert(options.autoAlert.mode, options.autoAlert.name);
          }
          if (options?.customAlert) {
            setAppAlert(
              options.customAlert.errorMessage,
              options.customAlert.errorSeverity || 'error'
            );
          }
        } else {
          if (options?.autoAlert) {
            setSuccessCrudAlert(options.autoAlert.mode, options.autoAlert.name);
          }
          if (options?.customAlert) {
            setAppAlert(
              options.customAlert.successMessage,
              options.customAlert.successSeverity || 'success'
            );
          }
        }
        if (onSettled) onSettled(data, error, variables, context);
        if (options.redirectAfterSuccessTo && !error) {
          setTimeout(() => {
            navigate(options.redirectAfterSuccessTo as string);
          }, 2500);
        }
      },
    },
    queryClient
  );

  // Return
  return mutation;
}

export default useAppMutation;

import { useState } from 'react';

// Types
import type { FC } from 'react';
import type { SxProps, Theme } from '@mui/material';

// Custom Hooks
import useUpdateEffect from 'core/hooks/useUpdateEffect';

// Custom Common Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import ListItemButtonLink from 'core/components/shared/List/ListItemButtonLink';

// Custom Core Components
import Select from 'core/components/base/inputs/Select';
import ListItemText from 'core/components/base/display/ListItemText';
import ListItemIcon from 'core/components/base/display/ListItemIcon';
import ListItemButton from 'core/components/base/display/ListItemButton';

// Custom Icon Components
import PlusCircleIcon from 'core/components/icons/PlusCircle';

// Custom Utilities
import { usePatternsOfDoc } from 'features/appBuilder/patterns/hooks';

// Custom Types
import type { PatternType } from 'features/appBuilder/patterns/core/types/item';
import type { PatternItemProps } from 'features/appBuilder/patterns/types/item';

export interface PatternItemsAutocompleteProps {
  disabled?: boolean;
  patternType: PatternType;
  defaultValue?: string;
  onChange?: (patternId: string, patternTitle: string) => void;
  sx?: SxProps<Theme>;
}

const PatternItemsAutocomplete: FC<PatternItemsAutocompleteProps> = (props) => {
  // Props
  const { disabled, patternType, defaultValue, onChange, sx } = props;

  // States
  const [value, setValue] = useState<PatternItemProps | ''>('');
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<PatternItemProps[]>();

  // Hooks
  const { status } = usePatternsOfDoc({
    id: patternType,
    onFetch: (doc) => setOptions(doc || []),
    select: (doc: any) => doc?.list || [],
    enabled:
      typeof patternType === 'string' &&
      patternType.length > 0 &&
      (open || (defaultValue && defaultValue.length > 0) ? true : false),
  });

  useUpdateEffect(() => {
    if (patternType) {
      if (
        defaultValue &&
        status === 'success' &&
        options &&
        options.length > 0
      ) {
        const patternToSet = options.find((p) => p.id === defaultValue);

        if (patternToSet) setValue(patternToSet);
      }
    } else {
      setValue('');
    }
  }, [status, options, patternType, defaultValue]);

  // Utilities
  const handleChange = (newValue: PatternItemProps | null) => {
    setValue(newValue || '');
    if (onChange)
      onChange(newValue?.id || '', newValue?.data.patternTitle || '');
  };

  // Render
  return (
    <Select
      open={open}
      disabled={disabled}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      sx={{ height: '2.4rem', ...sx }}
      value={value}
      displayEmpty
      renderValue={(v) => {
        const value = (v as PatternItemProps) || '';
        if (!value)
          return <BodyTwo color='text.secondary'>انتخاب پترن</BodyTwo>;
        return (
          <BodyTwo
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {value.data.patternTitle}
          </BodyTwo>
        );
      }}
    >
      <ListItemButtonLink target='_blank' href={`/pattern/${patternType}/add`}>
        <ListItemIcon>
          <PlusCircleIcon color='secondary' />
        </ListItemIcon>
        <ListItemText
          sx={{ color: 'secondary.main' }}
          primary='ساخت پترن جدید'
        />
      </ListItemButtonLink>
      {options?.map?.((opt) => (
        <ListItemButton
          key={opt.id}
          selected={value && opt.id === value.id ? true : false}
          onClick={() => handleChange(opt)}
        >
          <ListItemText primary={opt.data.patternTitle} />
        </ListItemButton>
      ))}
    </Select>
  );
};

export default PatternItemsAutocomplete;

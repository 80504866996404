// Custom Utility
import { getAppEndpointURL } from 'core/utilities/app/info';

/**
 * Prefixes the file URL with the base URL if necessary.
 * @param {string} URL - The file URL.
 * @returns {string} The prefixed file URL.
 */
export const prefixFileURL = (URL: string) => {
  if (!URL) return '';
  if (URL.startsWith('blob:')) return URL;
  if (URL.startsWith('https://')) return URL;

  const baseURL = getAppEndpointURL();

  return URL ? `${baseURL}/${URL}` : '';
};

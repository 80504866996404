import * as React from 'react';
import { alpha, useTheme } from '@mui/material';

// Custom Core Components
import Paper from 'core/components/base/surfaces/Paper';
import Button from 'core/components/base/inputs/Button';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';

// Context
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';
import {
  clearDeletionIdsMap,
  setMenuActionMode,
  setMenuItems,
  useSelectMenuActionMode,
  useSelectMenuItems,
  useSelectOriginalMenuItems,
} from 'core/store/slices/feature/menu';

// Custom Icons
import CloseIcon from 'core/components/icons/Close';

// Custom Types
interface CancelButtonProps {}

const CancelButton: React.FC<CancelButtonProps> = (props) => {
  // Context
  const actionMode = useSelectMenuActionMode();
  const loading = useSelectLoading();
  const originalItems = useSelectOriginalMenuItems();
  const currentItems = useSelectMenuItems();

  // Hooks
  const { palette } = useTheme();

  // Utilities
  const handleCancel = () => {
    if (actionMode === 'DELETE') {
      clearDeletionIdsMap();
      setMenuActionMode('NORMAL');
    } else {
      setMenuItems(originalItems);
    }
  };

  // Render
  return (
    <Button
      component={Paper}
      disabled={
        loading ||
        !['DELETE', 'NORMAL'].includes(actionMode) ||
        (actionMode !== 'DELETE' && isSame(originalItems, currentItems))
      }
      color='inherit'
      onClick={handleCancel}
      startIcon={<CloseIcon />}
      sx={{
        backgroundColor: palette.background.paper,
        color: actionMode === 'DELETE' ? palette.info.main : palette.error.main,
        minWidth: '6rem',
        borderRadius: '.5rem',
        padding: '.5rem .25rem',
        ':hover': {
          backgroundColor: alpha(palette.background.paper, 0.8),
        },
      }}
    >
      انصراف
    </Button>
  );
};

export default CancelButton;

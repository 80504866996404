// Custom Page Components
import SheetList from 'features/data/sheets/components/list';
import AddSheet from 'features/data/sheets/components/details/Add';
import EditSheet from 'features/data/sheets/components/details/Edit';

// Custom Utilities
import { getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'data';
const subFeature: SubFeatureType = 'sheets';
const sheetRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/sheet',
    Component: SheetList,
    feature,
    subFeature,
  },
  {
    path: '/sheet/add',
    Component: AddSheet,
    feature,
    subFeature,
  },
  {
    path: '/sheet/:id',
    Component: EditSheet,
    feature,
    subFeature,
  },
];

export default sheetRoutes;

// Core Types
import { PatternType } from 'features/appBuilder/patterns/core/types/item';

export const getPatternName = (type?: PatternType): string => {
  switch (type) {
    case 'header':
      return 'هدر';

    case 'footer':
      return 'فوتر';

    case 'content':
      return 'کانتنت';

    case 'stable':
      return 'استیبل';

    case 'profile':
      return 'پروفایل';

    case 'slider':
      return 'اسلایدر';

    case 'contentDetails':
      return 'تمپلیت کانتنت';

    case 'tagAndCategory':
      return 'موضوع و برچسب';

    case 'search':
      return 'جستجو';

    case 'form':
      return 'فرم';

    case 'group':
      return 'گروه';

    case 'table':
      return 'تیبل';

    default:
      return 'پترن';
  }
};

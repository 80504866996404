import type { FC } from 'react';

// Components
import IconButton, {
  type IconButtonProps,
} from 'core/components/base/inputs/IconButton';

// Custom Icon Components
import RequiredIcon from 'features/form/forms/components/icons/Required';

// Custom Types
interface RequiredIconButtonProps extends IconButtonProps {
  required?: boolean;
}

const RequiredIconButton: FC<RequiredIconButtonProps> = (props) => {
  const { required, sx, ...otherProps } = props;

  return (
    <IconButton
      sx={{ color: required ? 'text.primary' : 'text.disabled', ...sx }}
      {...otherProps}
    >
      <RequiredIcon />
    </IconButton>
  );
};

export default RequiredIconButton;

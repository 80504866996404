import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface InsertDriveFileIconProps extends SvgIconProps {}

const InsertDriveFileIcon: React.FC<InsertDriveFileIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm7 7V3.5L18.5 9z' />
  </IconBuilder>
);

export default InsertDriveFileIcon;

import * as React from 'react';

// Components
import ButtonLink, { ButtonLinkProps } from './ButtonLink';

interface PageHeroButtonLinkProps extends ButtonLinkProps {
  state?: any;
}

const PageHeroButtonLink: React.FC<PageHeroButtonLinkProps> = (props) => {
  const {
    children,
    state,
    color = 'info',
    variant = 'contained',
    sx,
    ...otherProps
  } = props;

  return (
    <ButtonLink
      color={color}
      variant={variant}
      state={state}
      sx={{ minWidth: '8rem', minHeight: '100%', ...sx }}
      {...otherProps}
    >
      {children}
    </ButtonLink>
  );
};

export default PageHeroButtonLink;

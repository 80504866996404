// Custom Utilities
import { generateObjectId } from 'core/utilities/helper/id';
import { getInitialCommonInputProps } from 'features/form/forms/utilities/input/initial/common';
import { genFormInputOption } from 'features/form/forms/utilities/input/initial/option';

// Custom Types
import type { FormInputInitialParams } from 'features/form/forms/utilities/input/initial/common';
import type { FormSelectInputProps } from 'features/form/forms/types/item/input/select';

interface Params extends FormInputInitialParams {}

export const genFormSelectInput = (params: Params): FormSelectInputProps => {
  const commonProps = getInitialCommonInputProps({
    ...params,
  });

  return {
    id: `draft-${generateObjectId()}`,
    data: {
      ...commonProps,
      options: [genFormInputOption(0)],
      minSelectionCount: 1,
      maxSelectionCount: 1,
      hasFieldCondition: false,
      hasSectionCondition: false,
      type: 'select',
    },
  };
};

import * as Yup from 'yup';

export const ticketTopicSchema = Yup.object().shape({
  title: Yup.string()
    .required('پر کردن عنوان موضوع الزامی است')
    .max(100, 'عنوان نمیتواند بیشتر از 100 کاراکتر باشد'),
  department: Yup.object().shape({
    title: Yup.string()
      .required('پر کردن عنوان دپارتمان الزامی است')
      .max(100, 'عنوان نمیتواند بیشتر از 100 کاراکتر باشد'),
    audienceIds: Yup.array().min(1, 'لطفا گروه مخاطبان را مشخص کنید'),
  }),
  personnel: Yup.object().shape({
    groupIds: Yup.array().min(1, 'لطفا دپارتمان را مشخص کنید'),
  }),
});

// Custom Types
import type { ProfilePatternConfigProps } from 'features/appBuilder/patterns/features/profile/types/config';

export const profilePatternConfigDefaults: ProfilePatternConfigProps = {
  isActive: true,
  avatar: {
    isActive: true,
    file: null,
    imageSize: 40,
    borderRadius: 8,
    displayType: 'default',
    fontColor: '#12252E',
    highlightColor: 'rgba(0, 0, 0, 0)',
    padding: '0px',
  },
  menuOne: {
    borderRadius: 8,
    isActive: true,
    containerId: '',
    containerTitle: '',
    padding: '0px',
    fontSize: '14',
    textDirection: 'rtl',
    textAlign: 'start',
    color: '#59666D',
    bgColor: '#ffffff',
    hoverColor: '#59666D',
    bgHoverColor: '#ffffff',
    itemCount: 20,
    activeBgColor: '',
    activeColor: '',
    itemImageSize: 24,
    subMenuBg: '',
    titleBgColor: '',
    titleColor: '',
    titleFontSize: '16',
    subMenuBgHoverColor: 'rgba(0, 0, 0, 0)',
    subMenuColor: 'rgba(0, 0, 0, 0)',
    subMenuHoverColor: 'rgba(0, 0, 0, 0)',
  },
};

// Feature Utilities
import { bakePatternCommonProps } from 'features/appBuilder/patterns/core/utilities/api/baker';

// Feature Types
import type { ContentDetailsPatternDataProps } from 'features/appBuilder/patterns/features/contentDetails/types';

/**
 * Transforms the provided content details pattern data by ensuring the `creator` field is included as a string.
 *
 * @param {ContentDetailsPatternDataProps} data - The content details pattern data to transform.
 * @returns {Omit<ContentDetailsPatternDataProps, 'creator'> & { creator: string }} The transformed content details pattern data with the `creator` field as a string.
 */
export const bakeContentDetailsPatternData = (
  data: ContentDetailsPatternDataProps
): Omit<ContentDetailsPatternDataProps, 'creator'> & { creator: string } => {
  const { config, footer, ...otherProps } = data;

  const commonProps = bakePatternCommonProps(otherProps);

  return {
    ...commonProps,
    config,
    footer,
  };
};

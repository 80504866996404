import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deepClone } from 'core/utilities/helper/helperPack';
import {
  areParentsMatch,
  sortMenus,
  updateParentIds,
} from 'features/menu/menus/utilities/helper';
import {
  findIndexById,
  forLoop,
  generateIndexMap,
  generateObjectMap,
} from 'core/utilities/helper';

// Custom Types
import type {
  MenuContainerProps,
  MenuItemProps,
} from 'features/menu/menus/types';

export type MenuActionModeType = 'DELETE' | 'SORT' | 'EDIT' | 'NORMAL';

interface StoreProps {
  originalItemsMap: Record<string, MenuItemProps>;
  originalItems: MenuItemProps[];
  current: MenuContainerProps | null;
  inEditItem: string;
  mode: MenuActionModeType;
  deleteIdsMap: Record<string, boolean>;
}

const initialState: StoreProps = {
  originalItemsMap: {},
  originalItems: [],
  current: null,
  mode: 'NORMAL',
  inEditItem: '',
  deleteIdsMap: {},
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenuContainer: (state, action: PayloadAction<MenuContainerProps>) => {
      state.current = action.payload;
      state.originalItems = action.payload.data.items;
      state.originalItemsMap = generateObjectMap(
        action.payload.data.items
      ) as Record<string, MenuItemProps>;
    },
    updateOriginalItems: (state) => {
      if (state.current) {
        state.originalItems = state.current.data.items;
        state.originalItemsMap = generateObjectMap(
          state.current.data.items
        ) as Record<string, MenuItemProps>;
      }
    },
    updateIsActive: (
      state,
      action: PayloadAction<{ menuId: string; value: boolean }>
    ) => {
      if (state.current) {
        const { menuId, value } = action.payload;
        const index = findIndexById(state.current.data.items, menuId);
        if (index > -1) state.current.data.items[index].data.isActive = value;
      }
    },
    addNewMenu: (state, action: PayloadAction<MenuItemProps>) => {
      if (state.current) {
        state.originalItemsMap[action.payload.id] = action.payload;
        state.current.data.items.push(action.payload);
        state.originalItems.push(action.payload);
      }
    },
    deleteMenu: (state, action: PayloadAction<MenuItemProps>) => {
      if (state.current) {
        const menu = action.payload;
        const parentId = menu.data.parentIds.at(-1);
        const container = state.current.data;
        // Delete Logics

        // 1- Delete Menu and All Its Children
        container.items = container.items.filter(
          (item) =>
            item.id !== menu.id && !item.data.parentIds.includes(menu.id)
        );

        // 2- Get Siblings, Sort It, Generate IndexMap.
        const siblings = generateIndexMap(
          container.items
            .filter((item) => item.data.parentIds.at(-1) === parentId)
            .sort(sortMenus)
        );

        // 3- Update Siblings SortNumber
        forLoop(container.items, (item) => {
          if (item.data.parentIds.at(-1) === parentId)
            item.data.sortNumber = siblings[item.id];
        });
      }
    },
    selectChildren: (
      state,
      action: PayloadAction<{
        selectedOpts: MenuItemProps[];
        newParentIds: string[];
      }>
    ) => {
      if (state.current) {
        const { selectedOpts, newParentIds } = action.payload;
        const container = state.current.data;
        const selectedIds = generateIndexMap(selectedOpts);
        // Select Logic

        // 1- Update SortNumber and ParentIds

        container.items.forEach((item) => {
          if (item.id in selectedIds) {
            item.data.parentIds = newParentIds;
            item.data.sortNumber = selectedIds[item.id];
          }
        });

        // 2- Resort Ex Siblings
        let sortedParentIds: any[] = [];
        const selectedOptsLength = selectedOpts.length;
        for (let i = 0; i < selectedOptsLength; i++) {
          // We Already Sorted Items on That Level so => Skip to next Iteration
          if (sortedParentIds.includes(selectedOpts[i].data.parentIds.at(-1)))
            continue;

          // 2-1- Get Ex Siblings, Sort It, Map to get Only Ids.
          const siblingsIndexMap = generateIndexMap(
            container.items
              .filter(
                (item) =>
                  item.data.parentIds.at(-1) ===
                  selectedOpts[i].data.parentIds.at(-1)
              )
              .sort(sortMenus)
          );

          // 2-2 Update SortNumber
          container.items.forEach((item) => {
            if (
              !(item.id in selectedIds) &&
              areParentsMatch(item, selectedOpts[i])
            )
              item.data.sortNumber = siblingsIndexMap[item.id];
          });
          sortedParentIds.push(selectedOpts[i].data.parentIds.at(-1));
        }

        // 3- Generate New ParentIds for Menu Childrens Recursivly
        selectedOpts.forEach((child) => {
          container.items = updateParentIds(container.items, {
            id: child.id,
            data: {
              ...child.data,
              parentIds: newParentIds,
            },
          });
        });
      }
    },
    unselectChildren: (
      state,
      action: PayloadAction<{
        unselectedOpts: MenuItemProps[];
        currentParentIds: string[];
      }>
    ) => {
      if (state.current) {
        const { unselectedOpts: list, currentParentIds } = action.payload;
        const container = state.current.data;
        const unselectedOpts = deepClone(list);
        const unselectedIdsIndexMap = generateIndexMap(unselectedOpts);
        // Unselect Logic

        // 1- Get Siblings, Sort It, Map to get Only Ids.
        const siblings = generateIndexMap(
          container.items
            .filter(
              (item) => item.data.parentIds.at(-1) === currentParentIds.at(-2)
            )
            .sort(sortMenus)
            .concat(unselectedOpts)
        );

        // 2- Update SortNumber and ParentIds
        container.items.forEach((item) => {
          // 1-1- Remove Last ParentId -> With this action, we go back one level.
          if (item.id in unselectedIdsIndexMap) item.data.parentIds.pop();

          // 1-2-Update SortNumbers
          if (item.data.parentIds.at(-1) === currentParentIds.at(-2))
            item.data.sortNumber = siblings[item.id];
        });

        // 3- Generate New ParentIds and Level for Item Childrens Recursivly
        unselectedOpts.forEach((item) => {
          // Go One Level Back
          item.data.parentIds.pop();
          // Sync Childrens With Parent Changes
          container.items = updateParentIds(container.items, item);
        });
      }
    },
    updateSortNumbers: (
      state,
      action: PayloadAction<{ items: MenuItemProps[] }>
    ) => {
      if (!state.current) return state;

      const { items } = action.payload;
      const sortedMenuIdsIndexMap = generateIndexMap(items);
      const container = state.current.data;
      forLoop(container.items, (item) => {
        if (item.id in sortedMenuIdsIndexMap)
          item.data.sortNumber = sortedMenuIdsIndexMap[item.id];
      });
    },
    updateMenu: (state, action: PayloadAction<MenuItemProps>) => {
      if (state.current) {
        const menu = action.payload;
        const container = state.current.data;
        const index = findIndexById(container.items, menu.id);
        if (index > -1) container.items[index].data = menu.data;
      }
    },
    setContainerTitle: (state, action: PayloadAction<string>) => {
      if (state.current) state.current.data.title = action.payload;
    },
    setMenuItems: (state, action: PayloadAction<MenuItemProps[]>) => {
      if (state.current) state.current.data.items = action.payload;
    },
    setInEdit: (state, action: PayloadAction<string>) => {
      state.inEditItem = action.payload;
    },
    setMenuActionMode: (state, action: PayloadAction<MenuActionModeType>) => {
      state.mode = action.payload;
    },
    toggleDeleteMenu: (state, action: PayloadAction<string>) => {
      const menuId = action.payload;
      if (menuId in state.deleteIdsMap) delete state.deleteIdsMap[menuId];
      else state.deleteIdsMap[menuId] = true;
    },
    clearDeletionIdsMap: (state) => {
      state.deleteIdsMap = {};
    },
    resetState: () => initialState,
  },
});

export const actions = menuSlice.actions;
export default menuSlice.reducer;

import type { FC } from 'react';
import type { Editor } from '@tiptap/react';

// Core Components
import IconButton from 'core/components/base/inputs/IconButton';
import FormatAlignJustifyIcon from 'core/components/icons/AlignCenter';
import FormatAlignLeftIcon from 'core/components/icons/AlignLeft';
import FormatAlignRightIcon from 'core/components/icons/AlignRight';
import FormatAlignCenterIcon from 'core/components/icons/FormatAlignCenterIcon';

// Component Types
interface EditorAlignIconButtonProps {
  editor: Editor | null;
}

const EditorAlignIconButton: FC<EditorAlignIconButtonProps> = (props) => {
  // Props
  const { editor } = props;

  return (
    <>
      {['left', 'center', 'right', 'justify'].map((opt) => (
        <IconButton
          key={'editor-align-option-to-' + opt}
          onClick={() => editor?.chain().focus().setTextAlign(opt).run()}
          color={editor?.isActive({ textAlign: opt }) ? 'info' : 'inherit'}
          size='small'
        >
          {opt === 'center' ? (
            <FormatAlignCenterIcon />
          ) : opt === 'right' ? (
            <FormatAlignRightIcon />
          ) : opt === 'justify' ? (
            <FormatAlignJustifyIcon />
          ) : (
            <FormatAlignLeftIcon />
          )}
        </IconButton>
      ))}
    </>
  );
};

export default EditorAlignIconButton;

import { memo, type FC, useMemo } from 'react';

// Custom Common Components
import ActionsBox from 'core/components/shared/Box/Actions';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Feature Components
import DeleteIconButton from 'features/form/shared/components/iconButton/Delete';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { useAppSelector } from 'core/hooks/redux';
import { useDialogContext } from 'core/store/context/DialogProvider';
import { useDeleteFormMutation } from 'features/form/forms/hooks';

// Custom Types
export interface FormItemHeaderProps {
  mode?: 'edit' | 'add';
  suffix?: string;
}

const FormItemActions: FC<FormItemHeaderProps> = (props) => {
  // Props
  const { mode = 'add', suffix = '' } = props;

  // Context
  const loading = useSelectLoading();
  const formTitle = useAppSelector((state) => state.form.data?.title || '');
  const formId = useAppSelector((state) => state.form.formId);
  const actionMode = useAppSelector((state) => state.form.actionMode);

  // Hooks
  const { onOpenDialog, onCloseDialog } = useDialogContext();
  const title = useMemo(() => {
    let actionTitle = mode === 'add' ? `ساخت فرم جدید` : `ویرایش فرم`;
    if (suffix) actionTitle += ` ${suffix}`;
    return actionTitle;
  }, [mode, suffix]);
  const { mutate: handleDeleteForm } = useDeleteFormMutation({
    autoAlert: { mode: 'delete', name: 'فرم' },
    noRevalidate: true,
    stayOnLoadingAfterMutate: true,
    redirectAfterSuccessTo: '/forms',
    onSuccess: () => onCloseDialog(),
  });

  // Utilities
  const handleOpenDeleteDialog = () => {
    onOpenDialog({
      onSubmit: () => handleDeleteForm(formId),
      title: 'آیا مطمئن هستید؟',
      description:
        'با حذف فرم، تمامی بازخوردهای ثبت شده در این فرم و همچنین اطلاعات ثبت شده در فرایند این فرم حذف خواهند شد.',
      submitTitle: 'حذف',
    });
  };

  // Render
  return (
    <ActionsBox>
      <PageTitle>{`${title} ${formTitle}`}</PageTitle>
      {formId && (
        <RowStack spacing={1}>
          <DeleteIconButton
            disabled={actionMode !== 'INITIAL' || loading}
            loading={loading && actionMode === 'DELETE'}
            onClick={handleOpenDeleteDialog}
          />
          {/* <CopyLoadingButton disabled={actionMode !== 'INITIAL' || loading} /> */}
        </RowStack>
      )}
    </ActionsBox>
  );
};

export default memo(FormItemActions, isSame);

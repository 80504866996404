import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { forLoop } from 'core/utilities/helper';
import { deepClone } from 'core/utilities/helper/helperPack';

// Custom Types
import type { ProcessFilterNameType } from '../components/details/Actions/Filter/ViewSettings/ProcessFields';
import type { ProcessProps } from 'features/form/processes/types/details';
import type {
  FeedbackInputProps,
  FeedbackOverviewDataProps,
  FeedbackProps,
} from 'features/form/feedbacks/types/detail';

interface StoreProps {
  feedbackOverview: FeedbackOverviewDataProps | null;
  columnCount: number;
  toggleMode: 'visibility' | 'width';
  hideEmpties: boolean;
  onlyRequireds: boolean;
  isPdfPreviewOpen: boolean;
  fullWidthInputs: Record<string, boolean>;
  hiddenInputs: Record<string, boolean>;
  hiddenProcesses: Record<
    string,
    Partial<Record<ProcessFilterNameType, boolean>>
  >;
  pdfValues: FeedbackProps[];
  excelValues: FeedbackProps[];
}

const initialState: StoreProps = {
  columnCount: 1,
  feedbackOverview: null,
  toggleMode: 'visibility',
  onlyRequireds: false,
  hideEmpties: false,
  isPdfPreviewOpen: false,
  hiddenProcesses: {},
  fullWidthInputs: {},
  hiddenInputs: {},
  pdfValues: [],
  excelValues: [],
};

const slice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setFeedback: (
      state,
      action: PayloadAction<FeedbackOverviewDataProps | null>
    ) => {
      if (action.payload) {
        const clone = deepClone(action.payload);

        forLoop(clone.inputs, (input) => (input.data.isHidden = false));
        forLoop(clone.sections, (section) => (section.data.isHidden = false));

        const inputs: FeedbackInputProps[] = [];
        const files: FeedbackInputProps[] = [];
        forLoop(clone.inputs, (input) =>
          input.data.type === 'file' ? files.push(input) : inputs.push(input)
        );

        state.feedbackOverview = { ...clone, inputs: [...inputs, ...files] };
      } else {
        state.feedbackOverview = null;
      }
    },
    toggleProcessItemSectionsVisibility: (
      state,
      action: PayloadAction<{
        itemId: string;
        name: ProcessFilterNameType;
      }>
    ) => {
      if (state?.feedbackOverview && state.feedbackOverview.process) {
        const { itemId, name } = action.payload;
        const isHidden = state.hiddenProcesses?.[itemId]?.[name] || false;
        if (isHidden) delete state.hiddenProcesses[itemId][name];
        else {
          if (!state.hiddenProcesses[itemId])
            state.hiddenProcesses[itemId] = {};
          state.hiddenProcesses[itemId][name] = !isHidden;
        }
      }
    },
    toggleSectionVisibility: (state, action: PayloadAction<string>) => {
      if (state?.feedbackOverview) {
        const sectionId = action.payload;
        const isHidden = sectionId in state.hiddenInputs || false;
        const sectionInputIds: string[] = [];
        forLoop(state.feedbackOverview.inputs, (input) => {
          if (input.data.sectionId === sectionId)
            sectionInputIds.push(input.id);
        });

        // Toggle Section Visibility
        if (isHidden) delete state.hiddenInputs[sectionId];
        else state.hiddenInputs[sectionId] = true;
      }
    },
    toggleInputVisibility: (state, action: PayloadAction<string>) => {
      if (state?.feedbackOverview) {
        const inputId = action.payload;
        const isHidden = inputId in state.hiddenInputs || false;
        if (isHidden) delete state.hiddenInputs[inputId];
        else state.hiddenInputs[inputId] = true;
      }
    },
    toggleInputFullWidth: (state, action: PayloadAction<string>) => {
      if (state?.feedbackOverview) {
        const inputId = action.payload;
        const isFullWidth = inputId in state.fullWidthInputs || false;
        if (isFullWidth) delete state.fullWidthInputs[inputId];
        else state.fullWidthInputs[inputId] = true;
      }
    },
    setFullWidthInputs: (
      state,
      action: PayloadAction<Record<string, boolean>>
    ) => {
      state.fullWidthInputs = action.payload;
    },
    setHiddenInputs: (
      state,
      action: PayloadAction<Record<string, boolean>>
    ) => {
      state.hiddenInputs = action.payload;
    },
    setToggleMode: (state, action: PayloadAction<'visibility' | 'width'>) => {
      state.toggleMode = action.payload;
    },
    setColumnCount: (state, action: PayloadAction<number>) => {
      state.columnCount = action.payload;
    },
    setIsFeedbackPdfPreviewOpen: (state, action: PayloadAction<boolean>) => {
      state.isPdfPreviewOpen = action.payload;
    },
    toggleHideEmpties: (
      state,
      action: PayloadAction<{
        inputIndex?: number;
        sectionIndex?: number;
        value: boolean;
      }>
    ) => {
      if (state.feedbackOverview) {
        const { inputIndex, sectionIndex, value } = action.payload;

        if (!inputIndex && !sectionIndex) state.hideEmpties = value;
        if (typeof inputIndex === 'number')
          state.feedbackOverview.inputs[inputIndex].data.isHidden = value;
        if (typeof sectionIndex === 'number')
          state.feedbackOverview.sections[sectionIndex].data.isHidden = value;
      }
    },
    setFeedbackPdfValues: (state, action: PayloadAction<FeedbackProps[]>) => {
      state.pdfValues = action.payload;
    },
    setFeedbackExcelValues: (state, action: PayloadAction<FeedbackProps[]>) => {
      state.excelValues = action.payload;
    },
    toggleOnlyRequireds: (state, action: PayloadAction<boolean>) => {
      state.onlyRequireds = action.payload;
    },
    resetState: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const actions = slice.actions;

export default slice.reducer;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface ArrowDropDownIconProps extends SvgIconProps {}
const ArrowDropDownIcon: React.FC<ArrowDropDownIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M4.59,8.91l6.56,7.65a1.1,1.1,0,0,0,.38.29,1.15,1.15,0,0,0,.94,0,1.1,1.1,0,0,0,.38-.29l6.56-7.65a1.13,1.13,0,0,0-.86-1.86H5.45A1.13,1.13,0,0,0,4.59,8.91Z' />
  </IconBuilder>
);

export default ArrowDropDownIcon;

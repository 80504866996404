import { useCallback, useState, useEffect, type FC } from 'react';

// Custom Core Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import Box from 'core/components/base/layout/Box';
import TextFieldNumber from 'core/components/shared/TextField/number';

// Custom Components
import PatternToolbarIconButton from './Icon';

// Custom Icon Components
import AddColIcon from 'core/components/icons/AddCol';
import ConfigIcon from 'core/components/icons/Config';
import DeleteIcon from 'core/components/icons/DeleteIcon';
import DuplicateIcon from 'core/components/icons/Duplicate';
import CellWidthIcon from 'core/components/icons/CellWidth';

// Custom Hooks
import { useAppDispatch, useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import { updateCellsStyles } from 'features/appBuilder/patterns/features/table/store';

// Custom Validation
import { TABLE_COL_LIMIT } from 'features/appBuilder/patterns/validations/table';

// Custom Types
import type { ToolbarMethods } from '.';
export interface TablePatternColToolbarProps extends Partial<ToolbarMethods> {}

const TablePatternColToolbar: FC<TablePatternColToolbarProps> = (props) => {
  // Props
  const { onAddCol, onDuplicateCol, onDeleteCol, onStylesOpen } = props;

  // States
  const [width, setWidth] = useState<number>(120);

  // Hooks
  const dispatch = useAppDispatch();
  const { selection, colCount } = useAppSelector((state) => state.table.data);

  const handleWidthUpdate = useCallback(() => {
    if (selection.length > 0) {
      const widthToSet = selection[0].styles.width;

      if (width !== widthToSet) setWidth(widthToSet);
    }
  }, [selection, width]);

  useEffect(() => {
    handleWidthUpdate();
  }, [selection, handleWidthUpdate]);

  const updateWidthInState = () => {
    if (selection.length > 0) {
      const stylesToSet = selection[0].styles;
      const widthToSet = !width || width < 120 ? 120 : width;
      dispatch(
        updateCellsStyles({
          cells: selection,
          styles: {
            ...stylesToSet,
            width: widthToSet,
          },
        })
      );
      if (!width || width < 120) setWidth(widthToSet);
    }
  };

  const items = [
    {
      title: 'duplicate',
      Icon: <DuplicateIcon />,
      handler: onDuplicateCol,
      disabled: colCount >= TABLE_COL_LIMIT,
    },
    {
      title: 'addCol',
      Icon: <AddColIcon />,
      handler: onAddCol,
      disabled: colCount >= TABLE_COL_LIMIT,
    },
    { title: 'config', Icon: <ConfigIcon />, handler: onStylesOpen },
    {
      title: 'delete',
      Icon: <DeleteIcon />,
      handler: onDeleteCol,
      disabled: colCount < 2,
    },
  ];

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {items.map(({ title, handler, Icon, ...otherProps }, index) => (
        <PatternToolbarIconButton
          key={title + index}
          onClick={handler}
          {...otherProps}
        >
          {Icon}
        </PatternToolbarIconButton>
      ))}
      <Box sx={{ display: 'flex' }}>
        <PatternToolbarIconButton
          sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        >
          <CellWidthIcon />
        </PatternToolbarIconButton>
        <TextFieldNumber
          dir='ltr'
          sx={{
            '.MuiInputBase-input': {
              paddingY: '12px',
            },
            width: '6rem',
          }}
          value={width}
          onChange={(e) => {
            const value = e.target.value;

            setWidth(parseInt(value));
          }}
          onBlur={updateWidthInState}
          InputProps={{
            sx: {
              borderRadius: '0.5rem',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
            endAdornment: (
              <>
                <BodyTwo sx={{ ml: '0.5rem' }}>px</BodyTwo>
              </>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default TablePatternColToolbar;

import { type FC } from 'react';

// Core Components
import LoadingButton, {
  type LoadingButtonProps,
} from 'core/components/base/inputs/LoadingButton';

// Custom Types
export interface SubmitButtonProps extends LoadingButtonProps {}

const SubmitButton: FC<SubmitButtonProps> = (props) => {
  // Props
  const { children, loadingPosition = 'end', sx, ...otherProps } = props;

  return (
    <LoadingButton
      loadingPosition={loadingPosition}
      sx={{
        bgcolor: 'white',
        color: 'info.main',
        width: '6rem',
        ':hover': { bgcolor: 'rgba(255,255,255, 0.8)' },
        ...sx,
      }}
      {...otherProps}
    >
      ذخیره
    </LoadingButton>
  );
};

export default SubmitButton;

import * as React from 'react';
import { Link } from 'react-router-dom';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';

// Core Components
import Checkbox from 'core/components/base/inputs/Checkbox';
import TableRow from 'core/components/base/display/TableRow';
import TableCell from 'core/components/base/display/TableCell';

// Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import PreviewSmall from 'core/components/shared/Files/PreviewSmall';

// Feature Components
import FileDetailDialog from 'features/file/files/components/shared/Dialog/FileDetailDialog';

// Custom Utilities
import { getLocaleDateAndTime } from 'core/utilities/helper/date';
import {
  convertBytes,
  getFileLocationLabel,
} from 'features/file/files/utilities/file';

// Custom Types
import type { FileProps } from 'features/file/files/types';

interface FileRowProps {
  file: FileProps;
  isFileSelected: boolean;
  titleClickBehavior: 'link' | 'popup';
  onSelectFile: (file: FileProps, selected: boolean) => void;
  onFileUpdated?: (file: FileProps) => void;
}

const FileRow: React.FC<FileRowProps> = (props) => {
  // Props
  const {
    file,
    isFileSelected,
    titleClickBehavior,
    onSelectFile,
    onFileUpdated,
  } = props;

  // States
  const showFileDetailsDialog = useBoolean();

  // Utilities
  const handleFileUpdated = (file: FileProps) => {
    if (onFileUpdated) onFileUpdated(file);
    showFileDetailsDialog.setFalse();
  };

  // Render
  return (
    <>
      <TableRow
        sx={{
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: 'divider',
        }}
      >
        <TableCell sx={{ paddingX: 0 }}>
          <RowStack spacing={0} alignItems='center'>
            <Checkbox
              checked={isFileSelected}
              onChange={() => onSelectFile(file, !isFileSelected)}
              size='small'
            />
            <PreviewSmall fileData={file} />
          </RowStack>
        </TableCell>
        <TableCell
          align='center'
          dir='ltr'
          sx={{ maxWidth: '7rem', whiteSpace: 'wrap', lineBreak: 'anywhere' }}
        >
          <BodyTwo
            {...(titleClickBehavior === 'link'
              ? {
                  to: `/files/${file.id}`,
                  component: Link,
                }
              : {
                  onClick: showFileDetailsDialog.setTrue,
                })}
            sx={{
              color: 'info.main',
              textDecoration: 'none',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              cursor: 'pointer',
              WebkitLineClamp: 3,
            }}
          >
            {file.data.title}
          </BodyTwo>
        </TableCell>
        <TableCell align='center'>{`${file.data.user.data.firstName} ${file.data.user.data.lastName}`}</TableCell>
        <TableCell align='center' dir='ltr'>
          {convertBytes(file.data.size, 0).toPersian()}
        </TableCell>
        <TableCell align='center'>
          {getFileLocationLabel(file.data.location) || '-'}
        </TableCell>
        <TableCell align='center'>{file.data.alt || '-'}</TableCell>
        <TableCell align='center' dir='ltr'>
          {getLocaleDateAndTime(file.data.createdAt, '-')}
        </TableCell>
      </TableRow>
      {titleClickBehavior === 'popup' && showFileDetailsDialog.state && (
        <FileDetailDialog
          fileId={file.id}
          onFileUpdated={handleFileUpdated}
          onClose={showFileDetailsDialog.setFalse}
        />
      )}
    </>
  );
};

export default FileRow;

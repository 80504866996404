import type { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

// Custom Hooks
import useUnMount from 'core/hooks/useUnMount';

// Feature Components
import ProcessDetails from 'features/form/processes/components/details/Details';

// Feature Utilities
import { useFormDetailsSummaryDoc } from 'features/form/forms/hooks';
import { useProcessDoc } from 'features/form/processes/hooks';
import { resetProcessState } from 'features/form/processes/store/actions';

// Context
import DocProvider from 'core/hooks/api/useDoc/Context';
import FormDetailsSummaryProvider from 'features/form/forms/contexts/FormDetailsSummary';

// Custom Types
import type { ProcessProps } from 'features/form/processes/types/details';

export interface EditProcessProps {}

const EditProcess: FC<EditProcessProps> = (props) => {
  // Hooks
  const methods = useForm<ProcessProps>({ mode: 'onTouched' });
  const processMethods = useProcessDoc({
    onFetch: methods.reset,
  });
  const formId = processMethods?.data?.data?.formId || '';
  const { data: formDetails } = useFormDetailsSummaryDoc({
    id: formId,
    enabled: typeof formId === 'string' && formId.length > 0,
    alertOnFetchError: { message: 'دریافت اطلاعات فرم با خطا مواجه شد.' },
  });

  useUnMount(() => resetProcessState());

  // Render
  return (
    <FormProvider {...methods}>
      <DocProvider {...processMethods}>
        <FormDetailsSummaryProvider data={formDetails}>
          <ProcessDetails mode='edit' />
        </FormDetailsSummaryProvider>
      </DocProvider>
    </FormProvider>
  );
};

export default EditProcess;

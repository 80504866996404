import { type FC } from 'react';

// Layouts
import TablePatternConfig from 'features/appBuilder/patterns/features/table/components/Config';
import TablePatternBody from 'features/appBuilder/patterns/features/table/components/Body';

// Custom Core Component
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Custom Hooks
import useUnMount from 'core/hooks/useUnMount';

// Custom Utilities
import { resetTable } from 'features/appBuilder/patterns/features/table/store';

// Custom Types
interface AddTableProps {}

const EditTable: FC<AddTableProps> = () => {
  useUnMount(() => resetTable());

  return (
    <>
      <ColumnStack>
        <TablePatternConfig />
        <TablePatternBody />
      </ColumnStack>
    </>
  );
};

export default EditTable;

import { useState, type FC, MouseEvent } from 'react';

// Custom Core Components
import Box from 'core/components/base/layout/Box';

// Cusotm Common Components
import TablePatternCellToolbar from './Cell';
import TablePatternMultiCellToolbar from './MultiCell';
import TablePatternRowToolbar from './Row';
import TablePatternColToolbar from './Col';
import TablePatternAllCellsToolbar from './All';

// Custom Hooks
import { useAppDispatch, useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import {
  addColumn,
  addRow,
  deleteColumn,
  deleteRow,
  mergeCell,
  resetCells,
  unmergeCells,
  updateCell,
} from 'features/appBuilder/patterns/features/table/store';

// Custom Types
import type {
  TableCellFileProps,
  TableCellRefProps,
  TableCellStringProps,
} from 'features/appBuilder/patterns/features/table/types/cell';
import CellCommonStylesMenu from '../CommonStyles';
import { getMergeAndUnmergeRange } from 'features/appBuilder/patterns/features/table/utilities';
export type ToolbarMode = 'cell' | 'multi-cell' | 'row' | 'col' | 'all' | '';
export interface ToolbarMethods {
  // Common
  onResetCells: () => void;
  onUpdateStyles: () => void;
  onStylesOpen: (e: MouseEvent<HTMLButtonElement>) => void;
  onStylesClose: () => void;
  // Cell
  onInsertRef: (
    data: Pick<TableCellRefProps, 'sheetId' | 'sheetTitle' | 'columnKey'>
  ) => void;
  onInsertFile: (data: Pick<TableCellFileProps, 'file'>) => void;
  onInsertText: (data: Pick<TableCellStringProps, 'value'>) => void;
  // Multi-Cell
  onMergeCells: () => void;
  // Row
  onAddRow: () => void;
  onDuplicateRow: () => void;
  onDeleteRow: () => void;
  // Col
  onAddCol: () => void;
  onDuplicateCol: () => void;
  onDeleteCol: () => void;
}
export interface TablePatternToolbarProps {}

const TablePatternToolbar: FC<TablePatternToolbarProps> = (props) => {
  // States
  const [stylesAnchorEl, setStylesAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const stylesOpen = Boolean(stylesAnchorEl);

  // Hooks
  const dispatch = useAppDispatch();
  const { selection, toolbarMode, rows } = useAppSelector(
    (state) => state.table.data
  );

  // Utilities
  // * Utilities in "CELL" toolbar mode
  const handleInsertRef = (
    data: Pick<TableCellRefProps, 'sheetId' | 'sheetTitle' | 'columnKey'>
  ) => {
    if (selection.length === 1) {
      const cell = selection[0];
      dispatch(updateCell({ ...cell, ...data, type: 'ref' }));
    }
  };

  const handleInsertFile = (data: Pick<TableCellFileProps, 'file'>) => {
    if (selection.length === 1) {
      const cell = selection[0];
      dispatch(updateCell({ ...cell, ...data, type: 'file' }));
    }
  };

  const handleInsertText = (data: Pick<TableCellStringProps, 'value'>) => {
    if (selection.length === 1) {
      const cell = selection[0];
      dispatch(updateCell({ ...cell, ...data, type: 'string' }));
    }
  };

  // * Utilities in "MULTI-CELL" toolbar mode
  const handleMergeCells = () => {
    const { cellsToMerge, cellsToUnmerge } = getMergeAndUnmergeRange(selection);

    dispatch(mergeCell(cellsToMerge));
    dispatch(unmergeCells(cellsToUnmerge));
  };

  // * Utilities in "ROW" toolbar mode
  const handleAddRow = () => {
    if (
      selection.length > 0 &&
      selection.every((cell) => cell.row === selection[0].row)
    ) {
      const rowNum = selection[0].row;
      dispatch(addRow({ index: rowNum }));
    }
  };

  const handleDuplicateRow = () => {
    if (
      selection.length > 0 &&
      selection.every((cell) => cell.row === selection[0].row)
    ) {
      const rowNum = selection[0].row;

      if (rows.length > rowNum)
        dispatch(addRow({ index: rowNum, row: rows[rowNum] }));
    }
  };

  const handleDeleteRow = () => {
    if (
      selection.length > 0 &&
      selection.every((cell) => cell.row === selection[0].row)
    ) {
      const rowNum = selection[0].row;
      dispatch(deleteRow([rowNum]));
    }
  };

  // * Utilities in "COLUMN" toolbar mode
  const handleAddCol = () => {
    if (
      selection.length > 0 &&
      selection.every((cell) => cell.col === selection[0].col)
    ) {
      const colNum = selection[0].col;
      dispatch(addColumn({ index: colNum }));
    }
  };

  const handleDuplicateCol = () => {
    if (
      selection.length > 0 &&
      selection.every((cell) => cell.col === selection[0].col)
    ) {
      const colNum = selection[0].col;
      dispatch(addColumn({ index: colNum, duplicate: true }));
    }
  };

  const handleDeleteCol = () => {
    if (
      selection.length > 0 &&
      selection.every((cell) => cell.col === selection[0].col)
    ) {
      const colNum = selection[0].col;
      dispatch(deleteColumn(colNum));
    }
  };

  // * Common Utilities
  const handleCellsReset = () => {
    dispatch(resetCells(selection));
  };

  const handleCellsStyles = () => {
    // dispatch(updateCellsStyles({ cells: selection, styles: newStyles }));
  };

  const handleStylesClick = (event: MouseEvent<HTMLButtonElement>) => {
    setStylesAnchorEl(event.currentTarget);
  };

  const handleStylesClose = () => {
    setStylesAnchorEl(null);
  };

  const methods: ToolbarMethods = {
    // Common
    onResetCells: handleCellsReset,
    onUpdateStyles: handleCellsStyles,
    onStylesOpen: handleStylesClick,
    onStylesClose: handleStylesClose,
    // CELL
    onInsertRef: handleInsertRef,
    onInsertFile: handleInsertFile,
    onInsertText: handleInsertText,
    // MULTICELL
    onMergeCells: handleMergeCells,
    // ROW
    onAddRow: handleAddRow,
    onDuplicateRow: handleDuplicateRow,
    onDeleteRow: handleDeleteRow,
    // COL
    onAddCol: handleAddCol,
    onDuplicateCol: handleDuplicateCol,
    onDeleteCol: handleDeleteCol,
  };

  return (
    <Box>
      {toolbarMode === 'all' ? (
        <TablePatternAllCellsToolbar {...methods} />
      ) : toolbarMode === 'col' ? (
        <TablePatternColToolbar {...methods} />
      ) : toolbarMode === 'row' ? (
        <TablePatternRowToolbar {...methods} />
      ) : toolbarMode === 'multi-cell' ? (
        <TablePatternMultiCellToolbar {...methods} />
      ) : (
        <TablePatternCellToolbar {...methods} />
      )}
      <CellCommonStylesMenu
        open={stylesOpen}
        anchorEl={stylesAnchorEl}
        onMenuClose={handleStylesClose}
      />
    </Box>
  );
};

export default TablePatternToolbar;

import * as React from 'react';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';

// Custom Core Components
import Grid2 from 'core/components/base/layout/Grid2';
import TextField from 'core/components/base/inputs/TextField';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import FileUploader from 'core/components/shared/Input/NewFileUploader';

// Custom Feature Components
import SubmitButton from 'features/menu/menus/components/details/MenuItem/SubmitButton';
import CancelButton from 'features/menu/menus/components/details/MenuItem/CancelButton';
import SubMenuSelect from 'features/menu/menus/components/details/MenuItem/SubMenuSelect';

// Custom Utilities
import {
  selectChildren,
  setInEdit,
  setMenuActionMode,
  unSelectChildren,
  updateMenu,
} from 'core/store/slices/feature/menu';
import {
  getErrorAndHelperText,
  getFileSource,
  isSame,
} from 'core/utilities/helper/helperPack';

// Context
import { useMenuItemContext } from 'core/store/context/feature/menu/MenuItem';

// Custom Types
import type { MenuItemProps } from 'features/menu/menus/types';
import type { MenuItemFormProps } from '.';

interface EditProps {
  childrens: MenuItemProps[];
  mode: 'edit' | 'preview' | null;
  onToggle: (value?: 'edit' | 'preview' | undefined) => void;
  onSetShowItems: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditMenu: React.FC<EditProps> = (props) => {
  // Props
  const { childrens, mode, onToggle, onSetShowItems } = props;
  // Context
  const menu = useMenuItemContext();
  const { control, setValue, handleSubmit, reset } =
    useFormContext<MenuItemFormProps>();

  // Utilities
  const handleCancel = () => {
    onToggle('preview');
    setTimeout(() => reset(), 600);
    setMenuActionMode('NORMAL');
    setInEdit('');
  };

  const onSubmit: SubmitHandler<MenuItemFormProps> = (data) => {
    const {
      selectableOpts = [],
      selectedOpts = [],
      unselectedOpts = [],
      ...otherProps
    } = data;

    const updatedMenu = {
      ...otherProps,
      hasSubItems: selectedOpts.length > 0,
    };

    if (!isSame(menu.data, updatedMenu)) updateMenu(menu.id, updatedMenu);

    if (unselectedOpts.length > 0)
      unSelectChildren(unselectedOpts, [...menu.data.parentIds, menu.id]);

    if (selectedOpts.length > 0)
      selectChildren(selectedOpts, [...menu.data.parentIds, menu.id]);

    if (unselectedOpts.length > 0 && selectedOpts.length === 0)
      onSetShowItems(false);

    setValue('unselectedOpts', []);
    onToggle('preview');
    setMenuActionMode('NORMAL');
    setInEdit('');
    reset(updatedMenu);
  };

  // Render
  return (
    <Grid2
      component='form'
      onSubmit={handleSubmit(onSubmit)}
      container
      spacing='2.25rem'
    >
      <Grid2
        xs={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <InputWrapper label='عنوان'>
          <Controller
            control={control}
            name='title'
            render={({ field, fieldState }) => (
              <TextField {...getErrorAndHelperText(fieldState)} {...field} />
            )}
          />
        </InputWrapper>
        <InputWrapper label='آدرس'>
          <Controller
            control={control}
            name='link'
            render={({ field }) => <TextField dir='ltr' {...field} />}
          />
        </InputWrapper>
        <InputWrapper label='انتخاب زیرمنو'>
          <SubMenuSelect
            childrens={childrens}
            mode={mode}
            onToggleMode={onToggle}
          />
        </InputWrapper>
      </Grid2>
      <Grid2
        xs={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          alignItems: 'center',
        }}
      >
        <Controller
          control={control}
          name='image'
          render={({ field }) => (
            <FileUploader
              location='menu'
              onUploadRemove={() => field.onChange(null)}
              onUploadChange={(file) => field.onChange(file ? file : null)}
              previewProps={{
                previewSrc: field.value ? getFileSource(field.value) : '',
              }}
              sx={{
                minHeight: 183,
                width: 300,
              }}
            />
          )}
        />
      </Grid2>
      <Grid2
        xs={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '1rem',
        }}
      >
        <Controller
          control={control}
          name='description'
          render={({ field }) => (
            <TextField
              placeholder='توضیحات منو'
              multiline
              fullWidth
              minRows={6.2}
              maxRows={6.2}
              {...field}
            />
          )}
        />
        <RowStack justifyContent='end'>
          <CancelButton onCancel={handleCancel} />
          <SubmitButton />
        </RowStack>
      </Grid2>
    </Grid2>
  );
};

export default React.memo(EditMenu, (prev, next) =>
  isSame([prev.childrens, prev.mode], [next.childrens, next.mode])
);

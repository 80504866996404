import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface CheckIconProps extends SvgIconProps {}

const CheckIcon: React.FC<CheckIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M9.09091 15.4925L5.27273 11.7313L4 12.9851L9.09091 18L20 7.25373L18.7273 6L9.09091 15.4925Z' />
  </IconBuilder>
);

export default CheckIcon;

import * as React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// HOC
import WithAuth from 'core/components/shared/HOC/WithAuth';

// Custom Hooks
import useSelection from 'core/hooks/common/useSelection';

// Custom Core Components
import IconButton from 'core/components/base/inputs/IconButton';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import DataGrid from 'core/components/shared/DataGrid';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import PageHeroButtonLink from 'core/components/shared/Button/PageHeroButtonLink';

// Custom Icons
import DeleteIcon from 'core/components/icons/DeleteIcon';

// Custom Utilities
import {
  useAudienceFoldersDoc,
  useDeleteAudienceFoldersMutation,
  useEditAudienceFolderMutation,
} from 'features/audience/folders/hooks';
import {
  getAudienceFolderRows,
  getAudienceFoldersColumns,
} from 'features/audience/folders/utilities/data';

// Context
import DocsProvider from 'core/hooks/api/useDocs/Context';
import SelectionProvider from 'core/store/context/common/UseSelection';
import { useDialogContext } from 'core/store/context/DialogProvider';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';

interface AudienceFoldersPageProps extends WithAuthProps {}

const filePath = 'components/pages/Audience/AudienceFolders';
const AudienceFolders: React.FC<AudienceFoldersPageProps> = (props) => {
  // Context
  const loading = useSelectLoading();
  const { onOpenDialog, onCloseDialog } = useDialogContext();

  // Hooks
  const selectionMethods = useSelection();
  const { selections, onSelectionsChange, onClearSelections } =
    selectionMethods;
  const audienceFoldersMethods = useAudienceFoldersDoc({
    alertOnFetchEmptyList: { message: 'تا کنون پوشه‌ای ایجاد نشده است.' },
    alertOnFetchError: { message: 'دریافت پوشه‌ها با خطا مواجه شد' },
  });
  const { list: folders } = audienceFoldersMethods.data;

  const { mutate: handleEditFolder } = useEditAudienceFolderMutation({
    autoAlert: { mode: 'edit', name: 'پوشه' },
  });
  const { mutate: handleDeleteFolders } = useDeleteAudienceFoldersMutation({
    autoAlert: { mode: 'delete', name: 'پوشه‌های مورد نظر' },
    onSuccess: () => {
      onClearSelections();
      onCloseDialog();
    },
  });

  // Utilities
  const handleToggleStatus = async (folderId: string) => {
    const folderIndex = folders.findIndex((folder) => folder.id === folderId);
    if (folderIndex === -1) return;
    const folder = cloneDeep(folders[folderIndex]);
    folder.data.isActive = !folder.data.isActive;
    handleEditFolder({ id: folder.id, data: folder.data });
  };

  const handleOpenDeleteDialog = () => {
    onOpenDialog({
      title: `آیا از حذف ${
        selections.length === 1 ? 'پوشه' : 'پوشه‌های'
      } مورد نظر مطمئن هستید؟`,
      onSubmit: () => handleDeleteFolders(selections),
    });
  };

  const rows = React.useMemo(() => getAudienceFolderRows(folders), [folders]);
  const columns = React.useMemo(
    () => getAudienceFoldersColumns(loading, handleToggleStatus),
    [loading, handleToggleStatus]
  );

  // Render
  return (
    <SelectionProvider {...selectionMethods}>
      <DocsProvider {...audienceFoldersMethods}>
        <PageHeadConfigs pageTitle='پوشه‌ها' />
        <PageHeroBox>
          <HeadingSix>پوشه‌ها</HeadingSix>
          <RowStack alignItems='center'>
            {selections.length > 0 && (
              <RowStack sx={{ alignItems: 'center' }} spacing={1}>
                <IconButton
                  disabled={selections.length === 0}
                  onClick={handleOpenDeleteDialog}
                >
                  <DeleteIcon color='error' />
                </IconButton>
                <BodyTwo>{selections.length?.toPersian()} انتخاب شده</BodyTwo>
              </RowStack>
            )}
            <PageHeroButtonLink
              variant='outlined'
              disabled={selections.length > 1 || loading}
              href={
                selections.length === 0
                  ? `/audience-folders/add`
                  : `/audience-folders/edit`
              }
              state={
                selections.length === 1
                  ? {
                      folderId: selections[0],
                    }
                  : undefined
              }
            >
              {selections.length === 0 ? 'پوشه جدید' : 'ویرایش'}
            </PageHeroButtonLink>
          </RowStack>
        </PageHeroBox>
        <DataGrid
          disabled={loading}
          selections={selections}
          selectionLabel='پوشه'
          onSelectionChange={onSelectionsChange}
          columns={columns}
          rows={rows}
        />
      </DocsProvider>
    </SelectionProvider>
  );
};

export default WithAuth(AudienceFolders, { filePath });

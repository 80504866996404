import { useState, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Custom Hooks
import useSummaryApi from 'core/hooks/api/useSummary';

// Core Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import Box from 'core/components/base/layout/Box';
import KeyboardArrowDownIcon from 'core/components/icons/ArrowDown';
import Menu from 'core/components/base/navigation/Menu';
import MenuItem from 'core/components/base/navigation/MenuItem';

// Feature Utilities
import { getSkinsList } from 'features/appBuilder/skins/utilities/api';

// Feature Types
import { ContentDetailsProps } from 'features/content/contents/types/details';
import { skinsQueryKey } from 'features/appBuilder/skins/hooks';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Component Types
export interface ContentDetailsActionsSkinProps {}

const ContentDetailsActionsSkin: FC<ContentDetailsActionsSkinProps> = (
  props
) => {
  // Hooks
  const { control, setValue } = useFormContext<ContentDetailsProps>();
  const { data: list } = useSummaryApi(getSkinsList, skinsQueryKey);
  const loading = useSelectLoading();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Utilities
  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Render
  return (
    <Controller
      control={control}
      name='data.skinId'
      defaultValue=''
      render={({ field: { value: skinId }, fieldState }) => (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              minWidth: 'fit-content',
              border: '2px solid',
              borderColor: fieldState.error ? 'error.light' : 'transparent',
              backgroundColor: 'background.paper',
              opacity: loading ? 0.6 : 1,
              cursor: loading ? 'auto' : 'pointer',
              p: '0.5rem',
              height: '40px',
              borderRadius: '0.5rem',
              ':hover': {
                opacity: '0.8',
              },
            }}
            onClick={handleOpen}
          >
            <BodyTwo noWrap color='text.disabled'>
              اسکین{skinId ? '' : '*'}:
            </BodyTwo>
            <Controller
              control={control}
              name='data.skinTitle'
              render={({ field: { value } }) => (
                <BodyOne sx={{ p: '0 0.5rem 0 0.25rem' }}>
                  {!value
                    ? 'انتخاب'
                    : value.length <= 8
                    ? value
                    : value.slice(0, 5) + '...'}
                </BodyOne>
              )}
            />

            <KeyboardArrowDownIcon />
          </Box>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            slotProps={{
              paper: {
                sx: {
                  boxShadow: 'none',
                  mt: 1,
                  border: '1px solid',
                  borderColor: 'divider',
                  width: '150px',
                },
              },
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              sx: {
                p: 0,
              },
            }}
          >
            {list?.map((skinItem) => (
              <MenuItem
                key={skinItem.id + skinItem.data.title}
                sx={{ whiteSpace: 'normal' }}
                onClick={() => {
                  setValue('data.skinId', skinItem.id);
                  setValue('data.skinTitle', skinItem.data.title);
                  handleClose();
                }}
                selected={skinId === skinItem.id}
              >
                {skinItem.data.title}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    />
  );
};

export default ContentDetailsActionsSkin;

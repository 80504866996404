// Custom Icon Components
import DataIcon from 'core/components/icons/Data';
import FeedOutlineIcon from 'core/components/icons/FeedOutline';
import FolderIcon from 'core/components/icons/Folder';
import HotCoffeeIcon from 'core/components/icons/HotCoffee';
import LayoutIcon from 'core/components/icons/Layout';
import ManagerOutlinedIcon from 'core/components/icons/Manager';
import PenToolIcon from 'core/components/icons/PenTool';
import PersonOutlineIcon from 'core/components/icons/PersonOutlined';
import TicketIcon from 'core/components/icons/Ticket';

// Custom Types
import type { FeatureType } from 'core/types/feature/FeatureType';

const featureIconMap: { [key in FeatureType]?: JSX.Element } = {
  content: <PenToolIcon />,
  file: <FolderIcon />,
  form: <FeedOutlineIcon />,
  manager: <ManagerOutlinedIcon />,
  audience: <PersonOutlineIcon />,
  menu: <HotCoffeeIcon />,
  banner: <HotCoffeeIcon />,
  template: <LayoutIcon />,
  data: <DataIcon />,
  ticket: <TicketIcon />,
};

export const getFeatureIcon = (feature: FeatureType): JSX.Element => {
  return featureIconMap[feature] || <PenToolIcon />;
};

import * as Yup from 'yup';

// Schemas
import {
  passwordRepeatSchema,
  passwordSchema,
} from 'core/utilities/validationSchema/common/Schemas';

const PasswordAndRepeatValidationSchema = Yup.object().shape({
  password: passwordSchema,
  passwordRepeat: passwordRepeatSchema,
});

export default PasswordAndRepeatValidationSchema;

import * as React from 'react';

// Custom Common Components
import CheckboxWithLabel from 'core/components/shared/Checkbox/CheckboxWithLabel';

// Custom Utilities
import {
  toggleFeedbackOnlyRequireds,
  useSelectFeedbackOnlyRequireds,
} from 'features/form/feedbacks/store';

// Custom Types
interface OnlyRequiredCheckboxProps {}

const OnlyRequiredCheckbox: React.FC<OnlyRequiredCheckboxProps> = (props) => {
  // Context
  const showOnlyRequireds = useSelectFeedbackOnlyRequireds();

  // Utilities
  const handleChange = (checked: boolean) => {
    toggleFeedbackOnlyRequireds(checked);
  };

  return (
    <CheckboxWithLabel
      checkboxProps={{
        checked: showOnlyRequireds,
        onChange: (e, checked) => handleChange(checked),
      }}
      label='نمایش فیلدهای ضروری'
    />
  );
};

export default OnlyRequiredCheckbox;

import { useState, type FC } from 'react';

// Core Components
import Menu from 'core/components/base/navigation/Menu';
import LoadingButton from 'core/components/base/inputs/LoadingButton';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import Upload from 'features/appBuilder/patterns/features/table/components/Toolbar/Menu/Upload';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { MenuProps } from 'core/components/base/navigation/Menu';
import type { FileProps } from 'features/file/files/types';
export interface FileMenuProps extends MenuProps {
  defaultFile?: FileProps;
  onSubmitFile?: (file: FileProps) => void;
  onMenuClose?: () => void;
}

const FileMenu: FC<FileMenuProps> = (props) => {
  // Props
  const {
    id = 'file-menu',
    MenuListProps,
    onSubmitFile,
    defaultFile,
    onMenuClose,
    ...otherProps
  } = props;

  // States
  const [file, setFile] = useState<FileProps | null>(null);

  // Context
  const loading = useSelectLoading();

  // Utilities
  const handleSubmit = async () => {
    if (file && onSubmitFile) onSubmitFile(file);
    if (onMenuClose) onMenuClose();
  };

  return (
    <Menu
      id={id}
      onClose={loading ? undefined : onMenuClose}
      slotProps={{
        paper: {
          sx: {
            borderRadius: '1rem',
            boxShadow: 3,
            padding: '1rem',
          },
        },
      }}
      MenuListProps={{
        'aria-labelledby': 'file-button',
        sx: { width: '12rem', p: 0 },
        ...MenuListProps,
      }}
      {...otherProps}
    >
      <ColumnStack>
        <ColumnStack spacing={1}>
          <InputLabel required>انتخاب تصویر</InputLabel>
          <Upload
            accept={['image']}
            location='pageBuilder'
            previewProps={file ? { previewSrc: file.data.url } : undefined}
            onUploadChange={(files) => setFile(files?.[0] || null)}
            onUploadRemove={() => setFile(null)}
          />
        </ColumnStack>
        <LoadingButton
          loading={loading}
          onClick={handleSubmit}
          disabled={!file}
          variant='outlined'
        >
          اضافه کردن
        </LoadingButton>
      </ColumnStack>
    </Menu>
  );
};

export default FileMenu;

import { useQueryClient } from '@tanstack/react-query';

// Custom Hooks
import useAppMutation from 'core/hooks/api/useMutation/useAppMutation';
import { isSucceed } from 'core/utilities/helper';

// Custom Types
import type { UseAppMutationOptionsProps } from 'core/hooks/api/useMutation/useAppMutation';

export const useAddMutation = <TData, TResponse = { status: number }>(
  apiHandler: (data: TData) => Promise<TResponse>,
  mutationKey: string[]
) => {
  return (
    options?: Omit<
      UseAppMutationOptionsProps<TResponse, Error, TData>,
      'mutationFn' | 'mutationKey'
    >
  ) => {
    const queryClient = useQueryClient();
    return useAppMutation({
      mutationFn: async (data: TData) => {
        const response = (await apiHandler(data)) as any;
        const status =
          'status' in response
            ? (response.status as number)
            : typeof response === 'number'
            ? response
            : 502;
        if (isSucceed(status)) return response as TResponse;
        else throw new Error('ERROR');
      },
      mutationKey,
      ...options,
      onSuccess: (data, variables, context) => {
        if (!options?.noRevalidate) {
          queryClient.invalidateQueries({
            queryKey: mutationKey,
          });
        }
        if (options?.onSuccess) options.onSuccess(data, variables, context);
      },
    });
  };
};

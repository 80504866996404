import { UseFormSetValue } from 'react-hook-form';
import * as XLSX from 'xlsx';

// Store
import { setAppAlert } from 'core/utilities/helper';

// Types
import type {
  SheetCellProps,
  SheetDataProps,
  SheetRowProps,
} from 'features/data/sheets/types';

interface MergedCellProps {
  colSpan: number;
  [key: string]: number | string;
}
interface ExcelRow {
  [key: string]: number | string;
}

let jsonData: ExcelRow[];

/**
 * Finds the table header by determining the column names from the original data.
 * @param {SheetRowProps[]} originalData - Array of original data.
 * @returns {string[]} - Array of table header column names.
 */
export const findTableHeader = (originalData: SheetRowProps[]) => {
  if (originalData && originalData.length > 0) {
    let resault = originalData.reduce((maxElement, currentElement) => {
      return Object.keys(currentElement).length > Object.keys(maxElement).length
        ? currentElement
        : maxElement;
    }, originalData[0]);
    const { phoneNumber, ...newResault } = resault;

    return Object.keys(newResault);
  } else {
    return [''];
  }
};

/**
 * Returns an array of alphabets corresponding to the table headers.
 * @param {string[]} tableHeader - Array of table headers.
 * @returns {string[]} - Array of alphabets.
 */
export const sheetHeadersHandler = (tableHeader: string[]): string[] =>
  Array.from({ length: tableHeader.length }, (_, index) =>
    String.fromCharCode(65 + index)
  );

/**
 * Converts an alphabet to its corresponding number.
 * @param {string} alphabet - Alphabet to convert.
 * @returns {number} - Corresponding number value.
 */
export const alphabetToNumber = (alphabet: string): number => {
  const alphabetUpperCase = alphabet.toUpperCase();
  return alphabetUpperCase.charCodeAt(0) - 64;
};

/**
 * Converts a number to its corresponding alphabet.
 *
 * @param {number} number - Number to convert.
 * @returns {string} - Corresponding alphabet value.
 * @throws {Error} - Throws an error if the number is not between 1 and 26.
 */
export const numberToAlphabet = (number: number): string => {
  if (number < 1 || number > 26) {
    throw new Error('Invalid input. Number should be between 1 and 26.');
  }
  return String.fromCharCode(number + 64);
};

/**
 * Converts object values to an array of keys.
 *
 * @param {{ [key: string]: number | string }} inputObject - Input object.
 * @returns {string[]} Array of keys.
 */
export const objectValuesToArray = (inputObject: {
  [key: string]: number | string;
}): string[] => {
  return Object.keys(inputObject);
};

/**
 * Reads Excel file data asynchronously and performs transformations.
 *
 * @param {ArrayBuffer} fileData - Excel file data in ArrayBuffer format.
 * @param {UseFormSetValue<SheetDataProps>} setValue - Form value setter function.
 * @param {React.Dispatch<React.SetStateAction<SheetRowProps[]>>} setOriginalData - State setter function for original data.
 * @returns {void}
 */
export const readExcel = async (
  fileData: ArrayBuffer,
  setValue: UseFormSetValue<SheetDataProps>,
  setOriginalData: React.Dispatch<React.SetStateAction<SheetRowProps[]>>
): Promise<void> => {
  const workbook = XLSX.read(fileData, { type: 'buffer' });
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];

  // Calculate the range of used cells and count of rows and columns
  const sheetRef = sheet['!ref'];
  if (sheetRef) {
    const range = XLSX.utils.decode_range(sheetRef);

    const rowsCount = range.e.r - range.s.r;
    const columnsCount = range.e.c - range.s.c + 1;

    setValue('rowCount', rowsCount);
    setValue('colCount', columnsCount);
  }

  const mergedCells = sheet['!merges'];

  jsonData = XLSX.utils.sheet_to_json(sheet, {
    raw: true,
    defval: '',
  });

  if (!jsonData[0]['شماره موبایل']) {
    setAppAlert('لطفا فایل دارای شماره موبایل آپلود کنید.');
    return;
  }
  const mergedStore: MergedCellProps[] = [];

  if (mergedCells && mergedCells.length > 0) {
    mergedCells.forEach((mergedCell) => {
      const [key, value] = Object.entries(jsonData[mergedCell.s.r - 1])[
        mergedCell.s.c
      ];
      mergedStore.push({
        [key]: value,
        colSpan: mergedCell.e.c - mergedCell.s.c + 1,
      });
    });
  }
  transformExcelData(jsonData, mergedStore, setOriginalData);
};

/**
 * Transforms Excel data by merging cells and creating a new data structure.
 *
 * @param {{ [key: string]: number | string }[]} data - Excel data to transform.
 * @param {MergedCellProps[]} merges - Array of merged cell properties.
 * @param {React.Dispatch<React.SetStateAction<SheetRowProps[]>>} setOriginalData - State setter function for original data.
 * @returns {void}
 */
const transformExcelData = (
  data: { [key: string]: number | string }[],
  merges: MergedCellProps[],
  setOriginalData: React.Dispatch<React.SetStateAction<SheetRowProps[]>>
): void => {
  const newData: SheetRowProps[] = [];
  let colCount = 0;

  data.forEach((row) => {
    const newRow: SheetRowProps = {
      phoneNumber: '',
    };

    Object.entries(row).forEach(([key, value], cellIdx) => {
      if (key === 'شماره موبایل') {
        value =
          typeof value === 'string' && value.startsWith('0')
            ? value
            : `0${value}`;
        newRow.phoneNumber = value;
      }

      const mergedCell = merges.find((merge) => merge[key] === value);

      newRow[key] = {
        label: key,
        value: value ? value : '-',
        colSpan: mergedCell ? mergedCell.colSpan : 1,
        id: `row-${cellIdx}-col-${colCount + cellIdx}`,
      };
    });

    newData.push(newRow);
  });

  setOriginalData(newData);
};

/**
 * Handles the download of Excel file.
 *
 * @param {SheetRowProps[] | File | null} uploadedFile - Uploaded file or data.
 * @returns {void}
 */
export const handleDownloadExcel = (
  uploadedFile: SheetRowProps[] | File | null
): void => {
  let newData: ExcelRow[] = [];

  if (uploadedFile && !(uploadedFile instanceof File)) {
    Object.values(uploadedFile).forEach((row) => {
      const newRow: ExcelRow = {};

      Object.entries(row).forEach(([key, value]) => {
        value = value as SheetCellProps;

        if (key !== 'phoneNumber') {
          newRow[key] = value.value;
        }
      });

      newData.push(newRow);
    });

    const worksheet = XLSX.utils.json_to_sheet(newData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'excelFile.xlsx');
  }
};

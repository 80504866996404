import { memo, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Custom Core Components
import TextField from 'core/components/base/inputs/TextField';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';

// Custom Feature Componenst
import FormInputWrapper, {
  FormInputWrapperProps,
} from 'features/form/forms/components/details/item/Input/shared/Wrapper';
import ConfigNumberInput from 'features/form/forms/components/details/item/Input/shared/Common/ConfigNumberInput';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';

// Custom Types
import type { FormTextInputProps } from 'features/form/forms/types/item/input/text';
import type { FormProps } from 'features/form/forms/types/item';
export interface FormTextInputComponentProps extends FormInputWrapperProps {
  input: FormTextInputProps;
}

const FormTextInput: FC<FormTextInputComponentProps> = (props) => {
  // Props
  const { input, disabled, onUpdate, ...otherProps } = props;

  // Hooks
  const { control, getValues } = useFormContext<FormProps>();
  const index = getValues('data.inputsIndexMap')[input.id];

  const FormTextInputConfig = (
    <RowStack>
      <RowStack spacing={0.5}>
        <InputLabel>کمترین</InputLabel>
        <Controller
          control={control}
          name={`data.inputs.${index}.data.minLength`}
          rules={{ onChange: onUpdate }}
          render={({ field }) => (
            <ConfigNumberInput disabled={disabled} {...field} />
          )}
        />
      </RowStack>
      <RowStack spacing={0.5}>
        <InputLabel>بیشترین</InputLabel>
        <Controller
          control={control}
          name={`data.inputs.${index}.data.maxLength`}
          rules={{ onChange: onUpdate }}
          render={({ field }) => (
            <ConfigNumberInput disabled={disabled} {...field} />
          )}
        />
      </RowStack>
    </RowStack>
  );

  return (
    <FormInputWrapper
      id={input.id}
      type='text'
      index={index}
      config={FormTextInputConfig}
      disabled={disabled}
      onUpdate={onUpdate}
      {...otherProps}
    >
      <Controller
        control={control}
        name={`data.inputs.${index}.data.placeholder`}
        defaultValue=''
        rules={{ onChange: onUpdate }}
        render={({ field }) => (
          <TextField
            disabled={disabled}
            placeholder='متن راهنما...'
            fullWidth
            sx={{ fontSize: '0.875rem' }}
            {...field}
          />
        )}
      />
    </FormInputWrapper>
  );
};

export default memo(FormTextInput, isSame);

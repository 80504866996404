// Custom Page Components
import AudienceAccess from 'features/audience/accesses/components';

// Custom Utilities
import { getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'audience';
const subFeature: SubFeatureType = 'audienceAccesses';
const audienceAccessesRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/audiences/access',
    Component: AudienceAccess,
    feature,
    subFeature,
  },
];

export default audienceAccessesRoutes;

import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Custom Core Components
import Table from 'core/components/base/display/Table';
import TableBody from 'core/components/base/display/TableBody';
import TableCell from 'core/components/base/display/TableCell';
import TableContainer from 'core/components/base/display/TableContainer';
import TableHead from 'core/components/base/display/TableHead';
import TableRow from 'core/components/base/display/TableRow';
import Checkbox from 'core/components/base/inputs/Checkbox';

// Custom Common Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import RouteLink from 'core/components/shared/Link/RouteLink';
import AntSwitch from 'core/components/shared/Switch/AntSwitch';
import TableContainerPaper from 'core/components/shared/Paper/TableContainerPaper';

// Custom Utilities
import { getLocaleDate } from 'core/utilities/helper/date';
import { tableScrollbarStyles } from 'core/utilities/styles/customStyles';
import { getLocaleDateAndTime } from 'core/utilities/helper/date';

// Context
import { useSelectionContext } from 'core/store/context/common/UseSelection';

// Custom Types
import type { AudienceProps } from 'features/audience/audiences/types';

interface AudiencesDataGridProps {
  disabled?: boolean;
  list: AudienceProps[];
  onStatusChange: (audienceId: string, status: boolean) => void;
}

const AudiencesDataGrid: React.FC<AudiencesDataGridProps> = (props) => {
  // Props
  const { list, disabled, onStatusChange } = props;

  // Context
  const selections = useSelectionContext();

  // Hooks
  const { palette } = useTheme();

  // Render
  return (
    <TableContainer
      sx={{ paddingBottom: '1rem', ...tableScrollbarStyles }}
      component={TableContainerPaper}
    >
      <Table>
        <TableHead>
          <TableRow sx={{ borderBottom: `1px solid ${palette.divider}` }}>
            <TableCell padding='checkbox'>
              <Checkbox
                checked={selections.isAllSelected(list)}
                color='info'
                disabled={disabled || list.length === 0}
                indeterminate={selections.isIndeterminate(list)}
                onChange={() => selections.onToggleAll(list)}
              />
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              نام
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              نام‌خانوادگی
            </TableCell>
            <TableCell sx={{ width: '12rem', whiteSpace: 'nowrap' }}>
              کد ملی
            </TableCell>
            <TableCell sx={{ width: '12rem', whiteSpace: 'nowrap' }}>
              کد پرسنلی
            </TableCell>
            <TableCell sx={{ width: '12rem', whiteSpace: 'nowrap' }}>
              موبایل
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              تاریخ تولد
            </TableCell>
            <TableCell sx={{ width: '12rem', whiteSpace: 'nowrap' }}>
              گروه‌
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              عنوان
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              ایمیل
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              بازخوردها
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              آخرین ورود
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              وضعیت
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              تاریخ عضویت
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {disabled && list.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4}>در حال دریافت اطلاعات مخاطبان.</TableCell>
            </TableRow>
          ) : list.length > 0 ? (
            list.map((audience) => (
              <TableRow key={audience.id}>
                <TableCell padding='checkbox'>
                  <Checkbox
                    color='info'
                    disabled={disabled || list.length === 0}
                    checked={selections.isSelected(audience.id)}
                    onChange={() => selections.onSelectionsChange(audience.id)}
                  />
                </TableCell>
                <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
                  {audience.data.firstName || '-'}
                </TableCell>
                <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
                  {audience.data.lastName || '-'}
                </TableCell>
                <TableCell sx={{ width: '12rem', whiteSpace: 'nowrap' }}>
                  {audience.data.nationalId?.toPersian() || '-'}
                </TableCell>
                <TableCell sx={{ width: '12rem', whiteSpace: 'nowrap' }}>
                  {audience.data.personalId?.toPersian() || '-'}
                </TableCell>
                <TableCell sx={{ width: '12rem', whiteSpace: 'nowrap' }}>
                  <RouteLink
                    disabled={disabled}
                    to={`/audiences/${audience.id}`}
                    state={audience.id}
                    sx={{
                      borderBottom: '1px dashed',
                      borderColor: 'text.secondary',
                      ':hover': {
                        borderBottom: '1px solid',
                      },
                    }}
                  >
                    {audience.data.phoneNumber?.toPersian()}
                  </RouteLink>
                </TableCell>
                <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
                  {audience.data.birthDate
                    ? getLocaleDate(audience.data.birthDate)
                    : '-'}
                </TableCell>
                <TableCell sx={{ width: '12rem', whiteSpace: 'nowrap' }}>
                  {audience.data.groups.length === 0
                    ? '-'
                    : audience.data.groups.map((g) => g.data.title).join('، ')}
                </TableCell>
                <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
                  {audience.data.jobTitle || '-'}
                </TableCell>
                <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
                  {audience.data.email || '-'}
                </TableCell>
                <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
                  {0?.toPersian()}
                </TableCell>
                <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
                  {audience.data.lastLogin.length > 0 ? (
                    <BodyTwo dir='ltr'>
                      {getLocaleDateAndTime(
                        audience.data.lastLogin[0].data.timestamp,
                        '-'
                      )}
                    </BodyTwo>
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
                  <AntSwitch
                    disabled={disabled}
                    checked={audience.data.isEnabled}
                    onChange={() => {
                      onStatusChange(audience.id, !audience.data.isEnabled);
                    }}
                  />
                </TableCell>
                <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
                  <BodyTwo dir='ltr'>
                    {getLocaleDateAndTime(audience.data.createdAt, '-')}
                  </BodyTwo>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>مخاطبی جهت نمایش وجود ندارد.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AudiencesDataGrid;

import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';

// Types
import type { LinkProps } from 'react-router-dom';
import type { HTMLAttributeAnchorTarget } from 'react';

// Core Components
import ListItemButton, {
  ListItemButtonProps,
} from 'core/components/base/display/ListItemButton';

interface ListItemButtonLinkProps extends ListItemButtonProps {
  href?: string;
  state?: any;
  target?: HTMLAttributeAnchorTarget;
}

const LinkWithRef = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => <Link {...props} ref={ref} />
);

const ListItemButtonLink: React.FC<ListItemButtonLinkProps> = (props) => {
  const { children, href, state, target, sx, ...otherProps } = props;
  const location = useLocation();
  const { palette } = useTheme();
  return (
    <ListItemButton
      to={href}
      component={LinkWithRef}
      state={href}
      sx={{
        ...sx,
        color: href === location.pathname ? palette.info.main : 'inherit',
      }}
      {...otherProps}
    >
      {children}
    </ListItemButton>
  );
};

export default ListItemButtonLink;

import cloneDeep from 'lodash/cloneDeep';

// Feature Defaults
import { contentPatternBodyDefaults } from 'features/appBuilder/patterns/features/content/utilities/defaults/body';
import { contentPatternConfigDefaults } from 'features/appBuilder/patterns/features/content/utilities/defaults/config';
import { contentPatterButtonDefaults } from 'features/appBuilder/patterns/features/content/utilities/defaults/button';

// Custom Types
import type { ContentPatternDataProps } from 'features/appBuilder/patterns/features/content/types';

const contentPatternDataDefaults: ContentPatternDataProps = {
  patternTitle: '',
  bgColorOne: '#F5F5F5',
  bgColorTwo: '#F5F5F5',
  dividerColor: '#C1C8D3',
  bgImage: null,
  bgImageType: 'cover',
  padding: '32px',
  cardBg: '#FFFFFF',
  cardCount: 8,
  creator: null,
  isActive: true,
  maxWidth: 'lg',
  sortIndex: 0,
  type: 'content',
  variant: 0,
  body: contentPatternBodyDefaults,
  config: contentPatternConfigDefaults,
  button: contentPatterButtonDefaults,
};

/**
 * Retrieves the default content pattern data.
 *
 * @returns {ContentPatternDataProps} The default content pattern data.
 */
export const getContentPatternDataDefault = () => {
  const cloned = cloneDeep(contentPatternDataDefaults);

  return cloned;
};

import { createContext, useContext } from 'react';

// Types
import type { FC, ReactNode } from 'react';
import type { MenuItemProps } from 'features/menu/menus/types';

const MenuItem = createContext<MenuItemProps>(null as unknown as MenuItemProps);

interface MenuItemProviderProps {
  children: ReactNode;
  menu: MenuItemProps;
}

export const MenuItemProvider: FC<MenuItemProviderProps> = (props) => {
  // Props
  const { children, menu } = props;
  // Render
  return <MenuItem.Provider value={menu}>{children}</MenuItem.Provider>;
};

export const useMenuItemContext = (): MenuItemProps => useContext(MenuItem);

// Feature Types
import type { FormPatternConfigProps } from 'features/appBuilder/patterns/features/form/types/config';

export const formPatterConfigDefaults: FormPatternConfigProps = {
  isActive: true,
  title: {
    isActive: true,
    lineClamp: 1,
    fontColor: '#12252E',
    highlightColor: 'rgba(0, 0, 0, 0)',
    padding: '0px',
    fontSize: '24',
    textAlign: 'start',
    textDirection: 'rtl',
    fontHoverColor: '',
    highlightHoverColor: '',
  },
  description: {
    isActive: true,
    lineClamp: 10,
    fontColor: '#12252E',
    highlightColor: 'rgba(0, 0, 0, 0)',
    padding: '0px',
    fontSize: '16',
    textAlign: 'start',
    textDirection: 'rtl',
    fontHoverColor: '',
    highlightHoverColor: '',
  },
  date: {
    isActive: true,
    borderRadius: 8,
    calendarType: 'jalali',
    fontColor: '#59666D',
    highlightColor: 'rgba(0, 0, 0, 0)',
    fontSize: '14',
    padding: '0px',
    prefix: '',
    textAlign: 'start',
    textDirection: 'rtl',
  },
  step: {
    isActive: true,
    padding: '0px',
    fontSize: '16',
    textDirection: 'rtl',
    fontColor: '#0091FF',
    highlightColor: 'rgba(0, 0, 0, 0)',
    bgColor: '',
    borderColor: '',
    iconColor: '',
  },
};

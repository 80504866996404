import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface WidthFullIconProps extends SvgIconProps {}

const WidthFullIcon: React.FC<WidthFullIconProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <path d='M22,2H2v14.2h20V2z' />
      <path d='M17,19.5H7V22h10V19.5z' />
    </IconBuilder>
  );
};

export default WidthFullIcon;

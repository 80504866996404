// Custom Page Components
import Templates from 'features/appBuilder/templates/components/list';
import EditTemplate from 'features/appBuilder/templates/components/details/Edit';

// Custom Utilities
import { getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'template';
const subFeature: SubFeatureType = 'templates';
const templatesRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/template',
    Component: Templates,
    feature,
    subFeature,
  },
  {
    path: '/template/:featureType',
    Component: EditTemplate,
    feature,
    subFeature,
  },
];

export default templatesRoutes;

import { useQueryClient } from '@tanstack/react-query';

// Custom Hooks
import useDocApi from 'core/hooks/api/useDoc';
import useDocsApi from 'core/hooks/api/useDocs';
import useAppMutation from 'core/hooks/api/useMutation/useAppMutation';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import { isSucceed } from 'core/utilities/helper';
import {
  addMenuContainer,
  addMenuItem,
  deleteMenuContainer,
  deleteMenuItems,
  getMenu,
  getMenusList,
  updateMenuContainer,
  updateMenuItems,
} from 'features/menu/menus/utilities/api';

// Custom Types
import type { UseMutationResult } from '@tanstack/react-query';
import type { UseAppMutationOptionsProps } from 'core/hooks/api/useMutation/useAppMutation';
import type {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';
import type {
  MenuContainerProps,
  MenuItemDataProps,
  MenuItemProps,
  MenuListItemProps,
} from 'features/menu/menus/types';

/**
 * Query key for fetching menus in react query.
 *
 * @constant {Array<string>}
 */
export const menusQueryKey = ['menus'];

/**
 * Custom hook to fetch menus.
 *
 * This hook utilizes the `useDocsApi` to retrieve menus list on the provided options.
 *
 * @param {UseDocsOptionType<MenuListItemProps>} [options] - Optional configuration options for fetching the menus.
 * @returns {UseDocsApiReturnProps<MenuListItemProps>} The result of the `useDocsApi` hook, which includes the fetched menus
 */
export const useMenuDocs = (
  options?: UseDocsOptionType<MenuListItemProps>
): UseDocsApiReturnProps<MenuListItemProps> =>
  useDocsApi<MenuListItemProps>(getMenusList, menusQueryKey, options);

/**
 * Custom hook to fetch the menu document.
 *
 * This hook utilizes the `useDocApi` to retrieve a specific menu based on the provided options.
 *
 * @param {UseDocOptionType<MenuContainerProps>} [options] - Optional configuration options for fetching the menu document.
 * @returns {UseDocApiReturnProps<MenuContainerProps>} The result of the `useDocApi` hook, which includes the fetched menu document, loading state, and any errors.
 */
export const useMenuDoc = (
  options?: UseDocOptionType<MenuContainerProps>
): UseDocApiReturnProps<MenuContainerProps> =>
  useDocApi<MenuContainerProps>(getMenu, menusQueryKey, options);

/**
 * Custom hook to delete a menu container.
 * @returns {UseMutationResult<string[], { status: number }>} A mutation result object.
 */
export const useDeleteMenuContainerMutation = useDeleteMutation<string[]>(
  deleteMenuContainer,
  menusQueryKey
);

/**
 * Custom hook to add a new menu container.
 * @returns {UseMutationResult<string, { status: number; data?: MenuContainerProps }>} A mutation result object.
 */
export const useAddMenuContainerMutation = useAddMutation<
  string,
  {
    status: number;
    data?: MenuContainerProps;
  }
>(addMenuContainer, menusQueryKey);

/**
 * Custom hook to edit an existing menu container.
 * @returns {UseMutationResult<string, { status: number; data?: MenuContainerProps }>} A mutation result object.
 */
export const useEditMenuContainerMutation = useEditMutation<
  string,
  {
    status: number;
    data?: MenuContainerProps;
  }
>(updateMenuContainer, menusQueryKey);

/**
 * Custom hook to add a new menu item.
 * @returns {UseMutationResult<MenuItemProps, { status: number; data?: MenuItemProps }>} A mutation result object.
 */
export const useAddMenuItemMutation = useAddMutation<
  {
    containerId: string;
    data: MenuItemDataProps;
  },
  {
    status: number;
    data?: MenuItemProps;
  }
>(addMenuItem, menusQueryKey);

/**
 * Custom hook to update multiple menu items.
 * @param {Omit<UseMutationOptions<{ status: number; data?: MenuItemProps[] }, Error, MenuItemProps[]>, 'mutationFn' | 'mutationKey'>} options - Options for the mutation.
 * @returns {UseMutationResult<{ status: number; data?: MenuItemProps[] }, Error, MenuItemProps[]>} A mutation result object.
 */
export function useUpdateMenuItemsMutation(
  options?: Omit<
    UseAppMutationOptionsProps<
      { status: number; data?: MenuItemProps[] },
      Error,
      MenuItemProps[]
    >,
    'mutationFn' | 'mutationKey'
  >
): UseMutationResult<
  { status: number; data?: MenuItemProps[] },
  Error,
  MenuItemProps[]
> {
  const queryClient = useQueryClient();
  return useAppMutation({
    mutationFn: async (items: MenuItemProps[]) => {
      const response = await updateMenuItems(items);
      const status =
        'status' in response
          ? (response.status as number)
          : typeof response === 'number'
          ? response
          : 502;
      if (isSucceed(status)) return response;
      else throw new Error('ERROR');
    },
    mutationKey: menusQueryKey,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: menusQueryKey,
      });
      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
  });
}

/**
 * Custom hook to delete multiple menu items.
 * @param {Omit<UseMutationOptions<{ status: number }, Error, string[]>, 'mutationFn' | 'mutationKey'>} options - Options for the mutation.
 * @returns {UseMutationResult<{ status: number }, Error, string[]>} A mutation result object.
 */
export function useDeleteMenuItemsMutation(
  options?: Omit<
    UseAppMutationOptionsProps<{ status: number }, Error, string[]>,
    'mutationFn' | 'mutationKey'
  >
): UseMutationResult<{ status: number }, Error, string[]> {
  const queryClient = useQueryClient();
  return useAppMutation({
    mutationFn: async (items: string[]) => {
      const response = await deleteMenuItems(items);
      const status =
        'status' in response
          ? (response.status as number)
          : typeof response === 'number'
          ? response
          : 502;
      if (isSucceed(status)) return response;
      else throw new Error('ERROR');
    },
    mutationKey: menusQueryKey,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: menusQueryKey,
      });
      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
  });
}

// Types
import type {
  ApiAudienceSettingsProps,
  ApiProfileRequiredField,
  ApiAdditionalRequiredField,
  ApiUpdateRequiredField,
} from 'features/audience/settings/types/api';
import type {
  AudienceSettingsFieldsDataProps,
  AudienceSettingsProps,
  EditableAudienceSettingsFieldsDataProps,
  RequiredAudienceSettingsFieldsDataProps,
} from 'features/audience/settings/types';

/**
 * Transforms API profile required fields into a structure accepted by the frontend.
 *
 * @param {ApiProfileRequiredField[]} apiFields - Array of API profile required fields.
 * @returns {AudienceSettingsFieldsDataProps} Transformed audience settings fields data.
 */
export const transformAudienceSettingsCommonFields = (
  apiFields: ApiProfileRequiredField[]
): AudienceSettingsFieldsDataProps => {
  let activeFields = apiFields.filter((apiFields) => apiFields.data.isActive);
  let activeFieldsNames = activeFields.map((field) => field.data.title);
  return {
    avatar: activeFieldsNames.includes('avatar'),
    biography: activeFieldsNames.includes('biography'),
    birthDate: activeFieldsNames.includes('birthDate'),
    email: activeFieldsNames.includes('email'),
    firstName: activeFieldsNames.includes('firstName'),
    lastName: activeFieldsNames.includes('lastName'),
    groups: activeFieldsNames.includes('groups'),
    jobTitle: activeFieldsNames.includes('jobTitle'),
    nationalId: activeFieldsNames.includes('nationalId'),
    personelId: activeFieldsNames.includes('personelId'),
  };
};

/**
 * Transforms API additional required fields into a structure accepted by the frontend.
 *
 * @param {ApiAdditionalRequiredField[]} apiFields - Array of API additional required fields.
 * @returns {RequiredAudienceSettingsFieldsDataProps} Transformed required audience settings fields data.
 */
export const transformAudienceSettingsRequiredFields = (
  apiFields: ApiAdditionalRequiredField[]
): RequiredAudienceSettingsFieldsDataProps => {
  let activeFields = apiFields.filter((apiFields) => apiFields.data.isActive);
  let activeFieldsNames = activeFields.map((field) => field.data.title);
  return {
    avatar: activeFieldsNames.includes('avatar'),
    biography: activeFieldsNames.includes('biography'),
    birthDate: activeFieldsNames.includes('birthDate'),
    email: activeFieldsNames.includes('email'),
    firstName: activeFieldsNames.includes('firstName'),
    lastName: activeFieldsNames.includes('lastName'),
    nationalId: activeFieldsNames.includes('nationalId'),
    personelId: activeFieldsNames.includes('personelId'),
  };
};

/**
 * Transforms API update required fields into a structure accepted by the frontend.
 *
 * @param {ApiUpdateRequiredField[]} apiFields - Array of API update required fields.
 * @returns {EditableAudienceSettingsFieldsDataProps} Transformed editable audience settings fields data.
 */
export const transformAudienceSettingsEditableFields = (
  apiFields: ApiUpdateRequiredField[]
): EditableAudienceSettingsFieldsDataProps => {
  let activeFields = apiFields.filter((apiFields) => apiFields.data.isActive);
  let activeFieldsNames = activeFields.map((field) => field.data.title);
  return {
    avatar: activeFieldsNames.includes('avatar'),
    biography: activeFieldsNames.includes('biography'),
    email: activeFieldsNames.includes('email'),
  };
};

/**
 * Transforms API audience settings into a structure accepted by the frontend.
 *
 * @param {ApiAudienceSettingsProps} apiSetting - API audience settings object.
 * @returns {AudienceSettingsProps} Transformed audience settings data.
 */
export const transformAudienceSetting = (
  apiSetting: ApiAudienceSettingsProps
): AudienceSettingsProps => {
  return {
    id: apiSetting._id,
    data: {
      audienceFields: transformAudienceSettingsCommonFields(
        apiSetting.data.profileRequiredField
      ),
      editableAudienceFields: transformAudienceSettingsEditableFields(
        apiSetting.data.updateRequiredField
      ),
      requiredAudienceFields: transformAudienceSettingsRequiredFields(
        apiSetting.data.additionalRequiredField
      ),
    },
  };
};

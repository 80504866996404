// Feature Utilities
import { bakePatternCommonProps } from 'features/appBuilder/patterns/core/utilities/api/baker';

// Feature Types
import type { SliderPatternDataProps } from 'features/appBuilder/patterns/features/slider/types';

/**
 * Transforms the provided slider pattern data by ensuring the `creator` field is included as a string.
 *
 * @param {SliderPatternDataProps} data - The slider pattern data to transform.
 * @returns {Omit<SliderPatternDataProps, 'creator'> & { creator: string }} The transformed slider pattern data with the `creator` field as a string.
 */
export const bakeSliderPatternData = (
  data: SliderPatternDataProps
): Omit<SliderPatternDataProps, 'creator'> & { creator: string } => {
  const { body, config, button, ...otherProps } = data;

  const commonProps = bakePatternCommonProps(otherProps);

  return {
    ...commonProps,
    body,
    config,
    button,
  };
};

import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import InputWrapper from 'core/components/shared/Wrapper/Input';

// Feature Shared Components
import PatternItemContainer, {
  type PatternItemContainerProps,
} from 'features/appBuilder/patterns/core/components/shared/items/Container';
import PatternNumberDropdown from 'features/appBuilder/patterns/core/components/shared/dropdown/Number';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
export interface PatternTextItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
  lineClampOptions?: number[];
}

const PatternTextItem: FC<PatternTextItemProps> = (props) => {
  // Props
  const { reference, lineClampOptions, ...otherProps } = props;

  // Hooks
  const { control } = useFormContext();
  const loading = useSelectLoading();

  return (
    <PatternItemContainer reference={reference} {...otherProps}>
      <InputWrapper disabled={loading} label='بیشترین تعداد خط برای نمایش'>
        <Controller
          control={control}
          name={`${reference}.lineClamp`}
          render={({ field }) => (
            <PatternNumberDropdown
              disabled={loading}
              defaultValue={field.value}
              reference={field.name}
              options={lineClampOptions}
            />
          )}
        />
      </InputWrapper>
    </PatternItemContainer>
  );
};

export default PatternTextItem;

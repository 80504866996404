// Custom Page Components
import FeedbackDetails from 'features/form/feedbacks/components/details';
import FormFeedbacksList from 'features/form/feedbacks/components/list';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'form';
const subFeature: SubFeatureType = 'feedbacks';
const feedbacksRoutes: ProtectedRouteProps[] = [
  {
    path: '/feedbacks/:formId',
    Component: FormFeedbacksList,
    feature,
    subFeature,
  },
  {
    path: '/feedbacks/:formId/details',
    Component: FeedbackDetails,
    feature,
    subFeature,
  },
];

export default feedbacksRoutes;

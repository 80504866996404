import * as React from 'react';
import { styled } from '@mui/material';

// Core Components
import Box from 'core/components/base/layout/Box';
import Paper from 'core/components/base/surfaces/Paper';
import IconButton from 'core/components/base/inputs/IconButton';

// Custom Common Commponents
import BodyOne from 'core/components/shared/Typography/BodyOne';
import CloseCircleIcon from 'core/components/icons/CloseCircle';
import CircularWithValueLabel from 'core/components/shared/Progress/CircularProgressWithPercentage';

// Icon Components
import FileUploadIcon from 'core/components/icons/FileUpload';

// Utilities
import { prefixFileURL } from 'core/utilities/helper/link';
import { addFiles } from 'features/file/files/utilities/files/api';
import { initialFile } from 'features/file/files/utilities/file';
import { readExcel } from 'features/data/sheets/utilities/helper';
import { isSucceed, setAppLoading } from 'core/utilities/helper';

// Custom Types
import type { PaperProps } from 'core/components/base/surfaces/Paper';
import type { UseFieldArrayAppend, UseFormSetValue } from 'react-hook-form';
import type { DeprecatedFileLocationType } from 'features/file/files/types';
import type { SheetRowProps } from 'features/data/sheets/types';

export type OnUploadChange = (
  event: React.ChangeEvent<HTMLInputElement>
) => void;

export type onUploadRemove = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
) => void;

export interface PreviewProps {
  previewAlt?: string;
  previewSrc: string;
}

interface UploadProps extends FileWrapperProps {
  refId: string;
  fileRef: string;
  location: DeprecatedFileLocationType; // TODO: Change To New FileLocationType when backend is ready.
  previewProps?: PreviewProps;
  accept?: string;
  setValue: UseFormSetValue<any>;
  append?: UseFieldArrayAppend<any, 'information.images'>; // The any type added due to remove form
  onUploadChange?: OnUploadChange;
  onUploadRemove?: onUploadRemove;
  setOriginalData?: React.Dispatch<React.SetStateAction<SheetRowProps[]>>;
}

interface FileWrapperProps extends PaperProps {
  error?: boolean;
  disabled?: boolean;
}

const FileWrapper = styled(Paper)<FileWrapperProps>(({ theme, error }) => ({
  alignItems: 'center',
  borderRadius: '8px',
  boxShadow: 'none',
  border: `1px #12252E80 dashed`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  minHeight: '100%',
  overflow: 'hidden',
  ':hover': {
    borderColor: theme.palette.info.main,
    p: {
      color: theme.palette.info.main,
    },
    svg: {
      path: {
        fill: theme.palette.info.main,
      },
    },
  },
  p: {
    color: theme.palette.text.disabled,
  },
}));

const DeprecatedFileUploader: React.FC<UploadProps> = React.forwardRef(
  (props, ref) => {
    // Props
    const {
      error,
      disabled,
      previewProps,
      variant,
      accept,
      refId,
      location,
      fileRef,
      setValue,
      append,
      setOriginalData,
      onUploadChange,
      onUploadRemove,
      ...otherProps
    } = props;

    // States
    const [isUploading, setIsUploading] = React.useState<boolean>(false);
    const [uploadPercentage, setUploadPercentage] = React.useState<number>(0);

    // Hooks
    const fileInputRef = React.useRef<HTMLInputElement | null>(null);
    const abortController = new AbortController();

    // Utilities
    const onChangeUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (location === 'sheet' && setOriginalData) {
        const reader = new FileReader();
        if (e.currentTarget.files) {
          const file = e.currentTarget.files[0];
          reader.onload = (event) => {
            const data = event.target?.result;
            if (data instanceof ArrayBuffer) {
              readExcel(data, setValue, setOriginalData);
            }
          };
          reader.readAsArrayBuffer(file);
        }
      } else if (location === 'category' && onUploadChange) {
        onUploadChange(e);
      } else if (e.target.files && e.target.files?.length > 0) {
        const coverFile = e.target.files[0];

        setAppLoading(true);
        setIsUploading(true);

        const { files, status } = await addFiles(
          { files: [coverFile], location: location, relatedModelId: refId },
          setUploadPercentage
        );
        if (isSucceed(status) && files.length > 0) {
          if (append) append(files[0]);
          else setValue(fileRef, files[0]);
        } else setUploadPercentage(0);

        setAppLoading(false);
        setIsUploading(false);
      }
    };

    const onRemoveUpload = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      setUploadPercentage(0);
      if (onUploadRemove && location === 'category') {
        onUploadRemove(e);
      } else {
        setValue(fileRef, initialFile);
      }
    };
    // Render
    return (
      <FileWrapper {...otherProps}>
        {previewProps && previewProps.previewSrc ? (
          <>
            <IconButton
              disabled={disabled}
              onClick={onRemoveUpload}
              sx={{
                position: 'absolute',
                top: 10,
                left: 10,
                zIndex: 10,
              }}
            >
              <CloseCircleIcon />
            </IconButton>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                padding: '0.5rem',
                position: 'absolute',
              }}
            >
              <img
                alt=''
                src={prefixFileURL(previewProps.previewSrc)}
                style={{
                  borderRadius: '4px',
                  filter: disabled ? 'grayscale(50%) brightness(90%)' : 'none',
                  height: '100%',
                  objectFit: 'contain',
                  width: '100%',
                }}
              />
            </Box>
          </>
        ) : (
          <Box
            onClick={() =>
              fileInputRef &&
              fileInputRef.current &&
              fileInputRef.current.click()
            }
            sx={{
              width: '100%',
              height: '100%',
              alignItems: 'center',
              cursor: disabled ? 'auto' : 'pointer',
              display: 'inherit',
              flexDirection: 'inherit',
              justifyContent: 'center',
            }}
          >
            {isUploading ? (
              <CircularWithValueLabel percentage={uploadPercentage} />
            ) : (
              <>
                <FileUploadIcon />
                <BodyOne>آپلود</BodyOne>
                <input
                  hidden
                  disabled={disabled}
                  onChange={onChangeUpload}
                  ref={fileInputRef}
                  type='file'
                  accept={accept}
                />
              </>
            )}
          </Box>
        )}
      </FileWrapper>
    );
  }
);

export default DeprecatedFileUploader;

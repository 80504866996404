// Custom Utilities
import { generateObjectId } from 'core/utilities/helper/id';

// Custom Types
import type { FormInputOptionProps } from 'features/form/forms/types/item/input/option';

export const genFormInputOption = (
  sortIndex: number,
  title = 'عنوان'
): FormInputOptionProps => {
  return {
    id: `draft-${generateObjectId()}`,
    data: {
      title,
      sortIndex,
      fieldIdsToUnhide: [],
      sectionIdsToUnhide: [],
    },
  };
};

import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Types
import type { FC } from 'react';

// Custom Common Components
import PageTitle from 'core/components/shared/Typography/PageTitle';
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import DeprecatedFileUploader from 'core/components/shared/Input/DeprecatedFileUploader';
import ButtonLink from 'core/components/shared/Button/ButtonLink';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import BodyOne from 'core/components/shared/Typography/BodyOne';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import DeleteDialog from 'core/components/shared/Dialog/Delete';

// Custom Core Components
import TextField from 'core/components/base/inputs/TextField';
import Button from 'core/components/base/inputs/Button';
import IconButton from 'core/components/base/inputs/IconButton';
import CircularProgress from 'core/components/base/feedback/CircularProgress';
import Box from 'core/components/base/layout/Box';

// Custom Icon Components
import DeleteIcon from 'core/components/icons/DeleteIcon';
import PDFIcon from 'core/components/icons/PDFIcon';
import ExcelIcon from 'core/components/icons/ExcelIcon';

// Custom Hooks
import useRefId from 'core/hooks/useRefId';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import {
  handleDownloadExcel,
  readExcel,
} from 'features/data/sheets/utilities/helper';

// Custom Types
import type { SheetDataProps, SheetRowProps } from 'features/data/sheets/types';
interface SheetSettingProps {
  onOpenDeleteDialog?: () => void;
  onSubmit: () => Promise<void>;
  onSetOriginalData: React.Dispatch<React.SetStateAction<SheetRowProps[]>>;
  originalData: SheetRowProps[];
  isAddPage?: boolean;
}

const SheetSetting: FC<SheetSettingProps> = (props) => {
  const {
    isAddPage,
    originalData,
    onOpenDeleteDialog,
    onSubmit,
    onSetOriginalData,
  } = props;

  // Hooks
  const { control, setValue, getValues } = useFormContext<SheetDataProps>();
  const loading = useSelectLoading();
  const [refId] = useRefId();

  // Utilities
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    if (e.currentTarget.files) {
      const file = e.currentTarget.files[0];
      reader.onload = (event) => {
        const data = event.target?.result;
        if (data instanceof ArrayBuffer) {
          readExcel(data, setValue, onSetOriginalData);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  // Render
  return (
    <>
      <PageHeroBox>
        <PageTitle>
          {getValues('title') ? 'ویرایش شیت مخاطبان' : 'شیت مخاطبان جدید'}
        </PageTitle>
        <RowStack>
          {!isAddPage && (
            <>
              <IconButton
                disabled={loading}
                color='error'
                sx={{
                  borderRadius: '0.25rem',
                  border: '1px solid',
                  borderColor: loading ? 'text.disabled' : 'error.main',
                }}
                onClick={onOpenDeleteDialog}
              >
                {loading ? (
                  <CircularProgress color='error' size={22} />
                ) : (
                  <DeleteIcon />
                )}
              </IconButton>
              <Button
                sx={{
                  border: '2px #12252E80 solid',
                  color: '#12252E80',
                  ':hover': {
                    border: '2px #12252E80 solid',
                    color: '#12252E80',
                  },
                }}
                variant='outlined'
                startIcon={<ExcelIcon />}
                onClick={() =>
                  originalData && handleDownloadExcel(originalData)
                }
              >
                Excel
              </Button>
              <Button
                sx={{
                  border: '2px #12252E80 solid',
                  color: '#12252E80',
                  ':hover': {
                    border: '2px #12252E80 solid',
                    color: '#12252E80',
                  },
                }}
                variant='outlined'
                startIcon={<PDFIcon />}
                onClick={() => window.print()}
              >
                PDF
              </Button>
            </>
          )}
          <Button
            disabled={loading}
            color='info'
            variant='contained'
            sx={{ height: '2.5rem', width: '8rem' }}
            onClick={onSubmit}
          >
            ذخیره
          </Button>
        </RowStack>
      </PageHeroBox>
      <RoundPaper sx={{ mb: 3 }}>
        <BodyOne sx={{ fontWeight: 500, fontSize: 20, mb: 4 }}>
          تنظیمات شیت
        </BodyOne>
        <RowStack sx={{ justifyContent: 'space-between' }}>
          <ColumnStack width='50%' spacing={1}>
            <InputLabel>عنوان</InputLabel>
            <Controller
              control={control}
              name='title'
              render={({ field }) => (
                <TextField
                  disabled={loading}
                  placeholder='عنوان شیت'
                  {...field}
                />
              )}
            />
          </ColumnStack>
          <ColumnStack width='30%'>
            <BodyTwo>تغییر اطلاعات</BodyTwo>
            <BodyOne>
              انتخاب فایل اکسل{' '}
              <ButtonLink
                disabled
                href='/sheet'
                sx={{ ':hover': { backgroundColor: 'inherit' } }}
              >
                (دانلود نمونه)
              </ButtonLink>{' '}
            </BodyOne>
            <Box sx={{ width: '98px', height: '78px' }}>
              <DeprecatedFileUploader
                refId={refId}
                location='sheet'
                fileRef='bgImage'
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                disabled={loading}
                setValue={setValue}
                setOriginalData={onSetOriginalData}
                onUploadChange={handleFileChange}
              />
            </Box>
          </ColumnStack>
        </RowStack>
      </RoundPaper>
    </>
  );
};

export default SheetSetting;

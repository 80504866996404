// Custom Page Components
import AddTopic from 'features/ticket/topics/components/details/AddTopic';
import EditTopic from 'features/ticket/topics/components/details/EditTopic';
import TicketTopics from 'features/ticket/topics/components/list';

// Custom Utilities
import { getFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'ticket';
const subFeature: SubFeatureType = 'ticketCategories';
const ticketCategoriesRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: `ایجاد موضوع ${getFeatureLabel(feature)}`,
    path: '/tickets/topics/add',
    Component: AddTopic,
    feature,
    subFeature,
  },
  {
    path: '/tickets/topics/:id',
    Component: EditTopic,
    feature,
    subFeature,
  },
  {
    sidebarTitle: `موضوعات`,
    path: '/tickets/topics',
    Component: TicketTopics,
    feature,
    subFeature,
  },
];

export default ticketCategoriesRoutes;

// Custom Types
import { getUrlWithQueryString } from 'core/utilities/helper/helperPack';

export const menuEndpoints = {
  get addContainer() {
    return `/menu/menuContainer/add`;
  },
  updateContainer(id: string) {
    return `/menu/menuContainer/edit/${id}`;
  },
  get removeContainers() {
    return `/menu/menuContainer/remove`;
  },
  getContainerById(id: string) {
    return `/menu/menuContainer/getDoc/${id}`;
  },
  get getContainersSummary() {
    return `/menu/menuContainer/getList`;
  },
  getContainers(query?: Record<string, any>) {
    const endpoint = '/menu/menuContainer/getList';
    if (query) return getUrlWithQueryString(endpoint, query);
    return endpoint;
  },
  get updateMenuItem() {
    return `/menu/menuItem/edit`;
  },
  addMenuItem(id: string) {
    return `/menu/menuItem/add/${id}`;
  },
  get removeMenuItem() {
    return `/menu/menuItem/remove`;
  },
};

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface FormatAlignCenterIconProps extends SvgIconProps {}

const FormatAlignCenterIcon: React.FC<FormatAlignCenterIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M19.7634 7.67818H4.23652C4.10589 7.67818 3.99994 7.49447 3.99994 7.34546V4.33271C3.99994 4.14901 4.1058 4 4.23652 4H19.7634C19.894 4 19.9999 4.18371 19.9999 4.33271V7.34546C19.9999 7.52917 19.8941 7.67818 19.7634 7.67818Z' />
    <path d='M19.1665 13.8422H5.23749C5.10687 13.8422 5.00092 13.6585 5.00092 13.5095V10.4968C5.00092 10.3131 5.10678 10.1641 5.23749 10.1641H19.1665C19.2971 10.1641 19.4031 10.3478 19.4031 10.4968V13.5095C19.4031 13.6932 19.2972 13.8422 19.1665 13.8422Z' />
    <path d='M15.9678 19.9985H8.24238C8.11175 19.9985 8.0058 19.8148 8.0058 19.6658V16.653C8.0058 16.4693 8.11166 16.3203 8.24238 16.3203H15.9678C16.0984 16.3203 16.2044 16.504 16.2044 16.653V19.6658C16.2044 19.8495 16.0985 19.9985 15.9678 19.9985Z' />
  </IconBuilder>
);

export default FormatAlignCenterIcon;

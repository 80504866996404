import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Core Components
import { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface AddLayerIconProps extends SvgIconProps {}

const AddLayerIcon: React.FC<AddLayerIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M21.13,8.12a2.07,2.07,0,0,0-1.48-.62H4.35a2.12,2.12,0,0,0-2.1,2.1v9.3A2.12,2.12,0,0,0,4.35,21h15.3a2.12,2.12,0,0,0,2.1-2.1V9.6A2.07,2.07,0,0,0,21.13,8.12Zm-5.72,6.74H12.64V18H11.37V14.86H8.59v-1.2h2.78v-2.9h1.27v2.9h2.77Zm3.87-9.39a.75.75,0,0,0-.53-.22H5.25a.75.75,0,0,0,0,1.5h13.5a.75.75,0,0,0,.53-1.28Zm-1.5-2.25A.75.75,0,0,0,17.25,3H6.75a.75.75,0,0,0,0,1.5h10.5a.75.75,0,0,0,.53-1.28Z' />
  </IconBuilder>
);

export default AddLayerIcon;

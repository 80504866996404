import * as React from 'react';

// Core Components
import Box from 'core/components/base/layout/Box';
import IconButton from 'core/components/base/inputs/IconButton';
import Typography from 'core/components/base/display/Typography';

// Icon Components
import ContentCopyIcon from 'core/components/icons/ContentCopy';

// Types
import type { TypographyProps } from 'core/components/base/display/Typography';
import type { BoxProps } from 'core/components/base/layout/Box';
import Tooltip from 'core/components/base/display/Tooltip';
interface CopyToClipboardProps {
  disableIcon?: boolean;
  iconPosition?: 'right' | 'left';
  value: string;
  label?: string;
  urlPrefixed?: boolean;
  textProps?: TypographyProps;
  wrapperProps?: BoxProps;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = (props) => {
  const {
    disableIcon,
    textProps,
    iconPosition = 'left',
    label,
    urlPrefixed,
    value,
    wrapperProps,
  } = props;
  const [isCopied, setIsCopied] = React.useState<boolean>(false);

  const userUrl = import.meta.env.VITE_APP_USER_URL;

  const handleCopyToClip = async () => {
    const valueToCopy = urlPrefixed && userUrl ? `${userUrl}${value}` : value;
    try {
      await navigator.clipboard.writeText(valueToCopy);
      setIsCopied(true);
    } catch (error) {}

    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  const Text = () => (
    <Typography
      component={textProps && textProps.component ? textProps.component : 'p'}
      onClick={(event) => {
        if (disableIcon) handleCopyToClip();
        if (textProps && textProps.onClick) textProps.onClick(event);
      }}
      variant={textProps && textProps.variant ? textProps.variant : 'body2'}
      overflow='hidden'
      flexGrow={1}
      textOverflow='ellipsis'
      whiteSpace='nowrap'
      {...textProps}
    >
      {label ? label : value}
    </Typography>
  );

  if (disableIcon) return <Text />;

  return (
    <Box
      {...wrapperProps}
      dir={iconPosition === 'left' ? 'ltr' : 'rtl'}
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        ...wrapperProps?.sx,
      }}
    >
      {iconPosition === 'right' && <Text />}
      <Tooltip title={isCopied ? 'کپی شد.' : 'کپی'}>
        <IconButton
          size='small'
          onClick={handleCopyToClip}
          sx={{ marginLeft: '0.25rem' }}
        >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
      {iconPosition === 'left' && <Text />}
    </Box>
  );
};

export default CopyToClipboard;

import * as React from 'react';

// Core Components
import AppBar from 'core/components/base/surfaces/AppBar';
import Toolbar from 'core/components/base/surfaces/Toolbar';

// Components
import Box from 'core/components/base/layout/Box';
import Logo from 'core/components/shared/Avatar/Logo';
import Container from 'core/components/base/layout/Container';
import LogoutButton from 'core/components/shared/Button/LogOut';
import BodyOne from 'core/components/shared/Typography/BodyOne';
import ProfileIconButton from 'core/components/shared/IconButton/Profile';
import SettingsIconButton from 'core/components/shared/IconButton/Settings';
import ThemeTogglerIconButton from 'core/components/shared/IconButton/ThemeToggler';

// Context

// Hooks
import { useAppDispatch, useAppSelector } from 'core/hooks/redux';

// Utitlities
import { getPermissionCookies, isPermitted } from 'core/utilities/permissions';
import { prefixFileURL } from 'core/utilities/helper/link';

// Types
import { toggleTheme } from 'core/store/slices/core/theme';

interface HeaderProps {}

const Header: React.FC<HeaderProps> = (props) => {
  // Hooks
  const manager = useAppSelector((state) => state.manager);
  const dispatch = useAppDispatch();

  // Hooks
  const permissions = getPermissionCookies();

  const handleThemeToggle = () => dispatch(toggleTheme());

  const getManagerFullName = () => {
    const fullnameRaw = [
      manager.data.firstName || '',
      manager.data.lastName || '',
    ].join(',');

    return fullnameRaw.replaceAll(',', '')
      ? fullnameRaw.replaceAll(',', ' ')
      : 'مدیر';
  };

  return (
    <AppBar>
      <Container sx={{ padding: 0 }}>
        <Toolbar
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          }}
          style={{ padding: 0 }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Logo />
            <Box
              sx={{
                marginLeft: '12rem',
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ThemeTogglerIconButton
              onClick={handleThemeToggle}
              sx={{ marginRight: '0.25rem' }}
            />
            {isPermitted(permissions, 'manager', 'managerSettings') && (
              <SettingsIconButton sx={{ marginRight: '0.25rem' }} />
            )}
            <ProfileIconButton
              imageUrl={
                manager.data.avatar?.id &&
                prefixFileURL(manager.data.avatar.data.url)
              }
              href='/profile'
              sx={{
                marginRight: '0.25rem',
              }}
            />
            <BodyOne sx={{ marginRight: '2rem' }}>
              {getManagerFullName()}
            </BodyOne>
            <LogoutButton />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;

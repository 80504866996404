import Cookies from 'universal-cookie';
import dayjs from 'dayjs';

const cookies = new Cookies();

/**
 * Retrieves the token from cookies.
 * @returns {string} The token.
 */
export const getToken = (): string => {
  const cookiesToken = cookies.get('token');
  if (cookiesToken) return cookiesToken;
  else return '';
};

/**
 * Sets the token in cookies.
 * @param {string} token - The token to be set.
 */
export const setToken = (token: string) => {
  if (token)
    cookies.set('token', token, {
      secure: true,
      expires: dayjs().add(1, 'w').toDate(),
    });
};

/**
 * Removes the token from cookies.
 */
export const removeToken = () => {
  cookies.remove('token');
};

import * as React from 'react';
import { ReactSortable } from 'react-sortablejs';
import { useFormContext, useFieldArray } from 'react-hook-form';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';

// Core Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Feature Components
import EvaluationQuestionOption from 'features/form/processes/components/details/Evaluation/Option';
import EvaluationQuestionOptionBuilder from 'features/form/processes/components/details/Evaluation/Option/OptionBuillder';

// Core Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { generateObjectId } from 'core/utilities/helper/id';
import { updateProcessFocus } from 'features/form/processes/store/actions';

// Context
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useSelectProcessItemIsEditable } from 'features/form/processes/store/selector';

// Feature Types
import type { ProcessProps } from 'features/form/processes/types/details';
import type { EvaluationQuestionOptionProps } from 'features/form/processes/types/details/evaluation/questionOption';

interface EvaluationOptionsProps {
  index: number;
}

const EvaluationOptions: React.FC<EvaluationOptionsProps> = (props) => {
  // Props
  const { index } = props;

  // States
  const isSorting = useBoolean();

  // Context
  const loading = useSelectLoading();
  const { control, setValue, getValues } = useFormContext<ProcessProps>();
  const evaluationId = getValues(`data.items.${index}.id`);
  const isEditable = useSelectProcessItemIsEditable(evaluationId);

  // Hooks
  const questionOptions = useFieldArray({
    control,
    keyName: 'keyId',
    name: `data.items.${index}.data.questionOptions`,
  });

  // Utilities
  const focusOnOption = (id: string) => {
    const element = document.getElementById(id);

    if (element) element.focus();
  };

  const handleAddOption = (label: string) => {
    const optId = `draft-question-option-${generateObjectId()}`;

    questionOptions.append({
      id: optId,
      data: {
        label,
        linkedId: '',
        linkedTitle: 'پایان',
        message: '',
        status: 'end',
        sortIndex: questionOptions.fields.length,
      },
    });

    setTimeout(() => {
      focusOnOption(optId);
      updateProcessFocus(evaluationId);
    }, 2);
  };

  const handleSort = (sorted: EvaluationQuestionOptionProps[]) => {
    if (!isSorting.state) return;
    const newSorted: EvaluationQuestionOptionProps[] = sorted.map(
      (item, index) => ({
        id: item.id,
        data: { ...item.data, sortIndex: index },
      })
    );

    setValue(`data.items.${index}.data.questionOptions`, newSorted);
    isSorting.setFalse();
  };

  const handleSortEnd = () => {
    const defaultEvaluation = control?._defaultValues?.data?.items?.[index];
    const newEvaluation = getValues(`data.items.${index}`);
    updateProcessFocus(
      isSame(defaultEvaluation, newEvaluation) ? '' : evaluationId
    );
  };

  // Render
  return (
    <ColumnStack>
      <ReactSortable
        handle={`.processItemInputHandle-${evaluationId}`}
        list={questionOptions.fields}
        setList={handleSort}
        onStart={isSorting.setTrue}
        onEnd={handleSortEnd}
      >
        {questionOptions.fields.map((opt, optIndex) => (
          <EvaluationQuestionOption
            key={opt.keyId}
            itemIndex={index}
            optionId={opt.id}
            disabled={!isEditable}
            optionIndex={optIndex}
          />
        ))}
      </ReactSortable>
      <EvaluationQuestionOptionBuilder
        disabled={!isEditable || loading}
        onChange={handleAddOption}
      />
    </ColumnStack>
  );
};

export default React.memo(EvaluationOptions, isSame);

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface CleanIconProps extends SvgIconProps {}

const CleanIcon: React.FC<CleanIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M22.5,21.7l-1.4-8.1h0.5c0.4,0,0.7-0.3,0.7-0.7V7.7c0-0.4-0.3-0.7-0.7-0.7h-6.7V2.2c0-0.4-0.3-0.7-0.7-0.7H9.8
	c-0.4,0-0.7,0.3-0.7,0.7V7H2.5C2.1,7,1.8,7.4,1.8,7.7v5.1c0,0.4,0.3,0.7,0.7,0.7h0.5l-1.4,8.1c0,0,0,0.1,0,0.1
	c0,0.4,0.3,0.7,0.7,0.7h19.6c0,0,0.1,0,0.1,0C22.3,22.4,22.6,22.1,22.5,21.7z M3.7,8.9H11V3.4h2v5.5h7.4v2.8H3.7V8.9z M16.3,20.6
	v-4.2c0-0.1-0.1-0.2-0.2-0.2h-1.3c-0.1,0-0.2,0.1-0.2,0.2v4.2H9.4v-4.2c0-0.1-0.1-0.2-0.2-0.2H7.9c-0.1,0-0.2,0.1-0.2,0.2v4.2h-4
	l1.2-6.9h14.3l1.2,6.9H16.3z'
    />
  </IconBuilder>
);

export default CleanIcon;

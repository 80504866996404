import * as Yup from 'yup';

// Custom Schemas
import {
  emailSchema,
  firstNameSchema,
  lastNameSchema,
} from 'core/utilities/validationSchema/common/Schemas';

const ProfileValidationSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  password: Yup.string().test({
    test: (v) => {
      if (!v) return true;

      if (v && v.length >= 8) return true;

      return false;
    },
    message: 'کلمه‌عبور باید شامل حداقل ۸ کاراکتر باشد.',
    name: 'کلمه‌‌عبور',
  }),
});

export default ProfileValidationSchema;

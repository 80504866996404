// Custom Utilities
import { generateObjectId } from 'core/utilities/helper/id';
import {
  initialFileData,
  locationToPlural,
  locationToSingular,
} from 'features/file/files/utilities/file';
import { deprecatedFileLocations, deprecatedFileTypesOptions } from '../files';

// Custom Types
import { SubFeatureTypeLabel } from 'core/types/feature/FeatureType';
import type {
  FileDataProps,
  FileFormat,
  DeprecatedFileLocationType,
  FileMemeType,
  FileModelNameType,
  FileProps,
  FileType,
  FileLocationFeatureType,
  FileLocationSubFeatureType,
} from 'features/file/files/types';

/**
 * Generates an initial file object with optional properties.
 *
 * @param {Partial<FileDataProps>} [props] - Optional properties for the file data.
 * @param {boolean} [withId=true] - Flag indicating whether to include an ID in the generated file.
 * @returns {FileProps} The generated initial file object.
 */
export const generateInitialFile = (
  props?: Partial<FileDataProps>,
  withId: boolean = true
): FileProps => ({
  id: withId ? generateObjectId() : '',
  data: {
    ...initialFileData,
    ...props,
  },
});

export const deprecatedFileLocationAndName: {
  [key in DeprecatedFileLocationType]: FileModelNameType;
} = {
  audience: 'audience',
  audienceFolder: 'folder',
  audienceSetting: 'audienceSetting',
  banner: 'banner',
  category: 'category',
  content: 'content',
  feedback: 'feedback',
  file: 'media',
  form: 'form',
  manager: 'admin',
  managerSetting: 'setting',
  menu: 'menu',
  page: 'page',
  pageBuilder: 'pageBuilder',
  place: 'place',
  tag: 'tag',
  ticket: 'ticket',
  sheet: 'sheet',
  pattern: 'pattern',
};
// TODO: This is the new one. use it when backend is ready.
// export const fileLocationAndName: Record<string, FileModelNameType> = {
//   audience: 'audience',
//   banner: 'banner',
//   banners: 'banner',
//   categories: 'category',
//   category: 'category',
//   content: 'content',
//   contents: 'content',
//   customPages: 'pageBuilder',
//   evaluations: 'form',
//   feedback: 'feedback',
//   feedbacks: 'feedback',
//   file: 'media',
//   files: 'media',
//   form: 'form',
//   forms: 'form',
//   formTags: 'form',
//   manager: 'admin',
//   managers: 'admin',
//   managerGroups: 'admin',
//   managerProfiles: 'admin',
//   managerSetting: 'setting',
//   managerSettings: 'setting',
//   menu: 'menu',
//   menus: 'menu',
//   page: 'page',
//   pages: 'page',
//   pageBuilder: 'pageBuilder',
//   pattern: 'pattern',
//   patterns: 'pattern',
//   profile: 'audience',
//   sheet: 'sheet',
//   sheets: 'sheet',
//   skins: 'pattern',
//   tag: 'tag',
//   tags: 'tag',
//   templates: 'pattern',
//   ticket: 'ticket',
//   tickets: 'ticket',
//   ticketCategories: 'ticket',
//   audienceAccesses: 'audienceSetting',
//   audienceFolder: 'audienceSetting',
//   audienceFolders: 'audienceSetting',
//   audiences: 'audience',
//   audienceGroups: 'audience',
//   audienceSetting: 'audienceSetting',
//   audienceSettings: 'audienceSetting',
// };

export const subFeatures: Record<
  FileLocationFeatureType,
  FileLocationSubFeatureType[]
> = {
  form: ['forms', 'feedbacks', 'processes', 'formTags', 'feedbacks'],
  // @ts-ignore //TODO: Remove 'pageBuilder' when backend isReady for new location implemention.
  template: ['customPages', 'patterns', 'skins', 'templates', 'pageBuilder'],
  data: ['sheets'],
  audience: [
    'audiences',
    'audienceAccesses',
    'audienceFolders',
    'audienceGroups',
    'audienceSettings',
  ],
  manager: ['managers', 'managerSettings', 'managerProfiles', 'managerGroups'],
  banner: ['banners'],
  content: ['contents', 'tags', 'categories'],
  ticket: ['tickets', 'ticketCategories'],
  menu: ['menus'],
  file: ['files'],
  page: ['pages'],
};

/**
 * Retrieves the sub-features associated with a given feature.
 * @param {FileLocationFeatureType} feature - The feature to retrieve sub-features for.
 * @returns {FileLocationSubFeatureType[]} The array of sub-features.
 */
export const getSubFeatures = (
  feature: FileLocationFeatureType
): FileLocationSubFeatureType[] => subFeatures[feature] || [];

/**
 * Retrieves the file model name based on the given file location.
 *
 * @param {DeprecatedFileLocationType} location - The location of the file.
 * @returns {FileModelNameType} The corresponding file model name.
 */
export const deprecatedGetFileModelByLocation = (
  location: DeprecatedFileLocationType
): FileModelNameType => deprecatedFileLocationAndName[location];
// TODO: The New one
/**
 * Retrieves the file model name based on the given file location.
 *
 * @param {FileLocationType} location - The location of the file.
 * @returns {FileModelNameType} The corresponding file model name.
 */
// export const getFileModelByLocation = (
//   location: FileLocationType
// ): FileModelNameType => fileLocationAndName[locationToSingular(location)];

/**
 * Downloads a file from the given URL.
 *
 * @param {string} url - The URL of the file to download.
 * @param {string} fileName - The name of the downloaded file.
 * @param {string} fileFormat - The format of the downloaded file.
 * @returns {void}
 */
export const downloadFile = (
  url: string,
  fileName: string,
  fileFormat: FileFormat
): void => {
  fetch(url)
    .then((res) => res.blob())
    .then((res) => {
      const aElement = document.createElement('a');
      aElement.setAttribute('download', `${fileName}.${fileFormat}`);
      const href = URL.createObjectURL(res);
      aElement.href = href;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
    });
};

/**
 * Converts the given number of bytes to a human-readable format.
 *
 * @param {number|string} bytes - The number of bytes to convert.
 * @param {number} [decimals=2] - The number of decimal places to include in the result.
 * @returns {string} The human-readable format of the converted bytes.
 */
export const convertBytes = (
  bytes: number | string,
  decimals: number = 2
): string => {
  if (!+bytes) return '0 Bytes';
  let volume = 0;
  if (typeof bytes === 'string') {
    volume = parseInt(bytes);
  } else volume = bytes;

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(volume) / Math.log(k));

  return `${parseFloat((volume / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Retrieves the label for the given file type.
 *
 * @param {FileType} type - The file type.
 * @returns {string} The label for the file type.
 */
export const getFileTypeLabel = (type: FileType): string => {
  const fileTypeMap: { type: FileType; label: string }[] = [
    { type: 'audio', label: 'صدا' },
    { type: 'document', label: 'داکیومنت' },
    { type: 'image', label: 'تصویر' },
    { type: 'video', label: 'ویدئو' },
  ];

  const fileType = fileTypeMap.find((t) => t.type === type);

  return fileType ? fileType.label : 'فایل';
};

/**
 * Retrieves the label for the given file location.
 *
 * @param {FileLocationPluralType} location - The file location.
 * @returns {string} The label for the file location.
 */
export const getFileLocationLabel = (location: string): string => {
  const isCustomPages = [
    'pageBuilder',
    'pageBuilders',
    'customPage',
    'customPages',
  ].includes(location);

  if (isCustomPages) return 'اپلیکیشن‌ساز'; //TODO: Temporary. Remove when backend is Ready for new implemention.

  return (
    SubFeatureTypeLabel[locationToPlural(location)] ||
    SubFeatureTypeLabel[
      locationToSingular(location) as keyof typeof SubFeatureTypeLabel
    ]
  );
};

/**
 * Transforms a file mime type to a file format.
 * @param {FileMemeType} type - The file mime type.
 * @returns {FileFormat} - The corresponding file format.
 */
export const transformFileMemeType = (type?: FileMemeType): FileFormat => {
  if (!type) return 'jpg';

  switch (type) {
    case 'video/x-msvideo':
      return 'avi';
    case 'video/mp4':
      return 'mp4';
    case 'video/x-matroska':
      return 'mkv';
    case 'video/x-ms-wmv':
      return 'wmv';
    case 'video/x-flv':
      return 'flv';
    case 'video/quicktime':
      return 'mov';
    case 'image/gif':
      return 'gif';
    case 'image/jpeg':
      return 'jpg';
    case 'image/png':
      return 'png';
    case 'image/svg+xml':
      return 'svg';
    case 'image/tiff':
      return 'tiff';
    case 'audio/mpeg':
      return 'mp3';
    case 'audio/wav':
      return 'wav';
    case 'audio/flac':
      return 'flac';
    case 'application/x-shockwave-flash':
      return 'swf';
    case 'application/msword':
      return 'doc';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'application/pdf':
      return 'pdf';
    default:
      return 'avi';
  }
};

/**
 * Checks if a location value is valid.
 * @param {string} location - The location value.
 * @returns {boolean} True if the location is valid, false otherwise.
 */
export const isFileLocationValid = (location: string): boolean =>
  location.length > 0 && deprecatedFileLocations.includes(location as any);

/**
 * Checks if a file type is valid.
 * @param {string} type - The type.
 * @returns {boolean} True if the type is valid, false otherwise.
 */
export const isFileTypeValid = (type: string): boolean =>
  type.length > 0 && deprecatedFileTypesOptions.includes(type as any);

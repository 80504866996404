import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Custom Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import RowStack from 'core/components/shared/Stack/RowStack';
import CheckboxWithLabel from 'core/components/shared/Checkbox/CheckboxWithLabel';

// Custom Utilities
import { getProfileFieldLabel } from 'features/audience/settings/utilities';
import { editableAudienceFields } from 'features/audience/settings/utilities/data';

// Custom Types
import type { AudienceSettingsDataProps } from 'features/audience/settings/types';

interface EditableFieldsProps {
  disabled: boolean;
}

const EditableFields: React.FC<EditableFieldsProps> = (props) => {
  // Props
  const { disabled } = props;

  // Hooks
  const { setValue, control } = useFormContext<AudienceSettingsDataProps>();

  // Render
  return (
    <ColumnStack>
      <HeadingSix>فیلد های قابل ویرایش توسط مخاطب</HeadingSix>
      <RowStack sx={{ paddingX: '2rem' }}>
        <ColumnStack spacing={1} sx={{ minWidth: '15rem' }}>
          {editableAudienceFields.slice(0, 2).map((fieldName) => (
            <Controller
              key={`required-fields-${fieldName}`}
              name={`editableAudienceFields.${fieldName}`}
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <CheckboxWithLabel
                  disabled={disabled}
                  label={getProfileFieldLabel(fieldName)}
                  checkboxProps={{
                    color: 'info',
                    checked: field.value,
                    onChange: (e, checked) =>
                      setValue(`editableAudienceFields.${fieldName}`, checked),
                  }}
                />
              )}
            />
          ))}
        </ColumnStack>
        <ColumnStack spacing={1}>
          {editableAudienceFields.slice(2).map((fieldName) => (
            <Controller
              key={`required-fields-${fieldName}`}
              name={`editableAudienceFields.${fieldName}`}
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <CheckboxWithLabel
                  disabled={disabled}
                  label={getProfileFieldLabel(fieldName)}
                  checkboxProps={{
                    color: 'info',
                    checked: field.value,
                    onChange: (e, checked) =>
                      setValue(`editableAudienceFields.${fieldName}`, checked),
                  }}
                />
              )}
            />
          ))}
        </ColumnStack>
      </RowStack>
    </ColumnStack>
  );
};

export default EditableFields;

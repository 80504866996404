import { type FC } from 'react';

// Custom Shared Components
import FormLoadingButton, { type FormLoadingButtonProps } from '../Core';

// Custom Icon Components
import SaveIcon from 'core/components/icons/Save';

// Types
export interface SaveLoadingButtonProps extends FormLoadingButtonProps {}

const SaveLoadingButton: FC<SaveLoadingButtonProps> = (props) => {
  // Props
  const { children = 'ذخیره', startIcon = <SaveIcon />, ...otherProps } = props;

  return (
    <FormLoadingButton color='info' startIcon={startIcon} {...otherProps}>
      {children}
    </FormLoadingButton>
  );
};

export default SaveLoadingButton;

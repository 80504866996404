// Pages
import Logout from 'features/manager/auth/components/Logout';
import Enable2FA from 'features/manager/auth/components/shared/EnableTwoFactorAuth';
import Profile from 'features/manager/auth/components/shared/Profile';
import NotAllowed from 'features/manager/auth/components/shared/NotAllowed';
import NotFound from 'features/manager/auth/components/shared/NotFound';

// Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'common';
const subFeature: SubFeatureType = 'profile';

export const commonRoutes: ProtectedRouteProps[] = [
  {
    path: '/enable-2fa',
    Component: Enable2FA,
    feature,
    subFeature,
  },
  {
    path: '/profile',
    Component: Profile,
    feature,
    subFeature,
  },
  {
    path: '/access-denied',
    Component: NotAllowed,
    feature,
    subFeature,
  },
  {
    path: '/404',
    Component: NotFound,
    feature,
    subFeature,
  },
  {
    path: '/logout',
    Component: Logout,
    feature,
    subFeature,
  },
];

// Custom Utilities
import { removeDuplicates } from 'core/utilities/helper/helperPack';
import {
  deprecatedGetFileModelByLocation,
  getSubFeatures,
  isFileLocationValid,
} from 'features/file/files/utilities/file';
import {
  locationToPlural,
  locationToSingular,
} from 'features/file/files/utilities/file';

// Custom Types
import type {
  FileBaseDataToSaveProps,
  FileDataToSaveProps,
} from 'features/file/files/types/fileToSave';
import {
  FileLocationSubFeatureType,
  FileLocationFeatureType,
  FileLocationType,
} from 'features/file/files/types';

/**
 * Bakes the file data and returns the baked file data to save.
 *
 * @param {FileBaseDataToSaveProps} data - The file base data to save.
 * @returns {DeprecatedFileDataToSaveProps} The baked file data to save.
 */
export const bakeFile = (
  data: FileBaseDataToSaveProps
): FileDataToSaveProps => ({
  alt: '',
  description: '',
  location: data.location,
  model: deprecatedGetFileModelByLocation(data.location),
  relatedModelId: data.relatedModelId,
  files: data.files,
});

/**
 * Bakes the location value and add singular or plural to it base on input location.
 * if input is singluar, the plural will also append and if is plural the singular will append.
 * @example bakeLocationParam('menu') // Return => ['menus','menu']
 * @param {string} location - The locations to bake.
 * @returns {[FileLocationSubFeatureType,FileLocationFeatureType]} the baked location param.
 */
const bakeLocationParam = (
  location: string
): [FileLocationSubFeatureType, FileLocationFeatureType] => [
  locationToPlural(location),
  locationToSingular(location),
];

/**
 * Bakes the location array.
 *
 * @param {string[]} locations - The array of locations to bake.
 * @returns {string[]} Return the baked locations
 */
export const getBakedLocations = (locations: string[]): string[] => {
  const bakedLocations: string[] = [];

  locations.forEach((location) => {
    // Add both plural and singular version.
    const subFeatures: FileLocationType[] = getSubFeatures(
      locationToSingular(location === 'pageBuilder' ? 'template' : location)
    ).flatMap((item) => bakeLocationParam(item));

    if (subFeatures.length > 0) bakedLocations.push(...subFeatures);
  });

  const totalLocations = removeDuplicates(bakedLocations).map(
    (item) => `"${item}"`
  );

  return totalLocations;
};

export const bakeTypeQuery = (
  types: string[],
  searchParams?: URLSearchParams
): string[] => {
  const bakedLocations: string[] = [];

  const totalLocations = removeDuplicates(types).map((item) => `"${item}"`);

  if (searchParams) searchParams.set('type', `[${totalLocations}]`);

  return totalLocations;
};

// TODO: This is the new one. use it when backend is ready.
export const bakeLocation = (
  location: string[],
  searchParams?: URLSearchParams
): string[] => {
  const bakedLocations: string[] = [];

  location.forEach((loc) => {
    if (isFileLocationValid(loc)) {
      const subFeatures: FileLocationType[] = [];

      // Add both plural and singular version.
      getSubFeatures(
        locationToSingular(loc === 'customPages' ? 'template' : loc)
      ).forEach((item) => subFeatures.push(...bakeLocationParam(item)));

      if (subFeatures.length > 0) bakedLocations.push(...subFeatures);
    }
  });

  const totalLocations = removeDuplicates(bakedLocations).map(
    (item) => `"${item}"`
  );

  if (searchParams) searchParams.set('location', `[${totalLocations}]`);

  return totalLocations;
};

// Feature Types
import type { PatternFormAlertProps } from 'features/appBuilder/patterns/core/types/item/formAlert';
import type { FormPatternAlertProps } from 'features/appBuilder/patterns/features/form/types/alert';

const commonProps: PatternFormAlertProps = {
  isActive: true,
  padding: '0px',
  fontColor: '#12252E',
  fontSize: '12',
  textAlign: 'center',
  textDirection: 'rtl',
  text: '',
  bgColor: 'rgba(0, 0, 0, 0)',
  variables: [],
};

export const formPatterAlertDefaults: FormPatternAlertProps = {
  isActive: true,
  expired: {
    ...commonProps,
    variables: ['{pubDate}', '{pubHour}', '{expDate}', '{expHour}'],
    text: 'این فرم از تاریخ {pubDate} ساعت {pubHour} تا تاریخ {expDate} ساعت {expHour} در دسترس بوده و اکنون منقضی شده است.',
  },
  accessible: {
    ...commonProps,
    variables: ['{pubDate}', '{pubHour}', '{expDate}', '{expHour}'],
    text: 'این فرم از تاریخ {pubDate} ساعت {pubHour} تا تاریخ {expDate} ساعت {expHour} در دسترس خواهد بود.',
  },
  cancelText: {
    ...commonProps,
    fontSize: '16',
    text: 'در صورت انصراف، تمامی تغییرات ثبت شده از بین خواهند رفت. آیا می خواهید تغییرات را لغو کنید؟',
  },
  submitText: {
    ...commonProps,
    fontSize: '24',
    variables: ['{title}'],
    text: 'بازخورد شما در فرم «{title}» با موفقیت ذخیره شد.',
  },
  fileUploaded: {
    ...commonProps,
    fontSize: '14',
    fontColor: '#FFFFFF',
    bgColor: '#026C38',
    text: 'فایل‌ با موفقیت بارگذاری شد',
  },
  fileUploading: {
    ...commonProps,
    fontSize: '14',
    bgColor: '#109CF1',
    text: 'در حال بارگذاری فایل',
  },
};

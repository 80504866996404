// Custom Utilities
import { genFormTextInput } from 'features/form/forms/utilities/input/initial/text';
import { genFormNumberInput } from 'features/form/forms/utilities/input/initial/number';
import { genFormAgreementInput } from 'features/form/forms/utilities/input/initial/agreement';
import { genFormFileInput } from 'features/form/forms/utilities/input/initial/file';
import { genFormCheckboxInput } from 'features/form/forms/utilities/input/initial/checkbox';
import { genFormRadioInput } from 'features/form/forms/utilities/input/initial/radio';
import { genFormTextareaInput } from 'features/form/forms/utilities/input/initial/textarea';
import { genFormSelectInput } from 'features/form/forms/utilities/input/initial/select';
import { genFormMobileInput } from 'features/form/forms/utilities/input/initial/mobile';
import { genFormEmailInput } from 'features/form/forms/utilities/input/initial/email';
import { genFormNationalIdInput } from 'features/form/forms/utilities/input/initial/nationalId';
import { genFormDateInput } from 'features/form/forms/utilities/input/initial/date';
import { genFormTimeInput } from 'features/form/forms/utilities/input/initial/time';
import { genFormRateInput } from 'features/form/forms/utilities/input/initial/rate';
import { genFormScoreInput } from 'features/form/forms/utilities/input/initial/score';

// Custom Types
import type { FormInputType } from 'features/form/forms/types/item/input/type';
import type { FormInputInitialParams } from 'features/form/forms/utilities/input/initial/common';

interface Params extends FormInputInitialParams {
  type: FormInputType;
}

export const genFormInitialInput = (params: Params) => {
  const { type, ...otherParams } = params;

  switch (type) {
    case 'text':
      return genFormTextInput({ ...otherParams });

    case 'number':
      return genFormNumberInput({ ...otherParams });

    case 'agreement':
      return genFormAgreementInput({ ...otherParams });

    case 'file':
      return genFormFileInput({ ...otherParams });

    case 'radio':
      return genFormRadioInput({ ...otherParams });

    case 'checkbox':
      return genFormCheckboxInput({ ...otherParams });

    case 'textarea':
      return genFormTextareaInput({ ...otherParams });

    case 'select':
      return genFormSelectInput({ ...otherParams });

    case 'mobile':
      return genFormMobileInput({ ...otherParams });

    case 'email':
      return genFormEmailInput({ ...otherParams });

    case 'nationalId':
      return genFormNationalIdInput({ ...otherParams });

    case 'date':
      return genFormDateInput({ ...otherParams });

    case 'time':
      return genFormTimeInput({ ...otherParams });

    case 'rate':
      return genFormRateInput({ ...otherParams });

    case 'score':
    default:
      return genFormScoreInput({ ...otherParams });
  }
};

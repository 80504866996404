import * as React from 'react';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Types
import type { RowStackProps } from 'core/components/shared/Stack/RowStack';

interface PrefixerProps extends RowStackProps {
  Prefix: React.ReactNode;
}

const Prefixer = React.forwardRef((props: PrefixerProps, ref: any) => {
  // Props
  const { sx, Prefix, children, spacing = '.5rem', ...otherProps } = props;

  // Render
  return (
    <RowStack
      ref={ref}
      spacing={spacing}
      sx={{
        alignItems: 'center',
        ...sx,
      }}
      {...otherProps}
    >
      {Prefix}
      {children}
    </RowStack>
  );
});

export default Prefixer;

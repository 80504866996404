import * as Yup from 'yup';

export const emailSchema = Yup.string()
  .email('ایمیل وارد شده صحیح نمی‌باشد.')
  .required('وارد کردن ایمیل اجباری است.')
  .label('ایمیل');

export const passwordSchema = Yup.string()
  .required('وارد کردن کلمه عبور الزامی می‌باشد.')
  .label('کلمه عبور');

export const passwordRepeatSchema = Yup.string()
  .required('وارد کردن تکرار کلمه عبور الزامی می‌باشد.')
  .label('کلمه عبور');

export const titleSchema = Yup.string()
  .required('وارد کردن عنوان اجباری می‌باشد.')
  .label('عنوان');

export const firstNameSchema = Yup.string()
  .required('وارد کردن نام اجباری می‌باشد.')
  .label('نام');

export const lastNameSchema = Yup.string()

  .required('وارد کردن نام‌خانوادگی اجباری می‌باشد.')
  .label('نام‌خانوادگی');

export const introduction = Yup.string()
  .required('وارد کردن متن معرفی اجباری می‌باشد.')
  .label('متن معرفی');

export const twoFactorVerificationCode = Yup.string()
  .required('وارد کردن کد شش رقمی اجباری می‌باشد.')
  .label('کد شش رقمی');

export const slugSchema = Yup.string().label('اسلاگ');

export const linkSchema = Yup.string().label('لینک');

export const messageSchema = Yup.string()
  .required('وارد کردن متن پیام اجباری‌ می‌باشد.')
  .label('متن پیام');

export const multilineEmailsSchema = Yup.string()
  .required('وارد کردن حداقل یک ایمیل اجباری می‌باشد.')
  .matches(
    /^[\w]+@([\w-]+\.)+[\w-]{2,4}$/gm,
    'در هر خط تنها یک ایمیل وارد کنید.'
  )
  .label('ایمیل‌ها');

export const formDescriptionSchema = Yup.string()
  .required('وارد کردن متن آغازین فرم اجباری می‌باشد.')
  .label('متن آغازین فرم');

// @deprecated since v2.0.0
export const formDescriptionSchemaDeprecated = Yup.string()
  .required('وارد کردن متن ابتدایی معرفی فرم اجباری می‌باشد.')
  .label('');

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface CellWidthIconProps extends SvgIconProps {}

const CellWidthIcon: React.FC<CellWidthIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M21,5c-0.3,0-0.5,0.1-0.7,0.3C20.1,5.5,20,5.7,20,6v12c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3
c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7V6c0-0.3-0.1-0.5-0.3-0.7C21.5,5.1,21.3,5,21,5z'
    />
    <path
      d='M16.7,8.3C16.5,8.1,16.3,8,16,8c-0.3,0-0.5,0.1-0.7,0.3C15.1,8.5,15,8.7,15,9c0,0.3,0.1,0.5,0.3,0.7l1.3,1.3H7.4l1.3-1.3
C8.9,9.5,9,9.3,9,9c0-0.3-0.1-0.5-0.3-0.7C8.5,8.1,8.3,8,8,8C7.7,8,7.5,8.1,7.3,8.3l-3,3C4.1,11.5,4,11.7,4,12
c0,0.3,0.1,0.5,0.3,0.7l3,3C7.5,15.9,7.7,16,8,16c0.3,0,0.5-0.1,0.7-0.3C8.9,15.5,9,15.3,9,15c0-0.3-0.1-0.5-0.3-0.7L7.4,13h9.2
l-1.3,1.3c-0.1,0.1-0.2,0.2-0.2,0.3S15,14.9,15,15c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2
C15.7,16,15.9,16,16,16c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.2l3-3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7L16.7,8.3z'
    />
    <path
      d='M3,5C2.7,5,2.5,5.1,2.3,5.3C2.1,5.5,2,5.7,2,6v12c0,0.3,0.1,0.5,0.3,0.7C2.5,18.9,2.7,19,3,19s0.5-0.1,0.7-0.3
C3.9,18.5,4,18.3,4,18V6c0-0.3-0.1-0.5-0.3-0.7C3.5,5.1,3.3,5,3,5z'
    />
  </IconBuilder>
);

export default CellWidthIcon;

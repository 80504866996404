import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  styled,
} from '@mui/material';

export interface IconButtonProps extends MuiIconButtonProps {}

const IconButton = styled(MuiIconButton)<IconButtonProps>(() => ({}));

export default IconButton;

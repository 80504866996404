import {
  ApiAudienceGroupProps,
  ApiAudienceGroupSummaryProps,
} from 'features/audience/groups/types/api';
import {
  AudienceGroupProps,
  AudienceGroupSummaryProps,
} from 'features/audience/groups/types';
import { bakeAudiencePermissions } from 'features/audience/permissions/utilities/api/baker';

/**
 * Transforms an audience group object into an API-compatible audience group object.
 *
 * @param {AudienceGroupProps} audienceGroup - The audience group object to transform.
 * @returns {ApiAudienceGroupProps} The transformed API audience group object.
 */
export const bakeAudienceGroup = (
  audienceGroup: AudienceGroupProps
): ApiAudienceGroupProps => {
  return {
    _id: audienceGroup.id,
    data: {
      title: audienceGroup.data.title,
      permissions: bakeAudiencePermissions(audienceGroup.data.permissions),
      countOfAudience: audienceGroup.data.audienceCount,
    },
  };
};

/**
 * Transforms an array of audience group objects into an array of API-compatible audience group objects.
 *
 * @param {AudienceGroupProps[]} audienceGroups - The array of audience group objects to transform.
 * @returns {ApiAudienceGroupProps[]} The array of transformed API audience group objects.
 */
export const bakeAudienceGroups = (
  audienceGroups: AudienceGroupProps[]
): ApiAudienceGroupProps[] => {
  const apiAudienceGroups: ApiAudienceGroupProps[] = [];
  audienceGroups.forEach((audienceGroup) =>
    apiAudienceGroups.push(bakeAudienceGroup(audienceGroup))
  );
  return apiAudienceGroups;
};

/**
 * Transforms an audience group summary object into an API-compatible audience group summary object.
 *
 * @param {AudienceGroupSummaryProps} audienceGroupSummary - The audience group summary object to transform.
 * @returns {ApiAudienceGroupSummaryProps} The transformed API audience group summary object.
 */
export const bakeAudienceGroupSummary = (
  audienceGroupSummary: AudienceGroupSummaryProps
): ApiAudienceGroupSummaryProps => ({
  id: audienceGroupSummary.id,
  data: {
    title: audienceGroupSummary.data.title,
    features: [],
    countOfAudience: audienceGroupSummary.data.audienceCount || 0,
  },
});

/**
 * Transforms an array of audience group summary objects into an array of API-compatible audience group summary objects.
 *
 * @param {AudienceGroupSummaryProps[]} audienceGroupsSummary - The array of audience group summary objects to transform.
 * @returns {ApiAudienceGroupSummaryProps[]} The array of transformed API audience group summary objects.
 */
export const bakeAudienceGroupsSummary = (
  audienceGroupsSummary: AudienceGroupSummaryProps[]
): ApiAudienceGroupSummaryProps[] => {
  const groups: ApiAudienceGroupSummaryProps[] = [];
  audienceGroupsSummary.forEach((audienceGroupSummary) =>
    groups.push(bakeAudienceGroupSummary(audienceGroupSummary))
  );
  return groups;
};

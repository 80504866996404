import { ProcessFilterItemProps } from '../../components/details/Actions/Filter/ViewSettings/ProcessFields';

export const columnCountOptions = [
  { key: 'column-one-1', value: '1', label: 'یک ستونه' },
  { key: 'column-two-2', value: '2', label: 'دو ستونه' },
  { key: 'column-three-3', value: '3', label: 'سه ستونه' },
];

export const evaluationFilterOptions: ProcessFilterItemProps[] = [
  {
    label: 'عنوان ارزیابی',
    name: 'title',
  },
  {
    label: 'سوال ارزیابی',
    name: 'questionTitle',
  },
  {
    label: 'گزینه انتخاب شده',
    name: 'selectedOpt',
  },
  {
    label: 'پیام پیشفرض',
    name: 'message',
  },
  {
    label: 'نام ارزیاب',
    name: 'evaluatorFullname',
  },
  {
    label: 'کامنت',
    name: 'comment',
  },
  {
    label: 'تاریخ',
    name: 'date',
  },
  {
    label: 'اطلاعات فیلد بازبینی',
    name: 'review',
  },
];

export const managerQuestionFilterOptions: ProcessFilterItemProps[] = [
  {
    label: 'جزئیات پرسش',
    name: 'questionDetails',
  },
];

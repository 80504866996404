/**
 * Checks if the provided hexadecimal color code represents a transparent color.
 *
 * @param {string} hexColor - The hexadecimal color code to check.
 * @returns {boolean} - Returns true if the color is transparent, false otherwise.
 */
export const isHexTransparent = (hexColor: string): boolean => {
  hexColor = hexColor.replace('#', '');

  if (hexColor.length === 8) {
    const alpha = parseInt(hexColor.slice(6), 16);

    if (alpha < 255) return true;
  }

  return false;
};

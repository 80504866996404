import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Utilities
import { initialCreator } from 'core/utilities/creator/initial';

// Types
import { ManagerProps } from 'features/manager/managers/types';

const initialState = (): ManagerProps => {
  const localManagerData = localStorage.getItem('manager');
  return localManagerData
    ? JSON.parse(localManagerData)
    : ({
        id: '',
        data: {
          createdAt: '',
          creator: initialCreator,
          email: '',
          groupId: '',
          lastLogins: [],
          relatedMediaId: '',
          biography: '',
          firstName: '',
          is2FAEnabled: false,
          isActive: false,
          lastName: '',
        },
      } as ManagerProps);
};

export const managerSlice = createSlice({
  name: 'manager',
  initialState,
  reducers: {
    initializeManager: (
      state: ManagerProps,
      action: PayloadAction<ManagerProps>
    ) => {
      state = action.payload;
      localStorage.setItem('manager', JSON.stringify(action.payload));
      return state;
    },
  },
});

export const { initializeManager } = managerSlice.actions;
export default managerSlice.reducer;

import { type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Custom Core Components
import InputBase from 'core/components/base/inputs/InputBase';

// Custom Common Components
import RowStack, {
  type RowStackProps,
} from 'core/components/shared/Stack/RowStack';

// Custom Feature Components
import RequiredIconButton from 'features/form/shared/components/iconButton/Required';

// Custom Types
import type { FormProps } from 'features/form/forms/types/item';
export interface InputLabelAndRequiredProps extends RowStackProps {
  index: number;
  disabled?: boolean;
  onUpdate?: () => void;
}

const InputLabelAndRequired: FC<InputLabelAndRequiredProps> = (props) => {
  // Props
  const { index, disabled, spacing = 0.5, sx, onUpdate, ...otherProps } = props;

  // Hooks
  const { control, setValue } = useFormContext<FormProps>();

  return (
    <RowStack
      spacing={spacing}
      sx={{ width: '100%', alignItems: 'flex-start', ...sx }}
      {...otherProps}
    >
      <Controller
        control={control}
        name={`data.inputs.${index}.data.isRequired`}
        render={({ field }) => (
          <RequiredIconButton
            disabled={disabled}
            required={field.value}
            onClick={() => {
              setValue(field.name, !field.value);
              if (onUpdate) onUpdate();
            }}
          />
        )}
      />
      <Controller
        control={control}
        name={`data.inputs.${index}.data.label`}
        rules={{ onChange: onUpdate }}
        defaultValue=''
        render={({ field }) => (
          <InputBase
            disabled={disabled}
            multiline
            minRows={1}
            maxRows={4}
            fullWidth
            required
            placeholder='عنوان را وارد کنید'
            sx={{
              pt: '0.5rem',
              ':before': { borderBottom: 'none' },
            }}
            {...field}
          />
        )}
      />
    </RowStack>
  );
};

export default InputLabelAndRequired;

import * as React from 'react';
import { Link, SxProps, useTheme } from '@mui/material';

// Custom Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import BodyOne from 'core/components/shared/Typography/BodyOne';

// Custom Icons
import FileIcon from 'core/components/icons/File';

// Custom Types
import type { FileFormat } from 'features/file/files/types';

interface FilePreviewAndDownloadProps {
  src: string;
  sx?: SxProps;
}

const FilePreviewAndDownload: React.FC<FilePreviewAndDownloadProps> = (
  props
) => {
  const { src, sx } = props;
  const { palette, shadows } = useTheme();

  const availableExtensions = [
    '.png',
    '.jpeg',
    '.jpg',
    '.svg',
    '.bmp',
    '.tiff',
    '.gif',
    '.MKV',
    '.mp4',
    '.avi',
    '.mov',
    '.webm',
    '.wmv',
    '.mp3',
    '.ogg',
    '.mpeg',
    '.wav',
    '.pdf',
    '.docx',
    '.doc',
    '.xlsx',
    '.txt',
    '.csv',
    '.zip',
    '.rar',
    '.xml',
  ];

  const getFileExtension = (name: string): FileFormat | 'none' => {
    const lastDot = name.lastIndexOf('.');

    if (lastDot > -1) {
      const extension = name.slice(lastDot);
      const extLower: string[] = [];

      availableExtensions.forEach((ext) =>
        extLower.push(ext.toLocaleLowerCase())
      );

      if (extLower.includes(extension)) return extension as FileFormat;
    }

    return 'none';
  };

  return (
    <ColumnStack spacing={1}>
      <Link
        href={src}
        target='_blank'
        sx={{
          width: '6.25rem',
          height: '5rem',
          borderRadius: '0.5rem',
          backgroundColor: palette.background.default,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          cursor: 'pointer',
          textDecoration: 'none',
          transition: 'all .5s',
          ...sx,
          ':hover': {
            boxShadow: shadows[2],
          },
        }}
      >
        {['.jpg', '.jpeg', '.png', '.svg'].includes(getFileExtension(src)) ? (
          <img
            src={src}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        ) : (
          <ColumnStack spacing={0} alignItems='center'>
            <FileIcon sx={{ color: palette.text.secondary }} />
            {/* TODO: Icon color should change to => background/linear <=. we don't have it now. */}
            <BodyOne sx={{ color: palette.text.secondary, fontSize: '.75rem' }}>
              {getFileExtension(src).replaceAll('.', '').toUpperCase()}
            </BodyOne>
          </ColumnStack>
        )}
      </Link>
    </ColumnStack>
  );
};

export default FilePreviewAndDownload;

import * as React from 'react';

// Core Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import HeadingOne from 'core/components/shared/Typography/HeadingOne';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import ActionsBox from 'core/components/shared/Box/Actions';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import PageHeadConfigs from 'core/components/shared/PageTitle';

interface NotAllowedProps {}

const NotAllowed: React.FC<NotAllowedProps> = (props) => {
  return (
    <>
      <PageHeadConfigs pageTitle='دسترسی غیرمجاز!' />
      <ActionsBox>
        <PageTitle>عدم دسترسی</PageTitle>
      </ActionsBox>
      <RoundPaper>
        <HeadingOne marginBottom='1rem'>دسترسی غیرمجاز</HeadingOne>
        <BodyOne>
          کاربر گرامی! شما دارای دسترسی مجاز به این صفحه نمی‌باشید. لطفاً از
          طریق منوی سمت راست صفحه‌ی مورد نظر خود را انتخاب نمایید.
        </BodyOne>
      </RoundPaper>
    </>
  );
};

export default NotAllowed;

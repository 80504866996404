import { type FC } from 'react';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import Pagination from 'core/components/shared/Filter/Pagination';
import PageSize from 'core/components/shared/Filter/PageSize';
import BodyOne from '../Typography/BodyOne';

// Custom Core Components
import Checkbox from 'core/components/base/inputs/Checkbox';
import Paper from 'core/components/base/surfaces/Paper';
import Table from 'core/components/base/display/Table';
import TableCell from 'core/components/base/display/TableCell';
import TableHead from 'core/components/base/display/TableHead';
import TableRow from 'core/components/base/display/TableRow';
import TableBody from 'core/components/base/display/TableBody';
import CircularProgress from 'core/components/base/feedback/CircularProgress';
import Collapse from 'core/components/base/utils/Collapse';
import Button from 'core/components/base/inputs/Button';

// Custom Utilities
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';

import { type DataGridColumn, DataGridRowValueProps } from './dataGrid';
import type { TableHeadProps } from 'core/components/base/display/TableHead';
import type { PaperProps } from 'core/components/base/surfaces/Paper';
import type { PageProps } from 'core/types/shared/pagination';
export interface DataGridProps {
  disabled?: boolean;
  disabledSelectAll?: boolean;
  fetching?: boolean;
  selectable?: boolean;
  columns?: DataGridColumn[];
  rows?: DataGridRowValueProps[];
  headProps?: TableHeadProps;
  paperProps?: PaperProps;
  page?: PageProps;
  selections?: string[];
  selectionLabel?: string;
  allIds?: string[];
  showSelectAll?: boolean;
  onPageNumChange?: (pageNumber: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
  onSelectionChange?: (selectedIds: string[]) => void;
}

const DataGrid: FC<DataGridProps> = (props) => {
  // Props
  const {
    columns,
    disabled,
    fetching,
    disabledSelectAll,
    page,
    headProps,
    selectable = true,
    rows,
    selections,
    paperProps,
    selectionLabel = 'آیتم',
    showSelectAll = false,
    allIds = [],
    onPageNumChange,
    onPageSizeChange,
    onSelectionChange,
  } = props;

  // Hooks
  const handleSelectAllInThisPage = () => {
    if (selections && rows) {
      let newSelectionArray: string[] = [];
      if (selections.length === rows.length) newSelectionArray = [];
      else rows.forEach((row) => newSelectionArray.push(row.id));

      if (onSelectionChange) onSelectionChange(newSelectionArray);
    }
  };

  const handleSelect = (rowId: string) => () => {
    let newSelectionArray = selections ? [...selections] : [];

    if (
      rows &&
      newSelectionArray.length !== 0 &&
      newSelectionArray.length === allIds.length
    ) {
      newSelectionArray = rows.map((row) => row.id);
    }

    const rowIdIndex = newSelectionArray.findIndex((id) => id === rowId);

    if (rowIdIndex > -1) newSelectionArray.splice(rowIdIndex, 1);
    else newSelectionArray.push(rowId);

    if (onSelectionChange) onSelectionChange(newSelectionArray);
  };

  const handlePageSizeChange = (pageSize: number) => {
    if (onPageSizeChange) onPageSizeChange(pageSize);
  };

  const handlePageNumChange = (pageNum: number) => {
    if (onPageNumChange) onPageNumChange(pageNum);
  };

  // Render
  return (
    <ColumnStack>
      <Collapse
        unmountOnExit
        in={
          showSelectAll &&
          rows &&
          selections &&
          allIds.length !== rows.length &&
          (selections.length === rows.length ||
            selections.length === allIds.length)
        }
      >
        {selections?.length === rows?.length && (
          <RowStack alignItems='center' spacing={0.5}>
            <BodyOne>{`${selections?.length.toPersian()} ${selectionLabel} انتخاب شده است.`}</BodyOne>
            <Button
              disabled={disabled}
              color='info'
              onClick={() => {
                if (onSelectionChange) {
                  onSelectionChange(allIds);
                }
              }}
            >{`انتخاب همه ${allIds.length.toPersian()} ${selectionLabel}`}</Button>
          </RowStack>
        )}
        {selections?.length === allIds.length && (
          <RowStack alignItems='center' spacing={0.5}>
            <BodyOne>{`همه ${selections?.length.toPersian()} ${selectionLabel} انتخاب شده است.`}</BodyOne>
            <Button
              disabled={disabled}
              color='info'
              onClick={() => {
                if (onSelectionChange) {
                  onSelectionChange([]);
                }
              }}
            >
              لغو انتخاب
            </Button>
          </RowStack>
        )}
      </Collapse>
      <Paper
        sx={{
          borderRadius: '0.5rem',
          boxShadow: 'none',
          overflow: 'auto',
          ...customRoundedScrollbarStyles,
          ...paperProps?.sx,
        }}
        {...paperProps}
      >
        <Table>
          <TableHead {...headProps}>
            <TableRow
              sx={{ borderBottom: '1px solid', borderColor: 'divider' }}
            >
              {selectable && (
                <TableCell padding='checkbox'>
                  {!disabledSelectAll && (
                    <Checkbox
                      disabled={disabled || rows?.length === 0}
                      color='info'
                      onClick={handleSelectAllInThisPage}
                      checked={
                        selections &&
                        rows &&
                        selections.length > 0 &&
                        (selections.length === rows.length ||
                          (showSelectAll &&
                            selections.length === allIds.length))
                      }
                      indeterminate={
                        selections &&
                        rows &&
                        selections.length > 0 &&
                        (selections.length < rows.length ||
                          (showSelectAll &&
                            selectionLabel.length < allIds.length))
                      }
                    />
                  )}
                </TableCell>
              )}
              {columns?.map(
                ({ field, headerTitle, renderCell, sx, ...columnProps }) => (
                  <TableCell
                    key={field + headerTitle}
                    sx={{ whiteSpace: 'nowrap', ...sx }}
                    {...columnProps}
                  >
                    {headerTitle}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.length > 0 ? (
              rows.map((row, rowIndex) => (
                <TableRow
                  key={row.id}
                  sx={{
                    borderBottom:
                      rowIndex + 1 < rows?.length ? '1px solid' : undefined,
                    borderColor:
                      rowIndex + 1 < rows?.length ? 'divider' : undefined,
                    position: 'relative',
                  }}
                >
                  {selectable && (
                    <TableCell padding='checkbox'>
                      <Checkbox
                        checked={selections?.includes(row.id)}
                        onClick={handleSelect(row.id)}
                        disabled={disabled}
                        color='info'
                      />
                    </TableCell>
                  )}
                  {columns?.map(
                    ({
                      field,
                      headerTitle,
                      renderCell,
                      sx,
                      ...columnProps
                    }) => (
                      <TableCell
                        key={`row-${rowIndex}-${field}`}
                        sx={{
                          py: 2,
                          whiteSpace: 'nowrap',
                          ...sx,
                        }}
                        {...columnProps}
                      >
                        {renderCell
                          ? renderCell({
                              row,
                              field,
                              headerTitle,
                              sx,
                              ...columnProps,
                            })
                          : row[field] || '-'}
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))
            ) : fetching ? (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  <RowStack
                    spacing={1}
                    sx={{ mx: 'auto', maxWidth: 'fit-contnet' }}
                  >
                    <BodyTwo>در حال دریافت اطلاعات</BodyTwo>{' '}
                    <CircularProgress size={16} />
                  </RowStack>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  داده‌ای جهت نمایش وجود ندارد
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
      {page && (
        <RowStack alignItems='center' spacing={1}>
          <PageSize
            disabled={disabled}
            page={page}
            onSizeChange={handlePageSizeChange}
            onPageChange={handlePageNumChange}
            sx={{
              backgroundColor: 'background.paper',
              height: '2rem',
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  mt: 0.25,
                  boxShadow: 'none',
                  border: '2px solid',
                  borderRadius: '0.5rem',
                  borderColor: 'divider',
                },
              },
              MenuListProps: {
                sx: { p: 0 },
              },
            }}
          />

          {page.totalDocs > 0 && (
            <Pagination
              page={page}
              disabled={disabled}
              onPageChange={handlePageNumChange}
              rtl
            />
          )}
        </RowStack>
      )}
    </ColumnStack>
  );
};

export default DataGrid;

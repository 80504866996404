// Custom Types
import type { PermissionProps } from 'core/types/permission';

export const permissionsList: PermissionProps[] = [
  {
    feature: 'content',
    subFeatures: ['contents', 'categories', 'tags'],
  },
  {
    feature: 'file',
    subFeatures: ['files'],
  },
  {
    feature: 'form',
    subFeatures: ['forms', 'feedbacks', 'processes'],
  },
  {
    feature: 'manager',
    subFeatures: ['managers', 'managerGroups', 'managerSettings'],
  },
  {
    feature: 'audience',
    subFeatures: [
      'audiences',
      'audienceGroups',
      'audienceSettings',
      'audienceFolders',
      'audienceAccesses',
    ],
  },
  {
    feature: 'menu',
    subFeatures: ['menus'],
  },
  {
    feature: 'banner',
    subFeatures: ['banners'],
  },
  {
    feature: 'template',
    subFeatures: ['templates', 'customPages', 'patterns', 'skins'],
  },
  {
    feature: 'data',
    subFeatures: ['sheets'],
  },
  {
    feature: 'ticket',
    subFeatures: ['tickets', 'ticketCategories'],
  },
];

export const getFeatureCount = (): {
  featureCount: number;
  subFeatureCount: number;
} => {
  let featureCount = 0;
  let subFeatureCount = 0;

  permissionsList.forEach((permission) => {
    featureCount++;
    permission.subFeatures.forEach(() => {
      subFeatureCount++;
    });
  });

  return {
    featureCount,
    subFeatureCount,
  };
};

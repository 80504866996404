import * as React from 'react';

// Custom Common Components
import CheckboxWithLabel from 'core/components/shared/Checkbox/CheckboxWithLabel';

// Custom Utilities
import {
  toggleFeedbackHideEmpties,
  useSelectFeedbackHideEmpties,
} from 'features/form/feedbacks/store';

// Custom Types
interface HideEmptiesCheckboxProps {}

const HideEmptiesCheckbox: React.FC<HideEmptiesCheckboxProps> = (props) => {
  // Context
  const hideEmpties = useSelectFeedbackHideEmpties();

  // Utilities
  const handleChange = (checked: boolean) => {
    toggleFeedbackHideEmpties(checked);
  };

  return (
    <CheckboxWithLabel
      checkboxProps={{
        checked: hideEmpties,
        onChange: (e, checked) => handleChange(checked),
      }}
      label='مخفی کردن بخش‌ها و فیلدهای خالی'
    />
  );
};

export default HideEmptiesCheckbox;

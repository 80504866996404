// Feature Types
import { forLoop } from 'core/utilities/helper';
import { ContentDetailsDataProps } from 'features/content/contents/types/details';
import {
  ContentBlockItemDataProps,
  ContentBlockItemProps,
} from 'features/content/contents/types/details/block';
import { ContentBlockGalleryItemDataProps } from 'features/content/contents/types/details/block/gallery';

interface ApiContentBlockItemProps
  extends Omit<ContentBlockItemDataProps, 'gallery'> {
  gallery: ContentBlockGalleryItemDataProps[];
}

interface ApiContentDetailsProps
  extends Omit<
    ContentDetailsDataProps,
    'tags' | 'categories' | 'blocks' | 'creator'
  > {
  tags: string[];
  categories: string[];
  blocks: ApiContentBlockItemProps[];
  creator: string;
}

const bakeBlockItems = (
  blocks: ContentBlockItemProps[]
): ApiContentBlockItemProps[] => {
  const blockItems: ApiContentBlockItemProps[] = [];

  forLoop(blocks, (block) => {
    const { gallery, ...otherProps } = block.data;

    blockItems.push({
      ...otherProps,
      gallery: gallery.map((g) => g.data),
    });
  });

  return blockItems;
};

export const bakeContentDetails = (
  data: ContentDetailsDataProps
): ApiContentDetailsProps => {
  const { tags, categories, blocks, creator, ...otherProps } = data;

  return {
    ...otherProps,
    creator: creator.id || '',
    tags: tags?.map((tag) => tag.id) || [],
    categories: categories?.map((category) => category.id) || [],
    blocks: bakeBlockItems(blocks),
  };
};

import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

// Types
import type { FC } from 'react';

// Custom Hooks
import useMount from 'core/hooks/useMount';

// Common Components
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import PageTitle from 'core/components/shared/Typography/PageTitle';

// Feature Components
import TemplateBody from 'features/appBuilder/templates/components/details/TemplateBody';

// Custom Utilities
import { useTemplateDoc } from 'features/appBuilder/templates/hooks';
import {
  getFeatureTypeLabel,
  isFeatureTypeValid,
} from 'features/appBuilder/templates/utilities/helper';

// Custom Types
import type { TemplateFeatureType } from 'features/appBuilder/templates/types/featureType';
import type {
  TemplateOperation,
  TemplateProps,
} from 'features/appBuilder/templates/types';

export interface EditTemplateProps {}

const EditTemplate: FC<EditTemplateProps> = () => {
  // States
  const [type, setType] = useState<TemplateFeatureType | ''>('');
  const [operation, setOperation] = useState<TemplateOperation>('INIT');

  // Hooks
  const { featureType } = useParams();
  const navigate = useNavigate();
  const formMethods = useForm<TemplateProps>({ mode: 'onChange' });
  const templateMethods = useTemplateDoc({
    enabled: typeof type === 'string' && type.length > 0,
    id: featureType,
    onFetch: (doc) =>
      formMethods.reset({
        id: `feature-${type}`,
        data: { ...doc?.data },
      }),
  });

  useMount(() => {
    if (featureType && isFeatureTypeValid(featureType)) {
      setType(featureType as TemplateFeatureType);
    } else {
      navigate('/template');
    }
  });

  // Render
  return (
    <>
      <PageHeadConfigs
        pageTitle={`ویرایش تمپلیت ${type ? getFeatureTypeLabel(type) : ''}`}
      />
      <FormProvider {...formMethods}>
        <PageHeroBox>
          <PageTitle>
            ویرایش تمپلیت {type ? getFeatureTypeLabel(type) : ''}
          </PageTitle>
        </PageHeroBox>
        <TemplateBody
          type={type}
          operation={operation}
          onOperationChange={setOperation}
        />
      </FormProvider>
    </>
  );
};

export default EditTemplate;

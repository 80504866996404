import * as React from 'react';

// Custom Hooks
import useUnMount from 'core/hooks/useUnMount';
import { useAppDispatch, useAppSelector } from 'core/hooks/redux';

// Custom Common Components
import Select from 'core/components/shared/Select/SelectWithPlaceholder';

// Custom Core Components
import FormControl from 'core/components/base/inputs/FormControl';
import MenuItem from 'core/components/base/navigation/MenuItem';
import ListItemIcon from 'core/components/base/display/ListItemIcon';
import ListItemText from 'core/components/base/display/ListItemText';

// Custom Icon Components
import ArrowDropDownIcon from 'core/components/icons/ArrowDropDown';
import PlusCircleIcon from 'core/components/icons/PlusCircle';

// Custom Utilities
import { setCurrentContainer } from 'core/store/slices/feature/banner';
import { findById } from 'core/utilities/helper';
import { isSame } from 'core/utilities/helper/helperPack';
import { genBannerContainer } from 'features/banner/banners/utilities';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
interface BannerContainersDropdownProps {}

const BannerContainersDropdown: React.FC<
  BannerContainersDropdownProps
> = () => {
  // States
  const [open, setOpen] = React.useState<boolean>(false);

  // Hooks
  const dispatch = useAppDispatch();
  const loading = useSelectLoading();
  const containers = useAppSelector((state) => state.banner.containers);
  const current = useAppSelector((state) => state.banner.current);
  const isDraft = useAppSelector((state) => state.banner.isDraft);

  useUnMount(() => dispatch({ type: '/banner/isDraft/false' }));

  // Checks to see if anything changed set isDraft to true else set it back to false
  React.useEffect(() => {
    if (!current) {
      dispatch({ type: '/banner/isDraft/false' });
      return;
    }

    const orgCurrent = findById(containers, current.id);
    if (orgCurrent) {
      const isEqual = isSame(orgCurrent, current);
      if (isEqual && isDraft) dispatch({ type: `/banner/isDraft/false` });
      else if (!isEqual && !isDraft) dispatch({ type: `/banner/isDraft/true` });
    }
  }, [current]);

  // Utilities
  const openSelect = () => setOpen(true);
  const closeSelect = () => setOpen(false);

  const getCurrentTitle = React.useCallback(() => {
    if (!current?.id) return 'فهرست‌ها';
    return findById(containers, current.id)?.data.title || 'فهرست‌ها';
  }, [current?.id, containers]);

  const handleCurrentChange = (containerId: string) => {
    if (containerId) {
      const container = findById(containers, containerId);

      if (container) dispatch(setCurrentContainer(container));
    }

    closeSelect();
  };

  const handleAdd = () => {
    dispatch(setCurrentContainer(genBannerContainer()));
    closeSelect();
  };

  // Render
  return (
    <FormControl size='small' sx={{ width: '12rem' }}>
      <Select
        disabled={loading || isDraft || current?.id === 'new'}
        open={open}
        onOpen={openSelect}
        onClose={closeSelect}
        placeholder={getCurrentTitle()}
        value={current?.id}
        sx={({ palette }) => ({
          backgroundColor: palette.background.paper,
          borderRadius: '0.5rem',
          boxShadow: 'none',
          '.MuiSvgIcon-root': {
            color: palette.info.main,
            fontSize: '1.5rem',
          },
        })}
        IconComponent={ArrowDropDownIcon}
        MenuProps={{
          sx: { marginTop: '2px' },
          PaperProps: {
            sx: {
              boxShadow: 'none',
              borderRadius: '0.5rem',
              border: '1px solid #C1C8D3',
              padding: '0.25rem',
            },
          },
          MenuListProps: { sx: { padding: 0 } },
        }}
      >
        <MenuItem
          value='add-button'
          disabled={loading}
          onClick={handleAdd}
          sx={({ palette }) => ({
            color: palette.info.main,
            ':hover': {
              backgroundColor: 'transparent',
              color: palette.primary.main,
            },
          })}
        >
          <ListItemIcon style={{ color: 'inherit', minWidth: 'fit-content' }}>
            <PlusCircleIcon />
          </ListItemIcon>
          <ListItemText primary='فهرست جدید' />
        </MenuItem>
        {containers.map((container) => (
          <MenuItem
            key={`dropdown-opt-${container.id}`}
            value={container.id}
            onClick={() => handleCurrentChange(container.id)}
          >
            {container.data.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BannerContainersDropdown;

import {
  Breadcrumbs as MuiBreadcrumbs,
  BreadcrumbsProps as MuiBreadcrumbsProps,
  styled,
} from '@mui/material';

export interface BreadcrumbsProps extends MuiBreadcrumbsProps {}

const Breadcrumbs = styled(MuiBreadcrumbs)<BreadcrumbsProps>(() => ({}));

export default Breadcrumbs;

// Types
import type { TemplateFeatureType } from 'features/appBuilder/templates/types/featureType';

export const templateFeatureType: TemplateFeatureType[] = [
  'category',
  'contentDetails',
  'form',
  'search',
  'tag',
];

export const templateFeatureTypeMap: { [key in TemplateFeatureType]: string } =
  {
    category: 'کتگوری',
    form: 'فرم',
    contentDetails: 'مطلب',
    search: 'جستجو',
    tag: 'برچسب',
  };

/**
 * Retrieves the label corresponding to a feature type.
 *
 * @param {TemplateFeatureType} [type] - The type of the feature.
 * @returns {string} The label corresponding to the feature type, or an empty string if the type is not specified or not found.
 */
export const getFeatureTypeLabel = (type?: TemplateFeatureType): string =>
  !type
    ? ''
    : type && templateFeatureTypeMap[type]
    ? templateFeatureTypeMap[type]
    : type;

/**
 * Checks if a feature type is valid.
 *
 * @param {string} [type] - The feature type to check.
 * @returns {boolean} Returns true if the feature type is valid, otherwise false.
 */
export const isFeatureTypeValid = (type?: string): boolean =>
  type && templateFeatureType.includes(type as TemplateFeatureType)
    ? true
    : false;

import { FC } from 'react';

// Core Components
import IconButton, {
  IconButtonProps,
} from 'core/components/base/inputs/IconButton';
import MaximizeIcon from 'core/components/icons/Maximize';
import MinimizeIcon from 'core/components/icons/Minimize';

// Custom Types
export interface EditorAlignIconButtonProps extends IconButtonProps {
  maximize?: boolean;
}

const EditorResizeIconButton: FC<EditorAlignIconButtonProps> = (props) => {
  // Props
  const {
    children,
    maximize,
    size = 'large',
    color = 'inherit',
    ...otherProps
  } = props;

  return (
    <IconButton color={color} size={size} {...otherProps}>
      {maximize ? <MinimizeIcon /> : <MaximizeIcon />}
    </IconButton>
  );
};

export default EditorResizeIconButton;

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// HOC
import WithAuth from 'core/components/shared/HOC/WithAuth';

// Core Components
import Box from 'core/components/base/layout/Box';
import LoadingButton from 'core/components/base/inputs/LoadingButton';

// Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import CenterBox from 'core/components/shared/Box/CenterBox';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingOne from 'core/components/shared/Typography/HeadingOne';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import RouteLink from 'core/components/shared/Link/RouteLink';
import TwoFactorTextField from 'core/components/shared/Input/TwoFactorTextField';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import Notification from 'core/components/shared/Notification';

// Utilities
import { loginWith2FA } from 'features/manager/auth/utilities/api/login';
import { setAppAlert, setAppLoading } from 'core/utilities/helper';

// Validation Schema
import TwoFactorVerificationCodeSchema from 'features/manager/auth/validations/TwoFactor';

import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';

interface TwoFactorAuthProps extends WithAuthProps {}
interface TwoFactorFormProps {
  code: string;
}

const filePath = '/components/pages/TwoFactorAuthentication.tsx';
const TwoFactorAuthentication: React.FC<TwoFactorAuthProps> = (props) => {
  // States
  const [email, setEmail] = React.useState<string>('');

  // Hooks
  const loading = useSelectLoading();
  const locationState = useLocation().state;
  const navigate = useNavigate();

  const resolver = useYupValidationResolver(TwoFactorVerificationCodeSchema);

  const { control, formState, handleSubmit } = useForm<TwoFactorFormProps>({
    mode: 'onTouched',
    resolver,
    defaultValues: { code: '' },
  });

  React.useEffect(() => {
    if (locationState && typeof locationState === 'object') {
      if ('email' in locationState && 'password') {
        const { email: emailToSet } = locationState as { email: string };
        setEmail(emailToSet);
      }
    } else {
      setAppAlert('ابتدا ایمیل و رمز عبور خود را وارد نمایید.', 'warning');

      setTimeout(() => {
        navigate('/login');
      }, 2500);
    }
  }, [locationState]);

  const onSubmit: SubmitHandler<TwoFactorFormProps> = async ({ code }) => {
    if (!email) return navigate('/login');

    const verifactionCode = code.trim().replaceAll('-', '').replaceAll(' ', '');

    setAppLoading(true);
    const status = await loginWith2FA(email, verifactionCode);

    if (status === 200) {
      setAppLoading(false);
      navigate('/');
      return;
    } else if (status === 401) {
      setAppAlert('کد وارد شده نامعتبر است.');
    } else {
      setAppAlert('خطا در ارزیابی صحت اطلاعات وارد شده.');
    }
    setAppLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>ورود به داشبورد مدیریتی</title>
      </Helmet>
      <CenterBox component='form' onSubmit={handleSubmit(onSubmit)}>
        <RoundPaper sx={{ padding: '3rem 6rem' }}>
          <ColumnStack spacing={3}>
            <Box>
              <HeadingOne gutterBottom textAlign='center' variant='h6'>
                ورود دو مرحله‌ای
              </HeadingOne>
              <BodyOne textAlign='center'>
                کد ورود دو مرحله‌ای را وارد کنید.
              </BodyOne>
            </Box>
            <Box>
              <InputLabel>کد ورود دو مرحله‌ای</InputLabel>
              <Controller
                control={control}
                defaultValue=''
                name='code'
                render={({ field, fieldState }) => (
                  <TwoFactorTextField
                    dir='ltr'
                    disabled={loading || !email}
                    fullWidth
                    error={
                      fieldState.isTouched && fieldState.error?.message
                        ? true
                        : false
                    }
                    helperText={
                      fieldState.isTouched && fieldState.error?.message
                    }
                    placeholder='Authenticator Code'
                    sx={{
                      '.MuiInputBase-input': {
                        textAlign: 'center',
                      },
                    }}
                    type='text'
                    {...field}
                  />
                )}
              />
            </Box>
            <ColumnStack spacing={1}>
              <Controller
                control={control}
                name='code'
                render={({ field }) => (
                  <LoadingButton
                    disabled={
                      !email || !field.value || formState.errors.code
                        ? true
                        : false
                    }
                    fullWidth
                    loading={loading}
                    sx={{ height: '3rem', width: '21rem' }}
                    type='submit'
                  >
                    تأیید
                  </LoadingButton>
                )}
              />
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <RouteLink
                  href='/login'
                  sx={({ palette }) => ({
                    ':hover': {
                      color: palette.info.light,
                    },
                  })}
                >
                  بازگشت
                </RouteLink>
              </Box>
            </ColumnStack>
          </ColumnStack>
        </RoundPaper>
      </CenterBox>
      <Notification />
    </>
  );
};

export default WithAuth(TwoFactorAuthentication, { filePath });

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface InsertImageIconProps extends SvgIconProps {}

const InsertImageIcon: React.FC<InsertImageIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M3.9,22.4c-0.6,0-1.2-0.2-1.6-0.7c-0.5-0.5-0.7-1-0.7-1.6V3.9c0-0.6,0.2-1.2,0.7-1.6c0.5-0.5,1-0.7,1.6-0.7h16.1
	c0.6,0,1.2,0.2,1.6,0.7c0.5,0.5,0.7,1,0.7,1.6v16.1c0,0.6-0.2,1.2-0.7,1.6c-0.5,0.5-1,0.7-1.6,0.7H3.9z M5.1,17.8h13.8L14.6,12
	l-3.5,4.6l-2.6-3.5L5.1,17.8z'
    />
  </IconBuilder>
);

export default InsertImageIcon;

import * as Yup from 'yup';

// Schemas
import { passwordSchema } from 'core/utilities/validationSchema/common/Schemas';

const PasswordValidationSchema = Yup.object().shape({
  password: passwordSchema,
});

export default PasswordValidationSchema;

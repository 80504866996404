import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface FormatAlignRightProps extends SvgIconProps {}

const FormatAlignRight: React.FC<FormatAlignRightProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <g>
        <path d='M21.7,8.3H2.3C2.1,8.3,2,8.2,2,8V5.5c0-0.2,0.1-0.3,0.3-0.3h19.4c0.2,0,0.3,0.2,0.3,0.3V8C22,8.2,21.8,8.3,21.7,8.3z' />
        <path
          d='M21.7,13.6H4.3c-0.2,0-0.3-0.2-0.3-0.3v-2.6c0-0.2,0.1-0.3,0.3-0.3h17.4c0.2,0,0.3,0.2,0.3,0.3v2.6
		C22,13.4,21.9,13.6,21.7,13.6z'
        />
        <path
          d='M21.7,18.8h-9.6c-0.2,0-0.3-0.2-0.3-0.3V16c0-0.2,0.1-0.3,0.3-0.3h9.6c0.2,0,0.3,0.2,0.3,0.3v2.6
		C22,18.7,21.9,18.8,21.7,18.8z'
        />
      </g>
    </IconBuilder>
  );
};

export default FormatAlignRight;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface TextIncreaseIconProps extends SvgIconProps {}

const TextIncreaseIcon: React.FC<TextIncreaseIconProps> = (props) => {
  const { color = 'inherit', ...otherProps } = props;

  return (
    <IconBuilder color={color} {...otherProps}>
      <path d='M15.3043 4H4V6.21739H8.47826V20H10.8261V6.21739H15.3043V4ZM17.6522 11.3913V9.17391H16.087V11.3913H13.7391V12.8696H16.087V15.087H17.6522V12.8696H20V11.3913H17.6522Z' />
    </IconBuilder>
  );
};

export default TextIncreaseIcon;

import type { FC, ReactNode } from 'react';

// Core Components
import Box from 'core/components/base/layout/Box';
import PlusCircleOutlineIcon from 'core/components/icons/PlusCircleOutline';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
export interface PatternItemContainerButtonProps {
  disabled?: boolean;
  label?: string;
  onClick?: () => void;
}

const PatternItemContainerButton: FC<PatternItemContainerButtonProps> = (
  props
) => {
  // Props
  const { label = 'افزودن تب جدید', disabled, onClick } = props;

  // Hooks
  const loading = useSelectLoading();

  // Utilities
  const handleClick = () => {
    if (!loading && !disabled && onClick) onClick();
  };

  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        borderRadius: '0.5rem',
        p: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        height: '366px',
      }}
    >
      <Box
        onClick={handleClick}
        aria-disabled={disabled || loading}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '0.5rem',
          gap: 1,
          cursor: disabled || loading ? 'auto' : 'pointer',
          border: '2px dashed',
          borderColor: disabled || loading ? 'text.disabled' : 'info.main',
          color: disabled || loading ? 'text.disabled' : 'info.main',
          width: '100%',
          height: '100%',
        }}
      >
        <PlusCircleOutlineIcon />
        <BodyTwo>{label}</BodyTwo>
      </Box>
    </Box>
  );
};

export default PatternItemContainerButton;

// Feature Utilities
import { bakePatternCommonProps } from 'features/appBuilder/patterns/core/utilities/api/baker';

// Feature Types
import type { SearchPatternDataProps } from 'features/appBuilder/patterns/features/search/types';

/**
 * Transforms the provided search pattern data by ensuring the `creator` field is included as a string.
 *
 * @param {SearchPatternDataProps} data - The search pattern data to transform.
 * @returns {Omit<SearchPatternDataProps, 'creator'> & { creator: string }} The transformed search pattern data with the `creator` field as a string.
 */
export const bakeSearchPatternData = (
  data: SearchPatternDataProps
): Omit<SearchPatternDataProps, 'creator'> & { creator: string } => {
  const { pageConfig, cardConfig, ...otherProps } = data;

  const commonProps = bakePatternCommonProps(otherProps);

  return {
    ...commonProps,
    pageConfig,
    cardConfig,
  };
};

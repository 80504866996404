import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import type { SubmitHandler } from 'react-hook-form';

// Custom Core Components
import TextField from 'core/components/base/inputs/TextField';
import Switch from 'core/components/base/inputs/Switch';
import MenuItem from 'core/components/base/navigation/MenuItem';

// Custom Common Componenst
import AvatarUploader from 'core/components/shared/Input/Avatar';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import RowStack from 'core/components/shared/Stack/RowStack';
import Select from 'core/components/shared/Select';
import SpacedBox from 'core/components/shared/Box/SpacedBox';
import SubmitLoadingButton from 'core/components/shared/LoadingButton/Submit';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Custom Hooks
import useRefId from 'core/hooks/useRefId';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// Custom Utilities
import { setAppAlert } from 'core/utilities/helper';
import { useManagerSummary } from 'features/manager/groups/hooks';
import { useAddManagerMutation } from 'features/manager/managers/hooks';

// Custom Validation Schemas
import AddManagerValidationSchema from 'features/manager/managers/validations/AddManager';

// Custom Types
import type { ManagerDataProps } from 'features/manager/managers/types';

interface AddManagerProps {}

interface FormInputs extends ManagerDataProps {
  password: string;
}

const AddManager: React.FC<AddManagerProps> = (props) => {
  // Context
  const loading = useSelectLoading();

  // Hooks
  const [refId] = useRefId();
  const navigate = useNavigate();
  const resolver = useYupValidationResolver(AddManagerValidationSchema);
  const { control, formState, handleSubmit, setValue, watch } =
    useForm<FormInputs>({
      mode: 'onTouched',
      resolver,
      defaultValues: { password: '' },
    });

  const { mutate: handleAddManager } = useAddManagerMutation({
    autoAlert: { mode: 'create', name: 'مدیر' },
    stayOnLoadingAfterMutate: true,
    noRevalidate: true,
    redirectAfterSuccessTo: '/managers',
  });
  const { data: groups } = useManagerSummary({
    alertOnFetchError: { message: 'دریافت گروه مدیران با خطا مواجه شد.' },
    alertOnFetchEmptyList: {
      message: 'گروه مدیریتی‌ای یافت نشد. ابتدا گروه مدیریتی ایجاد کنید.',
      severity: 'warning',
    },
    onFetch: (groups) => {
      if (groups && groups.length === 0) {
        setTimeout(() => navigate('/manager-groups'), 2500);
      }
    },
  });

  // Utilities
  const handleFormSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (groups.length === 0) return;
    if (!data.groupId) return setAppAlert('گروه‌ مدیریتی را مشخص کنید.');

    data.relatedMediaId = refId;

    handleAddManager(data);
  };

  return (
    <>
      <PageHeadConfigs pageTitle='افزودن مدیر' />
      <PageHeroBox>
        <HeadingSix>افزودن مدیر جدید</HeadingSix>
        <SubmitLoadingButton
          disabled={
            groups.length === 0 ||
            !watch('firstName') ||
            !watch('lastName') ||
            !watch('email')
          }
          loading={groups.length > 0 && loading}
          onClick={handleSubmit(handleFormSubmit)}
        >
          ذخیره
        </SubmitLoadingButton>
      </PageHeroBox>
      <RoundPaper>
        <SpacedBox sx={{ alignItems: 'flex-start' }}>
          <ColumnStack sx={{ width: '20%', mt: 5 }}>
            <Controller
              name='avatar'
              control={control}
              render={({ field }) => (
                <AvatarUploader
                  disabled={loading}
                  location='manager'
                  onDeleteImage={() => setValue(field.name, undefined)}
                  onImageFileChange={(file) => field.onChange(file)}
                  imageFile={field.value?.id ? field.value : undefined}
                  sx={{
                    width: '167px',
                    height: '216px',
                    borderRadius: '1.2rem',
                  }}
                />
              )}
            />
          </ColumnStack>
          <ColumnStack sx={{ width: '75%', mt: 5, pb: 3, pr: 2 }}>
            <RowStack sx={{ width: '100%', justifyContent: 'space-between' }}>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>نام</InputLabel>
                <Controller
                  control={control}
                  name='firstName'
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      error={
                        formState.touchedFields.firstName &&
                        formState.errors.firstName?.message
                          ? true
                          : false
                      }
                      helperText={
                        formState.touchedFields.firstName &&
                        formState.errors.firstName?.message
                      }
                      disabled={loading}
                      placeholder='نام'
                      type='text'
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>نام‌خانوادگی</InputLabel>
                <Controller
                  name='lastName'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      error={
                        formState.touchedFields.lastName &&
                        formState.errors.lastName?.message
                          ? true
                          : false
                      }
                      helperText={
                        formState.touchedFields.lastName &&
                        formState.errors.lastName?.message
                      }
                      disabled={loading}
                      placeholder='نام‌خانوادگی'
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
            </RowStack>
            <RowStack sx={{ width: '100%', justifyContent: 'space-between' }}>
              <ColumnStack width='45%' spacing={0}>
                <InputLabel>ایمیل</InputLabel>
                <Controller
                  name='email'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      dir='ltr'
                      error={
                        formState.touchedFields.email &&
                        formState.errors.email?.message
                          ? true
                          : false
                      }
                      helperText={
                        formState.touchedFields.email &&
                        formState.errors.email?.message
                      }
                      placeholder='example@nazar.com'
                      fullWidth
                      type='email'
                      disabled={loading}
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>کلمه عبور</InputLabel>
                <Controller
                  name='password'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      dir='ltr'
                      fullWidth
                      autoComplete='new-password'
                      error={
                        formState.touchedFields.password &&
                        formState.errors.password?.message
                          ? true
                          : false
                      }
                      helperText={
                        formState.touchedFields.password &&
                        formState.errors.password?.message?.toPersian()
                      }
                      InputProps={{ autoComplete: 'off' }}
                      type='password'
                      placeholder='password'
                      disabled={loading}
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
            </RowStack>
            <RowStack sx={{ justifyContent: 'space-between' }}>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>گروه‌های مدیریتی</InputLabel>
                <Controller
                  name='groupId'
                  control={control}
                  render={({ field: { value, ...otherFields } }) => (
                    <>
                      <Select
                        disabled={loading}
                        sx={{ borderRadius: '4px' }}
                        value={value ? value : ''}
                      >
                        {groups.length > 0 ? (
                          groups.map((group) => (
                            <MenuItem
                              disabled={loading}
                              key={group.id}
                              value={group.id}
                              onClick={() => setValue('groupId', group.id)}
                            >
                              {group.data.title}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>
                            <BodyTwo>گروهی جهت انتخاب وجود ندارد.</BodyTwo>
                          </MenuItem>
                        )}
                      </Select>
                      {formState.touchedFields.groupId &&
                        formState.errors.groupId?.message && (
                          <BodyTwo>{formState.errors.groupId?.message}</BodyTwo>
                        )}
                    </>
                  )}
                />
              </ColumnStack>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>غیرفعال کردن ورود دو مرحله‌ای</InputLabel>
                <RowStack
                  sx={({ palette }) => ({
                    width: '80%',
                    borderRadius: '10px',
                    background: palette.background.default,
                    paddingX: '1.5rem',
                    height: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  })}
                >
                  <InputLabel disabled>غیرفعال</InputLabel>
                  <Switch disabled />
                </RowStack>
              </ColumnStack>
            </RowStack>
            <ColumnStack spacing={0}>
              <InputLabel>بیوگرافی</InputLabel>
              <Controller
                name='biography'
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={loading}
                    multiline
                    fullWidth
                    rows={6}
                    placeholder='متن بیوگرافی'
                    {...field}
                  />
                )}
              />
            </ColumnStack>
          </ColumnStack>
        </SpacedBox>
      </RoundPaper>
    </>
  );
};

export default AddManager;

// Custom Page Components
import Add from 'features/content/contents/components/details/AddContent';
import Edit from 'features/content/contents/components/details/EditContent';

// Custom Utilities
import { getFeatureLabel, getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'content';
const subFeature: SubFeatureType = 'contents';
// ! THIS ROUTE IS ADDED TEMPORARLY TO FIX THE SIDEBAR CYCLIC DEPS ISSUES; DO NOT DELETE
const listRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/contents',
    Component: Add,
    feature,
    subFeature,
  },
  {
    sidebarTitle: `ایجاد ${getFeatureLabel(feature)}`,
    path: '/contents/add',
    Component: Add,
    feature,
    subFeature,
  },
  {
    path: '/contents/:id',
    Component: Edit,
    feature,
    subFeature,
  },
];

export default listRoutes;

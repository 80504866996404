import { useFormContext } from 'react-hook-form';

// Types
import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageTitle from 'core/components/shared/Typography/PageTitle';

// Custom Core Components
import CircularProgress from 'core/components/base/feedback/CircularProgress';
import LoadingButton from 'core/components/base/inputs/LoadingButton';
import IconButton from 'core/components/base/inputs/IconButton';

// Custom Icon Components
import DuplicateIcon from 'core/components/icons/Duplicate';
import DeleteIcon from 'core/components/icons/DeleteIcon';

// Context
import { useDialogContext } from 'core/store/context/DialogProvider';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import { setAppAlert } from 'core/utilities/helper';
import {
  useDeleteCustomPageMutation,
  useDuplicateCustomPageMutation,
  useUpdateCustomPageMutation,
} from 'features/appBuilder/customPages/components/hooks/customPage';

// Custom Types
import type {
  CustomPageOperation,
  CustomPageProps,
} from 'features/appBuilder/customPages/types';

export interface CustomPageActionProps {
  pageId: string;
  pageTitle: string;
  operation: CustomPageOperation;
  onOperationChange: (operation: CustomPageOperation) => void;
}

const CustomPageAction: FC<CustomPageActionProps> = (props) => {
  // Props
  const { pageId, pageTitle, operation, onOperationChange } = props;

  // Context
  const { onCloseDialog, onOpenDialog } = useDialogContext();

  // Hooks
  const loading = useSelectLoading();
  const { handleSubmit } = useFormContext<CustomPageProps>();
  const { mutate: handleDeletePage } = useDeleteCustomPageMutation({
    autoAlert: { mode: 'delete', name: 'صفحه سفارشی' },
    noRevalidate: true,
    stayOnLoadingAfterMutate: true,
    redirectAfterSuccessTo: '/custom-page',
    onMutate: () => onOperationChange('DEL'),
    onSuccess: () => onCloseDialog(),
  });
  const { mutate: handleUpdatePage } = useUpdateCustomPageMutation({
    stayOnLoadingAfterMutate: true,
    redirectAfterSuccessTo: '/custom-page',
    autoAlert: { mode: 'update', name: 'صفحه' },
    onMutate: () => onOperationChange('UP'),
  });
  const { mutate: handleDuplicatePage } = useDuplicateCustomPageMutation({
    onMutate: () => onOperationChange('UP'),
    customAlert: {
      successMessage: 'کپی صفحه سفارشی با موفقیت ایجاد شد.',
      errorMessage: 'خطا در ایجاد کپی صفحه سفارشی',
    },
  });

  const handleFormSubmit: SubmitHandler<CustomPageProps> = async (data) => {
    if (!pageId) return;
    if (!data.data.slug)
      return setAppAlert('وارد کردن اسلاگ اجباری است', 'warning');

    if (!data.data.slug.startsWith('/'))
      return setAppAlert('اسلاگ باید با "/" شروع شود.', 'warning');

    onOperationChange('UP');
    handleUpdatePage(data);
  };

  const handleOpenDialog = () =>
    onOpenDialog({
      title: 'آیا از حذف اطمینان دارید؟',
      onSubmit: () => handleDeletePage(pageId),
    });

  // Render
  return (
    <PageHeroBox>
      <PageTitle>ویرایش {pageTitle}</PageTitle>
      <RowStack spacing={1}>
        <IconButton
          disabled={
            loading || !pageId || operation.includes('BODY') ? true : false
          }
          color='error'
          onClick={handleOpenDialog}
          sx={{
            borderRadius: '0.25rem',
            border: '1px solid',
            borderColor:
              loading || !pageId || operation.includes('BODY')
                ? 'text.disabled'
                : 'error.main',
          }}
        >
          {operation === 'DEL' && loading ? (
            <CircularProgress color='error' size={22} />
          ) : (
            <DeleteIcon />
          )}
        </IconButton>
        <LoadingButton
          onClick={() => handleDuplicatePage(pageId)}
          disabled={
            loading || !pageId || operation.includes('BODY') ? true : false
          }
          loading={operation === 'DUP' && loading ? true : false}
          loadingPosition='start'
          variant='outlined'
          color='inherit'
          startIcon={<DuplicateIcon />}
        >
          کپی
        </LoadingButton>
        <LoadingButton
          disabled={
            loading || !pageId || operation.includes('BODY') ? true : false
          }
          loading={operation === 'UP' && loading ? true : false}
          loadingPosition='start'
          startIcon={<></>}
          onClick={handleSubmit(handleFormSubmit)}
          sx={{ px: '2.5rem' }}
        >
          بروزرسانی
        </LoadingButton>
      </RowStack>
    </PageHeroBox>
  );
};

export default CustomPageAction;

import { memo, type FC, useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Custom Core Components
import InputBase from 'core/components/base/inputs/InputBase';
import MenuItem from 'core/components/base/navigation/MenuItem';
import Radio from 'core/components/base/inputs/Radio';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import Select from 'core/components/shared/Select';
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Icon Components
import StarOutlineIcon from 'core/components/icons/StarOutline';

// Custom Feature Componenst
import FormInputWrapper, {
  FormInputWrapperProps,
} from 'features/form/forms/components/details/item/Input/shared/Wrapper';

// Custom Utilities
import { genObjectIdArray, isSame } from 'core/utilities/helper/helperPack';
import { toggleFormIsInInputConfigSelection } from 'features/form/forms/store/actions';

// Custom Types
import type { FormScoreInputProps } from 'features/form/forms/types/item/input/score';
import type { FormProps } from 'features/form/forms/types/item';
export interface FormScoreInputComponentProps extends FormInputWrapperProps {
  input: FormScoreInputProps;
}

const FormScoreInput: FC<FormScoreInputComponentProps> = (props) => {
  // Props
  const { input, disabled, onUpdate, ...otherProps } = props;

  // Hooks
  const [openMaxScore, setOpenMaxScore] = useState<boolean>(false);

  const { control, getValues, setValue } = useFormContext<FormProps>();
  const index = getValues('data.inputsIndexMap')[input.id];

  useEffect(() => {
    toggleFormIsInInputConfigSelection(openMaxScore);
  }, [openMaxScore]);

  const FormScoreInputConfig = (
    <ColumnStack spacing={1.5}>
      <InputWrapper>
        <InputLabel>تعداد مقیاس</InputLabel>
        <Controller
          control={control}
          name={`data.inputs.${index}.data.maxScore`}
          defaultValue={10}
          render={({ field }) => (
            <Select
              fullWidth
              defaultValue={field.value}
              onOpen={() => toggleFormIsInInputConfigSelection(true)}
              onClose={() => toggleFormIsInInputConfigSelection(false)}
              sx={{ width: '8rem' }}
            >
              {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                <MenuItem
                  key={`score-size-option-${index}-value-${item}`}
                  value={item}
                  onClick={(e) => {
                    e.stopPropagation();
                    setValue(field.name, item);
                  }}
                >
                  {item.toPersian()}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </InputWrapper>
    </ColumnStack>
  );

  return (
    <FormInputWrapper
      id={input.id}
      index={index}
      type='score'
      config={FormScoreInputConfig}
      disabled={disabled}
      onUpdate={onUpdate}
      {...otherProps}
    >
      <Controller
        control={control}
        name={`data.inputs.${index}.data.placeholder`}
        defaultValue=''
        rules={{ onChange: onUpdate }}
        render={({ field }) => (
          <InputBase
            disabled={disabled}
            placeholder='متن راهنما...'
            fullWidth
            sx={{ fontSize: '0.875rem' }}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name={`data.inputs.${index}.data.maxScore`}
        render={({ field }) => (
          <RowStack justifyContent='center' spacing={0.75}>
            {genObjectIdArray(field.value - 1).map((item) => (
              <Radio
                key={item}
                icon={<StarOutlineIcon />}
                disableRipple
                disabled={disabled}
                checked={false}
                sx={{ width: 'fit-content', cursor: 'auto' }}
              />
            ))}
          </RowStack>
        )}
      />
    </FormInputWrapper>
  );
};

export default memo(FormScoreInput, isSame);

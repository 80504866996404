import cloneDeep from 'lodash/cloneDeep';

// Custom Types
import type { TablePatternDataProps } from 'features/appBuilder/patterns/features/table/types';

const tablePatternDataDefaults: TablePatternDataProps = {
  patternTitle: '',
  bgColorOne: '#F5F5F5',
  bgColorTwo: '#F5F5F5',
  dividerColor: '#C1C8D3',
  bgImage: null,
  bgImageType: 'cover',
  padding: '32px',
  cardBg: '',
  cardCount: 0,
  creator: null,
  isActive: true,
  maxWidth: 'lg',
  sortIndex: 0,
  type: 'table',
  variant: 0,
  colCount: 0,
  refs: {},
  rowCount: 0,
  rows: [],
  sheetIds: [],
};

/**
 * Retrieves the default table pattern data.
 *
 * @returns {TablePatternDataProps} The default table pattern data.
 */
export const getTablePatternDataDefault = () => {
  const cloned = cloneDeep(tablePatternDataDefaults);

  return cloned;
};

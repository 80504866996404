// Custom Utilities
import endpoints from './endpoints';
import apiHandler from 'core/utilities/apiHandler';
import { setPermissionCookies } from 'core/utilities/permissions';
import { setToken } from 'core/utilities/token/token';
import { getAppAdminURL } from 'core/utilities/helper/getEnvVariables';
import { getManagerLandingRoute } from 'core/utilities/route/helper';

// Types
import type { PermissionProps } from 'core/types/permission';

export const login = async (
  email: string,
  password: string
): Promise<{ status: number; landingRoute: string; token?: string }> => {
  const { data: response, status } = await apiHandler.post<{
    docs: { token: string; permissions: PermissionProps[] };
  }>(endpoints.login, { email, password });

  let landingRoute = '/login';

  if (response && 'docs' in response && typeof response.docs === 'object') {
    const { docs } = response;
    landingRoute = getManagerLandingRoute(docs.permissions);
    setPermissionCookies(docs.permissions);
    setToken(docs.token);
  }

  return { status, landingRoute };
};

export const loginWith2FA = async (
  email: string,
  code: string
): Promise<number> => {
  const { status, data: response } = await apiHandler.post<{
    docs: { token: string; permissions: PermissionProps[] };
  }>(endpoints.verify2FA, { email, token: code });

  if (response && 'docs' in response) {
    setPermissionCookies(response.docs.permissions);
    setToken(response.docs.token);
  }

  return status;
};

export const requestPasswordRecovery = async (email: string) => {
  const baseUrl = getAppAdminURL();
  const route = '/password-recovery';

  const { status } = await apiHandler.post(endpoints.requestResetLink, {
    emails: email,
    baseUrl,
    route,
  });

  return status;
};

export const updatePassowrd = async (tempToken: string, password: string) => {
  const { status } = await apiHandler.patch(
    endpoints.resetPassword(tempToken),
    { password }
  );

  return status;
};

export const logout = async (): Promise<{ status: number }> => {
  return { status: 200 };
};

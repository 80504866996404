import React from 'react';
import dayjs from 'dayjs';
import * as faLocale from 'dayjs/locale/fa';
import { createRoot } from 'react-dom/client';
import { CacheProvider } from '@emotion/react';
import { Provider } from 'react-redux';

// Theme
import GlobalThemeProvider from 'core/store/GlobalThemeProvider';

import App from './App';
import 'core/styles/index.css';
import 'core/styles/fonts/vazir.css';

// Custom Store
import store from 'core/store';

// Utilities
import cacheRtl from 'core/utilities/emotion/cache';

dayjs.locale(faLocale);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <CacheProvider value={cacheRtl}>
    <Provider store={store}>
      <GlobalThemeProvider>
        <App />
      </GlobalThemeProvider>
    </Provider>
  </CacheProvider>
);

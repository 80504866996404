import { initialFile } from 'features/file/files/utilities/file';
import { generateObjectId } from 'core/utilities/helper/id';
import { AudienceProps } from 'features/audience/audiences/types';
import { initialCreator } from 'core/utilities/creator/initial';

export const initialAudience: AudienceProps = {
  id: generateObjectId(),
  data: {
    firstName: '',
    lastName: '',
    accessId: '',
    phoneNumber: '',
    email: '',
    personalId: '',
    nationalId: '',
    birthDate: '',
    jobTitle: '',
    biography: '',
    isEnabled: true,
    creator: initialCreator,
    dependants: [],
    avatar: initialFile,
    createdAt: '',
    lastLogin: [],
    groups: [],
  },
};

import { useState, useEffect } from 'react';

// Types
import type { FC } from 'react';
import type { SxProps, Theme } from '@mui/material';

// Custom Core Components
import TextField from 'core/components/base/inputs/TextField';
import Autocomplete from 'core/components/base/inputs/Autocomplete';
import CircularProgress from 'core/components/base/feedback/CircularProgress';

// Custom Utilities
import { getAudienceAccessList } from 'features/audience/accesses/utilities/api';

// Custom Types
import type { AccessProps } from 'features/audience/accesses/types';
export interface AccessAutocompleteProps {
  defaultValue?: string;
  onChange?: (accessId: string) => void;
  placeholder?: string;
  showPublic?: boolean;
  sx?: SxProps<Theme>;
}

const AccessAutocomplete: FC<AccessAutocompleteProps> = (props) => {
  // Props
  const {
    placeholder = 'اکسس',
    showPublic,
    defaultValue,
    onChange,
    sx,
  } = props;

  // States
  const [value, setValue] = useState<AccessProps | null>(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly AccessProps[]>([]);
  const loading = open && options.length === 0;

  // Hooks
  useEffect(() => {
    let active = true;

    if (defaultValue && active) {
      (async () => {
        const { list = [], status } = await getAudienceAccessList();

        if (showPublic) {
          list.unshift({
            id: 'viewer',
            data: { title: 'عمومی', audienceCount: 0 },
          });
        }

        if (list) {
          const accessToSet = list.find((a) => a.id === defaultValue);
          if (accessToSet && accessToSet.id !== value?.id)
            setValue(accessToSet);
        }
      })();
    } else {
      setValue(null);
    }

    return () => {
      active = false;
    };
  }, [defaultValue]);

  useEffect(() => {
    let active = true;

    if (!loading) return undefined;

    (async () => {
      const { list = [], status } = await getAudienceAccessList();

      if (active) {
        if (showPublic) {
          list.unshift({
            id: 'viewer',
            data: { title: 'عمومی', audienceCount: 0 },
          });
        }
        setOptions(list);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  // Utilities
  const handleChange = (newValue: AccessProps | null) => {
    setValue(newValue);
    if (onChange) onChange(newValue?.id || '');
  };

  return (
    <Autocomplete
      id='audience-access-list'
      loadingText='در حال دریافت...'
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.data.title}
      options={options}
      loading={loading}
      value={value}
      onChange={(e, newValue) => handleChange(newValue)}
      sx={sx}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AccessAutocomplete;

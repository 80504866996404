// Types
import type { FC } from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface CopyIconProps extends SvgIconProps {}

const DuplicateIcon: FC<CopyIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M11.5,16.5H5.2c-0.5,0-0.9-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3V3.8c0-0.5,0.2-0.9,0.5-1.3C4.2,2.2,4.7,2,5.2,2h10.9v1.8
	H5.2v10.9h6.4v-1.8l3.6,2.7l-3.6,2.7V16.5z M18.8,20.2V7.5h-10v5.5H7V7.5C7,7,7.2,6.5,7.5,6.2c0.3-0.3,0.8-0.5,1.3-0.5h10
	c0.5,0,0.9,0.2,1.3,0.5c0.3,0.3,0.5,0.8,0.5,1.3v12.7c0,0.5-0.2,0.9-0.5,1.3S19.3,22,18.8,22h-10c-0.5,0-0.9-0.2-1.3-0.5
	C7.2,21.1,7,20.7,7,20.2v-1.8h1.8v1.8H18.8z'
    />
  </IconBuilder>
);

export default DuplicateIcon;

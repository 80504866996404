import {
  Alert as MuiAlert,
  AlertProps as MuiAlertProps,
  AlertColor as MuiAlertColor,
  styled,
} from '@mui/material';

export type AlertColor = MuiAlertColor;

export interface AlertProps extends MuiAlertProps {}

const Alert = styled(MuiAlert)<AlertProps>(() => ({}));

export default Alert;

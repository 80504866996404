import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface ConfigIconProps extends SvgIconProps {}

const ConfigIcon: React.FC<ConfigIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M2,6c0-1.5,0.8-3.1,2-4c0,0.8,0.7,2,2,2c0.8,0,1.6,0.3,2.1,0.9S9,6.2,9,7c0,0.4-0.1,0.8-0.2,1.1c0.7,0.6,1.5,1.2,2.2,1.8
	L8.9,12c-0.6-0.7-1.2-1.4-1.8-2.2C6.7,9.9,6.4,10,6,10C3.8,10,2,8.2,2,6z M14.2,12.8l1.3-1.3c0.6,0.3,1.3,0.5,2,0.5
	c1.2,0,2.3-0.5,3.2-1.3c0.8-0.8,1.3-2,1.3-3.2c0-0.7-0.2-1.4-0.5-2L18,9l-2-2l3.5-3.5c-0.6-0.3-1.3-0.5-2-0.5
	c-1.2,0-2.3,0.5-3.2,1.3S13,6.3,13,7.5c0,0.7,0.2,1.4,0.5,2L3,20l2,2l6.8-6.8c1.9,1.9,3.9,3.8,6,5.4l1.4,1.1l1.5-1.5l-1.1-1.4
	C17.9,16.7,16.1,14.7,14.2,12.8z'
    />
  </IconBuilder>
);

export default ConfigIcon;

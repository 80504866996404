import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import Box from 'core/components/base/layout/Box';
import Checkbox from 'core/components/base/inputs/Checkbox';
import InsertImageIcon from 'core/components/icons/InsertImage';
import Button from 'core/components/base/inputs/Button';
import BinIcon from 'core/components/icons/Bin';

// Feature Utilities
import { useUploadDialogContext } from 'features/file/files/context/UploadDialog';

// Core Utilities
import { useAppSelector } from 'core/hooks/redux';
import { prefixFileURL } from 'core/utilities/helper/link';

// Feature Types
import type { ContentDetailsProps } from 'features/content/contents/types/details';

// Component Types
interface ContentDetailsConfigCoverProps {}

const ContentDetailsConfigCover: FC<ContentDetailsConfigCoverProps> = () => {
  // Hooks
  const loading = useAppSelector((state) => state.loading);
  const { control, setValue } = useFormContext<ContentDetailsProps>();
  const { setSettings } = useUploadDialogContext();

  // Utilities
  const handleOpenDialog = () => {
    setSettings({
      open: true,
      location: 'content',
      accept: ['image'],
      onInsertFile: (files) => {
        if (files.length > 0) {
        }
        setValue('data.coverImage', files[0]);
      },
    });
  };

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: 0, width: '35%' }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <BodyTwo color='text.secondary'>تصویر اصلی</BodyTwo>
        <Controller
          control={control}
          name='data.showCoverInContent'
          render={({ field }) => (
            <Box sx={{ display: 'flex', gap: 0, alignItems: 'center' }}>
              <Checkbox
                size='small'
                checked={field.value}
                onChange={(_, checked) => setValue(field.name, checked)}
              />
              <BodyTwo color={field.value ? 'text.primary' : 'text.secondary'}>
                نمایش تصویر در مطلب
              </BodyTwo>
            </Box>
          )}
        />
      </Box>
      <Controller
        control={control}
        name='data.coverImage'
        render={({ field }) => (
          <Box
            sx={{
              position: 'relative',
              bgcolor: '#F8F8F8',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              borderRadius: 2,
              cursor: field.value || loading ? 'auto' : 'pointer',
              p: 2,
              maxHeight: '153px',
              transition: 'all',
              transitionDuration: '200mx',
              ':hover': {
                border: field.value ? 'none' : '1px solid',
                borderColor: 'divider',
              },
              ':active': {
                opacity: field.value ? 1 : 0.8,
              },
            }}
            onClick={() => {
              !field.value && handleOpenDialog();
            }}
          >
            {field.value ? (
              <img
                src={prefixFileURL(field.value.data.url)}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            ) : (
              <InsertImageIcon color='info' />
            )}
            {field.value && (
              <Button
                startIcon={<BinIcon />}
                color='error'
                sx={{
                  position: 'absolute',
                  bottom: 8,
                  bgcolor: 'background.paper',
                  px: 2,
                  borderRadius: 2,
                  ':hover': {
                    bgcolor: 'background.paper',
                    opacity: 0.8,
                  },
                }}
                onClick={() => setValue('data.coverImage', null)}
              >
                حذف تصویر
              </Button>
            )}
          </Box>
        )}
      />
    </Box>
  );
};

export default ContentDetailsConfigCover;

import React from 'react';
import { Controller, useForm } from 'react-hook-form';

// Custom Hooks
import useToggle from 'core/hooks/useToggle';

// Types
import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';

// Custom Common Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import CloseIconButton from 'core/components/shared/IconButton/Close';

// Custom Core Components
import TextField from 'core/components/base/inputs/TextField';
import Box from 'core/components/base/layout/Box';
import Button from 'core/components/base/inputs/Button';
import IconButton from 'core/components/base/inputs/IconButton';
import Slide from 'core/components/base/utils/Slide';

// Context
import { useDialogContext } from 'core/store/context/DialogProvider';

// Custom Icon Components
import DeleteIcon from 'core/components/icons/DeleteIcon';

// Custom Types
export interface AccessTitleProps {
  title: string;
  onDelete: () => void;
  onUpdate: (title: string) => Promise<void>;
}

const AccessTitle: FC<AccessTitleProps> = (props) => {
  // Props
  const { title, onDelete, onUpdate } = props;

  // States
  const [mode, toggleMode] = useToggle('preview', 'edit', 'preview');

  // Context
  const { onOpenDialog, onCloseDialog } = useDialogContext();

  // Hooks
  const { control, handleSubmit, reset } = useForm<{ title: string }>({
    mode: 'onTouched',
  });

  React.useEffect(() => {
    reset({ title });
  }, [title]);

  // Utilites
  const handleClose = () => {
    reset({ title });
    onCloseDialog();
    toggleMode('preview');
  };

  const handleUpdate: SubmitHandler<{ title: string }> = async (data) => {
    if (data.title !== title) await onUpdate(data.title);
    handleClose();
  };

  const handleOpenDeleteDialog = () => {
    onOpenDialog({
      title: 'آیا از حذف اکسس مطمئن هستید؟',
      onSubmit: () => {
        handleClose();
        onDelete();
      },
    });
  };

  // Render
  return (
    <>
      {mode === 'preview' ? (
        <BodyOne
          sx={{ cursor: 'pointer', width: 'fit-content' }}
          onClick={() => toggleMode()}
        >
          {title}
        </BodyOne>
      ) : (
        <Box
          sx={({ palette }) => ({
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '3.5rem',
            right: 0,
            left: 0,
            background: palette.background.paper,
            top: 0,
          })}
        >
          <Slide
            direction='left'
            in={mode === 'edit'}
            mountOnEnter
            unmountOnExit
          >
            <Box
              sx={({ palette }) => ({
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '3.5rem',
                right: 0,
                left: 0,
                background: palette.background.paper,
                top: 0,
                px: '1rem',
              })}
            >
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Controller
                  control={control}
                  name='title'
                  defaultValue={title}
                  render={({ field }) => (
                    <TextField
                      placeholder='عنوان اکسس'
                      InputProps={{ sx: { height: '2.5rem', width: '21rem' } }}
                      {...field}
                    />
                  )}
                />
                <Button
                  variant='outlined'
                  color='info'
                  sx={{ height: '2.5rem', width: '5rem' }}
                  onClick={handleSubmit(handleUpdate)}
                >
                  ذخیره
                </Button>
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton color='error' onClick={handleOpenDeleteDialog}>
                  <DeleteIcon />
                </IconButton>
                <CloseIconButton onClick={() => toggleMode()} />
              </Box>
            </Box>
          </Slide>
        </Box>
      )}
    </>
  );
};

export default AccessTitle;

import Cookies from 'universal-cookie';
import dayjs from 'dayjs';

// Custom Utilities
import getOS from 'core/utilities/helper/os';
import getBrowser from 'core/utilities/helper/browser';

export const commonCookies = new Cookies();

/**
 * Sets OS-related cookies using the current OS information.
 *
 * @returns {void}
 */
export const setOSCookies = (): void => {
  const os = getOS();
  commonCookies.set('os-name', os.name, {
    secure: true,
    expires: dayjs().add(1, 'w').toDate(),
  });
  commonCookies.set('os-version', os.version, {
    secure: true,
    expires: dayjs().add(1, 'w').toDate(),
  });
};

/**
 * Removes OS-related cookies.
 *
 * @returns {void}
 */
export const removeOsCookies = (): void => {
  commonCookies.remove('os-name');
  commonCookies.remove('os-version');
};

/**
 * Sets browser-related cookies using the current browser information.
 *
 * @returns {void}
 */
export const setBrowserCookies = (): void => {
  const browser = getBrowser();
  commonCookies.set('browser-name', browser.name, {
    secure: true,
    expires: dayjs().add(1, 'w').toDate(),
  });
  commonCookies.set('browser-major-version', browser.version, {
    secure: true,
    expires: dayjs().add(1, 'w').toDate(),
  });
};

/**
 * Removes browser-related cookies.
 *
 * @returns {void}
 */
export const removeBrowserCookies = (): void => {
  commonCookies.remove('browser-name');
  commonCookies.remove('browser-major-version');
};

import * as React from 'react';

// Custom Common Components
import PageHeroBox from 'core/components/shared/Box/PageHero';
import ButtonLink from 'core/components/shared/Button/ButtonLink';
import PageTitle from 'core/components/shared/Typography/PageTitle';

// Custom Icons
import PlusCircleOutlineIcon from 'core/components/icons/PlusCircleOutline';

// Custom Types
interface FormListActionsProps {}

const FormListActions: React.FC<FormListActionsProps> = (props) => {
  // Render
  return (
    <PageHeroBox>
      <PageTitle>لیست فرم‌ها</PageTitle>
      <ButtonLink
        variant='contained'
        color='info'
        startIcon={<PlusCircleOutlineIcon />}
        href='/forms/add'
        sx={{
          '.MuiButton-startIcon': {
            marginRight: '0.25rem',
          },
        }}
      >
        فرم جدید
      </ButtonLink>
    </PageHeroBox>
  );
};

export default FormListActions;

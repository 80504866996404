import type { FC } from 'react';

// Core Components
import Stack from 'core/components/base/layout/Stack';
import IconButton from 'core/components/base/inputs/IconButton';

// Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import PageHeroButtonLink from 'core/components/shared/Button/PageHeroButtonLink';

// Feature Components
import ManagerGroupsDataGrid from 'features/manager/groups/components/details/body/List';

// Custom Icons
import DeleteIcon from 'core/components/icons/DeleteIcon';

// Custom Hooks
import useSelection from 'core/hooks/common/useSelection';

// Context
import DocsProvider from 'core/hooks/api/useDocs/Context';
import SelectionProvider from 'core/store/context/common/UseSelection';
import { useDialogContext } from 'core/store/context/DialogProvider';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Utilities
import {
  useDeleteManagerGroupsMutation,
  useManagerGroupDocs,
} from 'features/manager/groups/hooks';

// Custom Types
interface ManagerGroupsPageProps {}

const ManagerGroups: FC<ManagerGroupsPageProps> = (props) => {
  // Context
  const loading = useSelectLoading();
  const { onOpenDialog, onCloseDialog } = useDialogContext();

  // Hooks
  const managerMethods = useManagerGroupDocs();
  const selectionMethods = useSelection();
  const { selections, onClearSelections } = selectionMethods;
  const { mutate: handleDeleteManagerGroup } = useDeleteManagerGroupsMutation({
    autoAlert: { mode: 'delete', name: 'گروه‌های انتخابی' },
    onSuccess: () => onClearSelections(),
    onSettled: () => onCloseDialog(),
  });

  // Utilities
  const handleOpenDeleteDialog = () =>
    onOpenDialog({
      title: 'آیا از حذف گروه‌ انتخاب شده مطمئن هستید؟',
      onSubmit: () => handleDeleteManagerGroup(selections),
    });

  // Render
  return (
    <DocsProvider {...managerMethods}>
      <PageHeadConfigs pageTitle='گروه‌های مدیران' />
      <PageHeroBox>
        <HeadingSix>گروه‌های مدیران</HeadingSix>
        <Stack>
          {selections.length !== 0 && (
            <RowStack sx={{ alignItems: 'center' }} spacing={1}>
              <IconButton
                disabled={selections.length === 0}
                onClick={handleOpenDeleteDialog}
              >
                <DeleteIcon color='error' />
              </IconButton>
              <BodyTwo>{selections.length?.toPersian()} انتخاب شده</BodyTwo>
            </RowStack>
          )}
          <PageHeroButtonLink
            variant='outlined'
            disabled={selections.length > 1 || loading}
            href='/manager-groups/add'
            sx={{ minHeight: '2.5rem' }}
          >
            افزودن
          </PageHeroButtonLink>
        </Stack>
      </PageHeroBox>
      <SelectionProvider {...selectionMethods}>
        <ManagerGroupsDataGrid disabled={loading} />
      </SelectionProvider>
    </DocsProvider>
  );
};

export default ManagerGroups;

// Custom Utilities
import getEndpoint from 'core/utilities/helper/getEndpoint';
import apiHandler from 'core/utilities/apiHandler';
import { bakePattern } from 'features/appBuilder/patterns/utilities';
import { transformPatternContainers } from './transformer';
import { getDoc, getDocs } from 'core/utilities/apiHandler/helper';

// Custom Types
import type { PatternDataType } from 'features/appBuilder/patterns/types/type';
import type { PatternType } from 'features/appBuilder/patterns//core/types/item';
import type { PatternItemProps } from 'features/appBuilder/patterns/types/item';
import type { PatternContainerProps } from 'features/appBuilder/patterns/types/container';
import type { TablePatternProps } from 'features/appBuilder/patterns/types';
import type {
  ApiGetDocResponse,
  ApiGetDocsResponse,
} from 'core/types/api/hook/response';

/**
 * Fetches patterns of a specific type.
 *
 * @param {PatternType} type - The type of patterns to fetch.
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<ApiGetDocsResponse<PatternItemProps>>} - A promise that resolves to the response containing pattern items.
 */
export const getPatternsOf = async (
  type: PatternType,
  signal?: AbortSignal
): Promise<ApiGetDocsResponse<PatternItemProps>> => {
  const { getPatterns } = getEndpoint();
  const endpoint = `${getPatterns}/${type}`;
  return await getDocs<PatternItemProps[], PatternItemProps>(endpoint, {
    signal,
    returnMutationFn: (status, data) => ({ status, list: data }),
  });
};

/**
 * Fetches the count of patterns available.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<ApiGetDocsResponse<PatternContainerProps>>} - A promise that resolves to the response containing pattern container data.
 */
export const getPatterns = async (
  signal?: AbortSignal
): Promise<ApiGetDocsResponse<PatternContainerProps>> => {
  const endpoint = getEndpoint().getPatternCountURL;
  return await getDocs<
    { data: { type: PatternType; itemsCount: number }[] },
    PatternContainerProps
  >(endpoint, {
    signal,
    returnMutationFn: (status, data) => ({
      status,
      list: data && data.data ? transformPatternContainers(data.data) : [],
    }),
  });
};

/**
 * Duplicates a pattern by its ID.
 *
 * @param {PatternType} type - The type of the pattern to duplicate.
 * @param {string} id - The ID of the pattern to duplicate.
 * @returns {Promise<{ status: number }>} - A promise that resolves to an object containing the status of the operation.
 */
export const duplicatePattern = async (
  type: PatternType,
  id: string
): Promise<{ status: number }> => {
  const { duplicatePattern: endpoint } = getEndpoint();
  const { status } = await apiHandler.post(`${endpoint}/${type}?id=${id}`);
  return { status };
};

/**
 * Fetches a specific pattern by its ID.
 *
 * @param {PatternType} type - The type of the pattern to fetch.
 * @param {string} patternId - The ID of the pattern to fetch.
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<ApiGetDocResponse<PatternType>>} - A promise that resolves to the response containing the pattern data.
 */
export const getPattern = async (
  type: PatternType,
  patternId: string,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<PatternType>> => {
  const { getPattern } = getEndpoint();
  const endpoint = `${getPattern}/${type}?id=${patternId}`;
  return await getDoc<PatternType, PatternType>(endpoint, { signal });
};

/**
 * Adds a new pattern.
 *
 * @param {{ type: PatternType; pattern: PatternDataType }} payload - The payload containing the type and data of the pattern to add.
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<{ status: number }>} - A promise that resolves to an object containing the status of the operation.
 */
export const addPattern = async (
  payload: { type: PatternType; pattern: PatternDataType },
  signal?: AbortSignal
): Promise<{ status: number }> => {
  const { addPattern } = getEndpoint();
  const endpoint = `${addPattern}/${payload.type}`;
  const { status } = await apiHandler.post(
    endpoint,
    bakePattern(payload.pattern),
    {
      signal,
    }
  );
  return { status };
};

/**
 * Edits an existing pattern by its ID.
 *
 * @param {PatternType} type - The type of the pattern to edit.
 * @param {string} patternId - The ID of the pattern to edit.
 * @param {PatternDataType} pattern - The updated pattern data.
 * @returns {Promise<{ status: number }>} - A promise that resolves to an object containing the status of the operation.
 */
export const editPattern = async (
  type: PatternType,
  patternId: string,
  pattern: PatternDataType
): Promise<{ status: number }> => {
  const { editPattern } = getEndpoint();
  const endpoint = `${editPattern}/${type}?id=${patternId}`;
  const { status } = await apiHandler.patch(endpoint, pattern);
  return { status };
};

/**
 * Deletes multiple patterns by their IDs.
 *
 * @param {{ type: PatternType; ids: string[] }} payload - The payload containing the type and IDs of the patterns to delete.
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<{ status: number }>} - A promise that resolves to an object containing the status of the operation.
 */
export const deletePatterns = async (
  payload: { type: PatternType; ids: string[] },
  signal?: AbortSignal
): Promise<{ status: number }> => {
  const endPoint = getEndpoint();
  const { status } = await apiHandler.delete(
    `${endPoint.deletePattern}/${payload.type}`,
    { ids: payload.ids },
    { signal }
  );
  return { status };
};

/**
 * Fetches a preview of a specific pattern.
 *
 * @param {string} patternId - The ID of the pattern to preview.
 * @param {string} audienceId - The ID of the audience for the preview.
 * @returns {Promise<ApiGetDocResponse<TablePatternProps>>} - A promise that resolves to an object containing the status and optional preview data.
 */
export const getPatternPreview = async (
  patternId: string,
  audienceId: string
): Promise<ApiGetDocResponse<TablePatternProps>> => {
  const endpoint = `${
    getEndpoint().getPreview
  }/${patternId}?audienceId=${audienceId}`;
  return await getDoc<TablePatternProps, TablePatternProps>(endpoint);
};

import { AudienceDataFieldsLabel } from 'features/audience/settings/types';
import type { ProfileField } from 'features/audience/settings/components/ProfileFields';

/**
 * Retrieves the label for a given profile field name.
 *
 * @param {ProfileField} name - The name of the profile field for which to get the label.
 * @returns {string} The label associated with the profile field name, or the name itself if no label exists.
 */
export const getProfileFieldLabel = (name: ProfileField): string =>
  AudienceDataFieldsLabel[name] || name;

// Custom Types
import type { FormPatternDataProps } from 'features/appBuilder/patterns/features/form/types';

/**
 * Validates the form pattern data and returns an error message if validation fails.
 *
 * @param {FormPatternDataProps} data - The form pattern data to validate.
 * @returns {string} A string containing the error message if validation fails, or an empty string if the data is valid.
 */
export const validateFormPattern = (data: FormPatternDataProps): string => {
  let error = '';

  if (!data.patternTitle.trim()) {
    error = 'وارد کردن عنوان پترن الزامی می‌باشد.';
  }

  return error;
};

import { useRef, type FC } from 'react';
import { useFormContext } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Button from 'core/components/base/inputs/Button';
import Slide, { type SlideProps } from 'core/components/base/utils/Slide';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';
import useClickOutside from 'core/hooks/useClickOutside';

// Custom Utilities
import { genFormInitialInput } from 'features/form/forms/utilities/input/initial';
import {
  getFormInputTypeIcon,
  getFormInputTypeLabel,
} from 'features/form/forms/utilities/input/type';

// Custom Types
import type { FormProps } from 'features/form/forms/types/item';
import type { FormInputType } from 'features/form/forms/types/item/input/type';
export interface TypeIndicatorSlideProps extends Omit<SlideProps, 'children'> {
  index: number;
  type?: FormInputType;
  onClose?: () => void;
  onUpdate?: () => void;
}

const buttonMap: FormInputType[][] = [
  ['text', 'textarea', 'number'],
  ['radio', 'checkbox', 'select'],
  ['file'],
  ['mobile', 'email', 'nationalId'],
  ['date', 'time'],
  ['rate', 'score', 'agreement'],
];

const TypeIndicatorSlide: FC<TypeIndicatorSlideProps> = (props) => {
  // Props
  const { index, type, onClose, onUpdate, ...otherProps } = props;

  // Context
  const { getValues, setValue } = useFormContext<FormProps>();
  const currentStep = useAppSelector((state) => state.form.currentStep);
  const formId = useAppSelector((state) => state.form.formId);

  // Hooks
  const typesRef = useRef(null);
  useClickOutside(typesRef, () => onClose && onClose());

  const handleTypeChange = (newInpType: FormInputType) => {
    const inputs = cloneDeep(getValues('data.inputs'));
    const inputsIndexMap = cloneDeep(getValues('data.inputsIndexMap'));
    const input = inputs[index];

    if (!input) return;

    const newInput = genFormInitialInput({
      type: newInpType,
      formId,
      sectionId: input.data.sectionId,
      sortIndex: index,
      stepId: currentStep.id,
    });

    inputs.splice(index, 1, newInput);

    inputsIndexMap[newInput.id] = index;

    if (input.id) {
      delete inputsIndexMap[input.id];
    }

    if (onUpdate) onUpdate();

    setValue('data.inputsIndexMap', inputsIndexMap);
    setValue('data.inputs', inputs);
  };

  return (
    <Slide direction='left' {...otherProps}>
      <Box
        ref={typesRef}
        sx={{
          backgroundColor: 'background.default',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          paddingY: '0.5rem',
        }}
      >
        {buttonMap.map((row, index) => (
          <RowStack
            key={`form-field-type-button-map-row-${index}`}
            sx={{
              px: '1rem',
              borderBottom:
                index < buttonMap.length - 1 ? '1px solid white' : undefined,
            }}
          >
            {row.map((buttonType, buttonIndex) => (
              <Button
                key={buttonType + buttonIndex}
                disableRipple
                disabled={type === buttonType}
                startIcon={getFormInputTypeIcon(buttonType)}
                sx={{
                  justifyContent: 'flex-start',
                  color: 'text.secondary',
                  width: '5rem',
                  whiteSpace: 'nowrap',
                  ':hover': {
                    color: 'inherit',
                    background: 'none',
                  },
                  ':disabled': {
                    color: 'inherit',
                  },
                }}
                onClick={() => handleTypeChange(buttonType)}
              >
                {getFormInputTypeLabel(buttonType)}
              </Button>
            ))}
          </RowStack>
        ))}
      </Box>
    </Slide>
  );
};

export default TypeIndicatorSlide;

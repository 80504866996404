import { useRef, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import RMDatePicker from 'react-multi-date-picker';
import DateObject from 'react-date-object';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import persian from 'react-date-object/calendars/persian';
import persian_fa from 'react-date-object/locales/persian_fa';

// Core Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import Box from 'core/components/base/layout/Box';
import KeyboardArrowDownIcon from 'core/components/icons/ArrowDown';

// Core Utilities
import { useAppSelector } from 'core/hooks/redux';

// Core Styles
import './date.css';

// Feature Types
import { ContentDetailsProps } from 'features/content/contents/types/details';
import { getLocaleDate, getLocaleTime } from 'core/utilities/helper/date';
import useBoolean from 'core/hooks/useBoolean';

// Component Types
export interface ContentDetailsActionsDateProps {}

const ContentDetailsActionsDate: FC<ContentDetailsActionsDateProps> = (
  props
) => {
  // Hooks
  const pickerIsInitialized = useBoolean();
  const loading = useAppSelector((state) => state.loading);
  const { control, setValue } = useFormContext<ContentDetailsProps>();

  const pickerRef = useRef<HTMLElement>(null);

  // Utilities
  const handleOpen = () => {
    if (
      pickerRef.current &&
      'openCalendar' in pickerRef.current &&
      typeof pickerRef.current.openCalendar === 'function'
    ) {
      pickerRef.current.openCalendar();
      pickerIsInitialized.setTrue();
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        height: '40px',
        wrap: 'nowrap',
      }}
    >
      <Controller
        control={control}
        name='data.publishDate'
        render={({ field }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              minWidth: 'fit-content',
              backgroundColor: 'background.paper',
              opacity: loading ? 0.6 : 1,
              cursor: loading ? 'auto' : 'pointer',
              p: '0.5rem',
              borderRadius: '0.5rem',
              ':hover': {
                opacity: '0.8',
              },
            }}
            onClick={handleOpen}
          >
            <KeyboardArrowDownIcon />
            <BodyOne whiteSpace='nowrap' sx={{ p: '0 0.5rem 0 0.25rem' }}>
              {getLocaleTime(field.value)}
              {' | '}
              {getLocaleDate(field.value)}
            </BodyOne>
          </Box>
        )}
      />
      <RMDatePicker
        className='custom_picker'
        arrowStyle={{ display: 'none' }}
        ref={pickerRef}
        calendar={persian}
        format='HH:mm:ss - YYYY/MM/DD'
        locale={persian_fa}
        plugins={[<TimePicker position='bottom' />]}
        onChange={(date) => {
          if (date instanceof DateObject && pickerIsInitialized.state) {
            setValue('data.publishDate', new Date(date.toDate()).toISOString());
          }
        }}
        style={{
          boxShadow: 'none',
          display: 'none',
          position: 'absolute',
          height: '40px',
          top: 0,
          left: 0,
        }}
      />
    </Box>
  );
};

export default ContentDetailsActionsDate;

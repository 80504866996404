import { createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase('/loading/true', (state) => {
      state = true;
      return state;
    });
    builder.addCase('/loading/false', (state) => {
      state = false;
      return state;
    });
  },
});

export default loadingSlice.reducer;

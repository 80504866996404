import * as React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Styles
import 'contenido/dist/styles.css';

// Custom Middlewares
import ProtectedRoutesProvider from 'core/components/middleware/route/Protected';

// Custom Utilities
import { convertNumberToPersian } from 'core/utilities/helper/helperPack';
import { getToken } from 'core/utilities/token/token';
import { setBrowserCookies, setOSCookies } from 'core/utilities/helper/cookies';
import PublicRoutesProvider from 'core/components/middleware/route/Public';

const App = () => {
  // States
  const [userToken, setUserToken] = React.useState<string>(getToken());

  // Hooks
  React.useEffect(() => {
    setUserToken(getToken());
    initializeCommonCookies();
  }, []);

  const initializeCommonCookies = () => {
    setOSCookies();
    setBrowserCookies();
  };
  // eslint-disable-next-line
  String.prototype.toPersian = function () {
    return convertNumberToPersian(this as string);
  };

  // eslint-disable-next-line
  Number.prototype.toPersian = function () {
    return convertNumberToPersian(this.toString());
  };

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      {userToken ? <ProtectedRoutesProvider /> : <PublicRoutesProvider />}
    </QueryClientProvider>
  );
};

export default App;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface DashboardIconProps extends SvgIconProps {}

const DashboardIcon: React.FC<DashboardIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M3 13h8V3H3zm0 8h8v-6H3zm10 0h8V11h-8zm0-18v6h8V3z' />
  </IconBuilder>
);

export default DashboardIcon;

import { memo, useMemo, useCallback } from 'react';
import isEqual from 'lodash/isEqual';

// Type
import type { FC } from 'react';

// Custom Feature Components
import FormTextInput from 'features/form/forms/components/details/item/Input/Text';
import FormNumberInput from 'features/form/forms/components/details/item/Input/Number';
import FormAgreementInput from 'features/form/forms/components/details/item/Input/Agreement';
import FormFileInput from 'features/form/forms/components/details/item/Input/File';
import FormCheckboxInput from 'features/form/forms/components/details/item/Input/Checkbox';
import FormRadioInput from 'features/form/forms/components/details/item/Input/Radio';
import FormTextareaInput from 'features/form/forms/components/details/item/Input/Textarea';
import FormSelectInput from 'features/form/forms/components/details/item/Input/Select';
import FormMobileInput from 'features/form/forms/components/details/item/Input/Mobile';
import FormEmailInput from 'features/form/forms/components/details/item/Input/Email';
import FormNationalIdInput from 'features/form/forms/components/details/item/Input/NationalId';
import FormDateInput from 'features/form/forms/components/details/item/Input/Date';
import FormTimeInput from 'features/form/forms/components/details/item/Input/Time';
import FormRateInput from 'features/form/forms/components/details/item/Input/Rate';
import FormScoreInput from 'features/form/forms/components/details/item/Input/Score';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import { useAppSelector } from 'core/hooks/redux';
import {
  setFormActionMode,
  setFormCurrentSectionId,
} from 'features/form/forms/store/actions';

// Custom Types
import type { RoundPaperProps } from 'core/components/shared/Paper/RoundPaper';
import type { FormInput } from 'features/form/forms/types/item/input';
import type { FormTextInputProps } from 'features/form/forms/types/item/input/text';
import type { FormNumberInputProps } from 'features/form/forms/types/item/input/number';
import type { FormAgreementInputProps } from 'features/form/forms/types/item/input/agreement';
import type { FormFileInputProps } from 'features/form/forms/types/item/input/file';
import type { FormInputWrapperProps } from 'features/form/forms/components/details/item/Input/shared/Wrapper';
import type { FormCheckboxInputProps } from 'features/form/forms/types/item/input/checkbox';
import type { FormRadioInputProps } from 'features/form/forms/types/item/input/radio';
import type { FormSelectInputProps } from 'features/form/forms/types/item/input/select';
import type { FormTextAreaInputProps } from 'features/form/forms/types/item/input/textarea';
import type { FormMobileInputProps } from 'features/form/forms/types/item/input/mobile';
import type { FormEmailInputProps } from 'features/form/forms/types/item/input/email';
import type { FormNationalIdInputProps } from 'features/form/forms/types/item/input/nationalid';
import type { FormDateInputProps } from 'features/form/forms/types/item/input/date';
import type { FormTimeInputProps } from 'features/form/forms/types/item/input/time';
import type { FormRateInputProps } from 'features/form/forms/types/item/input/rate';
import type { FormScoreInputProps } from 'features/form/forms/types/item/input/score';
export interface FormInputComponentProps extends RoundPaperProps {
  input: FormInput;
}

const FormInputComponent: FC<FormInputComponentProps> = (props) => {
  // Props
  const { input, ...otherProps } = props;

  // Hooks
  const actionMode = useAppSelector((state) => state.form.actionMode);
  const currentSectionId = useAppSelector(
    (state) => state.form.currentSectionId
  );
  const loading = useSelectLoading();

  const isInInputRelatedModes = useMemo(
    () =>
      ['INPUT', 'INPUT_DELETE', 'INPUT_SORT', 'INITIAL'].includes(actionMode),
    [actionMode]
  );

  const isInAnotherSection = useMemo(
    () =>
      currentSectionId && currentSectionId !== input.data.sectionId
        ? true
        : false,
    [currentSectionId, input.data.sectionId]
  );

  const disabled = useMemo(
    () => loading || !isInInputRelatedModes || isInAnotherSection,
    [loading, isInInputRelatedModes, isInAnotherSection]
  );

  // Utilities
  const updateActionMode = useCallback(() => {
    setFormActionMode('INPUT');
    setFormCurrentSectionId(input.data.sectionId);
  }, [input?.data?.sectionId]);

  const propsToSpread: FormInputWrapperProps = useMemo(
    () => ({
      disabled,
      actionMode,
      sectionIsFocused: !isInAnotherSection,
      onUpdate: updateActionMode,
      ...otherProps,
    }),
    [disabled, actionMode, otherProps, isInAnotherSection, updateActionMode]
  );

  switch (input.data.type) {
    case 'text':
      return (
        <FormTextInput input={input as FormTextInputProps} {...propsToSpread} />
      );

    case 'number':
      return (
        <FormNumberInput
          input={input as FormNumberInputProps}
          {...propsToSpread}
        />
      );

    case 'agreement':
      return (
        <FormAgreementInput
          input={input as FormAgreementInputProps}
          {...propsToSpread}
        />
      );

    case 'file':
      return (
        <FormFileInput input={input as FormFileInputProps} {...propsToSpread} />
      );

    case 'checkbox':
      return (
        <FormCheckboxInput
          input={input as FormCheckboxInputProps}
          {...propsToSpread}
        />
      );

    case 'radio':
      return (
        <FormRadioInput
          input={input as FormRadioInputProps}
          {...propsToSpread}
        />
      );

    case 'textarea':
      return (
        <FormTextareaInput
          input={input as FormTextAreaInputProps}
          {...propsToSpread}
        />
      );

    case 'select':
      return (
        <FormSelectInput
          input={input as FormSelectInputProps}
          {...propsToSpread}
        />
      );

    case 'mobile':
      return (
        <FormMobileInput
          input={input as FormMobileInputProps}
          {...propsToSpread}
        />
      );

    case 'email':
      return (
        <FormEmailInput
          input={input as FormEmailInputProps}
          {...propsToSpread}
        />
      );

    case 'nationalId':
      return (
        <FormNationalIdInput
          input={input as FormNationalIdInputProps}
          {...propsToSpread}
        />
      );

    case 'date':
      return (
        <FormDateInput input={input as FormDateInputProps} {...propsToSpread} />
      );

    case 'time':
      return (
        <FormTimeInput input={input as FormTimeInputProps} {...propsToSpread} />
      );

    case 'rate':
      return (
        <FormRateInput input={input as FormRateInputProps} {...propsToSpread} />
      );

    case 'score':
      return (
        <FormScoreInput
          input={input as FormScoreInputProps}
          {...propsToSpread}
        />
      );

    default:
      return <p>Not Supported Yet!</p>;
  }
};

export default memo(FormInputComponent, isEqual);

import {
  ListItemText as MuiListItemText,
  ListItemTextProps as MuiListItemTextProps,
  styled,
} from '@mui/material';

export interface ListItemTextProps extends MuiListItemTextProps {}

const ListItemText = styled(MuiListItemText)<ListItemTextProps>(() => ({}));

export default ListItemText;

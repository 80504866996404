import { type FC } from 'react';

// Custom Common Components
import SingleAutocomplete from 'core/components/shared/Autocomplete/api/SingleAutocomplete';

// Custom Utilities
import { skinsQueryKey } from 'features/appBuilder/skins/hooks';
import { getSkinsList } from 'features/appBuilder/skins/utilities/api';

// Custom Types
import type { GenericAutocompleteProps } from 'core/components/shared/Autocomplete/api/SingleAutocomplete';

const SkinsAutocomplete: FC<
  Omit<GenericAutocompleteProps<{}>, 'apiHandler' | 'queryKey'>
> = (props) => {
  return (
    <SingleAutocomplete
      apiHandler={getSkinsList}
      queryKey={skinsQueryKey}
      {...props}
    />
  );
};

export default SkinsAutocomplete;

// Custom Utilities
import { genUUID } from 'core/utilities/helper';

// Custom Types
import type { BannerContainerProps } from 'features/banner/banners/types';

export const initialBannerContainer: BannerContainerProps = {
  id: 'new',
  data: {
    items: [],
    relatedMediaId: genUUID(),
    title: 'فهرست جدید',
  },
};

export const genBannerContainer = (
  props?: Partial<BannerContainerProps>
): BannerContainerProps => ({
  id: 'new',
  data: {
    ...initialBannerContainer.data,
    ...props,
  },
});

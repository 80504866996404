import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import TextField from 'core/components/base/inputs/TextField';
import InputWrapper from 'core/components/shared/Wrapper/Input';

// Feature Shared Components
import PatternItemContainer, {
  type PatternItemContainerProps,
} from 'features/appBuilder/patterns/core/components/shared/items/Container';
import SkinsAutocomplete from 'features/appBuilder/skins/components/Autocomplete';
import Box from 'core/components/base/layout/Box';
import ColorPicker from 'core/components/shared/Picker/Color';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
export interface PatternSearchbarItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
}

const PatternSearchbarItem: FC<PatternSearchbarItemProps> = (props) => {
  // Props
  const { reference, ...otherProps } = props;

  // Hooks
  const { control, setValue } = useFormContext();
  const loading = useSelectLoading();

  return (
    <PatternItemContainer reference={reference} {...otherProps}>
      <Box sx={{ display: 'flex', gap: 2, ['&>*']: { flexGrow: 1 } }}>
        <InputWrapper disabled={loading} maxWidth='12rem' label='عنوان جستجو'>
          <Controller
            control={control}
            name={`${reference}.title`}
            render={({ field }) => <TextField disabled={loading} {...field} />}
          />
        </InputWrapper>
        <InputWrapper disabled={loading} label='اسکین'>
          <Controller
            control={control}
            name={`${reference}.skinId`}
            render={({ field }) => (
              <SkinsAutocomplete
                defaultValue={field.value}
                onChange={(selectedId) => setValue(field.name, selectedId)}
                disabled={loading}
                fullWidth
              />
            )}
          />
        </InputWrapper>
      </Box>
      <InputWrapper disabled={loading} row label='بوردر فیلد'>
        <Controller
          control={control}
          name={`${reference}.borderColor`}
          render={({ field }) => (
            <ColorPicker
              disabled={loading}
              color={field.value}
              onColorChange={(color) => setValue(field.name, color)}
            />
          )}
        />
      </InputWrapper>
    </PatternItemContainer>
  );
};

export default PatternSearchbarItem;

// Custom Utilities
import { generateObjectId } from 'core/utilities/helper/id';
import { getInitialCommonInputProps } from 'features/form/forms/utilities/input/initial/common';

// Custom Types
import type { FormInputInitialParams } from 'features/form/forms/utilities/input/initial/common';
import type { FormFileInputProps } from 'features/form/forms/types/item/input/file';

interface Params extends FormInputInitialParams {}

export const genFormFileInput = (params: Params): FormFileInputProps => {
  const commonProps = getInitialCommonInputProps({
    ...params,
  });

  return {
    id: `draft-${generateObjectId()}`,
    data: {
      ...commonProps,
      accept: ['mp4', 'jpg', 'png'],
      type: 'file',
      minFileSize: 10,
      maxFileSize: 2048,
      uploadCountLimit: 1,
    },
  };
};

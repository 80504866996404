// Custom Store
import store from 'core/store';

// Custom Utilities
import { actions } from 'features/form/forms/store/slice';

// Custom Types
import type { FormInput } from 'features/form/forms/types/item/input';

export const updateFormInputs = (inputs: FormInput[]) => {
  store.dispatch(actions.updateInputs(inputs));
};

export const toggleFormInputSelection = (inputId: string) => {
  store.dispatch(actions.toggleSelectInput(inputId));
};

export const toggleFormIsInInputConfigSelection = (value?: boolean) => {
  const prev = store.getState().form.isInInputConfigSelection;

  store.dispatch(actions.toggleInputConfigSelection(value || !prev));
};

import { FC, useRef, useState } from 'react';
import { BubbleMenu, type Editor as EditorProps } from '@tiptap/react';

// Core Components
import Box from 'core/components/base/layout/Box';
import Divider from 'core/components/base/display/Divider';
import EditorAlignIconButton from 'core/components/shared/Editor/Toolbar/IconButton/Align';
import EditorBoldIconButton from 'core/components/shared/Editor/Toolbar/IconButton/Bold';
import EditorFontSizeIconButton from 'core/components/shared/Editor/Toolbar/IconButton/FontSize';
import EditorLinkIconButton from 'core/components/shared/Editor/Toolbar/IconButton/Link';
import RowStack from 'core/components/shared/Stack/RowStack';
import EditorBubbleMenuLinkMode from 'core/components/shared/Editor/BubbleMenu/link';

// Core Utilities
import useClickOutside from 'core/hooks/useClickOutside';

// Component Types
interface EditorBubbleMenuProps {
  editor: EditorProps | null;
}

const EditorBubbleMenu: FC<EditorBubbleMenuProps> = (props) => {
  // Props
  const { editor } = props;

  // Hooks
  const boxRef = useRef<HTMLElement>(null);
  const [mode, setMode] = useState<'link' | 'normal'>('normal');

  useClickOutside(boxRef, () => setMode('normal'));

  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{
        duration: 100,
      }}
      children={
        <Box
          dir='ltr'
          ref={boxRef}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.25rem',
            border: '1px solid',
            borderColor: 'divider',
            bgcolor: 'background.paper',
            borderRadius: '0.5rem',
            p: '0.25rem 0.5rem',
            minWidth: '22rem',
          }}
        >
          {mode === 'normal' ? (
            <>
              <RowStack>
                <EditorLinkIconButton
                  editor={editor}
                  onClick={() => setMode('link')}
                />
              </RowStack>
              <Divider flexItem orientation='vertical' />
              <RowStack spacing={0.5} alignItems='center'>
                <EditorBoldIconButton editor={editor} />
                <EditorFontSizeIconButton editor={editor} />
              </RowStack>
              <Divider flexItem orientation='vertical' />
              <RowStack spacing={0.5} alignItems='center'>
                <EditorAlignIconButton editor={editor} />
              </RowStack>
            </>
          ) : (
            <EditorBubbleMenuLinkMode
              editor={editor}
              onClose={() => setMode('normal')}
            />
          )}
        </Box>
      }
    />
  );
};

export default EditorBubbleMenu;

import { configureStore } from '@reduxjs/toolkit';

// Slices
import notification from 'core/store/slices/core/shared/notification';
import loading from 'core/store/slices/core/shared/loading';
import file from 'core/store/slices/core/shared/file';
import form from 'features/form/forms/store';
import feedback from 'features/form/feedbacks/store';
import process from 'features/form/processes/store';
import patterns from 'features/appBuilder/patterns/store';
import menu from 'core/store/slices/feature/menu';
import banner from 'core/store/slices/feature/banner';
import table from 'features/appBuilder/patterns/features/table/store';
import upload from 'core/store/slices/core/shared/upload';
import manager from 'core/store/slices/feature/manager';
import setting from 'core/store/slices/core/setting';
import auth from 'core/store/slices/core/auth';
import navigation from 'core/store/slices/core/navigation';
import sidebar from 'core/store/slices/core/shared/sidebar';
import theme from 'core/store/slices/core/theme';

const store = configureStore({
  reducer: {
    loading,
    notification,
    file,
    form,
    feedback,
    process,
    menu,
    banner,
    patterns,
    table,
    upload,
    manager,
    setting,
    auth,
    navigation,
    sidebar,
    theme,
  },
});

export default store;

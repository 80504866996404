import { SetStateAction, type FC, Dispatch, useEffect } from 'react';
import { Controller, useForm, type SubmitHandler } from 'react-hook-form';

// Custom Core Components
import Collapse from 'core/components/base/utils/Collapse';
import IconButton from 'core/components/base/inputs/IconButton';
import InputAdornment from 'core/components/base/inputs/InputAdornment';
import MenuItem from 'core/components/base/navigation/MenuItem';
import Select from 'core/components/base/inputs/Select';
import TextField from 'core/components/base/inputs/TextField';

// Custom Common Components
import ActionsBox from 'core/components/shared/Box/Actions';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Shared Components
import FormLoadingButton from 'features/form/shared/components/loadingButton/Core';
import FormButtonLink from 'features/form/shared/components/buttonLink';

// Custom Icon Components
import FilterIcon from 'core/components/icons/Filter';
import KeyboardArrowDownIcon from 'core/components/icons/ArrowDown';
import SearchIcon from 'core/components/icons/Search';

// Custom Styles
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';

// Context
import { useDialogContext } from 'core/store/context/DialogProvider';
import { useDocsContext } from 'core/store/context/useDeprecatedDocsApi';
import { useSelectionContext } from 'core/store/context/common/UseSelection';

// Custom Utilities
import { setAppAlert } from 'core/utilities/helper';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGroupOperationContext } from 'core/store/context/common/UseGroupOperation';
import {
  useAddFeedbackToProcessMutation,
  useDeleteFeedbacksMutation,
  useEditFeedbackIsEditableStatusMutation,
} from 'features/form/feedbacks/hooks';

// Custom Types
export interface FormFeedbacksListNormalActionsProps {
  in?: boolean;
  onOpenFilter?: () => void;
}

export type FeedbackListOperations =
  | 'delete'
  | 'editable'
  | 'non-editable'
  | 'add-process';

export type FeedbackListOperationHandler = Dispatch<
  SetStateAction<FeedbackListOperations>
>;

const FormFeedbacksListNormalActions: FC<
  FormFeedbacksListNormalActionsProps
> = (props) => {
  // Props
  const { onOpenFilter } = props;

  // Hooks
  const { formId } = useParams();
  const [searchParams] = useSearchParams();
  const { control, handleSubmit, reset } = useForm<{ search: string }>();

  // Context
  const loading = useSelectLoading();
  const { onOpenDialog, onCloseDialog } = useDialogContext();
  const { other, onFilter } = useDocsContext();
  const { selections, onClearSelections } = useSelectionContext();
  const { operation, onOperationChange } =
    useGroupOperationContext<FeedbackListOperations>();

  // Hooks
  const { mutate: handleDeleteFeedbacks } = useDeleteFeedbacksMutation({
    autoAlert: { mode: 'delete', name: 'بازخوردهای انتخاب شده' },
    onSuccess: () => {
      onClearSelections();
      onCloseDialog();
    },
  });
  const { mutate: handleAddFeedbackToProcess } =
    useAddFeedbackToProcessMutation({
      customAlert: {
        successMessage: 'بازخوردهای مورد نظر با موفقیت به فرایند افزوده شدند.',
        errorMessage: 'افزودن بازخوردها به فرایند با خطا مواجه شد.',
      },
      onSuccess: () => {
        onClearSelections();
        onCloseDialog();
      },
    });
  const { mutate: handleEditFeedbackIsEditableStatus } =
    useEditFeedbackIsEditableStatusMutation({
      autoAlert: { mode: 'update', name: 'وضعیت بازخورد' },
      onSuccess: () => {
        onClearSelections();
        onCloseDialog();
      },
    });

  useEffect(() => {
    const search = searchParams.get('search');
    reset({ search: search ?? '' });
  }, [searchParams, reset]);

  // Utilities
  const handleOpenDialog = () => {
    onOpenDialog({
      title: 'آیا از حذف بازخوردهای انتخاب شده مطمئن هستید؟',
      onSubmit: () => handleDeleteFeedbacks(selections),
    });
  };

  const handleGroupOperation = async () => {
    if (operation === 'none') return;

    if (
      operation === 'add-process' &&
      other &&
      'processId' in other &&
      typeof other.processId === 'string' &&
      other.processId
    ) {
      handleAddFeedbackToProcess({
        selection: selections,
        processId: other.processId,
      });
    } else if (operation === 'editable' || operation === 'non-editable') {
      handleEditFeedbackIsEditableStatus({
        selection: selections,
        isEditable: operation === 'editable' ? true : false,
      });
    }

    if (operation === 'add-process' && 'processId' in other && !other.processId)
      setAppAlert('ابتدا فرایند مد نظر را به فرم متصل نمایید!', 'warning');
  };

  const handleSearch: SubmitHandler<{ search: string }> = (data) => {
    onFilter({ search: data.search });
  };

  return (
    <Collapse in={props.in}>
      <ActionsBox
        sx={{
          overflowX: 'auto',
          overflowY: 'hidden',
          py: '0.25rem',
          ...customRoundedScrollbarStyles,
        }}
      >
        <PageTitle whiteSpace='nowrap' mr='1rem'>
          بازخوردهای فرم {other && 'formTitle' in other ? other.formTitle : ''}
        </PageTitle>
        <RowStack>
          <Controller
            control={control}
            name='search'
            defaultValue=''
            render={({ field }) => (
              <TextField
                placeholder='کلمه جستجو'
                InputProps={{
                  sx: {
                    height: '2.5rem',
                    px: '0.25rem',
                    minWidth: '12rem',
                    maxWidth: '15rem',
                    flexGrow: 1,
                  },
                  startAdornment: (
                    <InputAdornment position='start'>
                      <IconButton
                        onClick={onOpenFilter}
                        color='info'
                        size='small'
                      >
                        <FilterIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        disabled={loading}
                        onClick={handleSubmit(handleSearch)}
                        color='info'
                        size='small'
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onKeyDownCapture={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit(handleSearch)();
                  }
                }}
                {...field}
              />
            )}
          />
          <Select
            sx={{
              height: '2.5rem',
              width: '9rem',
              backgroundColor: 'background.paper',
              fontSize: '0.75rem',
            }}
            IconComponent={KeyboardArrowDownIcon}
            MenuProps={{
              PaperProps: {
                sx: {
                  mt: '0.25rem',
                  boxShadow: 'none',
                },
              },
              MenuListProps: {
                sx: {
                  p: '0.25rem',
                },
              },
            }}
            displayEmpty
            value={operation}
          >
            <MenuItem value='none' sx={{ display: 'none' }}>
              انتخاب عملیات
            </MenuItem>
            {(
              [
                { value: 'delete', title: 'حذف' },
                { value: 'editable', title: 'قابل ویرایش' },
                { value: 'non-editable', title: 'غیرقابل ویرایش' },
                { value: 'add-process', title: 'افزودن به فرآیند' },
              ] as { value: FeedbackListOperations; title: string }[]
            ).map((opt, index) => (
              <MenuItem
                key={opt.value + opt.title + index}
                value={opt.value}
                onClick={() => {
                  if (onOperationChange) {
                    if (operation === opt.value) {
                      onOperationChange('none');
                    } else {
                      onOperationChange(opt.value);
                    }
                  }
                }}
              >
                {opt.title}
              </MenuItem>
            ))}
          </Select>
          <FormLoadingButton
            disabled={
              selections.length === 0 || operation === 'none' || loading
            }
            color='info'
            onClick={() => {
              if (operation === 'delete') {
                handleOpenDialog();
              } else {
                handleGroupOperation();
              }
            }}
          >
            اعمال
          </FormLoadingButton>
          <FormButtonLink
            href={
              `/feedbacks/${formId}/details?activeId=${
                selections.slice().reverse()[0]
              }&` +
              selections
                .slice()
                .reverse()
                .map(
                  (id, index) =>
                    `feedbackId=${id}${
                      selections[index + 1] === undefined ? '' : '&'
                    }`
                )
                .join('')
            }
            disabled={selections.length === 0}
            color='info'
          >
            مشاهده
          </FormButtonLink>
        </RowStack>
      </ActionsBox>
    </Collapse>
  );
};

export default FormFeedbacksListNormalActions;

import type { FC } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

// Core Components
import PageHeroBox from 'core/components/shared/Box/PageHero';
import DeleteIconButton from 'core/components/shared/IconButton/Delete';
import SaveIconButton from 'core/components/shared/IconButton/Save';
import RowStack from 'core/components/shared/Stack/RowStack';

// Feature Components
import ContentDetailsActionsDraft from 'features/content/contents/components/body/actions/draft';
import ContentDetailsActionsSlug from 'features/content/contents/components/body/actions/slug';
import ContentDetailsActionsSkin from 'features/content/contents/components/body/actions/skin';
import ContentDetailsActionsDate from 'features/content/contents/components/body/actions/date';
import ContentDetailsActionsView from 'features/content/contents/components/body/actions/view';

// Feature Utilies
import {
  useAddContentMutation,
  useDeleteContentMutation,
  useEditContentMutation,
} from 'features/content/contents/hooks';

// Context
import { useDialogContext } from 'core/store/context/DialogProvider';

// Feature Types
import type { ContentDetailsProps } from 'features/content/contents/types/details';

// Custom Types
export interface ContentDetailsActionsProps {}

const ContentDetailsActions: FC<ContentDetailsActionsProps> = (props) => {
  // Context
  const { onOpenDialog, onCloseDialog } = useDialogContext();
  const { handleSubmit, getValues } = useFormContext<ContentDetailsProps>();

  // Hooks
  const { pathname } = useLocation();
  const { mutate: handleAddContent } = useAddContentMutation({
    stayOnLoadingAfterMutate: true,
    autoAlert: { mode: 'create', name: 'مطلب' },
    redirectAfterSuccessTo: '/contents',
  });
  const { mutate: handleEditContent } = useEditContentMutation({
    autoAlert: { mode: 'edit', name: 'مطلب' },
  });
  const { mutate: handleDeleteContent } = useDeleteContentMutation({
    autoAlert: { mode: 'delete', name: 'مطلب' },
    redirectAfterSuccessTo: '/contents',
    onSuccess: () => onCloseDialog(),
  });

  // Helpers
  const mode = pathname.includes(`/contents/add`) ? 'add' : 'edit';

  // Utilities
  const handleFormSubmit: SubmitHandler<ContentDetailsProps> = (content) => {
    if (mode === 'add') {
      handleAddContent(content.data);
    } else if (mode === 'edit') {
      handleEditContent(content);
    }
  };

  const handleDelete = () => {
    onOpenDialog({
      title: 'حذف مطلب',
      description: 'آیا از حذف این مطلب اطمینان دارید؟',
      onSubmit: () => {
        handleDeleteContent([getValues('id')]);
      },
    });
  };

  return (
    <PageHeroBox flexDirection='row-reverse'>
      <RowStack spacing={1}>
        {mode === 'edit' && <ContentDetailsActionsView />}
        <ContentDetailsActionsDate />
        <ContentDetailsActionsSkin />
        <ContentDetailsActionsDraft />
        <ContentDetailsActionsSlug />
        {mode === 'edit' && <DeleteIconButton onClick={handleDelete} />}
        <SaveIconButton
          sx={{ height: '40px' }}
          onClick={handleSubmit(handleFormSubmit)}
        />
      </RowStack>
    </PageHeroBox>
  );
};

export default ContentDetailsActions;

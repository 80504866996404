import type { FC } from 'react';

// Feature Components
import ContentDetailsPatternConfigSection from 'features/appBuilder/patterns/features/contentDetails/components/Config';
import ContentDetailsPatternFooterSection from 'features/appBuilder/patterns/features/contentDetails/components/Footer';

// Custom Types
export interface ContentDetailsPatternItemsProps {}

const ContentDetailsPatternItems: FC<ContentDetailsPatternItemsProps> = () => {
  return (
    <>
      <ContentDetailsPatternConfigSection />
      <ContentDetailsPatternFooterSection />
    </>
  );
};

export default ContentDetailsPatternItems;

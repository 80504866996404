import * as React from 'react';
import isEqual from 'lodash/isEqual';

// Custom Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Custom Core
import Box from 'core/components/base/layout/Box';

// Custom Icons
import HeadphonesIcon from 'core/components/icons/Headphones';
import InsertDriveFileIcon from 'core/components/icons/InsertDriveFile';

// Custom Utilities
import { getFileSource } from 'core/utilities/helper/helperPack';

// Custom Types
import type { FileProps } from 'features/file/files/types';
import { styled, SxProps } from '@mui/material';

const Image = styled('img')({});
const Audio = styled('audio')({});
const Video = styled('video')({});
interface PreviewLargeProps {
  fileData: FileProps;
  objectFit?: 'cover' | 'contain';
  sx?: SxProps;
}

const PreviewLarge: React.FC<PreviewLargeProps> = (props) => {
  const { fileData, objectFit = 'cover', sx } = props;

  // Render
  switch (fileData.data.type) {
    case 'image':
      return (
        <Image
          alt={fileData.data.alt || ''}
          loading='eager'
          src={getFileSource(fileData)}
          sx={{
            maxWidth: '100%',
            width: '100%',
            height: '408px',
            margin: '0 auto',
            objectFit,
            borderRadius: '.5rem',
            ...sx,
          }}
        />
      );
    case 'document':
      return (
        <ColumnStack
          spacing={0}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 224,
            height: 224,
            backgroundColor: 'rgba(245, 245, 245, 1)',
            borderRadius: '2rem',
            color: 'rgba(89, 102, 109, 1)',
          }}
        >
          <InsertDriveFileIcon
            sx={{
              fontSize: '8rem',
            }}
          />
          <BodyTwo
            sx={{
              fontSize: '1.5rem',
              fontWeight: 500,
            }}
          >
            {fileData.data.title
              .substring(fileData.data.title.lastIndexOf('.') + 1)
              .toUpperCase()}
          </BodyTwo>
        </ColumnStack>
      );
    case 'audio':
      return (
        <ColumnStack alignItems='center'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 224,
              height: 224,
              backgroundColor: 'rgba(245, 245, 245, 1)',
              borderRadius: '.5rem',
            }}
          >
            <HeadphonesIcon
              sx={{
                color: 'rgba(89, 102, 109, 1)',
                fontSize: '10rem',
              }}
            />
          </Box>
          <Audio
            controls
            controlsList='noplaybackrate'
            src={getFileSource(fileData)}
          />
        </ColumnStack>
      );
    case 'video':
      return (
        <Video
          controls={true}
          src={getFileSource(fileData)}
          sx={{
            maxWidth: '100%',
            width: 718,
            height: '408px',
            maxHeight: '408px',
            margin: '0 auto',
            objectFit: 'contain',
            borderRadius: '1rem',
            ...sx,
          }}
        />
      );
    default:
      return null;
  }
};

export default PreviewLarge;

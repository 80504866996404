// Types
import type { FC } from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface PlusCircleOutlineIconProps extends SvgIconProps {}

const PlusCircleOutlineIcon: FC<PlusCircleOutlineIconProps> = (props) => {
  const { color = 'inherit', ...otherProps } = props;

  return (
    <IconBuilder color={color} {...otherProps}>
      <path
        d='M12,2c-2,0-3.9,0.6-5.6,1.7C4.8,4.8,3.5,6.3,2.8,8.2C2,10,1.8,12,2.2,14c0.4,1.9,1.3,3.7,2.7,5.1c1.4,1.4,3.2,2.4,5.1,2.7
	c1.9,0.4,4,0.2,5.8-0.6c1.8-0.8,3.4-2,4.5-3.7C21.4,15.9,22,14,22,12c0-1.3-0.3-2.6-0.8-3.8C20.7,7,20,5.9,19.1,4.9
	c-0.9-0.9-2-1.7-3.2-2.2C14.6,2.3,13.3,2,12,2z M12,20c-1.6,0-3.1-0.5-4.4-1.3c-1.3-0.9-2.3-2.1-2.9-3.6S3.8,12,4.2,10.4
	s1.1-3,2.2-4.1s2.5-1.9,4.1-2.2s3.2-0.2,4.6,0.5c1.5,0.6,2.7,1.6,3.6,2.9C19.5,8.9,20,10.4,20,12c0,2.1-0.8,4.2-2.3,5.7
	C16.2,19.2,14.1,20,12,20z'
      />
      <path
        d='M15,11h-2V9c0-0.3-0.1-0.5-0.3-0.7C12.5,8.1,12.3,8,12,8c-0.3,0-0.5,0.1-0.7,0.3C11.1,8.5,11,8.7,11,9v2H9
	c-0.3,0-0.5,0.1-0.7,0.3C8.1,11.5,8,11.7,8,12c0,0.3,0.1,0.5,0.3,0.7C8.5,12.9,8.7,13,9,13h2v2c0,0.3,0.1,0.5,0.3,0.7
	c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7v-2h2c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7
	c0-0.3-0.1-0.5-0.3-0.7C15.5,11.1,15.3,11,15,11z'
      />
    </IconBuilder>
  );
};

export default PlusCircleOutlineIcon;

import { type FC, useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';

// Core Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import Skeleton from 'core/components/base/feedback/Skeleton';

// Feature Shared Components
import PatternActionBox from 'features/appBuilder/patterns/core/components/shared/ActionBox';
import PatternConfig from 'features/appBuilder/patterns/core/components/shared/Config';

// Feature Components
import HeaderPatternItems from 'features/appBuilder/patterns/features/header/components';
import FooterPatternItems from 'features/appBuilder/patterns/features/footer/components';
import ContentPatternItems from 'features/appBuilder/patterns/features/content/components';
import StablePatternItems from 'features/appBuilder/patterns/features/stable/components';
import ProfilePatternItems from 'features/appBuilder/patterns/features/profile/components';
import SliderPatternItems from 'features/appBuilder/patterns/features/slider/components';
import ContentDetailsPatternItems from 'features/appBuilder/patterns/features/contentDetails/components';
import TagAndCategoryPatternItems from 'features/appBuilder/patterns/features/tagAndCategory/components';
import SearchPatternItems from 'features/appBuilder/patterns/features/search/components';
import FormPatternItems from 'features/appBuilder/patterns/features/form/components';
import GroupPatternItem from 'features/appBuilder/patterns/features/group/components';
import AddTable from 'features/appBuilder/patterns/features/table/components/Add'; // Old Pattern

// Feature Utilities
import { isPatternTypeValid } from 'features/appBuilder/patterns/core/utilities/helper/type';
import { getHeaderPatternDataDefault } from 'features/appBuilder/patterns/features/header/utilities/defaults';
import { getFooterPatternDataDefault } from 'features/appBuilder/patterns/features/footer/utilities/defaults';
import { getContentPatternDataDefault } from 'features/appBuilder/patterns/features/content/utilities/defaults';
import { getStablePatternDataDefault } from 'features/appBuilder/patterns/features/stable/utilities/defaults';
import { getProfilePatternDataDefault } from 'features/appBuilder/patterns/features/profile/utilities/defaults';
import { getSliderPatternDataDefault } from 'features/appBuilder/patterns/features/slider/utilities/defaults';
import { getContentDetailsPatternDataDefault } from 'features/appBuilder/patterns/features/contentDetails/utilities/defaults';
import { getTagAndCategoryPatternDataDefault } from 'features/appBuilder/patterns/features/tagAndCategory/utilities/defaults';
import { getSearchPatternDataDefault } from 'features/appBuilder/patterns/features/search/utilities/defaults';
import { getFormPatternDataDefault } from 'features/appBuilder/patterns/features/form/utilities/defaults';
import { getGroupPatternDataDefault } from 'features/appBuilder/patterns/features/group/utilities/defaults';
import { getTablePatternDataDefault } from 'features/appBuilder/patterns/features/table/utilities/defaults';

// Custom Types
import type { PatternType } from 'features/appBuilder/patterns/core/types/item';
import type { PatternCommonProps } from 'features/appBuilder/patterns/core/types/item/common';

export interface AddPatternProps {}

const AddPattern: FC<AddPatternProps> = () => {
  // Hooks
  const { patternType } = useParams();

  const formMethods = useForm<PatternCommonProps>();

  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [type, setType] = useState<PatternType | undefined>(undefined);
  const navigate = useNavigate();

  const initializeType = useCallback(() => {
    if (patternType && isPatternTypeValid(patternType)) {
      setType(patternType as PatternType);

      let defaults;

      if (patternType === 'header') {
        defaults = getHeaderPatternDataDefault();
      }
      if (patternType === 'footer') {
        defaults = getFooterPatternDataDefault();
      }
      if (patternType === 'content') {
        defaults = getContentPatternDataDefault();
      }
      if (patternType === 'stable') {
        defaults = getStablePatternDataDefault();
      }
      if (patternType === 'profile') {
        defaults = getProfilePatternDataDefault();
      }
      if (patternType === 'slider') {
        defaults = getSliderPatternDataDefault();
      }
      if (patternType === 'contentDetails') {
        defaults = getContentDetailsPatternDataDefault();
      }
      if (patternType === 'tagAndCategory') {
        defaults = getTagAndCategoryPatternDataDefault();
      }
      if (patternType === 'search') {
        defaults = getSearchPatternDataDefault();
      }
      if (patternType === 'form') {
        defaults = getFormPatternDataDefault();
      }
      if (patternType === 'group') {
        defaults = getGroupPatternDataDefault();
      }
      if (patternType === 'table') {
        defaults = getTablePatternDataDefault();
      }

      if (defaults !== null) formMethods.reset(defaults);
    } else {
      navigate('/pattern');
    }
  }, [patternType, navigate]);

  useEffect(() => {
    initializeType();
  }, [initializeType]);

  return (
    <FormProvider {...formMethods}>
      <ColumnStack>
        <PatternActionBox patternType={type} />
        {type ? (
          <PatternConfig
            collapsed={collapsed}
            onCollapsedChange={setCollapsed}
            patternType={type}
          />
        ) : (
          <Skeleton
            variant='rectangular'
            sx={{ borderRadius: '0.5rem' }}
            height='4.5rem'
          />
        )}
        {type === 'header' && <HeaderPatternItems />}
        {type === 'footer' && <FooterPatternItems />}
        {type === 'content' && <ContentPatternItems />}
        {type === 'stable' && <StablePatternItems />}
        {type === 'profile' && <ProfilePatternItems />}
        {type === 'slider' && <SliderPatternItems />}
        {type === 'contentDetails' && <ContentDetailsPatternItems />}
        {type === 'tagAndCategory' && <TagAndCategoryPatternItems />}
        {type === 'search' && <SearchPatternItems />}
        {type === 'form' && <FormPatternItems />}
        {type === 'group' && <GroupPatternItem />}
        {type === 'table' && <AddTable />}
      </ColumnStack>
    </FormProvider>
  );
};

export default AddPattern;

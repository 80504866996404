// Custom Page Components
import AddAudience from 'features/audience/audiences/components/Add';
import Audiences from 'features/audience/audiences/components/list';
import EditAudience from 'features/audience/audiences/components/Edit';

// Custom Utilities
import { getFeatureLabel, getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'audience';
const subFeature: SubFeatureType = 'audiences';
const audiencesRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/audiences',
    Component: Audiences,
    feature,
    subFeature,
  },
  {
    sidebarTitle: `ایجاد ${getFeatureLabel(feature)}`,
    path: '/audiences/add',
    Component: AddAudience,
    feature,
    subFeature,
  },
  {
    path: '/audiences/:id',
    Component: EditAudience,
    feature,
    subFeature,
  },
];

export default audiencesRoutes;

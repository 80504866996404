import { useState, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';

// Custom Common Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import TextFieldOutlined from 'core/components/shared/TextField/Outlined';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Checkbox from 'core/components/base/inputs/Checkbox';
import Collapse from 'core/components/base/utils/Collapse';
import FormControlLabel from 'core/components/base/inputs/FormControlLabel';
import IconButton from 'core/components/base/inputs/IconButton';

// Custom Icon Components
import ArrowDropDownIcon from 'core/components/icons/ArrowDropDown';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import { getFeatureLabel, getSubFeatureLabel } from 'core/utilities/feature';
import {
  getFeatureCount,
  permissionsList,
} from 'core/utilities/permissions/list';

// Custom Types
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';
import type { PermissionProps } from 'core/types/permission';
import type { FormInputs } from 'features/manager/groups/components/details/actions/Add';
export interface ManagerGroupAddBodyProps {}

const { featureCount } = getFeatureCount();
const ManagerGroupAddBody: FC<ManagerGroupAddBodyProps> = (props) => {
  // States
  const [collapes, setCollapses] = useState<FeatureType[]>([]);

  // Hooks
  const loading = useSelectLoading();
  const { control, getValues, setValue } = useFormContext<FormInputs>();

  // Utilities
  const toggleFeatureCollpase = (feature: FeatureType) => () => {
    let updated: FeatureType[] = [];
    if (collapes.includes(feature)) {
      updated = collapes.filter((collape) => collape !== feature);
    } else {
      updated = [...collapes];
      updated.push(feature);
    }
    setCollapses(updated);
  };

  const sanitizePerms = (permissions: PermissionProps[]): PermissionProps[] => {
    const clonedPerms: PermissionProps[] = [];

    permissions.forEach((perm) => {
      if (perm.subFeatures.length > 0) {
        clonedPerms.push({
          feature: perm.feature,
          subFeatures: perm.subFeatures,
        });
      }
    });

    return clonedPerms;
  };

  const handleSelectAll = () => {
    const selection = getValues('permissions');
    if (selection.length === featureCount) setValue('permissions', []);
    else setValue('permissions', permissionsList);
  };

  const handleFeatureClick =
    (
      permissions: PermissionProps[],
      feature: FeatureType,
      subFeatures: SubFeatureType[]
    ) =>
    () => {
      const clonedPerms = cloneDeep(permissions);
      const permIndex = clonedPerms.findIndex(
        (perm) => perm.feature === feature
      );

      if (permIndex > -1) {
        if (clonedPerms[permIndex].subFeatures.length === subFeatures.length)
          clonedPerms.splice(permIndex, 1);
      } else {
        const permToPush = permissionsList.find(
          (perm) => perm.feature === feature
        );
        if (permToPush) {
          clonedPerms.push(permToPush);
        }
      }

      setValue('permissions', sanitizePerms(clonedPerms));
    };

  const handleSubFeatureClick =
    (
      permissions: PermissionProps[],
      feature: FeatureType,
      subFeature: SubFeatureType
    ) =>
    () => {
      const clonedPerms = cloneDeep(permissions);
      const permIndex = clonedPerms.findIndex(
        (perm) => perm.feature === feature
      );

      if (permIndex > -1) {
        const clonedPerm = clonedPerms[permIndex];
        const subFeautreIndex = clonedPerm.subFeatures.findIndex(
          (sub) => sub === subFeature
        );

        if (subFeautreIndex > -1) {
          clonedPerm.subFeatures.splice(subFeautreIndex, 1);
        } else {
          clonedPerm.subFeatures.push(subFeature);
        }

        clonedPerms[permIndex] = clonedPerm;
      } else {
        clonedPerms.push({
          feature,
          subFeatures: [subFeature],
        });
      }

      setValue('permissions', sanitizePerms(clonedPerms));
    };

  const getFeatureSelectionStateFor =
    (mode: 'checked' | 'indeterminate') =>
    (
      permissions: PermissionProps[],
      feature: FeatureType,
      subFeatures: SubFeatureType[]
    ): boolean => {
      const result = permissions.find((perm) => perm.feature === feature);

      if (result) {
        if (mode === 'checked') {
          if (result.subFeatures.length === subFeatures.length) return true;
        } else if (mode === 'indeterminate') {
          if (
            result.subFeatures.length > 0 &&
            result.subFeatures.length < subFeatures.length
          )
            return true;
        }
      }

      return false;
    };

  const isSubFeatureSelected = (
    permissions: PermissionProps[],
    feature: FeatureType,
    subFeature: SubFeatureType
  ) => {
    const perm = permissions.find((perm) => perm.feature === feature);

    if (perm) {
      if (perm.subFeatures.includes(subFeature)) return true;
    }

    return false;
  };

  return (
    <RoundPaper>
      <ColumnStack sx={{ maxWidth: '20rem', mx: 'auto', my: '2rem' }}>
        <InputWrapper label='عنوان گروه را وارد کنید' required>
          <Controller
            control={control}
            name='title'
            defaultValue=''
            render={({ field }) => (
              <TextFieldOutlined disabled={loading} {...field} />
            )}
          />
        </InputWrapper>
        <Controller
          control={control}
          name='permissions'
          defaultValue={[]}
          render={({ field }) => (
            <ColumnStack spacing={1}>
              <FormControlLabel
                disabled={loading}
                label={<BodyOne color='text.secondary'>انتخاب همه</BodyOne>}
                control={
                  <Checkbox
                    checked={field.value.length === featureCount}
                    indeterminate={
                      field.value.length > 0 &&
                      field.value.length < featureCount
                    }
                    onClick={handleSelectAll}
                    size='small'
                  />
                }
              />
              {permissionsList.map(({ feature, subFeatures }, index) => (
                <Box
                  key={feature + index}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      color: 'text.secondary',
                    }}
                  >
                    <FormControlLabel
                      disabled={loading}
                      label={<BodyOne>{getFeatureLabel(feature)}</BodyOne>}
                      control={
                        <Checkbox
                          checked={getFeatureSelectionStateFor('checked')(
                            field.value,
                            feature,
                            subFeatures
                          )}
                          indeterminate={getFeatureSelectionStateFor(
                            'indeterminate'
                          )(field.value, feature, subFeatures)}
                          onClick={handleFeatureClick(
                            field.value,
                            feature,
                            subFeatures
                          )}
                          size='small'
                        />
                      }
                    />
                    <IconButton
                      disabled={loading}
                      onClick={toggleFeatureCollpase(feature)}
                    >
                      <ArrowDropDownIcon
                        color='info'
                        fontSize='small'
                        sx={{
                          transform: collapes.includes(feature)
                            ? 'rotate(180deg)'
                            : undefined,
                        }}
                      />
                    </IconButton>
                  </Box>
                  <Collapse in={collapes.includes(feature)}>
                    <Box
                      sx={{
                        display: 'flex',
                        marginLeft: '2rem',
                        flexDirection: 'column',
                        color: 'text.secondary',
                      }}
                    >
                      {subFeatures.map((subFeature, index) => (
                        <FormControlLabel
                          disabled={loading}
                          key={subFeature + index}
                          label={
                            <BodyTwo>{getSubFeatureLabel(subFeature)}</BodyTwo>
                          }
                          control={
                            <Checkbox
                              checked={isSubFeatureSelected(
                                field.value,
                                feature,
                                subFeature
                              )}
                              onClick={handleSubFeatureClick(
                                field.value,
                                feature,
                                subFeature
                              )}
                              size='small'
                            />
                          }
                        />
                      ))}
                    </Box>
                  </Collapse>
                </Box>
              ))}
            </ColumnStack>
          )}
        />
      </ColumnStack>
    </RoundPaper>
  );
};

export default ManagerGroupAddBody;

import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import IconButton from 'core/components/base/inputs/IconButton';
import TextField from 'core/components/base/inputs/TextField';

// Custom Icons
import FilterIcon from 'core/components/icons/Filter';
import SearchIcon from 'core/components/icons/Search';

// Custom Types
import type { FileFilterProps } from 'features/file/files/types';
import type { SxProps } from '@mui/material';

interface SearchInputProps {
  sx?: SxProps;
  disableFilterButton?: boolean;
  onShowFilter?: () => void;
  onSearch?: () => void;
}

const SearchInput: React.FC<SearchInputProps> = (props) => {
  // Props
  const { sx, disableFilterButton, onSearch, onShowFilter } = props;

  // States
  const { control } = useFormContext<FileFilterProps>();

  // Render
  return (
    <Controller
      control={control}
      name='search'
      defaultValue=''
      render={({ field }) => (
        <TextField
          sx={{
            '.MuiOutlinedInput-root': {
              height: '2.5rem',
              borderRadius: '.5rem',
              paddingX: '.25rem',
            },
            ...sx,
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && onSearch) onSearch();
          }}
          placeholder='جستجو کنید ...'
          InputProps={{
            startAdornment: !disableFilterButton && (
              <IconButton onClick={onShowFilter} color='info' size='small'>
                <FilterIcon />
              </IconButton>
            ),
            endAdornment: (
              <IconButton
                onClick={() => onSearch && onSearch()}
                color='info'
                size='small'
              >
                <SearchIcon />
              </IconButton>
            ),
          }}
          {...field}
        />
      )}
    />
  );
};

export default SearchInput;

import * as Yup from 'yup';

// Schemas
import { twoFactorVerificationCode } from 'core/utilities/validationSchema/common/Schemas';

const TwoFactorVerificationCodeSchema = Yup.object().shape({
  code: twoFactorVerificationCode,
});

export default TwoFactorVerificationCodeSchema;

import { useState, useCallback } from 'react';

// Custom Types
export interface UseGroupOperationResponse<T> {
  operation: T | 'none';
  onOperationChange: (operatoin: T | 'none') => void;
}

function useGroupOperation<T>(): UseGroupOperationResponse<T> {
  // Hooks
  const [operation, setOperation] = useState<T | 'none'>('none');

  // Utilities
  const handleOperationChange = useCallback((newOperation: T | 'none') => {
    setOperation(newOperation);
  }, []);

  // Return
  return {
    operation,
    onOperationChange: handleOperationChange,
  };
}

export default useGroupOperation;

import * as React from 'react';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';

// Custom Core Components
import IconButton from 'core/components/base/inputs/IconButton';

// Custom Icons
import DragIcon from 'core/components/icons/Drag';

// Custom Types
import type { IconButtonProps } from 'core/components/base/inputs/IconButton';
interface DragIconButtonProps
  extends Omit<
    IconButtonProps,
    'onMouseDown' | 'onMouseUp' | 'onTouchStart' | 'onTouchEnd'
  > {}

const DragIconButton: React.FC<DragIconButtonProps> = (props) => {
  // Props
  const { sx, ...otherProps } = props;
  // States
  const isDragging = useBoolean();

  // Render
  return (
    <IconButton
      size='small'
      onMouseDown={isDragging.setTrue}
      onMouseUp={isDragging.setFalse}
      onTouchStart={isDragging.setTrue}
      onTouchEnd={isDragging.setFalse}
      sx={{
        cursor: isDragging.state ? 'grabbing' : 'grab',
        ...sx,
      }}
      {...otherProps}
    >
      <DragIcon />
    </IconButton>
  );
};

export default DragIconButton;

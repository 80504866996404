import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';
import Button from 'core/components/base/inputs/Button';

// Common Components
import ColorPicker from 'core/components/shared/Picker/Color';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import CloseIconButton from 'core/components/shared/IconButton/Close';
import TextFieldNumber from 'core/components/shared/TextField/number';
import FormatAlignRight from 'core/components/icons/FormatAlignRight';
import InsertImageIcon from 'core/components/icons/InsertImage';

// Feature Components
import PatternPadding from 'features/appBuilder/patterns/core/components/shared/Padding';

// Feature Types
import { ContentDetailsProps } from 'features/content/contents/types/details';

// Component Types
interface ContentDetailsBlockStylesProps {
  index?: number;
  open?: boolean;
  onClose?: () => void;
}

const ContentDetailsBlockStyles: FC<ContentDetailsBlockStylesProps> = (
  props
) => {
  // Props
  const { index = 0, open = false, onClose } = props;

  // Hooks
  const [errors, setErrors] = useState({ textWidth: '', galleryWidth: '' });
  const { control, formState, setValue } =
    useFormContext<ContentDetailsProps>();

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: open ? 0 : '110%',
        right: 0,
        bottom: 0,
        bgcolor: open ? 'rgba(255, 255, 255, 0.4)' : 'rgba(255, 255, 255, 0.9)',
        zIndex: open ? 10 : -10,
        opacity: open ? 1 : 0,
      }}
    >
      <Box
        sx={({ breakpoints }) => ({
          bgcolor: 'background.default',
          p: 2,
          gap: 1,
          display: 'flex',
          flexDirection: 'column',
          transition: 'all 150ms',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: open ? '35%' : '110%',
          [breakpoints.up('xl')]: {
            left: open ? '60%' : '110%',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <BodyTwo color='text.secondary'>تنظیمات بلوک</BodyTwo>
          <CloseIconButton
            size='small'
            sx={{ color: 'text.secondary' }}
            onClick={onClose}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Controller
            control={control}
            name={`data.blocks.${index}.data.direction`}
            render={({ field }) => {
              return (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Button
                    sx={{
                      ':hover': {
                        bgcolor:
                          field.value === 'column'
                            ? 'info.light'
                            : 'background.paper',
                        opacity: 0.9,
                      },
                      bgcolor:
                        field.value === 'column'
                          ? 'info.light'
                          : 'background.paper',
                      borderRadius: 2,
                      color:
                        field.value === 'column' ? 'white' : 'text.primary',
                      gap: 1,
                      flexDirection: 'column',
                      minWidth: 'fit-content',
                      height: '80px',
                    }}
                    onClick={() => setValue(field.name, 'column')}
                  >
                    <FormatAlignRight />
                    <InsertImageIcon />
                  </Button>
                  <Box
                    sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
                  >
                    <Button
                      sx={{
                        ':hover': {
                          bgcolor:
                            field.value === 'row-reverse'
                              ? 'info.light'
                              : 'background.paper',
                          opacity: 0.9,
                        },
                        bgcolor:
                          field.value === 'row-reverse'
                            ? 'info.light'
                            : 'background.paper',
                        borderRadius: 2,
                        color:
                          field.value === 'row-reverse'
                            ? 'white'
                            : 'text.primary',
                        gap: 1,
                        minWidth: 'fit-content',
                      }}
                      onClick={() => setValue(field.name, 'row-reverse')}
                    >
                      <InsertImageIcon />
                      <FormatAlignRight />
                    </Button>
                    <Button
                      sx={{
                        ':hover': {
                          bgcolor:
                            field.value === 'row'
                              ? 'info.light'
                              : 'background.paper',
                          opacity: 0.9,
                        },
                        bgcolor:
                          field.value === 'row'
                            ? 'info.light'
                            : 'background.paper',
                        borderRadius: 2,
                        color: field.value === 'row' ? 'white' : 'text.primary',
                        gap: 1,
                        minWidth: 'fit-content',
                      }}
                      onClick={() => setValue(field.name, 'row')}
                    >
                      <FormatAlignRight />
                      <InsertImageIcon />
                    </Button>
                  </Box>
                  <Button
                    sx={{
                      ':hover': {
                        bgcolor:
                          field.value === 'column-reverse'
                            ? 'info.light'
                            : 'background.paper',
                        opacity: 0.9,
                      },
                      bgcolor:
                        field.value === 'column-reverse'
                          ? 'info.light'
                          : 'background.paper',
                      borderRadius: 2,
                      color:
                        field.value === 'column-reverse'
                          ? 'white'
                          : 'text.primary',
                      gap: 1,
                      flexDirection: 'column',
                      minWidth: 'fit-content',
                      height: '80px',
                    }}
                    onClick={() => setValue(field.name, 'column-reverse')}
                  >
                    <InsertImageIcon />
                    <FormatAlignRight />
                  </Button>
                </Box>
              );
            }}
          />
          <Controller
            control={control}
            name={`data.blocks.${index}.data.padding`}
            render={({ field }) => (
              <PatternPadding
                defaultValue={field.value}
                onChange={(p) => setValue(field.name, p)}
              />
            )}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <ColumnStack spacing={0}>
            <BodyTwo color='text.secondary'>عرض متن</BodyTwo>
            <Controller
              control={control}
              name={`data.blocks.${index}.data.textWidth`}
              render={({ field: { onChange, onBlur, ...otherFields } }) => (
                <TextFieldNumber
                  error={errors.textWidth ? true : false}
                  helperText={errors.textWidth}
                  InputProps={{ sx: { height: '2.5rem' } }}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);

                    setErrors((e) => ({
                      ...e,
                      textWidth:
                        value < 0 || value > 100
                          ? 'عرض متن باید بین ۰ تا ۱۰۰ باشد.'
                          : '',
                    }));

                    onChange(e);
                  }}
                  onBlur={(e) => {
                    const value = parseInt(e.target.value);

                    if (value < 0 || value > 100) {
                      setValue(otherFields.name, 50);
                      onBlur();
                    } else {
                      setErrors((e) => ({ ...e, textWidth: '' }));
                      setValue(otherFields.name, value);
                    }
                  }}
                  {...otherFields}
                />
              )}
            />
          </ColumnStack>
          <ColumnStack spacing={0}>
            <BodyTwo color='text.secondary'>عرض گالری</BodyTwo>
            <Controller
              control={control}
              name={`data.blocks.${index}.data.galleryWidth`}
              render={({ field: { onChange, onBlur, ...otherFields } }) => (
                <TextFieldNumber
                  error={errors.galleryWidth ? true : false}
                  helperText={errors.galleryWidth}
                  InputProps={{ sx: { height: '2.5rem' } }}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);

                    setErrors((e) => ({
                      ...e,
                      galleryWidth:
                        value < 0 || value > 100
                          ? 'عرض گالری باید بین ۰ تا ۱۰۰ باشد.'
                          : '',
                    }));

                    onChange(e);
                  }}
                  onBlur={(e) => {
                    const value = parseInt(e.target.value);

                    if (value < 0 || value > 100) {
                      setValue(otherFields.name, 50);
                      onBlur();
                    } else {
                      setErrors((e) => ({ ...e, galleryWidth: '' }));
                      setValue(otherFields.name, value);
                    }
                  }}
                  {...otherFields}
                />
              )}
            />
          </ColumnStack>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
          <Box sx={{ display: 'flex', gap: 1, flexGrow: 1 }}>
            <BodyTwo color='text.secondary'>بکگراند بلوک</BodyTwo>
            <Controller
              control={control}
              name={`data.blocks.${index}.data.blockBgColor`}
              render={({ field }) => (
                <ColorPicker
                  color={field.value}
                  onColorChange={(color) => setValue(field.name, color)}
                />
              )}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 1, flexGrow: 1 }}>
            <BodyTwo color='text.secondary'>هاور بکگراند بلوک</BodyTwo>
            <Controller
              control={control}
              name={`data.blocks.${index}.data.blockHoverColor`}
              render={({ field }) => (
                <ColorPicker
                  color={field.value}
                  onColorChange={(color) => setValue(field.name, color)}
                />
              )}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContentDetailsBlockStyles;

import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

// Custom Utilities
import { findIndex } from 'core/utilities/helper';
import {
  getInputsIndexMap,
  getSectionInputsCount,
} from 'features/form/forms/utilities/input/helper';

// Custom Types
import type { FormDataProps } from 'features/form/forms/types/item';
import type { FormInput } from 'features/form/forms/types/item/input';
import type { FormSectionProps } from 'features/form/forms/types/item/section';
import type { FormStepProps } from 'features/form/forms/types/item/step';
import type {
  FormStoreStateProps,
  FormActionMode,
} from 'features/form/forms/types/store';

const initialState: FormStoreStateProps = {
  formId: '',
  data: null,
  mode: 'add',
  actionMode: 'INITIAL',
  currentStep: { id: '', index: 0 },
  currentSectionId: '',
  sectionCollapsed: {},
  inputsIndexMap: {},
  sectionInputsCount: {},
  selectedInputs: [],
  isInInputConfigSelection: false,
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    // * Common Slices
    resetForm: (state) => {
      state.formId = '';
      state.data = null;
      state.mode = 'add';
      state.actionMode = 'INITIAL';
      state.currentStep = { id: '', index: 0 };
      state.currentSectionId = '';
      state.sectionCollapsed = {};
      state.inputsIndexMap = {};
      state.sectionInputsCount = {};
      state.selectedInputs = [];
    },
    setForm: (state, action: PayloadAction<FormDataProps | null>) => {
      const form = action.payload;
      let indexMap: { [key: string]: number } = form
        ? getInputsIndexMap(form.inputs)
        : {};
      let sectionInputsCount = form ? getSectionInputsCount(form.inputs) : {};

      state.inputsIndexMap = indexMap;
      state.sectionInputsCount = sectionInputsCount;
      state.data = form;
    },
    setMode: (state, action: PayloadAction<'add' | 'edit'>) => {
      state.mode = action.payload;
    },
    setActionMode: (state, action: PayloadAction<FormActionMode>) => {
      const actionMode = action.payload;

      if (actionMode === 'INITIAL') {
        state.currentSectionId = '';
      }
      if (['SETTINGS', 'STEP'].includes(actionMode)) {
        state.currentSectionId = '';
        state.sectionCollapsed = {};
      }

      state.actionMode = actionMode;
      state.selectedInputs = [];
    },
    setId: (state, action: PayloadAction<string>) => {
      const id = action.payload;

      if (state.formId !== id) {
        state.formId = id;
      }
    },
    // * Step Slices
    setCurrentStep: (
      state,
      action: PayloadAction<{
        id: string;
        index: number;
        sections?: FormSectionProps[];
        inputs?: FormInput[];
      }>
    ) => {
      const { id, index, sections, inputs } = action.payload;

      state.currentStep = { id, index };

      if (state.data) {
        if (sections !== undefined) {
          state.data.sections = sections;
        }
        if (inputs !== undefined) {
          const inputsIndexMap = getInputsIndexMap(inputs);
          const sectionInputsCount = getSectionInputsCount(inputs);
          state.data.inputsIndexMap = inputsIndexMap;
          state.inputsIndexMap = inputsIndexMap;
          state.sectionInputsCount = sectionInputsCount;
          state.data.inputs = inputs;
        }
      }
    },
    setSteps: (state, actions: PayloadAction<FormStepProps[]>) => {
      if (state.data) {
        state.data.steps = actions.payload;
      }
    },
    // * Section Slices
    updateSections: (state, action: PayloadAction<FormSectionProps[]>) => {
      if (state.data) {
        state.data.sections = action.payload;
        state.currentSectionId = '';
      }
    },
    setCurrentSectionId: (state, action: PayloadAction<string>) => {
      state.currentSectionId = action.payload;
    },
    toggleSectionCollapsed: (
      state,
      action: PayloadAction<{ id: string; value?: boolean }>
    ) => {
      const { id, value } = action.payload;
      const status = value ?? !state.sectionCollapsed[id];
      state.sectionCollapsed[id] = status;
    },
    // * Input Slices
    updateInputs: (state, action: PayloadAction<FormInput[]>) => {
      const inputsIndexMap = getInputsIndexMap(action.payload);
      const sectionInputsCount = getSectionInputsCount(action.payload);

      if (state.data) state.data.inputs = action.payload;

      state.inputsIndexMap = inputsIndexMap;
      state.sectionInputsCount = sectionInputsCount;
    },
    toggleSelectInput: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const selection = state.selectedInputs;
      const index = findIndex(selection, id);
      if (index > -1) selection.splice(index, 1);
      else selection.push(id);

      state.selectedInputs = selection;
    },
    toggleInputConfigSelection: (state, action: PayloadAction<boolean>) => {
      state.isInInputConfigSelection = action.payload;
    },
  },
});

export const actions = formSlice.actions;
export default formSlice.reducer;

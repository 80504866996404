// Types
import type { FC } from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface QuestionMarkFilledIconProps extends SvgIconProps {}

const QuestionMarkFilledIcon: FC<QuestionMarkFilledIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M22,12c0,5.5-4.5,10-10,10S2,17.5,2,12C2,6.5,6.5,2,12,2S22,6.5,22,12z M12.3,5.3c-2.2,0-3.6,0.9-4.7,2.6
	C7.4,8.1,7.5,8.4,7.7,8.5l1.4,1.1c0.2,0.2,0.5,0.1,0.7-0.1c0.7-0.9,1.2-1.4,2.3-1.4c0.8,0,1.8,0.5,1.8,1.3c0,0.6-0.5,0.9-1.3,1.4
	c-0.9,0.5-2.2,1.2-2.2,2.8v0.2c0,0.3,0.2,0.5,0.5,0.5h2.3c0.3,0,0.5-0.2,0.5-0.5v-0.1c0-1.1,3.4-1.2,3.4-4.3
	C17,7.1,14.5,5.3,12.3,5.3z M12,15.3c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9s1.9-0.8,1.9-1.9C13.9,16.1,13,15.3,12,15.3z'
    />
  </IconBuilder>
);

export default QuestionMarkFilledIcon;

import type { FC } from 'react';

// Feature Components
import TagAndCategoryPatternPageConfigSection from 'features/appBuilder/patterns/features/tagAndCategory/components/PageConfig';
import TagAndCategoryPatternCardConfigSection from 'features/appBuilder/patterns/features/tagAndCategory/components/CardConfig';

// Custom Types
export interface TagAndCategoryPatternItemsProps {}

const TagAndCategoryPatternItems: FC<TagAndCategoryPatternItemsProps> = () => {
  return (
    <>
      <TagAndCategoryPatternPageConfigSection />
      <TagAndCategoryPatternCardConfigSection />
    </>
  );
};

export default TagAndCategoryPatternItems;

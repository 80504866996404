import {
    Dialog as MuiDialog,
    DialogProps as MuiDialogProps,
    styled,
  } from '@mui/material';
  
  export interface DialogProps extends MuiDialogProps {}
  
  const Dialog = styled(MuiDialog)<DialogProps>(() => ({}));
  
  export default Dialog;
  
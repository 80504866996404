import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface EyeIconProps extends SvgIconProps {}

const EyeIcon: React.FC<EyeIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M22.8671 11.4677C20.7958 7.61005 16.6953 5 12 5C7.30475 5 3.20304 7.61188 1.1329 11.4681C1.04552 11.633 1 11.8153 1 12.0002C1 12.185 1.04552 12.3673 1.1329 12.5323C3.20418 16.3899 7.30475 19 12 19C16.6953 19 20.797 16.3881 22.8671 12.5319C22.9545 12.3669 23 12.1847 23 11.9998C23 11.815 22.9545 11.6327 22.8671 11.4677ZM12 17.25C10.9122 17.25 9.84883 16.9421 8.94436 16.3652C8.03989 15.7883 7.33494 14.9684 6.91865 14.0091C6.50237 13.0498 6.39345 11.9942 6.60567 10.9758C6.81789 9.95738 7.34172 9.02191 8.11091 8.28769C8.8801 7.55346 9.8601 7.05345 10.927 6.85088C11.9939 6.6483 13.0998 6.75227 14.1048 7.14963C15.1098 7.54699 15.9687 8.2199 16.5731 9.08326C17.1774 9.94661 17.5 10.9616 17.5 12C17.5004 12.6895 17.3583 13.3724 17.0821 14.0095C16.8058 14.6466 16.4007 15.2255 15.8899 15.7131C15.3791 16.2006 14.7726 16.5873 14.1052 16.8511C13.4377 17.1148 12.7224 17.2503 12 17.25ZM12 8.5C11.6727 8.50437 11.3476 8.55084 11.0333 8.63818C11.2923 8.9742 11.4166 9.38772 11.3837 9.80373C11.3507 10.2197 11.1626 10.6107 10.8536 10.9057C10.5445 11.2007 10.135 11.3802 9.69914 11.4117C9.26332 11.4432 8.83011 11.3245 8.47808 11.0772C8.27763 11.7822 8.31381 12.5294 8.58155 13.2137C8.84928 13.898 9.33508 14.4849 9.97058 14.8919C10.6061 15.2988 11.3593 15.5053 12.1241 15.4822C12.889 15.4591 13.627 15.2077 14.2343 14.7633C14.8417 14.3189 15.2877 13.7038 15.5096 13.0048C15.7316 12.3057 15.7183 11.5578 15.4716 10.8664C15.2249 10.1749 14.7573 9.57466 14.1346 9.15017C13.5118 8.72567 12.7652 8.49828 12 8.5Z' />
  </IconBuilder>
);

export default EyeIcon;

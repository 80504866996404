import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface ContrastIconProps extends SvgIconProps {}

const ContrastIcon: React.FC<ContrastIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10m1-17.93c3.94.49 7 3.85 7 7.93s-3.05 7.44-7 7.93z' />
  </IconBuilder>
);

export default ContrastIcon;

import * as React from 'react';

// Custom HOCs
import WithAuth, { WithAuthProps } from 'core/components/shared/HOC/WithAuth';

// Custom Section Components
import AudiencesDataGrid from 'features/audience/audiences/components/DataGrid';
import AudienceListAction from 'features/audience/audiences/components/list/Action';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Core Components
import PageSize from 'core/components/shared/Filter/PageSize';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import Pagination from 'core/components/shared/Filter/Pagination';

// Custom Hooks
import useSelection from 'core/hooks/common/useSelection';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import {
  useAudienceDocs,
  useUpdateAudienceStatusMutation,
} from 'features/audience/audiences/hooks';

// Context
import DocsProvider from 'core/hooks/api/useDocs/Context';
import SelectionProvider from 'core/store/context/common/UseSelection';

// Custom Types
interface AudiencesPageProps extends WithAuthProps {}

const filePath = '/components/pages/Audiences.tsx';
const Audiences: React.FC<AudiencesPageProps> = (props) => {
  // Context
  const loading = useSelectLoading();

  // Hooks
  const selectionMethods = useSelection();
  const audienceListMethods = useAudienceDocs();
  const {
    data: { list: audiences, page },
    onPageSizeChange,
    onPageNumChange,
  } = audienceListMethods;

  const { mutate: handleUpdateAudienceStatus } =
    useUpdateAudienceStatusMutation({
      autoAlert: { mode: 'update', name: 'وضعیت مخاطب' },
    });

  // Render
  return (
    <SelectionProvider {...selectionMethods}>
      <DocsProvider {...audienceListMethods}>
        <PageHeadConfigs pageTitle='مخاطبان' />
        <AudienceListAction />
        <ColumnStack>
          <AudiencesDataGrid
            list={audiences}
            disabled={loading}
            onStatusChange={(id, status) =>
              handleUpdateAudienceStatus({ id, data: status })
            }
          />
          <RowStack alignItems='center'>
            <PageSize
              disabled={loading || audiences?.length === 0}
              page={page}
              sx={{ height: '2rem' }}
              onSizeChange={onPageSizeChange}
              onPageChange={onPageNumChange}
            />
            <Pagination
              disabled={loading || audiences?.length === 0}
              page={page}
              onPageChange={onPageNumChange}
            />
          </RowStack>
        </ColumnStack>
      </DocsProvider>
    </SelectionProvider>
  );
};

export default WithAuth(Audiences, { filePath });

import { useState, type FC, useRef, memo, useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import isEqual from 'lodash/isEqual';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Checkbox from 'core/components/base/inputs/Checkbox';

// Custom Common Components
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import SpacedBox from 'core/components/shared/Box/SpacedBox';
import RowStack from 'core/components/shared/Stack/RowStack';
import StylesIconButton from 'core/components/shared/IconButton/Styles';
import VisibilityIconButton from 'core/components/shared/IconButton/Visibility';

// Custom Feature Components
import InputIndicatorIconButton from 'features/form/forms/components/details/item/Input/shared/Wrapper/IndicatorIconButton';
import InputLabelAndRequired from 'features/form/forms/components/details/item/Input/shared/Common/LabelAndRequired';
import TypeIndicatorSlide from 'features/form/forms/components/details/item/Input/shared/Wrapper/TypeInidicatorSlide';
import InputConfigSlide from 'features/form/forms/components/details/item/Input/shared/Wrapper/Config';

// Custom Icon Components
import DragIcon from 'core/components/icons/Drag';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import { toggleFormInputSelection } from 'features/form/forms/store/actions';

// Custom Types
import type { RoundPaperProps } from 'core/components/shared/Paper/RoundPaper';
import type { FormProps } from 'features/form/forms/types/item';
import type { FormInputType } from 'features/form/forms/types/item/input/type';
import type { FormActionMode } from 'features/form/forms/types/store';

export interface FormInputWrapperProps {
  disabled?: boolean;
  actionMode?: FormActionMode;
  sectionIsFocused?: boolean;
  onUpdate?: () => void;
}

interface FormInputWrapperRootProps
  extends Omit<RoundPaperProps, 'onSelect'>,
    FormInputWrapperProps {
  id: string;
  index: number;
  config?: JSX.Element;
  type?: FormInputType;
}

const FormInputWrapper: FC<FormInputWrapperRootProps> = (props) => {
  // Props
  const {
    id,
    type,
    index,
    disabled,
    children,
    config,
    sx,
    actionMode,
    sectionIsFocused,
    onUpdate,
    ...otherProps
  } = props;

  // Hooks
  const { control, setValue } = useFormContext<FormProps>();
  const selectedInputs = useAppSelector((state) => state.form.selectedInputs);

  const [showTypes, setShowTypes] = useState(false);
  const [showConfig, setShowConfig] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    if (showConfig && actionMode !== 'INPUT') {
      if (onUpdate) onUpdate();
      setShowConfig(true);
    }
  }, [showConfig, actionMode, onUpdate]);

  // Memoized Sections
  const MemoizedTypeIndicatorSlide = useCallback(
    () => (
      <TypeIndicatorSlide
        index={index}
        in={showTypes}
        type={type}
        container={containerRef.current}
        onUpdate={onUpdate}
        onClose={() => setShowTypes(false)}
      />
    ),
    [index, showTypes, type, onUpdate]
  );

  const ConfigSlide = useCallback(
    () => (
      <InputConfigSlide
        in={showConfig}
        index={index}
        container={containerRef.current}
        onUpdate={onUpdate}
        onClose={() => setShowConfig(false)}
      >
        {config}
      </InputConfigSlide>
    ),
    [index, showConfig, onUpdate, config]
  );

  // Render
  return (
    <RoundPaper
      sx={{
        position: 'relative',
        padding: '0.5rem',
        minHeight: '16rem',
        overflow: 'hidden',
        ...sx,
      }}
      {...otherProps}
    >
      <Box ref={containerRef}>
        <SpacedBox sx={{ alignItems: 'flex-start' }}>
          <Controller
            control={control}
            name={`data.inputs.${index}`}
            render={({ field }) => (
              <InputIndicatorIconButton
                disabled={
                  disabled ||
                  (field.value.id && !field.value.id.startsWith('draft-'))
                    ? true
                    : false
                }
                type={field.value.data.type}
                onClick={() => setShowTypes(true)}
              />
            )}
          />
          <RowStack spacing={0.5}>
            <Controller
              control={control}
              name={`data.inputs.${index}.data.isHidden`}
              defaultValue={false}
              render={({ field }) => (
                <VisibilityIconButton
                  disabled={disabled}
                  visible={!field.value}
                  onClick={() => {
                    setValue(field.name, !field.value);
                    if (onUpdate) onUpdate();
                  }}
                />
              )}
            />
            <StylesIconButton
              disabled={disabled}
              onClick={() => setShowConfig(true)}
            />
          </RowStack>
        </SpacedBox>
        {sectionIsFocused && actionMode === 'INPUT_DELETE' ? (
          <Checkbox
            color='error'
            checked={selectedInputs.includes(id)}
            sx={{
              position: 'absolute',
              left: '0.5rem',
              top: '50%',
            }}
            onClick={() => toggleFormInputSelection(id)}
          />
        ) : (
          <DragIcon
            className={disabled ? undefined : 'handle_sort_input'}
            sx={{
              position: 'absolute',
              cursor: disabled ? 'auto' : 'grab',
              left: '0.5rem',
              top: '50%',
              color: disabled ? 'text.disabled' : 'text.secondary',
            }}
          />
        )}
        <Box
          sx={{
            width: '30rem',
            mx: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <InputLabelAndRequired
            disabled={disabled}
            onUpdate={onUpdate}
            index={index}
          />
          {children}
        </Box>
        {(showTypes || showConfig) && (
          <Box
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.4)',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          />
        )}
        <MemoizedTypeIndicatorSlide />
        <ConfigSlide />
      </Box>
    </RoundPaper>
  );
};

export default memo(FormInputWrapper, isEqual);

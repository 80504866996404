// Custom types
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';
import type { PermissionProps } from 'core/types/permission';

/**
 * Checks if a manager has the permission to access a specific feature and sub-feature.
 * @param {PermissionProps[]} managerPerms - An array of manager permissions.
 * @param {FeatureType} feature - The feature to check permission for.
 * @param {SubFeatureType} subFeature - The sub-feature to check permission for.
 * @returns {boolean} - Returns true if the manager has permission, false otherwise.
 */
export const isPermitted = (
  managerPerms: PermissionProps[],
  feature: FeatureType,
  subFeature: SubFeatureType
): boolean => {
  const managerPerm = managerPerms.find(
    (managerPerm) => managerPerm.feature === feature
  );

  if (managerPerm) {
    if (managerPerm.subFeatures.includes(subFeature)) return true;
  }

  return false;
};

export const extractPermissionSubFeatures = (
  permissions: PermissionProps[]
): SubFeatureType[] => {
  const subFeatures: SubFeatureType[] = [];

  permissions.forEach((permission) =>
    permission.subFeatures.forEach((subFeature) => subFeatures.push(subFeature))
  );

  return subFeatures;
};

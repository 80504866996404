import { type FC } from 'react';
import { alpha, useTheme } from '@mui/material';

// Custom Core Components
import Button, { type ButtonProps } from 'core/components/base/inputs/Button';

// Custom Types
export interface FormButtonProps extends ButtonProps {}

const FormButton: FC<FormButtonProps> = (props) => {
  // Props
  const {
    children,
    color = 'inherit',
    variant = 'contained',
    sx,
    ...otherProps
  } = props;

  // Hooks
  const { palette } = useTheme();

  return (
    <Button
      variant={variant}
      sx={{
        backgroundColor: 'background.paper',
        color: color === 'inherit' ? 'inherit' : `${color}.main`,
        ':hover': {
          backgroundColor: alpha(palette.background.paper, 0.8),
        },
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export default FormButton;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface AlbumWithCheckIconProps extends SvgIconProps {}

const AlbumWithCheckIcon: React.FC<AlbumWithCheckIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M16.3077 5.33333H7.69231C7.5291 5.33333 7.37257 5.2631 7.25717 5.13807C7.14176 5.01305 7.07692 4.84348 7.07692 4.66667C7.07692 4.48986 7.14176 4.32029 7.25717 4.19526C7.37257 4.07024 7.5291 4 7.69231 4H16.3077C16.4709 4 16.6274 4.07024 16.7428 4.19526C16.8582 4.32029 16.9231 4.48986 16.9231 4.66667C16.9231 4.84348 16.8582 5.01305 16.7428 5.13807C16.6274 5.2631 16.4709 5.33333 16.3077 5.33333ZM17.5385 7.33333H6.46154C6.29833 7.33333 6.1418 7.2631 6.0264 7.13807C5.91099 7.01305 5.84615 6.84348 5.84615 6.66667C5.84615 6.48986 5.91099 6.32029 6.0264 6.19526C6.1418 6.07024 6.29833 6 6.46154 6H17.5385C17.7017 6 17.8582 6.07024 17.9736 6.19526C18.089 6.32029 18.1538 6.48986 18.1538 6.66667C18.1538 6.84348 18.089 7.01305 17.9736 7.13807C17.8582 7.2631 17.7017 7.33333 17.5385 7.33333ZM18.2742 20H5.72577C5.26822 19.9994 4.82956 19.8023 4.50603 19.4518C4.18249 19.1013 4.00051 18.6261 4 18.1304V9.86958C4.00051 9.37391 4.18249 8.89869 4.50603 8.5482C4.82956 8.1977 5.26822 8.00055 5.72577 8H18.2742C18.7318 8.00055 19.1704 8.1977 19.494 8.5482C19.8175 8.89869 19.9995 9.37391 20 9.86958V18.1304C19.9995 18.6261 19.8175 19.1013 19.494 19.4518C19.1704 19.8023 18.7318 19.9994 18.2742 20Z'
      fill='inherit'
    />
    <path
      d='M10.5455 15.7463L8.63636 13.8657L8 14.4925L10.5455 17L16 11.6269L15.3636 11L10.5455 15.7463Z'
      fill='white'
    />
  </IconBuilder>
);

export default AlbumWithCheckIcon;

// Custom Types
import {
  FeatureType,
  FeatureTypeLabel,
  SubFeatureType,
  SubFeatureTypeLabel,
} from 'core/types/feature/FeatureType';

export const getFeatureLabel = (type: FeatureType): FeatureTypeLabel => {
  return FeatureTypeLabel[type] || type;
};

export const getSubFeatureLabel = (
  type: SubFeatureType
): SubFeatureTypeLabel => {
  return SubFeatureTypeLabel[type] || type;
};

import * as React from 'react';

// Custom Core Components
import Box from 'core/components/base/layout/Box';

// Custom Types
import type { BoxProps } from 'core/components/base/layout/Box';
interface SpacedBoxProps extends BoxProps {}

const SpacedBox: React.FC<SpacedBoxProps> = (props) => {
  const { children, sx, ...otherProps } = props;

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
};

export default SpacedBox;

import { ChangeEvent, useState, type FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import isEqual from 'lodash/isEqual';

// Custom Core Components
import InputBase from 'core/components/base/inputs/InputBase';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import Caption from 'core/components/shared/Typography/Caption';

// Custom Feature Componenst
import SingularUploader from 'features/file/files/components/shared/Uploader/Singular';
import FormInputWrapper, {
  FormInputWrapperProps,
} from 'features/form/forms/components/details/item/Input/shared/Wrapper';
import ConfigNumberInput from 'features/form/forms/components/details/item/Input/shared/Common/ConfigNumberInput';
import FileFormatsAutocomplete from 'features/file/files/components/shared/autocomplete/FileFormat';

// Custom Types
import type { RoundPaperProps } from 'core/components/shared/Paper/RoundPaper';
import type { FormFileInputProps } from 'features/form/forms/types/item/input/file';
import type { FormProps } from 'features/form/forms/types/item';
import type { FileFormat } from 'features/file/files/types';
export interface FormFileInputComponentProps
  extends RoundPaperProps,
    FormInputWrapperProps {
  input: FormFileInputProps;
}

const FormFileInput: FC<FormFileInputComponentProps> = (props) => {
  // Props
  const { input, sx, disabled, onUpdate, ...otherProps } = props;

  // Hooks
  const [errors, setErrors] = useState<{
    uploadCountLimit: boolean;
  }>({
    uploadCountLimit: false,
  });
  const { control, getValues, setValue } = useFormContext<FormProps>();
  const index = getValues('data.inputsIndexMap')[input.id];

  const FormFileInputConfig = (
    <ColumnStack spacing={1.5}>
      <ColumnStack spacing={0.5}>
        <InputLabel>نوع فایل</InputLabel>
        <Controller
          control={control}
          name={`data.inputs.${index}.data.accept`}
          render={({ field }) => (
            <FileFormatsAutocomplete
              excludedOptions={[
                'avi',
                'flv',
                'flac',
                'mov',
                'ogg',
                'mpeg',
                'pptx',
                'wmv',
                'wav',
              ]}
              disabled={disabled}
              values={field.value}
              onChange={(selecteds) => {
                setValue(field.name, selecteds as FileFormat[]);
                if (onUpdate) onUpdate();
              }}
            />
          )}
        />
      </ColumnStack>
      <ColumnStack spacing={0.5}>
        <InputLabel>حداقل و حداکثر حجم هر فایل (KB)</InputLabel>
        <RowStack>
          <RowStack spacing={0.5}>
            <InputLabel>کمترین حجم</InputLabel>
            <Controller
              control={control}
              name={`data.inputs.${index}.data.minFileSize`}
              rules={{
                onChange: (e: ChangeEvent<HTMLInputElement>) => {
                  const value = parseInt(e.target.value);

                  if (value < 0) {
                    setValue(`data.inputs.${index}.data.minFileSize`, 0);
                  }

                  if (onUpdate) onUpdate();
                },
              }}
              render={({ field }) => (
                <ConfigNumberInput disabled={disabled} {...field} />
              )}
            />
          </RowStack>
          <RowStack spacing={0.5}>
            <InputLabel>بیشترین حجم</InputLabel>
            <Controller
              control={control}
              name={`data.inputs.${index}.data.maxFileSize`}
              rules={{
                onChange: (e: ChangeEvent<HTMLInputElement>) => {
                  const value = parseInt(e.target.value);

                  if (value < 0) {
                    setValue(`data.inputs.${index}.data.maxFileSize`, 0);
                  }

                  if (onUpdate) onUpdate();
                },
              }}
              render={({ field }) => (
                <ConfigNumberInput disabled={disabled} {...field} />
              )}
            />
          </RowStack>
        </RowStack>
      </ColumnStack>
      <ColumnStack spacing={0.5}>
        <RowStack spacing={0.5}>
          <InputLabel>حداکثر تعداد فایل قابل آپلود</InputLabel>
          <Controller
            control={control}
            name={`data.inputs.${index}.data.uploadCountLimit`}
            rules={{
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                const value = parseInt(e.target.value);

                if (value > 4 || value < 1) {
                  setErrors((prev) => ({ ...prev, uploadCountLimit: true }));
                  setValue(`data.inputs.${index}.data.uploadCountLimit`, 1);
                } else {
                  setErrors((prev) => ({ ...prev, uploadCountLimit: false }));
                }

                if (onUpdate) onUpdate();
              },
            }}
            render={({ field }) => (
              <ConfigNumberInput
                disabled={disabled}
                sx={{ width: '2.5rem' }}
                {...field}
              />
            )}
          />
        </RowStack>
        {errors.uploadCountLimit && (
          <Caption color='error.main'>
            * حداکثر ۴ و حداقل ۱ قابل انتخاب می‌باشد.
          </Caption>
        )}
      </ColumnStack>
    </ColumnStack>
  );

  return (
    <FormInputWrapper
      id={input.id}
      sx={{ minHeight: '19rem', ...sx }}
      index={index}
      type='file'
      config={FormFileInputConfig}
      disabled={disabled}
      onUpdate={onUpdate}
      {...otherProps}
    >
      <Controller
        control={control}
        name={`data.inputs.${index}.data.placeholder`}
        defaultValue=''
        rules={{ onChange: onUpdate }}
        render={({ field }) => (
          <InputBase
            disabled={disabled}
            placeholder='متن راهنما...'
            fullWidth
            sx={{ fontSize: '0.875rem' }}
            {...field}
          />
        )}
      />
      <SingularUploader location='form' width='5rem' disabled />
    </FormInputWrapper>
  );
};

export default memo(FormFileInput, isEqual);

import { type FC } from 'react';

// Components
import IconButton, {
  type IconButtonProps,
} from 'core/components/base/inputs/IconButton';

// Icon Components
import ArrowDropDownIcon from 'core/components/icons/ArrowDropDown';

// Types
interface CollapseIconButtonProps extends IconButtonProps {
  collapsed?: boolean;
}

const CollapseIconButton: FC<CollapseIconButtonProps> = (props) => {
  const { collapsed, disableRipple = true, ...otherProps } = props;

  return (
    <IconButton disableRipple={disableRipple} {...otherProps}>
      <ArrowDropDownIcon
        color='info'
        sx={{
          color: props.disabled ? 'text.disabled' : 'info.main',
          transform: collapsed ? 'Rotate(180deg)' : undefined,
          transition: 'all ease-in-out 120ms',
        }}
      />
    </IconButton>
  );
};

export default CollapseIconButton;

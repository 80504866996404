import * as React from 'react';

// Core Components
import Paper from 'core/components/base/surfaces/Paper';

import type { PaperProps } from 'core/components/base/surfaces/Paper';
export interface TableContainerProps extends PaperProps {}

const TableContainerPaper: React.FC<TableContainerProps> = (props) => {
  const { children, sx, ...otherProps } = props;

  return (
    <Paper
      sx={{
        borderRadius: '0.5rem',
        p: '0 !important',
        boxShadow: 'none',
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Paper>
  );
};

export default TableContainerPaper;

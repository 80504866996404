import * as React from 'react';

// Components
import HeadingOne from './HeadingOne';

import type { HeadingOneProps } from './HeadingOne';
interface PageTitleProps extends HeadingOneProps {}

const PageTitle: React.FC<PageTitleProps> = (props) => {
  const { children, variant = 'h6', ...otherProps } = props;

  return (
    <HeadingOne variant={variant} {...otherProps}>
      {children}
    </HeadingOne>
  );
};

export default PageTitle;

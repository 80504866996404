import type { FC } from 'react';

// Core Components
import Box from 'core/components/base/layout/Box';

// Feature Components
import ContentDetailsConfigAbstract from 'features/content/contents/components/body/configs/abstract';
import ContentDetailsConfigCover from 'features/content/contents/components/body/configs/cover';
import ContentDetailsConfigTags from 'features/content/contents/components/body/configs/tag';
import ContentDetailsConfigCategories from 'features/content/contents/components/body/configs/category';

// Component Types
export interface ContentDetailsConfigProps {}

const ContentDetailsConfig: FC<ContentDetailsConfigProps> = (props) => {
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: 2,
        display: 'flex',
        gap: 2,
      }}
    >
      <ContentDetailsConfigAbstract />
      <ContentDetailsConfigCover />
      <Box
        sx={{
          width: '30%',
          display: 'flex',
          gap: 2,
          flexDirection: 'column',
          alignSelf: 'center',
          justifyContent: 'center',
          alignItems: 'stretch',
        }}
      >
        <ContentDetailsConfigCategories />
        <ContentDetailsConfigTags />
      </Box>
    </Box>
  );
};

export default ContentDetailsConfig;

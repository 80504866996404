import { type FC } from 'react';
import { alpha, useTheme } from '@mui/material';

// Custom Core Components
import ButtonLink, {
  ButtonLinkProps,
} from 'core/components/shared/Button/ButtonLink';

// Custom Types
export interface FormButtonLinkProps extends ButtonLinkProps {}

const FormButtonLink: FC<FormButtonLinkProps> = (props) => {
  // Props
  const {
    children,
    color = 'inherit',
    variant = 'contained',
    sx,
    ...otherProps
  } = props;

  // Hooks
  const { palette } = useTheme();

  return (
    <ButtonLink
      variant={variant}
      sx={{
        backgroundColor: 'background.paper',
        height: '2.5rem',
        color: color === 'inherit' ? 'inherit' : `${color}.main`,
        ':hover': {
          backgroundColor: alpha(palette.background.paper, 0.8),
        },
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </ButtonLink>
  );
};

export default FormButtonLink;

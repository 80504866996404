// Custom Hooks
import useDocsApi from 'core/hooks/api/useDocs';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  addManager,
  editManager,
  getManager,
  getManagersList,
  removeManagers,
} from 'features/manager/managers/utilities/api';

// Custom Types
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';
import type {
  ManagerDataProps,
  ManagerListItemProps,
  ManagerProps,
} from 'features/manager/managers/types';
import useDocApi, {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';
import {
  addManagerGroup,
  editManagerGroup,
  getManagerGroup,
  getManagerGroupsList,
  getManagerGroupsSummaryList,
  removeManagerGroups,
} from 'features/manager/groups/utilities/api';
import {
  ManagerGroupDataProps,
  ManagerGroupListItemProps,
  ManagerGroupProps,
  ManagerGroupSummaryProps,
} from 'features/manager/groups/types';
import useSummaryApi, {
  UseSummaryApiReturnProps,
  UseSummaryOptionType,
} from 'core/hooks/api/useSummary';

/**
 * Query key for fetching manager groups in react query.
 *
 * @constant {Array<string>}
 */
export const managerGroupsQueryKey = ['managerGroups'];

/**
 * Custom hook for fetching the list of manager groups.
 *
 * @param {UseDocsOptionType<ManagerGroupListItemProps>} [options] - Optional configuration options for the query.
 * @returns {UseDocsApiReturnProps<ManagerGroupListItemProps>} The result of the query, including the list of manager groups and other query states.
 */
export const useManagerGroupDocs = (
  options?: UseDocsOptionType<ManagerGroupListItemProps>
): UseDocsApiReturnProps<ManagerGroupListItemProps> =>
  useDocsApi<ManagerGroupListItemProps>(
    getManagerGroupsList,
    managerGroupsQueryKey,
    options
  );

/**
 * Custom hook for fetching a single manager group document.
 *
 * @param {UseDocOptionType<ManagerGroupProps>} [options] - Optional configuration options for the query.
 * @returns {UseDocApiReturnProps<ManagerGroupProps>} The result of the query, including the manager group document and other query states.
 */
export const useManagerGroupDoc = (
  options?: UseDocOptionType<ManagerGroupProps>
): UseDocApiReturnProps<ManagerGroupProps> =>
  useDocApi<ManagerGroupProps>(getManagerGroup, managerGroupsQueryKey, options);

/**
 * Custom hook for fetching the summary of manager groups.
 *
 * @param {UseSummaryOptionType<ManagerGroupSummaryProps>} [options] - Optional configuration options for the summary query.
 * @returns {UseSummaryApiReturnProps<ManagerGroupSummaryProps>} The result of the summary query, including the manager group summaries and other query states.
 */
export const useManagerSummary = (
  options?: UseSummaryOptionType<ManagerGroupSummaryProps>
): UseSummaryApiReturnProps<ManagerGroupSummaryProps> =>
  useSummaryApi(getManagerGroupsSummaryList, managerGroupsQueryKey, options);

/**
 * Custom hook for deleting manager groups.
 *
 * @returns {MutationFunction<string | string[]>} The mutation function to delete manager groups.
 */
export const useDeleteManagerGroupsMutation = useDeleteMutation<string[]>(
  removeManagerGroups,
  managerGroupsQueryKey
);

/**
 * Custom hook for adding a new manager group.
 *
 * @returns {MutationFunction<ManagerDataProps>} The mutation function to add a new manager group.
 */
export const useAddManagerGroupMutation = useAddMutation<
  Pick<ManagerGroupDataProps, 'permissions' | 'title'>
>(addManagerGroup, managerGroupsQueryKey);

/**
 * Custom hook for editing an existing manager group.
 *
 * @returns {MutationFunction<Pick<ManagerGroupDataProps, 'permissions' | 'title'>>, { status: number; doc?: ManagerProps }>} The mutation function to edit a manager group.
 */
export const useEditManagerGroupMutation = useEditMutation<
  Pick<ManagerGroupDataProps, 'permissions' | 'title'>,
  { status: number; doc?: ManagerGroupProps }
>(editManagerGroup, managerGroupsQueryKey);

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface StarOutlineIconProps extends SvgIconProps {}

const StarOutlineIcon: React.FC<StarOutlineIconProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <path d='M12,3.33l2,6.05a1,1,0,0,0,.95.69h6.5l-5.26,3.6a1,1,0,0,0-.38,1.14l2,6-5.21-3.78a1,1,0,0,0-.59-.19,1,1,0,0,0-.59.19L6.19,20.83l2-6a1,1,0,0,0-.39-1.14l-5.25-3.6H9.08a1,1,0,0,0,.95-.7l2-6m0-1.6a.74.74,0,0,0-.7.51L9.08,9.07H1.73a.74.74,0,0,0-.43.14A.72.72,0,0,0,1,9.58.8.8,0,0,0,1,10a.67.67,0,0,0,.28.36l6,4.09L5,21.3a.72.72,0,0,0,.26.83.71.71,0,0,0,.43.14h0a.71.71,0,0,0,.43-.14L12,17.86l5.89,4.27a.76.76,0,0,0,.43.14h0a.77.77,0,0,0,.34-.08,1,1,0,0,0,.26-.23.7.7,0,0,0,.13-.32.66.66,0,0,0,0-.34l-2.3-6.81,6-4.08A.74.74,0,0,0,23,10a.8.8,0,0,0,0-.46.72.72,0,0,0-.27-.37.74.74,0,0,0-.43-.14H14.91L12.7,2.24a.72.72,0,0,0-.27-.37A.76.76,0,0,0,12,1.73Z' />
    </IconBuilder>
  );
};

export default StarOutlineIcon;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface OutlinedBinIconProps extends SvgIconProps {}

const OutlinedBinIcon: React.FC<OutlinedBinIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM8 9h8v10H8zm7.5-5-1-1h-5l-1 1H5v2h14V4z' />
  </IconBuilder>
);

export default OutlinedBinIcon;

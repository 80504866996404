import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';

// Common Components
import InputWrapper from 'core/components/shared/Wrapper/Input';
import TextFieldNumber from 'core/components/shared/TextField/number';

// Feature Shared Components
import GalleryUploadButton from 'features/file/files/components/shared/Gallery/UploadButton';
import PatternItemContainer from 'features/appBuilder/patterns/core/components/shared/items/Container';
import PatternImageDisplayTypeDropdown from 'features/appBuilder/patterns/core/components/shared/dropdown/DisplayType';

// Context
import { useUploadDialogContext } from 'features/file/files/context/UploadDialog';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { PatternItemContainerProps } from 'features/appBuilder/patterns/core/components/shared/items/Container';

export interface PatternLogoItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
}

const PatternLogoItem: FC<PatternLogoItemProps> = (props) => {
  // Props
  const { reference, ...otherProps } = props;

  // Hooks
  const { setSettings } = useUploadDialogContext();

  // Context
  const { control, setValue } = useFormContext();
  const loading = useSelectLoading();

  // Utilities
  const handleOpenDialog = () => {
    setSettings({
      open: true,
      accept: ['image'],
      location: 'pageBuilder',
      onInsertFile: (files) => setValue(`${reference}.file`, files[0]),
    });
  };

  // Render
  return (
    <>
      <PatternItemContainer reference={reference} {...otherProps}>
        <Controller
          control={control}
          name={`${reference}.file`}
          render={({ field }) => (
            <GalleryUploadButton
              file={field.value ?? undefined}
              disabled={loading}
              onClick={handleOpenDialog}
              onRemove={() => setValue(field.name, null)}
              sx={{ width: '10rem', height: '2.25rem' }}
            />
          )}
        />
        <Box sx={{ display: 'flex', gap: 2, ['&>*']: { flexGrow: 1 } }}>
          <InputWrapper label='نوع نمایش'>
            <Controller
              control={control}
              name={`${reference}.displayType`}
              render={({ field }) => (
                <PatternImageDisplayTypeDropdown
                  disabled={loading}
                  fullWidth
                  defaultValue={field.value}
                  reference={reference}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper label='اندازه'>
            <Controller
              control={control}
              name={`${reference}.displayType`}
              rules={{
                onBlur: (e) => {
                  const strValue = e.target.value as string;
                  const value = parseInt(strValue);

                  setValue(
                    `${reference}.displayType`,
                    typeof value === 'number' ? value : 0
                  );
                },
              }}
              render={({ field: f }) => (
                <Controller
                  control={control}
                  name={`${reference}.imageSize`}
                  render={({ field }) => (
                    <TextFieldNumber
                      disabled={f.value === 'default' || loading}
                      {...field}
                    />
                  )}
                />
              )}
            />
          </InputWrapper>
        </Box>
      </PatternItemContainer>
    </>
  );
};

export default PatternLogoItem;

// Utilities
import apiHandler from 'core/utilities/apiHandler';
import getEndpoint from 'core/utilities/helper/getEndpoint';
import { initialFile } from 'features/file/files/utilities/file';
import { initialCreator } from 'core/utilities/creator/initial';
import { getDoc, getDocs, getSummary } from 'core/utilities/apiHandler/helper';

// Types
import type {
  ApiGetDocResponse,
  ApiGetDocsResponse,
  ApiGetSummaryResponse,
} from 'core/types/api/hook/response';
import type {
  CategoryDataProps,
  CategoryProps,
  CategoryResponseProps,
  CategorySummaryProps,
} from 'features/content/categories/types';

/**
 * Initial category properties with default values.
 * @type {CategoryProps}
 */

export const initialCategory: CategoryProps = {
  id: '',
  data: {
    count: 0,
    skinId: '',
    date: new Date(Date.now()).toISOString(),
    description: '',
    image: initialFile,
    relatedMediaId: '',
    slug: '',
    title: '',
    creator: initialCreator,
  },
};

/**
 * Fetches a list of categories based on the provided queries.
 *
 * @param {AbortSignal} signal - The signal to abort the request.
 * @param {Record<string, any>} [queries] - Optional queries to filter the categories.
 * @returns {Promise<ApiGetDocsResponse<CategoryProps>>} The status of the request, pagination information, and the list of categories.
 */
export const getCategories = async (
  signal: AbortSignal,
  queries?: Record<string, any>
): Promise<ApiGetDocsResponse<CategoryProps>> => {
  const { getCategoriesURL } = getEndpoint();

  return await getDocs<CategoryResponseProps, CategoryProps>(getCategoriesURL, {
    queries,
    signal,
  });
};

/**
 * Fetches a specific category by its ID.
 *
 * @param {string} categoryId - The ID of the category to fetch.
 * @param {AbortSignal} signal - The signal to cancel request.
 * @returns {Promise<ApiGetDocResponse<CategoryProps>>} The status of the request and the category data.
 */
export const getCategory = async (
  categoryId: string,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<CategoryProps>> => {
  const endpoint = `${getEndpoint().getCategoryUrl}/${categoryId}`;

  return await getDoc<CategoryProps, CategoryProps>(endpoint, { signal });
};

/**
 * Deletes categories with the specified IDs.
 *
 * @param {string[]} selection - An array of category IDs to delete.
 * @returns {Promise<{ status: number }>} The status of the delete request.
 */
export const deleteCategory = async (
  selection: string[]
): Promise<{ status: number }> => {
  const { deleteCategoryURL: endpoint } = getEndpoint();
  const { status } = await apiHandler.delete(endpoint, { ids: selection });
  return { status };
};

/**
 * Adds a new category.
 *
 * @param {CategoryDataProps} cat - The category data to add.
 * @returns {Promise<{ status: number }>} The status of the add request.
 */
export const addCategory = async (
  cat: CategoryDataProps
): Promise<{ status: number }> => {
  const { addCategoryURL: endpoint } = getEndpoint();
  const { status } = await apiHandler.post(endpoint, cat);
  return { status };
};

/**
 * Edits an existing category by its ID.
 *
 * @param {string} categoryId - The ID of the category to edit.
 * @param {CategoryDataProps} cat - The updated category data.
 * @returns {Promise<{ status: number }>} The status of the edit request.
 */
export const editCategory = async (
  categoryId: string,
  cat: CategoryDataProps
): Promise<{ status: number }> => {
  const { editCategoryURL } = getEndpoint();
  const endpoint = `${editCategoryURL}/${categoryId}`;
  const { status } = await apiHandler.patch(endpoint, cat);
  return { status };
};

/**
 * Fetches a summary list of categories from the API.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<ApiGetSummaryResponse<CategorySummaryProps>>}
 *          A promise that resolves to an object containing the HTTP status and a list of category summaries.
 */
export const getCategoriesSummaryList = async (
  signal?: AbortSignal
): Promise<ApiGetSummaryResponse<CategorySummaryProps>> => {
  const endpoint = getEndpoint().getCategoriesListURL;

  return await getSummary<CategorySummaryProps[], CategorySummaryProps>(
    endpoint,
    { signal }
  );
};

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface DropdownFilledIconProps extends SvgIconProps {}

const DropdownFilledIcon: React.FC<DropdownFilledIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M12,1C5.9,1,1,5.9,1,12s4.9,11,11,11s11-4.9,11-11S18.1,1,12,1z M12.1,15l-4.3-4.1h1.6l2.7,2.5l2.5-2.5h1.6L12.1,15z' />
  </IconBuilder>
);

export default DropdownFilledIcon;

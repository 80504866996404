// Custom Types
import type { TemplateProps } from 'features/appBuilder/templates/types';
import type { TemplateBodyProps } from 'features/appBuilder/templates/types/body';
import type { PatternInBodyProps } from 'features/appBuilder/patterns/types/inBody';
import type {
  ApiTemplateBodyProps,
  ApiTemplateProps,
} from 'features/appBuilder/templates/types/api';

export const transformTemplateBody = (
  apiBody: ApiTemplateBodyProps
): TemplateBodyProps => {
  const {
    headers: header,
    footers: footer,
    contents: content,
    profiles: profile,
    stables: stable,
    tagAndCategories: tagAndCategory,
    forms: form,
    searches: search,
    contentDetails: contentDetails,
    pages: page,
    groups: group,
    ...otherBodyData
  } = apiBody.data;

  const patterns: PatternInBodyProps[] = [];

  if (header) {
    header.forEach((p) => patterns.push(p));
  }
  if (footer) {
    footer.forEach((p) => patterns.push(p));
  }
  if (content) {
    content.forEach((p) => patterns.push(p));
  }
  if (profile) {
    profile.forEach((p) => patterns.push(p));
  }
  if (stable) {
    stable.forEach((p) => patterns.push(p));
  }
  if (tagAndCategory) {
    tagAndCategory.forEach((t) => patterns.push(t));
  }
  if (form) {
    form.forEach((f) => patterns.push(f));
  }
  if (contentDetails) {
    contentDetails.forEach((c) => patterns.push(c));
  }
  if (search) {
    search.forEach((s) => patterns.push(s));
  }
  if (page) {
    page.forEach((p) => patterns.push(p));
  }
  if (group) {
    group.forEach((g) => patterns.push(g));
  }

  return {
    id: apiBody.id,
    data: {
      ...otherBodyData,
      patterns,
    },
  };
};

export const transformTemplateBodies = (
  apiBodies: ApiTemplateBodyProps[]
): TemplateBodyProps[] => {
  const bodies: TemplateBodyProps[] = [];

  apiBodies.forEach((b) => bodies.push(transformTemplateBody(b)));

  return bodies;
};

export const transformTemplate = (
  template: ApiTemplateProps
): TemplateProps => {
  const transformedBodies: TemplateBodyProps[] = transformTemplateBodies(
    template.data.bodies
  );

  return {
    id: template.id,
    data: {
      feature: template.data.featureType,
      bodies: transformedBodies,
    },
  };
};

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface CodeBranchIconProps extends SvgIconProps {}

const CodeBranchIcon: React.FC<CodeBranchIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M20 7.34375C20 5.70352 18.6948 4.375 17.0833 4.375C15.4719 4.375 14.1667 5.70352 14.1667 7.34375C14.1667 8.69453 15.0526 9.83379 16.263 10.1938C16.2411 10.7912 16.1099 11.2514 15.862 11.5631C15.3005 12.2756 14.0646 12.3943 12.7557 12.5168C11.7276 12.6133 10.663 12.7172 9.79167 13.1439V7.8002C10.9766 7.42168 11.8333 6.29727 11.8333 4.96875C11.8333 3.32852 10.5281 2 8.91667 2C7.30521 2 6 3.32852 6 4.96875C6 6.29727 6.85677 7.42168 8.04167 7.8002V15.1961C6.85677 15.5783 6 16.7027 6 18.0312C6 19.6715 7.30521 21 8.91667 21C10.5281 21 11.8333 19.6715 11.8333 18.0312C11.8333 16.7695 11.0604 15.6896 9.96667 15.2629C10.0797 15.0699 10.251 14.8992 10.5099 14.7656C11.1005 14.4613 11.9828 14.3797 12.9198 14.2906C14.4583 14.1459 16.201 13.9789 17.2292 12.6801C17.7396 12.0344 17.9984 11.2031 18.0167 10.1604C19.1687 9.75957 20 8.65 20 7.34375ZM8.91667 4.375C9.2375 4.375 9.5 4.64219 9.5 4.96875C9.5 5.29531 9.2375 5.5625 8.91667 5.5625C8.59583 5.5625 8.33333 5.29531 8.33333 4.96875C8.33333 4.64219 8.59583 4.375 8.91667 4.375ZM8.91667 18.625C8.59583 18.625 8.33333 18.3578 8.33333 18.0312C8.33333 17.7047 8.59583 17.4375 8.91667 17.4375C9.2375 17.4375 9.5 17.7047 9.5 18.0312C9.5 18.3578 9.2375 18.625 8.91667 18.625ZM17.0833 6.75C17.4042 6.75 17.6667 7.01719 17.6667 7.34375C17.6667 7.67031 17.4042 7.9375 17.0833 7.9375C16.7625 7.9375 16.5 7.67031 16.5 7.34375C16.5 7.01719 16.7625 6.75 17.0833 6.75Z' />
  </IconBuilder>
);

export default CodeBranchIcon;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface H6IconProps extends SvgIconProps {}

const H6Icon: React.FC<H6IconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M5.88583 6.66227V10.5409H12.1142V6.66227C12.1142 6.10818 12.2437 5.69261 12.5028 5.41557C12.7684 5.13852 13.115 5 13.5425 5C13.9765 5 14.3263 5.13852 14.5919 5.41557C14.864 5.68646 15 6.10202 15 6.66227V17.3285C15 17.8887 14.864 18.3074 14.5919 18.5844C14.3198 18.8615 13.97 19 13.5425 19C13.1085 19 12.7619 18.8615 12.5028 18.5844C12.2437 18.3012 12.1142 17.8826 12.1142 17.3285V12.7757H5.88583V17.3285C5.88583 17.8887 5.7498 18.3074 5.47773 18.5844C5.20567 18.8615 4.85587 19 4.42834 19C3.99433 19 3.64777 18.8615 3.38866 18.5844C3.12955 18.3012 3 17.8826 3 17.3285V6.66227C3 6.10818 3.12632 5.69261 3.37895 5.41557C3.63806 5.13852 3.98785 5 4.42834 5C4.86235 5 5.21215 5.13852 5.47773 5.41557C5.7498 5.68646 5.88583 6.10202 5.88583 6.66227Z' />
    <path d='M18.0762 15.3535C18.3418 15.0371 18.6309 14.8027 18.9434 14.6504C19.2559 14.498 19.6055 14.4219 19.9922 14.4219C20.3633 14.4219 20.7051 14.4922 21.0176 14.6328C21.334 14.7695 21.6094 14.9668 21.8438 15.2246C22.082 15.4785 22.2656 15.7793 22.3945 16.127C22.5234 16.4746 22.5879 16.8379 22.5879 17.2168C22.5879 17.7676 22.4609 18.2676 22.207 18.7168C21.957 19.166 21.6055 19.5176 21.1523 19.7715C20.7031 20.0215 20.1934 20.1465 19.623 20.1465C18.9668 20.1465 18.4004 19.9863 17.9238 19.666C17.4473 19.3457 17.082 18.873 16.8281 18.248C16.5781 17.6191 16.4531 16.8516 16.4531 15.9453C16.4531 15.207 16.5293 14.5469 16.6816 13.9648C16.834 13.3828 17.0527 12.9004 17.3379 12.5176C17.623 12.1348 17.9688 11.8457 18.375 11.6504C18.7812 11.4551 19.2441 11.3574 19.7637 11.3574C20.2637 11.3574 20.7031 11.4551 21.082 11.6504C21.4648 11.8457 21.752 12.0879 21.9434 12.377C22.1387 12.666 22.2363 12.9434 22.2363 13.209C22.2363 13.3691 22.1758 13.5156 22.0547 13.6484C21.9336 13.7773 21.7793 13.8418 21.5918 13.8418C21.4277 13.8418 21.2754 13.791 21.1348 13.6895C20.9941 13.584 20.8906 13.4395 20.8242 13.2559C20.7344 13.0215 20.5918 12.834 20.3965 12.6934C20.2012 12.5488 19.9746 12.4766 19.7168 12.4766C19.5098 12.4766 19.3027 12.5371 19.0957 12.6582C18.8926 12.7754 18.7207 12.9355 18.5801 13.1387C18.2949 13.5527 18.127 14.291 18.0762 15.3535ZM19.6582 18.9922C20.0527 18.9922 20.3789 18.832 20.6367 18.5117C20.8945 18.1875 21.0234 17.7637 21.0234 17.2402C21.0234 16.8848 20.9629 16.5801 20.8418 16.3262C20.7207 16.0684 20.5547 15.873 20.3438 15.7402C20.1328 15.6035 19.9004 15.5352 19.6465 15.5352C19.4043 15.5352 19.1719 15.5996 18.9492 15.7285C18.7305 15.8574 18.5527 16.0449 18.416 16.291C18.2832 16.5332 18.2168 16.8184 18.2168 17.1465C18.2168 17.6738 18.3535 18.1133 18.627 18.4648C18.9004 18.8164 19.2441 18.9922 19.6582 18.9922Z' />
  </IconBuilder>
);

export default H6Icon;

import * as React from 'react';
import { useLocation } from 'react-router-dom';

// Core Components
import IconButtonLink from './IconButtonLink';

// Icon Components
import SettingsIcon from 'core/components/icons/Settings';

// Types
import type { IconButtonLinkProps } from './IconButtonLink';

interface SettingsIconButtonProps extends IconButtonLinkProps {}

const SettingsIconButton: React.FC<SettingsIconButtonProps> = (props) => {
  const { children, ...otherProps } = props;
  const location = useLocation();

  return (
    <IconButtonLink
      href='/settings'
      color={location.pathname === '/settings' ? 'info' : 'inherit'}
      {...otherProps}
    >
      <SettingsIcon color='inherit' fontSize='inherit' />
    </IconButtonLink>
  );
};

export default SettingsIconButton;

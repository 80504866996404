import {
  ListItem as MuiListItem,
  ListItemProps as MuiListItemProps,
  styled,
} from '@mui/material';

export interface ListItemProps extends MuiListItemProps {}

const ListItem = styled(MuiListItem)<ListItemProps>(() => ({}));

export default ListItem;

import { type FC } from 'react';

// Custom Core Components
import Box from 'core/components/base/layout/Box';

// Custom Components
import PatternToolbarIconButton from './Icon';

// Custom Icon Components
import MergeIcon from 'core/components/icons/Merge';
import CleanIcon from 'core/components/icons/Clean';
import ConfigIcon from 'core/components/icons/Config';

// Custom Types
import type { ToolbarMethods } from '.';
export interface TablePatternMultiCellToolbarProps
  extends Partial<ToolbarMethods> {}

const TablePatternMultiCellToolbar: FC<TablePatternMultiCellToolbarProps> = (
  props
) => {
  // Props
  const { onMergeCells, onResetCells, onStylesOpen } = props;

  const items = [
    { title: 'merge', Icon: <MergeIcon />, handler: onMergeCells },
    { title: 'clean', Icon: <CleanIcon />, handler: onResetCells },
    { title: 'config', Icon: <ConfigIcon />, handler: onStylesOpen },
  ];

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {items.map((item, index) => (
        <PatternToolbarIconButton
          key={item.title + index}
          onClick={item.handler}
        >
          {item.Icon}
        </PatternToolbarIconButton>
      ))}
    </Box>
  );
};

export default TablePatternMultiCellToolbar;

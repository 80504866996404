import { Controller, useForm, type SubmitHandler } from 'react-hook-form';
import { sanitize } from 'isomorphic-dompurify';

// Types
import type { FC } from 'react';

// Custom Common Components
import InputWrapper from 'core/components/shared/Wrapper/Input';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import RowStack from 'core/components/shared/Stack/RowStack';
import SkinSelect from 'core/components/shared/Select/Skin';
import SlugTextField from 'core/components/shared/TextField/Slug';
import FileUploader from 'core/components/shared/Input/NewFileUploader';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Button from 'core/components/base/inputs/Button';
import LoadingButton from 'core/components/base/inputs/LoadingButton';
import TextField from 'core/components/base/inputs/TextField';

// Custom Hooks
import useRefId from 'core/hooks/useRefId';
import useUpdateEffect from 'core/hooks/useUpdateEffect';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';
import {
  useAddTagMutation,
  useEditTagMutation,
} from 'features/content/tags/hooks';

// Custom Utilities
import { TagSchema } from 'features/content/tags/validations';
import {
  getErrorAndHelperText,
  getFileSource,
} from 'core/utilities/helper/helperPack';

// Custom Types
import type { RoundPaperProps } from 'core/components/shared/Paper/RoundPaper';
import type { TagProps } from 'features/content/tags/types';

export interface TagCardProps extends RoundPaperProps {
  tag?: TagProps;
  onClose: () => void;
}

const TagCard: FC<TagCardProps> = (props) => {
  // Props
  const { onClose, tag, sx, ...otherProps } = props;

  // Context
  const loading = useSelectLoading();

  // Hooks
  const [refId] = useRefId();
  const resolver = useYupValidationResolver(TagSchema);
  const { control, handleSubmit, reset } = useForm<TagProps>({
    mode: 'onTouched',
    resolver,
    defaultValues: tag || {},
  });

  useUpdateEffect(() => {
    if (tag) reset({ id: tag.id, data: { ...tag.data } });
  }, [tag]);

  // Utilities
  const { mutate: handleAddTag } = useAddTagMutation({
    autoAlert: { mode: 'create', name: 'برچسب' },
    onSuccess: () => onClose(),
  });
  const { mutate: handleEditTag } = useEditTagMutation({
    autoAlert: { mode: 'edit', name: 'برچسب' },
    onSuccess: () => onClose(),
  });

  const handleFormSubmit: SubmitHandler<TagProps> = async (data) => {
    const updatedTag = { ...data };
    if (!updatedTag.data.relatedMediaId) updatedTag.data.relatedMediaId = refId;
    if (tag) handleEditTag({ id: tag.id, data: updatedTag.data });
    else handleAddTag(updatedTag.data);
  };

  return (
    <RoundPaper
      sx={{ display: 'flex', flexDirection: 'column', gap: 2, ...sx }}
      {...otherProps}
    >
      <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
        <InputWrapper required label='عنوان' sx={{ flexGrow: 1 }}>
          <Controller
            control={control}
            name='data.title'
            defaultValue=''
            render={({ field, fieldState }) => (
              <TextField {...getErrorAndHelperText(fieldState)} {...field} />
            )}
          />
        </InputWrapper>
        <InputWrapper required label='اسکین' sx={{ flexGrow: 1 }}>
          <Controller
            control={control}
            name='data.skinId'
            render={({ field, fieldState }) => (
              <SkinSelect
                defaultValue={field.value}
                onChange={(skinId) => field.onChange(skinId)}
                {...getErrorAndHelperText(fieldState)}
              />
            )}
          />
        </InputWrapper>
        <InputWrapper label='اسلاگ' sx={{ flexGrow: 1 }}>
          <Controller
            control={control}
            name='data.slug'
            defaultValue=''
            render={({ field, fieldState }) => (
              <SlugTextField
                dir='ltr'
                copyValue={`/tag/${sanitize(field.value)}`}
                {...getErrorAndHelperText(fieldState)}
                {...field}
              />
            )}
          />
        </InputWrapper>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%' }}
      >
        <InputWrapper
          label='تصویر'
          sx={{ flexGrow: 1, maxWidth: '16rem', height: '9.5rem' }}
        >
          <Controller
            control={control}
            name='data.image'
            render={({ field }) => (
              <FileUploader
                location='tag'
                accept={['image']}
                disabled={loading}
                onUploadChange={(file) => field.onChange(file)}
                onUploadRemove={() => field.onChange(null)}
                sx={{
                  maxHeight: '8rem',
                }}
                previewProps={{
                  previewSrc: field.value ? getFileSource(field.value) : '',
                }}
              />
            )}
          />
        </InputWrapper>
        <InputWrapper label='توضیحات' sx={{ flexGrow: 1 }}>
          <Controller
            control={control}
            name='data.description'
            render={({ field }) => <TextField multiline rows={5} {...field} />}
          />
        </InputWrapper>
      </Box>
      <RowStack direction='row-reverse'>
        <LoadingButton
          color='info'
          variant='contained'
          type='submit'
          sx={{ width: '10rem' }}
          onClick={handleSubmit(handleFormSubmit)}
        >
          ذخیره
        </LoadingButton>
        <Button color='info' variant='outlined' onClick={onClose}>
          بستن
        </Button>
      </RowStack>
    </RoundPaper>
  );
};

export default TagCard;

import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

// Types
import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';

// Custom Common Components
import PatternTypeAutocomplete from 'core/components/shared/Autocomplete/PatternType';
import PatternItemsAutocomplete from 'core/components/shared/Autocomplete/PatternItems';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import CloseIconButton from 'core/components/shared/IconButton/Close';
import BodyOne from 'core/components/shared/Typography/BodyOne';
import PatternWidthSelect from 'core/components/shared/Menu/PatternWidth';
import DirectionButton from 'core/components/shared/Button/DirectionButton';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Paper from 'core/components/base/surfaces/Paper';
import Button from 'core/components/base/inputs/Button';
import IconButton from 'core/components/base/inputs/IconButton';

// Custom Icon Components
import DragIcon from 'core/components/icons/Drag';
import DeleteIcon from 'core/components/icons/DeleteIcon';
import LtrDirIcon from 'core/components/icons/LtrDirection';
import RtlDirIcon from 'core/components/icons/RtlDirection';

// Custom Utilities
import { getPatternTitle } from 'features/appBuilder/patterns/core/utilities/helper/type';

// Custom Types
import type { PaperProps } from 'core/components/base/surfaces/Paper';
import type { PatternInBodyProps } from 'features/appBuilder/patterns/types/inBody';

export interface CustomPagePatternProps extends PaperProps {
  pattern: PatternInBodyProps;
  onUpdate: (pattern: PatternInBodyProps) => void;
  onDelete: () => void;
}

const CustomPagePattern: FC<CustomPagePatternProps> = (props) => {
  // Props
  const { pattern, onDelete, onUpdate, sx, ...otherProps } = props;

  // States
  const [mode, setMode] = useState<'edit' | 'preview'>('preview');

  // Hooks
  const { control, setValue, handleSubmit, reset } =
    useForm<PatternInBodyProps>({ mode: 'onTouched' });

  useEffect(() => {
    reset(pattern);
  }, [pattern, reset]);

  const setInitialMode = useCallback(() => {
    if (pattern.id === 'draft') setMode('edit');
  }, [pattern]);

  useEffect(() => {
    setInitialMode();
  }, [setInitialMode]);

  const handlePatternUpdate: SubmitHandler<PatternInBodyProps> = (data) => {
    onUpdate(data);
    setMode('preview');
  };

  // Render
  return (
    <Paper
      sx={{
        borderRadius: '0.5rem',
        p: '0.5rem',
        boxShadow: 'none',
        ...sx,
      }}
      {...otherProps}
    >
      {mode === 'preview' ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '2.5rem',
              color: 'text.secondary',
            }}
          >
            <DragIcon />
          </Box>
          <Controller
            control={control}
            name='data.direction'
            defaultValue='rtl'
            render={({ field }) => (
              <Box
                sx={{
                  backgroundColor: 'background.paper',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '3rem',
                  borderRadius: '0.5rem',
                  color: 'text.secondary',
                  marginRight: '4rem',
                }}
              >
                {field.value === 'rtl' ? <RtlDirIcon /> : <LtrDirIcon />}
              </Box>
            )}
          />
          <BodyOne sx={{ cursor: 'pointer' }} onClick={() => setMode('edit')}>
            {pattern.data.type
              ? getPatternTitle(pattern.data.type)
              : 'بدون پترن'}
          </BodyOne>
          <BodyTwo sx={{ ml: '4rem' }}>
            {pattern.data.patternTitle || ''}
          </BodyTwo>
        </Box>
      ) : (
        <Controller
          control={control}
          name='data'
          render={({ field }) => (
            <Box
              sx={{
                color: 'text.secondary',
                display: 'flex',
                gap: 1,
                alignItems: 'center',
              }}
            >
              <Controller
                control={control}
                name='data.maxWidth'
                render={({ field }) => (
                  <PatternWidthSelect
                    disabled
                    // defaultValue={field.value}
                    // onWidthChange={(width) => setValue('data.maxWidth', width)}
                  />
                )}
              />
              <Controller
                control={control}
                name='data.direction'
                defaultValue='rtl'
                render={({ field }) => (
                  <DirectionButton
                    value={field.value}
                    onDirectionChange={(newDir) => {
                      setValue('data.direction', newDir);
                    }}
                  />
                )}
              />
              <PatternTypeAutocomplete
                onChange={(patternType) => {
                  setValue('data.type', patternType);
                  setValue('id', 'draft');
                  setValue('data.patternTitle', '');
                }}
                defaultValue={pattern.data.type}
                sx={{ width: '10rem' }}
              />
              <Controller
                control={control}
                name='data.type'
                render={({ field }) => (
                  <PatternItemsAutocomplete
                    disabled={!pattern.data.type}
                    defaultValue={pattern.id}
                    onChange={(patternId, patternTitle) => {
                      setValue('id', patternId);
                      setValue('data.patternTitle', patternTitle);
                    }}
                    sx={{ width: '10rem' }}
                    patternType={field.value}
                  />
                )}
              />
              <Controller
                control={control}
                name='data.patternTitle'
                render={({ field }) => (
                  <>
                    <Button
                      color='secondary'
                      variant='outlined'
                      sx={{ height: '2.5rem' }}
                      onClick={handleSubmit(handlePatternUpdate)}
                    >
                      ذخیره
                    </Button>
                    <IconButton onClick={onDelete} sx={{ ml: 'auto' }}>
                      <DeleteIcon />
                    </IconButton>
                    <CloseIconButton
                      color='inherit'
                      onClick={() => setMode('preview')}
                    />
                  </>
                )}
              />
            </Box>
          )}
        />
      )}
    </Paper>
  );
};

export default CustomPagePattern;

// Feature Types
import type { SearchPatternCardConfigProps } from 'features/appBuilder/patterns/features/search/types/cardConfig';

export const searchPatterCardConfigDefaults: SearchPatternCardConfigProps = {
  isActive: true,
  title: {
    isActive: true,
    lineClamp: 1,
    padding: '0px',
    fontSize: '18',
    textAlign: 'start',
    textDirection: 'rtl',
    fontColor: '#12252E',
    highlightColor: 'rgba(0, 0, 0, 0)',
    fontHoverColor: '#12252E',
    highlightHoverColor: 'rgba(0, 0, 0, 0)',
  },
  body: {
    isActive: true,
    lineClamp: 4,
    padding: '0px',
    fontSize: '12',
    textDirection: 'rtl',
    textAlign: 'start',
    fontColor: '#59666D',
    highlightColor: 'rgba(0, 0, 0, 0)',
    fontHoverColor: '#59666D',
    highlightHoverColor: 'rgba(0, 0, 0, 0)',
  },
  image: {
    borderRadius: 8,
    isActive: true,
    padding: '0px',
    displayType: 'default',
    height: 64,
    width: 64,
    objectFit: 'cover',
    position: 'end',
  },
  display: {
    borderRadius: 8,
    displayType: 'default',
    width: 256,
    isActive: true,
    align: 'start',
    padding: '0px',
    cardBg: '#FFFFFF',
    cardBgHover: '#FFFFFF',
  },
};

import { UAParser } from 'ua-parser-js';

/**
 * Retrieves information about the user's browser using the UAParser library.
 *
 * @returns {UAParser.IBrowser} - The browser information.
 */

const getBrowser = (): UAParser.IBrowser => {
  const parser = new UAParser();

  return parser.getBrowser();
};

export default getBrowser;

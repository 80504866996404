import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface DragIconProps extends SvgIconProps {}

const DragIcon: React.FC<DragIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M8.35,17a2.5,2.5,0,1,1-2.44,2.5A2.47,2.47,0,0,1,8.35,17Zm7.3,0a2.5,2.5,0,1,1-2.43,2.5A2.47,2.47,0,0,1,15.65,17ZM8.35,9.5A2.5,2.5,0,1,1,5.91,12,2.47,2.47,0,0,1,8.35,9.5Zm7.3,0A2.5,2.5,0,1,1,13.22,12,2.47,2.47,0,0,1,15.65,9.5ZM8.35,2a2.47,2.47,0,0,1,2.43,2.5,2.44,2.44,0,1,1-4.87,0A2.47,2.47,0,0,1,8.35,2Zm7.3,0a2.47,2.47,0,0,1,2.44,2.5,2.44,2.44,0,1,1-4.87,0A2.47,2.47,0,0,1,15.65,2Z' />
  </IconBuilder>
);

export default DragIcon;

import Blockquote from '@tiptap/extension-blockquote';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import { Color } from '@tiptap/extension-color';
import Document from '@tiptap/extension-document';
import Heading from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import ListItem from '@tiptap/extension-list-item';
import Link from '@tiptap/extension-link';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import TextDirection from 'tiptap-text-direction';
import TextStyle from '@tiptap/extension-text-style';

export const editorExtensions = [
  Document,
  Paragraph,
  Text,
  TextDirection.configure({ types: ['heading', 'paragraph'] }),
  Blockquote,
  Bold,
  Heading.configure({
    levels: [2, 3, 4, 5, 6],
  }),
  Highlight.extend({
    addAttributes() {
      return {
        color: {
          default: null,
          parseHTML: (element) => element.style.backgroundColor,
          renderHTML: (attributes) => {
            if (!attributes.color) {
              return {};
            }
            return {
              style: `color: inherit; background-color: ${
                attributes.color || 'white'
              }`,
            };
          },
        },
      };
    },
  }),
  Placeholder.configure({ placeholder: 'نوشتن متن...' }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
    defaultAlignment: 'right',
  }),
  OrderedList,
  ListItem,
  Link.configure({
    openOnClick: false,
    linkOnPaste: false,
  }),
  BulletList,
  Color,
  TextStyle.extend({
    addAttributes() {
      return {
        fontSize: {
          default: null,
          parseHTML: (element) => element.style.fontSize.replace(/px$/, ''),
          renderHTML: (attributes) => {
            if (!attributes.fontSize) {
              return {};
            }
            return { style: `font-size: ${attributes.fontSize || 16}px` };
          },
        },
      };
    },
  }),
];

// Custom Core Components
import CenterBox from 'core/components/shared/Box/CenterBox';
import HeadingFour from 'core/components/shared/Typography/HeadingFour';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Custom Types
interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps> = () => {
  return (
    <>
      <PageHeadConfigs pageTitle='صفحه مورد نظر یافت نشد' />
      <PageHeroBox>
        <HeadingSix>{'خطا!'?.toPersian()}</HeadingSix>
      </PageHeroBox>
      <RoundPaper>
        <CenterBox sx={{ minHeight: '10rem' }}>
          <HeadingFour>
            {'404 | صفحه مورد نظر شما یافت نشد.'?.toPersian()}
          </HeadingFour>
        </CenterBox>
      </RoundPaper>
    </>
  );
};

export default NotFound;

// Feature Utilities
import { PROCESS_MANAGER_QUESTION_DRAFT_FLAG } from 'features/form/processes/utilities/initial/managerQuestion';

/**
 * Checks if the given ID represents a manager question draft.
 *
 * @param {string} id - The ID to check.
 * @returns {boolean} True if the ID ends with the manager question draft flag; otherwise, false.
 */
export const isManagerQuestionDraft = (id: string): boolean => {
  return id && id.endsWith(PROCESS_MANAGER_QUESTION_DRAFT_FLAG) ? true : false;
};

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface MergeIconProps extends SvgIconProps {}

const MergeIcon: React.FC<MergeIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M7.6,17.6l4.4-4.4h3.6c0.4,1.3,1.7,2.2,3.1,2.2c1.9,0,3.3-1.4,3.3-3.3s-1.4-3.3-3.3-3.3c-1.4,0-2.7,0.9-3.1,2.2H12L7.6,6.4
	V2H2v5.6h3.3L10,12l-4.7,4.4H2V22h5.6V17.6z'
    />
  </IconBuilder>
);

export default MergeIcon;

import * as React from 'react';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';

// Common Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Types
interface FolderChipProps {
  title: string[];
}

const FolderChip: React.FC<FolderChipProps> = (props) => {
  // Props
  const { title } = props;

  // States
  const isHovered = useBoolean();

  // Render
  return (
    <BodyTwo
      onMouseEnter={isHovered.setTrue}
      onMouseLeave={isHovered.setFalse}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        verticalAlign: 'center',
        alignItems: 'center',
        width: 'fit-content',
        marginX: 'auto',
        padding: '.3rem',
        transition: 'background .3s',
        background: isHovered.state ? '#f5f5f5' : 'inherit',
        borderRadius: '.25rem',
      }}
    >
      {isHovered.state ? title.join(',') : title[0]}
    </BodyTwo>
  );
};

export default FolderChip;

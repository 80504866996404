import { isSucceed, setAppAlert } from 'core/utilities/helper';
import { getToken } from 'core/utilities/token/token';
import {
  getAuthTokenName,
  getAppBaseURL,
} from 'core/utilities/helper/getEnvVariables';

// Custom Types
import type { CommonResponseProps } from 'core/types/apiHandler';

const defaultBaseURL = getAppBaseURL();
const authTokenName = getAuthTokenName();

export type AppReqInitType = Omit<RequestInit & { baseURL?: string }, 'method'>;

/**
 * Extends the native fetch function and implements application logic for sending requests.
 * Used to make HTTP requests with additional application logic.
 * @template T - Type of data in the response.
 * @param {string} endpoint - The API endpoint to call.
 * @param {RequestInit & {baseURL?:string}} [reqInit] - Additional request initialization properties.
 * @param {string} [token] - Optional token for authorization.
 * @returns {Promise<CommonResponseProps<T>>} The response from the extended fetch call.
 */
async function appFetch<T = any>(
  endpoint: string,
  reqInit?: RequestInit & { baseURL?: string },
  token?: string
): Promise<CommonResponseProps<T>> {
  try {
    const userToken = token || getToken() || '';
    const hasQuery = endpoint.indexOf('?') > -1;
    const res = await fetch(
      hasQuery
        ? `${reqInit?.baseURL || defaultBaseURL}${endpoint}`
        : encodeURI(`${reqInit?.baseURL || defaultBaseURL}${endpoint}`),
      {
        ...reqInit,
        headers: {
          [authTokenName]: userToken || '',
          Content: 'application/json; charset=utf-8',
          'Content-Type': 'application/json; charset=utf-8',
          ...reqInit?.headers,
        },
        body: reqInit?.body ? JSON.stringify(reqInit.body) : undefined,
      }
    )
      .then(async (res) => {
        if (res?.ok) return await res.json();
        let response: Record<string, any> = {};
        try {
          response = await res.json();
        } catch (error) {
          response.status = res.status;
        }
        throw new Error('ERROR', { cause: response });
      })
      .then((v) => v);

    const { status, description, message, success, ...data } = res;

    return {
      status: status || 502,
      isSuccess: success || isSucceed(status),
      data: data as T | undefined,
      message,
    };
  } catch (error: any) {
    const status = error?.cause?.status || 502;
    const errorMessage =
      error?.cause?.errors?.[0] || 'خطایی در پردازش درخواست شما رخ داد';

    if (
      error.message !== 'signal is aborted without reason' &&
      error.message !== 'The user aborted a request.'
    )
      setAppAlert(errorMessage);

    return {
      status,
      isSuccess: false,
      message: errorMessage,
    };
  }
}

/**
 * Function to handle GET requests.
 * @template T - Type of data in the response.
 * @param {string} endpoint - The API endpoint for the GET request.
 * @param {Omit<RequestInit & {baseURL?:string}, 'method'>} reqInit - Request initialization properties.
 * @param {string} [token] - Optional token for authorization.
 * @returns {Promise<CommonResponseProps<T>>} The response from the GET request.
 */
async function getHandler<T>(
  endpoint: string,
  reqInit?: AppReqInitType,
  token?: string
): Promise<CommonResponseProps<T>> {
  return await appFetch<T>(
    endpoint,
    {
      ...reqInit,
      method: 'GET',
    },
    token
  );
}

/**
 * Function to handle POST requests.
 * @template T - Type of data in the response.
 * @param {string} endpoint - The API endpoint for the POST request.
 * @param {any} data - Data to be sent in the request body.
 * @param {Omit<RequestInit & {baseURL?:string}, 'method' | 'body'>} reqInit - Request initialization properties.
 * @param {string} [token] - Optional token for authorization.
 * @returns {Promise<CommonResponseProps<T>>} The response from the POST request.
 */
async function postHandler<T>(
  endpoint: string,
  data?: any,
  reqInit?: Omit<RequestInit & { baseURL?: string }, 'method' | 'body'>,
  token?: string
): Promise<CommonResponseProps<T>> {
  return await appFetch<T>(
    endpoint,
    {
      ...reqInit,
      method: 'POST',
      body: data,
    },
    token
  );
}

/**
 * Function to handle PATCH requests.
 * @template T - Type of data in the response.
 * @param {string} endpoint - The API endpoint for the PATCH request.
 * @param {any} data - Data to be sent in the request body.
 * @param {Omit<RequestInit & {baseURL?:string}, 'method' | 'body'>} [reqInit] - Request initialization properties.
 * @param {string} [token] - Optional token for authorization.
 * @returns {Promise<CommonResponseProps<T>>} The response from the PATCH request.
 */
async function patchHandler<T>(
  endpoint: string,
  data?: any,
  reqInit?: Omit<RequestInit & { baseURL?: string }, 'method' | 'body'>,
  token?: string
): Promise<CommonResponseProps<T>> {
  return await appFetch<T>(
    endpoint,
    {
      ...reqInit,
      method: 'PATCH',
      body: data,
    },
    token
  );
}

/**
 * Function to handle PUT requests.
 * @template T - Type of data in the response.
 * @param {string} endpoint - The API endpoint for the PUT request.
 * @param {any} data - Data to be sent in the request body.
 * @param {Omit<RequestInit & {baseURL?:string}, 'method' | 'body'>} [reqInit] - Request initialization properties.
 * @param {string} [token] - Optional token for authorization.
 * @returns {Promise<CommonResponseProps<T>>} The response from the PUT request.
 */
async function putHandler<T>(
  endpoint: string,
  data?: any,
  reqInit?: Omit<RequestInit & { baseURL?: string }, 'method' | 'body'>,
  token?: string
): Promise<CommonResponseProps<T>> {
  return await appFetch<T>(
    endpoint,
    {
      ...reqInit,
      method: 'PUT',
      body: data,
    },
    token
  );
}

/**
 * Function to handle DELETE requests.
 * @template T - Type of data in the response.
 * @param {string} endpoint - The API endpoint for the DELETE request.
 * @param {any} data - Data to be sent in the request body.
 * @param {Omit<RequestInit & {baseURL?:string}, 'method'>} [reqInit] - Request initialization properties.
 * @param {string} [token] - Optional token for authorization.
 * @returns {Promise<CommonResponseProps<T>>} The response from the DELETE request.
 */
async function deleteHandler<T>(
  endpoint: string,
  data?: any,
  reqInit?: AppReqInitType,
  token?: string
): Promise<CommonResponseProps<T>> {
  return await appFetch<T>(
    endpoint,
    {
      ...reqInit,
      method: 'DELETE',
      body: data,
    },
    token
  );
}

interface ApiHandlerProps {
  post: typeof postHandler;
  patch: typeof patchHandler;
  get: typeof getHandler;
  put: typeof putHandler;
  delete: typeof deleteHandler;
}

const apiHandler: ApiHandlerProps = {
  post: postHandler,
  patch: patchHandler,
  get: getHandler,
  put: putHandler,
  delete: deleteHandler,
};

export default apiHandler;

import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternTextItem from 'features/appBuilder/patterns/core/components/shared/items/Text';
import PatternImageItem from 'features/appBuilder/patterns/core/components/shared/items/Image';
import PatternDisplayCardItem from 'features/appBuilder/patterns/core/components/shared/items/DisplayCard';
import { useFormContext, useWatch } from 'react-hook-form';
import { SliderPatternDataProps } from 'features/appBuilder/patterns/features/slider/types';

// Custom Types
export interface SliderPatternConfigSectionItemProps {}
type CollapsedKey = 'section' | 'title' | 'body' | 'image' | 'display';

const SliderPatternConfigSection: FC<
  SliderPatternConfigSectionItemProps
> = () => {
  // Hooks
  const { control, getValues } = useFormContext<SliderPatternDataProps>();
  const variant = useWatch({
    control,
    name: 'variant',
    defaultValue: getValues('variant') || 0,
  });
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    title: false,
    body: false,
    image: false,
    display: false,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='تنظیمات و استایل اسلایدر'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='config'
    >
      <PatternTextItem
        reference='config.title'
        title='عنوان'
        collapsed={collapsed.title}
        onCollapsedChange={handleCollapseChanged('title')}
        lineClampOptions={[1, 2, 3]}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'fontColor',
          'highlightColor',
          'fontHoverColor',
          'highlightHoverColor',
        ]}
      />
      <PatternTextItem
        reference='config.body'
        title='متن'
        collapsed={collapsed.body}
        onCollapsedChange={handleCollapseChanged('body')}
        lineClampOptions={[1, 2, 3, 4, 5]}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'fontColor',
          'highlightColor',
          'fontHoverColor',
          'highlightHoverColor',
        ]}
      />
      <PatternImageItem
        reference='config.image'
        title='تنظیمات عکس'
        collapsed={collapsed.image}
        onCollapsedChange={handleCollapseChanged('image')}
        include={['padding', 'borderRadius']}
        noUpload
        noPosition
        noWidth={variant === 0}
      />
      <PatternDisplayCardItem
        reference='config.display'
        title='نمایش کارت‌ها'
        noDisplayType
        noWidth
        collapsed={collapsed.display}
        onCollapsedChange={handleCollapseChanged('display')}
        include={['cardBg', 'cardBgHover', 'padding', 'borderRadius']}
      />
    </PatternSectionContainer>
  );
};

export default SliderPatternConfigSection;

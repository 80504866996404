import * as React from 'react';

// Core Components
import Checkbox from 'core/components/base/inputs/Checkbox';

// Custom Components
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Types
import type { StackProps } from 'core/components/base/layout/Stack';
import type { CheckboxProps } from 'core/components/base/inputs/Checkbox';
import type { BodyTwoProps } from 'core/components/shared/Typography/BodyTwo';

interface CheckboxWithLabelProps {
  checkboxProps?: CheckboxProps;
  disabled?: boolean;
  defaultChecked?: boolean;
  labelProps?: BodyTwoProps;
  name?: string;
  label:
    | string
    | number
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  wrapperProps?: StackProps;
}

const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = (props) => {
  const {
    checkboxProps,
    defaultChecked,
    labelProps,
    label,
    disabled,
    wrapperProps,
    name,
    ...otherProps
  } = props;

  return (
    <RowStack spacing={0} alignItems='center' {...wrapperProps}>
      <Checkbox
        edge='start'
        sx={{
          '& .MuiSvgIcon-root': { fontSize: 18 },
          marginRight: '0.25rem',
        }}
        {...checkboxProps}
        checked={checkboxProps?.checked || false}
        defaultChecked={defaultChecked}
      />
      <BodyTwo whiteSpace={labelProps?.whiteSpace ?? 'nowrap'} {...labelProps}>
        {label}
      </BodyTwo>
    </RowStack>
  );
};

export default CheckboxWithLabel;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface InsertSheetColIconProps extends SvgIconProps {}

const InsertSheetColIcon: React.FC<InsertSheetColIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M10.9,20.9v-4.4c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3h8.9c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8v2.2
	c0,0.9-0.3,1.7-0.9,2.3c-0.6,0.6-1.4,1-2.2,1l-0.2,0H12c-0.3,0-0.6-0.1-0.8-0.3C11,21.5,10.9,21.2,10.9,20.9z M12,2l6.7,0
	c0.9,0,1.7,0.3,2.3,0.9c0.6,0.6,1,1.4,1,2.2l0,0.2V12c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3H12c-0.3,0-0.6-0.1-0.8-0.3
	c-0.2-0.2-0.3-0.5-0.3-0.8V3.1c0-0.3,0.1-0.6,0.3-0.8C11.4,2.1,11.7,2,12,2z M2,5.3C2,4.5,2.3,3.7,2.9,3c0.6-0.6,1.4-1,2.2-1l0.2,0
	h2.2c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8V12c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3H3.1c-0.3,0-0.6-0.1-0.8-0.3
	C2.1,12.6,2,12.3,2,12L2,5.3z M3.1,15.3h4.4c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8v4.4c0,0.3-0.1,0.6-0.3,0.8
	C8.1,21.9,7.9,22,7.6,22H5.3c-0.9,0-1.7-0.3-2.3-0.9c-0.6-0.6-1-1.4-1-2.2l0-0.2v-2.2c0-0.3,0.1-0.6,0.3-0.8S2.8,15.3,3.1,15.3z'
    />
  </IconBuilder>
);

export default InsertSheetColIcon;

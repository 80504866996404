import { type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Select from 'core/components/base/inputs/Select';
import MenuItem from 'core/components/base/navigation/MenuItem';
import TextField from 'core/components/base/inputs/TextField';
import Radio from 'core/components/base/inputs/Radio';

// Custom Common Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import DatePicker from 'core/components/shared/Picker/DatePicker';
import TimePicker from 'core/components/shared/Picker/TimePicker';

// Custom Icon Components
import ArrowDropDownIcon from 'core/components/icons/ArrowDropDown';

// Custom Utilities
import { forLoop } from 'core/utilities/helper';

// Custom Styles
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';

// Custom Types
import type { FormInputSummaryProps } from 'features/form/forms/types/item/input';
import type { FormFeedbacksListFilterState } from '.';

export interface FeedbackListFieldsFilterProps {
  formId: string;
  initialized: boolean;
  options: FormInputSummaryProps[];
}

const FeedbackListFieldsFilter: FC<FeedbackListFieldsFilterProps> = (props) => {
  // Props
  const { options, initialized } = props;

  // Context
  const { control, getValues, setValue } =
    useFormContext<FormFeedbacksListFilterState>();

  // Utilities
  const getKeyLabel = (id: string): string => {
    let label = '';

    forLoop(options, (opt) => {
      if (opt.id === id) label = opt.data.label;
    });

    return label;
  };

  const handleKeyChanged = (opt: FormInputSummaryProps, index: number) => {
    const items = cloneDeep(getValues('items'));

    const draftIndex = items.findIndex((item) => item.id === 'draft');

    if (draftIndex > -1) items.splice(draftIndex, 1);

    const optIndex = items.findIndex((item) => item.id === opt.id);

    if (optIndex > -1) {
      items.splice(optIndex, 1);
    } else {
      items.splice(index, 1, {
        id: opt.id,
        data: {
          label: opt.data.label,
          type: opt.data.type,
          values: [],
        },
      });
    }

    if (items.length < 5 && items.length < options.length) {
      items.push({
        id: 'draft',
        data: { label: '', type: 'text', values: [] },
      });
    }

    setValue('items', items);
  };

  const isKeySelectedBefore = (optId: string): boolean => {
    const items = getValues('items');

    const index = items.findIndex((item) => item.id === optId);

    return index > -1;
  };

  const isKeySelectable = (optId: string, currentId: string): boolean => {
    const keyIsSelectedBefore = isKeySelectedBefore(optId);

    if (keyIsSelectedBefore) {
      if (optId !== currentId) return false;
    }

    return true;
  };

  const handleValuesChanged = (optId: string, value: string) => {
    const items = cloneDeep(getValues('items'));

    const optIndex = items.findIndex((item) => item.id === optId);

    if (optIndex > -1) {
      const clonedOpt = cloneDeep(items[optIndex]);

      if (['select', 'radio', 'checkbox'].includes(clonedOpt.data.type)) {
        const currentValues =
          clonedOpt.data.values && clonedOpt.data.values.length > 0
            ? [...clonedOpt.data.values]
            : [];

        const valueIndex = currentValues.findIndex((v) => v === value);

        if (valueIndex > -1) {
          currentValues.splice(valueIndex, 1);
        } else {
          currentValues.push(value);
        }

        clonedOpt.data.values = currentValues;
      } else {
        clonedOpt.data.values = [value ?? ''];
      }

      items[optIndex] = clonedOpt;
    }

    setValue('items', items);
  };

  const getFieldValues = (id: string): string[] => {
    let values: string[] = [];

    const option = options.find((opt) => opt.id === id);

    if (option && option.data.values) values = option.data.values;

    return values;
  };

  const getFilterValue = (id: string): string[] => {
    const items = getValues('items');

    const item = items.find((item) => item.id === id);

    if (item && item.data.values) return item.data.values;

    return [];
  };

  return (
    <Controller
      control={control}
      name='items'
      render={({ field: fields }) => (
        <Box sx={{ width: '100%', display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          {fields &&
            fields.value &&
            fields.value.map((field, index) => (
              <Box
                key={`filter-key-${field.id}-${index}`}
                sx={{ width: '48%', flexShrink: 0 }}
              >
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Select
                    fullWidth
                    displayEmpty
                    defaultValue=''
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: '12rem',
                          ...customRoundedScrollbarStyles,
                        },
                      },
                    }}
                    IconComponent={ArrowDropDownIcon}
                    sx={{
                      height: '2.5rem',
                      '.MuiSvgIcon-root': {
                        color: 'info.main',
                        fontSize: '1.5rem',
                      },
                    }}
                    renderValue={(value) => {
                      if (field.id && field.id !== 'draft') {
                        return getKeyLabel(field.id);
                      }

                      return (
                        <BodyTwo color='text.secondary'>انتخاب سوال</BodyTwo>
                      );
                    }}
                  >
                    {!initialized && options.length === 0 && (
                      <MenuItem disabled value='loading'>
                        درحال دریافت فیلدها...
                      </MenuItem>
                    )}
                    {initialized && options.length === 0 && (
                      <MenuItem disabled value='none'>
                        اینپوتی برای انتخاب وجود ندارد
                      </MenuItem>
                    )}
                    {options.map((opt) => {
                      const disabled = !isKeySelectable(opt.id, field.id);

                      return (
                        <MenuItem
                          key={opt.id}
                          value={opt.id}
                          disabled={disabled}
                          onClick={() => handleKeyChanged(opt, index)}
                        >
                          {
                            <Radio
                              checked={isKeySelectedBefore(opt.id)}
                              size='small'
                              sx={{
                                color: disabled ? 'text.disabled' : 'inherit',
                              }}
                            />
                          }
                          {opt.data.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {['checkbox', 'radio', 'select'].includes(field.data.type) ? (
                    <Select
                      fullWidth
                      displayEmpty
                      multiple
                      defaultValue=''
                      sx={{
                        height: '2.5rem',
                        '.MuiSvgIcon-root': {
                          color: 'info.main',
                          fontSize: '1.5rem',
                        },
                      }}
                      IconComponent={ArrowDropDownIcon}
                      value={getFilterValue(field.id)}
                    >
                      {getFieldValues(field.id).map((o, oIndex) => (
                        <MenuItem
                          value={o}
                          key={o + oIndex}
                          onClick={() => handleValuesChanged(field.id, o)}
                        >
                          {o}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : field.data.type === 'date' ? (
                    <DatePicker
                      fullWidth
                      onDateChange={(date) =>
                        handleValuesChanged(field.id, date?.toISOString() || '')
                      }
                      disabled={field.id === 'draft'}
                      sx={{ height: '2.5rem' }}
                    />
                  ) : field.data.type === 'time' ? (
                    <TimePicker
                      fullWidth
                      placeholder='انتخاب ساعت'
                      onTimeChange={(time) =>
                        handleValuesChanged(field.id, time?.toISOString() || '')
                      }
                      disabled={field.id === 'draft'}
                      sx={{ height: '2.5rem' }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      onBlur={(e) => {
                        const value = e.target.value;

                        handleValuesChanged(field.id, value);
                      }}
                      disabled={field.id === 'draft'}
                      placeholder={
                        field.data.type === 'agreement'
                          ? 'متن گزینه توافق'
                          : field.data.type === 'file'
                          ? 'لینک فایل'
                          : 'انتخاب پاسخ'
                      }
                      sx={{ height: '2.5rem' }}
                      InputProps={{ sx: { height: '2.54rem' } }}
                    />
                  )}
                </Box>
              </Box>
            ))}
        </Box>
      )}
    />
  );
};

export default FeedbackListFieldsFilter;

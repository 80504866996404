import { useEffect, type FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Custom Feature Components
import FormItemActions from 'features/form/forms/components/details/item/Actions';
import FormItemSettings from 'features/form/forms/components/details/item/Settings';
import FormItemSteps from 'features/form/forms/components/details/item/Steps';
import FormItemSections from 'features/form/forms/components/details/item/Sections';

// Custom Hooks
import useMount from 'core/hooks/useMount';
import { useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import { genUUID } from 'core/utilities/helper';
import { setFormId, setFormMode } from 'features/form/forms/store/actions';

// Custom Types
import type { FormProps } from 'features/form/forms/types/item';

const AddForm: FC = () => {
  // Context
  const mode = useAppSelector((state) => state.form.mode);
  const formId = useAppSelector((state) => state.form.formId);

  // Hooks
  const formMethods = useForm<FormProps>({
    mode: 'onTouched',
  });

  const setRelatedMediaId = useCallback(() => {
    formMethods.setValue('data.relatedMediaId', genUUID());
  }, [formMethods]);

  useEffect(() => {
    setRelatedMediaId();
  }, [setRelatedMediaId]);

  useMount(() => {
    setFormId('');
    setFormMode('add');
  });

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='ساخت فرم' />
      <FormProvider {...formMethods}>
        <FormItemActions />
        <ColumnStack>
          <FormItemSettings />
          {mode === 'edit' && formId && (
            <>
              <FormItemSteps />
              <FormItemSections />
            </>
          )}
        </ColumnStack>
      </FormProvider>
    </>
  );
};

export default AddForm;

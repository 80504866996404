import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Custom Core Components
import Select from 'core/components/shared/Select';
import MenuItem from 'core/components/base/navigation/MenuItem';

// Custom Utilities
import { setPageSize } from 'core/utilities/pagination/pagination';

// Custom Types
import type { SelectProps } from 'core/components/base/inputs/Select';
import type {
  PageProps,
  PagePropsStateHandler,
} from 'core/types/shared/pagination';

interface PageSizeProps extends SelectProps {
  page: PageProps;
  sizeRange?: number[];
  setPage?: PagePropsStateHandler;
  onSizeChange?: (size: number) => void;
  onPageChange?: (size: number) => void;
}

const PageSize: React.FC<PageSizeProps> = (props) => {
  // Props
  const {
    disabled,
    sizeRange = [10, 20, 50, 100],
    page,
    setPage,
    onSizeChange,
    onPageChange,
    sx,
    ...otherProps
  } = props;

  // Hooks
  const { palette } = useTheme();

  // Utilities

  const handleSizeChange = (i: number) => {
    if (setPage) setPageSize(i, page, setPage);
    if (onPageChange) onPageChange(1);
    if (onSizeChange) onSizeChange(i);
  };

  // Render
  return (
    <Select
      value={page.size}
      disabled={disabled}
      sx={{
        minWidth: '5rem',
        background: palette.background.paper,
        ...sx,
      }}
      inputProps={{ sx: { paddingY: '0.25rem' } }}
      {...otherProps}
    >
      {sizeRange.map((i) => (
        <MenuItem
          disabled={disabled}
          key={`pageSize-${i}`}
          value={i}
          onClick={() => handleSizeChange(i)}
        >
          {i?.toPersian()}
        </MenuItem>
      ))}
    </Select>
  );
};

export default PageSize;

import {
    ToggleButtonGroup as MuiToggleButtonGroup,
    ToggleButtonGroupProps as MuiToggleButtonGroupProps,
    styled,
  } from '@mui/material';
  
  export interface ToggleButtonGroupProps extends MuiToggleButtonGroupProps {}
  
  const ToggleButtonGroup = styled(MuiToggleButtonGroup)<ToggleButtonGroupProps>(() => ({}));
  
  export default ToggleButtonGroup;
  
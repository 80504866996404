import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Custom Common Components
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Context
import { useSelectIsDeleteMode } from 'core/store/slices/feature/menu';

// Custom Types
interface ListHeaderProps {}

const ListHeader: React.FC<ListHeaderProps> = (props) => {
  // Context
  const isDeleteMode = useSelectIsDeleteMode();

  // Hooks
  const { palette } = useTheme();

  // Render
  return (
    <SpaceBetween
      sx={{
        padding: '10px 23px',
        background: palette.background.paper,
        borderRadius: '6px 6px 0 0 ',
      }}
    >
      <BodyTwo
        sx={{
          fontSize: '1rem',
          fontWeight: 400,
        }}
      >
        عنوان
      </BodyTwo>
      {isDeleteMode ? (
        <BodyTwo
          sx={{
            fontSize: '1rem',
            fontWeight: 400,
          }}
        >
          حذف
        </BodyTwo>
      ) : (
        <RowStack alignItems='center' spacing={1}>
          <BodyTwo
            sx={{
              fontSize: '1rem',
              fontWeight: 400,
            }}
          >
            وضعیت
          </BodyTwo>
          <BodyTwo
            sx={{
              fontSize: '1rem',
              fontWeight: 400,
            }}
          >
            ویرایش
          </BodyTwo>
        </RowStack>
      )}
    </SpaceBetween>
  );
};

export default ListHeader;

import { type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import MenuItem from 'core/components/base/navigation/MenuItem';

// Common Components
import Select, { type SelectProps } from 'core/components/shared/Select';

// Custom Types
import type { PatternButtonVariant } from 'features/appBuilder/patterns/core/types/item/menuButton';
export interface PatternButtonTypeDropdownProps
  extends Omit<SelectProps, 'defaultValue'> {
  reference: string;
  defaultValue?: PatternButtonVariant;
}

type SelectOption = { value: PatternButtonVariant; label: string };
const options: SelectOption[] = [
  { value: 'contained', label: 'توپر' },
  { value: 'outlined', label: 'توخالی' },
];

const PatternButtonTypeDropdown: FC<PatternButtonTypeDropdownProps> = (
  props
) => {
  // Props
  const { reference, defaultValue = 'contained', sx, ...otherProps } = props;

  // Hooks
  const { control, setValue } = useFormContext();

  // Render
  return (
    <Controller
      control={control}
      name={`${reference}.variant`}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Select sx={{ height: '48px', ...sx }} size='small' {...field}>
          {options.map((opt) => (
            <MenuItem
              selected={field.value === opt.value}
              key={`${reference}-${opt.value}`}
              value={opt.value}
              onClick={() => setValue(field.name, opt.value)}
            >
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

export default PatternButtonTypeDropdown;

import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import InputWrapper from 'core/components/shared/Wrapper/Input';

// Feature Shared Components
import PatternItemContainer from 'features/appBuilder/patterns/core/components/shared/items/Container';
import GalleryUploadButton from 'features/file/files/components/shared/Gallery/UploadButton';
import Box from 'core/components/base/layout/Box';
import PatternImageDisplayTypeDropdown from '../../dropdown/DisplayType';
import TextFieldNumber from 'core/components/shared/TextField/number';

// Context
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useUploadDialogContext } from 'features/file/files/context/UploadDialog';

// Custom Types
import type { PatternItemContainerProps } from 'features/appBuilder/patterns/core/components/shared/items/Container';

export interface PatternAvatarItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
}

const PatternAvatarItem: FC<PatternAvatarItemProps> = (props) => {
  // Props
  const { reference, ...otherProps } = props;

  // Hooks
  const { control, setValue } = useFormContext();
  const loading = useSelectLoading();
  const { setSettings } = useUploadDialogContext();

  // Utilities
  const handleOpenDialog = () => {
    setSettings({
      open: true,
      accept: ['image'],
      location: 'pageBuilder',
      onInsertFile: (files) => setValue(`${reference}.file`, files[0]),
    });
  };

  return (
    <PatternItemContainer reference={reference} {...otherProps}>
      <Controller
        control={control}
        name={`${reference}.file`}
        render={({ field }) => (
          <GalleryUploadButton
            file={field.value ?? undefined}
            disabled={loading}
            onClick={handleOpenDialog}
            onRemove={() => setValue(field.name, null)}
            sx={{ width: '10rem', height: '2.25rem' }}
          />
        )}
      />
      <Box sx={{ display: 'flex', gap: 2, ['&>*']: { flexGrow: 1 } }}>
        <InputWrapper label='نوع نمایش'>
          <Controller
            control={control}
            name={`${reference}.displayType`}
            render={({ field }) => (
              <PatternImageDisplayTypeDropdown
                disabled={loading}
                fullWidth
                defaultValue={field.value}
                reference={reference}
              />
            )}
          />
        </InputWrapper>
        <InputWrapper label='اندازه'>
          <Controller
            control={control}
            name={`${reference}.displayType`}
            render={({ field: f }) => (
              <Controller
                control={control}
                name={`${reference}.imageSize`}
                render={({ field }) => (
                  <TextFieldNumber
                    disabled={loading || f.value === 'default'}
                    {...field}
                  />
                )}
              />
            )}
          />
        </InputWrapper>
      </Box>
    </PatternItemContainer>
  );
};

export default PatternAvatarItem;

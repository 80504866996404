import { type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// Core Components
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import RowStack from 'core/components/shared/Stack/RowStack';
import SwitchWithLabel from 'core/components/shared/switchWithLabel';

// Feature Components
import DeleteIconButton from 'features/form/shared/components/iconButton/Delete';

// Core Utilities
import { setAppAlert } from 'core/utilities/helper';

// Context
import { useDialogContext } from 'core/store/context/DialogProvider';
import { useSelectProcessFocusIsEmpty } from 'features/form/processes/store/selector';

// Feature Utilities
import {
  useDeleteProcessesMutation,
  useToggleProcessActiveStatusMutation,
} from 'features/form/processes/hooks';

// Custom Types
import type { ProcessProps } from 'features/form/processes/types/details';
export interface ProcessDetailsActionsProps {
  mode?: 'edit' | 'add';
  disabled?: boolean;
}

const ProcessDetailsActions: FC<ProcessDetailsActionsProps> = (props) => {
  // Props
  const { mode = 'add', disabled } = props;

  // Context
  const isFocusEmpty = useSelectProcessFocusIsEmpty();
  const { control, setValue, getValues } = useFormContext<ProcessProps>();
  const { onOpenDialog, onCloseDialog } = useDialogContext();

  // Hooks
  const navigate = useNavigate();
  const { mutate: handleDeleteProcess } = useDeleteProcessesMutation({
    autoAlert: { mode: 'delete', name: 'فرایند' },
    noRevalidate: true,
    onSuccess: () => {
      onCloseDialog();
      setTimeout(() => navigate('/processes', { replace: true }), 2500);
    },
  });
  const { mutate: handleToggleProcessIsActive } =
    useToggleProcessActiveStatusMutation({
      onSuccess: ({ checked }) => {
        if (typeof checked !== 'boolean') return;

        setAppAlert(
          `فرایند با موفقیت ${checked ? 'فعال' : 'غیرفعال'} شد.`,
          'success'
        );
        setValue('data.isActive', checked);
      },
      onError: () => setAppAlert('خطا در تغییر وضعیت فرایند'),
    });

  // Utilities
  const handleIsActiveToggle = async () => {
    const id = getValues('id');
    const currentChecked = getValues('data.isActive');
    handleToggleProcessIsActive({ id, data: !currentChecked });
  };

  const handleOpenDeleteDialog = () => {
    onOpenDialog({
      title: `حذف فرایند`,
      description: `در صورت حذف فرایند، تمامی اطلاعات ثبت شده شامل ارزیابی‌های صورت گرفته، پرسش‌ها و بازبینی‌های پاسخ داده شده حذف خواهند شد.`,
      onSubmit: () => handleDeleteProcess([getValues('id')]),
    });
  };

  // Render
  return (
    <PageHeroBox>
      <Controller
        control={control}
        name='data.title'
        render={({ field }) => (
          <PageTitle>{mode === 'add' ? 'فرایند جدید' : field.value}</PageTitle>
        )}
      />

      {mode === 'edit' && (
        <RowStack alignItems='center' spacing={1}>
          <Controller
            control={control}
            name='data.isActive'
            defaultValue={false}
            rules={{
              onChange: handleIsActiveToggle,
            }}
            render={({ field }) => (
              <SwitchWithLabel
                checked={field.value}
                disabled={disabled || !isFocusEmpty}
                onCheckChange={handleIsActiveToggle}
              />
            )}
          />
          <DeleteIconButton
            disabled={disabled || !isFocusEmpty}
            onClick={handleOpenDeleteDialog}
          />
        </RowStack>
      )}
    </PageHeroBox>
  );
};

export default ProcessDetailsActions;

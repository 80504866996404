import type { FC } from 'react';

// Core Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Feature Utilities
import ContentDetailsActions from 'features/content/contents/components/body/actions';
import ContentDetailsTitle from 'features/content/contents/components/body/title';
import ContentDetailsConfig from 'features/content/contents/components/body/configs';
import ContentDetailsBlocks from 'features/content/contents/components/body/blocks';

// Component Types
interface ContentDataProps {}

const ContentData: FC<ContentDataProps> = () => {
  return (
    <>
      <ContentDetailsActions />
      <ColumnStack>
        <ContentDetailsTitle />
        <ContentDetailsBlocks />
        <ContentDetailsConfig />
      </ColumnStack>
    </>
  );
};

export default ContentData;

import * as React from 'react';
import isEqual from 'lodash/isEqual';
import { ReactSortable } from 'react-sortablejs';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Button from 'core/components/base/inputs/Button';

// Custom Common Components
import BannerItem from 'features/banner/banners/components/details/Item';

// Custom Hooks
import { useAppDispatch, useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { deepClone, scrollToBottom } from 'core/utilities/helper/helperPack';
import {
  addNewBanner,
  setBannerContainerList,
} from 'core/store/slices/feature/banner';

// Custom Types
import type { BannerItemProps } from 'features/banner/banners/types';

interface BannerListProps {}

const BannerList: React.FC<BannerListProps> = (props) => {
  // States
  const [isSorting, setIsSorting] = React.useState<boolean>(false);

  // Context
  const loading = useSelectLoading();
  const containerId = useAppSelector((store) => store.banner.current?.id || '');
  const banners = useAppSelector(
    (store) => store.banner.current?.data.items || []
  );

  // Hooks
  const dispatch = useAppDispatch();

  // Utilities
  const handleAddItem = () => {
    dispatch(addNewBanner());
    setTimeout(() => scrollToBottom(), 100);
  };

  const handleSort = (sorted: BannerItemProps[]) => {
    if (!isSorting) return;

    const newSorted = sorted.map((item, index) => ({
      id: item.id,
      data: {
        ...item.data,
        sortNumber: index,
      },
    }));

    if (!isEqual(newSorted, banners)) {
      dispatch(setBannerContainerList(newSorted));
    }

    setIsSorting(false);
  };

  // Render
  return (
    <Box sx={{ width: '100%' }}>
      {banners.length > 0 && (
        <ReactSortable
          animation={150}
          disabled={loading}
          direction='vertical'
          list={deepClone(banners)}
          handle='.bannerItemInputHandle'
          onStart={() => setIsSorting(true)}
          setList={(newState) => handleSort(newState)}
        >
          {banners.map((banner, index) => (
            <BannerItem
              key={`banner-${banner.id}`}
              banner={banner}
              bannerIndex={index}
              sx={{ mb: '0.5rem' }}
            />
          ))}
        </ReactSortable>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          color='info'
          disabled={!containerId || loading}
          onClick={handleAddItem}
          sx={{ width: '8rem', margin: '0 auto' }}
          variant='contained'
        >
          بنر جدید
        </Button>
      </Box>
    </Box>
  );
};

export default BannerList;

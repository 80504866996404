import * as React from 'react';

// Custom Core Components
import Skeleton from 'core/components/base/feedback/Skeleton';

// Custom Types
interface ImageSkeletonProps {}

const ImageSkeleton: React.FC<ImageSkeletonProps> = (props) => {
  // Render
  return (
    <Skeleton
      variant='rounded'
      animation='wave'
      sx={{
        width: '712px',
        height: '551px',
        margin: '0 auto',
      }}
    />
  );
};

export default ImageSkeleton;

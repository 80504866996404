export const AudienceAccessesEndpoints = {
  get add() {
    return `/access/create`;
  },
  edit(id: string) {
    return `/access/update/${id}`;
  },
  remove(id: string) {
    return `/access/remove/${id}`;
  },
  get getList() {
    return `/access/accesses`;
  },
};

import type { FC } from 'react';

// Custom Core Components
import IconButton, {
  type IconButtonProps,
} from 'core/components/base/inputs/IconButton';

// Custom Icon Components
import SettingsIcon from 'core/components/icons/Settings';

// Types
export interface PatternGearIconButtonProps extends IconButtonProps {
  open?: boolean;
}

const PatternGearIconButton: FC<PatternGearIconButtonProps> = (props) => {
  // Props
  const { children, open, sx, className, ...otherProps } = props;

  return (
    <IconButton
      sx={{
        transition: 'transform 0.5s',
        '&.rotate-icon': {
          transform: 'rotate(360deg)',
        },
        ...sx,
      }}
      className={className ?? open ? 'rotate-icon' : ''}
      {...otherProps}
    >
      <SettingsIcon />
    </IconButton>
  );
};

export default PatternGearIconButton;

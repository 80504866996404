import * as React from 'react';

// Feature Components
import CancelButton from 'features/form/shared/components/button/Cancel';

// Context
import { useSelectProcessItemIsFocused } from 'features/form/processes/store/selector';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { ButtonProps } from 'core/components/base/inputs/Button';

export interface ProcessCancelButtonProps extends ButtonProps {
  itemId: string;
  isDraft: boolean;
  onCancel: () => void;
}

const ProcessCancelButton: React.FC<ProcessCancelButtonProps> = (props) => {
  // Props
  const { itemId, onCancel, sx, ...otherProps } = props;

  // Context
  const isFocused = useSelectProcessItemIsFocused(itemId);
  const loading = useSelectLoading();

  // Render
  return (
    <CancelButton
      disabled={loading || !isFocused}
      variant='outlined'
      onClick={onCancel}
      sx={{
        p: '0.5rem',
        borderRadius: '0.5rem',
        borderColor: ({ palette }) => palette.error.main,
        ':hover': {
          borderColor: ({ palette }) => palette.error.main,
        },
        ...sx,
      }}
      {...otherProps}
    />
  );
};

export default ProcessCancelButton;

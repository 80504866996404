// Feature Utilities
import { bakeHeaderPatternData } from 'features/appBuilder/patterns/features/header/utilities/api/baker';
import { bakeFooterPatternData } from 'features/appBuilder/patterns/features/footer/utilities/api/baker';
import { bakeContentPatternData } from 'features/appBuilder/patterns/features/content/utilities/api/baker';
import { bakeProfilePatternData } from 'features/appBuilder/patterns/features/profile/utilities/api/baker';
import { bakeStablePatternData } from 'features/appBuilder/patterns/features/stable/utilities/api';
import { bakeSliderPatternData } from 'features/appBuilder/patterns/features/slider/utilities/api/baker';
import { bakeContentDetailsPatternData } from 'features/appBuilder/patterns/features/contentDetails/utilities/api/baker';
import { bakeTagAndCategoryPatternData } from 'features/appBuilder/patterns/features/tagAndCategory/utilities/api/baker';
import { bakeSearchPatternData } from 'features/appBuilder/patterns/features/search/utilities/api/baker';
import { bakeFormPatternData } from 'features/appBuilder/patterns/features/form/utilities/api/baker';
import { bakeTablePatternData } from 'features/appBuilder/patterns/features/table/utilities/api';
import { bakeGroupPatternData } from 'features/appBuilder/patterns/features/group/utilities/api/baker';

// Feature Types
import type { PatternData } from 'features/appBuilder/patterns/core/types/item';
import type { HeaderPatternDataProps } from 'features/appBuilder/patterns/features/header/types';
import type { FooterPatternDataProps } from 'features/appBuilder/patterns/features/footer/types';
import type { ContentPatternDataProps } from 'features/appBuilder/patterns/features/content/types';
import type { ProfilePatternDataProps } from 'features/appBuilder/patterns/features/profile/types';
import type { StablePatternDataProps } from 'features/appBuilder/patterns/features/stable/types';
import type { SliderPatternDataProps } from 'features/appBuilder/patterns/features/slider/types';
import type { ContentDetailsPatternDataProps } from 'features/appBuilder/patterns/features/contentDetails/types';
import type { TagAndCategoryPatternDataProps } from 'features/appBuilder/patterns/features/tagAndCategory/types';
import type { SearchPatternDataProps } from 'features/appBuilder/patterns/features/search/types';
import type { FormPatternDataProps } from 'features/appBuilder/patterns/features/form/types';
import type { TablePatternDataProps } from 'features/appBuilder/patterns/features/table/types';
import type { PatternCommonProps } from 'features/appBuilder/patterns/core/types/item/common';
import type { GroupPatternDataProps } from 'features/appBuilder/patterns/features/group/types';

/**
 * Transforms the provided pattern common properties by ensuring the `creator` field is included as a string.
 *
 * @param {PatternCommonProps} commonProps - The pattern common properties to transform.
 * @returns {Omit<PatternCommonProps, 'creator'> & { creator: string }} The transformed pattern common properties with the `creator` field as a string.
 */
export const bakePatternCommonProps = (
  commonProps: PatternCommonProps
): Omit<PatternCommonProps, 'creator'> & { creator: string } => {
  const { creator, ...otherCommonProps } = commonProps;

  return {
    ...otherCommonProps,
    creator: commonProps.creator?.id || '',
  };
};

/**
 * Processes the provided pattern data and returns a transformed result.
 *
 * @param {PatternData} pattern - The pattern data to process.
 * @returns {any} The transformed pattern data. The exact structure of the result depends on the pattern type.
 */
export const bakePatternBody = (pattern: PatternData): any => {
  let type = pattern.type;
  let body = null;

  if (type === 'header') {
    body = bakeHeaderPatternData(pattern as HeaderPatternDataProps);
  }

  if (type === 'footer') {
    body = bakeFooterPatternData(pattern as FooterPatternDataProps);
  }

  if (type === 'content') {
    body = bakeContentPatternData(pattern as ContentPatternDataProps);
  }

  if (type === 'stable') {
    body = bakeStablePatternData(pattern as StablePatternDataProps);
  }

  if (type === 'profile') {
    body = bakeProfilePatternData(pattern as ProfilePatternDataProps);
  }

  if (type === 'slider') {
    body = bakeSliderPatternData(pattern as SliderPatternDataProps);
  }

  if (type === 'contentDetails') {
    body = bakeContentDetailsPatternData(
      pattern as ContentDetailsPatternDataProps
    );
  }

  if (type === 'tagAndCategory') {
    body = bakeTagAndCategoryPatternData(
      pattern as TagAndCategoryPatternDataProps
    );
  }

  if (type === 'search') {
    body = bakeSearchPatternData(pattern as SearchPatternDataProps);
  }

  if (type === 'form') {
    body = bakeFormPatternData(pattern as FormPatternDataProps);
  }

  if (type === 'group') {
    body = bakeGroupPatternData(pattern as GroupPatternDataProps);
  }

  if (type === 'table') {
    body = bakeTablePatternData(pattern as TablePatternDataProps);
  }

  return body;
};

// Types
import type { FC } from 'react';

// Custom section Components
import GroupPatternColumns from 'features/appBuilder/patterns/features/group/components/Columns';

// Custom Types
interface GroupPatternItemProps {}

const GroupPatternItem: FC<GroupPatternItemProps> = () => {
  return (
    <>
      <GroupPatternColumns />
    </>
  );
};

export default GroupPatternItem;

import cloneDeep from 'lodash/cloneDeep';

// Feature Defaults
import { stablePatternContentDefaults } from 'features/appBuilder/patterns/features/stable/utilities/defaults/content';

// Custom Types
import type { StablePatternDataProps } from 'features/appBuilder/patterns/features/stable/types';

const stablePatternDataDefaults: StablePatternDataProps = {
  patternTitle: '',
  bgColorOne: '#F5F5F5',
  bgColorTwo: '#F5F5F5',
  dividerColor: '#C1C8D3',
  bgImage: null,
  bgImageType: 'cover',
  padding: '32px',
  cardBg: '',
  cardCount: 0,
  creator: null,
  isActive: true,
  maxWidth: 'lg',
  sortIndex: 0,
  type: 'stable',
  variant: 0,
  content: stablePatternContentDefaults,
};

/**
 * Retrieves the default stable pattern data.
 *
 * @returns {StablePatternDataProps} The default stable pattern data.
 */
export const getStablePatternDataDefault = () => {
  const cloned = cloneDeep(stablePatternDataDefaults);

  return cloned;
};

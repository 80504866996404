import * as React from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

// Custom Hooks
import { useAppDispatch, useAppSelector } from 'core/hooks/redux';
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// Core Components
import Box from 'core/components/base/layout/Box';
import Divider from 'core/components/base/display/Divider';
import LoadingButton from 'core/components/base/inputs/LoadingButton';
import Paper from 'core/components/base/surfaces/Paper';
import Stack from 'core/components/base/layout/Stack';
import TextField from 'core/components/base/inputs/TextField';
import Select from 'core/components/base/inputs/Select';
import MenuItem from 'core/components/base/navigation/MenuItem';

// Components
import PageHeadConfigs from 'core/components/shared/PageTitle';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import CheckboxWithLabel from 'core/components/shared/Checkbox/CheckboxWithLabel';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingTwo from 'core/components/shared/Typography/HeadingTwo';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import FileUploader from 'core/components/shared/Input/NewFileUploader';

// Hooks
import useRefId from 'core/hooks/useRefId';

// Store
import { updateTheme } from 'core/store/slices/core/setting';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Utilities
import { getFileSource } from 'core/utilities/helper/helperPack';
import { initialSettings } from 'features/manager/settings/utilities';
import {
  useEditSettingsMutation,
  useManagerSettingsDoc,
} from 'features/manager/settings/hooks';

// Validation
import SettingsValidationSchema from 'features/manager/settings/validations';

// Types
import type { SettingsDataToSubmit } from 'features/manager/settings/types';

interface SettingsPageProps {}

const Settings: React.FC<SettingsPageProps> = (props) => {
  // Context
  const loading = useSelectLoading();
  const settingState = useAppSelector((state) => state.setting);

  // Hooks
  const [refId, setRefId] = useRefId();
  const dispatch = useAppDispatch();
  const resolver = useYupValidationResolver(SettingsValidationSchema);
  const { control, handleSubmit, reset } = useForm<
    SettingsDataToSubmit & { password: string }
  >({
    mode: 'onTouched',
    resolver,
    defaultValues: initialSettings.data,
  });
  const { data: settings } = useManagerSettingsDoc({
    alertOnFetchError: { message: 'دریافت تنظیمات با خطا مواجه شد.' },
    enabled: true,
    initialData: initialSettings,
    onFetch: (doc) => {
      setRefId(doc?.data.relatedMediaId || refId);
      dispatch(updateTheme({ theme: doc.data.themes[0], index: 0 }));
      reset({
        ...doc.data,
        password: '',
      });
    },
  });
  const { mutate: handleEditSettings } = useEditSettingsMutation({
    autoAlert: { mode: 'update', name: 'تنظیمات' },
  });

  let theme = settingState.data.themes[0];

  // Utilities
  const onSubmit: SubmitHandler<SettingsDataToSubmit> = async (values) => {
    if (!settings?.id) return;
    const updatedData = { ...values, relatedMediaId: refId };
    handleEditSettings(updatedData);
  };

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='تنظیمات' />
      <Box
        component='form'
        onSubmit={handleSubmit((values) =>
          onSubmit({ ...values, themes: [theme] })
        )}
      >
        <PageHeroBox>
          <HeadingTwo>تنظیمات اپلیکیشن</HeadingTwo>
          <Controller
            control={control}
            name='description'
            render={({ field }) => (
              <LoadingButton
                disabled={!settings?.id || !field.value}
                loading={loading}
                type='submit'
              >
                به‌روزرسانی
              </LoadingButton>
            )}
          />
        </PageHeroBox>
        <Paper sx={{ borderRadius: '1rem', padding: '3rem' }}>
          <ColumnStack sx={{ maxWidth: '40rem', marginX: 'auto' }}>
            <Box>
              <InputLabel>متن معرفی سایت</InputLabel>
              <Controller
                control={control}
                name='description'
                render={({ field, fieldState }) => (
                  <TextField
                    disabled={!settings?.id}
                    fullWidth
                    multiline
                    placeholder='در رابطه با سایت خود بنویسید...'
                    rows={5}
                    type='text'
                    error={
                      fieldState.isTouched && fieldState.error?.message
                        ? true
                        : false
                    }
                    helperText={
                      fieldState.isTouched && fieldState.error?.message
                    }
                    {...field}
                  />
                )}
              />
            </Box>
            <Stack sx={{ marginBottom: '1rem' }}>
              <Box sx={{ width: '100%' }}>
                <InputLabel>آیکن سایت</InputLabel>
                <Controller
                  control={control}
                  name='favicon'
                  render={({ field }) => (
                    <FileUploader
                      location='managerSetting'
                      accept={['image']}
                      onUploadChange={(file) => field.onChange(file)}
                      onUploadRemove={() => field.onChange(null)}
                      disabled={loading}
                      sx={{
                        height: '150px',
                      }}
                      previewProps={{
                        previewAlt: 'آیکن سایت',
                        previewSrc: field.value
                          ? getFileSource(field.value)
                          : '',
                      }}
                    />
                  )}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <InputLabel>لوگوی سایت</InputLabel>
                <Controller
                  control={control}
                  name='logo'
                  render={({ field }) => (
                    <FileUploader
                      location='managerSetting'
                      accept={['image']}
                      onUploadChange={(file) => field.onChange(file)}
                      onUploadRemove={() => field.onChange(null)}
                      disabled={loading}
                      sx={{
                        height: '150px',
                      }}
                      previewProps={{
                        previewAlt: 'لوگوی سایت',
                        previewSrc: field.value
                          ? getFileSource(field.value)
                          : '',
                      }}
                    />
                  )}
                />
              </Box>
            </Stack>
            <Box sx={{ width: '100%', height: '2rem' }} />
            <Stack>
              <Box sx={{ width: '100%' }}>
                <InputLabel>فونت کل سایت</InputLabel>
                <Controller
                  control={control}
                  name='fontFamily'
                  render={({ field }) => (
                    <Select
                      disabled={loading || !settings?.id}
                      sx={{ width: '48%' }}
                      {...field}
                    >
                      <MenuItem value='Vazir'>وزیر</MenuItem>
                      <MenuItem value='Peyda'>پیدا</MenuItem>
                      <MenuItem value='IranSansWeb'>ایران‌سنس وب</MenuItem>
                    </Select>
                  )}
                />
              </Box>
            </Stack>
            <Stack></Stack>
            <Divider sx={{ paddingTop: '2rem' }} />
            <ColumnStack spacing={4} sx={{ paddingY: '2rem' }}>
              <HeadingSix>بازخورد فرم ها</HeadingSix>
              <ColumnStack spacing={0}>
                <Controller
                  control={control}
                  name='enableDeleteFeedback'
                  render={({ field }) => (
                    <CheckboxWithLabel
                      checkboxProps={{
                        checked: field.value,
                        onChange: field.onChange,
                        onBlur: field.onBlur,
                      }}
                      label='دسترسی مدیران به حذف بازخورد‌ها'
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='enableDeleteForm'
                  render={({ field }) => (
                    <CheckboxWithLabel
                      checkboxProps={{
                        checked: field.value,
                        onChange: field.onChange,
                        onBlur: field.onBlur,
                      }}
                      label='دسترسی مدیران به حذف فرم‌ها'
                    />
                  )}
                />
              </ColumnStack>
            </ColumnStack>
            <Divider />
            <Stack>
              <Box sx={{ width: '100%' }}>
                <InputLabel>نام فرستنده</InputLabel>
                <Controller
                  control={control}
                  name='email.name'
                  render={({ field, fieldState }) => (
                    <TextField
                      disabled={loading || !settings?.id}
                      fullWidth
                      placeholder='نام فرستنده'
                      type='text'
                      error={
                        fieldState.isTouched && fieldState.error?.message
                          ? true
                          : false
                      }
                      helperText={
                        fieldState.isTouched && fieldState.error?.message
                      }
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <InputLabel>ایمیل فرستنده</InputLabel>
                <Controller
                  control={control}
                  name='email.address'
                  render={({ field, fieldState }) => (
                    <TextField
                      disabled={loading || !settings?.id}
                      error={
                        fieldState.isTouched && fieldState.error?.message
                          ? true
                          : false
                      }
                      fullWidth
                      helperText={
                        fieldState.isTouched && fieldState.error?.message
                      }
                      placeholder='آدرس ایمیل فرستنده'
                      type='email'
                      {...field}
                    />
                  )}
                />
              </Box>
            </Stack>
            <Box sx={{ width: '100%' }}>
              <InputLabel>میل سرور SMTP</InputLabel>
              <Controller
                control={control}
                name='email.smtp'
                render={({ field, fieldState }) => (
                  <TextField
                    disabled={loading || !settings?.id}
                    error={
                      fieldState.isTouched && fieldState.error?.message
                        ? true
                        : false
                    }
                    fullWidth
                    helperText={
                      fieldState.isTouched && fieldState.error?.message
                    }
                    placeholder='آدرس سرور ایمیل'
                    type='url'
                    {...field}
                  />
                )}
              />
            </Box>
            <Stack>
              <Box sx={{ width: '100%' }}>
                <InputLabel>شناسه کاربری SMTP</InputLabel>
                <Controller
                  control={control}
                  name='email.username'
                  render={({ field, fieldState }) => (
                    <TextField
                      disabled={loading || !settings?.id}
                      error={
                        fieldState.isTouched && fieldState.error?.message
                          ? true
                          : false
                      }
                      fullWidth
                      helperText={
                        fieldState.isTouched && fieldState.error?.message
                      }
                      placeholder='شناسه کاربری'
                      type='text'
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <InputLabel>رمز SMTP</InputLabel>
                <Controller
                  control={control}
                  name='password'
                  render={({ field, fieldState }) => (
                    <TextField
                      dir='ltr'
                      disabled={loading || !settings?.id}
                      fullWidth
                      type='password'
                      placeholder='••••••••••••'
                      error={
                        fieldState.isTouched && fieldState.error?.message
                          ? true
                          : false
                      }
                      helperText={
                        fieldState.isTouched && fieldState.error?.message
                      }
                      {...field}
                    />
                  )}
                />
              </Box>
            </Stack>
          </ColumnStack>
        </Paper>
      </Box>
    </>
  );
};

export default Settings;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

// Types
import type { SubmitHandler } from 'react-hook-form';

// Custom Hooks
import useRefId from 'core/hooks/useRefId';
import { useAppSelector } from 'core/hooks/redux';
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// Core Components
import TextField from 'core/components/base/inputs/TextField';
import Switch from 'core/components/base/inputs/Switch';
import Select from 'core/components/base/inputs/Select';
import MenuItem from 'core/components/base/navigation/MenuItem';

// Common Componenst
import SpacedBox from 'core/components/shared/Box/SpacedBox';
import RowStack from 'core/components/shared/Stack/RowStack';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import DeprecatedImageUploader from 'core/components/shared/Input/Avatar';
import SubmitLoadingButton from 'core/components/shared/LoadingButton/Submit';
import DeleteLoadingButton from 'core/components/shared/LoadingButton/Delete';
import CheckboxWithLabel from 'core/components/shared/Checkbox/CheckboxWithLabel';

// Context
import { useDialogContext } from 'core/store/context/DialogProvider';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import { setAppAlert } from 'core/utilities/helper';
import { useManagerSummary } from 'features/manager/groups/hooks';
import { getErrorAndHelperText } from 'core/utilities/helper/helperPack';
import {
  useDeleteManagersMutation,
  useEditManagerMutation,
  useManagerDoc,
} from 'features/manager/managers/hooks';

// Custom Validation Schemas
import EditManagerValidationSchema from 'features/manager/managers/validations/EditManager';

// Custom Types
import type { ManagerDataProps } from 'features/manager/managers/types';

interface EditManagerProps {}

interface FormInputs extends ManagerDataProps {
  password: string;
}

const EditManager: React.FC<EditManagerProps> = (props) => {
  // Context
  const loading = useSelectLoading();
  const managerState = useAppSelector((state) => state.manager);
  const { onOpenDialog, onCloseDialog } = useDialogContext();

  // Hooks
  const [refId, setRefId] = useRefId();
  const navigate = useNavigate();
  const resolver = useYupValidationResolver(EditManagerValidationSchema);
  const formMethods = useForm<FormInputs>({
    mode: 'onTouched',
    resolver,
    defaultValues: { password: '' },
  });
  const { control, handleSubmit, getValues, setValue } = formMethods;
  const { data: manager } = useManagerDoc({
    autoLoading: true,
    alertOnFetchError: { message: 'دریافت اطلاعات مدیر با خطا مواجه شد.' },
    alertOnDocumentIdIsEmpty: { message: 'شناسه مدیر یافت نشد.' },
    redirectAfterDocumentIdIsEmptyTo: '/managers',
    redirectAfterErrorTo: '/managers',
    onFetch: (doc) => {
      setRefId(doc.data.relatedMediaId);
      formMethods.reset({
        ...doc.data,
        password: '',
      });
    },
  });
  const { data: groups } = useManagerSummary({
    autoLoading: true,
    enabled: manager?.id && manager.id.length > 0 ? true : false,
    alertOnFetchEmptyList: {
      message: 'گروه مدیریتی‌ای یافت نشد. ابتدا گروه مدیریتی ایجاد کنید.',
      severity: 'warning',
    },
    alertOnFetchError: {
      message: 'دریافت لیست گروه مدیران با خطا مواجه شد.',
    },
    onFetch: (groups) => {
      if (groups && groups.length === 0) {
        setTimeout(() => navigate('/manager-groups'), 2500);
      }
    },
  });
  const { mutate: handleEditManager } = useEditManagerMutation({
    noRevalidate: true,
    stayOnLoadingAfterMutate: true,
    redirectAfterSuccessTo: '/managers',
    autoAlert: { mode: 'edit', name: 'اطلاعات مدیر' },
  });
  const { mutate: handleDeleteManagers } = useDeleteManagersMutation({
    noRevalidate: true,
    redirectAfterSuccessTo: '/managers',
    stayOnLoadingAfterMutate: true,
    onSettled: () => onCloseDialog(),
  });

  useEffect(() => {
    if (manager && manager.id === managerState.id) {
      setAppAlert(
        'به منظور تغییر اطلاعات حساب خودتان، از طریق صفحه پروفایل اقدام کنید.',
        'warning'
      );
      setTimeout(() => navigate('/profile'), 3500);
    }
  }, [manager, managerState.id, navigate]);

  // Utilities
  const handleOpenDialog = () =>
    onOpenDialog({
      title: `آیا از حذف ${manager ? manager.data.firstName : '-'} ${
        manager ? manager.data.lastName : '-'
      } اطمینان دارید؟`,
      onSubmit: () => manager?.id && handleDeleteManagers(manager.id),
    });

  const handleFormSubmit: SubmitHandler<FormInputs> = async (payload) => {
    if (!manager || !manager.id || groups.length === 0) return;
    if (manager && manager.id === managerState.id) return;

    if (!payload.groupId) return setAppAlert('گروه‌ مدیریتی را مشخص کنید.');
    if (!payload.email) return setAppAlert('ایمیل را مشخص کنید.');

    handleEditManager({ id: manager.id, data: payload });
  };

  const disabled =
    (manager ? manager.id === managerState.id : false) || loading;

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='ویرایش مدیر' />
      <PageHeroBox>
        <HeadingSix>
          ویرایش{' '}
          {manager && manager.id
            ? `${manager.data.firstName} ${manager.data.lastName}`
            : 'مدیر'}
        </HeadingSix>
        <SubmitLoadingButton
          disabled={groups.length === 0 || disabled}
          loading={groups.length > 0 && loading}
          onClick={handleSubmit(handleFormSubmit)}
        >
          ذخیره
        </SubmitLoadingButton>
      </PageHeroBox>
      <RoundPaper>
        <SpacedBox sx={{ alignItems: 'flex-start' }}>
          <ColumnStack sx={{ width: '20%', mt: 5 }}>
            <Controller
              name='avatar'
              control={control}
              render={({ field }) => (
                <DeprecatedImageUploader
                  location='manager'
                  disabled={!manager || !manager.id || disabled}
                  onDeleteImage={() => setValue(field.name, undefined)}
                  onImageFileChange={(file) => field.onChange(file)}
                  imageFile={field.value?.id ? field.value : undefined}
                  sx={{
                    width: '167px',
                    height: '216px',
                    borderRadius: '1.2rem',
                  }}
                />
              )}
            />
          </ColumnStack>
          <ColumnStack sx={{ width: '75%', mt: 5, pb: 3, pr: 2 }}>
            <RowStack sx={{ width: '100%', justifyContent: 'space-between' }}>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>نام</InputLabel>
                <Controller
                  control={control}
                  name='firstName'
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      disabled={!manager || !manager.id || disabled}
                      placeholder='نام'
                      type='text'
                      {...getErrorAndHelperText(fieldState)}
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>نام‌خانوادگی</InputLabel>
                <Controller
                  name='lastName'
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      disabled={!manager || !manager.id || disabled}
                      placeholder='نام‌خانوادگی'
                      {...getErrorAndHelperText(fieldState)}
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
            </RowStack>
            <RowStack sx={{ width: '100%', justifyContent: 'space-between' }}>
              <ColumnStack width='45%' spacing={0}>
                <InputLabel>ایمیل</InputLabel>
                <Controller
                  name='email'
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      dir='ltr'
                      placeholder='example@nazar.com'
                      fullWidth
                      disabled={!manager || !manager.id || disabled}
                      {...getErrorAndHelperText(fieldState)}
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>کلمه عبور</InputLabel>
                <Controller
                  name='password'
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      dir='ltr'
                      fullWidth
                      type='password'
                      placeholder='password'
                      disabled={!manager || !manager.id || disabled}
                      {...getErrorAndHelperText(fieldState)}
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
            </RowStack>
            <RowStack sx={{ justifyContent: 'space-between' }}>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>گروه‌های مدیریتی</InputLabel>
                <Controller
                  name='groupId'
                  control={control}
                  render={({ field: { value, ...otherFields } }) => (
                    <Select
                      disabled={!manager || !manager.id || disabled}
                      sx={{ height: '3rem', borderRadius: '4px' }}
                      value={value ? value : ''}
                    >
                      {groups.map((group) => (
                        <MenuItem
                          disabled={!manager || !manager.id || disabled}
                          key={group.id}
                          value={group.id}
                          onClick={() => setValue('groupId', group.id)}
                        >
                          {group.data.title}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </ColumnStack>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>ورود دو مرحله‌ای</InputLabel>
                <RowStack
                  sx={({ palette }) => ({
                    width: '80%',
                    borderRadius: '10px',
                    background: palette.background.default,
                    paddingX: '1.5rem',
                    height: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  })}
                >
                  <InputLabel disabled>
                    {manager && manager.data?.is2FAEnabled ? 'فعال' : 'غیرفعال'}
                  </InputLabel>
                  <Switch
                    checked={
                      manager && manager.data?.is2FAEnabled ? true : false
                    }
                    disabled
                  />
                </RowStack>
              </ColumnStack>
            </RowStack>
            <ColumnStack spacing={0}>
              <InputLabel>بیوگرافی</InputLabel>
              <Controller
                name='biography'
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={!manager || !manager.id || disabled}
                    multiline
                    fullWidth
                    rows={6}
                    placeholder='متن بیوگرافی'
                    {...field}
                  />
                )}
              />
            </ColumnStack>
          </ColumnStack>
        </SpacedBox>
        <SpacedBox sx={{ marginTop: '4rem' }}>
          <Controller
            control={control}
            name='isActive'
            render={({ field }) => (
              <CheckboxWithLabel
                label='فعال'
                disabled={!manager || !manager.id || disabled}
                defaultChecked={getValues('isActive')}
                checkboxProps={{
                  checked: field.value,
                  onChange: (e, checked) => field.onChange(checked),
                }}
              />
            )}
          />
          <DeleteLoadingButton
            disabled={!manager || !manager.id || disabled}
            loading={loading && manager && manager.id ? true : false}
            onClick={handleOpenDialog}
          >
            حذف
          </DeleteLoadingButton>
        </SpacedBox>
      </RoundPaper>
    </>
  );
};

export default EditManager;

import { setAppAlert } from 'core/utilities/helper/alert';
import { removeDuplicates } from 'core/utilities/helper/helperPack';
import { acceptedFileTypes } from 'features/file/files/utilities/files';

/**
 * Retrieves the file extension from the provided URL.
 * @param {string} fileName - The URL to extract the file extension from.
 * @returns {string} The file extension.
 */
export const getFileExtension = (fileName: string, noDot = false): string => {
  const dotIndex = fileName.lastIndexOf('.');
  return fileName.substring(noDot ? dotIndex + 1 : dotIndex);
};

/**
 * Filters out invalid files based on accepted file types.
 *
 * @param {File[]} files - An array of File objects to be filtered.
 * @param {boolean} disableTypeAlert - An b.
 * @returns {File[]} An array of valid File objects that match the accepted file types.
 */
export const filterInvalidFiles = (
  files: File[],
  disableTypeAlert = false
): File[] => {
  let invalidFiles: string[] = [];

  const newFiles = files.filter((file) => {
    const isValid = acceptedFileTypes.includes(getFileExtension(file.name));
    if (!isValid) invalidFiles.push(getFileExtension(file.name, true));
    return isValid;
  });

  invalidFiles = removeDuplicates(invalidFiles);
  if (!disableTypeAlert && invalidFiles.length > 0)
    setAppAlert(
      `${
        invalidFiles.length > 1 ? 'فایل‌های' : 'فایل'
      } از نوع ${invalidFiles.join(',')} پذیرفته نیست.`
    );
  return newFiles;
};

/**
 * Retrieves the file extension title based on the provided extension.
 * @param {string} ext - The file extension.
 * @returns {string} The file extension title.
 */
export const getFileExtensionTitle = (ext: string): string => {
  if (['.ppt', 'ppt', '.pptx', 'pptx'].includes(ext)) return 'پاورپوینت';
  if (['.doc', 'doc', '.docx', 'docx'].includes(ext)) return 'ورد';
  if (['.xls', 'xls', '.xlsx', 'xlsx', '.csv', 'csv'].includes(ext))
    return 'اکسل';
  if (['.pdf', 'pdf'].includes(ext)) return 'پی‌دی‌اف';
  return 'فایل';
};

export const fileDownload = (
  data: any,
  filename: string = 'cms-file',
  type?: string
) => {
  const blob = new Blob([data], { type });
  const url = URL.createObjectURL(blob);

  if (document) {
    const anchor = document.createElement('a');
    anchor.href = url;

    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();

    document.body.removeChild(anchor);
    URL.revokeObjectURL(url);
  }
};

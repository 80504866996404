import { memo, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import BodyOne from 'core/components/shared/Typography/BodyOne';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import Caption from 'core/components/shared/Typography/Caption';
import RowStack from 'core/components/shared/Stack/RowStack';
import Checkbox from 'core/components/base/inputs/Checkbox';

// Feature Components
import EvaluatorItemAccesses from 'features/form/processes/components/details/Evaluation/Config/EvaluatorItemAccesses';
import ApplicantFieldAccesses from 'features/form/processes/components/details/Evaluation/Config/ApplicantFieldAccesses';
import EvaluationFieldAccesses from 'features/form/processes/components/details/Evaluation/Config/EvaluationFieldAccesses';

// Core Utilities
import { isSame } from 'core/utilities/helper/helperPack';

// Context
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useSelectProcessItemIsEditable } from 'features/form/processes/store/selector';

// Feature Utilities
import { updateProcessFocus } from 'features/form/processes/store/actions';

// Feature Types
import type { ProcessProps } from 'features/form/processes/types/details';

// Custom Types
export interface EvaluationConfigProps {
  index: number;
}

const EvaluationConfig: FC<EvaluationConfigProps> = (props) => {
  // Props
  const { index } = props;

  // Hooks
  const loading = useSelectLoading();
  const { control, getValues } = useFormContext<ProcessProps>();
  const evaluationId = getValues(`data.items.${index}.id`);
  const isEditable = useSelectProcessItemIsEditable(evaluationId);

  // TODO: Accesses Components Needs Refactor.
  // We are filtering form step/sections/inputs every time on every re-render
  // We should memoize the filter.

  // Render
  return (
    <ColumnStack
      sx={{ border: '0.8px solid #C1C8D3', mb: 2, p: 2, borderRadius: 2 }}
    >
      <ApplicantFieldAccesses disabled={!isEditable || loading} index={index} />
      <InputWrapper>
        <RowStack sx={{ alignItems: 'center', p: 0 }} spacing={0}>
          <Controller
            control={control}
            name={`data.items.${index}.data.isEvaluatorReviewEnabled`}
            render={({ field }) => (
              <Checkbox
                disabled={!isEditable || loading}
                size='small'
                disableRipple
                checked={field.value}
                onChange={(_, checked) => {
                  field.onChange(checked);
                  updateProcessFocus(evaluationId);
                }}
              />
            )}
          />
          <BodyOne
            disabled={!isEditable || loading}
            sx={{ whiteSpace: 'nowrap' }}
          >
            امکان ایجاد بازبینی
          </BodyOne>
        </RowStack>
        <Caption>امکان ایجاد یک سوال توسط ارزیابان برای متقاضی</Caption>
      </InputWrapper>
      <EvaluationFieldAccesses
        disabled={!isEditable || loading}
        index={index}
      />
      <EvaluatorItemAccesses disabled={!isEditable || loading} index={index} />
    </ColumnStack>
  );
};

export default memo(EvaluationConfig, isSame);

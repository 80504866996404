import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface UserFriendsIconProps extends SvgIconProps {}

const UserFriendsIcon: React.FC<UserFriendsIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M8,12c1.9,0,3.5-1.6,3.5-3.6S9.9,4.9,8,4.9S4.5,6.5,4.5,8.4S6.1,12,8,12z M10.4,13h-0.3c-0.6,0.3-1.4,0.5-2.1,0.5
	S6.5,13.3,5.9,13H5.6c-2,0-3.6,1.6-3.6,3.7v0.9c0,0.8,0.7,1.5,1.5,1.5h9c0.8,0,1.5-0.7,1.5-1.5v-0.9C14,14.7,12.4,13,10.4,13z
	 M17,12c1.7,0,3-1.4,3-3.1c0-1.7-1.3-3.1-3-3.1s-3,1.4-3,3.1C14,10.6,15.3,12,17,12z M18.5,13h-0.1c-0.4,0.2-0.9,0.3-1.4,0.3
	c-0.5,0-0.9-0.1-1.4-0.3h-0.1c-0.6,0-1.2,0.2-1.7,0.5c0.8,0.8,1.2,2,1.2,3.2v1.2c0,0.1,0,0.1,0,0.2h5.5c0.8,0,1.5-0.7,1.5-1.5
	C22,14.6,20.4,13,18.5,13z'
    />
  </IconBuilder>
);

export default UserFriendsIcon;

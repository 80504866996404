import { useParams } from 'react-router-dom';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect, useState, type FC } from 'react';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';
import useUpdateEffect from 'core/hooks/useUpdateEffect';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternTextItem from 'features/appBuilder/patterns/core/components/shared/items/Text';
import PatternImageItem from 'features/appBuilder/patterns/core/components/shared/items/Image';
import PatternDisplayCardItem from 'features/appBuilder/patterns/core/components/shared/items/DisplayCard';

// Context
import { useDocContext } from 'core/hooks/api/useDoc/Context';

// Custom Types
import type {
  ContentPatternProps,
  ContentPatternDataProps,
} from 'features/appBuilder/patterns/features/content/types';

export interface ContentPatternConfigSectionItemProps {}
type CollapsedKey = 'section' | 'title' | 'body' | 'image' | 'display';

const ContentPatternConfigSection: FC<
  ContentPatternConfigSectionItemProps
> = () => {
  // Hooks
  const params = useParams();
  const isEditMode = 'id' in params && 'patternType' in params;

  // States
  const isMounted = useBoolean(!isEditMode);
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    title: false,
    body: false,
    display: false,
    image: false,
  });

  // Context
  const patternDoc = useDocContext<ContentPatternProps>();
  const { control, setValue, getValues } =
    useFormContext<ContentPatternDataProps>();

  // Hooks
  const variant = useWatch({
    control,
    name: 'variant',
    defaultValue: getValues('variant') || 0,
  });

  useUpdateEffect(() => {
    if (!isEditMode) return isMounted.setTrue();
    if (patternDoc?.isFetched) {
      setTimeout(() => {
        const stateVariant = getValues('variant');
        const initialVariant = patternDoc?.data?.data?.variant;
        if (stateVariant === initialVariant) isMounted.setTrue();
      }, 300);
    }
  }, [isMounted.state, patternDoc?.isFetching]);

  useEffect(() => {
    if (!isMounted.state) return;
    switch (variant) {
      case 0:
        setValue('config.image.height', 224);
        setValue('config.display.width', 256);
        break;
      case 1:
        setValue('config.image.height', 80);
        setValue('config.image.width', 80);
        setValue('config.display.width', 256);
        break;
      case 2:
        setValue('config.image.height', 60);
        setValue('config.image.width', 60);
        break;
    }
  }, [variant]);

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  // Render
  return (
    <PatternSectionContainer
      title='تنظیمات و استایل کانتنت'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='config'
    >
      <PatternTextItem
        reference='config.title'
        title='عنوان'
        collapsed={collapsed.title}
        onCollapsedChange={handleCollapseChanged('title')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'fontColor',
          'highlightColor',
          'fontHoverColor',
          'highlightHoverColor',
        ]}
      />
      <PatternTextItem
        reference='config.body'
        title='متن'
        collapsed={collapsed.body}
        onCollapsedChange={handleCollapseChanged('body')}
        lineClampOptions={[1, 2, 3, 4, 5]}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'fontColor',
          'highlightColor',
          'fontHoverColor',
          'highlightHoverColor',
        ]}
      />
      <PatternImageItem
        reference='config.image'
        title='تنظیمات عکس'
        collapsed={collapsed.image}
        onCollapsedChange={handleCollapseChanged('image')}
        noUpload
        noPosition
        noWidth={variant === 0}
        playIcon
        include={['padding', 'borderRadius']}
      />
      <PatternDisplayCardItem
        noDisplayType={variant === 2}
        noWidth={variant === 2}
        reference='config.display'
        title='نمایش کارت‌ها'
        collapsed={collapsed.display}
        onCollapsedChange={handleCollapseChanged('display')}
        include={['cardBg', 'cardBgHover', 'padding', 'align', 'borderRadius']}
      />
    </PatternSectionContainer>
  );
};

export default ContentPatternConfigSection;

import * as Yup from 'yup';

export const ContentSchema = Yup.object().shape({
  data: Yup.object().shape({
    title: Yup.string().required('لطفا عنوان مطلب را وارد کنید'),
    skinId: Yup.string().required('لطفا اسکین مطلب را انتخاب کنید'),
    slug: Yup.string()
      .optional()
      .matches(
        /^[^?$#!()@٬٫٪×،*+=:^&%--ـ]*$/,
        'اسلاگ تنها می‌تواند شامل حروف و اعداد باشد'
      ),
  }),
});

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import type { SubmitHandler } from 'react-hook-form';

// Custom HOCs
import WithAuth from 'core/components/shared/HOC/WithAuth';

// Custom Common Components
import AudienceGroupForm from 'features/audience/groups/components/details/Form';
import SubmitLoadingButton from 'core/components/shared/LoadingButton/Submit';
import HeadingTwo from 'core/components/shared/Typography/HeadingTwo';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useAddAudienceGroupMutation } from 'features/audience/groups/hooks';

// Custom Utilities
import {
  setAppLoading,
  setFailedCrudAlert,
  setSuccessCrudAlert,
} from 'core/utilities/helper';

// Custom Types
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';
import type { AudienceGroupDataProps } from 'features/audience/groups/types';

interface AddAudienceGroupProps extends WithAuthProps {}

const AddAudienceGroup: React.FC<AddAudienceGroupProps> = (props) => {
  // Hooks
  const navigate = useNavigate();
  const loading = useSelectLoading();
  const formMethods = useForm<AudienceGroupDataProps>({
    mode: 'onTouched',
    defaultValues: { title: '' },
  });

  const { mutate: handleAddGroup } = useAddAudienceGroupMutation({
    autoAlert: { mode: 'create', name: 'گروه جدید' },
    redirectAfterSuccessTo: '/audience-groups',
  });

  // Utilities
  const handleFormSubmit: SubmitHandler<AudienceGroupDataProps> = async (
    data
  ) => {
    setAppLoading(true);
    handleAddGroup(data.title);
  };

  return (
    <>
      <PageHeadConfigs pageTitle='افزودن گروه مخاطبان' />
      <PageHeroBox>
        <HeadingTwo>گروه مخاطبان</HeadingTwo>
        <Controller
          control={formMethods.control}
          name='title'
          render={({ field }) => (
            <SubmitLoadingButton
              disabled={!field.value}
              loading={loading}
              onClick={formMethods.handleSubmit(handleFormSubmit)}
            >
              ذخیره
            </SubmitLoadingButton>
          )}
        />
      </PageHeroBox>
      <FormProvider {...formMethods}>
        <AudienceGroupForm />
      </FormProvider>
    </>
  );
};

export default WithAuth(AddAudienceGroup);

import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

// Custom Types
import { FeatureType } from 'core/types/feature/FeatureType';

const sidebarInitialState: {
  collapsed: boolean;
  feature: FeatureType | '';
} = {
  collapsed: false,
  feature: 'content',
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: sidebarInitialState,
  reducers: {
    onSidebarSectionOpen: (
      state,
      action: PayloadAction<{ feature: FeatureType; keepOpen?: boolean }>
    ) => {
      const { feature, keepOpen } = action.payload;
      if (!keepOpen && state.feature === feature) {
        state.feature = '';
      } else {
        state.feature = feature;
      }

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase('/sidebar/toggle', (state) => {
      state.collapsed = !state.collapsed;
      return state;
    });
    builder.addCase('/sidebar/open', (state) => {
      state.collapsed = false;
      return state;
    });
    builder.addCase('/sidebar/close', (state) => {
      state.collapsed = true;
      return state;
    });
  },
});

export const { onSidebarSectionOpen } = sidebarSlice.actions;
export default sidebarSlice.reducer;

import store from 'core/store';
import { MenuActionModeType, actions } from './slice';
import {
  MenuContainerProps,
  MenuItemDataProps,
  MenuItemProps,
} from 'features/menu/menus/types';

export const addMenu = (menu: MenuItemProps) =>
  store.dispatch(actions.addNewMenu(menu));

export const updateMenu = (menuId: string, data: MenuItemDataProps) =>
  store.dispatch(actions.updateMenu({ id: menuId, data }));

export const toggleIsActive = (menuId: string, value: boolean) =>
  store.dispatch(actions.updateIsActive({ menuId, value }));

export const updateSortNumbers = (menus: MenuItemProps[]) =>
  store.dispatch(actions.updateSortNumbers({ items: menus }));

export const setMenuActionMode = (mode: MenuActionModeType) =>
  store.dispatch(actions.setMenuActionMode(mode));

export const setMenuItems = (menus: MenuItemProps[]) =>
  store.dispatch(actions.setMenuItems(menus));

export const setMenuContainer = (container: MenuContainerProps) =>
  store.dispatch(actions.setMenuContainer(container));

export const setMenuContainerTitle = (title: string) =>
  store.dispatch(actions.setContainerTitle(title));

export const toggleDeleteMenu = (menuId: string) =>
  store.dispatch(actions.toggleDeleteMenu(menuId));

export const deleteMenu = (menu: MenuItemProps) =>
  store.dispatch(actions.deleteMenu(menu));

export const clearDeletionIdsMap = () =>
  store.dispatch(actions.clearDeletionIdsMap());

export const setInEdit = (menuId: string) =>
  store.dispatch(actions.setInEdit(menuId));

export const updateOriginalItems = () =>
  store.dispatch(actions.updateOriginalItems());

export const selectChildren = (
  selectedOpts: MenuItemProps[],
  newParentIds: string[]
) => store.dispatch(actions.selectChildren({ selectedOpts, newParentIds }));

export const unSelectChildren = (
  unselectedOpts: MenuItemProps[],
  currentParentIds: string[]
) =>
  store.dispatch(
    actions.unselectChildren({ unselectedOpts, currentParentIds })
  );

export const resetMenuState = () => store.dispatch(actions.resetState());

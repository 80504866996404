import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';

// Core Components
import MuiLink from 'core/components/base/navigation/Link';

// Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Types
import type { LinkProps as MuiLinkProps } from 'core/components/base/navigation/Link';
interface RouteLinkProps extends MuiLinkProps {
  disabled?: boolean;
  state?: any;
}

const RouteLink: React.FC<RouteLinkProps> = (props) => {
  const {
    children,
    disabled,
    state,
    href = '/',
    to,
    sx,
    ...otherProps
  } = props;

  const location = useLocation();
  const { palette } = useTheme();

  const LinkComp = React.forwardRef((props: any, ref: any) => {
    return (
      <Link ref={ref} to={to ? to : href} state={state} {...props}>
        {props.children}
      </Link>
    );
  });

  return disabled ? (
    <BodyTwo color='text.disabled'>{children}</BodyTwo>
  ) : (
    <MuiLink
      to={to ? to : href}
      component={LinkComp}
      sx={{
        color: href === location.pathname ? palette.info.main : 'inherit',
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </MuiLink>
  );
};

export default RouteLink;

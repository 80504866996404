// Custom Utilities
import { bakeAudienceGroupsSummary } from 'features/audience/groups/utilities/api';
import { initialFile } from 'features/file/files/utilities/file';

// Custom Types
import type {
  ApiAudienceProps,
  ApiAudienceDependantsProps,
} from 'features/audience/audiences/types/api';
import type {
  AudienceDependantProps,
  AudienceProps,
} from 'features/audience/audiences/types';

export const bakeAudienceDependant = (
  dependant: AudienceDependantProps
): ApiAudienceDependantsProps => ({
  firstName: dependant.data.firstName,
  lastName: dependant.data.lastName,
  nationalId: dependant.data.nationalId,
  relation: dependant.data.relation,
});

export const bakeAudienceDependants = (
  dependants: AudienceDependantProps[]
): ApiAudienceDependantsProps[] => {
  const apiDependants: ApiAudienceDependantsProps[] = [];

  dependants.forEach((dependant) =>
    apiDependants.push(bakeAudienceDependant(dependant))
  );

  return apiDependants;
};

export const bakeAudience = (audience: AudienceProps): ApiAudienceProps => ({
  _id: audience.id,
  data: {
    biography: audience.data.biography,
    birthDate: audience.data.birthDate,
    email: audience.data.email,
    firstName: audience.data.firstName,
    groups: bakeAudienceGroupsSummary(audience.data.groups),
    accessId: audience.data.accessId,
    isEnabled: audience.data.isEnabled,
    jobTitle: audience.data.jobTitle,
    lastName: audience.data.lastName,
    nationalId: audience.data.nationalId,
    personelId: audience.data.personalId,
    phoneNumber: audience.data.phoneNumber,
    dependants: bakeAudienceDependants(audience.data.dependants),
    avatar: audience.data.avatar ? audience.data.avatar : initialFile,
    creator: audience.data.creator,
  },
});

export const bakeAudiences = (
  audiences: AudienceProps[]
): ApiAudienceProps[] => {
  const apiAudiences: ApiAudienceProps[] = [];

  audiences.forEach((audience) => apiAudiences.push(bakeAudience(audience)));

  return apiAudiences;
};

import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternMenuButtonItem from 'features/appBuilder/patterns/core/components/shared/items/MenuButton';

// Custom Types
export interface HeaderPatternBrandItemProps {}
type CollapsedKey = 'section' | 'one' | 'two' | 'logout';

const HeaderPatternButtonSection: FC<HeaderPatternBrandItemProps> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    one: false,
    two: false,
    logout: false,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='دکمه‌ها'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='button'
    >
      <PatternMenuButtonItem
        reference='button.one'
        title='دکمه اول'
        collapsed={collapsed.one}
        onCollapsedChange={handleCollapseChanged('one')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'color',
          'bgColor',
          'hoverColor',
          'bgHoverColor',
          'borderRadius',
        ]}
      />
      <PatternMenuButtonItem
        reference='button.two'
        title='دکمه دوم'
        collapsed={collapsed.two}
        onCollapsedChange={handleCollapseChanged('two')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'color',
          'bgColor',
          'hoverColor',
          'bgHoverColor',
          'borderRadius',
        ]}
      />
      <PatternMenuButtonItem
        reference='button.logout'
        title='دکمه خروج'
        collapsed={collapsed.logout}
        onCollapsedChange={handleCollapseChanged('logout')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'color',
          'bgColor',
          'hoverColor',
          'bgHoverColor',
          'borderRadius',
        ]}
        showLinkConfig={false}
        showIconConfig
      />
    </PatternSectionContainer>
  );
};

export default HeaderPatternButtonSection;

import {
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
  styled,
} from '@mui/material';

export interface DialogActionsProps extends MuiDialogActionsProps {}

const DialogActions = styled(MuiDialogActions)<DialogActionsProps>(() => ({}));

export default DialogActions;

import { useAppSelector } from 'core/hooks/redux';
import { isSame } from 'core/utilities/helper/helperPack';

// Custom Types
import type { FeedbackProcessProps } from 'features/form/feedbacks/types/detail/process';
import type { ManagerQuestionProps } from 'features/form/processes/types/details/managerQuestion';
import type { FeedbackProcessEvaluationProps } from 'features/form/feedbacks/types/detail/process/evaluation';
import type { ProcessFilterNameType } from 'features/form/feedbacks/components/details/Actions/Filter/ViewSettings/ProcessFields';

/**
 * Selects the feedback process based on the index.
 * @param {number} [index=-1] - The index of the feedback process.
 * @returns {FeedbackProcessProps | undefined} The selected feedback process or undefined.
 */
export const useSelectProcess = (
  index: number = -1
): FeedbackProcessProps | undefined =>
  useAppSelector(
    ({ feedback }) =>
      index > -1
        ? feedback.pdfValues?.[index].data.process
        : feedback.feedbackOverview?.process,
    isSame
  );

/**
 * Selects the feedback process item based on the process item ID.
 * @param {string} processItemId - The ID of the process item.
 * @returns {Partial<Record<ProcessFilterNameType, boolean>>} The selected feedback process item.
 */
export const useSelectFeedbackProcessItem = (
  processItemId: string
): Partial<Record<ProcessFilterNameType, boolean>> =>
  useAppSelector(
    ({ feedback }) => feedback.hiddenProcesses?.[processItemId] || {}
  );

/**
 * Selects the process items from the feedback overview.
 * @returns {(ManagerQuestionProps | FeedbackProcessEvaluationProps)[]} The selected process items.
 */
export const useSelectProcessItems = (): (
  | ManagerQuestionProps
  | FeedbackProcessEvaluationProps
)[] =>
  useAppSelector(
    ({ feedback }) => feedback.feedbackOverview?.process?.data?.items || [],
    isSame
  );

/**
 * Checks if a process is hidden based on the process ID and filter name.
 * @param {string} processId - The ID of the process.
 * @param {ProcessFilterNameType} name - The filter name.
 * @returns {boolean} True if the process is hidden, false otherwise.
 */
export const useSelectIsProcessHidden = (
  processId: string,
  name: ProcessFilterNameType
): boolean =>
  useAppSelector(
    ({ feedback }) => feedback.hiddenProcesses?.[processId]?.[name] || false
  );

/**
 * Selects the full name of the applicant from the feedback overview data.
 * @returns {string} The full name of the applicant or an empty string if not found.
 */
export const useSelectApplicantFullname = (): string =>
  useAppSelector(({ feedback }) => {
    return feedback?.feedbackOverview?.process?.data?.applicantFullname || '';
  });

/**
 * Returns the hidden process sections state.
 *
 * @returns the hidden process sections state.
 */
export const useSelectHiddenProcessSections = () =>
  useAppSelector(({ feedback }) => feedback.hiddenProcesses || {});

/**
 * Returns the process id.
 *
 * @returns the process id.
 */
export const useSelectProcessId = () =>
  useAppSelector(
    ({ feedback }) => feedback.feedbackOverview?.process?.id || ''
  );

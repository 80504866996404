// Custom Hooks
import useMount from 'core/hooks/useMount';
import useAppQuery from 'core/hooks/useAppQuery';
import useUpdateEffect from 'core/hooks/useUpdateEffect';

// Custom Utilities
import { isSucceed } from 'core/utilities/helper';

// Custom Types
import type { UseAppQueryOptionsProps } from 'core/hooks/useAppQuery';
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type { ApiGetSummaryResponse } from 'core/types/api/hook/response';

export type UseSummaryApiReturnProps<T> = DefinedUseQueryResult<T[], Error>;

export interface UseSummaryApiProps<T> {
  options: Omit<
    DefinedInitialDataOptions<T[], Error, T[], QueryKey>,
    'queryFn' | 'initialData' | 'queryKey'
  > & { initialData?: T[] };
}

export interface UseSummaryOptionType<T>
  extends Omit<
    UseAppQueryOptionsProps<T[]>,
    'queryFn' | 'initialData' | 'queryKey' | 'onFetch'
  > {
  id?: string;
  onFetch?: (data: T[]) => void;
  onFetchFailed?: () => void;
  initialData?: T[];
}

function useSummaryApi<T>(
  queryFn: (signal: AbortSignal) => Promise<ApiGetSummaryResponse<T>>,
  queryKey: string[],
  options?: UseSummaryOptionType<T>
): UseSummaryApiReturnProps<T> {
  // Props
  const { onFetch, onFetchFailed, ...otherOptions } = options || {};

  // Hooks
  const query = useAppQuery<T[]>({
    select: (data: any) => {
      return Array.isArray(data)
        ? data
        : Array.isArray(data?.list)
        ? data.list
        : [];
    },
    queryKey,
    queryFn: async ({ signal }) => {
      const { list, status } = await queryFn(signal);
      if (isSucceed(status)) return list || [];
      else throw new Error('ERROR');
    },
    ...otherOptions,
    initialData: options?.initialData || [],
  });

  useMount(() => {
    if (onFetch && query?.data && query?.data.length > 0)
      onFetch(query?.data || []);
  });

  useUpdateEffect(() => {
    if (onFetch) onFetch(query?.data || []);
  }, [query.data]);

  useUpdateEffect(() => {
    if (query.isError && onFetchFailed) onFetchFailed();
  }, [query.isError]);

  return query;
}
export default useSummaryApi;

// Custom Utilities
import { menuEndpoints as endpoints } from 'features/menu/menus/utilities/endpoints';
import apiHandler from 'core/utilities/apiHandler';
import { getDoc, getDocs, getSummary } from 'core/utilities/apiHandler/helper';
import { getPaginate } from 'core/utilities/pagination/pagination';

// Custom Types
import type {
  ApiGetDocResponse,
  ApiGetDocsResponse,
  ApiGetSummaryResponse,
} from 'core/types/api/hook/response';
import type { ApiPaginationProps } from 'core/types/shared/pagination/api';
import type {
  MenuContainerProps,
  MenuContainerSummaryProps,
  MenuItemDataProps,
  MenuItemProps,
  MenuListItemProps,
} from 'features/menu/menus/types';

/**
 * Fetches a list of menus from the API.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request if needed.
 * @param {Record<string, any>} [queries] - An optional object containing query parameters for filtering the menus list.
 * @returns {Promise<ApiGetDocsResponse<MenuListItemProps>>} A promise that resolves to an object containing the status, list of menus, and pagination information.
 */
export const getMenusList = async (
  signal?: AbortSignal,
  queries?: Record<string, any>
): Promise<ApiGetDocsResponse<MenuListItemProps>> => {
  return await getDocs<
    { menus: MenuListItemProps[]; paginate: ApiPaginationProps },
    MenuListItemProps
  >(endpoints.getContainers(), {
    queries,
    signal,
    returnMutationFn: (status, data) => ({
      status,
      list: data?.menus || [],
      page: getPaginate(data?.paginate),
    }),
  });
};

/**
 * Fetches a summary list of menu containers from the API.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request if needed.
 * @returns {Promise<ApiGetSummaryResponse<MenuContainerSummaryProps>>} A promise that resolves to an object containing the status and the list of menu container summaries.
 */
export const getMenuSummaryList = async (
  signal?: AbortSignal
): Promise<ApiGetSummaryResponse<MenuContainerSummaryProps>> => {
  const endpoint = endpoints.getContainersSummary;

  return await getSummary<
    { menus: MenuContainerSummaryProps[]; paginate: ApiPaginationProps },
    MenuContainerSummaryProps
  >(endpoint, {
    signal,
    returnMutationFn: (status, data) => ({ status, list: data?.menus || [] }),
  });
};
/**
 * Adds a new menu container.
 * @param {string} title - The title of the menu container.
 * @returns {Promise<{ status: number; data?: MenuContainerProps }>} The status and data of the newly created menu container.
 */
export const addMenuContainer = async (
  title: string
): Promise<{ status: number; data?: MenuContainerProps }> => {
  const { status, data } = await apiHandler.post<{
    docs: MenuContainerProps;
  }>(endpoints.addContainer, { title });
  return { status, data: data?.docs };
};

/**
 * Retrieves a menu container by its ID.
 * @param {string} id - The ID of the menu container.
 * @param {AbortSignal} [signal] - An optional signal to abort the request.
 * @returns {Promise<ApiGetDocResponse<MenuContainerProps>>} The status and the requested menu container data.
 */
export const getMenu = async (
  id: string,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<MenuContainerProps>> => {
  const endpoint = endpoints.getContainerById(id);

  return await getDoc<MenuContainerProps, MenuContainerProps>(endpoint, {
    signal,
  });
};

/**
 * Updates an existing menu container.
 * @param {string} id - The ID of the menu container to update.
 * @param {string} title - The new title for the menu container.
 * @returns {Promise<{ status: number; data?: MenuContainerProps }>} The status and updated menu container data.
 */
export const updateMenuContainer = async (
  id: string,
  title: string
): Promise<{ status: number; data?: MenuContainerProps }> => {
  const { status, data } = await apiHandler.patch<{
    docs: MenuContainerProps;
  }>(endpoints.updateContainer(id), { title });
  return { status, data: data?.docs };
};

/**
 * Deletes one or more menu containers.
 * @param {string[]} containerIds - An array of IDs of the menu containers to delete.
 * @returns {Promise<{ status: number }>} The status of the delete operation.
 */
export const deleteMenuContainer = async (
  containerIds: string[]
): Promise<{ status: number }> => {
  const { status } = await apiHandler.delete(endpoints.removeContainers, {
    ids: containerIds,
  });
  return { status };
};

/**
 * Deletes one or more menu items.
 * @param {string[]} ids - An array of IDs of the menu items to delete.
 * @returns {Promise<{ status: number }>} The status of the delete operation.
 */
export const deleteMenuItems = async (
  ids: string[]
): Promise<{ status: number }> => {
  const { status } = await apiHandler.delete(endpoints.removeMenuItem, {
    ids,
  });
  return { status };
};

export const addMenuItem = async (payload: {
  containerId: string;
  data: MenuItemDataProps;
}): Promise<{ status: number; data?: MenuItemProps }> => {
  const { status, data } = await apiHandler.post<{
    docs: MenuItemProps;
  }>(endpoints.addMenuItem(payload.containerId), payload.data);
  return { status, data: data?.docs };
};

/**
 * Updates one or more menu items.
 * @param {MenuItemProps[]} items - An array of menu items to update.
 * @returns {Promise<{ status: number; data?: MenuItemProps[] }>} The status and updated menu item data.
 */
export const updateMenuItems = async (
  items: MenuItemProps[]
): Promise<{ status: number; data?: MenuItemProps[] }> => {
  const { status, data } = await apiHandler.patch<{
    docs: MenuItemProps[];
  }>(endpoints.updateMenuItem, {
    items,
  });
  return { status, data: data?.docs };
};

import {
  Unstable_Grid2 as MuiGrid2,
  Grid2Props as MuiGrid2Props,
  styled,
} from '@mui/material';

export interface Grid2Props extends MuiGrid2Props {}

const Grid2 = styled(MuiGrid2)<Grid2Props>(() => ({}));

export default Grid2;

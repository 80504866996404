import * as React from 'react';

// Components
import IconButton from 'core/components/base/inputs/IconButton';
import Tooltip from 'core/components/base/display/Tooltip';

// Icon Components
import ContentCopyIcon from 'core/components/icons/ContentCopy';

// Utilities
import { getAppUserURL } from 'core/utilities/app/info';

import type { IconButtonProps } from 'core/components/base/inputs/IconButton';
import type { TooltipProps } from 'core/components/base/display/Tooltip';
interface CopyToClipProps extends IconButtonProps {
  copyIcon?: JSX.Element;
  disableTooltip?: boolean;
  tooltipProps?: TooltipProps;
  tooltipTitle?: string;
  urlPrefixed?: boolean;
  dontPrefixWithSlash?: boolean;
  prefixText?: string;
  value: string;
}

const CopyToClip: React.FC<CopyToClipProps> = (props) => {
  const {
    copyIcon = <ContentCopyIcon />,
    disableTooltip,
    tooltipTitle = 'کپی کردن',
    tooltipProps,
    dontPrefixWithSlash,
    urlPrefixed,
    prefixText,
    value,
    disabled,
    onClick,
    ...otherProps
  } = props;

  const userUrl = getAppUserURL();
  const [title, setTitle] = React.useState<string>(tooltipTitle);

  const handleCopyToClip = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (onClick) onClick(event);
    const fixedValue = value.startsWith('/')
      ? value
      : `${dontPrefixWithSlash ? '' : '/'}${value}`;
    const prefix = `${urlPrefixed && userUrl ? userUrl : ''}${
      prefixText ? prefixText : ''
    }`;

    const valueToCopy = `${prefix}${fixedValue}`;
    try {
      await navigator.clipboard.writeText(valueToCopy);
      setTitle('کپی شد');
    } catch (error) {}

    setTimeout(() => {
      setTitle(tooltipTitle);
    }, 1500);
  };

  const CopyToClipBase = (
    <IconButton disabled={disabled} onClick={handleCopyToClip} {...otherProps}>
      {copyIcon}
    </IconButton>
  );

  const WithTooltip = (
    <Tooltip title={title} {...tooltipProps}>
      {CopyToClipBase}
    </Tooltip>
  );

  return disabled || disableTooltip ? CopyToClipBase : WithTooltip;
};

export default CopyToClip;

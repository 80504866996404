import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import FormControlLabel from 'core/components/base/inputs/FormControlLabel';
import AntSwitch from 'core/components/shared/Switch/AntSwitch';

// Core Utilities
import { useAppSelector } from 'core/hooks/redux';

// Feature Types
import type { ContentDetailsProps } from 'features/content/contents/types/details';

const ContentDetailsActionsDraft = () => {
  // Hooks
  const loading = useAppSelector((state) => state.loading);
  const { control } = useFormContext<ContentDetailsProps>();

  return (
    <Controller
      control={control}
      name='data.isDraft'
      render={({ field }) => (
        <FormControlLabel
          sx={{
            bgcolor: 'background.paper',
            borderRadius: '8px',
            p: '8px',
            color: field.value ? 'info.main' : 'text.secondary',
            height: '40px',
          }}
          disabled={loading}
          label='پیش‌نویس'
          control={
            <AntSwitch
              sx={{ mr: '8px' }}
              color='info'
              checked={field.value}
              onChange={(e, checked) => field.onChange(checked)}
            />
          }
        />
      )}
    />
  );
};

export default ContentDetailsActionsDraft;

import {
  Link as MuiLink,
  LinkProps as MuiLinkProps,
  styled,
} from '@mui/material';

export interface LinkProps extends MuiLinkProps {
  component?: React.ElementType;
  to?: string;
}

const Link = styled(MuiLink)<LinkProps>(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

Link.defaultProps = {
  underline: 'none',
};

export default Link;

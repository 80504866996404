// Custom Types
import type { HeaderPatternDataProps } from 'features/appBuilder/patterns/features/header/types';

/**
 * Validates the header pattern data and returns an error message if validation fails.
 *
 * @param {HeaderPatternDataProps} data - The header pattern data to validate.
 * @returns {string} A string containing the error message if validation fails, or an empty string if the data is valid.
 */
export const validateHeaderPattern = (data: HeaderPatternDataProps): string => {
  if (!data.patternTitle.trim()) {
    return 'وارد کردن عنوان پترن الزامی می‌باشد.';
  }

  return '';
};

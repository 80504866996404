import { useState, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { cloneDeepWith } from 'lodash';

// Core Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import Box from 'core/components/base/layout/Box';
import KeyboardArrowDownIcon from 'core/components/icons/ArrowDown';
import Menu from 'core/components/base/navigation/Menu';
import MenuItem from 'core/components/base/navigation/MenuItem';

// Core Utilities
import { useAppSelector } from 'core/hooks/redux';
import useSummaryApi from 'core/hooks/api/useSummary';

// Feature Utilities
import { categoriesQueryKey } from 'features/content/categories/hooks';
import { getCategoriesSummaryList } from 'features/content/categories/utilities/api';

// Feature Types
import type { ContentDetailsProps } from 'features/content/contents/types/details';
import type { CategorySummaryProps } from 'features/content/categories/types';

// Component Types
interface ContentDetailsConfigCategoriesProps {}

const ContentDetailsConfigCategories: FC<
  ContentDetailsConfigCategoriesProps
> = () => {
  // Hooks
  const loading = useAppSelector((state) => state.loading);
  const { control, getValues, setValue } =
    useFormContext<ContentDetailsProps>();
  const { data: list } = useSummaryApi(
    getCategoriesSummaryList,
    categoriesQueryKey
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Utilities
  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryClick = (category: CategorySummaryProps) => {
    const currentCategories = cloneDeepWith(getValues('data.categories')) || [];

    const categoryIndex = currentCategories.findIndex(
      (c) => c.id === category.id
    );

    if (categoryIndex > -1) {
      currentCategories.splice(categoryIndex, 1);
    } else {
      currentCategories.push(category);
    }

    setValue('data.categories', currentCategories);
  };

  const isSelected = (category: CategorySummaryProps) => {
    const categories = getValues('data.categories') || [];
    return categories.findIndex((c) => c.id === category.id) > -1;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
        flexGrow: 1,
      }}
    >
      <BodyTwo color='text.secondary'>موضوع</BodyTwo>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          backgroundColor: 'background.default',
          opacity: loading ? 0.6 : 1,
          cursor: loading ? 'auto' : 'pointer',
          p: '0.5rem',
          borderRadius: '0.5rem',
          ':hover': {
            opacity: '0.8',
          },
        }}
        onClick={handleOpen}
      >
        <Controller
          control={control}
          name='data.categories'
          render={({ field }) => (
            <BodyTwo sx={{ p: '0 0.5rem 0 0.25rem' }}>
              {field.value && field.value.length > 1
                ? `${field.value[0].data.title} +${(
                    field.value.length - 1
                  ).toPersian()}`
                : field.value && field.value.length === 1
                ? field.value[0].data.title
                : 'انتخاب'}
            </BodyTwo>
          )}
        />

        <KeyboardArrowDownIcon />
      </Box>
      <Menu
        id='category-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              boxShadow: 'none',
              mt: 1,
              border: '1px solid',
              borderColor: 'divider',
              width: anchorEl?.clientWidth,
            },
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            p: 0,
          },
        }}
      >
        {list?.map((categoryItem) => (
          <MenuItem
            key={categoryItem.id + categoryItem.data.title}
            sx={{ whiteSpace: 'normal' }}
            onClick={() => {
              handleCategoryClick(categoryItem);
              handleClose();
            }}
            selected={isSelected(categoryItem)}
          >
            {categoryItem.data.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ContentDetailsConfigCategories;

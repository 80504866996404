import { FC } from 'react';

// Core Components
import TableCell from 'core/components/base/display/TableCell';

// Types
import type { TableCellProps } from 'core/components/base/display/TableCell';

export interface SheetTableCellProps extends TableCellProps {}

const SheetTableCell: FC<SheetTableCellProps> = ({
  children,
  sx,
  ...otherProps
}) => {
  return (
    <TableCell
      sx={{
        border: `1px solid #C1C8D3`,
        p: 0.5,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </TableCell>
  );
};

export default SheetTableCell;

import type { MouseEvent, FC } from 'react';
import { useNavigate } from 'react-router-dom';

// Core Components
import Button, { type ButtonProps } from 'core/components/base/inputs/Button';

// Custom Types
export interface CancelButtonProps extends ButtonProps {}

const CancelButton: FC<CancelButtonProps> = (props) => {
  // Props
  const { children, onClick, color = 'error', sx, ...otherProps } = props;

  // Hooks
  const navigate = useNavigate();

  // Utilities
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    navigate(-1);
    if (onClick) onClick(event);
  };

  return (
    <Button
      onClick={handleClick}
      color={color}
      sx={{
        bgcolor: 'white',
        width: '6rem',
        ':hover': { bgcolor: 'rgba(255,255,255, 0.8)' },
        ...sx,
      }}
      {...otherProps}
    >
      انصراف
    </Button>
  );
};

export default CancelButton;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface FormatAlignRightIconProps extends SvgIconProps {}

const FormatAlignRightIcon: React.FC<FormatAlignRightIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M19.7634 7.67818H4.23652C4.10589 7.67818 3.99994 7.49447 3.99994 7.34546V4.33271C3.99994 4.14901 4.1058 4 4.23652 4H19.7634C19.894 4 19.9999 4.18371 19.9999 4.33271V7.34546C19.9999 7.52917 19.8941 7.67818 19.7634 7.67818Z' />
    <path d='M20.1656 13.8422H6.23658C6.10595 13.8422 6 13.6585 6 13.5095V10.4968C6 10.3131 6.10586 10.1641 6.23658 10.1641H20.1656C20.2962 10.1641 20.4021 10.3478 20.4021 10.4968V13.5095C20.4021 13.6932 20.2963 13.8422 20.1656 13.8422Z' />
    <path d='M19.962 19.9985H12.2366C12.106 19.9985 12 19.8148 12 19.6658V16.653C12 16.4693 12.1059 16.3203 12.2366 16.3203H19.962C20.0926 16.3203 20.1986 16.504 20.1986 16.653V19.6658C20.1986 19.8495 20.0927 19.9985 19.962 19.9985Z' />
  </IconBuilder>
);

export default FormatAlignRightIcon;

// Pages
import Contents from 'features/content/contents/components/list';
import AddContent from 'features/content/contents/components/details/AddContent';
import EditContent from 'features/content/contents/components/details/EditContent';

// Custom Utilities
import { getFeatureLabel, getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'content';
const subFeature: SubFeatureType = 'contents';
const contentRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/contents',
    Component: Contents,
    feature,
    subFeature,
  },
  {
    sidebarTitle: `ایجاد ${getFeatureLabel(feature)}`,
    path: '/contents/add',
    Component: AddContent,
    feature,
    subFeature,
  },
  {
    path: '/contents/:id',
    Component: EditContent,
    feature,
    subFeature,
  },
];

export default contentRoutes;

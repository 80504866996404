// Custom Hooks
import useDocsApi from 'core/hooks/api/useDocs';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  addTemplateSkin,
  deleteTemplateSkin,
  getTemplateSkinList,
  updateTemplateSkin,
} from 'features/appBuilder/skins/utilities/api';

// Custom Types
import type { TemplateSkinProps } from 'features/appBuilder/skins/types/skin';
import type {
  UseDocsOptionType,
  UseDocsApiReturnProps,
} from 'core/hooks/api/useDocs';

/**
 * Query key for fetching skins in react query.
 *
 * @constant {Array<string>}
 */
export const skinsQueryKey = ['skins'];

/**
 * Custom hook to fetch template skin documents.
 *
 * This hook utilizes the `useDocsApi` hook to retrieve a list of template skins.
 *
 * @param {UseDocsOptionType<TemplateSkinProps>} [options] - Optional configuration options for the API call.
 * @returns {UseDocsApiReturnProps<TemplateSkinProps>} The result of the `useDocsApi` hook.
 */
export const useTemplateSkinDocs = (
  options?: UseDocsOptionType<TemplateSkinProps> | undefined
): UseDocsApiReturnProps<TemplateSkinProps> =>
  useDocsApi<TemplateSkinProps>(getTemplateSkinList, skinsQueryKey, options);

/**
 * Custom hook for deleting skins.
 *
 * @returns {MutationResult<string>} The result of the delete mutation.
 */
export const useDeleteSkinsMutation = useDeleteMutation<string>(
  deleteTemplateSkin,
  skinsQueryKey
);

/**
 * Custom hook for adding a new skin.
 *
 * @returns {MutationResult<string>} The result of the add mutation.
 */
export const useAddSkinMutation = useAddMutation<string>(
  addTemplateSkin,
  skinsQueryKey
);

/**
 * Custom hook for editing an existing skin.
 *
 * @returns {MutationResult<string>} The result of the edit mutation.
 */
export const useEditSkinMutation = useEditMutation<string>(
  updateTemplateSkin,
  skinsQueryKey
);

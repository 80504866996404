import * as React from 'react';

// Core Components
import Typography from 'core/components/base/display/Typography';

// Types
import type { TypographyProps } from 'core/components/base/display/Typography';
export interface HeadingOneProps extends TypographyProps {}

const HeadingOne: React.FC<HeadingOneProps> = (props) => {
  const { children, component, variant = 'h4', ...otherProps } = props;

  return (
    <Typography component='h1' variant={variant} {...otherProps}>
      {children}
    </Typography>
  );
};

export default HeadingOne;

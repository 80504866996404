import debounce from 'lodash/debounce';

// Custom Store
import store from 'core/store';

// Custom Utilities
import { actions } from 'features/form/forms/store/slice';

// Custom Types
import type { FormDataProps } from 'features/form/forms/types/item';
import type { FormActionMode } from 'features/form/forms/types/store';

export const setForm = (form: FormDataProps) => {
  store.dispatch(actions.setForm(form));
};

export const clearForm = () => {
  store.dispatch(actions.resetForm());
};

export const setFormMode = (mode: 'add' | 'edit') => {
  store.dispatch(actions.setMode(mode));
};

export const setFormActionMode = debounce((actionMode: FormActionMode) => {
  const currentActionMode = store.getState().form.actionMode;
  if (currentActionMode !== actionMode) {
    store.dispatch(actions.setActionMode(actionMode));
  }
}, 150);

export const setFormId = (id: string) => {
  store.dispatch(actions.setId(id));
};

import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import SlugTextField from 'core/components/shared/TextField/Slug';

// Feature Types
import type { ContentDetailsProps } from 'features/content/contents/types/details';
import { getErrorAndHelperText } from 'core/utilities/helper/helperPack';

const ContentDetailsActionsSlug = () => {
  // Hooks
  const { control } = useFormContext<ContentDetailsProps>();

  return (
    <Controller
      control={control}
      name='data.slug'
      render={({ field, fieldState }) => (
        <SlugTextField
          fullWidth
          dir='ltr'
          copyValue={`/c/${field.value}`}
          placeholder='slug'
          InputProps={{ sx: { height: '40px' } }}
          {...getErrorAndHelperText(fieldState)}
          {...field}
        />
      )}
    />
  );
};

export default ContentDetailsActionsSlug;

import { memo } from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Type
import type { FC } from 'react';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';

// Context
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';
import {
  toggleFeedbackSectionVisibility,
  useSelectIsInputHidden,
} from 'features/form/feedbacks/store';

// Custom Types
import type { FeedbackSectionProps } from 'features/form/feedbacks/types';

interface ToggleButtonProps {
  section: FeedbackSectionProps;
}

const FilterModeButton: FC<ToggleButtonProps> = (props) => {
  // Props
  const { section } = props;

  // Context
  const loading = useSelectLoading();
  const isHidden = useSelectIsInputHidden(section.id);

  // Hooks
  const { palette } = useTheme();

  // Utilities
  const handleToggle = () => toggleFeedbackSectionVisibility(section.id);

  // Render
  return (
    <Button
      onClick={handleToggle}
      disabled={loading}
      sx={{
        minWidth: '8.125rem',
        maxWidth: 'fit-content',
        fontSize: '1rem',
        fontWeight: '700',
        color: palette.text.primary,
        height: '2.375rem',
        borderRadius: '.25rem',
        padding: '0.313rem 0.75rem',
        textDecoration: isHidden ? 'line-through' : 'none',
        backgroundColor: loading
          ? palette.text.disabled
          : isHidden
          ? '#EBEBEB'
          : '#9ED5FF',
        transition: 'opacity .3s',
        ':hover': {
          textDecoration: isHidden ? 'line-through' : 'none',
          backgroundColor: loading
            ? palette.text.disabled
            : isHidden
            ? '#EBEBEB'
            : '#9ED5FF',
          opacity: '.8',
        },
      }}
    >
      {section?.data?.title || ''}
    </Button>
  );
};

export default memo(FilterModeButton, isSame);

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface FolderIconProps extends SvgIconProps {}

const FolderIcon: React.FC<FolderIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M4.1 6L2 14V4H19C19 3.46957 18.7893 2.96086 18.4142 2.58579C18.0391 2.21071 17.5304 2 17 2H10L8 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H17C17.9 16 18.7 15.4 18.9 14.5L21.2 6H4.1ZM17 14H4L5.6 8H18.6L17 14Z' />
  </IconBuilder>
);

export default FolderIcon;

import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';
import {
  setFeedbackFullWidthInputs,
  setFeedbackHiddenInputs,
  toggleFeedbackHideEmpties,
  toggleFeedbackOnlyRequireds,
  setFeedbackColumnCount,
  useSelectFeedbackColumnCount,
  useSelectFeedbackHideEmpties,
  useSelectFeedbackOnlyRequireds,
  useSelectFeedbackOverview,
  useSelectSections,
  useSelectFullWidthInputs,
  useSelectHiddenInputs,
  useSelectProcessItems,
  useSelectFeedbackToggleMode,
} from 'features/form/feedbacks/store';

// Custom Core Components
import Paper from 'core/components/base/surfaces/Paper';

// Custom Feature Components
import SectionFields from 'features/form/feedbacks/components/details/Actions/Filter/ViewSettings/SectionFields';
import ProcessFields from 'features/form/feedbacks/components/details/Actions/Filter/ViewSettings/ProcessFields';
import ColumnCountSelector from 'features/form/feedbacks/components/details/Actions/Filter/AppearanceSettings/ColumnCountSelector';
import HideEmptiesCheckbox from 'features/form/feedbacks/components/details/Actions/Filter/AppearanceSettings/HideEmptiesCheckbox';
import OnlyRequiredCheckbox from 'features/form/feedbacks/components/details/Actions/Filter/AppearanceSettings/OnlyRequiredsCheckbox';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Utilities
import { forLoop } from 'core/utilities/helper';

// Custom Types
interface FilterProps {}

const Filter: React.FC<FilterProps> = (props) => {
  // Context
  const feedbackOverview = useSelectFeedbackOverview();
  const sections = useSelectSections();
  const fullWidthInputs = useSelectFullWidthInputs();
  const hiddenInputs = useSelectHiddenInputs();
  const hideEmpties = useSelectFeedbackHideEmpties();
  const onlyRequireds = useSelectFeedbackOnlyRequireds();
  const columnCount = useSelectFeedbackColumnCount();
  const processItems = useSelectProcessItems();
  const toggleMode = useSelectFeedbackToggleMode();

  // States
  const isInitialized = useBoolean();

  // Hooks
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (isInitialized.state || !feedbackOverview) return;
    initializeFilter();
  }, [isInitialized.state, feedbackOverview?.feedback.id]);

  React.useEffect(() => {
    if (!isInitialized.state) return;
    if (onlyRequireds) searchParams.set('onlyRequireds', 'true');
    else searchParams.delete('onlyRequireds');

    setSearchParams(searchParams, { replace: true });
  }, [onlyRequireds]);

  React.useEffect(() => {
    if (!isInitialized.state) return;
    if (hideEmpties) searchParams.set('hideEmpties', 'true');
    else searchParams.delete('hideEmpties');

    setSearchParams(searchParams, { replace: true });
  }, [hideEmpties]);

  React.useEffect(() => {
    if (!isInitialized.state) return;

    if (columnCount > 1) searchParams.set('column', columnCount.toString());
    else searchParams.delete('column');

    setSearchParams(searchParams, { replace: true });
  }, [columnCount]);

  React.useEffect(() => {
    if (!isInitialized.state) return;

    searchParams.delete('fullWidth');
    searchParams.delete('hide');

    Object.keys(fullWidthInputs).forEach((id) => {
      searchParams.append('fullWidth', id);
    });

    Object.keys(hiddenInputs).forEach((id) => {
      searchParams.append('hide', id);
    });

    setSearchParams(searchParams, { replace: true });
  }, [hiddenInputs, fullWidthInputs]);

  // Utilities
  const initializeFilter = () => {
    const columnCount = searchParams.get('column');
    const onlyRequireds =
      searchParams.get('onlyRequireds') === 'true' ? true : false;
    const hideEmpties =
      searchParams.get('hideEmpties') === 'true' ? true : false;

    const hideIds: Record<string, boolean> = {};

    const fullWidthIds: Record<string, boolean> = {};

    forLoop(searchParams.getAll('hide'), (id) => (hideIds[id] = true));
    forLoop(
      searchParams.getAll('fullWidth'),
      (id) => (fullWidthIds[id] = true)
    );

    if (Object.keys(hideIds).length > 0) setFeedbackHiddenInputs(hideIds);
    if (Object.keys(fullWidthIds).length > 0)
      setFeedbackFullWidthInputs(fullWidthIds);
    if (onlyRequireds) toggleFeedbackOnlyRequireds(true);
    if (hideEmpties) toggleFeedbackHideEmpties(true);
    if (columnCount && parseInt(columnCount))
      setFeedbackColumnCount(parseInt(columnCount));
    isInitialized.setTrue();
  };

  // Memoized Sections
  const ColumnCount = React.useCallback(() => <ColumnCountSelector />, []);
  const HideEmpties = React.useCallback(() => <HideEmptiesCheckbox />, []);
  const OnlyRequireds = React.useCallback(() => <OnlyRequiredCheckbox />, []);

  // Render
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        boxShadow: 'none',
        padding: '3rem 1rem',
        width: '100%',
      }}
    >
      {sections &&
        sections.map((section) => (
          <SectionFields
            section={section}
            key={`section-fields-${section.id}`}
          />
        ))}
      {toggleMode === 'visibility' &&
        processItems &&
        processItems.map((item) => (
          <ProcessFields item={item} key={`process-filter-${item.id}`} />
        ))}
      {toggleMode === 'width' && (
        <RowStack>
          <ColumnCount />
          <HideEmpties />
          <OnlyRequireds />
        </RowStack>
      )}
    </Paper>
  );
};

export default Filter;

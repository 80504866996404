import { type FC } from 'react';
import { alpha, useTheme } from '@mui/material';

// Custom Core Components
import Box, { type BoxProps } from 'core/components/base/layout/Box';

// Custom Types
export interface UploaderInputAndPreviewWrapperProps extends BoxProps {
  disabled?: boolean;
}

const UploaderInputAndPreviewWrapper: FC<
  UploaderInputAndPreviewWrapperProps
> = (props) => {
  // Props
  const {
    children,
    disabled,
    sx,
    width = '6rem',
    height = '5rem',
    ...otherProps
  } = props;

  // Hooks
  const { palette } = useTheme();

  // Styles

  let color = disabled
    ? palette.text.disabled
    : alpha(palette.text.secondary, 0.7);
  let hoverColor = disabled ? palette.text.disabled : palette.text.secondary;

  return (
    <Box
      width={width}
      height={height}
      sx={{
        display: 'flex',
        color,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '0.5rem',
        border: '2px dashed',
        borderColor: color,
        gap: 0.5,
        cursor: disabled ? 'auto' : 'pointer',
        ':hover': {
          borderColor: hoverColor,
          color: hoverColor,
        },
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
};

export default UploaderInputAndPreviewWrapper;

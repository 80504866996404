import { useState, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import MenuItem from 'core/components/base/navigation/MenuItem';

// Common Components
import Select, { type SelectProps } from 'core/components/shared/Select';

// Custom Types
import type { PatternRefType } from 'features/appBuilder/patterns/core/types/item/common';
export interface PatternRefTypeDropdownProps
  extends Omit<SelectProps, 'defaultValue'> {
  reference: string;
  keyName?: string;
  defaultValue?: PatternRefType;
  onRefTypeChange?: (type: PatternRefType) => void;
}

type SelectOption = { value: PatternRefType; label: string };
const options: SelectOption[] = [
  { value: 'tag', label: 'برچسب‌' },
  { value: 'category', label: 'موضوع' },
  { value: 'banner', label: 'بنر' },
];

const PatternRefTypeDropdown: FC<PatternRefTypeDropdownProps> = (props) => {
  // Props
  const {
    reference,
    keyName = 'refType',
    defaultValue = 'default',
    sx,
    onRefTypeChange,
    ...otherProps
  } = props;

  // Hooks
  const { control, setValue } = useFormContext();

  return (
    <Controller
      control={control}
      name={`${reference}.${keyName}`}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Select sx={{ height: '48px', ...sx }} size='small' {...field}>
          {options.map((opt) => (
            <MenuItem
              key={opt.label + opt.value}
              value={opt.value}
              onClick={() => {
                setValue(field.name, opt.value);

                onRefTypeChange && onRefTypeChange(opt.value);
              }}
            >
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

export default PatternRefTypeDropdown;

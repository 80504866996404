import { memo, type FC } from 'react';
import { useFormContext, Controller, SubmitHandler } from 'react-hook-form';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';

// Core Components
import Box from 'core/components/base/layout/Box';

// Custom Components
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import SpacedBox from 'core/components/shared/Box/SpacedBox';
import RowStack from 'core/components/shared/Stack/RowStack';
import GhostTextField from 'core/components/shared/ResizableTextField';

// Feature Components
import DeleteIconButton from 'features/form/shared/components/iconButton/Delete';
import SaveLoadingButton from 'features/form/shared/components/loadingButton/Save';
import ConfigIconButton from 'features/form/processes/components/details/shared/ConfigIconButton';
import ProcessItemStatusSelect from 'features/form/processes/components/details/shared/StatusSelect';
import ProcessCancelButton from 'features/form/processes/components/details/shared/Action/CancelButton';

// Core Utilities
import { isSame } from 'core/utilities/helper/helperPack';

// Context
import { useDialogContext } from 'core/store/context/DialogProvider';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import {
  useSelectProcessItemIsEditable,
  useSelectProcessItemIsFocused,
} from 'features/form/processes/store/selector';

// Feature Utilities
import { updateProcessFocus } from 'features/form/processes/store/actions';
import { isManagerQuestionDraft } from 'features/form/processes/utilities/helper/managerQuestion';
import {
  useAddProcessItemMutation,
  useDeleteProcessItemMutation,
  useEditProcessItemMutation,
} from 'features/form/processes/hooks';

// Feature Types
import type { ProcessProps } from 'features/form/processes/types/details';
import type { ManagerQuestionProps } from 'features/form/processes/types/details/managerQuestion';

// Custom Types
export interface ProcessManagerQuestionSectionProps {
  index: number;
  onDelete: (index: number) => void;
}

const ProcessManagerQuestionSection: FC<ProcessManagerQuestionSectionProps> = (
  props
) => {
  // Props
  const { index, onDelete } = props;

  // States
  const isEditMode = useBoolean();

  // Context
  const { onOpenDialog, onCloseDialog } = useDialogContext();
  const { control, handleSubmit, getValues, setValue, reset, resetField } =
    useFormContext<ProcessProps>();
  const processId = getValues('id');
  const managerQuestionId = getValues(`data.items.${index}.id`);
  const isDraft = isManagerQuestionDraft(managerQuestionId);
  const loading = useSelectLoading();
  const isEditable = useSelectProcessItemIsEditable(managerQuestionId);
  const isFocused = useSelectProcessItemIsFocused(managerQuestionId);

  // Hooks
  const { mutate: handleAddProcessItem } = useAddProcessItemMutation({
    autoAlert: { mode: 'create', name: 'پرسش' },
    onSuccess: ({ docs }) => {
      reset({
        id: getValues('id'),
        data: {
          ...getValues('data'),
          items: docs,
        },
      });
      updateProcessFocus('');
    },
  });
  const { mutate: handleEditProcessItem } = useEditProcessItemMutation({
    autoAlert: { mode: 'edit', name: 'پرسش' },
    onSuccess: ({ docs }) => {
      reset({
        id: getValues('id'),
        data: {
          ...getValues('data'),
          items: docs,
        },
      });

      updateProcessFocus('');
    },
  });
  const { mutate: handleDeleteProcessItem } = useDeleteProcessItemMutation({
    autoAlert: { mode: 'delete', name: 'پرسش' },
    onSuccess: ({ docs }) => {
      reset({
        id: getValues('id'),
        data: {
          ...getValues('data'),
          items: docs,
        },
      });
      onCloseDialog();
      updateProcessFocus('');
    },
  });

  // Utilities
  const handleCancel = () => {
    updateProcessFocus('');
    if (isDraft) onDelete(index);
    else resetField(`data.items.${index}`);
  };

  const handleEditMode = () => {
    if (isEditMode.state) updateProcessFocus('');
    else updateProcessFocus(managerQuestionId);

    isEditMode.toggle();
  };

  const handleFormSubmit: SubmitHandler<ProcessProps> = async (process) => {
    const item = process.data.items[index];

    if (item.data.processItemType === 'managerQuestion') {
      const isDraft = isManagerQuestionDraft(item.id);
      const managerQuestion = item as ManagerQuestionProps;

      if (isDraft) {
        handleAddProcessItem({ processId, processItem: managerQuestion });
      } else {
        handleEditProcessItem({ id: processId, data: managerQuestion });
      }
    }
  };

  const handleOpenDeleteDialog = () => {
    onOpenDialog({
      title: `آیا مطمئن هستید؟`,
      description: ` با حذف پرسش، اطلاعات ثبت شده در این پرسش در بازخوردها باقی خواهند ماند اما در بازخوردهای بعدی، این پرسش وجود نخواهد داشت.`,
      onSubmit: () =>
        handleDeleteProcessItem(getValues(`data.items.${index}.id`)),
    });
  };

  // Render
  return (
    <RoundPaper sx={{ p: '1.5rem', overflowX: 'hidden' }}>
      <SpacedBox>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Controller
            control={control}
            name={`data.items.${index}.data.title`}
            rules={{
              onChange: () => updateProcessFocus(managerQuestionId),
            }}
            render={({ field }) => {
              const error =
                field.value.length > 0 ? '' : 'عنوان پرسش الزامی است.';

              return (
                <GhostTextField
                  disabled={loading || !isEditable}
                  error={error ? true : false}
                  helperText={error}
                  fullWidth={false}
                  placeholder='عنوان پرسش'
                  variant='standard'
                  sx={{
                    width:
                      field.value.length > 50
                        ? '70ch'
                        : field.value.length > 20
                        ? field.value.length + 'ch'
                        : '20ch',
                  }}
                  {...field}
                />
              );
            }}
          />
          <Controller
            control={control}
            name={`data.items.${index}.data.status`}
            render={({ field: statusField }) => (
              <Controller
                control={control}
                name={`data.items.${index}.data.linkedId`}
                render={({ field: linkedIdField }) => (
                  <Controller
                    control={control}
                    name={`data.items.${index}.data.linkedTitle`}
                    render={({ field: linkedTitleField }) => (
                      <ProcessItemStatusSelect
                        defaultValue={
                          statusField.value === 'end'
                            ? 'end'
                            : linkedIdField.value
                        }
                        currentItemId={managerQuestionId}
                        disabled={loading || !isEditable}
                        label={
                          statusField.value && statusField.value !== 'end'
                            ? linkedTitleField.value || linkedIdField.value
                            : 'پایان'
                        }
                        onChange={(status, optId, optTitle) => {
                          updateProcessFocus(managerQuestionId);
                          setValue(statusField.name, status);
                          setValue(linkedIdField.name, optId);
                          setValue(linkedTitleField.name, optTitle);
                        }}
                      />
                    )}
                  />
                )}
              />
            )}
          />
        </Box>
        <RowStack>
          {isEditMode.state && !isDraft && (
            <DeleteIconButton
              disabled={!isEditable}
              onClick={handleOpenDeleteDialog}
              disableRipple={false}
            />
          )}
          <ConfigIconButton disabled={!isEditable} onClick={handleEditMode} />
        </RowStack>
      </SpacedBox>
      <Box sx={{ mt: '1rem', mb: '2rem' }}>
        <Controller
          control={control}
          name={`data.items.${index}.data.questionTitle`}
          rules={{
            onChange: () => updateProcessFocus(managerQuestionId),
          }}
          render={({ field }) => {
            const error = field.value.length > 0 ? '' : 'متن پرسش الزامی است.';

            return (
              <GhostTextField
                disabled={loading || !isEditable}
                error={error ? true : false}
                helperText={error}
                multiline
                placeholder='متن پرسش'
                variant='standard'
                {...field}
              />
            );
          }}
        />
      </Box>

      <Box sx={{ display: 'flex', gap: 1, width: 'fit-content', ml: 'auto' }}>
        <ProcessCancelButton
          isDraft={isDraft}
          itemId={managerQuestionId}
          onCancel={handleCancel}
        />
        <Controller
          control={control}
          name={`data.items.${index}.data.title`}
          render={({ field: titleField }) => (
            <Controller
              control={control}
              name={`data.items.${index}.data.questionTitle`}
              render={({ field: questionTitleField }) => (
                <SaveLoadingButton
                  onClick={handleSubmit(handleFormSubmit)}
                  disabled={
                    !titleField.value || !questionTitleField.value || !isFocused
                  }
                  sx={{ p: '0.5rem', borderRadius: '0.5rem' }}
                  variant='outlined'
                />
              )}
            />
          )}
        />
      </Box>
    </RoundPaper>
  );
};

export default memo(ProcessManagerQuestionSection, isSame);

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface FormatAlignJustifyIconProps extends SvgIconProps {}

const FormatAlignJustifyIcon: React.FC<FormatAlignJustifyIconProps> = (
  props
) => (
  <IconBuilder {...props}>
    <path d='M19.7634 7.67818H4.23652C4.10589 7.67818 3.99994 7.49447 3.99994 7.34546V4.33271C3.99994 4.14901 4.1058 4 4.23652 4H19.7634C19.894 4 19.9999 4.18371 19.9999 4.33271V7.34546C19.9999 7.52917 19.8941 7.67818 19.7634 7.67818Z' />
    <path d='M19.7634 13.6782H4.23652C4.10589 13.6782 3.99994 13.4945 3.99994 13.3455V10.3327C3.99994 10.149 4.1058 10 4.23652 10H19.7634C19.894 10 19.9999 10.1837 19.9999 10.3327V13.3455C19.9999 13.5292 19.8941 13.6782 19.7634 13.6782Z' />
    <path d='M19.7634 19.6782H4.23652C4.10589 19.6782 3.99994 19.4945 3.99994 19.3455V16.3327C3.99994 16.149 4.1058 16 4.23652 16H19.7634C19.894 16 19.9999 16.1837 19.9999 16.3327V19.3455C19.9999 19.5292 19.8941 19.6782 19.7634 19.6782Z' />
  </IconBuilder>
);

export default FormatAlignJustifyIcon;

import { useState, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { cloneDeepWith } from 'lodash';

// Core Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import Box from 'core/components/base/layout/Box';
import KeyboardArrowDownIcon from 'core/components/icons/ArrowDown';
import Menu from 'core/components/base/navigation/Menu';
import MenuItem from 'core/components/base/navigation/MenuItem';

// Core Utilities
import { useAppSelector } from 'core/hooks/redux';
import useSummaryApi from 'core/hooks/api/useSummary';

// Feature Utilities
import { tagsQueryKey } from 'features/content/tags/hooks';
import { getTagsSummaryList } from 'features/content/tags/utilities/api';

// Feature Types
import type { ContentDetailsProps } from 'features/content/contents/types/details';
import type { TagSummaryProps } from 'features/content/tags/types';

// Component Types
interface ContentDetailsConfigTagsProps {}

const ContentDetailsConfigTags: FC<ContentDetailsConfigTagsProps> = () => {
  // Hooks
  const loading = useAppSelector((state) => state.loading);
  const { control, getValues, setValue } =
    useFormContext<ContentDetailsProps>();
  const { data: list } = useSummaryApi(getTagsSummaryList, tagsQueryKey);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Utilities
  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTagClick = (tag: TagSummaryProps) => {
    const currentTags = cloneDeepWith(getValues('data.tags')) || [];

    const tagIndex = currentTags.findIndex((t) => t.id === tag.id);

    if (tagIndex > -1) {
      currentTags.splice(tagIndex, 1);
    } else {
      currentTags.push(tag);
    }

    setValue('data.tags', currentTags);
  };

  const isSelected = (tag: TagSummaryProps) => {
    const tags = getValues('data.tags') || [];
    return tags.findIndex((t) => t.id === tag.id) > -1;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
      }}
    >
      <BodyTwo color='text.secondary'>برچسب</BodyTwo>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          backgroundColor: 'background.default',
          opacity: loading ? 0.6 : 1,
          cursor: loading ? 'auto' : 'pointer',
          p: '0.5rem',
          borderRadius: '0.5rem',
          ':hover': {
            opacity: '0.8',
          },
        }}
        onClick={handleOpen}
      >
        <Controller
          control={control}
          name='data.tags'
          render={({ field }) => (
            <BodyTwo sx={{ p: '0 0.5rem 0 0.25rem' }}>
              {field.value && field.value.length > 1
                ? `${field.value[0].data.title} +${(
                    field.value.length - 1
                  ).toPersian()}`
                : field.value && field.value.length === 1
                ? field.value[0].data.title
                : 'انتخاب'}
            </BodyTwo>
          )}
        />

        <KeyboardArrowDownIcon />
      </Box>
      <Menu
        id='tag-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              boxShadow: 'none',
              mt: 1,
              border: '1px solid',
              borderColor: 'divider',
              width: anchorEl?.clientWidth,
            },
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            p: 0,
          },
        }}
      >
        {list?.map((tagItem) => (
          <MenuItem
            key={tagItem.id + tagItem.data.title}
            sx={{ whiteSpace: 'normal' }}
            onClick={() => {
              handleTagClick(tagItem);
              handleClose();
            }}
            selected={isSelected(tagItem)}
          >
            {tagItem.data.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ContentDetailsConfigTags;

import {
  Slide as MuiSlide,
  SlideProps as MuiSlideProps,
  styled,
} from '@mui/material';

export interface SlideProps extends MuiSlideProps {}

const Slide = styled(MuiSlide)<SlideProps>(() => ({}));

export default Slide;

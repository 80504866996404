import { useCallback, useEffect, type FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';
import Select from 'core/components/shared/Select/SelectWithPlaceholder';
import MenuItem from 'core/components/base/navigation/MenuItem';

// Feature Types
import type { ProcessEvaluationItemSummaryProps } from 'features/form/processes/types/list/item';
import type { FormFeedbacksListFilterState } from 'features/form/feedbacks/components/list/Actions/Filter';

// Custom Types
export interface FeedbacksEvaluationFilterProps {
  evaluations?: ProcessEvaluationItemSummaryProps[];
}

const FeedbacksEvaluationFilter: FC<FeedbacksEvaluationFilterProps> = (
  props
) => {
  // Props
  const { evaluations = [] } = props;

  // Context
  const { control, getValues, setValue } =
    useFormContext<FormFeedbacksListFilterState>();

  // Hooks
  const [searchParams] = useSearchParams();

  // Utilities
  const initPrevEvaluationsFilters = useCallback(() => {
    const itemsToSet: ProcessEvaluationItemSummaryProps[] = [];

    const searchParamsArray = [...searchParams.entries()];

    searchParamsArray.forEach((param) => {
      const [key, value] = param;

      if (key.includes('pid_')) {
        const evaluationId = key.replace('pid_', '');

        const evaluation = evaluations.find(
          (evaluation) => evaluation.id === evaluationId
        );

        if (evaluation) {
          itemsToSet.push({
            id: evaluation.id,
            data: {
              ...evaluation.data,
              selectedId: value,
            },
          });
        }
      }
    });

    setValue('evaluationItems', itemsToSet);
  }, [searchParams]);

  useEffect(() => {
    initPrevEvaluationsFilters();
  });

  // Utilities
  const handleOptionClick = (option: ProcessEvaluationItemSummaryProps) => {
    const currentSelections = [...getValues('evaluationItems')];

    const optIndex = currentSelections.findIndex((opt) => opt.id === option.id);

    if (optIndex > -1 && currentSelections[optIndex].data.selectedId) {
      currentSelections.splice(optIndex, 1);
    } else {
      currentSelections.push(option);
    }

    setValue('evaluationItems', currentSelections);
  };

  const handleValue = (index: number, optId: string) => {
    const currentSelections = [...getValues('evaluationItems')];

    currentSelections[index] = {
      id: currentSelections[index].id,
      data: {
        ...currentSelections[index].data,
        selectedId: optId,
      },
    };

    setValue('evaluationItems', currentSelections);
  };

  return (
    <Controller
      control={control}
      name='evaluationItems'
      render={({ field }) => (
        <Box sx={{ width: '100%', display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          {field.value.map((f, fIndex) => (
            <Box key={f.id} sx={{ width: '48%', flexShrink: 0 }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Select
                  fullWidth
                  placeholder='انتخاب ارزیابی'
                  sx={{ height: '2.5rem' }}
                  value={f.id}
                >
                  {evaluations.map((opt) => (
                    <MenuItem
                      disableRipple
                      key={opt.id}
                      onClick={() => handleOptionClick(opt)}
                      value={opt.id}
                      sx={{
                        color: opt.id === f.id ? 'info.main' : 'inherit',
                      }}
                    >
                      {opt.data.title}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  fullWidth
                  placeholder='انتخاب گزینه'
                  sx={{ height: '2.5rem' }}
                  defaultValue=''
                  value={f.data.selectedId}
                >
                  {f.data.questionOptions.map((qOpt) => (
                    <MenuItem
                      disableRipple
                      key={qOpt.id}
                      value={qOpt.id}
                      onClick={() => handleValue(fIndex, qOpt.id)}
                      sx={{
                        color:
                          qOpt.id === f.data.selectedId
                            ? 'info.main'
                            : 'inherit',
                      }}
                    >
                      {qOpt.data.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          ))}
          {((evaluations.length >= 3 && field.value.length < 3) ||
            (evaluations.length < 3 &&
              field.value.length < evaluations.length)) && (
            <Box sx={{ width: '48%', flexShrink: 0 }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Select
                  fullWidth
                  placeholder='انتخاب ارزیابی'
                  sx={{ height: '2.5rem' }}
                >
                  {evaluations.map((opt) => (
                    <MenuItem
                      disableRipple
                      key={opt.id}
                      onClick={() => handleOptionClick(opt)}
                    >
                      {opt.data.title}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  fullWidth
                  disabled
                  placeholder='انتخاب گزینه'
                  sx={{ height: '2.5rem' }}
                ></Select>
              </Box>
            </Box>
          )}
        </Box>
      )}
    />
  );
};

export default FeedbacksEvaluationFilter;

import * as React from 'react';
import { debounce } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

// Type
import type { MouseEvent, FormEvent } from 'react';

// Custom Core Components
import InputAdornment from 'core/components/base/inputs/InputAdornment';
import TextField from 'core/components/base/inputs/TextField';
import IconButton from 'core/components/base/inputs/IconButton';
import Box from 'core/components/base/layout/Box';

// Custom Common Components
import CloseIconButton from 'core/components/shared/IconButton/Close';

// Custom Icons
import SearchIcon from 'core/components/icons/Search';

// Custom Types
import type { StandardTextFieldProps } from '@mui/material';
import useUpdateEffect from 'core/hooks/useUpdateEffect';
interface SearchProps extends Omit<StandardTextFieldProps, 'onChange'> {
  onChange?: (search: string) => void;
  onFilter?: (search?: string) => void;
  queryName?: string;
}

interface FilterFormProps {
  search: string;
}

type EventType = FormEvent<HTMLDivElement> | MouseEvent<HTMLButtonElement>;

const Search: React.FC<SearchProps> = (props) => {
  // Props
  const {
    onChange,
    onFilter,
    disabled,
    queryName = 'search',
    InputProps,
    ...otherProps
  } = props;

  // Hooks
  const { control, setValue, getValues } = useForm<FilterFormProps>();
  const [searchParams] = useSearchParams();

  // Utilities
  const value = searchParams.get(queryName) || '';

  useUpdateEffect(() => setValue('search', value), [value]);

  const handleChange = (value: string) => {
    if (onChange) onChange(value);
    if (value.length === 0 && onFilter) onFilter();
  };

  const handleSearch = (e?: EventType) => {
    if (e) e.preventDefault();
    if (onFilter) onFilter(getValues('search'));
  };

  const debouncedUpdate = debounce(handleChange, 400);

  // Render
  return (
    <Box component='form' onSubmit={handleSearch}>
      <Controller
        control={control}
        name='search'
        rules={{
          onChange: (e) => debouncedUpdate(e.target.value as string),
        }}
        render={({ field }) => (
          <TextField
            placeholder='جستجو'
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {field.value && (
                    <CloseIconButton onClick={() => handleChange('')} />
                  )}
                  <IconButton onClick={handleSearch} disabled={disabled}>
                    <SearchIcon fontSize='inherit' />
                  </IconButton>
                </InputAdornment>
              ),
              ...InputProps,
              style: {
                paddingLeft: '0.25rem',
                ...InputProps?.style,
              },
            }}
            {...field}
            {...otherProps}
          />
        )}
      />
    </Box>
  );
};

export default Search;

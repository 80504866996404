import type { FC } from 'react';

// Feature Shared Components
import PatternItemContainer, {
  type PatternItemContainerProps,
} from 'features/appBuilder/patterns/core/components/shared/items/Container';

// Custom Types
export interface PatternTitleItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
}

const PatternEmptyItem: FC<PatternTitleItemProps> = (props) => {
  // Props
  const { reference, ...otherProps } = props;

  return <PatternItemContainer reference={reference} {...otherProps} />;
};

export default PatternEmptyItem;

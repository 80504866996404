import { FC } from 'react';
import { Editor } from '@tiptap/react';

// Core Components
import FormatBold from 'core/components/icons/FormatBold';
import IconButton from 'core/components/base/inputs/IconButton';

// Component Types
interface EditorBoldIconButtonProps {
  editor: Editor | null;
}

const EditorBoldIconButton: FC<EditorBoldIconButtonProps> = (props) => {
  // Props
  const { editor } = props;

  // Utilities
  const toggleBold = () => {
    editor?.chain().focus().toggleBold().run();
  };

  return (
    <IconButton
      onClick={toggleBold}
      color={editor?.isActive('bold') ? 'info' : 'inherit'}
      size='small'
    >
      <FormatBold />
    </IconButton>
  );
};

export default EditorBoldIconButton;

import * as React from 'react';

// Custom Feature Components
import InputModeButton from './SectionInputModeButton';

// Custom Utilities
import { useSelectSectionInputs } from 'features/form/feedbacks/store';

// Custom Types
interface SectionInputsButtonProps {
  sectionId: string;
}

const SectionInputsButton: React.FC<SectionInputsButtonProps> = (props) => {
  // Props
  const { sectionId } = props;

  // Context
  const inputs = useSelectSectionInputs(sectionId);

  // Render
  return (
    <>
      {inputs.length > 0 &&
        inputs.map((input) => (
          <InputModeButton
            input={input}
            key={`section-${sectionId}-input-${input.id}`}
          />
        ))}
    </>
  );
};

export default SectionInputsButton;

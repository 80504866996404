// Custom Types
import type { ApiCustomPageBodyProps } from 'features/appBuilder/customPages/types/body/api';
import type { ApiCustomPageProps } from 'features/appBuilder/customPages/types/api';
import type { CustomPageProps } from 'features/appBuilder/customPages/types';
import type { CustomPageBodyProps } from 'features/appBuilder/customPages/types/body';
import type { PatternInBodyProps } from 'features/appBuilder/patterns/types/inBody';

export const transformCustomPageBody = (
  apiBody: ApiCustomPageBodyProps
): CustomPageBodyProps => {
  const {
    headers: header,
    footers: footer,
    contents: content,
    profiles: profile,
    stables: stable,
    groups: group,
    tables: table,
    sliders: slider,
    ...otherBodyData
  } = apiBody.data;

  const patterns: PatternInBodyProps[] = [];

  if (header) {
    header.forEach((p) => patterns.push(p));
  }
  if (footer) {
    footer.forEach((p) => patterns.push(p));
  }
  if (content) {
    content.forEach((p) => patterns.push(p));
  }
  if (profile) {
    profile.forEach((p) => patterns.push(p));
  }
  if (stable) {
    stable.forEach((p) => patterns.push(p));
  }
  if (group) {
    group.forEach((g) => patterns.push(g));
  }
  if (table) {
    table.forEach((t) => patterns.push(t));
  }
  if (slider) {
    slider.forEach((s) => patterns.push(s));
  }

  return {
    id: apiBody.id,
    data: {
      ...otherBodyData,
      patterns,
    },
  };
};

export const transformCustomPage = (
  page: ApiCustomPageProps
): CustomPageProps => {
  const transformedBodies: CustomPageBodyProps[] = [];

  page.data.bodies.forEach((b) =>
    transformedBodies.push(transformCustomPageBody(b))
  );

  return {
    id: page.id,
    data: {
      isActive: page.data.isActive,
      slug: page.data.slug,
      title: page.data.title,
      bodies: transformedBodies,
    },
  };
};

import type { FC } from 'react';

// Core Components
import Button, { ButtonProps } from 'core/components/base/inputs/Button';
import AlbumWithCheckIcon from 'core/components/icons/AlbumWithCheck';

// Custom Types
export interface ProcessItemButtonProps extends ButtonProps {}

const ProcessItemButtonProps: FC<ProcessItemButtonProps> = (props) => {
  // Props
  const { children, sx, ...otherProps } = props;

  return (
    <Button
      color='info'
      sx={{ bgcolor: 'background.paper', borderRadius: '0.5rem', ...sx }}
      startIcon={<AlbumWithCheckIcon />}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export default ProcessItemButtonProps;

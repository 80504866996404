import { type FC } from 'react';

// Core Components
import IconButton, {
  type IconButtonProps,
} from 'core/components/base/inputs/IconButton';

// Icon Components
import ConfigIcon from 'core/components/icons/Config';

// Types
interface StylesIconButtonProps extends IconButtonProps {}

const StylesIconButton: FC<StylesIconButtonProps> = (props) => {
  const { children, ...otherProps } = props;

  return (
    <IconButton {...otherProps}>
      <ConfigIcon color='inherit' fontSize='inherit' />
    </IconButton>
  );
};

export default StylesIconButton;

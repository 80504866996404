// Custom Utilities
import { generateObjectId } from 'core/utilities/helper/id';
import { getInitialCommonInputProps } from 'features/form/forms/utilities/input/initial/common';

// Custom Types
import type { FormInputInitialParams } from 'features/form/forms/utilities/input/initial/common';
import type { FormNationalIdInputProps } from 'features/form/forms/types/item/input/nationalid';

interface Params extends FormInputInitialParams {}

export const genFormNationalIdInput = (
  params: Params
): FormNationalIdInputProps => {
  const commonProps = getInitialCommonInputProps({
    ...params,
  });

  return {
    id: `draft-${generateObjectId()}`,
    data: {
      ...commonProps,
      label: 'کد ملی',
      type: 'nationalId',
    },
  };
};

import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  styled,
} from '@mui/material';

export interface SelectProps extends MuiSelectProps {}

const Select = styled(MuiSelect)<SelectProps>(() => ({}));

export default Select;

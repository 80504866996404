import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface HotCoffeeIconProps extends SvgIconProps {}

const HotCoffeeIcon: React.FC<HotCoffeeIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M6,6.6c-0.5,0-1-0.4-1-1V2.8c0-0.5,0.4-1,1-1s1,0.4,1,1v2.9C7,6.1,6.6,6.6,6,6.6z' />
    <path d='M9.6,6.6c-0.5,0-1-0.4-1-1V2.8c0-0.5,0.4-1,1-1s1,0.4,1,1v2.9C10.6,6.1,10.1,6.6,9.6,6.6z' />
    <path d='M13.2,6.6c-0.5,0-1-0.4-1-1V2.8c0-0.5,0.4-1,1-1s1,0.4,1,1v2.9C14.1,6.1,13.7,6.6,13.2,6.6z' />
    <path
      d='M13.4,22.2H5.8c-2.6,0-4.8-2.1-4.8-4.8V8.9c0-0.5,0.4-1,1-1h15.2c0.5,0,1,0.4,1,1v8.6C18.2,20.1,16.1,22.2,13.4,22.2z
M3,9.8v7.6c0,1.6,1.3,2.9,2.9,2.9h7.6c1.6,0,2.9-1.3,2.9-2.9V9.8H3z'
    />
    <path
      d='M18.2,17.4h-1c-0.5,0-1-0.4-1-1s0.4-1,1-1h1c1.6,0,2.9-1.3,2.9-2.9c0-1.6-1.3-2.8-2.8-2.9l-1,0c-0.5,0-1-0.4-1-1s0.4-1,1-1
h1c2.6,0,4.7,2.2,4.7,4.8C23,15.3,20.9,17.4,18.2,17.4z'
    />
  </IconBuilder>
);

export default HotCoffeeIcon;

import { alpha } from '@mui/material';

// Types
import type { MouseEvent, FC } from 'react';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import TablePatternToolbar from './Toolbar';
import RefCellBadge from './Badge/Ref';
import FileCellBadge from './Badge/File';
import TextCell from './Field/Text';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Table from 'core/components/base/display/Table';
import TableBody from 'core/components/base/display/TableBody';
import TableRow from 'core/components/base/display/TableRow';
import TableCell from 'core/components/base/display/TableCell';

// Custom Hooks
import { useAppDispatch, useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import { updateSelection } from 'features/appBuilder/patterns/features/table/store';
import { getAlphabetArray } from 'core/utilities/alphabet';
import {
  extractTableCells,
  extractTableColumn,
  extractTableRow,
  getTableCounts,
  sortCells,
} from 'features/appBuilder/patterns/features/table/utilities';
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';

// Custom Types
import type { TableCellProps } from 'features/appBuilder/patterns/features/table/types/cell';
import type { TableRowProps } from 'features/appBuilder/patterns/features/table/types';
export interface TablePatternConfigProps {}

const TablePatternBody: FC<TablePatternConfigProps> = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const tableData = useAppSelector((state) => state.table.data);
  const { selection, toolbarMode } = tableData;

  // Utilites
  const handleColSelect = (colKey: string) => {
    const cellsToSelect = extractTableColumn(tableData.rows, colKey);

    dispatch(updateSelection({ cells: cellsToSelect, toolbarMode: 'col' }));
  };

  const handleRowSelect = (rowIndex: number) => {
    const cellsToSelect = extractTableRow(tableData.rows, rowIndex);

    dispatch(updateSelection({ cells: cellsToSelect, toolbarMode: 'row' }));
  };

  const handleSelectAll = () => {
    if (isAllCellsSelected()) {
      dispatch(updateSelection({ cells: [], toolbarMode: 'cell' }));
      return;
    }

    const cellsToSelect = extractTableCells(tableData.rows);

    dispatch(updateSelection({ cells: cellsToSelect, toolbarMode: 'all' }));
  };

  const handleCellSelection = (
    cell: TableCellProps,
    event: MouseEvent<HTMLTableCellElement>
  ) => {
    let clonedSelection: TableCellProps[] = [];

    if (event.ctrlKey || event.metaKey) {
      clonedSelection = [...selection];
    }

    const cellIndex = clonedSelection.findIndex(
      (c) => c.col === cell.col && c.row === cell.row
    );

    if (cellIndex > -1) {
      clonedSelection.splice(cellIndex, 1);
    } else {
      clonedSelection.push(cell);
    }

    sortCells(clonedSelection);

    dispatch(
      updateSelection({
        cells: clonedSelection,
        toolbarMode: clonedSelection.length > 1 ? 'multi-cell' : 'cell',
      })
    );
  };

  const isCellSelected = (cell: TableCellProps): boolean => {
    const index = selection.findIndex(
      (c) => c.col === cell.col && c.row === cell.row
    );

    return index > -1 ? true : false;
  };

  const isColSelected = (colIndex: number): boolean =>
    (toolbarMode === 'col' || toolbarMode === 'all') &&
    (isAllCellsSelected() || selection.every((cell) => cell.col === colIndex));

  const isRowSelected = (rowIndex: number): boolean =>
    (toolbarMode === 'row' || toolbarMode === 'all') &&
    (isAllCellsSelected() || selection.every((cell) => cell.row === rowIndex));

  const isAllCellsSelected = () =>
    toolbarMode === 'all' &&
    selection.length > 0 &&
    selection.length === getTableCounts(tableData.rows).cellCount;

  const getRowHeight = (row: TableRowProps): number => {
    let height = 35;

    if ('A' in row) {
      height = row['A'].styles.height;
    }

    return height;
  };

  return tableData.rowCount > 0 && tableData.colCount > 0 ? (
    <RoundPaper>
      <ColumnStack>
        <TablePatternToolbar />
        <Box
          sx={{
            overflowX: 'auto',
            p: '0.5rem',
            ...customRoundedScrollbarStyles,
          }}
        >
          <Table sx={{ borderCollapse: 'separate' }}>
            <TableBody>
              <TableRow>
                <TableCell
                  onClick={handleSelectAll}
                  sx={({ palette }) => ({
                    backgroundColor: isAllCellsSelected()
                      ? alpha(palette.info.light, 0.75)
                      : 'transparent',
                    border: isAllCellsSelected()
                      ? `2px solid ${palette.info.main}`
                      : '1px solid #C1C8D3',
                    width: 35,
                    height: 35,
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  })}
                ></TableCell>
                {getAlphabetArray(tableData.colCount, 'uppercase').map(
                  (colName, index) => (
                    <TableCell
                      key={`table-pattern-header-${colName}-${index}`}
                      onClick={() => handleColSelect(colName)}
                      sx={({ palette }) => ({
                        padding: 0,
                        backgroundColor: isColSelected(index)
                          ? alpha(palette.info.light, 0.75)
                          : 'transparent',
                        border: isColSelected(index)
                          ? `2px solid ${palette.info.main}`
                          : '1px solid #C1C8D3',
                        width: 180,
                        minWidth: 180,
                        flexShrink: 0,
                        height: 35,
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        textAlign: 'center',
                      })}
                    >
                      {colName}
                    </TableCell>
                  )
                )}
              </TableRow>
              {tableData.rows.map((row, rowIndex) => (
                <TableRow key={`pattern-table-row-${rowIndex}`}>
                  <TableCell
                    onClick={() => handleRowSelect(rowIndex)}
                    sx={({ palette }) => ({
                      padding: 0,
                      backgroundColor: isRowSelected(rowIndex)
                        ? alpha(palette.info.light, 0.75)
                        : 'transparent',
                      border: isRowSelected(rowIndex)
                        ? `2px solid ${palette.info.main}`
                        : '1px solid #C1C8D3',
                      width: 35,
                      height: getRowHeight(row),
                      textAlign: 'center',
                      cursor: 'pointer',
                    })}
                  >
                    {rowIndex + 1}
                  </TableCell>
                  {Object.values(row).map((cell) => (
                    <TableCell
                      key={`pattern-table-row-${rowIndex}-cell-${cell.col}`}
                      colSpan={cell.colSpan}
                      onClick={(event) => handleCellSelection(cell, event)}
                      sx={{
                        padding: 0,
                        border: isCellSelected(cell) ? '2px' : '1px',
                        borderStyle: 'solid',
                        borderColor: isCellSelected(cell)
                          ? 'info.main'
                          : '#C1C8D3',
                        width: 35,
                        height: 35,
                        cursor: 'pointer',
                        overflow: 'hidden',
                        p: '0.5rem',
                      }}
                    >
                      {cell.type === 'ref' ? (
                        <RefCellBadge cell={cell} />
                      ) : cell.type === 'file' ? (
                        <FileCellBadge cell={cell} />
                      ) : (
                        <TextCell cell={cell} />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </ColumnStack>
    </RoundPaper>
  ) : (
    <></>
  );
};

export default TablePatternBody;

import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  styled,
} from '@mui/material';

export interface AppBarProps extends MuiAppBarProps {}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderBottom: '1px solid #beced4',
  boxShadow: 'none',
  color: theme.palette.text.secondary,
}));

AppBar.defaultProps = {
  position: 'static',
};

export default AppBar;

import { type FC, useCallback, useEffect, useRef } from 'react';

// Custom Core Components
import Autocomplete from 'core/components/base/inputs/Autocomplete';
import TextField from 'core/components/base/inputs/TextField';
import FormHelperText from 'core/components/base/inputs/FormHelperText';
import MenuItem from 'core/components/base/navigation/MenuItem';
import Checkbox from 'core/components/base/inputs/Checkbox';

// Custom Common Component
import BodyOne from 'core/components/shared/Typography/BodyOne';

// Custom Icon Components
import ArrowDropDownIcon from 'core/components/icons/ArrowDropDown';

// Custom Styles
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';

// Custom Hooks
import useArray from 'core/hooks/useArray';
import useBoolean from 'core/hooks/useBoolean';
import useClickOutside from 'core/hooks/useClickOutside';

// Custom Types
import {
  AudienceDataFieldsLabel,
  type AudienceDataField,
} from 'features/audience/settings/types';

type Option = AudienceDataField | 'all';
export interface AudienceFieldsAutocompleteProps {
  values: string[];
  disabled?: boolean;
  error?: boolean;
  asFeedbackTitle?: boolean;
  helperText?: string;
  onChange: (values: string[]) => void;
}

const AudienceFieldsAutocomplete: FC<AudienceFieldsAutocompleteProps> = (
  props
) => {
  // Props
  const { values, asFeedbackTitle, helperText, disabled, error, onChange } =
    props;

  let options = asFeedbackTitle ? feedbackTitleOpts : defaultOpts;
  let title = asFeedbackTitle ? 'عنوان بازخورد' : 'اطلاعات پروفایل جهت نمایش';

  // Hooks
  const open = useBoolean();
  const selected = useArray<Option>();

  const ref = useRef(null);

  useClickOutside(ref, () => open.setFalse());

  const allOpts = [...defaultOpts];
  allOpts.splice(0, 1);

  const handleSelectionChange = (opt: Option | Option[]) => {
    let clonedSelected = Array.isArray(opt) ? opt : [...selected.state];

    if ((Array.isArray(opt) && opt.includes('all')) || opt === 'all') {
      if (selected.state.length === allOpts.length) {
        clonedSelected = [];
      } else {
        clonedSelected = allOpts;
      }
    } else if (!Array.isArray(opt)) {
      const index = clonedSelected.findIndex((o) => o === opt);

      if (index > -1) clonedSelected.splice(index, 1);
      else clonedSelected.push(opt);
    }

    selected.set(clonedSelected);
    if (onChange) onChange(clonedSelected);
  };

  const handleDefaults = useCallback(() => {
    if (values.length > 0) selected.set(values as Option[]);
    else selected.clear();
  }, [values]);

  useEffect(() => {
    handleDefaults();
  }, [handleDefaults]);

  return (
    <>
      <Autocomplete
        closeText='بستن'
        clearText='پاک کردن'
        openText='باز کردن'
        loadingText='در حال دریافت...'
        noOptionsText='گزینه‌ای برای انتخاب وجود ندارد'
        getLimitTagsText={(num) => <BodyOne>{num.toPersian()}+</BodyOne>}
        disableCloseOnSelect
        multiple
        disabled={disabled}
        open={open.state}
        limitTags={1}
        options={options}
        getOptionLabel={(opt) =>
          opt === 'all' ? 'همه' : AudienceDataFieldsLabel[opt]
        }
        popupIcon={
          <ArrowDropDownIcon
            sx={{
              color: disabled
                ? 'text.disabled'
                : error
                ? 'error.main'
                : 'info.main',
            }}
          />
        }
        renderInput={(params) => (
          <TextField {...params} placeholder={`انتخاب ${title}`} />
        )}
        ListboxProps={{
          sx: { ...customRoundedScrollbarStyles, maxHeight: '21rem', p: 0 },
        }}
        renderOption={(props, option) => (
          <MenuItem
            onClick={(e) => {
              handleSelectionChange(option);
            }}
            {...props}
          >
            <Checkbox
              onClick={() => {
                handleSelectionChange(option);
              }}
              size='small'
              checked={
                option === 'all'
                  ? allOpts.length === selected.length
                  : selected.state.includes(option)
              }
            />
            <BodyOne
              onClick={() => {
                handleSelectionChange(option);
              }}
              sx={{ flexGrow: 1 }}
            >
              {option === 'all' ? 'همه' : AudienceDataFieldsLabel[option]}
            </BodyOne>
          </MenuItem>
        )}
        value={selected.state}
        onOpen={() => {
          if (!disabled) open.setTrue();
        }}
        onClose={open.setFalse}
        onChange={(e, v) => handleSelectionChange(v)}
      />
      {helperText && (
        <FormHelperText sx={{ color: error ? 'error.main' : 'text.primary' }}>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default AudienceFieldsAutocomplete;

const feedbackTitleOpts: Option[] = [
  'firstName',
  'lastName',
  'email',
  'personelId',
  'nationalId',
];

const defaultOpts: Option[] = [
  'all',
  'firstName',
  'lastName',
  'avatar',
  'biography',
  'birthDate',
  'email',
  'groups',
  'jobTitle',
  'nationalId',
  'personelId',
  'phoneNumber',
  'access',
];

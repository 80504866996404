// Custom Utilities
import { subFeatureRouteMap } from 'core/utilities/route/map';

// Custom Types
import type { PermissionProps } from 'core/types/permission';

export const getManagerLandingRoute = (
  permissions: PermissionProps[]
): string => {
  for (const permission of permissions) {
    if (permission.subFeatures.length > 0) {
      let landingFeature = permission.subFeatures[0];

      return subFeatureRouteMap[landingFeature];
    }
  }

  return subFeatureRouteMap['profile'];
};

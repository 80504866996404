// Custom Hooks
import useDocApi from 'core/hooks/api/useDoc';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import useDocsApi from 'core/hooks/api/useDocs';
import { updatePattern } from 'features/appBuilder/patterns/core/utilities/api/edit';
import { duplicatePattern } from 'features/appBuilder/patterns/core/utilities/api/crud';
import {
  addPattern,
  deletePatterns,
  getPattern,
  getPatterns,
  getPatternsOf,
} from 'features/appBuilder/patterns/utilities/api';

// Custom Types
import type { PatternDataType } from 'features/appBuilder/patterns/types/type';
import type { PatternItemProps } from 'features/appBuilder/patterns/types/item';
import type { PatternContainerProps } from 'features/appBuilder/patterns/types/container';
import type {
  PatternType,
  PatternData,
  Pattern,
} from 'features/appBuilder/patterns/core/types/item';
import type {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';
import { createPattern } from 'features/appBuilder/patterns/core/utilities/api/create';

/**
 * Query key for fetching patterns in react query.
 *
 * @constant {Array<string>}
 */
export const patternsQueryKey = ['patterns'];

/**
 * Custom hook to fetch patterns list.
 *
 * This hook utilizes the `useDocsApi` to retrieve patterns based on the provided options.
 *
 * @param {UseDocsOptionType<PatternContainerProps>} [options] - Optional configuration options for fetching patterns.
 * @returns {UseDocsApiReturnProps<PatternContainerProps>} The result of the `useDocApi` hook, which includes the fetched patterns, loading state, and any errors.
 */
export const usePatternDocs = (
  options?: UseDocsOptionType<PatternContainerProps>
): UseDocsApiReturnProps<PatternContainerProps> =>
  useDocsApi<PatternContainerProps>(getPatterns, patternsQueryKey, options);

/**
 * Custom hook to fetch patterns of documents.
 *
 * This hook utilizes the `useDocApi` to retrieve an array of pattern items based on the provided options.
 *
 * @param {UseDocOptionType<PatternItemProps[]>} [options] - Optional configuration options for fetching the pattern documents.
 * @returns {UseDocApiReturnProps<PatternItemProps[]>} The result of the `useDocApi` hook, which includes the fetched pattern documents, loading state, and any errors.
 */
export const usePatternsOfDoc = (
  options?: UseDocOptionType<PatternItemProps[]>
): UseDocApiReturnProps<PatternItemProps[]> =>
  useDocApi<PatternItemProps[]>(
    (type, signal) => getPatternsOf(type as PatternType, signal),
    patternsQueryKey,
    options
  );

/**
 * Custom hook to fetch a pattern document.
 *
 * This hook utilizes the `useDocApi` to retrieve a specific pattern based on the provided pattern type and options.
 *
 * @param {string} [patternType] - The type of the pattern to fetch. If not provided, the default behavior will apply.
 * @param {UseDocOptionType<Pattern>} [options] - Optional configuration options for fetching the pattern document.
 * @returns {UseDocApiReturnProps<Pattern>} The result of the `useDocApi` hook, which includes the fetched pattern document, loading state, and any errors.
 */
export const usePatternDoc = (
  patternType?: string,
  options?: UseDocOptionType<Pattern>
): UseDocApiReturnProps<Pattern> =>
  useDocApi<Pattern>(
    (id, signal) => getPattern(patternType as PatternType, id, signal),
    patternsQueryKey,
    options
  );

/**
 * Custom hook for updating a pattern.
 *
 * @returns {MutationResult} The result of the update mutation, including the response.
 */
export const useUpdatePatternMutation = useEditMutation<PatternData>(
  updatePattern,
  patternsQueryKey
);

/**
 * Custom hook for adding a new pattern.
 *
 * @returns {MutationResult} The result of the add mutation, including the response.
 */
export const useAddPatternMutation = useAddMutation<PatternData>(
  createPattern,
  patternsQueryKey
);

/**
 * Custom hook for deleting patterns.
 *
 * @returns {MutationResult} The result of the delete mutation, including the response.
 */
export const useDeletePatternsMutation = useDeleteMutation<{
  type: PatternType;
  ids: string[];
}>(deletePatterns, patternsQueryKey);

export const useDuplicatePatternMutation = useAddMutation<{
  type: PatternType;
  id: string;
}>(duplicatePattern, patternsQueryKey);

import store from 'core/store';
import {
  MenuContainerProps,
  MenuItemDataProps,
  MenuItemProps,
} from 'features/menu/menus/types';
import { deepClone } from 'core/utilities/helper/helperPack';
import { forLoop, genUUID } from 'core/utilities/helper';

export const generateMenuItem = (): MenuItemDataProps => ({
  title: 'منو جدید',
  description: '',
  hasSubItems: false,
  image: null,
  isActive: true,
  parentId: null,
  link: '',
  parentIds: [],
  relatedMediaId: genUUID(),
  sortNumber: 0,
});

/**
 * Updates a menu children parentIds recursively.
 * @example
 * item.data.parentIds = [...menu.data.parentIds, menu.id];
 * clone.data.items = updateParentIds(clone.data.items, item);
 * @param {MenuItemProps[]} list - The list of menu items.
 * @param {MenuItemProps} updatedMenu - The updated menu item.
 * @param {boolean} stop - Flag to stop the recursive function.
 * @returns {MenuItemProps[]} The updated list of menu items.
 */
export const updateParentIds = (
  list: MenuItemProps[],
  updatedMenu: MenuItemProps,
  stop: boolean = false
): MenuItemProps[] => {
  if (stop) return list;
  let clone = deepClone(list);
  const updatedMenus: MenuItemProps[] = [];
  forLoop(clone, (item) => {
    if (item.data.parentIds.at(-1) === updatedMenu.id) {
      item.data.parentIds = [...updatedMenu.data.parentIds, updatedMenu.id];
      updatedMenus.push(item);
    }
  });
  // If Any Menu Updated, Find The Children and Update Their ParentIds
  if (updatedMenus.length > 0) {
    forLoop(updatedMenus, (v) => (clone = updateParentIds(clone, v)));
    return clone;
  } else return updateParentIds(clone, updatedMenu, true); // Return the Recursive Function with stop set to true
};

/**
 * Checks if an item is selectable based on the current menu.
 * @param {MenuItemProps} item - The item to check for selectability.
 * @param {MenuItemProps} currentMenu - The current menu item.
 * @returns {boolean} True if the item is selectable, false otherwise.
 */
export const isOptSelectable = (
  item: MenuItemProps,
  currentMenu: MenuItemProps
): boolean => {
  // Its the selected option
  if (currentMenu.data.parentIds.at(-1) === item.id) return false;
  // Its the currentMenu
  if (item.id === currentMenu.id) return false;
  // CurrentMenu Childrens
  if (currentMenu.id === item.data.parentIds.at(-1)) return true;
  // Menus with Same Parent(Level 1 not included)
  if (areParentsMatch(item, currentMenu)) return true;
  if (
    !currentMenu.data.parentIds.includes(item.id) &&
    item.data.parentIds.length === 0
  )
    return true;

  return false;
};

/**
 * Checks if the parents of two menu items match.
 * @param {MenuItemProps} a - The first menu item.
 * @param {MenuItemProps} b - The second menu item.
 * @returns {boolean} - True if the parents match, false otherwise.
 */
export const areParentsMatch = (
  a: MenuItemProps,
  b: MenuItemProps
): boolean => {
  const aParents = a.data.parentIds;
  const bParents = b.data.parentIds;
  return aParents.at(-1) === bParents.at(-1);
};

export const sortMenus = (a: MenuItemProps, b: MenuItemProps) =>
  a.data.sortNumber - b.data.sortNumber;

/**
 * Get the latest data from Redux without registering the component to it.
 * @returns {MenuContainerProps} The latest container data from Redux.
 */
export const getContainer = (): MenuContainerProps | null =>
  store.getState().menu.current;

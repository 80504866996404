import {
  List as MuiList,
  ListProps as MuiListProps,
  styled,
} from '@mui/material';

export interface ListProps extends MuiListProps {}

const List = styled(MuiList)<ListProps>(() => ({}));

List.defaultProps = {
  disablePadding: true,
  sx: {
    paddingX: '0.5rem',
  },
};

export default List;

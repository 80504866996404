import { useState, type FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import debounce from 'lodash/debounce';

// Custom Core Components
import TextField from 'core/components/base/inputs/TextField';
import Collapse from 'core/components/base/utils/Collapse';

// Custom Common Components
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import CollapseIconButton from 'core/components/shared/IconButton/Collapse';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import RowStack from 'core/components/shared/Stack/RowStack';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import CheckboxWithLabel from 'core/components/shared/Checkbox/CheckboxWithLabel';

// Custom Feature Components
import AudienceFieldsAutocomplete from 'features/audience/settings/components/FieldAutocomplete';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { setFormActionMode } from 'features/form/forms/store/actions';

// Custom Types
import type { FormProps } from 'features/form/forms/types/item';
interface FormSettingsAudienceFeedbackProps {
  disabled?: boolean;
}

const FormSettingsAudienceFeedback: FC<FormSettingsAudienceFeedbackProps> = (
  props
) => {
  // Props
  const { disabled } = props;

  // Hooks
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { control, setValue, setError } = useFormContext<FormProps>();
  const actionMode = useAppSelector((state) => state.form.actionMode);

  // Utilities
  const toggleCollapse = () => setCollapsed(!collapsed);
  const updateActionMode = debounce(() => {
    if (actionMode !== 'SETTINGS') {
      setFormActionMode('SETTINGS');
    }
  }, 50);

  // Render
  return (
    <RoundPaper
      sx={{
        backgroundColor: 'background.default',
        borderRadius: '1rem',
        width: '100%',
      }}
    >
      <SpaceBetween>
        <PageTitle color='text.secondary'>بازخورد</PageTitle>
        <CollapseIconButton collapsed={collapsed} onClick={toggleCollapse} />
      </SpaceBetween>
      <Collapse in={collapsed}>
        <ColumnStack pb='1rem'>
          <RowStack spacing={6}>
            <InputWrapper width='100%'>
              <InputLabel indentRight required>
                عنوان بازخورد
              </InputLabel>
              <Controller
                control={control}
                name='data.audienceFeedbackTitles'
                defaultValue={[]}
                render={({ field, fieldState }) => (
                  <AudienceFieldsAutocomplete
                    disabled={disabled}
                    asFeedbackTitle
                    values={field.value}
                    error={fieldState.error?.message ? true : false}
                    helperText={fieldState.error?.message}
                    onChange={(ids) => {
                      updateActionMode();
                      if (ids.length === 0) {
                        setError(field.name, {
                          message: 'انتخاب عنوان بازخورد اجباری است.',
                        });
                      } else {
                        setError(field.name, {
                          message: '',
                        });
                      }

                      setValue(field.name, ids);
                    }}
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper width='100%'>
              <InputLabel indentRight sx={{ fontSize: '14px', height: '24px' }}>
                مشاهده اطلاعات پروفایل مخاطب در بازخورد
              </InputLabel>
              <Controller
                control={control}
                name='data.audienceInfo'
                defaultValue={[]}
                render={({ field }) => (
                  <AudienceFieldsAutocomplete
                    disabled={disabled}
                    values={field.value}
                    onChange={(ids) => {
                      updateActionMode();
                      setValue(field.name, ids);
                    }}
                  />
                )}
              />
            </InputWrapper>
          </RowStack>
          <RowStack spacing={6}>
            <InputWrapper width='100%'>
              <InputLabel indentRight sx={{ fontSize: '14px', height: '24px' }}>
                تعداد بازخوردهای قابل ثبت توسط هر مخاطب
              </InputLabel>
              <Controller
                control={control}
                name='data.feedbackLimitPerAudience'
                rules={{ onChange: updateActionMode }}
                defaultValue={1}
                render={({ field }) => (
                  <TextField
                    disabled={disabled}
                    type='number'
                    placeholder='1'
                    {...field}
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper width='100%' sx={{ pt: '2rem' }}>
              <Controller
                control={control}
                name='data.editableFeedbacks'
                defaultValue={false}
                render={({ field }) => {
                  return (
                    <CheckboxWithLabel
                      disabled={disabled}
                      label='بازخورد قابل ویرایش توسط مخاطب'
                      checkboxProps={{
                        disabled: disabled,
                        checked: field.value,
                        onChange: (event, checked) => {
                          updateActionMode();
                          setValue(field.name, checked);
                        },
                      }}
                    />
                  );
                }}
              />
            </InputWrapper>
          </RowStack>
        </ColumnStack>
      </Collapse>
    </RoundPaper>
  );
};

export default memo(FormSettingsAudienceFeedback, isSame);

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface CalendarFilledIconProps extends SvgIconProps {}

const CalendarFilledIcon: React.FC<CalendarFilledIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M1,21.8C1,22.5,1.5,23,2.1,23c0,0,0,0,0.1,0h19.6c0.6,0,1.2-0.5,1.2-1.1c0,0,0,0,0-0.1v-13H1V21.8z M23,3.7
	c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1-0.1-0.3-0.1-0.5-0.1h-2.7V1h-2.4v1.6H7.3V1H4.9v1.6
	H2.2c-0.2,0-0.3,0-0.5,0.1C1.6,2.7,1.5,2.8,1.4,2.9C1.2,3,1.2,3.1,1.1,3.3C1,3.4,1,3.6,1,3.7v2.8h22V3.7z'
    />
  </IconBuilder>
);

export default CalendarFilledIcon;

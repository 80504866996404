import * as React from 'react';
import { alpha, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

// Custom Core Components
import Paper from 'core/components/base/surfaces/Paper';
import Button from 'core/components/base/inputs/Button';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Icons
import BinIcon from 'core/components/icons/Bin';
import PlusCircleOutlineIcon from 'core/components/icons/PlusCircleOutline';

// Context
import { useDialogContext } from 'core/store/context/DialogProvider';
import { useSelectionContext } from 'core/store/context/common/UseSelection';
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';

// Custom Utilities
import { useDeleteMenuContainerMutation } from 'features/menu/menus/hooks';

// Custom Types
interface MenuListActionProps {}

const MenuListAction: React.FC<MenuListActionProps> = (props) => {
  // Context
  const loading = useSelectLoading();
  const { selections, onClearSelections } = useSelectionContext();
  const { onOpenDialog, onCloseDialog } = useDialogContext();

  // Hooks
  const { palette } = useTheme();
  const { mutate: handleDeleteContainer } = useDeleteMenuContainerMutation({
    autoAlert: { mode: 'delete', name: 'فهرست‌های مورد نظر' },
    onSuccess: () => {
      onCloseDialog();
      onClearSelections();
    },
  });

  // Utilities
  const handleOpenDeleteDialog = () => {
    const message =
      selections.length > 1 ? 'فهرست‌های مورد نظر' : 'فهرست مورد نظر';

    onOpenDialog({
      title: `حذف فهرست`,
      description: `آیا از حذف ${message} انتخابی مطمئن هستید؟`,
      onSubmit: () => handleDeleteContainer(selections),
    });
  };

  // Render
  return (
    <RowStack alignItems='center' spacing='.5rem'>
      <Button
        component={Paper}
        color='error'
        disabled={loading || selections.length === 0}
        onClick={handleOpenDeleteDialog}
        sx={{
          backgroundColor: palette.background.paper,
          color: palette.error.main,
          minWidth: 'unset',
          borderRadius: '.25rem',
          ':hover': {
            opacity: 0.9,
          },
        }}
      >
        <BinIcon />
      </Button>
      <Link to='/menus/add'>
        <Button
          startIcon={<PlusCircleOutlineIcon color='info' />}
          variant='contained'
          component={Paper}
          disabled={loading}
          sx={{
            backgroundColor: palette.background.paper,
            color: palette.info.light,
            borderRadius: '.25rem',
            ':hover': {
              backgroundColor: alpha(palette.background.paper, 0.5),
              opacity: 0.9,
            },
          }}
        >
          منو جدید
        </Button>
      </Link>
    </RowStack>
  );
};

export default MenuListAction;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

export interface PdfIconProps extends SvgIconProps {}

const PdfIcon: React.FC<PdfIconProps> = (props) => (
  <IconBuilder
    width={30}
    height={30}
    viewBox='0 0 30 30'
    fill='none'
    {...props}
  >
    <path d='M26.6667 0H3.33333C1.5 0 0 1.5 0 3.33333V26.6667C0 28.5 1.5 30 3.33333 30H26.6667C28.5 30 30 28.5 30 26.6667V3.33333C30 1.5 28.5 0 26.6667 0ZM10.8333 14.1667C10.8333 15.5 9.66667 16.6667 8.33333 16.6667H6.66667V20H4.16667V10H8.33333C9.66667 10 10.8333 11.1667 10.8333 12.5V14.1667ZM19.1667 17.5C19.1667 18.8333 18 20 16.6667 20H12.5V10H16.6667C18 10 19.1667 11.1667 19.1667 12.5V17.5ZM25.8333 12.5H23.3333V14.1667H25.8333V16.6667H23.3333V20H20.8333V10H25.8333V12.5ZM15 12.5H16.6667V17.5H15V12.5ZM6.66667 12.5H8.33333V14.1667H6.66667V12.5Z' />
  </IconBuilder>
);

export default PdfIcon;

// Core Utilities
import { forLoop } from 'core/utilities/helper';

// Custom Types
import type { PatternTabItemProps } from 'features/appBuilder/patterns/core/types/item/tab';
import type { SliderPatternDataProps } from 'features/appBuilder/patterns/features/slider/types';

/**
 * Validates a specific content tab and returns an error message if validation fails.
 *
 * @param {PatternTabItemProps} tab - The content tab to validate.
 * @param {number} index - The index of the tab in the list of tabs.
 * @returns {string} A string containing the error message if validation fails, or an empty string if the tab is valid.
 */
const validateContentTab = (
  tab: PatternTabItemProps,
  index: number
): string => {
  if (!tab.refType) {
    return `وارد کردن نوع مرجع تب اجباری است.`;
  }

  if (!tab.sourceId) {
    return `وارد کردن منبع تب اجباری است.`;
  }

  return '';
};

/**
 * Validates the slider pattern data and returns an error message if validation fails.
 *
 * @param {SliderPatternDataProps} data - The slider pattern data to validate.
 * @returns {string} A string containing the error message if validation fails, or an empty string if the data is valid.
 */
export const validateSliderPattern = (data: SliderPatternDataProps): string => {
  let error = '';

  if (!data.patternTitle.trim()) {
    error = 'وارد کردن عنوان پترن الزامی می‌باشد.';
  }

  if (data.body.tabs.length === 0) {
    error = 'پترن کانتنت باید دارای حداقل یک تب باشد.';
  } else {
    forLoop(data.body.tabs, (tab, index) => {
      if (!error) {
        error = validateContentTab(tab, index);
      }
    });
  }

  return error;
};

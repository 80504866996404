// Custom Utilities
import apiHandler from 'core/utilities/apiHandler';
import { getDoc, getSummary } from 'core/utilities/apiHandler/helper';
import { getUrlWithQueryString } from 'core/utilities/helper/helperPack';
import { AudienceGroupsEndpoints } from 'features/audience/groups/utilities/api/endpoints';
import {
  transformAudienceGroupsSummary,
  transformAudienceGroup,
} from 'features/audience/groups/utilities';

// Custom Types
import type {
  ApiGetDocResponse,
  ApiGetSummaryResponse,
} from 'core/types/api/hook/response';
import type {
  AudienceGroupProps,
  AudienceGroupSummaryProps,
} from 'features/audience/groups/types';
import type {
  ApiAudienceGroupProps,
  ApiAudienceGroupSummaryProps,
} from 'features/audience/groups/types/api';

export const getAudienceGroups = async (
  signal?: AbortSignal,
  query?: Record<string, any>
): Promise<{
  status: number;
  list: AudienceGroupSummaryProps[];
}> => {
  const { status, data } = await apiHandler.get<{
    docs: ApiAudienceGroupSummaryProps[];
  }>(getUrlWithQueryString(AudienceGroupsEndpoints.getList, query), {
    signal,
  });

  const list: AudienceGroupSummaryProps[] = transformAudienceGroupsSummary(
    data?.docs || []
  );

  return { status, list };
};

/**
 * Fetches the summary list of audience groups.
 *
 * @param {AbortSignal} [signal] - An optional signal to abort the request.
 * @returns {Promise<ApiGetSummaryResponse<AudienceGroupSummaryProps>>} A promise that resolves to an object containing the status of the request and the list of audience group summaries.
 */
export const getAudienceGroupSummaryList = async (
  signal?: AbortSignal
): Promise<ApiGetSummaryResponse<AudienceGroupSummaryProps>> => {
  const endpoint = AudienceGroupsEndpoints.getSummaryList;

  return await getSummary<
    ApiAudienceGroupSummaryProps[],
    AudienceGroupSummaryProps
  >(endpoint, {
    signal,
    returnMutationFn: (status, data) => ({
      status,
      list: data ? transformAudienceGroupsSummary(data) : [],
    }),
  });
};

/**
 * Retrieves a list of audience groups from the server, including an option to include a public group.
 * @function getAudienceGroupsList
 * @async
 * @param {boolean} [showPublic=true] - Indicates whether to include a public group in the list. Default is true.
 * @returns {Promise<{ status: number; audienceGroupsList: AudienceGroupSummaryProps[] }>} A Promise that resolves with an object containing the HTTP status code and the retrieved audience groups list (if successful).
 */

export const deprecatedGetAudienceGroupsList = async (
  showPublic: boolean = true
): Promise<{
  status: number;
  audienceGroupsList: AudienceGroupSummaryProps[];
}> => {
  const { status, data: response } = await apiHandler.get(
    AudienceGroupsEndpoints.getSummaryList
  );

  const { docs } = response as { docs: ApiAudienceGroupSummaryProps[] };

  const audienceGroupsList: AudienceGroupSummaryProps[] =
    transformAudienceGroupsSummary(docs);

  showPublic &&
    audienceGroupsList.splice(0, 0, {
      id: 'public',
      data: {
        features: [],
        title: 'عمومی (بینندگان)',
        audienceCount: 0,
      },
    });

  return { status, audienceGroupsList };
};

/**
 * Fetches an audience group by its ID.
 *
 * @param {string} id - The ID of the audience group to fetch.
 * @param {AbortSignal} [signal] - An optional signal to abort the request.
 * @returns {Promise<ApiGetDocResponse<AudienceGroupProps>>} A promise that resolves to an object containing the status and the fetched audience group, if available.
 */
export const getAudienceGroup = async (
  id: string,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<AudienceGroupProps>> => {
  const endpoint = AudienceGroupsEndpoints.getById(id);

  return await getDoc<ApiAudienceGroupProps, AudienceGroupProps>(endpoint, {
    signal,
    returnMutationFn: (status, data) => ({
      status,
      doc: data ? transformAudienceGroup(data) : undefined,
    }),
  });
};

/**
 * Adds a new audience group.
 *
 * @param {string} title - The title of the audience group to be added.
 * @returns {Promise<{ status: number }>} A promise that resolves to an object containing the status of the add operation.
 */
export const addAudienceGroup = async (
  title: string
): Promise<{ status: number }> => {
  const { status } = await apiHandler.post(AudienceGroupsEndpoints.add, {
    title,
  });
  return { status };
};

/**
 * Edits an existing audience group.
 *
 * @param {string} groupId - The ID of the audience group to be edited.
 * @param {string} title - The new title for the audience group.
 * @returns {Promise<{ status: number; audienceGroup?: AudienceGroupProps }>} A promise that resolves to an object containing the status and the updated audience group, if available.
 */
export const editAudienceGroup = async (
  groupId: string,
  title: string
): Promise<{ status: number; audienceGroup?: AudienceGroupProps }> => {
  const { status, data: response } = await apiHandler.patch(
    AudienceGroupsEndpoints.edit(groupId),
    { title }
  );
  const { docs } = response as { docs: ApiAudienceGroupProps };
  const audienceGroup: AudienceGroupProps = transformAudienceGroup(docs);
  return { status, audienceGroup };
};

/**
 * Deletes one or more audience groups.
 *
 * @param {string[]} audienceGroupIds - An array of IDs of the audience groups to be deleted.
 * @returns {Promise<{ status: number }>} A promise that resolves to an object containing the status of the delete operation.
 */
export const deleteAudienceGroups = async (
  audienceGroupIds: string[]
): Promise<{ status: number }> => {
  const { status } = await apiHandler.delete(AudienceGroupsEndpoints.remove, {
    ids: audienceGroupIds,
  });
  return { status };
};

import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Custom Hooks
import { useSelectInputValue } from 'features/form/feedbacks/store';

// Custom Core Components
import Box from 'core/components/base/layout/Box';

// Custom Feature Components
import FeedbackInputFile from 'features/form/feedbacks/components/details/Input/File';

// Custom Common Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Context
import { useIndex } from 'core/store/context/IndexProvider';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { generateObjectId } from 'core/utilities/helper/id';
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';
import {
  convertFeedbackValue,
  feedbackSeperatorKey,
} from 'features/form/feedbacks/utilities/details/helper';

// Custom Types
import type { SxProps } from '@mui/material';
import type { FeedbackInputProps } from 'features/form/feedbacks/types/detail';

interface FeedbackInput {
  input: FeedbackInputProps;
  sx?: SxProps;
}

const FeedbackInput: React.FC<FeedbackInput> = (props) => {
  // Props
  const { sx } = props;
  const { id: inputId, data: input } = props.input;

  // Context
  const index = useIndex(); // (Index > -1) Means Its on PdfPreview else Feedback Details.
  const value = useSelectInputValue(inputId, index);

  // Hooks
  const { breakpoints, palette } = useTheme();

  // Utilities
  const isRepeated = (file: string) => file.includes(feedbackSeperatorKey);
  const getValue = React.useCallback(
    () => convertFeedbackValue(input, value),
    [input.type, value]
  );

  const ValueRoot = React.useMemo(() => {
    const EmptyValue = () => (
      <BodyTwo
        sx={{
          fontWeight: 400,
          fontSize: '.975rem',
          color: palette.text.secondary,
          [breakpoints.down('md')]: {
            fontSize: '.975rem',
          },
        }}
      >
        -
      </BodyTwo>
    );

    if (input.type === 'file')
      return value.filter((v) => v).length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            maxWidth: '100%',
            alignSelf: 'start',
            justifySelf: 'start',
            justifyContent: 'start',
            alignItems: 'start',
            gap: '1rem',
            overflow: 'auto',
            flexWrap: 'nowrap',
            [breakpoints.down('sm')]: {
              padding: '.75rem',
            },
            ...customRoundedScrollbarStyles,
          }}
        >
          {value.map((file) =>
            value ? (
              isRepeated(file) ? (
                file
                  .split(feedbackSeperatorKey)
                  .map((subfile) =>
                    subfile ? (
                      <FeedbackInputFile
                        file={subfile}
                        key={`feedback-input-${index}-${inputId}-${subfile}`}
                      />
                    ) : (
                      <EmptyValue key={generateObjectId()} />
                    )
                  )
              ) : file ? (
                <FeedbackInputFile
                  file={file}
                  key={`feedback-input-${index}-${inputId}-${file}`}
                />
              ) : (
                <EmptyValue key={generateObjectId()} />
              )
            ) : (
              <EmptyValue key={generateObjectId()} />
            )
          )}
        </Box>
      ) : (
        <EmptyValue key={generateObjectId()} />
      );

    return (
      <BodyTwo
        sx={{
          fontWeight: 400,
          fontSize: '.975rem',
          color: palette.text.secondary,
          whiteSpace: 'normal',
          wordBreak: 'break-all',
          [breakpoints.down('md')]: {
            fontSize: '.975rem',
          },
        }}
      >
        {getValue() || '-'}
      </BodyTwo>
    );
  }, [value, input.type, palette]);

  // Render
  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: '.5rem',
        alignItems: 'start',
        ...sx,
        flexWrap: 'wrap',
        [breakpoints.down('sm')]: {
          gap: '0.5rem',
          flexDirection: ['file', 'textarea'].includes(input.type)
            ? 'column'
            : 'row',
        },
      }}
    >
      {input.label && (
        <BodyTwo
          sx={{
            color: palette.text.primary,
            fontSize: '1rem',
            fontWeight: 400,
          }}
        >
          {`${input.label}${input.isRequired ? '*' : ''}`}
        </BodyTwo>
      )}
      {ValueRoot}
    </Box>
  );
};

export default React.memo(FeedbackInput, isSame);

import { memo, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Custom Core Components
import TextField from 'core/components/base/inputs/TextField';
import Checkbox from 'core/components/base/inputs/Checkbox';
import InputBase from 'core/components/base/inputs/InputBase';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Feature Componenst
import FormInputWrapper, {
  FormInputWrapperProps,
} from 'features/form/forms/components/details/item/Input/shared/Wrapper';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';

// Custom Types
import type { FormProps } from 'features/form/forms/types/item';
import type { FormAgreementInputProps } from 'features/form/forms/types/item/input/agreement';
interface FormAgreementInputComponentProps extends FormInputWrapperProps {
  input: FormAgreementInputProps;
}

const FormAgreementInput: FC<FormAgreementInputComponentProps> = (props) => {
  // Props
  const { input, disabled, onUpdate, ...otherProps } = props;

  // Hooks
  const { control, getValues } = useFormContext<FormProps>();
  const index = getValues('data.inputsIndexMap')[input.id];

  return (
    <FormInputWrapper
      id={input.id}
      index={index}
      type='agreement'
      disabled={disabled}
      onUpdate={onUpdate}
      {...otherProps}
    >
      <Controller
        control={control}
        name={`data.inputs.${index}.data.description`}
        defaultValue=''
        rules={{ onChange: onUpdate }}
        render={({ field }) => (
          <TextField
            disabled={disabled}
            placeholder='متن توافقنامه...'
            multiline
            rows={5}
            fullWidth
            sx={{ fontSize: '0.875rem' }}
            {...field}
          />
        )}
      />
      <RowStack spacing={0.5} sx={{ pb: '2rem' }}>
        <Checkbox disabled color='info' size='small' />
        <Controller
          control={control}
          name={`data.inputs.${index}.data.checkboxLabel`}
          defaultValue='موافقم'
          rules={{ onChange: onUpdate }}
          render={({ field }) => (
            <InputBase
              disabled={disabled}
              placeholder='موافقم'
              fullWidth
              sx={{ fontSize: '0.875rem' }}
              {...field}
            />
          )}
        />
      </RowStack>
    </FormInputWrapper>
  );
};

export default memo(FormAgreementInput, isSame);

import { type FC } from 'react';

// Custom Shared Components
import FormLoadingButton, { type FormLoadingButtonProps } from '../Core';

// Custom Icon Components
import AddLayerIcon from 'core/components/icons/AddLayer';

// Custom Types
export interface AddSectionLoadingButtonProps extends FormLoadingButtonProps {}

const AddSectionLoadingButton: FC<AddSectionLoadingButtonProps> = (props) => {
  // Props
  const {
    children = 'بخش جدید',
    color = 'info',
    startIcon = <AddLayerIcon />,
    loadingPosition = 'start',
    ...otherProps
  } = props;

  return (
    <FormLoadingButton
      color={color}
      startIcon={startIcon}
      loadingPosition={loadingPosition}
      {...otherProps}
    >
      {children}
    </FormLoadingButton>
  );
};

export default AddSectionLoadingButton;

import store from 'core/store';
import { actions } from '../slice';
import type { ProcessFilterNameType } from '../../components/details/Actions/Filter/ViewSettings/ProcessFields';

export const toggleProcessItemSectionsVisibility = (
  itemId: string,
  name: ProcessFilterNameType
) => {
  store.dispatch(actions.toggleProcessItemSectionsVisibility({ itemId, name }));
};

// Custom Utilites
import { generateObjectId } from 'core/utilities/helper/id';

// Custom Types
import type {
  BannerItemButtonDataProps,
  BannerItemButtonProps,
  BannerItemDataProps,
  BannerItemProps,
} from 'features/banner/banners/types';

export const initialBannerItemButton: BannerItemButtonProps = {
  id: '',
  data: {
    link: '',
    title: '',
  },
};

export const genBannerItemButton = (
  props?: Partial<BannerItemButtonDataProps>
): BannerItemButtonProps => ({
  id: generateObjectId(),
  data: {
    link: '',
    title: '',
    ...props,
  },
});

export const initialBannerItem: BannerItemProps = {
  id: generateObjectId(),
  data: {
    buttons: [genBannerItemButton(), genBannerItemButton()],
    description: '',
    image: null,
    isActive: true,
    link: '',
    relatedMediaId: '',
    sortNumber: 0,
    title: 'بنر جدید',
  },
};

export const genBannerItem = (
  props?: Partial<BannerItemDataProps>
): BannerItemProps => ({
  id: generateObjectId(),
  data: {
    ...initialBannerItem.data,
    ...props,
  },
});

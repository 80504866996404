import { useCallback, useEffect, useState } from 'react';

// Types
import type { FC } from 'react';

// Custom Common Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import DataGrid from 'core/components/shared/DataGrid';
import RouteLink from 'core/components/shared/Link/RouteLink';
import RowStack from 'core/components/shared/Stack/RowStack';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Types
import type { DataGridProps } from 'core/components/shared/DataGrid';
import type { PatternItemProps } from 'features/appBuilder/patterns/types/item';
import type { PatternType } from 'features/appBuilder/patterns/core/types/item';
import type { PatternItemLinkedPagesProps } from 'features/appBuilder/patterns/types/linkedPages';
import type {
  DataGridColumn,
  DataGridRowValueProps,
} from 'core/components/shared/DataGrid/dataGrid';

export interface PatternContainersDataGridProps
  extends Omit<DataGridProps, 'columns' | 'rows'> {
  items: PatternItemProps[];
  type: PatternType | '';
}

const columns: DataGridColumn[] = [
  {
    field: 'col1',
    headerTitle: 'عنوان',
    width: 250,
    renderCell: (params) =>
      params.row.col3 ? (
        <RouteLink
          href={`/pattern/${params.row.col3}/${params.row.id}`}
          sx={{ color: 'inherit' }}
          underline='hover'
          state={params.row.id}
        >
          {params.row.col1}
        </RouteLink>
      ) : (
        <BodyTwo>{params.row.col1}</BodyTwo>
      ),
  },
  {
    field: 'col2',
    headerTitle:
      'محل استفاده (نام صفحات سفارشی و قالبهایی که از این پترن استفاده کرده‌اند.)',
    renderCell: (params) => (
      <RowStack spacing={0.5} whiteSpace='nowrap'>
        {(params.row.col2 as PatternItemLinkedPagesProps[]).map(
          (linked, index) =>
            (linked.main || linked.deps.join(',').length > 0) && (
              <BodyTwo key={`${linked.main}${index}`}>
                {linked.main}
                {linked.deps && linked.deps.length > 0 ? (
                  <span>{`(${linked.deps.join('، ')})`}</span>
                ) : (
                  ''
                )}
                {index + 1 < params.row.col2.length ? ' - ' : ''}
              </BodyTwo>
            )
        )}
      </RowStack>
    ),
  },
];

const PatternListsDataGrid: FC<PatternContainersDataGridProps> = (props) => {
  // Props
  const { items, type, ...otherProps } = props;

  // States
  const [rows, setRows] = useState<DataGridRowValueProps[]>([]);

  // Hooks
  const loading = useSelectLoading();
  const generateRows = useCallback(() => {
    const rowsToSet: DataGridRowValueProps[] = [];

    items.forEach(({ id, data }) => {
      const row = {
        id,
        col1: data?.patternTitle,
        col2: data?.linked || [],
        col3: type,
      };
      rowsToSet.push(row);
    });

    setRows(rowsToSet);
  }, [items, type]);

  useEffect(() => {
    generateRows();
  }, [items, generateRows]);

  return (
    <DataGrid
      fetching={loading && rows.length === 0}
      headProps={{
        sx: { backgroundColor: '#D8E8F1' },
      }}
      columns={columns}
      rows={rows}
      {...otherProps}
    />
  );
};

export default PatternListsDataGrid;

import type { FC } from 'react';
import type { Editor } from '@tiptap/react';

// Core Components
import EditorHighlightColorIconButton from 'core/components/shared/Editor/Toolbar/IconButton/Color/Highlight';
import EditorFontColorIconButton from 'core/components/shared/Editor/Toolbar/IconButton/Color/Font';

// Component Types
interface EditorColorIconButtonProps {
  editor: Editor | null;
}

const EditorColorIconButton: FC<EditorColorIconButtonProps> = ({ editor }) => {
  return (
    <>
      <EditorFontColorIconButton editor={editor} />
      <EditorHighlightColorIconButton editor={editor} />
    </>
  );
};

export default EditorColorIconButton;

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

// HOC
import WithAuth from 'core/components/shared/HOC/WithAuth';

// Core Components
import Box from 'core/components/base/layout/Box';
import LoadingButton from 'core/components/base/inputs/LoadingButton';
import Paper from 'core/components/base/surfaces/Paper';
import Slide from 'core/components/base/utils/Slide';
import TextField from 'core/components/base/inputs/TextField';

// Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import RouteLink from 'core/components/shared/Link/RouteLink';
import CenterBox from 'core/components/shared/Box/CenterBox';
import HeadingOne from 'core/components/shared/Typography/HeadingOne';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import Notification from 'core/components/shared/Notification';

// Hooks
import { useAppDispatch } from 'core/hooks/redux';
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// Validation Schema
import PasswordValidationSchema from 'features/manager/auth/validations/Password';

// Custom Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { initializeAuth } from 'core/store/slices/core/auth';
import { setAppAlert, setAppLoading } from 'core/utilities/helper';
import {
  login,
  requestPasswordRecovery,
} from 'features/manager/auth/utilities/api/login';

import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';

interface PasswordLoginProps extends WithAuthProps {}
interface PasswordLoginFormProps {
  password: string;
}

const filePath = '/components/pages/PasswordLogin.tsx';
const PasswordLogin: React.FC<PasswordLoginProps> = (props) => {
  // States
  const [email, setEmail] = React.useState<string>('');
  const [mode, setMode] = React.useState<'password' | 'recovered'>('password');

  // Hooks
  const loading = useSelectLoading();
  const locationState = useLocation().state;
  const navigate = useNavigate();
  const pageWrapperRef = React.useRef(null);
  const dispatch = useAppDispatch();

  const resolver = useYupValidationResolver(PasswordValidationSchema);

  const { control, handleSubmit } = useForm<PasswordLoginFormProps>({
    mode: 'onTouched',
    resolver,
    defaultValues: {
      password: '',
    },
  });

  React.useEffect(() => {
    if (locationState && typeof locationState === 'object') {
      if ('email' in locationState) {
        const { email: emailToSet } = locationState as { email: string };

        setEmail(emailToSet);
      }
    } else {
      navigate('/login');
    }
  }, [locationState]);

  const handleRecoveryRequest = async () => {
    if (!email) return navigate('/login');
    setAppLoading(true);

    const result = await requestPasswordRecovery(email);

    if (result === 200) {
      setMode('recovered');
      setAppAlert('لینک بازیابی کلمه عبور برای شما ارسال شد.', 'success');
    } else {
      setAppAlert('انجام عملیات درخواستی با خطا روبرو شد.');
    }

    setAppLoading(false);
  };

  const onSubmit: SubmitHandler<PasswordLoginFormProps> = async ({
    password,
  }) => {
    if (!email) return navigate('/login');
    setAppLoading(true);

    const response = await login(email, password);
    const { status, landingRoute } = response;

    if (status === 200) {
      dispatch(initializeAuth({ isAuthenticated: true }));

      if (window) window.location.href = landingRoute;
    } else if (status === 302) {
      navigate('/password-login/2fa', { state: { email } });
    } else if (status === 403) {
      setAppAlert('شما دارای دسترسی لازم جهت ورود به اپلیکیشن نمی‌باشید.');
    } else if (status === 412) {
      setAppAlert('اطلاعات وارد شده صحیح نیست');
    } else if (status === 403) {
      setAppAlert('به دلیل عدم دسترسی، مجاز به ورود نمی‌باشید.');
    }

    setAppLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>ورود به داشبورد مدیریتی</title>
      </Helmet>
      <CenterBox
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        innerRef={pageWrapperRef}
      >
        <Slide container={pageWrapperRef.current} direction='left' in={true}>
          <Paper
            sx={{
              boxShadow: 'none',
              borderRadius: '1rem',
              padding: '3rem 6rem',
            }}
          >
            {mode === 'password' ? (
              <ColumnStack spacing={3}>
                <Box>
                  <HeadingOne gutterBottom textAlign='center' variant='h6'>
                    ورود
                  </HeadingOne>
                  <BodyOne textAlign='center'>ورود با ایمیل {email}</BodyOne>
                </Box>
                <Box>
                  <InputLabel>کلمه عبور</InputLabel>
                  <Controller
                    control={control}
                    name='password'
                    render={({ field, fieldState }) => (
                      <TextField
                        error={
                          fieldState.isTouched && fieldState.error?.message
                            ? true
                            : false
                        }
                        dir='ltr'
                        disabled={loading || !email}
                        fullWidth
                        helperText={
                          fieldState.isTouched && fieldState.error?.message
                        }
                        type='password'
                        placeholder='Password'
                        {...field}
                      />
                    )}
                  />
                </Box>
                <ColumnStack spacing={1}>
                  <Controller
                    control={control}
                    name='password'
                    render={({ field }) => (
                      <LoadingButton
                        disabled={!email || !field.value}
                        fullWidth
                        loading={loading}
                        sx={{ height: '3rem', width: '21rem' }}
                        type='submit'
                      >
                        تأیید
                      </LoadingButton>
                    )}
                  />
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <BodyOne
                      onClick={
                        !email || loading ? undefined : handleRecoveryRequest
                      }
                      sx={({ palette }) => ({
                        color:
                          !email || loading ? 'text.disabled' : 'text.primary',
                        cursor: !email || loading ? 'auto' : 'pointer',
                        ':hover': {
                          color:
                            !email || loading
                              ? 'text.disabled'
                              : palette.info.light,
                        },
                      })}
                    >
                      بازیابی کلمه عبور
                    </BodyOne>
                    <RouteLink
                      href='/login'
                      sx={({ palette }) => ({
                        ':hover': {
                          color: palette.info.light,
                        },
                      })}
                    >
                      بازگشت
                    </RouteLink>
                  </Box>
                </ColumnStack>
              </ColumnStack>
            ) : (
              <ColumnStack spacing={2}>
                <Box>
                  <HeadingOne textAlign='center'>!</HeadingOne>
                  <BodyOne textAlign='center'>
                    لینک بازیابی کلمه عبور به ایمیل شما ارسال شد.
                  </BodyOne>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                  <RouteLink
                    href='/login'
                    sx={({ palette }) => ({
                      ':hover': {
                        color: palette.info.light,
                      },
                    })}
                  >
                    بازگشت
                  </RouteLink>
                </Box>
              </ColumnStack>
            )}
          </Paper>
        </Slide>
      </CenterBox>
      <Notification />
    </>
  );
};

export default WithAuth(PasswordLogin, { filePath });

import { useState, useEffect } from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Types
import type { FC } from 'react';
import type { AutocompleteChangeReason } from '@mui/material';

// Custom Core Components
import FormHelperText from 'core/components/base/inputs/FormHelperText';
import TextField from 'core/components/base/inputs/TextField';
import Autocomplete from 'core/components/base/inputs/Autocomplete';
import CircularProgress from 'core/components/base/feedback/CircularProgress';

// Custom Hooks
import useSummaryApi from 'core/hooks/api/useSummary';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import { findById } from 'core/utilities/helper';

// Custom Icons
import ExpandMoreIcon from 'core/components/icons/ExpandMore';

// Custom Types
import type { AutocompleteProps } from '@mui/material/Autocomplete';
import type { TextFieldProps } from 'core/components/base/inputs/TextField';
import { ApiGetSummaryResponse } from 'core/types/api/hook/response';

export interface GenericAutocompleteProps<T>
  extends Omit<
    AutocompleteProps<
      T,
      boolean | undefined,
      boolean | undefined,
      boolean | undefined
    >,
    | 'options'
    | 'renderInput'
    | 'onChange'
    | 'onBlur'
    | 'defaultValue'
    | 'multiple'
  > {
  textFieldProps?: TextFieldProps;
  queryKey: string[];
  helperText?: string;
  defaultValue?: string;
  placeholder?: string;
  disablePopupCustomIcon?: boolean;
  height?: string;
  error?: boolean;
  onBlur?: (selectedId: string) => void;
  onOptionsChange?: (options: any[]) => void;
  onChange?: (selectedId: string, selectedOpt?: any | null) => void;
  apiHandler: (signal?: AbortSignal) => Promise<ApiGetSummaryResponse<T>>;
}

const SingleAutocomplete: FC<GenericAutocompleteProps<any>> = (props) => {
  // Props
  const {
    defaultValue,
    placeholder = 'انتخاب کنید',
    helperText,
    error,
    sx,
    disabled,
    disablePopupCustomIcon,
    textFieldProps,
    queryKey,
    getOptionLabel = (opt) => opt?.data?.title || '',
    onBlur,
    onOptionsChange,
    onChange,
    apiHandler,
    ...otherProps
  } = props;
  const { InputProps, ...otherTextFieldProps } = textFieldProps || {};
  const { endAdornment, ...otherInputProps } = InputProps || {};

  // States
  const [value, setValue] = useState<Record<string, any> | null>(null);
  const [open, setOpen] = useState(false);

  // Hooks
  const loading = useSelectLoading();
  const { palette } = useTheme();
  const {
    data: list = [],
    isFetching,
    status,
    refetch,
  } = useSummaryApi(apiHandler, queryKey);

  useEffect(() => {
    if (onOptionsChange) onOptionsChange(list);
  }, [list]);

  useEffect(() => {
    if (defaultValue && status === 'success' && list.length > 0) {
      const defValOpt = findById(list, defaultValue);
      setValue(defValOpt);
    }
  }, [status, defaultValue]);

  useEffect(() => {
    if (open) refetch();
  }, [open]);

  // Utilities
  const handleChange = (
    newValue: Record<string, any> | null,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'clear') {
      setValue(null);
      if (onChange) onChange('');
      return;
    }
    if (newValue) {
      setValue(newValue);
      if (onChange) onChange(newValue.id || '', newValue);
    }
  };

  return (
    <>
      <Autocomplete
        disabled={disabled}
        loadingText='در حال دریافت...'
        noOptionsText={`چیزی یافت نشد.`}
        open={open}
        onBlur={() => onBlur && onBlur(value ? value.id : '')}
        onOpen={() => {
          if (!disabled) setOpen(true);
        }}
        onClose={() => setOpen(false)}
        popupIcon={disablePopupCustomIcon ? undefined : <ExpandMoreIcon />}
        isOptionEqualToValue={(option, value) =>
          value && option.id === value.id
        }
        getOptionLabel={getOptionLabel}
        options={list}
        loading={isFetching || loading || disabled}
        value={value}
        onChange={(e, newValue, reason) => handleChange(newValue, reason)}
        sx={{
          ...sx,
          '& .MuiOutlinedInput-root': {
            border: error ? '.1px solid red' : '',
            borderRadius: '.5rem',
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isFetching ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
              ...otherInputProps,
            }}
            {...otherTextFieldProps}
          />
        )}
        {...otherProps}
      />
      {helperText && (
        <FormHelperText
          sx={{ color: error ? palette.error.main : palette.text.primary }}
        >
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default SingleAutocomplete;

import {
  memo,
  useCallback,
  useMemo,
  useState,
  type FC,
  useEffect,
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactSortable } from 'react-sortablejs';
import cloneDeep from 'lodash/cloneDeep';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Collapse from 'core/components/base/utils/Collapse';
import InputBase from 'core/components/base/inputs/InputBase';
import Divider from 'core/components/base/display/Divider';

// Custom Common Components
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Icon Components
import DragIcon from 'core/components/icons/Drag';
import VisibilityOffIcon from 'core/components/icons/VisibilityOff';
import AddLayerTwoIcon from 'core/components/icons/AddLayerTwo';

// Custom Feature Components
import FormSectionHeaderActions from 'features/form/forms/components/details/item/Section/HeaderActions';
import FormSectionActions from 'features/form/forms/components/details/item/Section/Actions';
import FormInputComponent from 'features/form/forms/components/details/item/Input';

// Custom Styles
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { forLoop } from 'core/utilities/helper';
import { getInputsIndexMap } from 'features/form/forms/utilities/input/helper';
import { useAppSelector } from 'core/hooks/redux';
import {
  setFormActionMode,
  setFormCurrentSectionId,
} from 'features/form/forms/store/actions';

// Custom Types
import type { RoundPaperProps } from 'core/components/shared/Paper/RoundPaper';
import type { FormProps } from 'features/form/forms/types/item';
import type { FormSectionProps } from 'features/form/forms/types/item/section';
import type { FormInput } from 'features/form/forms/types/item/input';
export interface FormItemSectionProps extends RoundPaperProps {
  section: FormSectionProps;
  index: number;
}

const FormItemSection: FC<FormItemSectionProps> = (props) => {
  // Props
  const { section, index, sx, ...otherProps } = props;

  // Context
  const [sortStatus, setSortStaus] = useState<0 | 1>(1);
  const [sortedInputs, setSortedInputs] = useState<FormInput[]>([]);
  const actionMode = useAppSelector((store) => store.form.actionMode);
  const sectionInputsCount = useAppSelector(
    (store) => store.form.sectionInputsCount
  );
  const isCollapsed = useAppSelector(
    (store) => store.form?.sectionCollapsed?.[section.id] || false
  );
  const currentSectionId = useAppSelector(
    (store) => store.form.currentSectionId
  );

  // Hooks
  const { control, getValues, setValue } = useFormContext<FormProps>();

  const editMode = useMemo(
    () => actionMode === 'SECTION' && currentSectionId === section.id,
    [actionMode, section.id, currentSectionId]
  );

  // Utilities
  const handleSortMode = useCallback(() => {
    setFormActionMode('INPUT_SORT');
    setFormCurrentSectionId(section.id);
  }, [section.id]);

  const handleSortInputs = useCallback(() => {
    if (sortStatus === 0) {
      let clonedInputs = cloneDeep(getValues('data.inputs'));
      const sortedInpsIds = sortedInputs.map((inp) => inp.id);

      forLoop(clonedInputs, (clonedInput, clonedInputIndex) => {
        if (sortedInpsIds.includes(clonedInput.id)) {
          let index = sortedInpsIds.findIndex(
            (inputId) => inputId === clonedInput.id
          );

          if (index > -1) {
            const inputToSave = cloneDeep(clonedInput);
            inputToSave.data.sortIndex = index;
            clonedInputs[clonedInputIndex] = inputToSave;
          }
        }
      });

      clonedInputs = clonedInputs.sort((inpA, inpB) =>
        inpA.data.sortIndex > inpB.data.sortIndex ? 1 : -1
      );

      const inputsIndexMap = getInputsIndexMap(clonedInputs);

      handleSortMode();

      setValue('data.inputs', clonedInputs);
      setValue('data.inputsIndexMap', inputsIndexMap);
      setSortStaus(1);
    }
  }, [sortStatus, sortedInputs, handleSortMode, getValues, setValue]);

  useEffect(() => {
    handleSortInputs();
  }, [handleSortInputs]);

  // Render
  return (
    <RoundPaper
      sx={{ p: 0, backgroundColor: '#e7e7e7', ...sx }}
      {...otherProps}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '6.5rem',
          padding: '1rem',
          gap: 1,
          transition: 'all ease-in-out 200ms',
        }}
      >
        <RowStack sx={{ alignItems: 'center' }} spacing={1}>
          {actionMode === 'SECTION_SORT' && (
            <DragIcon className='section_sort_handle' sx={{ cursor: 'grab' }} />
          )}
          {editMode ? (
            <Controller
              control={control}
              defaultValue={section.data.title}
              name={`data.sections.${index}.data.title`}
              render={({ field }) => (
                <InputBase
                  sx={{
                    fontSize: '1.25rem',
                    fontWeight: 500,
                    ':before': {
                      borderBottom: 'none',
                    },
                  }}
                  {...field}
                />
              )}
            />
          ) : (
            <RowStack spacing={1} sx={{ alignItems: 'center', mr: '4rem' }}>
              <PageTitle sx={{ whiteSpace: 'nowrap' }}>
                {section.data.title || 'بدون عنوان'}
              </PageTitle>
              {section.data.isHidden && (
                <VisibilityOffIcon sx={{ color: 'text.secondary' }} />
              )}
              {section.data.repeatCount > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '0.25rem',
                    color: 'text.secondary',
                    backgroundColor: 'background.default',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '2.25rem',
                      height: '2.25rem',
                    }}
                  >
                    <AddLayerTwoIcon />
                  </Box>
                  <Divider flexItem orientation='vertical' />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '2.25rem',
                      height: '2.25rem',
                    }}
                  >
                    {section.data.repeatCount.toPersian()}
                  </Box>
                </Box>
              )}
            </RowStack>
          )}
        </RowStack>
        <RowStack
          direction='row-reverse'
          sx={{
            flexGrow: 1,
            pb: editMode ? '0.25rem' : undefined,
            overflowX: 'auto',
            overflowY: 'hidden',
            ...customRoundedScrollbarStyles,
          }}
        >
          <FormSectionHeaderActions index={index} section={section} />
        </RowStack>
      </Box>
      <Collapse in={isCollapsed}>
        <Controller
          control={control}
          name='data.inputs'
          render={({ field }) => {
            const list = field.value.filter(
              (inp) => inp.data.sectionId === section.id
            );

            return (
              <ReactSortable
                animation={200}
                list={list}
                setList={setSortedInputs}
                onStart={() => setSortStaus(1)}
                onEnd={() => setSortStaus(0)}
                handle='.handle_sort_input'
              >
                {list.map((input, index) => (
                  <FormInputComponent
                    key={input.id}
                    input={input}
                    sx={{
                      mx: '2rem',
                      mb:
                        index < sectionInputsCount[section.id] - 1
                          ? '1rem'
                          : undefined,
                      mt:
                        input.id.includes('draft') && index > 0
                          ? '1rem'
                          : undefined,
                    }}
                  />
                ))}
              </ReactSortable>
            );
          }}
        />
        <FormSectionActions
          sectionId={section.id}
          sx={{ ml: 'auto', width: 'fit-content' }}
        />
      </Collapse>
    </RoundPaper>
  );
};

export default memo(FormItemSection, isSame);

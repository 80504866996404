import { type FC, useState, useMemo, useCallback, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Controller,
  useFormContext,
  type SubmitHandler,
} from 'react-hook-form';

// Custom Core Components
import Collapse from 'core/components/base/utils/Collapse';
import Button from 'core/components/base/inputs/Button';
import LoadingButton from 'core/components/base/inputs/LoadingButton';

// Custom Common Components
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import CollapseIconButton from 'core/components/shared/IconButton/Collapse';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Feature Components
import FormSettingsInfo from 'features/form/forms/components/details/item/Settings/Info';
import FormSettingsAccess from 'features/form/forms/components/details/item/Settings/Access';
import FormSettingsAudienceFeedback from 'features/form/forms/components/details/item/Settings/AudienceFeedback';
import FormSettingsViewerFeedback from 'features/form/forms/components/details/item/Settings/ViewerFeedback';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useDialogContext } from 'core/store/context/DialogProvider';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { setForm, setFormActionMode } from 'features/form/forms/store/actions';
import { setAppAlert } from 'core/utilities/helper';
import {
  useAddFormMutation,
  useEditFormMutation,
} from 'features/form/forms/hooks';

// Custom Types
import type { FormDataProps, FormProps } from 'features/form/forms/types/item';

export interface FormItemSettingsProps {
  mode?: 'edit' | 'add';
}

const FormItemSettings: FC<FormItemSettingsProps> = (props) => {
  // Props
  const { mode = 'add' } = props;

  // Context
  const loading = useSelectLoading();
  const actionMode = useAppSelector((store) => store.form.actionMode);
  const formId = useAppSelector((store) => store.form.formId);
  const data = useAppSelector((store) => store.form.data);
  const isPublic = useAppSelector(
    (store) => store.form.data?.audienceGroupsIds.length === 0
  );
  const { onOpenDialog, onCloseDialog } = useDialogContext();
  const { control, getValues, handleSubmit, reset } =
    useFormContext<FormProps>();

  // States
  const [collapsed, setCollapsed] = useState<boolean>(
    mode === 'add' ? true : false
  );
  // Hooks
  const navigate = useNavigate();
  const { mutate: handleAddForm } = useAddFormMutation({
    autoAlert: { mode: 'create', name: 'فرم' },
    stayOnLoadingAfterMutate: true,
    onSuccess: ({ doc }) => {
      if (!doc) return;
      navigate(`/forms/${doc.id}`, {
        replace: true,
      });
    },
  });
  const { mutate: handleEditForm } = useEditFormMutation({
    autoAlert: { mode: 'edit', name: 'فرم' },
    onSuccess: ({ doc }) => {
      if (!doc) return;
      setForm(doc.data);
      setFormActionMode('INITIAL');
    },
    onError: () => {
      navigate('/forms');
    },
  });

  const disabled = useMemo(
    () => loading || !['SETTINGS', 'INITIAL'].includes(actionMode),
    [loading, actionMode]
  );

  // Utilities
  const isValid = (data: FormDataProps) => {
    let valid = true;

    if (
      !data.title ||
      !data.slug ||
      !data.skinId ||
      data.managerGroupsIds.length < 1
    )
      valid = false;

    if (
      data.audienceGroupsIds.length >= 1 &&
      data.audienceFeedbackTitles.length < 1
    )
      valid = false;

    return valid;
  };
  const toggleCollapse = useCallback(
    () => setCollapsed(!collapsed),
    [collapsed]
  );
  const handleOpenCancelDialog = () => {
    onOpenDialog({
      onSubmit: handleCancel,
      description: 'تغییرات ایجاد شده، اعمال نخواهند شد.',
      submitTitle: 'لغو تغییرات',
    });
  };

  const handleCancel = () => {
    if (mode === 'add') navigate(-1);
    else {
      setFormActionMode('INITIAL');
      reset({
        id: formId,
        data: {
          ...data,
          sections: getValues('data.sections'),
          inputs: getValues('data.inputs'),
          inputsIndexMap: getValues('data.inputsIndexMap'),
        },
      });
    }
    onCloseDialog();
  };

  const handleFormSubmit: SubmitHandler<FormProps> = async (form) => {
    const dataIsValid = isValid(form.data);

    if (!dataIsValid) {
      setAppAlert('لطفا ابتدا فیلدهای اجباری را تکمیل کنید.', 'warning');

      return;
    }

    if (form.data.hasFeedback) {
      if (isPublic && form.data.audienceGroupsIds.length > 0) {
        setAppAlert('تبدیل فرم به فرم مخاطبان ممکن نیست.');
        return;
      } else if (!isPublic && form.data.audienceGroupsIds.length === 0) {
        setAppAlert('تبدیل فرم به فرم عمومی ممکن نیست.');
        return;
      }
    }

    const { steps, sections, inputs, inputsIndexMap, ...otherData } = form.data;

    let formToSave = {
      id: form.id,
      data: { ...otherData },
    };

    if (mode === 'add') handleAddForm(formToSave.data);
    else handleEditForm(formToSave);
  };

  // Render
  return (
    <RoundPaper
      sx={{
        height: collapsed ? 'fit-content' : '4.5rem',
        transition: `height ${collapsed ? '1.2s' : '0.4s'}`,
        overflow: 'hidden',
      }}
    >
      <ColumnStack>
        <SpaceBetween>
          <PageTitle color='text.secondary'>تنظیمات فرم</PageTitle>
          <CollapseIconButton collapsed={collapsed} onClick={toggleCollapse} />
        </SpaceBetween>
        <Collapse in={collapsed}>
          <ColumnStack spacing={3} sx={{ p: '1rem', alignItems: 'flex-end' }}>
            <FormSettingsInfo disabled={disabled} />
            <FormSettingsAccess disabled={disabled} />
            <Controller
              control={control}
              name='data.audienceGroupsIds'
              defaultValue={[]}
              render={({ field }) =>
                field.value.length > 0 ? (
                  <FormSettingsAudienceFeedback disabled={disabled} />
                ) : (
                  <FormSettingsViewerFeedback disabled={disabled} />
                )
              }
            />
            <RowStack width='fit-content'>
              <Button
                disabled={loading || actionMode !== 'SETTINGS'}
                onClick={handleOpenCancelDialog}
                color='error'
                variant='outlined'
                sx={{ width: '8rem' }}
              >
                انصراف
              </Button>
              <LoadingButton
                disabled={loading || actionMode !== 'SETTINGS'}
                loading={loading && actionMode === 'SETTINGS'}
                color='info'
                variant='outlined'
                sx={{ width: '8rem' }}
                onClick={handleSubmit(handleFormSubmit)}
              >
                ذخیره
              </LoadingButton>
            </RowStack>
          </ColumnStack>
        </Collapse>
      </ColumnStack>
    </RoundPaper>
  );
};

export default memo(FormItemSettings, isSame);

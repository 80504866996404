import {
  Divider as MuiDivider,
  DividerProps as MuiDividerProps,
  styled,
} from '@mui/material';

export interface DividerProps extends MuiDividerProps {}

const Divider = styled(MuiDivider)<DividerProps>(() => ({}));

export default Divider;

// Custom Utilities
import getEndpoint from 'core/utilities/helper/getEndpoint';
import apiHandler from 'core/utilities/apiHandler';
import { getDoc, getDocs } from 'core/utilities/apiHandler/helper';
import { bakeAudienceFolder } from 'features/audience/folders/utilities/api/baker';
import { transformAudienceFolder } from 'features/audience/folders/utilities/transformer';

// Custom Types
import type { ApiAudienceFoldersProps } from 'features/audience/folders/types/api';
import type {
  ApiGetDocResponse,
  ApiGetDocsResponse,
} from 'core/types/api/hook/response';
import type {
  AudienceFolderDataProps,
  AudienceFolderProps,
} from 'features/audience/folders/types';

export const getAudienceFolders = async (
  signal: AbortSignal,
  queries?: Record<string, any>
): Promise<ApiGetDocsResponse<AudienceFolderProps>> => {
  const { getAudienceFoldersURl } = getEndpoint();

  return await getDocs<ApiAudienceFoldersProps[], AudienceFolderProps>(
    getAudienceFoldersURl,
    {
      queries,
      signal,
      returnMutationFn: (status, data) => ({
        status,
        list: data ? data.map(transformAudienceFolder) : [],
      }),
    }
  );
};

/**
 * Fetches a single audience folder by its ID from the API.
 *
 * @param {string} folderId - The ID of the audience folder to fetch.
 * @returns {Promise<ApiGetDocResponse<AudienceFolderProps>>}
 * An object containing the HTTP status and the fetched audience folder.
 */
export const getAudienceFolder = async (
  folderId: string,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<AudienceFolderProps>> => {
  const { getAudienceFolderURL } = getEndpoint();
  const endpoint = `${getAudienceFolderURL}/${folderId}`;

  return await getDoc<ApiAudienceFoldersProps, AudienceFolderProps>(endpoint, {
    signal,
    returnMutationFn: (status, data) => ({
      status,
      doc: data ? transformAudienceFolder(data) : undefined,
    }),
  });
};

export const addAudienceFolder = async (
  folder: AudienceFolderDataProps
): Promise<{ status: number }> => {
  const { addAudienceFolderURL: endpoint } = getEndpoint();
  const baked = bakeAudienceFolder(folder);

  const { status } = await apiHandler.post(endpoint, baked);

  return { status };
};

/**
 * Edits an existing audience folder in the API.
 *
 * @param {string} folderId - The ID of the audience folder to edit.
 * @param {AudienceFolderDataProps} folder - The updated audience folder data.
 * @returns {Promise<{ status: number; audienceFolder?: AudienceFolderProps }>}
 * An object containing the HTTP status and the updated audience folder.
 */
export const editAudienceFolder = async (
  folderId: string,
  folder: AudienceFolderDataProps
): Promise<{ status: number; audienceFolder?: AudienceFolderProps }> => {
  const { editAudienceFolderURL } = getEndpoint();
  const endpoint = `${editAudienceFolderURL}/${folderId}`;
  const baked = bakeAudienceFolder(folder);
  const { status, data } = await apiHandler.patch(endpoint, baked);
  const { docs } = data as { docs: ApiAudienceFoldersProps };
  const audienceFolder: AudienceFolderProps = transformAudienceFolder(docs);
  return { status, audienceFolder };
};

export const deleteAudienceFolders = async (
  foldersIds: string[]
): Promise<{ status: number }> => {
  const { deleteAudienceFoldersByIdURL: endpoint } = getEndpoint();

  const { status } = await apiHandler.delete(endpoint, { ids: foldersIds });

  return { status };
};

import {
  TableCell as MuiTableCell,
  TableCellProps as MuiTableCellProps,
  styled,
} from '@mui/material';

export interface TableCellProps extends MuiTableCellProps {}

const TableCell = styled(MuiTableCell)<TableCellProps>(() => ({
  borderBottom: 'none',
}));

export default TableCell;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface HiddenIconProps extends SvgIconProps {}

const HiddenIcon: React.FC<HiddenIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M20.41,21.08a.71.71,0,0,1-.28-.06.82.82,0,0,1-.25-.16L3.38,4.36a.78.78,0,0,1-.2-.53.74.74,0,0,1,.22-.52.73.73,0,0,1,.52-.22.73.73,0,0,1,.52.21l16.5,16.5a.72.72,0,0,1,.21.38.82.82,0,0,1,0,.44.81.81,0,0,1-.28.33A.77.77,0,0,1,20.41,21.08Zm-8.62-6.2L9.36,12.46a.05.05,0,0,0,0,0H9.25l0,0a.13.13,0,0,0,0,.06A3,3,0,0,0,11.71,15a.07.07,0,0,0,.06,0l0,0a.11.11,0,0,0,0-.05Zm.75-5.61L15,11.7a.05.05,0,0,0,0,0h.06a.08.08,0,0,0,0,0,.13.13,0,0,0,0-.06A3,3,0,0,0,14.29,10a3.08,3.08,0,0,0-1.67-.85h-.06l0,0a.13.13,0,0,0,0,.06Z' />
    <path d='M23.19,12.9a1.56,1.56,0,0,0,.23-.82,1.45,1.45,0,0,0-.24-.81,15.83,15.83,0,0,0-4.65-4.71,11.79,11.79,0,0,0-6.37-2,10.39,10.39,0,0,0-3.37.55l-.08.05a.12.12,0,0,0,0,.08.17.17,0,0,0,0,.1.14.14,0,0,0,.05.08l2.21,2.21a.14.14,0,0,0,.08,0h.1a4.52,4.52,0,0,1,2.28,0,4.49,4.49,0,0,1,3.12,3.12,4.52,4.52,0,0,1,0,2.28.17.17,0,0,0,0,.1.14.14,0,0,0,.05.08l3.18,3.19a.19.19,0,0,0,.13.05.17.17,0,0,0,.12,0A16.34,16.34,0,0,0,23.19,12.9Zm-11,3.69a4.52,4.52,0,0,1-2-.46,4.47,4.47,0,0,1-1.57-1.26A4.49,4.49,0,0,1,7.8,11v-.09a.22.22,0,0,0,0-.08L4.61,7.71a.17.17,0,0,0-.12,0,.16.16,0,0,0-.13,0,18.94,18.94,0,0,0-3.17,3.55,1.52,1.52,0,0,0-.27.83,1.57,1.57,0,0,0,.24.84,15.71,15.71,0,0,0,4.61,4.71,11.58,11.58,0,0,0,6.39,2,11.25,11.25,0,0,0,3.4-.55.14.14,0,0,0,.08-.05.22.22,0,0,0,0-.08.38.38,0,0,0,0-.1l0-.08-2.23-2.22a.14.14,0,0,0-.08-.05.17.17,0,0,0-.1,0A4.73,4.73,0,0,1,12.17,16.59Z' />
  </IconBuilder>
);

export default HiddenIcon;

import { type FC } from 'react';
import { useAppSelector } from 'core/hooks/redux';

// Custom Core Components
import IconButton from 'core/components/base/inputs/IconButton';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { IconButtonProps } from 'core/components/base/inputs/IconButton';
export interface PatternToolbarIconButtonProps extends IconButtonProps {}

const PatternToolbarIconButton: FC<PatternToolbarIconButtonProps> = (props) => {
  // Props
  const { children, sx, ...otherProps } = props;

  // Hooks
  const loading = useSelectLoading();
  const selection = useAppSelector((state) => state.table.data.selection);

  return (
    <IconButton
      disableRipple
      disabled={loading || selection.length === 0}
      sx={({ palette }) => ({
        borderRadius: '0.5rem',
        backgroundColor: palette.grey[300],
        ':hover': {
          backgroundColor: palette.grey[200],
        },
        ':disabled': {
          backgroundColor: palette.grey[100],
        },
        ...sx,
      })}
      {...otherProps}
    >
      {children}
    </IconButton>
  );
};

export default PatternToolbarIconButton;

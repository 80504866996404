import { useState, type FC } from 'react';

// Custom Core Components
import Box from 'core/components/base/layout/Box';

// Custom Shared Components
import FormFeedbacksListFilterActions from 'features/form/feedbacks/components/list/Actions/Filter';
import FormFeedbacksListNormalActions from 'features/form/feedbacks/components/list/Actions/Normal';

// Custom Types
export interface FormFeedbacksListActionsProps {}

const FormFeedbacksListActions: FC<FormFeedbacksListActionsProps> = () => {
  // Hooks
  const [mode, setMode] = useState<'filter' | 'normal'>('normal');

  return (
    <Box
      sx={{
        width: '100%',
        overflowX: mode === 'filter' ? 'visible' : 'hidden',
      }}
    >
      <FormFeedbacksListNormalActions
        in={mode === 'normal'}
        onOpenFilter={() => setMode('filter')}
      />
      <FormFeedbacksListFilterActions
        in={mode === 'filter'}
        onClose={() => setMode('normal')}
      />
    </Box>
  );
};

export default FormFeedbacksListActions;

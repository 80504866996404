import * as React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import type { SubmitHandler } from 'react-hook-form';

// Custom Hooks
import useMount from 'core/hooks/useMount';

// Feature Components
import AudienceGroupForm from 'features/audience/groups/components/details/Form';

// Custom Common Components
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import HeadingTwo from 'core/components/shared/Typography/HeadingTwo';
import SubmitLoadingButton from 'core/components/shared/LoadingButton/Submit';

// Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { initialAudienceGroup } from 'features/audience/groups/utilities';
import { setAppAlert, setAppLoading } from 'core/utilities/helper';
import {
  useAudienceGroupDoc,
  useEditAudienceGroupMutation,
} from 'features/audience/groups/hooks';

// Custom Types
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';
import type { AudienceGroupDataProps } from 'features/audience/groups/types';

interface EditAudienceGroupProps extends WithAuthProps {}

const EditAudienceGroup: React.FC<EditAudienceGroupProps> = (props) => {
  // Context
  const loading = useSelectLoading();

  // Hooks
  const navigate = useNavigate();
  const { id: audienceGroupId } = useParams();
  const { data: group } = useAudienceGroupDoc({
    onFetch: (doc) => reset({ title: doc?.data?.title || '' }),
    initialData: initialAudienceGroup(),
  });
  const { mutate: handleEditGroup } = useEditAudienceGroupMutation({
    autoAlert: { mode: 'edit', name: 'گروه مخاطب' },
    redirectAfterSuccessTo: '/audience-groups',
  });
  const formMethods = useForm<AudienceGroupDataProps>({
    mode: 'onTouched',
    defaultValues: { title: '' },
  });
  const { handleSubmit, reset } = formMethods;

  useMount(() => {
    if (audienceGroupId) return;
    setAppAlert('شناسه گروه یافت نشد. مجدداً اقدام به ویرایش کنید.', 'warning');
    setTimeout(() => navigate('/audience-groups'), 2500);
  });

  // Utilities
  const handleFormSubmit: SubmitHandler<AudienceGroupDataProps> = async (
    data
  ) => {
    if (group?.id) {
      setAppLoading(true);
      handleEditGroup({ id: group.id, data: data.title });
    }
  };

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='ویرایش گروه مخاطبان' />
      <PageHeroBox>
        <HeadingTwo>ویرایش گروه مخاطبان</HeadingTwo>
        <Controller
          control={formMethods.control}
          name='title'
          render={({ field }) => (
            <SubmitLoadingButton
              disabled={!field.value}
              loading={loading}
              onClick={handleSubmit(handleFormSubmit)}
            >
              ذخیره
            </SubmitLoadingButton>
          )}
        />
      </PageHeroBox>
      <FormProvider {...formMethods}>
        <AudienceGroupForm />
      </FormProvider>
    </>
  );
};

export default EditAudienceGroup;

import * as React from 'react';

// Core Components
import Box from 'core/components/base/layout/Box';

// Types
import type { Ref } from 'react';
import type { BoxProps } from 'core/components/base/layout/Box';
interface CenterBoxProps extends BoxProps {
  innerRef?: Ref<unknown> | undefined;
}

const CenterBox: React.FC<CenterBoxProps> = (props) => {
  const { children, innerRef, sx, ...otherProps } = props;

  return (
    <Box
      ref={innerRef}
      sx={{
        alignItems: 'center',
        display: 'flex',
        minHeight: '100vh',
        justifyContent: 'center',
        width: '100%',
        overflow: 'hidden',
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
};

export default CenterBox;

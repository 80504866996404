import AntSwitch from 'core/components/shared/Switch/AntSwitch';
import FolderChip from 'features/audience/folders/components/list/Chip';
import type { AudienceFolderProps } from 'features/audience/folders/types';
import type {
  DataGridColumn,
  DataGridRowValueProps,
} from 'core/components/shared/DataGrid/dataGrid';

export const audienceFoldersListHeaderCells = [
  { key: 'title', title: 'عنوان پوشه', width: '5rem', align: 'center' },
  { key: 'reader', title: 'مشاهده کننده', width: '12rem', align: 'center' },
  { key: 'creator', title: 'آپلود کننده', width: '12rem', align: 'center' },
  { key: 'tags', title: 'برچسب فایل', width: '12rem', align: 'center' },
  { key: 'status', title: 'وضعیت', width: '5rem', align: 'center' },
  { key: 'capacity', title: 'حجم پوشه (MB)', width: '9rem', align: 'center' },
];

/**
 * Generates the column definitions for the audience folders data grid.
 *
 * @param {boolean} loading - Indicates whether the data is currently loading.
 * @param {(folderId: string) => void} onToggle - Callback function to handle toggling the status of a folder.
 * @returns {DataGridColumn[]} An array of column definitions for the data grid.
 */
export const getAudienceFoldersColumns = (
  loading: boolean,
  onToggle: (folderId: string) => void
): DataGridColumn[] =>
  audienceFoldersListHeaderCells.map((item) => ({
    field: item.key,
    headerTitle: item.title,
    align: 'center',
    ...(item.key === 'status' && {
      renderCell: ({ row }) => (
        <AntSwitch
          disabled={loading}
          checked={row[item.key]}
          sx={{ marginX: 'auto' }}
          onChange={() => onToggle(row.id)}
        />
      ),
    }),
    ...(['reader', 'creator', 'tags'].includes(item.key) && {
      renderCell: ({ row }) =>
        row[item.key].length === 0 ? (
          '-'
        ) : (
          <FolderChip title={row[item.key] || []} />
        ),
    }),
  }));

/**
 * Transforms an array of audience folder objects into the format required for the data grid rows.
 *
 * @param {AudienceFolderProps[]} [folders=[]] - An array of audience folder objects.
 * @returns {DataGridRowValueProps[]} An array of transformed data grid row values.
 */
export const getAudienceFolderRows = (
  folders: AudienceFolderProps[] = []
): DataGridRowValueProps[] =>
  folders.map((folder) => ({
    id: folder.id,
    title: folder.data.title,
    reader: folder?.data?.reader?.map((group) => group.data.title) || [],
    creator: folder?.data?.creator?.map((group) => group.data.title) || [],
    status: folder.data.isActive,
    capacity: folder.data.capacity.toPersian(),
    tags: folder.data.tags,
  }));

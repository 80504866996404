import cloneDeep from 'lodash/cloneDeep';

// Feature Defaults
import { profilePatternConfigDefaults } from 'features/appBuilder/patterns/features/profile/utilities/defaults/config';

// Custom Types
import type { ProfilePatternDataProps } from 'features/appBuilder/patterns/features/profile/types';

const profilePatternDataDefaults: ProfilePatternDataProps = {
  patternTitle: '',
  bgColorOne: '#F5F5F5',
  bgColorTwo: '#F5F5F5',
  dividerColor: '#C1C8D3',
  bgImage: null,
  bgImageType: 'cover',
  padding: '32px',
  cardBg: '',
  cardCount: 0,
  creator: null,
  isActive: true,
  maxWidth: 'lg',
  sortIndex: 0,
  type: 'profile',
  variant: 0,
  config: profilePatternConfigDefaults,
};

/**
 * Retrieves the default profile pattern data.
 *
 * @returns {ProfilePatternDataProps} The default profile pattern data.
 */
export const getProfilePatternDataDefault = () => {
  const cloned = cloneDeep(profilePatternDataDefaults);

  return cloned;
};

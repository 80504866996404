import { FC } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';
import RowStack from 'core/components/shared/Stack/RowStack';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import BodyOne from 'core/components/shared/Typography/BodyOne';

// Feature Components
import EvaluationAccessButton from 'features/form/processes/components/details/Evaluation/Config/AccessButton';

// Core Utilities
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';

// Feature Utilities
import { updateProcessFocus } from 'features/form/processes/store/actions';
import {
  evaluatorEvaluationAccessItems,
  evaluatorManagerQuestionAccessItems,
} from 'features/form/processes/utilities/helper/evaluation';

// Feature Types
import type { ProcessProps } from 'features/form/processes/types/details';
import type {
  EvaluatorEvaluationFieldAccessType,
  EvaluatorQuestionFieldAccessType,
} from 'features/form/processes/types/details/common/itemAccess';

// Custom Types
export interface EvaluatorItemAccessesProps {
  index: number;
  disabled?: boolean;
}

const EvaluatorItemAccesses: FC<EvaluatorItemAccessesProps> = (props) => {
  // Props
  const { index, disabled } = props;

  // Hooks
  const { control, getValues, setValue } = useFormContext<ProcessProps>();
  const evaluationId = getValues(`data.items.${index}.id`);
  const evaluatorItemAccesses = useFieldArray({
    control,
    name: `data.items.${index}.data.evaluatorItemAccesses`,
    keyName: 'keyId',
  });

  // Utilities
  const handleEvaluatorEvalutionAccessClick = (
    accessIndex: number,
    fieldAccessValue:
      | EvaluatorEvaluationFieldAccessType
      | EvaluatorQuestionFieldAccessType
  ) => {
    const accesses = getValues(
      `data.items.${index}.data.evaluatorItemAccesses.${accessIndex}.data.fields`
    );

    const fieldIndex = accesses.indexOf(fieldAccessValue);

    if (fieldIndex > -1) {
      accesses.splice(fieldIndex, 1);
    } else {
      accesses.push(fieldAccessValue);
    }

    setValue(
      `data.items.${index}.data.evaluatorItemAccesses.${accessIndex}.data.fields`,
      accesses
    );

    updateProcessFocus(evaluationId);
  };

  return (
    <ColumnStack>
      {evaluatorItemAccesses.fields &&
        evaluatorItemAccesses.fields.length > 0 && (
          <BodyOne>فیلدهای قابل مشاهده ارزیابی‌ها</BodyOne>
        )}
      <ColumnStack spacing={1}>
        {evaluatorItemAccesses.fields &&
          evaluatorItemAccesses.fields.map((itemAccess, itemAccessIndex) => (
            <Box
              key={itemAccess.keyId}
              sx={{
                backgroundColor: 'background.default',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                gap: 1,
                p: '1rem 0.5rem',
                borderRadius: '0.5rem',
              }}
            >
              <BodyOne sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
                {itemAccess.data.title}
              </BodyOne>
              <Box
                sx={{
                  ml: 2,
                  overflow: 'hidden',
                }}
              >
                <RowStack
                  spacing={1}
                  sx={{
                    overflow: 'auto',
                    pb: 0.5,
                    ...customRoundedScrollbarStyles,
                  }}
                >
                  {itemAccess.data.type === 'evaluation' ? (
                    <Controller
                      control={control}
                      name={`data.items.${index}.data.evaluatorItemAccesses.${itemAccessIndex}.data.fields`}
                      render={({ field }) => (
                        <>
                          {evaluatorEvaluationAccessItems.map(
                            (evaluationAccess) => (
                              <EvaluationAccessButton
                                disabled={disabled}
                                key={
                                  evaluationAccess.value +
                                  evaluationAccess.label
                                }
                                onClick={() =>
                                  handleEvaluatorEvalutionAccessClick(
                                    itemAccessIndex,
                                    evaluationAccess.value
                                  )
                                }
                                selected={field.value.includes(
                                  evaluationAccess.value
                                )}
                              >
                                {evaluationAccess.label}
                              </EvaluationAccessButton>
                            )
                          )}
                        </>
                      )}
                    />
                  ) : (
                    <Controller
                      control={control}
                      name={`data.items.${index}.data.evaluatorItemAccesses.${itemAccessIndex}.data.fields`}
                      render={({ field }) => (
                        <>
                          {evaluatorManagerQuestionAccessItems.map(
                            (evaluationAccess) => (
                              <EvaluationAccessButton
                                disabled={disabled}
                                key={
                                  evaluationAccess.value +
                                  evaluationAccess.label
                                }
                                onClick={() =>
                                  handleEvaluatorEvalutionAccessClick(
                                    itemAccessIndex,
                                    evaluationAccess.value
                                  )
                                }
                                selected={field.value.includes(
                                  evaluationAccess.value
                                )}
                              >
                                {evaluationAccess.label}
                              </EvaluationAccessButton>
                            )
                          )}
                        </>
                      )}
                    />
                  )}
                </RowStack>
              </Box>
            </Box>
          ))}
      </ColumnStack>
    </ColumnStack>
  );
};

export default EvaluatorItemAccesses;

import {
  Table as MuiTable,
  TableProps as MuiTableProps,
  styled,
} from '@mui/material';

export interface TableProps extends MuiTableProps {}

const Table = styled(MuiTable)<TableProps>(() => ({}));

export default Table;

import type { FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

// Core Components
import DepricatedEditor from 'core/components/shared/DepricatedEditor';
import InputWrapper from 'core/components/shared/Wrapper/Input';

// Feature Shared Components
import PatternItemContainer, {
  type PatternItemContainerProps,
} from 'features/appBuilder/patterns/core/components/shared/items/Container';
import PatternFontFamilyDropdown from 'features/appBuilder/patterns/core/components/shared/dropdown/FontFamily';

// Core Utilities
import useRefId from 'core/hooks/useRefId';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import Editor from 'core/components/shared/Editor';
import Box from 'core/components/base/layout/Box';
import RefreshIcon from 'core/components/icons/Refresh';
import useMount from 'core/hooks/useMount';
import useBoolean from 'core/hooks/useBoolean';
import Skeleton from 'core/components/base/feedback/Skeleton';

// Custom Types
export interface PatternEditorDescriptionItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
}

const PatternEditorDescriptionItem: FC<PatternEditorDescriptionItemProps> = (
  props
) => {
  // Props
  const { reference, ...otherProps } = props;

  // Hooks
  const renderEditor = useBoolean();
  const [refId] = useRefId();
  const { control, setValue } = useFormContext();
  const loading = useSelectLoading();

  useMount(() => {
    setTimeout(() => {
      renderEditor.setTrue();
    }, 1000);
  });

  return (
    <PatternItemContainer reference={reference} {...otherProps}>
      <InputWrapper label={'متن'}>
        <Controller
          control={control}
          name={`${reference}.text`}
          render={({ field }) => {
            return (
              <Box
                sx={{
                  bgcolor: 'background.paper',
                  p: 1,
                  borderRadius: 2,
                  height: '200px',
                }}
              >
                {renderEditor.state ? (
                  <Editor
                    readonly={!renderEditor.state || loading}
                    defaultValue={field.value}
                    onBlur={(html) => setValue(field.name, html)}
                  />
                ) : (
                  <Skeleton variant='rectangular' />
                )}
              </Box>
            );
          }}
        />
      </InputWrapper>
      <Controller
        control={control}
        name={`${reference}.fontFamily`}
        render={({ field }) => (
          <PatternFontFamilyDropdown
            disabled={loading}
            defaultValue={field.value}
            reference={reference}
            keyName={'fontFamily'}
          />
        )}
      />
    </PatternItemContainer>
  );
};

export default PatternEditorDescriptionItem;

import cloneDeep from 'lodash/cloneDeep';
// Custom Utilities
import {
  getAlphabetArray,
  getNextAlphabet,
  getPreviousAlphabet,
  isAlphabetAfter,
  isAlphabetBefore,
} from 'core/utilities/alphabet';
import { getInitialTableCell } from 'features/appBuilder/patterns/features/table/utilities';

// Custom Validation
import {
  TABLE_COL_LIMIT,
  TABLE_ROW_LIMIT,
} from 'features/appBuilder/patterns/validations/table';

// Types
import type {
  TablePatternRefs,
  TableRowProps,
} from 'features/appBuilder/patterns/features/table/types';
import type { TableCellProps } from 'features/appBuilder/patterns/features/table/types/cell';
import type { ToolbarMode } from 'features/appBuilder/patterns/features/table/components/Toolbar';

/**
 * Generates a table row object with specified number of columns and row index.
 *
 * @param {number} colCount - The number of columns in the row.
 * @param {number} rowIndex - The index of the row.
 * @returns {object} The table row object.
 */
export const generateRow = (
  colCount: number,
  rowIndex: number
): TableRowProps => {
  const row: TableRowProps = {};

  const colNames = getAlphabetArray(colCount, 'uppercase');

  colNames.forEach((colName, colIndex) => {
    row[colName] = getInitialTableCell({ col: colIndex, row: rowIndex });
  });

  return row;
};

/**
 * Generates an array of table row objects with specified number of columns and rows.
 *
 * @param {number} colCount - The number of columns in each row.
 * @param {number} rowCount - The number of rows.
 * @returns {object[]} An array of table row objects.
 */
export const generateRows = (
  colCount: number,
  rowCount: number
): TableRowProps[] => {
  const rows: TableRowProps[] = [];
  for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
    rows.push(generateRow(colCount, rowIndex));
  }

  return rows;
};

/**
 * Checks if a cell is merged by examining its colSpan value.
 *
 * @param {object} cell - The cell object.
 * @returns {boolean} True if the cell is merged, false otherwise.
 */
export const isMerged = (cell: TableCellProps): boolean => cell.colSpan > 1;

/**
 * Sorts an array of TableCellProps objects based on their `row` and `col` properties.
 *
 * @param {TableCellProps[]} cells - The array of TableCellProps objects to be sorted.
 * @returns {TableCellProps[]} The sorted array of TableCellProps objects.
 */
export const sortCells = (cells: TableCellProps[]): TableCellProps[] => {
  let clonedCells = cloneDeep(cells);

  clonedCells.sort((a, b) => {
    if (a.row === b.row) return a.col - b.col;
    return a.row - b.row;
  });

  return clonedCells;
};

/**
 * Converts a TableRowProps object into an array of TableCellProps objects.
 *
 * @param {TableRowProps} row - The TableRowProps object to convert.
 * @returns {TableCellProps[]} The array of TableCellProps objects.
 */
export const getRowCellsArray = (row: TableRowProps): TableCellProps[] => {
  const cells: TableCellProps[] = [];

  Object.values(row).forEach((rowValue) => cells.push(rowValue));

  return cells;
};

/**
 * Extracts an array of TableCellProps objects from an array of TableRowProps objects.
 *
 * @param {TableRowProps[]} rows - The array of TableRowProps objects to extract TableCellProps objects from.
 * @returns {TableCellProps[]} The array of extracted TableCellProps objects.
 */
export const extractTableCells = (rows: TableRowProps[]): TableCellProps[] => {
  const cells: TableCellProps[] = [];

  rows.forEach((row) => {
    const result = getRowCellsArray(row);
    result.forEach((cell) => cells.push(cell));
  });

  return cells;
};

/**
 * Extracts an array of TableCellProps objects from an array of TableRowProps objects.
 *
 * @param {TableRowProps[]} rows - The array of TableRowProps objects to extract TableCellProps objects from.
 * @param {string} colKey - The key of the column to extract from each TableRowProps object.
 * @returns {TableCellProps[]} The array of extracted TableCellProps objects.
 */
export const extractTableColumn = (
  rows: TableRowProps[],
  colKey: string
): TableCellProps[] => {
  const cells: TableCellProps[] = [];

  rows.forEach((row) => {
    if (colKey in row) {
      cells.push(row[colKey]);
    }
  });

  return cells;
};

/**
 * Extracts an array of TableCellProps objects from a specific TableRowProps object in an array of TableRowProps objects.
 *
 * @param {TableRowProps[]} rows - The array of TableRowProps objects to extract from.
 * @param {number} rowIndex - The index of the TableRowProps object to extract from the array.
 * @returns {TableCellProps[]} The array of extracted TableCellProps objects.
 */
export const extractTableRow = (
  rows: TableRowProps[],
  rowIndex: number
): TableCellProps[] => {
  const cells: TableCellProps[] = [];

  if (rows.length >= rowIndex + 1) {
    Object.values(rows[rowIndex]).forEach((cell) => cells.push(cell));
  }

  return cells;
};

/**
 * Determines the mode of the toolbar based on the selection state and table row and column count.
 *
 * @param {TableCellProps[]} selectionState - The current selection state.
 * @param {TablePatternDataProps} rowCount - The count of the table pattern rows.
 * @param {TablePatternDataProps} colCount - The count of the table pattern columns.
 * @returns {ToolbarMode} The mode of the toolbar.
 */
export const getToolbarMode = (
  selectionState: TableCellProps[],
  rowCount: number,
  colCount: number
) => {
  let newMode: ToolbarMode = '';
  if (selectionState.length === 0) newMode = '';
  if (selectionState.length === 1) newMode = 'cell';
  if (selectionState.length > 1) newMode = 'multi-cell';
  if (
    selectionState.length > 0 &&
    selectionState.length === rowCount * colCount
  )
    newMode = 'all';
  if (
    selectionState.length === colCount &&
    selectionState.every((cell) => cell.row === selectionState[0].row)
  )
    newMode = 'row';
  if (
    selectionState.length === rowCount &&
    selectionState.every((cell) => cell.col === selectionState[0].col)
  )
    newMode = 'col';

  return newMode;
};

/**
 * Determines whether a given cell is selected or not based on the provided selection array.
 *
 * @param {TableCellProps[]} selection - An array of TableCellProps objects representing the current selection state.
 * @param {TableCellProps} cell - A TableCellProps object representing the cell to check for selection.
 * @returns {boolean} True if the cell is selected, false otherwise.
 */
export const isCellSelected = (
  selection: TableCellProps[],
  cell: TableCellProps
): boolean => {
  const index = selection.findIndex(
    (c) => c.col === cell.col && c.row === cell.row
  );

  return index > -1 ? true : false;
};

/**
 * Retrieves the cells that need to be merged based on the provided selection state.
 *
 * @param {TableCellProps[]} selectionState - An array of TableCellProps objects representing the current selection state.
 * @returns {Array<{ col: number; row: number; colSpan: number }>} An array of objects representing the cells to be merged, containing their column index, row index, and column span.
 */
const getCellsToMerge = (
  selectionState: TableCellProps[]
): { col: number; row: number; colSpan: number }[] => {
  const selection = selectionState.filter((cell) => cell.colSpan === 1);
  if (selection.length < 1) return [];
  const sortedSelection = sortCells(selection);

  const result: { col: number; row: number; colSpan: number }[] = [];
  let currentRow = sortedSelection[0].row;
  let currentCol = sortedSelection[0].col;
  let currentColSpan = 1;

  for (let i = 1; i < sortedSelection.length; i++) {
    const cell = sortedSelection[i];

    if (cell.row === currentRow && cell.col === currentCol + currentColSpan) {
      currentColSpan += cell.colSpan;
    } else {
      result.push({
        col: currentCol,
        row: currentRow,
        colSpan: currentColSpan,
      });

      currentRow = cell.row;
      currentCol = cell.col;
      currentColSpan = cell.colSpan;
    }
  }
  result.push({ col: currentCol, row: currentRow, colSpan: currentColSpan });

  return result.filter((cell) => cell.colSpan !== 1);
};

/**
 * Retrieves the cells to merge and cells to unmerge based on the provided selection.
 *
 * @param {TableCellProps[]} selection - An array of TableCellProps objects representing the current selection state.
 * @returns {{ cellsToMerge: Array<{ col: number; row: number; colSpan: number }>, cellsToUnmerge: TableCellProps[] }} An object containing two arrays: cellsToMerge and cellsToUnmerge.
 * cellsToMerge: An array of objects representing the cells to be merged, containing their column index, row index, and column span.
 * cellsToUnmerge: An array of TableCellProps objects representing the cells to be unmerged.
 */
export const getMergeAndUnmergeRange = (selection: TableCellProps[]) => {
  let cellsToMerge = getCellsToMerge(selection);

  const cellsToUnmerge: TableCellProps[] = selection.filter(
    (cell) => cell.colSpan > 1
  );

  return { cellsToMerge, cellsToUnmerge };
};

/**
 * Retrieves the counts of cells, rows, and columns in a table.
 *
 * @param {TableRowProps[]} rows - An array of TableRowProps objects representing the rows of the table.
 * @returns {{ cellCount: number; rowCount: number; colCount: number }} An object containing the counts of cells, rows, and columns in the table.
 * cellCount: The total number of cells in the table.
 * rowCount: The number of rows in the table.
 * colCount: The number of columns in the table.
 */
export const getTableCounts = (
  rows: TableRowProps[]
): { cellCount: number; rowCount: number; colCount: number } => {
  let rowCount = 0;
  let colCount = 0;
  let cellCount = 0;

  rows.forEach((row) => {
    let rowCellsCount = Object.values(row).length;
    cellCount += rowCellsCount;
    colCount = rowCellsCount > colCount ? rowCellsCount : colCount;
    rowCount++;
  });

  return { rowCount, colCount, cellCount };
};

/**
 * Checks if the provided row count is valid.
 *
 * @param {number} rowCount - The number of rows to check.
 * @returns {boolean} True if the row count is valid, false otherwise.
 */
export const isRowCountValid = (rowCount: number): boolean =>
  rowCount > 0 && rowCount <= TABLE_ROW_LIMIT;

/**
 * Checks if the provided row count is valid.
 *
 * @param {number} rowCount - The number of rows to check.
 * @returns {boolean} True if the row count is valid, false otherwise.
 */
export const isColCountValid = (colCount: number): boolean =>
  colCount > 0 && colCount <= TABLE_COL_LIMIT;

export const extractTableRefs = (
  rows: TableRowProps[]
): { refs: TablePatternRefs; sheetIds: string[] } => {
  const refs: TablePatternRefs = {};

  rows.forEach((row) => {
    Object.values(row).forEach((cell) => {
      if (cell.type === 'ref') {
        if (cell.sheetId in refs) {
          if (!(cell.columnKey in refs[cell.sheetId])) {
            refs[cell.sheetId][cell.columnKey] = '';
          }
        } else {
          refs[cell.sheetId] = {
            [cell.columnKey]: '',
          };
        }
      }
    });
  });

  const sheetIds: string[] = Object.keys(refs);

  return { refs, sheetIds };
};

/**
 * Moves the cells of a specific column forward in each row of a table.
 *
 * @param {TableRowProps[]} rows - An array of table rows.
 * @param {string} colKey - The key of the column to move cells forward.
 * @returns {TableRowProps[]} An array of table rows with moved cells.
 */
export const moveRowCellsForward = (
  rows: TableRowProps[],
  colKey: string
): TableRowProps[] => {
  const movedRows: TableRowProps[] = rows.map((row) => {
    if (row.hasOwnProperty(colKey)) {
      const newRow: TableRowProps = {};

      let prevColSpan: number = 1;
      Object.entries(row).forEach(([key, value]) => {
        if (isAlphabetAfter(key, colKey)) {
          const newKey = getNextAlphabet(key, 'uppercase', prevColSpan);
          if (newKey) {
            newRow[newKey] = cloneDeep(value);
            newRow[newKey].col = value.col + prevColSpan;
          }
        } else {
          newRow[key] = value;
        }
        prevColSpan = value.colSpan;
      });

      return newRow;
    }

    return row;
  });

  return movedRows;
};

/**
 * Moves the cells of a specific column backward in each row of a table.
 *
 * @param {TableRowProps[]} rows - An array of table rows.
 * @param {string} colKey - The key of the column to move cells backward.
 * @returns {TableRowProps[]} An array of table rows with moved cells.
 */

export const moveRowCellsBackward = (
  rows: TableRowProps[],
  colKey: string
): TableRowProps[] => {
  const movedRows: TableRowProps[] = rows.map((row, rowIndex) => {
    if (row.hasOwnProperty(colKey)) {
      const newRow: TableRowProps = {};

      Object.entries(row).forEach(([key, value]) => {
        if (isAlphabetBefore(key, colKey)) {
          newRow[key] = cloneDeep(value);
        } else if (key === colKey) {
          // Skip
        } else if (isAlphabetAfter(key, colKey)) {
          const newKey = getPreviousAlphabet(key, 'uppercase');

          if (newKey) {
            newRow[newKey] = cloneDeep({
              ...value,
              col: value.col - 1,
            });
          }
        }
      });

      if (Object.keys(newRow).length === 0) {
        newRow['A'] = getInitialTableCell({ col: 0, row: rowIndex });
      }

      return newRow;
    }

    return row;
  });

  return movedRows;
};

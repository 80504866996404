// Custom Utilities
import getEndpoint from 'core/utilities/helper/getEndpoint';
import apiHandler from 'core/utilities/apiHandler';
import { bakeTemplateBody } from './baker';

// Custom Types
import type {
  TemplateBodyDataProps,
  TemplateBodyProps,
} from 'features/appBuilder/templates/types/body';

/**
 * Creates a new template body.
 *
 * @param {TemplateBodyDataProps} bodyData - The data for the template body to be created.
 * @returns {Promise<{ status: number }>} The status of the request.
 */
export const createTemplateBody = async (
  bodyData: TemplateBodyDataProps
): Promise<{ status: number }> => {
  const endpoint = `${getEndpoint().createCustomPageBodyURL}`;
  const { status } = await apiHandler.post(
    endpoint,
    bakeTemplateBody({ id: 'draft', data: bodyData }).data
  );
  return { status };
};

/**
 * Updates an existing template body.
 *
 * @param {string} id - The template id.
 * @param {TemplateBodyDataProps} data - The template body object containing the updated data.
 * @returns {Promise<{ status: number }>} The status of the request.
 */
export const updateTemplateBody = async (
  id: string,
  data: TemplateBodyDataProps
): Promise<{ status: number }> => {
  const endpoint = `${getEndpoint().updateCustomPageBodyURL}/${id}`;
  const { status } = await apiHandler.patch(
    endpoint,
    bakeTemplateBody({ id, data }).data
  );
  return { status };
};

/**
 * Duplicates an existing template body.
 *
 * @param {string} bodyId - The ID of the template body to be duplicated.
 * @returns {Promise<{ status: number }>} The status of the request.
 */
export const duplicateTemplateBody = async (
  bodyId: string
): Promise<{ status: number }> => {
  const endpoint = `${getEndpoint().duplicateCustomPageBodyURL}/${bodyId}`;
  const { status } = await apiHandler.post(endpoint);
  return { status };
};

/**
 * Deletes a template body.
 *
 * @param {string} bodyId - The ID of the template body to be deleted.
 * @returns {Promise<{ status: number }>} The status of the request.
 */
export const deleteTemplateBody = async (
  bodyId: string
): Promise<{ status: number }> => {
  const endpoint = `${getEndpoint().deleteCustomPageBodyURL}/${bodyId}`;
  const { status } = await apiHandler.delete(endpoint);
  return { status };
};

import * as React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyOne from 'core/components/shared/Typography/BodyOne';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import SpacedBox from 'core/components/shared/Box/SpacedBox';
import FilePreviewAndDownload from 'core/components/shared/FilePreviewAndDownload';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Custom Core Components
import Avatar from 'core/components/base/display/Avatar';
import Skeleton from 'core/components/base/feedback/Skeleton';
import IconButton from 'core/components/base/inputs/IconButton';
import LoadingButton from 'core/components/base/inputs/LoadingButton';

// Custom Icon Components
import HiddenIcon from 'core/components/icons/Hidden';
import DeleteIcon from 'core/components/icons/DeleteIcon';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import { prefixFileURL } from 'core/utilities/helper/link';
import { useTicketDoc } from 'features/ticket/tickets/hooks';
import { getLocaleDateAndTime } from 'core/utilities/helper/date';
import { useToggleTicketStatusMutation } from 'features/ticket/tickets/hooks';
import { getTicketMessageStatusLabel } from 'features/ticket/tickets/utilities';
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';

// Custom Types
import type { TicketMessageContentProps } from 'features/ticket/tickets/types';

interface TicketDetailsProps {}

const TicketDetails: React.FC<TicketDetailsProps> = (props) => {
  // Context
  const loading = useSelectLoading();

  // Hooks
  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const { id: ticketId } = useParams();
  const { data: ticket } = useTicketDoc({
    alertOnFetchError: { message: 'دریافت اطلاعات تیکت با خطا مواجه شد.' },
    alertOnDocumentIdIsEmpty: { message: 'شناسه تیکت یافت نشد.' },
    redirectAfterErrorTo: '/tickets',
    redirectAfterDocumentIdIsEmptyTo: '/tickets',
  });
  const { mutate: handleToggleTicketStatus } = useToggleTicketStatusMutation({
    autoAlert: { mode: 'update', name: 'وضعیت تیکت' },
  });

  // Utilities
  const hasVoice = (content: TicketMessageContentProps) =>
    content.voice && Object.keys(content.voice).length > 0 ? true : false;
  const hasFile = (content: TicketMessageContentProps) =>
    content.files &&
    content.files.length > 0 &&
    !content.files.some((obj) => Object.keys(obj).length === 0)
      ? true
      : false;
  const hasMedia = (content: TicketMessageContentProps) =>
    hasVoice(content) || hasFile(content);

  return (
    <>
      <PageHeadConfigs pageTitle='جزئیات تیکت' />
      {!ticket ? (
        <>
          <PageHeroBox>
            <HeadingSix>جزئیات تیکت...</HeadingSix>
            <Skeleton width='8rem' height='4rem' />
          </PageHeroBox>
          <Skeleton
            width='100%'
            height='17rem'
            variant='rectangular'
            sx={{ borderRadius: '1rem' }}
          />
        </>
      ) : (
        <>
          <PageHeroBox>
            <HeadingSix>{ticket.data.title}</HeadingSix>
            <LoadingButton
              disabled={!ticketId}
              loading={
                typeof ticketId === 'string' && ticketId && loading
                  ? true
                  : false
              }
              variant='outlined'
              color='error'
              onClick={() =>
                ticketId &&
                typeof ticketId === 'string' &&
                handleToggleTicketStatus({
                  id: ticketId,
                  data: !ticket.data.isActive,
                })
              }
            >
              {ticket.data.isActive ? 'غیرفعال کردن' : 'فعال کردن'}
            </LoadingButton>
          </PageHeroBox>
          <RoundPaper>
            <ColumnStack>
              <RowStack
                sx={{
                  alignItems: 'center',
                  overflowX: 'auto',
                  overflowY: 'hidden',
                  py: '1rem',
                  whiteSpace: 'nowrap',
                  ...customRoundedScrollbarStyles,
                }}
              >
                <RowStack spacing={0.5}>
                  <BodyTwo fontWeight='bold'>شماره شناسه:</BodyTwo>
                  <BodyTwo>{ticket.data.code}</BodyTwo>
                </RowStack>
                <RowStack spacing={0.5}>
                  <BodyTwo fontWeight='bold'>آخرین وضعیت:</BodyTwo>
                  <BodyTwo>
                    {getTicketMessageStatusLabel(ticket.data.status)}
                  </BodyTwo>
                </RowStack>
                <RowStack spacing={0.5}>
                  <BodyTwo fontWeight='bold'>پرسنل:</BodyTwo>
                  <BodyTwo>{`${ticket.data.creator.data.firstName} ${ticket.data.creator.data.lastName}`}</BodyTwo>
                </RowStack>
                <RowStack spacing={0.5}>
                  <BodyTwo fontWeight='bold'>دپارتمان:</BodyTwo>
                  <BodyTwo>
                    {`${ticket.data.depTitle} +${ticket.data.depMembers.length}`?.toPersian()}
                  </BodyTwo>
                </RowStack>
                <RowStack spacing={0.5}>
                  <BodyTwo fontWeight='bold'>مشترکین:</BodyTwo>
                  <BodyTwo>
                    {ticket.data.subscribers.length === 1
                      ? ticket.data.subscribers[0]
                      : ticket.data.subscribers.length > 1
                      ? `${ticket.data.subscribers[0]} +${
                          ticket.data.subscribers.length - 1
                        }`
                      : 'بدون مشترک'?.toPersian()}
                  </BodyTwo>
                </RowStack>
              </RowStack>
              <ColumnStack spacing={2} px='4rem'>
                {ticket.data.messages.map((message) => (
                  <ColumnStack
                    sx={{
                      borderRadius: '1rem',
                      border: '1px solid',
                      padding: '1rem',
                      borderColor: 'text.secondary',
                    }}
                  >
                    <SpacedBox>
                      <RowStack>
                        <Avatar
                          src={
                            message.data.creator.data.avatar
                              ? prefixFileURL(
                                  message.data.creator.data.avatar.data.url
                                )
                              : undefined
                          }
                          sx={{ width: '3rem', height: '3rem' }}
                        >
                          <BodyTwo fontSize='0.75rem'>
                            {message.data.creator.data.lastName}
                          </BodyTwo>
                        </Avatar>
                        <ColumnStack spacing={0}>
                          <BodyOne>{`${message.data.creator.data.firstName} ${message.data.creator.data.lastName}`}</BodyOne>
                          <BodyTwo
                            sx={{
                              backgroundColor: 'background.default',
                              borderRadius: '2px',
                              textAlign: 'center',
                              p: 1,
                            }}
                          >
                            {message.data.creator.data.role}
                          </BodyTwo>
                        </ColumnStack>
                      </RowStack>
                      {message.data.isHidden && (
                        <IconButton disabled>
                          <HiddenIcon fontSize='small' />
                        </IconButton>
                      )}
                    </SpacedBox>
                    <RowStack
                      justifyContent='space-between'
                      direction={isDownLg ? 'column' : 'row'}
                    >
                      {message.data.content.text && (
                        <BodyOne
                          maxWidth={
                            hasMedia(message.data.content) && !isDownLg
                              ? '50%'
                              : undefined
                          }
                        >
                          {message.data.content.text}
                        </BodyOne>
                      )}
                      {hasMedia(message.data.content) && (
                        <ColumnStack
                          maxWidth={
                            hasMedia(message.data.content) && !isDownLg
                              ? '50%'
                              : undefined
                          }
                        >
                          {hasVoice(message.data.content) && (
                            <audio
                              src={prefixFileURL(
                                message.data.content!.voice!.data.url
                              )}
                              controls
                            />
                          )}
                          {hasFile(message.data.content) && (
                            <RowStack>
                              {message.data.content.files?.map((file) => (
                                <FilePreviewAndDownload
                                  src={prefixFileURL(file.data?.url)}
                                  key={file.id}
                                />
                              ))}
                            </RowStack>
                          )}
                        </ColumnStack>
                      )}
                    </RowStack>
                    <BodyOne color='text.secondary'>
                      {getTicketMessageStatusLabel(message.data.status)}
                    </BodyOne>
                    <SpacedBox>
                      <BodyTwo dir='ltr' width='fit-content'>
                        {getLocaleDateAndTime(message.data.createdAt)}
                      </BodyTwo>
                      {message.data.isRemoved && (
                        <IconButton disabled>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </SpacedBox>
                  </ColumnStack>
                ))}
              </ColumnStack>
            </ColumnStack>
          </RoundPaper>
        </>
      )}
    </>
  );
};

export default TicketDetails;

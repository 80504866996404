import { useState, useEffect, useCallback, type FC } from 'react';

// Custom Core Components
import Box from 'core/components/base/layout/Box';

// Custom Components
import PatternToolbarIconButton from './Icon';
import TextFieldNumber from 'core/components/shared/TextField/number';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Icon Components
import ConfigIcon from 'core/components/icons/Config';
import CleanIcon from 'core/components/icons/Clean';
import CellWidthIcon from 'core/components/icons/CellWidth';
import CellHeightIcon from 'core/components/icons/CellHeight';

// Custom Hooks
import { useAppDispatch, useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import { updateCellsStyles } from 'features/appBuilder/patterns/features/table/store';

// Custom Types
import type { ToolbarMethods } from '.';
export interface TablePatternAllCellsToolbarProps
  extends Partial<ToolbarMethods> {}

const TablePatternAllCellsToolbar: FC<TablePatternAllCellsToolbarProps> = (
  props
) => {
  // Props
  const { onResetCells, onStylesOpen } = props;

  // States
  const [height, setHeight] = useState<number>(35);
  const [width, setWidth] = useState<number>(120);

  // Hooks
  const dispatch = useAppDispatch();
  const { selection } = useAppSelector((state) => state.table.data);

  const handleHeightUpdate = useCallback(() => {
    if (selection.length > 0) {
      const heightToSet = selection[0].styles.height;

      if (height !== heightToSet) setHeight(heightToSet);
    }
  }, [selection]);

  useEffect(() => {
    handleHeightUpdate();
  }, [selection, handleHeightUpdate]);

  const updateHeightInState = () => {
    if (selection.length > 0) {
      const stylesToSet = selection[0].styles;
      const heightToSet = !height || height < 35 ? 35 : height;
      dispatch(
        updateCellsStyles({
          cells: selection,
          styles: {
            ...stylesToSet,
            height: heightToSet,
          },
        })
      );
      if (!height || height < 35) setHeight(heightToSet);
    }
  };

  const handleWidthUpdate = useCallback(() => {
    if (selection.length > 0) {
      const widthToSet = selection[0].styles.width;

      if (width !== widthToSet) setWidth(widthToSet);
    }
  }, [selection]);

  useEffect(() => {
    handleWidthUpdate();
  }, [selection, handleWidthUpdate]);

  const updateWidthInState = () => {
    if (selection.length > 0) {
      const stylesToSet = selection[0].styles;
      const widthToSet = !width || width < 120 ? 120 : width;
      dispatch(
        updateCellsStyles({
          cells: selection,
          styles: {
            ...stylesToSet,
            width: widthToSet,
          },
        })
      );
      if (!width || width < 120) setWidth(widthToSet);
    }
  };

  const items = [
    { title: 'clean', Icon: <CleanIcon />, handler: onResetCells },
    { title: 'config', Icon: <ConfigIcon />, handler: onStylesOpen },
  ];

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {items.map((item, index) => (
        <PatternToolbarIconButton
          key={item.title + index}
          onClick={item.handler}
        >
          {item.Icon}
        </PatternToolbarIconButton>
      ))}
      <Box sx={{ display: 'flex' }}>
        <PatternToolbarIconButton
          sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        >
          <CellHeightIcon />
        </PatternToolbarIconButton>
        <TextFieldNumber
          dir='ltr'
          sx={{
            '.MuiInputBase-input': {
              paddingY: '12px',
            },
            width: '6rem',
          }}
          value={height}
          onChange={(e) => {
            const value = e.target.value;

            setHeight(parseInt(value));
          }}
          onBlur={updateHeightInState}
          InputProps={{
            sx: {
              borderRadius: '0.5rem',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
            endAdornment: (
              <>
                <BodyTwo sx={{ ml: '0.5rem' }}>px</BodyTwo>
              </>
            ),
          }}
        />
      </Box>
      <Box sx={{ display: 'flex' }}>
        <PatternToolbarIconButton
          sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        >
          <CellWidthIcon />
        </PatternToolbarIconButton>
        <TextFieldNumber
          dir='ltr'
          sx={{
            '.MuiInputBase-input': {
              paddingY: '12px',
            },
            width: '6rem',
          }}
          value={width}
          onChange={(e) => {
            const value = e.target.value;

            setWidth(parseInt(value));
          }}
          onBlur={updateWidthInState}
          InputProps={{
            sx: {
              borderRadius: '0.5rem',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
            endAdornment: (
              <>
                <BodyTwo sx={{ ml: '0.5rem' }}>px</BodyTwo>
              </>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default TablePatternAllCellsToolbar;

import type { FC, ReactNode } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import BodyOne from 'core/components/shared/Typography/BodyOne';
import SpacedBox from 'core/components/shared/Box/SpacedBox';
import RowStack from 'core/components/shared/Stack/RowStack';
import IconButton from 'core/components/base/inputs/IconButton';
import ArrowDropDownIcon from 'core/components/icons/ArrowDropDown';
import Collapse from 'core/components/base/utils/Collapse';
import AntSwitch from 'core/components/shared/Switch/AntSwitch';
import Box from 'core/components/base/layout/Box';

// Core Utilities
import { useAppSelector } from 'core/hooks/redux';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';

// Custom Types
export interface PatternSectionContainerProps {
  reference: string;
  children?: ReactNode;
  collapsed?: boolean;
  title?: string;
  onCollapsedChange?: (state: boolean) => void;
}

const PatternSectionContainer: FC<PatternSectionContainerProps> = (props) => {
  // Props
  const {
    reference,
    children,
    title = 'عنوان',
    collapsed,
    onCollapsedChange,
  } = props;

  // Hooks
  const { control, setValue } = useFormContext();
  const loading = useSelectLoading();

  // Utilities
  const isSidebarClose = useAppSelector((state) => state.sidebar.collapsed);
  const { breakpoints } = useTheme();
  const isUpLg = useMediaQuery(breakpoints.up('lg'));
  const handleCollpase = () => {
    if (onCollapsedChange) onCollapsedChange(!collapsed);
  };

  return (
    <RoundPaper sx={{ px: '1rem' }}>
      <SpacedBox>
        <RowStack alignItems='center' spacing={1}>
          <IconButton disabled={loading} color='info' onClick={handleCollpase}>
            <ArrowDropDownIcon
              sx={{
                transform: collapsed ? 'rotate(0)' : 'rotate(90deg)',
                transition: 'all',
                transitionDuration: '200ms',
              }}
            />
          </IconButton>
          <BodyOne fontWeight='bold'>{title}</BodyOne>
        </RowStack>
        <Controller
          control={control}
          name={`${reference}.isActive`}
          render={({ field }) => (
            <AntSwitch
              disabled={loading}
              checked={field.value}
              onChange={(_, checked) => setValue(field.name, checked)}
            />
          )}
        />
      </SpacedBox>
      <Collapse in={collapsed}>
        <Box
          sx={{
            py: '1rem',
            display: 'flex',
            flexWrap: 'wrap',
            overflow: 'auto',
            gap: 1,
            ...customRoundedScrollbarStyles,
            ['&>div']: {
              flexGrow: 1,
              width: isUpLg || isSidebarClose ? '49.5%' : '100%',
              maxWidth: isUpLg || isSidebarClose ? '49.5%' : '100%',
            },
          }}
        >
          {children}
        </Box>
      </Collapse>
    </RoundPaper>
  );
};

export default PatternSectionContainer;

import { type FC } from 'react';

// Custom Shared Components
import FormButton, { type FormButtonProps } from '../Core';

// Custom Icon Components
import BinIcon from 'core/components/icons/Bin';

// Types
export interface RemoveButtonProps extends FormButtonProps {}

const RemoveButton: FC<RemoveButtonProps> = (props) => {
  // Props
  const {
    children = 'حذف فیلد',
    startIcon = <BinIcon />,
    ...otherProps
  } = props;

  return (
    <FormButton color='error' startIcon={startIcon} {...otherProps}>
      {children}
    </FormButton>
  );
};

export default RemoveButton;

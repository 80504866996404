// Custom Hooks
import useDocApi from 'core/hooks/api/useDoc';
import useDocsApi from 'core/hooks/api/useDocs';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  addTicketTopic,
  deleteTicketTopics,
  editTicketTopic,
  getTicketTopic,
  getTicketTopics,
  toggleTicketTopicStatus,
} from 'features/ticket/topics/utilities/api';

// Custom Types
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';
import type {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';
import type {
  TicketTopicDataProps,
  TicketTopicProps,
  TicketTopicsProps,
} from 'features/ticket/topics/types';

/**
 * Query key for fetching ticket topics in react query.
 *
 * @constant {Array<string>}
 */
export const ticketTopicsQueryKey = ['ticketTopics'];

/**
 * Custom hook to fetch ticket topics.
 *
 * This hook utilizes the `useDocsApi` to retrieve ticket topics list on the provided options.
 *
 * @param {UseDocsOptionType<TicketTopicsProps>} [options] - Optional configuration options for fetching the ticket topics.
 * @returns {UseDocsApiReturnProps<TicketTopicsProps>} The result of the `useDocsApi` hook, which includes the fetched ticket topics
 */
export const useTicketTopicDocs = (
  options?: UseDocsOptionType<TicketTopicsProps>
): UseDocsApiReturnProps<TicketTopicsProps> =>
  useDocsApi<TicketTopicsProps>(getTicketTopics, ticketTopicsQueryKey, options);

/**
 * Custom hook to fetch a ticket topic document.
 *
 * This hook utilizes the `useDocApi` to retrieve a specific ticket topic based on the provided options.
 *
 * @param {UseDocOptionType<TicketTopicProps>} options - The options for fetching the ticket topic document.
 * @returns {UseDocApiReturnProps<TicketTopicProps>} The result of the `useDocApi` hook, which includes the fetched document, loading state, and any errors.
 */
export const useTicketTopicDoc = (
  options?: UseDocOptionType<TicketTopicProps>
): UseDocApiReturnProps<TicketTopicProps> =>
  useDocApi<TicketTopicProps>(getTicketTopic, ticketTopicsQueryKey, options);

/**
 * Custom hook for deleting ticket topics.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the delete mutation, including the status and potential errors.
 */
export const useDeleteTopicsMutation = useDeleteMutation<string[]>(
  deleteTicketTopics,
  ticketTopicsQueryKey
);

/**
 * Custom hook for toggling the status of a ticket topic.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the toggle status mutation, including the status and potential errors.
 */
export const useToggleTopicStatusMutation = useEditMutation<boolean>(
  toggleTicketTopicStatus,
  ticketTopicsQueryKey
);

/**
 * Custom hook for editing a ticket topic.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the edit mutation, including the status and potential errors.
 */
export const useEditTicketTopicMutation = useEditMutation<TicketTopicDataProps>(
  editTicketTopic,
  ticketTopicsQueryKey
);

/**
 * Custom hook for adding a new ticket topic.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the add mutation, including the status and potential errors.
 */
export const useAddTicketTopicMutation = useAddMutation<TicketTopicDataProps>(
  addTicketTopic,
  ticketTopicsQueryKey
);

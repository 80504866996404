// Feature Types
import type { FooterPatternDescriptionProps } from 'features/appBuilder/patterns/features/footer/types/description';

export const footerPatternDescriptionDefaults: FooterPatternDescriptionProps = {
  isActive: true,
  title: {
    isActive: true,
    text: '',
    padding: '0px',
    fontSize: '18',
    textDirection: 'rtl',
    textAlign: 'start',
    fontFamily: 'vazir',
    fontColor: '#12252E',
    highlightColor: 'rgba(0, 0, 0, 0)',
    bgColor: 'rgba(0, 0, 0, 0)',
    fontHoverColor: '#12252E',
    highlightHoverColor: 'rgba(0, 0, 0, 0)',
    bgHoverColor: 'rgba(0, 0, 0, 0)',
  },
  body: {
    isActive: true,
    text: '',
    padding: '0px',
    fontSize: '12',
    textDirection: 'rtl',
    textAlign: 'center',
    fontFamily: 'vazir',
    fontColor: '#59666D',
    highlightColor: 'rgba(0, 0, 0, 0)',
    bgColor: 'rgba(0, 0, 0, 0)',
    fontHoverColor: '#59666D',
    highlightHoverColor: 'rgba(0, 0, 0, 0)',
    bgHoverColor: 'rgba(0, 0, 0, 0)',
  },
};

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface ContentCopyIconProps extends SvgIconProps {}

const ContentCopyIcon: React.FC<ContentCopyIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2m0 16H8V7h11z' />
  </IconBuilder>
);

export default ContentCopyIcon;

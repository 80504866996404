import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';
import TextFieldNumber from 'core/components/shared/TextField/number';
import InputWrapper from 'core/components/shared/Wrapper/Input';

// Feature Shared Components
import BannerContainerSelect from 'features/banner/banners/components/shared/dropdown';
import PatternItemContainer from 'features/appBuilder/patterns/core/components/shared/items/Container';
import PatternImageDisplayTypeDropdown from 'features/appBuilder/patterns/core/components/shared/dropdown/DisplayType';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { PatternItemContainerProps } from 'features/appBuilder/patterns/core/components/shared/items/Container';

export interface PatternBannerItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
}

const PatternBannerItem: FC<PatternBannerItemProps> = (props) => {
  // Props
  const { reference, ...otherProps } = props;

  // Hooks
  const { control, setValue } = useFormContext();
  const loading = useSelectLoading();

  return (
    <PatternItemContainer reference={reference} {...otherProps}>
      <Box sx={{ display: 'flex', gap: 2, ['&>*']: { flexGrow: 1 } }}>
        <InputWrapper label='عنوان'>
          <Controller
            control={control}
            name={`${reference}.containerId`}
            render={({ field }) => (
              <BannerContainerSelect
                disabled={loading}
                defaultValue={field.value}
                onChange={(opt) => {
                  setValue(field.name, opt.id);
                  setValue(`${reference}.containerTitle`, opt.data.title);
                }}
              />
            )}
          />
        </InputWrapper>
        <InputWrapper sx={{ maxWidth: '4.5rem' }} label='تعداد آیتم'>
          <Controller
            control={control}
            name={`${reference}.itemCount`}
            rules={{
              onBlur: (e) => {
                const strValue = e.target.value as string;
                const value = parseInt(strValue);

                setValue(
                  `${reference}.itemCount`,
                  typeof value === 'number' ? value : 0
                );
              },
            }}
            render={({ field }) => (
              <TextFieldNumber disabled={loading} fullWidth {...field} />
            )}
          />
        </InputWrapper>
      </Box>
      <Box sx={{ display: 'flex', gap: 2, ['&>*']: { flexGrow: 1 } }}>
        <InputWrapper label='نوع نمایش'>
          <Controller
            control={control}
            name={`${reference}.imageType`}
            render={({ field }) => (
              <PatternImageDisplayTypeDropdown
                disabled={loading}
                fullWidth
                defaultValue={field.value}
                reference={reference}
                keyName='imageType'
              />
            )}
          />
        </InputWrapper>
        <InputWrapper label='اندازه'>
          <Controller
            control={control}
            name={`${reference}.imageType`}
            render={({ field: f }) => (
              <Controller
                control={control}
                name={`${reference}.imageSize`}
                rules={{
                  onBlur: (e) => {
                    const strValue = e.target.value as string;
                    const value = parseInt(strValue);

                    setValue(
                      `${reference}.imageSize`,
                      typeof value === 'number' ? value : 0
                    );
                  },
                }}
                render={({ field }) => (
                  <TextFieldNumber
                    disabled={f.value === 'default' || loading}
                    {...field}
                  />
                )}
              />
            )}
          />
        </InputWrapper>
      </Box>
    </PatternItemContainer>
  );
};

export default PatternBannerItem;

import { type FC } from 'react';

// Core Components
import Box, { type BoxProps } from 'core/components/base/layout/Box';
import PlusCircleOutlineIcon from 'core/components/icons/PlusCircleOutline';

// Custom Types
export interface GroupPatternAddColumnButtonProps extends BoxProps {
  disabled?: boolean;
  onClick?: () => void;
}

const GroupPatternAddColumnButton: FC<GroupPatternAddColumnButtonProps> = (
  props
) => {
  // Props
  const { disabled, sx, onClick, ...otherProps } = props;

  // Utilities
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        bgcolor: '#f5f5f5',
        ':hover': {
          bgcolor: 'rgba(255, 255, 255, 0.4)',
        },
        border: '2px dashed',
        borderColor: 'info.main',
        color: 'info.main',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: '4rem 1rem',
        gap: 1,
        cursor: disabled ? 'auto' : 'pointer',
        borderRadius: '0.5rem',
        ...sx,
      }}
    >
      <PlusCircleOutlineIcon />
      افزودن ستون
    </Box>
  );
};

export default GroupPatternAddColumnButton;

import type { FC } from 'react';

// Feature Components
import FormPatternConfigSection from 'features/appBuilder/patterns/features/form/components/Config';
import FormPatternBodySection from 'features/appBuilder/patterns/features/form/components/Body';
import FormPatternButtonSection from 'features/appBuilder/patterns/features/form/components/Button';
import FormPatternErrorSection from 'features/appBuilder/patterns/features/form/components/Error';
import FormPatternAlertSection from 'features/appBuilder/patterns/features/form/components/Alert';

// Custom Types
export interface FormPatternItemsProps {}

const FormPatternItems: FC<FormPatternItemsProps> = () => {
  return (
    <>
      <FormPatternConfigSection />
      <FormPatternBodySection />
      <FormPatternButtonSection />
      <FormPatternErrorSection />
      <FormPatternAlertSection />
    </>
  );
};

export default FormPatternItems;

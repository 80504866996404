// Feature Validations
import { validateHeaderPattern } from 'features/appBuilder/patterns/features/header/utilities/validation';
import { validateFooterPattern } from 'features/appBuilder/patterns/features/footer/utilities/validation';
import { validateStablePattern } from 'features/appBuilder/patterns/features/stable/utilities/validation';
import { validateProfilePattern } from 'features/appBuilder/patterns/features/profile/utilities/validation';
import { validateContentPattern } from 'features/appBuilder/patterns/features/content/utilities/validation';
import { validateSliderPattern } from 'features/appBuilder/patterns/features/slider/utilities/validation';
import { validateContentDetailsPattern } from 'features/appBuilder/patterns/features/contentDetails/utilities/validation';
import { validateTagAndCategoryPattern } from 'features/appBuilder/patterns/features/tagAndCategory/utilities/validation';
import { validateSearchPattern } from 'features/appBuilder/patterns/features/search/utilities/validation';
import { validateFormPattern } from 'features/appBuilder/patterns/features/form/utilities/validation';
import { validateTablePattern } from 'features/appBuilder/patterns/features/table/utilities/validation';
import { validateGroupPattern } from 'features/appBuilder/patterns/features/group/utilities/validation';

// Feature Types
import type { PatternData } from 'features/appBuilder/patterns/core/types/item';
import type { HeaderPatternDataProps } from 'features/appBuilder/patterns/features/header/types';
import type { FooterPatternDataProps } from 'features/appBuilder/patterns/features/footer/types';
import type { StablePatternDataProps } from 'features/appBuilder/patterns/features/stable/types';
import type { ProfilePatternDataProps } from 'features/appBuilder/patterns/features/profile/types';
import type { ContentPatternDataProps } from 'features/appBuilder/patterns/features/content/types';
import type { SliderPatternDataProps } from 'features/appBuilder/patterns/features/slider/types';
import type { ContentDetailsPatternDataProps } from 'features/appBuilder/patterns/features/contentDetails/types';
import type { TagAndCategoryPatternDataProps } from 'features/appBuilder/patterns/features/tagAndCategory/types';
import type { SearchPatternDataProps } from 'features/appBuilder/patterns/features/search/types';
import type { FormPatternDataProps } from 'features/appBuilder/patterns/features/form/types';
import type { TablePatternDataProps } from 'features/appBuilder/patterns/features/table/types';
import type { GroupPatternDataProps } from 'features/appBuilder/patterns/features/group/types';

export const validatePattern = (pattern: PatternData): string => {
  const type = pattern.type;

  switch (type) {
    case 'header':
      return validateHeaderPattern(pattern as HeaderPatternDataProps);

    case 'footer':
      return validateFooterPattern(pattern as FooterPatternDataProps);

    case 'content':
      return validateContentPattern(pattern as ContentPatternDataProps);

    case 'stable':
      return validateStablePattern(pattern as StablePatternDataProps);

    case 'profile':
      return validateProfilePattern(pattern as ProfilePatternDataProps);

    case 'slider':
      return validateSliderPattern(pattern as SliderPatternDataProps);

    case 'contentDetails':
      return validateContentDetailsPattern(
        pattern as ContentDetailsPatternDataProps
      );

    case 'tagAndCategory':
      return validateTagAndCategoryPattern(
        pattern as TagAndCategoryPatternDataProps
      );

    case 'search':
      return validateSearchPattern(pattern as SearchPatternDataProps);

    case 'form':
      return validateFormPattern(pattern as FormPatternDataProps);

    case 'table':
      return validateTablePattern(pattern as TablePatternDataProps);

    case 'group':
      return validateGroupPattern(pattern as GroupPatternDataProps);

    default:
      return '';
  }
};

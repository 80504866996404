// Custom Utilities
import { generateObjectId } from 'core/utilities/helper/id';
import { getInitialCommonInputProps } from 'features/form/forms/utilities/input/initial/common';

// Custom Types
import type { FormInputInitialParams } from 'features/form/forms/utilities/input/initial/common';
import type { FormRateInputProps } from 'features/form/forms/types/item/input/rate';

interface Params extends FormInputInitialParams {}

export const genFormRateInput = (params: Params): FormRateInputProps => {
  const commonProps = getInitialCommonInputProps({
    ...params,
  });

  return {
    id: `draft-${generateObjectId()}`,
    data: {
      ...commonProps,
      label: 'رتبه',
      maxRate: 10,
      startLabel: '',
      endLabel: '',
      type: 'rate',
    },
  };
};

import * as React from 'react';

// Custom Core Components
import MenuItem from 'core/components/base/navigation/MenuItem';

// Custom Common Components
import Select from 'core/components/shared/Select';

// Custom Utilities
import { columnCountOptions } from 'features/form/feedbacks/utilities/details/data';
import {
  setFeedbackColumnCount,
  useSelectFeedbackColumnCount,
} from 'features/form/feedbacks/store';

// Custom Types
interface ColumnCountSelectorProps {}

const ColumnCountSelector: React.FC<ColumnCountSelectorProps> = (props) => {
  // Context
  const columnCount = useSelectFeedbackColumnCount();

  // Render
  return (
    <Select
      value={columnCount.toString()}
      onChange={(e) =>
        setFeedbackColumnCount(parseInt(e.target.value as string))
      }
      placeholder='تعداد ستون'
      sx={{ width: '11.625rem', maxHeight: '2.625rem', borderRadius: '.5rem' }}
    >
      {columnCountOptions.map((opt) => (
        <MenuItem key={opt.key} value={opt.value}>
          {opt.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ColumnCountSelector;

// Feature Types
import { PatternTabItemProps } from 'features/appBuilder/patterns/core/types/item/tab';
import { SliderPatternBodyProps } from 'features/appBuilder/patterns/features/slider/types/body';

export const sliderPatternTabDefaults: PatternTabItemProps = {
  isActive: true,
  borderRadius: 8,
  refType: '',
  archiveLink: '',
  archiveTitle: '',
  padding: '0px',
  sourceId: '',
  sourceTitle: '',
  title: '',
  fontColor: '#fff',
  fontSize: '14px',
  fontHoverColor: '#fff',
  highlightColor: '#12252E',
  highlightHoverColor: '#12252E',
  textAlign: 'start',
  textDirection: 'rtl',
};

export const sliderPatternBodyDefaults: SliderPatternBodyProps = {
  isActive: true,
  tabs: [sliderPatternTabDefaults],
};

import * as React from 'react';

// Custom Common Components
import PageHeroBox from 'core/components/shared/Box/PageHero';
import ButtonLink from 'core/components/shared/Button/ButtonLink';
import PageTitle from 'core/components/shared/Typography/PageTitle';

// Custom Icons
import PlusCircleOutlineIcon from 'core/components/icons/PlusCircleOutline';
import Box from 'core/components/base/layout/Box';
import DeleteIconButton from 'features/form/shared/components/iconButton/Delete';
import BodyOne from 'core/components/shared/Typography/BodyOne';

// Context
import { useSelectionContext } from 'core/store/context/common/UseSelection';
import { useDeleteProcessesMutation } from 'features/form/processes/hooks';

// Custom Utilities
import { useDialogContext } from 'core/store/context/DialogProvider';

// Custom Types
interface ProcessesActionsProps {
  onDelete?: () => {};
}

const ProcessesActions: React.FC<ProcessesActionsProps> = (props) => {
  // Context
  const { onOpenDialog, onCloseDialog } = useDialogContext();
  const { selections, onClearSelections } = useSelectionContext();

  // Hooks
  const { mutate: handleDeleteProcesses } = useDeleteProcessesMutation({
    autoAlert: { mode: 'delete', name: 'فرایند' },
    onSuccess: () => {
      onClearSelections();
      onCloseDialog();
    },
  });

  // Utilities
  const handleOpenDialog = () =>
    onOpenDialog({
      title: `حذف فرایند`,
      description: `در صورت حذف فرایند، تمامی اطلاعات ثبت شده شامل ارزیابی‌های صورت گرفته، پرسش‌ها و بازبینی‌های پاسخ داده شده حذف خواهند شد.`,
      onSubmit: () => handleDeleteProcesses(selections),
    });

  // Render
  return (
    <PageHeroBox>
      <PageTitle>لیست فرایندها</PageTitle>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        {selections.length > 0 && (
          <DeleteIconButton
            onClick={handleOpenDialog}
            sx={{ bgcolor: 'transparent' }}
          />
        )}
        {selections.length > 0 && (
          <BodyOne>{selections.length} انتخاب شده</BodyOne>
        )}
        <ButtonLink
          variant='contained'
          color='info'
          startIcon={<PlusCircleOutlineIcon />}
          href='/processes/add'
          sx={{
            '.MuiButton-startIcon': {
              marginRight: '0.25rem',
            },
          }}
        >
          فرایند جدید
        </ButtonLink>
      </Box>
    </PageHeroBox>
  );
};

export default ProcessesActions;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { findAndDelete, findById } from 'core/utilities/helper/array';
import { genBannerItem } from 'features/banner/banners/utilities';

// Custom Types
import type {
  BannerContainerProps,
  BannerItemProps,
} from 'features/banner/banners/types';

interface InitialStateProps {
  containers: BannerContainerProps[];
  isDraft: boolean;
  current: BannerContainerProps | null;
}

const initialState: InitialStateProps = {
  containers: [],
  isDraft: false,
  current: null,
};

export const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    addNewBanner: (state) => {
      if (state.current)
        state.current.data.items.push(
          genBannerItem({ sortNumber: state.current.data.items.length })
        );
    },
    deleteBanner: (state, action: PayloadAction<{ bannerId: string }>) => {
      if (state.current) {
        const { bannerId } = action.payload;

        let sortNumber = 0;
        state.current.data.items = state.current.data.items.filter((item) => {
          if (item.id !== bannerId) {
            item.data.sortNumber = sortNumber;
            ++sortNumber;
            return true;
          } else return false;
        });
      }
    },
    updateBanner: (
      state,
      action: PayloadAction<{ bannerIndex: number; data: BannerItemProps }>
    ) => {
      if (state.current) {
        const { bannerIndex, data } = action.payload;
        state.current.data.items[bannerIndex] = data;
      }
    },
    updateOriginalContainer: (
      state,
      action: PayloadAction<BannerContainerProps>
    ) => {
      const container = action.payload;
      const index = state.containers.findIndex(
        (item) => item.id === container.id
      );

      if (index > -1) state.containers[index] = container;
    },
    deleteContainer: (
      state,
      action: PayloadAction<{ containerId: string }>
    ) => {
      const { containerId } = action.payload;
      state.containers = findAndDelete(state.containers, containerId);
    },
    setBannerContainers: (
      state,
      action: PayloadAction<BannerContainerProps[]>
    ) => {
      state.containers = action.payload;
    },
    setBannerContainerList: (
      state,
      action: PayloadAction<BannerItemProps[]>
    ) => {
      if (state.current) state.current.data.items = action.payload;
    },
    setCurrentContainer: (
      state,
      action: PayloadAction<BannerContainerProps | null>
    ) => {
      state.current = action.payload;
    },
    setCurrentContainerTitle: (state, action: PayloadAction<string>) => {
      if (state.current) state.current.data.title = action.payload;
    },
    cancelChanges: (state) => {
      if (state.current) {
        const container = findById(state.containers, state.current.id);
        if (container) state.current = container;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase('/banner/isDraft/true', (state) => {
      state.isDraft = true;
    });
    builder.addCase('/banner/isDraft/false', (state) => {
      state.isDraft = false;
    });
  },
});

export const {
  setBannerContainers,
  setBannerContainerList,
  setCurrentContainer,
  setCurrentContainerTitle,
  updateOriginalContainer,
  deleteContainer,
  addNewBanner,
  deleteBanner,
  updateBanner,
  cancelChanges,
} = bannerSlice.actions;
export default bannerSlice.reducer;

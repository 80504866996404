import { type FC } from 'react';

// Core Components
import IconButton, {
  type IconButtonProps,
} from 'core/components/base/inputs/IconButton';
import CircularProgress from 'core/components/base/feedback/CircularProgress';

// Icon Components
import BinIcon from 'core/components/icons/Bin';

// Types
interface BinIconButtonProps extends IconButtonProps {
  loading?: boolean;
}

const BinIconButton: FC<BinIconButtonProps> = (props) => {
  const { loading, children, ...otherProps } = props;

  return (
    <IconButton {...otherProps}>
      {loading ? (
        <CircularProgress color='inherit' size={20} />
      ) : (
        <BinIcon color='inherit' fontSize='inherit' />
      )}
    </IconButton>
  );
};

export default BinIconButton;

// Custom Utilitis
import apiHandler from 'core/utilities/apiHandler';
import { getDoc, getDocs, getSummary } from 'core/utilities/apiHandler/helper';
import { managerEndpoints as endpoints } from 'features/manager/managers/utilities/api/endpoints';

// Custom Types
import type { ApiPaginationProps } from 'core/types/shared/pagination/api';
import type {
  ApiGetDocResponse,
  ApiGetDocsResponse,
  ApiGetSummaryResponse,
} from 'core/types/api/hook/response';
import type {
  ManagerDataProps,
  ManagerProps,
  ManagerListItemProps,
  ManagerSummaryProps,
} from 'features/manager/managers/types';

/**
 * Adds a manager using the provided data.
 * @param {ManagerDataProps} data - The data for the manager.
 * @returns {Promise<{ status: number; doc?: ManagerProps }>} - A promise that resolves to an object containing the status and manager (if available).
 */
export const addManager = async (
  data: ManagerDataProps
): Promise<{ status: number; doc?: ManagerProps }> => {
  const dataToSend = { ...data, groups: [data.groupId] };

  const { status } = await apiHandler.post(endpoints.add, dataToSend);

  return { status };
};

/**
 * Edits a manager with the provided ID using the provided data.
 * @param {string} id - The ID of the manager to be edited.
 * @param {ManagerDataProps} data - The updated data for the manager.
 * @returns {Promise<{ status: number; doc?: ManagerProps }>} - A promise that resolves to an object containing the status and manager (if available).
 */
export const editManager = async (
  id: string,
  data: Partial<ManagerDataProps>
): Promise<{ status: number; doc?: ManagerProps }> => {
  const dataToSend = {
    ...data,
    avatar: data.avatar ?? null,
    isEnabled: data.isActive,
    groups: [data.groupId],
  };

  const { status, data: response } = await apiHandler.patch<{
    docs: ManagerProps;
  }>(endpoints.edit(id), dataToSend);

  return { status, doc: response?.docs };
};

/**
 * Deletes one or more managers with the provided IDs.
 * @param {string | string[]} ids - The ID(s) of the manager(s) to be deleted.
 * @returns {Promise<number>} - A promise that resolves to the status code.
 */
export const removeManagers = async (
  ids: string | string[]
): Promise<{ status: number }> => {
  const { status } = await apiHandler.delete(`${endpoints.remove}`, {
    ids: typeof ids === 'string' ? [ids] : ids,
  });

  return { status };
};

/**
 * Retrieves a manager with the provided ID.
 * @param {string} id - The ID of the manager to retrieve.
 * @returns {Promise<ApiGetDocResponse<ManagerProps>>} - A promise that resolves to an object containing the status and doc (if available).
 */
export const getManager = async (
  id: string,
  signal?: AbortSignal
): Promise<ApiGetDocResponse<ManagerProps>> => {
  const endpoint = endpoints.getById(id);

  return await getDoc<ManagerProps, ManagerProps>(endpoint, { signal });
};

/**
 * Fetches a list of managers from the API.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request if needed.
 * @param {Record<string, any>} [queries] - An optional object containing query parameters for filtering the manager list.
 * @returns {Promise<ApiGetDocsResponse<ManagerListItemProps>>} A promise that resolves to an object containing the status and the list of managers, along with pagination information.
 */
export const getManagersList = async (
  signal?: AbortSignal,
  queries?: Record<string, any>
): Promise<ApiGetDocsResponse<ManagerListItemProps>> => {
  return await getDocs<
    { data: ManagerListItemProps[]; paginate: ApiPaginationProps },
    ManagerListItemProps
  >(endpoints.getList(), {
    queries,
    signal,
  });
};

/**
 * Fetches a summary list of managers from the API.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request if needed.
 * @returns {Promise<ApiGetSummaryResponse<ManagerSummaryProps>>} A promise that resolves to an object containing the status and the list of manager summaries.
 */
export const getManagersSummaryList = async (
  signal?: AbortSignal
): Promise<ApiGetSummaryResponse<ManagerSummaryProps>> => {
  const endpoint = endpoints.getList();

  return await getSummary<
    { data: ManagerSummaryProps[]; paginate: ApiPaginationProps },
    ManagerSummaryProps
  >(endpoint, {
    signal,
    returnMutationFn: (status, data) => ({
      status,
      list:
        data.data && data.data.length > 0
          ? data.data.map((manager) => ({
              id: manager.id,
              data: {
                groupId: manager.data.groupId || '',
                groupTitle: manager.data.groupTitle || '',
                fullName: manager.data.fullName || '',
              },
            }))
          : [],
    }),
  });
};

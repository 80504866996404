import {
  TableRow as MuiTableRow,
  TableRowProps as MuiTableRowProps,
  styled,
} from '@mui/material';

export interface TableRowProps extends MuiTableRowProps {}

const TableRow = styled(MuiTableRow)<TableRowProps>(() => ({}));

export default TableRow;

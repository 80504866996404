import cloneDeep from 'lodash/cloneDeep';

// Feature Defaults
import { contentDetailsPatterConfigDefaults } from 'features/appBuilder/patterns/features/contentDetails/utilities/defaults/config';
import { contentDetailsPatterFooterDefaults } from 'features/appBuilder/patterns/features/contentDetails/utilities/defaults/footer';

// Custom Types
import type { ContentDetailsPatternDataProps } from 'features/appBuilder/patterns/features/contentDetails/types';

const contentDetailsPatternDataDefaults: ContentDetailsPatternDataProps = {
  patternTitle: '',
  bgColorOne: '#F5F5F5',
  bgColorTwo: '#F5F5F5',
  dividerColor: '#C1C8D3',
  bgImage: null,
  bgImageType: 'cover',
  padding: '32px',
  cardBg: '#FFFFFF',
  cardCount: 8,
  creator: null,
  isActive: true,
  maxWidth: 'lg',
  sortIndex: 0,
  type: 'contentDetails',
  variant: 0,
  config: contentDetailsPatterConfigDefaults,
  footer: contentDetailsPatterFooterDefaults,
};

/**
 * Retrieves the default content details pattern data.
 *
 * @returns {ContentDetailsPatternDataProps} The default content details pattern data.
 */
export const getContentDetailsPatternDataDefault = () => {
  const cloned = cloneDeep(contentDetailsPatternDataDefaults);

  return cloned;
};

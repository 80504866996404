import Cookies from 'universal-cookie';
import dayjs from 'dayjs';

// Custom Types
import type { PermissionProps } from 'core/types/permission';
import { cloneDeep } from 'lodash';

export const permissionsCookies = new Cookies();

export const getPermissionCookies = (): PermissionProps[] => {
  const permissions = permissionsCookies.get('permissions');

  return permissions;
};

export const setPermissionCookies = (permissions: PermissionProps[]) => {
  const perms = cloneDeep(permissions);

  permissionsCookies.set('permissions', perms, {
    secure: true,
    expires: dayjs().add(1, 'w').toDate(),
  });
};

export const removePermissionCookies = () => {
  permissionsCookies.remove('permissions');
};

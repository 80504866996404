import { type FC } from 'react';
import { alpha, useTheme } from '@mui/material';

// Custom Core Components
import CircularProgress from 'core/components/base/feedback/CircularProgress';

// Custom Common Components
import BinIconButton from 'core/components/shared/IconButton/Bin';

// Custom Feature Components
import UploaderInputAndPreviewWrapper from 'features/file/files/components/shared/Uploader/Wrapper';

// Custom Types
import type { FileType } from 'features/file/files/types';
import type { UploaderInputAndPreviewWrapperProps } from 'features/file/files/components/shared/Uploader/Wrapper';

export interface UploderPreviewProps
  extends UploaderInputAndPreviewWrapperProps {
  disabled?: boolean;
  fileType?: FileType | null;
  previewLink?: string;
  onRemove?: () => void;
}

const UploderPreview: FC<UploderPreviewProps> = (props) => {
  // Props
  const { disabled, fileType, previewLink, onRemove } = props;

  // Hooks
  const { palette } = useTheme();

  // Utilities
  const handleRemove = () => {
    if (!disabled && onRemove) onRemove();
  };

  return (
    <UploaderInputAndPreviewWrapper
      sx={{
        position: 'relative',
        overflow: 'hidden',
        border: '1px solid',
        cursor: 'auto',
      }}
    >
      {!previewLink ? (
        <CircularProgress color='inherit' size={24} />
      ) : previewLink && fileType === 'image' ? (
        <img
          alt='file-uploder-preview'
          src={previewLink}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      ) : (
        '-'
      )}
      <BinIconButton
        disabled={disabled}
        color='error'
        onClick={handleRemove}
        sx={{
          position: 'absolute',
          top: '50%',
          right: '50%',
          transform: 'translate(50%, -50%)',
          backgroundColor: alpha(palette.background.paper, 0.6),
          ':hover': {
            backgroundColor: alpha(palette.background.paper, 0.8),
          },
        }}
      />
    </UploaderInputAndPreviewWrapper>
  );
};

export default UploderPreview;

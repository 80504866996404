import { useCallback, useEffect, useState } from 'react';

// Types
import type { FC } from 'react';

// Core Components
import Box from 'core/components/base/layout/Box';

// Common Components
import DataGrid from 'core/components/shared/DataGrid';
import RouteLink from 'core/components/shared/Link/RouteLink';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Utilities
import { getFeatureTypeLabel } from 'features/appBuilder/templates/utilities/helper';

// Custom Types
import type { DataGridProps } from 'core/components/shared/DataGrid';
import type { TemplateListItemProps } from 'features/appBuilder/templates/types/listItem';
import type {
  DataGridColumn,
  DataGridRowValueProps,
} from 'core/components/shared/DataGrid/dataGrid';

export interface TemplateContainersDataGridProps
  extends Omit<DataGridProps, 'columns' | 'rows'> {
  containers: TemplateListItemProps[];
}

const columns: DataGridColumn[] = [
  {
    field: 'col1',
    headerTitle: 'عنوان',
    width: 250,
    renderCell: (params) => (
      <RouteLink href={`/template/${params.row.col3}`} underline='hover'>
        {params.row.col1}
      </RouteLink>
    ),
  },
  {
    field: 'col2',
    headerTitle: 'نسخه‌های قالب',
    renderCell: (params) => (
      <Box sx={{ display: 'flex', gap: 0.5 }}>
        {(params.row.col2 as string[]).length > 0 ? (
          (params.row.col2 as string[]).map((item, index) => (
            <BodyTwo
              key={`template-list-item-skin-${item}-${index}`}
              sx={{
                backgroundColor: 'background.default',
                p: '0.25rem 0.75rem',
                borderRadius: '0.5rem',
              }}
            >
              {item}
            </BodyTwo>
          ))
        ) : (
          <BodyTwo>-</BodyTwo>
        )}
      </Box>
    ),
  },
];

const TemplateContainersDateGrid: FC<TemplateContainersDataGridProps> = (
  props
) => {
  // Props
  const { containers, ...otherProps } = props;

  // States
  const [rows, setRows] = useState<DataGridRowValueProps[]>([]);

  // Hooks
  const generateRows = useCallback(() => {
    const rowsToSet: DataGridRowValueProps[] = [];

    containers.forEach(({ id, data }) => {
      const row = {
        id,
        col1: getFeatureTypeLabel(data.feature),
        col2: data.skins,
        col3: data.feature,
      };
      rowsToSet.push(row);
    });

    setRows(rowsToSet);
  }, [containers]);

  useEffect(() => {
    generateRows();
  }, [containers, generateRows]);

  return (
    <DataGrid
      headProps={{
        sx: { backgroundColor: '#D8E8F1' },
      }}
      columns={columns}
      rows={rows}
      {...otherProps}
    />
  );
};

export default TemplateContainersDateGrid;

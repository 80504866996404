// Types
import type { FC } from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface AddLayerTwoIconProps extends SvgIconProps {}

const AddLayerTwoIcon: FC<AddLayerTwoIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      d='M16.6,9.7c0-0.6-0.2-1.2-0.7-1.6c-0.4-0.4-1-0.7-1.6-0.7c-2.5,0-7.5,0-10,0c-0.6,0-1.2,0.2-1.6,0.7
	C2.2,8.5,2,9.1,2,9.7c0,2.5,0,7.5,0,10c0,0.6,0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7c2.5,0,7.5,0,10,0c0.6,0,1.2-0.2,1.6-0.7
	c0.4-0.4,0.7-1,0.7-1.6V9.7z M8.5,13.9H5.8c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h2.7v2.7c0,0.4,0.3,0.8,0.8,0.8
	c0.4,0,0.8-0.3,0.8-0.8v-2.7h2.7c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8h-2.7v-2.7c0-0.4-0.3-0.8-0.8-0.8
	c-0.4,0-0.8,0.3-0.8,0.8V13.9z M7.4,6.6h6.9c0.8,0,1.6,0.3,2.2,0.9c0.6,0.6,0.9,1.4,0.9,2.2v6.9h2.3c0.6,0,1.2-0.2,1.6-0.7
	c0.4-0.4,0.7-1,0.7-1.6c0-2.5,0-7.5,0-10c0-0.6-0.2-1.2-0.7-1.6c-0.4-0.4-1-0.7-1.6-0.7c-2.5,0-7.5,0-10,0C9.1,2,8.5,2.2,8.1,2.7
	c-0.4,0.4-0.7,1-0.7,1.6V6.6z'
    />
  </IconBuilder>
);

export default AddLayerTwoIcon;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface CheckboxFilledIconProps extends SvgIconProps {}

const CheckboxFilledIcon: React.FC<CheckboxFilledIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M12,1C5.9,1,1,5.9,1,12s4.9,11,11,11s11-4.9,11-11S18.1,1,12,1z M10.9,15.1l-3-3l1-1l2,2.1l4.2-4.3l1,1L10.9,15.1z' />
  </IconBuilder>
);

export default CheckboxFilledIcon;

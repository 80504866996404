// Custom Hooks
import useDocApi from 'core/hooks/api/useDoc';
import { useAddMutation } from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  editSettings,
  getSettings,
} from 'features/manager/settings/utilities/api';

// Custom Types
import type {
  SettingsDataToSubmit,
  SettingsProps,
} from 'features/manager/settings/types';
import type {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';

/**
 * Query key for fetching manager settings in react query.
 *
 * @constant {Array<string>}
 */
export const managerSettingsQueryKey = ['managerSettings'];

/**
 * Custom hook for fetching the manager settings document.
 *
 * @param {UseDocOptionType<SettingsProps>} [options] - Optional configuration options for the document query.
 * @returns {UseDocApiReturnProps<SettingsProps>} The result of the document query, including the settings document and other query states.
 */
export const useManagerSettingsDoc = (
  options?: UseDocOptionType<SettingsProps>
): UseDocApiReturnProps<SettingsProps> =>
  useDocApi<SettingsProps>(getSettings, managerSettingsQueryKey, options);

/**
 * Custom hook for editing manager settings.
 *
 * @returns {MutationFunction<SettingsDataToSubmit>} The mutation function to edit the settings.
 */
export const useEditSettingsMutation = useAddMutation<SettingsDataToSubmit>(
  editSettings,
  managerSettingsQueryKey
);

import { useSelector, useDispatch } from 'react-redux';

// Types
import type { TypedUseSelectorHook } from 'react-redux';
import { AppDispatch, RootState } from 'core/types/store';

// Custom Types

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface RateFilledIconProps extends SvgIconProps {}
const RateFilledIcon: React.FC<RateFilledIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm8,9a1.65,1.65,0,0,1-.48.47,1.43,1.43,0,0,1-.63.24,1.69,1.69,0,0,1-.67-.05l-2.49,2.49a1.7,1.7,0,0,1,0,.71,1.68,1.68,0,0,1-.3.65,1.53,1.53,0,0,1-.56.45,1.49,1.49,0,0,1-.69.16,1.56,1.56,0,0,1-.7-.16,1.5,1.5,0,0,1-.55-.45,1.56,1.56,0,0,1-.28-1.36l-1.82-1.82a1.56,1.56,0,0,1-1,0l-3,3a1.6,1.6,0,0,1-.71,1.81,1.56,1.56,0,0,1-1,.21A1.58,1.58,0,0,1,4,14a1.6,1.6,0,0,1,1.81-.71l3-3A1.56,1.56,0,0,1,9.1,8.9a1.7,1.7,0,0,1,.55-.45,1.61,1.61,0,0,1,1.4,0,1.7,1.7,0,0,1,.55.45,1.56,1.56,0,0,1,.28,1.36l1.82,1.82a1.56,1.56,0,0,1,1,0l2.45-2.45a1.64,1.64,0,0,1,0-.73,1.46,1.46,0,0,1,.29-.66,1.59,1.59,0,0,1,1.27-.64,1.62,1.62,0,0,1,.65.14,1.68,1.68,0,0,1,.54.41,1.48,1.48,0,0,1,.32.59,1.37,1.37,0,0,1,.05.67A1.6,1.6,0,0,1,20,10Z' />
  </IconBuilder>
);

export default RateFilledIcon;

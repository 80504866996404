import { useState, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';
import Editor from 'core/components/shared/Editor';
import ConfigIconButton from 'features/form/processes/components/details/shared/ConfigIconButton';
import BinIconButton from 'core/components/shared/IconButton/Bin';
import DragIcon from 'core/components/icons/Drag';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Feature Components
import ContentDetailsBlockStyles from 'features/content/contents/components/body/blocks/block/blockStyles';
import ContentDetailsBlockGallery from 'features/content/contents/components/body/blocks/block/gallery';
import ContentDetailsGalleryStyles from 'features/content/contents/components/body/blocks/block/gallery/GalleryStyles';

// Core Utilities
import { extractHtmlText } from 'core/utilities/helper/html';
import { prefixFileURL } from 'core/utilities/helper/link';

// Feature Types
import type { ContentDetailsProps } from 'features/content/contents/types/details';

// Component Types
export interface ContentDetailsBlockProps {
  index?: number;
  defaultValue?: string;
  minimize?: boolean;
  deletable?: boolean;
  focused?: boolean;
  onDelete?: () => void;
  onFocus?: () => void;
  onHTMLChange?: (html: string) => void;
}

const ContentDetailsBlock: FC<ContentDetailsBlockProps> = (props) => {
  // Props
  const {
    index = 0,
    defaultValue,
    minimize,
    deletable,
    focused,
    onDelete,
    onFocus,
    onHTMLChange,
  } = props;

  // Hooks
  const [configMode, setConfigMode] = useState<'block' | 'gallery' | 'none'>(
    'none'
  );
  const { control } = useFormContext<ContentDetailsProps>();

  return (
    <Box
      sx={{
        position: 'relative',
        bgcolor: 'background.paper',
        height: minimize ? '4rem' : '20rem',
        borderRadius: 2,
        p: 2,
        overflow: 'hidden',
        border: '2px solid',
        borderColor: focused && !minimize ? 'text.secondary' : 'transparent',
        cursor: focused ? 'auto' : 'pointer',
        ':hover': {
          borderColor: 'text.disabled',
        },
      }}
      onClick={onFocus}
    >
      <ContentDetailsBlockStyles
        index={index}
        open={configMode === 'block'}
        onClose={() => setConfigMode('none')}
      />
      <ContentDetailsGalleryStyles
        index={index}
        open={configMode === 'gallery'}
        onClose={() => setConfigMode('none')}
      />
      {!focused && (
        <Box
          sx={{
            zIndex: 10,
            position: 'absolute',
            right: '22rem',
            left: 0,
            top: 0,
            bottom: 0,
            bgcolor: 'white',
            opacity: 0.1,
          }}
        />
      )}
      {minimize ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <DragIcon sx={{ cursor: 'grab' }} />
          <Controller
            control={control}
            name={`data.blocks.${index}.data.text`}
            render={({ field }) => (
              <BodyTwo sx={{ maxWidth: '18rem', textWrap: 'nowrap' }}>
                {extractHtmlText(field.value)}
              </BodyTwo>
            )}
          />
          <Controller
            control={control}
            name={`data.blocks.${index}.data.gallery`}
            render={({ field }) => (
              <Box sx={{ display: 'flex', gap: 1 }}>
                {field.value.map((item) => (
                  <Box
                    key={item.id}
                    sx={{
                      width: '2rem',
                      minHeight: '2rem',
                      maxHeight: '2rem',
                      flexShrink: 0,
                      flexGrow: 0,
                      bgcolor: 'background.default',
                      borderRadius: 2,
                      overflow: 'hidden',
                    }}
                  >
                    {item.data.file.data.mimeType.startsWith('image') ? (
                      <img
                        src={prefixFileURL(item.data.file.data.url)}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    ) : (
                      <BodyTwo>{item.data.file.data.mimeType}</BodyTwo>
                    )}
                  </Box>
                ))}
              </Box>
            )}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            height: '100%',
            gap: 2,
          }}
        >
          <Box sx={{ flexGrow: 1, height: '100%' }}>
            <Editor
              defaultValue={defaultValue}
              onBlur={onHTMLChange}
              readonly={!focused}
            />
          </Box>
          <ContentDetailsBlockGallery
            index={index}
            hidden={configMode !== 'none'}
            onOpenSetting={() => setConfigMode('gallery')}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignSelf: 'stretch',
            }}
          >
            <ConfigIconButton onClick={() => setConfigMode('block')} />
            {deletable && (
              <BinIconButton
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete && onDelete();
                }}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ContentDetailsBlock;

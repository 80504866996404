import { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

// Types
import type { FC, MouseEvent } from 'react';
import type { SubmitHandler } from 'react-hook-form';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Core Components
import Box from 'core/components/base/layout/Box';
import Button from 'core/components/base/inputs/Button';
import Menu from 'core/components/base/navigation/Menu';
import IconButton from 'core/components/base/inputs/IconButton';
import MenuItem from 'core/components/base/navigation/MenuItem';
import LoadingButton from 'core/components/base/inputs/LoadingButton';
import CircularProgress from 'core/components/base/feedback/CircularProgress';

// Common Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import ColorPicker from 'core/components/shared/Picker/Color';
import RowStack from 'core/components/shared/Stack/RowStack';

// Feature Components
import CustomPageBodyPatterns from 'features/appBuilder/templates/components/details/CustomPageBodyPatterns';

// Icon Components
import DeleteIcon from 'core/components/icons/DeleteIcon';
import DuplicateIcon from 'core/components/icons/Duplicate';
import PlusIcon from 'core/components/icons/Plus';
import LtrDirIcon from 'core/components/icons/LtrDirection';
import RtlDirIcon from 'core/components/icons/RtlDirection';

// Custom Utilities
import { isSucceed, setAppAlert, setAppLoading } from 'core/utilities/helper';
import { getTemplateSkinList } from 'features/appBuilder/skins/utilities/api';
import {
  useAddTemplateBodyMutation,
  useDeleteTemplateBodyMutation,
  useDuplicateTemplateBodyMutation,
  useUpdateTemplateBodyMutation,
} from 'features/appBuilder/templates/hooks';

// Custom Types
import type { TemplateSkinProps } from 'features/appBuilder/skins/types/skin';
import type { TemplateFeatureType } from 'features/appBuilder/templates/types/featureType';
import type {
  TemplateOperation,
  TemplateProps,
} from 'features/appBuilder/templates/types';

export interface TemplateBodyComponentProps {
  type: TemplateFeatureType | '';
  operation: TemplateOperation;
  onOperationChange: (operation: TemplateOperation) => void;
}

const TemplateBody: FC<TemplateBodyComponentProps> = (props) => {
  // Props
  const { type, operation, onOperationChange } = props;

  // States
  const [activeBody, setActiveBody] = useState<number>(-1);
  const [options, setOptions] = useState<TemplateSkinProps[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Hooks
  const loading = useSelectLoading();
  const { control, handleSubmit, getValues, setValue } =
    useFormContext<TemplateProps>();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'data.bodies',
    keyName: 'fieldId',
  });

  const { mutateAsync: addTemplateBody } = useAddTemplateBodyMutation();
  const { mutateAsync: duplicateTemplateBody } =
    useDuplicateTemplateBodyMutation();
  const { mutateAsync: updateTemplateBody } = useUpdateTemplateBodyMutation();
  const { mutateAsync: deleteTemplateBody } = useDeleteTemplateBodyMutation();

  // Utilities
  const handleAddBtnClick = async (event: MouseEvent<HTMLButtonElement>) => {
    setAppLoading(true);
    setAnchorEl(event.currentTarget);
    const bodies = getValues('data.bodies');
    const bodyIds = bodies.map((body) => body.data.skinId);
    const { list = [], status } = await getTemplateSkinList();

    if (isSucceed(status) && list.length > 0) {
      const newBoddies = list.filter((option) => !bodyIds.includes(option.id));
      setOptions(newBoddies);
    } else {
      setOptions([]);
    }

    setAppLoading(false);
  };

  const handleSkinMenuClose = () => {
    setAnchorEl(null);
  };

  const handleBodyButtonClick = (index: number) => () => {
    if (activeBody !== index) {
      if (
        activeBody !== -1 &&
        fields.length > activeBody &&
        fields[activeBody].id === 'draft'
      ) {
        setAppAlert(
          `ابتدا اسکین ساخته شده (${fields[activeBody].data.skinTitle}) را ذخیره یا حذف کنید.`,
          'warning'
        );
      } else {
        setActiveBody(index);
      }
    }
  };

  const handleAddBody = (skin: TemplateSkinProps) => () => {
    if (type) {
      const index = getValues('data.bodies').length;
      onOperationChange('INIT_BODY');

      if (
        activeBody !== -1 &&
        fields.length > activeBody &&
        fields[activeBody].id === 'draft'
      ) {
        setAppAlert(
          `ابتدا اسکین ساخته شده (${fields[activeBody].data.skinTitle}) را ذخیره یا حذف کنید.`,
          'warning'
        );
        return;
      }

      append({
        id: 'draft',
        data: {
          bgColor: 'rgba(0,0,0,1)',
          direction: 'rtl',
          patterns: [],
          skinId: skin.id,
          featureType: type,
          skinTitle: skin.data.title,
        },
      });
      handleSkinMenuClose();
      setActiveBody(index);
    }
  };

  const toggleBodyDir = (index: number, dir: 'rtl' | 'ltr') => () => {
    setValue(
      `data.bodies.${index}.data.direction`,
      dir === 'ltr' ? 'rtl' : 'ltr'
    );
  };

  const handleCancel = (index: number) => {
    remove(index);
    onOperationChange('INIT');
  };

  const handleBodyDelete = async () => {
    if (activeBody > -1 && getValues('data.bodies').length > activeBody) {
      onOperationChange('DEL_BODY');
      const bodyId = getValues(`data.bodies.${activeBody}`).id;
      await deleteTemplateBody(bodyId);
      setActiveBody(activeBody - 1);
    }
    onOperationChange('INIT');
  };

  const handleBodyDuplicate = async () => {
    if (activeBody > -1 && getValues('data.bodies').length > activeBody) {
      onOperationChange('DUP_BODY');
      const bodyId = getValues(`data.bodies.${activeBody}`).id;
      await duplicateTemplateBody(bodyId);
    }
    onOperationChange('INIT');
  };

  const handleBodySubmit: SubmitHandler<TemplateProps> = async (page) => {
    if (activeBody > -1 && getValues('data.bodies').length > activeBody) {
      onOperationChange('INIT_BODY');

      const isNew = page.data.bodies[activeBody].id === 'draft';

      if (isNew) {
        await addTemplateBody(page.data.bodies[activeBody].data);
      } else {
        await updateTemplateBody(page.data.bodies[activeBody]);
      }

      onOperationChange('INIT');
    }
  };

  // Render
  return (
    <ColumnStack>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          background: 'rgba(193, 200, 211, 0.3)',
          p: '1.5rem 1rem',
          borderRadius: '0.5rem',
        }}
      >
        <BodyOne color='text.secondary' mr='1rem'>
          اسکین
        </BodyOne>
        <Controller
          defaultValue={[]}
          control={control}
          name='data.bodies'
          render={({ field }) => (
            <>
              {field.value.map((b, index) => (
                <Box
                  key={b.id}
                  onClick={handleBodyButtonClick(index)}
                  sx={{
                    backgroundColor: 'background.paper',
                    cursor: activeBody === index ? 'text' : 'pointer',
                    p: '0.5rem 1rem',
                    borderRadius: '0.25rem',
                    opacity: index === activeBody ? '1' : '0.8',
                    color:
                      index === activeBody ? 'text.primary' : 'text.secondary',
                    ':hover': { opacity: '1', color: 'text.primary' },
                  }}
                >
                  <BodyOne>
                    {getValues(`data.bodies.${index}.data.skinTitle`)}
                  </BodyOne>
                </Box>
              ))}
            </>
          )}
        />
        <IconButton
          sx={{
            backgroundColor: 'background.paper',
            opacity: '0.8',
            borderRadius: '0.25rem',
            color: 'text.secondary',
            cursor: loading ? 'default' : 'pointer',
            height: '40px',
            width: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ':hover': {
              backgroundColor: 'background.paper',
              color: 'text.primary',
              opacity: '1',
            },
          }}
          id='skin-menu-button'
          aria-controls={open ? 'skin-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleAddBtnClick}
        >
          {open && loading ? (
            <CircularProgress size={16} />
          ) : (
            <PlusIcon fontSize='small' />
          )}
        </IconButton>
        <Menu
          id='skin-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleSkinMenuClose}
          MenuListProps={{
            'aria-labelledby': 'skin-menu-button',
          }}
        >
          {options.map((opt) => (
            <MenuItem key={opt.id} onClick={handleAddBody(opt)}>
              {opt.data.title}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {activeBody > -1 &&
        getValues(`data.bodies`).length > activeBody &&
        getValues(`data.bodies.${activeBody}`) && (
          <ColumnStack>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <RowStack spacing={3} alignItems='center'>
                <Controller
                  control={control}
                  name={`data.bodies.${activeBody}.data.direction`}
                  render={({ field }) => (
                    <Box
                      sx={{
                        backgroundColor: 'background.paper',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '3rem',
                        height: '3rem',
                        borderRadius: '0.5rem',
                        cursor: 'pointer',
                      }}
                      onClick={toggleBodyDir(activeBody, field.value)}
                    >
                      {getValues(`data.bodies.${activeBody}.data.direction`) ===
                      'rtl' ? (
                        <RtlDirIcon />
                      ) : (
                        <LtrDirIcon />
                      )}
                    </Box>
                  )}
                />
                <Controller
                  control={control}
                  defaultValue='#fff'
                  name={`data.bodies.${activeBody}.data.bgColor`}
                  render={({ field }) => (
                    <ColorPicker
                      sx={{
                        position: 'relative',
                        top: '2px',
                      }}
                      color={getValues(
                        `data.bodies.${activeBody}.data.bgColor`
                      )}
                      disabled={loading}
                      onColorChange={(color) => field.onChange(color)}
                    />
                  )}
                />
              </RowStack>
              <RowStack spacing={1}>
                {getValues(`data.bodies.${activeBody}.id`) === 'draft' ? (
                  <Button
                    onClick={() => handleCancel(activeBody)}
                    color='error'
                    variant='outlined'
                  >
                    انصراف
                  </Button>
                ) : (
                  <>
                    <IconButton
                      disabled={loading}
                      color='error'
                      onClick={handleBodyDelete}
                      sx={{
                        borderRadius: '0.25rem',
                        border: '1px solid',
                        borderColor: loading ? 'text.disabled' : 'error.main',
                      }}
                    >
                      {operation === 'DEL_BODY' && loading ? (
                        <CircularProgress color='error' size={22} />
                      ) : (
                        <DeleteIcon />
                      )}
                    </IconButton>
                    <LoadingButton
                      disabled={loading}
                      loading={operation === 'UP_BODY' && loading}
                      loadingPosition='start'
                      variant='outlined'
                      color='inherit'
                      onClick={handleBodyDuplicate}
                      startIcon={<DuplicateIcon />}
                    >
                      کپی
                    </LoadingButton>
                  </>
                )}
                <LoadingButton
                  disabled={loading || operation === 'BODY_PATTERN'}
                  loading={operation === 'UP_BODY' && loading}
                  loadingPosition='start'
                  startIcon={<></>}
                  variant='outlined'
                  onClick={handleSubmit(handleBodySubmit)}
                  sx={{ px: '2.5rem' }}
                >
                  ذخیره
                </LoadingButton>
              </RowStack>
            </Box>
            <CustomPageBodyPatterns
              operation={operation}
              onOperationChange={onOperationChange}
              activeBody={activeBody}
              update={update}
            />
          </ColumnStack>
        )}
    </ColumnStack>
  );
};

export default TemplateBody;

import * as React from 'react';

// Core Components
import Box from 'core/components/base/layout/Box';
import Paper from 'core/components/base/surfaces/Paper';

// Custom Common Components
import Prefixer from 'core/components/shared/Prefixer';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';
import FilePreviewAndDownload from 'core/components/shared/FilePreviewAndDownload';

// Custom Feature Components
import TextSeperatedByDivider from 'features/form/feedbacks/components/details/Process/shared/TextSeperatedByDivider';

// Custom Icons
import QuoteIcon from 'features/form/feedbacks/components/icons/Quote';

// Custom Utilities
import { getLocaleDateAndTime } from 'core/utilities/helper/date';
import { getFileSource } from 'core/utilities/helper/helperPack';

// Custom Types
import type { FileProps } from 'features/file/files/types';
import type { SxProps, Theme } from '@mui/material';

interface CommentProps {
  message?: string;
  files?: FileProps[];
  fullName?: string;
  completionDate?: string;
  sx?: SxProps<Theme>;
}

const ProcessComment: React.FC<CommentProps> = (props) => {
  // Props
  const { message, fullName, completionDate, files, sx } = props;

  // Render
  return (
    <SpaceBetween
      component={Paper}
      sx={{
        boxShadow: 'none',
        alignItems: 'center',
        borderRadius: '.5rem',
        padding: '.5rem',
        gap: '.5rem',
        width: { xs: '100%', md: '50%' },
        '@media print': {
          border: ({ palette }) => `1px solid ${palette.divider}`,
        },
        ...sx,
      }}
    >
      {(message || fullName || completionDate) && (
        <ColumnStack gap='.5rem'>
          {message && <BodyTwo color='body'>{message}</BodyTwo>}
          {(fullName || completionDate) && (
            <Prefixer
              gap='.25rem'
              Prefix={
                <QuoteIcon
                  sx={{
                    color: '#AEAEAE',
                  }}
                />
              }
            >
              <TextSeperatedByDivider
                color='body'
                firstText={fullName}
                secondText={
                  completionDate
                    ? getLocaleDateAndTime(completionDate, '-')
                    : ''
                }
                secondTextProps={{
                  sx: {
                    direction: 'rtl',
                  },
                }}
              />
            </Prefixer>
          )}
        </ColumnStack>
      )}
      {files && files.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            maxWidth: '200px',
            flexWrap: 'wrap',
            gap: '.5rem',
            justifyContent: 'end',
          }}
        >
          {files.map((file) => (
            <FilePreviewAndDownload
              sx={{ width: 48, height: 48 }}
              src={getFileSource(file)}
              key={file.id}
            />
          ))}
        </Box>
      )}
    </SpaceBetween>
  );
};

export default ProcessComment;

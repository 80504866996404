// Custom Types
import type { ApiBakedTemplateBodyProps } from 'features/appBuilder/templates/types/api';
import type { BakedPatternInBodyDataProps } from 'features/appBuilder/patterns/types/inBody';
import type { TemplateBodyProps } from 'features/appBuilder/templates/types/body';

export const bakeTemplateBody = (
  body: TemplateBodyProps
): ApiBakedTemplateBodyProps => {
  const headers: BakedPatternInBodyDataProps[] = [];
  const footers: BakedPatternInBodyDataProps[] = [];
  const contents: BakedPatternInBodyDataProps[] = [];
  const profiles: BakedPatternInBodyDataProps[] = [];
  const stables: BakedPatternInBodyDataProps[] = [];
  const contentDetails: BakedPatternInBodyDataProps[] = [];
  const forms: BakedPatternInBodyDataProps[] = [];
  const tagAndCategories: BakedPatternInBodyDataProps[] = [];
  const searches: BakedPatternInBodyDataProps[] = [];
  const groups: BakedPatternInBodyDataProps[] = [];
  const sliders: BakedPatternInBodyDataProps[] = [];

  body.data.patterns.forEach((p) => {
    switch (p.data.type) {
      case 'content':
        contents.push({
          ...p.data,
          contentId: p.id,
        });
        break;

      case 'footer':
        footers.push({
          ...p.data,
          footerId: p.id,
        });
        break;

      case 'header':
        headers.push({
          ...p.data,
          headerId: p.id,
        });
        break;

      case 'profile':
        profiles.push({
          ...p.data,
          profileId: p.id,
        });
        break;

      case 'stable':
        stables.push({
          ...p.data,
          stableId: p.id,
        });
        break;

      case 'contentDetails':
        contentDetails.push({
          ...p.data,
          contentDetailsId: p.id,
        });
        break;

      case 'form':
        forms.push({
          ...p.data,
          formId: p.id,
        });
        break;

      case 'tagAndCategory':
        tagAndCategories.push({
          ...p.data,
          tagAndCategoryId: p.id,
        });
        break;

      case 'search':
        searches.push({
          ...p.data,
          searchId: p.id,
        });
        break;

      case 'group':
        groups.push({
          ...p.data,
          groupId: p.id,
        });
        break;

      case 'slider':
        sliders.push({
          ...p.data,
          sliderId: p.id,
        });
        break;

      default:
        return;
    }
  });

  const { patterns, ...otherData } = body.data;

  return {
    id: body.id,
    data: {
      ...otherData,
      title: `template-body-${body.data.featureType}-${body.data.skinTitle}`,
      bodyType: 'template',
      headers,
      footers,
      contents,
      profiles,
      stables,
      contentDetails: contentDetails,
      forms,
      tagAndCategories,
      searches,
      groups,
      sliders,
    },
  };
};

import { useCallback, useEffect, useState } from 'react';

// Types
import type { FC } from 'react';

// Custom Common Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import DataGrid from 'core/components/shared/DataGrid';
import RouteLink from 'core/components/shared/Link/RouteLink';
import RowStack from 'core/components/shared/Stack/RowStack';

// Types
import type { CustomPageSummaryProps } from 'features/appBuilder/customPages/types';
import type { DataGridProps } from 'core/components/shared/DataGrid';
import type {
  DataGridColumn,
  DataGridRowValueProps,
} from 'core/components/shared/DataGrid/dataGrid';
import type { AccessProps } from 'features/audience/accesses/types';

export interface CustomPageListDataGridProps
  extends Omit<DataGridProps, 'columns' | 'rows'> {
  pages: CustomPageSummaryProps[];
}

const columns: DataGridColumn[] = [
  {
    field: 'col1',
    headerTitle: 'عنوان',
    width: 200,
    renderCell: (params) => (
      <RouteLink
        href={`/custom-page/edit`}
        state={params.row.id}
        underline='hover'
      >
        {params.row.col1}
      </RouteLink>
    ),
  },
  {
    field: 'col2',
    headerTitle: 'آدرس',
    sx: { flexGrow: 1 },
    renderCell: (params) => <BodyOne dir='ltr'>{params.row.col2}</BodyOne>,
  },
  {
    field: 'col3',
    width: 450,
    headerTitle: 'نسخه‌های پرایوت بر اساس اکسس (محدودیت دسترسی)',
    renderCell: (params) => (
      <RowStack>
        {(params.row.col3 as AccessProps[]).map((customPage) => (
          <BodyTwo
            id={customPage.id}
            sx={{
              backgroundColor: 'background.default',
              p: '0.25rem 0.5rem',
              borderRadius: '0.25rem',
            }}
          >
            {(customPage.data && customPage.data.title) ?? '-'}
          </BodyTwo>
        ))}
      </RowStack>
    ),
  },
];

const CustomPageListDateGrid: FC<CustomPageListDataGridProps> = (props) => {
  // Props
  const { pages, ...otherProps } = props;

  // States
  const [rows, setRows] = useState<DataGridRowValueProps[]>([]);

  // Hooks
  const generateRows = useCallback(() => {
    const rowsToSet: DataGridRowValueProps[] = [];

    pages.forEach(({ id, data }) => {
      const row = {
        id,
        col1: data.title,
        col2: data.slug,
        col3: data.access || [],
      };
      rowsToSet.push(row);
    });

    setRows(rowsToSet);
  }, [pages]);

  useEffect(() => {
    generateRows();
  }, [pages, generateRows]);

  return (
    <DataGrid
      selectable={false}
      headProps={{
        sx: { backgroundColor: '#D8E8F1' },
      }}
      columns={columns}
      rows={rows}
      {...otherProps}
    />
  );
};

export default CustomPageListDateGrid;

import {
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  styled,
} from '@mui/material';

export interface DialogTitleProps extends MuiDialogTitleProps {}

const DialogTitle = styled(MuiDialogTitle)<DialogTitleProps>(() => ({}));

export default DialogTitle;

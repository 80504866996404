import {
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
  styled,
} from '@mui/material';

export interface MenuItemProps extends MuiMenuItemProps {}

const MenuItem = styled(MuiMenuItem)<MenuItemProps>(() => ({}));

export default MenuItem;

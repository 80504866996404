import * as React from 'react';
import { alpha, Fade } from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import isEqual from 'lodash/isEqual';

import type { SubmitHandler } from 'react-hook-form';

// Custom Common Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import CloseIconButton from 'core/components/shared/IconButton/Close';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import RowStack from 'core/components/shared/Stack/RowStack';
import SpacedBox from 'core/components/shared/Box/SpacedBox';
import SubmitButton from 'core/components/shared/Button/SubmitButton';
import TextFieldOutlined from 'core/components/shared/TextField/Outlined';
import FileUploader from 'core/components/shared/Input/NewFileUploader';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Checkbox from 'core/components/base/inputs/Checkbox';
import Collapse from 'core/components/base/utils/Collapse';
import FormControlLabel from 'core/components/base/inputs/FormControlLabel';
import IconButton from 'core/components/base/inputs/IconButton';
import Paper from 'core/components/base/surfaces/Paper';
import Table from 'core/components/base/display/Table';
import TableBody from 'core/components/base/display/TableBody';
import TableCell from 'core/components/base/display/TableCell';
import TableRow from 'core/components/base/display/TableRow';

// Custom Icon Components
import BinIcon from 'core/components/icons/Bin';
import DragIcon from 'core/components/icons/Drag';
import EditIcon from 'core/components/icons/Edit';

// Custom Hooks
import { useAppDispatch } from 'core/hooks/redux';
import useRefId from 'core/hooks/useRefId';

// Custom Utilites
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { genBannerItemButton } from 'features/banner/banners/utilities';
import { deleteBanner, updateBanner } from 'core/store/slices/feature/banner';
import {
  fileToObjectUrl,
  getFileSource,
  getNumberTitle,
} from 'core/utilities/helper/helperPack';

// Custom Types
import type { BoxProps } from 'core/components/base/layout/Box';
import type {
  BannerItemDataProps,
  BannerItemProps,
} from 'features/banner/banners/types';

interface BannerItemComponentProps extends BoxProps {
  banner: BannerItemProps;
  bannerIndex: number;
}

const BannerItem: React.FC<BannerItemComponentProps> = (props) => {
  // Props
  const { banner, bannerIndex, ...otherProps } = props;

  // States
  const [mode, setMode] = React.useState<'preview' | 'edit'>('preview');

  // Hooks
  const dispatch = useAppDispatch();
  const loading = useSelectLoading();
  const { control, handleSubmit, reset } = useForm<BannerItemDataProps>({
    mode: 'onTouched',
    defaultValues: { ...banner.data },
  });

  const { fields } = useFieldArray({
    control,
    name: 'buttons',
    keyName: 'btnId',
  });

  // useEffects
  React.useEffect(() => {
    const { buttons = [genBannerItemButton(), genBannerItemButton()] } =
      banner.data;

    reset({
      ...banner.data,
      buttons,
    });
  }, [banner]);

  React.useEffect(() => {
    if (mode === 'preview') setTimeout(() => reset({ ...banner.data }), 400);
  }, [mode]);

  // Utilities
  const handleItemDelete = () => {
    dispatch(deleteBanner({ bannerId: banner.id }));
    setMode('preview');
  };

  const handleFormSubmit: SubmitHandler<BannerItemDataProps> = (data) => {
    const image =
      data.image instanceof File ? fileToObjectUrl(data.image) : data.image;

    const updatedData = {
      id: banner.id,
      data: {
        ...data,
        image,
      },
    };

    dispatch(updateBanner({ bannerIndex, data: updatedData }));
    setMode('preview');
  };

  // Render
  return (
    <Fade in={true}>
      <Box {...otherProps}>
        <Paper
          sx={{
            borderRadius: mode === 'edit' ? '0.5rem 0.5rem 0 0' : '0.5rem',
            transition:
              mode === 'edit' ? 'border-radius 0s' : 'border-radius 2.45s',
            boxShadow: 'none',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          {mode === 'edit' && (
            <Box
              sx={({ palette }) => ({
                background: alpha(palette.info.dark, 0.085),
                height: '100%',
                position: 'absolute',
                width: '100%',
                zIndex: 10,
              })}
            />
          )}
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: '3rem' }}>
                  <DragIcon
                    className='bannerItemInputHandle'
                    sx={{
                      color:
                        mode === 'edit' ? 'text.disabled' : 'text.secondary',
                    }}
                  />
                </TableCell>
                <TableCell
                  color={mode === 'edit' ? 'text.disabled' : 'text.secondary'}
                  style={{
                    color: 'inherit',
                    width: '9rem',
                    maxWidth: '9rem',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {banner.data.title}
                </TableCell>
                <TableCell style={{ width: '10rem' }}>
                  {banner.data.image ? (
                    <img
                      alt=''
                      src={getFileSource(banner.data.image)}
                      width='70px'
                      height='50px'
                      style={{ objectFit: 'contain' }}
                    />
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell
                  color={mode === 'edit' ? 'text.disabled' : 'text.secondary'}
                  style={{
                    width: '10rem',
                    maxWidth: '10rem',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {banner.data.link || '-'}
                </TableCell>
                <TableCell
                  color={mode === 'edit' ? 'text.disabled' : 'text.secondary'}
                  style={{ width: '20rem', padding: 0 }}
                >
                  <BodyOne
                    sx={({ breakpoints }) => ({
                      height: '1.5rem',
                      margin: '1rem',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '20rem',
                      [breakpoints.down('lg')]: {
                        width: '12rem',
                      },
                    })}
                  >
                    {banner.data.description || '-'}
                  </BodyOne>
                </TableCell>
                <TableCell style={{ width: '5rem' }}>
                  <IconButton
                    color='info'
                    disabled={loading || mode === 'edit'}
                    onClick={() =>
                      setMode(mode === 'edit' ? 'preview' : 'edit')
                    }
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell style={{ width: '5rem' }}>
                  <IconButton
                    disabled={loading || mode === 'edit'}
                    onClick={handleItemDelete}
                  >
                    <BinIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Collapse in={mode === 'edit'} unmountOnExit timeout={500}>
          <Paper
            sx={{
              borderRadius: '0 0 0.5rem 0.5rem',
              boxShadow: 'none',
              padding: '1.5rem',
            }}
          >
            <ColumnStack spacing={1}>
              <RowStack direction='row-reverse'>
                <CloseIconButton
                  disabled={loading}
                  onClick={() => setMode('preview')}
                />
              </RowStack>
              <RowStack
                style={{ justifyContent: 'center', marginBottom: '1rem' }}
              >
                <ColumnStack sx={{ width: '26rem' }}>
                  <ColumnStack spacing={1}>
                    <InputLabel gutterBottom={false} disabled={loading}>
                      عنوان
                    </InputLabel>
                    <Controller
                      control={control}
                      name='title'
                      render={({ field }) => (
                        <TextFieldOutlined
                          fullWidth
                          disabled={loading}
                          placeholder='عنوان'
                          {...field}
                        />
                      )}
                    />
                  </ColumnStack>
                  <ColumnStack spacing={1}>
                    <InputLabel gutterBottom={false} disabled={loading}>
                      آدرس
                    </InputLabel>
                    <Controller
                      control={control}
                      name='link'
                      render={({ field }) => (
                        <TextFieldOutlined
                          fullWidth
                          dir={field.value ? 'auto' : 'rtl'}
                          disabled={loading}
                          placeholder='آدرس بنر'
                          {...field}
                        />
                      )}
                    />
                  </ColumnStack>
                </ColumnStack>
                <Controller
                  control={control}
                  name='image'
                  render={({ field }) => (
                    <FileUploader
                      location='banner'
                      accept={['image']}
                      onUploadChange={(file) => field.onChange(file)}
                      onUploadRemove={() => field.onChange(null)}
                      disabled={loading}
                      previewProps={{
                        previewSrc: field.value
                          ? getFileSource(field.value)
                          : '',
                      }}
                      sx={{
                        width: '50%',
                        minHeight: '90%',
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='description'
                  render={({ field }) => (
                    <TextFieldOutlined
                      disabled={loading}
                      placeholder='توضیحات'
                      multiline
                      rows={6}
                      InputProps={{
                        sx: { height: 'auto' },
                      }}
                      sx={{ width: '32rem' }}
                      {...field}
                    />
                  )}
                />
              </RowStack>
              {fields.map((field, index) => (
                <SpacedBox key={field.btnId} sx={{ gap: 2 }}>
                  <ColumnStack spacing={0} width='100%'>
                    <InputLabel>
                      {`عنوان دکمه ${getNumberTitle(index + 1)}`}
                    </InputLabel>
                    <Controller
                      control={control}
                      name={`buttons.${index}.data.title`}
                      defaultValue=''
                      render={({ field }) => (
                        <TextFieldOutlined
                          fullWidth
                          disabled={loading}
                          placeholder='نوشته'
                          {...field}
                        />
                      )}
                    />
                  </ColumnStack>
                  <ColumnStack spacing={0} width='100%'>
                    <InputLabel disabled={loading}>
                      {`لینک دکمه ${getNumberTitle(index + 1)}`}
                    </InputLabel>
                    <Controller
                      control={control}
                      name={`buttons.${index}.data.link`}
                      defaultValue=''
                      render={({ field }) => (
                        <TextFieldOutlined
                          fullWidth
                          dir='ltr'
                          disabled={loading}
                          placeholder='Url://www...'
                          {...field}
                        />
                      )}
                    />
                  </ColumnStack>
                </SpacedBox>
              ))}
              <SpacedBox>
                <Controller
                  control={control}
                  name='isActive'
                  render={({ field }) => (
                    <FormControlLabel
                      disabled={loading}
                      label='حالت فعال'
                      componentsProps={{
                        typography: {
                          fontSize: '14px',
                        },
                      }}
                      control={
                        <Checkbox
                          disabled={loading}
                          size='small'
                          checked={field.value}
                          {...field}
                        />
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='title'
                  render={({ field }) => (
                    <SubmitButton
                      onClick={handleSubmit(handleFormSubmit)}
                      disabled={loading || (field.value ? false : true)}
                    >
                      ذخیره
                    </SubmitButton>
                  )}
                />
              </SpacedBox>
            </ColumnStack>
          </Paper>
        </Collapse>
      </Box>
    </Fade>
  );
};

export default React.memo(BannerItem, (prev, next) =>
  isEqual(prev.banner, next.banner)
);

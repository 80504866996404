// Custom Page Components
import Settings from 'features/manager/settings/components';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'manager';
const subFeature: SubFeatureType = 'managerSettings';
const managerSettingsRoutes: ProtectedRouteProps[] = [
  {
    path: '/settings',
    Component: Settings,
    feature,
    subFeature,
  },
];

export default managerSettingsRoutes;

// Custom Utilities
import getEndpoint from 'core/utilities/helper/getEndpoint';
import apiHandler from 'core/utilities/apiHandler';
import { getDocs } from 'core/utilities/apiHandler/helper';

// Custom Types
import type { TemplateSkinProps } from 'features/appBuilder/skins/types/skin';
import type { ApiGetDocsResponse } from 'core/types/api/hook/response';

/**
 * Fetches a list of template skins.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<ApiGetDocsResponse<TemplateSkinProps>>} The status of the request and the list of skins.
 */
export const getTemplateSkinList = async (
  signal?: AbortSignal
): Promise<ApiGetDocsResponse<TemplateSkinProps>> => {
  const endpoint = getEndpoint().getSkinsURL + '?limit=210';

  return await getDocs<{ skins: TemplateSkinProps[] }, TemplateSkinProps>(
    endpoint,
    {
      signal,
      returnMutationFn: (status, data) => ({ status, list: data?.skins || [] }),
    }
  );
};

/**
 * Adds a new template skin.
 *
 * @param {string} title - The title of the skin to be added.
 * @returns {Promise<{ status: number }>} The status of the request.
 */
export const addTemplateSkin = async (
  title: string
): Promise<{ status: number }> => {
  const endpoint = getEndpoint().createSkinURL;
  const { status } = await apiHandler.post(endpoint, { title });
  return { status };
};

/**
 * Updates an existing template skin.
 *
 * @param {string} skinId - The ID of the skin to be updated.
 * @param {string} title - The new title for the skin.
 * @returns {Promise<{ status: number }>} The status of the request.
 */
export const updateTemplateSkin = async (
  skinId: string,
  title: string
): Promise<{ status: number }> => {
  const endpoint = `${getEndpoint().updateSkinURL}/${skinId}`;
  const { status } = await apiHandler.patch(endpoint, { title });
  return { status };
};

/**
 * Deletes a template skin.
 *
 * @param {string} skinId - The ID of the skin to be deleted.
 * @returns {Promise<{ status: number }>} The status of the request.
 */
export const deleteTemplateSkin = async (
  skinId: string
): Promise<{ status: number }> => {
  const endpoint = getEndpoint().deleteSkinURL;
  const { status } = await apiHandler.delete(endpoint, {
    ids: [skinId],
  });
  return { status };
};

/**
 * Fetches a list of skins.
 *
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the request.
 * @returns {Promise<ApiGetDocsResponse<TemplateSkinProps>>} The status of the request and the list of skins.
 */
export const getSkinsList = async (
  signal?: AbortSignal
): Promise<ApiGetDocsResponse<TemplateSkinProps>> => {
  const endpoint = getEndpoint().getSkinsURL + '?limit=210';

  return await getDocs<{ skins: TemplateSkinProps[] }, TemplateSkinProps>(
    endpoint,
    {
      signal,
      returnMutationFn: (status, data) => ({ status, list: data?.skins || [] }),
    }
  );
};

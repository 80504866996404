import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
// Types
import type { FC } from 'react';
import type { SxProps, Theme } from '@mui/material';

// Custom Common Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Core Components
import Select from 'core/components/base/inputs/Select';
import ListItemText from 'core/components/base/display/ListItemText';
import ListItemButton from 'core/components/base/display/ListItemButton';

// Custom Utilities
import {
  getPatternTitle,
  patternTypesArray,
} from 'features/appBuilder/patterns/core/utilities/helper/type';

// Custom Types
import type { PatternType } from 'features/appBuilder/patterns/core/types/item';
import { forLoop } from 'core/utilities/helper';

export interface PatternTypeAutocompleteProps {
  disabled?: boolean;
  defaultValue?: PatternType;
  onChange?: (patternType: PatternType) => void;
  sx?: SxProps<Theme>;
  selectedPatterns?: PatternType[];
  exclude?: PatternType[];
}

const PatternTypeAutocomplete: FC<PatternTypeAutocompleteProps> = (props) => {
  // Props
  const { disabled, defaultValue, exclude, sx, selectedPatterns, onChange } =
    props;

  // States
  const [value, setValue] = useState<PatternType>('header');
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<PatternType[]>(patternTypesArray);

  // Hooks
  const location = useLocation();
  const pagePath = location.pathname;
  const isTemplatePage = location.pathname.startsWith('/template/');

  useEffect(() => {
    if (exclude && exclude.length > 0) {
      const opts: PatternType[] = [];

      forLoop(options, (opt) => {
        if (!exclude.includes(opt)) {
          opts.push(opt);
        }
      });

      setOptions(opts);
    }
  }, [exclude]);

  useEffect(() => {
    let active = true;

    if (defaultValue && active) {
      setValue(defaultValue);
    } else {
      setValue('header');
    }

    if (selectedPatterns) {
      const newOptions = options.filter(
        (option) => !selectedPatterns.includes(option)
      );
      setOptions(newOptions);
    }
    return () => {
      active = false;
    };
  }, [defaultValue, options, selectedPatterns]);

  // Utilities

  const handleChange = (newValue: PatternType) => {
    setValue(newValue);
    if (onChange) onChange(newValue);
  };

  const filterPatterns = useCallback(
    (options: PatternType[]): PatternType[] => {
      const templateSkins = [
        'tagAndCategory',
        'form',
        'search',
        'contentDetails',
      ];

      const filtered = options.filter((opt) => !templateSkins.includes(opt));

      let pagePattern: PatternType | '' =
        pagePath.includes('tag') || pagePath.includes('category')
          ? 'tagAndCategory'
          : pagePath.includes('tagAndCategory')
          ? 'tagAndCategory'
          : pagePath.includes('form')
          ? 'form'
          : pagePath.includes('search')
          ? 'search'
          : pagePath.includes('contentDetails')
          ? 'contentDetails'
          : '';

      if (isTemplatePage && pagePattern) {
        filtered.push(pagePattern);
      }

      return filtered;
    },
    [isTemplatePage]
  );

  // Render
  return (
    <Select
      open={open}
      disabled={disabled}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      sx={{ height: '2.4rem', ...sx }}
      value={value}
      displayEmpty
      renderValue={(v) => {
        const value = v as PatternType;
        if (!value)
          return <BodyTwo color='text.secondary'>انتخاب نوع پترن</BodyTwo>;
        return <BodyTwo>{getPatternTitle(value)}</BodyTwo>;
      }}
    >
      {filterPatterns(options).map((opt) => (
        <ListItemButton
          key={opt}
          selected={opt === value}
          onClick={() => handleChange(opt)}
        >
          <ListItemText primary={getPatternTitle(opt)} />
        </ListItemButton>
      ))}
    </Select>
  );
};

export default PatternTypeAutocomplete;

import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';
import { useAppSelector } from 'core/hooks/redux';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import TableRow from 'core/components/base/display/TableRow';
import TableCell from 'core/components/base/display/TableCell';

// Custom Common Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import RouteLink from 'core/components/shared/Link/RouteLink';
import IconButtonLink from 'core/components/shared/IconButton/IconButtonLink';
import IconButton from 'core/components/base/inputs/IconButton';

// Custom Icons
import PlusIcon from 'core/components/icons/Plus';
import CodeBranchIcon from 'core/components/icons/CodeBranch';

// Custom Utilities
import { getAppUserURL } from 'core/utilities/app/info';
import { getLocaleDate } from 'core/utilities/helper/date';
import { isSucceed, setAppAlert, setAppLoading } from 'core/utilities/helper';
import { addProcess } from 'features/form/processes/utilities/api';

// Custom Types
import type { FormListItemProps } from 'features/form/forms/types/list';

interface DataGridItemProps {
  form: FormListItemProps;
}

const DataGridItem: React.FC<DataGridItemProps> = (props) => {
  // Props
  const formId = props.form.id;
  const {
    audienceGroupTitles = [],
    createdAt,
    creator,
    skinTitle = '-',
    linkedProcessId = '',
    slug = '-',
    title = '-',
    feedbacksCount = 0,
  } = props.form.data;

  // Hooks
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.loading);
  const isGroupHovered = useBoolean(false);

  // Utilities
  const handleToggleHover = (hover: boolean) => {
    if (audienceGroupTitles.length === 0) return;
    isGroupHovered.set(hover);
  };

  const getAudienceGroupsTitle = React.useCallback(() => {
    const groups = audienceGroupTitles;
    if (groups.length === 0) return '-';
    if (isGroupHovered.state) return groups.join(', ');
    let title = groups[0];
    if (groups.length > 1) title += ` + ${groups.length - 1}`;

    return title;
  }, [isGroupHovered.state]);

  const handleAddProcess = async () => {
    setAppLoading(true);

    const { status, processId } = await addProcess({
      feedbackId: '',
      formId,
      formTitle: title,
      isActive: false,
      items: [],
      progressStack: [],
      title: `فرایند ${title}`,
    });

    setAppLoading(false);
    if (isSucceed(status) && processId) {
      navigate(`/processes/${processId}`);
    } else {
      setAppAlert('خطا در ساخت فرایند');
    }

    setAppLoading(false);
  };

  // TODO: Check and Update Routes later

  // Render
  return (
    <TableRow>
      <TableCell
        sx={{
          width: '7rem',
          whiteSpace: 'nowrap',
          textAlign: 'center',
          ':hover': {
            textDecoration: 'underline',
          },
        }}
      >
        <RouteLink href={`/forms/${formId}`}>{title}</RouteLink>
      </TableCell>
      <TableCell
        sx={{
          width: '7rem',
          whiteSpace: 'nowrap',
          textAlign: 'center',
          ':hover': {
            textDecoration: 'underline',
          },
        }}
      >
        <RouteLink href={`/feedbacks/${formId}`}>
          {feedbacksCount?.toPersian()}
        </RouteLink>
      </TableCell>
      <TableCell
        sx={{
          width: '12rem',
          whiteSpace: 'nowrap',
          textAlign: 'center',
          ':hover': {
            textDecoration: 'underline',
          },
        }}
      >
        <a
          style={{
            textDecoration: 'none',
          }}
          href={`${getAppUserURL()}/form/${slug}`}
          target='_blank'
          rel='noreferrer'
        >
          {slug}
        </a>
      </TableCell>
      <TableCell
        onMouseEnter={() => handleToggleHover(true)}
        onMouseLeave={() => handleToggleHover(false)}
        sx={{
          width: '12rem',
          whiteSpace: 'nowrap',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <BodyTwo
            sx={{
              padding: '.3rem',
              textAlign: 'center',
              transition: 'background .3s',
              background: isGroupHovered ? '#f5f5f5' : 'inherit',
              borderRadius: '.25rem',
            }}
          >
            {getAudienceGroupsTitle()}
          </BodyTwo>
        </Box>
      </TableCell>
      <TableCell
        sx={{
          width: '12rem',
          whiteSpace: 'nowrap',
          textAlign: 'center',
        }}
      >
        {skinTitle}
      </TableCell>
      <TableCell
        sx={{
          width: '12rem',
          whiteSpace: 'nowrap',
          textAlign: 'center',
        }}
      >
        {linkedProcessId ? (
          <IconButtonLink href={`/processes/${linkedProcessId}`} size='small'>
            <CodeBranchIcon fontSize='small' />
          </IconButtonLink>
        ) : (
          <IconButton
            disabled={loading}
            onClick={handleAddProcess}
            size='small'
          >
            <PlusIcon color='primary' fontSize='small' />
          </IconButton>
        )}
      </TableCell>
      <TableCell
        sx={{
          width: '7rem',
          whiteSpace: 'nowrap',
          textAlign: 'center',
        }}
      >
        {`${creator?.data?.firstName} ${creator?.data?.lastName}`}
      </TableCell>
      <TableCell
        sx={{
          width: '12rem',
          whiteSpace: 'nowrap',
          textAlign: 'center',
        }}
      >
        {createdAt ? getLocaleDate(createdAt) : '-'}
      </TableCell>
    </TableRow>
  );
};

export default DataGridItem;

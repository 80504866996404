import type { FC } from 'react';

// Core Components
import BinIcon from 'core/components/icons/Bin';
import LoadingIconButton, {
  LoadingIconButtonProps,
} from 'core/components/base/inputs/LoadingIconButton';

// Custom Types
export interface DeleteIconButton extends LoadingIconButtonProps {}

const DeleteIconButton: FC<DeleteIconButton> = (props) => {
  // Props
  const { color = 'error', disableRipple = true, sx, ...otherProps } = props;

  return (
    <LoadingIconButton
      color={color}
      disableRipple={disableRipple}
      sx={{
        bgcolor: 'background.paper',
        borderRadius: 2,
        ':active': { bgcolor: 'rgba(255, 255, 255, 0.6)' },
        ...sx,
      }}
      {...otherProps}
    >
      <BinIcon />
    </LoadingIconButton>
  );
};

export default DeleteIconButton;

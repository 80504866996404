import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Radio from 'core/components/base/inputs/Radio';
import TextField from 'core/components/base/inputs/TextField';
import Collapse from 'core/components/base/utils/Collapse';
import Box from 'core/components/base/layout/Box';
import BodyOne from 'core/components/shared/Typography/BodyOne';
import TextFieldNumber from 'core/components/shared/TextField/number';
import SpacedBox from 'core/components/shared/Box/SpacedBox';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import RowStack from 'core/components/shared/Stack/RowStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Feature Components
import PatternPadding from 'features/appBuilder/patterns/core/components/shared/Padding';
import PatternBackground from 'features/appBuilder/patterns/core/components/shared/patternBackground';
import PatternGearIconButton from 'features/appBuilder/patterns/components/details/shared/iconButton/Gear';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { getNumberTitle } from 'core/utilities/helper/helperPack';

// Core Styles
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';

// Feature Images
import headerStyle1 from 'features/appBuilder/patterns/features/header/assets/styles/header-style-1.svg';
import footerStyle1 from 'features/appBuilder/patterns/features/footer/assets/styles/footer-style-1.svg';
import contentStyle1 from 'features/appBuilder/patterns/features/content/assets/styles/content-style-1.svg';
import contentStyle2 from 'features/appBuilder/patterns/features/content/assets/styles/content-style-2.svg';
import contentStyle3 from 'features/appBuilder/patterns/features/content/assets/styles/content-style-3.svg';
import stableStye1 from 'features/appBuilder/patterns/features/stable/assets/styles/stable-style-1.svg';
import stableStye2 from 'features/appBuilder/patterns/features/stable/assets/styles/stable-style-2.svg';
import profileStyle1 from 'features/appBuilder/patterns/features/profile/assets/styles/profile-style-1.svg';
import sliderStyle1 from 'features/appBuilder/patterns/features/slider/assets/styles/slider-style-1.svg';
import sliderStyle2 from 'features/appBuilder/patterns/features/slider/assets/styles/slider-style-2.svg';
import contentDetailsStyle1 from 'features/appBuilder/patterns/features/contentDetails/assets/styles/content_details_style_1.svg';
import tagAndCategoryDetailsStyle1 from 'features/appBuilder/patterns/features/tagAndCategory/assets/styles/tag_and_category_style_1.svg';
import searchStyle1 from 'features/appBuilder/patterns/features/search/assets/styles/search_style_1.svg';
import formStyle1 from 'features/appBuilder/patterns/features/form/assets/styles/form_style_1.svg';
import formStyle2 from 'features/appBuilder/patterns/features/form/assets/styles/form_style_2.svg';

// Custom Types
import type { PatternType } from 'features/appBuilder/patterns/core/types/item';
import type { PatternCommonProps } from 'features/appBuilder/patterns/core/types/item/common';

export interface PatternConfigProps {
  patternType?: PatternType;
  collapsed?: boolean;
  onCollapsedChange?: (state: boolean) => void;
}

const PatternConfig: FC<PatternConfigProps> = (props) => {
  // Props
  const {
    patternType = 'header',
    collapsed,
    onCollapsedChange: onCollapseChange,
  } = props;

  // Hooks
  const { control, setValue } = useFormContext<PatternCommonProps>();
  const loading = useSelectLoading();

  const handleToggleCollapse = () => {
    onCollapseChange && onCollapseChange(!collapsed);
  };

  // Utilities
  const getImageSrc = (variant: number) => {
    if (patternType === 'header') {
      return headerStyle1;
    }

    if (patternType === 'stable') {
      return variant === 0 ? stableStye1 : stableStye2;
    }

    if (patternType === 'content') {
      return variant === 0
        ? contentStyle1
        : variant === 1
        ? contentStyle2
        : contentStyle3;
    }

    if (patternType === 'profile') {
      return profileStyle1;
    }

    if (patternType === 'slider') {
      return variant === 0 ? sliderStyle1 : sliderStyle2;
    }

    if (patternType === 'contentDetails') {
      return contentDetailsStyle1;
    }

    if (patternType === 'tagAndCategory') {
      return tagAndCategoryDetailsStyle1;
    }

    if (patternType === 'search') {
      return searchStyle1;
    }

    if (patternType === 'form') {
      return variant === 0 ? formStyle1 : formStyle2;
    }

    return footerStyle1;
  };

  const getIndexArray = (): number[] => {
    if (patternType === 'content') return [0, 1, 2];
    if (['stable', 'slider', 'form'].includes(patternType)) return [0, 1];

    return [0];
  };

  return (
    <RoundPaper sx={{ px: '1rem' }}>
      <SpacedBox>
        <BodyOne>تنظیمات استایل</BodyOne>
        <PatternGearIconButton
          open={collapsed}
          onClick={handleToggleCollapse}
        />
      </SpacedBox>
      <Collapse in={collapsed}>
        <SpacedBox
          sx={{
            alignItems: 'flex-start',
            width: '100%',
            flexDirection: 'column',
            overflowX: 'auto',
            overflowY: 'hidden',
            pt: 3,
            pb: 2,
            ...customRoundedScrollbarStyles,
          }}
        >
          <Box
            sx={{
              width: '98%',
              display: 'flex',
              gap: 4,
              flexGrow: 1,
              alignItems: 'flex-start',
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '10.75rem',
              }}
            >
              <Box sx={{ display: 'flex', gap: 2, flexGrow: 1 }}>
                <ColumnStack spacing={1} flexGrow={1}>
                  <InputLabel required indentRight whiteSpace='nowrap'>
                    عنوان پترن
                  </InputLabel>
                  <Controller
                    name='patternTitle'
                    control={control}
                    defaultValue=''
                    render={({ field, fieldState }) => (
                      <TextField
                        placeholder='عنوان'
                        fullWidth
                        disabled={loading}
                        error={fieldState.error ? true : false}
                        helperText={
                          fieldState.error && fieldState.error.message
                        }
                        {...field}
                      />
                    )}
                  />
                </ColumnStack>
                {['content', 'slider', 'tagAndCategory', 'search'].includes(
                  patternType
                ) && (
                  <ColumnStack spacing={1}>
                    <InputLabel required indentRight whiteSpace='nowrap'>
                      تعداد کل کارت‌ها
                    </InputLabel>
                    <Controller
                      name='cardCount'
                      control={control}
                      defaultValue={0}
                      render={({ field, fieldState }) => (
                        <TextFieldNumber
                          placeholder='عنوان'
                          fullWidth
                          disabled={loading}
                          error={fieldState.error ? true : false}
                          helperText={
                            fieldState.error && fieldState.error.message
                          }
                          {...field}
                        />
                      )}
                    />
                  </ColumnStack>
                )}
              </Box>
              <PatternBackground disabled={loading} patternType={patternType} />
            </Box>
            <ColumnStack spacing={1}>
              <InputLabel indentRight whiteSpace='nowrap'>
                پدینگ کل پترن
              </InputLabel>
              <Controller
                control={control}
                name='padding'
                render={({ field }) => (
                  <PatternPadding
                    defaultValue={field.value}
                    disabled={loading}
                    onChange={(padding) => setValue(field.name, padding)}
                  />
                )}
              />
            </ColumnStack>
          </Box>
          {!['group', 'table'].includes(patternType) && (
            <Controller
              control={control}
              defaultValue={0}
              name='variant'
              render={({ field }) => (
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    mt: 4,
                    gap: 2,
                    flexWrap: 'wrap',
                    ['&>*']: { width: '48%' },
                  }}
                >
                  {getIndexArray().map((style) => (
                    <ColumnStack key={`${patternType}-style-${style}`}>
                      <RowStack spacing={0} alignItems='center'>
                        <Radio
                          checked={field.value === style}
                          disabled={loading}
                          value={style}
                          onClick={() => setValue('variant', style)}
                        />
                        <BodyTwo>
                          استایل {getNumberTitle(style + 1, 'fa')}
                        </BodyTwo>
                      </RowStack>
                      <img
                        style={{
                          boxShadow: ' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        }}
                        src={getImageSrc(style)}
                        alt=''
                      />
                    </ColumnStack>
                  ))}
                </Box>
              )}
            />
          )}
        </SpacedBox>
      </Collapse>
    </RoundPaper>
  );
};

export default PatternConfig;

import {
  Snackbar as MuiSnackbar,
  SnackbarProps as MuiSnackbarProps,
  styled,
} from '@mui/material';

export interface SnackbarProps extends MuiSnackbarProps {}

const Snackbar = styled(MuiSnackbar)<SnackbarProps>(() => ({}));

export default Snackbar;

import {
  FileLocationSubFeatureType,
  FileLocationFeatureType,
} from 'features/file/files/types';

/**
 * Checks if a given location is singular.
 * @example isLocationSingular('menu') // Return true
 * @param {FileLocationType} location - The location to check.
 * @returns {boolean} Returns true if the location is singular, false otherwise.
 */
const isLocationSingular = (location: string): boolean =>
  !location.endsWith('s');

/**
 * Checks if a given location is plural.
 * @example isLocationPlural('menus') // Return true
 * @param {FileLocationType} location - The location to check.
 * @returns {boolean} Returns true if the location is plural, false otherwise.
 */
const isLocationPlural = (location: string): boolean => location.endsWith('s');

/**
 * Converts a given location value to its singular form.
 * @param {FileLocationType} location - The location value to convert.
 * @returns {FileLocationSubFeatureType} The converted singular form of the location value.
 */

export const locationToSingular = (
  location: string
): FileLocationFeatureType => {
  if (!location || isLocationSingular(location))
    return location as FileLocationFeatureType;
  const plural = location as FileLocationSubFeatureType;

  if (plural.endsWith('categories'))
    return plural.replace('categories', 'category') as FileLocationFeatureType;
  if (plural.endsWith('Categories'))
    return plural.replace('Categories', 'Category') as FileLocationFeatureType;
  return plural.substring(0, plural.length - 1) as FileLocationFeatureType;
};

/**
 * Converts a given location value to its plural form.
 * @param {string} location - The location value to convert.
 * @returns {FileLocationSubFeatureType} The converted plural form of the location value.
 */
export const locationToPlural = (
  location: string
): FileLocationSubFeatureType => {
  if (!location || isLocationPlural(location))
    return location as FileLocationSubFeatureType;

  const singluar = location as FileLocationFeatureType;

  if (singluar.endsWith('category'))
    return singluar.replace(
      'category',
      'categories'
    ) as FileLocationSubFeatureType;
  if (singluar.endsWith('Category'))
    return singluar.replace(
      'Category',
      'Categories'
    ) as FileLocationSubFeatureType;
  return `${singluar}s` as FileLocationSubFeatureType;
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  isInitialized: false,
  isEditMode: false,
};

export const patternSlice = createSlice({
  name: 'loding',
  initialState,
  reducers: {
    setIsInitialized: (state, action: PayloadAction<boolean>) => {
      state.isInitialized = action.payload;
    },
    setIsEditMode: (state, action: PayloadAction<boolean>) => {
      state.isEditMode = action.payload;
    },
  },
});

export default patternSlice.reducer;

export const { setIsInitialized, setIsEditMode } = patternSlice.actions;

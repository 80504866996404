import * as React from 'react';

// Custom Core Components
import MuiSkeleton from 'core/components/base/feedback/Skeleton';

// Custom Common Components
import PageHeroBox from 'core/components/shared/Box/PageHero';
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';
import RowStack from 'core/components/shared/Stack/RowStack';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Custom Types
interface SkeletonProps {}

const Skeleton: React.FC<SkeletonProps> = (props) => {
  // Render
  return (
    <>
      <PageHeroBox>
        <SpaceBetween width='100%'>
          <RowStack width='100%' alignItems='center'>
            <MuiSkeleton
              variant='rectangular'
              sx={{
                minWidth: '2.188rem',
                maxWidth: '2.188rem',
                minHeight: '2.188rem',
                maxHeight: '2.188rem',
                borderRadius: '.5rem',
              }}
            />
            <MuiSkeleton sx={{ width: '15%', fontSize: '2.2rem' }} />
          </RowStack>
          <RowStack width='100%' justifyContent='end' alignItems='center'>
            <MuiSkeleton
              variant='rectangular'
              sx={{
                minWidth: '2.188rem',
                maxWidth: '2.188rem',
                minHeight: '2.188rem',
                maxHeight: '2.188rem',
                borderRadius: '.5rem',
              }}
            />
            <MuiSkeleton
              variant='rounded'
              sx={{
                minWidth: '5.938rem',
                maxWidth: '5.938rem',
                minHeight: '2.188rem',
                maxHeight: '2.188rem',
              }}
            />
            <MuiSkeleton
              variant='rounded'
              sx={{
                minWidth: '5.938rem',
                maxWidth: '5.938rem',
                minHeight: '2.188rem',
                maxHeight: '2.188rem',
              }}
            />
          </RowStack>
        </SpaceBetween>
      </PageHeroBox>
      <ColumnStack spacing={2}>
        <SpaceBetween>
          <RowStack spacing={1} alignItems='center'>
            <MuiSkeleton
              variant='rectangular'
              sx={{
                minWidth: '2.188rem',
                maxWidth: '2.188rem',
                minHeight: '2.188rem',
                maxHeight: '2.188rem',
                borderRadius: '.5rem',
              }}
            />
            <MuiSkeleton
              variant='rounded'
              sx={{
                minWidth: '10rem',
                maxWidth: '10rem',
                minHeight: '2.188rem',
                maxHeight: '2.188rem',
                borderRadius: '.5rem',
              }}
            />

            <MuiSkeleton
              variant='rectangular'
              sx={{
                minWidth: '2.188rem',
                maxWidth: '2.188rem',
                minHeight: '2.188rem',
                maxHeight: '2.188rem',
                borderRadius: '.5rem',
              }}
            />
          </RowStack>
          <RowStack spacing={1} alignItems='center'>
            <MuiSkeleton
              variant='text'
              sx={{
                minWidth: '10rem',
                maxWidth: '10rem',
                minHeight: '3rem',
                maxHeight: '3rem',
                borderRadius: '.5rem',
              }}
            />
            <MuiSkeleton
              variant='text'
              sx={{
                minWidth: '6rem',
                maxWidth: '6rem',
                minHeight: '3rem',
                maxHeight: '3rem',
                borderRadius: '.5rem',
              }}
            />
          </RowStack>
        </SpaceBetween>
        <MuiSkeleton
          variant='rounded'
          sx={{
            width: '100%',
            height: '31.25rem',
          }}
        />
      </ColumnStack>
    </>
  );
};

export default Skeleton;

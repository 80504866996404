import { forwardRef, type FC } from 'react';
import { type OutlinedTextFieldProps } from '@mui/material';

// Custom Core Components
import TextField from 'core/components/base/inputs/TextField';

// Custom Types
export interface ConfigNumberInputProps
  extends Omit<OutlinedTextFieldProps, 'variant'> {}

const ConfigNumberInput: FC<ConfigNumberInputProps> = forwardRef(
  (props, ref) => {
    // Props
    const { dir = 'ltr', InputProps, inputProps, sx, ...otherProps } = props;

    return (
      <TextField
        ref={ref}
        type='number'
        dir={dir}
        InputProps={{
          sx: {
            height: '1.5rem',
            fontSize: '0.75rem',
            ...InputProps?.sx,
          },
          ...InputProps,
        }}
        inputProps={{
          sx: { px: 0.75, ...inputProps?.sx },
          ...inputProps,
        }}
        sx={{
          width: '3rem',
          height: '1.5rem',
          'input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          'input[type=number]::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          ...sx,
        }}
        {...otherProps}
      />
    );
  }
);

export default ConfigNumberInput;

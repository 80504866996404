// Custom Types
import type { SearchPatternDataProps } from 'features/appBuilder/patterns/features/search/types';

/**
 * Validates the search pattern data and returns an error message if validation fails.
 *
 * @param {SearchPatternDataProps} data - The search pattern data to validate.
 * @returns {string} A string containing the error message if validation fails, or an empty string if the data is valid.
 */
export const validateSearchPattern = (data: SearchPatternDataProps): string => {
  let error = '';

  if (!data.patternTitle.trim()) {
    error = 'وارد کردن عنوان پترن الزامی می‌باشد.';
  }

  return error;
};

/**
 * IndexContext
 *
 * A context for storing the index of the current item.
 */
import { createContext, useContext } from 'react';
// Types
import type { FC, ReactNode } from 'react';

const Index = createContext<number>(-1);

interface IndexProviderProps {
  children: ReactNode;
  index: number;
}

export const IndexProvider: FC<IndexProviderProps> = (props) => {
  // Props
  const { children, index } = props;
  // Render
  return <Index.Provider value={index}>{children}</Index.Provider>;
};

/**
 * @returns {number} The index that this item is wrapped by or -1.
 */
export const useIndex = (): number => useContext(Index);

import * as React from 'react';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';

// Common Components
import DeleteDialog from 'core/components/shared/Dialog/Delete';
import DeleteLoadingButton from 'core/components/shared/LoadingButton/Delete';
import SubmitLoadingButton from 'core/components/shared/LoadingButton/Submit';
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
interface BannerActionsProps {
  openDeleteDialog: boolean;
  onDeleteContainer: () => void;
  onSetOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: () => void;
}

const BannerActions: React.FC<BannerActionsProps> = (props) => {
  // Props
  const {
    openDeleteDialog,
    onDeleteContainer,
    onSetOpenDeleteDialog,
    onSubmit,
  } = props;

  // Context
  const loading = useSelectLoading();
  const isDraft = useAppSelector((state) => state.banner.isDraft);
  const containerId = useAppSelector((state) => state.banner.current?.id || '');

  // Render
  return (
    <>
      <RowStack>
        <DeleteLoadingButton
          loading={loading && containerId ? true : false}
          disabled={!containerId}
          onClick={() => onSetOpenDeleteDialog(true)}
        >
          {containerId === 'new' || isDraft ? 'انصراف' : 'حذف فهرست'}
        </DeleteLoadingButton>
        <SubmitLoadingButton
          onClick={onSubmit}
          disabled={loading || !containerId}
        >
          ذخیره
        </SubmitLoadingButton>
      </RowStack>
      <DeleteDialog
        open={openDeleteDialog}
        title={
          containerId === 'new' || isDraft
            ? 'آیا از لغو تغییرات اعمال شده مطمئن هستید؟'
            : 'آیا از حذف فهرست انتخاب شده مطمئن هستید؟'
        }
        submitTitle={containerId === 'new' || isDraft ? 'لغو تغییرات' : 'حذف'}
        onClose={() => onSetOpenDeleteDialog(false)}
        loading={loading}
        onDelete={onDeleteContainer}
      />
    </>
  );
};

export default BannerActions;

import * as React from 'react';

// Core Components
import Dialog from 'core/components/base/feedback/Dialog';
import DialogContent from 'core/components/base/feedback/DialogContent';

// Feature Components
import EditFilePage from 'features/file/files/components/details';

// Custom Types
import type { FileProps } from 'features/file/files/types';
interface FileDetailDialogProps {
  fileId: string;
  onClose: () => void;
  onFileUpdated?: (updated: FileProps) => void;
}

const FileDetailDialog: React.FC<FileDetailDialogProps> = (props) => {
  // Props
  const { fileId, onClose, onFileUpdated } = props;

  // Render
  return (
    <Dialog
      open={true}
      onClose={onClose}
      PaperProps={{
        sx: {
          minWidth: '80vw',
          borderRadius: '1rem',
        },
      }}
    >
      <DialogContent sx={{ overflow: 'hidden', padding: '0 !important' }}>
        <EditFilePage
          fileId={fileId}
          disableRedirectAfterUpdate
          disablePageTitle
          onFileUpdated={onFileUpdated}
          onCloseDialog={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default FileDetailDialog;

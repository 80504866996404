import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';
import ColorPicker from 'core/components/shared/Picker/Color';
import InputWrapper from 'core/components/shared/Wrapper/Input';

// Feature Shared Components
import PatternItemContainer from 'features/appBuilder/patterns/core/components/shared/items/Container';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { PatternItemContainerProps } from 'features/appBuilder/patterns/core/components/shared/items/Container';

export interface PatternColorItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
  colorControllers: { keyName: string; title: string }[];
}

const PatternColorItem: FC<PatternColorItemProps> = (props) => {
  // Props
  const { reference, colorControllers, ...otherProps } = props;

  // Hooks
  const loading = useSelectLoading();
  const { control, setValue } = useFormContext();

  // Render
  return (
    <PatternItemContainer reference={reference} {...otherProps}>
      <Box sx={{ display: 'flex', gap: 2, '[&>*]': { flexGrow: 1 } }}>
        {colorControllers.map((colorController, index) => (
          <Controller
            key={colorController.keyName + colorController.title + index}
            control={control}
            name={`${reference}.${colorController.keyName}`}
            render={({ field }) => (
              <InputWrapper
                row
                sx={{ gap: 1, width: '50%' }}
                label={colorController.title}
              >
                <ColorPicker
                  disabled={loading}
                  color={field.value}
                  onColorChange={(color) => setValue(field.name, color)}
                />
              </InputWrapper>
            )}
          />
        ))}
      </Box>
    </PatternItemContainer>
  );
};

export default PatternColorItem;

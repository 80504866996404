import * as React from 'react';

// Custom Core Components
import SvgIcon from 'core/components/base/display/SvgIcon';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface IconBuilderProps extends SvgIconProps {}

const IconBuilder: React.FC<IconBuilderProps> = (props) => {
  const {
    children,
    color = 'inherit',
    viewBox = '0 0 24 24',
    ...otherProps
  } = props;

  // Render
  return (
    <SvgIcon color={color} viewBox={viewBox} {...otherProps}>
      {children}
    </SvgIcon>
  );
};

export default IconBuilder;

// Custom Utilities
import { generateObjectId } from 'core/utilities/helper/id';
import { getInitialCommonInputProps } from 'features/form/forms/utilities/input/initial/common';

// Custom Types
import type { FormInputInitialParams } from 'features/form/forms/utilities/input/initial/common';
import type { FormTimeInputProps } from 'features/form/forms/types/item/input/time';

interface Params extends FormInputInitialParams {}

export const genFormTimeInput = (params: Params): FormTimeInputProps => {
  const commonProps = getInitialCommonInputProps({
    ...params,
  });

  return {
    id: `draft-${generateObjectId()}`,
    data: {
      ...commonProps,
      label: 'ساعت',
      type: 'time',
    },
  };
};

import { useCallback, useEffect, useState, type FC } from 'react';

// Core Components
import Select from 'core/components/shared/Select';
import CircularProgress from 'core/components/base/feedback/CircularProgress';
import MenuItem from 'core/components/base/navigation/MenuItem';
import ArrowDropDownIcon from 'core/components/icons/ArrowDropDown';
import Box from 'core/components/base/layout/Box';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Core Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Core Utilities
import { isSucceed, setAppLoading } from 'core/utilities/helper';

// Feature Utilities
import { getBannerSummaryList } from 'features/banner/banners/utilities/api';

// Custom Types
import type { BannerContainerSummaryProps } from 'features/banner/banners/types';
export interface BannerContainerSelectProps {
  disabled?: boolean;
  defaultValue?: string;
  defaultValueTitle?: string;
  onChange?: (opt: BannerContainerSummaryProps) => void;
}

const BannerContainerSelect: FC<BannerContainerSelectProps> = (props) => {
  // Props
  const {
    defaultValue = '',
    disabled,
    defaultValueTitle = '',
    onChange,
  } = props;

  // States
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string>(defaultValue);
  const [valueTitle, setValueTitle] = useState<string>(defaultValueTitle);
  const [opts, setOpts] = useState<BannerContainerSummaryProps[]>([]);

  // Context
  const loading = useSelectLoading();

  // Hooks
  const setOptions = useCallback(async () => {
    setAppLoading(true);
    const { status, list } = await getBannerSummaryList();

    setAppLoading(false);
    if (status && isSucceed(status) && list && list.length > 0) {
      setOpts(list);

      if (defaultValue && !defaultValueTitle) {
        const valueToSet = list.find((m) => m.id === defaultValue);

        if (valueToSet) {
          setValue(valueToSet.id);
          setValueTitle(valueToSet.data.title);
        }
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (defaultValue) {
      setOptions();
    }
  }, [defaultValue]);

  // Utilities
  const handleOpen = () => {
    if (opts.length === 0) setOptions();

    setOpen(true);
  };

  const handleValueChange = (opt: BannerContainerSummaryProps) => {
    setValue(opt.id);
    setValueTitle(opt.data.title);

    if (onChange) onChange(opt);
  };

  // Helpers
  const Loading = () => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '3rem',
        height: '3rem',
      }}
    >
      <CircularProgress color={loading ? 'inherit' : undefined} size={16} />
    </Box>
  );

  return (
    <Select
      disabled={disabled || loading}
      open={open}
      onOpen={handleOpen}
      onClose={() => setOpen(false)}
      IconComponent={loading ? Loading : ArrowDropDownIcon}
      value={value}
      renderValue={() => <BodyTwo>{valueTitle}</BodyTwo>}
    >
      {opts.length > 0 ? (
        opts.map((opt) => (
          <MenuItem
            key={opt.id}
            value={opt.id}
            onClick={() => handleValueChange(opt)}
          >
            {opt.data.title}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>در حال دریافت...</MenuItem>
      )}
    </Select>
  );
};

export default BannerContainerSelect;

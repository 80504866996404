// Custom Hooks
import useDocApi from 'core/hooks/api/useDoc';
import { useAddMutation, useEditMutation } from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  disable2FA,
  editProfile,
  enable2FA,
  getProfile,
} from 'features/manager/auth/utilities/shared/api';

// Custom Types
import type {
  ManagerDataProps,
  ManagerProps,
} from 'features/manager/managers/types';
import type {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';

/**
 * Query key for fetching tags in react query.
 *
 * @constant {Array<string>}
 */
export const authQueryKey = ['auth'];
/**
 * Custom hook to fetch the profile document of a manager.
 *
 * This hook utilizes the `useDocApi` to retrieve the profile data based on the provided options.
 *
 * @param {UseDocOptionType<ManagerProps>} [options] - Optional configuration options for fetching the profile.
 * @returns {UseDocApiReturnProps<ManagerProps>} The result of the `useDocApi` hook, which includes the fetched profile document.
 */
export const useProfileDoc = (
  options?: UseDocOptionType<ManagerProps>
): UseDocApiReturnProps<ManagerProps> =>
  useDocApi(getProfile, authQueryKey, options);

/**
 * Custom hook to handle the disabling of two-factor authentication (2FA).
 *
 * This hook utilizes the `useAddMutation` to perform the disable operation for 2FA.
 *
 * @returns {UseAddMutationReturn<void>} The result of the `useAddMutation` hook,
 * which includes methods and state related to the disable operation.
 */
export const useDisable2FaMutation = useAddMutation<void>(
  disable2FA,
  authQueryKey
);

/**
 * Custom hook to handle the enabling of two-factor authentication (2FA).
 *
 * This hook utilizes the `useAddMutation` to perform the enable operation for 2FA.
 *
 * @param {Object} options - The options for enabling 2FA.
 * @param {string} options.code - The code for enabling 2FA.
 * @param {string} options.ascii - The ASCII representation for enabling 2FA.
 * @returns {UseAddMutationReturn<{ code: string; ascii: string }>} The result of the `useAddMutation` hook,
 * which includes methods and state related to the enable operation.
 */
export const useEnable2FaMutation = useAddMutation<{
  code: string;
  ascii: string;
}>(enable2FA, authQueryKey);

/**
 * Custom hook to handle the editing of a manager's profile.
 *
 * This hook utilizes the `useEditMutation` to perform the edit operation for the profile.
 *
 * @param {ManagerDataProps} profile - The updated data of the manager's profile.
 * @returns {UseEditMutationReturn<ManagerDataProps, { status: number; doc?: ManagerProps }>} The result of the `useEditMutation` hook,
 * which includes methods and state related to the edit operation.
 */
export const useEditProfileMutation = useEditMutation<
  ManagerDataProps,
  { status: number; doc?: ManagerProps }
>(editProfile, authQueryKey);

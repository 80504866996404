// Custom Utilities
import { initialTableCellCommonStyles } from '../../../utilities/common';

// Custom Types
import type {
  TablePatternProps,
  TablePatternStateProps,
} from 'features/appBuilder/patterns/features/table/types';
import type { TableCellProps } from 'features/appBuilder/patterns/features/table/types/cell';

/**
 * Generates an initial table cell configuration by merging the provided options with default properties.
 *
 * @param {Partial<Omit<TableCellProps, 'type'>>} options - Partial table cell properties to customize the initial table cell, excluding the `type` field.
 * @returns {TableCellProps} The complete initial table cell configuration with the merged options.
 */
export const getInitialTableCell = (
  options: Partial<Omit<TableCellProps, 'type'>>
): TableCellProps => ({
  type: 'string',
  col: options.col || 0,
  row: options.row || 0,
  colSpan: options.colSpan || 1,
  id: `col-${options.col || 0}-row-${options.row || 0}`,
  styles: options.styles || initialTableCellCommonStyles,
  value: '',
});

export const initialTablePattern: TablePatternProps = {
  id: 'new',
  data: {
    patternTitle: '',
    bgColorOne: 'rgba(0, 0, 0, 0)',
    bgColorTwo: 'rgba(0, 0, 0, 0)',
    bgImage: null,
    bgImageType: 'cover',
    cardBg: '',
    cardCount: 0,
    creator: null,
    dividerColor: '',
    maxWidth: 'lg',
    sortIndex: 0,
    type: 'table',
    isActive: true,
    variant: 0,
    padding: '0px',
    sheetIds: [],
    refs: {},
    rows: [],
    colCount: 0,
    rowCount: 0,
  },
};

export const initialTablePatternState: TablePatternStateProps = {
  id: 'new',
  data: {
    ...initialTablePattern.data,
    selection: [],
    toolbarMode: '',
    initialized: false,
  },
};

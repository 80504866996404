import type { FC } from 'react';

// Core Components
import CircularProgress from 'core/components/base/feedback/CircularProgress';
import IconButton, {
  IconButtonProps,
} from 'core/components/base/inputs/IconButton';

// Custom Types
export interface LoadingIconButtonProps extends IconButtonProps {
  loading?: boolean;
}

const LoadingIconButton: FC<LoadingIconButtonProps> = (props) => {
  // Props
  const { children, loading, disabled, ...otherProps } = props;

  return (
    <IconButton disabled={disabled || loading} {...otherProps}>
      {loading ? <CircularProgress size={24} /> : children}
    </IconButton>
  );
};

export default LoadingIconButton;

// Custom Store
import store from 'core/store';

// Custom Utilities
import { actions } from 'features/form/processes/store/slice';

// Custom Types

export const resetProcessState = () => {
  store.dispatch(actions.onResetState());
};

export const updateProcessFocus = (focus: string) => {
  store.dispatch(actions.onProcessFocusChange(focus));
};

// Types
import type { FC } from 'react';

// Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';
interface FormatBoldProps extends SvgIconProps {}

const FormatBold: FC<FormatBoldProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M19.2,12.9c-0.4-0.6-0.8-1.1-1.4-1.4c1-1.1,1.5-2.5,1.5-4c0-3.7-2.7-5.6-6.5-5.5H6C5.4,2,4.9,2.3,4.5,2.7
	c-0.4,0.4-0.6,1-0.5,1.5v15.5c0,0.6,0.2,1.1,0.5,1.5C4.9,21.7,5.4,22,6,22h7.2c4,0,6.9-1.8,6.9-5.8C20.1,15.1,19.8,13.9,19.2,12.9z
	 M8.3,5.6h3.9c1.7,0,2.8,0.5,2.8,2.2c0,1.6-1.1,2.1-2.8,2.1H8.3V5.6z M12.6,18.4H8.3v-4.3h4.3c2.5,0,2.9,1.1,2.9,2.1
	C15.5,17.3,15.2,18.4,12.6,18.4z'
    />
  </IconBuilder>
);

export default FormatBold;

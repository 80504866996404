// Custom Hooks
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  createCustomPageBody,
  deleteCustomPageBody,
  duplicateCustomPageBody,
  updateCustomPageBody,
} from 'features/appBuilder/customPages/utilities/api';

// Custom Types
import type { CustomPageBodyDataProps } from 'features/appBuilder/customPages/types/body';

/**
 * Query key for fetching custom pages in react query.
 *
 * @constant {Array<string>}
 */
export const customPagesBodyQueryKey = ['customPages'];

/**
 * Custom hook for duplicating a custom page body.
 *
 * @returns {MutationResult<string>} The result of the duplicate mutation.
 */
export const useDuplicateCustomPageBodyMutation = useAddMutation<string>(
  duplicateCustomPageBody,
  customPagesBodyQueryKey
);

/**
 * Custom hook for adding a new custom page body.
 *
 * @returns {MutationResult<string>} The result of the add mutation.
 */
export const useAddCustomPageBodyMutation = useAddMutation(
  createCustomPageBody,
  customPagesBodyQueryKey
);

/**
 * Custom hook for updating an existing custom page body.
 *
 * @returns {MutationResult<CustomPageBodyDataProps>} The result of the update mutation.
 */
export const useUpdateCustomPageBodyMutation =
  useEditMutation<CustomPageBodyDataProps>(
    updateCustomPageBody,
    customPagesBodyQueryKey
  );

/**
 * Custom hook for deleting a custom page body.
 *
 * @returns {MutationResult<void>} The result of the delete mutation.
 */
export const useDeleteCustomPageBodyMutation = useDeleteMutation(
  deleteCustomPageBody,
  customPagesBodyQueryKey
);

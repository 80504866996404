// Custom Page Components
import ManagersList from 'features/manager/managers/components/list';
import AddManager from 'features/manager/managers/components/details/Add';
import EditManager from 'features/manager/managers/components/details/Edit';

// Custom Utilities
import { getFeatureLabel, getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'manager';
const subFeature: SubFeatureType = 'managers';
const managersRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/managers',
    Component: ManagersList,
    feature,
    subFeature,
  },
  {
    sidebarTitle: `افزودن ${getFeatureLabel(feature)}`,
    path: '/managers/add',
    Component: AddManager,
    feature,
    subFeature,
  },
  {
    path: '/managers/:id',
    Component: EditManager,
    feature,
    subFeature,
  },
];

export default managersRoutes;

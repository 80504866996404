import * as React from 'react';
import { Link } from 'react-router-dom';

// Core Components
import Button, { ButtonProps } from 'core/components/base/inputs/Button';

export interface ButtonLinkProps extends ButtonProps {
  state?: any;
}

const ButtonLink: React.FC<ButtonLinkProps> = (props) => {
  const { children, state, disabled, href = '/', ...otherProps } = props;

  return disabled ? (
    <Button disabled={disabled} {...otherProps}>
      {children}
    </Button>
  ) : (
    <Link
      to={href}
      style={{
        color: 'inherit',
        textDecoration: 'none',
      }}
      state={state}
    >
      <Button {...otherProps}>{children}</Button>
    </Link>
  );
};

export default ButtonLink;

// Custom Page Components
import AudienceSettings from 'features/audience/settings/components/AudienceSettings';

// Custom Utilities
import { getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'audience';
const subFeature: SubFeatureType = 'audienceSettings';
const audienceSettingsRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/audiences-settings',
    Component: AudienceSettings,
    feature,
    subFeature,
  },
];

export default audienceSettingsRoutes;

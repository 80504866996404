import { type FC } from 'react';
import { alpha, useTheme } from '@mui/material';

// Custom Core Components
import IconButton, {
  type IconButtonProps,
} from 'core/components/base/inputs/IconButton';

// Custom Icons
import BinIcon from 'core/components/icons/Bin';
import CircularProgress from 'core/components/base/feedback/CircularProgress';

// Custom Types
export interface DeleteIconButtonProps extends IconButtonProps {
  loading?: boolean;
}

const DeleteIconButton: FC<DeleteIconButtonProps> = (props) => {
  // Props
  const { loading, sx, disableRipple = true, color, ...otherProps } = props;

  // Hooks
  const { palette } = useTheme();

  return (
    <IconButton
      color='error'
      disableRipple={disableRipple}
      sx={{
        backgroundColor: 'background.paper',
        borderRadius: '0.5rem',
        ':hover': {
          backgroundColor: alpha(palette.background.paper, 0.8),
        },
        ':active': {
          backgroundColor: alpha(palette.background.paper, 0.6),
        },
        ':disabled': {
          backgroundColor: alpha(palette.text.disabled, 0.12),
        },
        ...sx,
      }}
      {...otherProps}
    >
      {loading ? <CircularProgress color='inherit' size={24} /> : <BinIcon />}
    </IconButton>
  );
};

export default DeleteIconButton;

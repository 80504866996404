import * as React from 'react';
import { styled, useTheme } from '@mui/material';

// Core Components
import Box, { BoxProps } from 'core/components/base/layout/Box';
import Paper, { PaperProps } from 'core/components/base/surfaces/Paper';

// Component
import ErrorText from 'core/components/shared/Typography/ErrorText';

// Icon Components
import IconButton from 'core/components/base/inputs/IconButton';
import PersonIcon from 'core/components/icons/Person';
import CameraIcon from 'core/components/icons/Camera';
import OutlinedBinIcon from 'core/components/icons/OutlinedBinIcon';

// Context
import { useUploadDialogContext } from 'features/file/files/context/UploadDialog';

// Custom Utilities
import { getFileSource } from 'core/utilities/helper/helperPack';

// Custom Types
import type {
  DeprecatedFileLocationType,
  FileProps,
} from 'features/file/files/types';

type AllowedImageFormats =
  | '.png'
  | '.jpg'
  | '.jped'
  | '.pjpeg'
  | '.svg'
  | '.webp';

interface AvatarUploaderProps extends BoxProps {
  location: DeprecatedFileLocationType;
  error?: string;
  name?: string;
  disabled?: boolean;
  filesToAccept?: AllowedImageFormats[];
  hideLabel?: boolean;
  imageFile?: FileProps;
  onImageFileChange?: (file?: FileProps) => void;
  onDeleteImage?: () => void;
  inputProps?: React.HtmlHTMLAttributes<HTMLInputElement>;
}

interface ImageWrapperProps extends PaperProps {
  error?: string;
}

const ImageWrapper = styled(Paper)<ImageWrapperProps>(({ theme, error }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  borderRadius: '16px',
  boxShadow: 'none',
  border: error ? `1px solid ${theme.palette.error.main}` : '',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  position: 'relative',
  overflow: 'hidden',
  p: {
    color: theme.palette.text.disabled,
  },
}));

const AvatarUploader: React.FC<AvatarUploaderProps> = (props) => {
  const {
    children,
    error,
    disabled,
    filesToAccept = ['.jpeg', '.jpg', '.pjpeg', '.png', '.svg', '.webp'],
    hideLabel,
    imageFile,
    location,
    name,
    inputProps,
    sx,
    onImageFileChange,
    onDeleteImage,
    ...otherProps
  } = props;

  // Context
  const { setSettings } = useUploadDialogContext();

  // Utilities
  const handleOpenDialog = () => {
    setSettings({
      open: true,
      location,
      accept: ['image'],
      onInsertFile: (files) => onImageFileChange && onImageFileChange(files[0]),
    });
  };

  // Hooks
  const { palette } = useTheme();

  return (
    <Box>
      <Box
        sx={sx ? { ...sx, position: 'relative' } : { position: 'relative' }}
        {...otherProps}
      >
        <ImageWrapper
          as='label'
          error={error}
          sx={{
            cursor: 'pointer',
            background: palette.background.paper,
            border: '4px rgba(41, 182, 246, 1) solid',
          }}
        >
          {imageFile?.id ? (
            <img
              alt={imageFile.data?.alt}
              src={getFileSource(imageFile)}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                padding: '0.25rem',
                display: 'flex',
                alignItems: 'flex-end',
              }}
            />
          ) : !hideLabel ? (
            <PersonIcon
              sx={{
                fontSize: '170px',
                color: '#0091FF80',
                position: 'absolute',
                bottom: -30,
              }}
            />
          ) : null}
        </ImageWrapper>
        {error && <ErrorText>{error}</ErrorText>}
      </Box>
      {!imageFile ? (
        <IconButton
          disabled={disabled}
          sx={{
            position: 'relative',
            left: '-10px',
            bottom: '30px',
            background: '#F5F5F5',
            border: '1px rgba(41, 182, 246, 1) solid',
            ':hover': {
              background: '#F5F5F5',
            },
          }}
          onClick={handleOpenDialog}
        >
          <CameraIcon sx={{ color: 'rgba(41, 182, 246, 1)', zIndex: 1 }} />
        </IconButton>
      ) : (
        <IconButton
          disabled={disabled}
          sx={{
            position: 'relative',
            left: '-10px',
            bottom: '30px',
            background: '#F5F5F5',
            border: '1px #D32F2F solid',
            ':hover': {
              background: '#F5F5F5',
            },
          }}
          onClick={() => {
            onImageFileChange && onImageFileChange();
            onDeleteImage && onDeleteImage();
          }}
        >
          <OutlinedBinIcon sx={{ color: '#D32F2F' }} />
        </IconButton>
      )}
    </Box>
  );
};

export default AvatarUploader;

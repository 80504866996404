import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  styled,
} from '@mui/material';

export interface SwitchProps extends MuiSwitchProps {}

const Switch = styled(MuiSwitch)<SwitchProps>(({}) => ({}));

export default Switch;

import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';
import RowStack from 'core/components/shared/Stack/RowStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import ColorPicker from 'core/components/shared/Picker/Color';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Feature Components
import PatternBgTypeDropdown from 'features/appBuilder/patterns/core/components/shared/patternBackground/PatternBgTypeDropdown';
import GalleryUploadButton from 'features/file/files/components/shared/Gallery/UploadButton';

// Context
import { useUploadDialogContext } from 'features/file/files/context/UploadDialog';

// Custom Types
import type { PatternType } from 'features/appBuilder/patterns/core/types/item';
import type { CommonPatternProps } from 'features/appBuilder/patterns/types/shared/common';

interface PatternBackgroundProps {
  patternType?: PatternType;
  disabled?: boolean;
}

const PatternBackground: FC<PatternBackgroundProps> = ({
  patternType = 'header',
  disabled,
}) => {
  // Context
  const { setSettings } = useUploadDialogContext();
  const { control, setValue } = useFormContext<CommonPatternProps>();

  // Utilities
  const handleOpenDialog = () => {
    setSettings({
      open: true,
      accept: ['image'],
      location: 'pageBuilder',
      onInsertFile: (files) => setValue('bgImage', files[0]),
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        justifyContent: 'space-between',
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <RowStack spacing={1} alignItems='flex-end'>
          <InputLabel>
            {['header', 'footer'].includes(patternType)
              ? 'بکگراند ۱'
              : 'بکگراند پترن'}
          </InputLabel>
          <Controller
            name='bgColorOne'
            control={control}
            render={({ field }) => (
              <ColorPicker
                disabled={disabled}
                color={field.value}
                onColorChange={(color) => setValue(field.name, color)}
              />
            )}
          />
        </RowStack>
        {['header', 'footer'].includes(patternType) && (
          <>
            <RowStack spacing={1} alignItems='flex-end'>
              <InputLabel>بکگراند ۲</InputLabel>
              <Controller
                name='bgColorTwo'
                control={control}
                render={({ field }) => (
                  <ColorPicker
                    disabled={disabled}
                    color={field.value}
                    onColorChange={(color) => setValue(field.name, color)}
                  />
                )}
              />
            </RowStack>
            <RowStack spacing={1} alignItems='flex-end'>
              <InputLabel>جداکننده</InputLabel>
              <Controller
                name='dividerColor'
                control={control}
                render={({ field }) => (
                  <ColorPicker
                    disabled={disabled}
                    color={field.value}
                    onColorChange={(color) => setValue(field.name, color)}
                  />
                )}
              />
            </RowStack>
          </>
        )}
      </Box>
      {!['table', 'group'].includes(patternType) && (
        <Box sx={{ display: 'flex', gap: 2 }}>
          <ColumnStack
            spacing={0}
            justifyContent='flex-end'
            sx={{ transform: 'translateY(-4px)' }}
          >
            <InputLabel>تصویر بکگراند پترن</InputLabel>
            <Controller
              control={control}
              name='bgImage'
              render={({ field }) => (
                <GalleryUploadButton
                  file={field.value ?? undefined}
                  disabled={disabled}
                  onOpenUploadDialog={handleOpenDialog}
                  onRemove={() => setValue(field.name, null)}
                  sx={{ width: '10rem', height: '2.25rem' }}
                />
              )}
            />
          </ColumnStack>
          <ColumnStack
            spacing={0}
            justifyContent='flex-end'
            sx={{ transform: 'translateY(-4px)' }}
          >
            <InputLabel>نوع تصویر</InputLabel>
            <Controller
              control={control}
              name='bgImageType'
              render={({ field }) => (
                <PatternBgTypeDropdown
                  defaultValue={field.value}
                  disabled={disabled}
                />
              )}
            />
          </ColumnStack>
        </Box>
      )}
    </Box>
  );
};

export default PatternBackground;

import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternMenuItem from 'features/appBuilder/patterns/core/components/shared/items/Menu';

// Custom Types
export interface FooterPatternBrandItemProps {}
type CollapsedKey =
  | 'section'
  | 'verticalOne'
  | 'verticalTwo'
  | 'verticalThree'
  | 'horizontalOne';

const FooterPatternMenuSection: FC<FooterPatternBrandItemProps> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    verticalOne: false,
    verticalTwo: false,
    verticalThree: false,
    horizontalOne: false,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='منوها'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='menu'
    >
      <PatternMenuItem
        reference='menu.verticalOne'
        title='منو عمودی اول'
        collapsed={collapsed.verticalOne}
        onCollapsedChange={handleCollapseChanged('verticalOne')}
        menuTitle
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'color',
          'bgColor',
          'hoverColor',
          'bgHoverColor',
          'borderRadius',
        ]}
      />
      <PatternMenuItem
        reference='menu.verticalTwo'
        title='منو عمودی دوم'
        collapsed={collapsed.verticalTwo}
        onCollapsedChange={handleCollapseChanged('verticalTwo')}
        menuTitle
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'color',
          'bgColor',
          'hoverColor',
          'bgHoverColor',
          'borderRadius',
        ]}
      />
      <PatternMenuItem
        reference='menu.verticalThree'
        title='منو عمودی سوم'
        collapsed={collapsed.verticalThree}
        onCollapsedChange={handleCollapseChanged('verticalThree')}
        menuTitle
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'color',
          'bgColor',
          'hoverColor',
          'bgHoverColor',
          'borderRadius',
        ]}
      />
      <PatternMenuItem
        reference='menu.horizontalOne'
        title='منو افقی'
        collapsed={collapsed.horizontalOne}
        onCollapsedChange={handleCollapseChanged('horizontalOne')}
        iconImageSize
        noSubMenu
        include={[
          'padding',
          'activeColor',
          'activeBgColor',
          'fontSize',
          'textDirection',
          'textAlign',
          'color',
          'bgColor',
          'hoverColor',
          'bgHoverColor',
          'borderRadius',
        ]}
      />
    </PatternSectionContainer>
  );
};

export default FooterPatternMenuSection;

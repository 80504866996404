// Custom Hooks
import useDocsApi from 'core/hooks/api/useDocs';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  addCategory,
  deleteCategory,
  editCategory,
  getCategories,
} from 'features/content/categories/utilities/api';

// Custom Types
import type {
  CategoryDataProps,
  CategoryProps,
} from 'features/content/categories/types';
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';
/**
 * Query key for fetching categories in react query.
 *
 * @constant {Array<string>}
 */
export const categoriesQueryKey = ['categories'];

/**
 * Custom hook to fetch categories.
 *
 * This hook utilizes the `useDocApi` to retrieve categories list on the provided options.
 *
 * @param {UseDocOptionType<CategoryProps>} [options] - Optional configuration options for fetching the categories.
 * @returns {UseDocApiReturnProps<CategoryProps>} The result of the `useDocApi` hook, which includes the fetched categories
 */
export const useCategoriesDocs = (
  options?: UseDocsOptionType<CategoryProps>
): UseDocsApiReturnProps<CategoryProps> =>
  useDocsApi<CategoryProps>(getCategories, categoriesQueryKey, options);

/**
 * Custom hook for deleting a category.
 * Uses the `useDeleteMutation` hook to perform the delete operation.
 *
 * @returns {ReturnType<typeof useDeleteMutation>} The mutation object for deleting a category.
 */
export const useDeleteCategoryMutation = useDeleteMutation<string[]>(
  deleteCategory,
  categoriesQueryKey
);

/**
 * Custom hook for adding a category.
 * Uses the `useAddMutation` hook to perform the add operation.
 *
 * @returns {ReturnType<typeof useAddMutation>} The mutation object for adding a category.
 */
export const useAddCategoryMutation = useAddMutation<CategoryDataProps>(
  addCategory,
  categoriesQueryKey
);

/**
 * Custom hook for editing a category.
 * Uses the `useEditMutation` hook to perform the edit operation.
 *
 * @returns {ReturnType<typeof useEditMutation>} The mutation object for editing a category.
 */
export const useEditCategoryMutation = useEditMutation<CategoryDataProps>(
  editCategory,
  categoriesQueryKey
);

import * as React from 'react';

// Custom Core Components
import Table from 'core/components/base/display/Table';
import TableCell from 'core/components/base/display/TableCell';
import TableHead from 'core/components/base/display/TableHead';
import TableRow from 'core/components/base/display/TableRow';
import TableContainer from 'core/components/base/display/TableContainer';

// Custom Common Components
import TableHeaderPaper from 'core/components/shared/Table/TableHeaderPaper';

// Custom Types
interface BannerTableProps {}

const BannerTable: React.FC<BannerTableProps> = (props) => {
  // Render
  return (
    <TableContainer component={TableHeaderPaper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '3rem' }}></TableCell>
            <TableCell style={{ width: '10rem' }}>عنوان</TableCell>
            <TableCell style={{ width: '10rem' }}>تصویر</TableCell>
            <TableCell style={{ width: '10rem' }}>آدرس</TableCell>
            <TableCell style={{ width: '20rem' }}>توضیحات</TableCell>
            <TableCell style={{ width: '5rem' }}>ویرایش</TableCell>
            <TableCell style={{ width: '5rem' }}>حذف</TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
};

export default BannerTable;

export enum AudienceDataFieldsLabel {
  firstName = 'نام',
  lastName = 'نام‌خانوادگی',
  birthDate = 'تاریخ تولد',
  email = 'ایمیل',
  personelId = 'کد پرسنلی',
  nationalId = 'کد ملی',
  jobTitle = 'عنوان شغلی',
  phoneNumber = 'شماره موبایل',
  biography = 'توضیحات',
  avatar = 'عکس پروفایل',
  groups = 'گروه مخاطبین',
  access = 'اکسس',
}

export type AudienceDataField = keyof typeof AudienceDataFieldsLabel;

export interface AudienceSettingsFieldsDataProps {
  firstName: boolean;
  lastName: boolean;
  birthDate: boolean;
  email: boolean;
  personelId: boolean;
  nationalId: boolean;
  jobTitle: boolean;
  biography: boolean;
  avatar: boolean;
  groups: boolean;
}

export interface RequiredAudienceSettingsFieldsDataProps
  extends Omit<
    AudienceSettingsFieldsDataProps,
    'groups' | 'jobTitle' | 'phoneNumber'
  > {}

export interface EditableAudienceSettingsFieldsDataProps
  extends Pick<
    AudienceSettingsFieldsDataProps,
    'email' | 'avatar' | 'biography'
  > {}

export interface AudienceSettingsDataProps {
  requiredAudienceFields: RequiredAudienceSettingsFieldsDataProps;
  audienceFields: AudienceSettingsFieldsDataProps;
  editableAudienceFields: EditableAudienceSettingsFieldsDataProps;
}

export interface AudienceSettingsProps {
  id: string;
  data: AudienceSettingsDataProps;
}

import {
  Toolbar as MuiToolbar,
  ToolbarProps as MuiToolbarProps,
  styled,
} from '@mui/material';

export interface ToolbarProps extends MuiToolbarProps {}

const Toolbar = styled(MuiToolbar)<ToolbarProps>(() => ({}));

export default Toolbar;

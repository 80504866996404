// Custom Utilities
import apiHandler from 'core/utilities/apiHandler';

// Custom Endpoints
import { formFeedbacksListEndpoints as endpoints } from 'features/form/feedbacks/utilities/api/endpoints';

// Custom Types
import type { ApiPaginationProps } from 'core/types/shared/pagination/api';
import type {
  FeedbackListItemProps,
  FeedbackListProps,
} from 'features/form/feedbacks/types';
import type { DeprecatedApiGetDocsHandler } from 'core/types/api/hook/handler';

/**
 * Retrieves feedback items associated with a specific form ID.
 *
 * @param {Object} configs - Configuration object for the request.
 * @param {string} configs.id - The ID of the form to retrieve feedbacks for.
 * @param {Object} [configs.page] - Pagination information.
 * @param {Object} [configs.page.current] - The current page number.
 * @param {Object} [configs.page.size] - The number of items per page.
 * @param {string} [configs.params] - Additional query parameters for the request.
 * @returns {Promise<{ status: number; list: FeedbackListItemProps[]; page: { current: number; size: number; totalDocs: number }; other: { formTitle: string; allFeedbacksIds: string[]; filterResultIds: string[]; feedbackTitle: string[]; isPublic: boolean } }>}
 *          A promise that resolves to an object containing the status, list of feedback items, pagination info, and other related information.
 */
export const getFeedbacksByFormId: DeprecatedApiGetDocsHandler<
  FeedbackListItemProps
> = async (configs) => {
  if (!configs || !configs.id) return { status: 400, list: [] };

  const { status, data: response } = await apiHandler.get<{
    docs: { data: FeedbackListProps; paginate: ApiPaginationProps };
  }>(
    endpoints.getListById(configs.id as string, {
      page: configs.page,
      paramsString: configs.params,
    })
  );
  let list: FeedbackListItemProps[] = [];
  let formTitle: string = '';
  let processId: string = '';
  let feedbackTitle: string[] = [];
  let isPublic = true;
  let allFeedbacksIds: string[] = [];
  let filterResultIds: string[] = [];

  if (response) {
    list = response.docs.data.data.feedbacks;
    formTitle = response.docs.data.data.formTitle;
    feedbackTitle = response.docs.data.data.feedbackTitle || [];
    isPublic = response.docs.data.data.isPublic || true;
    allFeedbacksIds = response.docs.data.data.allFeedbacksIds;
    filterResultIds = response.docs.data.data.filterResultIds;
    processId = response.docs.data.data.processId || '';
  }
  return {
    status,
    list,
    page: {
      current: configs.page.current,
      size: configs.page.size,
      totalDocs: filterResultIds.length,
    },
    other: {
      formTitle,
      allFeedbacksIds,
      filterResultIds,
      feedbackTitle,
      isPublic,
      processId,
    },
  };
};

export const editFeedbackIsEditableStatus = async (payload: {
  selection: string[];
  isEditable: boolean;
}): Promise<{ status: number }> => {
  const { status } = await apiHandler.put(endpoints.groupOperation('update'), {
    ids: payload.selection,
    isEditable: payload.isEditable,
  });

  return { status };
};

export const addFeedbacksToProcess = async (payload: {
  selection: string[];
  processId: string;
}): Promise<{ status: number }> => {
  const { status } = await apiHandler.put(
    endpoints.groupOperation('add-process', `processId=${payload.processId}`),
    {
      ids: payload.selection,
    }
  );
  return { status };
};

/**
 * Removes selected feedback items.
 *
 * @param {string[] | string} selection - An array of feedback IDs or a single feedback ID to remove.
 * @returns {Promise<{status:number}>} A promise that resolves to the status code of the operation.
 */
export const removeFeedbacks = async (
  selection: string[] | string
): Promise<{ status: number }> => {
  const { status } = await apiHandler.put(endpoints.groupOperation('remove'), {
    ids: typeof selection === 'string' ? [selection] : selection,
  });

  return { status };
};

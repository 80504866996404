import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';
interface FingerprintFilledIconProps extends SvgIconProps {}

const FingerprintFilledIcon: React.FC<FingerprintFilledIconProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <path d='M3.17,9.52a.59.59,0,0,1-.35-.09.68.68,0,0,1-.21-.17A1.08,1.08,0,0,1,2.49,9a.82.82,0,0,1,0-.27.92.92,0,0,1,.09-.25C3.45,7,6.13,3.58,12,3.58a11.22,11.22,0,0,1,6.62,2,11.3,11.3,0,0,1,2.79,2.89.8.8,0,0,1,.11.25.62.62,0,0,1,0,.27.77.77,0,0,1-.1.25.86.86,0,0,1-.19.19.73.73,0,0,1-1-.19A9.38,9.38,0,0,0,12,4.94c-5.14,0-7.47,3-8.23,4.24a.67.67,0,0,1-.25.25A.63.63,0,0,1,3.17,9.52Z' />
      <path d='M15,23l-.18,0c-4.25-1.05-5.84-5.31-5.91-5.48v0c0-.13-.89-3,.43-4.75A3.23,3.23,0,0,1,12,11.53a2.9,2.9,0,0,1,2.48,1.07,5.17,5.17,0,0,1,.82,2c.38,1.45.65,2.22,2.19,2.3a1.61,1.61,0,0,0,1.38-.7,4.05,4.05,0,0,0,.29-3.76,7.92,7.92,0,0,0-7.17-5A7.35,7.35,0,0,0,7.29,9.15a7.54,7.54,0,0,0-2.35,3.43c-.76,2.39.24,6.16.25,6.19a.82.82,0,0,1,0,.27,1.14,1.14,0,0,1-.09.26.81.81,0,0,1-.18.19.64.64,0,0,1-.25.12.71.71,0,0,1-.86-.49c0-.17-1.11-4.19-.23-7a8.74,8.74,0,0,1,8.4-6,8.84,8.84,0,0,1,5.65,2A9.59,9.59,0,0,1,20.48,12,5.39,5.39,0,0,1,20,17a3,3,0,0,1-2.58,1.26c-2.65-.13-3.13-2-3.48-3.33s-.58-2-1.95-2a1.87,1.87,0,0,0-1.6.63A3.29,3.29,0,0,0,10,15.69a7.13,7.13,0,0,0,.23,1.37c.11.27,1.53,3.75,4.93,4.6a.66.66,0,0,1,.24.11.61.61,0,0,1,.19.2.92.92,0,0,1,.09.25.62.62,0,0,1,0,.27.77.77,0,0,1-.25.37A.71.71,0,0,1,15,23Z' />
      <path d='M9.49,22.69a.63.63,0,0,1-.27-.06A.78.78,0,0,1,9,22.47a11.07,11.07,0,0,1-3-6.29H6a7.3,7.3,0,0,1,1.56-5.61A5.78,5.78,0,0,1,12,8.77a5.59,5.59,0,0,1,5.12,3,8.21,8.21,0,0,1,1,2.92.82.82,0,0,1,0,.27.92.92,0,0,1-.13.24.68.68,0,0,1-.21.17.59.59,0,0,1-.26.07.71.71,0,0,1-.51-.14.68.68,0,0,1-.26-.47,7.11,7.11,0,0,0-.82-2.44A4.18,4.18,0,0,0,12,10.14a4.45,4.45,0,0,0-3.45,1.33A6,6,0,0,0,7.33,16,9.65,9.65,0,0,0,10,21.53a.79.79,0,0,1,.14.23.62.62,0,0,1,0,.27,1.22,1.22,0,0,1-.06.26.83.83,0,0,1-.16.21A.76.76,0,0,1,9.49,22.69Z' />
      <path d='M17.34,20.72a5.82,5.82,0,0,1-3.77-1.24,6.81,6.81,0,0,1-2.27-4.42.67.67,0,0,1,.16-.51.72.72,0,0,1,1-.08.7.7,0,0,1,.24.48,5.56,5.56,0,0,0,1.78,3.48,4.83,4.83,0,0,0,3.7.87.71.71,0,0,1,.51.14.66.66,0,0,1,.26.46.62.62,0,0,1,0,.27.7.7,0,0,1-.13.24.55.55,0,0,1-.2.17.79.79,0,0,1-.26.08A8.22,8.22,0,0,1,17.34,20.72ZM18.52,2.5A12.08,12.08,0,0,0,12,1C7.93,1,5.87,2.24,5.41,2.56l-.08.06h0a.58.58,0,0,0-.18.23.67.67,0,0,0-.06.28.58.58,0,0,0,.06.26.57.57,0,0,0,.15.22.57.57,0,0,0,.23.15.75.75,0,0,0,.26,0,.74.74,0,0,0,.41-.13S8,2.37,12,2.37s5.8,1.3,5.82,1.31a.71.71,0,0,0,.42.14.86.86,0,0,0,.27,0,.54.54,0,0,0,.22-.15.69.69,0,0,0,.16-.22.75.75,0,0,0,.05-.26.77.77,0,0,0-.11-.39A.75.75,0,0,0,18.52,2.5Z' />
    </IconBuilder>
  );
};

export default FingerprintFilledIcon;

export const AudienceGroupsEndpoints = {
  get add() {
    return `/audience/group/addGroup`;
  },
  edit(id: string) {
    return `/audience/group/update/${id}`;
  },
  get remove() {
    return `/audience/group/deleteGroups`;
  },
  getGroup(id: string) {
    return `/audience/group/group`;
  },
  getById(id: string) {
    return `/audience/group/group/${id}`;
  },
  get getList() {
    return `/audience/group/groups`;
  },
  get getSummaryList() {
    return `/audience/group/groupList`;
  },
};

import debounce from 'lodash/debounce';

// Custom Store
import store from 'core/store';

// Custom Utilities
import { actions } from 'features/form/forms/store/slice';
import { FormSectionProps } from 'features/form/forms/types/item/section';

export const toggleFormSectionCollapsed = (id: string, value?: boolean) => {
  store.dispatch(actions.toggleSectionCollapsed({ id, value }));
};

export const isFormSectionCollapsed = (id: string): boolean =>
  id in store.getState().form.sectionCollapsed;

export const updateFormSections = (sections: FormSectionProps[]) => {
  store.dispatch(actions.updateSections(sections));
};

export const setFormCurrentSectionId = debounce((id: string) => {
  store.dispatch(actions.setCurrentSectionId(id));
}, 150);

import {
  Container as MuiContainer,
  ContainerProps as MuiContainerProps,
  styled,
} from '@mui/material';

export interface ContainerProps extends MuiContainerProps {}

const Container = styled(MuiContainer)<ContainerProps>(({ theme }) => ({
  maxWidth: theme.breakpoints.values.lg,
  [theme.breakpoints.up('xl')]: {
    maxWidth: theme.breakpoints.values.xl,
  },
}));

export default Container;

import { type FC } from 'react';

// Custom Shared Components
import FormButton, { type FormButtonProps } from '../Core';

// Custom Icon Components
import SortIcon from 'core/components/icons/Sort';

// Types
export interface SortButtonProps extends FormButtonProps {}

const SortButton: FC<SortButtonProps> = (props) => {
  // Props
  const {
    children = 'ترتیب‌ بخش‌ها',
    startIcon = <SortIcon />,
    color = 'info',
    ...otherProps
  } = props;

  return (
    <FormButton color={color} startIcon={startIcon} {...otherProps}>
      {children}
    </FormButton>
  );
};

export default SortButton;

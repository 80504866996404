import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface InsertPhotoIconProps extends SvgIconProps {}

const InsertPhotoIcon: React.FC<InsertPhotoIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2M8.5 13.5l2.5 3.01L14.5 12l4.5 6H5z' />
  </IconBuilder>
);

export default InsertPhotoIcon;

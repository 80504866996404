import * as React from 'react';
import { SxProps } from '@mui/material';

// Custom Core Components
import IconButton from 'core/components/base/inputs/IconButton';

// Custom Icon Components
import CloseIcon from 'core/components/icons/Close';

// Custom Types
import type { IconButtonProps } from 'core/components/base/inputs/IconButton';
interface CloseIconButtonProps extends IconButtonProps {
  disabled?: boolean;
  iconSx?: SxProps;
}

const CloseIconButton: React.FC<CloseIconButtonProps> = (props) => {
  const { disabled, iconSx, children, color = 'error', ...otherProps } = props;

  return (
    <IconButton disabled={disabled} color={color} {...otherProps}>
      <CloseIcon fontSize='inherit' sx={iconSx} />
    </IconButton>
  );
};

export default CloseIconButton;

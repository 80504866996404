import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Custom Hooks
import { useDialogContext } from 'core/store/context/DialogProvider';
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';
import { useSearchParams, useNavigate } from 'react-router-dom';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';

// Custom Icons
import DeleteIcon from 'core/components/icons/DeleteIcon';

// Custom Utilities
import { removeFeedbacks } from 'features/form/feedbacks/utilities/api/list';
import {
  filter,
  forLoop,
  isSucceed,
  setAppAlert,
  setAppLoading,
} from 'core/utilities/helper';

// Custom Types
interface DeleteButtonProps {}

const DeleteButton: React.FC<DeleteButtonProps> = (props) => {
  // Context
  const loading = useSelectLoading();

  // Hooks
  const navigate = useNavigate();
  const { onOpenDialog, onCloseDialog } = useDialogContext();
  const { palette } = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeId = searchParams.get('activeId');
  const feedbackIds = searchParams.getAll('feedbackId');

  // Utilities
  const handleDeleteFeedback = async () => {
    if (!activeId) return;
    setAppLoading(true);
    const { status } = await removeFeedbacks([activeId]);
    if (isSucceed(status)) {
      if (feedbackIds.length === 1) setTimeout(() => navigate(-1), 1500);
      else {
        const newIds = filter(feedbackIds, (id) => id !== activeId);
        searchParams.set('activeId', newIds[0]);
        searchParams.delete('feedbackId');
        forLoop(newIds, (id) => searchParams.append('feedbackId', id));
        setSearchParams(searchParams, { replace: true });
      }

      setAppAlert(
        'بازخورد مورد نظر با موفقیت حذف شد',
        'success',
        undefined,
        undefined,
        undefined,
        1000
      );
    } else setAppAlert('خطا در حذف بازخورد');
    onCloseDialog();
    setAppLoading(false);
  };
  const handleOpenDialog = () => {
    onOpenDialog({
      onSubmit: handleDeleteFeedback,
      title: 'آیا مطمئن هستید؟',
      description:
        'با حذف بازخورد، تمامی اطلاعات ثبت شده این بازخورد و فرایند آن حذف می‌شوند.',
      submitTitle: 'حذف',
    });
  };

  // Render
  return (
    <Button
      variant='contained'
      onClick={handleOpenDialog}
      disabled={loading}
      sx={{
        background: palette.common.white,
        minWidth: '2.625rem',
        maxWidth: '2.625rem',
        height: '2.625rem',
        borderRadius: '.25rem',
        transition: 'opacity .3s',
        ':hover': {
          background: palette.common.white,
          opacity: '.7',
        },
      }}
    >
      <DeleteIcon color='error' />
    </Button>
  );
};

export default DeleteButton;

import { useState, type FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import debounce from 'lodash/debounce';

// Custom Core Components
import TextField from 'core/components/base/inputs/TextField';
import Collapse from 'core/components/base/utils/Collapse';

// Custom Common Components
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import CollapseIconButton from 'core/components/shared/IconButton/Collapse';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import RowStack from 'core/components/shared/Stack/RowStack';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import SlugTextField from 'core/components/shared/TextField/Slug';

// Custom Feature Components
import SkinsAutocomplete from 'features/appBuilder/skins/components/Autocomplete';
import MultipleUploader from 'features/file/files/components/shared/Uploader/Multiple';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { setFormActionMode } from 'features/form/forms/store/actions';

// Custom Types
import type { FormProps } from 'features/form/forms/types/item';
export interface FormSettingsInfoProps {
  disabled?: boolean;
}

const FormSettingsInfo: FC<FormSettingsInfoProps> = (props) => {
  // Props
  const { disabled } = props;

  // States
  const [collapsed, setCollapsed] = useState<boolean>(true);

  // Hooks
  const actionMode = useAppSelector((state) => state.form.actionMode);
  const { control, setValue, setError } = useFormContext<FormProps>();

  // Utilities
  const toggleCollapse = () => setCollapsed(!collapsed);
  const updateActionMode = debounce(() => {
    if (actionMode !== 'SETTINGS') {
      setFormActionMode('SETTINGS');
    }
  }, 250);

  // Render
  return (
    <RoundPaper
      sx={{
        backgroundColor: 'background.default',
        borderRadius: '1rem',
        width: '100%',
      }}
    >
      <SpaceBetween>
        <PageTitle color='text.secondary'>مشخصات</PageTitle>
        <CollapseIconButton collapsed={collapsed} onClick={toggleCollapse} />
      </SpaceBetween>
      <Collapse in={collapsed}>
        <ColumnStack pb='1rem'>
          <RowStack spacing={6}>
            <InputWrapper width='100%'>
              <InputLabel indentRight required>
                عنوان
              </InputLabel>
              <Controller
                control={control}
                name='data.title'
                defaultValue=''
                rules={{ onChange: updateActionMode }}
                render={({ field, fieldState, formState }) => (
                  <TextField
                    disabled={disabled}
                    error={fieldState.error?.message ? true : false}
                    helperText={fieldState.error?.message}
                    placeholder='عنوان فرم'
                    {...field}
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper width='100%' />
          </RowStack>
          <RowStack spacing={6}>
            <InputWrapper width='100%'>
              <InputLabel indentRight required>
                آدرس فرم
              </InputLabel>
              <Controller
                control={control}
                name='data.slug'
                rules={{ onChange: updateActionMode }}
                defaultValue=''
                render={({ field, fieldState }) => (
                  <SlugTextField
                    disabled={disabled}
                    placeholder='slug'
                    copyValue={`/form/${field.value}`}
                    error={fieldState.error?.message ? true : false}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper width='100%'>
              <InputLabel indentRight>
                صفحه هدایت‌شونده پس از تکمیل فرم
              </InputLabel>
              <Controller
                control={control}
                name='data.redirectTo'
                rules={{ onChange: updateActionMode }}
                defaultValue=''
                render={({ field }) => (
                  <SlugTextField
                    copyValue={field.value}
                    disabled={disabled}
                    placeholder='slug'
                    {...field}
                  />
                )}
              />
            </InputWrapper>
          </RowStack>
          <RowStack spacing={6}>
            <InputWrapper width='100%'>
              <InputLabel indentRight>متن آغازین در ابتدای فرم:</InputLabel>
              <Controller
                control={control}
                name='data.description'
                rules={{ onChange: updateActionMode }}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    disabled={disabled}
                    placeholder='متن ابتدایی معرفی فرم را وارد نمایید'
                    multiline
                    rows={3}
                    {...field}
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper width='100%'>
              <InputLabel indentRight>متن پایانی بعد از تکمیل فرم:</InputLabel>
              <Controller
                control={control}
                name='data.submitDescription'
                rules={{ onChange: updateActionMode }}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    disabled={disabled}
                    placeholder='متن پایانی فرم را وارد نمایید'
                    multiline
                    rows={3}
                    {...field}
                  />
                )}
              />
            </InputWrapper>
          </RowStack>
          <RowStack spacing={6}>
            <InputWrapper width='100%' sx={{ flexShrink: 0.5 }}>
              <InputLabel indentRight required>
                اسکین
              </InputLabel>
              <Controller
                control={control}
                name='data.skinId'
                defaultValue=''
                render={({ field, fieldState }) => (
                  <SkinsAutocomplete
                    disabled={disabled}
                    error={fieldState.error?.message ? true : false}
                    helperText={fieldState.error?.message}
                    defaultValue={field.value}
                    onBlur={(selectedId) => {
                      if (!selectedId) field.onBlur();
                    }}
                    onChange={(selectedId) => {
                      if (selectedId)
                        setError('data.skinId', {
                          message: '',
                        });
                      setValue('data.skinId', selectedId);
                      updateActionMode();
                    }}
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper width='100%' sx={{ flexShrink: 0.5 }}>
              <InputLabel indentRight>
                افزودن عکس برای فرم (حداکثر ۳ عکس)
              </InputLabel>
              <Controller
                control={control}
                name='data.images'
                defaultValue={[]}
                render={({ field }) => (
                  <MultipleUploader
                    location='form'
                    disabled={disabled}
                    limit={3}
                    accept={['image']}
                    value={field.value}
                    onChange={(files) => {
                      setValue(field.name, files);
                      updateActionMode();
                    }}
                  />
                )}
              />
            </InputWrapper>
          </RowStack>
        </ColumnStack>
      </Collapse>
    </RoundPaper>
  );
};

export default memo(FormSettingsInfo, isSame);

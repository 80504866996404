// Types
import type { FC } from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface AddRowIconProps extends SvgIconProps {}

const AddRowIcon: FC<AddRowIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M4,10V2H2v10h20V2h-2v8h-4V2h-2v8h-4V2H8v8H4z M13,19v3h-2v-3H8v-2h3v-3h2v3h3v2H13z' />
  </IconBuilder>
);

export default AddRowIcon;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface ChevronRightIconProps extends SvgIconProps {}

const ChevronRightIcon: React.FC<ChevronRightIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' />
  </IconBuilder>
);

export default ChevronRightIcon;

const authEndpoints = {
  get login() {
    return '/verify';
  },
  get logout() {
    return '/logOut';
  },
  get verify2FA() {
    return '/verify2FA';
  },
  get requestResetLink() {
    return '/recoveryPassword';
  },
  resetPassword(tempToken: string) {
    return `/updatePassword?resetPassword=${tempToken}`;
  },
  // logoutURL: '/logOut',
};

export default authEndpoints;

import { type FC, useCallback, useRef } from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';

// Custom Core Components
import Paper from 'core/components/base/surfaces/Paper';
import Collapse from 'core/components/base/utils/Collapse';

// Custom Feature Components
import PdfButton from 'features/form/feedbacks/components/details/Actions/Pdf/Button';
import ExcelButton from 'features/form/feedbacks/components/details/Actions/Excel/Button';
import ArrowBackButton from 'features/form/feedbacks/components/details/Actions/ArrowBackButton';
import DeleteButton from 'features/form/feedbacks/components/details/Actions/DeleteButton';
import FilterButton from 'features/form/feedbacks/components/details/Actions/FilterButton';
import Filter from 'features/form/feedbacks/components/details/Actions/Filter';
import FilterToggleButton from 'features/form/feedbacks/components/details/Actions/Filter/ModeButtonGroup';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';

// Custom Utilities
import { useAppSelector } from 'core/hooks/redux';
import { useSelectFormTitle } from 'features/form/feedbacks/store';

// Custom Types
interface FeedbackDetailsActionsProps {}

const FeedbackDetailsActions: FC<FeedbackDetailsActionsProps> = (props) => {
  // States
  const showFilter = useBoolean(false);

  // Context
  const formTitle = useSelectFormTitle();
  const themeMode = useAppSelector((state) => state.theme.themeMode);
  const ref = useRef();

  // Hooks
  const { palette, breakpoints } = useTheme();

  // Memoized Sections
  const ModeToggleButton = useCallback(() => <FilterToggleButton />, []);

  // Render
  return (
    <SpaceBetween ref={ref} width='100%'>
      <Paper
        sx={{
          width: showFilter.state ? '100%' : 'fit-content',
          overflow: 'hidden',
          backgroundColor: showFilter.state
            ? palette.background.paper
            : themeMode === 'dark'
            ? '#000000b3 !important'
            : 'transparent',
          padding: showFilter.state ? '2rem' : undefined,
          boxShadow: 'none',
          transition: 'all 300ms ease-in-out',
        }}
      >
        <SpaceBetween>
          <RowStack alignItems='center'>
            <FilterButton
              showFilter={showFilter.state}
              onClick={showFilter.toggle}
            />
            <BodyTwo
              color={palette.text.secondary}
              sx={{
                fontSize: '1.25rem',
                fontWeight: 500,
                [breakpoints.down('md')]: {
                  fontSize: '1rem',
                },
              }}
            >
              مشاهده بازخورد {formTitle}
            </BodyTwo>
          </RowStack>
          {showFilter.state && <ModeToggleButton />}
        </SpaceBetween>
        <Collapse
          in={showFilter.state}
          timeout={{
            appear: 300,
            enter: 300,
            exit: 100,
          }}
        >
          <Filter />
        </Collapse>
      </Paper>
      {!showFilter.state && (
        <RowStack alignItems='center'>
          <DeleteButton />
          <ExcelButton />
          <PdfButton />
          <ArrowBackButton />
        </RowStack>
      )}
    </SpaceBetween>
  );
};

export default FeedbackDetailsActions;

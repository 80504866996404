import type { FC } from 'react';
import type { Editor } from '@tiptap/react';

// Core Components
import IconButton from 'core/components/base/inputs/IconButton';
import ListIcon from 'core/components/icons/List';
import OrderedListIcon from 'core/components/icons/OrderedList';

// Component Types
interface EditorListIconButtonProps {
  editor: Editor | null;
}

const EditorListIconButton: FC<EditorListIconButtonProps> = (props) => {
  // Hooks
  const { editor } = props;

  return (
    <>
      {['bulletList', 'orderedList'].map((opt) => (
        <IconButton
          key={'editor-option-for-' + opt}
          onClick={() =>
            opt === 'bulletList'
              ? editor?.chain().focus().toggleBulletList().run()
              : editor?.chain().focus().toggleOrderedList().run()
          }
          color={editor?.isActive(opt) ? 'info' : 'inherit'}
          size='small'
        >
          {opt === 'bulletList' ? <ListIcon /> : <OrderedListIcon />}
        </IconButton>
      ))}
    </>
  );
};

export default EditorListIconButton;

import { createContext, useContext, type FC, type ReactNode } from 'react';

// Custom Types
import type { UseFilterResponse } from 'core/hooks/common/useFilter';

interface UseFilterProviderProps extends UseFilterResponse {
  children: ReactNode;
}

const UseFilterContext = createContext<UseFilterResponse>({
  filter: {},
  onFilterChange: (filter) => {},
  onFilterChangeByKey: (key, value) => {},
});

export const useFilterContext = () => useContext(UseFilterContext);

export const FilterProvider: FC<UseFilterProviderProps> = (props) => {
  // Props
  const { children, ...otherProps } = props;

  return (
    <UseFilterContext.Provider value={otherProps}>
      {children}
    </UseFilterContext.Provider>
  );
};

import * as React from 'react';

// Core Components
import Typography, {
  TypographyProps,
} from 'core/components/base/display/Typography';

// Types
interface BodyOneProps extends TypographyProps {
  disabled?: boolean;
}

const BodyOne: React.FC<BodyOneProps> = (props) => {
  const {
    children,
    component = 'p',
    disabled,
    variant = 'body1',
    ...otherProps
  } = props;

  return (
    <Typography
      color={disabled ? 'text.disabled' : 'inherit'}
      component={component}
      variant={variant}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};

export default BodyOne;

import * as React from 'react';

// Custom Feature Components
import Evaluation from 'features/form/feedbacks/components/details/Process/Evaluation';
import ManagerQuestion from 'features/form/feedbacks/components/details/Process/ManagerQuestion';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { useSelectFeedbackProcessItem } from 'features/form/feedbacks/store';

// Custom Types
import type { ManagerQuestionProps } from 'features/form/processes/types/details/managerQuestion';
import type { FeedbackProcessEvaluationProps } from 'features/form/feedbacks/types/detail/process/evaluation';

interface ProcessRendererProps {
  item: FeedbackProcessEvaluationProps | ManagerQuestionProps;
  showDeleteButton: boolean;
  showResetButton: boolean;
}

const ProcessRenderer: React.FC<ProcessRendererProps> = (props) => {
  // Props
  const { item, showDeleteButton, showResetButton } = props;

  // Context
  const hiddenSections = useSelectFeedbackProcessItem(item.id);

  // Render
  if (
    hiddenSections?.all ||
    (item.data.processItemType === 'managerQuestion' &&
      hiddenSections?.questionDetails)
  )
    return <></>;

  switch (item.data.processItemType) {
    case 'evaluation':
      return (
        <Evaluation
          showDeleteButton={showDeleteButton}
          showResetButton={showResetButton}
          evaluation={item as FeedbackProcessEvaluationProps}
        />
      );
    case 'managerQuestion':
      return (
        <ManagerQuestion
          question={item as ManagerQuestionProps}
          showDeleteButton={showDeleteButton}
          showResetButton={showResetButton}
        />
      );

    default:
      return <></>;
  }
};

export default React.memo(ProcessRenderer, isSame);

import * as React from 'react';

// Common Components
import CopyToClip from 'core/components/shared/IconButton/CopyToClip';

// Core Components
import TextField from 'core/components/base/inputs/TextField';
import InputAdornment from 'core/components/base/inputs/InputAdornment';

// Custom Icons
import CopyIcon from 'core/components/icons/Copy';

// Custom Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { TextFieldProps } from 'core/components/base/inputs/TextField';

type SlugTextFieldProps = TextFieldProps & { copyValue?: string };

const SlugTextField: React.FC<SlugTextFieldProps> = React.forwardRef(
  (props, ref) => {
    const {
      variant = 'outlined',
      copyValue = '',
      InputProps,
      size,
      disabled = false,
      ...otherProps
    } = props;

    // Context
    const loading = useSelectLoading();

    // Render
    return (
      <TextField
        variant={variant}
        InputProps={{
          dir: 'ltr',
          inputProps: {
            style: {
              padding: 0,
              fontSize: '14px',
            },
          },
          sx: { height: '3rem', ...InputProps?.sx },
          style: { borderRadius: '0.5rem' },
          autoComplete: 'off',
          startAdornment: (
            <InputAdornment sx={{ ml: 0 }} position='start'>
              <CopyToClip
                copyIcon={<CopyIcon color='info' />}
                size='small'
                disabled={loading || disabled}
                urlPrefixed
                value={copyValue}
              />
            </InputAdornment>
          ),
          ...InputProps,
        }}
        inputRef={ref}
        {...otherProps}
        disabled={loading || disabled}
      />
    );
  }
);

export default SlugTextField;

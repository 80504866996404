// Custom Types
import type { ContentDetailsPatternDataProps } from 'features/appBuilder/patterns/features/contentDetails/types';

/**
 * Validates the content details pattern data and returns an error message if validation fails.
 *
 * @param {ContentDetailsPatternDataProps} data - The content details pattern data to validate.
 * @returns {string} A string containing the error message if validation fails, or an empty string if the data is valid.
 */
export const validateContentDetailsPattern = (
  data: ContentDetailsPatternDataProps
): string => {
  let error = '';

  if (!data.patternTitle.trim()) {
    error = 'وارد کردن عنوان پترن الزامی می‌باشد.';
  }

  return error;
};

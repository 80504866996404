// Feature Utilities
import { bakePatternCommonProps } from 'features/appBuilder/patterns/core/utilities/api/baker';

// Feature Types
import type { ProfilePatternDataProps } from 'features/appBuilder/patterns/features/profile/types';

/**
 * Transforms the provided profile pattern data by ensuring the `creator` field is included as a string.
 *
 * @param {ProfilePatternDataProps} data - The profile pattern data to transform.
 * @returns {Omit<ProfilePatternDataProps, 'creator'> & { creator: string }} The transformed profile pattern data with the `creator` field as a string.
 */
export const bakeProfilePatternData = (
  data: ProfilePatternDataProps
): Omit<ProfilePatternDataProps, 'creator'> & { creator: string } => {
  const { config, ...otherProps } = data;

  const commonProps = bakePatternCommonProps(otherProps);

  return {
    ...commonProps,
    config,
  };
};

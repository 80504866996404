import { createContext, useContext } from 'react';

// Types
import type { FC, ReactNode } from 'react';

type EditModeType = 'edit' | 'create';

const MenuMode = createContext<EditModeType>('create');

interface MenuModeProviderProps {
  children: ReactNode;
  mode: EditModeType;
}

const MenuModeProvider: FC<MenuModeProviderProps> = (props) => {
  // Props
  const { children, mode } = props;
  // Render
  return <MenuMode.Provider value={mode}>{children}</MenuMode.Provider>;
};

export const useMenuModeContext = (): EditModeType => useContext(MenuMode);

export default MenuModeProvider;

import { useForm, FormProvider } from 'react-hook-form';

// Common Components
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Feature Components
import ContentData from 'features/content/contents/components/details/ContentsData';

// Custom Hooks
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// Feature Utilities
import { useContentDoc } from 'features/content/contents/hooks';
import { ContentSchema } from 'features/content/contents/validations';
import { initialContent } from 'features/content/contents/utilities/content';

// Feature Types
import type { ContentDetailsProps } from 'features/content/contents/types/details';

// Custom Types
interface EditContentProps {}

const EditContent: React.FC<EditContentProps> = () => {
  // Hooks
  const resolver = useYupValidationResolver(ContentSchema);
  const formMethods = useForm<ContentDetailsProps>({
    mode: 'onTouched',
    resolver,
    defaultValues: initialContent,
  });
  const contentMethods = useContentDoc({
    onFetch: (doc) => formMethods.reset(doc),
    initialData: initialContent,
  });

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='ویرایش مطلب' />
      <FormProvider {...formMethods}>
        <ContentData />
      </FormProvider>
    </>
  );
};

export default EditContent;

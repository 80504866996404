import * as React from 'react';

// Core Component
import Typography from 'core/components/base/display/Typography';

// Types
import type { TypographyProps } from 'core/components/base/display/Typography';
export interface HeadingSixProps extends TypographyProps {
  disabled?: boolean;
}

const HeadingSix: React.FC<HeadingSixProps> = (props) => {
  const {
    children,
    component,
    fontWeight = 'medium',
    fontSize = '20px',
    lineHeight = '0.15px',
    variant = 'h6',
    ...otherProps
  } = props;

  return (
    <Typography
      component='h6'
      fontWeight={fontWeight}
      fontSize={fontSize}
      lineHeight={lineHeight}
      variant={variant}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};

export default HeadingSix;

import type { FC } from 'react';

// Feature Components
import StablePatternContentSection from 'features/appBuilder/patterns/features/stable/components/Content';

// Custom Types
export interface StablePatternItemsProps {}

const StablePatternItems: FC<StablePatternItemsProps> = () => {
  return (
    <>
      <StablePatternContentSection />
    </>
  );
};

export default StablePatternItems;

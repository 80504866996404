import cloneDeep from 'lodash/cloneDeep';

// Feature Utilities
import { searchPatterCardConfigDefaults } from 'features/appBuilder/patterns/features/search/utilities/defaults/cardConfig';
import { searchPatterPageConfigDefaults } from 'features/appBuilder/patterns/features/search/utilities/defaults/pageConfig';

// Custom Types
import type { SearchPatternDataProps } from 'features/appBuilder/patterns/features/search/types';

const searchPatternDataDefaults: SearchPatternDataProps = {
  patternTitle: '',
  bgColorOne: '#F5F5F5',
  bgColorTwo: '#F5F5F5',
  dividerColor: '#C1C8D3',
  bgImage: null,
  bgImageType: 'cover',
  padding: '32px',
  cardBg: '#FFFFFF',
  cardCount: 20,
  creator: null,
  isActive: true,
  maxWidth: 'lg',
  sortIndex: 0,
  type: 'search',
  variant: 0,
  cardConfig: searchPatterCardConfigDefaults,
  pageConfig: searchPatterPageConfigDefaults,
};

/**
 * Retrieves the default search pattern data.
 *
 * @returns {SearchPatternDataProps} The default search pattern data.
 */
export const getSearchPatternDataDefault = () => {
  const cloned = cloneDeep(searchPatternDataDefaults);

  return cloned;
};

import {
  Paper as MuiPaper,
  PaperProps as MuiPaperProps,
  styled,
} from '@mui/material';

export interface PaperProps extends MuiPaperProps {}

const Paper = styled(MuiPaper)<PaperProps>(() => ({}));

export default Paper;

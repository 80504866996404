import React, { createContext, useContext } from 'react';

// Custom Types
import type { UseDocsApiResponse } from 'core/hooks/api/useDeprecatedDocs';

// @ts-ignore
const UseDocsApiContext: T = createContext<UseDocsApiResponse<T>>();

interface UseDocsApiProviderProps extends UseDocsApiResponse<any> {
  children: React.ReactNode;
}

export const DeprecatedDocsProvider: React.FC<UseDocsApiProviderProps> = ({
  children,
  ...otherProps
}) => {
  return (
    <UseDocsApiContext.Provider value={otherProps}>
      {children}
    </UseDocsApiContext.Provider>
  );
};

export const useDocsContext = <T = any,>(): Required<UseDocsApiResponse<T>> =>
  useContext(UseDocsApiContext);

import * as React from 'react';

// Custom Core Components
import Skeleton from 'core/components/base/feedback/Skeleton';

// Custom Types
interface InfoSkeletonProps {}

const InfoSkeleton: React.FC<InfoSkeletonProps> = (props) => {
  // Render
  return (
    <>
      <Skeleton sx={{ width: 80 }} />
      <Skeleton sx={{ width: 100 }} />
      <Skeleton sx={{ width: 70 }} />
      <Skeleton sx={{ width: 90 }} />
      <Skeleton sx={{ width: 100 }} />
    </>
  );
};

export default InfoSkeleton;

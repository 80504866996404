import { ThemeOptions } from '@mui/material';

const fontName = 'Vazir';

const globalThemeOptions: ThemeOptions = {
  direction: 'rtl',
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        fontFamily: fontName,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: fontName,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
          direction: 'ltr',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
          p: {
            fontFamily: fontName,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: fontName,
          p: {
            fontFamily: fontName,
          },
        },
      },
    },
  },
  typography: {
    allVariants: { fontFamily: fontName },
  },
};

export default globalThemeOptions;

import {
  Pagination as MuiPagination,
  PaginationProps as MuiPaginationProps,
  styled,
} from '@mui/material';

export interface PaginationProps extends MuiPaginationProps {}

const Pagination = styled(MuiPagination)<PaginationProps>(() => ({}));

export default Pagination;

import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Custom Hooks
import { useIndex } from 'core/store/context/IndexProvider';
import {
  useSelectFeedbackColumnCount,
  useSelectFeedbackHideEmpties,
  useSelectFeedbackOnlyRequireds,
  useSelectFullWidthInputs,
  useSelectIsSectionHidden,
  useSelectVisibleSectionsInput,
} from 'features/form/feedbacks/store';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Paper from 'core/components/base/surfaces/Paper';
import Collapse from 'core/components/base/utils/Collapse';

// Custom Feature Components
import FeedbackInput from 'features/form/feedbacks/components/details/Input';

// Custom Common Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';

// Custom Types
import type { SxProps } from '@mui/material';
import type { FeedbackSectionProps } from 'features/form/feedbacks/types/detail';

interface FeedbackSectionComponentProps {
  section: FeedbackSectionProps;
  sx?: SxProps;
}

const FeedbackSection: React.FC<FeedbackSectionComponentProps> = (props) => {
  // Props
  const { section, sx } = props;

  // Context
  const feedbackIndex = useIndex();
  const columnCount = useSelectFeedbackColumnCount();
  const hideEmpties = useSelectFeedbackHideEmpties();
  const isInputFullWidth = useSelectFullWidthInputs();
  const isSectionHidden = useSelectIsSectionHidden(section.id);
  const onlyRequireds = useSelectFeedbackOnlyRequireds();
  const inputs = useSelectVisibleSectionsInput(section.id, feedbackIndex);

  // States
  const [hideSection, setHideSection] = React.useState<boolean>(false);

  // Hooks
  const { breakpoints, palette } = useTheme();

  React.useEffect(() => {
    if (hideEmpties && inputs.length === 0) setHideSection(true);
    else setHideSection(false);
  }, [hideEmpties, feedbackIndex, inputs]);

  // Memoized Sections
  const SectionTitle = React.useCallback(
    () => (
      <BodyTwo
        sx={{
          fontSize: '1.25rem',
          fontWeight: 500,
          color: palette.text.primary,
        }}
      >
        {section.data.title}
      </BodyTwo>
    ),
    [section.data.title, palette]
  );
  const SectionInputs = React.useCallback(
    () => (
      <Box
        className={`input-${feedbackIndex}-${section.id}`}
        sx={{
          display: 'flex',
          width: '100%',
          gap: '1rem',
        }}
        width='100%'
        gap='1rem'
        flexWrap='wrap'
      >
        {inputs.map((input) => {
          let isFullWidth = input.id in isInputFullWidth;

          // Render
          return (
            <FeedbackInput
              input={input}
              key={`feedback-${feedbackIndex}-section-${section.id}-input-${input.id}`}
              sx={{
                width: isFullWidth
                  ? '100%'
                  : `calc(100% / ${columnCount} - 1.4rem)`,
                [breakpoints.down('md')]: {
                  width: '100%',
                },
                ['@media print']: {
                  width: isFullWidth
                    ? '100%'
                    : `calc(100% / ${columnCount} - 1.4rem)`,
                },
              }}
            />
          );
        })}
      </Box>
    ),
    [
      onlyRequireds,
      palette,
      columnCount,
      inputs,
      section.id,
      feedbackIndex,
      isInputFullWidth,
    ]
  );

  // Render
  return (
    <Collapse unmountOnExit in={!isSectionHidden && !hideSection}>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          padding: '1rem 1.5rem 1.188rem',
          borderRadius: '.5rem',
          width: '100%',
          minWidth: '100%',
          boxShadow: 'none',
          ['@media print']: {
            border: '1px dashed #000',
            borderRadius: '1rem',
            padding: '1rem',
          },
          ...sx,
        }}
      >
        {section.data.title && <SectionTitle />}
        <SectionInputs />
      </Paper>
    </Collapse>
  );
};

export default React.memo(FeedbackSection, isSame);

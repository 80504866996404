// Custom Hooks
import useUnMount from 'core/hooks/useUnMount';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Core Components
import DialogProvider from 'core/store/context/DialogProvider';
import Pagination from 'core/components/shared/Filter/Pagination';
import PageSize from 'core/components/shared/Filter/PageSize';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import SelectionProvider from 'core/store/context/common/UseSelection';

// Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import RowStack from 'core/components/shared/Stack/RowStack';

// Feature Components
import ProcessesActions from 'features/form/processes/components/list/Actions';
import ProcessesDataGrid from 'features/form/processes/components/list/DataGrid';

// Core Utilities
import useSelection from 'core/hooks/common/useSelection';
import useDialog from 'core/hooks/useDialog';

// Feature Utilities
import { useProcessDocs } from 'features/form/processes/hooks';

// Context
import DocsProvider from 'core/hooks/api/useDocs/Context';

// Types

interface ProcessesListPageProps {}

const ProcessesList: React.FC<ProcessesListPageProps> = (props) => {
  // Context
  const loading = useSelectLoading();

  // Hooks
  const selectionMethods = useSelection();
  const dialogMethods = useDialog();
  const processListMethods = useProcessDocs({
    alertOnFetchError: { message: 'دریافت لیست فرایندها با خطا مواجه شد.' },
  });

  const {
    data: { list, page },
    onPageSizeChange,
    onPageNumChange,
  } = processListMethods;

  useUnMount(() => selectionMethods.onClearSelections());

  // Render
  return (
    <DocsProvider {...processListMethods}>
      <SelectionProvider {...selectionMethods}>
        <DialogProvider {...dialogMethods}>
          <PageHeadConfigs pageTitle='فرایندها' />
          <ProcessesActions />
          <ColumnStack>
            <ProcessesDataGrid />
            <RowStack>
              <PageSize
                disabled={loading || list?.length === 0}
                page={page}
                sizeRange={[5, 10, 20, 50, 100]}
                sx={{ height: '2rem' }}
                onSizeChange={onPageSizeChange}
                onPageChange={onPageNumChange}
              />
              <Pagination
                disabled={loading || list?.length === 0}
                page={page}
                onPageChange={onPageNumChange}
              />
            </RowStack>
          </ColumnStack>
        </DialogProvider>
      </SelectionProvider>
    </DocsProvider>
  );
};

export default ProcessesList;

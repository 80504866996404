import { useState, useCallback } from 'react';

// Custom Types
import type { ColorType } from 'core/types/mui/color';

export interface UseDialogProps {
  showDialog: boolean;
  onSubmit?: SubmitHandler;
  disabled?: boolean;
  loading?: boolean;
  title?: string;
  description?: string;
  submitTitle?: string;
  submitColor?: ColorType;
}
export interface UseDialogReturnProps {
  showDialog: boolean;
  onOpenDialog: () => void;
  onCloseDialog: () => void;
  onSubmit?: () => void;
  onSetSubmitColor: React.Dispatch<React.SetStateAction<ColorType>>;
  onSetTitle: React.Dispatch<React.SetStateAction<string>>;
  onSetDescription: React.Dispatch<React.SetStateAction<string>>;
  onSetSubmitTitle: React.Dispatch<React.SetStateAction<string>>;
  onSetSubmitHandler: React.Dispatch<React.SetStateAction<any>>;
  disabled?: boolean;
  loading?: boolean;
  title?: string;
  description?: string;
  submitTitle?: string;
  submitColor?: ColorType;
}

type SubmitHandler = () => Promise<void>;

const useDialog = (): UseDialogReturnProps => {
  // States
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [submitTitle, setSubmitTitle] = useState<string>('');
  const [submitColor, setSubmitColor] = useState<ColorType>('primary');
  const [submitHandler, setSubmitHandler] = useState<
    SubmitHandler | undefined
  >();

  // Utilities
  const handleShowDialog = useCallback(() => setShowDialog(true), []);
  const handleCloseDialog = useCallback(() => setShowDialog(false), []);

  // Return
  return {
    showDialog,
    submitTitle,
    submitColor,
    title,
    description,
    onSetTitle: setTitle,
    onSetDescription: setDescription,
    onSetSubmitTitle: setSubmitTitle,
    onSetSubmitColor: setSubmitColor,
    onSetSubmitHandler: setSubmitHandler,
    onSubmit: submitHandler,
    onOpenDialog: handleShowDialog,
    onCloseDialog: handleCloseDialog,
  };
};

export default useDialog;

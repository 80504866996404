// Core Utilities
import { generateObjectId } from 'core/utilities/helper/id';

// Feature Types
import type { PatternGroupColumnProps } from 'features/appBuilder/patterns/features/group/types/column';

export const groupPatternColumnDefaults: PatternGroupColumnProps = {
  id: generateObjectId(),
  data: {
    items: [],
    title: 'ستون',
    width: 100,
  },
};

export const getGroupPatternColumnDefaults = (): PatternGroupColumnProps => ({
  id: generateObjectId(),
  data: {
    items: [],
    title: `ستون`,
    width: 100,
  },
});

import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// Core Components
import Paper from 'core/components/base/surfaces/Paper';
import Button from 'core/components/base/inputs/Button';

// Custom Components
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Icons
import CloseIcon from 'core/components/icons/Close';
import DeleteIcon from 'core/components/icons/DeleteIcon';
import ArrowBackIcon from 'core/components/icons/ArrowBack';
import DownloadFileIcon from 'core/components/icons/DownloadFile';
import InsertLinkIcon from 'core/components/icons/InsertLink';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import { downloadFile } from 'features/file/files/utilities/file';
import { prefixFileURL } from 'core/utilities/helper/link';
import { transformFileMemeType } from 'features/file/files/utilities/file';
import { useDocContext } from 'core/hooks/api/useDoc/Context';
import { getFileSource } from 'core/utilities/helper/helperPack';
import { setAppAlert } from 'core/utilities/helper';

// Custom Types
import type { FileProps } from 'features/file/files/types';
import type { SxProps } from '@mui/material';

interface FileActionsProps {
  onDelete?: () => void;
  onCloseDialog?: () => void;
  disableDeleteButton?: boolean;
  sx?: SxProps;
}

const FileActions: React.FC<FileActionsProps> = (props) => {
  // Props
  const { disableDeleteButton, sx, onDelete, onCloseDialog } = props;

  // Context
  const loading = useSelectLoading();
  const { data: file } = useDocContext<FileProps>();

  // Hooks
  const navigate = useNavigate();

  // Utilities
  const handleCopyToClip = async () => {
    if (file) {
      const valueToCopy = getFileSource(file);
      try {
        await navigator.clipboard.writeText(valueToCopy);
        setAppAlert('آدرس کپی شد.', 'success');
      } catch (error) {
        setAppAlert('خطا در کپی کردن آدرس');
      }
    }
  };

  // Render
  return (
    <RowStack sx={{ alignItems: 'center', ...sx }}>
      <Button
        component={Paper}
        role='button'
        disabled={loading}
        sx={{
          backgroundColor: 'background.paper',
          color: 'text.primary',
          minWidth: '2rem',
          height: '2rem',
          padding: 0,
          borderRadius: '.5rem',
          transition: 'opacity .3s',
          ':hover': {
            opacity: 0.8,
            backgroundColor: 'background.paper',
            color: 'text.primary',
          },
        }}
        variant='contained'
        onClick={handleCopyToClip}
      >
        <InsertLinkIcon />
      </Button>
      {!disableDeleteButton && (
        <Button
          component={Paper}
          role='button'
          disabled={loading}
          sx={{
            backgroundColor: 'background.paper',
            color: 'error.main',
            minWidth: '2rem',
            height: '2rem',
            padding: 0,
            borderRadius: '.5rem',
            transition: 'opacity .3s',
            ':hover': {
              opacity: 0.8,
              backgroundColor: 'background.paper',
              color: 'error.main',
            },
          }}
          variant='contained'
          color='error'
          onClick={onDelete}
        >
          <DeleteIcon />
        </Button>
      )}
      <Button
        component={Paper}
        role='button'
        disabled={loading}
        onClick={() => {
          if (file) {
            downloadFile(
              prefixFileURL(file.data.url),
              file.data.title,
              transformFileMemeType(file.data.mimeType)
            );
          }
        }}
        sx={{
          backgroundColor: 'background.paper',
          color: 'info.main',
          minWidth: '2rem',
          height: '2rem',
          padding: 0,
          borderRadius: '.5rem',
          transition: 'opacity .3s',
          ':hover': {
            opacity: 0.8,
            backgroundColor: 'background.paper',
            color: 'info.main',
          },
        }}
        variant='contained'
      >
        <DownloadFileIcon />
      </Button>
      <Button
        component={Paper}
        role='button'
        disabled={loading}
        onClick={() => (onCloseDialog ? onCloseDialog() : navigate(-1))}
        sx={{
          backgroundColor: 'background.paper',
          color: onCloseDialog ? 'error.main' : 'text.primary',
          minWidth: '2rem',
          height: '2rem',
          padding: 0,
          borderRadius: '.5rem',
          transition: 'opacity .3s',
          ':hover': {
            opacity: 0.8,
            backgroundColor: 'background.paper',
            color: onCloseDialog ? 'error.main' : 'text.primary',
          },
        }}
        variant='contained'
      >
        {onCloseDialog ? <CloseIcon /> : <ArrowBackIcon />}
      </Button>
    </RowStack>
  );
};

export default FileActions;

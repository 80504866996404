import { memo } from 'react';

// Type
import type { FC } from 'react';

// Custom Core Components
import Box from 'core/components/base/layout/Box';

// Custom Feature Components
import SectionModeButton from 'features/form/feedbacks/components/details/Actions/Filter/ViewSettings/SectionModeButton';
import SectionInputsButton from 'features/form/feedbacks/components/details/Actions/Filter/ViewSettings/SectionInputsButton';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';

// Custom Types
import type { FeedbackSectionProps } from 'features/form/feedbacks/types';

interface SectionFieldsProps {
  section: FeedbackSectionProps;
}

const SectionFields: FC<SectionFieldsProps> = (props) => {
  // Props
  const { section } = props;

  // Render
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1rem',
        flexWrap: 'wrap',
        maxWidth: '100%',
        padding: '.3rem',
        ...customRoundedScrollbarStyles,
      }}
    >
      <SectionModeButton section={section} />
      <SectionInputsButton sectionId={section.id} />
    </Box>
  );
};

export default memo(SectionFields, isSame);

import { type FC } from 'react';

// Custom Common Components
import MultiAutocomplete from 'core/components/shared/Autocomplete/api/MultipleAutocomplete';

// Custom Utilities
import { getManagerGroupsSummaryList } from 'features/manager/groups/utilities/api';
import { managerGroupsQueryKey } from 'features/manager/groups/hooks';

// Custom Types
import type { GenericMultiAutocompleteProps } from 'core/components/shared/Autocomplete/api/MultipleAutocomplete';

const ManagersAutocomplete: FC<
  Omit<GenericMultiAutocompleteProps<{}>, 'apiHandler' | 'queryKey'>
> = (props) => {
  return (
    <MultiAutocomplete
      limitTags={2}
      title='گروه مدیران'
      {...props}
      apiHandler={getManagerGroupsSummaryList}
      queryKey={managerGroupsQueryKey}
    />
  );
};

export default ManagersAutocomplete;

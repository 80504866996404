import * as React from 'react';
import { alpha, useTheme } from '@mui/material';

// Custom Core Components
import Table from 'core/components/base/display/Table';
import TableBody from 'core/components/base/display/TableBody';
import TableCell from 'core/components/base/display/TableCell';
import TableContainer from 'core/components/base/display/TableContainer';
import TableHead from 'core/components/base/display/TableHead';
import TableRow from 'core/components/base/display/TableRow';
import Checkbox from 'core/components/base/inputs/Checkbox';

// Custom Components
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import TableContainerPaper from 'core/components/shared/Paper/TableContainerPaper';

// Custom Utilities
import { getAudienceFeatureLabel } from 'features/audience/groups/utilities/helper';

// Context
import { useSelectionContext } from 'core/store/context/common/UseSelection';
import { convertNumberToPersian } from 'core/utilities/helper/helperPack';

// Custom Types
import type { AudienceGroupSummaryProps } from 'features/audience/groups/types';
interface AudienceGroupsDataGridProps {
  disabled?: boolean;
  groups: AudienceGroupSummaryProps[];
}

const AudienceGroupsDataGrid: React.FC<AudienceGroupsDataGridProps> = (
  props
) => {
  // Props
  const { groups = [], disabled } = props;

  // Context
  const selections = useSelectionContext();

  // Hooks
  const { palette } = useTheme();

  // Render
  return (
    <TableContainer component={TableContainerPaper}>
      <Table>
        <TableHead>
          <TableRow sx={{ borderBottom: `1px solid ${palette.divider}` }}>
            <TableCell padding='checkbox'>
              <Checkbox
                color='info'
                disabled={disabled || groups.length === 0}
                checked={selections.isAllSelected(groups)}
                indeterminate={selections.isIndeterminate(groups)}
                onChange={() => selections.onToggleAll(groups)}
              />
            </TableCell>
            <TableCell sx={{ width: '10rem', whiteSpace: 'nowrap' }}>
              عنوان گروه
            </TableCell>
            <TableCell sx={{ width: '5rem' }}>اعضا</TableCell>
            <TableCell>دسترسی‌ها</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {disabled && groups.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4}>در حال دریافت اطلاعات گروه‌ها.</TableCell>
            </TableRow>
          ) : groups.length > 0 ? (
            groups.map((group) => (
              <TableRow key={group.id}>
                <TableCell padding='checkbox'>
                  <Checkbox
                    color='info'
                    disabled={disabled || groups.length === 0}
                    checked={selections.isSelected(group.id)}
                    onChange={() => selections.onSelectionsChange(group.id)}
                  />
                </TableCell>
                <TableCell sx={{ width: '10rem', whiteSpace: 'nowrap' }}>
                  {group.data.title}
                </TableCell>
                <TableCell align='center' sx={{ width: '5rem' }}>
                  {convertNumberToPersian(group.data.audienceCount || 0)}
                </TableCell>
                <TableCell>
                  <RowStack spacing={0.5}>
                    {group.data.features.length > 0 ? (
                      group.data.features.map((feature) => (
                        <BodyTwo
                          key={`group-feature-${feature}`}
                          sx={{
                            background: alpha(palette.background.default, 0.3),
                            padding: '0.25rem 0.5rem',
                            borderRadius: '2px',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {getAudienceFeatureLabel(feature)}
                        </BodyTwo>
                      ))
                    ) : (
                      <BodyTwo sx={{ paddingLeft: '2rem' }}>-</BodyTwo>
                    )}
                  </RowStack>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>گروهی جهت نمایش وجود ندارد.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AudienceGroupsDataGrid;

import * as React from 'react';
import isEqual from 'lodash/isEqual';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Feature Components
import TextSeperatedByDivider from 'features/form/feedbacks/components/details/Process/shared/TextSeperatedByDivider';

// Custom Types
import type { ProcessItemOverallStatus } from 'features/form/processes/types/details/common/sharedItemProps';

interface QuestionTitleProps {
  questionTitle?: string;
  overalStatus?: ProcessItemOverallStatus;
  selectedOptionTitle?: string;
  message?: string;
}

const EvaluationQuestionTitle: React.FC<QuestionTitleProps> = (props) => {
  // Props
  const { overalStatus, questionTitle, selectedOptionTitle, message } = props;

  // Render
  return (
    <RowStack
      spacing='1rem'
      sx={{
        alignItems: { md: 'center' },
        flexDirection: { xs: 'column', sm: 'row' },
        flexWrap: 'wrap',
      }}
    >
      {questionTitle && <BodyTwo color='text'>{questionTitle}</BodyTwo>}
      {overalStatus === 'completed' && (
        <TextSeperatedByDivider
          firstText={selectedOptionTitle}
          firstTextProps={{
            color: 'info.main',
          }}
          secondText={message}
          secondTextProps={{
            color: 'body',
          }}
        />
      )}
    </RowStack>
  );
};

export default React.memo(EvaluationQuestionTitle, isEqual);

import { useCallback, useState } from 'react';

export interface UseBooleanReturnProps {
  state: boolean;
  set: React.Dispatch<React.SetStateAction<boolean>>;
  toggle: () => void;
  setTrue: () => void;
  setFalse: () => void;
}

/**
 * Custom React hook for managing boolean state and utility functions.
 * @param {boolean} [defValue=false] - Default value for the boolean state. Defaults to false.
 * @returns {UseBooleanReturnProps} - Object containing the boolean state and utility functions.
 */
const useBoolean = (defValue: boolean = false): UseBooleanReturnProps => {
  // States
  const [state, setState] = useState(defValue);

  // Utilities
  const handleToggle = useCallback(() => setState((v) => !v), []);
  const handleSetTrue = useCallback(() => setState(true), []);
  const handleSetFalse = useCallback(() => setState(false), []);

  // Return
  return {
    state,
    set: setState,
    setFalse: handleSetFalse,
    setTrue: handleSetTrue,
    toggle: handleToggle,
  };
};

export default useBoolean;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface RadioButtonFilledIconProps extends SvgIconProps {}

const RadioButtonFilledIcon: React.FC<RadioButtonFilledIconProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <path d='M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18.58A8.58,8.58,0,1,1,20.58,12,8.59,8.59,0,0,1,12,20.58Z' />
      <path d='M19.25,12A7.25,7.25,0,1,1,12,4.75,7.26,7.26,0,0,1,19.25,12Z' />
    </IconBuilder>
  );
};

export default RadioButtonFilledIcon;

import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';
import EyeIcon from 'core/components/icons/Eye';

// Feature Types
import type { ContentDetailsProps } from 'features/content/contents/types/details';

const ContentDetailsActionsView = () => {
  // Hooks
  const { control } = useFormContext<ContentDetailsProps>();

  return (
    <Controller
      control={control}
      name='data.viewsCount'
      render={({ field }) => (
        <Box
          sx={{
            bgcolor: 'background.paper',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            borderRadius: 2,
            minWidth: '4rem',
          }}
        >
          {field.value.toPersian()}
          <EyeIcon />
        </Box>
      )}
    />
  );
};

export default ContentDetailsActionsView;

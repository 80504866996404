import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';
import {
  useFileDoc,
  useDeleteFileMutation,
  useEditFileMutation,
} from 'features/file/files/hooks';

// Core Components
import Box from 'core/components/base/layout/Box';
import Grid2 from 'core/components/base/layout/Grid2';
import LoadingButton from 'core/components/base/inputs/LoadingButton';

// Custom Components
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import StackPaper from 'core/components/shared/Stack/StackPaper';
import PreviewLarge from 'core/components/shared/Files/PreviewLarge';
import PageTitle from 'core/components/shared/Typography/PageTitle';

// Feature Components
import FileInfo from 'features/file/files/components/details/Info';
import FileForm from 'features/file/files/components/details/Form';
import FileActions from 'features/file/files/components/details/Actions';
import FileImageSkeleton from 'features/file/files/components/details/ImageSkeleton';
import FileFormSkeleton from 'features/file/files/components/details/FormSkeleton';

// Feature Components
import DeleteDialog from 'features/file/files/components/list/DeleteDialog';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Context
import DocProvider from 'core/hooks/api/useDoc/Context';

// Custom Types
import type { FileDataProps, FileProps } from 'features/file/files/types';

interface EditFilePageProps {
  fileId?: string;
  disablePageTitle?: boolean;
  disableRedirectAfterUpdate?: boolean;
  onFileUpdated?: (updated: FileProps) => void;
  onCloseDialog?: () => void;
}

const EditFilePage: React.FC<EditFilePageProps> = (props) => {
  // Props
  const {
    fileId,
    disablePageTitle,
    disableRedirectAfterUpdate,
    onCloseDialog,
    onFileUpdated,
  } = props;

  // States
  const {
    state: showDeleteDialog,
    setTrue: handleOpenDeleteDialog,
    setFalse: handleCloseDeleteDialog,
  } = useBoolean();

  // Context
  const loading = useSelectLoading();

  const params = useParams();
  const id = fileId || params?.id || '';

  // Hooks
  const navigate = useNavigate();
  const fileMethods = useFileDoc({
    id,
    onFetch: (doc) => methods.reset({ ...doc.data }),
  });
  const { isFetched, data: file } = fileMethods;
  const methods = useForm<FileDataProps>({
    defaultValues: {
      ...file?.data,
    },
  });

  const { mutate: handleEditFile } = useEditFileMutation({
    autoAlert: { mode: 'edit', name: 'فایل مورد نظر' },
    onSuccess: (_, variable) => {
      if (onFileUpdated)
        onFileUpdated({ id: variable.id, data: variable.data });
      if (!disableRedirectAfterUpdate)
        setTimeout(() => navigate('/files'), 2000);
    },
  });

  const { mutate: handleDeleteFile } = useDeleteFileMutation({
    autoAlert: { mode: 'delete', name: 'فایل مورد نظر' },
    redirectAfterSuccessTo: '/files',
  });

  // Render
  return (
    <DocProvider {...fileMethods}>
      {!disablePageTitle && <PageHeadConfigs pageTitle='فایل‌ها' />}
      {!disablePageTitle && (
        <PageHeroBox>
          <PageTitle sx={{ direction: 'ltr /* @noflip */' }}>
            {file ? file.data.title : 'فایل‌ها'}
          </PageTitle>
          {isFetched && <FileActions onDelete={handleOpenDeleteDialog} />}
        </PageHeroBox>
      )}
      <StackPaper
        paperProps={{
          sx: {
            boxShadow: 'none',
            padding: disablePageTitle ? '0 .5rem' : '.5rem',
          },
        }}
      >
        <Grid2 container spacing={4}>
          {isFetched && file ? (
            <Grid2
              xs={8.5}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: ['video', 'image'].includes(file?.data?.type)
                  ? 'baseline'
                  : 'center',
              }}
            >
              <PreviewLarge
                objectFit='cover'
                fileData={file}
                {...(fileId && {
                  sx: {
                    height: '80vh',
                  },
                })}
              />
            </Grid2>
          ) : (
            <Grid2 xs={8.5}>
              <FileImageSkeleton />
            </Grid2>
          )}
          <Grid2 xs={3.5}>
            <Box
              component='form'
              onSubmit={methods.handleSubmit((data) =>
                handleEditFile({ id, data })
              )}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '2rem',
              }}
            >
              <FormProvider {...methods}>
                {disablePageTitle && (
                  <FileActions
                    onCloseDialog={onCloseDialog}
                    disableDeleteButton
                    sx={{ justifySelf: 'end', alignSelf: 'end' }}
                  />
                )}
                <FileInfo />
                {isFetched && file ? <FileForm /> : <FileFormSkeleton />}
              </FormProvider>
              <LoadingButton
                loadingPosition='center'
                loading={loading || !isFetched}
                variant='contained'
                type='submit'
                sx={{
                  alignSelf: 'end',
                  borderRadius: '.5rem',
                }}
              >
                بروزرسانی
              </LoadingButton>
            </Box>
          </Grid2>
        </Grid2>
      </StackPaper>
      {showDeleteDialog && (
        <DeleteDialog
          open={showDeleteDialog}
          submitColor='error'
          title={`آیا از حذف  فایل انتخاب شده مطمئن هستید؟`}
          onClose={() => handleCloseDeleteDialog()}
          onDelete={() => handleDeleteFile([id])}
        />
      )}
    </DocProvider>
  );
};

export default EditFilePage;

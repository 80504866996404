import { useState, type FC, useCallback, useEffect, useRef, memo } from 'react';

// Custom Core Components
import Autocomplete from 'core/components/base/inputs/Autocomplete';
import TextField from 'core/components/base/inputs/TextField';
import FormHelperText from 'core/components/base/inputs/FormHelperText';
import MenuItem from 'core/components/base/navigation/MenuItem';
import Checkbox from 'core/components/base/inputs/Checkbox';

// Custom Common Component
import BodyOne from 'core/components/shared/Typography/BodyOne';

// Custom Icon Components
import ArrowDropDownIcon from 'core/components/icons/ArrowDropDown';

// Custom Styles
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';

// Custom Hooks
import useClickOutside from 'core/hooks/useClickOutside';

// Custom Utilities
import { toggleFormIsInInputConfigSelection } from 'features/form/forms/store/actions';

// Custom Types
import type { FileFormat } from 'features/file/files/types';
import { isSame } from 'core/utilities/helper/helperPack';

type Option = FileFormat | 'all';
export interface FileFormatsAutocompleteProps {
  values: string[];
  excludedOptions?: FileFormat[];
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  onChange: (values: string[]) => void;
}

const FileFormatsAutocomplete: FC<FileFormatsAutocompleteProps> = (props) => {
  // Props
  const {
    excludedOptions = [],
    values,
    helperText,
    disabled,
    error,
    onChange,
  } = props;

  // Hooks
  const [excluded, setExcluded] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [selected, setSelected] = useState<Option[]>([]);
  const [opts, setOpts] = useState<Option[]>(options);

  const ref = useRef(null);

  useClickOutside(ref, () => setOpen(false));

  const allOpts = [...opts];
  allOpts.splice(0, 1);

  const handleIsInInputConfigSelection = useCallback(() => {
    toggleFormIsInInputConfigSelection(open);
  }, [open]);

  useEffect(() => {
    handleIsInInputConfigSelection();
  }, [handleIsInInputConfigSelection]);

  const handleExcludedOptions = useCallback(() => {
    if (!excluded) {
      if (excludedOptions.length > 0) {
        const optsToSet: Option[] = [...opts].filter((opt) => {
          if (opt === 'all') return true;

          return excludedOptions.includes(opt) ? false : true;
        });

        setOpts(optsToSet);
      } else {
        setOpts(opts);
      }
      setExcluded(true);
    }
  }, [opts, excludedOptions, excluded]);

  useEffect(() => {
    handleExcludedOptions();
  }, [handleExcludedOptions]);

  // Utilities
  const handleSelectionChange = (opt: Option | Option[]) => {
    let clonedSelected = Array.isArray(opt) ? opt : [...selected];

    if ((Array.isArray(opt) && opt.includes('all')) || opt === 'all') {
      if (selected.length === allOpts.length) {
        clonedSelected = [];
      } else {
        clonedSelected = allOpts;
      }
    } else if (!Array.isArray(opt)) {
      const index = clonedSelected.findIndex((o) => o === opt);

      if (index > -1) clonedSelected.splice(index, 1);
      else clonedSelected.push(opt);
    }

    setSelected(clonedSelected);
    if (onChange) onChange(clonedSelected);
  };

  const handleDefaults = useCallback(() => {
    if (values && values.length > 0 && !initialized) {
      setSelected(values as Option[]);
      setInitialized(true);
    }
  }, [values, initialized]);

  useEffect(() => {
    handleDefaults();
  }, [handleDefaults]);

  return (
    <>
      <Autocomplete
        closeText='بستن'
        clearText='پاک کردن'
        openText='باز کردن'
        loadingText='در حال دریافت...'
        noOptionsText='گزینه‌ای برای انتخاب وجود ندارد'
        getLimitTagsText={(num) => <BodyOne>{num.toPersian()}+</BodyOne>}
        disableCloseOnSelect
        multiple
        disabled={disabled}
        open={open}
        limitTags={1}
        options={opts}
        getOptionLabel={(opt) => (opt === 'all' ? 'همه' : opt)}
        popupIcon={
          <ArrowDropDownIcon
            sx={{
              color: disabled
                ? 'text.disabled'
                : error
                ? 'error.main'
                : 'info.main',
            }}
          />
        }
        renderInput={(params) => (
          <TextField {...params} placeholder='انتخاب نوع فایل' />
        )}
        ListboxProps={{
          sx: { ...customRoundedScrollbarStyles, maxHeight: '21rem', p: 0 },
        }}
        renderOption={(props, option) => (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              handleSelectionChange(option);
            }}
            {...props}
          >
            <Checkbox
              onClick={(e) => {
                e.stopPropagation();
                handleSelectionChange(option);
              }}
              size='small'
              checked={
                option === 'all'
                  ? allOpts.length === selected.length
                  : selected.includes(option)
              }
            />
            <BodyOne
              onClick={(e) => {
                e.stopPropagation();
                handleSelectionChange(option);
              }}
              sx={{ flexGrow: 1 }}
            >
              {option === 'all' ? 'همه' : option}
            </BodyOne>
          </MenuItem>
        )}
        value={selected}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(e, v) => handleSelectionChange(v)}
      />
      {helperText && (
        <FormHelperText sx={{ color: error ? 'error.main' : 'text.primary' }}>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default memo(FileFormatsAutocomplete, isSame);

const options: Option[] = [
  'avi',
  'doc',
  'docx',
  'flac',
  'flv',
  'gif',
  'jpeg',
  'jpg',
  'mkv',
  'mov',
  'mp3',
  'mp4',
  'mpeg',
  'ogg',
  'pdf',
  'png',
  'pptx',
  'svg',
  'wav',
  'wmv',
  'xlsx',
];

import * as React from 'react';

// Core Component
import Typography from 'core/components/base/display/Typography';

// Types
import type { TypographyProps } from 'core/components/base/display/Typography';
interface HeadingFourProps extends TypographyProps {}

const HeadingFour: React.FC<HeadingFourProps> = (props) => {
  const { children, component, variant = 'h6', ...otherProps } = props;

  return (
    <Typography component='h4' variant={variant} {...otherProps}>
      {children}
    </Typography>
  );
};

export default HeadingFour;

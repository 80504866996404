import { useEffect, useCallback, useState } from 'react';

// Types
import type { FC } from 'react';

// Core Components
import Typography from 'core/components/base/display/Typography';

// Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import DataGrid from 'core/components/shared/DataGrid';
import CopyToClipboard from 'core/components/shared/Typography/CopyToClipboard';
import EmptyCell from 'core/components/shared/Typography/EmptyCell';

// Utilities
import { convertToURL } from 'core/utilities/helper/helperPack';

// Custom Types
import type { CategoryProps } from 'features/content/categories/types';
import type { DataGridProps } from 'core/components/shared/DataGrid';
import type {
  DataGridColumn,
  DataGridRowValueProps,
} from 'core/components/shared/DataGrid/dataGrid';
import { useDocsContext } from 'core/hooks/api/useDocs/Context';

interface CategoryDataGridProps
  extends Omit<DataGridProps, 'rows' | 'columns'> {
  categories: CategoryProps[];
}

const columns: DataGridColumn[] = [
  {
    field: 'col1',
    headerTitle: 'عنوان',
    width: 150,
  },
  {
    field: 'col2',
    headerTitle: 'توضیح',
    width: 300,
    renderCell: (params) => (
      <Typography
        component='p'
        variant='body2'
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '300px',
        }}
      >
        {params.row.col2}
      </Typography>
    ),
  },
  {
    field: 'col3',
    headerTitle: 'آدرس',
    width: 200,
    renderCell: (params) => {
      const url = convertToURL(`/cat/${params.row.col3}`);

      return url ? (
        <CopyToClipboard
          value={url}
          label={params.row.col3}
          wrapperProps={{
            sx: {
              dir: 'ltr',
              width: '100%',
              justifyContent: 'flex-start',
            },
          }}
        />
      ) : (
        <EmptyCell />
      );
    },
  },
  {
    field: 'col4',
    headerTitle: 'مطالب',
    width: 65,
    align: 'center',
    renderCell: (params) => <BodyTwo>{params.row.col4?.toPersian()}</BodyTwo>,
  },
];

const CategoriesDataGrid: FC<CategoryDataGridProps> = (props) => {
  // Props
  const { categories = [], ...otherProps } = props;

  // States
  const [rows, setRows] = useState<DataGridRowValueProps[]>([]);

  // Hooks
  const generateRows = useCallback(() => {
    const rows: DataGridRowValueProps[] = [];

    categories?.forEach?.(({ id, data }) => {
      const row = {
        id,
        col1: data.title,
        col2: data.description ? data.description : '-',
        col3: data.slug,
        col4: data.count,
      };
      rows.push(row);
    });

    setRows(rows);
  }, [categories]);

  useEffect(() => {
    generateRows();
  }, [categories, generateRows]);

  return <DataGrid columns={columns} rows={rows} {...otherProps} />;
};

export default CategoriesDataGrid;

import {
  type FC,
  type MouseEvent,
  type FocusEvent,
  useCallback,
  memo,
} from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

// Cusotm Core Components
import Box, { type BoxProps } from 'core/components/base/layout/Box';
import InputBase from 'core/components/base/inputs/InputBase';

// Custom Common Components
import BodyOne from 'core/components/shared/Typography/BodyOne';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import { isSucceed, setAppAlert, setAppLoading } from 'core/utilities/helper';

// Custom Feature Utilities
import { getFormStep } from 'features/form/forms/utilities/api/steps';
import { setFormCurrentStep } from 'features/form/forms/store/actions/step';
import { setFormActionMode } from 'features/form/forms/store/actions';
import { getInputsIndexMap } from 'features/form/forms/utilities/input/helper';

// Custom Types
import type { FormProps } from 'features/form/forms/types/item';
import type { FormStepProps } from 'features/form/forms/types/item/step';
export interface StepButtonProps extends BoxProps {
  step: FormStepProps;
  index?: number;
  disabled?: boolean;
}

const StepButton: FC<StepButtonProps> = (props) => {
  // Props
  const {
    step,
    color = 'text.disabled',
    index = 0,
    disabled,
    sx,
    onClick,
    ...otherProps
  } = props;

  // Context
  const actionMode = useAppSelector((state) => state.form.actionMode);
  const currentStep = useAppSelector((state) => state.form.currentStep);
  const { control, setValue } = useFormContext<FormProps>();

  // Hooks
  const { update } = useFieldArray({ control, name: 'data.steps' });

  // Utilities
  const isCurrent = useCallback(
    () =>
      step.id === currentStep.id && step.data.sortIndex === currentStep.index,
    [step.id, currentStep.id, step.data.sortIndex, currentStep.index]
  );

  const handleStepClick = async (e: MouseEvent<HTMLDivElement>) => {
    if (disabled) return;

    if (actionMode === 'STEP') {
    } else if (isCurrent()) {
      setFormActionMode('STEP');
    } else {
      setAppLoading(true);
      const { status, inputs, sections } = await getFormStep(step.id);
      setAppLoading(false);

      if (isSucceed(status) && inputs && sections) {
        setFormCurrentStep({
          id: step.id,
          index: step.data.sortIndex,
          inputs,
          sections,
        });

        const inputsIndexMap = getInputsIndexMap(inputs);
        setValue('data.sections', sections);
        setValue('data.inputs', inputs);
        setValue('data.inputsIndexMap', inputsIndexMap);
      } else {
        setAppAlert('خطا در دریافت اطلاعات این مرحله');
      }
    }

    if (onClick) onClick(e);
  };

  return (
    <Box
      color={color}
      onClick={handleStepClick}
      sx={{
        cursor: disabled ? 'auto' : 'pointer',
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        width: 'fit-conent',
        gap: 1,
        ':hover': {
          color:
            actionMode === 'STEP'
              ? 'text.secondary'
              : disabled
              ? 'text.disabled'
              : isCurrent()
              ? 'info.main'
              : 'text.secondary',
        },
        ...sx,
      }}
      {...otherProps}
    >
      <Box
        sx={{
          height: '1.5rem',
          minWidth: '1.5rem',
          width: '1.5rem',
          borderRadius: '50%',
          backgroundColor:
            actionMode === 'STEP'
              ? 'text.secondary'
              : disabled
              ? 'text.disabled'
              : isCurrent()
              ? 'info.main'
              : 'text.secondary',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
        }}
      >
        {(index + 1).toPersian()}
      </Box>
      {actionMode === 'STEP' ? (
        <Controller
          control={control}
          name={`data.steps.${step.data.sortIndex}.data.title`}
          rules={{
            onBlur: (e: FocusEvent<HTMLInputElement>) => {
              if (!e.target.value.trim) {
                update(step.data.sortIndex, {
                  id: step.id,
                  data: {
                    ...step.data,
                    title: `مرحله ${step.data.sortIndex + 1}`,
                  },
                });
              }
            },
          }}
          render={({ field }) => (
            <InputBase
              placeholder='بدون عنوان'
              sx={{
                whiteSpace: 'nowrap',
                width: '4.5rem',
                ':before': { borderBottom: 'none' },
              }}
              {...field}
            />
          )}
        />
      ) : (
        <BodyOne
          sx={{
            color: disabled
              ? 'text.disabled'
              : isCurrent()
              ? 'info.main'
              : 'text.secondary',
          }}
          fontWeight='medium'
        >
          {step.data.title.toPersian() || 'بدون عنوان'}
        </BodyOne>
      )}
    </Box>
  );
};

const areEqual = (
  prevProps: Readonly<StepButtonProps>,
  nextProps: Readonly<StepButtonProps>
): boolean => {
  return (
    prevProps.step.id === nextProps.step.id &&
    prevProps.step.data.title === nextProps.step.data.title &&
    prevProps.step.data.sortIndex === nextProps.step.data.sortIndex &&
    prevProps.step.data.formId === nextProps.step.data.formId
  );
};

export default memo(StepButton, areEqual);

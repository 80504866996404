import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternMenuItem from 'features/appBuilder/patterns/core/components/shared/items/Menu';

// Custom Types
export interface HeaderPatternBrandItemProps {}
type CollapsedKey = 'section' | 'one' | 'two';

const HeaderPatternMenuSection: FC<HeaderPatternBrandItemProps> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    one: false,
    two: false,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='منوها'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='menu'
    >
      <PatternMenuItem
        reference='menu.one'
        title='منو اول'
        collapsed={collapsed.one}
        onCollapsedChange={handleCollapseChanged('one')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'color',
          'bgColor',
          'hoverColor',
          'bgHoverColor',
          'borderRadius',
        ]}
      />
      <PatternMenuItem
        reference='menu.two'
        title='منو دوم'
        collapsed={collapsed.two}
        onCollapsedChange={handleCollapseChanged('two')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'color',
          'bgColor',
          'hoverColor',
          'bgHoverColor',
          'borderRadius',
        ]}
      />
    </PatternSectionContainer>
  );
};

export default HeaderPatternMenuSection;

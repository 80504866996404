import * as React from 'react';

// Core Components
import Button from 'core/components/base/inputs/Button';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';

// Custom Types
import type { FormDetailsInputSummaryProps } from 'features/form/forms/types/item/input';

interface InputButtonProps {
  disabled: boolean;
  onClick: (inputId: string) => void;
  input: FormDetailsInputSummaryProps;
  value: string[];
}

const EvaluationFieldAccessInputButton: React.FC<InputButtonProps> = (
  props
) => {
  // Props
  const { disabled, input, value, onClick } = props;

  // Render
  return (
    <Button
      disabled={disabled}
      variant='contained'
      onClick={() => onClick(input.id)}
      sx={{
        fontWeight: 400,
        fontSize: '14px',
        color: value.includes(input.id) ? 'text.primary' : 'text.secondary',
        backgroundColor: value.includes(input.id) ? '#9ED5FF' : '#C1C8D3',
        ':hover': {
          backgroundColor: value.includes(input.id) ? '#9ED5FF' : '#C1C8D3',
        },
        height: '1.5rem',
        whiteSpace: 'nowrap',
        flexShrink: 0,
      }}
    >
      {input.data.label}
    </Button>
  );
};

export default React.memo(EvaluationFieldAccessInputButton, isSame);

import * as React from 'react';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';

// Custom Icons
import CloseIcon from 'core/components/icons/Close';
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';

// Custom Types
interface CancelButtonProps {
  onCancel: () => void;
}

const CancelButton: React.FC<CancelButtonProps> = (props) => {
  // Props
  const { onCancel } = props;

  // Context
  const loading = useSelectLoading();

  // Render
  return (
    <Button
      onClick={onCancel}
      disabled={loading}
      startIcon={<CloseIcon />}
      variant='outlined'
      color='error'
      sx={{
        borderRadius: '.5rem',
      }}
    >
      انصراف
    </Button>
  );
};

export default React.memo(
  CancelButton,
  (prev, next) => prev.onCancel.toString() === next.onCancel.toString()
);

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface DownloadFileIconProps extends SvgIconProps {}

const DownloadFileIcon: React.FC<DownloadFileIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M18 2h-8L4 8v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2m-6 15-4-4h3V9.02L13 9v4h3z' />{' '}
  </IconBuilder>
);

export default DownloadFileIcon;

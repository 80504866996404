// Feature Types
import type { ContentPatternButtonProps } from 'features/appBuilder/patterns/features/content/types/button';

export const contentPatterButtonDefaults: ContentPatternButtonProps = {
  isActive: true,
  tabs: {
    borderRadius: 8,
    isActive: true,
    padding: '0px',
    fontSize: '14',
    textAlign: 'start',
    textDirection: 'rtl',
    fontColor: '#FFFFFF',
    bgColor: '#59666D',
    fontHoverColor: '#FFFFFF',
    bgHoverColor: '#12252E',
    activeTabColor: '#FFFFFF',
    activeTabBgColor: '#12252E',
  },
  archive: {
    isActive: true,
    padding: '8px 16px',
    fontSize: '14',
    textDirection: 'rtl',
    textAlign: 'end',
    fontColor: '#FFFFFF',
    borderRadius: 8,
    bgColor: '#12252E',
    fontHoverColor: '#FFFFFF',
    bgHoverColor: '#12252E',
  },
};

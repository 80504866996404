import cloneDeep from 'lodash/cloneDeep';

// Feature Utilities
import { formPatterConfigDefaults } from 'features/appBuilder/patterns/features/form/utilities/defaults/config';
import { bodyPatterConfigDefaults } from 'features/appBuilder/patterns/features/form/utilities/defaults/body';
import { formPatterButtonDefaults } from 'features/appBuilder/patterns/features/form/utilities/defaults/button';
import { formPatterErrorDefaults } from 'features/appBuilder/patterns/features/form/utilities/defaults/error';
import { formPatterAlertDefaults } from 'features/appBuilder/patterns/features/form/utilities/defaults/alert';

// Custom Types
import type { FormPatternDataProps } from 'features/appBuilder/patterns/features/form/types';

const formPatternDataDefaults: FormPatternDataProps = {
  patternTitle: '',
  bgColorOne: '#F5F5F5',
  bgColorTwo: '#F5F5F5',
  dividerColor: '#C1C8D3',
  bgImage: null,
  bgImageType: 'cover',
  padding: '32px',
  cardBg: '#FFFFFF',
  cardCount: 20,
  creator: null,
  isActive: true,
  maxWidth: 'lg',
  sortIndex: 0,
  type: 'form',
  variant: 0,
  config: formPatterConfigDefaults,
  body: bodyPatterConfigDefaults,
  button: formPatterButtonDefaults,
  error: formPatterErrorDefaults,
  alert: formPatterAlertDefaults,
};

/**
 * Retrieves the default form pattern data.
 *
 * @returns {FormPatternDataProps} The default form pattern data.
 */
export const getFormPatternDataDefault = () => {
  const cloned = cloneDeep(formPatternDataDefaults);

  return cloned;
};

import {
  DialogContent as MuiDialogContent,
  DialogContentProps as MuiDialogContentProps,
  styled,
} from '@mui/material';

export interface DialogContentProps extends MuiDialogContentProps {}

const DialogContent = styled(MuiDialogContent)<DialogContentProps>(() => ({}));

export default DialogContent;

// Custom Page Components
import Menus from 'features/menu/menus/components/list';
import AddMenu from 'features/menu/menus/components/details/Add';
import EditMenu from 'features/menu/menus/components/details/Edit';

// Custom Utilities
import { getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'menu';
const subFeature: SubFeatureType = 'menus';
const menusRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/menus',
    Component: Menus,
    feature,
    subFeature,
  },
  {
    path: '/menus/add',
    Component: AddMenu,
    feature,
    subFeature,
  },
  {
    path: '/menus/:id',
    Component: EditMenu,
    feature,
    subFeature,
  },
];

export default menusRoutes;

// Types
import type { FC } from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface FormatAlignCenterProps extends SvgIconProps {}

const FormatAlignCenter: FC<FormatAlignCenterProps> = (props) => (
  <IconBuilder {...props}>
    <g>
      <path d='M21.7,8.3H2.3C2.1,8.3,2,8.2,2,8V5.5c0-0.2,0.1-0.3,0.3-0.3h19.4c0.2,0,0.3,0.2,0.3,0.3V8C22,8.2,21.9,8.3,21.7,8.3z' />
      <path
        d='M21,13.6H3.5c-0.2,0-0.3-0.2-0.3-0.3v-2.6c0-0.2,0.1-0.3,0.3-0.3H21c0.2,0,0.3,0.2,0.3,0.3v2.6
		C21.3,13.4,21.1,13.6,21,13.6z'
      />
      <path d='M17,18.8H7.3c-0.2,0-0.3-0.2-0.3-0.3V16c0-0.2,0.1-0.3,0.3-0.3H17c0.2,0,0.3,0.2,0.3,0.3v2.6C17.3,18.7,17.1,18.8,17,18.8z' />
    </g>
  </IconBuilder>
);

export default FormatAlignCenter;

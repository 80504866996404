import { type FC } from 'react';

// Custom Core Components
import Box from 'core/components/base/layout/Box';

// Custom Common Components
import CloseIconButton from 'core/components/shared/IconButton/Close';

// Custom Icon Components
import InsertSheetColIcon from 'core/components/icons/InsertSheetCol';

// Custom Hooks
import { useAppDispatch } from 'core/hooks/redux';

// Custom Utilities
import { resetCells } from 'features/appBuilder/patterns/features/table/store';

// Custom Types
import type { TableCellRefProps } from 'features/appBuilder/patterns/features/table/types/cell';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
export interface RefCellBadgeProps {
  cell: TableCellRefProps;
}

const RefCellBadge: FC<RefCellBadgeProps> = (props) => {
  // Props
  const { cell } = props;

  // Hooks
  const dispatch = useAppDispatch();

  // Utilities
  const handleResetCell = () => {
    dispatch(resetCells([cell]));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        borderRadius: '0.25rem',
        bgcolor: 'background.default',
        overflow: 'hidden',

        p: '0.25rem',
      }}
    >
      <InsertSheetColIcon fontSize='small' />
      <BodyTwo sx={{ whiteSpace: 'nowrap' }}>{cell.columnKey}</BodyTwo>
      <CloseIconButton
        onClick={(e) => {
          e.stopPropagation();
          handleResetCell();
        }}
        size='small'
        color='inherit'
        style={{ marginRight: 'auto' }}
      />
    </Box>
  );
};

export default RefCellBadge;

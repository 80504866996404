import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
  styled,
} from '@mui/material';

export interface TypographyProps extends MuiTypographyProps {
  component?: React.ElementType;
}

const Typography = styled(MuiTypography)<TypographyProps>(() => ({
  wordBreak: 'break-word',
}));

export default Typography;

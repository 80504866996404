import * as Yup from 'yup';

// Schemas
import { introduction } from 'core/utilities/validationSchema/common/Schemas';

const SettingsValidationSchema = Yup.object().shape({
  description: introduction.nullable(),
});

export default SettingsValidationSchema;

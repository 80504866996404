import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface ChevronLeftIconProps extends SvgIconProps {}

const ChevronLeftIcon: React.FC<ChevronLeftIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12z' />
  </IconBuilder>
);

export default ChevronLeftIcon;

// Custom Utilities
import { initialFile } from 'features/file/files/utilities/file';
import { transformAudienceGroupsSummary } from 'features/audience/groups/utilities';

// Custom Types
import type { ApiAudienceProps } from 'features/audience/audiences/types/api';
import type { AudienceProps } from 'features/audience/audiences/types';
import type { AudienceDependantProps } from 'features/audience/audiences/types/index';
import type { ApiAudienceDependantsProps } from 'features/audience/audiences/types/api/index';

/**
 * Transforms an API audience object to an audience object.
 *
 * @param {ApiAudienceProps} apiAudience - The API audience object.
 * @returns {AudienceProps} The transformed audience object.
 */
export const transformAudience = (
  apiAudience: ApiAudienceProps
): AudienceProps => ({
  id: apiAudience._id,
  data: {
    // ! Transformer is needed
    avatar: apiAudience.data.avatar ? apiAudience.data.avatar : initialFile,
    biography: apiAudience.data.biography || '',
    birthDate: apiAudience.data.birthDate || '',
    createdAt: new Date().toISOString(),
    dependants: apiAudience.data.dependants
      ? transformDependants(apiAudience.data.dependants)
      : [],
    email: apiAudience.data.email || '',
    firstName: apiAudience.data.firstName || '',
    groups: transformAudienceGroupsSummary(apiAudience.data.groups),
    isEnabled: apiAudience.data.isEnabled || false,
    jobTitle: apiAudience.data.jobTitle || '',
    lastLogin: [],
    lastName: apiAudience.data.lastName || '',
    nationalId: apiAudience.data.nationalId || '',
    personalId: apiAudience.data.personelId || '',
    phoneNumber: apiAudience.data.phoneNumber,
    accessId: apiAudience.data.accessId || '',
    creator: apiAudience.data.creator,
  },
});

/**
 * Transforms an array of API audience objects to an array of audience objects.
 *
 * @param {ApiAudienceProps[]} apiAudiences - The array of API audience objects.
 * @returns {AudienceProps[]} The transformed array of audience objects.
 */
export const transformAudiences = (
  apiAudiences: ApiAudienceProps[]
): AudienceProps[] => {
  const audiences: AudienceProps[] = [];
  apiAudiences.forEach((apiAudience) =>
    audiences.push(transformAudience(apiAudience))
  );
  return audiences;
};

/**
 * Transforms an array of API audience dependants objects to an array of audience dependant objects.
 *
 * @param {ApiAudienceDependantsProps[]} dependants - The array of API audience dependants objects.
 * @returns {AudienceDependantProps[]} The transformed array of audience dependant objects.
 */
export const transformDependants = (
  dependants: ApiAudienceDependantsProps[]
): AudienceDependantProps[] => {
  const transformedDependant: AudienceDependantProps[] = [];
  dependants.forEach((dependant: ApiAudienceDependantsProps) => {
    transformedDependant.push({
      id: '',
      data: dependant,
    });
  });
  return transformedDependant;
};

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Custom Common Components
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import TextFieldOutlined from 'core/components/shared/TextField/Outlined';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
interface AudienceGroupFormPageProps {}

const AudienceGroupForm: React.FC<AudienceGroupFormPageProps> = (props) => {
  // Hooks
  const { control } = useFormContext();
  const loading = useSelectLoading();

  // Render
  return (
    <RoundPaper sx={{ padding: '3rem' }}>
      <ColumnStack spacing={4} sx={{ width: '27rem', mx: 'auto' }}>
        <ColumnStack spacing={0}>
          <InputLabel>عنوان گروه</InputLabel>
          <Controller
            control={control}
            name='title'
            render={({ field }) => (
              <TextFieldOutlined
                disabled={loading}
                placeholder='رفاه'
                {...field}
              />
            )}
          />
        </ColumnStack>
      </ColumnStack>
    </RoundPaper>
  );
};

export default AudienceGroupForm;

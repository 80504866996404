import { alpha } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import type { SubmitHandler } from 'react-hook-form';

// Custom Common Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import ImageUploader from 'core/components/shared/Input/Avatar';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import RowStack from 'core/components/shared/Stack/RowStack';
import SubmitLoadingButton from 'core/components/shared/LoadingButton/Submit';
import TextFieldOutlined from 'core/components/shared/TextField/Outlined';
import MultiAutocomplete from 'core/components/shared/Autocomplete/api/MultipleAutocomplete';

// Custom Core Components
import Checkbox from 'core/components/base/inputs/Checkbox';
import FormControlLabel from 'core/components/base/inputs/FormControlLabel';
import TextField from 'core/components/base/inputs/TextField';

// Custom Hooks
import useYupValidationResolver from 'core/hooks/common/useYupResolver';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import {
  useEditTicketTopicMutation,
  useTicketTopicDoc,
} from 'features/ticket/topics/hooks';

// Custom Utilities
import { audiencesQueryKey } from 'features/audience/audiences/hooks';
import { getErrorAndHelperText } from 'core/utilities/helper/helperPack';
import { ticketTopicSchema } from 'features/ticket/topics/validations';
import { audienceGroupsQueryKey } from 'features/audience/groups/hooks';
import { getAudiencesSummaryList } from 'features/audience/audiences/utilities/api';
import { getAudienceGroupSummaryList } from 'features/audience/groups/utilities/api';

// Custom Types
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';
import type { AudienceSummaryProps } from 'features/audience/audiences/types';
import type { TicketTopicDataProps } from 'features/ticket/topics/types';

interface EditTopicProps extends WithAuthProps {}

const EditTopic: React.FC<EditTopicProps> = (props) => {
  // Context
  const loading = useSelectLoading();

  // Hooks
  const { id: topicId } = useParams();
  const resolver = useYupValidationResolver(ticketTopicSchema);
  const { control, setValue, handleSubmit, reset } =
    useForm<TicketTopicDataProps>({
      mode: 'onTouched',
      resolver,
    });

  const topicMethods = useTicketTopicDoc({
    onFetch: (doc) => reset({ ...doc.data }),
  });

  const { mutate: handleEditTicketTopic } = useEditTicketTopicMutation({
    autoAlert: { mode: 'edit', name: 'موضوع' },
    stayOnLoadingAfterMutate: true,
    redirectAfterSuccessTo: '/tickets/topics',
  });

  // Utilities
  const handleFormSubmit: SubmitHandler<TicketTopicDataProps> = async (
    data
  ) => {
    if (!topicId || typeof topicId !== 'string') return;
    handleEditTicketTopic({ id: topicId, data });
  };

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='ویرایش موضوع تیکت' />
      <PageHeroBox>
        <HeadingSix>ویرایش موضوع</HeadingSix>
        <SubmitLoadingButton
          disabled={loading}
          onClick={handleSubmit(handleFormSubmit)}
        >
          ذخیره
        </SubmitLoadingButton>
      </PageHeroBox>
      <ColumnStack spacing={2}>
        <ColumnStack p='1rem 2rem'>
          <RowStack>
            <ColumnStack spacing={0} width='48%'>
              <InputLabel required>عنوان موضوع</InputLabel>
              <Controller
                control={control}
                name='title'
                defaultValue=''
                render={({ field, fieldState }) => (
                  <TextField
                    disabled={loading}
                    fullWidth={false}
                    placeholder='عنوان'
                    sx={{ width: '70%' }}
                    {...getErrorAndHelperText(fieldState)}
                    {...field}
                  />
                )}
              />
            </ColumnStack>
            <ColumnStack spacing={0} width='48%'>
              <InputLabel>
                مدت زمان (روز) منقضی شدن تیکت پس از آخرین پاسخ
              </InputLabel>
              <Controller
                control={control}
                name='expireAfter'
                defaultValue={7}
                render={({ field }) => (
                  <TextFieldOutlined
                    disabled={loading}
                    placeholder='تعداد روز'
                    type='number'
                    sx={{ width: '70%' }}
                    {...field}
                  />
                )}
              />
            </ColumnStack>
          </RowStack>
          <RowStack>
            <ColumnStack spacing={0} width='48%'>
              <InputLabel required>گروه پرسنل</InputLabel>
              <Controller
                control={control}
                name='personnel.groupIds'
                render={({ field, fieldState }) => (
                  <MultiAutocomplete
                    title='گروه مخاطبان'
                    queryKey={audienceGroupsQueryKey}
                    apiHandler={getAudienceGroupSummaryList}
                    defaultValue={field.value}
                    sx={{ flexGrow: 1, width: '70%' }}
                    onChange={(selecteds) => field.onChange(selecteds)}
                    {...getErrorAndHelperText(fieldState)}
                  />
                )}
              />
            </ColumnStack>
            <ColumnStack spacing={0} width='48%'>
              <InputLabel>تعداد تیکت‌های باز برای یک پرسنل</InputLabel>
              <Controller
                control={control}
                name='personnel.limit'
                defaultValue={1}
                render={({ field }) => (
                  <TextFieldOutlined
                    disabled={loading}
                    placeholder='تعداد'
                    type='number'
                    sx={{ width: '70%' }}
                    {...field}
                  />
                )}
              />
            </ColumnStack>
          </RowStack>
        </ColumnStack>
        <ColumnStack
          p='1.5rem 2rem'
          sx={({ palette }) => ({
            backgroundColor: alpha(palette.info.main, 0.4),
          })}
        >
          <RowStack>
            <ColumnStack spacing={0} width='48%'>
              <InputLabel required>عنوان دپارتمان</InputLabel>
              <Controller
                control={control}
                name='department.title'
                defaultValue=''
                render={({ field, fieldState }) => (
                  <TextFieldOutlined
                    disabled={loading}
                    fullWidth={false}
                    placeholder='عنوان دپارتمان'
                    sx={{ width: '70%' }}
                    {...getErrorAndHelperText(fieldState)}
                    {...field}
                  />
                )}
              />
            </ColumnStack>
            <ColumnStack spacing={0} width='48%'>
              <InputLabel required>دپارتمان</InputLabel>
              <Controller
                control={control}
                name='department.audienceIds'
                render={({ field, fieldState }) => (
                  <MultiAutocomplete
                    title='مخاطبان'
                    getOptionLabel={(item: AudienceSummaryProps) =>
                      item.data.fullName || 'بدون نام'
                    }
                    apiHandler={getAudiencesSummaryList}
                    queryKey={audiencesQueryKey}
                    defaultValue={field.value}
                    onChange={(audiences) => field.onChange(audiences)}
                    disabled={loading}
                    sx={{ width: '70%' }}
                    {...getErrorAndHelperText(fieldState)}
                  />
                )}
              />
            </ColumnStack>
          </RowStack>
          <RowStack>
            <ColumnStack spacing={0} width='48%'>
              <InputLabel>آواتار دپارتمان</InputLabel>
              <Controller
                control={control}
                name='department.logo'
                defaultValue={null}
                render={({ field }) => (
                  <ImageUploader
                    location='ticket'
                    disabled={loading}
                    height='10rem'
                    width='10rem'
                    onImageFileChange={(file) =>
                      setValue('department.logo', file || null)
                    }
                    imageFile={field.value?.id ? field.value : undefined}
                  />
                )}
              />
            </ColumnStack>
            <ColumnStack width='48%'>
              <ColumnStack spacing={0}></ColumnStack>
              <ColumnStack spacing={0}>
                <InputLabel>معرفی دپارتمان</InputLabel>
                <Controller
                  control={control}
                  name='department.description'
                  defaultValue=''
                  render={({ field }) => (
                    <TextFieldOutlined
                      disabled={loading}
                      placeholder='معرفی کوتاه دپارتمان'
                      type='string'
                      sx={{ width: '70%' }}
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
            </ColumnStack>
          </RowStack>
        </ColumnStack>
        <ColumnStack p='1.5rem 2rem'>
          <RowStack>
            <ColumnStack spacing={0} width='48%'>
              <InputLabel>مشترکین</InputLabel>
              <Controller
                control={control}
                name='subscribers.audienceIds'
                render={({ field, fieldState }) => (
                  <MultiAutocomplete
                    title='مخاطبان'
                    apiHandler={getAudiencesSummaryList}
                    queryKey={audiencesQueryKey}
                    defaultValue={field.value}
                    onChange={(audiences) => field.onChange(audiences)}
                    disabled={loading}
                    sx={{ width: '70%' }}
                    getOptionLabel={(item: AudienceSummaryProps) =>
                      item.data.fullName || 'بدون نام'
                    }
                    {...getErrorAndHelperText(fieldState)}
                  />
                )}
              />
            </ColumnStack>
          </RowStack>
          <RowStack>
            <ColumnStack spacing={0} width='48%'>
              <Controller
                control={control}
                name='subscribers.availableOpts.openAndClose'
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ width: 'fit-content' }}
                    label='امکان باز و بسته شدن تیکت توسط مشترکین'
                    control={
                      <Checkbox
                        checked={field.value}
                        disabled={loading}
                        color='info'
                        size='small'
                        onChange={(e, checked) =>
                          setValue(
                            'subscribers.availableOpts.openAndClose',
                            checked
                          )
                        }
                      />
                    }
                  />
                )}
              />
            </ColumnStack>
            <ColumnStack spacing={0} width='48%'>
              <Controller
                control={control}
                name='subscribers.availableOpts.replyToCreator'
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ width: 'fit-content' }}
                    label='امکان پاسخ به پرسنل توسط مشترکین'
                    control={
                      <Checkbox
                        checked={field.value}
                        disabled={loading}
                        color='info'
                        size='small'
                        onChange={(e, checked) =>
                          setValue(
                            'subscribers.availableOpts.replyToCreator',
                            checked
                          )
                        }
                      />
                    }
                  />
                )}
              />
            </ColumnStack>
          </RowStack>
          <RowStack>
            <ColumnStack spacing={0} width='48%'>
              <Controller
                control={control}
                name='subscribers.availableOpts.mention'
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ width: 'fit-content' }}
                    label={
                      <>
                        <BodyOne>امکان منشن کردن مشترکین توسط مشترکین</BodyOne>
                        <BodyTwo>
                          (مشترکین به صورت پیش‌فرض می‌توانند پرسنل و اعضای
                          دپارتمان را منشن کنند.)
                        </BodyTwo>
                      </>
                    }
                    control={
                      <Checkbox
                        checked={field.value}
                        disabled={loading}
                        color='info'
                        size='small'
                        onChange={(e, checked) =>
                          setValue('subscribers.availableOpts.mention', checked)
                        }
                      />
                    }
                  />
                )}
              />
            </ColumnStack>
            <ColumnStack spacing={0} width='48%'>
              <Controller
                control={control}
                name='subscribers.availableOpts.replyFromCreator'
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ width: 'fit-content' }}
                    label={
                      <>
                        <BodyOne>امکان فعال کردن دریافت پاسخ پرسنل</BodyOne>
                        <BodyTwo>(دریافت یک پاسخ جدید از سوی پرسنل.)</BodyTwo>
                      </>
                    }
                    control={
                      <Checkbox
                        checked={field.value}
                        disabled={loading}
                        color='info'
                        size='small'
                        onChange={(e, checked) =>
                          setValue(
                            'subscribers.availableOpts.replyFromCreator',
                            checked
                          )
                        }
                      />
                    }
                  />
                )}
              />
            </ColumnStack>
          </RowStack>
        </ColumnStack>
      </ColumnStack>
    </>
  );
};

export default EditTopic;

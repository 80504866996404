import * as React from 'react';
import store from 'core/store';
import { useFormContext, useWatch } from 'react-hook-form';

// Custom Types
import useBoolean from 'core/hooks/useBoolean';

// Custom Core Components
import MenuItem from 'core/components/base/navigation/MenuItem';
import TextField from 'core/components/base/inputs/TextField';
import Autocomplete from 'core/components/base/inputs/Autocomplete';

// Custom Icons
import ArrowDropDownIcon from 'core/components/icons/ArrowDropDown';

// Context
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';
import { useMenuItemContext } from 'core/store/context/feature/menu/MenuItem';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { findAndDelete, findIndexById } from 'core/utilities/helper';
import {
  getContainer,
  isOptSelectable,
} from 'features/menu/menus/utilities/helper';

// Custom Types
import type { MenuItemFormProps } from '.';
import type { MenuItemProps } from 'features/menu/menus/types';

interface SubMenuSelectProps {
  mode: 'preview' | 'edit' | null;
  onToggleMode: (mode: 'preview' | 'edit') => void;
  childrens: MenuItemProps[];
}

const SubMenuSelect: React.FC<SubMenuSelectProps> = (props) => {
  // Props
  const { childrens, mode } = props;

  // States
  const isProccessing = useBoolean(true);

  // Context
  const menu = useMenuItemContext();
  const loading = useSelectLoading();
  const { control, getValues, setValue } = useFormContext<MenuItemFormProps>();

  const selectableOpts = useWatch({
    control,
    name: 'selectableOpts',
    exact: true,
    defaultValue: [],
  });
  const selectedOpts = useWatch({
    control,
    name: 'selectedOpts',
    exact: true,
    defaultValue: [],
  });

  // Hooks

  React.useEffect(() => {
    if (mode === 'edit' && menu.data.hasSubItems) {
      const items = getContainer()?.data.items || [];
      const selecteds = items.filter(
        (item) => item.data.parentIds.at(-1) === menu.id
      );
      setValue('selectedOpts', selecteds);
    }
  }, [mode, menu.data.hasSubItems]);

  // Utilities
  const handleSelectOpt = (opt: MenuItemProps) => {
    const selectedOpts = getValues('selectedOpts') || [];
    const isSelected = findIndexById(selectedOpts, opt.id) > -1;

    // Unselect the selected children.
    if (isSelected && findIndexById(childrens, opt.id) > -1) {
      setValue('unselectedOpts', [
        ...getValues('unselectedOpts').filter((item) => item.id !== opt.id),
        opt,
      ]);
      setValue('selectedOpts', findAndDelete(selectedOpts, opt.id));
    } else if (isSelected)
      // Unselect the selected options. It means user select it but didn't save it,so its not a child yet.
      setValue('selectedOpts', findAndDelete(selectedOpts, opt.id));
    else setValue('selectedOpts', [...selectedOpts, opt]);
  };

  const getSelectableOptions = () => {
    isProccessing.setTrue();
    const items = store.getState().menu.current?.data.items || [];
    setValue(
      'selectableOpts',
      items.filter((item) => isOptSelectable(item, menu))
    );
    isProccessing.setFalse();
  };

  // Render
  return (
    <Autocomplete
      value={selectedOpts}
      options={isProccessing.state ? [] : selectableOpts}
      disabled={loading}
      loading={isProccessing.state}
      loadingText='در حال پردازش اطلاعات'
      limitTags={4}
      closeText='بستن'
      multiple
      disableClearable
      popupIcon={
        <ArrowDropDownIcon
          sx={{
            color: loading ? 'text.disabled' : 'info.main',
          }}
        />
      }
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.id}>
          {option.data.title}
        </MenuItem>
      )}
      filterSelectedOptions
      onOpen={getSelectableOptions}
      getOptionLabel={(option) => option.data.title}
      onChange={(e, value, reason, details) =>
        handleSelectOpt(details?.option as MenuItemProps)
      }
      noOptionsText='منویی یافت نشد.'
      isOptionEqualToValue={(opt, selected) => opt.id === selected.id}
      renderInput={(params) => <TextField placeholder='زیرمنو' {...params} />}
    />
  );
};

export default React.memo(SubMenuSelect, (prev, next) =>
  isSame([prev.childrens, prev.mode], [next.childrens, next.mode])
);

import * as React from 'react';

// Types
import type { SxProps, Theme } from '@mui/material';

// Common Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Core Components
import CircularProgress from 'core/components/base/feedback/CircularProgress';
import Autocomplete from 'core/components/base/inputs/Autocomplete';
import TextField from 'core/components/base/inputs/TextField';

// Custom Icon Components
import ArrowDropDownIcon from 'core/components/icons/ArrowDropDown';

// Custom Utilities
import { deprecatedGetAudienceGroupsList } from 'features/audience/groups/utilities/api';
import { setAppAlert } from 'core/utilities/helper';

// Custom Types
import type { TextFieldProps } from 'core/components/base/inputs/TextField';
import type { AudienceGroupSummaryProps } from 'features/audience/groups/types';

interface AudienceGroupsAutocompleteProps {
  defaultValues?: string[];
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  textFieldProps?: TextFieldProps;
  fieldName?: string;
  sx?: SxProps<Theme>;
  onBlur?: () => void;
  onChange?: (groups: AudienceGroupSummaryProps[]) => void;
}

const AudienceGroupsAutocomplete: React.FC<AudienceGroupsAutocompleteProps> = (
  props
) => {
  // Props
  const {
    defaultValues,
    disabled,
    error,
    helperText,
    textFieldProps,
    fieldName,
    sx,
    onBlur,
    onChange,
  } = props;

  // States
  const [groups, setGroups] = React.useState<AudienceGroupSummaryProps[]>([]);
  const [values, setValues] = React.useState<AudienceGroupSummaryProps[]>([]);
  const [fetching, setFetching] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const loading = fetching || (open && groups.length === 0);

  // useEffects
  const handleDefaultsChanged = React.useCallback(async () => {
    let audienceGroupsList = groups;

    if (groups.length === 0) {
      audienceGroupsList = await setAudienceGroupsSummaryList();
    }

    const valuesToSet: AudienceGroupSummaryProps[] = [];

    if (defaultValues && defaultValues.length > 0) {
      defaultValues.forEach((dv) => {
        const defaultValue = audienceGroupsList.find((v) => v.id === dv);

        if (defaultValue) {
          valuesToSet.push(defaultValue);
        }
      });
    }

    setValues(valuesToSet);
  }, [defaultValues, groups]);

  React.useEffect(() => {
    handleDefaultsChanged();
  }, [handleDefaultsChanged]);

  React.useEffect(() => {
    if (open) setFetching(true);
  }, [open]);

  React.useEffect(() => {
    if (loading) setAudienceGroupsSummaryList();
  }, [loading]);

  // Utilities
  const setAudienceGroupsSummaryList = async () => {
    setFetching(true);

    const { status, audienceGroupsList } =
      await deprecatedGetAudienceGroupsList(false);

    if (status === 200 && audienceGroupsList.length === 0) {
      setAppAlert('تاکنون گروهی برای مخاطبان ایجاد نشده است.', 'warning');

      setGroups([]);
      setValues([]);
      setOpen(false);
    } else if (status === 200 && audienceGroupsList) {
      setGroups(audienceGroupsList);
    } else {
      setAppAlert('خطا در دریافت گروه‌های مخاطبان.');
      setOpen(false);
    }

    setFetching(false);
    return audienceGroupsList;
  };

  return (
    <>
      <Autocomplete
        closeText='بستن'
        disabled={props.disabled}
        filterSelectedOptions
        getOptionLabel={(option) => option.data.title}
        isOptionEqualToValue={(o, v) => o.id === v.id}
        limitTags={4}
        loading={fetching}
        popupIcon={
          <ArrowDropDownIcon
            sx={{
              color: props.disabled
                ? 'text.disabled'
                : props.error
                ? 'error.main'
                : 'info.main',
            }}
          />
        }
        loadingText='در حال دریافت گروه‌های مخاطبان...'
        multiple
        noOptionsText='گروهی یافت نشد.'
        onBlur={() => onBlur && onBlur()}
        onChange={(e, props) => {
          onChange && onChange(props);
          setValues(props);
        }}
        onClose={() => setOpen(false)}
        onOpen={() => {
          if (!disabled) setOpen(true);
        }}
        open={open}
        openText='مشاهده گروه‌ها'
        options={groups}
        sx={{ flexGrow: 1, minWidth: '10rem', ...sx }}
        value={values}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='انتخاب گروه'
            error={error}
            name={fieldName}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            {...textFieldProps}
          />
        )}
      />
      {helperText && (
        <BodyTwo variant='caption' color='error.main'>
          {helperText}
        </BodyTwo>
      )}
    </>
  );
};

export default AudienceGroupsAutocomplete;

import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import BodyOne from 'core/components/shared/Typography/BodyOne';
import RowStack from 'core/components/shared/Stack/RowStack';
import Box from 'core/components/base/layout/Box';
import Button from 'core/components/base/inputs/Button';

// Core Utilities
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';
import { forLoop } from 'core/utilities/helper';

// Feature Utilities
import EvaluationFieldAccessInputButton from 'features/form/processes/components/details/Evaluation/Config/EvaluationFieldAccesses/InputButton';
import { evaluationProfileAccessItems } from 'features/form/processes/utilities/helper/evaluation';
import { updateProcessFocus } from 'features/form/processes/store/actions';
import { useFormDetailsSummaryContext } from 'features/form/forms/contexts/FormDetailsSummary';

// Feature Types
import type { EvaluatorProfileFiledAccessType } from 'features/form/processes/types/details/common/itemAccess';
import type { ProcessProps } from 'features/form/processes/types/details';
import type { FormDetailsSummaryProps } from 'features/form/forms/types/item';

// Custom Types
export interface EvaluationFieldAccessesProps {
  index: number;
  disabled?: boolean;
}

const EvaluationFieldAccesses: FC<EvaluationFieldAccessesProps> = (props) => {
  // Props
  const { index, disabled = false } = props;

  // Context
  const formDetails = useFormDetailsSummaryContext() as FormDetailsSummaryProps;
  const { control, getValues, setValue } = useFormContext<ProcessProps>();
  const evaluationId = getValues(`data.items.${index}.id`);

  // Helpers
  const getSectionInputIds = (sectionId: string): string[] =>
    formDetails.data.inputs
      .filter((input) => input.data.sectionId === sectionId)
      .map((input) => input.id);

  const isSectionSelected = (sectionId: string): -1 | 0 | 1 => {
    const inputsIds = getSectionInputIds(sectionId);

    let selectionStatus: number = -1;
    const selectedFields =
      getValues(`data.items.${index}.data.formFieldsIds`) || [];

    forLoop(inputsIds, (inpId) => {
      if (selectedFields.includes(inpId)) {
        selectionStatus = 0;
      }
    });

    if (
      selectionStatus === 0 &&
      inputsIds.every((id) => selectedFields.includes(id))
    ) {
      selectionStatus = 1;
    }

    return selectionStatus as -1 | 0 | 1;
  };

  // Utilities
  const handleSectionClick = (sectionId: string) => {
    const formFields =
      getValues(`data.items.${index}.data.formFieldsIds`) || [];
    const inputsIds = getSectionInputIds(sectionId);
    const sectionSelectionStatus = isSectionSelected(sectionId);

    let updatedFormFields: string[] = [];

    if (sectionSelectionStatus === -1) {
      updatedFormFields = [...formFields, ...inputsIds];
    } else if (sectionSelectionStatus === 1) {
      updatedFormFields = formFields.filter(
        (fieldId) => !inputsIds.includes(fieldId)
      );
    } else {
      updatedFormFields = [...formFields];

      forLoop(inputsIds, (inpId) => {
        if (!updatedFormFields.includes(inpId)) {
          updatedFormFields.push(inpId);
        }
      });
    }

    setValue(`data.items.${index}.data.formFieldsIds`, updatedFormFields);
    updateProcessFocus(evaluationId);
  };

  const handleInputClick = (inputId: string) => {
    const fields = getValues(`data.items.${index}.data.formFieldsIds`) || [];

    const inputIndex = fields.indexOf(inputId);

    if (inputIndex > -1) {
      fields.splice(inputIndex, 1);
    } else {
      fields.push(inputId);
    }

    setValue(`data.items.${index}.data.formFieldsIds`, fields);
    updateProcessFocus(evaluationId);
  };

  const handleProfileItemClick = (
    profileItem: EvaluatorProfileFiledAccessType
  ) => {
    const fields = getValues(`data.items.${index}.data.profileFieldAccess`);

    const inputIndex = fields.indexOf(profileItem);

    if (inputIndex > -1) {
      fields.splice(inputIndex, 1);
    } else {
      fields.push(profileItem);
    }

    setValue(`data.items.${index}.data.profileFieldAccess`, fields);
    updateProcessFocus(evaluationId);
  };

  return (
    <ColumnStack>
      <BodyOne>فیلدهای قابل مشاهده فرم</BodyOne>
      <Controller
        control={control}
        name={`data.items.${index}.data.profileFieldAccess`}
        render={({ field }) => (
          <Box
            sx={{
              backgroundColor: 'background.default',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: 1,
              p: '1rem 0.5rem',
              borderRadius: '0.5rem',
            }}
          >
            <BodyOne sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
              اطلاعات پروفایل
            </BodyOne>
            <Box sx={{ overflow: 'hidden' }}>
              <RowStack
                spacing={1}
                sx={{
                  overflow: 'auto',
                  ml: 2,
                  pb: 0.5,
                  ...customRoundedScrollbarStyles,
                }}
              >
                {evaluationProfileAccessItems.map((profileItem) => (
                  <Button
                    key={profileItem.name + profileItem.label}
                    variant='contained'
                    disabled={disabled}
                    onClick={() => handleProfileItemClick(profileItem.name)}
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      color:
                        field.value && field.value.includes(profileItem.name)
                          ? 'text.primary'
                          : 'text.secondary',
                      backgroundColor:
                        field.value && field.value.includes(profileItem.name)
                          ? '#9ED5FF'
                          : '#C1C8D3',
                      ':hover': {
                        backgroundColor:
                          field.value && field.value.includes(profileItem.name)
                            ? '#9ED5FF'
                            : '#C1C8D3',
                      },
                      height: '1.5rem',
                      whiteSpace: 'nowrap',
                      flexShrink: 0,
                    }}
                  >
                    {profileItem.label}
                  </Button>
                ))}
              </RowStack>
            </Box>
          </Box>
        )}
      />
      <Controller
        control={control}
        name={`data.items.${index}.data.formFieldsIds`}
        render={({ field }) => (
          <ColumnStack spacing={1}>
            {formDetails.data.steps.map((step, stepIndex) => (
              <Box
                key={step.id}
                sx={{
                  backgroundColor: 'background.default',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: 1,
                  p: '1rem 0.5rem',
                  borderRadius: '0.5rem',
                }}
              >
                <BodyOne
                  disabled={disabled}
                  sx={{
                    my: '0.25rem',
                    width: '1rem',
                    height: '1rem',
                    borderRadius: '1rem',
                    color: 'white',
                    textAlign: 'center',
                    flexShrink: 0,
                    fontSize: '0.75rem',
                    verticalAlign: 'center',
                    backgroundColor: 'text.secondary',
                  }}
                >
                  {(stepIndex + 1).toPersian()}
                </BodyOne>
                <BodyOne sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
                  {step.data.title}
                </BodyOne>
                <ColumnStack sx={{ ml: '2rem', overflow: 'hidden' }}>
                  {formDetails.data.sections
                    .filter((section) => section.data.stepId === step.id)
                    .map((section) => {
                      const sectionSelectedStatus = isSectionSelected(
                        section.id
                      );

                      return (
                        <RowStack key={step.id + section.id}>
                          <Button
                            disabled={disabled}
                            variant={
                              sectionSelectedStatus === 1
                                ? 'contained'
                                : 'outlined'
                            }
                            sx={{
                              ':hover': {
                                backgroundColor:
                                  sectionSelectedStatus === 1
                                    ? '#9ED5FF'
                                    : 'transparent',
                              },
                              color:
                                sectionSelectedStatus === -1
                                  ? 'text.secondary'
                                  : sectionSelectedStatus === 1
                                  ? 'text.primary'
                                  : 'info.main',
                              backgroundColor:
                                sectionSelectedStatus === 1
                                  ? '#9ED5FF'
                                  : 'transparent',
                              height: '1.5rem',
                              whiteSpace: 'nowrap',
                              flexShrink: 0,
                            }}
                            onClick={() => handleSectionClick(section.id)}
                          >
                            {section.data.title}
                          </Button>
                          <RowStack
                            spacing={1}
                            sx={{
                              overflow: 'auto',
                              pb: 0.5,
                              ...customRoundedScrollbarStyles,
                            }}
                          >
                            {formDetails.data.inputs // TODO: We can memoize this filter inside a seperate component with useMemo. do it later.
                              .filter(
                                (input) =>
                                  input.data.stepId === step.id &&
                                  input.data.sectionId === section.id
                              )
                              .map((input) => (
                                <EvaluationFieldAccessInputButton
                                  key={`${input.data.stepId}-${input.data.sectionId}-${input.id}`}
                                  input={input}
                                  disabled={disabled}
                                  value={field.value}
                                  onClick={handleInputClick}
                                />
                              ))}
                          </RowStack>
                        </RowStack>
                      );
                    })}
                </ColumnStack>
              </Box>
            ))}
          </ColumnStack>
        )}
      />
    </ColumnStack>
  );
};

export default EvaluationFieldAccesses;

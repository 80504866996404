// Feature Types
import { PatternType } from 'features/appBuilder/patterns/core/types/item/index';

export const patternTypesArray: PatternType[] = [
  'header',
  'footer',
  'content',
  'stable',
  'profile',
  'slider',
  'contentDetails',
  'tagAndCategory',
  'search',
  'form',
  'table',
  'group',
];

export const patternTypesLabelMap: { [key in PatternType]: string } = {
  header: 'هدر',
  footer: 'فوتر',
  content: 'کانتنت',
  stable: 'استیبل',
  profile: 'پروفایل',
  slider: 'اسلایدر',
  contentDetails: 'تمپلیت کانتنت',
  tagAndCategory: 'موضوع و برچسب',
  search: 'جستجو',
  form: 'فرم',
  table: 'تیبل',
  group: 'گروه',
};

export const isPatternTypeValid = (type: string): boolean =>
  patternTypesArray.includes(type as PatternType);

export const getPatternTitle = (type: PatternType): string =>
  patternTypesLabelMap[type] || '-';

// Core Utilities
import { generateObjectId } from 'core/utilities/helper/id';

// Feature Types
import type { EvaluationProps } from '../../types/details/evaluation';
import type { EvaluatorItemAccessProps } from 'features/form/processes/types/details/common/itemAccess';

export const PROCESS_EVALUATION_DRAFT_FLAG = '-evaluation-draft';
export const PROCESS_EVALUATION_QUESTION_OPTION_DRAFT_FLAG =
  '-evaluation-question-option-draft';

/**
 * Generates the initial evaluation value with default properties.
 *
 * @param {Object} [props] - Optional properties to customize the evaluation value.
 * @param {EvaluatorItemAccessProps[]} [props.evaluatorItemAccesses] - An array of evaluator item access properties.
 *
 * @returns {EvaluationProps} The initial evaluation object with default values.
 */
export const genInitialEvaluationValue = (props?: {
  evaluatorItemAccesses: EvaluatorItemAccessProps[];
  formFieldsIds: string[];
}): EvaluationProps => ({
  id: `${generateObjectId()}${PROCESS_EVALUATION_DRAFT_FLAG}`,
  data: {
    processItemType: 'evaluation',
    overalStatus: 'pending',
    title: 'ارزیابی جدید',
    questionTitle: 'سوال ارزیابی',
    questionOptions: [
      {
        id: `${generateObjectId()}${PROCESS_EVALUATION_QUESTION_OPTION_DRAFT_FLAG}`,
        data: {
          label: 'گزینه اول',
          linkedId: '',
          linkedTitle: '',
          message: 'پیام پیشفرض گزینه اول',
          sortIndex: 0,
          status: 'end',
        },
      },
    ],
    selectedOptionId: '',

    isOwner: false,
    applicantFieldAccess: [
      'evaluationTitle',
      'evaluationQuestion',
      'selectedOption',
      'message',
      'evaluator',
      'comment',
      'date',
    ],
    profileFieldAccess: [
      'firstname',
      'lastname',
      'birthdate',
      'email',
      'personnelCode',
      'nationalId',
      'jobTitle',
      'audienceGroup',
      'biography',
      'avatar',
    ],
    audienceGroupId: '',
    audienceGroupTitle: '',

    isEvaluatorReviewEnabled: true,
    reviews: [],

    formFieldsIds: props?.formFieldsIds || [],
    evaluatorItemAccesses: props?.evaluatorItemAccesses || [],

    completionDate: '',
    evaluatorComment: '',
    evaluatorCommentFiles: [],
    evaluatorFullname: '',
    evaluatorId: '',
  },
});

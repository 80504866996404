import {
  InputBase as MuiInputBase,
  InputBaseProps as MuiInputBaseProps,
  styled,
} from '@mui/material';

export interface InputBaseProps extends MuiInputBaseProps {}

const InputBase = styled(MuiInputBase)<InputBaseProps>(() => ({}));

export default InputBase;

import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { Link } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

// Custom Section Components
import InfoSkeleton from 'features/file/files/components/details/InfoSkeleton';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Utilities
import { getLocaleDate, getLocaleTime } from 'core/utilities/helper/date';
import { useDocContext } from 'core/hooks/api/useDoc/Context';
import {
  convertBytes,
  getFileLocationLabel,
} from 'features/file/files/utilities/file';

// Custom Types
import type { FileDataProps, FileProps } from 'features/file/files/types';

interface FileInfo {}

const FileInfo: React.FC<FileInfo> = (props) => {
  // Context
  const { isFetched } = useDocContext<FileProps>();
  const { getValues } = useFormContext<FileDataProps>();
  const { user, ...file } = getValues();
  const { palette } = useTheme();

  const commonStyles = {
    sx: {
      width: 'fit-content',
      fontSize: '.975rem',
      color: palette.mode === 'dark' ? 'text.primary' : 'rgba(89, 102, 109, 1)',
      fontWeight: 400,
    },
  };

  // Render
  return (
    <SpaceBetween width='100%'>
      <ColumnStack>
        <BodyTwo sx={commonStyles}>بارگذاری شده توسط:</BodyTwo>
        <BodyTwo sx={commonStyles}>محل بارگذاری:</BodyTwo>
        <BodyTwo sx={commonStyles}>تاریخ:</BodyTwo>
        <BodyTwo sx={commonStyles}>ساعت:</BodyTwo>
        <BodyTwo sx={commonStyles}>اندازه فایل:</BodyTwo>
        {((isFetched && ['video', 'image'].includes(file.type)) ||
          !isFetched) && <BodyTwo sx={commonStyles}>ابعاد:</BodyTwo>}
      </ColumnStack>
      <ColumnStack alignItems='end'>
        {isFetched && file ? (
          <>
            <Link
              to={`/files?uploadBy=${user?.id}`}
              style={{ color: 'dodgerblue', textDecoration: 'none' }}
            >
              <BodyTwo
                sx={{
                  fontSize: commonStyles.sx.fontSize,
                  width: 'fit-content',
                }}
              >
                {user?.data?.firstName || user?.data?.lastName
                  ? `${user?.data?.firstName} ${user?.data?.lastName}`?.toPersian()
                  : '-'}
              </BodyTwo>
            </Link>
            <Link
              to={`/files?location=${file.location}`}
              style={{ color: 'dodgerblue', textDecoration: 'none' }}
            >
              <BodyTwo
                sx={{
                  fontSize: commonStyles.sx.fontSize,
                  width: 'fit-content',
                }}
              >
                {file.location
                  ? getFileLocationLabel(file.location)?.toPersian()
                  : '-'}
              </BodyTwo>
            </Link>
            <BodyTwo sx={commonStyles}>
              {getValues('createdAt')
                ? getLocaleDate(getValues('createdAt'))?.toPersian()
                : ''}
            </BodyTwo>
            <BodyTwo sx={commonStyles}>
              {getValues('createdAt')
                ? getLocaleTime(getValues('createdAt'))?.toPersian()
                : ''}
            </BodyTwo>
            <BodyTwo dir='ltr' sx={commonStyles}>
              {convertBytes(file.size, 0)?.toPersian()}
            </BodyTwo>

            {['video', 'image'].includes(file.type) && (
              <BodyTwo sx={{ ...commonStyles.sx, direction: 'rtl' }}>
                {`${file.width} x ${file.height} px`?.toPersian()}
              </BodyTwo>
            )}
          </>
        ) : (
          <InfoSkeleton />
        )}
      </ColumnStack>
    </SpaceBetween>
  );
};

export default FileInfo;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface H5IconProps extends SvgIconProps {}

const H5Icon: React.FC<H5IconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M5.88583 6.66227V10.5409H12.1142V6.66227C12.1142 6.10818 12.2437 5.69261 12.5028 5.41557C12.7684 5.13852 13.115 5 13.5425 5C13.9765 5 14.3263 5.13852 14.5919 5.41557C14.864 5.68646 15 6.10202 15 6.66227V17.3285C15 17.8887 14.864 18.3074 14.5919 18.5844C14.3198 18.8615 13.97 19 13.5425 19C13.1085 19 12.7619 18.8615 12.5028 18.5844C12.2437 18.3012 12.1142 17.8826 12.1142 17.3285V12.7757H5.88583V17.3285C5.88583 17.8887 5.7498 18.3074 5.47773 18.5844C5.20567 18.8615 4.85587 19 4.42834 19C3.99433 19 3.64777 18.8615 3.38866 18.5844C3.12955 18.3012 3 17.8826 3 17.3285V6.66227C3 6.10818 3.12632 5.69261 3.37895 5.41557C3.63806 5.13852 3.98785 5 4.42834 5C4.86235 5 5.21215 5.13852 5.47773 5.41557C5.7498 5.68646 5.88583 6.10202 5.88583 6.66227Z' />
    <path d='M21.5332 12.8574H18.75L18.416 14.7559C18.9707 14.459 19.4824 14.3105 19.9512 14.3105C20.3262 14.3105 20.6758 14.3809 21 14.5215C21.3281 14.6621 21.6152 14.8574 21.8613 15.1074C22.1074 15.3535 22.3008 15.6465 22.4414 15.9863C22.582 16.3262 22.6523 16.6914 22.6523 17.082C22.6523 17.6484 22.5254 18.1641 22.2715 18.6289C22.0176 19.0938 21.6504 19.4629 21.1699 19.7363C20.6934 20.0098 20.1426 20.1465 19.5176 20.1465C18.8184 20.1465 18.25 20.0176 17.8125 19.7598C17.3789 19.502 17.0703 19.207 16.8867 18.875C16.707 18.5391 16.6172 18.2461 16.6172 17.9961C16.6172 17.8438 16.6797 17.6992 16.8047 17.5625C16.9336 17.4258 17.0938 17.3574 17.2852 17.3574C17.6133 17.3574 17.8574 17.5469 18.0176 17.9258C18.1738 18.2734 18.3848 18.5449 18.6504 18.7402C18.916 18.9316 19.2129 19.0273 19.541 19.0273C19.8418 19.0273 20.1055 18.9492 20.332 18.793C20.5586 18.6367 20.7324 18.4219 20.8535 18.1484C20.9785 17.875 21.041 17.5645 21.041 17.2168C21.041 16.8418 20.9746 16.5215 20.8418 16.2559C20.709 15.9902 20.5312 15.793 20.3086 15.6641C20.0859 15.5312 19.8379 15.4648 19.5645 15.4648C19.2285 15.4648 18.9883 15.5059 18.8438 15.5879C18.6992 15.6699 18.4902 15.8145 18.2168 16.0215C17.9473 16.2246 17.7246 16.3262 17.5488 16.3262C17.3574 16.3262 17.1875 16.2578 17.0391 16.1211C16.8906 15.9805 16.8164 15.8223 16.8164 15.6465C16.8164 15.627 16.8379 15.4941 16.8809 15.248L17.373 12.4414C17.4277 12.1055 17.5293 11.8613 17.6777 11.709C17.8262 11.5566 18.0684 11.4805 18.4043 11.4805H21.498C22.1074 11.4805 22.4121 11.7129 22.4121 12.1777C22.4121 12.3809 22.3379 12.5449 22.1895 12.6699C22.041 12.7949 21.8223 12.8574 21.5332 12.8574Z' />
  </IconBuilder>
);

export default H5Icon;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface CheckCircleIconProps extends SvgIconProps {}

const CheckCircleIcon: React.FC<CheckCircleIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm6.23,8.07-5.57,5.57-1.84,1.84a.5.5,0,0,1-.79,0l0,0h0L8.16,15.61h0L5.8,13.25a.59.59,0,0,1-.18-.39.51.51,0,0,1,.18-.41l1.83-1.83A.65.65,0,0,1,8,10.44a.47.47,0,0,1,.44.14l2,2L15.57,7.4A.57.57,0,0,1,16,7.22a.53.53,0,0,1,.41.18L18.2,9.24a.64.64,0,0,1,.17.39.48.48,0,0,1-.14.44Z' />
  </IconBuilder>
);

export default CheckCircleIcon;

import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { useParams, useSearchParams } from 'react-router-dom';

// Custom Hooks
import useUnMount from 'core/hooks/useUnMount';
import useDeprecatedDocsApi from 'core/hooks/api/useDeprecatedDocs';
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';
import {
  setFeedbackPdfValues,
  useSelectFeedbackPdfValues,
} from 'features/form/feedbacks/store';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Dialog from 'core/components/base/feedback/Dialog';
import Button from 'core/components/base/inputs/Button';
import Skeleton from 'core/components/base/feedback/Skeleton';

// Custom Common Components
import CloseIconButton from 'core/components/shared/IconButton/Close';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';
import RowStack from 'core/components/shared/Stack/RowStack';
import PageSize from 'core/components/shared/Filter/PageSize';
import Pagination from 'core/components/shared/Filter/Pagination';

// Custom Feature  Components
import Feedback from 'features/form/feedbacks/components/details/Feedback';

// Custom Utilities
import { getFeedbackValues } from 'features/form/feedbacks/utilities/api/details';
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';

// Context
import { IndexProvider } from 'core/store/context/IndexProvider';

// Custom Types
interface PdfPreviewProps {
  open: boolean;
  onClose: () => void;
}

const PdfPreview: React.FC<PdfPreviewProps> = (props) => {
  // Props
  const { open, onClose } = props;

  // Context
  const loading = useSelectLoading();
  const feedbacks = useSelectFeedbackPdfValues();

  // Hooks
  const { formId = '' } = useParams();
  const { palette } = useTheme();
  const [searchParams] = useSearchParams();
  const feedbackIds = searchParams.getAll('feedbackId');

  const feedbackApiMethods = useDeprecatedDocsApi(getFeedbackValues, {
    id: feedbackIds,
    shouldUseId: true,
    defaultPageSize: 20,
    additionalData: {
      formId,
    },
  });

  const {
    list = [],
    page,
    fetching,
    initialized,
    onPageNumChange,
    onPageSizeChange,
  } = feedbackApiMethods;

  React.useEffect(() => {
    setFeedbackPdfValues(list || []);
  }, [list]);

  useUnMount(() => setFeedbackPdfValues([]));

  // Utilities
  const handlePrint = () => window.print();

  // Render
  return (
    <Dialog
      keepMounted={true}
      open={open}
      PaperProps={{
        sx: {
          background: palette.background.default,
          minWidth: '70vw',
          maxWidth: '70vw',
          border: `1px solid ${palette.divider}`,
          padding: '2rem',
          display: 'flex',
          flexDirection: 'column',
          ...customRoundedScrollbarStyles,
          gap: '1.25rem',
          ['@media print']: {
            minWidth: '100vw',
            maxWidth: '100vw',
          },
        },
      }}
    >
      <SpaceBetween alignItems='center'>
        <CloseIconButton
          size='small'
          color='error'
          onClick={onClose}
          disabled={loading || fetching}
          sx={{
            border: `1px solid ${palette.error.main}`,
          }}
        />
        <Button
          variant='contained'
          color='error'
          disabled={loading || fetching}
          onClick={handlePrint}
          sx={{ width: 'fit-content' }}
        >
          پرینت
        </Button>
      </SpaceBetween>
      <RowStack alignItems='center'>
        <PageSize
          page={page}
          onSizeChange={onPageSizeChange}
          sx={{ height: '2rem' }}
        />
        <Pagination page={page} onPageChange={onPageNumChange} />
      </RowStack>
      {open && initialized && !fetching ? (
        <Box
          id='printable-section'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            width: '100%',
          }}
        >
          {feedbacks.map((feedback, index) => (
            <IndexProvider
              index={index}
              key={`pdf-review-feedback-${feedback.id}`}
            >
              <Feedback feedback={feedback} />
            </IndexProvider>
          ))}
        </Box>
      ) : (
        <ColumnStack spacing={2}>
          <SpaceBetween>
            <RowStack spacing={1} alignItems='center'>
              <Skeleton
                variant='rectangular'
                sx={{
                  minWidth: '2.188rem',
                  maxWidth: '2.188rem',
                  minHeight: '2.188rem',
                  maxHeight: '2.188rem',
                  borderRadius: '.5rem',
                }}
              />
              <Skeleton
                variant='rounded'
                sx={{
                  minWidth: '10rem',
                  maxWidth: '10rem',
                  minHeight: '2.188rem',
                  maxHeight: '2.188rem',
                  borderRadius: '.5rem',
                }}
              />

              <Skeleton
                variant='rectangular'
                sx={{
                  minWidth: '2.188rem',
                  maxWidth: '2.188rem',
                  minHeight: '2.188rem',
                  maxHeight: '2.188rem',
                  borderRadius: '.5rem',
                }}
              />
            </RowStack>
            <RowStack spacing={1} alignItems='center'>
              <Skeleton
                variant='text'
                sx={{
                  minWidth: '10rem',
                  maxWidth: '10rem',
                  minHeight: '3rem',
                  maxHeight: '3rem',
                  borderRadius: '.5rem',
                }}
              />
              <Skeleton
                variant='text'
                sx={{
                  minWidth: '6rem',
                  maxWidth: '6rem',
                  minHeight: '3rem',
                  maxHeight: '3rem',
                  borderRadius: '.5rem',
                }}
              />
            </RowStack>
          </SpaceBetween>
          <Skeleton
            variant='rounded'
            sx={{
              width: '100%',
              height: '31.25rem',
            }}
          />
        </ColumnStack>
      )}
    </Dialog>
  );
};

export default PdfPreview;

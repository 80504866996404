import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface WidthSmIconProps extends SvgIconProps {}

const WidthSmIcon: React.FC<WidthSmIconProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <path d='M22,2H2v2.5h20V2z' />
      <path d='M22,7.8H2v8.5h20V7.8z' />
      <path d='M22,19.5H2V22h20V19.5z' />
    </IconBuilder>
  );
};

export default WidthSmIcon;

import { type FC } from 'react';

// Core Components
import IconButton, {
  type IconButtonProps,
} from 'core/components/base/inputs/IconButton';

// Icon Components
import CopyIcon from 'core/components/icons/Copy';

// Types
interface CopyIconButtonProps extends IconButtonProps {}

const CopyIconButton: FC<CopyIconButtonProps> = (props) => {
  const { children, ...otherProps } = props;

  return (
    <IconButton {...otherProps}>
      <CopyIcon color='inherit' fontSize='inherit' />
    </IconButton>
  );
};

export default CopyIconButton;

// Custom Hooks
import useDocsApi from 'core/hooks/api/useDocs';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  addAudienceAccess,
  deleteAudienceAccess,
  getAudienceAccessList,
  updateAudienceAccess,
} from 'features/audience/accesses/utilities/api';

// Custom Types
import type { AccessProps } from 'features/audience/accesses/types';
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';

/**
 * Query key for fetching accesses in react query.
 *
 * @constant {Array<string>}
 */
export const accessQueryKey = ['accesses'];

/**
 * Custom hook to fetch audience access list.
 *
 * This hook utilizes the `useDocsApi` to retrieve audience access list based on the provided options.
 *
 * @param {UseDocsOptionType<AccessProps>} [options] - Optional configuration options for fetching the audience access list.
 * @returns {UseDocsApiReturnProps<AccessProps>} The result of the `useDocApi` hook, which includes the fetched audience access list, loading state, and any errors.
 */
export const useAudienceAccessDocs = (
  options?: UseDocsOptionType<AccessProps>
): UseDocsApiReturnProps<AccessProps> =>
  useDocsApi<AccessProps>(getAudienceAccessList, accessQueryKey, options);

/**
 * Custom hook for deleting an audience access.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the delete mutation, including status and potential errors.
 */
export const useDeleteAudienceAccessMutation = useDeleteMutation<string>(
  deleteAudienceAccess,
  accessQueryKey
);

/**
 * Custom hook for adding a new audience access.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the add mutation, including status and potential errors.
 */
export const useAddAudienceAccessMutation = useAddMutation<string>(
  addAudienceAccess,
  accessQueryKey
);

/**
 * Custom hook for editing an existing audience access.
 *
 * @returns {MutationResult<{ status: number }, unknown>} The result of the edit mutation, including status and potential errors.
 */
export const useEditAudienceAccessMutation = useEditMutation<string>(
  updateAudienceAccess,
  accessQueryKey
);

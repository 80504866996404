import { useState, type FC } from 'react';

// Custom Hooks
import useUpdateEffect from 'core/hooks/useUpdateEffect';

// Core Components
import Button from 'core/components/base/inputs/Button';
import UserFriendsIcon from 'core/components/icons/UserFriends';
import BodyOne from 'core/components/shared/Typography/BodyOne';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import Menu from 'core/components/base/navigation/Menu';
import MenuItem from 'core/components/base/navigation/MenuItem';

// Core Utilities
import useSummaryApi from 'core/hooks/api/useSummary';

// Feature Utilities
import { audienceGroupsQueryKey } from 'features/audience/groups/hooks';
import { getAudienceGroupSummaryList } from 'features/audience/groups/utilities/api';

// Custom Types
import type { ButtonProps } from 'core/components/base/inputs/Button';
import type { AudienceGroupSummaryProps } from 'features/audience/groups/types';

export interface AudienceGroupSelectProps extends ButtonProps {
  label?: string;
  selectedId?: string;
  onItemClick?: (item: AudienceGroupSummaryProps) => void;
}

const AudienceGroupSelect: FC<AudienceGroupSelectProps> = (props) => {
  // Props
  const {
    children,
    selectedId = '',
    label = 'انتخاب گروه مخاطبان*',
    sx,
    onItemClick,
    ...otherProps
  } = props;

  // States
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  // Hooks
  const { data: list, refetch } = useSummaryApi(
    getAudienceGroupSummaryList,
    audienceGroupsQueryKey
  );

  useUpdateEffect(() => {
    if (open) refetch();
  }, [open]);

  // Render
  return (
    <>
      <Button
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        sx={{
          textWrap: 'nowrap',
          height: '38px',
          bgcolor: '#F5F5F5',
          flexShrink: 0,
          fontSize: '14px',
          fontWeight: 400,
          justifyContent: 'flex-start',
          px: '0.5rem',
          borderRadius: '0.25rem',
          color: '#12252E',
          ...sx,
        }}
        startIcon={<UserFriendsIcon />}
        {...otherProps}
      >
        <BodyTwo
          sx={{
            width: '11rem',
            maxWidth: '11rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textAlign: 'left',
          }}
        >
          {label}
        </BodyTwo>
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        elevation={0}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          sx: {
            mt: 0.5,
            p: 1,
            borderRadius: 1,
            bgcolor: '#F5F5F5',
            width: '216px',
          },
        }}
      >
        {list && list.length > 0 ? (
          list.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              onClick={() => {
                setAnchorEl(null);
                if (onItemClick) onItemClick(item);
              }}
              sx={{ color: selectedId === item.id ? 'info.main' : '#807D7B' }}
            >
              {item.data.title}
            </MenuItem>
          ))
        ) : (
          <BodyOne>در حال حاضر گروهی برای مخاطبان وجود ندارد.</BodyOne>
        )}
      </Menu>
    </>
  );
};

export default AudienceGroupSelect;

// Custom Page Components
import List from 'features/manager/groups/components/list';
import Add from 'features/manager/groups/components/details/Add';
import Edit from 'features/manager/groups/components/details/Edit';

// Custom Utilities
import { getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'manager';
const subFeature: SubFeatureType = 'managerGroups';
const managerGroupsRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/manager-groups',
    Component: List,
    feature,
    subFeature,
  },
  {
    path: '/manager-groups/add',
    Component: Add,
    feature,
    subFeature,
  },
  {
    path: '/manager-groups/:id',
    Component: Edit,
    feature,
    subFeature,
  },
];

export default managerGroupsRoutes;

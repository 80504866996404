import {
  FormControl as MuiFormControl,
  FormControlProps as MuiFormControlProps,
  styled,
} from '@mui/material';

export interface FormControlProps extends MuiFormControlProps {}

const FormControl = styled(MuiFormControl)<FormControlProps>(() => ({}));

export default FormControl;

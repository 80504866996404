import { useEffect, type FC, useCallback } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  type SubmitHandler,
} from 'react-hook-form';

// Custom Core Components
import Menu from 'core/components/base/navigation/Menu';
import IconButton from 'core/components/base/inputs/IconButton';

// Custom Common Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import ColorPicker from 'core/components/shared/Picker/Color';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import RowStack from 'core/components/shared/Stack/RowStack';
import SpacedBox from 'core/components/shared/Box/SpacedBox';
import TextFieldNumber from 'core/components/shared/TextField/number';

// Custom Feature Components
import PatternPadding from 'features/appBuilder/patterns/core/components/shared/Padding';

// Custom Icon Components
import ConfigIcon from 'core/components/icons/Config';
import FormatAlignCenter from 'core/components/icons/FormatAlignCenter';
import FormatAlignLeft from 'core/components/icons/FormatAlignLeft';
import FormatAlignRight from 'core/components/icons/FormatAlignRight';
import FormatTextdirectionLToRIcon from 'core/components/icons/LtrDirection';
import FormatTextdirectionRToLIcon from 'core/components/icons/RtlDirection';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useAppDispatch, useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import { updateCellsStyles } from 'features/appBuilder/patterns/features/table/store';

// Custom Types
import type { TableCellStyleProps } from 'features/appBuilder/patterns/features/table/types/styles';
import type { AlignType, DirType } from 'features/appBuilder/patterns/types';
import type { MenuProps } from 'core/components/base/navigation/Menu';

export interface CellCommonStylesMenuProps extends MenuProps {
  onMenuClose: () => void;
}

const CellCommonStylesMenu: FC<CellCommonStylesMenuProps> = (props) => {
  // Props
  const {
    id = 'table-pattern-insert-ref-menu',
    MenuListProps,
    onMenuClose,
    ...otherProps
  } = props;

  // Hooks
  const dispatch = useAppDispatch();
  const loading = useSelectLoading();
  const selection = useAppSelector((state) => state.table.data.selection);
  const formMethods = useForm<TableCellStyleProps>({
    mode: 'onTouched',
  });
  const { control, setValue, handleSubmit, reset, getValues } = formMethods;

  const handleUpdate: SubmitHandler<TableCellStyleProps> = useCallback(
    (data) => {
      if (selection.length > 0) {
        dispatch(updateCellsStyles({ cells: selection, styles: data }));
      }
    },
    [dispatch, selection]
  );

  // useEffects
  useEffect(() => {
    if (selection.length > 0) {
      const cellStyles = selection[0].styles;
      reset({ ...cellStyles });
    }
  }, [selection, reset]);

  useEffect(() => {
    if (!props.open) {
      handleSubmit(handleUpdate)();
    }
  }, [props.open, handleUpdate, handleSubmit]);

  return (
    <Menu
      id={id}
      onClose={onMenuClose}
      PaperProps={{
        sx: {
          borderRadius: '1rem',
          boxShadow: 3,
          padding: '1rem',
        },
      }}
      MenuListProps={{
        'aria-labelledby': 'table-pattern-insert-ref-button',
        sx: { width: 'fit-content', p: 0 },
        ...MenuListProps,
      }}
      {...otherProps}
    >
      <FormProvider {...formMethods}>
        <ColumnStack spacing={4}>
          <SpacedBox>
            <BodyOne>تنظیمات عمومی سلول‌ها</BodyOne>
            <ConfigIcon />
          </SpacedBox>
          <SpacedBox sx={{ gap: 4, alignItems: 'flex-start' }}>
            <PatternPadding sx={{ backgroundColor: 'transparent' }} />
            <ColumnStack>
              <ColumnStack spacing={0}>
                <InputLabel>اندازه(فونت / عرض عکس)</InputLabel>
                <Controller
                  name='size'
                  defaultValue={24}
                  control={control}
                  rules={{
                    onChange: () => {
                      let value = getValues('size');

                      if (typeof value === 'string') value = parseInt(value);

                      setValue('size', value);
                    },
                  }}
                  render={({ field }) => (
                    <TextFieldNumber
                      sx={{
                        '.MuiInputBase-input': {
                          padding: '12px',
                        },
                        width: '85px',
                      }}
                      InputProps={{
                        startAdornment: (
                          <BodyTwo sx={{ marginRight: '10px' }}>px</BodyTwo>
                        ),
                      }}
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
              <ColumnStack spacing={0}>
                <InputLabel>چیدمان</InputLabel>
                <ColumnStack
                  sx={({ breakpoints }) => ({
                    flexDirection: breakpoints.down('xl') ? 'column' : 'row',
                    border: '1px solid #C1C8D3',
                    borderRadius: '4px',
                    padding: '2px',
                  })}
                  spacing={0}
                >
                  <Controller
                    name={'direction'}
                    defaultValue='rtl'
                    control={control}
                    render={({ field }) => (
                      <RowStack spacing={0}>
                        {(['rtl', 'ltr'] as DirType[]).map((dir) => (
                          <IconButton
                            key={`pattern-dir-${dir}`}
                            sx={{
                              color:
                                field.value === dir ? '#0091FF' : 'inherit',
                            }}
                            onClick={() => setValue(`direction`, dir)}
                          >
                            {dir === 'rtl' ? (
                              <FormatTextdirectionRToLIcon />
                            ) : (
                              <FormatTextdirectionLToRIcon />
                            )}
                          </IconButton>
                        ))}
                      </RowStack>
                    )}
                  />
                  <Controller
                    name='align'
                    defaultValue='right'
                    control={control}
                    render={({ field }) => (
                      <RowStack spacing={0}>
                        {(['right', 'center', 'left'] as AlignType[]).map(
                          (align) => (
                            <IconButton
                              key={`pattern-align-${align}`}
                              sx={{
                                color:
                                  field.value === align ? '#0091FF' : 'inherit',
                              }}
                              onClick={() => setValue('align', align)}
                            >
                              {align === 'right' ? (
                                <FormatAlignRight />
                              ) : align === 'center' ? (
                                <FormatAlignCenter />
                              ) : (
                                <FormatAlignLeft />
                              )}
                            </IconButton>
                          )
                        )}
                      </RowStack>
                    )}
                  />
                </ColumnStack>
              </ColumnStack>
            </ColumnStack>
          </SpacedBox>
          <RowStack sx={{ mb: 2 }}>
            <Controller
              name='color'
              defaultValue='#000000'
              control={control}
              render={({ field }) => (
                <ColorPicker
                  color={field.value}
                  disabled={loading}
                  onColorChange={(color) => setValue('color', color)}
                />
              )}
            />
            <InputLabel whiteSpace='nowrap'>محتوی</InputLabel>
            <Controller
              name='bgColor'
              defaultValue='rgba(0,0,0,0.5)'
              control={control}
              render={({ field }) => (
                <ColorPicker
                  color={field.value}
                  disabled={loading}
                  onColorChange={(color) => setValue('bgColor', color)}
                />
              )}
            />
            <InputLabel whiteSpace='nowrap'>بکگراند</InputLabel>
            <Controller
              name='borderColor'
              defaultValue='#000000'
              control={control}
              render={({ field }) => (
                <ColorPicker
                  color={field.value}
                  disabled={loading}
                  onColorChange={(color) => setValue('borderColor', color)}
                />
              )}
            />
            <InputLabel whiteSpace='nowrap'>بردر</InputLabel>
          </RowStack>
          <RowStack sx={{ mb: 2 }}>
            <Controller
              name='hoverColor'
              defaultValue='#000000'
              control={control}
              render={({ field }) => (
                <ColorPicker
                  color={field.value}
                  disabled={loading}
                  onColorChange={(color) => setValue('hoverColor', color)}
                />
              )}
            />
            <InputLabel whiteSpace='nowrap'>هاور محتوی</InputLabel>
            <Controller
              name='hoverBgColor'
              defaultValue='rgba(0,0,0,0.5)'
              control={control}
              render={({ field }) => (
                <ColorPicker
                  color={field.value}
                  disabled={loading}
                  onColorChange={(color) => setValue('hoverBgColor', color)}
                />
              )}
            />
            <InputLabel whiteSpace='nowrap'>هاور بکگراند</InputLabel>
            <Controller
              name='hoverBorderColor'
              defaultValue='#000000'
              control={control}
              render={({ field }) => (
                <ColorPicker
                  color={field.value}
                  disabled={loading}
                  onColorChange={(color) => setValue('hoverBorderColor', color)}
                />
              )}
            />
            <InputLabel whiteSpace='nowrap'>هاور بردر</InputLabel>
          </RowStack>
        </ColumnStack>
      </FormProvider>
    </Menu>
  );
};

export default CellCommonStylesMenu;

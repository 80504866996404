import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';
import TextField from 'core/components/base/inputs/TextField';
import InputWrapper from 'core/components/shared/Wrapper/Input';

// Feature Shared Components
import PatternItemContainer, {
  type PatternItemContainerProps,
} from 'features/appBuilder/patterns/core/components/shared/items/Container';
import PatternCalendarTypeDropdown from 'features/appBuilder/patterns/core/components/shared/dropdown/CalendarType';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
export interface PatternDateItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
  noPrefix?: boolean;
}

const PatternDateItem: FC<PatternDateItemProps> = (props) => {
  // Props
  const { reference, noPrefix, ...otherProps } = props;

  // Hooks
  const { control } = useFormContext();
  const loading = useSelectLoading();

  return (
    <PatternItemContainer reference={reference} {...otherProps}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        {!noPrefix && (
          <InputWrapper label='عنوان قبل از تاریخ' sx={{ width: '100%' }}>
            <Controller
              control={control}
              name={`${reference}.prefix`}
              render={({ field }) => (
                <TextField disabled={loading} {...field} />
              )}
            />
          </InputWrapper>
        )}
        <InputWrapper label='نوع تقویم' sx={{ width: '100%' }}>
          <Controller
            control={control}
            name={`${reference}.calendarType`}
            render={({ field }) => (
              <PatternCalendarTypeDropdown
                disabled={loading}
                fullWidth
                reference={reference}
                defaultValue={field.value}
              />
            )}
          />
        </InputWrapper>
      </Box>
    </PatternItemContainer>
  );
};

export default PatternDateItem;

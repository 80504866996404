import { memo, useCallback } from 'react';

// Type
import type { FC } from 'react';

// Custom Feature Components
import FormSectionHeaderPreviewActions from 'features/form/forms/components/details/item/Section/HeaderActions/Preview';
import FormSectionHeaderEditActions from 'features/form/forms/components/details/item/Section/HeaderActions/Edit';

// Custom Utilities
import { useAppSelector } from 'core/hooks/redux';
import { isSame } from 'core/utilities/helper/helperPack';

// Custom Types
import type { FormSectionProps } from 'features/form/forms/types/item/section';

interface FormSectionHeaderActionsProps {
  section: FormSectionProps;
  index: number;
}

const FormSectionHeaderActions: FC<FormSectionHeaderActionsProps> = (props) => {
  // Props
  const { section, index } = props;

  // Hooks
  const actionMode = useAppSelector((state) => state.form.actionMode);
  const currentSectionId = useAppSelector(
    (state) => state.form.currentSectionId
  );

  // Memoized Sections
  const EditActions = useCallback(
    () => <FormSectionHeaderEditActions index={index} section={section} />,
    [index, section]
  );
  const PreviewActions = useCallback(
    () => <FormSectionHeaderPreviewActions index={index} section={section} />,
    [index, section]
  );

  // Render
  return actionMode === 'SECTION' && currentSectionId === section.id ? (
    <EditActions />
  ) : (
    <PreviewActions />
  );
};

export default memo(FormSectionHeaderActions, isSame);

import {
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  styled,
} from '@mui/material';

export interface RadioGroupProps extends MuiRadioGroupProps {
  to?: string;
  component?: JSX.Element;
}

const RadioGroup = styled(MuiRadioGroup)<RadioGroupProps>(() => ({}));

export default RadioGroup;

import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';

// Feature Components
import ProcessItemButtonProps from 'features/form/processes/components/details/shared/ItemButton';

// Custom Utilities
import { genInitialEvaluationValue } from 'features/form/processes/utilities/initial/evaluation';
import { genInitialManagerQuestionValue } from 'features/form/processes/utilities/initial/managerQuestion';
import { updateProcessFocus } from 'features/form/processes/store/actions';
import { forLoop } from 'core/utilities/helper';

// Context
import { useSelectProcessFocusIsEmpty } from 'features/form/processes/store/selector';
import { useFormDetailsSummaryContext } from 'features/form/forms/contexts/FormDetailsSummary';

// Custom Types
import type { ProcessProps } from 'features/form/processes/types/details';
import type { ProcessItemType } from 'features/form/processes/types/details/common/type';
import type { EvaluatorItemAccessProps } from 'features/form/processes/types/details/common/itemAccess';
import type { EvaluationProps } from 'features/form/processes/types/details/evaluation';
import type { ManagerQuestionProps } from 'features/form/processes/types/details/managerQuestion';
export interface ProcessAddItemSectionProps {
  disabled?: boolean;
  onAdd: (evaluation: EvaluationProps | ManagerQuestionProps) => void;
}

const ProcessAddItemSection: FC<ProcessAddItemSectionProps> = (props) => {
  // Props
  const { onAdd, disabled } = props;

  // Hooks
  const { getValues } = useFormContext<ProcessProps>();

  // Context
  const isFocusEmpty = useSelectProcessFocusIsEmpty();
  const processItems = getValues('data.items');
  const formDetails = useFormDetailsSummaryContext();

  // Utilities
  const getEvaluatorAccessess = (): EvaluatorItemAccessProps[] => {
    const accesses: EvaluatorItemAccessProps[] = [];

    forLoop(processItems, (processItem) => {
      accesses.push({
        id: processItem.id,
        data: {
          title: processItem.data.title,
          type: processItem.data.processItemType,
          fields:
            processItem.data.processItemType === 'evaluation'
              ? [
                  'evaluationTitle',
                  'evaluationQuestion',
                  'selectedOption',
                  'message',
                  'evaluator',
                  'comment',
                  'date',
                  'reviewDetails',
                ]
              : ['questionDetails'],
        },
      });
    });

    return accesses;
  };

  const getFormFieldsIds = (): string[] => {
    const formFieldsIds: string[] = [];

    if (formDetails?.data.inputs) {
      formFieldsIds.push(...formDetails.data.inputs.map((input) => input.id));
    }

    return formFieldsIds;
  };

  const handleAdd = (type: ProcessItemType) => () => {
    if (type === 'evaluation') {
      const evaluatorItemAccesses = getEvaluatorAccessess();
      const formFieldsIds = getFormFieldsIds();
      const newEvaluationItem = genInitialEvaluationValue({
        evaluatorItemAccesses,
        formFieldsIds,
      });

      updateProcessFocus(newEvaluationItem.id);
      onAdd(newEvaluationItem);
    } else {
      const newManagerQuestionItem = genInitialManagerQuestionValue();

      updateProcessFocus(newManagerQuestionItem.id);
      onAdd(newManagerQuestionItem);
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <ProcessItemButtonProps
        disabled={!isFocusEmpty || disabled}
        onClick={handleAdd('evaluation')}
      >
        ارزیابی جدید
      </ProcessItemButtonProps>
      <ProcessItemButtonProps
        disabled={!isFocusEmpty || disabled}
        onClick={handleAdd('managerQuestion')}
      >
        پرسش جدید
      </ProcessItemButtonProps>
    </Box>
  );
};

export default ProcessAddItemSection;

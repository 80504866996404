// Custom Types
import type { FormStepProps } from 'features/form/forms/types/item/step';

/**
 * Retrieves the next form step based on the current step ID.
 *
 * This function takes an array of form steps and the ID of the current step,
 * and returns the next step in the sequence. If the current step is the last
 * step, it returns the last step again.
 *
 * @param {FormStepProps[]} steps - An array of form steps.
 * @param {string} currentStepId - The ID of the current form step.
 * @returns {FormStepProps} The next form step or the last step if the current step is the last one.
 *
 * @example
 * const steps = [
 *   { id: 'step1', ... },
 *   { id: 'step2', ... },
 *   { id: 'step3', ... }
 * ];
 * const nextStep = getNextFormStep(steps, 'step2');
 * // nextStep will be { id: 'step3', ... }
 */
export const getNextFormStep = (
  steps: FormStepProps[],
  currentStepId: string
): FormStepProps => {
  let nextIndex = 0;
  const currentStepIndex = steps.findIndex((step) => step.id === currentStepId);

  if (currentStepIndex < steps.length - 1) {
    nextIndex = currentStepIndex + 1;
  }

  return steps[nextIndex];
};

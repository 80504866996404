import { useState, type FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternItemContainerButton from 'features/appBuilder/patterns/core/components/shared/items/ContainerButton';
import PatternTabItem from 'features/appBuilder/patterns/core/components/shared/items/Tab';

// Core Utilities
import { getNumberTitle } from 'core/utilities/helper/helperPack';
import { contentPatternTabDefaults } from 'features/appBuilder/patterns/features/content/utilities/defaults/body';

// Custom Types
export interface ContentPatternBodySectionProps {}
type CollapsedKey = 'section' | 0 | 1 | 2 | 3 | 4;

const ContentPatternBodySection: FC<ContentPatternBodySectionProps> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  });
  const { control, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'body.tabs',
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='محتوای کانتنت'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='body'
    >
      {fields.map((tab, index) => (
        <PatternTabItem
          key={tab.id}
          reference={`body.tabs.${index}`}
          title={`تب ${getNumberTitle(index + 1)}`}
          collapsed={collapsed[index as 0 | 1 | 2 | 3 | 4]}
          onCollapsedChange={handleCollapseChanged(index as 0 | 1 | 2 | 3 | 4)}
          onRemove={fields.length > 1 ? () => remove(index) : undefined}
          include={['padding']}
          titleIsRequired
        />
      ))}
      {fields.length < 5 && (
        <PatternItemContainerButton
          onClick={() => {
            append(contentPatternTabDefaults);
          }}
        />
      )}
    </PatternSectionContainer>
  );
};

export default ContentPatternBodySection;

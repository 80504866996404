import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  styled,
} from '@mui/material';

export type TextFieldProps = MuiTextFieldProps;

const TextField = styled(MuiTextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    height: '1rem',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.background.paper,
    '&:hover fieldset': {
      border: '1px solid',
      borderColor: theme.palette.text.secondary,
    },
    '&.Mui-focused fieldset': {
      border: '2px solid',
      borderColor: theme.palette.text.secondary,
    },
  },
}));

TextField.defaultProps = {
  autoComplete: 'new-password',
  InputProps: {
    autoComplete: 'new-password',
  },
  inputProps: {
    autoComplete: 'new-password',
  },
};

export default TextField;

import { type FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Custom Core Components
import MenuItem from 'core/components/base/navigation/MenuItem';
import TextField from 'core/components/base/inputs/TextField';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import RowStack from 'core/components/shared/Stack/RowStack';
import Select from 'core/components/shared/Select';
import DatePicker from 'core/components/shared/Picker/DatePicker';

// Custom Feature Componenst
import FormInputWrapper, {
  FormInputWrapperProps,
} from 'features/form/forms/components/details/item/Input/shared/Wrapper';

// Custom Utilities
import { toggleFormIsInInputConfigSelection } from 'features/form/forms/store/actions';
import { isSame } from 'core/utilities/helper/helperPack';

// Custom Types
import type { RoundPaperProps } from 'core/components/shared/Paper/RoundPaper';
import type { FormDateInputProps } from 'features/form/forms/types/item/input/date';
import type { FormProps } from 'features/form/forms/types/item';
import type { CalendarType } from 'core/types/shared/date';

export interface FormDateInputComponentProps
  extends RoundPaperProps,
    FormInputWrapperProps {
  input: FormDateInputProps;
}

const FormDateInput: FC<FormDateInputComponentProps> = (props) => {
  // Props
  const { input, sx, disabled, onUpdate, ...otherProps } = props;

  // Hooks
  const { control, getValues, setValue } = useFormContext<FormProps>();
  const index = getValues('data.inputsIndexMap')[input.id];

  const FormTextInputConfig = (
    <ColumnStack spacing={1.5}>
      <RowStack>
        <InputWrapper>
          <InputLabel variant='body2'>نوع تقویم</InputLabel>
          <Controller
            control={control}
            name={`data.inputs.${index}.data.calendarType`}
            defaultValue='jalali'
            render={({ field }) => (
              <Select
                fullWidth
                value={field.value}
                sx={{ height: '2.5rem' }}
                onOpen={() => toggleFormIsInInputConfigSelection(true)}
                onClose={() => toggleFormIsInInputConfigSelection(false)}
              >
                {(['jalali', 'gregorian', 'lunar'] as CalendarType[]).map(
                  (cType, index) => (
                    <MenuItem
                      key={cType + index}
                      value={cType}
                      onClick={(e) => {
                        e.stopPropagation();
                        setValue(field.name, cType);
                        if (onUpdate) onUpdate();
                      }}
                    >
                      {cType === 'jalali'
                        ? 'شمسی'
                        : cType === 'gregorian'
                        ? 'میلادی'
                        : 'قمری'}
                    </MenuItem>
                  )
                )}
              </Select>
            )}
          />
        </InputWrapper>
        <InputWrapper>
          <InputLabel variant='body2'>نوع نمایش</InputLabel>
          <Controller
            control={control}
            name={`data.inputs.${index}.data.pick`}
            defaultValue='yyyy/mm/dd'
            render={({ field }) => (
              <Select
                fullWidth
                value={field.value}
                sx={{ width: '9rem', height: '2.5rem' }}
                onOpen={() => toggleFormIsInInputConfigSelection(true)}
                onClose={() => toggleFormIsInInputConfigSelection(false)}
              >
                {(['yyyy/mm/dd', 'yyyy'] as ('yyyy/mm/dd' | 'yyyy')[]).map(
                  (pickType, index) => (
                    <MenuItem
                      key={pickType + index}
                      value={pickType}
                      onClick={(e) => {
                        e.stopPropagation();
                        setValue(field.name, pickType);
                        if (onUpdate) onUpdate();
                      }}
                    >
                      {pickType === 'yyyy' ? 'سال' : 'روز / ماه / سال'}
                    </MenuItem>
                  )
                )}
              </Select>
            )}
          />
        </InputWrapper>
      </RowStack>
      <RowStack>
        <InputWrapper width='100%'>
          <InputLabel variant='body2'>از تاریخ</InputLabel>
          <Controller
            control={control}
            name={`data.inputs.${index}.data.minDate`}
            defaultValue=''
            render={({ field }) => (
              <DatePicker
                value={field.value ? new Date(field.value) : undefined}
                sx={{
                  width: '8rem',
                  height: '2.5rem',
                }}
                onDateChange={(date) => {
                  setValue(field.name, date ? date.toISOString() : '');
                  if (onUpdate) onUpdate();
                }}
              />
            )}
          />
        </InputWrapper>
        <InputWrapper width='100%'>
          <InputLabel variant='body2'>تا تاریخ</InputLabel>
          <Controller
            control={control}
            name={`data.inputs.${index}.data.maxDate`}
            defaultValue=''
            render={({ field }) => (
              <DatePicker
                value={field.value ? new Date(field.value) : undefined}
                sx={{
                  width: '8rem',
                  height: '2.5rem',
                }}
                onDateChange={(date) => {
                  setValue(field.name, date ? date.toISOString() : '');
                  if (onUpdate) onUpdate();
                }}
              />
            )}
          />
        </InputWrapper>
      </RowStack>
    </ColumnStack>
  );

  return (
    <FormInputWrapper
      id={input.id}
      sx={{ minHeight: '16.5rem', ...sx }}
      index={index}
      type='date'
      config={FormTextInputConfig}
      disabled={disabled}
      onUpdate={onUpdate}
      {...otherProps}
    >
      <Controller
        control={control}
        name={`data.inputs.${index}.data.placeholder`}
        defaultValue=''
        rules={{ onChange: onUpdate }}
        render={({ field }) => (
          <TextField
            disabled={disabled}
            placeholder=''
            fullWidth
            sx={{ fontSize: '0.875rem' }}
            {...field}
          />
        )}
      />
    </FormInputWrapper>
  );
};

export default memo(FormDateInput, isSame);

import { type FC } from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Cusstom Core Components
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface HiddenFieldsIconProps extends SvgIconProps {}

const HiddenFieldsIcon: FC<HiddenFieldsIconProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <path d='M5.8,2h12.5C20.3,2,22,3.7,22,5.8v12.5c0,2.1-1.7,3.8-3.8,3.8H5.8C3.7,22,2,20.3,2,18.2V5.8C2,3.7,3.7,2,5.8,2z' />
      <path
        style={{ fill: '#fff' }}
        d='M14.5,11c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.7,0.3
	s0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7C15,11.1,14.8,11,14.5,11z M14.5,13.7c-0.5,0-0.9-0.2-1.2-0.5
	c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.4,0.2-0.9,0.5-1.2c0.3-0.3,0.8-0.5,1.2-0.5c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2
	c0,0.4-0.2,0.9-0.5,1.2C15.4,13.5,15,13.7,14.5,13.7z M14.5,9.5c-1.7,0-3.2,1-3.8,2.5c0.6,1.5,2,2.5,3.8,2.5c1.7,0,3.2-1,3.8-2.5
	C17.7,10.5,16.2,9.5,14.5,9.5z'
      />
      <path
        style={{ fill: '#fff' }}
        d='M20.3,5.8H3.7C3.5,5.8,3.2,6,3.2,6.3v11.4c0,0.3,0.2,0.6,0.5,0.6h16.5c0.3,0,0.5-0.2,0.5-0.6V6.3
	C20.8,6,20.5,5.8,20.3,5.8z M19.8,17.1H4.2V6.9h15.6V17.1z'
      />
      <path
        style={{ fill: '#fff' }}
        d='M7.5,13.8c-0.3,0-0.5,0.2-0.5,0.4c0,0.2,0.2,0.4,0.5,0.4h2.8c0.3,0,0.5-0.2,0.5-0.4c0-0.2-0.2-0.4-0.5-0.4H9.3
	v-3.6h0.9c0.3,0,0.5-0.2,0.5-0.4s-0.2-0.4-0.5-0.4H7.5C7.2,9.5,7,9.7,7,9.9s0.2,0.4,0.5,0.4h0.9v3.6H7.5z'
      />
    </IconBuilder>
  );
};

export default HiddenFieldsIcon;

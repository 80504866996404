import * as React from 'react';

// Custom Utilities
import {
  addAudiencesToAccess,
  addAudiencesToGroups,
  deleteAudiences,
  editAudiencesJobTitle,
  getAudiencesExcelReport,
  removeAudienceFromAccess,
  removeAudiencesFromGroups,
} from 'features/audience/audiences/utilities/api';

// Custom Types
import { AudienceDataFieldsLabel } from 'features/audience/settings/types';
import type { AudienceOperationType } from 'features/audience/audiences/types';
import type { ProfileField } from 'features/audience/settings/components/ProfileFields';

/**
 * Retrieves the label for a given audience profile field.
 *
 * @param {ProfileField} name - The name of the profile field.
 * @returns {string} The label associated with the profile field, or the name if no label is found.
 */
export const getAudienceProfileFieldLabel = (name: ProfileField): string =>
  AudienceDataFieldsLabel[name] || name;

/**
 * Generates helper text based on the specified audience operation and selection.
 *
 * @param {AudienceOperationType} operation - The operation type to perform.
 * @param {string[]} selection - An array of selected audience identifiers.
 * @returns {React.ReactNode} The helper text to display based on the operation and selection.
 */
export const getOperationHelperText = (
  operation: AudienceOperationType,
  selection: string[]
): React.ReactNode => {
  switch (operation) {
    case 'ADD_TO_GROUP':
      return (
        <>
          <b>{selection.length.toPersian()} مخاطب</b> انتخاب شده در گروه‌های
          تعیین شده عضو می‌شوند.
        </>
      );

    case 'DELETE_FROM_GROUP':
      return (
        <>
          <b>{selection.length.toPersian()} مخاطب</b> انتخاب شده از گروه‌های
          تعیین شده حذف می‌شوند.
        </>
      );

    case 'DELETE':
      return (
        <>
          <b>{selection.length.toPersian()} مخاطب</b> انتخاب شده حذف می‌شوند.
        </>
      );

    case 'UPDATE_JOB_TITLE':
      return (
        <>
          عنوان شغلی <b>{selection.length.toPersian()} مخاطب</b> انتخاب شده به
          عنوان شغلی جدید تغییر خواهد کرد.
        </>
      );

    case 'EXPORT_EXCEL':
      return (
        <>
          اطلاعات{' '}
          <b>
            {selection.length === 0
              ? 'تمام مخاطبان'
              : selection.length.toPersian() + ' مخاطب'}
          </b>{' '}
          {selection.length > 0 ? 'انتخاب شده' : ''} در فایل اکسل خروجی گرفته
          می‌شود.
        </>
      );
    case 'ADD_ACCESS':
      return (
        <>
          {' '}
          <b>
            {selection.length === 0
              ? 'تمام مخاطبان'
              : selection.length.toPersian() + ' مخاطب'}
          </b>{' '}
          {selection.length > 0 ? 'انتخاب شده' : ''} به اکسس انتخاب شده اضافه
          می‌شود.
        </>
      );
    case 'REMOVE_ACCESS':
      return (
        <>
          اکسس{' '}
          <b>
            {selection.length === 0
              ? 'تمام مخاطبان'
              : selection.length.toPersian() + ' مخاطب'}
          </b>{' '}
          {selection.length > 0 ? 'انتخاب شده' : ''} حذف می‌شود.
        </>
      );
  }
};

/**
 * Performs an audience group operation based on the provided parameters.
 *
 * @param {AudienceOperationType} operation - The operation to perform on the audience.
 * @param {string[]} audiencesIds - An array of audience identifiers.
 * @param {string[]} value - Additional values needed for the operation.
 * @returns {Promise<{ status: number; failed: string[]; passed: string[] }>} A promise that resolves to an object containing the status of the operation and arrays of failed and passed audience IDs.
 * @throws {Error} Throws an error if the operation is unsupported.
 */
export const audienceGroupOperation = async (
  operation: AudienceOperationType,
  audiencesIds: string[],
  value: string[]
): Promise<{ status: number; failed: string[]; passed: string[] }> => {
  //
  const operationMap: Partial<
    Record<AudienceOperationType, () => Promise<any>>
  > = {
    DELETE: async () => deleteAudiences(audiencesIds),
    UPDATE_JOB_TITLE: async () => editAudiencesJobTitle(audiencesIds, value[0]),
    ADD_TO_GROUP: async () => addAudiencesToGroups(audiencesIds, value),
    DELETE_FROM_GROUP: async () =>
      removeAudiencesFromGroups(audiencesIds, value),
    EXPORT_EXCEL: async () => getAudiencesExcelReport(audiencesIds),
    REMOVE_ACCESS: async () => removeAudienceFromAccess(audiencesIds),
    ADD_ACCESS: async () => {
      const { status, failed, passed } = await addAudiencesToAccess(
        audiencesIds,
        value[0]
      );
      return { status, failed, passed };
    },
  };

  const operationFunc = operationMap[operation];
  if (!operationFunc) throw new Error(`Unsupported operation: ${operation}`);

  const result = await operationFunc();

  const { failed = [], passed = [] } = result;

  return { status: result.status || result, failed, passed };
};

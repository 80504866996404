import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface PlusIconProps extends SvgIconProps {}

const PlusIcon: React.FC<PlusIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M19.5,9.5h-5v-5c0-0.7-0.3-1.3-0.7-1.8C13.3,2.3,12.7,2,12,2s-1.3,0.3-1.8,0.7C9.8,3.2,9.5,3.8,9.5,4.5v5h-5
	c-0.7,0-1.3,0.3-1.8,0.7C2.3,10.7,2,11.3,2,12s0.3,1.3,0.7,1.8c0.5,0.5,1.1,0.7,1.8,0.7h5v5c0,0.7,0.3,1.3,0.7,1.8
	c0.5,0.5,1.1,0.7,1.8,0.7s1.3-0.3,1.8-0.7c0.5-0.5,0.7-1.1,0.7-1.8v-5h5c0.7,0,1.3-0.3,1.8-0.7c0.5-0.5,0.7-1.1,0.7-1.8
	s-0.3-1.3-0.7-1.8C20.8,9.8,20.2,9.5,19.5,9.5z'
    />
  </IconBuilder>
);

export default PlusIcon;

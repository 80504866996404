import { useCallback, type FC, useMemo, memo } from 'react';
import {
  useFormContext,
  useFieldArray,
  type SubmitHandler,
} from 'react-hook-form';

// Custom Common Components
import RowStack, {
  type RowStackProps,
} from 'core/components/shared/Stack/RowStack';

// Custom Shared Components
import RemoveButton from 'features/form/shared/components/button/Remove';
import AddButton from 'features/form/shared/components/button/Add';
import CancelButton from 'features/form/shared/components/button/Cancel';
import SaveLoadingButton from 'features/form/shared/components/loadingButton/Save';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';
import { useDialogContext } from 'core/store/context/DialogProvider';

// Utilities
import { forLoop } from 'core/utilities/helper';
import { getInputsIndexMap } from 'features/form/forms/utilities/input/helper';
import { deepClone, isSame } from 'core/utilities/helper/helperPack';
import { genFormInitialInput } from 'features/form/forms/utilities/input/initial';
import {
  useAddAndEditFormInputsMutation,
  useDeleteFormInputsMutation,
} from 'features/form/forms/hooks';
import {
  setFormActionMode,
  setFormCurrentSectionId,
  updateFormInputs,
} from 'features/form/forms/store/actions';

// Custom Types
import type { FormProps } from 'features/form/forms/types/item';
interface FormSectionActionsProps extends RowStackProps {
  sectionId: string;
}

const FormSectionActions: FC<FormSectionActionsProps> = (props) => {
  // Props
  const { sectionId, sx, ...otherProps } = props;

  // Context
  const currentSectionId = useAppSelector(
    (state) => state.form.currentSectionId
  );
  const actionMode = useAppSelector((state) => state.form.actionMode);
  const sectionInputsCount = useAppSelector(
    (state) => state.form.sectionInputsCount
  );
  const selectedInputs = useAppSelector((state) => state.form.selectedInputs);
  const formInputs = useAppSelector((state) => state.form.data?.inputs || []);
  const formId = useAppSelector((state) => state.form.formId);
  const currentStep = useAppSelector((state) => state.form.currentStep);
  const { control, getValues, setValue, handleSubmit } =
    useFormContext<FormProps>();

  // Hooks
  const { onOpenDialog, onCloseDialog } = useDialogContext();
  const { append } = useFieldArray({ control, name: 'data.inputs' });
  const { mutate: handleDeleteFormInputs } = useDeleteFormInputsMutation({
    noRevalidate: true,
    autoAlert: { mode: 'delete', name: 'فیلد‌ها' },
    onSuccess: () => {
      const allInputs = deepClone(formInputs);
      let inputsIndexMap: { [key: string]: number } = {};
      let updatedInputs = [];
      updatedInputs = allInputs.filter(
        (input) => !selectedInputs.includes(input.id)
      );

      setFormActionMode('INITIAL');

      setValue('data.inputs', updatedInputs);
      updateFormInputs(updatedInputs);
      inputsIndexMap = getInputsIndexMap(updatedInputs);
      onCloseDialog();
      setValue('data.inputsIndexMap', inputsIndexMap);
    },
  });
  const { mutate: handleAddAndEditFormInputs } =
    useAddAndEditFormInputsMutation({
      noRevalidate: true,
      autoAlert: { mode: 'update', name: 'فیلد‌ها' },
      onSuccess: ({ docs }) => {
        if (!docs) return;
        const allInputs = deepClone(formInputs);
        let updatedInputs = [];
        let inputsIndexMap: { [key: string]: number } = {};
        updatedInputs = allInputs.filter(
          (input) => input.data.sectionId !== sectionId
        );

        if (docs && docs.length > 0)
          forLoop(docs, (inp) => updatedInputs.push(inp));

        setFormActionMode('INITIAL');

        setValue('data.inputs', updatedInputs);
        updateFormInputs(updatedInputs);
        inputsIndexMap = getInputsIndexMap(updatedInputs);

        setValue('data.inputsIndexMap', inputsIndexMap);
      },
    });

  const isInInputRelatedModes = useMemo(
    () =>
      currentSectionId === sectionId &&
      (actionMode === 'INPUT' ||
        actionMode === 'INPUT_SORT' ||
        actionMode === 'INPUT_DELETE'),
    [actionMode, currentSectionId, sectionId]
  );

  const isDeleteDisabled = useMemo(
    () =>
      actionMode !== 'INITIAL' ||
      !sectionInputsCount[sectionId] ||
      sectionInputsCount[sectionId] === 0,
    [actionMode, sectionId, sectionInputsCount]
  );

  const isAddDisabled = useMemo(
    () =>
      (currentSectionId && currentSectionId !== sectionId) ||
      !['INITIAL', 'INPUT'].includes(actionMode),
    [actionMode, currentSectionId, sectionId]
  );

  // Utilities
  const handleOpenCancelDialog = () => {
    onOpenDialog({
      onSubmit: handleCancel,
      submitTitle: 'لغو تغییرات',
      description: 'تغییرات ایجاد شده، اعمال نخواهند شد.',
    });
  };

  const handleDeleteDialog = () => {
    onOpenDialog({
      onSubmit: handleSubmit(handleFormSubmit),
      description:
        'با حذف این فیلدها، اطلاعات ثبت شده در این فیلدها نیز از بازخوردها حذف می‌شود.',
    });
  };

  const handleRemoveInput = useCallback(() => {
    setFormActionMode('INPUT_DELETE');
    setFormCurrentSectionId(sectionId);
  }, [sectionId]);

  const handleAddInput = useCallback(() => {
    setFormActionMode('INPUT');
    setFormCurrentSectionId(sectionId);
    const inputs = getValues('data.inputs');

    let lastInputSortIndex =
      inputs.length > 0 ? inputs[inputs.length - 1].data.sortIndex : 0;
    lastInputSortIndex = lastInputSortIndex ?? 0;

    const sortIndex = inputs.length > 0 ? lastInputSortIndex + 1 : 0;

    const inputToAdd = genFormInitialInput({
      formId,
      stepId: currentStep.id,
      sectionId,
      sortIndex,
      type: 'text',
    });

    const inputIndexInTheArray = getValues('data.inputs').length;

    const inputsIndexMap = getValues('data.inputsIndexMap');
    const clonedInputsIndexMap = deepClone(inputsIndexMap);
    clonedInputsIndexMap[inputToAdd.id] = inputIndexInTheArray;
    setValue('data.inputsIndexMap', clonedInputsIndexMap);

    append(inputToAdd);
  }, [sectionId, formInputs, currentStep.id, sectionId]);

  const handleCancel = () => {
    const originalInputs = formInputs;
    setValue('data.inputs', originalInputs);

    setFormActionMode('INITIAL');
    setFormCurrentSectionId('');
    onCloseDialog();
  };

  const handleFormSubmit: SubmitHandler<FormProps> = async (form) => {
    if (actionMode === 'INPUT_DELETE') {
      handleDeleteFormInputs(selectedInputs);
    } else {
      const inputs = form.data.inputs.filter(
        (input) => input.data.sectionId === currentSectionId
      );

      handleAddAndEditFormInputs(inputs);
    }
  };

  // Render
  return (
    <RowStack sx={{ p: '1rem 2rem 2rem', ...sx }} {...otherProps}>
      <RemoveButton
        onClick={handleRemoveInput}
        disabled={isDeleteDisabled}
        sx={{ px: '1rem' }}
      />
      <AddButton
        disabled={isAddDisabled}
        sx={{ px: '1rem' }}
        onClick={handleAddInput}
      />
      {isInInputRelatedModes && (
        <CancelButton sx={{ px: '1rem' }} onClick={handleOpenCancelDialog} />
      )}
      {isInInputRelatedModes && (
        <SaveLoadingButton
          sx={{ px: '1rem' }}
          onClick={
            actionMode === 'INPUT_DELETE'
              ? handleDeleteDialog
              : handleSubmit(handleFormSubmit)
          }
        >
          {actionMode === 'INPUT_DELETE' ? 'حذف' : 'ذخیره'}
        </SaveLoadingButton>
      )}
    </RowStack>
  );
};

export default memo(FormSectionActions, isSame);

import cloneDeep from 'lodash/cloneDeep';

// Custom Types
import type { GroupPatternDataProps } from 'features/appBuilder/patterns/features/group/types';

const groupPatternDataDefaults: GroupPatternDataProps = {
  patternTitle: '',
  bgColorOne: '#F5F5F5',
  bgColorTwo: '#F5F5F5',
  dividerColor: '#C1C8D3',
  bgImage: null,
  bgImageType: 'cover',
  padding: '32px',
  cardBg: '',
  cardCount: 0,
  creator: null,
  isActive: true,
  maxWidth: 'lg',
  sortIndex: 0,
  type: 'group',
  variant: 0,
  columnOne: null,
  columnTwo: null,
  columnThree: null,
};

/**
 * Retrieves the default group pattern data.
 *
 * @returns {GroupPatternDataProps} The default group pattern data.
 */
export const getGroupPatternDataDefault = () => {
  const cloned = cloneDeep(groupPatternDataDefaults);

  return cloned;
};

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface FormatAlignLeftIconProps extends SvgIconProps {}

const FormatAlignLeftIcon: React.FC<FormatAlignLeftIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M19.7634 7.67818H4.23658C4.10595 7.67818 4 7.49447 4 7.34546V4.33271C4 4.14901 4.10586 4 4.23658 4H19.7634C19.894 4 20 4.18371 20 4.33271V7.34546C20 7.52917 19.8941 7.67818 19.7634 7.67818Z' />
    <path d='M18.1656 13.8422H4.23658C4.10595 13.8422 4 13.6585 4 13.5095V10.4968C4 10.3131 4.10586 10.1641 4.23658 10.1641H18.1656C18.2962 10.1641 18.4021 10.3478 18.4021 10.4968V13.5095C18.4021 13.6932 18.2963 13.8422 18.1656 13.8422Z' />
    <path d='M11.962 19.9985H4.23658C4.10595 19.9985 4 19.8148 4 19.6658V16.653C4 16.4693 4.10586 16.3203 4.23658 16.3203H11.962C12.0926 16.3203 12.1986 16.504 12.1986 16.653V19.6658C12.1986 19.8495 12.0927 19.9985 11.962 19.9985Z' />
  </IconBuilder>
);

export default FormatAlignLeftIcon;

import * as React from 'react';
import store from 'core/store';

// Custom Components
import PageHeadConfigs from 'core/components/shared/PageTitle';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Feature Components
import BannerList from 'features/banner/banners/components/details/List';
import BannerTable from 'features/banner/banners/components/details/Table';
import BannerActions from 'features/banner/banners/components/details/Actions';
import BannerContainerTitle from 'features/banner/banners/components/details/ContainerTitle';
import BannerContainerDropdown from 'features/banner/banners/components/details/ContainersDropdown';

// Custom Hooks
import useRefId from 'core/hooks/useRefId';
import { useAppDispatch, useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import { deepClone } from 'core/utilities/helper/helperPack';
import {
  useAddBannerMutation,
  useBannerDocs,
  useDeleteBannerContainerMutation,
  useEditBannerMutation,
} from 'features/banner/banners/hooks';
import {
  cancelChanges,
  deleteContainer,
  setBannerContainers,
  setCurrentContainer,
} from 'core/store/slices/feature/banner';

// Custom Types
interface BannerPageProps {}

const Banner: React.FC<BannerPageProps> = () => {
  // States
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  // Context
  const isDraft = useAppSelector((state) => state.banner.isDraft);
  const containerId = useAppSelector((state) => state.banner.current?.id || '');

  // Hooks
  const [refId] = useRefId();
  const dispatch = useAppDispatch();
  const bannerMethods = useBannerDocs({
    defaultPageSize: 9999,
    onFetch: (banners) => {
      dispatch(setBannerContainers(banners));
      dispatch({ type: '/banner/isDraft/false' });
    },
  });
  const { mutate: handleAddBanner } = useAddBannerMutation({
    autoAlert: { mode: 'create', name: 'فهرست جدید' },
    onSuccess: ({ banner }) => {
      if (!banner) return;
      dispatch(setCurrentContainer(banner));
      dispatch({ type: '/banner/isDraft/false' });
    },
  });
  const { mutate: handleDeleteBanner } = useDeleteBannerContainerMutation({
    autoAlert: { mode: 'delete', name: 'فهرست' },
    onSuccess: () => {
      dispatch(deleteContainer({ containerId }));
      dispatch(setCurrentContainer(null));
      dispatch({ type: '/banner/isDraft/false' });
      setOpenDeleteDialog(false);
    },
  });
  const { mutate: handleEditBanner } = useEditBannerMutation({
    autoAlert: { mode: 'edit', name: 'فهرست' },
    onSuccess: ({ banner }) => {
      if (!banner) return;
      dispatch(setCurrentContainer(banner));
      dispatch({ type: '/banner/isDraft/false' });
    },
  });

  // Utilities
  const getCurrentContainer = () => store.getState().banner.current;

  const handleDeleteContainer = async () => {
    if (!containerId) return setOpenDeleteDialog(false);

    if (containerId === 'new') dispatch(setCurrentContainer(null));
    else if (containerId !== 'new' && isDraft) dispatch(cancelChanges());
    else handleDeleteBanner(containerId);
  };

  const handleSubmit = async () => {
    const current = deepClone(getCurrentContainer());
    if (!current) return;

    current.data.relatedMediaId = refId;

    if (containerId === 'new') handleAddBanner(current.data);
    else handleEditBanner({ id: current.id, data: current.data });
  };

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='بنرها' />
      <PageHeroBox>
        <BannerContainerDropdown />
        <BannerActions
          openDeleteDialog={openDeleteDialog}
          onDeleteContainer={handleDeleteContainer}
          onSetOpenDeleteDialog={setOpenDeleteDialog}
          onSubmit={handleSubmit}
        />
      </PageHeroBox>
      <ColumnStack>
        <BannerContainerTitle />
        <ColumnStack>
          <BannerTable />
          <BannerList />
        </ColumnStack>
      </ColumnStack>
    </>
  );
};

export default Banner;

import { createContext, useContext, type FC, type ReactNode } from 'react';

// Custom Types
import type { UseSelectionResponse } from 'core/hooks/common/useSelection';

interface UseSelectionProviderProps extends UseSelectionResponse {
  children: ReactNode;
}

const UseSelectionContext = createContext<UseSelectionResponse>({
  selections: [],
  onSelectionsChange: (selection) => {},
  onToggleAll: (dataList) => {},
  onClearSelections: () => {},
  isSelected: (id) => false,
  isAllSelected: (dataList) => false,
  isIndeterminate: (dataList) => false,
});

export const useSelectionContext = () => useContext(UseSelectionContext);

export const SelectionProvider: FC<UseSelectionProviderProps> = (props) => {
  // Props
  const { children, ...otherProps } = props;

  return (
    <UseSelectionContext.Provider value={otherProps}>
      {children}
    </UseSelectionContext.Provider>
  );
};

export default SelectionProvider;

import { Controller, FormProvider, useForm } from 'react-hook-form';

// Types
import type { FC } from 'react';

// Custom Feature Component
import AudienceOperations from 'features/audience/audiences/components/list/GroupOperation';

// Custom Common Components
import Search from 'core/components/shared/Filter/Search';
import RowStack from 'core/components/shared/Stack/RowStack';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';

// Custom Core Components
import Collapse from 'core/components/base/utils/Collapse';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import { getOperationHelperText } from 'features/audience/audiences/utilities/helper';

// Context
import { useDocsContext } from 'core/hooks/api/useDocs/Context';
import { useSelectionContext } from 'core/store/context/common/UseSelection';

// Custom Types
import type {
  AudienceProps,
  AudienceOperationType,
} from 'features/audience/audiences/types';

interface AudienceListActionProps {}

export interface AudienceOperationFormProps {
  operationValue: string[];
  selectedOperation: AudienceOperationType;
}

const AudienceListAction: FC<AudienceListActionProps> = (props) => {
  // Context
  const audienceList = useDocsContext<AudienceProps>();
  const loading = useSelectLoading();
  const { onFilter } = audienceList;
  const { selections } = useSelectionContext();

  // Hooks
  const formMethods = useForm<AudienceOperationFormProps>({
    defaultValues: {
      operationValue: [], // For Single Values, Index 0 will be the value.
      selectedOperation: '',
    },
  });

  // Render
  return (
    <FormProvider {...formMethods}>
      <PageHeroBox>
        <HeadingSix>مخاطبان</HeadingSix>
        <RowStack alignItems='center'>
          <Search
            InputProps={{
              style: {
                height: '2.5rem',
                borderRadius: '.5rem',
                width: '17.375rem',
              },
            }}
            onFilter={(search) => onFilter({ ...(search && { search }) })}
            disabled={loading}
          />
          <AudienceOperations />
        </RowStack>
      </PageHeroBox>
      <Controller
        control={formMethods.control}
        name='selectedOperation'
        render={({ field }) => (
          <Collapse in={field.value ? true : false}>
            <BodyTwo sx={{ paddingY: '0.5rem' }}>
              {getOperationHelperText(field.value, selections)}
            </BodyTwo>
          </Collapse>
        )}
      />
    </FormProvider>
  );
};

export default AudienceListAction;

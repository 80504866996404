// Custom Page Components
import SkinList from 'features/appBuilder/skins/components/list';

// Custom Utilities
import { getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'template';
const subFeature: SubFeatureType = 'skins';
const skinsRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/template/skin',
    Component: SkinList,
    feature,
    subFeature,
  },
];

export default skinsRoutes;

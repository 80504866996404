import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface ClockIconProps extends SvgIconProps {}

const ClockIcon: React.FC<ClockIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M19.07,4.92a10,10,0,1,0,0,14.14A10,10,0,0,0,19.07,4.92Zm-6.29,7.31a.77.77,0,0,1-.18.44L9.22,16.33a.65.65,0,0,1-1-.88L11.48,12V7.32a.65.65,0,0,1,1.3,0Z' />
  </IconBuilder>
);

export default ClockIcon;

import * as React from 'react';

// Core Components
import Box from 'core/components/base/layout/Box';

import type { BoxProps } from 'core/components/base/layout/Box';
export interface SpaceBetweenProps extends BoxProps {}

const SpaceBetween: React.FC<SpaceBetweenProps> = React.forwardRef(
  (props, ref) => {
    const { children, sx, ...otherProps } = props;
    return (
      <Box
        ref={ref}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...sx,
        }}
        {...otherProps}
      >
        {children}
      </Box>
    );
  }
);

SpaceBetween.displayName = 'SpaceBetween';

export default SpaceBetween;

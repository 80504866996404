import { type FC } from 'react';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';

// Custom Common Components
import RowStack, {
  type RowStackProps,
} from 'core/components/shared/Stack/RowStack';
import SubmitLoadingButton from 'core/components/shared/LoadingButton/Submit';

// Custom Hooks
import { useDialogContext } from 'core/store/context/DialogProvider';

// Custom Types
export interface StepActionsProps extends RowStackProps {
  onSubmit?: () => void;
  onCancel?: () => void;
}

const StepActions: FC<StepActionsProps> = (props) => {
  // Props
  const { onCancel, onSubmit, sx, ...otherProps } = props;

  // Hooks
  const { onOpenDialog, onCloseDialog } = useDialogContext();

  const handleOpenDialog = () => {
    onOpenDialog({
      onSubmit: handleCancel,
      description: 'تغییرات ایجاد شده، اعمال نخواهند شد.',
      submitTitle: 'لغو تغییرات',
    });
  };
  const handleCancel = () => {
    onCloseDialog();
    if (onCancel) onCancel();
  };

  return (
    <RowStack sx={{ alignItems: 'center', ...sx }} {...otherProps}>
      <Button
        color='error'
        variant='outlined'
        sx={{ width: '4.5rem' }}
        onClick={handleOpenDialog}
      >
        انصراف
      </Button>
      <SubmitLoadingButton
        color='info'
        variant='outlined'
        sx={{ minWidth: '4.5rem' }}
        onClick={onSubmit}
      >
        ذخیره
      </SubmitLoadingButton>
    </RowStack>
  );
};

export default StepActions;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface H2IconProps extends SvgIconProps {}

const H2Icon: React.FC<H2IconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M5.88583 6.66227V10.5409H12.1142V6.66227C12.1142 6.10818 12.2437 5.69261 12.5028 5.41557C12.7684 5.13852 13.115 5 13.5425 5C13.9765 5 14.3263 5.13852 14.5919 5.41557C14.864 5.68646 15 6.10202 15 6.66227V17.3285C15 17.8887 14.864 18.3074 14.5919 18.5844C14.3198 18.8615 13.97 19 13.5425 19C13.1085 19 12.7619 18.8615 12.5028 18.5844C12.2437 18.3012 12.1142 17.8826 12.1142 17.3285V12.7757H5.88583V17.3285C5.88583 17.8887 5.7498 18.3074 5.47773 18.5844C5.20567 18.8615 4.85587 19 4.42834 19C3.99433 19 3.64777 18.8615 3.38866 18.5844C3.12955 18.3012 3 17.8826 3 17.3285V6.66227C3 6.10818 3.12632 5.69261 3.37895 5.41557C3.63806 5.13852 3.98785 5 4.42834 5C4.86235 5 5.21215 5.13852 5.47773 5.41557C5.7498 5.68646 5.88583 6.10202 5.88583 6.66227Z' />
    <path d='M18.5625 18.6172H21.6211C21.9258 18.6172 22.1582 18.6797 22.3184 18.8047C22.4785 18.9297 22.5586 19.0996 22.5586 19.3145C22.5586 19.5059 22.4941 19.668 22.3652 19.8008C22.2402 19.9336 22.0488 20 21.791 20H17.4785C17.1855 20 16.957 19.9199 16.793 19.7598C16.6289 19.5957 16.5469 19.4043 16.5469 19.1855C16.5469 19.0449 16.5996 18.8594 16.7051 18.6289C16.8105 18.3945 16.9258 18.2109 17.0508 18.0781C17.5703 17.5391 18.0391 17.0781 18.457 16.6953C18.875 16.3086 19.1738 16.0547 19.3535 15.9336C19.6738 15.707 19.9395 15.4805 20.1504 15.2539C20.3652 15.0234 20.5273 14.7891 20.6367 14.5508C20.75 14.3086 20.8066 14.0723 20.8066 13.8418C20.8066 13.5918 20.7461 13.3691 20.625 13.1738C20.5078 12.9746 20.3457 12.8203 20.1387 12.7109C19.9355 12.6016 19.7129 12.5469 19.4707 12.5469C18.959 12.5469 18.5566 12.7715 18.2637 13.2207C18.2246 13.2793 18.1582 13.4395 18.0645 13.7012C17.9746 13.9629 17.8711 14.1641 17.7539 14.3047C17.6406 14.4453 17.4727 14.5156 17.25 14.5156C17.0547 14.5156 16.8926 14.4512 16.7637 14.3223C16.6348 14.1934 16.5703 14.0176 16.5703 13.7949C16.5703 13.5254 16.6309 13.2441 16.752 12.9512C16.873 12.6582 17.0527 12.3926 17.291 12.1543C17.5332 11.916 17.8379 11.7246 18.2051 11.5801C18.5762 11.4316 19.0098 11.3574 19.5059 11.3574C20.1035 11.3574 20.6133 11.4512 21.0352 11.6387C21.3086 11.7637 21.5488 11.9355 21.7559 12.1543C21.9629 12.373 22.123 12.627 22.2363 12.916C22.3535 13.2012 22.4121 13.498 22.4121 13.8066C22.4121 14.291 22.291 14.7324 22.0488 15.1309C21.8105 15.5254 21.5664 15.8359 21.3164 16.0625C21.0664 16.2852 20.6465 16.6367 20.0566 17.1172C19.4707 17.5977 19.0684 17.9707 18.8496 18.2363C18.7559 18.3418 18.6602 18.4688 18.5625 18.6172Z' />
  </IconBuilder>
);

export default H2Icon;

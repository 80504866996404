import {
  Chip as MuiChip,
  ChipProps as MuiChipProps,
  styled,
} from '@mui/material';

export interface ChipProps extends MuiChipProps {}

const Chip = styled(MuiChip)<ChipProps>(() => ({}));

export default Chip;

import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import TextField from 'core/components/base/inputs/TextField';
import InputWrapper from 'core/components/shared/Wrapper/Input';

// Feature Shared Components
import PatternItemContainer, {
  type PatternItemContainerProps,
} from 'features/appBuilder/patterns/core/components/shared/items/Container';
import PatternFontFamilyDropdown from 'features/appBuilder/patterns/core/components/shared/dropdown/FontFamily';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
export interface PatternTitleItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
  textArea?: boolean;
  fontFamily?: boolean;
  inputLabel?: string;
  placeholder?: string;
}

const PatternTitleItem: FC<PatternTitleItemProps> = (props) => {
  // Props
  const {
    reference,
    placeholder,
    inputLabel,
    fontFamily,
    textArea,
    ...otherProps
  } = props;

  // Hooks
  const { control } = useFormContext();
  const loading = useSelectLoading();

  return (
    <PatternItemContainer reference={reference} {...otherProps}>
      <InputWrapper
        disabled={loading}
        label={inputLabel ? inputLabel : textArea ? 'توضیحات' : 'عنوان'}
      >
        <Controller
          control={control}
          name={`${reference}.text`}
          render={({ field }) => (
            <TextField
              disabled={loading}
              multiline={textArea}
              rows={textArea ? 3 : undefined}
              placeholder={placeholder}
              {...field}
            />
          )}
        />
      </InputWrapper>
      {fontFamily && (
        <Controller
          control={control}
          name={`${reference}.fontFamily`}
          render={({ field }) => (
            <PatternFontFamilyDropdown
              disabled={loading}
              defaultValue={field.value}
              reference={reference}
              keyName={'fontFamily'}
            />
          )}
        />
      )}
    </PatternItemContainer>
  );
};

export default PatternTitleItem;

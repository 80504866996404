import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Context
import { useIndex } from 'core/store/context/IndexProvider';
import {
  useSelectFeedbackColumnCount,
  useSelectFeedbackProfile,
} from 'features/form/feedbacks/store';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Paper from 'core/components/base/surfaces/Paper';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Utilites
import { getLocaleDate } from 'core/utilities/helper/date';
import { getAudienceProfileFieldLabel } from 'features/audience/audiences/utilities/helper';
import {
  convertNumberToPersian,
  getFileSource,
} from 'core/utilities/helper/helperPack';

// Custom Types
import type { ProfileField } from 'features/audience/settings/components/ProfileFields';

interface FeedbackProfileInfoProps {}
const FeedbackProfileInfo: React.FC<FeedbackProfileInfoProps> = (props) => {
  // Context
  const index = useIndex(); // The expression  (Index > -1)  means it is on the PDF preview; otherwise, it refers to feedback details."
  const profile = useSelectFeedbackProfile(index);
  const columnCount = useSelectFeedbackColumnCount();

  // Hooks
  const { palette, breakpoints } = useTheme();

  // Render
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '1rem 1.5rem 1.188rem',
        borderRadius: '1rem',
        width: '100%',
        boxShadow: 'none',
        ['@media print']: {
          border: '1px dashed #000',
          borderRadius: '1rem',
          padding: '1rem',
        },
      }}
    >
      <BodyTwo
        sx={{
          fontSize: '1.25rem',
          fontWeight: 500,
          color: palette.text.primary,
        }}
      >
        اطلاعات پروفایل
      </BodyTwo>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1rem',
          flexWrap: 'wrap',
        }}
      >
        {Object.entries(profile).map(([name, value]) => (
          <RowStack
            spacing='.5rem'
            sx={{
              width: `calc(100% / ${columnCount} - 1.4rem)`,
              [breakpoints.down('md')]: {
                width: '100%',
              },
              ['@media print']: {
                width: `calc(100% / ${columnCount} - 1.4rem)`,
              },
            }}
            key={`${
              index ? 'feedback-pdf-preview' : 'feedback-details'
            }-${name}-${value}`}
          >
            <BodyTwo
              sx={{
                fontSize: '1rem',
                color: palette.text.primary,
              }}
            >
              {getAudienceProfileFieldLabel(name as ProfileField)}
            </BodyTwo>
            {name === 'avatar' ? (
              <img
                src={getFileSource(value)}
                style={{
                  width: 80,
                  height: 80,
                  objectFit: 'cover',
                  borderRadius: '.5rem',
                }}
              />
            ) : (
              <BodyTwo
                sx={{
                  fontSize: '.975rem',
                  maxWidth: '100%',
                  color: palette.text.secondary,
                  lineBreak: 'anywhere',
                }}
              >
                {name === 'birthDate'
                  ? getLocaleDate(value)
                  : convertNumberToPersian(value) || '-'}
              </BodyTwo>
            )}
          </RowStack>
        ))}
      </Box>
    </Paper>
  );
};

export default FeedbackProfileInfo;

import * as React from 'react';

// Type
import type { SxProps } from '@mui/material';

// Custom Core Components
import { PaginationProps as MuiPaginationProps } from 'core/components/base/navigation/Pagination';
import MuiPagination from 'core/components/base/navigation/Pagination';
import PaginationItem from 'core/components/base/navigation/PaginationItem';

// Custom Types
import type { PageProps } from 'core/types/shared/pagination';

interface PaginationProps extends Omit<MuiPaginationProps, 'page'> {
  disabled?: boolean;
  page: PageProps;
  onPageChange: (newPage: number) => void;
  rtl?: boolean;
  paginationItemSx?: SxProps;
}

const Pagination: React.FC<PaginationProps> = (props) => {
  // Props
  const {
    disabled,
    onPageChange,
    page,
    rtl,
    sx,
    paginationItemSx,
    ...otherProps
  } = props;

  const { current, size, totalDocs } = page;

  // Utilities
  const handlePageChange = (page: number) => {
    if (onPageChange) onPageChange(page);
  };

  // Render
  return (
    <>
      <MuiPagination
        variant='outlined'
        disabled={disabled}
        shape='rounded'
        color='standard'
        page={current}
        count={
          Math.ceil(totalDocs / size) === 0 ? 1 : Math.ceil(totalDocs / size)
        }
        onChange={(e, current) => handlePageChange(current)}
        // @ts-ignore
        sx={{
          ...sx,
          ...(rtl && {
            direction: 'rtl !important',
            '.MuiPaginationItem-previousNext': {
              transform: 'rotate(180deg)',
              borderRadius: '5px !important',
            },
          }),
        }}
        renderItem={
          rtl
            ? ({ page, ...otherParams }) => (
                <PaginationItem
                  sx={{
                    ...paginationItemSx,
                  }}
                  page={page ? page.toString()?.toPersian() : '1'?.toPersian()}
                  {...otherParams}
                />
              )
            : undefined
        }
        {...otherProps}
      />
    </>
  );
};

export default Pagination;

import { type FC } from 'react';

// Custom Core Components
import Box, { type BoxProps } from 'core/components/base/layout/Box';
import InputLabel from 'core/components/shared/Typography/InputLabel';

// Custom Types
export interface InputWrapperProps extends BoxProps {
  disabled?: boolean;
  label?: string;
  required?: boolean;
  row?: boolean;
}

const InputWrapper: FC<InputWrapperProps> = (props) => {
  // Props
  const {
    children,
    row,
    label = '',
    disabled,
    required,
    sx,
    ...otherProps
  } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: row ? 'row' : 'column',
        gap: 0.5,
        ...sx,
      }}
      {...otherProps}
    >
      <InputLabel disabled={disabled} required={required}>
        {label}
      </InputLabel>
      {children}
    </Box>
  );
};

export default InputWrapper;

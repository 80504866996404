import * as React from 'react';

// Custom HOCs
import WithAuth from 'core/components/shared/HOC/WithAuth';

// Custom Hooks
import useSelection from 'core/hooks/common/useSelection';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Core Components
import Pagination from 'core/components/shared/Filter/Pagination';
import PageSize from 'core/components/shared/Filter/PageSize';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';

// Custom Feature Components
import MenusDataGrid from 'features/menu/menus/components/list/DataGrid';
import MenuListAction from 'features/menu/menus/components/list/Action';

// Context
import SelectionProvider from 'core/store/context/common/UseSelection';

// Custom Utilities
import DocsProvider from 'core/hooks/api/useDocs/Context';
import { useMenuDocs } from 'features/menu/menus/hooks';

// Custom Types
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';

interface MenusPageProps extends WithAuthProps {}

const filePath = 'features/menu/menus/components/list/index.tsx';
const Menus: React.FC<MenusPageProps> = (props) => {
  // Context
  const loading = useSelectLoading();

  // Hooks
  const selectonMethods = useSelection();
  const menusListMethods = useMenuDocs();

  const {
    data: { list, page },
    onPageSizeChange,
    onPageNumChange,
  } = menusListMethods;

  // Render
  return (
    <DocsProvider {...menusListMethods}>
      <SelectionProvider {...selectonMethods}>
        <PageHeadConfigs pageTitle='منوها' />
        <PageHeroBox width='100%'>
          <HeadingSix>لیست منوها</HeadingSix>
          <MenuListAction />
        </PageHeroBox>
        <ColumnStack>
          <MenusDataGrid disabled={loading} />
          <RowStack alignItems='center'>
            <PageSize
              disabled={loading || list?.length === 0}
              page={page}
              sx={{ height: '2rem' }}
              onSizeChange={onPageSizeChange}
              onPageChange={onPageNumChange}
            />
            <Pagination
              disabled={loading || list?.length === 0}
              page={page}
              onPageChange={onPageNumChange}
            />
          </RowStack>
        </ColumnStack>
      </SelectionProvider>
    </DocsProvider>
  );
};

export default WithAuth(Menus, { filePath });

import * as React from 'react';

// Custom Core Components
import TableCell from 'core/components/base/display/TableCell';

// Custom Utilites
import { getLocaleDateAndTime } from 'core/utilities/helper/date';
import { getFileSource } from 'core/utilities/helper/helperPack';
import { excelTableCommonCellStyles } from 'features/form/feedbacks/utilities/details/styles';
import { isProcessSectionHidden } from 'features/form/feedbacks/utilities/details/helper';

// Context
import { useSelectHiddenProcessSections } from 'features/form/feedbacks/store';

// Custom Types
import type { ManagerQuestionProps } from 'features/form/processes/types/details/managerQuestion';
import type { FeedbackProcessEvaluationProps } from 'features/form/feedbacks/types/detail/process/evaluation';

// Custom Types
interface ExcelBodyProcessCellProps {
  feedbackId: string;
  item: ManagerQuestionProps | FeedbackProcessEvaluationProps;
  processId?: string;
  applicantFullname?: string;
}

const ExcelBodyProcessCell: React.FC<ExcelBodyProcessCellProps> = (props) => {
  // Props
  const { item, applicantFullname, feedbackId, processId } = props;

  // Context
  const hiddenProcessSections = useSelectHiddenProcessSections();

  if (isProcessSectionHidden(hiddenProcessSections, item.id, 'all'))
    return <></>;
  if (
    item.data.processItemType === 'managerQuestion' &&
    isProcessSectionHidden(hiddenProcessSections, item.id, 'questionDetails')
  )
    return <></>;

  // Render
  return (
    <React.Fragment>
      {item.data.processItemType === 'evaluation' ? (
        <>
          {!isProcessSectionHidden(hiddenProcessSections, item.id, 'title') &&
            item.data.title && (
              <TableCell {...excelTableCommonCellStyles}>
                {item.data.title}
              </TableCell>
            )}
          {!isProcessSectionHidden(
            hiddenProcessSections,
            item.id,
            'questionTitle'
          ) &&
            item.data.questionTitle && (
              <TableCell {...excelTableCommonCellStyles}>
                {item.data.questionTitle}
              </TableCell>
            )}
          {!isProcessSectionHidden(
            hiddenProcessSections,
            item.id,
            'selectedOpt'
          ) &&
            item.data.selectedOptionTitle && (
              <TableCell {...excelTableCommonCellStyles}>
                {item.data.selectedOptionTitle}
              </TableCell>
            )}
          {!isProcessSectionHidden(hiddenProcessSections, item.id, 'message') &&
            item.data.message && (
              <TableCell {...excelTableCommonCellStyles}>
                {item.data.message}
              </TableCell>
            )}
          {!isProcessSectionHidden(
            hiddenProcessSections,
            item.id,
            'evaluatorFullname'
          ) &&
            item.data.evaluatorFullname && (
              <TableCell {...excelTableCommonCellStyles}>
                {item.data.evaluatorFullname}
              </TableCell>
            )}
          {!isProcessSectionHidden(hiddenProcessSections, item.id, 'comment') &&
            item.data.evaluatorComment && (
              <TableCell {...excelTableCommonCellStyles}>
                {item.data.evaluatorComment}
              </TableCell>
            )}
          {!isProcessSectionHidden(hiddenProcessSections, item.id, 'date') &&
            item.data.completionDate && (
              <TableCell {...excelTableCommonCellStyles}>
                {getLocaleDateAndTime(item.data.completionDate, '-')}
              </TableCell>
            )}
          {!isProcessSectionHidden(hiddenProcessSections, item.id, 'comment') &&
            item.data.evaluatorCommentFiles.length > 0 && (
              <TableCell {...excelTableCommonCellStyles}>
                {item.data.evaluatorCommentFiles.map(getFileSource).join(' - ')}
              </TableCell>
            )}
          {!isProcessSectionHidden(hiddenProcessSections, item.id, 'review') &&
            item.data.reviews &&
            item.data.reviews.length > 0 &&
            item.data.reviews.map((review) => (
              <React.Fragment
                key={`${feedbackId}-${processId}-${item.id}-${review.id}`}
              >
                <TableCell {...excelTableCommonCellStyles}>
                  {review.data.reviewQuestionTitle || '-'}
                </TableCell>
                {review.data.applicantReviewAnswer && (
                  <TableCell {...excelTableCommonCellStyles}>
                    {review.data.applicantReviewAnswer || '-'}
                  </TableCell>
                )}
                {applicantFullname && (
                  <TableCell {...excelTableCommonCellStyles}>
                    {applicantFullname}
                  </TableCell>
                )}
                {review.data.completionDate && (
                  <TableCell {...excelTableCommonCellStyles}>
                    {getLocaleDateAndTime(review.data.completionDate, '-')}
                  </TableCell>
                )}
                {review.data.applicantReviewFiles &&
                  review.data.applicantReviewFiles.length > 0 && (
                    <TableCell {...excelTableCommonCellStyles}>
                      {review.data.applicantReviewFiles
                        .map(getFileSource)
                        .join(' - ')}
                    </TableCell>
                  )}
              </React.Fragment>
            ))}
        </>
      ) : (
        <>
          {item.data.title && (
            <TableCell {...excelTableCommonCellStyles}>
              {item.data.title || '-'}
            </TableCell>
          )}
          {item.data.questionTitle && (
            <TableCell {...excelTableCommonCellStyles}>
              {item.data.questionTitle || '-'}
            </TableCell>
          )}
          {item.data.applicantAnswer && (
            <TableCell {...excelTableCommonCellStyles}>
              {item.data.applicantAnswer}
            </TableCell>
          )}
          {item.data.completionDate && (
            <TableCell {...excelTableCommonCellStyles}>
              {getLocaleDateAndTime(item.data.completionDate, '-')}
            </TableCell>
          )}
          {item.data.applicantAnswerFiles &&
            item.data.applicantAnswerFiles.length > 0 && (
              <TableCell {...excelTableCommonCellStyles}>
                {item.data.applicantAnswerFiles.map(getFileSource).join(' - ')}
              </TableCell>
            )}
        </>
      )}
    </React.Fragment>
  );
};

export default ExcelBodyProcessCell;

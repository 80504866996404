import {
  FormHelperText as MuiFormHelperText,
  FormHelperTextProps as MuiFormHelperTextProps,
  styled,
} from '@mui/material';

export interface FormHelperTextProps extends MuiFormHelperTextProps {}

const FormHelperText = styled(MuiFormHelperText)<FormHelperTextProps>(
  ({ theme }) => ({
    color: theme.palette.error.main,
  })
);

export default FormHelperText;

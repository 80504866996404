// Core Utilities
import { forLoop } from 'core/utilities/helper';
import { getNumberTitle } from 'core/utilities/helper/helperPack';

// Custom Types
import type { PatternTabItemProps } from 'features/appBuilder/patterns/core/types/item/tab';
import type { ContentPatternDataProps } from 'features/appBuilder/patterns/features/content/types';

/**
 * Validates the content of a tab and returns an error message if validation fails.
 *
 * @param {PatternTabItemProps} tab - The tab object to validate.
 * @param {number} index - The index of the tab in the list of tabs.
 * @returns {string} A string containing the error message if validation fails, or an empty string if valid.
 */
const validateContentTab = (
  tab: PatternTabItemProps,
  index: number
): string => {
  let tabTitle = `تب ${getNumberTitle(index + 1)}`;

  if (!tab.title.trim()) {
    return `وارد کردن عنوان تب (${tabTitle}) اجباری است.`;
  }

  if (!tab.refType) {
    return `وارد کردن نوع مرجع تب (${tabTitle}) اجباری است.`;
  }

  if (!tab.sourceId) {
    return `وارد کردن منبع تب (${tabTitle}) اجباری است.`;
  }

  return '';
};

/**
 * Validates a content pattern and returns an error message if validation fails.
 *
 * @param {ContentPatternDataProps} data - The content pattern data to validate.
 * @returns {string} A string containing the error message if validation fails, or an empty string if valid.
 */
export const validateContentPattern = (
  data: ContentPatternDataProps
): string => {
  let error = '';

  if (!data.patternTitle.trim()) {
    error = 'وارد کردن عنوان پترن الزامی می‌باشد.';
  }

  if (data.body.tabs.length === 0) {
    error = 'پترن کانتنت باید دارای حداقل یک تب باشد.';
  } else {
    forLoop(data.body.tabs, (tab, index) => {
      if (!error) {
        error = validateContentTab(tab, index);
      }
    });
  }

  return error;
};

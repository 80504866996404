// Custom Utilities
import apiHandler from 'core/utilities/apiHandler';
import { profileEndpoints as endpoints } from './endpoints';

// Custom Types
import type { TwoFactorAuthProps } from 'features/manager/auth/types';
import type { ApiCreateQRFor2FAProps } from 'features/manager/auth/types/api';

/**
 * Generates a two-factor authentication (2FA) QR code and returns relevant data.
 *
 * @returns {Promise<{ status: number; doc?: TwoFactorAuthProps }>} A promise that resolves to an object
 * containing the status code of the request and, if successful, the 2FA-related data (`doc`).
 */
export const create2FAQr = async (): Promise<{
  status: number;
  doc?: TwoFactorAuthProps;
}> => {
  const { status, data: response } = await apiHandler.get<{
    docs: ApiCreateQRFor2FAProps;
  }>(endpoints.createQR2FA);

  return {
    status,
    doc: {
      id: 'qr-data',
      data: {
        ascii: response?.docs?.ascii || '',
        imageSrc: response?.docs?.qr || '',
        key: response?.docs?.key || '',
      },
    },
  };
};

/**
 * Enables two-factor authentication (2FA) by verifying the provided code and secret.
 *
 * @param {string} code - The 2FA verification code entered by the user.
 * @param {string} ascii - The ASCII representation of the 2FA secret.
 * @returns {Promise<{status:number}>} A promise that resolves to a status code indicating the result of the 2FA activation.
 */
export const enable2FA = async (payload: {
  code: string;
  ascii: string;
}): Promise<{ status: number }> => {
  const data = new URLSearchParams();
  data.append('token', payload.code);
  data.append('ascii', payload.ascii);

  const { status } = await apiHandler.patch(endpoints.enable2FA, data);

  return { status };
};

/**
 * Disables two-factor authentication (2FA) for the current user.
 *
 * @returns {Promise<{status:number}>} A promise that resolves to a status code indicating the result of the 2FA deactivation.
 */
export const disable2FA = async (): Promise<{ status: number }> => {
  const { status } = await apiHandler.patch(endpoints.disable2FA);

  return { status };
};

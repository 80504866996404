import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface DataIconProps extends SvgIconProps {}

const DataIcon: React.FC<DataIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M4.2,3.1h15.6c0.6,0,1.2,0.2,1.6,0.7c0.4,0.4,0.7,1,0.7,1.6v13.3c0,0.6-0.2,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7H4.2
	c-0.6,0-1.2-0.2-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6V5.3c0-0.6,0.2-1.2,0.7-1.6C3.1,3.3,3.6,3.1,4.2,3.1z M4.2,7.6V12h6.7V7.6H4.2z
	 M13.1,7.6V12h6.7V7.6H13.1z M4.2,14.2v4.4h6.7v-4.4H4.2z M13.1,14.2v4.4h6.7v-4.4H13.1z'
    />
  </IconBuilder>
);

export default DataIcon;

import {
  Skeleton as MuiSkeleton,
  SkeletonProps as MuiSkeletonProps,
  styled,
} from '@mui/material';

export interface SkeletonProps extends MuiSkeletonProps {
  to?: string;
}

const Skeleton = styled(MuiSkeleton)<SkeletonProps>(() => ({}));

export default Skeleton;

import * as React from 'react';
import { useFormContext, Controller, SubmitHandler } from 'react-hook-form';

// Custom Core Components
import Checkbox from 'core/components/base/inputs/Checkbox';
import IconButton from 'core/components/base/inputs/IconButton';
import FormControlLabel from 'core/components/base/inputs/FormControlLabel';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingTwo from 'core/components/shared/Typography/HeadingTwo';
import TextFieldOutlined from 'core/components/shared/TextField/Outlined';
import SubmitLoadingButton from 'core/components/shared/LoadingButton/Submit';
import MultiAutocomplete from 'core/components/shared/Autocomplete/api/MultipleAutocomplete';

// Custom Icons
import DeleteIcon from 'core/components/icons/DeleteIcon';

// Custom Utilities
import { getErrorAndHelperText } from 'core/utilities/helper/helperPack';
import { audienceGroupsQueryKey } from 'features/audience/groups/hooks';
import { getAudienceGroupSummaryList } from 'features/audience/groups/utilities/api';

// Custom Types
import type { AudienceFolderDataProps } from 'features/audience/folders/types';

interface AudienceFolderFormProps {
  mode: 'create' | 'edit';
  disabled: boolean;
  folderId?: string;
  onFormSubmit: SubmitHandler<AudienceFolderDataProps>;
  onOpenDeleteDialog?: () => void;
}

const AudienceFolderForm: React.FC<AudienceFolderFormProps> = (props) => {
  // Props
  const { mode, disabled, folderId, onFormSubmit, onOpenDeleteDialog } = props;

  // Hooks
  const { control, formState, setValue, handleSubmit } =
    useFormContext<AudienceFolderDataProps>();

  // Render
  return (
    <>
      <PageHeroBox>
        <HeadingTwo>{mode === 'edit' ? 'ویرایش پوشه' : 'پوشه جدید'}</HeadingTwo>
        <RowStack>
          <Controller
            control={control}
            name='isActive'
            render={({ field: { value, ...otherProps } }) => (
              <FormControlLabel
                label='فعال'
                control={
                  <Checkbox
                    disabled={disabled}
                    color='info'
                    checked={value}
                    {...otherProps}
                  />
                }
              />
            )}
          />
          {mode === 'edit' && (
            <IconButton
              color='error'
              disabled={!folderId || disabled}
              onClick={onOpenDeleteDialog}
            >
              <DeleteIcon />
            </IconButton>
          )}
          <Controller
            control={control}
            name='title'
            render={({ field }) => (
              <SubmitLoadingButton
                disabled={!field.value || !formState.isValid}
                loading={disabled}
                onClick={handleSubmit(onFormSubmit)}
              >
                ذخیره
              </SubmitLoadingButton>
            )}
          />
        </RowStack>
      </PageHeroBox>
      <RoundPaper sx={{ padding: '3rem' }}>
        <ColumnStack spacing={4} sx={{ width: '27rem', mx: 'auto' }}>
          <InputWrapper required label='عنوان پوشه'>
            <Controller
              control={control}
              name='title'
              render={({ field, fieldState }) => (
                <TextFieldOutlined
                  disabled={disabled}
                  placeholder='عنوان پوشه'
                  {...field}
                  {...getErrorAndHelperText(fieldState)}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper label='ظرفیت پوشه (مگابایت)'>
            <Controller
              control={control}
              name='capacity'
              rules={{
                onChange: (e) => {
                  if (e.target.value)
                    setValue('capacity', e.target.value.replace(/^0+/, ''));
                  else setValue('capacity', 0);
                },
              }}
              render={({ field, fieldState }) => (
                <TextFieldOutlined
                  dir='ltr'
                  type='number'
                  disabled={disabled}
                  placeholder='ظرفیت پوشه'
                  {...field}
                  {...getErrorAndHelperText(fieldState)}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper label='ویرایشگر'>
            <Controller
              control={control}
              name='updater'
              render={({ field }) => (
                <MultiAutocomplete
                  title='گروه مخاطبان'
                  queryKey={audienceGroupsQueryKey}
                  apiHandler={getAudienceGroupSummaryList}
                  disabled={disabled}
                  sx={{ flexGrow: 1, minWidth: '10rem' }}
                  defaultValue={field.value.map((group) => group.id)}
                  onChange={(_, audiences) => field.onChange(audiences)}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper label='مشاهده کننده'>
            <Controller
              control={control}
              name='reader'
              render={({ field }) => (
                <MultiAutocomplete
                  title='گروه مخاطبان'
                  queryKey={audienceGroupsQueryKey}
                  apiHandler={getAudienceGroupSummaryList}
                  disabled={disabled}
                  sx={{ flexGrow: 1, minWidth: '10rem' }}
                  defaultValue={field.value.map((group) => group.id)}
                  onChange={(_, audiences) => field.onChange(audiences)}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper label='آپلود کننده'>
            <Controller
              control={control}
              name='creator'
              render={({ field }) => (
                <MultiAutocomplete
                  title='گروه مخاطبان'
                  queryKey={audienceGroupsQueryKey}
                  apiHandler={getAudienceGroupSummaryList}
                  disabled={disabled}
                  sx={{ flexGrow: 1, minWidth: '10rem' }}
                  defaultValue={field.value.map((group) => group.id)}
                  onChange={(_, audiences) => field.onChange(audiences)}
                />
              )}
            />
          </InputWrapper>
        </ColumnStack>
      </RoundPaper>
    </>
  );
};

export default AudienceFolderForm;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialFile } from 'features/file/files/utilities/file';

// Custom Types
import type { FileProps } from 'features/file/files/types';

interface FileStore {
  file: {
    data: FileProps;
  };
}

const initialState: FileStore = {
  file: {
    data: initialFile,
  },
};

const slice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    // =============> File <=============
    setFileData: (state, action: PayloadAction<FileProps>) => {
      state.file.data = action.payload;
      return state;
    },
  },
});

export const { setFileData } = slice.actions;

export default slice.reducer;

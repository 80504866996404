import { ApiAudienceFoldersDataProps } from 'features/audience/folders/types/api';
import { AudienceFolderDataProps } from 'features/audience/folders/types';

interface ApiAddAudienceFolderData
  extends Pick<
    ApiAudienceFoldersDataProps,
    'title' | 'capacity' | 'tags' | 'isActive'
  > {
  creator: string[];
  reader: string[];
  updater: string[];
}

/**
 * Transforms an audience folder object into the format required for the API to add a new folder.
 *
 * @param {AudienceFolderDataProps} folder - The audience folder data to transform.
 * @returns {ApiAddAudienceFolderData} The transformed audience folder data suitable for the API.
 */
export const bakeAudienceFolder = (
  folder: AudienceFolderDataProps
): ApiAddAudienceFolderData => {
  return {
    title: folder.title,
    capacity: folder.capacity * 1000000, // MB to Byte
    creator: folder.creator.map((folder) => folder.id),
    reader: folder.reader.map((folder) => folder.id),
    updater: folder.updater.map((folder) => folder.id),
    tags: folder.tags,
    isActive: folder.isActive,
  };
};

import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { menuContainerSchema } from 'features/menu/menus/validations';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';
import useUpdateEffect from 'core/hooks/useUpdateEffect';
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// Custom Core Components
import Collapse from 'core/components/base/utils/Collapse';
import Paper from 'core/components/base/surfaces/Paper';
import IconButton from 'core/components/base/inputs/IconButton';
import TextField from 'core/components/base/inputs/TextField';
import Button from 'core/components/base/inputs/Button';

// Custom Common Components
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import RowStack from 'core/components/shared/Stack/RowStack';

// Context
import { useMenuModeContext } from 'core/store/context/feature/menu/MenuMode';
import {
  setMenuContainerTitle,
  useSelectMenuContainerId,
  useSelectMenuContainerTitle,
} from 'core/store/slices/feature/menu';

// Custom Icons
import SettingsIcon from 'core/components/icons/Settings';

// Custom Utilities
import { getErrorAndHelperText } from 'core/utilities/helper/helperPack';
import {
  useAddMenuContainerMutation,
  useEditMenuContainerMutation,
} from 'features/menu/menus/hooks';

// Custom Types
import type { MenuContainerDataProps } from 'features/menu/menus/types';

interface MenuContainerProps {}

const MenuContainer: React.FC<MenuContainerProps> = (props) => {
  // Context
  const containerTitle = useSelectMenuContainerTitle();
  const containerId = useSelectMenuContainerId();
  const crudMode = useMenuModeContext();

  // States
  const collapsed = useBoolean(crudMode === 'create');

  // Hooks
  const navigate = useNavigate();
  const resolver = useYupValidationResolver(menuContainerSchema);
  const { control, handleSubmit, reset } = useForm<MenuContainerDataProps>({
    mode: 'onTouched',
    resolver,
    defaultValues: {
      title: containerTitle || '',
    },
  });

  const { mutate: handleAddContainer } = useAddMenuContainerMutation({
    autoAlert: { mode: 'create', name: 'فهرست' },
    onSuccess: ({ data: container }) => navigate(`/menus/${container?.id}`),
  });

  const { mutate: handleEditContainer } = useEditMenuContainerMutation({
    autoAlert: { mode: 'edit', name: 'فهرست' },
    onSuccess: ({ data: container }) => {
      setMenuContainerTitle(container?.data?.title || '');
      collapsed.setFalse();
    },
  });

  useUpdateEffect(() => handleReset(), [containerTitle]);

  // Utilities
  const handleToggle = () => {
    if (collapsed.state) handleReset();
    collapsed.toggle();
  };

  const handleReset = () => {
    if (containerId)
      setTimeout(() => reset({ title: containerTitle || '' }), 300);
  };

  const handleCancel = () => {
    collapsed.setFalse();
    handleReset();
  };

  const onSubmit: SubmitHandler<MenuContainerDataProps> = async ({ title }) => {
    if (crudMode === 'create') handleAddContainer(title);
    else handleEditContainer({ id: containerId, data: title });
  };

  // Render
  return (
    <Paper
      component='form'
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '18px',
        padding: '1.25rem 1.5rem',
        borderRadius: '.5rem',
        boxShadow: 'none',
      }}
    >
      <SpaceBetween alignItems='center'>
        <BodyTwo
          sx={{
            fontWeight: 400,
            fontSize: '1rem',
          }}
        >
          تنظیمات فهرست
        </BodyTwo>
        <IconButton onClick={handleToggle}>
          <SettingsIcon
            sx={{
              rotate: collapsed.state ? '160deg' : '0deg',
              transition: 'all .2s',
            }}
          />
        </IconButton>
      </SpaceBetween>
      <Collapse in={collapsed.state} unmountOnExit>
        <ColumnStack spacing='1rem'>
          <ColumnStack
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              backgroundColor: '#f5f5f5',
              padding: '1rem 1.5rem',
              borderRadius: '.5rem',
            }}
          >
            <ColumnStack spacing={0}>
              <InputLabel>عنوان فهرست</InputLabel>
              <Controller
                control={control}
                name='title'
                render={({ field, fieldState }) => (
                  <TextField
                    {...getErrorAndHelperText(fieldState)}
                    {...field}
                  />
                )}
              />
            </ColumnStack>
          </ColumnStack>
          <RowStack width='100%' justifyContent='end'>
            <Button onClick={handleCancel} variant='outlined' color='error'>
              انصراف
            </Button>
            <Button type='submit' variant='outlined' color='info'>
              ذخیره
            </Button>
          </RowStack>
        </ColumnStack>
      </Collapse>
    </Paper>
  );
};

export default MenuContainer;

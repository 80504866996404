import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Core Components
import Typography, {
  TypographyProps,
} from 'core/components/base/display/Typography';

interface ErrorTextProps extends TypographyProps {}

const ErrorText: React.FC<ErrorTextProps> = (props) => {
  const {
    children,
    component = 'p',
    variant = 'caption',
    ...otherProps
  } = props;

  const theme = useTheme();

  return (
    <Typography
      component={component}
      variant={variant}
      sx={{
        color: theme.palette.error.main,
      }}
      {...otherProps}
    >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {children}
    </Typography>
  );
};

export default ErrorText;

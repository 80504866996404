// Custom Utilities
import { generateObjectId } from 'core/utilities/helper/id';
import { getInitialCommonInputProps } from 'features/form/forms/utilities/input/initial/common';

// Custom Types
import type { FormInputInitialParams } from 'features/form/forms/utilities/input/initial/common';
import type { FormDateInputProps } from 'features/form/forms/types/item/input/date';

interface Params extends FormInputInitialParams {}

export const genFormDateInput = (params: Params): FormDateInputProps => {
  const commonProps = getInitialCommonInputProps({
    ...params,
  });

  return {
    id: `draft-${generateObjectId()}`,
    data: {
      ...commonProps,
      label: 'تاریخ',
      calendarType: 'jalali',
      pick: 'yyyy/mm/dd',
      type: 'date',
    },
  };
};

// Custom Utilities
import apiHandler from 'core/utilities/apiHandler';
import getEndpoint from 'core/utilities/helper/getEndpoint';
import { getDocs, getSummary } from 'core/utilities/apiHandler/helper';
import { getPaginate } from 'core/utilities/pagination/pagination';

// Custom Types
import type { ApiPaginationProps } from 'core/types/shared/pagination/api';
import type {
  ApiGetDocsResponse,
  ApiGetSummaryResponse,
} from 'core/types/api/hook/response';
import type {
  BannerContainerDataProps,
  BannerContainerProps,
  BannerContainerSummaryProps,
} from 'features/banner/banners/types';

export const getBanners = async (
  signal: AbortSignal,
  queries?: Record<string, any>
): Promise<ApiGetDocsResponse<BannerContainerProps>> => {
  const { getBannersURL } = getEndpoint();

  return await getDocs<
    { banners: BannerContainerProps[]; paginate: ApiPaginationProps },
    BannerContainerProps
  >(getBannersURL, {
    queries,
    signal,
    returnMutationFn: (status, data) => ({
      status,
      list: data?.banners || [],
      page: getPaginate(data?.paginate),
    }),
  });
};

/**
 * Retrieves the summary list of banner containers from the server.
 *
 * @param {AbortSignal} signal - The cancel signal.
 * @returns {Promise<ApiGetSummaryResponse<BannerContainerSummaryProps>>} - A promise that resolves to an object containing the status code and an array of banner container summaries.
 */
export const getBannerSummaryList = async (
  signal?: AbortSignal
): Promise<ApiGetSummaryResponse<BannerContainerSummaryProps>> => {
  const endpoint = getEndpoint().getBannerListURL;

  return await getSummary<
    BannerContainerSummaryProps[],
    BannerContainerSummaryProps
  >(endpoint, { signal });
};

export const deleteBannerContainer = async (
  containerId: string
): Promise<{ status: number }> => {
  const endpoint = `${getEndpoint().deleteBannerURL}/${containerId}`;
  const { status } = await apiHandler.delete(endpoint);
  return { status };
};

/**
 * Creates a new banner and send it to the server.
 *
 * @param {BannerContainerDataProps} container - The banner container object to add.
 * @param {AbortSignal} signal - The abort signal to cancel the request.
 * @returns {Promise<{ status: number; banner?: BannerContainerProps }>} - A promise that resolves to an object containing the status code and the added banner.
 */
export const addBanner = async (
  container: BannerContainerDataProps,
  signal?: AbortSignal
): Promise<{ status: number; banner?: BannerContainerProps }> => {
  const endpoint = getEndpoint().addBannerURL;

  const { status, data } = await apiHandler.post<{
    docs: BannerContainerProps;
  }>(endpoint, container, {
    signal,
  });

  return { status, banner: data?.docs };
};

/**
 * Edits an existing banner on the server.
 *
 * @param {string} containerId - The banner container id.
 * @param {BannerContainerDataProps} container - The updated banner container object.
 * @param {AbortSignal} signal - The abort signal to cancel the request.
 * @returns {Promise<{ status: number; banner: BannerContainerProps }>} - A promise that resolves to an object containing the status code and the edited banner container.
 */
export const editBanner = async (
  containerId: string,
  container: BannerContainerDataProps,
  signal?: AbortSignal
): Promise<{ status: number; banner?: BannerContainerProps }> => {
  const endpoint = `${getEndpoint().editBannerURL}/${containerId}`;

  const { status, data } = await apiHandler.patch<{
    docs: BannerContainerProps;
  }>(endpoint, container, {
    signal,
  });

  return { status, banner: data?.docs };
};

import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// Types
import { SubmitHandler } from 'react-hook-form';

// Custom HOCs
import WithAuth from 'core/components/shared/HOC/WithAuth';

// Custom Components
import SubmitLoadingButton from 'core/components/shared/LoadingButton/Submit';
import RowStack from 'core/components/shared/Stack/RowStack';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import FileUploader from 'core/components/shared/Input/NewFileUploader';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import MultiAutocomplete from 'core/components/shared/Autocomplete/api/MultipleAutocomplete';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Custom Core Components
import Radio from 'core/components/base/inputs/Radio';
import TextField from 'core/components/base/inputs/TextField';
import RadioGroup from 'core/components/base/inputs/RadioGroup';
import FormControlLabel from 'core/components/base/inputs/FormControlLabel';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// Custom Utilities
import { convertNumberToEnglish } from 'core/utilities/helper/helperPack';
import { setAppAlert, setAppLoading } from 'core/utilities/helper';
import { getAudienceGroupSummaryList } from 'features/audience/groups/utilities/api';
import { useAddAudienceMutation } from 'features/audience/audiences/hooks';
import { audienceGroupsQueryKey } from 'features/audience/groups/hooks';

// Custom Validation Schemas
import AddAudienceValidationSchema from 'features/audience/audiences/validations/Add';

// Custom Types
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';

interface AddAudiencePageProps extends WithAuthProps {}

interface FormInputs {
  phoneNumbers: string;
  jobTitle: string;
  groups: string[];
}

const AddAudience: React.FC<AddAudiencePageProps> = (props) => {
  // Context
  const loading = useSelectLoading();

  // Hooks
  const navigate = useNavigate();
  const resolver = useYupValidationResolver(AddAudienceValidationSchema);
  const { control, formState, handleSubmit, setValue, reset } =
    useForm<FormInputs>({
      mode: 'onTouched',
      resolver,
      defaultValues: { groups: [], jobTitle: '', phoneNumbers: '' },
    });
  const { mutate: handleAddAudience } = useAddAudienceMutation({
    onSuccess: (_, { phoneNumbers }) => {
      const plural = phoneNumbers.length > 1;
      setAppAlert(
        `${plural ? 'مخاطبان' : 'مخاطب'} با موفقیت افزوده ${
          plural ? 'شدند' : 'شد'
        }.`,
        'success'
      );
      navigate('/audiences');
      reset({
        jobTitle: '',
        phoneNumbers: '',
      });
    },
    onError: (_, { phoneNumbers }) => {
      const plural = phoneNumbers.length > 1;
      setAppAlert(
        `خطا در افزودن ${plural ? 'مخاطبان' : 'مخاطب'} وارد شده.`,
        'error'
      );
    },
  });

  // Utilities
  const handleFormSubmit: SubmitHandler<FormInputs> = async (data) => {
    setAppLoading(true);

    handleAddAudience({
      audienceGroups: data.groups,
      jobTitle: data.jobTitle,
      phoneNumbers: data.phoneNumbers.split('\n'),
    });
  };

  return (
    <>
      <PageHeadConfigs pageTitle='افزودن مخاطبان' />
      <PageHeroBox>
        <HeadingSix>افزودن مخاطبان</HeadingSix>
        <SubmitLoadingButton
          disabled={!formState.isValid}
          onClick={handleSubmit(handleFormSubmit)}
          loading={loading}
        >
          ذخیره
        </SubmitLoadingButton>
      </PageHeroBox>
      <RoundPaper>
        <RadioGroup sx={{ gap: 4, maxWidth: '43rem', margin: '1.5rem auto' }}>
          <ColumnStack spacing={1}>
            <FormControlLabel
              value='manual'
              control={<Radio checked />}
              label='افزودن با لیست شماره:'
            />
            <BodyTwo>
              پس از افزودن لیست شماره‌ها، مخاطبان می‌توانند با عنوان شغلی انتخاب
              شده در سایت و با سطح دسترسی گروه مخاطبین تعیین شده به بخش‌های مجاز
              خود دسترسی داشته باشند.
            </BodyTwo>
            <RowStack>
              <ColumnStack spacing={0} sx={{ width: '48%' }}>
                <InputLabel>شماره موبایل‌ها</InputLabel>
                <Controller
                  control={control}
                  name='phoneNumbers'
                  rules={{
                    onChange(event) {
                      const value = event.target.value;

                      const converted = convertNumberToEnglish(value);
                      setValue('phoneNumbers', converted);
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      disabled={loading}
                      error={
                        fieldState.isTouched && formState.errors.phoneNumbers
                          ? true
                          : false
                      }
                      helperText={
                        fieldState.isTouched &&
                        formState.errors.phoneNumbers?.message
                      }
                      multiline
                      rows={7}
                      fullWidth
                      placeholder={`در هر خط یک شماره وارد کنید.\n 09123456789 \n 09123456780`?.toPersian()}
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
              <ColumnStack sx={{ width: '48%' }}>
                <ColumnStack spacing={0}>
                  <InputLabel>عنوان شغلی</InputLabel>
                  <Controller
                    control={control}
                    name='jobTitle'
                    render={({ field }) => (
                      <TextField
                        disabled={loading}
                        fullWidth
                        placeholder='نام شغل'
                        {...field}
                      />
                    )}
                  />
                </ColumnStack>
                <ColumnStack spacing={0}>
                  <InputLabel>گروه مخاطبان</InputLabel>
                  <Controller
                    control={control}
                    name='groups'
                    render={({ field }) => (
                      <MultiAutocomplete
                        title='گروه مخاطبان'
                        sx={{ flexGrow: 1, minWidth: '10rem' }}
                        apiHandler={getAudienceGroupSummaryList}
                        queryKey={audienceGroupsQueryKey}
                        defaultValue={field.value}
                        onChange={(audiences) => field.onChange(audiences)}
                      />
                    )}
                  />
                </ColumnStack>
              </ColumnStack>
            </RowStack>
          </ColumnStack>
          <ColumnStack spacing={1}>
            <FormControlLabel
              disabled
              value='excel'
              control={<Radio disabled />}
              label='افزودن با فایل اکسل:'
            />
            <BodyTwo disabled>
              پس از افزودن فایل Xlsx، مخاطبان می‌توانند با عنوان شغلی انتخاب شده
              و با سطح دسترسی گروه مخاطبین تعیین شده به بخش‌های مجاز خود دسترسی
              داشته باشند. (ترتیب ستون‌ها از چپ به راست و با عناوین انگلیسی وارد
              شود: نام، نام‌خانوادگی، موبایل، کدملی، کدپرسنلی، تاریخ تولد، عنوان
              شغلی، گروه ماطبان، نام فرد تحت تکفل اول، نام‌خانوادگی فرد تحت تکفل
              اول، کدملی فرد تحت تکفل اول، رابطه فرد تحت تکفل اول):
            </BodyTwo>
            <BodyTwo dir='ltr' disabled>
              Name, LastName, Mobile, NationalId, PersonalId, Birthdate, Job,
              Group, D1Name, D1LastName, D1NationalId, D1Relation, D2Name,
              D2LastName, D2NationalId, D2Relation, ...
            </BodyTwo>
            <FileUploader
              location='audience'
              disabled
              sx={{
                width: '100%',
                minHeight: '200px',
              }}
            />
          </ColumnStack>
        </RadioGroup>
      </RoundPaper>
    </>
  );
};

export default WithAuth(AddAudience);

import { useCallback, useEffect } from 'react';

// Types
import { FC } from 'react';

// Custom Common Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import CenterBox from 'core/components/shared/Box/CenterBox';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Custom Core Components
import CircularProgress from 'core/components/base/feedback/CircularProgress';

// Custom Utilities
import { logout } from 'features/manager/auth/utilities/api/login';
import { removeAllCookies } from 'core/utilities/cookie';

const Logout: FC = () => {
  // Hooks
  const handleLogout = useCallback(async () => {
    const { status } = await logout();

    if (status === 200) {
      removeAllCookies();
      if (window) window.location.href = '/login';
    } else {
    }
  }, []);

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return (
    <CenterBox>
      <ColumnStack alignItems='center'>
        <CircularProgress size={24} sx={{ color: 'text.primary' }} />
        <BodyOne>لطفا منتظر بمانید...</BodyOne>
      </ColumnStack>
    </CenterBox>
  );
};

export default Logout;

import { useState, type MouseEvent, type FC } from 'react';
import type { Editor } from '@tiptap/react';

// Core Components
import IconButton from 'core/components/base/inputs/IconButton';
import H2Icon from 'core/components/icons/H2';
import H3Icon from 'core/components/icons/H3';
import H4Icon from 'core/components/icons/H4';
import H5Icon from 'core/components/icons/H5';
import H6Icon from 'core/components/icons/H6';
import Menu from 'core/components/base/navigation/Menu';
import MenuItem from 'core/components/base/navigation/MenuItem';

// Component Types
interface EditorHeadingIconButtonProps {
  editor: Editor | null;
}

const headingOptions = [
  { icon: <H2Icon />, level: 2 },
  { icon: <H3Icon />, level: 3 },
  { icon: <H4Icon />, level: 4 },
  { icon: <H5Icon />, level: 5 },
  { icon: <H6Icon />, level: 6 },
];

const EditorHeadingIconButton: FC<EditorHeadingIconButtonProps> = (props) => {
  // Hooks
  const { editor } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Utilities
  const isHeading = () => {
    if (editor?.isActive('heading')) return true;
    return false;
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        color={isHeading() ? 'info' : 'inherit'}
        size='small'
      >
        {editor?.isActive('heading', { level: 3 }) ? (
          <H3Icon />
        ) : editor?.isActive('heading', { level: 4 }) ? (
          <H4Icon />
        ) : editor?.isActive('heading', { level: 5 }) ? (
          <H5Icon />
        ) : editor?.isActive('heading', { level: 6 }) ? (
          <H6Icon />
        ) : (
          <H2Icon />
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              boxShadow: 'none',
              border: '1px solid',
              borderColor: 'divider',
            },
          },
        }}
        MenuListProps={{
          sx: { boxShadow: 'none', p: 0 },
        }}
      >
        {headingOptions.map((opt) => (
          <MenuItem
            sx={{
              p: 1,
              color: editor?.isActive('heading', { level: opt.level })
                ? 'info.main'
                : 'inherit',
            }}
            onClick={() => {
              editor
                ?.chain()
                .focus()
                .toggleHeading({ level: opt.level as 1 | 2 | 3 | 4 | 5 | 6 })
                .run();
            }}
            key={'editor-heading-option-for-h' + opt.level}
          >
            {opt.icon}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default EditorHeadingIconButton;

import * as React from 'react';

// Custom Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Custom Core
import Box from 'core/components/base/layout/Box';

// Custom icon
import HeadphonesIcon from 'core/components/icons/Headphones';
import PdfIcon from 'core/components/icons/PDF';

// Custom Utilities
import { prefixFileURL } from 'core/utilities/helper/link';
import { isSame } from 'core/utilities/helper/helperPack';

// Custom Types
import type { FileProps } from 'features/file/files/types';

interface PreviewSmallProps {
  fileData: FileProps;
  objectFit?: 'cover' | 'contain';
}

const PreviewSmall: React.FC<PreviewSmallProps> = (props) => {
  const { fileData, objectFit = 'cover' } = props;
  const fileType = fileData.data.type;

  // Utilities
  const ImageRoot = (
    <img
      alt=''
      loading='eager'
      src={prefixFileURL(fileData.data.url)}
      style={{
        width: 64,
        height: 64,
        objectFit,
        borderRadius: '.5rem',
      }}
    />
  );

  const DocumentRoot = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 64,
        height: 64,
        backgroundColor: '#d9d9d9',
        borderRadius: '.5rem',
      }}
    >
      <PdfIcon />
    </Box>
  );

  const VideoRoot = (
    <video
      controls={false}
      autoPlay={false}
      playsInline={false}
      disablePictureInPicture
      disableRemotePlayback
      src={prefixFileURL(fileData.data.url)}
      style={{
        width: 64,
        height: 64,
        objectFit,
        borderRadius: '.5rem',
      }}
    />
  );

  const AudioRoot = (
    <ColumnStack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 64,
          height: 64,
          backgroundColor: '#d9d9d9',
          borderRadius: '.5rem',
        }}
      >
        <HeadphonesIcon
          sx={{
            color: '#12252E',
            fillOpacity: '0.5 !important',
            fontSize: '2.5rem',
          }}
        />
      </Box>
    </ColumnStack>
  );

  // Render
  switch (fileType) {
    case 'image':
      return ImageRoot;
    case 'document':
      return DocumentRoot;
    case 'audio':
      return AudioRoot;
    case 'video':
      return VideoRoot;
    default:
      return DocumentRoot;
  }
};

export default React.memo(PreviewSmall, (prev, next) =>
  isSame(prev.fileData, next.fileData)
);

import * as React from 'react';

// Custom Common Components
import IconButton from 'core/components/base/inputs/IconButton';
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Icons
import DeleteIcon from 'core/components/icons/DeleteIcon';
import RestartAlt from 'core/components/icons/RestartAlt';

// Custom Utilities
import {
  isSucceed,
  setAppAlert,
  setAppLoading,
  setSuccessCrudAlert,
} from 'core/utilities/helper';
import {
  deleteProcessItem,
  resetProcessItem,
} from 'features/form/feedbacks/utilities/api/details/process';

// Context
import { useSelectProcess } from 'features/form/feedbacks/store';
import { useDialogContext } from 'core/store/context/DialogProvider';

// Custom Types
import type { ProcessItemType } from 'features/form/processes/types/details/common/type';

interface ProcessActionsProps {
  type: ProcessItemType;
  showDeleteButton: boolean;
  showResetButton: boolean;
}

const ProcessActions: React.FC<ProcessActionsProps> = (props) => {
  // Props
  const { showDeleteButton, showResetButton, type } = props;

  // Context
  const process = useSelectProcess();
  const feedbackId = process?.data?.feedbackId || '';
  const { onOpenDialog } = useDialogContext();
  const itemName = type === 'evaluation' ? 'ارزیابی' : 'پرسش';

  // Utilities
  const handleOpenDialog = (
    handler: () => Promise<void>,
    actionName: 'restart' | 'delete'
  ) =>
    onOpenDialog({
      onSubmit: handler,
      title: `آیا مطمئن هستید؟`,
      submitTitle: actionName === 'restart' ? 'ریست' : 'حذف',
      description:
        actionName === 'restart'
          ? `با ریست کردن این ${itemName}، اطلاعات ثبت شده در این ${itemName} در این بازخورد حذف خواهند شد و فرایند مجددا از همین ${itemName} ادامه پیدا خواهد کرد.`
          : `با حذف این ${itemName}، اطلاعات ثبت شده در این ${itemName} در این بازخورد حذف خواهند شد و فرایند از ${itemName} قبلی ادامه پیدا خواهد کرد.`,
    });

  const handleDelete = async () => {
    if (!feedbackId) return;
    setAppLoading(true);
    const { status, process } = await deleteProcessItem(feedbackId);
    if (isSucceed(status)) {
      setSuccessCrudAlert('delete', itemName);
      setTimeout(() => location.reload(), 1000);
    } else setAppAlert(`خطا در حذف ${itemName}`);
    setAppLoading(false);
  };
  const handleRestart = async () => {
    if (!feedbackId) return;
    setAppLoading(true);
    const { status, process } = await resetProcessItem(feedbackId);
    if (isSucceed(status)) {
      setAppAlert(`ریست‌‌ ${itemName} با موفقیت انجام شد.`);
      setTimeout(() => location.reload(), 1000);
    } else setAppAlert(`خطا در ریست ${itemName}`);
    setAppLoading(false);
  };

  // Render
  return (
    <RowStack alignItems='center' spacing='.25rem' id='hide-on-print'>
      {showResetButton && (
        <IconButton onClick={() => handleOpenDialog(handleRestart, 'restart')}>
          <RestartAlt />
        </IconButton>
      )}
      {showDeleteButton && (
        <IconButton
          color='error'
          onClick={() => handleOpenDialog(handleDelete, 'delete')}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </RowStack>
  );
};

export default ProcessActions;

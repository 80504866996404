// Custom Page Components
import Cats from 'features/content/categories/components/list';

// Custom Utilities
import { getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'content';
const subFeature: SubFeatureType = 'categories';
const categoryRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/contents/categories',
    Component: Cats,
    feature,
    subFeature,
  },
];

export default categoryRoutes;

import type { FC } from 'react';

// Feature Components
import ContentPatternBodySection from 'features/appBuilder/patterns/features/content/components/Body';
import ContentPatternConfigSection from 'features/appBuilder/patterns/features/content/components/Config';
import ContentPatternButtonSection from 'features/appBuilder/patterns/features/content/components/Button';

// Custom Types
export interface ContentPatternItemsProps {}

const ContentPatternItems: FC<ContentPatternItemsProps> = () => {
  return (
    <>
      <ContentPatternBodySection />
      <ContentPatternConfigSection />
      <ContentPatternButtonSection />
    </>
  );
};

export default ContentPatternItems;

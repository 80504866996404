// Custom Hooks
import useDocApi from 'core/hooks/api/useDoc';
import useDocsApi from 'core/hooks/api/useDocs';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  createTemplateBody,
  deleteTemplateBody,
  duplicateTemplateBody,
  getTemplate,
  getTemplates,
  updateTemplateBody,
} from 'features/appBuilder/templates/utilities/api';

// Custom Types
import type { TemplateProps } from 'features/appBuilder/templates/types';
import type { TemplateBodyDataProps } from 'features/appBuilder/templates/types/body';
import type { TemplateFeatureType } from 'features/appBuilder/templates/types/featureType';
import type { TemplateListItemProps } from 'features/appBuilder/templates/types/listItem';
import type {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';

/**
 * Query key for fetching templates in react query.
 *
 * @constant {Array<string>}
 */
export const templatesQueryKey = ['templates'];

/**
 * Custom hook to fetch a template document.
 *
 * This hook utilizes the `useDocApi` to retrieve a specific template based on the provided options.
 *
 * @param {UseDocOptionType<TemplateProps>} [options] - Optional configuration options for fetching the template document.
 * @returns {UseDocApiReturnProps<TemplateProps>} The result of the `useDocApi` hook, which includes the fetched template document, loading state, and any errors.
 */
export const useTemplateDoc = (
  options?: UseDocOptionType<TemplateProps>
): UseDocApiReturnProps<TemplateProps> =>
  useDocApi<TemplateProps>(
    (type, signal) => getTemplate(type as TemplateFeatureType, signal),
    templatesQueryKey,
    options
  );

/**
 * Custom hook to fetch templates list.
 *
 * This hook utilizes the `useDocsApi` to retrieve templates based on the provided options.
 *
 * @param {UseDocsOptionType<TemplateListItemProps>} [options] - Optional configuration options for fetching the templates list.
 * @returns {UseDocsApiReturnProps<TemplateListItemProps>} The result of the `useDocApi` hook, which includes the fetched templates list, loading state, and any errors.
 */
export const useTemplateDocs = (
  options?: UseDocsOptionType<TemplateListItemProps>
): UseDocsApiReturnProps<TemplateListItemProps> =>
  useDocsApi<TemplateListItemProps>(getTemplates, templatesQueryKey, options);

/**
 * Custom hook for duplicating a template body
 *
 * @returns {MutationResult<string>} The result of the duplicate mutation.
 */
export const useDuplicateTemplateBodyMutation = useAddMutation<string>(
  duplicateTemplateBody,
  templatesQueryKey
);

/**
 * Custom hook for adding a new template body
 *
 * @returns {MutationResult<string>} The result of the add mutation.
 */
export const useAddTemplateBodyMutation = useAddMutation(
  createTemplateBody,
  templatesQueryKey
);

/**
 * Custom hook for updating an existing template body
 *
 * @returns {MutationResult<TemplateBodyDataProps>} The result of the update mutation.
 */
export const useUpdateTemplateBodyMutation =
  useEditMutation<TemplateBodyDataProps>(updateTemplateBody, templatesQueryKey);

/**
 * Custom hook for deleting a template body
 *
 * @returns {MutationResult<void>} The result of the delete mutation.
 */
export const useDeleteTemplateBodyMutation = useDeleteMutation(
  deleteTemplateBody,
  templatesQueryKey
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Utilities

const initialState = () => {
  const localNavigationData = localStorage.getItem('Navigation');
  return localNavigationData
    ? JSON.parse(localNavigationData)
    : { activeSidebarGroupId: '' };
};

interface NavigationsProps {
  activeSidebarGroupId: string;
}
export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    updateActiveSidebarGroup: (
      state: NavigationsProps,
      action: PayloadAction<NavigationsProps>
    ) => {
      state = action.payload;
      localStorage.setItem('Navigation', JSON.stringify(state));
      return state;
    },
  },
});

export const { updateActiveSidebarGroup } = navigationSlice.actions;
export default navigationSlice.reducer;

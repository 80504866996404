// Custom Hooks
import useDocsApi from 'core/hooks/api/useDocs';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  addManager,
  editManager,
  getManager,
  getManagersList,
  removeManagers,
} from 'features/manager/managers/utilities/api';

// Custom Types
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';
import type {
  ManagerDataProps,
  ManagerListItemProps,
  ManagerProps,
} from 'features/manager/managers/types';
import useDocApi, {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';

/**
 * Query key for fetching managers in react query.
 *
 * @constant {Array<string>}
 */
export const managersQueryKey = ['managers'];

/**
 * Custom hook for fetching the list of managers.
 *
 * @param {UseDocsOptionType<ManagerListItemProps>} [options] - Optional configuration options for the query.
 * @returns {UseDocsApiReturnProps<ManagerListItemProps>} The result of the query, including the list of managers and other query states.
 */
export const useManagerDocs = (
  options?: UseDocsOptionType<ManagerListItemProps>
): UseDocsApiReturnProps<ManagerListItemProps> =>
  useDocsApi<ManagerListItemProps>(getManagersList, managersQueryKey, options);

/**
 * Custom hook for fetching a single manager document.
 *
 * @param {UseDocOptionType<ManagerProps>} [options] - Optional configuration options for the query.
 * @returns {UseDocApiReturnProps<ManagerProps>} The result of the query, including the manager document and other query states.
 */
export const useManagerDoc = (
  options?: UseDocOptionType<ManagerProps>
): UseDocApiReturnProps<ManagerProps> =>
  useDocApi<ManagerProps>(getManager, managersQueryKey, options);

/**
 * Custom hook for deleting managers.
 *
 * @returns {MutationFunction<string | string[]>} The mutation function to delete managers.
 */
export const useDeleteManagersMutation = useDeleteMutation<string | string[]>(
  removeManagers,
  managersQueryKey
);

/**
 * Custom hook for adding a new manager.
 *
 * @returns {MutationFunction<ManagerDataProps>} The mutation function to add a new manager.
 */
export const useAddManagerMutation = useAddMutation<ManagerDataProps>(
  addManager,
  managersQueryKey
);

/**
 * Custom hook for editing an existing manager.
 *
 * @returns {MutationFunction<Partial<ManagerDataProps>, { status: number; doc?: ManagerProps }>} The mutation function to edit a manager.
 */
export const useEditManagerMutation = useEditMutation<
  Partial<ManagerDataProps>,
  { status: number; doc?: ManagerProps }
>(editManager, managersQueryKey);

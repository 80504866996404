import * as React from 'react';

// Types
import type { FC } from 'react';

// Custom Common Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import CloseIconButton from 'core/components/shared/IconButton/Close';

// Custom Core Components
import Dialog from 'core/components/base/feedback/Dialog';
import DialogTitle from 'core/components/base/feedback/DialogTitle';
import DialogContent from 'core/components/base/feedback/DialogContent';

// Custom Types
import type { DialogProps } from 'core/components/base/feedback/Dialog';
interface AccessDialogProps extends DialogProps {
  failed: string[];
  passed: string[];
  onClose: () => void;
  onChangeSelection: (selectedIds: string[]) => void;
}

const AccessDialog: FC<AccessDialogProps> = (props) => {
  const {
    onClose,
    passed,
    failed,
    open,
    onChangeSelection,
    sx,
    ...otherProps
  } = props;

  return (
    <Dialog
      open={open}
      maxWidth='xl'
      PaperProps={{
        sx: {
          borderRadius: '0.5rem',
          boxShadow: 'none',
          padding: '1rem',
        },
      }}
      {...otherProps}
    >
      <CloseIconButton color='error' sx={{ ml: 'auto' }} onClick={onClose} />
      <DialogTitle whiteSpace='nowrap' textAlign='center'>
        درخواست افزودن {(failed.length + passed.length)?.toPersian()} مخاطب به
        اکسس انتخاب شده انجام شد.
      </DialogTitle>
      <DialogContent>
        <BodyOne align='center'>
          <BodyOne
            component='span'
            onClick={() => onChangeSelection(passed)}
            sx={{
              color: 'info.main',
              cursor: 'pointer',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {passed.length?.toPersian()} مخاطب
          </BodyOne>{' '}
          به اکسس انتخاب شده افزوده شد.
        </BodyOne>
        <BodyOne align='center'>
          <BodyOne
            component='span'
            onClick={() => onChangeSelection(failed)}
            sx={{
              color: 'info.main',
              cursor: 'pointer',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {failed.length?.toPersian()} مخاطب
          </BodyOne>{' '}
          به دلیل عضویت قبلی، در اکسس افزوده نشده‌اند.
        </BodyOne>
      </DialogContent>
    </Dialog>
  );
};

export default AccessDialog;

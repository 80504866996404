import {
  SvgIcon as MuiSvgIcon,
  SvgIconProps as MuiSvgIconProps,
  styled,
} from '@mui/material';

export interface SvgIconProps extends MuiSvgIconProps {}

const SvgIcon = styled(MuiSvgIcon)<SvgIconProps>(() => ({}));

export default SvgIcon;

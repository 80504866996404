import * as React from 'react';

// Custom Core Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface CellHeightIconProps extends SvgIconProps {}
const CellHeightIcon: React.FC<CellHeightIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M19,21c0-0.3-0.1-0.5-0.3-0.7C18.5,20.1,18.3,20,18,20H6c-0.3,0-0.5,0.1-0.7,0.3C5.1,20.5,5,20.7,5,21
	c0,0.3,0.1,0.5,0.3,0.7C5.5,21.9,5.7,22,6,22h12c0.3,0,0.5-0.1,0.7-0.3C18.9,21.5,19,21.3,19,21z'
    />
    <path
      d='M15.7,16.7c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7C15.5,15.1,15.3,15,15,15c-0.3,0-0.5,0.1-0.7,0.3L13,16.6V7.4
	l1.3,1.3C14.5,8.9,14.7,9,15,9c0.3,0,0.5-0.1,0.7-0.3C15.9,8.5,16,8.3,16,8c0-0.3-0.1-0.5-0.3-0.7l-3-3C12.5,4.1,12.3,4,12,4
	c-0.3,0-0.5,0.1-0.7,0.3l-3,3C8.1,7.5,8,7.7,8,8c0,0.3,0.1,0.5,0.3,0.7C8.5,8.9,8.7,9,9,9c0.3,0,0.5-0.1,0.7-0.3L11,7.4l0,9.2
	l-1.3-1.3c-0.1-0.1-0.2-0.2-0.3-0.2C9.3,15,9.1,15,9,15c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
	C8,15.7,8,15.9,8,16c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.3l3,3c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3L15.7,16.7z'
    />
    <path
      d='M19,3c0-0.3-0.1-0.5-0.3-0.7C18.5,2.1,18.3,2,18,2H6C5.7,2,5.5,2.1,5.3,2.3C5.1,2.5,5,2.7,5,3c0,0.3,0.1,0.5,0.3,0.7
	C5.5,3.9,5.7,4,6,4h12c0.3,0,0.5-0.1,0.7-0.3C18.9,3.5,19,3.3,19,3z'
    />
  </IconBuilder>
);

export default CellHeightIcon;

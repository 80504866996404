import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface FormatTextdirectionLToRIconProps extends SvgIconProps {}

const FormatTextdirectionLToRIcon: React.FC<
  FormatTextdirectionLToRIconProps
> = (props) => (
  <IconBuilder {...props}>
    <path d='M9 10v5h2V4h2v11h2V4h2V2H9C6.79 2 5 3.79 5 6s1.79 4 4 4m12 8-4-4v3H5v2h12v3z' />
  </IconBuilder>
);

export default FormatTextdirectionLToRIcon;

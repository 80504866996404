// Custom Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import AccessTitle from 'features/audience/accesses/components/Title';

// Custom Types
import type { DataGridColumn } from 'core/components/shared/DataGrid/dataGrid';

/**
 * Generates the column definitions for the audience access data grid.
 *
 * @param {(accessId: string) => void} onDelete - Callback function to handle the deletion of an access entry.
 * @param {(accessId: string, title: string) => Promise<void>} onUpdate - Callback function to handle the update of an access entry.
 * @returns {DataGridColumn[]} An array of column definitions for the data grid.
 */
export const getAccessDataGridColumns = (
  onDelete: (accessId: string) => void,
  onUpdate: (accessId: string, title: string) => Promise<void>
): DataGridColumn[] => [
  {
    field: 'col1',
    headerTitle: 'عنوان',
    width: 250,
    renderCell: (params) => (
      <AccessTitle
        title={params.row.col1}
        onDelete={() => onDelete(params.row.id)}
        onUpdate={(title) => onUpdate(params.row.id, title)}
      />
    ),
  },
  {
    field: 'col2',
    headerTitle: 'تعداد مخاطبان',
    renderCell: (params) => (
      <BodyOne>{(params.row.col2 || 0)?.toPersian()}</BodyOne>
    ),
  },
];

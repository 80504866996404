import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface EditIconProps extends SvgIconProps {}

const EditIcon: React.FC<EditIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M4.62,2A2.62,2.62,0,0,0,2,4.62V19.38A2.62,2.62,0,0,0,4.62,22H19.38A2.62,2.62,0,0,0,22,19.38V4.62A2.62,2.62,0,0,0,19.38,2ZM16.53,5.5a.42.42,0,0,1,.31.13l1.53,1.53a.44.44,0,0,1,0,.62l-.49.5L16.77,9.39,14.61,7.23l1.11-1.11.5-.49a.46.46,0,0,1,.31-.13ZM14.07,7.78l2.15,2.15L8.9,17.25,8.62,17,7,15.38l-.29-.28.58-.58ZM6.24,15.61l.08.08,2.07,2.07h0L8,17.87l-1.72.48-.49.14c-.21.05-.33-.07-.28-.28l.14-.49h0L6.13,16l.11-.39Z' />
  </IconBuilder>
);

export default EditIcon;

import type { FC } from 'react';

// Custom Feature Components
import UploaderInput from 'features/file/files/components/shared/Uploader/Input';
import UploderPreview from 'features/file/files/components/shared/Uploader/Preview';

// Custom Utilities
import { getFileSource } from 'core/utilities/helper/helperPack';

// Custom Types
import type { UploaderInputProps } from 'features/file/files/components/shared/Uploader/Input';
import type { FileProps } from 'features/file/files/types';

export interface SingularUploaderProps
  extends Omit<UploaderInputProps, 'value' | 'limit' | 'onChange'> {
  value?: FileProps;
  onChange?: (file: FileProps | null) => void;
}

const SingularUploader: FC<SingularUploaderProps> = (props) => {
  // Props
  const { disabled, value, onChange, ...otherProps } = props;

  // Utilities
  const handleChange = (files: FileProps[]) => {
    if (onChange) {
      if (files.length > 0) {
        onChange(files[0]);
      } else {
        onChange(null);
      }
    }
  };

  return value ? (
    <UploderPreview
      disabled={disabled}
      fileType={value.data.type}
      previewLink={getFileSource(value)}
      onRemove={() => handleChange([])}
    />
  ) : (
    <UploaderInput
      disabled={disabled}
      limit={1}
      onChange={handleChange}
      {...otherProps}
    />
  );
};

export default SingularUploader;

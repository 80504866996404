import * as React from 'react';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Custom Feature Components
import EvaluationReview from 'features/form/feedbacks/components/details/Process/Evaluation/Reviews/Review';

// Custom Types
import type { EvaluationReviewProps } from 'features/form/processes/types/details/evaluation/review';
import type { FeedbackProcessEvaluationProps } from 'features/form/feedbacks/types/detail/process/evaluation';

interface EvaluationReviewsProps {
  reviews: EvaluationReviewProps[];
  evaluation: FeedbackProcessEvaluationProps;
}

const EvaluationReviews: React.FC<EvaluationReviewsProps> = (props) => {
  // Props
  const { reviews, evaluation } = props;

  // Render
  return (
    <ColumnStack
      component='section'
      spacing='1rem'
      sx={{
        padding: { xs: '.5rem', md: '1rem' },
        width: '100%',
        border: '1px solid #c5c5c5',
        borderRadius: '.5rem',
        '@media print': {
          border: '1px solid #c5c5c5',
        },
      }}
    >
      {reviews.map((review) => (
        <EvaluationReview
          evaluation={evaluation}
          review={review}
          key={review.id}
        />
      ))}
    </ColumnStack>
  );
};

export default EvaluationReviews;

import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternTitleItem from 'features/appBuilder/patterns/core/components/shared/items/Title';

// Custom Types
export interface FooterPatternDescriptionItemProps {}
type CollapsedKey = 'section' | 'title' | 'body';

const FooterPatternDescriptionSection: FC<
  FooterPatternDescriptionItemProps
> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    title: false,
    body: false,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='توضیحات'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='description'
    >
      <PatternTitleItem
        reference='description.title'
        title='عنوان'
        collapsed={collapsed.title}
        onCollapsedChange={handleCollapseChanged('title')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'fontColor',
          'highlightColor',
          'fontHoverColor',
          'highlightHoverColor',
        ]}
      />
      <PatternTitleItem
        reference='description.body'
        title='توضیحات'
        collapsed={collapsed.body}
        onCollapsedChange={handleCollapseChanged('body')}
        textArea
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'fontColor',
          'highlightColor',
          'fontHoverColor',
          'highlightHoverColor',
        ]}
      />
    </PatternSectionContainer>
  );
};

export default FooterPatternDescriptionSection;

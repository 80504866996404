// Feature Types
import type { TagAndCategoryPatternPageConfigProps } from 'features/appBuilder/patterns/features/tagAndCategory/types/pageConfig';

export const tagAndCategoryPatterPageConfigDefaults: TagAndCategoryPatternPageConfigProps =
  {
    isActive: true,
    title: {
      isActive: true,
      text: '',
      padding: '0px',
      fontSize: '14',
      fontColor: '#FFFFFF',
      highlightColor: '#12252E',
      textAlign: 'center',
      textDirection: 'rtl',
    },
  };

import type { FC } from 'react';

// Core Components
import IconButton, {
  IconButtonProps,
} from 'core/components/base/inputs/IconButton';
import ConfigIcon from 'core/components/icons/Config';

// Custom Types
export interface ConfigIconButtonProps extends IconButtonProps {}

const ConfigIconButton: FC<ConfigIconButtonProps> = (props) => {
  // Props
  const { children, ...otherProps } = props;

  return (
    <IconButton {...otherProps}>
      <ConfigIcon />
    </IconButton>
  );
};

export default ConfigIconButton;

import { type FC } from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Core Components
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface FullWidthLeftIconProps extends SvgIconProps {}

const FullWidthLeftIcon: FC<FullWidthLeftIconProps> = (props) => {
  return (
    <IconBuilder viewBox='0 0 12 20' {...props}>
      <path d='M10.9998 0C10.7346 0 10.4802 0.105357 10.2927 0.292893C10.1051 0.48043 9.99979 0.734784 9.99979 1V9H3.41379L5.70679 6.707C5.88894 6.5184 5.98974 6.2658 5.98746 6.0036C5.98518 5.7414 5.88001 5.49059 5.6946 5.30518C5.5092 5.11977 5.25838 5.0146 4.99619 5.01233C4.73399 5.01005 4.48139 5.11084 4.29279 5.293L0.292786 9.293C0.105315 9.48053 0 9.73484 0 10C0 10.2652 0.105315 10.5195 0.292786 10.707L4.29279 14.707C4.38503 14.8025 4.49538 14.8787 4.61738 14.9311C4.73939 14.9835 4.87061 15.0111 5.00339 15.0123C5.13616 15.0134 5.26784 14.9881 5.39074 14.9378C5.51364 14.8875 5.62529 14.8133 5.71918 14.7194C5.81307 14.6255 5.88733 14.5138 5.93761 14.391C5.98789 14.2681 6.01319 14.1364 6.01204 14.0036C6.01088 13.8708 5.9833 13.7396 5.93089 13.6176C5.87848 13.4956 5.8023 13.3852 5.70679 13.293L3.41379 11H9.99979V19C9.99979 19.2652 10.1051 19.5196 10.2927 19.7071C10.4802 19.8946 10.7346 20 10.9998 20C11.265 20 11.5194 19.8946 11.7069 19.7071C11.8944 19.5196 11.9998 19.2652 11.9998 19V1C11.9998 0.734784 11.8944 0.48043 11.7069 0.292893C11.5194 0.105357 11.265 0 10.9998 0Z' />
    </IconBuilder>
  );
};
export default FullWidthLeftIcon;

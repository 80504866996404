// Custom Page Components
import ProcessesList from 'features/form/processes/components/list';
import AddProcess from 'features/form/processes/components/details/Add';
import EditProcess from 'features/form/processes/components/details/Edit';

// Custom Utilities
import { getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'form';
const subFeature: SubFeatureType = 'processes';
const processesRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/processes',
    Component: ProcessesList,
    feature,
    subFeature,
  },
  {
    path: '/processes/add',
    Component: AddProcess,
    feature,
    subFeature,
  },
  {
    path: '/processes/:id',
    Component: EditProcess,
    feature,
    subFeature,
  },
];

export default processesRoutes;

import { Controller, useFormContext } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';
import type { FC } from 'react';

// Custom Hooks
import useMounted from 'core/hooks/useMounted';

// Core Components
import Box from 'core/components/base/layout/Box';
import RowStack from 'core/components/shared/Stack/RowStack';
import Radio from 'core/components/base/inputs/Radio';
import GhostTextField from 'core/components/shared/ResizableTextField';
import CloseIconButton from 'core/components/shared/IconButton/Close';

// Feature Components
import ProcessItemStatusSelect from 'features/form/processes/components/details/shared/StatusSelect';

// Custom Icons
import DragIcon from 'core/components/icons/Drag';

// Core Utilities
import { forLoop } from 'core/utilities/helper';
import { updateProcessFocus } from 'features/form/processes/store/actions';

// Feature Types
import type { ProcessProps } from 'features/form/processes/types/details';

// Custom Types
export interface EvaluationQuestionOptionComponentProps {
  itemIndex: number;
  optionIndex: number;
  optionId: string;
  disabled?: boolean;
}

const EvaluationQuestionOption: FC<EvaluationQuestionOptionComponentProps> = (
  props
) => {
  // Props
  const { disabled, itemIndex, optionIndex, optionId } = props;

  // States
  const mounted = useMounted();

  // Context
  const { control, setValue, getValues } = useFormContext<ProcessProps>();
  const evaluationId = getValues(`data.items.${itemIndex}.id`);

  // Utilities
  const handleDelete = () => {
    const options = [
      ...(getValues(`data.items.${itemIndex}.data.questionOptions`) || []),
    ];

    options.splice(optionIndex, 1);

    forLoop(options, (opt, idx) => (opt.data.sortIndex = idx));

    setValue(`data.items.${itemIndex}.data.questionOptions`, options);
    updateProcessFocus(evaluationId);
  };

  // Render
  return (
    <RowStack spacing={0.5} sx={{ alignItems: 'start' }}>
      <Controller
        control={control}
        name={`data.items.${itemIndex}.data.questionOptions`}
        render={({ field }) =>
          field.value.length > 1 ? (
            <Box sx={{ height: '2rem', display: 'flex', alignItems: 'center' }}>
              <DragIcon
                className={`processItemInputHandle-${evaluationId}`}
                fontSize='small'
                sx={{
                  cursor: disabled ? 'auto' : 'grab',
                  color: disabled ? 'text.disabled' : 'text.secondary',
                  ':active': {
                    cursor: 'grabbing',
                  },
                }}
              />
            </Box>
          ) : (
            <></>
          )
        }
      />
      <Radio
        sx={{ py: 0.5, cursor: 'auto' }}
        disableRipple
        readOnly
        disabled={disabled}
        checked={false}
      />
      <Controller
        control={control}
        name={`data.items.${itemIndex}.data.questionOptions.${optionIndex}.data.label`}
        rules={{ onChange: () => updateProcessFocus(evaluationId) }}
        render={({ field }) => (
          <GhostTextField
            disabled={disabled}
            id={optionId}
            variant='standard'
            placeholder='عنوان گزینه'
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name={`data.items.${itemIndex}.data.questionOptions`}
        render={({ field }) =>
          field.value.length > 1 ? (
            <CloseIconButton
              disabled={disabled}
              sx={{ color: 'text.secondary' }}
              onClick={handleDelete}
            />
          ) : (
            <></>
          )
        }
      />
      <Controller
        control={control}
        name={`data.items.${itemIndex}.data.questionOptions.${optionIndex}.data.linkedId`}
        render={({ field: linkedIdField }) => (
          <Controller
            control={control}
            name={`data.items.${itemIndex}.data.questionOptions.${optionIndex}.data.linkedTitle`}
            render={({ field: linkedTitleField }) => (
              <Controller
                control={control}
                name={`data.items.${itemIndex}.data.questionOptions.${optionIndex}.data.status`}
                render={({ field }) => (
                  <ProcessItemStatusSelect
                    disabled={disabled}
                    currentItemId={evaluationId}
                    label={
                      field.value === 'end'
                        ? 'پایان'
                        : linkedTitleField.value || linkedIdField.value
                    }
                    onChange={(status, optId, optTitle) => {
                      setValue(field.name, status);
                      setValue(linkedIdField.name, optId);
                      setValue(linkedTitleField.name, optTitle);
                      updateProcessFocus(evaluationId);
                    }}
                  />
                )}
              />
            )}
          />
        )}
      />
      <Controller
        control={control}
        name={`data.items.${itemIndex}.data.questionOptions.${optionIndex}.data.message`}
        rules={{ onChange: () => updateProcessFocus(evaluationId) }}
        render={({ field }) => (
          <GhostTextField
            disabled={disabled}
            variant='standard'
            multiline
            rows={mounted ? undefined : 1}
            minRows={mounted ? 1 : undefined}
            maxRows={mounted ? 5 : undefined}
            placeholder='متن پیام گزینه'
            sx={{ border: '1px solid #C1C8D3', borderRadius: '0.5rem' }}
            {...field}
          />
        )}
      />
    </RowStack>
  );
};

export default EvaluationQuestionOption;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialStateProps {
  percentage: number;
}
const initialState: InitialStateProps = {
  percentage: 0,
};

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setUploadPercentage: (state, action: PayloadAction<number>) => {
      state.percentage = action.payload;
    },
  },
});

export const { setUploadPercentage } = uploadSlice.actions;
export default uploadSlice.reducer;

import * as Yup from 'yup';

// Custom Schemas
import {
  emailSchema,
  firstNameSchema,
  lastNameSchema,
  passwordSchema,
} from 'core/utilities/validationSchema/common/Schemas';

const AddManagerValidationSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  password: passwordSchema.min(8, 'کلمه عبور نمیتواند کمتر از 8 کاراکتر باشد.'),
});

export default AddManagerValidationSchema;

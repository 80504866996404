import * as React from 'react';
import { alpha } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import type { SubmitHandler } from 'react-hook-form';

// Custom Common Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import ImageUploader from 'core/components/shared/Input/Avatar';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import RowStack from 'core/components/shared/Stack/RowStack';
import SubmitLoadingButton from 'core/components/shared/LoadingButton/Submit';
import TextFieldOutlined from 'core/components/shared/TextField/Outlined';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import MultiAutocomplete from 'core/components/shared/Autocomplete/api/MultipleAutocomplete';

// Custom Core Components
import Checkbox from 'core/components/base/inputs/Checkbox';
import TextField from 'core/components/base/inputs/TextField';
import FormControlLabel from 'core/components/base/inputs/FormControlLabel';

// Custom Utilities
import { audienceGroupsQueryKey } from 'features/audience/groups/hooks';
import { getAudiencesSummaryList } from 'features/audience/audiences/utilities/api';
import { getAudienceGroupSummaryList } from 'features/audience/groups/utilities/api';
import { ticketTopicSchema } from 'features/ticket/topics/validations';
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';
import { audiencesQueryKey } from 'features/audience/audiences/hooks';
import { getErrorAndHelperText } from 'core/utilities/helper/helperPack';
import { useAddTicketTopicMutation } from 'features/ticket/topics/hooks';

// Custom Types
import type { TicketTopicDataProps } from 'features/ticket/topics/types';
import type { AudienceSummaryProps } from 'features/audience/audiences/types';

interface AddTopicProps {}

const AddTopic: React.FC<AddTopicProps> = (props) => {
  // Context
  const loading = useSelectLoading();

  // Hooks
  const resolver = useYupValidationResolver(ticketTopicSchema);
  const { control, setValue, handleSubmit } = useForm<TicketTopicDataProps>({
    mode: 'onTouched',
    resolver,
  });
  const { mutate: handleAddTicketTopic } = useAddTicketTopicMutation({
    autoAlert: { mode: 'create', name: 'موضوع جدید' },
    stayOnLoadingAfterMutate: true,
    redirectAfterSuccessTo: '/tickets/topics',
  });

  // Utilities
  const handleFormSubmit: SubmitHandler<TicketTopicDataProps> = async (
    data
  ) => {
    handleAddTicketTopic(data);
  };

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='افزودن موضوع تیکت' />
      <PageHeroBox>
        <HeadingSix>موضوع جدید</HeadingSix>
        <SubmitLoadingButton
          disabled={loading}
          onClick={handleSubmit(handleFormSubmit)}
        >
          ذخیره
        </SubmitLoadingButton>
      </PageHeroBox>
      <ColumnStack spacing={2}>
        <ColumnStack p='1rem 2rem'>
          <RowStack>
            <ColumnStack spacing={0} width='48%'>
              <InputLabel required>عنوان موضوع</InputLabel>
              <Controller
                control={control}
                name='title'
                defaultValue=''
                render={({ field, fieldState }) => (
                  <TextField
                    disabled={loading}
                    fullWidth={false}
                    placeholder='عنوان'
                    sx={{ width: '70%' }}
                    {...getErrorAndHelperText(fieldState)}
                    {...field}
                  />
                )}
              />
            </ColumnStack>
            <ColumnStack spacing={0} width='48%'>
              <InputLabel>
                مدت زمان (روز) منقضی شدن تیکت پس از آخرین پاسخ
              </InputLabel>
              <Controller
                control={control}
                name='expireAfter'
                defaultValue={7}
                render={({ field }) => (
                  <TextFieldOutlined
                    disabled={loading}
                    placeholder='تعداد روز'
                    type='number'
                    sx={{ width: '70%' }}
                    {...field}
                  />
                )}
              />
            </ColumnStack>
          </RowStack>
          <RowStack>
            <ColumnStack spacing={0} width='48%'>
              <InputLabel required>گروه پرسنل</InputLabel>
              <Controller
                control={control}
                name='personnel.groupIds'
                defaultValue={[]}
                render={({ field, fieldState }) => (
                  <MultiAutocomplete
                    title='گروه مخاطبان'
                    apiHandler={getAudienceGroupSummaryList}
                    queryKey={audienceGroupsQueryKey}
                    sx={{ flexGrow: 1, minWidth: '10rem' }}
                    defaultValue={field.value}
                    onChange={(audiences) => field.onChange(audiences)}
                    {...getErrorAndHelperText(fieldState)}
                  />
                )}
              />
            </ColumnStack>
            <ColumnStack spacing={0} width='48%'>
              <InputLabel>تعداد تیکت‌های باز برای یک پرسنل</InputLabel>
              <Controller
                control={control}
                name='personnel.limit'
                defaultValue={1}
                render={({ field }) => (
                  <TextFieldOutlined
                    disabled={loading}
                    placeholder='تعداد'
                    type='number'
                    sx={{ width: '70%' }}
                    {...field}
                  />
                )}
              />
            </ColumnStack>
          </RowStack>
        </ColumnStack>
        <ColumnStack
          p='1.5rem 2rem'
          sx={({ palette }) => ({
            backgroundColor: alpha(palette.info.main, 0.4),
          })}
        >
          <RowStack>
            <ColumnStack spacing={0} width='48%'>
              <InputLabel required>عنوان دپارتمان</InputLabel>
              <Controller
                control={control}
                name='department.title'
                defaultValue=''
                render={({ field, fieldState }) => (
                  <TextFieldOutlined
                    disabled={loading}
                    fullWidth={false}
                    placeholder='عنوان دپارتمان'
                    sx={{ width: '70%' }}
                    {...getErrorAndHelperText(fieldState)}
                    {...field}
                  />
                )}
              />
            </ColumnStack>
            <ColumnStack spacing={0} width='48%'>
              <InputLabel required>دپارتمان</InputLabel>
              <Controller
                control={control}
                name='department.audienceIds'
                defaultValue={[]}
                render={({ field, fieldState }) => (
                  <MultiAutocomplete
                    title='مخاطبان'
                    apiHandler={getAudiencesSummaryList}
                    queryKey={audiencesQueryKey}
                    defaultValue={field.value}
                    onChange={(audiences) => field.onChange(audiences)}
                    getOptionLabel={(item: AudienceSummaryProps) =>
                      item.data.fullName || 'بدون نام'
                    }
                    {...getErrorAndHelperText(fieldState)}
                  />
                )}
              />
            </ColumnStack>
          </RowStack>
          <RowStack>
            <ColumnStack spacing={0} width='48%'>
              <InputLabel>آواتار دپارتمان</InputLabel>
              <Controller
                control={control}
                name='department.logo'
                defaultValue={null}
                render={({ field }) => (
                  <ImageUploader
                    location='ticket'
                    disabled={loading}
                    height='10rem'
                    width='10rem'
                    onImageFileChange={(file) =>
                      setValue('department.logo', file || null)
                    }
                    imageFile={field.value?.id ? field.value : undefined}
                  />
                )}
              />
            </ColumnStack>
            <ColumnStack width='48%'>
              <ColumnStack spacing={0}></ColumnStack>
              <ColumnStack spacing={0}>
                <InputLabel>معرفی دپارتمان</InputLabel>
                <Controller
                  control={control}
                  name='department.description'
                  defaultValue=''
                  render={({ field }) => (
                    <TextFieldOutlined
                      disabled={loading}
                      placeholder='معرفی کوتاه دپارتمان'
                      type='string'
                      sx={{ width: '70%' }}
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
            </ColumnStack>
          </RowStack>
        </ColumnStack>
        <ColumnStack p='1.5rem 2rem'>
          <RowStack>
            <ColumnStack spacing={0} width='48%'>
              <InputLabel>مشترکین</InputLabel>
              <Controller
                control={control}
                name='subscribers.audienceIds'
                defaultValue={[]}
                render={({ field, fieldState }) => (
                  <MultiAutocomplete
                    title='مخاطبان'
                    getOptionLabel={(item: AudienceSummaryProps) =>
                      item.data.fullName || 'بدون نام'
                    }
                    apiHandler={getAudiencesSummaryList}
                    queryKey={audiencesQueryKey}
                    defaultValue={field.value}
                    onChange={(audiences) => field.onChange(audiences)}
                    disabled={loading}
                    sx={{ width: '70%' }}
                    {...getErrorAndHelperText(fieldState)}
                  />
                )}
              />
            </ColumnStack>
          </RowStack>
          <RowStack>
            <ColumnStack spacing={0} width='48%'>
              <Controller
                control={control}
                name='subscribers.availableOpts.openAndClose'
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ width: 'fit-content' }}
                    label='امکان باز و بسته شدن تیکت توسط مشترکین'
                    control={
                      <Checkbox
                        disabled={loading}
                        color='info'
                        size='small'
                        onChange={(e, checked) =>
                          setValue(
                            'subscribers.availableOpts.openAndClose',
                            checked
                          )
                        }
                      />
                    }
                  />
                )}
              />
            </ColumnStack>
            <ColumnStack spacing={0} width='48%'>
              <Controller
                control={control}
                name='subscribers.availableOpts.replyToCreator'
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ width: 'fit-content' }}
                    label='امکان پاسخ به پرسنل توسط مشترکین'
                    control={
                      <Checkbox
                        disabled={loading}
                        color='info'
                        size='small'
                        onChange={(e, checked) =>
                          setValue(
                            'subscribers.availableOpts.replyToCreator',
                            checked
                          )
                        }
                      />
                    }
                  />
                )}
              />
            </ColumnStack>
          </RowStack>
          <RowStack>
            <ColumnStack spacing={0} width='48%'>
              <Controller
                control={control}
                name='subscribers.availableOpts.mention'
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ width: 'fit-content' }}
                    label={
                      <>
                        <BodyOne>امکان منشن کردن مشترکین توسط مشترکین</BodyOne>
                        <BodyTwo>
                          (مشترکین به صورت پیش‌فرض می‌توانند پرسنل و اعضای
                          دپارتمان را منشن کنند.)
                        </BodyTwo>
                      </>
                    }
                    control={
                      <Checkbox
                        disabled={loading}
                        color='info'
                        size='small'
                        onChange={(e, checked) =>
                          setValue('subscribers.availableOpts.mention', checked)
                        }
                      />
                    }
                  />
                )}
              />
            </ColumnStack>
            <ColumnStack spacing={0} width='48%'>
              <Controller
                control={control}
                name='subscribers.availableOpts.replyFromCreator'
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ width: 'fit-content' }}
                    label={
                      <>
                        <BodyOne>امکان فعال کردن دریافت پاسخ پرسنل</BodyOne>
                        <BodyTwo>(دریافت یک پاسخ جدید از سوی پرسنل.)</BodyTwo>
                      </>
                    }
                    control={
                      <Checkbox
                        disabled={loading}
                        color='info'
                        size='small'
                        onChange={(e, checked) =>
                          setValue(
                            'subscribers.availableOpts.replyFromCreator',
                            checked
                          )
                        }
                      />
                    }
                  />
                )}
              />
            </ColumnStack>
          </RowStack>
        </ColumnStack>
      </ColumnStack>
    </>
  );
};

export default AddTopic;

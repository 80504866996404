import { v4 as uuidv4 } from 'uuid';

/**
 * Generates a UUID using the v4 algorithm.
 *
 * @returns {string} The generated UUID.
 */
export const genUUID = (): string => uuidv4();

export const objectIdPattern = /^[0-9a-fA-F]{24}$/;
export const isObjectId = (id: string): boolean => objectIdPattern.test(id);

/**
 * Generates a random ObjectId.
 *
 * @returns {string} The random object id.
 */
export const generateObjectId = (): string => {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);

  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, () => ((Math.random() * 16) | 0).toString(16))
      .toLowerCase()
  );
};

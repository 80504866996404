import * as React from 'react';
import { ReactSortable } from 'react-sortablejs';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';

// Custom Feature Components
import MenuItem from 'features/menu/menus/components/details/MenuItem';

// Custom Utilities
import { deepClone, isSame } from 'core/utilities/helper/helperPack';
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';
import {
  setMenuActionMode,
  updateSortNumbers,
  useSelectItemsWithNoParent,
} from 'core/store/slices/feature/menu';

// Custom Types
import type { MenuItemProps } from 'features/menu/menus/types';

interface ListItemsProps {}

const ListItems: React.FC<ListItemsProps> = (props) => {
  // Context
  const items = useSelectItemsWithNoParent();
  const loading = useSelectLoading();

  // States
  const isSorting = useBoolean();

  // Utilities
  const handleStartSorting = () => {
    setMenuActionMode('SORT');
    isSorting.setTrue();
  };
  const handleSort = (sorted: MenuItemProps[]) => {
    if (!isSorting.state) return;
    const newSorted = sorted.map((item, index) => ({
      id: item.id,
      data: {
        ...item.data,
        sortNumber: index,
      },
    }));

    if (!isSame(newSorted, items)) updateSortNumbers(newSorted);
    isSorting.setFalse();
    setMenuActionMode('NORMAL');
  };

  // Render
  return (
    <ReactSortable
      animation={150}
      disabled={loading}
      direction='vertical'
      list={deepClone(items)}
      handle='.itemSortHandle'
      onStart={handleStartSorting}
      setList={(newState) => handleSort(newState)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '.5rem',
      }}
    >
      {items.map((item) => (
        <MenuItem
          key={`parent-item-${item.id}`}
          menu={item}
          handleClassName='itemSortHandle'
        />
      ))}
    </ReactSortable>
  );
};

export default ListItems;

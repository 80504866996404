import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
  styled,
} from '@mui/material';

export interface TooltipProps extends MuiTooltipProps {}

const Tooltip = styled(MuiTooltip)<TooltipProps>(() => ({}));

export default Tooltip;

import { type FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// Feature Components
import ManagerGroupAddActions from 'features/manager/groups/components/details/actions/Add';
import ManagerGroupAddBody from 'features/manager/groups/components/details/body/Add';

// Feature Common Components
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Custom Utilities
import { useAddManagerGroupMutation } from 'features/manager/groups/hooks';

// Custom Types
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';
import type { FormInputs } from 'features/manager/groups/components/details/actions/Add';

interface AddManagerGroupProps extends WithAuthProps {}

const AddManagerGroup: FC<AddManagerGroupProps> = () => {
  // Hooks
  const formMethods = useForm<FormInputs>({
    mode: 'onTouched',
  });

  const { mutate: handleAddManagerGroup } = useAddManagerGroupMutation({
    autoAlert: { mode: 'create', name: 'گروه' },
    stayOnLoadingAfterMutate: true,
    redirectAfterSuccessTo: '/manager-groups',
  });

  // Utilities
  const handleSubmit = async (data: FormInputs) => {
    handleAddManagerGroup(data);
  };

  return (
    <>
      <PageHeadConfigs pageTitle='ایجاد گروه مدیران' />
      <FormProvider {...formMethods}>
        <ManagerGroupAddActions mode='create' onSubmit={handleSubmit} />
        <ManagerGroupAddBody />
      </FormProvider>
    </>
  );
};

export default AddManagerGroup;

// Feature Utilities
import { bakePatternCommonProps } from 'features/appBuilder/patterns/core/utilities/api/baker';

// Feature Types
import type { HeaderPatternDataProps } from 'features/appBuilder/patterns/features/header/types';

/**
 * Transforms the provided header pattern data by ensuring the `creator` field is included as a string.
 *
 * @param {HeaderPatternDataProps} data - The header pattern data to transform.
 * @returns {Omit<HeaderPatternDataProps, 'creator'> & { creator: string }} The transformed header pattern data with the `creator` field as a string.
 */
export const bakeHeaderPatternData = (
  data: HeaderPatternDataProps
): Omit<HeaderPatternDataProps, 'creator'> & { creator: string } => {
  const { brand, menu, button, banner, ...otherProps } = data;

  const commonProps = bakePatternCommonProps(otherProps);

  return {
    ...commonProps,
    brand: {
      ...brand,
    },
    menu: {
      ...menu,
    },
    button: {
      ...button,
    },
    banner: {
      isActive: banner.isActive,
      one: {
        ...banner.one,
        containerTitle: '',
      },
    },
  };
};

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';
interface CloseIconProps extends SvgIconProps {}

const CloseIcon: React.FC<CloseIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M20.27,4.82,13.09,12l7.18,7.18a.77.77,0,1,1-1.09,1.09L12,13.09,4.82,20.27a.77.77,0,1,1-1.09-1.09L10.91,12,3.73,4.82A.77.77,0,0,1,4.82,3.73L12,10.91l7.18-7.18a.77.77,0,1,1,1.09,1.09Z' />
  </IconBuilder>
);

export default CloseIcon;

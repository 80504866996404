import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Custom Core Components
import Table from 'core/components/base/display/Table';
import TableBody from 'core/components/base/display/TableBody';
import TableCell from 'core/components/base/display/TableCell';
import TableContainer from 'core/components/base/display/TableContainer';
import TableHead from 'core/components/base/display/TableHead';
import TableRow from 'core/components/base/display/TableRow';

// Custom Common Components
import TableContainerPaper from 'core/components/shared/Paper/TableContainerPaper';
import RouteLink from 'core/components/shared/Link/RouteLink';

// Custom Utilities
import { getLocaleDateAndTime } from 'core/utilities/helper/date';
import { getTicketMessageStatusLabel } from 'features/ticket/tickets/utilities';

// Custom Styles
import { tableScrollbarStyles } from 'core/utilities/styles/customStyles';

// Custom Types
import type { TicketSummaryProps } from 'features/ticket/tickets/types';

interface TicketsDataGridProps {
  tickets: TicketSummaryProps[];
  disabled?: boolean;
  onSelectionChange?: (ids: string[]) => void;
  onStatusChange?: (ticketId: string, status: boolean) => void;
}

const TicketsDataGrid: React.FC<TicketsDataGridProps> = (props) => {
  // Props
  const { disabled, tickets } = props;

  // Hooks
  const theme = useTheme();

  // Render
  return (
    <TableContainer
      component={TableContainerPaper}
      sx={{ ...tableScrollbarStyles }}
    >
      <Table>
        <TableHead>
          <TableRow sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
            <TableCell sx={{ width: '12rem', whiteSpace: 'nowrap' }}>
              عنوان
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              شناسه
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              پرسنل
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              دپارتمان
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              موضوع
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              وضعیت
            </TableCell>
            <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
              آخرین بروزرسانی
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {disabled && tickets.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4}>
                در حال دریافت اطلاعات تیکت‌ها...
              </TableCell>
            </TableRow>
          ) : tickets.length > 0 ? (
            tickets.map((ticket) => (
              <TableRow key={ticket.id}>
                <TableCell sx={{ width: '12rem', whiteSpace: 'nowrap' }}>
                  <RouteLink
                    disabled={disabled}
                    to={`/tickets/${ticket.id}`}
                    sx={{
                      borderBottom: '1px dashed',
                      borderColor: 'text.secondary',
                      ':hover': {
                        borderBottom: '1px solid',
                      },
                    }}
                  >
                    {ticket.data.title}
                  </RouteLink>
                </TableCell>
                <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
                  {ticket.data.code || '-'}
                </TableCell>
                <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
                  {ticket.data.personnel.map((el) => el).join(' - ') || '-'}
                </TableCell>
                <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
                  {ticket.data.depMembers.length > 0
                    ? `${ticket.data.depMembers[0]}${
                        ticket.data.depMembers.length > 1
                          ? ` +${
                              ticket.data.depMembers.length - 1
                            }`?.toPersian()
                          : ''
                      }`
                    : '-'}
                </TableCell>
                <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
                  {ticket.data.topic.data.title}
                </TableCell>
                {/*
                <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
                  <Switch
                    color='info'
                    disabled={disabled}
                    checked={ticket.data.isActive}
                    onChange={(e, checked) =>
                      onStatusChange && onStatusChange(ticket.id, checked)
                    }
                  />
                </TableCell>
              */}
                <TableCell
                  dir='ltr'
                  sx={{ width: '7rem', whiteSpace: 'nowrap' }}
                >
                  {getTicketMessageStatusLabel(ticket.data.status) || '-'}
                </TableCell>
                <TableCell
                  dir='ltr'
                  sx={{ width: '7rem', whiteSpace: 'nowrap' }}
                >
                  {getLocaleDateAndTime(ticket.data.updatedAt) || '-'}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>تیکتی جهت نمایش وجود ندارد.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TicketsDataGrid;

import React from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';

// Types
import type { SubmitHandler } from 'react-hook-form';

// HOC
import WithAuth, { WithAuthProps } from 'core/components/shared/HOC/WithAuth';

// Core Components
import TextField from 'core/components/base/inputs/TextField';

// Common Components
import SpacedBox from 'core/components/shared/Box/SpacedBox';
import RowStack from 'core/components/shared/Stack/RowStack';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import AvatarUploader from 'core/components/shared/Input/Avatar';
import DatePicker from 'core/components/shared/Picker/DatePicker';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import SubmitLoadingButton from 'core/components/shared/LoadingButton/Submit';
import DeleteLoadingButton from 'core/components/shared/LoadingButton/Delete';
import CheckboxWithLabel from 'core/components/shared/Checkbox/CheckboxWithLabel';
import SingleAutocomplete from 'core/components/shared/Autocomplete/api/SingleAutocomplete';
import MultiAutocomplete from 'core/components/shared/Autocomplete/api/MultipleAutocomplete';

// Feature Components
import AudienceDependants from 'features/audience/audiences/components/Dependants';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import { setAppAlert } from 'core/utilities/helper';
import { accessQueryKey } from 'features/audience/accesses/hooks';
import { audienceGroupsQueryKey } from 'features/audience/groups/hooks';
import { getAudienceAccessList } from 'features/audience/accesses/utilities/api';
import { getAudienceGroupSummaryList } from 'features/audience/groups/utilities/api';
import {
  useAudienceDoc,
  useDeleteAudienceMutation,
  useEditAudienceMutation,
} from 'features/audience/audiences/hooks';

// Context
import { useDialogContext } from 'core/store/context/DialogProvider';

// Custom Types
import type { AudienceDataProps } from 'features/audience/audiences/types';

interface EditAudiencePageProps extends WithAuthProps {}

interface FormInputs extends AudienceDataProps {}

const filePath = 'src/components/pages/Audience/EditAudience.tsx';

const EditAudience: React.FC<EditAudiencePageProps> = (props) => {
  // Context
  const loading = useSelectLoading();
  const { onOpenDialog, onCloseDialog } = useDialogContext();

  // Hooks
  const { data: audience } = useAudienceDoc({
    alertOnFetchError: { message: 'دریافت اطلاعات مخاطب با خطا مواجه شد.' },
    alertOnDocumentIdIsEmpty: { message: 'مخاطب یافت نشد' },
    redirectAfterDocumentIdIsEmptyTo: '/audiences',
    redirectAfterErrorTo: '/audiences',
    onFetch: (doc) => doc && formMethods.reset(doc.data),
  });
  const { mutate: handleEditAudience } = useEditAudienceMutation({
    autoAlert: { mode: 'update', name: 'اطلاعات مخاطب' },
    redirectAfterSuccessTo: '/audiences',
  });
  const { mutate: handleDeleteAudience } = useDeleteAudienceMutation({
    autoAlert: { mode: 'delete', name: 'مخاطب' },
    redirectAfterSuccessTo: '/audiences',
    onSuccess: () => onCloseDialog(),
  });
  const formMethods = useForm<FormInputs>({
    mode: 'onTouched',
    defaultValues: {},
  });

  const disabled = !audience?.id || loading;

  // Utilities
  const handleOpenDeleteDialog = () => {
    onOpenDialog({
      title: `آیا از حذف ${formMethods.getValues(
        'firstName'
      )} ${formMethods.getValues('lastName')} اطمینان دارید؟`,
      onSubmit: () => {
        if (audience) handleDeleteAudience([audience.id]);
        else onCloseDialog();
      },
    });
  };

  const handleFormSubmit: SubmitHandler<AudienceDataProps> = async (data) => {
    if (!audience?.id) return;

    if (data.groups.length === 0) return setAppAlert('گروه مخاطب را مشخص کنید');

    handleEditAudience({ id: audience?.id, data });
  };

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='ویرایش مخاطب' />
      <PageHeroBox>
        <HeadingSix>
          {` پروفایل `}
          {audience?.id
            ? ` ${audience?.data?.firstName} ${audience?.data?.lastName}`
            : 'مخاطب'}
        </HeadingSix>
        <SubmitLoadingButton
          disabled={loading}
          loading={loading}
          onClick={formMethods.handleSubmit(handleFormSubmit)}
        >
          ذخیره
        </SubmitLoadingButton>
      </PageHeroBox>
      <RoundPaper>
        <SpacedBox sx={{ alignItems: 'flex-start' }}>
          <ColumnStack sx={{ width: '20%', mt: 5 }}>
            <Controller
              name='avatar'
              control={formMethods.control}
              render={({ field }) => (
                <AvatarUploader
                  location='audience'
                  disabled={loading}
                  onImageFileChange={(file) => field.onChange(file)}
                  imageFile={field.value?.id ? field.value : undefined}
                  sx={{
                    width: '167px',
                    height: '216px',
                    borderRadius: '1.2rem',
                  }}
                />
              )}
            />
          </ColumnStack>
          <ColumnStack sx={{ width: '75%', mt: 5, pb: 3, pr: 2 }}>
            <RowStack sx={{ width: '100%', justifyContent: 'space-between' }}>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>نام</InputLabel>
                <Controller
                  control={formMethods.control}
                  name='firstName'
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      disabled={disabled}
                      placeholder='نام'
                      type='text'
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>نام‌خانوادگی</InputLabel>
                <Controller
                  name='lastName'
                  control={formMethods.control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      disabled={disabled}
                      placeholder='نام‌خانوادگی'
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
            </RowStack>
            <RowStack sx={{ width: '100%', justifyContent: 'space-between' }}>
              <ColumnStack width='45%' spacing={0}>
                <InputLabel>شماره تلفن</InputLabel>
                <Controller
                  name='phoneNumber'
                  control={formMethods.control}
                  render={({ field }) => (
                    <TextField
                      dir='ltr'
                      placeholder={'+98xxxxxxxxx'?.toPersian()}
                      fullWidth
                      disabled={disabled}
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
              <ColumnStack width='45%' spacing={0}>
                <InputLabel>ایمیل</InputLabel>
                <Controller
                  name='email'
                  control={formMethods.control}
                  render={({ field }) => (
                    <TextField
                      dir='ltr'
                      placeholder='example@nazar.com'
                      fullWidth
                      disabled={disabled}
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
            </RowStack>
            <RowStack sx={{ width: '100%', justifyContent: 'space-between' }}>
              <ColumnStack width='45%' spacing={0}>
                <InputLabel>کدپرسنلی</InputLabel>
                <Controller
                  name='personalId'
                  control={formMethods.control}
                  render={({ field }) => (
                    <TextField
                      dir='ltr'
                      placeholder={'12345678'?.toPersian()}
                      fullWidth
                      disabled={disabled}
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
              <ColumnStack width='45%' spacing={0}>
                <InputLabel>کدملی</InputLabel>
                <Controller
                  name='nationalId'
                  control={formMethods.control}
                  render={({ field }) => (
                    <TextField
                      dir='ltr'
                      placeholder={'002xxxxxxx'?.toPersian()}
                      fullWidth
                      disabled={disabled}
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
            </RowStack>
            <RowStack sx={{ justifyContent: 'space-between' }}>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>تاریخ تولد</InputLabel>
                <Controller
                  name='birthDate'
                  control={formMethods.control}
                  render={({ field }) => (
                    <DatePicker
                      disabled={loading}
                      sx={{
                        width: '100%',
                        height: '3rem',
                        '& .rmdp-input': {
                          textAlign: 'start !important',
                        },
                      }}
                      value={new Date(field.value)}
                      onDateChange={(date) =>
                        date !== null &&
                        formMethods.setValue('birthDate', date.toISOString())
                      }
                    />
                  )}
                />
              </ColumnStack>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>عنوان شغلی</InputLabel>
                <Controller
                  name='jobTitle'
                  control={formMethods.control}
                  render={({ field }) => (
                    <TextField
                      placeholder='تعمیرات'
                      fullWidth
                      disabled={disabled}
                      {...field}
                    />
                  )}
                />
              </ColumnStack>
            </RowStack>
            <RowStack sx={{ justifyContent: 'space-between' }}>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>گروه مخاطبین</InputLabel>
                <Controller
                  name='groups'
                  control={formMethods.control}
                  render={({ field }) => (
                    <MultiAutocomplete
                      title='گروه مخاطبان'
                      apiHandler={getAudienceGroupSummaryList}
                      queryKey={audienceGroupsQueryKey}
                      sx={{ flexGrow: 1, minWidth: '10rem' }}
                      defaultValue={
                        field?.value?.map?.((group) => group.id) || []
                      }
                      onChange={(_, selectedOpts) =>
                        field.onChange(selectedOpts)
                      }
                    />
                  )}
                />
              </ColumnStack>
              <ColumnStack spacing={0} width='45%'>
                <InputLabel>اکسس</InputLabel>
                <Controller
                  name='accessId'
                  control={formMethods.control}
                  render={({ field }) => (
                    <SingleAutocomplete
                      placeholder='اکسس'
                      apiHandler={getAudienceAccessList}
                      queryKey={accessQueryKey}
                      defaultValue={field.value}
                      onChange={(accessId) => field.onChange(accessId)}
                    />
                  )}
                />
              </ColumnStack>
            </RowStack>
            <ColumnStack spacing={0}>
              <InputLabel>بیوگرافی</InputLabel>
              <Controller
                name='biography'
                control={formMethods.control}
                render={({ field }) => (
                  <TextField
                    disabled={disabled}
                    dir='rtl'
                    multiline
                    fullWidth
                    rows={6}
                    placeholder='متن بیوگرافی'
                    {...field}
                  />
                )}
              />
            </ColumnStack>
          </ColumnStack>
        </SpacedBox>
        <SpacedBox sx={{ marginTop: '4rem' }}>
          <Controller
            control={formMethods.control}
            name='isEnabled'
            render={({ field }) => (
              <CheckboxWithLabel
                checkboxProps={{
                  checked: field.value,
                  onChange: (e, checked) =>
                    formMethods.setValue('isEnabled', checked),
                }}
                disabled={disabled}
                label='فعال'
              />
            )}
          />
          <DeleteLoadingButton
            disabled={disabled}
            loading={loading && audience?.id ? true : false}
            onClick={handleOpenDeleteDialog}
          >
            حذف
          </DeleteLoadingButton>
        </SpacedBox>
      </RoundPaper>
      <FormProvider {...formMethods}>
        <AudienceDependants />
      </FormProvider>
    </>
  );
};

export default WithAuth(EditAudience, { filePath });

import ArrowBackIcon from 'core/components/icons/ArrowBack';
import Button from 'core/components/base/inputs/Button';
import * as React from 'react';

// Custom Hooks

// Custom Core Components

// Custom Common Components

// Custom Utilities

// Custom Types
interface ArrowBackButtonProps {}

const ArrowBackButton: React.FC<ArrowBackButtonProps> = (props) => {
  // Props
  const {} = props;

  // States

  // Hooks

  // Utilities

  // Render
  return (
    <Button
      color='info'
      variant='contained'
      sx={{
        borderRadius: '.25rem',
        minWidth: '2.625rem',
        maxWidth: '2.625rem',
        height: '2.625rem',
        background: ({ palette }) => palette.common.white,
        color: ({ palette }) => palette.info.main,
        transition: 'opacity .3s',
        ':hover': {
          opacity: '.7',
          background: ({ palette }) => palette.common.white,
          color: ({ palette }) => palette.info.main,
        },
      }}
    >
      <ArrowBackIcon />
    </Button>
  );
};

export default ArrowBackButton;

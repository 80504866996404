import {
  TableHead as MuiTableHead,
  TableHeadProps as MuiTableHeadProps,
  styled,
} from '@mui/material';

export interface TableHeadProps extends MuiTableHeadProps {}

const TableHead = styled(MuiTableHead)<TableHeadProps>(() => ({}));

export default TableHead;

import * as React from 'react';

// Custom Common Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Custom Utilities
import { getFileSource, isSame } from 'core/utilities/helper/helperPack';
import { downloadFile, imageTypes } from 'features/file/files/utilities/file';
import { FileFormat, FileImageFormat } from 'features/file/files/types';

// Custom Types
interface FeedbackInputFileProps {
  file: string;
}

const FeedbackInputFile: React.FC<FeedbackInputFileProps> = (props) => {
  // Props
  const { file } = props;

  // Utilities
  const isImage = (name: string) => {
    const fileExt = getFileExt(name);
    return imageTypes.includes(fileExt as FileImageFormat);
  };

  const getFileExt = (fileName: string) => {
    const dotIndex = fileName.lastIndexOf('.');
    return fileName.substring(dotIndex + 1);
  };

  const getFileName = (url: string) =>
    url.substring(url.lastIndexOf('_-_') + 3);

  const getFileNameForDownload = (url: string) => {
    const fileName = getFileName(url);
    return fileName.substring(0, fileName.lastIndexOf('.'));
  };

  const handleDownload = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    url: string
  ) => {
    if (isImage(url)) {
      e.preventDefault();
      downloadFile(
        getFileSource(url),
        getFileNameForDownload(url),
        getFileExt(url) as FileFormat
      );
    }
  };

  //   Render
  return (
    <a
      download
      href={getFileSource(file)}
      onClick={(e) => handleDownload(e, file)}
      key={`feedback-input-file-${file}`}
      style={{
        textDecoration: 'none',
      }}
    >
      {isImage(file) ? (
        <img
          src={getFileSource(file)}
          alt={''}
          width={80}
          height={80}
          style={{
            minWidth: 80,
            zIndex: 0,
            borderRadius: '.5rem',
            objectFit: 'cover',
            cursor: 'pointer',
          }}
        />
      ) : (
        <ColumnStack
          spacing={0.5}
          sx={{
            cursor: 'pointer',
            width: '5rem',
            height: '5rem',
            borderRadius: '.5rem',
            background: '#ccc',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <BodyTwo
            sx={{
              maxWidth: '100%',
              fontSize: '.875rem',
              fontWeight: 500,
            }}
          >
            {getFileExt(file).toUpperCase()}
          </BodyTwo>
          <BodyTwo
            sx={{
              maxWidth: '100%',
              fontSize: '.65rem',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitLineClamp: 2,
              padding: 0,
            }}
          >
            {file.substring(file.lastIndexOf('_-_') + 3)}
          </BodyTwo>
        </ColumnStack>
      )}
    </a>
  );
};

export default React.memo(FeedbackInputFile, isSame);

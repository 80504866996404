// Types
import type { FC } from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface AddColIconProps extends SvgIconProps {}

const AddColIcon: FC<AddColIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M14,4h8V2L12,2v20h10v-2h-8v-4h8v-2h-8v-4h8V8h-8V4z M5,13H2l0-2h3V8h2v3h3v2H7v3H5V13z' />
  </IconBuilder>
);

export default AddColIcon;

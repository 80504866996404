import React, { useState } from 'react';

// HOC
import WithAuth from 'core/components/shared/HOC/WithAuth';

// Hooks
import useSelection from 'core/hooks/common/useSelection';

// Section Components
import DataGrid from 'features/content/categories/components/list/DataGrid';

// Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import DeleteLoadingButton from 'core/components/shared/LoadingButton/Delete';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import CategoryCard from 'features/content/categories/components/list/Card';

// Core Components
import Box from 'core/components/base/layout/Box';
import Collapse from 'core/components/base/utils/Collapse';
import Stack from 'core/components/base/layout/Stack';
import LoadingButton from 'core/components/base/inputs/LoadingButton';

// Context
import DocsProvider from 'core/hooks/api/useDocs/Context';
import { useDialogContext } from 'core/store/context/DialogProvider';

// Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { isSucceed } from 'core/utilities/helper';
import {
  useCategoriesDocs,
  useDeleteCategoryMutation,
} from 'features/content/categories/hooks';
import {
  getCategory,
  initialCategory,
} from 'features/content/categories/utilities/api';

// Custom Types
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';
import type { CategoryProps } from 'features/content/categories/types';

interface CategoryPageProps extends WithAuthProps {}

const Category: React.FC<CategoryPageProps> = (props) => {
  // States
  const [category, setCategory] = useState<CategoryProps>(initialCategory);
  const [mode, setMode] = useState<'create' | 'edit' | 'delete' | 'none'>(
    'none'
  );

  // Context
  const loading = useSelectLoading();
  const { onCloseDialog, onOpenDialog } = useDialogContext();

  // Hooks
  const { selections, onSelectionsChange, onClearSelections } = useSelection();
  const categoriesMethods = useCategoriesDocs();
  const { data, onPageNumChange, onPageSizeChange } = categoriesMethods;
  const { mutate: handleDeleteCategory } = useDeleteCategoryMutation({
    autoAlert: { mode: 'delete', name: 'موضوعات انتخاب شده' },
    onSuccess: () => {
      onClearSelections();
      onCloseDialog();
    },
  });

  // Utilities
  const handleClose = () => setMode('none');
  const handleOpenDeleteDialog = () => {
    onOpenDialog({
      title: 'آیا از حذف دسته‌بندی‌های انتخاب شده مطمئن هستید؟',
      onSubmit: () => handleDeleteCategory(selections),
    });
  };

  const getCategoryById = React.useCallback(async () => {
    if (selections.length === 1) {
      setMode('edit');
      const { doc: selectedCategory, status } = await getCategory(
        selections[0]
      );

      if (!selectedCategory) return;
      if (isSucceed(status) && selectedCategory) setCategory(selectedCategory);
    } else {
      setCategory(initialCategory);
      setMode('create');
    }
  }, [setCategory, setMode, selections]);

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='موضوعات' />
      <DocsProvider {...categoriesMethods}>
        <Box sx={{ marginY: '1rem' }}>
          <PageHeroBox>
            <PageTitle>موضوعات</PageTitle>
            <Stack>
              <DeleteLoadingButton
                disabled={selections.length === 0}
                onClick={handleOpenDeleteDialog}
              >
                حذف
              </DeleteLoadingButton>
              <LoadingButton
                onClick={getCategoryById}
                disabled={
                  selections.length > 1 || mode === 'create' || mode === 'edit'
                }
              >
                {selections.length === 0 ? 'ایجاد موضوع' : 'ویرایش'}
              </LoadingButton>
            </Stack>
          </PageHeroBox>
          <ColumnStack>
            <Collapse unmountOnExit in={mode === 'edit' || mode === 'create'}>
              <Box>
                <PageHeroBox>
                  <PageTitle>
                    {mode === 'create' ? 'ایجاد موضوع' : 'ویرایش موضوع'}
                  </PageTitle>
                </PageHeroBox>
                <ColumnStack>
                  <CategoryCard
                    category={mode === 'edit' ? category : undefined}
                    onClose={handleClose}
                  />
                </ColumnStack>
              </Box>
            </Collapse>
            <Box>
              <DataGrid
                disabled={loading}
                onPageNumChange={onPageNumChange}
                onPageSizeChange={onPageSizeChange}
                selections={selections}
                onSelectionChange={onSelectionsChange}
                categories={data.list}
                page={data.page}
              />
            </Box>
          </ColumnStack>
        </Box>
      </DocsProvider>
    </>
  );
};

export default WithAuth(Category);

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface KeyboardDoubleArrowRightIconProps extends SvgIconProps {}

const KeyboardDoubleArrowRightIcon: React.FC<
  KeyboardDoubleArrowRightIconProps
> = (props) => (
  <IconBuilder {...props}>
    <path d='M6.41 6 5 7.41 9.58 12 5 16.59 6.41 18l6-6z' />
    <path d='m13 6-1.41 1.41L16.17 12l-4.58 4.59L13 18l6-6z' />
  </IconBuilder>
);

export default KeyboardDoubleArrowRightIcon;

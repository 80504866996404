import { useState, type FC } from 'react';

// Custom Core Components
import Box from 'core/components/base/layout/Box';

// Custom Components
import PatternToolbarIconButton from './Icon';

// Custom Icon Components
import InsertImageIcon from 'core/components/icons/InsertImage';
import InsertSheetColIcon from 'core/components/icons/InsertSheetCol';
import CleanIcon from 'core/components/icons/Clean';
import ConfigIcon from 'core/components/icons/Config';
import RefMenu from './Menu/Ref';
import FileMenu from './Menu/File';

// Custom Types
import type { ToolbarMethods } from '.';
export interface TablePatternCellToolbarProps extends Partial<ToolbarMethods> {}

const TablePatternCellToolbar: FC<TablePatternCellToolbarProps> = (props) => {
  // Props
  const { onInsertRef, onInsertFile, onResetCells, onStylesOpen } = props;

  // States
  const [refAnchorEl, setRefAnchorEl] = useState<null | HTMLElement>(null);
  const refOpen = Boolean(refAnchorEl);

  const [fileAnchorEl, setFileAnchorEl] = useState<null | HTMLElement>(null);
  const fileOpen = Boolean(fileAnchorEl);

  // Utilities
  const handleRefClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setRefAnchorEl(event.currentTarget);
  };

  const handleFileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFileAnchorEl(event.currentTarget);
  };

  const handleRefClose = () => {
    setRefAnchorEl(null);
  };

  const handleFileClose = () => {
    setFileAnchorEl(null);
  };

  const items = [
    {
      title: 'ref',
      Icon: <InsertSheetColIcon />,
      handler: handleRefClick,
      id: 'table-pattern-insert-ref-button',
      'aria-controls': refOpen ? 'table-pattern-insert-ref-menu' : undefined,
      'aria-haspopup': true,
      'aria-expanded': refOpen ? true : undefined,
    },
    {
      title: 'file',
      Icon: <InsertImageIcon />,
      handler: handleFileClick,
      id: 'file-button',
      'aria-controls': fileOpen ? 'file-menu' : undefined,
      'aria-haspopup': true,
      'aria-expanded': fileOpen ? true : undefined,
    },
    { title: 'reset', Icon: <CleanIcon />, handler: onResetCells },
    { title: 'styles', Icon: <ConfigIcon />, handler: onStylesOpen },
  ];

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {items.map(({ title, handler, Icon, ...rest }, index) => (
        <PatternToolbarIconButton
          key={title + index}
          onClick={handler}
          {...rest}
        >
          {Icon}
        </PatternToolbarIconButton>
      ))}
      <RefMenu
        open={refOpen}
        anchorEl={refAnchorEl}
        onMenuClose={handleRefClose}
        onSubmitRef={(opt) => onInsertRef && onInsertRef(opt)}
      />
      <FileMenu
        open={fileOpen}
        anchorEl={fileAnchorEl}
        onMenuClose={handleFileClose}
        onSubmitFile={(file) =>
          onInsertFile &&
          onInsertFile({
            file,
          })
        }
      />
    </Box>
  );
};

export default TablePatternCellToolbar;

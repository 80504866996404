import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface CloseCircleIconProps extends SvgIconProps {}

const CloseCircleIcon: React.FC<CloseCircleIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M12.17,2.09a10,10,0,1,0,10,10A10,10,0,0,0,12.17,2.09Zm5.13,13.1-2,2a.71.71,0,0,1-.39.18.5.5,0,0,1-.44-.14l-2.38-2.38L9.79,17.15a.52.52,0,0,1-.83,0L7.13,15.28a.5.5,0,0,1,0-.8L9.4,12.2,7,9.84a.53.53,0,0,1,0-.84l2-2a.5.5,0,0,1,.79,0l2.37,2.37,2.28-2.28a.57.57,0,0,1,.38-.18.53.53,0,0,1,.41.18l1.84,1.84a.64.64,0,0,1,.17.39.48.48,0,0,1-.14.44L14.93,12l2.37,2.38a.59.59,0,0,1,.18.38A.5.5,0,0,1,17.3,15.19Z' />
  </IconBuilder>
);

export default CloseCircleIcon;

import { useEffect, type FC } from 'react';

// Layouts
import TablePatternConfig from 'features/appBuilder/patterns/features/table/components/Config';
import TablePatternBody from 'features/appBuilder/patterns/features/table/components/Body';

// Custom Core Component
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Custom Hooks
import { useAppDispatch } from 'core/hooks/redux';

// Custom Utilities
import { resetTable } from 'features/appBuilder/patterns/features/table/store';

// Custom Types
interface AddTableProps {}

const AddTable: FC<AddTableProps> = () => {
  // Hooks
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetTable());
  }, []);

  // Render
  return (
    <>
      <ColumnStack>
        <TablePatternConfig />
        <TablePatternBody />
      </ColumnStack>
    </>
  );
};

export default AddTable;

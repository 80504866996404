import * as React from 'react';

// Custom Common Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Core Components
import Dialog from 'core/components/base/feedback/Dialog';
import DialogTitle from 'core/components/base/feedback/DialogTitle';
import Button from 'core/components/base/inputs/Button';
import LoadingButton from 'core/components/base/inputs/LoadingButton';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { DialogProps } from 'core/components/base/feedback/Dialog';
interface DeleteDialogProps extends DialogProps {
  onDelete: () => void;
  description?: string | React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  submitTitle?: string;
  submitColor?:
    | 'inherit'
    | 'info'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'warning'
    | undefined;
}

const DeleteDialog: React.FC<DeleteDialogProps> = (props) => {
  const {
    disabled,
    submitTitle = 'حذف',
    submitColor = 'error',
    description,
    open,
    title = 'آیا مطمئن هستید؟',
    loading,
    onClose,
    onDelete,
    sx,
    ...otherProps
  } = props;

  // Hooks
  const appLoading = useSelectLoading();

  return (
    <Dialog
      open={open}
      onClose={!appLoading && onClose ? (e, r) => onClose(e, r) : undefined}
      PaperProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '0.5rem',
          boxShadow: 'none',
          minWidth: '40rem',
          padding: '5rem',
        },
      }}
      {...otherProps}
    >
      <img src='/images/Delete.svg' />
      <DialogTitle textAlign='center'>{title}</DialogTitle>
      {description && (
        <BodyOne
          sx={{
            fontWeight: 500,
            mb: '1rem',
            padding: '1rem 1.5rem',
            textAlign: 'center',
          }}
        >
          {description}
        </BodyOne>
      )}
      <RowStack justifyContent='center' spacing={4}>
        <Button
          color='inherit'
          disabled={loading || appLoading || disabled}
          onClick={() => onClose && onClose({}, 'escapeKeyDown')}
          sx={{ minWidth: '8rem' }}
          variant='outlined'
        >
          انصراف
        </Button>
        <LoadingButton
          color='error'
          onClick={() => onDelete && onDelete()}
          disabled={disabled}
          loading={loading || appLoading}
          sx={{ minWidth: '8rem' }}
          variant='contained'
        >
          حذف
        </LoadingButton>
      </RowStack>
    </Dialog>
  );
};

export default DeleteDialog;

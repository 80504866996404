import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternEmptyItem from 'features/appBuilder/patterns/core/components/shared/items/Empty';
import PatternImageItem from 'features/appBuilder/patterns/core/components/shared/items/Image';

// Custom Types
export interface ContentPatternConfigSectionItemProps {}
type CollapsedKey = 'section' | 'title' | 'body' | 'image' | 'abstract';

const ContentDetailsPatternConfigSection: FC<
  ContentPatternConfigSectionItemProps
> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    title: false,
    body: false,
    abstract: false,
    image: false,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='تنظیمات محتوا'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='config'
    >
      <PatternEmptyItem
        reference='config.title'
        title='عنوان'
        collapsed={collapsed.title}
        onCollapsedChange={handleCollapseChanged('title')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'fontColor',
          'highlightColor',
        ]}
      />
      <PatternImageItem
        reference='config.image'
        title='تنظیمات عکس شاخص'
        collapsed={collapsed.image}
        onCollapsedChange={handleCollapseChanged('image')}
        include={['padding', 'borderRadius']}
        noUpload
      />
      <PatternEmptyItem
        reference='config.abstract'
        title='چکیده'
        collapsed={collapsed.abstract}
        onCollapsedChange={handleCollapseChanged('abstract')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'fontColor',
          'highlightColor',
        ]}
      />
    </PatternSectionContainer>
  );
};

export default ContentDetailsPatternConfigSection;

import * as React from 'react';

// core components
import Avatar from 'core/components/base/display/Avatar';

interface LogoProps {}

const Logo: React.FC<LogoProps> = (props) => {
  return (
    <Avatar
      alt='Apwa - Portal'
      src='/images/apwa.svg'
      sx={{
        width: 46,
        height: 46,
        fontSize: '0.75rem',
      }}
    >
      Apwa
    </Avatar>
  );
};

export default Logo;

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

// Types
import type { FC } from 'react';

// Custom Hooks
import useMount from 'core/hooks/useMount';
import useUnMount from 'core/hooks/useUnMount';
import useBoolean from 'core/hooks/useBoolean';
import useUpdateEffect from 'core/hooks/useUpdateEffect';

// Core Components
import Collapse from 'core/components/base/utils/Collapse';

// Common Components
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Feature Components
import Feedback from 'features/form/feedbacks/components/details/Feedback';
import FeedbackSkeleton from 'features/form/feedbacks/components/details/Skeleton';
import FeedbackDetailsActions from 'features/form/feedbacks/components/details/Actions';

// Custom Utilities
import { setAppAlert } from 'core/utilities/helper';
import { useFeedbackDoc } from 'features/form/feedbacks/hooks';
import {
  setFeedback,
  useSelectFeedback,
  useSelectProcess,
} from 'features/form/feedbacks/store';

// Custom Types
const FeedbackDetails: FC = () => {
  // States
  const showFeedback = useBoolean();

  // Context
  const feedback = useSelectFeedback();
  const process = useSelectProcess();

  // Hooks
  const { formId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const activeId = searchParams.get('activeId') || '';
  const { isFetching, isSuccess } = useFeedbackDoc({
    id: activeId,
    alertOnDocumentIdIsEmpty: { message: 'شناسه بازخورد یافت نشد.' },
    redirectAfterErrorTo: -1,
    redirectAfterDocumentIdIsEmptyTo: -1,
    onFetch: (doc) => {
      setFeedback(doc.data);
      setTimeout(() => showFeedback.setTrue(), 700);
    },
  });

  useUnMount(() => setFeedback(null));

  useUpdateEffect(() => {
    if (isFetching) showFeedback.setFalse();
  }, [isFetching]);

  useMount(() => {
    if (!formId || !activeId) {
      setTimeout(() => navigate(-1), 2500);
      setAppAlert('شناسه بازخورد یافت نشد.');
    }
  });

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='جزئیات بازخورد' />
      <Collapse in={isSuccess && !isFetching}>
        <PageHeroBox sx={{ height: 'fit-content', maxWidth: '100%' }}>
          <FeedbackDetailsActions />
        </PageHeroBox>
        {feedback && <Feedback feedback={feedback} process={process} />}
      </Collapse>
      <Collapse in={!showFeedback.state}>
        <FeedbackSkeleton />
      </Collapse>
    </>
  );
};

export default FeedbackDetails;

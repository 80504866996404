import MuiLinearProgress, {
  LinearProgressProps as MuiLinearProgressProps,
} from '@mui/material/LinearProgress';
import styled from '@mui/material/styles/styled';

export interface LinearProgressProps extends MuiLinearProgressProps {}

const LinearProgress = styled(MuiLinearProgress)<LinearProgressProps>(
  () => ({})
);

export default LinearProgress;

// Custom Types
import type { PatternType } from 'features/appBuilder/patterns/core/types/item';

export const PatternsEndpoints = {
  add(type: PatternType) {
    return `/pattern/create/${type}`;
  },
  edit(type: PatternType, id: string) {
    return `/pattern/update/${type}?id=${id}`;
  },
  getById(type: PatternType, id: string) {
    return `/pattern/${type}?id=${id}`;
  },
  delete(type: PatternType) {
    return `/pattern/delete/${type}`;
  },
  duplicate(type: PatternType, id: string) {
    return `/pattern/copy/${type}?id=${id}`;
  },
  getByType(type: PatternType) {
    return `/pattern/getPatterns/${type}`;
  },
};

import { useState, type FC, useEffect, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import debounce from 'lodash/debounce';

// Custom Core Components
import Collapse from 'core/components/base/utils/Collapse';

// Custom Common Components
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import CollapseIconButton from 'core/components/shared/IconButton/Collapse';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import RowStack from 'core/components/shared/Stack/RowStack';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import InputLabel from 'core/components/shared/Typography/InputLabel';

// Custom Feature Components
import InputAutocomplete from 'features/form/forms/components/details/item/Settings/autocomplete/InputAutocomplete';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import { setFormActionMode } from 'features/form/forms/store/actions';

// Custom Types
import type { FormProps } from 'features/form/forms/types/item';
export interface FormSettingsViewerFeedbackProps {
  disabled?: boolean;
}

const FormSettingsViewerFeedback: FC<FormSettingsViewerFeedbackProps> = (
  props
) => {
  // Props
  const { disabled } = props;

  // Context
  const formId = useAppSelector((state) => state.form.formId);
  const mode = useAppSelector((state) => state.form.mode);
  const actionMode = useAppSelector((state) => state.form.actionMode);
  const { control, setValue } = useFormContext<FormProps>();

  // Hooks
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const handleCollapsedInitialization = useCallback(() => {
    if (mode === 'add') setCollapsed(false);
    if (mode === 'edit') setCollapsed(true);
  }, [mode]);

  useEffect(() => {
    handleCollapsedInitialization();
  }, [handleCollapsedInitialization]);

  // Utilities
  const toggleCollapse = () => setCollapsed(!collapsed);
  const updateActionMode = debounce(() => {
    if (actionMode !== 'SETTINGS') {
      setFormActionMode('SETTINGS');
    }
  }, 50);

  return (
    <RoundPaper
      sx={{
        backgroundColor: 'background.default',
        borderRadius: '1rem',
        width: '100%',
      }}
    >
      <SpaceBetween>
        <PageTitle color='text.secondary'>بازخورد</PageTitle>
        <CollapseIconButton collapsed={collapsed} onClick={toggleCollapse} />
      </SpaceBetween>
      <Collapse in={collapsed}>
        <ColumnStack pb='1rem'>
          <RowStack spacing={6}>
            <InputWrapper width='40%'>
              <InputLabel indentRight>عنوان بازخورد</InputLabel>
              <Controller
                control={control}
                name='data.publicFeedbackTitles'
                defaultValue={[]}
                render={({ field }) => (
                  <InputAutocomplete
                    formId={formId || ''}
                    disabled={mode === 'add' || !formId || disabled}
                    defaultValue={field.value}
                    onChange={(ids) => {
                      updateActionMode();

                      setValue('data.publicFeedbackTitles', ids);
                    }}
                  />
                )}
              />
            </InputWrapper>
          </RowStack>
        </ColumnStack>
      </Collapse>
    </RoundPaper>
  );
};

export default FormSettingsViewerFeedback;

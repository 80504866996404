import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';

// Feature Components
import RequiredFields from 'features/audience/settings/components/RequiredFields';
import EditableFields from 'features/audience/settings/components/EditableFields';
import ProfileFields from 'features/audience/settings/components/ProfileFields';
import PageAddresses from 'features/audience/settings/components/PageAddresses';

// Custom Common Components
import PageHeadConfigs from 'core/components/shared/PageTitle';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingTwo from 'core/components/shared/Typography/HeadingTwo';
import SubmitLoadingButton from 'core/components/shared/LoadingButton/Submit';

// Custom Utilities
import {
  useAudienceSettingDoc,
  useEditAudienceSettingMutation,
} from 'features/audience/settings/hooks';

// Context
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';
import type { AudienceSettingsFieldsDataProps } from 'features/audience/settings/types';
import type { AudienceSettingsDataProps } from 'features/audience/settings/types';

interface AudienceSettingsComponentProps extends WithAuthProps {}
export type ProfileField = keyof AudienceSettingsFieldsDataProps;

const AudienceSettings: React.FC<AudienceSettingsComponentProps> = (props) => {
  // Context
  const loading = useSelectLoading();

  // Hooks
  const methods = useForm<AudienceSettingsDataProps>({
    mode: 'onTouched',
  });
  const { status, isFetching } = useAudienceSettingDoc({
    enabled: true, // There is no ID to pass to the hook, so fetching won't start; we should enable it manually.
    onFetch: (doc) => doc && methods.reset(doc.data),
  });

  const { mutate: handleEditAudienceSetting } = useEditAudienceSettingMutation({
    autoAlert: { mode: 'edit', name: 'تنظیمات' },
  });

  // Utilities
  const handleFormSubmit: SubmitHandler<AudienceSettingsDataProps> = async (
    data
  ) => {
    handleEditAudienceSetting(data);
  };

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='تنظیمات مخاطبان' />
      <FormProvider {...methods}>
        <PageHeroBox>
          <HeadingTwo>تنظیمات مخاطبان</HeadingTwo>
          <SubmitLoadingButton
            loading={loading || isFetching || status !== 'success'}
            onClick={methods.handleSubmit(handleFormSubmit)}
          >
            ذخیره
          </SubmitLoadingButton>
        </PageHeroBox>
        <RoundPaper sx={{ padding: '3rem' }}>
          <ColumnStack spacing={5}>
            <ProfileFields
              disabled={status !== 'success' || loading || isFetching}
            />
            <RequiredFields
              disabled={status !== 'success' || loading || isFetching}
            />
            <EditableFields
              disabled={status !== 'success' || loading || isFetching}
            />
            <PageAddresses />
          </ColumnStack>
        </RoundPaper>
      </FormProvider>
    </>
  );
};

export default AudienceSettings;

import * as React from 'react';
import { sanitize } from 'isomorphic-dompurify';
import { useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

// Custom Hooks
import useArray from 'core/hooks/useArray';
import useDocsApi from 'core/hooks/api/useDocs';
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// Core Components
import Box from 'core/components/base/layout/Box';
import Dialog from 'core/components/base/feedback/Dialog';

// Common Components
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import CloseIconButton from 'core/components/shared/IconButton/Close';

// Feature Components
import FileTabs from 'features/file/files/components/shared/Dialog/FileTabs';
import FileManagerSection from 'features/file/files/components/shared/Dialog/Sections/FileManager';
import FileUploadSection from 'features/file/files/components/shared/Dialog/Sections/Upload';
import FileExternalSection from 'features/file/files/components/shared/Dialog/Sections/External';

// Custom Utilities
import { setAppAlert } from 'core/utilities/helper';
import { filesQueryKey } from 'features/file/files/hooks';
import { getFiles } from 'features/file/files/utilities/files';
import { generateObjectId } from 'core/utilities/helper/id';
import { getFileTypeLabel } from 'features/file/files/utilities/file';
import { externalUrlSchema } from 'features/file/files/validations/dialog';

// Context
import DocsProvider from 'core/hooks/api/useDocs/Context';

// Custom Types
import type {
  DeprecatedFileLocationType,
  FileFilterProps,
  FileProps,
  FileType,
} from 'features/file/files/types';

export interface FileUploadDialogProps {
  open: boolean;
  disableExternalSection?: boolean;
  disableUploadSection?: boolean;
  disableFileManagerSection?: boolean;
  disableFilesInSection?: boolean;
  multiple?: boolean;
  maxSelect?: number;
  refId?: string;
  location?: DeprecatedFileLocationType;
  script?: string;
  selectedFiles?: FileProps[];
  uploadedFiles?: FileProps[];
  accept?: FileType[];
  onClose: () => void;
  onInsertFile?: (files: FileProps[]) => void;
  onInsertScript?: (url: string) => void;
  onFileUploaded?: (file: FileProps) => void;
}

export interface FileScriptProps {
  url: string;
}

const FileUploadDialog: React.FC<FileUploadDialogProps> = (props) => {
  // Props
  const {
    open,
    multiple = false,
    maxSelect,
    script = '',
    uploadedFiles,
    disableFileManagerSection,
    disableFilesInSection = true,
    disableExternalSection = true,
    disableUploadSection,
    accept = [],
    onClose,
    onInsertFile,
    onInsertScript,
    onFileUploaded,
  } = props;

  // States
  const files = useArray<FileProps>();
  // const uploadedFiles = useArray<FileProps>(); // TODO: Disable Section Files for now.
  const selectedFiles = useArray<FileProps>(props?.selectedFiles || []);
  const uploadQueue = useArray<{ id: string; data: File }>();
  const [selectedTab, setSelectedTab] = React.useState<string>(() => {
    if (!disableUploadSection) return 'upload';
    if (!disableFileManagerSection) return 'file-manager';
    if (!disableExternalSection) return 'external-url';
    if (!disableFilesInSection) return 'section-files';
    return '';
  });

  // Hooks
  const [searchParams, setSearchParams] = useSearchParams();
  const filesDocs = useDocsApi(getFiles, filesQueryKey, {
    defaultPageSize: 10,
    onFetch: files.set,
  });
  const resolver = useYupValidationResolver(externalUrlSchema);
  const scriptFormMethods = useForm<FileScriptProps>({
    mode: 'onTouched',
    resolver,
    defaultValues: {
      url: script,
    },
  });
  const filesFilterMethods = useForm<FileFilterProps>({
    defaultValues: {
      search: sanitize(searchParams.get('search') || ''),
      startDate: sanitize(searchParams.get('startDate') || ''),
      uploadBy: sanitize(searchParams.get('uploadBy') || ''),
      endDate: sanitize(searchParams.get('endDate') || ''),
      type: (searchParams.getAll('type').map((type) => sanitize(type)) ||
        []) as any[],
      location: (searchParams
        .getAll('location')
        .map((location) => sanitize(location)) || []) as any[],
    },
  });

  // Utilities
  const handleClose = React.useCallback(() => {
    handleDeleteFilter();
    onClose();
  }, [onClose]);

  const handleFilter = React.useCallback(() => {
    const {
      startDate,
      endDate,
      search,
      limit,
      location,
      page,
      type,
      minSize,
      maxSize,
    } = filesFilterMethods.getValues();
    setSearchParams({
      ...(type && { type: type.map((item) => sanitize(item)) }),
      ...(page && { page: sanitize(String(page)) }),
      ...(startDate && { startDate: sanitize(startDate) }),
      ...(endDate && { endDate: sanitize(endDate) }),
      ...(limit && { limit: sanitize(String(limit)) }),
      ...(search && { search: sanitize(search) }),
      ...(location && { location: location.map((loc) => sanitize(loc)) }),
      ...(minSize && { minSize: sanitize(minSize) }),
      ...(maxSize && { maxSize: sanitize(maxSize) }),
    });
  }, []);

  const handleDeleteFilter = React.useCallback(() => {
    setSearchParams({});
    filesFilterMethods.reset({
      type: [],
      location: [],
      startDate: '',
      endDate: '',
      minSize: '',
      maxSize: '',
      search: '',
    });
  }, []);

  const handleTabChange = React.useCallback((value: string) => {
    setSelectedTab(value);
    if (value === 'section-files') selectedFiles.clear();
    setSearchParams({});
  }, []);

  const handleAddFileToQueue = React.useCallback((files: File[]) => {
    uploadQueue.set((prevQueues) => [
      ...prevQueues,
      ...files.map((file) => ({
        id: generateObjectId(),
        data: file,
      })),
    ]);
  }, []);

  const handleInsertFiles = React.useCallback(() => {
    if (onInsertFile) onInsertFile(selectedFiles.state);
    selectedFiles.clear();
    onClose();
  }, [selectedFiles.state]);

  const handleInsertScript = React.useCallback(async () => {
    const isValid = await scriptFormMethods.trigger('url');
    if (!isValid) return setAppAlert('وارد کردن آدرس اجباری است.');
    if (onInsertScript) onInsertScript(scriptFormMethods.getValues('url'));
    scriptFormMethods.reset({
      url: '',
    });
    onClose();
  }, []);

  const handleSelectFile = React.useCallback(
    (file: FileProps, selected: boolean) => {
      if (selected) {
        if (isLimitValid(selectedFiles.length + 1) && isTypeValid(file))
          selectedFiles.push(file);
      } else {
        const index = selectedFiles.state.findIndex(
          (item) => item.id === file.id
        );
        selectedFiles.deleteByIndex(index);
      }
    },
    [selectedFiles.state]
  );

  const isLimitValid = (newLength: number): boolean => {
    if (selectedTab === 'section-files') return true;

    if (!multiple && newLength > 1) {
      setAppAlert('حداکثر 1 فایل قابل انتخاب است'.toPersian());
      return false;
    }
    if (typeof maxSelect === 'number' && newLength > maxSelect) {
      setAppAlert(`حداکثر ${maxSelect} فایل قابل انتخاب است.`.toPersian());
      return false;
    }
    return true;
  };

  const isTypeValid = (file: FileProps): boolean => {
    if (selectedTab === 'section-files') return true;

    if (accept.length > 0 && !accept.includes(file.data.type)) {
      let typeLabel = accept.map((type) => getFileTypeLabel(type)).join(' و ');
      setAppAlert(`فایل انتخابی باید از نوع ${typeLabel} باشد.`);
      return false;
    }

    return true;
  };

  const handleToggleAll = React.useCallback(() => {
    if (selectedFiles.length === files.length) selectedFiles.clear();
    else {
      if (isLimitValid(files.length)) selectedFiles.set(files.state);
    }
  }, [selectedFiles.length, files.length, multiple]);

  const handleFileUpdated = (file: FileProps) => {
    files.updateById(file.id, (obj) => (obj.data = file.data));
  };

  // Render
  return (
    <DocsProvider {...filesDocs}>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll='body'
        PaperProps={{
          sx: {
            borderRadius: '1rem',
            minWidth: '80vw',
            padding: '1.5rem',
            minHeight: 'fit-content',
            maxHeight: 'fit-content',
            height: 'fit-content',
          },
        }}
      >
        <ColumnStack spacing='1rem'>
          <SpaceBetween alignItems='center'>
            <FileTabs
              value={selectedTab}
              onTabChange={handleTabChange}
              disableExternalSection={disableExternalSection}
              disableUploadSection={disableUploadSection}
              disableFileManagerSection={disableFileManagerSection}
              disableFilesInSection={disableFilesInSection}
            />
            <CloseIconButton onClick={handleClose} />
          </SpaceBetween>
          {!disableUploadSection && (
            <Box sx={{ display: selectedTab === 'upload' ? 'block' : 'none' }}>
              <FileUploadSection
                files={files}
                uploadQueue={uploadQueue}
                selectedFiles={selectedFiles}
                uploadedFiles={uploadedFiles}
                onToggleAll={handleToggleAll}
                onSelectFile={handleSelectFile}
                onAddFileToQueue={handleAddFileToQueue}
                onInsertFile={handleInsertFiles}
                onFileUploaded={onFileUploaded}
                onFileUpdated={handleFileUpdated}
              />
            </Box>
          )}
          {!disableFileManagerSection && (
            <Box
              sx={{
                display: selectedTab === 'file-manager' ? 'block' : 'none',
              }}
            >
              <FormProvider {...filesFilterMethods}>
                <FileManagerSection
                  files={files}
                  onDeleteFilter={handleDeleteFilter}
                  onFilter={handleFilter}
                  selectedFiles={selectedFiles.state}
                  onToggleAll={handleToggleAll}
                  onInsertFile={handleInsertFiles}
                  onSelectFile={handleSelectFile}
                  onFileUpdated={handleFileUpdated}
                />
              </FormProvider>
            </Box>
          )}
          {!disableExternalSection && (
            <Box
              sx={{
                display: selectedTab === 'external-url' ? 'block' : 'none',
              }}
            >
              <FormProvider {...scriptFormMethods}>
                <FileExternalSection onInsertScript={handleInsertScript} />
              </FormProvider>
            </Box>
          )}
          {/* {!disableFilesInSection && (
            <Box
              sx={{
                display: selectedTab === 'section-files' ? 'block' : 'none',
              }}
            >
              <FilesInSection
                selectedFiles={selectedFiles.state}
                onSelectFile={handleSelectFile}
                onToggleAll={handleToggleAll}
                uploadedFiles={uploadedFiles}
              />
            </Box>
          )} */}
        </ColumnStack>
      </Dialog>
    </DocsProvider>
  );
};

export default FileUploadDialog;

import * as React from 'react';

// Custom Section Components
import TicketTopicsDataGrid from 'features/ticket/topics/components/list/DataGrid';

// Custom Common Components
import Search from 'core/components/shared/Filter/Search';
import RowStack from 'core/components/shared/Stack/RowStack';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageSize from 'core/components/shared/Filter/PageSize';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import Pagination from 'core/components/shared/Filter/Pagination';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import PageHeroButtonLink from 'core/components/shared/Button/PageHeroButtonLink';

// Custom Core Components
import IconButton from 'core/components/base/inputs/IconButton';
import Stack from 'core/components/base/layout/Stack';

// Custom Icon Components
import DeleteIcon from 'core/components/icons/DeleteIcon';

// Custom Hooks
import useSelection from 'core/hooks/common/useSelection';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Context
import DocsProvider from 'core/hooks/api/useDocs/Context';
import SelectionProvider from 'core/store/context/common/UseSelection';
import { useDialogContext } from 'core/store/context/DialogProvider';

// Custom Utilities
import { useTicketTopicDocs } from 'features/ticket/topics/hooks';
import {
  useDeleteTopicsMutation,
  useToggleTopicStatusMutation,
} from 'features/ticket/topics/hooks';

// Custom Types

interface TicketTopicsPageProps {}

const TicketTopics: React.FC<TicketTopicsPageProps> = (props) => {
  // Context
  const loading = useSelectLoading();
  const { onOpenDialog, onCloseDialog } = useDialogContext();

  // Hooks
  const selectionsMethods = useSelection();
  const { selections, onClearSelections } = selectionsMethods;
  const ticketTopicsMethods = useTicketTopicDocs({
    alertOnFetchError: { message: 'دریافت موضوعات تیکت با خطا مواجه شد.' },
    alertOnFetchEmptyList: { message: 'تا کنون موضوعی ایجاد نشده است.' },
  });
  const {
    data: { list, page },
    onFilter,
    onPageSizeChange,
    onPageNumChange,
  } = ticketTopicsMethods;
  const { mutate: handleToggleTopicStatus } = useToggleTopicStatusMutation({
    autoAlert: { mode: 'update', name: 'وضعیت تیکت' },
  });
  const { mutate: handleDeleteTopics } = useDeleteTopicsMutation({
    autoAlert: { mode: 'delete', name: 'موضوعات انتخاب شده' },
    onSuccess: () => onClearSelections(),
    onSettled: () => onCloseDialog(),
  });

  // Utilities
  const handleOpenDeleteDialog = () =>
    onOpenDialog({
      title: 'آیا از حذف موضوعات انتخاب شده مطمئن هستید؟',
      onSubmit: () => handleDeleteTopics(selections),
    });

  // Render
  return (
    <DocsProvider {...ticketTopicsMethods}>
      <PageHeadConfigs pageTitle='موضوعات تیکت' />
      <PageHeroBox>
        <HeadingSix>موضوعات</HeadingSix>
        <Stack>
          <RowStack>
            {selections.length !== 0 && (
              <RowStack sx={{ alignItems: 'center' }} spacing={1}>
                <IconButton
                  disabled={selections.length === 0 || loading}
                  onClick={handleOpenDeleteDialog}
                >
                  <DeleteIcon color='error' />
                </IconButton>
                <BodyTwo>{selections.length?.toPersian()} انتخاب شده</BodyTwo>
              </RowStack>
            )}
            <Search
              onFilter={(search) => onFilter({ ...(search && { search }) })}
              disabled={loading}
            />
          </RowStack>
          <PageHeroButtonLink
            disabled={selections.length > 1 || loading}
            href={`/tickets/topics/add`}
            state={
              selections.length === 1
                ? {
                    groupId: selections[0],
                  }
                : undefined
            }
          >
            موضوع جدید
          </PageHeroButtonLink>
        </Stack>
      </PageHeroBox>
      <ColumnStack>
        <SelectionProvider {...selectionsMethods}>
          <TicketTopicsDataGrid
            list={list}
            disabled={loading}
            onStatusChange={(id, status) =>
              handleToggleTopicStatus({ id, data: status })
            }
          />
        </SelectionProvider>
        <RowStack alignItems='center'>
          <PageSize
            onSizeChange={onPageSizeChange}
            onPageChange={onPageNumChange}
            disabled={loading || list.length === 0}
            page={page}
            sx={{ height: '2rem' }}
          />
          <Pagination
            disabled={loading || list.length === 0}
            page={page}
            onPageChange={onPageNumChange}
            rtl
          />
        </RowStack>
      </ColumnStack>
    </DocsProvider>
  );
};

export default TicketTopics;

import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternButtonItem from 'features/appBuilder/patterns/core/components/shared/items/Button';
import PatternSizeItem from 'features/appBuilder/patterns/core/components/shared/items/Size';
import PatternColorItem from 'features/appBuilder/patterns/core/components/shared/items/Color';
import PatternEmptyItem from 'features/appBuilder/patterns/core/components/shared/items/Empty';
import SliderTabItem from 'features/appBuilder/patterns/features/slider/components/Button/PatternTabItem';

// Custom Types
export interface SliderPatternButtonSectionItemProps {}
type CollapsedKey =
  | 'section'
  | 'tabs'
  | 'archive'
  | 'buttonOne'
  | 'buttonTwo'
  | 'pagination'
  | 'arrows';

const SliderPatternButtonSection: FC<
  SliderPatternButtonSectionItemProps
> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    tabs: false,
    archive: false,
    section: false,
    buttonOne: false,
    buttonTwo: false,
    pagination: false,
    arrows: false,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='تنظیمات دکمه‌ها'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='button'
    >
      <PatternButtonItem
        reference='button.buttonOne'
        title='دکمه ۱'
        collapsed={collapsed.buttonOne}
        onCollapsedChange={handleCollapseChanged('buttonOne')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'flexAlign',
          'color',
          'bgColor',
          'hoverColor',
          'bgHoverColor',
          'borderRadius',
        ]}
      />
      <PatternButtonItem
        reference='button.buttonTwo'
        title='دکمه ۲'
        collapsed={collapsed.buttonTwo}
        onCollapsedChange={handleCollapseChanged('buttonTwo')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'flexAlign',
          'color',
          'bgColor',
          'hoverColor',
          'bgHoverColor',
          'borderRadius',
        ]}
      />
      <PatternSizeItem
        reference='button.arrows'
        title='فلش‌های چپ و راست'
        collapsed={collapsed.arrows}
        onCollapsedChange={handleCollapseChanged('arrows')}
        include={[
          'padding',
          'color',
          'bgColor',
          'hoverColor',
          'bgHoverColor',
          'borderRadius',
        ]}
      />
      <PatternColorItem
        reference='button.pagination'
        title='پجینیشن'
        collapsed={collapsed.pagination}
        onCollapsedChange={handleCollapseChanged('pagination')}
        colorControllers={[{ keyName: 'activeColor', title: 'حالت فعال' }]}
        include={['padding', 'fillColor', 'borderRadius']}
      />
      <PatternEmptyItem
        collapsed={collapsed.archive}
        onCollapsedChange={handleCollapseChanged('archive')}
        title='بایگانی'
        reference='button.archive'
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'flexAlign',
          'color',
          'bgColor',
          'hoverColor',
          'bgHoverColor',
          'borderRadius',
        ]}
      />
    </PatternSectionContainer>
  );
};

export default SliderPatternButtonSection;

import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

// Feature Components
import ManagerGroupAddActions from 'features/manager/groups/components/details/actions/Add';
import ManagerGroupAddBody from 'features/manager/groups/components/details/body/Add';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Custom Utilities
import {
  useEditManagerGroupMutation,
  useManagerGroupDoc,
} from 'features/manager/groups/hooks';

// Custom Types
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';
import type { FormInputs } from 'features/manager/groups/components/details/actions/Add';

interface EditManagerGroupProps extends WithAuthProps {}

const EditManagerGroup: FC<EditManagerGroupProps> = () => {
  // Hooks
  const navigate = useNavigate();
  const formMethods = useForm<FormInputs>({
    mode: 'onTouched',
  });
  const { data: group } = useManagerGroupDoc({
    onFetch: (doc) => {
      formMethods.reset({
        permissions: doc.data.permissions || [],
        title: doc.data.title,
      });
    },
  });
  const { mutate: handleEditManagerGroup } = useEditManagerGroupMutation({
    autoAlert: { mode: 'update', name: 'گروه' },
    stayOnLoadingAfterMutate: true,
    redirectAfterSuccessTo: '/manager-groups',
  });

  const handleSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (!group || !group.id) return;
    handleEditManagerGroup({ id: group.id, data });
  };

  return (
    <>
      <PageHeadConfigs pageTitle='ویرایش گروه مدیران' />
      <FormProvider {...formMethods}>
        <ManagerGroupAddActions mode='edit' onSubmit={handleSubmit} />
        <ManagerGroupAddBody />
      </FormProvider>
    </>
  );
};

export default EditManagerGroup;

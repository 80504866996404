import {
  PaginationItem as MuiPaginationItem,
  PaginationItemProps as MuiPaginationItemProps,
  styled,
} from '@mui/material';

export interface PaginationItemProps extends MuiPaginationItemProps {}

const PaginationItem = styled(MuiPaginationItem)<PaginationItemProps>(
  () => ({})
);

export default PaginationItem;

import * as React from 'react';

// Custom Common Components
import Header from 'core/components/shared/Header';
import Sidebar from 'core/components/shared/Sidebar';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import Notification from 'core/components/shared/Notification';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Container from 'core/components/base/layout/Container';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';

interface MainLayoutProps {
  children?: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = (props) => {
  // Props
  const { children } = props;

  // Hooks
  const { collapsed } = useAppSelector((state) => state.sidebar);
  const { message } = useAppSelector((state) => state.notification);

  // Render
  return (
    <>
      <Header />
      <Container
        sx={{ marginY: '1rem', overflowX: 'auto', overflowY: 'hidden' }}
      >
        <Box sx={{ display: 'flex', gap: 2, maxWidth: '100%' }}>
          <Box sx={{ maxWidth: 'fit-content' }}>
            <PageHeroBox />
            <Sidebar />
          </Box>
          <Box
            sx={({ breakpoints }) => ({
              flexGrow: 1,
              width: `calc(99% - ${collapsed ? 4 : 12}rem)`,
              [breakpoints.down('lg')]: {
                width: `calc(98% - ${collapsed ? 4 : 12}rem)`,
              },
            })}
          >
            {children}
          </Box>
        </Box>
      </Container>
      {message && <Notification />}
    </>
  );
};

export default MainLayout;

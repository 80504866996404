import * as Yup from 'yup';

const AddAudienceFolderValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('وارد کردن عنوان پوشه اجباری است')
    .label('عنوان پوشه'),
  capacity: Yup.number()
    .test({
      test: (v) => {
        if (!v) return true;

        if (v < 0) return false;

        if (v > 10000) return false;

        return true;
      },
      message(params) {
        if (params.value) {
          if (params.value > 10000)
            return 'حجم پوشه نمی‌تواند بیشتر از ۱۰۰۰۰ مگابایت باشد.';
          if (params.value < 0)
            return 'حجم پوشه نمی‌تواند کمتر از صفر مگابایت باشد.';
        }
      },
    })
    .label('حجم پوشه'),
});

export default AddAudienceFolderValidationSchema;

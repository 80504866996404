import * as React from 'react';

// Core Components
import MenuItem from 'core/components/base/navigation/MenuItem';
import Select from 'core/components/base/inputs/Select';
import Stack from 'core/components/base/layout/Stack';

// Common Components
import PageHeadConfigs from 'core/components/shared/PageTitle';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyOne from 'core/components/shared/Typography/BodyOne';
import Pagination from 'core/components/shared/Filter/Pagination';
import PageSize from 'core/components/shared/Filter/PageSize';
import Search from 'core/components/shared/Filter/Search';

// Feature Components
import TicketsDataGrid from 'features/ticket/tickets/components/list/DataGrid';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import { useTicketDocs } from 'features/ticket/tickets/hooks';
import { ticketMessageStatusMap } from 'features/ticket/tickets/utilities';
import { useToggleTicketStatusMutation } from 'features/ticket/tickets/hooks';

// Custom Types
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';
import type { MessageStatusType } from 'features/ticket/tickets/types';

interface TicketsPageProps extends WithAuthProps {}

const Tickets: React.FC<TicketsPageProps> = (props) => {
  // States
  const [filterStatus, setFilterStatus] = React.useState<
    MessageStatusType | ''
  >('');

  // Context
  const loading = useSelectLoading();

  // Hooks
  const {
    data: { page, list },
    onFilter,
    onPageNumChange,
    onPageSizeChange,
  } = useTicketDocs({
    alertOnFetchEmptyList: { message: 'تا کنون تیکتی ایجاد نشده است.' },
    alertOnFetchError: { message: 'دریافت لیست تیکت‌ها با خطا مواجه شد.' },
  });

  const { mutate: handleToggleTicketStatus } = useToggleTicketStatusMutation({
    autoAlert: { mode: 'update', name: 'وضعیت تیکت' },
  });

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='تیکت‌ها' />
      <PageHeroBox>
        <HeadingSix>تیکت‌ها</HeadingSix>
        <Stack>
          <RowStack>
            <Select
              defaultValue='all'
              disabled={loading}
              renderValue={(value) => {
                if (typeof value === 'string') {
                  if (!value) return <BodyOne>همه وضعیت‌ها</BodyOne>;
                  if (Object.keys(ticketMessageStatusMap).includes(value))
                    return (
                      <BodyOne>
                        {ticketMessageStatusMap[value as MessageStatusType]}
                      </BodyOne>
                    );
                  return <BodyOne>همه وضعیت‌ها</BodyOne>;
                } else return <BodyOne>همه وضعیت‌ها</BodyOne>;
              }}
              sx={({ palette }) => ({
                backgroundColor: palette.background.paper,
                height: '2.5rem',
                minWidth: '10rem',
              })}
            >
              <MenuItem onClick={() => setFilterStatus('')} value='all'>
                همه وضعیت‌ها
              </MenuItem>
              {Object.keys(ticketMessageStatusMap).map((optStatus, key) => (
                <MenuItem
                  key={`filter-item-${optStatus}-${key}`}
                  value={optStatus}
                  onClick={() => {
                    const statusToSet: MessageStatusType | '' =
                      optStatus === filterStatus
                        ? ''
                        : (optStatus as MessageStatusType);
                    setFilterStatus(statusToSet);
                  }}
                >
                  {ticketMessageStatusMap[optStatus as MessageStatusType]}
                </MenuItem>
              ))}
            </Select>
            <Search
              onFilter={(search) => onFilter({ ...(search && { search }) })}
              disabled={loading}
              inputProps={{
                style: {
                  height: '.48rem',
                },
              }}
            />
          </RowStack>
        </Stack>
      </PageHeroBox>
      <ColumnStack>
        <TicketsDataGrid
          disabled={loading}
          tickets={list}
          onStatusChange={(id, status) =>
            handleToggleTicketStatus({ id, data: status })
          }
        />
        <RowStack>
          <PageSize
            onSizeChange={onPageSizeChange}
            onPageChange={onPageNumChange}
            disabled={loading || list.length === 0}
            page={page}
            sx={{ height: '2rem' }}
          />
          <Pagination
            disabled={loading || list.length === 0}
            page={page}
            onPageChange={onPageNumChange}
            rtl
          />
        </RowStack>
      </ColumnStack>
    </>
  );
};

export default Tickets;

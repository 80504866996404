import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface TextDecreaseIconProps extends SvgIconProps {}

const TextDecreaseIcon: React.FC<TextDecreaseIconProps> = (props) => {
  const { color = 'inherit', ...otherProps } = props;
  const styles: React.CSSProperties = {
    fill: color,
    fillRule: 'evenodd',
  };

  return (
    <IconBuilder color={color} {...otherProps}>
      <path d='M8.69565 5H20V7.21739H15.5217V19H13.1739V7.21739H8.69565V5ZM5.34783 12.3913H6.91304H9.26087V13.8696H6.91304H5.34783H4V12.3913H5.34783Z' />
    </IconBuilder>
  );
};

export default TextDecreaseIcon;

// Custom Icon Components
import AgreementIcon from 'features/form/forms/components/icons/Agreement';
import AttachFileIcon from 'features/form/forms/components/icons/AttachFile';
import CheckboxFilledIcon from 'core/components/icons/CheckboxFilled';
import NumbersFilledIcon from 'core/components/icons/NumbersFilled';
import RadioButtonFilledIcon from 'core/components/icons/RadioButtonFilled';
import TextFilledIcon from 'core/components/icons/TextFilled';
import TextareaFilledIcon from 'core/components/icons/TextareaFilled';
import DropdownFilledIcon from 'core/components/icons/DropdownFilled';
import PhoneFilledIcon from 'core/components/icons/PhoneFilled';
import EmailFilledIcon from 'core/components/icons/EmailFilled';
import FingerprintFilledIcon from 'core/components/icons/FingerprintFilled';
import CalendarFilledIcon from 'core/components/icons/CalendarFilled';
import ClockFilledIcon from 'core/components/icons/ClockFilled';
import RateFilledIcon from 'core/components/icons/RateFilled';
import ScoreFilledIcon from 'core/components/icons/ScoreFilled';

// Custom Types
import type { FormInputType } from 'features/form/forms/types/item/input/type';

export const formInputTypeDetailsMap: {
  [key in FormInputType]: {
    shortLabel: string;
    fullLabel: string;
    Icon: JSX.Element;
  };
} = {
  text: {
    shortLabel: 'کوتاه',
    fullLabel: 'متن کوتاه',
    Icon: <TextFilledIcon />,
  },
  number: {
    shortLabel: 'عدد',
    fullLabel: 'عدد',
    Icon: <NumbersFilledIcon />,
  },
  radio: {
    shortLabel: 'تک',
    fullLabel: 'تک انتخابی',
    Icon: <RadioButtonFilledIcon />,
  },
  checkbox: {
    shortLabel: 'چند',
    fullLabel: 'چند انتخابی',
    Icon: <CheckboxFilledIcon />,
  },
  file: {
    shortLabel: 'فایل',
    fullLabel: 'فایل',
    Icon: <AttachFileIcon />,
  },
  agreement: {
    shortLabel: 'توافق',
    fullLabel: 'توافق‌نامه',
    Icon: <AgreementIcon />,
  },
  textarea: {
    shortLabel: 'بلند',
    fullLabel: 'متن بلند',
    Icon: <TextareaFilledIcon />,
  },
  select: {
    shortLabel: 'کرکره',
    fullLabel: 'کرکره‌ای',
    Icon: <DropdownFilledIcon />,
  },
  mobile: {
    shortLabel: 'موبایل',
    fullLabel: 'شماره موبایل',
    Icon: <PhoneFilledIcon />,
  },
  email: {
    shortLabel: 'ایمیل',
    fullLabel: 'آدرس ایمیل',
    Icon: <EmailFilledIcon />,
  },
  nationalId: {
    shortLabel: 'کد ملی',
    fullLabel: 'کد ملی',
    Icon: <FingerprintFilledIcon />,
  },
  date: {
    shortLabel: 'تاریخ',
    fullLabel: 'تاریخ',
    Icon: <CalendarFilledIcon />,
  },
  time: {
    shortLabel: 'ساعت',
    fullLabel: 'ساعت',
    Icon: <ClockFilledIcon />,
  },
  rate: {
    shortLabel: 'رتبه',
    fullLabel: 'رتبه',
    Icon: <RateFilledIcon />,
  },
  score: {
    shortLabel: 'امتیاز',
    fullLabel: 'امتیاز',
    Icon: <ScoreFilledIcon />,
  },
};

/**
 * Retrieves the label for a given form input type.
 *
 * This function returns either the full label or the short label for the specified
 * form input type based on the provided parameters.
 *
 * @param {FormInputType} type - The type of the form input for which to retrieve the label.
 * @param {boolean} [full=false] - A flag indicating whether to return the full label (true) or the short label (false).
 * @returns {string} The label corresponding to the specified form input type.
 *
 * @example
 * const label = getFormInputTypeLabel('text', true);
 * // label will be the full label for the 'text' input type.
 */
export const getFormInputTypeLabel = (
  type: FormInputType,
  full?: boolean
): string =>
  full
    ? formInputTypeDetailsMap[type].fullLabel
    : formInputTypeDetailsMap[type].shortLabel;

/**
 * Retrieves the icon for a given form input type.
 *
 * This function returns the icon associated with the specified form input type
 * from the `formInputTypeDetailsMap`.
 *
 * @param {FormInputType} type - The type of the form input for which to retrieve the icon.
 * @returns {React.ElementType} The icon component corresponding to the specified form input type.
 *
 * @example
 * const icon = getFormInputTypeIcon('text');
 * // icon will be the icon component for the 'text' input type.
 */
export const getFormInputTypeIcon = (type: FormInputType) =>
  formInputTypeDetailsMap[type].Icon;

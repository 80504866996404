// Types
import type { FC } from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface FormatAlignLeftProps extends SvgIconProps {}

const FormatAlignLeft: FC<FormatAlignLeftProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <g>
        <path d='M2.3,4.7h19.5c0.1,0,0.3,0.1,0.3,0.2v2.9C22,7.9,21.9,8,21.7,8H2.3C2.1,8,2,7.9,2,7.8V4.9C2,4.8,2.1,4.7,2.3,4.7z' />
        <path
          d='M2.3,10.3h17.5c0.1,0,0.3,0.1,0.3,0.2v2.9c0,0.1-0.1,0.2-0.3,0.2H2.3c-0.1,0-0.3-0.1-0.3-0.2v-2.9
		C2,10.4,2.1,10.3,2.3,10.3z'
        />
        <path d='M2.3,16H12c0.1,0,0.3,0.1,0.3,0.2v2.9c0,0.1-0.1,0.2-0.3,0.2H2.3c-0.1,0-0.3-0.1-0.3-0.2v-2.9C2,16.1,2.1,16,2.3,16z' />
      </g>
    </IconBuilder>
  );
};

export default FormatAlignLeft;

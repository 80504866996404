export const extractHtmlText = (
  htmlString: string,
  limit: number = 42
): string => {
  const tempEl = document.createElement('div');
  tempEl.innerHTML = htmlString;

  const textContent = tempEl.textContent || '';

  return (
    textContent.substring(0, limit) + (textContent.length > limit ? '...' : '')
  );
};

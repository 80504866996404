export const tableScrollbarStyles = {
  '::-webkit-scrollbar': {
    marginTop: '1rem',
    width: '10px',
    height: '1rem',
  },
  '::-webkit-scrollbar-track': {
    background: '#fff',
    borderRadius: '1rem',
    overflow: 'hidden',
  },
  '::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '0.5rem',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
};

export const customScrollbarStyles = {
  '::-webkit-scrollbar': {
    width: '10px',
    height: '6px',
  },
  '::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '::-webkit-scrollbar-thumb': {
    background: '#888',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
};

export const customRoundedScrollbarStyles = {
  '::-webkit-scrollbar': {
    width: '10px',
    height: '6px',
  },
  '::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '5px',
    overflow: 'hidden',
  },
  '::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '5px',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
};

import type { FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';

// Common Components
import InputWrapper from 'core/components/shared/Wrapper/Input';
import TextFieldNumber from 'core/components/shared/TextField/number';

// Feature Shared Components
import PatternItemContainer from 'features/appBuilder/patterns/core/components/shared/items/Container';
import PatternImageDisplayTypeDropdown from 'features/appBuilder/patterns/core/components/shared/dropdown/DisplayType';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { PatternItemContainerProps } from 'features/appBuilder/patterns/core/components/shared/items/Container';
export interface PatternDisplayCardItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
  noDisplayType?: boolean;
  noWidth?: boolean;
}

const PatternDisplayCardItem: FC<PatternDisplayCardItemProps> = (props) => {
  // Props
  const { reference, noDisplayType, noWidth, ...otherProps } = props;

  // Hooks
  const { control, getValues } = useFormContext();
  const loading = useSelectLoading();
  const displayType = useWatch({
    control,
    name: `${reference}.displayType`,
    defaultValue: getValues(`${reference}.displayType`) || 'default',
  });

  // Render
  return (
    <PatternItemContainer reference={reference} {...otherProps}>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          '[&>*]': { flexGrow: 1 },
        }}
      >
        {!noDisplayType && (
          <InputWrapper label='نوع نمایش' sx={{ width: '100%' }}>
            <Controller
              control={control}
              name={`${reference}.displayType`}
              render={({ field }) => (
                <PatternImageDisplayTypeDropdown
                  disabled={loading}
                  fullWidth
                  defaultValue={field.value}
                  reference={reference}
                />
              )}
            />
          </InputWrapper>
        )}
        {!noWidth && (
          <InputWrapper label='عرض کارت‌ها' sx={{ width: '100%' }}>
            <Controller
              control={control}
              name={`${reference}.width`}
              render={({ field }) => (
                <TextFieldNumber
                  disabled={displayType === 'default' || loading}
                  {...field}
                />
              )}
            />
          </InputWrapper>
        )}
      </Box>
    </PatternItemContainer>
  );
};

export default PatternDisplayCardItem;

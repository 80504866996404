import * as React from 'react';

// Custom Core Components
import Paper from 'core/components/base/surfaces/Paper';

// Custom Types
import type { PaperProps } from 'core/components/base/surfaces/Paper';
interface TableHeaderPaperProps extends PaperProps {}

const TableHeaderPaper: React.FC<TableHeaderPaperProps> = (props) => {
  const { children, sx, ...otherProps } = props;

  return (
    <Paper
      sx={{
        boxShadow: 'none',
        borderRadius: '0.25rem',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottom: '2px solid #C1C8D3',
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Paper>
  );
};

export default TableHeaderPaper;

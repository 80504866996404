// Custom Types
import type { TablePatternDataProps } from 'features/appBuilder/patterns/features/table/types';

/**
 * Validates the table pattern data and returns an error message if validation fails.
 *
 * @param {TablePatternDataProps} data - The table pattern data to validate.
 * @returns {string} A string containing the error message if validation fails, or an empty string if the data is valid.
 */
export const validateTablePattern = (data: TablePatternDataProps): string => {
  if (!data.patternTitle.trim()) {
    return 'وارد کردن عنوان پترن الزامی می‌باشد.';
  }

  if (data.rowCount === 0) {
    return 'جدول باید حداقل یک سطر داشته باشد.';
  }

  if (data.colCount === 0) {
    return 'جدول باید حداقل یک ستون داشته باشد.';
  }

  return '';
};

// Custom Core Components
import Switch from 'core/components/base/inputs/Switch';

// Custom Common Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import RouteLink from 'core/components/shared/Link/RouteLink';

// Custom Utilities
import { getLocaleDateAndTime } from 'core/utilities/helper/date';
import { editFeedbackIsEditableStatus } from 'features/form/feedbacks/utilities/api/list';
import { isSucceed, setAppAlert, setAppLoading } from 'core/utilities/helper';

// Custom Types
import {
  AudienceDataField,
  AudienceDataFieldsLabel,
} from 'features/audience/settings/types';
import type { DataGridColumn } from 'core/components/shared/DataGrid/dataGrid';

const handleIsEditableChange = async (
  id: string,
  checked: boolean,
  refresh: () => void
) => {
  setAppLoading(true);

  const { status } = await editFeedbackIsEditableStatus({
    selection: [id],
    isEditable: checked,
  });

  if (isSucceed(status)) {
    refresh();
  } else {
    setAppAlert('خطا در بروزرسانی وضعیت بازخورد');
  }
  setAppLoading(false);
};

const getFeedbacksListDataGridColumns = (
  title: string[],
  refresh: () => void,
  loading: boolean,
  formId: string,
  isPublic: boolean
): DataGridColumn[] => {
  let titleArray: string[] = [];

  if (isPublic) {
    titleArray = title;
  } else {
    titleArray = title.map(
      (t) => AudienceDataFieldsLabel[t as AudienceDataField] || t
    );
  }

  return [
    {
      field: 'col1',
      headerTitle: titleArray.length > 0 ? titleArray.join(' و ') : 'عنوان',
      width: '20%',
      renderCell: (params) =>
        formId ? (
          <RouteLink
            sx={{ color: 'info.main' }}
            underline='hover'
            href={`/feedbacks/${formId}/details?activeId=${params.row.id}&feedbackId=${params.row.id}`}
          >
            {params.row.col1 || '-'}
          </RouteLink>
        ) : (
          <BodyTwo color='info.main'>{params.row.col1 || '-'}</BodyTwo>
        ),
    },
    {
      field: 'col2',
      headerTitle: 'تاریخ ثبت',
      width: '20%',
      renderCell: (params) => (
        <BodyTwo dir='ltr'>
          {getLocaleDateAndTime(params.row.col2, ' - ')}
        </BodyTwo>
      ),
    },
    {
      field: 'col3',
      headerTitle: 'آخرین وضعیت فرایند',
      width: '20%',
      align: 'center',
      renderCell: (params) => (
        <BodyTwo
          dir='ltr'
          sx={{
            bgcolor: 'background.default',
            display: 'inline',
            p: '2px 4px',
            borderRadius: '4px',
            color: 'text.secondary',
          }}
        >
          {params.row.col3 || ' - '}
        </BodyTwo>
      ),
    },
    {
      field: 'col4',
      headerTitle: 'تاریخ ثبت آخرین وضعیت',
      width: '30%',
      renderCell: (params) => (
        <BodyTwo dir='ltr'>
          {params.row.col4 ? getLocaleDateAndTime(params.row.col4) : ' - '}
        </BodyTwo>
      ),
    },
    {
      field: 'col5',
      headerTitle: 'ویرایش بازخورد فرم',
      width: '10%',
      renderCell: (params) => (
        <Switch
          disabled={loading || isPublic}
          onChange={(_, checked) =>
            handleIsEditableChange(params.row.id, checked, refresh)
          }
          checked={params.row.col5}
          color='info'
        />
      ),
    },
  ];
};

export default getFeedbacksListDataGridColumns;

import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Core Components
import Box from 'core/components/base/layout/Box';
import Paper from 'core/components/base/surfaces/Paper';
import IconButton from 'core/components/base/inputs/IconButton';

// Custom Common Commponents
import BodyOne from 'core/components/shared/Typography/BodyOne';

// Custom Icons
import CloseCircleIcon from 'core/components/icons/CloseCircle';

// Icon Components
import FileUploadIcon from 'core/components/icons/FileUpload';

// Utilities
import { prefixFileURL } from 'core/utilities/helper/link';

// Context
import { useUploadDialogContext } from 'features/file/files/context/UploadDialog';

// Custom Types
import type { PaperProps } from 'core/components/base/surfaces/Paper';
import type {
  DeprecatedFileLocationType,
  FileProps,
  FileType,
} from 'features/file/files/types';

export type OnUploadChange = (
  event: React.ChangeEvent<HTMLInputElement>
) => void;

export interface PreviewProps {
  previewAlt?: string;
  previewSrc: string;
}

interface UploadProps extends FileWrapperProps {
  location: DeprecatedFileLocationType;
  previewProps?: PreviewProps;
  multiple?: boolean;
  maxSelect?: number;
  accept?: FileType[];
  onUploadChange?: (files: FileProps | null) => void;
  onUploadRemove?: () => void;
}

interface FileWrapperProps extends PaperProps {
  error?: boolean;
  disabled?: boolean;
}

const FileUploader: React.FC<UploadProps> = React.forwardRef((props, ref) => {
  // Props
  const {
    error,
    disabled,
    previewProps,
    location,
    accept = [],
    multiple = false,
    maxSelect,
    onUploadChange,
    onUploadRemove,
    sx,
    ...otherProps
  } = props;

  // Context
  const loading = useSelectLoading();
  const { setSettings } = useUploadDialogContext();

  // Hooks
  const theme = useTheme();

  // Utilities
  const handleOpenDialog = () => {
    setSettings({
      open: true,
      accept,
      multiple,
      maxSelect,
      location,
      onInsertFile: (files) => onUploadChange && onUploadChange(files[0]),
    });
  };

  // Render
  return (
    <Paper
      sx={{
        alignItems: 'center',
        borderRadius: '8px',
        boxShadow: 'none',
        border: `1px #12252E80 dashed`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        minHeight: '100%',
        overflow: 'hidden',
        ':hover': {
          borderColor: theme.palette.info.main,
          p: {
            color: theme.palette.info.main,
          },
          svg: {
            path: {
              fill: theme.palette.info.main,
            },
          },
        },
        p: {
          color: theme.palette.text.disabled,
        },
        ...sx,
      }}
      {...otherProps}
    >
      {previewProps && previewProps.previewSrc ? (
        <>
          <IconButton
            disabled={disabled || loading}
            onClick={() => onUploadRemove && onUploadRemove()}
            sx={{
              position: 'absolute',
              top: 10,
              left: 10,
              zIndex: 10,
            }}
          >
            <CloseCircleIcon />
          </IconButton>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              padding: '0.5rem',
              position: 'absolute',
            }}
          >
            <img
              alt={previewProps?.previewAlt}
              src={prefixFileURL(previewProps.previewSrc)}
              style={{
                borderRadius: '.25rem',
                filter: disabled ? 'grayscale(50%) brightness(90%)' : 'none',
                height: '100%',
                objectFit: 'contain',
                width: '100%',
              }}
            />
          </Box>
        </>
      ) : (
        <Box
          onClick={handleOpenDialog}
          sx={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            cursor: disabled || loading ? 'auto' : 'pointer',
            display: 'inherit',
            flexDirection: 'inherit',
            justifyContent: 'center',
          }}
        >
          <FileUploadIcon
            sx={{
              position: 'relative',
              right: '.3rem',
              color: 'text.disabled',
            }}
          />
          <BodyOne>آپلود</BodyOne>
        </Box>
      )}
    </Paper>
  );
});

export default FileUploader;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface MinimizeIconProps extends SvgIconProps {}

const MinimizeIcon: React.FC<MinimizeIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M15.5,2.2l6.3,6.3c0.4,0.4,0.1,1.2-0.5,1.2l-6.4,0c-0.4,0-0.7-0.3-0.7-0.7l0-6.3C14.3,2.1,15,1.8,15.5,2.2z' />
    <path d='M8.5,21.8l-6.3-6.3c-0.4-0.4-0.1-1.2,0.5-1.2l6.4,0c0.4,0,0.7,0.3,0.7,0.7l0,6.3C9.7,21.9,9,22.2,8.5,21.8z' />
  </IconBuilder>
);

export default MinimizeIcon;

import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { useSearchParams } from 'react-router-dom';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';
import {
  setIsFeedbackPdfPreviewOpen,
  useSelectIsPdfPreviewOpen,
} from 'features/form/feedbacks/store';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';
import CircularProgress from 'core/components/base/feedback/CircularProgress';

// Custom Feature Components
import PdfPreview from 'features/form/feedbacks/components/details/Actions/Pdf/Preview';
import Popup from 'features/form/feedbacks/components/details/Actions/Pdf/Popup';

// Custom Icons
import PdfIcon from 'core/components/icons/PDF';

// Custom Types
interface PDFButtonProps {}

const PdfButton: React.FC<PDFButtonProps> = (props) => {
  // Context
  const loading = useSelectLoading();
  const openPreview = useSelectIsPdfPreviewOpen();
  // States
  const [openPopup, setOpenPopup] = React.useState<boolean>(false);

  // Hooks
  const { palette } = useTheme();
  const [params] = useSearchParams();
  const feedbackIds = params.getAll('feedbackId');

  // Utilities
  const handlePDF = () => {
    if (feedbackIds.length > 1) handleOpenPopup();
    else handleSinglePrint();
  };

  const handleOpenPreview = () => setIsFeedbackPdfPreviewOpen(true);
  const handleClosePreview = () => setIsFeedbackPdfPreviewOpen(false);
  const handleClosePopup = () => setOpenPopup(false);
  const handleOpenPopup = () => setOpenPopup(true);
  const handleSinglePrint = () => {
    handleClosePopup();
    setTimeout(() => window.print(), 200);
  };

  const handlePrintAll = () => {
    handleClosePopup();
    // API Call Here -> Fetch Feedbacks Data;
    setTimeout(() => handleOpenPreview(), 200);
  };

  // Render
  return (
    <>
      <Button
        onClick={handlePDF}
        disabled={loading}
        variant='contained'
        startIcon={!loading && <PdfIcon color='error' />}
        sx={{
          background: palette.common.white,
          color: palette.common.black,
          minWidth: '5.938rem',
          maxWidth: '5.938rem',
          height: '2.625rem',
          borderRadius: '.25rem',
          transition: 'opacity .3s',
          ':hover': {
            background: palette.common.white,
            opacity: '.7',
          },
        }}
      >
        {loading ? <CircularProgress size='1.2rem' color='inherit' /> : 'PDF'}
      </Button>
      <Popup
        open={openPopup}
        onClose={handleClosePopup}
        onSinglePdf={handleSinglePrint}
        onAllPdf={handlePrintAll}
      />
      {openPreview && (
        <PdfPreview open={openPreview} onClose={handleClosePreview} />
      )}
    </>
  );
};

export default PdfButton;

import { type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

// Custom Hooks
import useMount from 'core/hooks/useMount';
import useBoolean from 'core/hooks/useBoolean';
import useUpdateEffect from 'core/hooks/useUpdateEffect';

// Custom Common Components
import TextFieldNumber from 'core/components/shared/TextField/number';

// Custom Core Components
import Box, { BoxProps } from 'core/components/base/layout/Box';
import FourWayArrow from 'core/components/icons/FourwayArrow';
import TextField from 'core/components/base/inputs/TextField';

// Custom Icon Components
import KeyboardArrowDownIcon from 'core/components/icons/ArrowDown';

// Custom Types
interface PatternPaddingProps extends Omit<BoxProps, 'onChange'> {
  defaultValue?: string;
  disabled?: boolean;
  onChange?: (padding: string) => void;
}

const PatternPadding: FC<PatternPaddingProps> = (props) => {
  // Props
  const { defaultValue, disabled, onChange, sx, ...otherProps } = props;

  // States
  const isInitialized = useBoolean();

  // Hooks
  const { control, getValues, setValue } = useForm<{
    top: number;
    right: number;
    bottom: number;
    left: number;
    main: number | string;
  }>({
    defaultValues: {
      bottom: 0,
      left: 0,
      right: 0,
      top: 0,
      main: 0,
    },
  });

  useMount(() => {
    if (!defaultValue) return;
    handleInitializeState(defaultValue);
  });

  useUpdateEffect(() => {
    if (isInitialized.state || !defaultValue) return;
    handleInitializeState(defaultValue);
    isInitialized.setTrue();
  }, [defaultValue]);

  // Helpers
  const handleInitializeState = (defaultValue: string) => {
    const { top, right, bottom, left } = extractPaddingValues(defaultValue);

    setValue('top', top);
    setValue('right', right);
    setValue('bottom', bottom);
    setValue('left', left);

    setValue(
      'main',
      top === right && top === bottom && top === left ? top : 'mix'
    );
  };
  const extractPaddingValues = (
    paddingString: string
  ): { top: number; right: number; bottom: number; left: number } => {
    const values = paddingString.split(' ').map((val) => parseInt(val));
    let top = 0,
      right = 0,
      bottom = 0,
      left = 0;

    if (values.length === 1) {
      top = right = bottom = left = values[0];
    } else if (values.length === 2) {
      [top, bottom] = [values[0], values[0]];
      [right, left] = [values[1], values[1]];
    } else if (values.length === 3) {
      [top, right, bottom] = [values[0], values[1], values[2]];
      left = right;
    } else if (values.length === 4) {
      [top, right, bottom, left] = values;
    }

    return { top, right, bottom, left };
  };

  // Utilities
  const updatePaddingByMain = (value: number) => {
    if (value >= 0) {
      if (onChange) onChange(`${value}px`);

      setValue('top', value);
      setValue('right', value);
      setValue('bottom', value);
      setValue('left', value);
      setValue('main', value);
    }
  };

  const updatePadding = () => {
    let pTop = getValues('top');
    pTop = pTop && pTop > 0 ? pTop : 0;

    let pRight = getValues('right');
    pRight = pRight && pRight > 0 ? pRight : 0;

    let pBottom = getValues('bottom');
    pBottom = pBottom && pBottom > 0 ? pBottom : 0;

    let pLeft = getValues('left');
    pLeft = pLeft && pLeft > 0 ? pLeft : 0;

    const padding = `${pTop}px ${pRight}px ${pBottom}px ${pLeft}px`;

    if (pTop == pRight && pTop == pBottom && pTop == pLeft) {
      setValue('main', pTop);
    } else {
      setValue('main', 'mix');
    }

    if (onChange) onChange(padding);
  };

  // Render
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '0.5rem',
        gap: 1,
        ...sx,
      }}
      {...otherProps}
    >
      <Controller
        control={control}
        name='top'
        rules={{ onChange: updatePadding }}
        render={({ field }) => (
          <TextFieldNumber
            sx={{
              '.MuiOutlinedInput-root': {
                paddingLeft: '6px',
              },
              '.MuiInputBase-input': {
                padding: '12px',
              },
              width: '80px',
              '& input[type=number]': {
                '-moz-appearance': 'textfield',
              },
              '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
              '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
            }}
            disabled={disabled}
            InputProps={{
              startAdornment: (
                <KeyboardArrowDownIcon
                  sx={{
                    color: 'text.secondary',
                    transform: 'rotate(180deg)',
                  }}
                />
              ),
            }}
            {...field}
          />
        )}
      />
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Controller
          control={control}
          rules={{ onChange: updatePadding }}
          name='right'
          render={({ field }) => (
            <TextFieldNumber
              sx={{
                '.MuiOutlinedInput-root': {
                  paddingLeft: '6px',
                },
                '.MuiInputBase-input': {
                  padding: '12px',
                },
                width: '80px',
                '& input[type=number]': {
                  '-moz-appearance': 'textfield',
                },
                '& input[type=number]::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                '& input[type=number]::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
              }}
              disabled={disabled}
              InputProps={{
                startAdornment: (
                  <KeyboardArrowDownIcon
                    sx={{
                      color: 'text.secondary',
                      transform: 'rotate(-90deg)',
                    }}
                  />
                ),
              }}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name='main'
          render={({ field }) => (
            <TextField
              dir='ltr'
              value={field.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const updatedValue = e.target.value ?? 0;

                const numValue =
                  typeof updatedValue === 'string' && updatedValue.length > 0
                    ? parseInt(updatedValue)
                    : 0;

                updatePaddingByMain(numValue);
              }}
              disabled={disabled}
              sx={{
                '.MuiInputBase-root': {
                  paddingRight: '2px !important',
                },
                width: '80px',
                '& input[type=number]': {
                  '-moz-appearance': 'textfield',
                },
                '& input[type=number]::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                '& input[type=number]::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                '.MuiOutlinedInput-root': {
                  paddingLeft: '6px',
                },
                '.MuiInputBase-input': {
                  padding: '12px',
                },
              }}
              inputProps={{
                style: {
                  textAlign: 'center',
                },
              }}
              InputProps={{
                endAdornment: <FourWayArrow fontSize='small' sx={{ m: 0 }} />,
              }}
            />
          )}
        />
        <Controller
          control={control}
          rules={{ onChange: updatePadding }}
          name='left'
          render={({ field }) => (
            <TextFieldNumber
              sx={{
                '.MuiOutlinedInput-root': {
                  paddingRight: '6px',
                },
                '.MuiInputBase-input': {
                  padding: '12px',
                },
                width: '80px',
                '& input[type=number]': {
                  '-moz-appearance': 'textfield',
                },
                '& input[type=number]::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                '& input[type=number]::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
              }}
              disabled={disabled}
              InputProps={{
                endAdornment: (
                  <KeyboardArrowDownIcon
                    sx={{
                      color: 'text.secondary',
                      transform: 'rotate(90deg)',
                    }}
                  />
                ),
              }}
              {...field}
            />
          )}
        />
      </Box>
      <Controller
        control={control}
        name='bottom'
        rules={{ onChange: updatePadding }}
        render={({ field }) => (
          <TextFieldNumber
            sx={{
              '.MuiOutlinedInput-root': {
                paddingLeft: '6px',
              },
              '.MuiInputBase-input': {
                padding: '12px',
              },
              width: '80px',
              '& input[type=number]': {
                '-moz-appearance': 'textfield',
              },
              '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
              '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
            }}
            disabled={disabled}
            InputProps={{
              startAdornment: (
                <KeyboardArrowDownIcon
                  sx={{
                    color: 'text.secondary',
                  }}
                />
              ),
            }}
            {...field}
          />
        )}
      />
    </Box>
  );
};

export default PatternPadding;

import React, { createContext, useContext } from 'react';

// Custom Types
import type { UseDocApiReturnProps } from 'core/hooks/api/useDoc';

// @ts-ignore
const UseDocApiContext: T = createContext<UseDocsApiResponse<T>>();

type UseDocApiProviderProps = UseDocApiReturnProps<any> & {
  children: React.ReactNode;
};

export const DocProvider: React.FC<UseDocApiProviderProps> = ({
  children,
  ...otherProps
}) => {
  return (
    <UseDocApiContext.Provider value={otherProps}>
      {children}
    </UseDocApiContext.Provider>
  );
};

export const useDocContext = <T = any,>(): Required<UseDocApiReturnProps<T>> =>
  useContext(UseDocApiContext);

export default DocProvider;

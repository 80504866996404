import { AudiencePermissionProps } from 'features/audience/permissions/types';
import { ApiAudiencePermissionProps } from 'features/audience/permissions/types/api';

/**
 * Transforms an API audience permission object into a structure accepted by the frontend.
 *
 * @param {ApiAudiencePermissionProps} apiPermission - API audience permission object.
 * @returns {AudiencePermissionProps} Transformed audience permission data.
 */
export const transformAudiencePermission = (
  apiPermission: ApiAudiencePermissionProps
): AudiencePermissionProps => {
  return {
    id: apiPermission._id,
    data: {
      title: apiPermission.data.title,
      features: apiPermission.data.features,
    },
  };
};

/**
 * Transforms an array of API audience permission objects into a structure accepted by the frontend.
 *
 * @param {ApiAudiencePermissionProps[]} apiPermissions - Array of API audience permission objects.
 * @returns {AudiencePermissionProps[]} Array of transformed audience permission data.
 */
export const transformAudiencePermissions = (
  apiPermissions: ApiAudiencePermissionProps[]
): AudiencePermissionProps[] => {
  const transformAudiencePermissions: AudiencePermissionProps[] = [];
  apiPermissions.forEach((apiPermission) =>
    transformAudiencePermissions.push(
      transformAudiencePermission(apiPermission)
    )
  );
  return transformAudiencePermissions;
};

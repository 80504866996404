// Custom Hooks
import useDocApi from 'core/hooks/api/useDoc';
import useDocsApi from 'core/hooks/api/useDocs';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  addContent,
  deleteContent,
  editContent,
  getContentData,
  getContentsData,
} from 'features/content/contents/utilities/api';

// Custom Types
import type {
  ContentDetailsDataProps,
  ContentDetailsProps,
} from 'features/content/contents/types/details';
import type {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';
import type { ContentListItemProps } from 'features/content/contents/types/list';
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';

/**
 * Query key for fetching contents in react query.
 *
 * @constant {Array<string>}
 */
export const contentsQueryKey = ['contents'];

/**
 * Custom hook to fetch contents.
 *
 * This hook utilizes the `useDocApi` to retrieve contents list on the provided options.
 *
 * @param {UseDocOptionType<ContentListItemProps>} [options] - Optional configuration options for fetching the contents.
 * @returns {UseDocApiReturnProps<ContentListItemProps>} The result of the `useDocApi` hook, which includes the fetched contents
 */
export const useContentsDocs = (
  options?: UseDocsOptionType<ContentListItemProps>
): UseDocsApiReturnProps<ContentListItemProps> =>
  useDocsApi<ContentListItemProps>(getContentsData, contentsQueryKey, options);

/**
 * Custom hook to fetch content document.
 *
 * This hook utilizes the `useDocApi` to retrieve a specific content document based on the provided options.
 *
 * @param {UseDocOptionType<ContentDetailsProps>} [options] - Optional configuration options for fetching the content document.
 * @returns {UseDocApiReturnProps<ContentDetailsProps>} The result of the `useDocApi` hook, which includes the fetched content document, loading state, and any errors.
 */
export const useContentDoc = (
  options?: UseDocOptionType<ContentDetailsProps>
): UseDocApiReturnProps<ContentDetailsProps> =>
  useDocApi<ContentDetailsProps>(getContentData, contentsQueryKey, options);

/**
 * Custom hook for deleting content.
 * Uses the `useDeleteMutation` hook to perform the delete operation.
 *
 * @returns {ReturnType<typeof useDeleteMutation>} The mutation object for deleting content.
 */
export const useDeleteContentMutation = useDeleteMutation<string[]>(
  deleteContent,
  contentsQueryKey
);

/**
 * Custom hook for adding content.
 * Uses the `useAddMutation` hook to perform the add operation.
 *
 * @returns {ReturnType<typeof useAddMutation>} The mutation object for adding content.
 */
export const useAddContentMutation = useAddMutation<ContentDetailsDataProps>(
  addContent,
  contentsQueryKey
);

/**
 * Custom hook for editing content.
 * Uses the `useEditMutation` hook to perform the edit operation.
 *
 * @returns {ReturnType<typeof useEditMutation>} The mutation object for editing content.
 */
export const useEditContentMutation = useEditMutation<ContentDetailsDataProps>(
  editContent,
  contentsQueryKey
);

import {
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
  styled,
} from '@mui/material';

export interface FormControlLabelProps extends MuiFormControlLabelProps {}

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(
  () => ({})
);

export default FormControlLabel;

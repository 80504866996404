import { useState } from 'react';

// Types
import type { Dispatch, SetStateAction } from 'react';

// Custom Utilities
import { genUUID } from 'core/utilities/helper';

const useRefId = (
  initialId?: string
): [string, Dispatch<SetStateAction<string>>, () => void] => {
  const [refId, setRefId] = useState<string>(() => {
    if (initialId) return initialId;
    else return genUUID();
  });

  const genRefId = () => {
    setRefId(genUUID());
  };

  return [refId, setRefId, genRefId];
};

export default useRefId;

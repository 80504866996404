import { useCallback, memo, useEffect } from 'react';

// Type
import type { FC } from 'react';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';
import { useIndex } from 'core/store/context/IndexProvider';
import { useAppSelector } from 'core/hooks/redux';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import AntSwitch from 'core/components/shared/Switch/AntSwitch';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyOne from 'core/components/shared/Typography/BodyOne';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import SpaceBetween from 'core/components/shared/Box/SpaceBetween';

// Custom Feature Components
import FeedbackSteps from 'features/form/feedbacks/components/details/Steps';
import FeedbackProfileInfo from 'features/form/feedbacks/components/details/ProfileInfo';
import UserAutocomplete from 'features/form/feedbacks/components/details/UserAutocomplete';
import ProcessRenderer from 'features/form/feedbacks/components/details/Process/ProcessRenderer';

// Custom Icons
import CalendarFourIcon from 'core/components/icons/CalendarFour';

// Custom Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { getLocaleDateAndTime } from 'core/utilities/helper/date';
import { deepClone, isSame } from 'core/utilities/helper/helperPack';
import { editFeedbackIsEditableStatus } from 'features/form/feedbacks/utilities/api/list';
import { isSucceed, setAppAlert, setAppLoading } from 'core/utilities/helper';

// Custom Types
import type { FeedbackProps } from 'features/form/feedbacks/types';
import type { FeedbackProcessProps } from '../../types/detail/process';

interface FeedbackPageProps {
  feedback: FeedbackProps;
  process?: FeedbackProcessProps;
}
const Feedback: FC<FeedbackPageProps> = (props) => {
  // Props
  const { feedback, process } = props;

  // Context
  const loading = useSelectLoading();
  const itsNotOnPdfPreview = useIndex() === -1; // (Index > -1) Means Its on PdfPreview else Feedback Details.
  const isFeedbackPreviewOpen = useAppSelector(
    (state) => state.feedback.isPdfPreviewOpen
  );

  // States
  const isEditable = useBoolean(feedback.data.isEditable);

  useEffect(() => {
    isEditable.set(feedback.data.isEditable);
  }, [feedback?.data?.isEditable]);

  // Utilities
  const handleToggleEditable = useCallback(async () => {
    setAppLoading(true);
    const clone = deepClone(isEditable.state);
    const newValue = !isEditable.state;
    isEditable.toggle();
    const { status } = await editFeedbackIsEditableStatus({
      selection: [feedback.id],
      isEditable: newValue,
    });

    if (isSucceed(status))
      setAppAlert(
        newValue
          ? 'بازخورد مورد نظر قابل ویرایش شد'
          : 'بازخورد مورد نظر با موفقیت غیرقابل ویرایش شد.',
        'success'
      );
    else {
      setAppAlert(
        newValue
          ? 'خطا در قابل ویرایش کردن بازخورد'
          : 'خطا در غیرقابل ویرایش کردن بازخورد'
      );
      isEditable.set(clone);
    }
    setAppLoading(false);
  }, [feedback.id, isEditable]);

  // Memoized Sections
  const EditToggle = useCallback(
    () => (
      <>
        <RowStack spacing={1} alignItems='center'>
          <BodyTwo
            sx={{
              display: 'none',
              ['@media print']: {
                display: 'block',
              },
            }}
          >
            {isEditable ? 'قابل ویرایش' : 'غیرقابل ویرایش'}
          </BodyTwo>
          <AntSwitch
            disabled={loading}
            checked={isEditable.state}
            onChange={handleToggleEditable}
            className='hide-on-print'
          />
          <BodyTwo className='hide-on-print' sx={{ fontSize: '.875rem' }}>
            قابل ویرایش
          </BodyTwo>
        </RowStack>
      </>
    ),
    [isEditable, loading, handleToggleEditable]
  );

  const Information = useCallback(
    () => (
      <RowStack spacing={0} sx={{ color: '#838D92', alignItems: 'center' }}>
        <BodyTwo sx={{ direction: 'rtl' }}>
          {getLocaleDateAndTime(feedback.data.createdAt, '-')}
        </BodyTwo>
        <CalendarFourIcon
          sx={{
            color: 'inherit',
            position: 'relative',
            top: '1px',
          }}
        />
      </RowStack>
    ),
    [feedback.data.createdAt]
  );

  const Profile = useCallback(
    () => <FeedbackProfileInfo />,
    [feedback.data.isPublic, feedback.data.audienceInfo]
  );

  // Render
  return (
    <ColumnStack
      spacing='1rem'
      id={isFeedbackPreviewOpen ? undefined : 'printable-section'}
      sx={{ zIndex: 0, pageBreakBefore: 'always', pageBreakAfter: 'always' }}
    >
      <SpaceBetween marginX='.5rem'>
        <Box>
          {itsNotOnPdfPreview ? (
            <UserAutocomplete />
          ) : (
            <BodyTwo
              sx={{
                width: 'fit-content',
                fontSize: '1rem',
                fontWeight: 500,
                display: itsNotOnPdfPreview ? 'none' : 'block',
                ['@media print']: {
                  display: 'block',
                },
              }}
            >
              {feedback.data.title || '-'}
            </BodyTwo>
          )}
        </Box>

        <RowStack alignItems='center' spacing={0.5}>
          <Information />
          <EditToggle />
        </RowStack>
      </SpaceBetween>
      {!feedback.data.isPublic &&
        Object.keys(feedback?.data?.audienceInfo || {}).length > 0 && (
          <Profile />
        )}
      <FeedbackSteps />
      {process && process.data && (
        <>
          {process.data.items.map((item, index) => {
            const isFirstItem = index === 0;
            const isLastItem = process?.data?.items?.[index + 1] === undefined;
            const isCompleted = item.data.overalStatus === 'completed';

            return (
              <ProcessRenderer
                item={item}
                key={item.id}
                showDeleteButton={!isFirstItem && isLastItem && !isCompleted}
                showResetButton={
                  isFirstItem &&
                  isCompleted &&
                  process.data?.items[1] &&
                  process.data?.items[1].data.overalStatus !== 'completed'
                }
              />
            );
          })}
          {!process.data.isActive ? (
            <BodyOne color='text'>فرایند موقتا غیرفعال شده است.</BodyOne>
          ) : ['pending', 'completed'].includes(process.data.overalStatus) ? (
            <BodyOne color='info.main'>
              {process.data.overalStatus === 'completed'
                ? 'فرایند به پایان رسیده است'
                : 'ارزیابی در حال انجام است...'}
            </BodyOne>
          ) : null}
        </>
      )}
    </ColumnStack>
  );
};

export default memo(Feedback, isSame);

import * as React from 'react';

// Custom Core Components
import TableRow from 'core/components/base/display/TableRow';
import TableCell from 'core/components/base/display/TableCell';
import TableHead from 'core/components/base/display/TableHead';

// Custom Feature Components
import ExcelTableHeaderProcessCells from './Process';

// Custom Utilities
import { excelTableCommonCellStyles } from 'features/form/feedbacks/utilities/details/styles';
import { isInputHidden } from 'features/form/feedbacks/utilities/details/helper';
import {
  useSelectAudienceInfo,
  useSelectFeedbackExcelValues,
  useSelectHiddenInputs,
  useSelectInputs,
} from 'features/form/feedbacks/store';

// Custom Types
import { AudienceDataFieldsLabel } from 'features/audience/settings/types';

interface ExcelTableHeaderProps {}

const ExcelTableHeader: React.FC<ExcelTableHeaderProps> = (props) => {
  // Context
  const feedbacks = useSelectFeedbackExcelValues();
  const audienceInfo = useSelectAudienceInfo();
  const inputs = useSelectInputs();
  const hiddenInputs = useSelectHiddenInputs();

  // Render
  return (
    <TableHead>
      <TableRow>
        <TableCell {...excelTableCommonCellStyles}>عنوان بازخورد</TableCell>
        <TableCell {...excelTableCommonCellStyles}>تاریخ ثبت</TableCell>
        <TableCell {...excelTableCommonCellStyles}>شناسه بازخورد</TableCell>
        <TableCell {...excelTableCommonCellStyles}>دسترسی</TableCell>
        {audienceInfo.length > 0 &&
          audienceInfo.map((info) => (
            <TableCell
              key={`table-preview-audienceInfo-${info}`}
              {...excelTableCommonCellStyles}
            >
              {AudienceDataFieldsLabel?.[info] || '-'}
            </TableCell>
          ))}
        {inputs.length > 0 &&
          inputs.map((input) =>
            !isInputHidden(hiddenInputs, input) ? (
              <TableCell
                {...excelTableCommonCellStyles}
                key={`table-preview-${input.id}`}
              >
                {input.data?.label || '-'}
              </TableCell>
            ) : (
              <></>
            )
          )}
        {feedbacks.length > 0 && (
          <ExcelTableHeaderProcessCells feedbacks={feedbacks} />
        )}
      </TableRow>
    </TableHead>
  );
};

export default ExcelTableHeader;

import {
  useEffect,
  useState,
  type FC,
  type MouseEvent,
  useCallback,
} from 'react';
import { useFormContext } from 'react-hook-form';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';
import Menu from 'core/components/base/navigation/Menu';
import MenuItem from 'core/components/base/navigation/MenuItem';
import Checkbox from 'core/components/base/inputs/Checkbox';
import BodyOne from 'core/components/shared/Typography/BodyOne';

// Custom Icon Components
import HiddenFieldsIcon from 'features/form/forms/components/icons/HiddenFields';

// Custom Types
import type { ButtonProps } from 'core/components/base/inputs/Button';
import type { FormInputSummaryProps } from 'features/form/forms/types/item/input';
import type { FormProps } from 'features/form/forms/types/item';
export interface InputOptionFieldSelectProps
  extends Omit<ButtonProps, 'onChange'> {
  optId: string;
  values: string[];
  inputSectionId?: string;
  onChange?: (values: string[]) => void;
}

const InputOptionFieldSelect: FC<InputOptionFieldSelectProps> = (props) => {
  // Props
  const { optId, values, sx, inputSectionId, onChange, ...otherProps } = props;

  // Hooks
  const { getValues } = useFormContext<FormProps>();

  const [options, setOptions] = useState<FormInputSummaryProps[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (id: string) => () => {
    let newValues: string[] = [...values];

    const vIndex = newValues.findIndex((v) => v === id);

    if (vIndex > -1) {
      newValues.splice(vIndex, 1);
    } else {
      newValues.push(id);
    }

    if (onChange) onChange(newValues);
  };

  const updateOptions = useCallback(() => {
    if (open) {
      const inputs = getValues('data.inputs').filter(
        (inp) => inp.data.sectionId === inputSectionId
      );
      const opts: FormInputSummaryProps[] = [];

      inputs.forEach((inp) => {
        if (inp.data.isHidden && !inp.id.startsWith('draft-')) {
          opts.push({
            id: inp.id,
            data: {
              label: inp.data.label,
              type: inp.data.type,
              values: [],
            },
          });
        }
      });

      setOptions(opts);
    }
  }, [open, inputSectionId, getValues]);

  useEffect(() => {
    updateOptions();
  }, [updateOptions]);

  return (
    <>
      <Button
        id={`input-option-button-${optId}`}
        aria-controls={open ? `input-option-menu-${optId}` : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        startIcon={<HiddenFieldsIcon />}
        onClick={handleClick}
        sx={{
          minWidth: '9rem',
          backgroundColor: 'background.default',
          color: 'text.secondary',
          ...sx,
        }}
        {...otherProps}
      >
        {values && values.length > 0
          ? `${values.length} فیلد مخفی`
          : 'فیلدهای مخفی'}
      </Button>
      <Menu
        id={`input-option-menu-${optId}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `input-option-button-${optId}`,
          sx: { m: 0, p: 0 },
        }}
        PaperProps={{
          sx: {
            marginTop: '0.25rem',
            minWidth: '10rem',
            boxShadow: 'none',
            backgroundColor: 'background.default',
          },
        }}
      >
        {options && options.length > 0 ? (
          options.map((opt) => (
            <MenuItem key={opt.id} onClick={handleSelect(opt.id)} disableRipple>
              <Checkbox
                checked={values.includes(opt.id)}
                onClick={handleSelect(opt.id)}
                size='small'
              />
              <BodyOne>{opt.data.label}</BodyOne>
            </MenuItem>
          ))
        ) : (
          <BodyOne sx={{ color: 'text.secondary', p: '1rem' }}>
            فیلد قابل انتخاب یافت نشد.
          </BodyOne>
        )}
      </Menu>
    </>
  );
};

export default InputOptionFieldSelect;

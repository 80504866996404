// Custom Page Components
import CustomPageList from 'features/appBuilder/customPages/components/list';
import EditCustomPage from 'features/appBuilder/customPages/components/details/Edit';

// Custom Utilities
import { getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'template';
const subFeature: SubFeatureType = 'customPages';
const customPagesRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/custom-page',
    Component: CustomPageList,
    feature,
    subFeature,
  },
  {
    path: '/custom-page/edit',
    Component: EditCustomPage,
    feature,
    subFeature,
  },
];

export default customPagesRoutes;

import * as React from 'react';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';

// Custom Types
import type { ButtonProps } from 'core/components/base/inputs/Button';
interface SubmitButtonProps extends ButtonProps {}

const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
  const { children, variant = 'outlined', sx, ...otherProps } = props;
  return (
    <Button variant={variant} sx={{ minWidth: '7rem', ...sx }} {...otherProps}>
      {children}
    </Button>
  );
};

export default SubmitButton;

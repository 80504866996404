import * as React from 'react';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';

// Custom Icons
import LtrDirIcon from 'core/components/icons/LtrDirection';
import RtlDirIcon from 'core/components/icons/RtlDirection';

// Custom Types
interface DirectionButtonProps {
  value: 'ltr' | 'rtl';
  onDirectionChange?: (newValue: 'ltr' | 'rtl') => void;
  disabled?: boolean;
}

const DirectionButton: React.FC<DirectionButtonProps> = (props) => {
  const { value = 'rtl', disabled, onDirectionChange: onChange } = props;
  // Utilities
  const handleChange = () => {
    if (onChange) onChange(value === 'ltr' ? 'rtl' : 'ltr');
  };

  // Render
  return (
    <>
      <Button
        disabled={disabled}
        color='secondary'
        onClick={handleChange}
        variant={disabled ? 'text' : 'outlined'}
        sx={{
          color: disabled ? 'text.secondary' : 'secondary.main',
          width: '2.5rem',
          height: '2.5rem',
          minWidth: 'fit-content',
          p: '0.25rem',
        }}
      >
        {value === 'rtl' ? <RtlDirIcon /> : <LtrDirIcon />}
      </Button>
    </>
  );
};

export default DirectionButton;

import * as React from 'react';

// Custom Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface EmailFilledIconProps extends SvgIconProps {}

const EmailFilledIcon: React.FC<EmailFilledIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M20.2,3.4H3.8C3,3.4,2.3,3.7,1.8,4.2C1.3,4.7,1,5.4,1,6.1v11.8c0,0.7,0.3,1.4,0.8,1.9c0.5,0.5,1.2,0.8,1.9,0.8h16.5
	c0.7,0,1.4-0.3,1.9-0.8c0.5-0.5,0.8-1.2,0.8-1.9V6.1c0-0.7-0.3-1.4-0.8-1.9C21.7,3.7,21,3.4,20.2,3.4z M19.6,7.9l-7.1,5.5
	c-0.3,0.2-0.7,0.2-1,0L4.4,7.9C4.4,7.8,4.3,7.8,4.3,7.7C4.2,7.6,4.2,7.5,4.2,7.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.3
	c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.2l6.6,5.1l6.6-5.1
	c0.2-0.1,0.4-0.2,0.6-0.2c0.4,0.1,0.7,0.4,0.7,0.9C19.8,7.6,19.7,7.8,19.6,7.9z'
    />
  </IconBuilder>
);

export default EmailFilledIcon;

import { convertNumberToPersian } from 'core/utilities/helper/helperPack';
import * as Yup from 'yup';

const AddAudienceValidationSchema = Yup.object().shape({
  phoneNumbers: Yup.string()
    .required('وارد کردن حداقل یک شماره موبایل اجباری می‌باشد.')
    .test({
      test: (value) => {
        if (typeof value !== 'string') return false;

        let valid = true;
        const numbers = value.split('\n');

        numbers.forEach((number) => {
          if (!number.match(/^([0-9]{11})$/gm)) valid = false;
        });

        return valid;
      },
      message(params) {
        const errorLines: string[] = [];

        if (params && params.value) {
          const values = params.value as string;

          if (values)
            values.split('\n').forEach((value, index) => {
              if (!value.match(/^([0-9]{11})$/gm))
                errorLines.push((index + 1).toString());
            });
        }

        const lastCommaIndex = errorLines.join('، ').lastIndexOf('،');

        return errorLines.length > 0
          ? convertNumberToPersian(
              `شماره موبایل وارد شده در ${
                errorLines.length > 1
                  ? `خطوط ${
                      errorLines.join('، ').substring(0, lastCommaIndex) +
                      ' و ' +
                      errorLines.join('، ').substring(lastCommaIndex + 1)
                    }`
                  : `خط ${errorLines[0]}`
              } صحیح نمی‌باشد.`
            )
          : 'وارد کردن حداقل یک شماره موبایل اجباری می‌باشد.';
      },
    })
    .label('شماره‌ موبایل'),
});

export default AddAudienceValidationSchema;

import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import InputWrapper from 'core/components/shared/Wrapper/Input';

// Feature Components
import PatternItemContainer from 'features/appBuilder/patterns/core/components/shared/items/Container';
import PatternButtonTypeDropdown from 'features/appBuilder/patterns/core/components/shared/dropdown/ButtonType';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Types
import type { PatternItemContainerProps } from 'features/appBuilder/patterns/core/components/shared/items/Container';

export interface PatternButtonItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
}

const PatternButtonItem: FC<PatternButtonItemProps> = (props) => {
  // Props
  const { reference, ...otherProps } = props;

  // Hooks
  const { control } = useFormContext();
  const loading = useSelectLoading();

  return (
    <PatternItemContainer reference={reference} {...otherProps}>
      <InputWrapper label='نوع دکمه'>
        <Controller
          control={control}
          name={`${reference}.variant`}
          render={({ field }) => (
            <PatternButtonTypeDropdown
              disabled={loading}
              defaultValue={field.value}
              reference={reference}
            />
          )}
        />
      </InputWrapper>
    </PatternItemContainer>
  );
};

export default PatternButtonItem;

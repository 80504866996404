import React, { createContext, useContext } from 'react';

// Custom Types
import type { UseDocsApiReturnProps } from 'core/hooks/api/useDocs';

// @ts-ignore
const UseDocs: T = createContext<UseDocsApiReturnProps<T>>();

type UseDocsApiProviderProps = UseDocsApiReturnProps<any> & {
  children: React.ReactNode;
};

const DocsProvider: React.FC<UseDocsApiProviderProps> = ({
  children,
  ...otherProps
}) => {
  return <UseDocs.Provider value={otherProps}>{children}</UseDocs.Provider>;
};

export const useDocsContext = <T = any,>(): Required<
  UseDocsApiReturnProps<T>
> => useContext(UseDocs);

export default DocsProvider;

import * as React from 'react';

// Core Components
import Stack from 'core/components/base/layout/Stack';

import type { StackProps } from 'core/components/base/layout/Stack';
export interface RowStackProps extends StackProps {}

const RowStack: React.FC<RowStackProps> = (props) => {
  const { children, direction = 'row', spacing = 2, ...otherProps } = props;

  return (
    <Stack direction={direction} spacing={spacing} {...otherProps}>
      {children}
    </Stack>
  );
};

export default RowStack;

import * as React from 'react';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';
import Dialog from 'core/components/base/feedback/Dialog';
import DialogActions from 'core/components/base/feedback/DialogActions';

// Custom Common Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Types
interface PopupProps {
  open: boolean;
  onSinglePdf: () => void;
  onAllPdf: () => void;
  onClose: () => void;
}

const Popup: React.FC<PopupProps> = (props) => {
  // Props
  const { onClose, onAllPdf, onSinglePdf, open } = props;

  // Render
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '560px',
          height: '313px',
          borderRadius: '1rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem',
        },
      }}
    >
      <BodyTwo sx={{ fontSize: '1rem', fontWeight: 700, textAlign: 'center' }}>
        خروجی PDF
      </BodyTwo>
      <BodyTwo
        sx={{ textAlign: 'center', fontWeight: 500, fontSize: '.875rem' }}
      >
        لطفا مشخص کنید که خروجی PDF <br /> فقط از همین بازخورد باشد یا همه
        بازخوردها؟
      </BodyTwo>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button variant='contained' color='info' onClick={onSinglePdf}>
          این بازخورد
        </Button>
        <Button variant='outlined' color='primary' onClick={onAllPdf}>
          همه بازخوردها
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;

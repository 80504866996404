import type {
  DeprecatedFileLocationType,
  FileType,
} from 'features/file/files/types';

export const deprecatedFileLocations: DeprecatedFileLocationType[] = [
  'content',
  'file',
  'page',
  'pageBuilder',
  'manager',
  'menu',
  'banner',
  'audience',
  'audienceFolder',
  'audienceSetting',
  'category',
  'feedback',
  'tag',
  'sheet',
  'ticket',
  'managerSetting',
  'place',
];

// TODO: Below code is the new one. use it when backend is Ready.
// export const fileLocations: FileLocationType[] = [
//   'audience',
//   'audienceAccesses',
//   'audienceFolders',
//   'audienceGroups',
//   'audienceSettings',
//   'audiences',
//   'banner',
//   'banners',
//   'categories',
//   'content',
//   'contents',
//   'customPages',
//   'evaluations',
//   'feedbacks',
//   'file',
//   'files',
//   'form',
//   'formTags',
//   'forms',
//   'manager',
//   'managers',
//   'managerGroups',
//   'managerSettings',
//   'menu',
//   'menus',
//   'page',
//   'pages',
//   'patterns',
//   'profile',
//   'sheets',
//   'skins',
//   'tags',
//   'templates',
//   'ticket',
//   'tickets',
//   'ticketCategories',
// ];

export const fileTypesOptions: { value: FileType; label: string }[] = [
  { value: 'image', label: 'تصاویر' },
  { value: 'video', label: 'ویدیو' },
  { value: 'audio', label: 'صدا' },
  { value: 'document', label: 'داکیومنت' },
];

export const fileFeatureLocationOptions: {
  value: DeprecatedFileLocationType;
  label: string;
}[] = [
  { value: 'file', label: 'فایل' },
  { value: 'form', label: 'فرم' },
  { value: 'manager', label: 'مدیران' },
  { value: 'menu', label: 'منو' },
  { value: 'banner', label: 'بنر' },
  { value: 'audience', label: 'مخاطب' },
  { value: 'sheet', label: 'دیتا' },
  { value: 'content', label: 'مطلب' },
  { value: 'pageBuilder', label: 'اپلیکیشن‌ساز' },
  { value: 'ticket', label: 'تیکت' },
];

export const deprecatedFileTypesOptions: FileType[] = [
  'image',
  'video',
  'audio',
  'document',
];

export const deprecatedfileFeatureLocationOptions: DeprecatedFileLocationType[] =
  ['content', 'file', 'page', 'pageBuilder', 'manager', 'menu', 'banner'];

// TODO: This is the new one. use it when backend is ready.
// export const fileFeatureLocationOptions: FileLocationType[] = [
//   'contents',
//   'files',
//   'pages',
//   'customPages',
//   'managers',
//   'menus',
//   'banners',
// ];

export const acceptedFileTypes = [
  '.jpg',
  '.jpeg',
  '.png',
  '.svg',
  '.wma',
  '.webp',
  '.tiff',
  '.gif',
  '.mp3',
  '.swf',
  '.mkv',
  '.mp4',
  '.wmv',
  '.flv',
  '.mov',
  '.avi',
  '.pdf',
  '.xlsx',
  '.docx',
  '.doc',
  '.txt',
  '.pptx',
];

import { useCallback, useEffect, useState } from 'react';

// Types
import type { FC } from 'react';

// Custom Common Components
import DataGrid from 'core/components/shared/DataGrid';
import RouteLink from 'core/components/shared/Link/RouteLink';
import BodyOne from 'core/components/shared/Typography/BodyOne';

// Types
import type { PatternContainerProps } from 'features/appBuilder/patterns/types/container';
import type { DataGridProps } from 'core/components/shared/DataGrid';
import type {
  DataGridColumn,
  DataGridRowValueProps,
} from 'core/components/shared/DataGrid/dataGrid';

export interface PatternContainersDataGridProps
  extends Omit<DataGridProps, 'columns' | 'rows'> {
  containers: PatternContainerProps[];
}

const columns: DataGridColumn[] = [
  {
    field: 'col1',
    headerTitle: 'عنوان',
    width: 250,
    renderCell: (params) => (
      <RouteLink href={`/pattern/${params.row.col3}`} underline='hover'>
        {params.row.col1}
      </RouteLink>
    ),
  },
  {
    field: 'col2',
    headerTitle: 'تعداد',
    renderCell: (params) => (
      <BodyOne>{(params.row.col2 || 0)?.toPersian()}</BodyOne>
    ),
  },
];

const PatternContainersDataGrid: FC<PatternContainersDataGridProps> = (
  props
) => {
  // Props
  const { containers, ...otherProps } = props;

  // States
  const [rows, setRows] = useState<DataGridRowValueProps[]>([]);

  // Hooks
  const generateRows = useCallback(() => {
    const rowsToSet: DataGridRowValueProps[] = [];

    containers.forEach(({ id, data }) => {
      const row = {
        id,
        col1: data.title,
        col2: data.itemsCount,
        col3: data.type,
      };
      rowsToSet.push(row);
    });

    setRows(rowsToSet);
  }, [containers]);

  useEffect(() => {
    generateRows();
  }, [containers, generateRows]);

  return (
    <DataGrid
      headProps={{
        sx: { backgroundColor: '#D8E8F1' },
      }}
      columns={columns}
      rows={rows}
      {...otherProps}
    />
  );
};

export default PatternContainersDataGrid;

import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternTitleItem from 'features/appBuilder/patterns/core/components/shared/items/Title';
import PatternLogoItem from 'features/appBuilder/patterns/core/components/shared/items/Logo';

// Custom Types
export interface FooterPatternBrandItemProps {}
type CollapsedKey = 'section' | 'logo' | 'titleOne' | 'titleTwo';

const FooterPatternBrandSection: FC<FooterPatternBrandItemProps> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    logo: false,
    titleOne: false,
    titleTwo: false,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='لوگو'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='brand'
    >
      <PatternLogoItem
        reference='brand.logo'
        title='تصویر'
        collapsed={collapsed.logo}
        onCollapsedChange={handleCollapseChanged('logo')}
        include={['padding', 'borderRadius']}
      />
      <PatternTitleItem
        reference='brand.titleOne'
        title='عنوان اول'
        collapsed={collapsed.titleOne}
        onCollapsedChange={handleCollapseChanged('titleOne')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'fontColor',
          'highlightColor',
          'fontHoverColor',
          'highlightHoverColor',
        ]}
      />
      <PatternTitleItem
        reference='brand.titleTwo'
        title='عنوان دوم'
        collapsed={collapsed.titleTwo}
        onCollapsedChange={handleCollapseChanged('titleTwo')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'fontColor',
          'highlightColor',
          'fontHoverColor',
          'highlightHoverColor',
        ]}
      />
    </PatternSectionContainer>
  );
};

export default FooterPatternBrandSection;

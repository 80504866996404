import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface HeadphonesIconProps extends SvgIconProps {}

const HeadphonesIcon: React.FC<HeadphonesIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M12 3c-4.97 0-9 4.03-9 9v7c0 1.1.9 2 2 2h4v-8H5v-1c0-3.87 3.13-7 7-7s7 3.13 7 7v1h-4v8h4c1.1 0 2-.9 2-2v-7c0-4.97-4.03-9-9-9' />
  </IconBuilder>
);

export default HeadphonesIcon;

// Types
import type { FC } from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface CheckmarkFilledIconProps extends SvgIconProps {}

const CheckmarkFilledIcon: FC<CheckmarkFilledIconProps> = (props) => (
  <IconBuilder {...props}>
    <path
      d='M22,12c0,5.5-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2S22,6.5,22,12z M10.8,17.3l7.4-7.4c0.3-0.3,0.3-0.7,0-0.9l-0.9-0.9
	c-0.3-0.3-0.7-0.3-0.9,0l-6.1,6.1l-2.8-2.8c-0.3-0.3-0.7-0.3-0.9,0l-0.9,0.9c-0.3,0.3-0.3,0.7,0,0.9l4.2,4.2
	C10.2,17.5,10.6,17.5,10.8,17.3z'
    />
  </IconBuilder>
);

export default CheckmarkFilledIcon;

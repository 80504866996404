import { FC, lazy, Suspense, useState } from 'react';
import type { Editor } from '@tiptap/react';

// Core Components
import IconButton from 'core/components/base/inputs/IconButton';
import Box from 'core/components/base/layout/Box';
import ColorIcon from 'core/components/icons/Color';
import Menu from 'core/components/base/navigation/Menu';

const ReactColor = lazy(() =>
  import('react-color').then((m) => ({ default: m.SketchPicker }))
);

// Component Types
interface EditorHighlightColorIconButtonProps {
  editor: Editor | null;
}

const EditorHighlightColorIconButton: FC<
  EditorHighlightColorIconButtonProps
> = (props) => {
  // Hooks
  const { editor } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{
          color: editor?.getAttributes('textStyle').color || 'inherit',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            width: '1.25rem',
            height: '1.25rem',
            bgcolor: editor?.getAttributes('highlight').color || 'white',
            borderRadius: '50%',
            border: '1px solid #C1C8D3',
          }}
        />
        <ColorIcon
          sx={{
            width: '0.75rem',
            height: '0.75rem',
            position: 'absolute',
          }}
        />
      </IconButton>
      <Suspense fallback={<></>}>
        <Menu
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{ sx: { margin: 0, padding: 0 } }}
        >
          <ReactColor
            color={editor?.getAttributes('highlight').color}
            onChange={(color) => {
              const { r, g, b, a = 1 } = color.rgb;

              editor
                ?.chain()
                .focus()
                .toggleHighlight({ color: `rgba(${r}, ${g}, ${b}, ${a})` })
                .run();
            }}
          />
        </Menu>
      </Suspense>
    </>
  );
};

export default EditorHighlightColorIconButton;

import * as React from 'react';

// Custom Core Components
import TextField from 'core/components/base/inputs/TextField';

// Custom Types
import type { TextFieldProps } from 'core/components/base/inputs/TextField';
type TextFieldOutlinedProps = TextFieldProps;

const TextFieldOutlined: React.FC<TextFieldOutlinedProps> = React.forwardRef(
  (props, ref) => {
    const { variant = 'outlined', InputProps, ...otherProps } = props;

    return (
      <TextField
        variant={variant}
        InputProps={{
          sx: { height: '3rem', ...InputProps?.sx },
          autoComplete: 'off',
          ...InputProps,
        }}
        inputRef={ref}
        {...otherProps}
      />
    );
  }
);

export default TextFieldOutlined;

/**
 * Creates a new array of the specified size.
 *
 * @param {number} size - The size of the new array.
 * @returns {number[]} The new array.
 */
const newArray = (size: number): number[] => {
  let x: number[] = [];
  for (let i = 0; i < size; i++) {
    x[i] = i;
  }

  return x;
};

export default newArray;

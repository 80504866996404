import { useNavigate } from 'react-router-dom';

// Types
import type { FC } from 'react';

// Custom Common Components
import Pagination from 'core/components/shared/Filter/Pagination';
import PageSize from 'core/components/shared/Filter/PageSize';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Feature Components
import CustomPageListDataGrid from 'features/appBuilder/customPages/components/list/DataGrid';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import LoadingButton from 'core/components/base/inputs/LoadingButton';

// Custom Icon Components
import PlusCircleOutlineIcon from 'core/components/icons/PlusCircleOutline';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import {
  useAddCustomPageMutation,
  useCustomPageDocs,
} from 'features/appBuilder/customPages/components/hooks/customPage';

// Custom Types

export interface CustomPageListProps {}

const CustomPageList: FC<CustomPageListProps> = () => {
  // Hooks
  const navigate = useNavigate();
  const loading = useSelectLoading();
  const {
    data: { list, page },
    onPageNumChange,
    onPageSizeChange,
  } = useCustomPageDocs();
  const { mutate: handleAddPage } = useAddCustomPageMutation({
    stayOnLoadingAfterMutate: true,
    autoAlert: { mode: 'create', name: 'صفحه جدید' },
    onSuccess: ({ pageId }) => {
      if (pageId)
        setTimeout(() => {
          navigate('/custom-page/edit', { state: pageId });
        }, 2000);
    },
  });

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='صفحات سفارشی' />
      <PageHeroBox>
        <PageTitle>صفحات سفارشی</PageTitle>
      </PageHeroBox>
      <ColumnStack>
        <CustomPageListDataGrid pages={list || []} />
        <Box sx={{ display: 'flex', gap: 2 }}>
          <LoadingButton
            loading={loading}
            loadingPosition='start'
            variant='outlined'
            startIcon={<PlusCircleOutlineIcon />}
            size='large'
            sx={{ px: '1.5rem', height: '2rem' }}
            onClick={handleAddPage}
          >
            صفحه جدید
          </LoadingButton>
          <PageSize
            sx={{ height: '2rem' }}
            page={page}
            onSizeChange={onPageSizeChange}
          />
          <Pagination onPageChange={onPageNumChange} page={page} />
        </Box>
      </ColumnStack>
    </>
  );
};

export default CustomPageList;

import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternAvatarItem from 'features/appBuilder/patterns/core/components/shared/items/Avatar';
import PatternMenuItem from 'features/appBuilder/patterns/core/components/shared/items/Menu';

// Custom Types
export interface ProfilePatternConfigItemProps {}
type CollapsedKey = 'section' | 'avatar' | 'menuOne';

const ProfilePatternConfigSection: FC<ProfilePatternConfigItemProps> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    avatar: false,
    menuOne: false,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='تنظیمات پروفایل'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='config'
    >
      <PatternAvatarItem
        title='تصویر جایگزین پروفایل'
        reference='config.avatar'
        collapsed={collapsed.avatar}
        onCollapsedChange={handleCollapseChanged('avatar')}
        include={['padding', 'fontColor', 'highlightColor', 'borderRadius']}
      />
      <PatternMenuItem
        title='انتخاب منو'
        reference='config.menuOne'
        collapsed={collapsed.menuOne}
        onCollapsedChange={handleCollapseChanged('menuOne')}
        noItemCount
        noSubMenu
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'color',
          'bgColor',
          'hoverColor',
          'borderRadius',
          'bgHoverColor',
        ]}
      />
    </PatternSectionContainer>
  );
};

export default ProfilePatternConfigSection;

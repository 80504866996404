// Core Utilities
import { forLoop } from 'core/utilities/helper';
import { getNumberTitle } from 'core/utilities/helper/helperPack';

// Custom Types
import type { GroupPatternDataProps } from 'features/appBuilder/patterns/features/group/types';

/**
 * Validates the group pattern data and returns an error message if validation fails.
 *
 * @param {GroupPatternDataProps} data - The group pattern data to validate.
 * @returns {string} A string containing the error message if validation fails, or an empty string if the data is valid.
 */
export const validateGroupPattern = (data: GroupPatternDataProps): string => {
  if (!data.patternTitle.trim()) {
    return 'وارد کردن عنوان پترن الزامی می‌باشد.';
  }

  if (data.columnOne && data.columnOne.data.items.length > 0) {
    let error = '';
    forLoop(data.columnOne.data.items, (item, index) => {
      if (error) return;
      if (!item.data.patternId || !item.data.type)
        error = `انتخاب پترن ${getNumberTitle(
          index + 1
        )} در ستون اول الزامی است.`;
    });

    return error;
  }

  if (data.columnTwo && data.columnTwo.data.items.length > 0) {
    let error = '';
    forLoop(data.columnTwo.data.items, (item, index) => {
      if (error) return;
      if (!item.data.patternId || !item.data.type)
        error = `انتخاب پترن ${getNumberTitle(
          index + 1
        )} در ستون دوم الزامی است.`;
    });

    return error;
  }

  if (data.columnThree && data.columnThree.data.items.length > 0) {
    let error = '';
    forLoop(data.columnThree.data.items, (item, index) => {
      if (error) return;
      if (!item.data.patternId || !item.data.type)
        error = `انتخاب پترن ${getNumberTitle(
          index + 1
        )} در ستون سوم الزامی است.`;
    });

    return error;
  }

  return '';
};

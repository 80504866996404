import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

// Types
import type { SubmitHandler } from 'react-hook-form';

// Custom HOCs
import WithAuth from 'core/components/shared/HOC/WithAuth';

// Common Components
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Feature Components
import AudienceFolderForm from 'features/audience/folders/components/details/Form';

// Custom Hooks
import useYupValidationResolver from 'core/hooks/common/useYupResolver';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Validation Schemas
import AddAudienceFolderValidationSchema from 'features/audience/folders/validations';

// Utilities
import { useDialogContext } from 'core/store/context/DialogProvider';
import {
  useAudienceFolderDoc,
  useDeleteAudienceFoldersMutation,
  useEditAudienceFolderMutation,
} from 'features/audience/folders/hooks';

// Custom Types
import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';
import type { AudienceFolderDataProps } from 'features/audience/folders/types';

interface EditAudienceFolderProps extends WithAuthProps {}

const EditAudienceFolder: React.FC<EditAudienceFolderProps> = (props) => {
  // Context
  const loading = useSelectLoading();
  const { onOpenDialog, onCloseDialog } = useDialogContext();

  // Hooks
  const { state } = useLocation();
  const folderId = String(state?.folderId || '');
  const resolver = useYupValidationResolver(AddAudienceFolderValidationSchema);
  const methods = useForm<AudienceFolderDataProps>({
    mode: 'onTouched',
    resolver,
    defaultValues: {
      title: '',
      capacity: 0,
      creator: [],
      isActive: true,
      reader: [],
      tags: [],
      updater: [],
    },
  });
  const { status } = useAudienceFolderDoc({
    onFetch: (doc) => doc?.data && methods.reset(doc?.data),
    id: folderId,
    redirectAfterErrorTo: '/audience-folders',
    redirectAfterDocumentIdIsEmptyTo: '/audience-folders',
    alertOnFetchError: { message: 'دریافت اطلاعات پوشه با خطا مواجه شد.' },
    alertOnDocumentIdIsEmpty: {
      message: 'شناسه پوشه یافت نشد. مجدداً اقدام به ویرایش کنید.',
      severity: 'warning',
    },
  });

  const { mutate: handleEditFolder } = useEditAudienceFolderMutation({
    autoAlert: { mode: 'edit', name: 'پوشه' },
    redirectAfterSuccessTo: '/audience-folders',
  });
  const { mutate: handleDeleteFolder } = useDeleteAudienceFoldersMutation({
    autoAlert: { mode: 'delete', name: 'پوشه' },
    redirectAfterSuccessTo: '/audience-folders',
    onSuccess: () => onCloseDialog(),
  });

  // Utilities
  const handleEdit: SubmitHandler<AudienceFolderDataProps> = async (data) => {
    handleEditFolder({ id: folderId, data });
  };

  const handleOpenDeleteDialog = () =>
    onOpenDialog({
      title: 'آیا از حذف پوشه اطمینان دارید؟',
      onSubmit: () => handleDeleteFolder([folderId]),
    });

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='ویرایش پوشه' />
      <FormProvider {...methods}>
        <AudienceFolderForm
          disabled={loading || status !== 'success'}
          mode='edit'
          folderId={folderId}
          onFormSubmit={handleEdit}
          onOpenDeleteDialog={handleOpenDeleteDialog}
        />
      </FormProvider>
    </>
  );
};

export default WithAuth(EditAudienceFolder);

import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// Types
import type { FC } from 'react';

// Section Components
import SheetTable from 'features/data/sheets/components/details/SheetTable';
import SheetSetting from 'features/data/sheets/components/details/SheetSetting';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Custom Utilities
import { findTableHeader } from 'features/data/sheets/utilities/helper';
import { useAddSheetMutation } from 'features/data/sheets/hooks';

// Custom Types
import type { SheetDataProps, SheetRowProps } from 'features/data/sheets/types';

interface AddSheetProps {}

const AddSheet: FC<AddSheetProps> = () => {
  // State
  const [originalData, setOriginalData] = React.useState<SheetRowProps[]>([]);
  const [tableHeader, setTableHeader] = React.useState<string[]>([]);

  // Hooks
  const formMethods = useForm<SheetDataProps>();
  const { mutate: handleAddSheet } = useAddSheetMutation({
    autoAlert: { mode: 'create', name: 'شیت' },
    stayOnLoadingAfterMutate: true,
    redirectAfterSuccessTo: '/sheet',
  });

  React.useEffect(() => {
    if (originalData) setTableHeader(findTableHeader(originalData));
  }, [originalData]);

  // Utilites
  const bakeSheet = () => {
    const sheet: SheetDataProps = {
      title: formMethods.getValues('title'),
      isGeneric: false,
      rowCount: formMethods.getValues('rowCount'),
      colCount: formMethods.getValues('colCount'),
      headers: tableHeader,
      createdAt: '',
      updatedAt: '',
      rows: originalData ? originalData : [],
    };
    return sheet;
  };

  const handleSubmit = async () => {
    handleAddSheet(bakeSheet());
  };

  return (
    <>
      <PageHeadConfigs pageTitle='شیت مخاطبان جدید' />
      <FormProvider {...formMethods}>
        <SheetSetting
          onSubmit={handleSubmit}
          originalData={originalData}
          onSetOriginalData={setOriginalData}
          isAddPage
        />
        {originalData && originalData.length > 0 && (
          <SheetTable tableData={originalData} tableHeader={tableHeader} />
        )}
      </FormProvider>
    </>
  );
};

export default AddSheet;

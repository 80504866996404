import * as Yup from 'yup';

// Schemas
import { emailSchema } from 'core/utilities/validationSchema/common/Schemas';

const EmailValidationSchema = Yup.object().shape({
  email: emailSchema,
});

export default EmailValidationSchema;

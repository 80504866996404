import * as React from 'react';
import { alpha, useTheme } from '@mui/material';

// Core Components
import RouteLink from 'core/components/shared/Link/RouteLink';
import TableContainerPaper from 'core/components/shared/Paper/TableContainerPaper';
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import Table from 'core/components/base/display/Table';
import TableBody from 'core/components/base/display/TableBody';
import TableCell from 'core/components/base/display/TableCell';
import TableContainer from 'core/components/base/display/TableContainer';
import TableHead from 'core/components/base/display/TableHead';
import TableRow from 'core/components/base/display/TableRow';
import Checkbox from 'core/components/base/inputs/Checkbox';

// Core Utilities
import { getSubFeatureLabel } from 'core/utilities/feature/helper/label';
import { useDocsContext } from 'core/hooks/api/useDocs/Context';

// Core Types
import type { PermissionProps } from 'core/types/permission';
import type { SubFeatureType } from 'core/types/feature/FeatureType';

// Feature Types
import type { ManagerGroupListItemProps } from 'features/manager/groups/types';
import { useSelectionContext } from 'core/store/context/common/UseSelection';

// Component Types
interface ManagerGroupsDataGridProps {
  disabled?: boolean;
}

const ManagerGroupsDataGrid: React.FC<ManagerGroupsDataGridProps> = (props) => {
  // Props
  const { disabled } = props;

  // Context
  const {
    data: { list: groups },
  } = useDocsContext<ManagerGroupListItemProps>();
  const { selections, onToggleAll, onSelectionsChange } = useSelectionContext();

  // Hooks
  const theme = useTheme();

  // Utilities
  const getSubFeatures = (perms: PermissionProps[]): string[] => {
    let subFeatures: SubFeatureType[] = [];

    perms.forEach((perm) => {
      perm.subFeatures.forEach((sub) => subFeatures.push(sub));
    });

    return subFeatures.map((sub) => getSubFeatureLabel(sub));
  };

  // Render
  return (
    <TableContainer component={TableContainerPaper}>
      <Table>
        <TableHead>
          <TableRow sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
            <TableCell padding='checkbox'>
              <Checkbox
                checked={
                  groups.length > 0 && selections.length === groups.length
                }
                color='info'
                disabled={disabled || groups.length === 0}
                indeterminate={
                  groups.length > 0 &&
                  selections.length > 0 &&
                  selections.length < groups.length
                }
                onChange={() => onToggleAll(groups)}
              />
            </TableCell>
            <TableCell sx={{ width: '10rem', whiteSpace: 'nowrap' }}>
              عنوان گروه
            </TableCell>
            <TableCell sx={{ width: '5rem' }}>اعضا</TableCell>
            <TableCell>دسترسی‌ها</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {disabled && groups.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4}>در حال دریافت اطلاعات گروه‌ها.</TableCell>
            </TableRow>
          ) : groups.length > 0 ? (
            groups.map((group) => (
              <TableRow key={group.id}>
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={selections.includes(group.id)}
                    color='info'
                    disabled={disabled || groups.length === 0}
                    onChange={() => onSelectionsChange(group.id)}
                  />
                </TableCell>
                <TableCell sx={{ width: '10rem', whiteSpace: 'nowrap' }}>
                  <RouteLink
                    href={`/manager-groups/${group.id}`}
                    underline='hover'
                  >
                    {group.data.title}
                  </RouteLink>
                </TableCell>
                <TableCell align='left' sx={{ width: '5rem' }}>
                  {group.data.count?.toPersian() || '-'}
                </TableCell>
                <TableCell>
                  <RowStack spacing={0.5}>
                    {group.data.permissions
                      ? getSubFeatures(group.data.permissions).map(
                          (subFeature, index) => (
                            <BodyTwo
                              key={subFeature + index}
                              sx={{
                                background: alpha(
                                  theme.palette.background.default,
                                  0.3
                                ),
                                padding: '0.25rem 0.5rem',
                                borderRadius: '2px',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {subFeature}
                            </BodyTwo>
                          )
                        )
                      : '-'}
                  </RowStack>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>گروهی جهت نمایش وجود ندارد.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ManagerGroupsDataGrid;

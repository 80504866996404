import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

// Types
import type { FC } from 'react';

// Custom Section Components
import PageHeadConfigs from 'core/components/shared/PageTitle';
import CustomPageAction from 'features/appBuilder/customPages/components/details/Action';
import CustomPageBody from 'features/appBuilder/customPages/components/details/Body';
import CustomPageConfig from 'features/appBuilder/customPages/components/details/Config';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Custom Hooks
import useMount from 'core/hooks/useMount';

import { setAppAlert, setAppLoading } from 'core/utilities/helper';
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// Custom Utilities
import { useCustomPageDoc } from 'features/appBuilder/customPages/components/hooks/customPage';

// Custom Types
import type {
  CustomPageProps,
  CustomPageOperation,
} from 'features/appBuilder/customPages/types';

export interface EditCustomPageProps {}

const configValidation = Yup.object().shape({
  data: Yup.object().shape({
    title: Yup.string().required('وارد کردن عنوان صفحه سفارشی اجباری است.'),
    slug: Yup.string()
      .required('وارد کردن اسلاگ اجباری است.')
      .matches(/^\//, 'اسلاگ باید با "/" شروع شود.'),
  }),
});

const EditCustomPage: FC<EditCustomPageProps> = () => {
  // States
  const [operation, setOperation] = useState<CustomPageOperation>('INIT');

  // Hooks
  const navigate = useNavigate();
  const { state: pageId } = useLocation();
  const resolver = useYupValidationResolver(configValidation);
  const formMethods = useForm<CustomPageProps>({
    mode: 'onTouched',
    resolver,
  });
  const { data: page } = useCustomPageDoc({
    id: pageId,
    onFetch: (doc) => formMethods.reset(doc),
  });

  useMount(() => {
    if (!(pageId && typeof pageId === 'string')) {
      setAppLoading(true);
      setAppAlert('شناسه صفحه یافت نشد');
      setTimeout(() => {
        navigate('/custom-page');
      }, 2500);
    }
  });

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='ویرایش صفحه سفارشی' />
      <FormProvider {...formMethods}>
        <CustomPageAction
          pageId={pageId}
          pageTitle={page?.data.title || ''}
          operation={operation}
          onOperationChange={setOperation}
        />
        <ColumnStack spacing={3}>
          <CustomPageConfig
            operation={operation}
            onOperationChange={setOperation}
          />
          <CustomPageBody
            operation={operation}
            onOperationChange={setOperation}
          />
        </ColumnStack>
      </FormProvider>
    </>
  );
};

export default EditCustomPage;

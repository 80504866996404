import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternTitleItem from 'features/appBuilder/patterns/core/components/shared/items/Title';

// Custom Types
export interface FormPatternErrorSectionItemProps {}
type CollapsedKey =
  | 'section'
  | 'required'
  | 'invalid'
  | 'charLimit'
  | 'numLimit'
  | 'optionLimit'
  | 'uploadLimit'
  | 'invalidUpload'
  | 'emptyRequired'
  | 'failedUpload'
  | 'dateLimit'
  | 'feedbackCountLimit';

type MapButton = {
  key: CollapsedKey;
  label: string;
  palceholder: string;
};
const buttons: MapButton[] = [
  {
    key: 'required',
    label: 'خطای فیلد اجباری',
    palceholder: 'تکمیل فیلد «{title}» اجباری است',
  },
  {
    key: 'invalid',
    label: 'خطای نادرست بودن فیلد',
    palceholder: '«{title}» وارد شده صحیح نیست',
  },
  {
    key: 'charLimit',
    label: 'خطای تعداد کاراکتر',
    palceholder:
      '«{title}» نمی‌تواند کمتر از {minLength} و بیشتر از {maxLength} کاراکتر باشد',
  },
  {
    key: 'numLimit',
    label: 'خطای مقدار اعداد',
    palceholder: '«{title}» نمی‌تواند کمتر از {min} و بیشتر از {max} باشد',
  },
  {
    key: 'optionLimit',
    label: 'خطای تعداد گزینه در فیلد‌های چند انتخابی',
    palceholder:
      'فیلد «{title}» نمی‌تواند کمتر از {min} و بیشتر از {max} گزینه باشد',
  },
  {
    key: 'uploadLimit',
    label: 'خطای حجم فایل',
    palceholder:
      'حجم «{title}» نمی‌تواند کمتر از {min} و بیشتر از {max} کیلوبایت باشد',
  },
  {
    key: 'invalidUpload',
    label: 'خطای بارگذاری فایل با پسوند غیرمجاز',
    palceholder: 'آپلود فایل مورد نظر مجاز نیست',
  },
  {
    key: 'emptyRequired',
    label: 'خطا عدم تکمیل فیلدهای اجباری',
    palceholder: 'پیش از ثبت فرم ابتدا فیلدهای ستاره‌دار را تکمیل کنید',
  },
  {
    key: 'failedUpload',
    label: 'خطای آپلود فایل',
    palceholder: 'آپلود فایل ناموفق بود، لطفا دوباره تلاش کنید',
  },
  {
    key: 'dateLimit',
    label: 'خطای انتخاب تاریخ',
    palceholder: 'تاریخ انتخاب شده در بازه {minDate} تا {maxDate} نیست',
  },
  {
    key: 'feedbackCountLimit',
    label: 'خطای حداکثر بازخورد قابل ثبت',
    palceholder:
      'شما حداکثر تعداد بازخورد قابل ثبت در این فرم را ثبت کرده‌اید.',
  },
];

const FormPatternErrorSection: FC<FormPatternErrorSectionItemProps> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    required: false,
    invalid: false,
    charLimit: false,
    numLimit: false,
    optionLimit: false,
    uploadLimit: false,
    invalidUpload: false,
    emptyRequired: false,
    failedUpload: false,
    dateLimit: false,
    feedbackCountLimit: false,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='پیام‌های اخطار'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='error'
    >
      {buttons.map((item, index) => (
        <PatternTitleItem
          title={item.label}
          inputLabel='متن خطا'
          key={`${item.key}-${item.label}-${index}`}
          collapsed={collapsed[item.key]}
          onCollapsedChange={handleCollapseChanged(item.key)}
          reference={`error.${item.key}`}
          placeholder={item.palceholder}
          include={[
            'padding',
            'fontSize',
            'textDirection',
            'fontColor',
            'bgColor',
          ]}
        />
      ))}
    </PatternSectionContainer>
  );
};

export default FormPatternErrorSection;

import * as React from 'react';
import Fade from '@mui/material/Fade';

// Custom Hooks
import useRefId from 'core/hooks/useRefId';
import useBoolean from 'core/hooks/useBoolean';

// Core Components
import Table from 'core/components/base/display/Table';
import Checkbox from 'core/components/base/inputs/Checkbox';
import TableHead from 'core/components/base/display/TableHead';
import TableRow from 'core/components/base/display/TableRow';
import TableCell from 'core/components/base/display/TableCell';
import TableBody from 'core/components/base/display/TableBody';

// Custom Components
import RowStack from 'core/components/shared/Stack/RowStack';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';

// Feature Components
import FileRow from 'features/file/files/components/shared/Table/FileRow';
import UploadRow from 'features/file/files/components/shared/Table/UploadRow';
import UploadBox from 'features/file/files/components/shared/Dialog/UploadBox';
import TableHeadCell from 'features/file/files/components/shared/Table/TableHeadCell';

// Custom Utilities
import { generateObjectId } from 'core/utilities/helper/id';
import { fileTableHeaderCells } from 'features/file/files/utilities/files/data';
import { filterInvalidFiles } from 'core/utilities/helper/file';

// Custom Types
import type { FileProps } from 'features/file/files/types';
import type { SxProps } from '@mui/material/styles';
import type { UseArrayReturnProps } from 'core/hooks/useArray';

interface FilesTableProps {
  selectedFiles: FileProps[];
  onSelectFile: (file: FileProps, checked: boolean) => void;
  onToggleAll: () => void;
  titleClickBehavior: 'link' | 'popup';
  disableSort?: boolean;
  disableDragAndDrop?: boolean;
  uploadQueue?: UseArrayReturnProps<{ id: string; data: File }>;
  files: UseArrayReturnProps<FileProps>;
  sx?: SxProps;
  onFileUploaded?: (file: FileProps) => void;
  onFileUpdated?: (file: FileProps) => void;
}

const FilesTable: React.FC<FilesTableProps> = (props) => {
  // Props
  const {
    selectedFiles,
    disableDragAndDrop,
    titleClickBehavior,
    disableSort,
    uploadQueue,
    files,
    onToggleAll,
    onSelectFile,
    onFileUploaded,
    onFileUpdated,
    sx,
  } = props;

  // States
  const [sort, setSort] = React.useState<string>('createdAt-desc');
  const isDragging = useBoolean();

  // Hooks
  const [refId] = useRefId();
  const uniqueId = React.useId();

  // Utilities
  const handleAddFileToQueue = React.useCallback((files: File[]) => {
    if (disableDragAndDrop || !uploadQueue) return;
    uploadQueue.set([
      ...uploadQueue.state,
      ...files.map((file) => ({
        id: generateObjectId(),
        data: file,
      })),
    ]);
    isDragging.setFalse();
  }, []);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    if (disableDragAndDrop) return;
    e.preventDefault();
    handleAddFileToQueue(filterInvalidFiles(Array.from(e.dataTransfer.files)));
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    if (disableDragAndDrop) return;
    e.preventDefault();
  };

  // Render
  return (
    <RoundPaper
      onDragOver={() => !disableDragAndDrop && isDragging.setTrue()}
      onDragLeave={() => !disableDragAndDrop && isDragging.setFalse()}
      sx={{
        width: '100%',
        position: 'relative',
        display: 'flex',
        minHeight: '80vh',
        height: 'fit-content',
        flexDirection: 'column',
        gap: '1rem',
        padding: 0,
        borderRadius: '1rem',
        overflow: 'hidden',
        ...sx,
      }}
    >
      <Fade in={isDragging.state}>
        <UploadBox
          hideUploadButton
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Fade>
      <Table
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        sx={{
          borderRadius: '.5rem',
        }}
      >
        <TableHead
          sx={{
            background: ({ palette }) =>
              palette.mode === 'light'
                ? 'rgba(225, 231, 234, 1)'
                : 'background.paper',
          }}
        >
          <TableRow>
            <TableCell
              sx={{
                width: '1rem',
                padding: 0,
              }}
            >
              <RowStack alignItems='center' spacing={0}>
                <Checkbox
                  onClick={onToggleAll}
                  checked={
                    files.length > 0 && selectedFiles.length === files.length
                  } // TODO: There is a bug when you select all items and then go to the next page.
                  indeterminate={
                    selectedFiles.length > 0 &&
                    selectedFiles.length < files.length
                  }
                  size='small'
                />
                <span>فایل</span>
              </RowStack>
            </TableCell>
            {fileTableHeaderCells.map((filterItem) => (
              <TableHeadCell
                {...filterItem}
                hasFilter={disableSort ? false : filterItem?.hasFilter || false}
                sort={sort}
                onSortChange={(value) => setSort(value)}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            backgroundColor: isDragging.state
              ? 'rgba(0, 145, 255, 0.15)'
              : 'inherit',
          }}
        >
          {uploadQueue?.isEmpty && files.isEmpty && (
            <TableRow
              sx={{
                borderTopWidth: '1px',
                borderTopStyle: 'solid',
                borderTopColor: 'divider',
              }}
            >
              <TableCell align='center' colSpan={7}>
                فایلی جهت نمایش وجود ندارد.
              </TableCell>
            </TableRow>
          )}
          {onFileUploaded &&
            !uploadQueue?.isEmpty &&
            uploadQueue?.state?.map?.((file, index) => (
              <UploadRow
                key={`${uniqueId}-queue-${file.id}`}
                refId={refId}
                onUploadFinish={onFileUploaded}
                onDelete={() => uploadQueue.deleteByIndex(index)}
                status={index === 0 ? 'upload' : 'queue'}
                file={file.data}
              />
            ))}

          {!files.isEmpty &&
            files.state.map((file) => (
              <FileRow
                titleClickBehavior={titleClickBehavior}
                key={`${uniqueId}-${file.id}`}
                file={file}
                onSelectFile={onSelectFile}
                onFileUpdated={onFileUpdated}
                isFileSelected={
                  selectedFiles.findIndex(
                    (selectedFile) => selectedFile.id === file.id
                  ) > -1
                }
              />
            ))}
        </TableBody>
      </Table>
    </RoundPaper>
  );
};

export default FilesTable;

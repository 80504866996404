import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Custom Hooks
import useBoolean from 'core/hooks/useBoolean';

// Custom Core Components
import Collapse from 'core/components/base/utils/Collapse';
import Paper from 'core/components/base/surfaces/Paper';
import IconButton from 'core/components/base/inputs/IconButton';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Feature Components
import ListHeader from 'features/menu/menus/components/details/List/Header';
import ListItems from 'features/menu/menus/components/details/List/Items';
import ListActions from 'features/menu/menus/components/details/List/Actions';

// Custom Icons
import ArrowDropDownIcon from 'core/components/icons/ArrowDropDown';

interface MenuListProps {}

const MenuList: React.FC<MenuListProps> = (props) => {
  // States
  const collapsed = useBoolean(true);

  // Hooks
  const { palette } = useTheme();

  // Render
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '18px',
        padding: '1.25rem 1.5rem',
        borderRadius: '8px',
        boxShadow: 'none',
        background: collapsed.state ? '#e7e7e7' : palette.background.paper,
      }}
    >
      <RowStack alignItems='center' spacing={1}>
        <IconButton onClick={collapsed.toggle}>
          <ArrowDropDownIcon
            color='info'
            sx={{
              rotate: collapsed.state ? '0' : '90deg',
              transition: 'all .2s',
            }}
          />
        </IconButton>
        <BodyTwo
          sx={{
            fontWeight: 400,
            fontSize: '1rem',
          }}
        >
          منو
        </BodyTwo>
      </RowStack>
      <Collapse
        in={collapsed.state}
        sx={{
          display: collapsed.state ? 'auto' : 'none',
          '.MuiCollapse-wrapperInner': {
            display: collapsed.state ? 'flex' : 'none',
            flexDirection: 'column',
            gap: '1rem',
          },
        }}
      >
        <ListHeader />
        <ListItems />
        <ListActions />
      </Collapse>
    </Paper>
  );
};

export default MenuList;

/**
 * Returns an object containing the endpoint URLs.
 *
 * @returns The object containing the endpoint URLs.
 */
const getEndpoint = () => ({
  // Auth
  chechEmailForLoginURL: '/login',
  requestPasswordRecoveryURL: '/recoveryPassword',
  loginURL: '/verify',
  passwordRecoveryURL: '/updatePassword',
  twoFactorLoginURL: '/verify2FA',
  logoutURL: '/logOut',

  // Audience
  addAudienceURL: '/audience/create',
  deleteAudienceURL: '/audience/delete',
  editAudienceURL: '/audience/updateAudience',
  getAudiencesURL: '/audience/audiences',
  getAudienceURL: '/audience/audience',
  toggleAudienceStatusURL: '/audience/activatedAudience',

  // Access
  createAccessURL: '/access/create',
  deleteAccessURL: '/access/remove',
  updateAccessURL: '/access/update',
  getAcessesURL: '/access/accesses',
  addAudienceToAccessURL: '/access/addAudienceToAccess',
  removeAudienceAccessURL: '/access/removeAudiecneFromAccess',

  // Audience Folders
  getAudienceFoldersURl: '/audience/folder/folders',
  getAudienceFolderURL: '/audience/folder/folder',
  addAudienceFolderURL: '/audience/folder/create',
  editAudienceFolderURL: '/audience/folder/updateFolder',
  deleteAudienceFoldersByIdURL: '/audience/folder/deleteFolders',

  // Audience Group Permission
  getAudienceGroupPermissionsURL: '/permission/permissions ',

  // Audience Group Operations
  changeAudienceJobTitleURL: '/audience/groupOperation?action=changeJobTitle',
  addAudienceToGroupURL: '/audience/groupOperation?action=addToGroup',
  removeAudienceFromGroupURL: '/audience/groupOperation?action=removeFromGroup',
  getAudienceExcelURL: '/audience/groupOperation?action=excel',
  deleteAudiencesURL: '/audience/groupOperation?action=remove',

  // Banner
  addBannerURL: '/banner/create',
  deleteBannerURL: '/banner/delete',
  editBannerURL: '/banner/update',
  getBannersURL: '/banner/banners',
  sortBannersURL: '/banner/sort',
  getBannerListURL: '/banner/bannerList',

  // Category
  addCategoryURL: '/category/add',
  deleteCategoryURL: '/category/remove',
  editCategoryURL: '/category/edit',
  getCategoriesURL: '/category/getDocs',
  getCategoryUrl: '/category/getDoc',
  getCategoriesListURL: '/category/getList',

  // Content
  addContentURL: '/content/add',
  deleteContentURL: '/content/remove',
  editContentURL: '/content/edit',
  getContentsURL: '/content/getDocs',
  getContentURL: '/content/getDoc',

  // Custom Page
  addCustomPageURL: '/customPage/create',
  getCustomPagesURL: '/customPage/customPages',
  getCustomPageURL: '/customPage',
  updateCustomPageURL: '/customPage/update',
  duplicateCustomPageURL: '/customPage/copy',
  deleteCustomPageURL: '/customPage/delete',

  // Custom Page Body
  createCustomPageBodyURL: '/body/create',
  duplicateCustomPageBodyURL: '/body/copy',
  deleteCustomPageBodyURL: '/body/delete',
  updateCustomPageBodyURL: '/body/update',

  // Custom Page Section
  addSectionURL: '/pageBuilder/addSection',
  getSectionURL: '/pageBuilder/getSection', // sectionId
  deleteSectionURL: '/pageBuilder/deleteSection', //sectionId
  editSectionURL: '/pageBuilder/editSection', //sectionId

  // Email
  addAutomatedEmailURL: '/email/addAutomaticEmail',
  createEmailPreviewURL: '/email/previewEmail',
  deleteEmailsURL: '/email/deleteEmail',
  getOutboxEmailsURL: '/email/getEmails',
  getAutoEmailsURL: '/email/getAutomaticEmails',
  getEmailDetailsURL: '/email/getEmail',
  getReceivedEmailsURL: '/email/getReceivedEmails',
  getEmailChatURL: '/email/getEmailByEmail',
  sendEmailURL: '/email/addEmail',

  // Evaluations
  getEvaluationsURL: '/evaluation/evaluations',
  getEvaluationsListURL: '/evaluation/evaluationsList',
  getEvaluationURL: '/evaluation/evaluation',
  addEvaluationURL: 'evaluation/create',
  updateEvaluationURL: '/evaluation/updateEvaluation',
  deleteEvaluationsURL: '/evaluation/deleteEvaluations',

  // Evaluaions Status & Log
  addStatusLogURL: '/feedback/addLog',
  deleteStatusLogURL: '/feedback/deleteFeedback',
  toggleStatusVisibilityURL: '/feedback/hideStatus',
  toggleStatusLogVisibilityURL: '/feedback/hideAlog',

  // Feedback
  getFeedbacksURL: '/feedback/getFeedbacks',
  getFeedbackURL: '/feedback/getFeedback',
  getFormFeedbackURL: '/feedback/getFormFeedbacks',
  getFeedbacksExcelURL: '/feedback/getExcelFeedback',
  deleteFeedbackURL: '/feedback/deleteFeedback',
  feedbackGroupOperation: '/feedback/feedbackGroupOperation',
  addStatusLog: '/feedback/addLog',
  deleteStatusLog: '/feedback/deleteFeedback',
  editableFeedback: '/feedback/updateIsEditableFeedback',
  getAudienceInfo: '',
  resetEvaluation: '/feedback/process/resetEvaluation',
  removeEvaluation: '/feedback/process/removeEvaluation',

  // Files // * Module base endpoints
  addFileURL: '/uploads?status=obj',
  getFilesURL: '/files',
  getFileURL: '/file',
  deleteFilesURL: '/removeFile',

  // Form
  addFormURL: '/form/addForm',
  deleteFormURL: '/form/deleteForm',
  editFormURL: '/form/editForm',
  getFormsURL: '/form/getForms',
  getFormURL: '/form/getForm',
  getFormsListURL: '/form/FormsList',
  toggleFormEditableFeedbackURL: '/form/editableFeedback',

  // Form Tag
  getFormTagsURL: '/formTag/getFormTags',
  createFormTagURL: '/formTag/create',

  // Manager
  addManagerURL: '/admin/addAdmin',
  deleteManagerURL: '/admin/deleteAdmin',
  editManagerURL: '/admin/editAdmin',
  getManagersURL: '/admin/getAdmins',
  getManagerURL: '/admin/getAdmin',
  toggleManagerStatusURL: '/admin/activatedAdmin',

  // Manager Groups
  addManagerGroupURL: '/group/addGroup',
  deleteManagerGroupsByIdURL: '/group/deleteGroup',
  editManagerGroupURL: '/group/editGroup',
  getManagerGroupsSummaryURL: '/group/getGroupList',
  getManagerGroupsURL: '/group/getGroups',
  getManagerGroupByIdURL: '/group/getGroup',

  // Audience Groups
  addAudienceGroupURL: '/audience/group/addGroup',
  deleteAudienceGroupsByIdURL: '/audience/group/deleteGroups',
  editAudienceGroupURL: '/audience/group/update',
  getAudienceGroupsURL: '/audience/group/groups',
  getAudienceGroupsListURL: '/audience/group/groupList',
  getAudienceGroupURL: '/audience/group/group',
  getAudienceGroupByIdURL: '/audience/group/getGroup',

  // Audience Setting
  getAudienceSetting: '/audience/setting/setting',
  editAudienceSetting: '/audience/setting/updateSetting',
  getAudienceListURL: '/audience/audienceList',

  // Menu
  addMenuURL: '/menu/create',
  deleteMenuURL: '/menu/delete',
  editMenuURL: '/menu/update',
  getMenusURL: '/menu/menus',
  getMenuListURL: '/menu/menuList',
  sortMenusURL: '/menu/sortAppearances',

  // Page
  addPageURL: '/page/addPage',
  deletePagesURL: '/page/deletePage',
  editPageURL: '/page/editPage',
  getPagesURL: '/page/getPages',
  getPageURL: '/page/getPage',

  // Permission
  getPermissionsURL: '/permission/permissions',

  // Place
  getPlacesURL: '/place/getPlaces',
  getPlacesListURL: '/place/getPlacesList',

  // Profile
  getProfileURL: '/admin/getProfile',
  editProfileURL: '/admin/editProfile',

  // Pattern
  getPatternCountURL: '/pattern/getPatternsCount',
  getPattern: '/pattern',
  getPreview: '/pattern/getPreview',
  addPattern: '/pattern/create',
  editPattern: '/pattern/update',
  deletePattern: '/pattern/delete',
  getPatterns: '/pattern/getPatterns',
  duplicatePattern: '/pattern/copy',

  // Settings
  getSettingsURL: '/setting/getSetting',
  editSettingsURL: '/setting/editSetting',

  // Skins
  createSkinURL: '/skin/create',
  deleteSkinURL: '/skin/delete',
  updateSkinURL: '/skin/update',
  getSkinsURL: '/skin/skins',

  // Sheet
  getSheets: '/sheet/getSheets',
  getSheet: '/sheet/getSheet',
  addSheet: '/sheet/create',
  editSheet: '/sheet/edit',
  deleteSheet: '/sheet/deleteSheet',

  // Tag
  addTagURL: '/tag/addTag',
  deleteTagURL: '/tag/deleteTag',
  editTagURL: '/tag/editTag',
  getTagsURL: '/tag/getTags',
  getTagsListURL: '/tag/getTagsList',

  // Templates:
  getTemplatesURL: '/template/templates',
  getTemplateURL: '/template',

  // Tickets
  getTicketsURL: '/ticket/tickets',
  getTicketURL: '/ticket/ticket',
  toggleTicketStatusURL: '/ticket/editIsActive',
  addTicketTopicsURL: '/topic/create',
  editTicketTopicsURL: '/topic/edit',
  getTicketTopicsURL: '/topic/topics',
  getTicketTopicURL: '/topic',
  toggleTicketTopicStatusURL: '/topic/edit',
  deleteTicketTopicURL: '/topic/delete',

  // Two Factor Auth
  createQRFor2FAURL: '/createQUR',
  disable2FAURL: '/deActiveQUR',
  enable2FAURL: '/activeQUR',

  // User
  addUserURL: '/admin/addAdmin', // @depreacted Will be deleted in version 1.0.0
  deleteUserURL: '/admin/deleteAdmin', // @depreacted Will be deleted in version 1.0.0
  editUserURL: '/admin/editAdmin', // @depreacted Will be deleted in version 1.0.0
  getUsersURL: '/admin/getAdmins', // @depreacted Will be deleted in version 1.0.0
  getUserURL: '/admin/getAdmin', // @depreacted Will be deleted in version 1.0.0
  toggleUserStatusURL: '/admin/activatedAdmin', // @depreacted Will be deleted in version 1.0.0

  // User Groups @depreacted
  addGroupURL: '/group/addGroup', // @depreacted Will be deleted in version 1.0.0
  deleteGroupsByIdURL: '/group/deleteGroup', // @depreacted Will be deleted in version 1.0.0
  editGroupURL: '/group/editGroup', // @depreacted Will be deleted in version 1.0.0
  getGroupsSummaryURL: '/group/getGroupList', // @depreacted Will be deleted in version 1.0.0
  getGroupsURL: '/group/getGroups', // @depreacted Will be deleted in version 1.0.0
  getGroupById: '/group/getGroup', // @depreacted Will be deleted in version 1.0.0

  // Module
  uploadURL: '/uploads',
  fileURL: '/file',
  filesURL: '/files',
  updateFile: '/updateFile',
  removeFile: '/removeFile',
});

export default getEndpoint;

import * as React from 'react';

// Core Components
import Typography, {
  TypographyProps,
} from 'core/components/base/display/Typography';

export interface InputLabelProps extends TypographyProps {
  required?: boolean;
  indentRight?: boolean;
  disabled?: boolean;
}

const InputLabel: React.FC<InputLabelProps> = (props) => {
  const {
    children,
    indentRight = false,
    component = 'p',
    gutterBottom = false,
    variant = 'body1',
    required = false,
    disabled = false,
    sx,
    ...otherProps
  } = props;

  return (
    <Typography
      component={component}
      gutterBottom={gutterBottom}
      variant={variant}
      sx={{
        color: disabled ? 'text.disabled' : undefined,
        position: indentRight ? 'relative' : 'initial',
        left: indentRight ? '1rem' : '0',
        whiteSpace: 'nowrap',
        ...sx,
      }}
      {...otherProps}
    >
      {`${children}${required ? ' *' : ''}`}
    </Typography>
  );
};

export default InputLabel;

import { memo, type FC, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import CollapseIconButton from 'core/components/shared/IconButton/Collapse';
import StylesIconButton from 'core/components/shared/IconButton/Styles';
import CopyIconButton from 'core/components/shared/IconButton/Copy';
import BinIconButton from 'core/components/shared/IconButton/Bin';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useDialogContext } from 'core/store/context/DialogProvider';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { getInputsIndexMap } from 'features/form/forms/utilities/input/helper';
import {
  setFormActionMode,
  setFormCurrentSectionId,
  setFormCurrentStep,
  toggleFormSectionCollapsed,
  updateFormSections,
} from 'features/form/forms/store/actions';

// Custom Types
import type { FormProps } from 'features/form/forms/types/item';
import type { FormSectionProps } from 'features/form/forms/types/item/section';
import {
  useCopyFormSectionMutation,
  useDeleteFormSectionMutation,
} from 'features/form/forms/hooks';

export interface FormSectionHeaderPreviewActionsProps {
  section: FormSectionProps;
  index: number;
}

const FormSectionHeaderPreviewActions: FC<
  FormSectionHeaderPreviewActionsProps
> = (props) => {
  // Props
  const { section, index } = props;

  // Context
  const { onOpenDialog, onCloseDialog } = useDialogContext();
  const { getValues, setValue } = useFormContext<FormProps>();

  // Hooks
  const loading = useSelectLoading();
  const actionMode = useAppSelector((store) => store.form.actionMode);
  const isCollapsed = useAppSelector(
    (store) => store.form?.sectionCollapsed?.[section.id] || false
  );
  const currentSectionId = useAppSelector(
    (store) => store.form.currentSectionId
  );
  const isInAnotherSection = useMemo(
    () => currentSectionId && currentSectionId !== section.id,
    [section.id, currentSectionId]
  );
  const disabled = useMemo(
    () => isInAnotherSection || !['INITIAL', 'SECTION'].includes(actionMode),
    [isInAnotherSection, actionMode]
  );
  const { mutate: handleDeleteFormSection } = useDeleteFormSectionMutation({
    autoAlert: { mode: 'delete', name: 'بخش انتخابی' },
    noRevalidate: true,
    onMutate: () => {
      setFormActionMode('SECTION_DELETE');
      setFormCurrentSectionId(section.id);
    },
    onSettled: () => setFormActionMode('INITIAL'),
    onSuccess: () => {
      const sections = cloneDeep(getValues('data.sections'));
      sections.splice(index, 1);

      setValue('data.sections', sections);
      onCloseDialog();
      updateFormSections(sections);
    },
  });
  const { mutate: handleCopyFormSection } = useCopyFormSectionMutation({
    customAlert: {
      successMessage: 'بخش با موفقیت کپی شد.',
      errorMessage: 'کپی بخش با خطا مواجه شد.',
    },
    onMutate: () => {
      setFormActionMode('SECTION_COPY');
      setFormCurrentSectionId(section.id);
    },
    onSuccess: ({ doc }) => {
      if (!doc) return;
      setFormCurrentStep({
        id: doc.id,
        index: doc.data.sortIndex,
        inputs: doc.data.inputs,
        sections: doc.data.sections,
      });
      const inputsIndexMap = getInputsIndexMap(doc.data.inputs);

      setValue('data.sections', doc.data.sections);
      setValue('data.inputsIndexMap', inputsIndexMap);
      setValue('data.inputs', doc.data.inputs);
    },
    onSettled: () => {
      setFormActionMode('INITIAL');
      setFormCurrentSectionId('');
    },
  });
  // Utilities
  const toggleCollapse = () => toggleFormSectionCollapsed(section.id);
  const closeCollapse = () => toggleFormSectionCollapsed(section.id, false);
  const handleChangeMode = () => {
    setFormCurrentSectionId(section.id);
    setFormActionMode('SECTION');
    closeCollapse();
  };

  const handleOpenDialog = useCallback(
    () =>
      onOpenDialog({
        onSubmit: () => handleDeleteFormSection(section.id),
        description: 'با حذف این بخش، تمامی فیلدهای آن حذف می‌شوند.',
      }),
    [onOpenDialog]
  );

  // Render
  return (
    <RowStack spacing={1}>
      <BinIconButton
        disabled={disabled}
        loading={
          !isInAnotherSection && loading && actionMode === 'SECTION_DELETE'
        }
        onClick={handleOpenDialog}
      />
      <CopyIconButton
        onClick={() => handleCopyFormSection(section.id)}
        disabled={disabled}
      />
      <StylesIconButton disabled={disabled} onClick={handleChangeMode} />
      <CollapseIconButton collapsed={isCollapsed} onClick={toggleCollapse} />
    </RowStack>
  );
};

export default memo(FormSectionHeaderPreviewActions, isSame);

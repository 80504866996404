import * as React from 'react';

// Core Components
import Grid, { GridProps } from 'core/components/base/layout/Grid';

interface GridContainerProps extends GridProps {}

const GridContainer: React.FC<GridContainerProps> = (props) => {
  const { children, container, spacing = 2, ...otherProps } = props;
  return (
    <Grid container spacing={spacing} {...otherProps}>
      {children}
    </Grid>
  );
};

export default GridContainer;

// Pages
import Login from 'features/manager/auth/components/Login';
import PasswordLogin from 'features/manager/auth/components/PasswordLogin';
import PasswordRecovery from 'features/manager/auth/components/PasswordRecovery';
import TwoFactorAuth from 'features/manager/auth/components/TwoFactorAuthentication';

// Types
import type { PublicRouteProps } from 'core/types/shared/routes';

const publicRoutes: PublicRouteProps[] = [
  { path: '/login', Component: Login },
  { path: '/password-login', Component: PasswordLogin },
  { path: '/password-recovery', Component: PasswordRecovery },
  { path: '/password-login/2fa', Component: TwoFactorAuth },
];

export default publicRoutes;

import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  styled,
} from '@mui/material';

export interface CheckboxProps extends MuiCheckboxProps {}

const Checkbox = styled(MuiCheckbox)<CheckboxProps>(() => ({}));

Checkbox.defaultProps = {
  color: 'info',
};

export default Checkbox;

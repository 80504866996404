import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import type { FC } from 'react';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Custom Feature Components
import FormItemActions from 'features/form/forms/components/details/item/Actions';
import FormItemSettings from 'features/form/forms/components/details/item/Settings';
import FormItemSteps from 'features/form/forms/components/details/item/Steps';
import FormItemSections from 'features/form/forms/components/details/item/Sections';

// Custom Hooks
import useUnMount from 'core/hooks/useUnMount';
import useMount from 'core/hooks/useMount';

// Custom Utilities
import { useFormDoc } from 'features/form/forms/hooks';
import { setFormCurrentStep } from 'features/form/forms/store/actions/step';
import {
  clearForm,
  setForm,
  setFormActionMode,
  setFormId,
  setFormMode,
} from 'features/form/forms/store/actions';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';

// Custom Types
import type { FormProps } from 'features/form/forms/types/item';

const EditForm: FC = () => {
  // Context
  const formTitle = useAppSelector((state) => state.form.data?.title || '');

  // Hooks
  const { id } = useParams();
  const formMethods = useForm<FormProps>({
    mode: 'onTouched',
  });
  const formDocMethods = useFormDoc({
    alertOnFetchError: { message: 'دریافت اطلاعات فرم با خطا مواجه شد.' },
    alertOnDocumentIdIsEmpty: { message: 'شناسه فرم یافت نشد.' },
    redirectAfterDocumentIdIsEmptyTo: '/forms',
    redirectAfterErrorTo: '/forms',
    onFetch: (doc) => {
      setForm(doc.data);
      const clonedData = cloneDeep(doc.data);

      if (clonedData.inputs.length > 0) {
        let indexMapToSet: { [key: string]: number } = {};
        clonedData.inputs.forEach(
          (inp, index) => (indexMapToSet[inp.id] = index)
        );

        clonedData.inputsIndexMap = indexMapToSet;
      } else {
        clonedData.inputsIndexMap = {};
      }

      formMethods.reset({ id, data: clonedData });

      const currentStep = clonedData.steps[0];
      setFormCurrentStep({
        id: currentStep.id,
        index: currentStep.data.sortIndex,
      });
    },
  });

  useMount(() => {
    if (!id) return;
    setFormId(id);
    setFormMode('edit');
    setFormActionMode('INITIAL');
  });

  useUnMount(() => clearForm());

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle={`ویرایش فرم ${formTitle}`} />
      <FormProvider {...formMethods}>
        <FormItemActions mode='edit' />
        <ColumnStack>
          <FormItemSettings mode='edit' />
          <FormItemSteps />
          <FormItemSections />
        </ColumnStack>
      </FormProvider>
    </>
  );
};

export default EditForm;

import type { ProfileField } from 'features/audience/settings/components/AudienceSettings';
import type {
  EditableAudienceSettingsFieldsDataProps,
  RequiredAudienceSettingsFieldsDataProps,
} from 'features/audience/settings/types';

type RequiredAudienceField = keyof RequiredAudienceSettingsFieldsDataProps;
type EditableAudienceField = keyof EditableAudienceSettingsFieldsDataProps;

export const requiredFields: RequiredAudienceField[] = [
  'firstName',
  'lastName',
  'birthDate',
  'email',
  'personelId',
  'nationalId',
  'biography',
  'avatar',
];

export const editableAudienceFields: EditableAudienceField[] = [
  'email',
  'biography',
  'avatar',
];

export const profileFields: ProfileField[] = [
  'firstName',
  'lastName',
  'birthDate',
  'email',
  'personelId',
  'nationalId',
  'jobTitle',
  'biography',
  'avatar',
  'groups',
];

export const pagesAddress: {
  label: string;
  link: string;
  disabled?: boolean;
}[] = [
  { label: 'پروفایل', link: 'profile', disabled: false },
  { label: 'اطلاعات پروفایل', link: 'profile/info', disabled: false },
  { label: 'ویرایش پروفایل', link: 'profile/info/edit', disabled: false },
  { label: 'همه فرم‌ها', link: 'profile/form', disabled: false },
  { label: 'یک فرم', link: 'profile/form/formSlug', disabled: false },
  {
    label: 'بازخوردهای یک فرم',
    link: 'profile/form/formId',
    disabled: false,
  },
  { label: 'همه پوشه', link: 'profilde/folder', disabled: false },
  { label: 'یک پوشه', link: 'profile/folder/folderId', disabled: false },
  { label: 'همه تیکت‌ها', link: 'profile/ticker', disabled: false },
];

// Feature
import type { EvaluationProps } from 'features/form/processes/types/details/evaluation';
import type { ManagerQuestionProps } from 'features/form/processes/types/details/managerQuestion';

/**
 * Transforms and prepares a process item for backend submission,
 * ensuring it adheres to the approved structure.
 *
 * This function "bakes" the data by extracting relevant fields and
 * formatting them appropriately based on the type of process item
 * (either 'evaluation' or 'managerQuestion').
 *
 * @param {string} processId - The ID of the process to which the item belongs.
 * @param {ManagerQuestionProps | EvaluationProps} processItem - The process item to be transformed.
 *
 * @returns {Object} The transformed process item ready for backend submission.
 *
 * @throws {Error} Throws an error if the processItem type is not recognized.
 */
export const bakeProcessItem = (
  processId: string,
  processItem: ManagerQuestionProps | EvaluationProps
) => {
  if (processItem.data.processItemType === 'evaluation') {
    const { questionOptions, ...otherData } = processItem.data;

    return {
      processBlueprintId: processId,
      questionOptions: questionOptions.map((opt) => ({
        ...opt.data,
        label: opt.data.label || 'بدون عنوان',
      })),
      ...otherData,
    };
  }

  return {
    processBlueprintId: processId,
    ...processItem.data,
  };
};

import { useEffect, useState, useCallback, type FC } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import {
  Controller,
  FormProvider,
  useForm,
  type SubmitHandler,
} from 'react-hook-form';

// Custom Core Components
import Collapse from 'core/components/base/utils/Collapse';
import Box from 'core/components/base/layout/Box';
import TextField from 'core/components/base/inputs/TextField';
import InputAdornment from 'core/components/base/inputs/InputAdornment';

// Custom Common Components
import CloseIconButton from 'core/components/shared/IconButton/Close';
import DatePicker from 'core/components/shared/Picker/DatePicker';
import DeleteLoadingButton from 'core/components/shared/LoadingButton/Delete';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import RowStack from 'core/components/shared/Stack/RowStack';
import SubmitLoadingButton from 'core/components/shared/LoadingButton/Submit';
import ButtonGroup from 'core/components/base/inputs/ButtonGroup';
import Button from 'core/components/base/inputs/Button';

// Feature Components
import FeedbackListFieldsFilter from 'features/form/feedbacks/components/list/Actions/Filter/Fields';
import FeedbacksEvaluationFilter from 'features/form/feedbacks/components/list/Actions/Filter/Evaluations';

// Custom Icon Components
import BinIcon from 'core/components/icons/Bin';
import FilterIcon from 'core/components/icons/Filter';
import SearchIcon from 'core/components/icons/Search';

// Custom Utilities
import { getInputsAndValuesList } from 'features/form/forms/utilities/api/inputs';
import { forLoop, isSucceed } from 'core/utilities/helper';

// Feature Utilities
import { getProcessItemsSummary } from 'features/form/processes/utilities/api';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { ParamsProps } from 'core/hooks/api/useDeprecatedDocs';
import { useDocsContext } from 'core/store/context/useDeprecatedDocsApi';

import { ProcessEvaluationItemSummaryProps } from 'features/form/processes/types/list/item';

// Custom Types
import type { FormInputSummaryProps } from 'features/form/forms/types/item/input';

type FilterMode = 'inputs' | 'evaluation';
export interface FormFeedbacksListFilterActionsProps {
  in?: boolean;
  onClose?: () => void;
}

export type FormFeedbacksListFilterState = ParamsProps & {
  items: FormInputSummaryProps[];
  evaluationItems: ProcessEvaluationItemSummaryProps[];
};

const defaultFilterItem: FormInputSummaryProps = {
  id: 'draft',
  data: { label: '', type: 'text', values: [] },
};

const FormFeedbacksListFilterActions: FC<
  FormFeedbacksListFilterActionsProps
> = (props) => {
  // Props
  const { onClose } = props;

  // Hooks
  const [searchParams] = useSearchParams();
  const [filterMode, setFilterMode] = useState<FilterMode>('inputs');
  const [id, setId] = useState<string>('');
  const [processId, setProcessId] = useState('');
  const [initialized, setInitialized] = useState<boolean>(false);
  const [options, setOptions] = useState<FormInputSummaryProps[]>([]);
  const [evaluations, setEvaluations] = useState<
    ProcessEvaluationItemSummaryProps[]
  >([]);

  const loading = useSelectLoading();
  const location = useLocation();

  const { formId } = useParams();

  const { onFilter, other } = useDocsContext();

  const formMethods = useForm<FormFeedbacksListFilterState>({
    mode: 'onTouched',
    defaultValues: {
      search: '',
      startDate: '',
      endDate: '',
      items: [defaultFilterItem],
      evaluationItems: [],
    },
  });
  const { control, setValue, reset, handleSubmit } = formMethods;

  const initEvaluationOptions = useCallback(async () => {
    if (processId) {
      const { status, list = [] } = await getProcessItemsSummary(processId);
      if (isSucceed(status) && list.length > 0) {
        const evaluationsToSave: ProcessEvaluationItemSummaryProps[] = [];

        forLoop(list, (doc) => {
          if (doc.data.type === 'evaluation') {
            evaluationsToSave.push(doc as ProcessEvaluationItemSummaryProps);
          }
        });

        setEvaluations(evaluationsToSave);
      }
    }
  }, [processId]);

  const pId =
    other && 'processId' in other && typeof other.processId === 'string'
      ? other.processId
      : '';

  useEffect(() => {
    if (pId && pId !== processId) {
      setProcessId(pId);
    }
  }, [pId]);

  const initOpts = useCallback(async () => {
    if (!initialized && formId) {
      const { status, list = [] } = await getInputsAndValuesList(formId);

      if (isSucceed(status)) {
        const listToSet = list.filter(
          (item) => item.data.type !== 'date' && item.data.type !== 'time'
        );
        setOptions(listToSet);

        setInitialized(true);
      }
    }
  }, [initialized, formId]);

  useEffect(() => {
    initOpts();
    initEvaluationOptions();
  }, [initOpts, initEvaluationOptions]);

  useEffect(() => {
    const params = searchParams.toString();

    if (params.includes('pid_')) {
      setFilterMode('evaluation');
    }
  }, [searchParams]);

  useEffect(() => {
    if (props.in) {
      const params: FormFeedbacksListFilterState = {
        search: '',
        endDate: '',
        startDate: '',
        items: [],
        evaluationItems: [],
      };

      if (location.search) {
        const search = location.search.replaceAll('?', '');
        const entries = search.split('&');

        entries.forEach((entry) => {
          const keyValue = entry.split('=');
          const key = keyValue[0];
          const value = keyValue[1];

          if (key === 'search') {
            params.search = value;
          } else if (key === 'endDate') {
            params.endDate = value.replaceAll('%3A', ':');
          } else if (key === 'startDate') {
            params.startDate = value.replaceAll('%3A', ':');
          } else {
            const values = decodeURI(value).replaceAll('%2C', ',').split(',');

            const inp = options.find((opt) => opt.id === key);

            params.items.push({
              id: key,
              data: {
                label: '',
                type: inp?.data.type || 'text',
                values,
              },
            });
          }
        });
      }

      if (params.items.length < 5) {
        params.items.push(defaultFilterItem);
      }

      reset(params);
    }
  }, [location, props.in, reset]);

  useEffect(() => {
    if (formId) {
      setId(formId);
    }
  }, [formId]);

  // Utilities
  const handleReset = () => {
    onFilter({
      search: '',
      startDate: '',
      endDate: '',
      items: [defaultFilterItem],
      evaluationItems: [],
    });
  };

  const handleFilterModeChange = (value: FilterMode) => {
    handleReset();
    setFilterMode(value);
  };

  const handleFilterSubmit: SubmitHandler<FormFeedbacksListFilterState> = (
    data
  ) => {
    const newSearchParams = new URLSearchParams();

    if (data.search && data.search.length > 0) {
      newSearchParams.set('search', data.search);
    }

    if (data.startDate && data.startDate.length > 0) {
      newSearchParams.set('startDate', data.startDate);
    }

    if (data.endDate && data.endDate.length > 0) {
      newSearchParams.set('endDate', data.endDate);
    }

    if (data.items && data.items.length > 0) {
      const items = data.items;

      const draftIndex = items.findIndex((item) => item.id === 'draft');

      if (draftIndex > -1) items.splice(draftIndex, 1);

      if (items.length > 0) {
        forLoop(items, (item, index) => {
          if (['select', 'checkbox', 'radio'].includes(item.data.type)) {
            const valuesArray =
              item.data.values && item.data.values.length > 0
                ? item.data.values
                : [];

            const values = valuesArray.map((v) => `${v}`).join(',');

            if (values) {
              newSearchParams.set(item.id, values);
            }
          } else {
            const value =
              item.data.values && item.data.values.length > 0
                ? item.data.values[0]
                : '';

            if (value) {
              newSearchParams.set(item.id, value);
            }
          }
        });
      }
    }

    if (filterMode === 'evaluation' && data.evaluationItems.length > 0) {
      const evaluationItems = data.evaluationItems;

      if (evaluationItems.length > 0) {
        forLoop(evaluationItems, (item) => {
          newSearchParams.append(`pid_${item.id}`, item.data.selectedId);
        });
      }
    }

    onFilter({}, newSearchParams);
  };

  return (
    <Collapse unmountOnExit in={props.in}>
      <RoundPaper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mb: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            minWidth: '100%',
          }}
        >
          <ButtonGroup color='info'>
            {(
              [
                { value: 'inputs', label: 'بازخوردها' },
                { value: 'evaluation', label: 'فرآیند' },
              ] as { value: FilterMode; label: string }[]
            ).map((btn) => (
              <Button
                key={btn.label + btn.value}
                sx={{ borderRadius: '0.5rem' }}
                variant={filterMode === btn.value ? 'contained' : 'outlined'}
                onClick={() => handleFilterModeChange(btn.value)}
              >
                {btn.label}
              </Button>
            ))}
          </ButtonGroup>
          <CloseIconButton color='error' onClick={onClose} />
        </Box>
        {filterMode === 'inputs' && (
          <Box sx={{ display: 'flex', gap: 1, minWidth: '100%' }}>
            <Controller
              control={control}
              name='search'
              defaultValue=''
              render={({ field }) => (
                <TextField
                  placeholder='کلمه جستجو'
                  sx={{ flexGrow: 1 }}
                  InputProps={{
                    sx: {
                      height: '2.5rem',
                      px: '0.5rem',
                      minWidth: '12rem',
                    },
                    startAdornment: (
                      <InputAdornment position='start'>
                        <FilterIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                  value={field.value ? decodeURI(field.value) : ''}
                />
              )}
            />
            <Controller
              control={control}
              name='startDate'
              render={({ field }) => (
                <DatePicker
                  placeholder='تاریخ شروع'
                  sx={{ width: '10rem', height: '2.5rem' }}
                  value={field.value ? new Date(field.value) : undefined}
                  onDateChange={(date) => {
                    let dateToSet = '';

                    if (date) {
                      const dateObj = date;

                      dateObj.setHours(0, 0, 0, 0);

                      dateToSet = dateObj.toISOString();
                    }

                    setValue(field.name, dateToSet);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name='endDate'
              render={({ field }) => (
                <DatePicker
                  placeholder='تاریخ پایان'
                  sx={{ width: '10rem', height: '2.5rem' }}
                  value={field.value ? new Date(field.value) : undefined}
                  onDateChange={(date) => {
                    let dateToSet = '';

                    if (date) {
                      const dateObj = date;

                      dateObj.setHours(23, 59, 59, 999);

                      dateToSet = dateObj.toISOString();
                    }

                    setValue(field.name, dateToSet);
                  }}
                />
              )}
            />
          </Box>
        )}
        <FormProvider {...formMethods}>
          {id && filterMode === 'inputs' ? (
            <FeedbackListFieldsFilter
              formId={id}
              initialized={initialized}
              options={options}
            />
          ) : (
            <FeedbacksEvaluationFilter evaluations={evaluations} />
          )}
        </FormProvider>
        <RowStack sx={{ ml: 'auto' }}>
          <DeleteLoadingButton
            loading={loading}
            onClick={handleReset}
            startIcon={<BinIcon />}
          >
            حذف فیلتر
          </DeleteLoadingButton>
          <SubmitLoadingButton
            variant='outlined'
            color='info'
            sx={{ minWidth: '6rem' }}
            onClick={handleSubmit(handleFilterSubmit)}
            loading={loading}
          >
            اعمال
          </SubmitLoadingButton>
        </RowStack>
      </RoundPaper>
    </Collapse>
  );
};

export default FormFeedbacksListFilterActions;

import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternTitleItem from 'features/appBuilder/patterns/core/components/shared/items/Title';

// Custom Types
export interface FormPatternAlertSectionItemProps {}
type CollapsedKey =
  | 'section'
  | 'expired'
  | 'accessible'
  | 'cancelText'
  | 'submitText'
  | 'fileUploaded'
  | 'fileUploading';

type MapButton = {
  key: CollapsedKey;
  label: string;
  palceholder: string;
};
const buttons: MapButton[] = [
  {
    key: 'expired',
    label: 'فرم منقضی شده',
    palceholder:
      'این فرم از تاریخ {pubDate} ساعت {pubHour} تا تاریخ {expDate} ساعت {expHour} در دسترس بوده و اکنون منقضی شده است.',
  },
  {
    key: 'accessible',
    label: 'فرم منتشر نشده',
    palceholder:
      'این فرم از تاریخ {pubDate} ساعت {pubHour} تا تاریخ {expDate} ساعت {expHour} در دسترس خواهد بود.',
  },
  {
    key: 'cancelText',
    label: 'انصراف از تکمیل فرم',
    palceholder:
      'در صورت انصراف، تمامی تغییرات ثبت شده از بین خواهند رفت. آیا می خواهید تغییرات را لغو کنید؟',
  },
  {
    key: 'submitText',
    label: 'پیام ثبت بازخورد',
    palceholder: 'بازخورد شما در فرم «{title}» با موفقیت ذخیره شد.',
  },
  {
    key: 'fileUploaded',
    label: 'متن فایل آپلود شده',
    palceholder: 'فایل‌ با موفقیت بارگذاری شد',
  },
  {
    key: 'fileUploading',
    label: 'متن فایل درحال آپلود',
    palceholder: 'در حال بارگذاری فایل',
  },
];

const FormPatternAlertSection: FC<FormPatternAlertSectionItemProps> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    expired: false,
    accessible: false,
    cancelText: false,
    submitText: false,
    fileUploaded: false,
    fileUploading: false,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  // Helpers

  return (
    <PatternSectionContainer
      title='پیام‌های اطلاع‌رسانی'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='alert'
    >
      {buttons.map((item, index) => (
        <PatternTitleItem
          title={item.label}
          inputLabel='متن اطلاع‌رسانی'
          key={`${item.key}-${item.label}-${index}`}
          collapsed={collapsed[item.key]}
          onCollapsedChange={handleCollapseChanged(item.key)}
          reference={`alert.${item.key}`}
          placeholder={item.palceholder}
          include={
            item.key === 'submitText'
              ? [
                  'padding',
                  'fontSize',
                  'textDirection',
                  'fontColor',
                  'bgColor',
                  'textAlign',
                ]
              : ['padding', 'fontSize', 'textDirection', 'fontColor', 'bgColor']
          }
        />
      ))}
    </PatternSectionContainer>
  );
};

export default FormPatternAlertSection;

// Custom Utilities
import { transformAudiencePermissions } from 'features/audience/permissions/utilities';

// Custom Types
import type {
  ApiAudienceGroupProps,
  ApiAudienceGroupSummaryProps,
} from 'features/audience/groups/types/api';
import type {
  AudienceGroupProps,
  AudienceGroupSummaryProps,
} from 'features/audience/groups/types';

export const transformAudienceGroupSummary = (
  apiAudienceGroup: ApiAudienceGroupSummaryProps
): AudienceGroupSummaryProps => {
  return {
    id: apiAudienceGroup.id || apiAudienceGroup._id || '',
    data: {
      title: apiAudienceGroup.data.title,
      audienceCount: apiAudienceGroup.data.countOfAudience || 0,
      features: apiAudienceGroup.data.features || [],
    },
  };
};

export const transformAudienceGroupsSummary = (
  apiAudienceGroups: ApiAudienceGroupSummaryProps[]
): AudienceGroupSummaryProps[] => {
  const audienceGroups: AudienceGroupSummaryProps[] = [];

  apiAudienceGroups.forEach((apiAudienceGroup) =>
    audienceGroups.push(transformAudienceGroupSummary(apiAudienceGroup))
  );

  return audienceGroups;
};

export const transformAudienceGroup = (
  apiAudienceGroup: ApiAudienceGroupProps
): AudienceGroupProps => {
  return {
    id: apiAudienceGroup._id,
    data: {
      title: apiAudienceGroup.data.title,
      audienceCount: apiAudienceGroup.data.countOfAudience,
      permissions: transformAudiencePermissions(
        apiAudienceGroup.data.permissions
      ),
    },
  };
};

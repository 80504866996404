import * as React from 'react';

// Core Components
import Typography, {
  TypographyProps,
} from 'core/components/base/display/Typography';

// Types
export interface BodyTwoProps extends TypographyProps {
  disabled?: boolean;
}

const BodyTwo: React.FC<BodyTwoProps> = (props) => {
  const {
    children,
    disabled = false,
    component = 'p',
    variant = 'body2',
    sx,
    ...otherProps
  } = props;

  return (
    <Typography
      component={component}
      variant={variant}
      sx={{
        color: disabled ? 'text.disabled' : undefined,
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};

export default BodyTwo;

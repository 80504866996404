// Custom Core Components
import Box, { type BoxProps } from 'core/components/base/layout/Box';

// Custom Types
interface ActionsBoxProps extends BoxProps {}

/**
 * Represents a custom component for rendering actions box.
 * @component ActionsBox
 * @param {BoxProps} props - The props for the ActionsBox component.
 * @returns {ReactElement} The rendered ActionsBox component.
 */
const ActionsBox: React.FC<ActionsBoxProps> = (props) => {
  const { children, sx, ...otherProps } = props;

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginY: '1rem',
        height: '3rem',
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
};

export default ActionsBox;

import { useMemo } from 'react';

// Types
import type { FC } from 'react';

// Custom Common Components
import DataGrid from 'core/components/shared/DataGrid';

// Custom Utilities
import { getAccessDataGridColumns } from 'features/audience/accesses/utilities/helper';

// Types
import type { DataGridProps } from 'core/components/shared/DataGrid';
import type { AccessProps } from 'features/audience/accesses/types';
import type { DataGridRowValueProps } from 'core/components/shared/DataGrid/dataGrid';

export interface AccessDataGridProps
  extends Omit<DataGridProps, 'columns' | 'rows'> {
  accessList: AccessProps[];
  onDelete: (accessId: string) => void;
  onUpdate: (accessId: string, title: string) => Promise<void>;
}

const AccessDataGrid: FC<AccessDataGridProps> = (props) => {
  // Props
  const { accessList = [], onDelete, onUpdate, ...otherProps } = props;

  // Hooks
  const rows = useMemo(() => {
    return accessList.map(({ id, data }) => ({
      id,
      col1: data.title,
      col2:
        data.audiences && data.audiences.length > 0 ? data.audiences.length : 0,
    })) as DataGridRowValueProps[];
  }, [accessList]);

  return (
    <DataGrid
      selectable={false}
      columns={getAccessDataGridColumns(onDelete, onUpdate)}
      rows={rows}
      headProps={{
        sx: { backgroundColor: '#D8E8F1' },
      }}
      {...otherProps}
    />
  );
};

export default AccessDataGrid;

// Types
import type { FC } from 'react';

// Custom Section Components
import SheetsListDataGrid from 'features/data/sheets/components/list/DataGrid';

// Custom Common Components
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import ButtonLink from 'core/components/shared/Button/ButtonLink';
import RowStack from 'core/components/shared/Stack/RowStack';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Icon Components
import PlusCircleOutlineIcon from 'core/components/icons/PlusCircleOutline';

// Custom Utilities
import { useSheetDocs } from 'features/data/sheets/hooks';

// Custom Types
interface SheetListProps {}

const SheetList: FC<SheetListProps> = (props) => {
  // Hooks
  const { data: sheet } = useSheetDocs({ defaultPageSize: 999 });

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='شیت‌ها' />
      <PageHeroBox>
        <PageTitle>شیت‌ها</PageTitle>
      </PageHeroBox>
      <ColumnStack>
        <SheetsListDataGrid items={sheet.list} />
        <RowStack sx={{ width: '100%', justifyContent: 'center' }}>
          <ButtonLink
            href='/sheet/add'
            variant='outlined'
            color='info'
            startIcon={<PlusCircleOutlineIcon />}
          >
            شیت جدید
          </ButtonLink>
        </RowStack>
      </ColumnStack>
    </>
  );
};

export default SheetList;

import cloneDeep from 'lodash/cloneDeep';

// Feature Utilities
import { tagAndCategoryPatterCardConfigDefaults } from 'features/appBuilder/patterns/features/tagAndCategory/utilities/defaults/cardConfig';
import { tagAndCategoryPatterPageConfigDefaults } from 'features/appBuilder/patterns/features/tagAndCategory/utilities/defaults/pageConfig';

// Custom Types
import type { TagAndCategoryPatternDataProps } from 'features/appBuilder/patterns/features/tagAndCategory/types';

const tagAndCategoryPatternDataDefaults: TagAndCategoryPatternDataProps = {
  patternTitle: '',
  bgColorOne: '#F5F5F5',
  bgColorTwo: '#F5F5F5',
  dividerColor: '#C1C8D3',
  bgImage: null,
  bgImageType: 'cover',
  padding: '32px',
  cardBg: '#FFFFFF',
  cardCount: 20,
  creator: null,
  isActive: true,
  maxWidth: 'lg',
  sortIndex: 0,
  type: 'tagAndCategory',
  variant: 0,
  cardConfig: tagAndCategoryPatterCardConfigDefaults,
  pageConfig: tagAndCategoryPatterPageConfigDefaults,
};

export const getTagAndCategoryPatternDataDefault = () => {
  const cloned = cloneDeep(tagAndCategoryPatternDataDefaults);

  return cloned;
};

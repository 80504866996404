import { useCallback, type FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactSortable } from 'react-sortablejs';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Feature Components
import FormItemSection from 'features/form/forms/components/details/item/Section';

// Custom Shared Components
import CancelButton from 'features/form/shared/components/button/Cancel';
import SaveLoadingButton from 'features/form/shared/components/loadingButton/Save';
import SortButton from 'features/form/shared/components/button/Sort';
import AddSectionLoadingButton from 'features/form/shared/components/loadingButton/AddSection';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import { useAppSelector } from 'core/hooks/redux';
import { deepClone } from 'core/utilities/helper/helperPack';
import {
  setFormActionMode,
  updateFormSections,
} from 'features/form/forms/store/actions';

// Custom Types
import type { ColumnStackProps } from 'core/components/shared/Stack/ColumnStack';
import type { FormProps } from 'features/form/forms/types/item';
import type { FormSectionProps } from 'features/form/forms/types/item/section';
import {
  useAddFormSectionMutation,
  useEditFormSectionsSortMutation,
} from 'features/form/forms/hooks';

interface FormItemSectionsProps extends ColumnStackProps {}
const FormItemSections: FC<FormItemSectionsProps> = (props) => {
  // Context
  const loading = useSelectLoading();
  const actionMode = useAppSelector((store) => store.form.actionMode);
  const formId = useAppSelector((store) => store.form.formId);
  const formSections = useAppSelector(
    (store) => store.form.data?.sections || []
  );
  const currentStep = useAppSelector((store) => store.form.currentStep);
  const currentSectionId = useAppSelector(
    (store) => store.form.currentSectionId
  );

  // Hooks
  const { control, setValue, getValues } = useFormContext<FormProps>();
  const isInInitialMode = useMemo(
    () => !currentSectionId && actionMode === 'INITIAL',
    [actionMode, currentSectionId]
  );
  const { mutate: handleAddFormSection } = useAddFormSectionMutation({
    noRevalidate: true,
    autoAlert: { mode: 'create', name: 'بخش جدید' },
    onSuccess: ({ section }) => {
      if (!section) return;
      const sections = deepClone(formSections) || [];
      sections.push(section);

      setValue('data.sections', sections);
      updateFormSections(sections);
    },
    onSettled: () => setFormActionMode('INITIAL'),
  });
  const { mutate: handleEditFormSectionsSort } =
    useEditFormSectionsSortMutation({
      noRevalidate: true,
      autoAlert: { mode: 'update', name: 'ترتیب بخش‌ها' },
      onSuccess: ({ docs }) => {
        if (!docs) return;
        setValue('data.sections', docs);
        updateFormSections(docs);
        setFormActionMode('INITIAL');
      },
    });

  // Utilities
  const handleStartSort = () => setFormActionMode('SECTION_SORT');
  const handleSort = (newList: FormSectionProps[]) => {
    let listToSet: FormSectionProps[] = [];

    newList.forEach((section, index) => {
      listToSet.push({
        id: section.id,
        data: {
          ...section.data,
          sortIndex: index,
        },
      });
    });

    setValue('data.sections', listToSet);
  };
  const handleAddSection = useCallback(async () => {
    setFormActionMode('SECTION_ADD');
    const sortIndex = formSections.length || 0;

    handleAddFormSection({ formId, stepId: currentStep.id, sortIndex });
  }, [formSections, currentStep.id]);

  const handleCancelSort = () => {
    setValue('data.sections', formSections);
    setFormActionMode('INITIAL');
  };

  const handleSubmitSort = async () => {
    if (actionMode === 'SECTION_SORT') {
      const sections = getValues('data.sections');
      handleEditFormSectionsSort(sections);
    }
  };

  const SectionItems = useCallback(
    () => (
      <Controller
        control={control}
        name='data.sections'
        render={({ field }) => (
          <>
            {field.value && field.value.length > 0 && (
              <ReactSortable
                animation={200}
                list={field.value}
                setList={handleSort}
                handle='.section_sort_handle'
              >
                {field.value.map((section, index) => (
                  <FormItemSection
                    key={section.id}
                    section={section}
                    index={index}
                    sx={{
                      mb: index < field.value.length - 1 ? '1rem' : undefined,
                    }}
                  />
                ))}
              </ReactSortable>
            )}
          </>
        )}
      />
    ),
    [control, handleSort]
  );

  const SectionActions = useCallback(
    () => (
      <>
        {actionMode !== 'SECTION_SORT' && (
          <AddSectionLoadingButton
            disabled={!isInInitialMode}
            loading={loading && actionMode === 'SECTION_ADD'}
            onClick={handleAddSection}
          />
        )}
        {actionMode !== 'SECTION_SORT' && formSections.length > 1 && (
          <SortButton disabled={!isInInitialMode} onClick={handleStartSort} />
        )}
        {actionMode === 'SECTION_SORT' && (
          <CancelButton onClick={handleCancelSort} />
        )}
        {actionMode === 'SECTION_SORT' && (
          <SaveLoadingButton
            loading={loading && actionMode === 'SECTION_SORT'}
            onClick={handleSubmitSort}
          />
        )}
      </>
    ),
    [loading, actionMode, formSections]
  );

  // Render
  return (
    <ColumnStack>
      <SectionItems />
      <RowStack>
        <SectionActions />
      </RowStack>
    </ColumnStack>
  );
};

export default FormItemSections;

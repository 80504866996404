import * as React from 'react';
import { useParams } from 'react-router-dom';

// Custom HOC
import WithAuth from 'core/components/shared/HOC/WithAuth';

// Custom Hooks
import useUnMount from 'core/hooks/useUnMount';

// Custom Common Components
import PageHeroBox from 'core/components/shared/Box/PageHero';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';

// Custom Feature Components
import MenuContainer from 'features/menu/menus/components/details/Container';
import MenuList from 'features/menu/menus/components/details/List';

// Context
import MenuModeProvider from 'core/store/context/feature/menu/MenuMode';
import {
  resetMenuState,
  setMenuContainer,
  useSelectMenuContainerTitle,
} from 'core/store/slices/feature/menu';

// Custom Utilities
import { useMenuDoc } from 'features/menu/menus/hooks';

// Custom Types

interface MenuDetailsProps {}

const MenuDetailsPage: React.FC<MenuDetailsProps> = (props) => {
  // Context
  const containerTitle = useSelectMenuContainerTitle();

  // Hooks
  const params = useParams();
  const isEditMode = 'id' in params;
  const methods = useMenuDoc({
    onFetch: setMenuContainer,
    alertOnFetchError: { message: 'دریافت اطلاعات فهرست با خطا مواجه شد.' },
    redirectAfterErrorTo: '/menus',
  });

  useUnMount(() => resetMenuState());

  // Render
  return (
    <MenuModeProvider mode={isEditMode ? 'edit' : 'create'}>
      <PageHeadConfigs pageTitle='منوها' />
      <PageHeroBox width='100%'>
        <HeadingSix>{containerTitle || 'عنوان فهرست'}</HeadingSix>
      </PageHeroBox>
      <ColumnStack>
        <MenuContainer />
        {containerTitle && <MenuList />}
      </ColumnStack>
    </MenuModeProvider>
  );
};

export default WithAuth(MenuDetailsPage);

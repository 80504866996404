// Core Utilities
import apiHandler from 'core/utilities/apiHandler';

// Feature Utilities
import { PatternsEndpoints } from 'features/appBuilder/patterns/core/utilities/api/endpoints';
import { bakePatternBody } from 'features/appBuilder/patterns/core/utilities/api/baker';

// Feature Types
import type { PatternData } from 'features/appBuilder/patterns/core/types/item';
import { isSucceed } from 'core/utilities/helper';

/**
 * Creates a new pattern based on the provided pattern data.
 *
 * @param {PatternData} pattern - The data of the pattern to be created.
 * @returns {Promise<{ status: number; message: string }>} A promise that resolves to an object containing
 * the status code and a message indicating the result of the creation operation.
 */
export const createPattern = async (
  pattern: PatternData
): Promise<{ status: number; message: string }> => {
  let type = pattern.type;
  let body = bakePatternBody(pattern);

  const { status } = await apiHandler.post(PatternsEndpoints.add(type), body);

  return {
    status,
    message: isSucceed(status)
      ? 'پترن جدید ساخته شد.'
      : 'ساخت پترن جدید با خطا مواجه شد.',
  };
};

// Custom Utilities
import { forLoop } from 'core/utilities/helper';

// Custom Types
import type { FormInput } from 'features/form/forms/types/item/input';

/**
 * Calculates the count of inputs grouped by their section IDs.
 *
 * This function takes an array of form inputs and returns an object where each key
 * is a section ID and the value is the count of inputs belonging to that section.
 *
 * @param {FormInput[]} inputs - An array of form inputs, each containing a `data` property with a `sectionId`.
 * @returns {{ [key: string]: number }} An object mapping section IDs to the count of inputs within each section.
 *
 * @example
 * const inputs = [
 *   { data: { sectionId: 'section1' } },
 *   { data: { sectionId: 'section1' } },
 *   { data: { sectionId: 'section2' } }
 * ];
 *  * const counts = getSectionInputsCount(inputs);
 * // counts will be { section1: 2, section2: 1 }
 */
export const getSectionInputsCount = (inputs: FormInput[]) => {
  const sectionInputsCount: { [key: string]: number } = {};

  forLoop(inputs, (inp) => {
    if (sectionInputsCount[inp.data.sectionId] !== undefined) {
      sectionInputsCount[inp.data.sectionId] =
        sectionInputsCount[inp.data.sectionId] + 1;
    } else {
      sectionInputsCount[inp.data.sectionId] = 1;
    }
  });

  return sectionInputsCount;
};

/**
 * Creates a mapping of input IDs to their respective indexes in the input array.
 *
 * This function takes an array of form inputs and returns an object where each key
 * is the input ID and the value is the index of that input in the original array.
 *
 * @param {FormInput[]} inputs - An array of form inputs, each containing an `id`.
 * @returns {{ [key: string]: number }} An object mapping input IDs to their indexes.
 *
 * @example
 * const inputs = [
 *   { id: 'input1' },
 *   { id: 'input2' },
 *   { id: 'input3' }
 * ];
 * const indexMap = getInputsIndexMap(inputs);
 * // indexMap will be { input1: 0, input2: 1, input3: 2 }
 */
export const getInputsIndexMap = (inputs: FormInput[]) => {
  let indexMap: { [key: string]: number } = {};

  forLoop(inputs, (inp, index) => (indexMap[inp.id] = index));

  return indexMap;
};

import * as React from 'react';

// Custom Core Components
import ButtonLink, { type ButtonLinkProps } from './ButtonLink';

// Custom Types
interface LogOutProps extends ButtonLinkProps {}

const LogoutButton: React.FC<LogOutProps> = (props) => {
  // Props
  const {
    href,
    sx,
    color = 'error',
    variant = 'outlined',
    ...otherProps
  } = props;

  return (
    <ButtonLink
      href='/logout'
      sx={{
        maxHeight: '2rem',
        minWidth: '4.75rem',
        ...sx,
      }}
      color={color}
      variant={variant}
      {...otherProps}
    >
      خروج
    </ButtonLink>
  );
};

export default LogoutButton;

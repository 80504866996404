import * as React from 'react';

// Components
import BodyOne from 'core/components/shared/Typography/BodyOne';

interface EmptyCellProps {}

const EmptyCell: React.FC<EmptyCellProps> = (props) => {
  return (
    <BodyOne dir='ltr' textAlign='center' width='100%'>
      -
    </BodyOne>
  );
};

export default EmptyCell;

import { useNavigate, useParams } from 'react-router-dom';

// Custom Hooks
import useMount from 'core/hooks/useMount';
import useAppQuery from 'core/hooks/useAppQuery';
import useUpdateEffect from 'core/hooks/useUpdateEffect';

// Custom Utilities
import { isSucceed, setAppAlert } from 'core/utilities/helper';

// Custom Types
import type { DefinedUseQueryResult } from '@tanstack/react-query';
import type {
  AlertProps,
  UseAppQueryOptionsProps,
} from 'core/hooks/useAppQuery';

export type UseDocApiReturnProps<T> = DefinedUseQueryResult<T | null, Error>;

export type UseDocOptionType<T> = Omit<
  UseAppQueryOptionsProps<T | null>,
  'queryFn' | 'initialData' | 'queryKey' | 'alertOnFetchEmptyList' | 'onFetch'
> & {
  id?: string;
  initialData?: T | null;
  onFetch?: (doc: T) => void;
  /**
   * The URL to redirect to if the document ID is empty.
   * @type {-1 | string}
   */
  redirectAfterDocumentIdIsEmptyTo?: -1 | string;
  /**
   * Alert properties to display when the document ID is empty.
   * @type {AlertProps}
   */
  alertOnDocumentIdIsEmpty?: AlertProps;
};

function useDocApi<T>(
  queryFn: (id: string, signal: AbortSignal) => any,
  queryKey: string[],
  options: UseDocOptionType<T> = {}
): UseDocApiReturnProps<T> {
  // Props
  const { id, onFetch, ...otherOptions } = options;

  // Hooks
  const navigate = useNavigate();
  const params = useParams();
  const dataId = id
    ? id
    : params && 'id' in params && typeof params.id === 'string'
    ? params.id
    : '';

  const query = useAppQuery<T | null>({
    select: (data: any) => data?.doc || null,
    ...otherOptions,
    initialData: null,
    enabled:
      'enabled' in options
        ? options.enabled
        : dataId && dataId.length > 0
        ? true
        : false,
    queryKey: [...queryKey, dataId],
    queryFn: ({ signal }) => {
      return new Promise(async (resolve, reject) => {
        const response = await queryFn(dataId, signal);
        const status = response?.status || 502;
        if (isSucceed(status)) return resolve(response);
        else reject('دریافت اطلاعات با خطا مواجه شد');
      });
    },
    onFetch: (data) => {
      if (onFetch && data) onFetch(data);
    },
  });

  useMount(() => {
    if (dataId) return;
    if (options.alertOnDocumentIdIsEmpty) {
      setAppAlert(
        options.alertOnDocumentIdIsEmpty.message,
        options.alertOnDocumentIdIsEmpty?.severity || 'error'
      );
    }
    if (typeof options?.redirectAfterDocumentIdIsEmptyTo !== 'undefined') {
      setTimeout(
        () => navigate(options.redirectAfterDocumentIdIsEmptyTo as any),
        2500
      );
    }
  });

  useMount(() => {
    if (onFetch && query.data) onFetch(query?.data);
  });

  useUpdateEffect(() => {
    if (onFetch && query.data) onFetch(query?.data);
  }, [query.data]);

  // Utilities
  return query;
}

export default useDocApi;

import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Custom Core Components
import Checkbox from 'core/components/base/inputs/Checkbox';
import Table from 'core/components/base/display/Table';
import TableRow from 'core/components/base/display/TableRow';
import TableBody from 'core/components/base/display/TableBody';
import TableCell from 'core/components/base/display/TableCell';
import TableHead from 'core/components/base/display/TableHead';
import TableContainer from 'core/components/base/display/TableContainer';
import TableContainerPaper from 'core/components/shared/Paper/TableContainerPaper';

// Custom Common Components
import ProcessesDataGridItem from './DataGridItem';

// Core Utilities
import { tableScrollbarStyles } from 'core/utilities/styles/customStyles';
import { useSelectionContext } from 'core/store/context/common/UseSelection';

// Context
import { useDocsContext } from 'core/hooks/api/useDocs/Context';

// Custom Types
import type { ProcessSummaryProps } from 'features/form/processes/types/list';

interface ProcessesDataGridProps {}

const ProcessesDataGrid: React.FC<ProcessesDataGridProps> = (props) => {
  // Context
  const {
    isFetching,
    data: { list = [] },
  } = useDocsContext<ProcessSummaryProps>();

  // Hooks
  const { palette } = useTheme();

  // Utilities
  const selections = useSelectionContext();

  // Render
  return (
    <TableContainer
      sx={{ paddingBottom: '1rem', ...tableScrollbarStyles }}
      component={TableContainerPaper}
    >
      <Table>
        <TableHead>
          <TableRow sx={{ borderBottom: `1px solid ${palette.divider}` }}>
            <TableCell padding='checkbox'>
              <Checkbox
                size='small'
                disabled={list.length === 0}
                onClick={() => selections.onToggleAll(list)}
                checked={selections.isAllSelected(list)}
                indeterminate={selections.isIndeterminate(list)}
                sx={{ py: 0 }}
              />
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>عنوان فرایند</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'start' }}>
              مراحل فرایند
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isFetching && list.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4}>در حال دریافت فرایندها...</TableCell>
            </TableRow>
          ) : list.length > 0 ? (
            list.map((process) => (
              <ProcessesDataGridItem process={process} key={process.id} />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>فرایندی جهت نمایش وجود ندارد.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProcessesDataGrid;

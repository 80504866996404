import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import Box from 'core/components/base/layout/Box';
import CloseIconButton from 'core/components/shared/IconButton/Close';
import TextFieldNumber from 'core/components/shared/TextField/number';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import ColorPicker from 'core/components/shared/Picker/Color';
import PatternImageDisplayTypeDropdown from 'features/appBuilder/patterns/core/components/shared/dropdown/DisplayType';

// Core Utilities
import useBoolean from 'core/hooks/useBoolean';

// Feature Types
import { ContentDetailsProps } from 'features/content/contents/types/details';
import PatternBgTypeDropdown from 'features/appBuilder/patterns/core/components/shared/patternBackground/PatternBgTypeDropdown';
import IconButton from 'core/components/base/inputs/IconButton';
import FormatAlignLeft from 'core/components/icons/FormatAlignLeft';
import FormatAlignCenter from 'core/components/icons/FormatAlignCenter';
import FormatAlignRight from 'core/components/icons/FormatAlignRight';

// Component Types
interface ContentDetailsGalleryStylesProps {
  index?: number;
  open?: boolean;
  onClose?: () => void;
}

const ContentDetailsGalleryStyles: FC<ContentDetailsGalleryStylesProps> = (
  props
) => {
  // Props
  const { index = 0, open = false, onClose } = props;

  // Hooks
  const { control, getValues, setValue } =
    useFormContext<ContentDetailsProps>();

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: open ? 0 : '110%',
        right: 0,
        bottom: 0,
        bgcolor: open ? 'rgba(255, 255, 255, 0.4)' : 'rgba(255, 255, 255, 0.9)',
        zIndex: open ? 10 : -10,
        opacity: open ? 1 : 0,
      }}
    >
      <Box
        sx={({ breakpoints }) => ({
          bgcolor: 'background.default',
          p: 2,
          gap: 1,
          display: 'flex',
          flexDirection: 'column',
          transition: 'all 150ms',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: open ? '35%' : '110%',
          [breakpoints.up('xl')]: {
            left: open ? '60%' : '110%',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <BodyTwo color='text.secondary'>تنظیمات گالری</BodyTwo>
          <CloseIconButton
            size='small'
            sx={{ color: 'text.secondary' }}
            onClick={onClose}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <ColumnStack spacing={0} sx={{ flexGrow: 1, minWidth: '15rem' }}>
            <BodyTwo color='text.secondary'>نوع نمایش</BodyTwo>
            <Controller
              control={control}
              name={`data.blocks.${index}.data.galleryStyles`}
              render={({ field }) => (
                <PatternImageDisplayTypeDropdown
                  onChange={() => {
                    const displayType = getValues(
                      `data.blocks.${index}.data.galleryStyles.displayType`
                    );

                    if (displayType === 'default') {
                      setValue(
                        `data.blocks.${index}.data.galleryStyles.width`,
                        180
                      );
                      setValue(
                        `data.blocks.${index}.data.galleryStyles.height`,
                        180
                      );
                    }
                  }}
                  fullWidth
                  reference={field.name}
                  sx={{ height: '40px' }}
                />
              )}
            />
          </ColumnStack>
          <Controller
            control={control}
            name={`data.blocks.${index}.data.galleryStyles.displayType`}
            render={({ field: { value: displayType } }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <ColumnStack spacing={0}>
                  <BodyTwo color='text.secondary'>عرض</BodyTwo>
                  <Controller
                    control={control}
                    name={`data.blocks.${index}.data.galleryStyles.width`}
                    render={({ field }) => (
                      <TextFieldNumber
                        disabled={displayType === 'default'}
                        InputProps={{
                          sx: { height: '2.5rem', maxWidth: '6rem' },
                        }}
                        {...field}
                      />
                    )}
                  />
                </ColumnStack>
                <ColumnStack spacing={0}>
                  <BodyTwo color='text.secondary'>ارتفاع</BodyTwo>
                  <Controller
                    control={control}
                    name={`data.blocks.${index}.data.galleryStyles.height`}
                    render={({ field }) => (
                      <TextFieldNumber
                        disabled={displayType === 'default'}
                        InputProps={{
                          sx: { height: '2.5rem', maxWidth: '6rem' },
                        }}
                        {...field}
                      />
                    )}
                  />
                </ColumnStack>
              </Box>
            )}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <ColumnStack spacing={0} sx={{ flexGrow: 1, minWidth: '13rem' }}>
            <BodyTwo color='text.secondary'>تناسب عکس</BodyTwo>
            <Controller
              control={control}
              name={`data.blocks.${index}.data.galleryStyles.objectFit`}
              render={({ field }) => (
                <PatternBgTypeDropdown
                  disableTile
                  keyName={field.name}
                  defaultValue={field.value}
                  sx={{ width: '100%', height: '40px' }}
                />
              )}
            />
          </ColumnStack>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <ColumnStack spacing={0}>
              <BodyTwo color='text.secondary'>انحنا</BodyTwo>
              <Controller
                control={control}
                name={`data.blocks.${index}.data.galleryStyles.borderRadius`}
                render={({ field }) => (
                  <TextFieldNumber
                    InputProps={{ sx: { height: '2.5rem', maxWidth: '6rem' } }}
                    {...field}
                  />
                )}
              />
            </ColumnStack>
            <ColumnStack spacing={0}>
              <BodyTwo color='text.secondary'>چیدمان گالری</BodyTwo>
              <Controller
                control={control}
                name={`data.blocks.${index}.data.galleryStyles.align`}
                render={({ field }) => (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {(
                      ['right', 'center', 'left'] as (
                        | 'right'
                        | 'center'
                        | 'left'
                      )[]
                    ).map((alignItem) => (
                      <IconButton
                        key={`block-gallery-styles-align-${alignItem}`}
                        color={field.value === alignItem ? 'info' : 'inherit'}
                        onClick={() => setValue(field.name, alignItem)}
                      >
                        {alignItem === 'left' ? (
                          <FormatAlignLeft />
                        ) : alignItem === 'center' ? (
                          <FormatAlignCenter />
                        ) : (
                          <FormatAlignRight />
                        )}
                      </IconButton>
                    ))}
                  </Box>
                )}
              />
            </ColumnStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContentDetailsGalleryStyles;

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// HOC
import WithAuth from 'core/components/shared/HOC/WithAuth';

// Core Components
import Box from 'core/components/base/layout/Box';
import LoadingButton from 'core/components/base/inputs/LoadingButton';
import Paper from 'core/components/base/surfaces/Paper';
import Slide from 'core/components/base/utils/Slide';
import TextField from 'core/components/base/inputs/TextField';

// Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import RouteLink from 'core/components/shared/Link/RouteLink';
import CenterBox from 'core/components/shared/Box/CenterBox';
import HeadingOne from 'core/components/shared/Typography/HeadingOne';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import ButtonLink from 'core/components/shared/Button/ButtonLink';

// Utilities
import { updatePassowrd } from 'features/manager/auth/utilities/api/login';
import { setAppAlert, setAppLoading } from 'core/utilities/helper';
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// Validation Schema
import PasswordAndRepeatValidationSchema from 'features/manager/auth/validations/PasswordAndRepeat';

// Custom Types

import type { WithAuthProps } from 'core/components/shared/HOC/WithAuth';
interface PasswordRecoveryProps extends WithAuthProps {}
interface PasswordRecoveryFormProps {
  password: string;
  passwordRepeat: string;
}

const filePath = '/components/pages/PasswordRecovery.tsx';
const PasswordRecovery: React.FC<PasswordRecoveryProps> = (props) => {
  // States
  const [tempToken, setTempToken] = React.useState<string>('');
  const [mode, setMode] = React.useState<'password' | 'updated'>('password');
  // Hooks
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const loading = useSelectLoading();
  const pageWrapperRef = React.useRef(null);

  const resolver = useYupValidationResolver(PasswordAndRepeatValidationSchema);

  const { control, handleSubmit, watch } = useForm<PasswordRecoveryFormProps>({
    mode: 'onTouched',
    resolver,
    defaultValues: {
      password: '',
      passwordRepeat: '',
    },
  });
  const state = watch();

  React.useEffect(() => {
    const hasToken = searchParams.has('token');

    if (hasToken) {
      const token = searchParams.get('token');

      if (!token) return navigate('/login');
      setTempToken(token);
    }
    if (!hasToken) return navigate('/login');
  }, [searchParams]);

  // Utilities
  const onSubmit: SubmitHandler<PasswordRecoveryFormProps> = async ({
    password,
    passwordRepeat,
  }) => {
    if (!tempToken) return navigate('/login');
    setAppLoading(true);

    if (password !== passwordRepeat) {
      setAppAlert('کلمه عبور وارد شده با تکرار آن مطابقت ندارد.', 'warning');
      setAppLoading(false);
      return;
    }

    const resultStatusCode = await updatePassowrd(tempToken, password);

    if (resultStatusCode === 200) {
      setMode('updated');
    } else if (resultStatusCode === 404) {
      setAppAlert('لینک شما برای بازیابی کلمه عبور نامعتبر است.');
    } else if (resultStatusCode === 422) {
      setAppAlert('لینک شما برای بازیابی کلمه عبور منقضی شده است.');
    } else if (resultStatusCode === 411) {
      setAppAlert('لینک شما برای بازیابی کلمه عبور قبلاً استفاده شده است.');
    }

    setAppLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>بازیابی کلمه عبور</title>
      </Helmet>
      <CenterBox
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        innerRef={pageWrapperRef}
      >
        <Slide container={pageWrapperRef.current} direction='left' in={true}>
          <Paper sx={{ borderRadius: '1rem', padding: '3rem 6rem' }}>
            {mode === 'password' ? (
              <ColumnStack spacing={3}>
                <Box>
                  <HeadingOne gutterBottom textAlign='center' variant='h6'>
                    بازیابی کلمه عبور
                  </HeadingOne>
                  <BodyOne textAlign='center'>
                    کلمه عبور جدید را وارد کنید.
                  </BodyOne>
                </Box>
                <ColumnStack spacing={1}>
                  <Box>
                    <InputLabel>کلمه عبور</InputLabel>
                    <Controller
                      control={control}
                      name='password'
                      render={({ field, fieldState }) => (
                        <TextField
                          error={
                            fieldState.isTouched && fieldState.error?.message
                              ? true
                              : false
                          }
                          dir='ltr'
                          disabled={loading || !tempToken}
                          fullWidth
                          helperText={
                            fieldState.isTouched && fieldState.error?.message
                          }
                          placeholder='Password'
                          type='password'
                          {...field}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <InputLabel>تکرار کلمه عبور</InputLabel>
                    <Controller
                      control={control}
                      defaultValue=''
                      name='passwordRepeat'
                      render={({ field, fieldState }) => (
                        <TextField
                          error={
                            fieldState.isTouched && fieldState.error?.message
                              ? true
                              : false
                          }
                          dir='ltr'
                          disabled={loading || !tempToken}
                          fullWidth
                          helperText={
                            fieldState.isTouched && fieldState.error?.message
                          }
                          placeholder='Password Repeat'
                          type='password'
                          {...field}
                        />
                      )}
                    />
                  </Box>
                </ColumnStack>
                <ColumnStack spacing={1}>
                  <LoadingButton
                    disabled={
                      !tempToken || !state.password || !state.passwordRepeat
                    }
                    fullWidth
                    loading={loading}
                    sx={{ height: '3rem', width: '21rem' }}
                    type='submit'
                  >
                    تأیید
                  </LoadingButton>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <RouteLink
                      href='/login'
                      sx={({ palette }) => ({
                        ':hover': {
                          color: palette.info.light,
                        },
                      })}
                    >
                      بازگشت
                    </RouteLink>
                  </Box>
                </ColumnStack>
              </ColumnStack>
            ) : (
              <ColumnStack spacing={2}>
                <Box>
                  <HeadingOne textAlign='center'>✔</HeadingOne>
                  <BodyOne textAlign='center'>
                    کلمه عبور شما با موفقیت بازیابی شد.
                  </BodyOne>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <ButtonLink href='/login' color='info'>
                    ورود به حساب کاربری
                  </ButtonLink>
                </Box>
              </ColumnStack>
            )}
          </Paper>
        </Slide>
      </CenterBox>
    </>
  );
};

export default WithAuth(PasswordRecovery, { filePath });

import { useState, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Core Components
import MenuItem from 'core/components/base/navigation/MenuItem';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Common Components
import Select, { type SelectProps } from 'core/components/shared/Select';
import CircularProgress from 'core/components/base/feedback/CircularProgress';
import Box from 'core/components/base/layout/Box';

// Custom Types
export type SourceDropdownSelectOption = {
  id: string;
  data: { title: string };
};
export interface PatternRefTypeDropdownProps
  extends Omit<SelectProps, 'defaultValue'> {
  reference: string;
  loading?: boolean;
  keyName?: string;
  defaultValue?: string;
  defaultValueTitle?: string;
  opts: SourceDropdownSelectOption[];
}

const PatternSourceDropdown: FC<PatternRefTypeDropdownProps> = (props) => {
  // Props
  const {
    reference,
    keyName = 'sourceId',
    defaultValue = '',
    defaultValueTitle = '',
    loading,
    opts,
    sx,
    ...otherProps
  } = props;

  // Hooks
  const { control, setValue } = useFormContext();

  // Helpers
  const Loading = () => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: '0.25rem 0.75rem',
      }}
    >
      <CircularProgress color='inherit' size={20} />
    </Box>
  );

  return (
    <Controller
      control={control}
      name={`${reference}.${keyName}`}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Select
          disabled={opts.length === 0}
          sx={{ height: '48px', ...sx }}
          size='small'
          IconComponent={loading ? Loading : undefined}
          renderValue={(value) => {
            let label = '';

            if (value && typeof value === 'string') {
              const opt = opts.find((v) => v.id === value);

              if (opt) {
                label = opt.data.title;
              }
            }

            return <BodyTwo>{label}</BodyTwo>;
          }}
          {...field}
        >
          {opts.map((opt) => (
            <MenuItem
              key={opt.id + opt.data.title}
              value={opt.id}
              onClick={() => {
                setValue(field.name, opt.id);
                setValue(`${reference}.sourceTitle`, opt.data.title);
              }}
            >
              {opt.data.title}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

export default PatternSourceDropdown;

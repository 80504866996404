import * as React from 'react';

// Custom Core Components
import Paper from 'core/components/base/surfaces/Paper';
import Button from 'core/components/base/inputs/Button';
import Switch from 'core/components/base/inputs/Switch';
import Checkbox from 'core/components/base/inputs/Checkbox';
import IconButton from 'core/components/base/inputs/IconButton';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import DragIconButton from 'core/components/shared/IconButton/Drag';

// Custom Icons
import ArrowDropDownIcon from 'core/components/icons/ArrowDropDown';
import EditPenIcon from 'core/components/icons/EditPen';
import { useSelectLoading } from 'core/store/slices/core/shared/loading/selector';

// Context
import {
  setInEdit,
  setMenuActionMode,
  toggleDeleteMenu,
  toggleIsActive,
  useSelectInEditItem,
  useSelectIsMenuSelectedForDeletion,
  useSelectMenuActionMode,
} from 'core/store/slices/feature/menu';

// Custom Types
import { useMenuItemContext } from 'core/store/context/feature/menu/MenuItem';
import { isSame } from 'core/utilities/helper/helperPack';

interface MenuItemPreviewProps {
  mode: 'edit' | 'preview' | null;
  handleClassName: string;
  hasSubItems: boolean;
  onToggleShowSubItems: () => void;
  onToggle: (value?: 'edit' | 'preview' | undefined) => void;
}

const MenuItemPreview: React.FC<MenuItemPreviewProps> = (props) => {
  // Props
  const { mode, handleClassName, hasSubItems, onToggleShowSubItems, onToggle } =
    props;

  // Context
  const menu = useMenuItemContext();
  const loading = useSelectLoading();
  const actionMode = useSelectMenuActionMode();
  const { selected, parentSelected } = useSelectIsMenuSelectedForDeletion(menu);
  const inEditItem = useSelectInEditItem();

  // Utilities
  const handleEdit = () => {
    onToggle('edit');
    setMenuActionMode('EDIT');
    setInEdit(menu.id);
  };

  // Render
  return (
    <Paper
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        boxShadow: 'none',
        alignItems: 'center',
        padding: '1rem 2rem 1rem 1rem',
        borderRadius: mode === 'edit' ? '0.625rem 0.625rem 0 0' : '0.625rem',
        background: mode === 'edit' ? '#DDE5E8' : 'inherit',
        transition: 'all .3s',
      }}
    >
      <RowStack spacing='.5rem' alignItems='center'>
        <DragIconButton className={handleClassName} />
        <IconButton
          disabled={
            loading ||
            !hasSubItems ||
            actionMode !== 'NORMAL' ||
            inEditItem.length > 0
          }
          size='small'
          onClick={() => onToggleShowSubItems()}
        >
          <ArrowDropDownIcon
            sx={{
              rotate: mode === 'edit' ? '0' : '90deg',
              transition: 'rotate .3s',
            }}
          />
        </IconButton>
        <BodyTwo>{menu.data.title}</BodyTwo>
      </RowStack>
      {actionMode === 'DELETE' ? (
        <Checkbox
          size='small'
          color='info'
          edge='end'
          disabled={loading || parentSelected}
          checked={selected || parentSelected || false}
          onChange={() => toggleDeleteMenu(menu.id)}
        />
      ) : (
        <RowStack alignItems='center' spacing={4}>
          <Switch
            size='small'
            color='info'
            disabled={!['NORMAL'].includes(actionMode) || loading}
            checked={menu.data.isActive}
            onChange={(e, checked) => toggleIsActive(menu.id, checked)}
          />
          <Button
            disabled={
              mode === 'edit' ||
              !['EDIT', 'NORMAL'].includes(actionMode) ||
              loading ||
              inEditItem.length > 0
            }
            onClick={handleEdit}
            variant='contained'
            color='info'
            size='small'
            sx={{
              minWidth: '20px',
              maxWidth: '20px',
              minHeight: '20px',
              maxHeight: '20px',
            }}
          >
            <EditPenIcon sx={{ fontSize: '1rem' }} />
          </Button>
        </RowStack>
      )}
    </Paper>
  );
};

export default React.memo(MenuItemPreview, (prev, next) =>
  isSame([prev.handleClassName, prev.mode], [next.handleClassName, next.mode])
);

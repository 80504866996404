import * as React from 'react';

// Custom Hooks
import { useSelectHiddenProcessSections } from 'features/form/feedbacks/store';

// Custom Core Components
import TableCell from 'core/components/base/display/TableCell';

// Custom Common Components
import newArray from 'core/utilities/helper/newArray';

// Custom Utilites
import { excelTableCommonCellStyles } from 'features/form/feedbacks/utilities/details/styles';
import { calculateProcessTableHeaderCells } from 'features/form/feedbacks/utilities/details/helper';

// Custom Types
import type { FeedbackProps } from 'features/form/feedbacks/types';

// Custom Types
interface ExcelTableHeaderProcessCellsProps {
  feedbacks: FeedbackProps[];
}

const ExcelTableHeaderProcessCells: React.FC<
  ExcelTableHeaderProcessCellsProps
> = (props) => {
  // Props
  const { feedbacks } = props;

  // Context
  const hiddenProcessSections = useSelectHiddenProcessSections();

  // Hooks
  const handleCalculateProcessTableHeaderCells = React.useCallback(
    () => calculateProcessTableHeaderCells(hiddenProcessSections, feedbacks),
    [hiddenProcessSections, feedbacks]
  );

  // Render
  return (
    <>
      {newArray(handleCalculateProcessTableHeaderCells()).map((_, index) => (
        <TableCell
          {...excelTableCommonCellStyles}
          key={`process-header-row-${index}`}
        >
          رکورد {index + 1}
        </TableCell>
      ))}
    </>
  );
};

export default ExcelTableHeaderProcessCells;

import * as React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';

// Custom Hooks
import { useAppSelector } from 'core/hooks/redux';

// Types
import type { ReactNode } from 'react';

// Themes
import darkTheme from 'core/styles/theme/darkTheme';
import lightTheme from 'core/styles/theme/lightTheme';

// Context Provider
import DialogProvider from './context/DialogProvider';

interface ThemeProviderProps {
  children?: ReactNode;
}

const GlobalThemeProvider: React.FC<ThemeProviderProps> = (props) => {
  const { children } = props;
  const themeState = useAppSelector((state) => state.theme) || {
    themeMode: 'light',
  };

  return (
    <ThemeProvider
      theme={themeState.themeMode === 'light' ? lightTheme : darkTheme}
    >
      <CssBaseline />
      <DialogProvider>{children}</DialogProvider>
    </ThemeProvider>
  );
};

export default GlobalThemeProvider;

import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Cusstom Core Components
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface AttachFileIconProps extends SvgIconProps {}

const AttachFileIcon: React.FC<AttachFileIconProps> = (props) => {
  return (
    <IconBuilder {...props}>
      <path
        d='M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10C22,6.5,17.5,2,12,2z M16.9,11.8c-0.6,0.7-3.5,4.3-4.2,5
	c-0.7,0.7-1.4,1-2.2,1c-1.1,0-2-0.6-2.3-0.9C7,16,6,14.3,7.4,12.5c1.1-1.4,3.2-4.1,4.4-5.5c0.5-0.6,1.6-1.9,3.6-0.4
	c1.3,1,1.5,2.1,0.6,3.4c-0.9,1.2-3.8,4.5-3.8,4.5c-0.3,0.4-1.2,1.1-2.2,0.4c-1.3-1-0.5-2.2-0.5-2.2l3.2-3.9l0.4,0.3l0,0l-3.2,3.8
	c0,0-0.6,0.8,0.4,1.6c0.8,0.6,1.4-0.2,1.5-0.3l0,0c0.7-0.8,3-3.4,3.7-4.5c0.5-0.7,1-1.7-0.5-2.7c-1.6-1.2-2.5-0.2-2.9,0.4
	c-1.1,1.3-3.3,4-4.4,5.5c-0.5,0.7-0.7,1.4-0.5,2.2c0.2,0.6,0.6,1.2,1.2,1.6c0.5,0.4,2.3,1.6,3.9-0.1c0.7-0.8,4.1-5,4.2-5l0,0
	L16.9,11.8L16.9,11.8z'
      />
    </IconBuilder>
  );
};

export default AttachFileIcon;

// Components
import AddAudienceFolder from 'features/audience/folders/components/details/Add';
import AudienceFolders from 'features/audience/folders/components/list';
import EditAudienceFolder from 'features/audience/folders/components/details/Edit';

// Custom Utilities
import { getSubFeatureLabel } from 'core/utilities/feature';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type {
  FeatureType,
  SubFeatureType,
} from 'core/types/feature/FeatureType';

const feature: FeatureType = 'audience';
const subFeature: SubFeatureType = 'audienceFolders';
const audienceFoldersRoutes: ProtectedRouteProps[] = [
  {
    sidebarTitle: getSubFeatureLabel(subFeature),
    path: '/audience-folders',
    Component: AudienceFolders,
    feature,
    subFeature,
  },
  {
    path: '/audience-folders/add',
    Component: AddAudienceFolder,
    feature,
    subFeature,
  },
  {
    path: '/audience-folders/edit',
    Component: EditAudienceFolder,
    feature,
    subFeature,
  },
];

export default audienceFoldersRoutes;

import { type FC } from 'react';
import { Helmet } from 'react-helmet-async';

// Custom Types
interface PageHeadConfigsProps {
  pageTitle?: string;
}

const PageHeadConfigs: FC<PageHeadConfigsProps> = (props) => {
  // Props
  const { pageTitle } = props;

  // Render
  return (
    <Helmet>
      <title>{pageTitle ? pageTitle : 'اپوا - سی‌ام‌اس'}</title>
    </Helmet>
  );
};

export default PageHeadConfigs;

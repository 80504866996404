import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Types
import type { FC } from 'react';

// Custom Hooks
import useMount from 'core/hooks/useMount';
import useSelection from 'core/hooks/common/useSelection';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import RouteLink from 'core/components/shared/Link/RouteLink';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import BodyOne from 'core/components/shared/Typography/BodyOne';
import ButtonLink from 'core/components/shared/Button/ButtonLink';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import PageTitle from 'core/components/shared/Typography/PageTitle';
import DeleteLoadingButton from 'core/components/shared/LoadingButton/Delete';

// Custom Core Components
import Breadcrumbs from 'core/components/base/navigation/Breadcrumbs';
import LoadingButton from 'core/components/base/inputs/LoadingButton';

// Feature Components
import PatternListsDataGrid from 'features/appBuilder/patterns/components/list/PatternsListDataGrid';

// Custom Icon Components
import PlusCircleIcon from 'core/components/icons/PlusCircle';
import DuplicateIcon from 'core/components/icons/Duplicate';

// Feature Utilities
import { usePatternsOfDoc } from 'features/appBuilder/patterns/hooks';
import {
  useDeletePatternsMutation,
  useDuplicatePatternMutation,
} from 'features/appBuilder/patterns/hooks';
import {
  getPatternTitle,
  isPatternTypeValid,
} from 'features/appBuilder/patterns/core/utilities/helper/type';

// Context
import { useDialogContext } from 'core/store/context/DialogProvider';

// Custom Types
import type { PatternType } from 'features/appBuilder/patterns/core/types/item';

interface PatternListPageProps {}

const PatternList: FC<PatternListPageProps> = (props) => {
  // States
  const [type, setType] = useState<PatternType | ''>('');

  // Context
  const { onOpenDialog, onCloseDialog } = useDialogContext();

  // Hooks
  const navigate = useNavigate();
  const { selections, onSelectionsChange, onClearSelections } = useSelection();
  const { patternType } = useParams();
  const { data: items = [] } = usePatternsOfDoc({
    id: type,
    select: (doc: any) => doc?.list || [],
    enabled: typeof type === 'string' && type.length > 0,
  });

  const { mutate: handleDuplicatePattern } = useDuplicatePatternMutation({
    customAlert: {
      successMessage: 'کپی پترن انتخاب شده با موفقیت ایجاد شد.',
      errorMessage: 'کپی پترن با خطا مواجه شد.',
    },
    onSuccess: () => onClearSelections(),
  });
  const { mutate: handleDeletePatterns } = useDeletePatternsMutation({
    autoAlert: { mode: 'delete', name: 'پترن‌های انتخاب شده' },
    onSettled: () => onCloseDialog(),
    onSuccess: () => onClearSelections(),
  });

  useMount(() => {
    if (patternType && isPatternTypeValid(patternType)) {
      setType(patternType as PatternType);
    } else {
      navigate('/pattern');
    }
  });

  // Utilities
  const handleOpenDeleteDialog = () =>
    onOpenDialog({
      title: `آیا از حذف ${
        selections.length > 1 ? 'پترن‌های' : 'پترن'
      } انتخابی مطمئن هستید؟`,
      onSubmit: () => {
        if (type && selections.length > 0) {
          handleDeletePatterns({ type, ids: selections });
        }
      },
    });

  const handleDuplicate = async () => {
    if (type && selections.length === 1) {
      const selectedPattern = selections[0];
      handleDuplicatePattern({ type, id: selectedPattern });
    }
  };

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='پترن‌ها' />
      <PageHeroBox>
        <PageTitle>پترن‌ها</PageTitle>
        <RowStack sx={{ color: 'rgba(18, 37, 46, 0.5)' }}>
          <DeleteLoadingButton
            disabled={selections.length === 0}
            onClick={handleOpenDeleteDialog}
          >
            حذف
          </DeleteLoadingButton>
          <LoadingButton
            disabled={selections.length === 0 || selections.length > 1}
            variant='outlined'
            color='inherit'
            startIcon={<DuplicateIcon />}
            onClick={handleDuplicate}
          >
            کپی
          </LoadingButton>
          <ButtonLink
            disabled={type === ''}
            variant='contained'
            color='info'
            href={`/pattern/${type}/add`}
            startIcon={<PlusCircleIcon />}
          >
            پترن جدید
          </ButtonLink>
        </RowStack>
      </PageHeroBox>
      <ColumnStack>
        <Breadcrumbs aria-label='breadcrumb' separator='>'>
          <RouteLink href='/pattern' underline='hover'>
            پترن‌ها
          </RouteLink>
          {type && <BodyOne>{getPatternTitle(type)}</BodyOne>}
        </Breadcrumbs>
        <PatternListsDataGrid
          items={items || []}
          type={type}
          selections={selections}
          onSelectionChange={onSelectionsChange}
        />
      </ColumnStack>
    </>
  );
};

export default PatternList;

import { useState, useCallback, useEffect } from 'react';

// Custom Types
type Filter = { [key: string]: string | string[] };

export interface UseFilterResponse {
  filter: Filter;
  onFilterChange: (fitler: Filter) => void;
  onFilterChangeByKey: (key: string, value: string | string[]) => void;
}

function useFilter(): UseFilterResponse {
  // Hooks
  const [filter, setFilter] = useState<Filter>({});

  // Utilities
  const handleFilterChange = useCallback((newFilter: Filter) => {
    setFilter(newFilter);
  }, []);

  const handleFilterChangeByKey = useCallback(
    (key: string, value: string | string[]) => {
      setFilter((prevFilter) => ({
        ...prevFilter,
        [key]: value,
      }));
    },
    []
  );

  // Return
  return {
    filter,
    onFilterChange: handleFilterChange,
    onFilterChangeByKey: handleFilterChangeByKey,
  };
}

export default useFilter;

import cloneDeep from 'lodash/cloneDeep';

// Feature Defaults
import { headerPatternBrandDefaults } from 'features/appBuilder/patterns/features/header/utilities/defaults/brand';
import { headerPatternMenuDefaults } from 'features/appBuilder/patterns/features/header/utilities/defaults/menu';
import { headerPatternButtonDefaults } from 'features/appBuilder/patterns/features/header/utilities/defaults/button';
import { headerPatternBannerDefaults } from 'features/appBuilder/patterns/features/header/utilities/defaults/banner';

// Custom Types
import type { HeaderPatternDataProps } from 'features/appBuilder/patterns/features/header/types';

const headerPatternDataDefaults: HeaderPatternDataProps = {
  patternTitle: '',
  bgColorOne: '#F5F5F5',
  bgColorTwo: '#F5F5F5',
  dividerColor: '#C1C8D3',
  bgImage: null,
  bgImageType: 'cover',
  padding: '32px',
  cardBg: '',
  cardCount: 0,
  creator: null,
  isActive: true,
  maxWidth: 'lg',
  sortIndex: 0,
  type: 'header',
  variant: 0,
  brand: headerPatternBrandDefaults,
  menu: headerPatternMenuDefaults,
  button: headerPatternButtonDefaults,
  banner: headerPatternBannerDefaults,
};

/**
 * Retrieves the default header pattern data.
 *
 * @returns {HeaderPatternDataProps} The default header pattern data.
 */
export const getHeaderPatternDataDefault = () => {
  const cloned = cloneDeep(headerPatternDataDefaults);

  return cloned;
};

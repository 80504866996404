// Custom Hooks
import useDocApi from 'core/hooks/api/useDoc';
import useDocsApi from 'core/hooks/api/useDocs';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  addFormStep,
  editFormSteps,
  removeFormStep,
} from 'features/form/forms/utilities/api/steps';
import {
  addFormSection,
  copyFormSection,
  editFormSectionsSort,
  removeFormSection,
} from 'features/form/forms/utilities/api/section';
import {
  addAndEditFormInputs,
  removeFormInputs,
} from 'features/form/forms/utilities/api/inputs';
import {
  addForm,
  editForm,
  getForm,
  getFormDetailsSummary,
  getForms,
  removeForm,
} from 'features/form/forms/utilities/api';

// Custom Types
import type { FormListItemProps } from 'features/form/forms/types/list';
import type { FormInput } from 'features/form/forms/types/item/input';
import type { FormSectionProps } from 'features/form/forms/types/item/section';
import type {
  FormStepDataProps,
  FormStepProps,
} from 'features/form/forms/types/item/step';
import type {
  FormDetailsSummaryProps,
  FormProps,
  FormSettingsDataProps,
} from 'features/form/forms/types/item';
import type {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';

/**
 * Query key for fetching forms in react query.
 *
 * @constant {Array<string>}
 */
export const formsQueryKey = ['forms'];

/**
 * Custom hook to fetch forms.
 *
 * This hook utilizes the `useDocsApi` to retrieve forms list on the provided options.
 *
 * @param {UseDocsOptionType<FormListItemProps>} [options] - Optional configuration options for fetching the forms.
 * @returns {UseDocsApiReturnProps<FormListItemProps>} The result of the `useDocsApi` hook, which includes the fetched forms
 */
export const useFormDocs = (
  options?: UseDocsOptionType<FormListItemProps>
): UseDocsApiReturnProps<FormListItemProps> =>
  useDocsApi<FormListItemProps>(getForms, formsQueryKey, options);

/**
 * Custom hook to fetch the form details summary document.
 *
 * This hook utilizes the `useDocApi` to retrieve a specific form details summary based on the provided options.
 *
 * @param {UseDocOptionType<FormDetailsSummaryProps>} [options] - Optional configuration options for fetching the form details summary document.
 * @returns {UseDocApiReturnProps<FormDetailsSummaryProps>} The result of the `useDocApi` hook, which includes the fetched form details summary document, loading state, and any errors.
 */
export const useFormDetailsSummaryDoc = (
  options?: UseDocOptionType<FormDetailsSummaryProps>
): UseDocApiReturnProps<FormDetailsSummaryProps> =>
  useDocApi<FormDetailsSummaryProps>(
    getFormDetailsSummary,
    formsQueryKey,
    options
  );

/**
 * Custom hook to fetch the form document.
 *
 * This hook utilizes the `useDocApi` to retrieve a specific form document based on the provided options.
 *
 * @param {UseDocOptionType<FormProps>} [options] - Optional configuration options for fetching the form document.
 * @returns {UseDocApiReturnProps<FormProps>} The result of the `useDocApi` hook, which includes the fetched form document, loading state, and any errors.
 */
export const useFormDoc = (
  options?: UseDocOptionType<FormProps>
): UseDocApiReturnProps<FormProps> =>
  useDocApi<FormProps>(getForm, formsQueryKey, options);

/**
 * Custom Hook to delete a form.
 *
 * @returns {ReturnType<typeof useDeleteMutation<string>>} The mutation object for deleting a form.
 */
export const useDeleteFormMutation = useDeleteMutation<string>(
  removeForm,
  formsQueryKey
);

/**
 * Custom Hook to delete a form section.
 *
 * @returns {ReturnType<typeof useDeleteMutation<string>>} The mutation object for deleting a form section.
 */
export const useDeleteFormSectionMutation = useDeleteMutation<string>(
  removeFormSection,
  formsQueryKey
);

/**
 * Custom Hook to delete multiple form inputs.
 *
 * @returns {ReturnType<typeof useDeleteMutation<string[]>>} The mutation object for deleting form inputs.
 */
export const useDeleteFormInputsMutation = useDeleteMutation<string[]>(
  removeFormInputs,
  formsQueryKey
);

/**
 * Custom Hook to delete a specific step from a form.
 *
 * @returns {ReturnType<typeof useDeleteMutation<{ stepId: string; activeStepId: string }, { status: number; sections: FormSectionProps[]; inputs: FormInput[] }>>} The mutation object for deleting a form step.
 */
export const useDeleteFormStepMutation = useDeleteMutation<
  { stepId: string; activeStepId: string },
  {
    status: number;
    sections: FormSectionProps[];
    inputs: FormInput[];
  }
>(removeFormStep, formsQueryKey);

/**
 * Custom Hook to edit the steps of a form.
 *
 * @returns {ReturnType<typeof useEditMutation<FormStepProps[], { status: number; docs?: FormStepProps[] }>>} The mutation object for editing form steps.
 */
export const useEditFormSteps = useEditMutation<
  FormStepProps[],
  { status: number; docs?: FormStepProps[] }
>(editFormSteps, formsQueryKey);

/**
 * Custom Hook to add a new step to a form.
 *
 * @returns {ReturnType<typeof useAddMutation<{ formId: string; index: number }, { status: number; docs?: FormStepProps }>>} The mutation object for adding a form step.
 */
export const useAddFormStepMutation = useAddMutation<
  { formId: string; index: number },
  { status: number; docs?: FormStepProps }
>(addFormStep, formsQueryKey);

/**
 * Custom Hook to add a new section to a form.
 *
 * @returns {ReturnType<typeof useAddMutation<{ formId: string; stepId: string; sortIndex: number }, { status: number; section?: FormSectionProps }>>} The mutation object for adding a form section.
 */
export const useAddFormSectionMutation = useAddMutation<
  {
    formId: string;
    stepId: string;
    sortIndex: number;
  },
  { status: number; section?: FormSectionProps }
>(addFormSection, formsQueryKey);

/**
 * Custom Hook to edit the sorting of form sections.
 *
 * @returns {ReturnType<typeof useAddMutation<FormSectionProps[], { status: number; docs?: FormSectionProps[] }>>} The mutation object for editing the sort order of form sections.
 */
export const useEditFormSectionsSortMutation = useAddMutation<
  FormSectionProps[],
  { status: number; docs?: FormSectionProps[] }
>(editFormSectionsSort, formsQueryKey);

/**
 * Custom Hook to add and edit form inputs.
 *
 * @returns {ReturnType<typeof useAddMutation<FormInput[], { status: number; docs?: FormInput[] }>>} The mutation object for adding and editing form inputs.
 */
export const useAddAndEditFormInputsMutation = useAddMutation<
  FormInput[],
  { status: number; docs?: FormInput[] }
>(addAndEditFormInputs, formsQueryKey);

/**
 * Custom Hook to add a new form.
 *
 * @returns {ReturnType<typeof useAddMutation<FormSettingsDataProps, { status: number; doc?: FormProps }>>} The mutation object for adding a form.
 */
export const useAddFormMutation = useAddMutation<
  FormSettingsDataProps,
  { status: number; doc?: FormProps }
>(addForm, formsQueryKey);

/**
 * Custom Hook to edit an existing form.
 *
 * @returns {ReturnType<typeof useEditMutation<FormSettingsDataProps, { status: number; doc?: FormProps }>>} The mutation object for editing a form.
 */
export const useEditFormMutation = useEditMutation<
  FormSettingsDataProps,
  { status: number; doc?: FormProps }
>(editForm, formsQueryKey);

/**
 * Custom Hook to copy a form section.
 *
 * @returns {ReturnType<typeof useAddMutation<string, { status: number; doc?: { id: string; data: FormStepDataProps & { sections: FormSectionProps[]; inputs: FormInput[]; }; }; }>>} The mutation object for copying a form section.
 */
export const useCopyFormSectionMutation = useAddMutation<
  string,
  {
    status: number;
    doc?: {
      id: string;
      data: FormStepDataProps & {
        sections: FormSectionProps[];
        inputs: FormInput[];
      };
    };
  }
>(copyFormSection, formsQueryKey);

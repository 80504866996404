import { useEffect, useCallback } from 'react';

// Type
import type { FC } from 'react';

// Core Components
import TextField from 'core/components/base/inputs/TextField';
import Autocomplete from 'core/components/base/inputs/Autocomplete';
import FormHelperText from 'core/components/base/inputs/FormHelperText';
import CircularProgress from 'core/components/base/feedback/CircularProgress';

// Custom Icons
import ExpandMoreIcon from 'core/components/icons/ExpandMore';

// Hooks
import useArray from 'core/hooks/useArray';
import useBoolean from 'core/hooks/useBoolean';
import useSummaryApi from 'core/hooks/api/useSummary';
import useUpdateEffect from 'core/hooks/useUpdateEffect';

// Custom Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Types
import type { TextFieldProps } from 'core/components/base/inputs/TextField';
import type { AutocompleteProps } from '@mui/material/Autocomplete';
import type { ApiGetSummaryResponse } from 'core/types/api/hook/response';

interface ListOption {
  id: string;
  data: {
    title: string;
    [key: string]: any;
  };
}

export interface GenericMultiAutocompleteProps<T>
  extends Omit<
    AutocompleteProps<
      T,
      boolean | undefined,
      boolean | undefined,
      boolean | undefined
    >,
    'options' | 'renderInput' | 'onChange' | 'onBlur' | 'defaultValue'
  > {
  defaultRefreshCount?: number;
  defaultValue?: string[];
  textFieldProps?: TextFieldProps;
  title?: string;
  helperText?: string;
  disablePopupCustomIcon?: boolean;
  error?: boolean;
  apiHandler: (signal?: AbortSignal) => Promise<ApiGetSummaryResponse<T>>;
  queryKey: string[];
  onBlur?: (selectedIds: string[]) => void;
  onChange?: (selectedIds: string[], selectedOpts: any[]) => void;
}

const MultiAutocomplete: FC<GenericMultiAutocompleteProps<any>> = (props) => {
  // Props
  const {
    defaultRefreshCount = 1,
    title = '',
    limitTags = 4,
    defaultValue,
    textFieldProps,
    helperText,
    error,
    disabled,
    sx,
    disablePopupCustomIcon,
    queryKey,
    getOptionLabel = (option) => {
      if (typeof option === 'string') return option;

      return option.data.title;
    },
    apiHandler,
    onBlur,
    onChange,
    ...otherProps
  } = props;

  // States
  const selecteds = useArray<ListOption>();
  const open = useBoolean();

  // Hooks
  const {
    data: list = [],
    isFetching,
    isSuccess,
    refetch,
  } = useSummaryApi<any>(apiHandler, queryKey);

  const handleDefaults = useCallback(() => {
    if (!isSuccess || !defaultValue || list.length === 0) return;
    const defValues =
      list && list?.filter?.((item) => defaultValue.includes(item.id));

    selecteds.set(defValues);
  }, [defaultValue, isSuccess, list]);

  useEffect(() => {
    handleDefaults();
  }, [handleDefaults]);

  useUpdateEffect(() => {
    if (open.state) refetch();
  }, [open.state]);

  // Context
  const loading = useSelectLoading();

  // Utilities
  const onChangeHandler = (selectedsValue: ListOption[]) => {
    const newIds: string[] = [];

    selectedsValue.forEach((opt) => newIds.push(opt.id));

    if (onChange) onChange(newIds, selectedsValue);
    selecteds.set(selectedsValue);
  };

  // Render
  return (
    <>
      <Autocomplete
        closeText='بستن'
        filterSelectedOptions
        isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
        getOptionLabel={getOptionLabel}
        limitTags={limitTags}
        disabled={disabled || loading}
        loading={isFetching && !disabled}
        loadingText='در حال دریافت اطلاعات.'
        multiple
        noOptionsText='چیزی یافت نشد.'
        popupIcon={!disablePopupCustomIcon ? <ExpandMoreIcon /> : undefined}
        onBlur={() => onBlur && onBlur(selecteds.state.map((s) => s.id))}
        onChange={(e, value) => onChangeHandler(value)}
        onClose={open.setFalse}
        onOpen={() => {
          if (!disabled) open.setTrue();
        }}
        open={open.state}
        openText={`انتخاب ${title}`}
        options={list}
        value={selecteds.state}
        sx={{
          '& .MuiOutlinedInput-root': {
            border: error ? '.1px solid red' : '',
            borderRadius: '.5rem',
          },
          ...sx,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={`انتخاب ${title}`}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isFetching && !disabled ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            {...textFieldProps}
          />
        )}
        {...otherProps}
      />
      {helperText && (
        <FormHelperText sx={{ color: error ? 'error.main' : 'text.primary' }}>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default MultiAutocomplete;

// Utilities
import { initialFile } from 'features/file/files/utilities/file';

// Custom Types
import type { SettingsProps } from 'features/manager/settings/types';

export const initialSettings: SettingsProps = {
  id: '',
  data: {
    description: '',
    email: { address: '', name: '', smtp: '', username: '' },
    favicon: initialFile,
    relatedMediaId: '',
    logo: initialFile,
    fontFamily: 'Vazir',
    enableDeleteFeedback: false,
    enableDeleteForm: false,
    themes: [],
  },
};

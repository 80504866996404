export const tagEndpoints = {
  get add() {
    return `/tag/add`;
  },
  edit(id: string) {
    return `/tag/edit/${id}`;
  },
  get remove() {
    return `/tag/remove`;
  },
  getById(id: string) {
    return `/tag/getDoc/${id}`;
  },
  get getList() {
    return `/tag/getDocs`;
  },
  get getSummaryList() {
    return `/tag/getList`;
  },
};

import { type FC } from 'react';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Link from 'core/components/base/navigation/Link';

// Custom Common Components
import CloseIconButton from 'core/components/shared/IconButton/Close';

// Custom Hooks
import { useAppDispatch } from 'core/hooks/redux';

// Custom Utilities
import { resetCells } from 'features/appBuilder/patterns/features/table/store';
import { prefixFileURL } from 'core/utilities/helper/link';

// Custom Types
import type { TableCellFileProps } from 'features/appBuilder/patterns/features/table/types/cell';
export interface FileCellBadgeProps {
  cell: TableCellFileProps;
}

const FileCellBadge: FC<FileCellBadgeProps> = (props) => {
  // Props
  const { cell } = props;

  // Hooks
  const dispatch = useAppDispatch();

  // Utilities
  const handleResetCell = () => {
    dispatch(resetCells([cell]));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        gap: 1,
        borderRadius: '0.25rem',
        bgcolor: 'background.default',
        p: '0.25rem',
      }}
    >
      <img
        alt=''
        src={prefixFileURL(cell.file.data.url)}
        style={{ width: 24, height: 24, objectFit: 'contain' }}
      />
      <Link
        onClick={(e) => e.stopPropagation()}
        href={prefixFileURL(cell.file.data.url)}
        target='_blank'
        rel='noreferrer'
        underline='hover'
      >
        مشاهده
      </Link>
      <CloseIconButton
        onClick={(e) => {
          e.stopPropagation();
          handleResetCell();
        }}
        size='small'
        color='inherit'
        style={{ marginRight: 'auto' }}
      />
    </Box>
  );
};

export default FileCellBadge;

import type { FC } from 'react';

// Feature Components
import FooterPatternBrandSection from 'features/appBuilder/patterns/features/footer/components/Brand';
import FooterPatternMenuSection from 'features/appBuilder/patterns/features/footer/components/Menu';
import FooterPatternDescriptionSection from 'features/appBuilder/patterns/features/footer/components/Description';
import FooterPatternBannerSection from 'features/appBuilder/patterns/features/footer/components/Banner';

// Custom Types
export interface FooterPatternItemsProps {}

const FooterPatternItems: FC<FooterPatternItemsProps> = () => {
  return (
    <>
      <FooterPatternBrandSection />
      <FooterPatternMenuSection />
      <FooterPatternDescriptionSection />
      <FooterPatternBannerSection />
    </>
  );
};

export default FooterPatternItems;

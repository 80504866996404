import {
  Grid as MuiGrid,
  GridProps as MuiGridProps,
  styled,
} from '@mui/material';

export interface GridProps extends MuiGridProps {}

const Grid = styled(MuiGrid)<GridProps>(() => ({}));

export default Grid;

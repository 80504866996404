import { type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import Slide, { type SlideProps } from 'core/components/base/utils/Slide';

// Custom Common Components
import CheckboxWithLabel from 'core/components/shared/Checkbox/CheckboxWithLabel';
import CloseIconButton from 'core/components/shared/IconButton/Close';

// Custom Styles
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';

// Custom Types
import type { FormProps } from 'features/form/forms/types/item';
export interface InputConfigSlideProps extends Omit<SlideProps, 'children'> {
  index: number;
  children?: JSX.Element;
  onClose?: () => void;
  onUpdate?: () => void;
}

const InputConfigSlide: FC<InputConfigSlideProps> = (props) => {
  // Props
  const { index, children, onClose, onUpdate, ...otherProps } = props;

  // Hooks
  const { control, setValue } = useFormContext<FormProps>();

  return (
    <Slide direction='right' {...otherProps}>
      <Box
        sx={{
          backgroundColor: 'background.default',
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'flex-end',
          top: 0,
          bottom: 0,
          right: 0,
          minWidth: '18rem',
          padding: '0.5rem',
          zIndex: 10,
        }}
      >
        <CloseIconButton color='inherit' onClick={onClose} />
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            overflow: 'hidden',
            justifyContent: 'flex-end',
            px: '0.5rem',
            gap: 1,
            ...customRoundedScrollbarStyles,
          }}
        >
          {children}
          <Controller
            control={control}
            name={`data.inputs.${index}.data.isFullwidth`}
            defaultValue={true}
            render={({ field }) => (
              <CheckboxWithLabel
                checkboxProps={{
                  checked: field.value,
                  onChange: (event, checked) => {
                    setValue(field.name, checked);
                    if (onUpdate) onUpdate();
                  },
                }}
                label='تمام عرض'
              />
            )}
          />
        </Box>
      </Box>
    </Slide>
  );
};

export default InputConfigSlide;

import * as React from 'react';
import isEqual from 'lodash/isEqual';
import { Link } from 'react-router-dom';

// Custom Core Components
import TableRow from 'core/components/base/display/TableRow';
import Checkbox from 'core/components/base/inputs/Checkbox';
import TableCell from 'core/components/base/display/TableCell';

// Custom Common Components
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Utilities
import { getLocaleDate } from 'core/utilities/helper/date';

// Custom Types
import type { MenuListItemProps } from 'features/menu/menus/types';

// Custom Types
interface DataGridItemProps {
  menu: MenuListItemProps;
  selected: boolean;
  disabled: boolean;
  onSelect: (menuId: string) => void;
}

const DataGridItem: React.FC<DataGridItemProps> = (props) => {
  // Props
  const { menu, selected, disabled = false, onSelect } = props;

  // Render
  return (
    <TableRow>
      <TableCell padding='checkbox'>
        <Checkbox
          color='info'
          disabled={disabled}
          checked={selected}
          onChange={() => onSelect(menu.id)}
        />
      </TableCell>
      <TableCell sx={{ minWidth: '7rem', whiteSpace: 'wrap' }}>
        <Link
          to={`/menus/${menu.id}`}
          style={{
            textDecoration: 'none',
          }}
        >
          <BodyTwo
            sx={{
              color: 'text.primary',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {menu.data.title || '-'}
          </BodyTwo>
        </Link>
      </TableCell>
      <TableCell sx={{ width: '7rem', whiteSpace: 'nowrap' }}>
        {menu.data.itemsCount.toPersian() || '-'}
      </TableCell>
      <TableCell
        align='center'
        sx={{
          width: '7rem',
          whiteSpace: 'nowrap',
          textAlign: 'center',
        }}
      >
        <BodyTwo dir='ltr'>{getLocaleDate(menu.data.createdAt)}</BodyTwo>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(DataGridItem, isEqual);

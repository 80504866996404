import {
    ToggleButton as MuiToggleButton,
    ToggleButtonProps as MuiToggleButtonProps,
    styled,
  } from '@mui/material';
  
  export interface ToggleButtonProps extends MuiToggleButtonProps {}
  
  const ToggleButton = styled(MuiToggleButton)<ToggleButtonProps>(() => ({}));
  
  export default ToggleButton;
  
// Feature Types
import type { PatternFormAlertProps } from 'features/appBuilder/patterns/core/types/item/formAlert';
import type { FormPatternErrorProps } from 'features/appBuilder/patterns/features/form/types/error';

const commonProps: PatternFormAlertProps = {
  isActive: true,
  padding: '0px',
  fontColor: '#D32F2F',
  fontSize: '12',
  textDirection: 'rtl',
  textAlign: 'center',
  text: '',
  bgColor: 'rgba(0, 0, 0, 0)',
  variables: [],
};

export const formPatterErrorDefaults: FormPatternErrorProps = {
  isActive: true,
  required: {
    ...commonProps,
    variables: ['{title}'],
    text: 'تکمیل فیلد «{title}» اجباری است',
  },
  invalid: {
    ...commonProps,
    variables: ['{title}'],
    text: '«{title}» وارد شده صحیح نیست',
  },
  charLimit: {
    ...commonProps,
    variables: ['{title}', '{min}', '{max}'],
    text: '«{title}» نمی‌تواند کمتر از {minLength} و بیشتر از {maxLength} کاراکتر باشد',
  },
  numLimit: {
    ...commonProps,
    variables: ['{title}', '{min}', '{max}'],
    text: '«{title}» نمی‌تواند کمتر از {min} و بیشتر از {max} باشد',
  },
  optionLimit: {
    ...commonProps,
    variables: ['{title}', '{min}', '{max}'],
    text: 'فیلد «{title}» نمی‌تواند کمتر از {min} و بیشتر از {max} گزینه باشد',
  },
  uploadLimit: {
    ...commonProps,
    variables: ['{title}', '{min}', '{max}'],
    fontSize: '14',
    fontColor: '#FFFFFF',
    bgColor: '#D32F2F',
    text: 'حجم «{title}» نمی‌تواند کمتر از {min} و بیشتر از {max} کیلوبایت باشد',
  },
  invalidUpload: {
    ...commonProps,
    fontSize: '14',
    fontColor: '#FFFFFF',
    bgColor: '#D32F2F',
    text: 'آپلود فایل مورد نظر مجاز نیست',
  },
  emptyRequired: {
    ...commonProps,
    fontSize: '14',
    fontColor: '#FFFFFF',
    bgColor: '#D32F2F',
    text: 'پیش از ثبت فرم ابتدا فیلدهای ستاره‌دار را تکمیل کنید',
  },
  failedUpload: {
    ...commonProps,
    fontSize: '14',
    fontColor: '#FFFFFF',
    bgColor: '#D32F2F',
    text: 'آپلود فایل ناموفق بود، لطفا دوباره تلاش کنید',
  },
  dateLimit: {
    ...commonProps,
    variables: ['minDate', 'maxDate'],
    text: 'تاریخ انتخاب شده در بازه {minDate} تا {maxDate} نیست',
  },
  feedbackCountLimit: {
    ...commonProps,
    fontSize: '14',
    fontColor: '#FFFFFF',
    bgColor: '#D32F2F',
    text: 'شما حداکثر تعداد بازخورد قابل ثبت در این فرم را ثبت کرده‌اید.',
  },
};

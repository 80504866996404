import { useState, type FC } from 'react';

// Feature Shared Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternEmptyItem from 'features/appBuilder/patterns/core/components/shared/items/Empty';
import PatternColorItem from 'features/appBuilder/patterns/core/components/shared/items/Color';

// Custom Types
export interface ContentPatternConfigSectionItemProps {}
type CollapsedKey = 'section' | 'tabs' | 'archive';

const ContentPatternButtonSection: FC<
  ContentPatternConfigSectionItemProps
> = () => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    tabs: false,
    archive: false,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='تنظیمات دکمه‌ها'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='button'
    >
      <PatternColorItem
        reference='button.tabs'
        title='تب‌ها'
        collapsed={collapsed.tabs}
        onCollapsedChange={handleCollapseChanged('tabs')}
        colorControllers={[
          { keyName: 'activeTabColor', title: 'متن تب فعال' },
          { keyName: 'activeTabBgColor', title: 'بکگراند تب فعال' },
        ]}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'fontColor',
          'bgColor',
          'fontHoverColor',
          'bgHoverColor',
          'borderRadius',
        ]}
      />
      <PatternEmptyItem
        reference='button.archive'
        title='بایگانی'
        collapsed={collapsed.archive}
        onCollapsedChange={handleCollapseChanged('archive')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'fontColor',
          'bgColor',
          'fontHoverColor',
          'bgHoverColor',
          'borderRadius',
        ]}
      />
    </PatternSectionContainer>
  );
};

export default ContentPatternButtonSection;

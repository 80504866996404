import { FormDetailsSummaryProps } from 'features/form/forms/types/item';
import { createContext, useContext } from 'react';

// Types

import type { FC, ReactNode } from 'react';

type FormDetailsSummary = Record<string, string | string[]>;

const FormDetailsSummary = createContext<FormDetailsSummaryProps | null>(null);

interface FormDetailsSummaryProviderProps {
  children: ReactNode;
  data: FormDetailsSummaryProps | null;
}

const FormDetailsSummaryProvider: FC<FormDetailsSummaryProviderProps> = (
  props
) => {
  // Props
  const { children, data } = props;

  // Render
  return (
    <FormDetailsSummary.Provider value={data}>
      {children}
    </FormDetailsSummary.Provider>
  );
};

export const useFormDetailsSummaryContext =
  (): FormDetailsSummaryProps | null => useContext(FormDetailsSummary);

export default FormDetailsSummaryProvider;

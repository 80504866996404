// Custom Types
import type { FormInputCommonDataProps } from 'features/form/forms/types/item/input/common';

export interface FormInputInitialParams {
  formId: string;
  stepId: string;
  sectionId: string;
  sortIndex: number;
}

export const getInitialCommonInputProps = (
  params: FormInputInitialParams
): Omit<FormInputCommonDataProps, 'type'> => ({
  visibleAccordingTo: [],
  isFullwidth: false,
  isHidden: false,
  isRequired: false,
  label: 'عنوان',
  placeholder: '',
  ...params,
});

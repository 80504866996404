// Custom Hooks
import useDocApi from 'core/hooks/api/useDoc';
import useDocsApi from 'core/hooks/api/useDocs';
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from 'core/hooks/api/useMutation';

// Custom Utilities
import {
  addCustomPage,
  deleteCustomPage,
  duplicateCustomPage,
  getCustomPage,
  getCustomPages,
  updateCustomPage,
} from 'features/appBuilder/customPages/utilities/api';

// Custom Types
import type {
  CustomPageDataProps,
  CustomPageProps,
  CustomPageSummaryProps,
} from 'features/appBuilder/customPages/types';
import type {
  UseDocApiReturnProps,
  UseDocOptionType,
} from 'core/hooks/api/useDoc';
import type {
  UseDocsApiReturnProps,
  UseDocsOptionType,
} from 'core/hooks/api/useDocs';

/**
 * Query key for fetching custom pages in react query.
 *
 * @constant {Array<string>}
 */
export const customPagesQueryKey = ['customPages'];

/**
 * Custom hook to fetch a custom page document.
 *
 * This hook utilizes the `useDocApi` to retrieve a specific custom page based on the provided options.
 *
 * @param {UseDocOptionType<CustomPageProps>} [options] - Optional configuration options for fetching the custom page document.
 * @returns {UseDocApiReturnProps<CustomPageProps>} The result of the `useDocApi` hook, which includes the fetched custom page document, loading state, and any errors.
 */
export const useCustomPageDoc = (
  options?: UseDocOptionType<CustomPageProps>
): UseDocApiReturnProps<CustomPageProps> =>
  useDocApi<CustomPageProps>(getCustomPage, customPagesQueryKey, options);

/**
 * Custom hook to fetch custom pages list.
 *
 * This hook utilizes the `useDocsApi` to retrieve custom pages based on the provided options.
 *
 * @param {UseDocsOptionType<CustomPageProps>} [options] - Optional configuration options for fetching the custom page document.
 * @returns {UseDocsApiReturnProps<CustomPageProps>} The result of the `useDocApi` hook, which includes the fetched custom page document, loading state, and any errors.
 */
export const useCustomPageDocs = (
  options?: UseDocsOptionType<CustomPageSummaryProps>
): UseDocsApiReturnProps<CustomPageSummaryProps> =>
  useDocsApi<CustomPageSummaryProps>(
    getCustomPages,
    customPagesQueryKey,
    options
  );

/**
 * Custom hook to duplicate a custom page.
 *
 * @returns {MutationResult<string>} The result of the mutation for duplicating a custom page.
 */
export const useDuplicateCustomPageMutation = useAddMutation<string>(
  duplicateCustomPage,
  customPagesQueryKey
);

/**
 * Custom hook to add a new custom page.
 *
 * @returns {MutationResult} The result of the mutation for adding a custom page.
 */
export const useAddCustomPageMutation = useAddMutation(
  addCustomPage,
  customPagesQueryKey
);

/**
 * Custom hook to update an existing custom page.
 *
 * @returns {MutationResult<CustomPageDataProps>} The result of the mutation for updating a custom page.
 */
export const useUpdateCustomPageMutation = useEditMutation<CustomPageDataProps>(
  updateCustomPage,
  customPagesQueryKey
);

/**
 * Custom hook to delete a custom page.
 *
 * @returns {MutationResult} The result of the mutation for deleting a custom page.
 */
export const useDeleteCustomPageMutation = useDeleteMutation(
  deleteCustomPage,
  customPagesQueryKey
);

import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';

// Custom Utilities
import { isSame } from 'core/utilities/helper/helperPack';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useSelectIsProcessHidden } from 'features/form/feedbacks/store';
import { toggleProcessItemSectionsVisibility } from 'features/form/feedbacks/store/actions/process';

// Custom Types
import type { ProcessFilterItemProps } from './ProcessFields';

interface ProcessModeButtonProps {
  processItemId: string;
  item: ProcessFilterItemProps;
  disabled?: boolean;
}

const ProcessModeButton: React.FC<ProcessModeButtonProps> = (props) => {
  // Props
  const { processItemId, item, disabled } = props;

  // Context
  const loading = useSelectLoading();
  const isHidden = useSelectIsProcessHidden(processItemId, item.name);

  // Hooks
  const { palette } = useTheme();

  // Render
  return (
    <Button
      disabled={loading || disabled}
      onClick={() =>
        toggleProcessItemSectionsVisibility(processItemId, item.name)
      }
      sx={{
        color: 'black',
        width: 'fit-content',
        background: isHidden ? '#EBEBEB' : '#9ED5FF',
        textDecoration: isHidden ? 'line-through' : 'none',
        ':hover': {
          textDecoration: isHidden ? 'line-through' : 'none',
          background: isHidden ? '#EBEBEB' : '#9ED5FF',
          opacity: '.8',
        },
        ':disabled': {
          background: palette.text.disabled,
        },
      }}
    >
      {item.label}
    </Button>
  );
};

export default React.memo(ProcessModeButton, isSame);

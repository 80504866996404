// Types
import type { ComponentType } from 'react';

// Cusotm Types
export interface WithAuthOptionsProps {}
export interface WithAuthProps {
  authToken?: string;
  protected?: boolean;
}

const WithAuth =
  <T extends WithAuthProps>(
    WrappedComponent: ComponentType<T>,
    options?: WithAuthOptionsProps
  ) =>
  (props: T) => {
    return <WrappedComponent {...props} />;
  };

export default WithAuth;

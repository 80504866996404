// Custom Store
import store from 'core/store';

// Custom Utilities
import { actions } from 'features/form/forms/store/slice';

// Custom Types
import type { FormInput } from 'features/form/forms/types/item/input';
import type { FormSectionProps } from 'features/form/forms/types/item/section';
import type { FormStepProps } from 'features/form/forms/types/item/step';

export const setFormCurrentStep = (options: {
  id: string;
  index: number;
  sections?: FormSectionProps[];
  inputs?: FormInput[];
}) => {
  store.dispatch(actions.setCurrentStep({ ...options }));
};

export const updateFormSteps = (steps: FormStepProps[]) => {
  store.dispatch(actions.setSteps(steps));
};

import {
  Collapse as MuiCollapse,
  CollapseProps as MuiCollapseProps,
  styled,
} from '@mui/material';

export interface CollapseProps extends MuiCollapseProps {}

const Collapse = styled(MuiCollapse)<CollapseProps>(() => ({}));

export default Collapse;

import { Controller, useFormContext } from 'react-hook-form';

// Types
import type { FC } from 'react';

// Custom Common Components
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import AntSwitch from 'core/components/shared/Switch/AntSwitch';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import SlugTextField from 'core/components/shared/TextField/Slug';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Custom Core Components
import Box from 'core/components/base/layout/Box';
import TextField from 'core/components/base/inputs/TextField';

// Custom Utilities
import { getAppUserURL } from 'core/utilities/app/info';

// Custom Types
import type {
  CustomPageOperation,
  CustomPageProps,
} from 'features/appBuilder/customPages/types';
export interface CustomPageConfigProps {
  operation: CustomPageOperation;
  onOperationChange: (operation: CustomPageOperation) => void;
}

const CustomPageConfig: FC<CustomPageConfigProps> = (props) => {
  // Props
  const { operation } = props;

  // Hooks
  const { control, setValue, watch } = useFormContext<CustomPageProps>();

  // Render
  return (
    <RoundPaper
      sx={{ p: '1rem 2rem', display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <Controller
          control={control}
          defaultValue={true}
          name='data.isActive'
          render={({ field }) => (
            <AntSwitch
              disabled={operation.includes('BODY')}
              checked={field.value}
              onChange={(e, checked) => setValue('data.isActive', checked)}
            />
          )}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 6 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <InputLabel required>عنوان</InputLabel>
          <Controller
            control={control}
            name='data.title'
            defaultValue=''
            render={({ field, fieldState }) => (
              <TextField
                disabled={operation.includes('BODY')}
                fullWidth
                placeholder='عنوان صفحه'
                error={fieldState.error && fieldState.error.message !== ''}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <InputLabel required>آدرس</InputLabel>
            <BodyTwo dir='ltr' color='text.secondary'>
              {`${getAppUserURL()}${watch('data.slug') || ''}`}
            </BodyTwo>
          </Box>
          <Controller
            control={control}
            name='data.slug'
            defaultValue=''
            render={({ field, fieldState }) => (
              <SlugTextField
                disabled={operation.includes('BODY')}
                dir='ltr'
                fullWidth
                placeholder='/slug'
                copyValue={field.value}
                error={fieldState.error && fieldState.error.message !== ''}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Box>
      </Box>
    </RoundPaper>
  );
};

export default CustomPageConfig;

import cloneDeep from 'lodash/cloneDeep';

// Feature Defaults
import { footerPatternBrandDefaults } from 'features/appBuilder/patterns/features/footer/utilities/defaults/brand';
import { footerPatternMenuDefaults } from 'features/appBuilder/patterns/features/footer/utilities/defaults/menu';
import { footerPatternDescriptionDefaults } from 'features/appBuilder/patterns/features/footer/utilities/defaults/description';
import { footerPatternBannerDefaults } from 'features/appBuilder/patterns/features/footer/utilities/defaults/banner';

// Custom Types
import type { FooterPatternDataProps } from 'features/appBuilder/patterns/features/footer/types';

const footerPatternDataDefaults: FooterPatternDataProps = {
  patternTitle: '',
  bgColorOne: '#F5F5F5',
  bgColorTwo: '#F5F5F5',
  dividerColor: '#C1C8D3',
  bgImage: null,
  bgImageType: 'cover',
  padding: '32px',
  cardBg: '',
  cardCount: 0,
  creator: null,
  isActive: true,
  maxWidth: 'lg',
  sortIndex: 0,
  type: 'footer',
  variant: 0,
  brand: footerPatternBrandDefaults,
  menu: footerPatternMenuDefaults,
  description: footerPatternDescriptionDefaults,
  banner: footerPatternBannerDefaults,
};

/**
 * Retrieves the default footer pattern data.
 *
 * @returns {FooterPatternDataProps} The default footer pattern data.
 */
export const getFooterPatternDataDefault = () => {
  const cloned = cloneDeep(footerPatternDataDefaults);

  return cloned;
};

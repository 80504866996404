// Custom Section Components
import FormsDataGrid from 'features/form/forms/components/list/DataGrid';
import FormListActions from 'features/form/forms/components/list/Actions';

// Custom Common Components
import RowStack from 'core/components/shared/Stack/RowStack';
import PageHeadConfigs from 'core/components/shared/PageTitle';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';

// Custom Core Components
import Pagination from 'core/components/shared/Filter/Pagination';
import PageSize from 'core/components/shared/Filter/PageSize';

// Custom Hooks
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Custom Utilities
import { useFormDocs } from 'features/form/forms/hooks';

// Context
import DocsProvider from 'core/hooks/api/useDocs/Context';

interface FormListPageProps {}

const FormLists: React.FC<FormListPageProps> = (props) => {
  // Context
  const loading = useSelectLoading();

  // Hooks
  const formListMethods = useFormDocs({
    alertOnFetchError: {
      message: 'دریافت لیست‌ فرم‌ها با خطا مواجه شد.',
    },
  });

  const {
    data: { page, list },
    onPageSizeChange,
    onPageNumChange,
  } = formListMethods;

  // Render
  return (
    <DocsProvider {...formListMethods}>
      <PageHeadConfigs pageTitle='فرم‌ها' />
      <FormListActions />
      <ColumnStack>
        <FormsDataGrid />
        <RowStack>
          <PageSize
            disabled={loading || list?.length === 0}
            page={page}
            sizeRange={[5, 10, 20, 50, 100]}
            sx={{ height: '2rem' }}
            onSizeChange={onPageSizeChange}
            onPageChange={onPageNumChange}
          />
          <Pagination
            disabled={loading || list?.length === 0}
            page={page}
            onPageChange={onPageNumChange}
          />
        </RowStack>
      </ColumnStack>
    </DocsProvider>
  );
};

export default FormLists;

import type { AudienceOperationType } from 'features/audience/audiences/types';

export interface AudienceOperationOptionProps {
  value: AudienceOperationType;
  label: string;
  disabled?: boolean;
  color?: string;
}

export const audienceOperationsData: AudienceOperationOptionProps[] = [
  { value: 'DELETE_FROM_GROUP', label: 'حذف از گروه' },
  { value: 'ADD_TO_GROUP', label: 'افزودن به گروه' },
  { value: 'UPDATE_JOB_TITLE', label: 'تغییر عنوان شغلی' },
  { value: 'DELETE', label: 'حذف مخاطبان', color: 'red' },
  { value: 'EXPORT_EXCEL', label: 'خروجی Excel' },
  { value: 'ADD_ACCESS', label: 'افزودن اکسس' },
  { value: 'REMOVE_ACCESS', label: 'حذف اکسس' },
  {
    value: 'MESSAGE_ON_TELEGRAM',
    label: 'ارسال پیام در ربات تلگرام',
    disabled: true,
  },
  { value: 'MESSAGE_ON_BALE', label: 'ارسال پیام در ربات بله', disabled: true },
  { value: 'BULK_EDIT', label: 'ویرایش گروهی', disabled: true },
];

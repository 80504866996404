// Custom Types
import {
  AudienceFeatureType,
  AudienceFeatureTypeLabel,
} from 'features/audience/features/types';

/**
 * Retrieves the label corresponding to an audience feature type.
 *
 * @param {AudienceFeatureType} type - The type of the audience feature.
 * @returns {AudienceFeatureTypeLabel} The label corresponding to the audience feature type.
 */
export const getAudienceFeatureLabel = (
  type: AudienceFeatureType
): AudienceFeatureTypeLabel => {
  return AudienceFeatureTypeLabel[type];
};

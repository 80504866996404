import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// Types
import type { SubmitHandler } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';
import Divider from 'core/components/base/display/Divider';
import LoadingButton from 'core/components/base/inputs/LoadingButton';

// Components
import BodyOne from 'core/components/shared/Typography/BodyOne';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import CopyToClipboard from 'core/components/shared/Typography/CopyToClipboard';
import HeadingSix from 'core/components/shared/Typography/HeadingSix';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import PageHeroBox from 'core/components/shared/Box/PageHero';
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import TwoFactorTextField from 'core/components/shared/Input/TwoFactorTextField';
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Hooks
import useUnMount from 'core/hooks/useUnMount';
import { useSelectLoading } from 'core/store/slices/core/shared/loading';
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// Utilities
import { isSucceed, setAppAlert, setAppLoading } from 'core/utilities/helper';
import {
  create2FAQr,
  enable2FA,
} from 'features/manager/auth/utilities/shared/api';

// Validation Schema
import TwoFactorVerificationCodeSchema from 'features/manager/auth/validations/TwoFactor';

// Types
import type { SnackbarAlertStateProps } from 'core/components/shared/Snackbar';
import type { TwoFactorAuthProps } from 'features/manager/auth/types';
import { useEnable2FaMutation } from 'features/manager/auth/hooks';
interface EnableTwoFactorAuthProps {}

export interface EnableTwoFactorAuthStateProps {
  alertMessage: SnackbarAlertStateProps;
  twoFactorAuthData: {
    ascii: string;
    qr: string;
    key: string;
  };
}

const downloadMethods = [
  {
    alt: 'لینک دانلود Google Authenticator از گوگل پلی',
    src: '/images/google-play.svg',
    href: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US',
  },
  {
    alt: 'لینک دانلود Google Authenticator از اپ استور',
    src: '/images/app-store.svg',
    href: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
  },
];

const EnableTwoFactorAuth: React.FC<EnableTwoFactorAuthProps> = (props) => {
  // States
  const [twoFactorProps, setTwoFactorProps] =
    React.useState<TwoFactorAuthProps>({
      id: '',
      data: {
        ascii: '',
        imageSrc: '',
        key: '',
      },
    });

  // Context
  const loading = useSelectLoading();

  // Hooks
  const navigate = useNavigate();
  const resolver = useYupValidationResolver(TwoFactorVerificationCodeSchema);
  const { control, formState, handleSubmit, watch } = useForm<{ code: string }>(
    {
      mode: 'onTouched',
      resolver,
      defaultValues: { code: '' },
    }
  );
  useUnMount(() => setAppLoading(false));

  const setQRData = React.useCallback(async () => {
    setAppLoading(true);

    const { status, doc: qrData } = await create2FAQr();

    if (isSucceed(status) && qrData) setTwoFactorProps(qrData);
    else setAppAlert('خطا در ایجاد کد QR.');

    setAppLoading(false);
  }, [setTwoFactorProps]);

  React.useEffect(() => {
    setQRData();
  }, [setQRData]);

  const handleFormSubmit: SubmitHandler<{ code: string }> = async (data) => {
    if (!twoFactorProps.data.ascii) return;
    setAppLoading(true);

    const code = data.code.replaceAll('-', '').replaceAll(' ', '');
    const { status } = await enable2FA({
      code,
      ascii: twoFactorProps.data.ascii,
    });

    if (isSucceed(status)) {
      setAppAlert('ورود دو مرحله‌ای برای شما فعال گردید.', 'success');

      navigate('/profile');

      return;
    } else if (status === 401) setAppAlert('کد وارد شده نادرست می‌باشد.');
    else setAppAlert('خطا در فعا‌ل‌سازی ورود دو مرحله‌ای.');

    setAppLoading(false);
  };

  return (
    <>
      <PageHeadConfigs pageTitle='فعال‌سازی ورود دو مرحله‌ای' />
      <PageHeroBox>
        <HeadingSix>فعال‌سازی ورود دو مرحله‌ای</HeadingSix>
        <LoadingButton
          disabled={!watch('code')}
          loading={watch('code') && loading ? true : false}
          onClick={handleSubmit(handleFormSubmit)}
        >
          فعال‌سازی
        </LoadingButton>
      </PageHeroBox>
      <RoundPaper sx={{ padding: '1rem 2rem' }}>
        <BodyOne
          sx={{
            textAlign: 'center',
            maxWidth: '60%',
            marginX: 'auto',
            marginY: '2rem',
          }}
        >
          در صورت فعال‌سازی ورود دو مرحله‌ای، برای ورود به داشبورد نیاز است پس
          از وارد کردن پسورد، کد شش رقمی جدیدی وارد شود.
        </BodyOne>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: '30%', p: '0.5rem' }}>
            <Box sx={{ marginRight: '1rem' }}>
              <BodyOne textAlign='justify'>
                {'1. ابتدا اپلیکیشن Google Authenticator را از اپ استور و یا گوگل پلی دانلود کنید.'.toPersian()}
              </BodyOne>
              <Box sx={{ marginTop: '5rem' }}>
                {downloadMethods.map((method) => (
                  <Box
                    key={method.href + method.alt}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      alt={method.alt}
                      src={method.src}
                      style={{
                        height: '70px',
                        width: '180px',
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Divider variant='middle' orientation='vertical' flexItem />
          <Box sx={{ width: '40%', p: '0.5rem' }}>
            <Box sx={{ marginRight: '1rem' }}>
              <BodyOne textAlign='justify'>
                {'2. تصویر QR نمایش داده شده را توسط نرم‌افزار Google Authenticator اسکن نمائید. همچنین می‌توانید کد نمایش داده شده را به صورت دستی در Authenticator وارد نمائید.'.toPersian()}
              </BodyOne>
              <Box
                sx={{
                  height: '14rem',
                  marginX: 'auto',
                  width: '14rem',
                }}
              >
                {twoFactorProps.data.imageSrc && (
                  <img
                    alt='بارکد qr برای فعال‌سازی ورود دو مرحله‌ای'
                    src={`data:image${twoFactorProps.data.imageSrc}`}
                    style={{ height: '100%', width: '100%' }}
                  />
                )}
              </Box>
              {twoFactorProps.data.imageSrc && (
                <CopyToClipboard
                  textProps={{
                    sx: {
                      width: '12rem',
                    },
                  }}
                  value={twoFactorProps.data.key}
                />
              )}
            </Box>
          </Box>
          <Divider variant='middle' orientation='vertical' flexItem />
          <Box sx={{ width: '30%', p: '0.5rem' }}>
            <ColumnStack>
              <BodyOne textAlign='justify'>
                {'3. کد شش رقمی دریافت شده را برای فعال‌سازی وارد کنید.'.toPersian()}
              </BodyOne>
              <Box>
                <InputLabel>کد فعال‌سازی</InputLabel>
                <Controller
                  control={control}
                  name='code'
                  render={({ field, fieldState }) => (
                    <TwoFactorTextField
                      dir='ltr'
                      disabled={loading || !twoFactorProps.data.ascii}
                      error={
                        formState.dirtyFields.code && formState.errors.code
                          ? true
                          : false
                      }
                      fullWidth
                      helperText={
                        formState.dirtyFields.code &&
                        formState.errors.code?.message
                      }
                      placeholder='Authenticator Code'
                      sx={{
                        '.MuiInputBase-input': {
                          textAlign: 'center',
                        },
                      }}
                      {...field}
                    />
                  )}
                />
              </Box>
            </ColumnStack>
          </Box>
        </Box>
      </RoundPaper>
    </>
  );
};

export default EnableTwoFactorAuth;

import { memo, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Custom Core Components
import InputBase from 'core/components/base/inputs/InputBase';
import MenuItem from 'core/components/base/navigation/MenuItem';
import Radio from 'core/components/base/inputs/Radio';

// Custom Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import Select from 'core/components/shared/Select';
import RowStack from 'core/components/shared/Stack/RowStack';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import SpacedBox from 'core/components/shared/Box/SpacedBox';

// Custom Feature Componenst
import FormInputWrapper, {
  FormInputWrapperProps,
} from 'features/form/forms/components/details/item/Input/shared/Wrapper';

// Custom Utilities
import { genObjectIdArray, isSame } from 'core/utilities/helper/helperPack';
import { toggleFormIsInInputConfigSelection } from 'features/form/forms/store/actions';

// Custom Types
import type { FormRateInputProps } from 'features/form/forms/types/item/input/rate';
import type { FormProps } from 'features/form/forms/types/item';
export interface FormRateInputComponentProps extends FormInputWrapperProps {
  input: FormRateInputProps;
}

const FormRateInput: FC<FormRateInputComponentProps> = (props) => {
  // Props
  const { input, disabled, onUpdate, ...otherProps } = props;

  // Hooks
  const { control, getValues, setValue } = useFormContext<FormProps>();
  const index = getValues('data.inputsIndexMap')[input.id];

  const FormRateInputConfig = (
    <ColumnStack spacing={1.5}>
      <InputWrapper>
        <InputLabel>تعداد مقیاس</InputLabel>
        <Controller
          control={control}
          name={`data.inputs.${index}.data.maxRate`}
          defaultValue={10}
          render={({ field }) => (
            <Select
              fullWidth
              defaultValue={field.value}
              onOpen={() => toggleFormIsInInputConfigSelection(true)}
              onClose={() => toggleFormIsInInputConfigSelection(false)}
              sx={{ width: '8rem' }}
            >
              {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                <MenuItem
                  key={`rate-size-option-${index}-value-${item}`}
                  value={item}
                  onClick={(e) => {
                    e.stopPropagation();
                    setValue(field.name, item);
                  }}
                >
                  {item.toPersian()}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </InputWrapper>
    </ColumnStack>
  );

  return (
    <FormInputWrapper
      id={input.id}
      index={index}
      type='rate'
      config={FormRateInputConfig}
      disabled={disabled}
      onUpdate={onUpdate}
      {...otherProps}
    >
      <Controller
        control={control}
        name={`data.inputs.${index}.data.placeholder`}
        defaultValue=''
        rules={{ onChange: onUpdate }}
        render={({ field }) => (
          <InputBase
            disabled={disabled}
            placeholder='متن راهنما...'
            fullWidth
            sx={{ fontSize: '0.875rem' }}
            {...field}
          />
        )}
      />
      <ColumnStack alignItems='center' mx='auto' width='fit-content'>
        <Controller
          control={control}
          name={`data.inputs.${index}.data.maxRate`}
          render={({ field }) => (
            <RowStack spacing={0.75}>
              {genObjectIdArray(field.value - 1).map((item, index) => (
                <ColumnStack key={item} spacing={0} alignItems='center'>
                  <BodyTwo>{(index + 1).toPersian()}</BodyTwo>
                  <Radio
                    disableRipple
                    disabled={disabled}
                    checked={false}
                    sx={{ width: 'fit-content', cursor: 'auto' }}
                  />
                </ColumnStack>
              ))}
            </RowStack>
          )}
        />
        <SpacedBox sx={{ width: '100%' }}>
          <Controller
            control={control}
            name={`data.inputs.${index}.data.startLabel`}
            defaultValue=''
            rules={{ onChange: onUpdate }}
            render={({ field }) => (
              <InputBase
                disabled={disabled}
                placeholder='برچسب اول'
                sx={{ fontSize: '0.875rem', width: '5rem' }}
                {...field}
              />
            )}
          />{' '}
          <Controller
            control={control}
            name={`data.inputs.${index}.data.endLabel`}
            defaultValue=''
            rules={{ onChange: onUpdate }}
            render={({ field }) => (
              <InputBase
                disabled={disabled}
                dir='ltr'
                placeholder='برچسب آخر'
                sx={{ fontSize: '0.875rem', width: '5rem' }}
                {...field}
              />
            )}
          />
        </SpacedBox>
      </ColumnStack>
    </FormInputWrapper>
  );
};

export default memo(FormRateInput, isSame);

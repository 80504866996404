// Custom Types
import type { ApiBakedCustomPageBodyProps } from 'features/appBuilder/customPages/types/body/api';
import type { CustomPageBodyProps } from 'features/appBuilder/customPages/types/body';
import type { BakedPatternInBodyDataProps } from 'features/appBuilder/patterns/types/inBody';

export const bakeCustomPageBody = (
  body: CustomPageBodyProps
): ApiBakedCustomPageBodyProps => {
  const headers: BakedPatternInBodyDataProps[] = [];
  const footers: BakedPatternInBodyDataProps[] = [];
  const contents: BakedPatternInBodyDataProps[] = [];
  const profiles: BakedPatternInBodyDataProps[] = [];
  const stables: BakedPatternInBodyDataProps[] = [];
  const groups: BakedPatternInBodyDataProps[] = [];
  const tables: BakedPatternInBodyDataProps[] = [];
  const sliders: BakedPatternInBodyDataProps[] = [];
  const forms: BakedPatternInBodyDataProps[] = [];

  body.data.patterns.forEach((p) => {
    switch (p.data.type) {
      case 'content':
        contents.push({
          ...p.data,
          contentId: p.id,
        });
        break;

      case 'footer':
        footers.push({
          ...p.data,
          footerId: p.id,
        });
        break;

      case 'header':
        headers.push({
          ...p.data,
          headerId: p.id,
        });
        break;

      case 'profile':
        profiles.push({
          ...p.data,
          profileId: p.id,
        });
        break;

      case 'stable':
        stables.push({
          ...p.data,
          stableId: p.id,
        });
        break;

      case 'group':
        groups.push({
          ...p.data,
          groupId: p.id,
        });
        break;

      case 'table':
        tables.push({
          ...p.data,
          tableId: p.id,
        });
        break;

      case 'slider':
        sliders.push({
          ...p.data,
          sliderId: p.id,
        });
        break;

      case 'form':
        forms.push({
          ...p.data,
          formId: p.id,
        });
        break;

      default:
        return;
    }
  });

  const { patterns, ...otherData } = body.data;

  return {
    id: body.id,
    data: {
      ...otherData,
      title: body.data.title,
      bodyType: 'page',
      headers,
      footers,
      contents,
      profiles,
      stables,
      sliders,
      groups,
      tables,
    },
  };
};

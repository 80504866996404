// Custom Routes
import contentsRoutes from 'features/content/contents/configs/routes';
import categoryRoutes from 'features/content/categories/configs/routes';
import tagRoutes from 'features/content/tags/configs/routes';
import filesRoutes from 'features/file/files/configs/routes';
import menusRoutes from 'features/menu/menus/configs/routes';
import bannersRoutes from 'features/banner/banners/configs/routes';
import formsRoutes from 'features/form/forms/configs/routes';
import processesRoutes from 'features/form/processes/configs/routes';
import managersRoutes from 'features/manager/managers/configs/routes';
import managerGroupsRoutes from 'features/manager/groups/configs/routes';
import managerSettingsRoutes from 'features/manager/settings/configs/routes';
import audienceAccessesRoutes from 'features/audience/accesses/configs/routes';
import audiencesRoutes from 'features/audience/audiences/configs/routes';
import audienceFoldersRoutes from 'features/audience/folders/configs/routes';
import audienceGroupsRoutes from 'features/audience/groups/configs/routes';
import audienceSettingsRoutes from 'features/audience/settings/configs/routes';
import ticketCategoriesRoutes from 'features/ticket/topics/configs/routes';
import customPagesRoutes from 'features/appBuilder/customPages/configs/routes';
import patternsRoutes from 'features/appBuilder/patterns/configs/routes';
import skinsRoutes from 'features/appBuilder/skins/configs/routes';
import templatesRoutes from 'features/appBuilder/templates/configs/routes';
import sheetRoutes from 'features/data/sheets/configs/routes';
import ticketsRoutes from 'features/ticket/tickets/configs/routes';

// Custom Types
import type { ProtectedRouteProps } from 'core/types/shared/routes';
import type { SubFeatureType } from 'core/types/feature/FeatureType';

export const subFeatureRouteMap: {
  [key in SubFeatureType]?: ProtectedRouteProps[];
} = {
  // CONTENT
  contents: contentsRoutes,
  categories: categoryRoutes,
  tags: tagRoutes,
  // FILE
  files: filesRoutes,
  // FORM
  forms: formsRoutes,
  processes: processesRoutes,
  // MANAGER
  managers: managersRoutes,
  managerGroups: managerGroupsRoutes,
  managerSettings: managerSettingsRoutes,
  // AUDIENCE
  audienceAccesses: audienceAccessesRoutes,
  audiences: audiencesRoutes,
  audienceFolders: audienceFoldersRoutes,
  audienceGroups: audienceGroupsRoutes,
  audienceSettings: audienceSettingsRoutes,
  // MENU
  menus: menusRoutes,
  // BANNER
  banners: bannersRoutes,
  // TEMPLATE
  customPages: customPagesRoutes,
  patterns: patternsRoutes,
  skins: skinsRoutes,
  templates: templatesRoutes,
  // DATA
  sheets: sheetRoutes,
  // TICKET
  tickets: ticketsRoutes,
  ticketCategories: ticketCategoriesRoutes,
};

export const getSubFeatureRoute = (
  subFeature: SubFeatureType
): ProtectedRouteProps[] => subFeatureRouteMap[subFeature] || [];

export const getSubFeatureRoutes = (
  subFeatures: SubFeatureType[]
): ProtectedRouteProps[] => {
  const routes: ProtectedRouteProps[] = [];

  subFeatures.forEach((subFeature) => {
    const routesToPush = getSubFeatureRoute(subFeature);

    if (routesToPush.length > 0) {
      routesToPush.forEach((routeToPush) => routes.push(routeToPush));
    }
  });

  return routes;
};

// Feature Utilities
import { bakePatternCommonProps } from 'features/appBuilder/patterns/core/utilities/api/baker';

// Feature Types
import type { StablePatternDataProps } from 'features/appBuilder/patterns/features/stable/types';

/**
 * Transforms the provided stable pattern data by ensuring the `creator` field is included as a string.
 *
 * @param {StablePatternDataProps} data - The stable pattern data to transform.
 * @returns {Omit<StablePatternDataProps, 'creator'> & { creator: string }} The transformed stable pattern data with the `creator` field as a string.
 */
export const bakeStablePatternData = (
  data: StablePatternDataProps
): Omit<StablePatternDataProps, 'creator'> & { creator: string } => {
  const { content, ...otherProps } = data;

  const commonProps = bakePatternCommonProps(otherProps);

  return {
    ...commonProps,
    content,
  };
};
